import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AgendamientoCitaService } from './agendamiento-cita.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AgendamientoModel, ConsultarModel } from '../../dtos/agendamiento-citas/proyecto-validador';
import { ProgressSpinnerDialogComponent } from '../../progress-spinner-dialog/progress-spinner-dialog.component';
import { forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {MAT_DATE_LOCALE, DateAdapter} from '@angular/material';
import * as _moment from 'moment';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-agendamiento-cita',
  templateUrl: './agendamiento-cita.component.html',
  styleUrls: ['./agendamiento-cita.component.sass'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }]
})


export class AgendamientoCitaComponent implements OnInit {

  proyecto: AgendamientoModel = {};
  consulta: ConsultarModel = {};
  cancelarModel: ConsultarModel = {};
  respuestaRecaptcha = null;

  tipoDocumentos;
  temasVisita;
  horarios;
  turnos;
  diasAdelantes;

  tipoDocumentoSeleccionado;
  temaVisitaSeleccionado;
  horarioVisitaSeleccionado;

  festivos;

  minDate: Date;
  maxDate: Date;

  visibleDialogoConfirmacion: boolean = false;
  visibleDialogoConsulta: boolean = false;
  visibleDialogoCancelar: boolean = false;

  dataConfirmacion = {};
  visibleEnviar: boolean = false;


  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(
    private info: AgendamientoCitaService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    private messageService: MessageService,
    private dateAdapter: DateAdapter<any>,
    private principalComponent: PrincipalComponent
    ) {

    }

  /**Metodo que inicializa el componente */
  ngOnInit() {
    this.proyecto.cancelar = false;
    this.proyecto.codigoValidacion = null;
    this.cargarDatos();
    this.dateAdapter.setLocale('es-ES');
  }


  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    const date = d.getDate();
    const dateCompare = _moment(d).format('YYYY-MM-DD')
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6 && (this.festivos.find(row => dateCompare === row.fecha) ? false : true);
  }

  public resolved(captchaResponse: string) {
    this.respuestaRecaptcha = captchaResponse;
  }

  /**Metodo que carga los datos */
  cargarDatos() {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });

    forkJoin(
      this.info.getTipoDocumento(),
      this.info.getTemaVisita(),
      this.info.getTurnos(),
      this.info.getDiasAdelante(),
      this.info.getFestivos()
    ).subscribe(([documentos, visitas, turno, dias, festivos]) => {
      this.tipoDocumentos = documentos;
      this.temasVisita = visitas;
      this.turnos = turno;
      this.diasAdelantes = dias;
      this.festivos = festivos;
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const currentDay = new Date().getDate();
      this.minDate = new Date(currentYear , currentMonth, currentDay + 1);
      this.maxDate = new Date(currentYear , currentMonth, currentDay + Number( this.diasAdelantes.valor) + 2);
      this.dialogSpin.close();

    }, error => {
      this.principalComponent.cargarErrorServicio(error);
      this.dialogSpin.close();
    });
  }

  /**Metodo que convierte la la fecha a string */
  convertStringDateToTime(data) {
      const date = new Date(data);
      const localOffset = date.getTimezoneOffset() * 60000;
      const utc = date.getTime() + localOffset;
      return utc;
    }

    /**Metodo que confirma la cancelacion */
    cancelarConfirmacion(articulacionForm: NgForm) {
      this.visibleDialogoConfirmacion = false;
      this.visibleDialogoConsulta = false;
      this.visibleDialogoCancelar = false;

    }

    /**Metodo que carga el habeas data */
    changeHabeasData(event){
      if (event === 'true') {
        this.visibleEnviar = true;
      } else {
        this.visibleEnviar = false;
      }
    }

    /**Metodo que cambia el horario */
    changeHorario(event){
      if (event !== null && event !== undefined ) {
        const dateCompare = _moment(event).format('YYYYMMDD');
        this.horarioVisitaSeleccionado = null;
        this.info.getHorario(dateCompare).subscribe((data: {}) => this.horarios = data 
        ,error => {
          this.principalComponent.cargarErrorServicio(error);
        })
      }
    }

    /**Metodo que guarda la informacion */
    guardarAgendamiento(formAgendamiento: NgForm) {
      this.proyecto.idIdentificacion = this.tipoDocumentoSeleccionado.id;
      this.proyecto.idTemaVisita = this.temaVisitaSeleccionado.id;
      this.proyecto.horario = this.horarioVisitaSeleccionado.nombre;
     this.info.guardarAgendamiento(this.proyecto).subscribe((data: {}) => {
       let array: any = data;
        if (array.codigo != null) {
          this.visibleDialogoConfirmacion = true;
          this.dataConfirmacion = array.codigo;
          formAgendamiento.resetForm();
        } else {
          this.lanzarMensajeError(array.respuestaServicio.mensajeSalida);
        }
      },error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**Metodo de consultar agendamiento */
  consultarAgendamiento(formAgendamientoConsultar: NgForm) {
   this.info.consultarAgendamiento(this.consulta).subscribe((data: {}) => {
     let array: any = data;
      if (array.codigo != null) {
        this.visibleDialogoConsulta = true;
        this.dataConfirmacion = array.codigo;
        formAgendamientoConsultar.resetForm();
      } else {
        this.lanzarMensajeError(array.respuestaServicio.mensajeSalida);
      }
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
}

/**Metodo que cancela el agendamiento */
cancelarAgendamiento(formAgendamientoCancelar: NgForm) {
 this.info.cancelarAgendamiento(this.cancelarModel).subscribe((data: {}) => {
   let array: any = data;
    if (array.codigo != null) {
      this.visibleDialogoCancelar = true;
      this.dataConfirmacion = array.codigo;
      formAgendamientoCancelar.resetForm();
    } else {
      this.lanzarMensajeError(array.respuestaServicio.mensajeSalida);
    }
  },error => {
    this.principalComponent.cargarErrorServicio(error);
  });
}

    ///////////////////////////////Mensajes//////////////////////////////
    lanzarMensajeInfo(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
    }

    lanzarMensajeError(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
    }

    lanzarMensajeWarning(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
    }

    limpiarMensajes() {
      this.messageService.clear();
    }

}






