import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { CmsEstadoDTO, CmsSolicitudComisionDTO, VwCmsComision, CmsComisionModificacionDTO } from '../comisiones.dto';
import { forkJoin } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ComisionesService } from "../comisiones.service";
import { EnumCmsConstantes } from 'src/app/comisiones/comisiones-constantes';
import { EnumCmsMaTipoModificacionComision } from '../comisiones-constantes';
import { EnumRegex, EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";

@Component({
  selector: 'app-comisiones-solicitudes',
  templateUrl: './comisiones-solicitudes.component.html',
  styleUrls: ['./comisiones-solicitudes.component.sass']
})
export class ComisionesSolicitudesComponent implements OnInit {

  blockedDocument: boolean;

  solicitud: VwCmsComision = {};
  solicitudList: VwCmsComision[];
  visibleDialogoDesistirComision: boolean;
  visibleDialogoModificaComision: boolean;
  visibleDialogoCancelarComision: boolean;
  visibleDialogoValidacionDependencia: boolean;
  observacionesCancelacion: string;
  cantidadMinCaracteresCampoAbierto = 10;
  visibleDialogoDesistirModificacionComision: boolean;

  estadoComision: CmsEstadoDTO = {};
  estadoComisionList: CmsEstadoDTO[];
  formCancelarComision;
  formModificaComision;
  cmsComisionModificacion: CmsComisionModificacionDTO;

  constructor(
    formBuilder: FormBuilder,
    private router: Router,
    private comisionesService: ComisionesService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
    this.formCancelarComision = formBuilder.group({
      observacionesCancelacion: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(2000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });

    this.formModificaComision = formBuilder.group({
      observacionesModificacion: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(2000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.blockedDocument = true;
    this.validarDependenciaPersona();
    this.cargarListadoComisiones();
  }

  validarDependenciaPersona() {
    this.comisionesService.srvValidarDependenciaPersonaComision()
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida !== EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.visibleDialogoValidacionDependencia = true;
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            this.blockedDocument = false;
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  cargarListadoComisiones() {
    forkJoin(
      this.comisionesService.srvListarComisionesPersona(),
      this.comisionesService.srvListarEstadosComision()
    ).subscribe(
      ([comisionPersonaListData, estadosData]) => {
        this.solicitudList = comisionPersonaListData.comisiones;
        this.estadoComisionList = estadosData.estados;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  onChangeEstadoComision() {
    if (this.estadoComision !== null) {
      this.comisionesService.srvListarComisionesPorEstado(this.estadoComision.id).subscribe(
        result => {
          if (result != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.solicitudList = result.comisiones;
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    } else {
      this.comisionesService.srvListarComisionesPersona().subscribe(
        result => {
          if (result != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.solicitudList = result.comisiones;
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  registrarNuevaSolicitud() {
    this.router.navigate([this.router.url.replace('comisiones-solicitudes', '') + "/comisiones-crear-editar-solicitud/0/0/0/false/false/0/false"]);
  }

  editarSolicitud(solicitudL: VwCmsComision) {
    this.solicitud = solicitudL;
    this.router.navigate([this.router.url.replace('comisiones-solicitudes', '') + "/comisiones-crear-editar-solicitud/" + this.solicitud.idComision + "/" + this.solicitud.idComisionVersion + "/0" + "/false/false/" + this.solicitud.idPersona + "/false"]);
  }

  irCrearNuevaVersionComision(solicitud: VwCmsComision) {
    this.blockedDocument = true;
    this.solicitud = solicitud;
    this.formModificaComision.reset();
    this.visibleDialogoModificaComision = true;
    this.cmsComisionModificacion = {};
  }

  crearNuevaVersionComision() {
    this.visibleDialogoModificaComision = false;
    if (this.formModificaComision.valid) {
      this.blockedDocument = true;
      this.cmsComisionModificacion = {
        idComision: this.solicitud.idComision,
        idComisionVersion: this.solicitud.idComisionVersion,
        observaciones: this.formModificaComision.value.observacionesModificacion
      }

      forkJoin(
        this.comisionesService.srvModificarComision(this.cmsComisionModificacion)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.cargarListadoComisiones();
              this.router.navigate([this.router.url.replace('comisiones-solicitudes', '') + "/comisiones-crear-editar-solicitud/" + this.solicitud.idComision + "/" + result.idComisionVersion + "/0" + "/false/false/" + this.solicitud.idPersona + "/false"]);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            this.blockedDocument = false;
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        },
        () => {
          this.blockedDocument = false;
        }
      );
    } else {
      this.messageService.add({ severity: "error", summary: "Error", detail: "El campo justificación de modificación es obligatorio.", life: 10000, });
      this.blockedDocument = false;
      this.formModificaComision.reset();
    }
  }

  verDetalles(solicitudL: CmsSolicitudComisionDTO) {
    this.solicitud = solicitudL;
    this.router.navigate([this.router.url.replace('comisiones-solicitudes', '') + "/comisiones-crear-editar-solicitud/" + this.solicitud.idComision + "/" + this.solicitud.idComisionVersion + "/0" + "/true/false/" + this.solicitud.idPersona + "/false"]);
  }

  irDesistirComision(solicitudL: CmsSolicitudComisionDTO) {
    this.solicitud = solicitudL;
    this.visibleDialogoDesistirComision = true;
    this.cmsComisionModificacion = {};
  }

  desisitirComision() {
    this.blockedDocument = true;
    this.visibleDialogoDesistirComision = false;
    this.cmsComisionModificacion = {
      idComision: this.solicitud.idComision,
      idComisionVersion: this.solicitud.idComisionVersion,
      observaciones: ''
    }

    forkJoin(
      this.comisionesService.srvDesistirComision(this.cmsComisionModificacion)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {

          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cargarListadoComisiones();
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          this.blockedDocument = false;
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irDesistirModificacionComision(solicitudL: CmsSolicitudComisionDTO) {
    this.solicitud = solicitudL;
    this.visibleDialogoDesistirModificacionComision = true;
  }

  desistirModificacionComision() {
    this.blockedDocument = true;
    this.visibleDialogoDesistirModificacionComision = false;
    this.cmsComisionModificacion = {
      idComision: this.solicitud.idComision,
      idComisionVersion: this.solicitud.idComisionVersion,
      observaciones: ''
    }

    forkJoin(
      this.comisionesService.srvDesistirModificarComision(this.cmsComisionModificacion)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {

          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cargarListadoComisiones();
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          this.blockedDocument = false;
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }


  irCacelarComision(solicitudL: CmsSolicitudComisionDTO) {
    this.solicitud = solicitudL;
    this.visibleDialogoCancelarComision = true;
    this.formCancelarComision.reset();
    this.cmsComisionModificacion = {};
  }

  cancelarComision() {
    this.blockedDocument = true;
    this.visibleDialogoCancelarComision = false;
    this.cmsComisionModificacion = {
      idComision: this.solicitud.idComision,
      idComisionVersion: this.solicitud.idComisionVersion,
      observaciones: this.observacionesCancelacion
    }
    this.cmsComisionModificacion.idPaso = EnumCmsConstantes.CMS_PASO_COMISION_CANCELADA;

    if (this.formCancelarComision.valid) {
      forkJoin(
        this.comisionesService.srvCancelarComision(this.cmsComisionModificacion)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.cargarListadoComisiones();
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            this.blockedDocument = false;
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        },
        () => {
          this.blockedDocument = false;
        }
      );
    } else {
      this.messageService.add({ severity: "error", summary: "Error", detail: "El campo justificación de cancelación es obligatorio.", life: 10000, });
      this.blockedDocument = false;
    }

  }

  bntVolver() {
    const ruta = "/comisiones";
    this.router.navigate([ruta]);
  }


  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

  /////////////////////////////// Mensajes//////////////////////////////
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
