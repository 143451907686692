export class Data {
    constructor(
        public id?: number,
        public objeto?: string,
        public label?: string,
        public value?: string,
        public zona?: string,
        public numero_de_contrato?: string,
        public banner1s?: Banner1s [],
        public estado_proyecto?: string,
        public categoria?: number,
        public fecha_publicacion?: Date,
        public fecha_publicacion_str?: string,
        public imagenes?: Imagenes [],
        public contratista?: string,
        public documentos?: Documentos [],
        public field_municipio?: string,
        public field_fecha_suscripcion_del_cont?: string,
        public field_fecha_inicio_proyecto?: string,
        public field_fecha_fin_proyecto?: string,
        public field_interventor_proyecto?: string,
        public field_tipo_de_carga?: string,
        public field_tipo_de_permiso?: string,
        public field_valor_proyecto?: string,
        public field_interventor_proyecto_2?: string,
        public field_accionistas_contratista_s?: Field_accionistas_contratista_s [],
        public field_accionistas_interventores_s?: Field_accionistas_interventores_s [],
        public field_accionistas_interventordos_s?: Field_accionistas_interventordos_s [],
        public url_contratista?: string,
        public field_url_interventor_proyecto?: string,
        ){ }
}

export class Banner1s {
    constructor(
        public filename?: string,
        public status?: string
        ){ }
}
export class Imagenes {
    constructor(
        public fid?: number,
        public uid?: number,
        public filename?: string,
        public filemime?: string
        ){ }
}


export class Documentos {
    constructor(
        public fid?: number,
        public uid?: number,
        public filename?: string,
        public filemime?: string
        ){ }
}


export class Field_accionistas_contratista_s {
    constructor(
        public field_contratista_listado?: string,
        public field_porcentaje_participacion?: string,
        public field_nacionalidad?: string,
        ){ }
}

export class Field_accionistas_interventores_s {
    constructor(
        public field_interventor_listado?: string,
        public field_porcentaje_participacion?: string,
        public field_nacionalidad?: string,
        ){ }
}
export class Field_accionistas_interventordos_s {
    constructor(
        public field_contratista_interventor?: string,
        public field_porcentaje_participacion?: string,
        public field_nacionalidad?: string,
        ){ }
}