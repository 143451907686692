import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class InteresadosListaService {

  public END_POINT = '';

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public getProyectos(id) {
    return this.http.get(this.END_POINT + '/ServiciosCarga/list/' + id )
      .pipe(
        retry(1));
  }

  public getTrayectos(id) {
    return this.http.get(this.END_POINT + '/ServiciosCarga/trayectos/' + id)
      .pipe(
        retry(1));
  }

  public getInteresados(id, registry) {
    return this.http.get(this.END_POINT + '/ServiciosCarga/interesados/list/' + id + '/' + registry)
      .pipe(
        retry(1));
  }

  public deleteProyecto(id) {
    return this.http.delete(this.END_POINT + '/ServiciosCarga/' + id )
      .pipe(
        retry(1));
  }

  public guardarEditProyecto(data) {
    return this.http.put(this.END_POINT + '/ServiciosCarga/' , data)
      .pipe(
        retry(1));
  }

  public guardarNuevoProyecto(data) {
    return this.http.post(this.END_POINT + '/ServiciosCarga/', data)
      .pipe(
        retry(1));
  }

}
