import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { EnumRespuestaServicio, EnumRegex, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

import { ProyectoDTO } from 'src/app/modulo-planeacion-proyecto/planeacion-proyecto.dto';
import { UnidadFuncionalCarreteroService } from 'src/app/unidad-funcional-carretero/unidad-funcional-carretero.service';

import { CrrMaPrKDTO } from 'src/app/equipos-its/equipos-its.dto';
import { UnidadFuncional } from 'src/app/unidad-funcional/unidad-funcional';
import { CrrViaAfectadaDTO, CrrMaTipoCierreViaDTO, CrrImagenViaAfectadaDTO, CrrMaClasificacionContingenciaCierreViaDTO, CrrTipoAfectacionViaDTO, CrrMaTipoAfectacionCierreViaDTO, EnumCrrMaTipoAfectacion } from '../../ola-invernal.dto';
import { OlaInvernalService } from '../../ola-invernal.service';
import { TraficoPeajesCargaService } from 'src/app/peajes-gestion/trafico-peajes/trafico-peajes-carga/trafico-peajes-carga.service';
import { PjePeajePorProyecto } from 'src/app/peajes-gestion/trafico-peajes/PjePeajePorProyecto';
import { InformacionGeneralPeajesService } from 'src/app/peajes-gestion/informacion-general-peajes/informacion-general-peajes.service';
import { PjeUbicacionPeajeDTO } from 'src/app/peajes-gestion/informacion-general-peajes/PjeUbicacionPeajeDTO';

@Component({
  selector: 'app-ola-invernal-registro',
  templateUrl: './ola-invernal-registro.component.html',
  styles: []
})
export class OlaInvernalRegistroComponent implements OnInit {


  blockedDocument: boolean;

  idProyecto: number;
  soloLectura: boolean;
  idOlaInvernal: number;

  fechaActual: Date;
  cantidadMinCaracteresCampoAbierto: number;
  cantidadMaximaAdjuntos: number;
  archivos: any = []; //Los archivos que yo suba van a quedar en este arreglo

  proyectoSeleccionado: ProyectoDTO;

  cierreViaAfectada: CrrViaAfectadaDTO;
  unidadesFuncionales: any[] = [];
  tiposCierre: CrrMaTipoCierreViaDTO[] = [];
  clasificacionesContingencia: CrrMaClasificacionContingenciaCierreViaDTO[] = [];
  tiposAfectacion: CrrMaTipoAfectacionCierreViaDTO[] = [];
  prkList: CrrMaPrKDTO[] = [];
  dialogoExitoCierre = false;

  listaCrrTipoAfectacionVia: CrrTipoAfectacionViaDTO[] = [];
  afectacionViaAfectada: CrrTipoAfectacionViaDTO;
  peajes: PjePeajePorProyecto[] = [];
  dialogoGuardarAfectacion = false;
  dialogoEliminarAfectacion = false;

  listaCrrImagenViaAfectada: CrrImagenViaAfectadaDTO[] = [];
  imagenViaAfectada: CrrImagenViaAfectadaDTO;
  dialogoGuardarFotografia = false;
  dialogoEliminarFotografia = false;
  cantidadMaximaRegistrosFotograficos: number;
  cantidadActualRegistrosFotograficos: number;

  formCierre;
  formAfectacion;
  formApertura;
  formFotografia;

  constructor(
    formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private olaInvernalService: OlaInvernalService,
    private componentesComunesService: ComponentesComunesService,
    private unidadFuncionalCarreteroService: UnidadFuncionalCarreteroService,
    private traficoPeajesCargaService: TraficoPeajesCargaService,
    private informacionGeneralPeajesService: InformacionGeneralPeajesService
  ) {
    this.soloLectura = true;
    this.fechaActual = new Date();
    this.cantidadMinCaracteresCampoAbierto = 10;
    this.cantidadMaximaAdjuntos = 1;
    this.cantidadMaximaRegistrosFotograficos = 2;
    this.cantidadActualRegistrosFotograficos = 0;

    this.cierreViaAfectada = {};
    this.imagenViaAfectada = {};

    this.prkList = [
      {
        nombre: "PR",
      },
      {
        nombre: "KM",
      },
    ];

    this.formCierre = formBuilder.group({
      unidadFuncional: [null, [Validators.required]],
      codigoRuta: [null, [Validators.required, Validators.maxLength(20), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
      tipoUbicacion: [null, [Validators.required]],
      prInicial: [null, [Validators.required, Validators.max(999), Validators.min(0), Validators.maxLength(3), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
      prFinal: [null, [Validators.required, Validators.max(999), Validators.min(0), Validators.maxLength(3), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
      distanciaAlPuntoInicial: [null, [Validators.required, Validators.min(0), Validators.max(1200), Validators.maxLength(16)]],
      distanciaAlPuntoFinal: [null, [Validators.required, Validators.min(0), Validators.max(1200), Validators.maxLength(16)]],
      latitudInicial: [null, [Validators.required, Validators.min(-90), Validators.max(90), Validators.maxLength(16), Validators.pattern(EnumRegex.REGEX_LATITUD)]],
      latitudFinal: [null, [Validators.required, Validators.min(-90), Validators.max(90), Validators.maxLength(16), Validators.pattern(EnumRegex.REGEX_LATITUD)]],
      longitudInicial: [null, [Validators.required, Validators.min(-180), Validators.max(180), Validators.maxLength(16), Validators.pattern(EnumRegex.REGEX_LONGITUD)]],
      longitudFinal: [null, [Validators.required, Validators.min(-180), Validators.max(180), Validators.maxLength(16), Validators.pattern(EnumRegex.REGEX_LONGITUD)]],
      sentidoCirculacion: [null, [Validators.required, Validators.maxLength(100)]],
      motivoEvento: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(1000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
      tipoCierre: [null, [Validators.required]],
      clasificacionContingencia: [null, [Validators.required]],
      planAccion: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(1000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
      rutaAlterna: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(100), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
      fechaCierre: [null, [Validators.required]],
      fechaEstimadaApertura: [null, [Validators.required]],
    });

    this.formAfectacion = formBuilder.group({
      tipo: [null, [Validators.required]],
      peaje: [null, null],
      tipoUbicacion: [null, [Validators.required]],
      latitud: [null, [Validators.required, Validators.min(-90), Validators.max(90), Validators.maxLength(16), Validators.pattern(EnumRegex.REGEX_LATITUD)]],
      longitud: [null, [Validators.required, Validators.min(-180), Validators.max(180), Validators.maxLength(16), Validators.pattern(EnumRegex.REGEX_LONGITUD)]],
      prInicial: [null, [Validators.required, Validators.max(999), Validators.min(0), Validators.maxLength(3), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
      prFinal: [null, [Validators.required, Validators.max(999), Validators.min(0), Validators.maxLength(3), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
      distanciaAlPuntoInicial: [null, [Validators.required, Validators.min(0), Validators.max(1200), Validators.maxLength(16)]],
      distanciaAlPuntoFinal: [null, [Validators.required, Validators.min(0), Validators.max(1200), Validators.maxLength(16)]],
      observacionesAfectacion: [null, [Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(2000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });

    this.formApertura = formBuilder.group({
      fechaRealApertura: [null, [Validators.required]],
      observacionesApertura: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(1000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });

    this.formFotografia = formBuilder.group({
      fechaFotografia: [null, [Validators.required]],
      tituloFotografia: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(100), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
      descripcionFotografia: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(1000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });
  }

  ngOnInit() {

    const paramIdProyecto = "idProyecto";
    const paramIdOlaInveral = "idOlaInveral";
    const paramSoloLectura = "soloLectura";

    this.ruta.params.subscribe((params) => {
      this.idProyecto = Number(params[paramIdProyecto]);
      this.idOlaInvernal = Number(params[paramIdOlaInveral]);
      this.soloLectura = params[paramSoloLectura] == "true";
    });

    this.habilitarDeshabilitarFormularios();

    this.cargarInfoProyecto();

    this.cargarMaTiposCierre(null);
    this.cargarMaClasificacionContingencia(null);
    this.cargarMaTipoAfectacion(null);
    this.cargarUnidadesFuncionales(null);


    if (this.idOlaInvernal != 0) {
      this.cargarInformacionCierre();
      this.cargarAfectacion();
      this.cargarRegistroFotografico();
    }
  }

  /**
   * Metodo para habilitar o deshabilitar los formularios del Registro de Ola Invernal
   */
  habilitarDeshabilitarFormularios() {
    if (this.soloLectura) {
      this.formCierre.disable();
      this.formAfectacion.disable();
      this.formApertura.disable();
      this.formFotografia.disable();
    } else {
      this.formCierre.enable();
      this.formAfectacion.enable();
      this.formApertura.enable();
      this.formFotografia.enable();
    }
  }

  /**
   * Metodo para cargar la información del proyecto seleccionado
   */
  cargarInfoProyecto() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  /**
   * Metodo que consulta la información parametrica de los tipos de cierre de ola invernal
   * @param event Evento que buscar dentro del listado 
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaTiposCierre(event) {
    const filtroTipoCierre = [];
    return this.olaInvernalService.srvConsultarMaTiposCierreOlaInvernal()
      .subscribe(
        (result) => {
          this.tiposCierre = result.listaCrrMaTipoCierreVia;
          if (this.tiposCierre === null) {
            this.tiposCierre = [];
          }

          if (this.cierreViaAfectada.idTipoCierreVia != null) {
            this.cierreViaAfectada.tipoCierreViaDTO = this.tiposCierre.find(item => item.id == this.cierreViaAfectada.idTipoCierreVia);
            this.formCierre.get('tipoCierre').setValue(this.cierreViaAfectada.tipoCierreViaDTO);
          }

          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.tiposCierre.length; i++) {
              const filtro = this.tiposCierre[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroTipoCierre.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.tiposCierre = filtroTipoCierre;
            }
          }
        });
  }

  /**
   * Metodo que consulta la información de unidades funcionales del proyecto seleccionado
   * @param event Evento que buscar dentro del listado 
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarUnidadesFuncionales(event) {
    const filtroProyectos = [];
    return this.unidadFuncionalCarreteroService.getUnidadesFuncionalesPorProyecto(this.idProyecto)
      .subscribe((result) => {
        this.unidadesFuncionales = result.unidadesFuncionales;
        if (this.unidadesFuncionales === null) {
          this.unidadesFuncionales = [];
        }

        if (this.cierreViaAfectada.idUnidadFuncional != null) {
          this.cierreViaAfectada.unidadFuncionalDTO = this.unidadesFuncionales.find(item => item.idUnidadFuncional == this.cierreViaAfectada.idUnidadFuncional);
          this.formCierre.get('unidadFuncional').setValue(this.cierreViaAfectada.unidadFuncionalDTO);
        }

        if (event != null) {
          let existeFiltro: boolean;
          for (let i = 0; i < this.unidadesFuncionales.length; i++) {
            const filtro = this.unidadesFuncionales[i];
            if (String(filtro.Nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroProyectos.push(filtro);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.unidadesFuncionales = filtroProyectos;
          }
        }
      });
  }


  /**
   * Metodo que consulta la información parametrica de la clasificación de contingencia de ola invernal
   * @param event Evento que buscar dentro del listado 
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaClasificacionContingencia(event) {
    const filtroLista = [];
    return this.olaInvernalService.srvConsultarMaClasificacionContingenciaOlaInvernal()
      .subscribe(
        (result) => {
          this.clasificacionesContingencia = result.listaCrrMaClasificacionContingenciaCierreVia;
          if (this.clasificacionesContingencia === null) {
            this.clasificacionesContingencia = [];
          }

          if (this.cierreViaAfectada.idClasificacionContingencia != null) {
            this.cierreViaAfectada.clasificacionContingenciaDTO = this.clasificacionesContingencia.find(item => item.id == this.cierreViaAfectada.idClasificacionContingencia);
            this.formCierre.get('clasificacionContingencia').setValue(this.cierreViaAfectada.clasificacionContingenciaDTO);
          }

          if (event != null && this.clasificacionesContingencia != null) {
            let existeFiltro: boolean;
            for (const filtro of this.clasificacionesContingencia) {
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroLista.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.clasificacionesContingencia = filtroLista;
            }
          }
        });
  }

  /**
   * Metodo que consulta la información de parametrica de los tipos de afectaciones
   * @param event Evento que buscar dentro del listado 
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaTipoAfectacion(event) {
    const filtroList = [];
    return this.olaInvernalService.srvConsultarMaTipoAfectacionCierreVia()
      .subscribe(
        (result) => {
          this.tiposAfectacion = result.listaCrrMaTipoAfectacionCierreVia;
          if (this.tiposAfectacion === null) {
            this.tiposAfectacion = [];
          }

          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.tiposAfectacion.length; i++) {
              const filtro = this.tiposAfectacion[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroList.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.tiposAfectacion = filtroList;
            }
          }
        });
  }

  /**
   * Metodo que consulta la información de Ola Invernal registrada por id especificado
   * @returns Objeto de respuesta de tipo CrrViaAfectadaRpt
   */
  cargarInformacionCierre() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarCierreOlaInvernalPorId(this.idOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cierreViaAfectada = result.crrViaAfectada;
            if (this.cierreViaAfectada != null) {
              if (this.cierreViaAfectada.fechaCierre != null) {
                this.cierreViaAfectada.fechaCierre = new Date(this.cierreViaAfectada.fechaCierre);
              }
              if (this.cierreViaAfectada.fechaEstimadaApertura != null) {
                this.cierreViaAfectada.fechaEstimadaApertura = new Date(this.cierreViaAfectada.fechaEstimadaApertura);
              }
              if (this.cierreViaAfectada.fechaRealApertura != null) {
                this.cierreViaAfectada.fechaRealApertura = new Date(this.cierreViaAfectada.fechaRealApertura);
              }
              if (this.cierreViaAfectada.fechaReporte != null) {
                this.cierreViaAfectada.fechaReporte = new Date(this.cierreViaAfectada.fechaReporte);
              }
              if (this.cierreViaAfectada.idUnidadFuncional != null) {
                this.cargarUnidadesFuncionales(null);
              }
              if (this.cierreViaAfectada.tipoUbicacion != null) {
                this.cierreViaAfectada.tipoUbicacionDTO = this.prkList.find(item => item.nombre == this.cierreViaAfectada.tipoUbicacion);
                this.formCierre.get('tipoUbicacion').setValue(this.cierreViaAfectada.tipoUbicacionDTO);
              }
              if (this.cierreViaAfectada.idTipoCierreVia != null) {
                this.cargarMaTiposCierre(null);
              }
              if (this.cierreViaAfectada.idClasificacionContingencia != null) {
                this.cargarMaClasificacionContingencia(null);
              }
              if (this.cierreViaAfectada.observacionesApertura == null) {
                this.cierreViaAfectada.observacionesApertura = "";//Este campo no permite null
              }

            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que controla el evento al momento de seleccionar la UnidadFuncional
   * @param unidadFuncional  
   */
  onSelectUnidadFuncional(unidadFuncional: UnidadFuncional) {
    if (unidadFuncional != null) {
      this.cierreViaAfectada.idUnidadFuncional = unidadFuncional.idUnidadFuncional;
    }
  }

  /**
   * Metodo que controla el evento al momento de seleccionar un Municipio
   * @param ubicacionDeptMun 
   */
  onSelectMunicipio(ubicacionDeptMun: any) {
    if (ubicacionDeptMun != null && ubicacionDeptMun.municipio != null) {
      this.cierreViaAfectada.idMunicipio = ubicacionDeptMun.municipio.id;
    }
  }

  /**
   * Metodo que controla el evento al momento de seleccionar un Tipo de Cierre
   * @param tipoCierre 
   */
  onSelectTipoCierre(tipoCierre: CrrMaTipoCierreViaDTO) {
    if (tipoCierre != null && tipoCierre.id != null) {
      this.cierreViaAfectada.idTipoCierreVia = tipoCierre.id;
    }
  }

  /**
   * Metodo que controla el evento al momento de seleccionar una Clasificacion de Contingencia
   * @param clasificacionContingencia 
   */
  onSelectClasificacionContingencia(clasificacionContingencia: CrrMaClasificacionContingenciaCierreViaDTO) {
    if (clasificacionContingencia != null) {
      this.cierreViaAfectada.idClasificacionContingencia = clasificacionContingencia.id;
    }
  }

  /**
   * Metodo que controla el evento al momento de seleccionar el tipo de ubicacion
   * @param event 
   */
  onChangePR(event) {
    if (event.value != null) {
      this.cierreViaAfectada.tipoUbicacion = event.value.nombre;
    }
  }

  /**
   * Metodo que valida que los campos del formulario de cierre esten completos
   * @returns 
   */
  validarFormCierre() {
    let formularioValido = true;
    if (!this.formCierre.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Por favor diligencia los campos marcados con *");
    } else {
      //SI ES VALIDO
      if (this.cierreViaAfectada == null) {
        formularioValido = false;
        this.lanzarMensajeError("El cierre seleccionado es null");
      }
      if (this.cierreViaAfectada.idUnidadFuncional == null || this.cierreViaAfectada.idUnidadFuncional == 0) {
        formularioValido = false;
        this.lanzarMensajeError("Debe seleccionar la UF");
      }
      if (this.cierreViaAfectada.idMunicipio == null || this.cierreViaAfectada.idMunicipio == "") {
        formularioValido = false;
        this.lanzarMensajeError("Debe seleccionar el departamento y municipio");
      }
      if (this.cierreViaAfectada.idTipoCierreVia == null || this.cierreViaAfectada.idTipoCierreVia == 0) {
        formularioValido = false;
        this.lanzarMensajeError("Debe seleccionar el tipo de cierre");
      }
      if (this.cierreViaAfectada.idClasificacionContingencia == null || this.cierreViaAfectada.idClasificacionContingencia == 0) {
        formularioValido = false;
        this.lanzarMensajeError("Debe seleccionar la clasificación de la contingencia");
      }
    }
    return formularioValido;
  }

  /**
   * Metodo que valida que los campos del formulario de apertura esten completos
   * @returns 
   */
  validarFormApertura() {
    let formularioValido = true;
    if (!this.formApertura.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Por favor diligencia los campos marcados con *");
    } else {
      //SI ES VALIDO
      if (this.cierreViaAfectada == null) {
        formularioValido = false;
        this.lanzarMensajeError("El cierre seleccionado es null");
      }
    }
    return formularioValido;
  }

  /**
   * Metodo que llama al servicio de guardar cierre de Ola Invernal
   */
  guardarCierreOlaInvernal() {
    this.blockedDocument = true;
    if (this.validarFormCierre()) {

      if (this.idOlaInvernal == 0) {
        if (this.cierreViaAfectada.observacionesApertura == null || this.cierreViaAfectada.observacionesApertura == undefined) {
          this.cierreViaAfectada.observacionesApertura = " ";
        }
      }
      this.olaInvernalService.srvGuardarCierreOlaInvernal(this.cierreViaAfectada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              if (this.idOlaInvernal == 0) {
                this.dialogoExitoCierre = true;
              }
              this.idOlaInvernal = result.id;
              this.cargarInformacionCierre();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    } else {
      this.blockedDocument = false;
    }
  }

  /**
   * Metodo que llama al servicio de guardar cierre de Ola Invernal con la información de apertura
   */
  guardarAperturaOlaInvernal() {
    this.blockedDocument = true;
    if (this.validarFormApertura()) {
      this.olaInvernalService.srvGuardarCierreOlaInvernal(this.cierreViaAfectada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarInformacionCierre();
              this.bntVolver();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    } else {
      this.blockedDocument = false;
    }
  }

  //#region "Registro Afectación"

  /**
   * Metodo que llama al servicio de consulta de afectaciones por cierre de ola invernal
   * @returns Objeto de tipo CrrTipoAfectacionViaDTO[] con el listado de afectaciones
   */
  cargarAfectacion() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarAfectacionesPorIdViaAfectada(this.idOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.listaCrrTipoAfectacionVia = result.listaCrrTipoAfectacionVia;
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que inicializa las variables y el formulario de afectación para ola invernal
   */
  inicializarVariablesAfectacion() {
    this.iniciarlizarDTOAfectacion();
    this.formAfectacion.reset();
    this.formAfectacion.enable();
  }

  /**
   * Metodo que inicializa las variable de afectación para ola invernal
   */
  iniciarlizarDTOAfectacion() {
    this.afectacionViaAfectada = {};
    this.afectacionViaAfectada.idViaAfectada = this.cierreViaAfectada.id;
    this.afectacionViaAfectada.idPeaje = null;
    this.afectacionViaAfectada.peajeDTO = null;
    this.formAfectacion.get('peaje').setValidators(null);
  }

  /**
   * Metodo que inicializa las variable de afectación para ola invernal y abre el dialog del formulario de afectacion
   */
  irAgregarAfectacion() {
    this.inicializarVariablesAfectacion();
    this.blockedDocument = true;
    this.dialogoGuardarAfectacion = true;
  }

  /**
   * Metodo que gestiona el formulario de afectacion para consulta y consulta de la afectacion 
   * @param afectacion Objeto de tipo CrrTipoAfectacionViaDTO para realizar la consulta correspondiente
   */
  irConsultarAfectacion(afectacion: CrrTipoAfectacionViaDTO) {
    this.inicializarVariablesAfectacion();
    this.blockedDocument = true;
    this.formAfectacion.disable();
    this.cargarAfectacionPorId(afectacion.id);
  }

  /**
   * Metodo que gestiona el formulario de afectacion para edicion y consulta de la afectacion, deshabilita los campos si el tipo es peaje porque estos datos son propios del peaje y no se deben modificar
   * @param afectacion Objeto de tipo CrrTipoAfectacionViaDTO para realizar la consulta correspondiente
   */
  irEditarAfectacion(afectacion: CrrTipoAfectacionViaDTO) {
    this.inicializarVariablesAfectacion();
    this.blockedDocument = true;

    this.afectacionViaAfectada = afectacion;
    this.cargarAfectacionPorId(afectacion.id);

    if (this.afectacionViaAfectada.idTipo === EnumCrrMaTipoAfectacion.PJE_TIPO_AFECTACION_PEAJE) {
      this.formAfectacion.controls['tipo'].disable();
      this.formAfectacion.get('peaje').setValidators(Validators.required);
      this.formAfectacion.controls['tipoUbicacion'].disable();
      this.formAfectacion.controls['latitud'].disable();
      this.formAfectacion.controls['longitud'].disable();
      this.formAfectacion.controls['prInicial'].disable();
      this.formAfectacion.controls['prFinal'].disable();
      this.formAfectacion.controls['distanciaAlPuntoInicial'].disable();
      this.formAfectacion.controls['distanciaAlPuntoFinal'].disable();
    } else {
      this.formAfectacion.enable();
      this.formAfectacion.controls['tipo'].disable();
    }
  }

  /**
   * Metodo que gestiona el formulario de eliminación de afectacion
   * @param afectacion Objeto de tipo CrrTipoAfectacionViaDTO para realizar la eliminacion
   */
  irEliminarAfectacion(afectacion: CrrTipoAfectacionViaDTO) {
    this.inicializarVariablesAfectacion();
    this.afectacionViaAfectada = afectacion;
    this.blockedDocument = true;
    this.dialogoEliminarAfectacion = true;
  }

  /**
   * Metodo que llama al servicio de consulta de afectacion por id y gestiona los datos del formulario de afectacion
   * @param idAfectacion Id de la afectacion a consulatr
   * @returns 
   */
  cargarAfectacionPorId(idAfectacion: number) {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarAfectacionPorId(idAfectacion).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            if (result.crrTipoAfectacionVia != null) {
              this.dialogoGuardarAfectacion = true;
              this.afectacionViaAfectada = result.crrTipoAfectacionVia;
              if (this.afectacionViaAfectada.tipo != null) {
                this.afectacionViaAfectada.tipoAfectacionDTO = this.tiposAfectacion.find(item => item.nombre == this.afectacionViaAfectada.tipo);
                this.formAfectacion.get('tipo').setValue(this.afectacionViaAfectada.tipoAfectacionDTO);
              }
              if (this.afectacionViaAfectada.idPeaje != null) {
                this.cargarPeajesProyectoAfectacion(null);
              }
              if (this.afectacionViaAfectada.tipoUbicacion != null) {
                this.afectacionViaAfectada.tipoUbicacionDTO = this.prkList.find(item => item.nombre == this.afectacionViaAfectada.tipoUbicacion);
                this.formAfectacion.get('tipoUbicacion').setValue(this.afectacionViaAfectada.tipoUbicacionDTO);
              }
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que controla el evento de seleccion del Tipo de afectacion, inicializa el DTO y gestiona el formulario de afectacion
   * @param tipoAfectacion Objeto de tipo CrrMaTipoAfectacionCierreViaDTO  
   */
  onSelectTipoAfectacion(tipoAfectacion: CrrMaTipoAfectacionCierreViaDTO) {
    this.iniciarlizarDTOAfectacion();

    if (tipoAfectacion != null) {
      this.afectacionViaAfectada.idTipo = tipoAfectacion.id;
      if (this.afectacionViaAfectada.idTipo === EnumCrrMaTipoAfectacion.PJE_TIPO_AFECTACION_PEAJE) {
        this.formAfectacion.get('peaje').setValidators(Validators.required);
        this.formAfectacion.controls['tipoUbicacion'].disable();
        this.formAfectacion.controls['latitud'].disable();
        this.formAfectacion.controls['longitud'].disable();
        this.formAfectacion.controls['prInicial'].disable();
        this.formAfectacion.controls['prFinal'].disable();
        this.formAfectacion.controls['distanciaAlPuntoInicial'].disable();
        this.formAfectacion.controls['distanciaAlPuntoFinal'].disable();
        this.cargarPeajesProyectoAfectacion(null);
      }
    }
  }

  /**
   * Metodo que controla el evento de autocompletado de peajes y llama el servicio de peajes por proyecto y actualiza la variable de peajes en el formulario de afectacion
   * @param event 
   * @returns 
   */
  cargarPeajesProyectoAfectacion(event) {
    const filtroLista = [];
    return this.traficoPeajesCargaService.getPeajes(this.idProyecto)
      .subscribe((result) => {
        this.peajes = result.peajes;
        if (this.peajes === null) {
          this.peajes = [];
        }

        if (this.afectacionViaAfectada.idPeaje != null) {
          this.afectacionViaAfectada.peajeDTO = this.peajes.find(item => item.idPeaje == this.afectacionViaAfectada.idPeaje);
          this.formAfectacion.get('peaje').setValue(this.afectacionViaAfectada.peajeDTO);
        }

        if (event != null) {
          let existeFiltro: boolean;
          for (let i = 0; i < this.peajes.length; i++) {
            const filtro = this.peajes[i];
            if (String(filtro.peaje).toLowerCase().includes(event.query.toLowerCase())) {
              filtroLista.push(filtro);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.peajes = filtroLista;
          }
        }
      });
  }

  /**
   * Metodo que controla el evento de seleccion de peaje y llama el servicio de consulta de peajes por id para traer la información de la ubicacion y actualiza el formulario de afectacion
   * @param peaje Objeto de tipo PjePeajePorProyecto seleccionado
   * @returns 
   */
  onSelectPeajeAfectacion(peaje: PjePeajePorProyecto) {
    if (peaje != null) {
      this.afectacionViaAfectada.idPeaje = peaje.idPeaje;
      return this.informacionGeneralPeajesService.ConsultarPjePeajePorId(this.afectacionViaAfectada.idPeaje).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              if (result.ubicacionPeaje != null) {
                this.cargarDTOAfectacionPeaje(result.ubicacionPeaje);
              }
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        });
    }
  }

  /**
   * Metodo que carga el objeto de tipo CrrTipoAfectacionViaDTO con la información de ubicacion del peaje, y deshabilita para edicion el formulario de afectacion
   * @param peajeUbicacion Objeto de tipo PjeUbicacionPeajeDTO con la informacion de ubicacion del peaje
   */
  cargarDTOAfectacionPeaje(peajeUbicacion: PjeUbicacionPeajeDTO) {
    if (this.afectacionViaAfectada != null && this.afectacionViaAfectada.idTipo === EnumCrrMaTipoAfectacion.PJE_TIPO_AFECTACION_PEAJE
      && peajeUbicacion != null && peajeUbicacion.idPeaje != 0) {
      this.afectacionViaAfectada.idPeaje = this.afectacionViaAfectada.peajeDTO.idPeaje;
      this.afectacionViaAfectada.tipoUbicacion = peajeUbicacion.tipoUbicacion;
      this.afectacionViaAfectada.tipoUbicacionDTO = this.prkList.find(item => item.nombre == peajeUbicacion.tipoUbicacion);
      this.formAfectacion.get('tipoUbicacion').setValue(this.afectacionViaAfectada.tipoUbicacionDTO);
      this.afectacionViaAfectada.longitud = peajeUbicacion.posicionX;
      this.afectacionViaAfectada.latitud = peajeUbicacion.posicionY;
      this.afectacionViaAfectada.puntoInicial = peajeUbicacion.punto;
      this.afectacionViaAfectada.puntoFinal = peajeUbicacion.punto;
      this.afectacionViaAfectada.distanciaAlPuntoInicial = peajeUbicacion.distanciaAlPunto;
      this.afectacionViaAfectada.distanciaAlPuntoFinal = peajeUbicacion.distanciaAlPunto;
    }
  }

  /**
   * Metodo que controla el evento de seleccion de Tipo de ubicacion para el formulario de afectacion actualiza el formulario de afectacion
   * @param event 
   */
  onChangeTipoUbicacionAfectacion(event) {
    if (event.value != null) {
      this.afectacionViaAfectada.tipoUbicacion = event.value.nombre;
    }
  }

  /**
   * Metodo que controla el evento de cierre del dialogo e inicializa las variables del formulario de afectacion 
   */
  cancelarDialogoAfectacion() {
    this.inicializarVariablesAfectacion();
    this.blockedDocument = false;
    this.dialogoGuardarAfectacion = false;
  }

  /**
   * Metodo que valida que los campos del formulario de afectacion esten completos
   * @returns Verdadero sí la información esta correcta o Falso en caso contrario
   */
  validarFormAfectacion() {
    let formularioValido = true;
    if (!this.formAfectacion.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Por favor diligenciar los campos marcados con *");
    } else {
      if (this.afectacionViaAfectada.idTipo == null || this.afectacionViaAfectada.idTipo == 0) {
        formularioValido = false;
        this.lanzarMensajeError("Debe seleccionar el tipo de afectación");
      }
      if (this.afectacionViaAfectada.idTipo != null && this.afectacionViaAfectada.idTipo == EnumCrrMaTipoAfectacion.PJE_TIPO_AFECTACION_PEAJE) {
        if (this.afectacionViaAfectada.idPeaje == null || this.afectacionViaAfectada.idPeaje == 0) {
          formularioValido = false;
          this.lanzarMensajeError("Debe seleccionar el peaje");
        } else {
          if (this.afectacionViaAfectada.tipoUbicacion == null || this.afectacionViaAfectada.tipoUbicacion == undefined
            || this.afectacionViaAfectada.longitud == null || this.afectacionViaAfectada.longitud == undefined
            || this.afectacionViaAfectada.latitud == null || this.afectacionViaAfectada.latitud == undefined
            || this.afectacionViaAfectada.puntoInicial == null || this.afectacionViaAfectada.puntoInicial == undefined
            || this.afectacionViaAfectada.puntoFinal == null || this.afectacionViaAfectada.puntoFinal == undefined
            || this.afectacionViaAfectada.distanciaAlPuntoInicial == null || this.afectacionViaAfectada.distanciaAlPuntoInicial == undefined
            || this.afectacionViaAfectada.distanciaAlPuntoFinal == null || this.afectacionViaAfectada.distanciaAlPuntoFinal == undefined) {
            formularioValido = false;
            this.lanzarMensajeError("Por favor selecciona un peaje válido");
          }
        }
      }
    }
    return formularioValido;
  }

  /**
   * Metodo que llama al servicio de guardar cierre afectacion por ola invernal
   */
  guardarAfectacion() {
    this.dialogoGuardarAfectacion = false;

    if (this.validarFormAfectacion()) {
      this.afectacionViaAfectada.idViaAfectada = this.cierreViaAfectada.id;

      this.olaInvernalService.srvGuardarAfectacionViaAfectada(this.afectacionViaAfectada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarAfectacion();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  /**
   * Metodo que llamar el servicio de eliminación de una afectacion seleccionada por su id
   */
  eliminarAfectacion() {
    this.dialogoEliminarAfectacion = false;
    if (this.afectacionViaAfectada != null && this.afectacionViaAfectada.id != 0) {
      this.olaInvernalService.srvEliminarAfectacionViaAfectadaPorId(this.afectacionViaAfectada.id).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarAfectacion();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  //#endregion "Afectación"

  //#region "Registro Fotografico"

  /**
   * Metodo que llama al servicio de consulta de imagenes por ola invernal
   * @returns Listado de registro fotograficos
   */
  cargarRegistroFotografico() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarImagenesPorIdViaAfectada(this.idOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.listaCrrImagenViaAfectada = result.listaCrrImagenViaAfectada;
            if (this.listaCrrImagenViaAfectada != null && this.listaCrrImagenViaAfectada.length > 0) {
              this.cantidadActualRegistrosFotograficos = this.listaCrrImagenViaAfectada.length;
            } else {
              this.cantidadActualRegistrosFotograficos = 0;
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
    * Metodo que inicializa las variable de fotografia para ola invernal
    */
  inicializarVariablesFotografia() {
    this.imagenViaAfectada = {};
    this.imagenViaAfectada.idViaAfectada = this.cierreViaAfectada.id;
    this.archivos = [];
    this.formFotografia.reset();
    this.formFotografia.enable();

  }

  /**
   * Metodo que inicializa las variable de fotografia para ola invernal y abre el dialog del formulario de fotografia
   */
  irSubirFotografia() {
    if (this.cantidadActualRegistrosFotograficos != this.cantidadMaximaRegistrosFotograficos) {
      this.inicializarVariablesFotografia();
      this.blockedDocument = true;
      this.dialogoGuardarFotografia = true;
    } else {
      this.lanzarMensajeError("La cantidad máxima de registros fotograficos es " + this.cantidadMaximaRegistrosFotograficos);
    }
  }

  /**
   * Metodo que controla el evento de cierre del dialogo e inicializa las variables del formulario de fotografia
   */
  cancelarDialogoSubirFotografia() {
    this.inicializarVariablesFotografia();
    this.blockedDocument = false;
    this.dialogoGuardarFotografia = false;
  }

  /**
   * Metodo que gestiona el formulario de fotografia para consulta y consulta de la fotografia por id 
   * @param foto Fotografia seleccionada para consultar
   */
  irConsultarFotografia(foto: CrrImagenViaAfectadaDTO) {
    this.inicializarVariablesFotografia();
    this.blockedDocument = true;
    this.formFotografia.disable();
    this.cargarRegistroFotograficoPorId(foto.id);
  }

  /**
   * Metodo que gestiona el formulario de fotografia para edicion y consulta de la fotografia por id 
   * @param foto Fotografia seleccionada para consultar
   */
  irEditarFotografia(foto: CrrImagenViaAfectadaDTO) {
    this.inicializarVariablesFotografia();
    this.blockedDocument = true;
    this.formFotografia.enable();
    this.cargarRegistroFotograficoPorId(foto.id);
  }

  /**
   * Metodo que gestiona el formulario de eliminación de fotografia
   * @param foto 
   */
  irEliminarFotografia(foto: CrrImagenViaAfectadaDTO) {
    this.inicializarVariablesFotografia();
    this.imagenViaAfectada = foto;
    this.blockedDocument = true;
    this.dialogoEliminarFotografia = true;
  }

  /**
   * Metodo que valida que los campos del formulario de fotografia esten completos
   * @returns Verdadero sí la información esta correcta o Falso en caso contrario
   */
  validarFormFotografia() {
    let formularioValido = true;
    if (!this.formFotografia.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Por favor diligencia los campos marcados con *");
    } else {
      if (this.cierreViaAfectada == null || this.cierreViaAfectada.id == null || this.cierreViaAfectada.id == 0) {
        formularioValido = false;
        this.lanzarMensajeError("El cierre seleccionado es null");
      }
      if (this.archivos == null || this.archivos[0] == null || this.archivos[0].nombreCompleto == null || this.archivos[0].nombreCompleto == '') {
        formularioValido = false;
        this.lanzarMensajeError("Debe adjuntar la fotografía");
      }
    }
    return formularioValido;
  }

  /**
    * Metodo que llama al servicio de guardar fotografia de la vía afectada por id ola invernal
    */
  guardarFotografia() {
    this.dialogoGuardarFotografia = false;

    if (this.validarFormFotografia()) {
      this.imagenViaAfectada.idViaAfectada = this.cierreViaAfectada.id;
      this.imagenViaAfectada.ruta = this.archivos[0].nombreCompleto;

      this.olaInvernalService.srvGuardarImagenViaAfectada(this.imagenViaAfectada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarRegistroFotografico();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  /**
   * Metodo que llama al servicio de consulta de fotografia por id y gestiona los datos del formulario de fotografia
   * @param idImagenOlaInvernal Id de la fotografia a consultar
   */
  cargarRegistroFotograficoPorId(idImagenOlaInvernal: number) {
    this.olaInvernalService.srvConsultarImagenOlaInvernalPorId(idImagenOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.imagenViaAfectada = result.crrImagenViaAfectada;
            if (this.imagenViaAfectada != null) {
              this.dialogoGuardarFotografia = true;
              if (this.imagenViaAfectada.fechaFotografia != null) {
                this.imagenViaAfectada.fechaFotografia = new Date(this.imagenViaAfectada.fechaFotografia);
              }
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que llamar el servicio de eliminación de una fotografia seleccionada por su id
   */
  eliminarFotografia() {
    this.dialogoEliminarFotografia = false;
    if (this.imagenViaAfectada != null && this.imagenViaAfectada.id != 0) {
      this.olaInvernalService.srvEliminarImagenViaAfectadaPorId(this.imagenViaAfectada.id).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarRegistroFotografico();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }
  //#endregion "Registro Fotografico"

  //#region "Navegación"
  bntVolver() {
    const ruta = "/carreteros/infGeneral/proyectoHome/" + this.idProyecto + "/ola-invernal-registro/" + this.idProyecto;
    this.router.navigate([ruta]);
  }
  //#endregion "Navegación"

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCrrMaTipoAfectacion() {
    return EnumCrrMaTipoAfectacion;
  }



}
