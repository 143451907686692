import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { IngBienReservaDTO, IngIngresoReservaDTO, IngReservaPuestoTrabajoDTO, IngBienPersonalDTO } from '../puestos-trabajo.dto';
import { PuestosTrabajoService } from '../puestos-trabajo.service';
import { forkJoin } from 'rxjs';
import { InvBienAniPersonalDTO, InvMaTipoBien } from 'src/app/inventario-equipos/inventario-equipos.dto';
import { InventarioEquiposService } from 'src/app/inventario-equipos/inventario-equipos.service';
import { EnumRespuestaServicio } from '../../comun/constantes/constantes-comunes';
import { EnumInvInventario } from 'src/app/inventario-equipos/inventario-constantes';

@Component({
  selector: 'app-registrar-reserva',
  templateUrl: './registrar-reserva.component.html',
  styleUrls: ['./registrar-reserva.component.sass']
})
export class RegistrarReservaComponent implements OnInit {

  blockedDocument: boolean;
  idReservaPuestoTrabajo: number;
  reservaPuestoTrabajo: IngReservaPuestoTrabajoDTO;
  equiposReserva: InvBienAniPersonalDTO[];
  registroPuestoTrabajo: IngIngresoReservaDTO;
  habilitaBuscarPorNumeroDocumento: boolean;
  numeroDocumento: string;
  formBuscar;
  bienListSelected: InvBienAniPersonalDTO[];
  visibleConsultaPorIdReserva: boolean;
  visibleConsultaPorNumeroDocumento: boolean;
  fechaActual: Date;
  formatoFechaActual: string;
  reservaPuestoTrabajoList: IngReservaPuestoTrabajoDTO[];
  dialogoEquipos: boolean;
  dialogoAdvertenciaIngreso: boolean;
  mensajeAdvertenciaIngreso: string;
  dialogoRegistrarEquipoPersonal: boolean;
  formRegistrarEquipoPersonal;
  bienPersonal: IngBienPersonalDTO = {};
  tipoBienList: InvMaTipoBien[];
  bienList: InvBienAniPersonalDTO[];
  esPuestoFijo: boolean;
  dialogoNoTieneReserva: boolean;
  mensajeNoTieneReserva: string;
  mensajeIngresoSalida: string;
  dialogoExitoIngresoSalida: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private puestosTrabajoService: PuestosTrabajoService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private inventarioEquiposService: InventarioEquiposService
  ) {

    // Crear formulario Predio
    this.formBuscar = formBuilder.group({
      numeroDocumento: [null, [Validators.required, Validators.maxLength(100)]]
    });
    // Crear formulario Registrar Equipo Personal
    this.formRegistrarEquipoPersonal = formBuilder.group({
      idTipoBien: [null, [Validators.required]],
      marca: [null, [Validators.required, Validators.pattern("^([A-Za-z0-9ñÑáéíóúÁÉÍÓÚ])+[-_.,A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]*")]],
      serial: [null, [Validators.required, Validators.pattern("^([A-Za-z0-9ñÑáéíóúÁÉÍÓÚ])+[-_.,A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]*")]],
    });

  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {

    this.fechaActual = new Date();
    this.formatoFechaActual = this.fechaActual.getFullYear() + "-" + (this.fechaActual.getMonth() + 1) + "-" + this.fechaActual.getDate();
    this.mensajeIngresoSalida = '';

    this.reservaPuestoTrabajo = {};
    this.registroPuestoTrabajo = {};
    // Obtener la reserva seleccionada
    /* const idReservaPuestoTrabajo = "idReserva";
    this.ruta.params.subscribe((params) => {
      this.idReservaPuestoTrabajo = params[idReservaPuestoTrabajo];
    }); */

    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.inventarioEquiposService.srvListarTipoBien()

    ).subscribe(
      ([
        tipoBienListData
      ]) => {

        this.tipoBienList = tipoBienListData.tiposBien;

        this.consultarPersonaTienePuestoPermanente();
        this.listarMisAsignaciones();
        this.cargarDatosReserva();
        this.bienListSelected = [];

        this.blockedDocument = false;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );



  }

  consultarPersonaTienePuestoPermanente() {


    this.blockedDocument = true;
    // Valida si la persona cuenta con puestos de trabajo fijos
    forkJoin(
      this.puestosTrabajoService.srvConsultarPersonaTienePuestoPermanente(),
    ).subscribe(
      ([
        tienePuestoTrabajoData
      ]) => {

        // console.log("reservaData", tienePuestoTrabajoData);

        if (tienePuestoTrabajoData.personaPuestoTrabajo != null) {
          this.esPuestoFijo = true;
        } else {
          this.esPuestoFijo = false;
        }

        this.blockedDocument = false;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );

  }

  cargarBienes() {
    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.puestosTrabajoService.srvConsultarBienesReserva(
        this.idReservaPuestoTrabajo
      )
    ).subscribe(
      ([
        bienesReservaData
      ]) => {
        //Obtener Reserva (usando obtenerReservaPuestoTrabajoPorId )       
        if (!this.esPuestoFijo) {
          this.equiposReserva = bienesReservaData.bienesReserva;
          this.bienListSelected = bienesReservaData.bienesReserva;
          // console.log(JSON.stringify(this.equiposReserva));
        } else {
          this.listarMisAsignaciones();        
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarDatosReserva() {
    this.blockedDocument = true;

    /* if (this.idReservaPuestoTrabajo == 0) {

      this.habilitaBuscarPorNumeroDocumento = true;

      this.blockedDocument = false;

    } else { */

    this.visibleConsultaPorIdReserva = true;

    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.puestosTrabajoService.srvConsultarReservaPuestoParaIngresoSalida()
    ).subscribe(
      ([reservaData,]) => {
        //Obtener Reserva (usando obtenerReservaPuestoTrabajoPorId )
        // console.log("reservaData", reservaData);

        if (reservaData.respuestaServicio.codigoSalida === 0) {
          this.visibleConsultaPorIdReserva = false;
          this.dialogoNoTieneReserva = true;
          this.mensajeNoTieneReserva = reservaData.respuestaServicio.mensajeSalida;
          // console.log('No tiene reservas');

        } else {
          this.visibleConsultaPorIdReserva = true;
          this.reservaPuestoTrabajo = reservaData.reserva;

          //Si se tiene una reserva a editar...
          if (this.reservaPuestoTrabajo != null) {
            //Cargar fecha
            if (this.reservaPuestoTrabajo.fecha != null) {
              this.reservaPuestoTrabajo.fecha = new Date(
                this.reservaPuestoTrabajo.fecha
              );
            }
            this.idReservaPuestoTrabajo = this.reservaPuestoTrabajo.id;
            //this.cargarBienes();

            //  Cargar el objeto del dropdown Llevara Equipo
          } else {
            this.reservaPuestoTrabajo = {};
          }
        }

      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
    //}

  }

  btnBuscarReservaPorNumeroDocumento() {

    if (this.formBuscar.valid) {
      this.blockedDocument = true;
      // Inicializar listas parametricas y DTOs
      forkJoin(
        this.puestosTrabajoService.srvConsultarReservasPuestoTrabajoPorNumeroYFecha(
          this.numeroDocumento, this.formatoFechaActual
        ),
      ).subscribe(
        ([
          reservaData
        ]) => {

          //Obtener Reserva (usando srvConsultarReservaPuestoTrabajoPorNumeroDocumento )
          // console.log("reservaData", reservaData);
          this.reservaPuestoTrabajoList = reservaData.reservas;

          //Si se tiene una reserva a editar...
          if (this.reservaPuestoTrabajo != null) {
            //Cargar fecha
            if (this.reservaPuestoTrabajo.fecha != null) {
              this.reservaPuestoTrabajo.fecha = new Date(
                this.reservaPuestoTrabajo.fecha
              );
            }



          } else {
            this.reservaPuestoTrabajo = {};
          }

          this.blockedDocument = false;
        },
        (error) => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
        }
      );



    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos marcados con asterisco (*)');
    }
  }

  btnEquipos(reservaPuestoTrabajoSelected: IngReservaPuestoTrabajoDTO) {
    this.blockedDocument = true;
    forkJoin(
      this.puestosTrabajoService.srvConsultarBienesReserva(reservaPuestoTrabajoSelected.id)
    ).subscribe(
      ([equiposReservaData]) => {
        // console.log(equiposReservaData);
        this.equiposReserva = equiposReservaData.bienesReserva;
        this.dialogoEquipos = true;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  btnRegistrarIngreso(idReserva, forzarSalida) {
    this.blockedDocument = true;
    this.mensajeIngresoSalida = '';
    this.registroPuestoTrabajo.idReservaPuestoTrabajo = this.reservaPuestoTrabajo.id;
    if (idReserva != null) {
      this.registroPuestoTrabajo.id = idReserva;

    } else {
      this.registroPuestoTrabajo.id = 0;
    }
    this.registroPuestoTrabajo.registrarIngresoSalidaBienForzosamente = forzarSalida;
    let bienesSeleccionados: IngBienReservaDTO[] = [];
    this.bienListSelected.forEach(element => {
      if (element.tipoPertenencia == 'ANI') {
        let bieneSeleccionado: IngBienReservaDTO = {};
        bieneSeleccionado.idBienAni = element.idBien;
        bienesSeleccionados.push(bieneSeleccionado);
      } else {
        let bieneSeleccionado: IngBienReservaDTO = {};
        bieneSeleccionado.idBienPersonal = element.idBien;
        bienesSeleccionados.push(bieneSeleccionado);
      }
    });
    this.registroPuestoTrabajo.bienesIngresoSalida = bienesSeleccionados;
    // console.log(this.registroPuestoTrabajo);

    forkJoin(
      this.puestosTrabajoService.srvGuardarIngreso(this.registroPuestoTrabajo)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.mensajeIngresoSalida = result.respuestaServicio.mensajeSalida;
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            this.listarMisAsignaciones();
            this.dialogoExitoIngresoSalida = true;
          } else {
            // console.log('salida');
            if (result.respuestaServicio.codigoSalida === 999) {
              this.dialogoAdvertenciaIngreso = true;
              this.mensajeAdvertenciaIngreso = result.respuestaServicio.mensajeSalida;
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO + 'srvGuardarIngreso');
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.cargarDatosReserva();
        this.blockedDocument = false;
      }
    );
  }

  btnForzarIngreso() {
    this.btnRegistrarIngreso(null, true);
    this.dialogoAdvertenciaIngreso = false;
  }

  btnRegistrarSalida() {
    this.blockedDocument = true;
    this.mensajeIngresoSalida = '';
    this.registroPuestoTrabajo.idReservaPuestoTrabajo = this.idReservaPuestoTrabajo;
    this.registroPuestoTrabajo.id = this.reservaPuestoTrabajo.idIngresoSalida;
    this.registroPuestoTrabajo.registrarIngresoSalidaBienForzosamente = false;
    let bienesSeleccionados: IngBienReservaDTO[] = [];
    this.bienListSelected.forEach(element => {
      if (element.tipoPertenencia == 'ANI') {
        let bieneSeleccionado: IngBienReservaDTO = {};
        bieneSeleccionado.idBienAni = element.idBien;
        bienesSeleccionados.push(bieneSeleccionado);
      } else {
        let bieneSeleccionado: IngBienReservaDTO = {};
        bieneSeleccionado.idBienPersonal = element.idBien;
        bienesSeleccionados.push(bieneSeleccionado);
      }
    });
    this.registroPuestoTrabajo.bienesIngresoSalida = bienesSeleccionados;
    // console.log(this.registroPuestoTrabajo);
    forkJoin(
      this.puestosTrabajoService.srvGuardarSalida(this.registroPuestoTrabajo)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.mensajeIngresoSalida = result.respuestaServicio.mensajeSalida;
            this.dialogoExitoIngresoSalida = true;
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            this.listarMisAsignaciones();
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.cargarDatosReserva();
        this.dialogoAdvertenciaIngreso = false;
        this.blockedDocument = false;
      }
    );
  }

  btnAceptarExitoIngresoSalida() {
    this.mensajeIngresoSalida = '';
    this.dialogoExitoIngresoSalida = false;
    this.bntVolver();
  }

  /**
   * Guarda los cambios al crear o editar un equipo personal
   */
  submitRegistrarEquipoPersonal() {
    if (this.formRegistrarEquipoPersonal.valid) {
      this.blockedDocument = true;
      this.bienPersonal.idTipoBien = this.bienPersonal.idTipoBienDTO.id;
      // Guardar el DTO
      forkJoin(
        this.puestosTrabajoService.srvGuardarBienPersonal(this.bienPersonal)
      ).subscribe(
        ([result]) => {
          // console.log(result);
          if (result != null && result.respuestaServicio != null) {
            // Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              //Asignar el id del predio recien guardado
              //this.predio.idPredioHistorico = result.predio.idPredioHistorico;
              this.lanzarMensajeInfo("Los cambios se guardaron correctamente");
              this.listarMisAsignaciones();
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            // console.log('Ocurrio un error al consumir el servicio srvGuardarPredio');
          }
        },
        (error) => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
          this.dialogoRegistrarEquipoPersonal = false;
        }
      );
    } else {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    }
  }

  /**
   * Lista las asignaciones asociadas al usuario logueado
   */
  listarMisAsignaciones() {
    this.blockedDocument = true;
    forkJoin(
      this.inventarioEquiposService.srvConsultarBienesAniYPersonal(null)
    ).subscribe(
      ([asignacionesListData]) => {
        // console.log(asignacionesListData);
        this.equiposReserva = asignacionesListData.bienesAniPersonal;
        // console.log("Bienes.....");
        // console.log(JSON.stringify(this.equiposReserva));
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  bntVolver() {
    const ruta = "home-puestos-trabajo";
    this.router.navigate([ruta]);
  }

  btnRegistrarEquipoPersonal() {
    this.dialogoRegistrarEquipoPersonal = true;
    this.bienPersonal = {};
  }

  // // // // /Mensajes// // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  get EnumInvInventario(){
    return EnumInvInventario;
  }

}
