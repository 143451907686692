import {  Component, OnInit,Inject, Renderer2 } from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";



@Component({
  selector: 'app-rpt-crr-proyecto-videos',
  templateUrl: './rpt-crr-proyecto-videos.component.html',
  styleUrls: ['./rpt-crr-proyecto-videos.component.css'],
})
export class RptCrrProyectoVideosComponent implements OnInit {

  
  proyectos: any = [];


  constructor(@Inject(DOCUMENT) private document: any,
  private _renderer2: Renderer2,
  private messageService: MessageService,
  private principalComponent: PrincipalComponent,
  public location: Location, private reportesCarreteroService: ReportesCarreteroService) { }

  ngOnInit() {
    let script = this._renderer2.createElement('script');
        script.id = `deCarusel_menu`;
        script.text = `deCarusel_menu();`;

    this._renderer2.appendChild(this.document.body, script);



    this.reportesCarreteroService.getProyectosYoutube(6).subscribe((data: any = []) => {
      this.proyectos = data;
   
      
    }, error => {
    
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });

  }
 


    

}
