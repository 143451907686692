import { Component, OnInit } from '@angular/core';
import { InformeContratistaBaseComponent } from '../informe-contratista-base/informe-contratista-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { InformeContratistaService } from '../informe-contratista.service';
import { MessageService } from 'primeng/api';
import { CtoAnexoInformeContratistaDTO } from '../../../../contratacion-dto/anexo-informe-contratista';
import { forkJoin } from 'rxjs';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { EnumRespuestaServicio } from '../../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumCtoTipoAnexoInforme } from 'src/app/contratacion/constantes/contratacion-constantes';
import { CtoMaAnexoInformeDTO } from '../../../../contratacion-dto/cto-ma-tipo-anexo-informe';

@Component({
  selector: 'app-informe-contratista-anexos',
  templateUrl: './informe-contratista-anexos.component.html',
  styles: []
})
export class InformeContratistaAnexosComponent implements OnInit {

  facturaCargada: boolean;
  visibleDialogoSubirFactura: boolean;
  rutaAnexosAdicionales: string;
  rutaFactura: string;
  archivos: any = [];
  anexosAdicionalesList: any[] = [];
  idAnexoEliminar: number;
  visibleDialogoEliminarAnexo: boolean;
  visibleDialogoSubirAdjuntos: boolean;
  visibleDialogoCrearAnexo: boolean;
  tiposAnexosList: any[] = [];
  tipoAnexoSeleccionado: CtoMaAnexoInformeDTO;
  idAnexo: number;
  nombreTipoAnexo: string;
  visibleDialogoVerAdjuntosAnexos: boolean;
  adjuntosAnexosList: any[] = [];
  idAdjuntoEliminarAnexo: number;
  visibleDialogoEliminarAdjunto: boolean;


  constructor(
    public lineaBase: InformeContratistaBaseComponent,
    private route: Router,
    private ruta: ActivatedRoute,
    private informeContratistaService: InformeContratistaService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarMaTiposDeAnexos();
    this.cargarInformeAnexos(this.lineaBase.idInforme);
    if (!this.lineaBase.habilitaEdicion) {
      this.guardarInformeContratistaActualizaInfoTributaria();
    }
  }

  cargarMaTiposDeAnexos() {
    forkJoin(
      this.informeContratistaService.srvGetMaTiposAnexosPersonaNatural()
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.tiposAnexosList = result.tiposAnexos;
        } else {
          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
        }
      } else {
        console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }


  /**
   * Metodo que llama al servicio de guardar factura, cuando se carga el componente para actualizar las variables de información tributaria del pago, EsResponsableIva y EstaObligadoFacturar
   */
  guardarInformeContratistaActualizaInfoTributaria() {
    //Se validan los campos de Factura en relación a la información tributaria
    if (this.lineaBase.validarCargarFacturaObligatoriedad()) {
      if (this.lineaBase.informeContratista.rutaSoporteFactura != null && this.lineaBase.informeContratista.rutaSoporteFactura != '') {
        this.facturaCargada = true;
      }
    } else {
      this.lineaBase.informeContratista.numeroFactura = null;
      this.lineaBase.informeContratista.rutaSoporteFactura = null;
    }

    //Se actualiza la información tributaria llamando al servicio de factura
    if (this.lineaBase.esBorrador) {
      this.informeContratistaService.srvGuardarFacturaBorrador(this.lineaBase.informeContratista).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo("La información tributaria del pago se actualizó correctamente");
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    } else {
      this.informeContratistaService.srvGuardarFactura(this.lineaBase.informeContratista).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo("La información tributaria del pago se actualizó correctamente");
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }

  //#region <Factura>  

  /**
   * Metodo que inicializa las variables del formulario de factura y abre el dialog para adjuntar el archivo
   */
  irVerDialogoSubirFactura() {
    this.visibleDialogoSubirFactura = true;
    this.rutaFactura = this.lineaBase.vigencia + '/' + this.lineaBase.idContrato + '_' + this.lineaBase.numeroContrato + '/' + this.lineaBase.numeroDocumento + '/' + this.lineaBase.numeroPago + '/anexos/';
  }

  /**
   * Metodo que controla el evento de cierre del dialogo que adjuntar el archivo de factura
   */
  cancelarDialogoSubirFactura() {
    this.visibleDialogoSubirFactura = false;
  }

  /**
   * Metodo que controla el evento del cierre del dialogo para adjuntar factura y la obtención de la información del archivo cargado para luego llamar el servicio de guardarFactura
   */
  aceptarAdjuntoFactura() {
    this.lineaBase.blockedDocument = true;
    // console.log(this.archivos[0].nombreCompleto);
    this.rutaFactura = this.archivos[0].nombreCompleto;//se obtiene la ruta completa del archivo (ruta+nombreArchivo)
    // console.log(this.rutaFactura);
    this.lineaBase.informeContratista.rutaSoporteFactura = this.rutaFactura;
    this.visibleDialogoSubirFactura = false;
    this.archivos = [];
    this.facturaCargada = true;

    //llamado al servicio de guardar factura
    this.guardarFactura();
  }

  /**
   * Metodo que valida la inforamción de la factura y llama al servicio de guardar factura cuando se de clic en el botón Guardar de esta sección o cuando se adjunte el archivo de factura
   * @returns Resultado de la operación
   */
  guardarFactura() {
    this.lineaBase.blockedDocument = true;
    if (this.lineaBase.informeContratista != null) {
      if (this.lineaBase.validarCargarFacturaObligatoriedad()) {
        if (this.lineaBase.informeContratista.numeroFactura == null) {
          this.lineaBase.lanzarMensajeError('El número de la factura es obligatorio');
          this.lineaBase.blockedDocument = false;
          return;
        } else {
          if (this.lineaBase.informeContratista.numeroFactura.length < 1 && this.lineaBase.informeContratista.numeroFactura.length > 20) {
            this.lineaBase.lanzarMensajeError('El número de la factura debe contener entre 1 y 20 dígitos');
            this.lineaBase.blockedDocument = false;
            return;
          }
        }

        if (this.lineaBase.esResponsableIva && !this.facturaCargada) {
          this.lineaBase.lanzarMensajeError('El contratista es responsable de IVA por lo tanto es obligatorio adjuntar la factura.');
          this.lineaBase.blockedDocument = false;
          return;
        }
        if (this.lineaBase.esResponsableIva && !this.facturaCargada) {
          this.lineaBase.lanzarMensajeError('El contratista es responsable de IVA por lo tanto es obligatorio adjuntar la factura.');
          this.lineaBase.blockedDocument = false;
          return;
        }
        if (this.lineaBase.estaObligadoFacturar && !this.facturaCargada) {
          this.lineaBase.lanzarMensajeError('El contratista esta obligado a facturar por lo tanto es obligatorio adjuntar la factura.');
          this.lineaBase.blockedDocument = false;
          return;
        }
      } else {
        //Se mapea el objeto informeContratista con la información de facura en null, para los que NO deben presentarla
        this.lineaBase.informeContratista.numeroFactura = null;
        this.lineaBase.informeContratista.rutaSoporteFactura = null;
      }

      if (this.lineaBase.esBorrador) {
        this.informeContratistaService.srvGuardarFacturaBorrador(this.lineaBase.informeContratista).subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.lineaBase.lanzarMensajeInfo("La información de Factura se guardó exitosamente.");
              } else {
                this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              }
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.lineaBase.blockedDocument = false;
          }
        );
      } else {
        this.informeContratistaService.srvGuardarFactura(this.lineaBase.informeContratista).subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.lineaBase.lanzarMensajeInfo("La información de Factura se guardó exitosamente.");
              } else {
                this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              }
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.lineaBase.blockedDocument = false;
          }
        );
      }
    }
  }


  //#endregion 

  //#region <Anexos Adicionales>  
  cargarInformeAnexos(idInforme) {
    forkJoin(
      this.informeContratistaService.GetAnexosPorSubTipoAnexoInforme(
        EnumCtoTipoAnexoInforme.SUBTIPO_ANEXOS_ADICIONALES + ',' + EnumCtoTipoAnexoInforme.SUBTIPO_CUENTAS_REGISTRO,
        idInforme, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.anexosAdicionalesList = result.anexos;
          if (!this.lineaBase.habilitaEdicion) {
            this.guardarInformeContratistaActualizaInfoTributaria();
          }
        } else {
          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
        }
      } else {
        console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }

  verDialogoEliminarAnexo(_anexo) {
    this.idAnexoEliminar = _anexo.id;
    this.visibleDialogoEliminarAnexo = true;
  }

  cancelarDialogoEliminarAnexo() {
    this.visibleDialogoEliminarAnexo = false;
  }

  aceptarEliminarAnexo() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoEliminarAnexo = false;
    if (this.lineaBase.esBorrador) {
      return this.informeContratistaService.srvEliminarAnexoInformeBorrador(this.idAnexoEliminar).subscribe(
        result => {
          this.cargarInformeAnexos(this.lineaBase.idInforme);
          this.visibleDialogoEliminarAnexo = false;
          this.lineaBase.lanzarMensajeInfo('El anexo se eliminó exitosamente.');
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {

          this.lineaBase.blockedDocument = false;
        }
      );
    } else {
      return this.informeContratistaService.srvEliminarAnexoInforme(this.idAnexoEliminar).subscribe(
        result => {
          this.cargarInformeAnexos(this.lineaBase.idInforme);
          this.visibleDialogoEliminarAnexo = false;
          this.lineaBase.lanzarMensajeInfo('El anexo se eliminó exitosamente.');
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
    }
  }

  irVerDialogoAgregarAnexo() {
    this.visibleDialogoCrearAnexo = true;
    this.tipoAnexoSeleccionado = null;
  }

  cancelarDialogoAgregarAnexo() {
    this.visibleDialogoCrearAnexo = false;
  }

  irVerDialogoSubirAdjuntos(_idAnexo, _nombreTipoAnexo) {
    this.visibleDialogoSubirAdjuntos = true;
    this.idAnexo = _idAnexo;
    this.nombreTipoAnexo = _nombreTipoAnexo;
    this.rutaAnexosAdicionales = this.lineaBase.vigencia + '/' + this.lineaBase.idContrato + '_' + this.lineaBase.numeroContrato + '/' + this.lineaBase.numeroDocumento + '/' + this.lineaBase.numeroPago + '/anexos/';
  }

  cancelarDialogoSubirAdjuntos() {
    this.visibleDialogoSubirAdjuntos = false;
  }

  crearAnexo() {
    this.lineaBase.blockedDocument = true;
    let anexo: CtoAnexoInformeContratistaDTO;
    anexo = {};
    anexo.idTipoAnexo = this.tipoAnexoSeleccionado.id;
    anexo.idInforme = this.lineaBase.idInforme;
    if (this.lineaBase.esBorrador) {
      this.informeContratistaService.srvGuardarAnexoInformeBorrador(anexo).subscribe(
        (data: {}) => {
          this.lineaBase.lanzarMensajeInfo('La información se guardó exitosamente.');
          // console.log(data);
          anexo = data;
          this.visibleDialogoCrearAnexo = false;
          this.cargarInformeAnexos(this.lineaBase.idInforme);
          this.lineaBase.blockedDocument = false;
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    } else {
      this.informeContratistaService.srvGuardarAnexoInforme(anexo).subscribe(
        (data: {}) => {
          this.lineaBase.lanzarMensajeInfo('La información se guardó exitosamente.');
          anexo = data;
          this.visibleDialogoCrearAnexo = false;
          this.cargarInformeAnexos(this.lineaBase.idInforme);
          this.lineaBase.blockedDocument = false;
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }

  aceptarAdjuntoAnexo() {
    this.lineaBase.blockedDocument = true;
    // console.log(this.archivos);
    let adjuntosBorradorList: CtoAdjuntoInformeBorradorDTO[] = [];
    this.archivos.forEach(element => {
      let adjuntoBorrador: CtoAdjuntoInformeBorradorDTO = {};
      adjuntoBorrador.idAnexo = this.idAnexo;
      adjuntoBorrador.nombreArchivoUsuario = element.nombre;
      adjuntoBorrador.rutaSoporte = element.nombreCompleto;
      adjuntosBorradorList.push(adjuntoBorrador);
    });
    this.visibleDialogoSubirAdjuntos = false;
    this.archivos = [];

    forkJoin(
      this.informeContratistaService.getAdjuntosPorAnexo(this.idAnexo, this.lineaBase.esBorrador)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.adjuntosAnexosList = result.adjuntos;
            let tamanioFinalAdjuntos: number = this.archivos.length + this.adjuntosAnexosList.length;
            if (tamanioFinalAdjuntos <= 3) {
              if (this.lineaBase.esBorrador) {
                let vara: any = this.informeContratistaService.srvGuardarAdjuntoInformeBorrador(adjuntosBorradorList).subscribe((data: {}) => {
                  this.lineaBase.lanzarMensajeInfo('Los adjuntos se guardaron exitosamente.');
                  this.lineaBase.blockedDocument = false;
                });
              } else {
                let vara: any = this.informeContratistaService.srvGuardarAdjuntoInforme(adjuntosBorradorList).subscribe((data: {}) => {
                  this.lineaBase.lanzarMensajeInfo('Los adjuntos se guardaron exitosamente.');
                  this.lineaBase.blockedDocument = false;
                });
              }
            } else {
              this.lineaBase.lanzarMensajeError('Solo se permiten 3 archivos máximo por anexo.');
            }
          } else {
            // console.log(result.respuestaServicio.mensajeSalida);
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }

  irVerDialogoVerAdjuntosAnexos(_idAnexo) {
    this.visibleDialogoVerAdjuntosAnexos = true;
    this.idAnexo = _idAnexo;
    this.cargarAdjuntosPorAnexo(this.idAnexo);
  }

  verDialogoEliminarAdjunto(_adjunto) {
    this.idAdjuntoEliminarAnexo = _adjunto.id;
    this.visibleDialogoEliminarAdjunto = true;
  }

  cancelarDialogoVerAdjunto() {
    this.visibleDialogoVerAdjuntosAnexos = false;
    this.adjuntosAnexosList = [];
  }

  cancelarEliminarAdjunto() {
    this.visibleDialogoEliminarAdjunto = false;
    this.lineaBase.blockedDocument = false;
  }

  aceptarEliminarAdjunto() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoEliminarAdjunto = false;
    if (this.lineaBase.esBorrador) {
      return this.informeContratistaService.srvEliminarAdjuntoInformeBorrador(this.idAdjuntoEliminarAnexo).subscribe(
        result => {
          this.cargarAdjuntosPorAnexo(this.idAnexo);
          this.visibleDialogoEliminarAdjunto = false;
          this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
    } else {
      return this.informeContratistaService.srvEliminarAdjuntoInforme(this.idAdjuntoEliminarAnexo).subscribe(
        result => {
          this.cargarAdjuntosPorAnexo(this.idAnexo);
          this.visibleDialogoEliminarAdjunto = false;
          this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
    }
  }

  cargarAdjuntosPorAnexo(id) {
    this.adjuntosAnexosList = [];
    forkJoin(
      this.informeContratistaService.getAdjuntosPorAnexo(id, this.lineaBase.esBorrador)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.adjuntosAnexosList = result.adjuntos;
          } else {
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }
  //#endregion 
}
