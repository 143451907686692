import { Component, OnInit, ViewChild } from '@angular/core';
import { RetribucionFinancieraIndexada } from './retribucion-financiera-indexada';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { InformacionGeneralService } from 'src/app/informacion-general/informacion-general.service';
import { RetribucionFinancieraService } from './retribucion-financiera.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { NgForm } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-retribucion-financiera',
  templateUrl: './retribucion-financiera.component.html',
  styleUrls: ['./retribucion-financiera.component.sass']
})
export class RetribucionFinancieraComponent implements OnInit {

  retribucionesList: any = [];

  displayedColumns: any[];

  dataSource: MatTableDataSource<RetribucionFinancieraIndexada>;

  retribucionFinancieraIndexadaSeleccionada: RetribucionFinancieraIndexada;

  idProyecto: number;
  proyecto: any;

  idRetribucion: any;

  visibleAgregarRetribucionIndexado: boolean = false;
  visibleDialogoAdvertenciaFecha: boolean = false;
  visibleDialogoExito: boolean = false;
  visibleConfirmarEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRetribucion: boolean = false;
  visibleValoresDolares: boolean= false;

  datosRetribucionIndexado: RetribucionFinancieraIndexada = {};

  origenSaldos: any = [];
  filtroOrigenSaldos: any[];
  origenSaldoSeleccionado: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private infoService: InformacionGeneralService, private retribucionFinancieraService: RetribucionFinancieraService, private route: Router, private ruta: ActivatedRoute, private parametricasService: ParametricasService,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
      this.cargarRetribucionFinanciera(this.idProyecto);
    });
    this.displayedColumns = [
      { field: 'nombreUnidadFuncional', header: 'Unidad funcional' },
      { field: 'nombreOrigen', header: 'Fuente retribución' },
      { field: 'formatoFecha', header: 'Fecha de actualización' },
      { field: 'formatoPorcentaje', header: '% de la unidad funcional' },
      { field: 'formatoValorPesos', header: 'Valor en pesos' },
      { field: 'formatoValorDolares', header: 'Valor en dolares' }
    ];

    /* var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto); */
  }

  cargarRetribucionFinanciera(id: number) {
    return this.retribucionFinancieraService.getRetribucionFinanceraIndexada(id).subscribe((data: {}) => {
      this.retribucionesList = data;
      this.dataSource = new MatTableDataSource(this.retribucionesList);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAgregarRetribucionFinancieraIdexada() {
    this.visibleAgregarRetribucionIndexado = true;
    this.idRetribucion = "new";
    this.datosRetribucionIndexado = {};
  }

  cancelarAgregarRetribucionFinancieraIdexada() {
    this.visibleAgregarRetribucionIndexado = false;
  }

  guardarRetribucionFinancieraIdexada(forma: NgForm) {
    if (this.idRetribucion === 0) {
     let vara: any = this.retribucionFinancieraService.guardarRetribucionFinanceraIndexada(this.datosRetribucionIndexado).subscribe((data: {}) => {
        let respuesta: RetribucionFinancieraIndexada = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
        this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.retribucionFinancieraService.editarRetribucionFinanceraIndexada(this.datosRetribucionIndexado, this.idRetribucion).subscribe((data: {}) => {
        let respuesta: RetribucionFinancieraIndexada = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
        this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  aceptarDialogoAdvertenciaFecha() {
    this.visibleDialogoAdvertenciaFecha = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.cargarRetribucionFinanciera(this.idProyecto);
    this.visibleAgregarRetribucionIndexado = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editarRetribucionFinancieraIdexada(retribucionFinancieraIndexada: RetribucionFinancieraIndexada) {
    this.retribucionFinancieraIndexadaSeleccionada = retribucionFinancieraIndexada;
    this.idRetribucion = this.retribucionFinancieraIndexadaSeleccionada.id;
    this.visibleAgregarRetribucionIndexado = true;
    this.visibleValoresDolares = this.retribucionFinancieraIndexadaSeleccionada.idOrigen ==3? true:false;
    if (this.retribucionFinancieraIndexadaSeleccionada.id == 0) {
      this.datosRetribucionIndexado = {};
      this.datosRetribucionIndexado.id = 0;
      this.datosRetribucionIndexado.idUnidadFuncional = this.retribucionFinancieraIndexadaSeleccionada.idUnidadFuncional;
      this.datosRetribucionIndexado.idOrigen = this.retribucionFinancieraIndexadaSeleccionada.idOrigen;
    } else {
      return this.retribucionFinancieraService.getRetribucionFinanceraIndexadaPorId(this.retribucionFinancieraIndexadaSeleccionada.id).subscribe((data: {}) => {
        this.datosRetribucionIndexado = data;
        this.datosRetribucionIndexado.porcentaje = this.datosRetribucionIndexado.porcentaje * 100;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  irEliminarRetribucionFinancieraIdexada(retribucionFinancieraIndexada: RetribucionFinancieraIndexada) {
    this.retribucionFinancieraIndexadaSeleccionada = retribucionFinancieraIndexada;
    this.visibleConfirmarEliminarRegistro = true;
    this.idRetribucion = this.retribucionFinancieraIndexadaSeleccionada.id;
 }

  cancelarEliminarRetribucionFinancieraIdexada() {
    this.visibleConfirmarEliminarRegistro = false;
  }

  aceptarEliminarRetribucionFinancieraIdexada() {
    return this.retribucionFinancieraService.eliminarRetribucionFinanceraIndexada(this.idRetribucion).subscribe((data: {}) => {
      this.cargarRetribucionFinanciera(this.idProyecto);
      this.visibleDialogoExitoEliminarRegistro = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarRetribucionFinancieraIdexada() {
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleConfirmarEliminarRegistro = false;
  }

}
