import { Component, Inject } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AppInsightsService } from './app-insights.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  constructor(private adalSvc: MsAdalAngular6Service, appInsightsService: AppInsightsService) {
    appInsightsService.logPageView('MainPage');

    /* this.adalSvc.acquireToken('https://graph.microsoft.com').subscribe((token: string) => {
     });*/
  }
}
