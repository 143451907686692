import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { TrnPersona } from '../../trn-persona';
import { EnumCmsConstantes } from '../../../comisiones/comisiones-constantes';

@Component({
  selector: 'app-persona-dependencia',
  templateUrl: './persona-dependencia.component.html',
  styleUrls: ['./persona-dependencia.component.sass']
})

export class PersonaDependenciaComponent implements OnInit {

  @Input()
  personaDatos: TrnPersona;

  dependenciasPrimerNivel: any[];
  dependenciaPrimerNivel: any = null;
  dependeciasSegundoNivel: any[];
  dependeciaSegundoNivel: any = null;
  private funcionario: any;

  documentoBloqueado: boolean = false;
  visibleDialogoExito: boolean = false;

  constructor(
    private router: Router,
    private parametricasService: ParametricasService,
    private personaService: PersonaService,
    private componentePrincipal: PrincipalComponent) { }

  ngOnInit() {
    this.personaService.ObtenerFuncionario(this.personaDatos.id).subscribe(
      respuesta => {
        if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          return;
        }

        this.funcionario = respuesta.funcionario;

        if (this.funcionario.dependenciaPrimerNivel != null) {
          this.dependenciaPrimerNivel = { nombre: this.funcionario.dependenciaPrimerNivel, id: this.funcionario.idDependenciaPrimerNivel };
        }

        if (this.funcionario.dependencia != null) {
          if (this.funcionario.idDependenciaPrimerNivel == this.funcionario.idDependencia){
            this.funcionario.dependencia = 'Despacho';
          }
          
          this.dependeciaSegundoNivel = { nombre: this.funcionario.dependencia, id: this.funcionario.idDependencia }
        }
      }
    );
  }

  guardarDependencia() {
    if (this.dependenciaPrimerNivel == null || this.dependeciaSegundoNivel == null) {
      this.componentePrincipal.cargarErrorServicio("Debe seleccionar la dependencia a la que pertenece");
      return;
    }
    
    this.documentoBloqueado = true;

    this.personaService.GuardarDependencia(this.funcionario.idPersona, this.dependenciaPrimerNivel.id, this.dependeciaSegundoNivel.id).subscribe(
      result => {
        this.visibleDialogoExito = true;
      },
      error => {
        this.componentePrincipal.cargarErrorServicio(error);
      },
      () => {
        this.documentoBloqueado = false;
      }
    );
  }

  // Informativo - Dialogo
  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.router.navigate([this.router.url]);
  }

  cargarDependenciasPrimerNivel(event: any) {
    const filtroDependencias = [];
    return this.parametricasService.srvListarDependenciasPorIdPadre(1).subscribe(
      (respuesta: any) => {
        if (respuesta.respuestaServicio.codigoSalida == EnumRespuestaServicio.CODIGO_ERROR_OPERACION) {
          this.componentePrincipal.cargarErrorServicio(respuesta.respuestaServicio.mensajeSalida);
          return;
        }

        this.dependenciasPrimerNivel = respuesta.dependencias;

        if (this.dependenciasPrimerNivel != null && event != null) {
          let existeFiltro: boolean;
          this.dependenciasPrimerNivel.forEach(dependencia => {
            if (String(dependencia.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroDependencias.push(dependencia);
              existeFiltro = true;
            }
          });

          if (existeFiltro) {
            this.dependenciasPrimerNivel = filtroDependencias;
          }
        }
      },
      error => {
        this.componentePrincipal.cargarErrorServicio(error);
      },
      () => {
      });
  }
  
  limpiarSegundoNivel() {
    this.dependeciaSegundoNivel = null;
  }

  cargarDependenciasSegundoNivel(event: any) {
    if (this.dependenciaPrimerNivel != null) {
      const filtroDependencias = [];
      return this.parametricasService.ConsultarDependenciasAniPorIdDependenciaPrimerNivel(this.dependenciaPrimerNivel.id).subscribe(
        (respuesta: any) => {
          this.dependeciasSegundoNivel = respuesta;
          if (this.dependeciaSegundoNivel != null && event != null) {
            let existeFiltro: boolean;
            this.dependeciasSegundoNivel.forEach(dependencia => {
              if (String(dependencia.nombre).toLocaleLowerCase().includes(event.query.toLowerCase())) {
                filtroDependencias.push(dependencia);
                existeFiltro = true;
              }
            });
            
            if (existeFiltro) {
              this.dependeciasSegundoNivel = filtroDependencias;
            }
          }
        },
        error => {
          this.componentePrincipal.cargarErrorServicio(error);
        },
        () => {
        });
    }
  }
}
