import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CrrMaTipoCierreViaDTO, CrrViaAfectadaDTO, CrrViaAfectadaReporteDTO } from '../../ola-invernal.dto';
import { OlaInvernalService } from '../../ola-invernal.service';
import { ParametricasService } from '../../../parametricas/parametricas.service';
import { EnumTipoProyecto } from '../../../comun/enum-tipo-proyecto';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ola-invernal-reporte-detalle-listado',
  templateUrl: './ola-invernal-reporte-detalle-listado.component.html',
  styles: []
})
export class OlaInvernalReporteDetalleListadoComponent implements OnInit {

  // Informacion del datatable
  reporteOlaInvernal: CrrViaAfectadaDTO[] = [];
  reporteExcelOlaInvernal: CrrViaAfectadaDTO[] = [];
  totalRecords: number;
  loading: boolean;
  directBtnPdf: boolean;

  tiposCierre: CrrMaTipoCierreViaDTO[] = [];
  tipoCierreViaFiltroDTO: CrrMaTipoCierreViaDTO;
  proyectos: any[] = [];
  proyectoFiltroDTO: any;
  fechaInicioFiltro?: Date;
  fechaFinFiltro?: Date;
  filtroReporteOlaInvernal: CrrViaAfectadaReporteDTO;

  buscarFiltro: string;

  formReporte;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private datepipe: DatePipe,
    private principalComponent: PrincipalComponent,
    private olaInvernalService: OlaInvernalService,
    private parametricasService: ParametricasService
  ) {
    this.filtroReporteOlaInvernal = {};
    this.fechaInicioFiltro = null;
    this.fechaFinFiltro = null;

    this.formReporte = formBuilder.group({
      fechaInicioFiltro: [null, [Validators.required]],
      fechaFinFiltro: [null, [Validators.required]],
      tipoCierre: [null, null],
      proyecto: [null, null],
    });
  }

  /**
   * Metodo que incializa invoca el listado de parametricas
   */
  ngOnInit() {
    this.directBtnPdf = false;
    this.cargarProyectos(null);
    this.cargarTiposCierre(null);
  }

  /**
   * Metodo utilizado para la paginación y el uso de filtros
   * @param event 
   */
  loadDataLazy(event: LazyLoadEvent) {
    if (this.formReporte.valid) {
      this.loading = true;
      this.reporteOlaInvernal = [];
      this.consultarReporteDetalleOlaInvernal(event.first, event.rows, event.sortField, event.sortOrder);
    }
  }

  /**
   * Metodo consultar la informaicón del databale de acuerdo a los filtros definidos
   * @param buscarTodosRegistros Bandera para determinar si la invocación debe realizar la consulta de registros
   */

  buscar(buscarTodosRegistros: boolean) {
    if (buscarTodosRegistros) {
      if (this.formReporte.valid) {
        const filaDesde = 0;
        const cantidadRegistros = 10;
        this.loading = true;
        this.consultarReporteDetalleOlaInvernal(filaDesde, cantidadRegistros, 'unidadFuncional', 2);
      } else {
        this.lanzarMensajeError("Los campos marcados con * son obligatorios");
      }
    }
  }

  /**
   * Metodo que invoca el servicio para consultar la tabla parametrica de tipos de cierre de ola invernal
   * @param event Evento
   * @returns Listado Observable con el resultado de la operación
   */
  cargarTiposCierre(event) {
    const filtroTipoCierre = [];
    return this.olaInvernalService.srvConsultarMaTiposCierreOlaInvernal()
      .subscribe(
        (result) => {
          this.tiposCierre = result.listaCrrMaTipoCierreVia;
          if (this.tiposCierre === null) {
            this.tiposCierre = [];
          }

          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.tiposCierre.length; i++) {
              const filtro = this.tiposCierre[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroTipoCierre.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.tiposCierre = filtroTipoCierre;
            }
          }
        });
  }

  /**
    * Metodo que invoca el servicio para consultar la tabla parametrica del listado de proyectos carreteros
    * @param event Evento
    * @returns Listado Observable con el resultado de la operación
    */
  cargarProyectos(event) {
    const filtroList = [];
    return this.parametricasService.getProyectosPorTipo(EnumTipoProyecto.Carretero)
      .subscribe(
        (result) => {
          this.proyectos = result;
          if (this.proyectos === null) {
            this.proyectos = [];
          }

          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.proyectos.length; i++) {
              const filtro = this.proyectos[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroList.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.proyectos = filtroList;
            }
          }
        });
  }


  /**
    * Metodo que invoca el servicio para consultar los registro de ola invernal, de acuerdo a los filtros definidos
    * @param event Evento
    * @returns Listado Observable con el resultado de la operación
    */
  private consultarReporteDetalleOlaInvernal(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.filtroReporteOlaInvernal = {
      fechaInicioReporte: this.fechaInicioFiltro,
      fechaFinReporte: this.fechaFinFiltro,
      idProyecto: (this.proyectoFiltroDTO != null ? this.proyectoFiltroDTO.id : 0),
      idTipoCierre: (this.tipoCierreViaFiltroDTO != null ? this.tipoCierreViaFiltroDTO.id : 0),
      filaDesde: filaDesde,
      cantidadRegistros: cantidadRegistros,
      buscar: null,
      campoOrden: campoOrden,
      ascDesc: (ascDesc != null ? (ascDesc == 1 ? 'asc' : 'desc') : null),
    };


    this.olaInvernalService.srvConsultarReporteDetalleOlaInvernal(this.filtroReporteOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.reporteOlaInvernal = result.listaCrrOlaInvernal;
            this.totalRecords = this.reporteOlaInvernal != null && this.reporteOlaInvernal[0] != null ? this.reporteOlaInvernal[0].totalRegistros : 0;
            if(this.directBtnPdf){
              this.exportPdf();
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
        this.loading = false;
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Metodo utilizado para limpiar los campos del formulario
   */
  limpiarFiltros() {
    this.filtroReporteOlaInvernal = {};
    this.proyectoFiltroDTO = null;
    this.tipoCierreViaFiltroDTO = null;
    this.fechaInicioFiltro = null;
    this.fechaFinFiltro = null;
    this.buscarFiltro = null;
    this.reporteOlaInvernal = [];
    this.formReporte = this.formBuilder.group({
      fechaInicioFiltro: [null, [Validators.required]],
      fechaFinFiltro: [null, [Validators.required]],
      tipoCierre: [null, null],
      proyecto: [null, null],
    });
  }


  /**
   * Metodo utilizado para redireccionar a la pagina de detalle de cada reprte de ola invernal
   */
  irConsultarDetalle(olaInvernal: CrrViaAfectadaDTO) {
    this.router.navigate([this.router.url + '/reportes/carreteros/ola-invernal-reporte-detalle', olaInvernal.idProyecto, olaInvernal.id]);
  }

  /**
   * Metodo utilizado para invocar la funcionalidad de exportar excel la información de registro de ola invernal
   */
  exportExcel() {
    this.loading = true;

    this.filtroReporteOlaInvernal = {
      fechaInicioReporte: this.fechaInicioFiltro,
      fechaFinReporte: this.fechaFinFiltro,
      idProyecto: (this.proyectoFiltroDTO != null ? this.proyectoFiltroDTO.id : 0),
      idTipoCierre: (this.tipoCierreViaFiltroDTO != null ? this.tipoCierreViaFiltroDTO.id : 0),
      filaDesde: 0,
      cantidadRegistros: 9999999,
      buscar: null,
      campoOrden: null,
      ascDesc: null
    };

    const reporteExcelTransformeOlaInvernal: any[] = [];
    this.olaInvernalService.srvConsultarReporteDetalleOlaInvernal(this.filtroReporteOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.reporteExcelOlaInvernal = result.listaCrrOlaInvernal;
            this.reporteExcelOlaInvernal.forEach(rpt => {
              const excel = {
                id: rpt.id,
                idUnidadFuncional: rpt.idUnidadFuncional,
                unidadFuncional: rpt.unidadFuncional,
                idProyecto: rpt.idProyecto,
                nombreProyecto: rpt.nombreProyecto,
                idMunicipio: rpt.idMunicipio,
                municipio: rpt.municipio,
                idDepartamento: rpt.idDepartamento,
                departamento: rpt.departamento,
                idTipoCierreVia: rpt.idTipoCierreVia,
                tipoCierreVia: rpt.tipoCierreVia,
                fechaReporte: this.datepipe.transform(rpt.fechaReporte, 'dd/MM/yyyy HH:mm'),
                codigoRuta: rpt.codigoRuta,
                tipoUbicacion: rpt.tipoUbicacion,
                puntoInicial: rpt.puntoInicial,
                distanciaAlPuntoInicial: rpt.distanciaAlPuntoInicial,
                puntoFinal: rpt.puntoFinal,
                distanciaAlPuntoFinal: rpt.distanciaAlPuntoFinal,
                longitudInicial: rpt.longitudInicial,
                latitudInicial: rpt.latitudInicial,
                longitudFinal: rpt.longitudFinal,
                latitudFinal: rpt.latitudFinal,
                sentidoCirculacion: rpt.sentidoCirculacion,
                motivoEvento: rpt.motivoEvento,
                planAccion: rpt.planAccion,
                rutaAlterna: rpt.rutaAlterna,
                fechaCierre: this.datepipe.transform(rpt.fechaCierre, 'dd/MM/yyyy HH:mm'),
                fechaEstimadaApertura: this.datepipe.transform(rpt.fechaEstimadaApertura, 'dd/MM/yyyy HH:mm'),
                observacionesApertura: rpt.observacionesApertura,
                fechaRealApertura: this.datepipe.transform(rpt.fechaRealApertura, 'dd/MM/yyyy HH:mm'),
                personalOperativo: rpt.personalOperativo,
                maquinariaAmarillaDisponible: rpt.maquinariaAmarillaDisponible,
                unidadesEventosGrandesMagnitudes: rpt.unidadesEventosGrandesMagnitudes,
                rutaImagen1: rpt.rutaImagen1,
                rutaImagen2: rpt.rutaImagen2
              }
              reporteExcelTransformeOlaInvernal.push(excel);
            });

            import('xlsx').then(xlsx => {
              const worksheet = xlsx.utils.json_to_sheet(reporteExcelTransformeOlaInvernal);
              const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
              const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
              this.saveAsExcelFile(excelBuffer, 'reporteOlaInvernal');
            });
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
        this.loading = false;
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.loading = false;
      }
    );

  }

  /**
   * Metodo utilizado para descargar el archivo en formato xlsx generado
   * @param buffer 
   * @param fileName 
   */
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  /**
   * Metodo que invoca la generacion del certificado del pdf
   */
  exportPdf() {
    if(this.totalRecords == undefined ){
      this.directBtnPdf = true;
    }

    if(this.totalRecords > 0 ){
      const filaDesde = 0;
      const cantidadRegistros = 10;
      const campoOrden = "unidadFuncional";
      const ascDesc = Number(2);

      this.filtroReporteOlaInvernal = {
        fechaInicioReporte: this.fechaInicioFiltro,
        fechaFinReporte: this.fechaFinFiltro,
        idProyecto: (this.proyectoFiltroDTO != null ? this.proyectoFiltroDTO.id : 0),
        idTipoCierre: (this.tipoCierreViaFiltroDTO != null ? this.tipoCierreViaFiltroDTO.id : 0),
        filaDesde: filaDesde,
        cantidadRegistros: cantidadRegistros,
        buscar: null,
        campoOrden: campoOrden,
        ascDesc: (ascDesc != null ? (ascDesc == 1 ? 'asc' : 'desc') : null),
      };

      this.loading = true;
      this.directBtnPdf = false;
      this.olaInvernalService.GeneracionPdfOlaInvernal(this.filtroReporteOlaInvernal).subscribe(
        result => {
          
          var resultado = result;
          this.loading = true;
          if (resultado != null && resultado.datos != null && resultado.datos.archivoUpload != null) {
            this.onDownload(resultado.datos);          
            setTimeout(() => {
              this.loading = false;
            }, 2000);  
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
      setTimeout(() => {
        this.loading = true;
      }, 1000);
    }
    
  }


  /**
  * Metodo que descarga el archivo
  *
  * @param {*} data
  * @memberof OlaInvernalReporteDetalleListadoComponent
  */
  onDownload(data) {
    this.loading = true;
    var a = document.createElement("a");
    var fileName = data.nombreArchivo;
    var base64str = data.archivoUpload;
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    var blob = new Blob([view], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }

  bntVolver() {
    const ruta = "/reportes/carreteros";
    this.router.navigate([ruta]);
  }

  /**
  * Lanza mensaje de informacion
  */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
