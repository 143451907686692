import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PolizasService {

  public END_POINT = '';

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  /**
   * Metodo Constructor
   * @param config 
   * @param http 
   */
  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  /**
   * Metodo que carga la lista de contratos
   * @param id 
   */
  public getContratosListaPrincipal(id) {
    return this.http.get(this.END_POINT + '/PlzPoliza/ConsultarPolizasProyecto/' + id ) 
      .pipe(
        retry(1));
  }

  /**
   * Metodo que elimina una poliza
   * @param idPoliza 
   */
  public deleteDetalle(idPoliza) {
    return this.http.delete(this.END_POINT + '/PlzPoliza/EliminarPoliza/' + idPoliza )
      .pipe(
        retry(1));
  }

}
