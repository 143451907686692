import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProyectoDTO, VwCrrAvanceUfDTO, IntervencionDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { EjecucionDTO, EjecucionDetalleDTO, VwCrrAvanceIntervencionUfDepartamentoDTO } from '../../ejecucion-proyecto.dto';
import { MessageService, ConfirmationService } from 'primeng/api';
import { EjecucionProyectoService } from '../../ejecucion_proyecto.service';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { Observable, forkJoin } from 'rxjs';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ProyectoLineaBase } from '../../../dtos/seguimiento-comunicaciones/proyecto-comunicaciones';


@Component({
  selector: 'app-avance-proyecto-unidades-funcionales',
  templateUrl: './avance-proyecto-unidades-funcionales.component.html',
  styleUrls: ['./avance-proyecto-unidades-funcionales.component.sass']
})
export class AvanceProyectoUnidadesFuncionalesComponent implements OnInit {

  @Input() proyectoSeleccionado: ProyectoDTO;
  @Input() ejecucionSeleccionada: EjecucionDTO;

  @Output() OutputIrAEjecuciones = new EventEmitter();
  @Output() OutputPublicarEjecucion = new EventEmitter();
  @Output() OutputUnidadFuncionalSeleccionado = new EventEmitter();

  blockedDocument: boolean = false;

  porcentajeAvanceSeleccionado: number;

  vwCrrAvanceUfDTOList: VwCrrAvanceUfDTO[];
  vwCrrAvanceIntervencionUfDeptoDTOList: VwCrrAvanceIntervencionUfDepartamentoDTO[];

  intervencionDTOList: IntervencionDTO[] = [];
  unidadFuncionalSeleccionada: VwCrrAvanceUfDTO;

  constructor(private route: Router, private ruta: ActivatedRoute,
    private ejecucionProyectoService: EjecucionProyectoService,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    //this.obtenerAvanceIntervencionUfDepartamento();
  }

  irAEjecuciones() {
    this.OutputIrAEjecuciones.emit("");
  }

  publicarEjecucion() {
    //Si se va a publicar...
    if (!this.ejecucionSeleccionada.publicado) {

      let faltaReportarIntervenciones = this.verificarSiFaltaReportarIntervenciones();

      if (!faltaReportarIntervenciones) {
        //Lanzar mensaje de advertencia...
        this.confirmationService.confirm({
          message: '¿Esta seguro publicar la ejecución? Una vez publicada no podra modificarla',
          accept: () => {
            this.blockedDocument = true;
            //Colocar publicado en TRUE
            this.ejecucionSeleccionada.publicado = true;
            //Guardar los cambios en BD
            this.ejecucionProyectoService.guardarEjecucion(this.ejecucionSeleccionada).subscribe((data: { resultado: { idCrrEjecucion: any } }) => {
              this.OutputPublicarEjecucion.emit({ codigo: 1, respuesta: "Las ejecución se publicó correctamente", idEjecucionPublicada: data.resultado.idCrrEjecucion });
              this.actualizaTablasAvance();
            }, error => {
              this.principalComponent.cargarErrorServicio(error);
            });
          }
        });
      } else {
        this.OutputPublicarEjecucion.emit({ codigo: 2, respuesta: "Falta reportar las intervenciones de todas las unidades funcionales" });
      }
    } else {
      this.OutputPublicarEjecucion.emit({ codigo: 3, respuesta: "La ejecución ya se publicó previamente" });
    }
  }

  actualizaTablasAvance() {
    this.blockedDocument = true;
    
    // Guardar el DTO
    forkJoin(
      this.ejecucionProyectoService.actualizaTablasAvance(this.proyectoSeleccionado.id)
    ).subscribe(
      ([result]) => {
        console.log('ActualizaTablasAvance');
        console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            
          } else {
            
          }
        } else {
          // console.log('Ocurrio un error al consumir el servicio srvGuardarPredio');
        }
      },
      (error) => {
        // console.log(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  seleccionarUnidadFuncional(_unidadFuncionalSeleccionada): void {
    this.unidadFuncionalSeleccionada = _unidadFuncionalSeleccionada;
    this.OutputUnidadFuncionalSeleccionado.emit(this.unidadFuncionalSeleccionada);
  }

  verificarSiFaltaReportarIntervenciones(): boolean {
    let faltaReportarIntervencion = false;
    //obtener las ejecuciones
    forkJoin(
      this.ejecucionProyectoService.srvAvanceUfPorEjecucion(this.ejecucionSeleccionada.id)
    ).subscribe(([avanceUfPorEjecucionData]) => {
      this.vwCrrAvanceUfDTOList = avanceUfPorEjecucionData;
      //Recorrer cada ejecucion
      this.vwCrrAvanceUfDTOList.forEach(vwCrrAvanceUfDTOVar => {
        //Obtener las intervenciones reportadas
        forkJoin(
          this.ejecucionProyectoService.srvAvanceIntervencionUfDepartamento(vwCrrAvanceUfDTOVar.idUnidadFuncional, this.ejecucionSeleccionada.id)
        ).subscribe(([avanceIntervencionUfDeptoData]) => {
          this.vwCrrAvanceIntervencionUfDeptoDTOList = avanceIntervencionUfDeptoData;
          //Recorrer cada intervencion reportada
          this.vwCrrAvanceIntervencionUfDeptoDTOList.forEach(avanceIntervencionVar => {
            if (avanceIntervencionVar.idEjecucionDetalle == null) {
              faltaReportarIntervencion = true;
            }
          });
        }, error => {
          this.principalComponent.cargarErrorServicio(error);
        });
      });
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
    return faltaReportarIntervencion;
  }


  labelVerDetalles() {
    if (this.ejecucionSeleccionada.publicado) {
      return "Ver Avance Reportado";
    } else {
      return "Reportar Ejecución";
    }
  }

  iconoVerDetalles() {
    if (this.ejecucionSeleccionada.publicado) {
      return "remove_red_eye";
    } else {
      return "create";
    }
  }

  outputPorcentajeAvance(_porcentaje) {
    this.porcentajeAvanceSeleccionado = _porcentaje;
  }

}
