import { Component, OnInit } from '@angular/core';
import { ContratoCreditoPuenteBaseComponent } from '../contrato-credito-puente-base/contrato-credito-puente-base.component';
import { InformacionGeneralService } from 'src/app/informacion-general/informacion-general.service';
import { ContratoCreditoPuenteIndexadoService } from './contrato-credito-puente-indexado.service';
import { ContratoCreditoCarreteroService } from 'src/app/contrato-credito-carretero/contrato-credito-carretero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ContratoCreditoPuenteIndexado } from './ContratoCreditoPuenteIndexado';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-contrato-credito-puente-indexado',
  templateUrl: './contrato-credito-puente-indexado.component.html',
  styleUrls: ['./contrato-credito-puente-indexado.component.sass']
})
export class ContratoCreditoPuenteIndexadoComponent implements OnInit {

  contratoCreditoList: any = [];

  displayedColumns: any[];

  contratoCreditoPuenteIndexadoSeleccionado: ContratoCreditoPuenteIndexado

  idProyecto: number;
  proyecto: any;

  idContratoCredito: any;

  visibleAgregarContratoCreditoPuenteIndexado: boolean = false;
  visibleDialogoAdvertenciaFecha: boolean = false;
  visibleDialogoExito: boolean = false;
  visibleConfirmarEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarContratoCreditoPuente: boolean = false;

  datosContratoCreditoPuenteIndexado: ContratoCreditoPuenteIndexado = {};



  habilitaCrear: boolean = false;

  constructor(public contratoCreditoPuenteBase: ContratoCreditoPuenteBaseComponent, private infoService: InformacionGeneralService, private infoContratoCredito: ContratoCreditoPuenteIndexadoService,
    private contratoCreditoCarreteroService: ContratoCreditoCarreteroService, private route: Router, private ruta: ActivatedRoute, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
      this.cargarContratoCreditoPuente(this.idProyecto);
    });
    this.displayedColumns = [
      { field: 'formatoFecha', header: 'Fecha de referencia' },
      { field: 'formatoValorPesos', header: 'Valor en pesos' },
      { field: 'formatoValorDolares', header: 'Valor en dolares' }
    ];
  }

  cargarContratoCreditoPuente(id: number) {
    return this.infoContratoCredito.getContratoCreditoPuenteIndexado(id).subscribe((data: {}) => {
      this.contratoCreditoList = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
      this.contratoCreditoPuenteBase.nombreProyecto = this.proyecto.nombre;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAgregarContratoCreditoPuenteIdexado() {
    this.visibleAgregarContratoCreditoPuenteIndexado = true;
    this.idContratoCredito = "new";
    this.datosContratoCreditoPuenteIndexado = {};
  }

  cancelarAgregarContratoCreditoPuenteIdexado() {
    this.visibleAgregarContratoCreditoPuenteIndexado = false;
  }

  guardarInformacionContratoCreditoPuenteIndexado(forma: NgForm) {
   if(this.datosContratoCreditoPuenteIndexado.valorDolares == 0 && this.datosContratoCreditoPuenteIndexado.valorPesos == 0){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Al menos un valor debe ser diferente de 0.', life: 10000 });
    }else{
      if (this.idContratoCredito === 'new') {
       this.datosContratoCreditoPuenteIndexado.idProyecto = this.idProyecto;
        let vara: any = this.infoContratoCredito.guardarContratoCreditoPuenteIndexado(this.datosContratoCreditoPuenteIndexado).subscribe((data: {}) => {
          let respuesta: ContratoCreditoPuenteIndexado = data;
          this.visibleDialogoExito = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        this.infoContratoCredito.editarContratoCreditoPuenteIndexado(this.datosContratoCreditoPuenteIndexado, this.idContratoCredito).subscribe((data: {}) => {
          let respuesta: ContratoCreditoPuenteIndexado = data;
          this.visibleDialogoExito = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      }
    }
  }

  editarContratoCreditoPuenteIndexado(contratoCreditoIndexado: ContratoCreditoPuenteIndexado) {
    this.contratoCreditoPuenteIndexadoSeleccionado = contratoCreditoIndexado;
    this.idContratoCredito = this.contratoCreditoPuenteIndexadoSeleccionado.id;
    this.visibleAgregarContratoCreditoPuenteIndexado = true;
   return this.infoContratoCredito.getContratoCreditoPuenteIndexadoPorId(this.contratoCreditoPuenteIndexadoSeleccionado.id).subscribe((data: {}) => {
      this.datosContratoCreditoPuenteIndexado = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoAdvertenciaFecha() {
    this.visibleDialogoAdvertenciaFecha = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.cargarContratoCreditoPuente(this.idProyecto);
    this.visibleAgregarContratoCreditoPuenteIndexado = false;
  }

  irEliminarContratoCreditoPuenteIndexado(contratoCreditoIndexado: ContratoCreditoPuenteIndexado) {
    this.contratoCreditoPuenteIndexadoSeleccionado = contratoCreditoIndexado;
    this.visibleConfirmarEliminarRegistro = true;
    this.idContratoCredito = this.contratoCreditoPuenteIndexadoSeleccionado.id;
  }

  cancelarEliminarContratoCreditoPuenteIndexado() {
    this.visibleConfirmarEliminarRegistro = false;
  }

  aceptarEliminarContratoCreditoPuenteIndexado() {
    return this.infoContratoCredito.eliminarContratoCreditoPuenteIndexadoPorId(this.idContratoCredito).subscribe((data: {}) => {
      this.cargarContratoCreditoPuente(this.idProyecto);
      this.visibleDialogoExitoEliminarRegistro = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarContratoCreditoPuenteIndexada() {
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleConfirmarEliminarRegistro = false;
  }

}
