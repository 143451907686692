import { Component, OnInit } from '@angular/core';
import { InformeCxpBaseComponent } from '../informe-cxp-base/informe-cxp-base.component';
import { InformeRevisionService } from '../../../informe-revision.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-informe-cxp-aprobacion',
  templateUrl: './informe-cxp-aprobacion.component.html',
  styles: []
})
export class InformeCxpAprobacionComponent implements OnInit {


  constructor(
    private lineaBase: InformeCxpBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
  }

  aprobarRechazarInformeRevision(aprobo: boolean) {
    // console.log('aprobarRechazarInformeRevision');
    this.lineaBase.blockedDocument = true;
    if (this.lineaBase.asignacionInforme != null) {
      this.lineaBase.asignacionInforme.aprobo = aprobo;
      // console.log(JSON.stringify(this.lineaBase.asignacionInforme));
      if (this.validarAprobarRechazarInformeRevision()) {
        this.infCtaSupervisionService.AprobarRechazarInformeRevision(this.lineaBase.idInformeParam, this.lineaBase.idPasoParam, this.lineaBase.idVersionInformeParam, this.lineaBase.asignacionInforme)
          .subscribe(
            result => {
              // console.log(JSON.stringify(result));
                if (result != null && result.respuestaServicio != null) {
                  if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.lineaBase.mensajeDialogo = result.respuestaServicio.mensajeSalida;
                    this.lineaBase.visibleDialogoExito = true;
                  } else {
                    this.lineaBase.mensajeDialogo = result.respuestaServicio.mensajeSalida;
                    this.lineaBase.visibleDialogoError = true;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                  }
                } else {
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO.toString(), life: 10000 });
                }
            },
            error => {
              console.error(error);
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
              this.lineaBase.blockedDocument = false;
              this.ngOnInit();
            }
          );
      } else {
        this.lineaBase.blockedDocument = false;
      }
    }
  }

  validarAprobarRechazarInformeRevision(): boolean {
    let accionValida = true;
    if (!this.lineaBase.asignacionInforme.aprobo) {
      if (this.lineaBase.asignacionInforme.observacionDevolucion == null) {
        accionValida = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.lineaBase.msgValObservacionRechazo, life: 10000 });
        return accionValida;
      }
      if (this.lineaBase.asignacionInforme.observacionDevolucion != null &&
        (this.lineaBase.asignacionInforme.observacionDevolucion == "" || this.lineaBase.asignacionInforme.observacionDevolucion.length == 0 || this.lineaBase.asignacionInforme.observacionDevolucion.length < 10)) {
        accionValida = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.lineaBase.msgValObservacionRechazoMinimo, life: 10000 });
        return accionValida;
      }
    }
    return accionValida;
  }

}
