export class FncDatosGeneralesFinancierosDTO {
    public idProyecto?: number;
    public proyecto?: string;
    public fechaConstitucionPatrimonio?: Date;
    public fechaCierreFinanciero?: Date;
    public tdi?: number;
    public equity?: number;
    public equityFondeado?: number;
    public fechaSuscripcionContrato?: Date;
    public valorFinalContrato?: number;
    public tieneContratoCredito?: number;
    public fechaSuscripcionContratoCredito?: Date;
    public valorPesosContratoCredito?: number;
    public valorDolaresContratoCredito?: number;
    public valoresContratoCredito?: CntValoresIndexadosContratoCreditoDTO[];
    public fechasReferencia?: FncFechaReferenciaDTO[];
    public valoresIndexadosDatosGenerales?: FncValoresIndexadosDatosGeneralesDTO[];
    public valoresIndexadosVigenciasFuturas?: FncValoresIndexadosVigenciasFuturasDTO[];    
}

export class CntValoresIndexadosContratoCreditoDTO {
    public id?: number;
    public idContratoCredito?: number;
    public fecha?: Date;
    public valorPesos?: number;
    public valorDolares?: number;
}

export class FncValoresIndexadosDatosGeneralesDTO {
    public id?: number;
    public idProyecto?: number;
    public fecha?: Date;
    public equity?: number;
    public equityFondeado?: number;
    public cierreFinancieroPresentado?: number;
}

export class FncFechaReferenciaDTO {
    public id?: number;
    public fecha?: Date;
}

export class FncValoresIndexadosVigenciasFuturasDTO {
    public id?: number;
    public idProyecto?: number;
    public fecha?: Date;
    public acumulado?: number;
    public giradas?: number;
    public porcentajeDolares?: number;
}

export class FncVigenciasFuturasDTO {
    public idProyecto?: number;
    public proyecto?: string;
    public fecha?: Date;
    public acumulado?: number;
    public giradas?: number;
    public porcentajeDolares?: number;
}

export class CntContratoPuenteDTO {
    public idProyecto?: number;
    public proyecto?: string;
    public idContrato?: number;
    public fechaSuscripcion?: Date;
    public valorPesos?: number;
    public valorDolares?: number;
}

export class FncDatosGeneralesFinancierosVIxDTO {
    public idProyecto?: number;
    public proyecto?: string;
    public fecha?: Date;
    public equity?: number;
    public equityFondeado?: number;
    public cierreFinancieroPresentado?: number;
}

export class FncSaldoCuentaDTO {
    public idProyecto?: number;
    public proyecto?: string;
    public fecha?: Date;
    public idOrigen?: number;
    public origen?: string;
    public valorPesos?: number;
    public valorDolares?: number;
}

export class FncSaldoCuentaCSVDTO {    
    public proyecto?: string;
    public fecha?: Date;    
    public origen?: string;
    public valorPesos?: number;
    public valorDolares?: number;
}

export class FncRetribucionDTO {
    public idProyecto?: number;
    public proyecto?: string;
    public idUnidadFuncional?: number;
    public unidadFuncional?: string;
    public fechaRetribucion?: Date;
    public valorExplotacionComercial?: number;
    public porcentajeExplotacionComercial?: number;
    public valorPeajes?: number;
    public porcentajePeajes?: number;
    public valorPesosVigenciasFuturas?: number;
    public valorDolaresVigenciasFuturas?: number;
    public porcentajeVigenciasFuturas?: number;
}

export class FncRetribucionCSVDTO {    
    public proyecto?: string;    
    public unidadFuncional?: string;
    public fechaRetribucion?: Date;
    public valorExplotacionComercial?: any;
    public porcentajeExplotacionComercial?: any;
    public valorPeajes?: any;
    public porcentajePeajes?: any;
    public valorPesosVigenciasFuturas?: any;
    public valorDolaresVigenciasFuturas?: any;
    public porcentajeVigenciasFuturas?: any;
}

export class FncVpipDTO {
    public idProyecto?: number;
    public proyecto?: string;
    public idTipo?: number;
    public tipo?: string;
    public valorContractual?: number;
    public valorAcumulado?: number;
    public porcentajeCumplimiento?: number;
    public fechaValorAcumulado?: Date;
    public fechaInicioPreconstruccion?: Date;
    public fechaCumplimientoVPIP?: Date;
    public anios?: number;
    public mesesTotales?: number;
    public meses?: number;
    public formatoFechaCumplimientoVPIP?: string;
    public valorContractualList?: FncVpipDTO[];
    public valorVpip8List?: FncVpipDTO[];
    public valorVpip13List?: FncVpipDTO[];
    public valorVpip18List?: FncVpipDTO[];
}