import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sig-dispositivos-its',
  templateUrl: './sig-dispositivos-its.component.html',
  styleUrls: ['./sig-dispositivos-its.component.sass']
})
export class SigDispositivosITSComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
