import { Component, Input, OnInit } from "@angular/core";
import { loadModules } from "esri-loader";
import { CrrPeajesDTO } from "src/app/reportes/carreteros/reportes-peajes.dto";
import { CrrInformacionProyectoDTO } from "src/app/reportes/carreteros/reportes-carreteros.dto";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import * as _moment from "moment";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";

@Component({
  selector: "app-rpt-crr-proyecto-peaje",
  templateUrl: "./rpt-crr-proyecto-peaje.component.html",
  styleUrls: ["./rpt-crr-proyecto-peaje.component.sass"],
})
export class RptCrrProyectoPeajeComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private adalSvc: MsAdalAngular6Service,
    private ruta: ActivatedRoute
  ) {}

  @Input() informacionGeneralProyecto: CrrInformacionProyectoDTO;
  private isAuthenticated: boolean;
  blockedDocument = false;
  informacionGeneralPeajes: CrrPeajesDTO[] = [];
  informacionGeneralPeajesFiltro: CrrPeajesDTO[] = [];
  totalPeajesOperacion: number = 0;
  totalPeajesSuspension: number = 0;
  totalTarifaPreferencial: number = 0;
  totalPeajesElectronicos: number = 0;
  totalProyectos: number = 0;
  idsPeajesPorProyecto: string;
  informacionPeajesGroupBy: any = [];
  informacionGeneralCompleta: CrrInformacionProyectoDTO[] = [];
  idProyecto: number;
  rutaIframe: string;
  validacionuf: number;

  ngOnInit(): void {
    this.isAuthenticated = this.adalSvc.isAuthenticated;

    this.ruta.params.subscribe((params) => {
      this.idProyecto = params.id;
      this.rutaIframe =
        "https://animapas.maps.arcgis.com/apps/dashboards/a9b307a3cd854f43b02764d027c95e20#p1=" +
        this.idProyecto +
        "&p2=" +
        this.idProyecto;
    });

    fetch(
      "https://aniscopiosig-server.ani.gov.co/arcgisserver/rest/services/UnidadFuncional/Unidades_Funcionales/FeatureServer/0/query?where=IDPR=" +
        this.idProyecto +
        "&returnCountOnly=true&f=json"
    )
      .then((response) => response.json())
      .then((conteouf) => {
        this.validacionuf = conteouf.count;
      });

    this.reportesCarreteroService
      .getInformacionCrrPeajesPorIdProyecto(this.idProyecto)
      .subscribe(
        (dato) => {
          var dateCompare = _moment().format("YYYY-MM-DD HH:mm");

          if (dato != null && dato.respuestaServicio != null) {
            if (dato.respuestaServicio.codigoSalida === 1) {
              console.log("idpeajes");
              this.informacionGeneralPeajes = dato.infoPeajes;

              this.informacionGeneralPeajes = this.informacionGeneralPeajes;
              this.informacionGeneralPeajesFiltro =
                this.informacionGeneralPeajes.filter(
                  (a) =>
                    a.idAdministradoPor == 1 &&
                    dateCompare >= a.fechaInicioEstado &&
                    dateCompare <= a.fechaFinEstado
                );

              this.totalPeajesOperacion =
                this.informacionGeneralPeajesFiltro.filter(
                  (a) => a.idEstado == 1
                ).length;
              this.totalPeajesSuspension =
                this.informacionGeneralPeajesFiltro.filter(
                  (a) => a.idEstado == 3
                ).length;

              this.informacionPeajesGroupBy =
                this.informacionGeneralPeajesFiltro.reduce(function (
                  result,
                  current
                ) {
                  result[current.idProyecto] = result[current.idProyecto] || [];
                  result[current.idProyecto].push(current);
                  return result;
                },
                {});

              for (
                let i = 0;
                i < this.informacionGeneralPeajesFiltro.length;
                i++
              ) {
                if (i == 0) {
                  this.idsPeajesPorProyecto =
                    this.idsPeajesPorProyecto +
                    '"' +
                    this.informacionGeneralPeajesFiltro[i].idPeaje +
                    '"';
                } else {
                  this.idsPeajesPorProyecto =
                    this.idsPeajesPorProyecto +
                    ',"' +
                    this.informacionGeneralPeajesFiltro[i].idPeaje +
                    '"';
                }
              }

              this.totalProyectos = Object.keys(
                this.informacionPeajesGroupBy
              ).length;

              console.log(this.informacionPeajesGroupBy);

              this.totalTarifaPreferencial =
                this.informacionGeneralPeajesFiltro.filter(
                  (a) => a.tieneTarifaEspecial === 1
                ).length;
              this.totalPeajesElectronicos =
                this.informacionGeneralPeajesFiltro.filter(
                  (a) => a.tienePagoElectronico === true
                ).length;

              console.log("totalTarifaPreferencial");
              console.log(this.totalTarifaPreferencial);

              console.log("totalPeajesElectronicos");
              console.log(this.totalPeajesElectronicos);
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: dato.respuestaServicio.mensajeSalida,
                life: 10000,
              });
            }
          }
          console.log("idpeajes2");
        },
        (error) => {
          this.blockedDocument = false;
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );

    var colorUFS = [
      "#239FCC",
      "#ebbe56",
      "#BC80BD",
      "#FB8073",
      "#42AF99",
      "#B34979",
      "#b34950",
      "#b35949",
      "#49b3a1",
      "#49b1b3",
      "#4998b3",
      "#4991b3",
      "#4977b3",
      "#4965b3",
      "#4e49b3",
      "#7749b3",
      "#8949b3",
      "#aa49b3",
      "#b349ae",
      "#b34972",
      "#b3495c",
      "#239FCC",
    ];

    var capaufs = [];

    loadModules([
      "esri/config",
      "esri/Map",
      "esri/WebMap",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/widgets/Search",
      "esri/symbols/SimpleLineSymbol",
      "esri/renderers/SimpleRenderer",
      "esri/widgets/Legend",
    ])
      .then(
        ([
          esriConfig,
          Map,
          WebMap,
          MapView,
          FeatureLayer,
          Search,
          SimpleLineSymbol,
          SimpleRenderer,
          Legend,
        ]) => {
          esriConfig.apiKey =
            "AAPK2456e7e0539844b39d13266e742a95cbYV9nTVDpArBvqC_Y21vE-ZcWrZCNAXTgi3ZGBtO11pzgJMdZwMq9FmQyq_78eY8I";

          const webmap = new WebMap({
            portalItem: {
              // autocasts as new PortalItem()
              id: "d9e1868ce24a40adaa94a2c9a5f942a6",
            },
          });

          const map = new Map({
            //basemap: "streets-night-vector",
            basemap: "oceans",
          });

          const view = new MapView({
            container: "viewDivPeajes",
            map: webmap,
            center: [-74, 4], // lon, lat
            scale: 10000000,
            popup: {
              defaultPopupTemplateEnabled: false,
              dockEnabled: true,
              dockOptions: {
                buttonEnabled: false,
                breakpoint: true,
              },
            },
          });

          const otherSym = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#FD77DD",
            width: "3px",
            style: "solid",
          };

          const renderer = new SimpleRenderer({
            symbol: new SimpleLineSymbol({
              width: 1,
              color: "#fb12ff",
              style: "path",
            }),
          });

          const proyRenderer = {
            type: "simple",
            symbol: {
              color: "#5dfca5",
              type: "simple-line",
              style: "path",
              width: 1,
            },
          };

          const citiesRenderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              //url: "peaje.png",
              url: "/assets/img/newhome/peaje.png",
              width: "40px",
              height: "40px",
            },
          };

          const featureLayerUF = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/2",

            //renderer: hwyRenderer,
            title: "Unidades Funcionales",
            definitionExpression: "ID_MT = '" + this.idProyecto + "'",
          });

          const featureLayerConcesiones = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/1",
            renderer: renderer,
          });

          const featureLayerPeajes = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgis/rest/services/13_Modos/ServicioPeajes2022/FeatureServer/0",
            renderer: citiesRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              title: "{NOMBRE}</br>Estado: {Estado}",

              overwriteActions: true,
            },
            definitionExpression: "Idpr = '" + this.idProyecto + "'",
          });

          featureLayerUF.queryFeatures().then(function (results) {
            // prints the array of result graphics to the console
            let demo23 = results.features;
            let colorUFS = [
              "#00ced1",
              "#2f4f4f",
              "#483d8b",
              "#8fbc8f",
              "#e9967a",
              "#8b0000",
              "#ff8c00",
              "#008b8b",
              "#00008b",
              "#dc143c",
              "#6495ed",
              "#ff7f50",
              "#7fff00",
              "#ff1493",
              "#9400d3",
              "#008000",
              "#ff69b4",
              "#cd5c5c",
              "#b349ae",
              "#f08080",
              "#b3495c",
              "#b0c4de",
            ];
            //const demo23 = results.features;
            let contador = 0;
            demo23.forEach(function (elemento, indice, array) {
              contador = contador + 1;
              capaufs.push({
                value: elemento.attributes.CODIGOUF,
                symbol: {
                  type: "simple-line", // autocasts as new SimpleLineSymbol()
                  color: colorUFS[contador],
                  width: "7px",
                  style: "solid",
                },
              });
            });

            //return frutas;
          });

          const loadLegend = async () => {
            const otherSym1 = {
              type: "simple-line", // autocasts as new SimpleLineSymbol()
              color: "#FD77DD",
              width: "5px",
              style: "solid",
            };
            //console.log(hwyRenderer2);
            const hwyRenderer3 = {
              type: "unique-value", // autocasts as new UniqueValueRenderer()
              opacity: 1,
              //defaultSymbol: otherSym1,
              defaultLabel: "UF",
              field: "CODIGOUF",
              uniqueValueInfos: capaufs,
            };

            const labelClass2 = {
              // autocasts as new LabelClass()
              symbol: {
                type: "text", // autocasts as new TextSymbol()
                color: "#11528a",
                haloColor: "#ffffff",
                haloSize: 1,
                font: {
                  size: 11,
                  weight: "bold",
                },
              },
              labelPlacement: "center-along",
              labelExpressionInfo: {
                expression: "$feature.CODIGOUF",
              },
            };

            const featureLayerUF2 = new FeatureLayer({
              url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/2",
              outFields: ["*"],
              renderer: hwyRenderer3,
              title: "Unidades Funcionales",
              definitionExpression: "ID_MT = '" + this.idProyecto + "'",
              labelingInfo: [labelClass2],
            });

            const legend = new Legend({
              view: view,
              hideLayersNotInCurrentView: true,
              respectLayerVisibility: false,
              layerInfos: [
                {
                  layer: featureLayerUF2,
                  title: "Unidad Funcional",
                },
              ],
              definitionExpression: "ID_MT = '" + this.idProyecto + "'",
            });
            // Add widget to the bottom right corner of the view

            view.ui.add(legend, "bottom-left");
            webmap.add(featureLayerUF2);
            webmap.add(featureLayerPeajes);
          };

          const searchWidget = new Search({
            view: view,
            renderer: proyRenderer,
            container: "basemapGalleryDiv",
            allPlaceholder: "Proyecto",
            popupEnabled: false,
            includeDefaultSources: false,
            sources: [
              {
                layer: featureLayerConcesiones,
                searchFields: ["ID_MT"],
                enableLabel: false,
                suggestionTemplate: "{ID_MT}, Party: {NOMBRE}",
                displayField: "ID_MT",
                exactMatch: true,
                outFields: ["*"],
                name: "Proyecto",
                maxResults: 9,
                placeholder: "example: 3708",
                resultSymbol: {
                  color: "#0b2ac6",
                  type: "simple-line",
                  style: "short-dot",
                  width: 1,
                },
              },
            ],
          });

          // Add the search widget to the top left corner of the view
          view.ui.add(searchWidget, {
            position: "top-right",
            class: "none",
            declaredClass: "none",
          });

          searchWidget.on("search-start", function (event) {
            console.log("Search started.");
          });

          const myTimeout = setTimeout(loadLegend, 3000);

          searchWidget.search("" + this.idProyecto + "");
          //webmap.add(featureLayerPeajes);
        }
      )
      .catch((err) => {
        // handle any errors
        console.error(err);
      });
  }
}
