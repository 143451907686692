import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { EnumRespuestaServicio, EnumRegex, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

import { ProyectoDTO } from 'src/app/modulo-planeacion-proyecto/planeacion-proyecto.dto';
import { UnidadFuncionalCarreteroService } from 'src/app/unidad-funcional-carretero/unidad-funcional-carretero.service';

import { FrrViaAfectadaDTO, FrrMaTipoCierreViaDTO, FrrImagenViaAfectadaDTO,
  FrrMaClasificacionContingenciaCierreViaDTO, FrrTipoAfectacionViaDTO,
  FrrMaTipoAfectacionCierreViaDTO, EnumFrrMaTipoAfectacion, FrrMaRecaudoAfectadoDTO,
  FrrMaRutaAlternaDTO, FrrMaEtapaProyectoDTO } from '../../ola-invernal-ferreo.dto';
import { OlaInvernalFerreoService } from '../../ola-invernal-ferreo.service';
import { TraficoPeajesCargaService } from 'src/app/peajes-gestion/trafico-peajes/trafico-peajes-carga/trafico-peajes-carga.service';
import { PjePeajePorProyecto } from 'src/app/peajes-gestion/trafico-peajes/PjePeajePorProyecto';
import { InformacionGeneralPeajesService } from 'src/app/peajes-gestion/informacion-general-peajes/informacion-general-peajes.service';



@Component({
  selector: 'app-ola-invernal-ferreo-registro-registro',
  templateUrl: './ola-invernal-ferreo-registro-registro.component.html',
  styleUrls: ['./ola-invernal-ferreo-registro-registro.component.scss']
})
export class OlaInvernalFerreoRegistroRegistroComponent implements OnInit {



  blockedDocument: boolean;

  idProyecto: number;
  soloLectura: boolean;
  idOlaInvernal: number;

  fechaActual: Date;
  cantidadMinCaracteresCampoAbierto: number;
  cantidadMaximaAdjuntos: number;
  archivos: any = []; //Los archivos que yo suba van a quedar en este arreglo

  proyectoSeleccionado: ProyectoDTO;

  cierreViaAfectada: FrrViaAfectadaDTO;
  tiposCierre: FrrMaTipoCierreViaDTO[] = [];
  clasificacionesContingencia: FrrMaClasificacionContingenciaCierreViaDTO[] = [];
  etapasProyectos: FrrMaEtapaProyectoDTO[] = [];
  recaudoAfectados: FrrMaRecaudoAfectadoDTO[] = [];
  tieneRutaAlternas: FrrMaRutaAlternaDTO[] = [];
  tiposAfectacion: FrrMaTipoAfectacionCierreViaDTO[] = [];
  dialogoExitoCierre = false;

  listaFrrTipoAfectacionVia: FrrTipoAfectacionViaDTO[] = [];
  afectacionViaAfectada: FrrTipoAfectacionViaDTO;
  peajes: PjePeajePorProyecto[] = [];
  dialogoGuardarAfectacion = false;
  dialogoEliminarAfectacion = false;
  isAtlantico = false;

  listaFrrImagenViaAfectada: FrrImagenViaAfectadaDTO[] = [];
  imagenViaAfectada: FrrImagenViaAfectadaDTO;
  dialogoGuardarFotografia = false;
  dialogoEliminarFotografia = false;
  cantidadMaximaRegistrosFotograficos: number;
  cantidadActualRegistrosFotograficos: number;

  formCierre;
  formAfectacion;
  formApertura;
  formFotografia;

  constructor(
    formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private olaInvernalService: OlaInvernalFerreoService,
    private componentesComunesService: ComponentesComunesService,
    private unidadFuncionalCarreteroService: UnidadFuncionalCarreteroService,
    private traficoPeajesCargaService: TraficoPeajesCargaService,
    private informacionGeneralPeajesService: InformacionGeneralPeajesService) {
      this.soloLectura = true;
      this.fechaActual = new Date();
      this.cantidadMinCaracteresCampoAbierto = 10;
      this.cantidadMaximaAdjuntos = 1;
      this.cantidadMaximaRegistrosFotograficos = 2;
      this.cantidadActualRegistrosFotograficos = 0;

      this.cierreViaAfectada = {};
      this.imagenViaAfectada = {};

      this.formCierre = formBuilder.group({
        prInicial: [null, [Validators.required, Validators.max(9999), Validators.min(0), Validators.maxLength(4), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
        prFinal: [null, [Validators.required, Validators.max(9999), Validators.min(0), Validators.maxLength(4), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
        distanciaAlPuntoInicial: [null, [Validators.required, Validators.min(0), Validators.max(999), Validators.maxLength(16), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO_DECIMAL_MAS_DECIMAS_FERREO)]],
        distanciaAlPuntoFinal: [null, [Validators.required, Validators.min(0), Validators.max(999), Validators.maxLength(16), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO_DECIMAL_MAS_DECIMAS_FERREO)]],
        longitudAfectacionSTR: [null, null],
        motivoEvento: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(1000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
        planAccion: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(1000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
        rutaAlternaForm: [null, null],
        clasContingForm: [null, null],
        clasEtapaForm: [null, null],
        recaudoAfectadoForm: [null, null],
        fechaCierre: [null, [Validators.required]],
        fechaEstimadaApertura: [null, [Validators.required]],
      });

      this.formAfectacion = formBuilder.group({
        tipoAfectacion: [null, [Validators.required]],
        prInicialAfectacion: [null, [Validators.required, Validators.max(9999), Validators.min(0), Validators.maxLength(4), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
        prFinalAfectacion: [null, [Validators.required, Validators.max(9999), Validators.min(0), Validators.maxLength(4), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
        distanciaAlPuntoInicialAfectacion: [null, [Validators.required, Validators.min(0), Validators.max(999), Validators.maxLength(16)]],
        distanciaAlPuntoFinalAfectacion: [null, [Validators.required, Validators.min(0), Validators.max(999), Validators.maxLength(16)]],
        observacionesAfectacion: [null, [Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(220), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
      });

      this.formApertura = formBuilder.group({
        fechaRealApertura: [null, [Validators.required]],
        observacionesApertura: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(1000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
      });

      this.formFotografia = formBuilder.group({
        fechaFotografia: [null, [Validators.required]],
        tituloFotografia: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(100), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
        descripcionFotografia: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(1000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
      });
    }

  /**
   * Metodo de inicio
   */
  ngOnInit() {
    const paramIdProyecto = "idProyecto";
    const paramIdOlaInveral = "idOlaInveral";
    const paramSoloLectura = "soloLectura";

    this.ruta.params.subscribe((params) => {
      this.idProyecto = Number(params[paramIdProyecto]);
      this.idOlaInvernal = Number(params[paramIdOlaInveral]);
      this.soloLectura = params[paramSoloLectura] == "true";
    });
    this.cargarInfoProyecto();

    this.habilitarDeshabilitarFormularios();

    this.cargarMaRecaudoAfectado(null);
    this.cargarEtapaProyecto(null);
    this.cargarMaRutaAlterna(null);
    this.cargarMaClasificacionContingencia(null);
    this.cargarMaTipoAfectacion(null);


    if (this.idOlaInvernal != 0) {
      this.cargarInformacionCierre();
      this.cargarAfectacion();
      this.cargarRegistroFotografico();
    }
  }



  /**
   * Metodo para habilitar o deshabilitar los formularios del Registro de Ola Invernal
   */
  habilitarDeshabilitarFormularios() {
    if (this.soloLectura) {
      this.formCierre.disable();
      this.formAfectacion.disable();
      this.formApertura.disable();
      this.formFotografia.disable();
    } else {
      this.formCierre.enable();
      this.formAfectacion.enable();
      this.formApertura.enable();
      this.formFotografia.enable();
    }
  }

  /**
   * Metodo para cargar la información del proyecto seleccionado
   */
  cargarInfoProyecto() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
        var pos = this.proyectoSeleccionado.nombre.search("Atlántico");
        if(pos!=-1){
          this.isAtlantico = true;
        }else{
          this.isAtlantico = false;
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  /**
   * Metodo que carga la ruta alterna
   * @param event
   */
  cargarMaRutaAlterna(event) {
    const filtroLista = [];
    this.tieneRutaAlternas = [];
    this.tieneRutaAlternas.push({ id: true , nombre : "Si" });
    this.tieneRutaAlternas.push({ id: false , nombre : "No" });

    if (this.cierreViaAfectada.tieneRutaAlterna != null) {
      this.cierreViaAfectada.tieneRutaAlternaDTO = this.tieneRutaAlternas.find(item => item.id == this.cierreViaAfectada.tieneRutaAlterna);
      this.formCierre.get('rutaAlternaForm').setValue(this.cierreViaAfectada.tieneRutaAlternaDTO);
    }

    if (event != null && this.tieneRutaAlternas != null) {
      let existeFiltro: boolean;
      for (const filtro of this.tieneRutaAlternas) {
        if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
          filtroLista.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.tieneRutaAlternas = filtroLista;
      }
    }
  }

  /**
   * Metodo que carga los recaudos afectados
   * @param event
   */
  cargarMaRecaudoAfectado(event) {
    const filtroLista = [];
    this.recaudoAfectados = [];
    this.recaudoAfectados.push({ id: true , nombre : "Si" });
    this.recaudoAfectados.push({ id: false , nombre : "No" });

    if (this.cierreViaAfectada.recaudoAfectado != null) {
      this.cierreViaAfectada.recaudoAfectadoDTO = this.recaudoAfectados.find(item => item.id == this.cierreViaAfectada.recaudoAfectado);
      this.formCierre.get('recaudoAfectadoForm').setValue(this.cierreViaAfectada.recaudoAfectadoDTO);
    }

    if (event != null && this.recaudoAfectados != null) {
      let existeFiltro: boolean;
      for (const filtro of this.recaudoAfectados) {
        if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
          filtroLista.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.recaudoAfectados = filtroLista;
      }
    }
  }

  /**
   * Metodo que consulta la información parametrica de la clasificación de contingencia de ola invernal
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaClasificacionContingencia(event) {
    const filtroLista = [];
    return this.olaInvernalService.srvConsultarMaClasificacionContingenciaOlaInvernal()
      .subscribe(
        (result) => {
          this.clasificacionesContingencia = result.listaFrrMaClasificacionContingenciaCierreVia;
          if (this.clasificacionesContingencia === null) {
            this.clasificacionesContingencia = [];
          }

          if (this.cierreViaAfectada.idClasificacionContingencia != null) {
            this.cierreViaAfectada.clasificacionContingenciaDTO = this.clasificacionesContingencia.find(item => item.id == this.cierreViaAfectada.idClasificacionContingencia);
            this.formCierre.get('clasContingForm').setValue(this.cierreViaAfectada.clasificacionContingenciaDTO);
          }

          if (event != null && this.clasificacionesContingencia != null) {
            let existeFiltro: boolean;
            for (const filtro of this.clasificacionesContingencia) {
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroLista.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.clasificacionesContingencia = filtroLista;
            }
          }
        });
  }

  /**
   * Metodo que consulta la información parametrica de la clasificación de contingencia de ola invernal
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarEtapaProyecto(event) {
    const filtroLista = [];
    return this.olaInvernalService.srvConsultarMaEtapaProyectoOlaInvernal()
      .subscribe(
        (result) => {
          this.etapasProyectos = result;
          if (this.etapasProyectos === null) {
            this.etapasProyectos = [];
          }

          if (this.cierreViaAfectada.idEtapa != null) {
            this.cierreViaAfectada.etapaProyectoDTO = this.etapasProyectos.find(item => item.id == this.cierreViaAfectada.idEtapa);
            this.formCierre.get('clasEtapaForm').setValue(this.cierreViaAfectada.etapaProyectoDTO);
          }

          if (event != null && this.etapasProyectos != null) {
            let existeFiltro: boolean;
            for (const filtro of this.etapasProyectos) {
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroLista.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.etapasProyectos = filtroLista;
            }
          }
        });
  }

  calcularLogitudAfectacion(){
    if(this.cierreViaAfectada.distanciaAlPuntoInicial != undefined && this.cierreViaAfectada.distanciaAlPuntoInicial != null &&
    this.cierreViaAfectada.distanciaAlPuntoFinal != undefined && this.cierreViaAfectada.distanciaAlPuntoFinal != null &&
    this.cierreViaAfectada.puntoInicial != undefined && this.cierreViaAfectada.puntoInicial != null &&
    this.cierreViaAfectada.puntoFinal != undefined && this.cierreViaAfectada.puntoFinal != null ){
      let distanciaInicial = Number(this.cierreViaAfectada.distanciaAlPuntoInicial);
      let distanciFinal = Number(this.cierreViaAfectada.distanciaAlPuntoFinal);
      let pkInicial = (Number(this.cierreViaAfectada.puntoInicial) * 1000 ) + distanciaInicial;
      let pkFinal = (Number(this.cierreViaAfectada.puntoFinal) * 1000) + distanciFinal;
      let longitudAfec =  pkFinal- pkInicial;
      this.cierreViaAfectada.longitudAfectacionSTR = longitudAfec / 1000;
    }else{
      this.cierreViaAfectada.longitudAfectacionSTR = null;
    }

  }

  /**
   * Metodo que consulta la información de parametrica de los tipos de afectaciones
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaTipoAfectacion(event) {
    const filtroList = [];
    return this.olaInvernalService.srvConsultarMaTipoAfectacionCierreVia()
      .subscribe(
        (result) => {
          this.tiposAfectacion = result.listaFrrMaTipoAfectacionCierreVia;
          if (this.tiposAfectacion === null) {
            this.tiposAfectacion = [];
          }

          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.tiposAfectacion.length; i++) {
              const filtro = this.tiposAfectacion[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroList.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.tiposAfectacion = filtroList;
            }
          }
        });
  }

  /**
   * Metodo que consulta la información de Ola Invernal registrada por id especificado
   * @returns Objeto de respuesta de tipo FrrViaAfectadaRpt
   */
  cargarInformacionCierre() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarCierreOlaInvernalPorId(this.idOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cierreViaAfectada = result.frrViaAfectada;

            if (this.cierreViaAfectada != null) {
              if (this.cierreViaAfectada.fechaCierre != null) {
                this.cierreViaAfectada.fechaCierre = new Date(this.cierreViaAfectada.fechaCierre);
              }
              if (this.cierreViaAfectada.fechaEstimadaApertura != null) {
                this.cierreViaAfectada.fechaEstimadaApertura = new Date(this.cierreViaAfectada.fechaEstimadaApertura);
              }
              if (this.cierreViaAfectada.fechaRealApertura != null) {
                this.cierreViaAfectada.fechaRealApertura = new Date(this.cierreViaAfectada.fechaRealApertura);
              }
              if (this.cierreViaAfectada.fechaReporte != null) {
                this.cierreViaAfectada.fechaReporte = new Date(this.cierreViaAfectada.fechaReporte);
              }

              if(this.cierreViaAfectada.nombreProyecto.indexOf("Atlántico")!=-1){
                this.isAtlantico = true;
                if (this.cierreViaAfectada.tieneRutaAlterna != null) {
                  this.cargarMaRutaAlterna(null);
                }
              }else{
                this.isAtlantico = false;
              }

              if (this.cierreViaAfectada.recaudoAfectado != null) {
                this.cargarMaRecaudoAfectado(null);
              }
              if (this.cierreViaAfectada.idEtapa != null) {
                this.cargarEtapaProyecto(null);
              }
              if (this.cierreViaAfectada.idClasificacionContingencia != null) {
                this.cargarMaClasificacionContingencia(null);
              }
              if (this.cierreViaAfectada.idClasificacionContingencia != null) {
                this.cargarMaClasificacionContingencia(null);
              }
              if (this.cierreViaAfectada.observacionesApertura == null) {
                this.cierreViaAfectada.observacionesApertura = "";//Este campo no permite null
              }

              this.cierreViaAfectada.longitudAfectacionSTR = this.cierreViaAfectada.longitudAfectacion / 1000;

            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }


  /**
   * Metodo que controla el evento al momento de seleccionar un Municipio
   * @param ubicacionDeptMun
   */
  onSelectMunicipio(ubicacionDeptMun: any) {
    if (ubicacionDeptMun != null && ubicacionDeptMun.municipio != null) {
      this.cierreViaAfectada.idMunicipio = ubicacionDeptMun.municipio.id;
    }
  }

    /**
   * Metodo que controla el evento al momento de seleccionar un recaudo Afectado
   * @param recAfectado
   */
  onSelectRecaudoAfectado(recAfectado: FrrMaRecaudoAfectadoDTO) {
    if (recAfectado != null) {
      this.cierreViaAfectada.recaudoAfectado = recAfectado.id;
    }
  }

      /**
   * Metodo que controla el evento al momento de seleccionar una etapa del proyecto
   * @param etaProyecto
   */
  onSelectEtapaProyecto(etaProyecto: FrrMaEtapaProyectoDTO) {
    if (etaProyecto != null) {
      this.cierreViaAfectada.idEtapa = etaProyecto.id;
    }
  }

    /**
   * Metodo que controla el evento al momento de seleccionar una ruta alterna
   * @param rutaAlter
   */
  onSelectRutaAlterna(rutaAlter: FrrMaRutaAlternaDTO) {
    if (rutaAlter != null) {
      this.cierreViaAfectada.tieneRutaAlterna = rutaAlter.id;
    }
  }

  /**
   * Metodo que controla el evento al momento de seleccionar una Clasificacion de Contingencia
   * @param clasificacionContingencia
   */
  onSelectClasificacionContingencia(clasificacionContingencia: FrrMaClasificacionContingenciaCierreViaDTO) {
    if (clasificacionContingencia != null) {
      this.cierreViaAfectada.idClasificacionContingencia = clasificacionContingencia.id;
    }
  }


  /**
   * Metodo que valida que los campos del formulario de cierre esten completos
   * @returns
   */
  validarFormCierre() {
    let formularioValido = true;
    if (!this.formCierre.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Por favor diligencia los campos marcados con *");
    } else {
      //SI ES VALIDO
      if (this.cierreViaAfectada == null) {
        formularioValido = false;
        this.lanzarMensajeError("El cierre seleccionado es null");
      }
      if (this.cierreViaAfectada.idMunicipio == null || this.cierreViaAfectada.idMunicipio == "") {
        formularioValido = false;
        this.lanzarMensajeError("Debe seleccionar el departamento y municipio");
      }
      if(this.isAtlantico){
        if (this.cierreViaAfectada.tieneRutaAlterna == null || this.cierreViaAfectada.tieneRutaAlterna == undefined) {
          formularioValido = false;
          this.lanzarMensajeError("Debe seleccionar la ruta alterna");
        }
      }

      if (this.cierreViaAfectada.idClasificacionContingencia == null || this.cierreViaAfectada.idClasificacionContingencia == 0) {
        formularioValido = false;
        this.lanzarMensajeError("Debe seleccionar la clasificación de la contingencia");
      }

      if (this.cierreViaAfectada.recaudoAfectado == null || this.cierreViaAfectada.recaudoAfectado == undefined) {
        formularioValido = false;
        this.lanzarMensajeError("Debe seleccionar la afectación del recaudo");
      }
    }
    return formularioValido;
  }

  /**
   * Metodo que valida que los campos del formulario de apertura esten completos
   * @returns
   */
  validarFormApertura() {
    let formularioValido = true;
    if (!this.formApertura.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Por favor diligencia los campos marcados con *");
    } else {
      //SI ES VALIDO
      if (this.cierreViaAfectada == null) {
        formularioValido = false;
        this.lanzarMensajeError("El cierre seleccionado es null");
      }
    }
    return formularioValido;
  }

  /**
   * Metodo que llama al servicio de guardar cierre de Ola Invernal
   */
  guardarCierreOlaInvernal() {
    this.blockedDocument = true;
    if (this.validarFormCierre()) {

      if (this.idOlaInvernal == 0) {
        if (this.cierreViaAfectada.observacionesApertura == null || this.cierreViaAfectada.observacionesApertura == undefined) {
          this.cierreViaAfectada.observacionesApertura = " ";
        }
      }
      this.cierreViaAfectada.idProyecto = this.idProyecto;
      this.cierreViaAfectada.longitudAfectacion = this.cierreViaAfectada.longitudAfectacionSTR *1000;

      this.olaInvernalService.srvGuardarCierreOlaInvernal(this.cierreViaAfectada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              if (this.idOlaInvernal == 0) {
                this.dialogoExitoCierre = true;
              }
              this.idOlaInvernal = result.id;
              this.cargarInformacionCierre();
            } else {
              this.blockedDocument = false;
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.blockedDocument = false;
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    } else {
      this.blockedDocument = false;
    }
  }

  /**
   * Metodo que llama al servicio de guardar cierre de Ola Invernal con la información de apertura
   */
  guardarAperturaOlaInvernal() {
    this.blockedDocument = true;
    if (this.validarFormApertura()) {
      this.olaInvernalService.srvGuardarCierreOlaInvernal(this.cierreViaAfectada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarInformacionCierre();
              this.bntVolver();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    } else {
      this.blockedDocument = false;
    }
  }

  //#region "Registro Afectación"

  /**
   * Metodo que llama al servicio de consulta de afectaciones por cierre de ola invernal
   * @returns Objeto de tipo FrrTipoAfectacionViaDTO[] con el listado de afectaciones
   */
  cargarAfectacion() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarAfectacionesPorIdViaAfectada(this.idOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.listaFrrTipoAfectacionVia = result.listaFrrTipoAfectacionVia;
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que inicializa las variables y el formulario de afectación para ola invernal
   */
  inicializarVariablesAfectacion() {
    this.iniciarlizarDTOAfectacion();
    this.formAfectacion.reset();
    this.formAfectacion.enable();
  }

  /**
   * Metodo que inicializa las variable de afectación para ola invernal
   */
  iniciarlizarDTOAfectacion() {
    this.afectacionViaAfectada = {};
    this.afectacionViaAfectada.idViaAfectada = this.cierreViaAfectada.id;
  }

  /**
   * Metodo que inicializa las variable de afectación para ola invernal y abre el dialog del formulario de afectacion
   */
  irAgregarAfectacion() {
    this.inicializarVariablesAfectacion();
    this.blockedDocument = true;
    this.dialogoGuardarAfectacion = true;
  }

  /**
   * Metodo que gestiona el formulario de afectacion para consulta y consulta de la afectacion
   * @param afectacion Objeto de tipo FrrTipoAfectacionViaDTO para realizar la consulta correspondiente
   */
  irConsultarAfectacion(afectacion: FrrTipoAfectacionViaDTO) {
    this.inicializarVariablesAfectacion();
    this.blockedDocument = true;
    this.formAfectacion.disable();
    this.cargarAfectacionPorId(afectacion.id);
  }

  /**
   * Metodo que gestiona el formulario de afectacion para edicion y consulta de la afectacion, deshabilita los campos si el tipo es peaje porque estos datos son propios del peaje y no se deben modificar
   * @param afectacion Objeto de tipo FrrTipoAfectacionViaDTO para realizar la consulta correspondiente
   */
  irEditarAfectacion(afectacion: FrrTipoAfectacionViaDTO) {
    this.inicializarVariablesAfectacion();
    this.blockedDocument = true;

    this.afectacionViaAfectada = afectacion;
    this.cargarAfectacionPorId(afectacion.id);

    if (this.afectacionViaAfectada.idTipo === EnumFrrMaTipoAfectacion.PJE_TIPO_AFECTACION_PEAJE) {
      this.formAfectacion.controls['tipo'].disable();
      this.formAfectacion.controls['prInicial'].disable();
      this.formAfectacion.controls['prFinal'].disable();
      this.formAfectacion.controls['observaciones'].disable();
    } else {
      this.formAfectacion.enable();
      this.formAfectacion.controls['tipo'].disable();
    }
  }

  /**
   * Metodo que gestiona el formulario de eliminación de afectacion
   * @param afectacion Objeto de tipo FrrTipoAfectacionViaDTO para realizar la eliminacion
   */
  irEliminarAfectacion(afectacion: FrrTipoAfectacionViaDTO) {
    this.inicializarVariablesAfectacion();
    this.afectacionViaAfectada = afectacion;
    this.blockedDocument = true;
    this.dialogoEliminarAfectacion = true;
  }

  /**
   * Metodo que llama al servicio de consulta de afectacion por id y gestiona los datos del formulario de afectacion
   * @param idAfectacion Id de la afectacion a consulatr
   * @returns
   */
  cargarAfectacionPorId(idAfectacion: number) {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarAfectacionPorId(idAfectacion).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            if (result.frrTipoAfectacionVia != null) {
              this.dialogoGuardarAfectacion = true;
              this.afectacionViaAfectada = result.frrTipoAfectacionVia;
              if (this.afectacionViaAfectada.tipo != null) {
                this.afectacionViaAfectada.tipoAfectacionDTO = this.tiposAfectacion.find(item => item.nombre == this.afectacionViaAfectada.tipo);
                this.formAfectacion.get('tipo').setValue(this.afectacionViaAfectada.tipoAfectacionDTO);
              }
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que controla el evento de seleccion del Tipo de afectacion, inicializa el DTO y gestiona el formulario de afectacion
   * @param tipoAfectacion Objeto de tipo FrrMaTipoAfectacionCierreViaDTO
   */
  onSelectTipoAfectacion(tipoAfectacion: FrrMaTipoAfectacionCierreViaDTO) {
    this.iniciarlizarDTOAfectacion();

    if (tipoAfectacion != null) {
      this.afectacionViaAfectada.idTipo = tipoAfectacion.id;
      if (this.afectacionViaAfectada.idTipo === EnumFrrMaTipoAfectacion.PJE_TIPO_AFECTACION_PEAJE) {
        this.formAfectacion.controls['tipo'].disable();
        this.formAfectacion.controls['prInicial'].disable();
        this.formAfectacion.controls['prFinal'].disable();
        this.formAfectacion.controls['observaciones'].disable();
      }
    }
  }


  /**
   * Metodo que controla el evento de cierre del dialogo e inicializa las variables del formulario de afectacion
   */
  cancelarDialogoAfectacion() {
    this.inicializarVariablesAfectacion();
    this.blockedDocument = false;
    this.dialogoGuardarAfectacion = false;
  }

  /**
   * Metodo que valida que los campos del formulario de afectacion esten completos
   * @returns Verdadero sí la información esta correcta o Falso en caso contrario
   */
  validarFormAfectacion() {
    let formularioValido = true;
    if (!this.formAfectacion.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Por favor diligenciar los campos marcados con *");
    } else {
      if (this.afectacionViaAfectada.idTipo == null || this.afectacionViaAfectada.idTipo == 0) {
        formularioValido = false;
        this.lanzarMensajeError("Debe seleccionar el tipo de afectación");
      }
      if (this.afectacionViaAfectada.idTipo == null || this.afectacionViaAfectada.idTipo == undefined
        || this.afectacionViaAfectada.puntoInicial == null || this.afectacionViaAfectada.puntoInicial == undefined
        || this.afectacionViaAfectada.puntoFinal == null || this.afectacionViaAfectada.puntoFinal == undefined
        || this.afectacionViaAfectada.observaciones == null || this.afectacionViaAfectada.observaciones == undefined) {
        formularioValido = false;
        this.lanzarMensajeError("Por favor diligenciar los campos marcados con *");
      }
    }
    return formularioValido;
  }

  /**
   * Metodo que llama al servicio de guardar cierre afectacion por ola invernal
   */
  guardarAfectacion() {
    this.dialogoGuardarAfectacion = false;

    if (this.validarFormAfectacion()) {
      this.afectacionViaAfectada.idViaAfectada = this.cierreViaAfectada.id;

      this.olaInvernalService.srvGuardarAfectacionViaAfectada(this.afectacionViaAfectada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarAfectacion();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  /**
   * Metodo que llamar el servicio de eliminación de una afectacion seleccionada por su id
   */
  eliminarAfectacion() {
    this.dialogoEliminarAfectacion = false;
    if (this.afectacionViaAfectada != null && this.afectacionViaAfectada.id != 0) {
      this.olaInvernalService.srvEliminarAfectacionViaAfectadaPorId(this.afectacionViaAfectada.id).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarAfectacion();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  //#endregion "Afectación"

  //#region "Registro Fotografico"

  /**
   * Metodo que llama al servicio de consulta de imagenes por ola invernal
   * @returns Listado de registro fotograficos
   */
  cargarRegistroFotografico() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarImagenesPorIdViaAfectada(this.idOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.listaFrrImagenViaAfectada = result.listaFrrImagenViaAfectada;
            if (this.listaFrrImagenViaAfectada != null && this.listaFrrImagenViaAfectada.length > 0) {
              this.cantidadActualRegistrosFotograficos = this.listaFrrImagenViaAfectada.length;
            } else {
              this.cantidadActualRegistrosFotograficos = 0;
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
    * Metodo que inicializa las variable de fotografia para ola invernal
    */
  inicializarVariablesFotografia() {
    this.imagenViaAfectada = {};
    this.imagenViaAfectada.idViaAfectada = this.cierreViaAfectada.id;
    this.archivos = [];
    this.formFotografia.reset();
    this.formFotografia.enable();

  }

  /**
   * Metodo que inicializa las variable de fotografia para ola invernal y abre el dialog del formulario de fotografia
   */
  irSubirFotografia() {
    if (this.cantidadActualRegistrosFotograficos != this.cantidadMaximaRegistrosFotograficos) {
      this.inicializarVariablesFotografia();
      this.blockedDocument = true;
      this.dialogoGuardarFotografia = true;
    } else {
      this.lanzarMensajeError("La cantidad máxima de registros fotograficos es " + this.cantidadMaximaRegistrosFotograficos);
    }
  }

  /**
   * Metodo que controla el evento de cierre del dialogo e inicializa las variables del formulario de fotografia
   */
  cancelarDialogoSubirFotografia() {
    this.inicializarVariablesFotografia();
    this.blockedDocument = false;
    this.dialogoGuardarFotografia = false;
  }

  /**
   * Metodo que gestiona el formulario de fotografia para consulta y consulta de la fotografia por id
   * @param foto Fotografia seleccionada para consultar
   */
  irConsultarFotografia(foto: FrrImagenViaAfectadaDTO) {
    this.inicializarVariablesFotografia();
    this.blockedDocument = true;
    this.formFotografia.disable();
    this.cargarRegistroFotograficoPorId(foto.id);
  }

  /**
   * Metodo que gestiona el formulario de fotografia para edicion y consulta de la fotografia por id
   * @param foto Fotografia seleccionada para consultar
   */
  irEditarFotografia(foto: FrrImagenViaAfectadaDTO) {
    this.inicializarVariablesFotografia();
    this.blockedDocument = true;
    this.formFotografia.enable();
    this.cargarRegistroFotograficoPorId(foto.id);
  }

  /**
   * Metodo que gestiona el formulario de eliminación de fotografia
   * @param foto
   */
  irEliminarFotografia(foto: FrrImagenViaAfectadaDTO) {
    this.inicializarVariablesFotografia();
    this.imagenViaAfectada = foto;
    this.blockedDocument = true;
    this.dialogoEliminarFotografia = true;
  }

  /**
   * Metodo que valida que los campos del formulario de fotografia esten completos
   * @returns Verdadero sí la información esta correcta o Falso en caso contrario
   */
  validarFormFotografia() {
    let formularioValido = true;
    if (!this.formFotografia.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Por favor diligencia los campos marcados con *");
    } else {
      if (this.cierreViaAfectada == null || this.cierreViaAfectada.id == null || this.cierreViaAfectada.id == 0) {
        formularioValido = false;
        this.lanzarMensajeError("El cierre seleccionado es null");
      }
      if (this.archivos == null || this.archivos[0] == null || this.archivos[0].nombreCompleto == null || this.archivos[0].nombreCompleto == '') {
        formularioValido = false;
        this.lanzarMensajeError("Debe adjuntar la fotografía");
      }
    }
    return formularioValido;
  }

  /**
    * Metodo que llama al servicio de guardar fotografia de la vía afectada por id ola invernal
    */
  guardarFotografia() {
    this.dialogoGuardarFotografia = false;

    if (this.validarFormFotografia()) {
      this.imagenViaAfectada.idViaAfectada = this.cierreViaAfectada.id;
      this.imagenViaAfectada.ruta = this.archivos[0].nombreCompleto;

      this.olaInvernalService.srvGuardarImagenViaAfectada(this.imagenViaAfectada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarRegistroFotografico();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  /**
   * Metodo que llama al servicio de consulta de fotografia por id y gestiona los datos del formulario de fotografia
   * @param idImagenOlaInvernal Id de la fotografia a consultar
   */
  cargarRegistroFotograficoPorId(idImagenOlaInvernal: number) {
    this.olaInvernalService.srvConsultarImagenOlaInvernalPorId(idImagenOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.imagenViaAfectada = result.frrImagenViaAfectada;
            if (this.imagenViaAfectada != null) {
              this.dialogoGuardarFotografia = true;
              if (this.imagenViaAfectada.fechaFotografia != null) {
                this.imagenViaAfectada.fechaFotografia = new Date(this.imagenViaAfectada.fechaFotografia);
              }
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que llamar el servicio de eliminación de una fotografia seleccionada por su id
   */
  eliminarFotografia() {
    this.dialogoEliminarFotografia = false;
    if (this.imagenViaAfectada != null && this.imagenViaAfectada.id != 0) {
      this.olaInvernalService.srvEliminarImagenViaAfectadaPorId(this.imagenViaAfectada.id).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarRegistroFotografico();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }
  //#endregion "Registro Fotografico"

  //#region "Navegación"
  bntVolver() {
    const ruta = "/ferrocarriles/infGeneral/proyectoHome/" + this.idProyecto + "/ola-invernal-ferreo-registro/" + this.idProyecto;
    this.router.navigate([ruta]);
  }
  //#endregion "Navegación"

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumFrrMaTipoAfectacion() {
    return EnumFrrMaTipoAfectacion;
  }

}
