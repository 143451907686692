import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { ProyectoDTO } from '../componentes-comunes.dto';
import { ComponentesComunesService } from '../componentes-comunes.service';

@Component({
  selector: 'app-seleccionar-proyecto',
  templateUrl: './seleccionar-proyecto.component.html',
  styleUrls: ['./seleccionar-proyecto.component.sass']
})
export class SeleccionarProyectoComponent implements OnInit {

  public proyectoDTOList: ProyectoDTO[];
  public proyectoSeleccionado: ProyectoDTO;
  proyectoDTOSelectedList:any;
  // Usamos el decorador Output
  @Output() OutputProyectoSeleccionado = new EventEmitter();

  constructor(private route: Router, private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService) { }

  ngOnInit() {
    this.listarProyectos();
  }

  listarProyectos() {
    return this.componentesComunesService.listarProyectos().subscribe(data => {
      this.proyectoDTOList = data;
    });
  }

  rowSelectProyecto(_proyectoSeleccionado: ProyectoDTO) {
    this.proyectoSeleccionado = _proyectoSeleccionado;
    //Emitir el proyectoSeleccionado afuera del componente
    this.OutputProyectoSeleccionado.emit(this.proyectoSeleccionado);
  }

}
