import { removeSummaryDuplicates } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InformacionGeneralService } from 'src/app/informacion-general/informacion-general.service';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { InformacionGeneral } from './informacionGeneral';
import { InformacionGeneralProyectoService } from './informacion-general-proyecto.service';
import { Generacion } from 'src/app/informacion-general/generacion';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
    selector: 'app-informacion-general-proyecto',
    templateUrl: './informacion-general-proyecto.component.html',
    styleUrls: ['./informacion-general-proyecto.component.sass']
})

export class InformacionGeneralProyectoComponent implements OnInit {

    informacionGeneral: InformacionGeneral = {};
    informacionGeneralGet: any = [];
    generaciones: any = [];
    filtroGeneraciones: any[];
    olas: any = [];
    filtroOlas: any[];
    olaSeleccionada: any;
    generacionSeleccionada: any;
    idProyecto: any;
    blockedDocument: boolean;
    visibleDialogoExito: boolean = false;

    constructor(private messageService: MessageService, private parametricasService: ParametricasService, private informacionGeneralProyectoService: InformacionGeneralProyectoService,
        private route: ActivatedRoute, private ruta: Router, private principalComponent: PrincipalComponent) { }


    ngOnInit() {
        this.informacionGeneral = new InformacionGeneral();
        this.route.params.subscribe(params => {
            this.idProyecto = params.id;
            this.cargarInformacionGeneral(this.idProyecto);
        });

        /* if (this.informacionGeneral.idGeneracion == null) {
            this.generacionSeleccionada = new Generacion(null, 0);
        } */


    }

    cargarInformacionGeneral(idProyecto: number) {
        forkJoin(
            this.informacionGeneralProyectoService.obtenerInformacionGeneral(idProyecto)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                //Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {
                    this.informacionGeneralGet = result.informacionGeneralProyecto;
                    this.informacionGeneral = this.informacionGeneralGet;
                    this.olaSeleccionada = this.informacionGeneral.idOla;
                    this.generacionSeleccionada = this.informacionGeneral.idGeneracion;
                }
                else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                console.error(error);
                this.principalComponent.cargarErrorServicio(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
                this.blockedDocument = false;
            },
            () => {
                //Desbloquear pantalla
                this.blockedDocument = false;
            }
        );

    }

    guardarInformacionGeneral() {

        this.informacionGeneral.idOla = this.olaSeleccionada;
        this.informacionGeneral.idGeneracion = this.generacionSeleccionada;
        forkJoin(
            this.informacionGeneralProyectoService.guardarInformacionGeneral(this.informacionGeneral, this.idProyecto)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                //Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {
                    this.visibleDialogoExito = true;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                console.error(error);
                this.principalComponent.cargarErrorServicio(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
                this.blockedDocument = false;
            },
            () => {
                //Desbloquear pantalla

            }
        );

    }

    aceptarDialogoExitoProyecto() {
        this.visibleDialogoExito = false;
    }

    cargarGeneraciones(event) {
        this.filtroGeneraciones = [];
        return this.parametricasService.getGeneraciones().subscribe((data: {}) => {
            this.generaciones = data;
            let existeFiltro: boolean;
            for (let i = 0; i < this.generaciones.length; i++) {
                let filtroGeneracion = this.generaciones[i];
                let item: string = String(filtroGeneracion.nombre);
                if (item.toLowerCase().includes(event.query.toLowerCase())) {
                    this.filtroGeneraciones.push(filtroGeneracion);
                    existeFiltro = true;
                }
            }
            if (existeFiltro) {
                this.generaciones = this.filtroGeneraciones;
            }
        });
    }

    cargarFiltroGeneraciones(event) {
        this.filtroGeneraciones = [];
        for (let i = 0; i < this.generaciones.length; i++) {
            let filtro = this.generaciones[i];
            let item: string = String(filtro.nombre);
            if (item.toLowerCase().includes(event.query.toLowerCase())) {
                this.filtroGeneraciones.push(filtro);
            }
        }
    }

    cargarOlas(event) {
        this.filtroOlas = [];
        return this.parametricasService.getOlas().subscribe((data: {}) => {
            this.olas = data;
            let existeFiltro: boolean;
            for (let i = 0; i < this.olas.length; i++) {
                let filtro = this.olas[i];
                let item: string = String(filtro.nombre);
                if (item.toLowerCase().includes(event.query.toLowerCase())) {

                    this.filtroOlas.push(filtro);
                    existeFiltro = true;
                }
            }
            if (existeFiltro) {
                this.olas = this.filtroOlas;
            }
        });
    }

    cargarFiltroOlas(event) {
        this.filtroOlas = [];
        for (let i = 0; i < this.olas.length; i++) {
            let filtro = this.olas[i];
            let item: string = String(filtro.nombre);
            if (item.toLowerCase().includes(event.query.toLowerCase())) {
                this.filtroOlas.push(filtro);
            }
        }
    }


}
