import { Component, OnInit } from '@angular/core'; import { CtoReporteCxpContratoPlanPagoCuentaCobroDTO, CtoReporteCxpContratoPlanPagoCuentaCobroFiltroDTO } from '../../contratacion-dto/cto-reporte-cxp-contrato-plan-pago-cuenta-cobro.dto';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { ReportesCuentasCobroCxpService } from '../reporte-contratacion-cuentas-cobro.service';
import { ContratacionService } from '../../contratacion.service';
import { CtoPasoInformeDTO } from '../../contratacion-dto/cto-paso-informe';

@Component({
  selector: 'app-cto-reporte-cxp-planes-pago-cuentas-cobro',
  templateUrl: './cto-reporte-cxp-planes-pago-cuentas-cobro.component.html',
  styles: []
})
export class CtoReporteCxpPlanesPagoCuentasCobroComponent implements OnInit {

  // Informacion del datatable
  reporteCxpPlanesPago: CtoReporteCxpContratoPlanPagoCuentaCobroDTO[] = [];
  totalRecords: number;
  loading: boolean;

  //Filtros
  reporteCxpPlanesPagoFiltro: CtoReporteCxpContratoPlanPagoCuentaCobroFiltroDTO;
  pasosInformeListFiltro: CtoPasoInformeDTO[];

  formReporteCxpPlanesPagoFiltro;

  DATATABLE_CANTIDAD_REGISTROS = 10;
  DATATABLE_ASCDESC = 1;//1=asc, !=1 desc
  DATATABLE_CAMPO_ORDEN = 'idPlanPago';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private datepipe: DatePipe,
    private principalComponent: PrincipalComponent,
    private reportesContratoCuentasCobroService: ReportesCuentasCobroCxpService,
    private contratacionService: ContratacionService,
    private parametricasService: ParametricasService
  ) {
    const regexMayorACero = "^[1-9][0-9]*$";


    this.formReporteCxpPlanesPagoFiltro = formBuilder.group({
      formVigenciaFiltro: [null, [Validators.min(1), Validators.max(9999), Validators.minLength(1), Validators.maxLength(4), Validators.pattern(regexMayorACero)]],
      formEstadoInformeFiltro: [null, null],
      formFechaFinPlanPagoFechaDesdeFiltro: [null, null],
      formFechaFinPlanPagoFechaHastaFiltro: [null, null]
    });
  }

  /**
   * Metodo que incializa invoca el listado de parametricas
   */
  ngOnInit() {
    this.inicializarVariablePasoInformeFiltro();
    this.reporteCxpPlanesPagoFiltro = {};
    this.inicializarVariablesFiltros();
  }

  /**
   * Metodo utilizado para la paginación y el uso de filtros
   * @param event 
   */
  loadDataLazy(event: LazyLoadEvent) {
    if (this.formReporteCxpPlanesPagoFiltro.valid) {
      this.resetearPaginacionVariablesFiltros();
      this.cargarReporteCxpPlanesPago(event.first, event.rows, ((event.sortField === null || event.sortField === undefined) ? this.reporteCxpPlanesPagoFiltro.campoOrden : event.sortField), ((event.sortOrder === null || event.sortOrder === undefined) ? this.reporteCxpPlanesPagoFiltro.ascDesc : event.sortOrder));
    }
  }

  /**
   * Metodo consultar la información del datatable de acuerdo a los filtros definidos   
   */
  buscar() {
    if (this.validarFormularioFiltros()) {
      // Validar filtros ya que el disabled se puede cambiar
      this.resetearPaginacionVariablesFiltros();
      this.cargarReporteCxpPlanesPago(this.reporteCxpPlanesPagoFiltro.filaDesde, this.reporteCxpPlanesPagoFiltro.cantidadRegistros, this.reporteCxpPlanesPagoFiltro.campoOrden, this.reporteCxpPlanesPagoFiltro.ascDesc);
    }
  }

  /**
   * Metodo para inciializar las variables del modelo usadas para los filtros selecionados
   */
  inicializarVariablesFiltros() {
    this.reporteCxpPlanesPagoFiltro.vigencia = null;
    this.reporteCxpPlanesPagoFiltro.idPasoInforme = null;
    this.reporteCxpPlanesPagoFiltro.pasoInformeSeleccionado = null;
    this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaDesdeFiltro = null;
    this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaHastaFiltro = null;

    this.resetearPaginacionVariablesFiltros();
  }

  /**
   * Metodo para inciializar las variables del modelo usadas para la paginación del Datatable 
   */
  resetearPaginacionVariablesFiltros() {
    this.reporteCxpPlanesPagoFiltro.filaDesde = 0;
    this.reporteCxpPlanesPagoFiltro.cantidadRegistros = this.DATATABLE_CANTIDAD_REGISTROS;
    this.reporteCxpPlanesPagoFiltro.buscar = null;
    this.reporteCxpPlanesPagoFiltro.campoOrden = this.DATATABLE_CAMPO_ORDEN;
    this.reporteCxpPlanesPagoFiltro.ascDesc = this.DATATABLE_ASCDESC;
  }

  /**
   * Metodo que valida que el formulario de filtros este correctamente diligenciado
   * @returns Verdado o Falso
   */
  validarFormularioFiltros() {
    let formularioValido = true;
    if (!this.formReporteCxpPlanesPagoFiltro.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Para realizar la búsqueda debe indicar alguno de los filtros");
    }
    if (this.reporteCxpPlanesPagoFiltro.pasoInformeSeleccionado != null && this.reporteCxpPlanesPagoFiltro.pasoInformeSeleccionado.id == null) {
      formularioValido = false;
      this.lanzarMensajeError("Estado del informe: Seleccione un estado valido");
    }
    //Fecha Fin de Plan Pago
    if ((this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaDesdeFiltro != null && this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaHastaFiltro == null)
      || (this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaDesdeFiltro == null && this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaHastaFiltro != null)) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha Fin de Plan Pago: Debe seleccionar el rango de fechas");
    }
    if (this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaDesdeFiltro != null && this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaHastaFiltro != null
      && this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaDesdeFiltro > this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaHastaFiltro) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha Fin de Plan Pago: La fecha inicio no puede ser mayor a la fecha fin");
    }
    return formularioValido;
  }

  /**
   * Metodo que inicializa el listado de CtoPasoInforme con 2 estados ficticios por defecto
   */
  inicializarVariablePasoInformeFiltro(): CtoPasoInformeDTO[] {
    const pasosInformeFicticiosListFiltro = [
      {
        id: 41,
        nombre: 'Sin Iniciar'
      },
      {
        id: 42,
        nombre: 'En Borrador'
      }];

    return pasosInformeFicticiosListFiltro;
  }

  /**
   * Metodo utilizado para limpiar los campos del formulario
   */
  limpiarFiltros() {
    this.inicializarVariablesFiltros();
    this.formReporteCxpPlanesPagoFiltro.controls.formFechaFinPlanPagoFechaHastaFiltro.clearValidators();
    this.formReporteCxpPlanesPagoFiltro.reset();
    this.cargarReporteCxpPlanesPago(this.reporteCxpPlanesPagoFiltro.filaDesde, this.reporteCxpPlanesPagoFiltro.cantidadRegistros, this.reporteCxpPlanesPagoFiltro.campoOrden, this.reporteCxpPlanesPagoFiltro.ascDesc);
  }

  /**
   * Metodo que maneja el evento de selección de @formFechaFinPlanPagoFechaDesdeFiltro se obliga a selesccionar la fecha @formFechaFinPlanPagoFechaHastaFiltro
   */
  onSelectFormFechaFinPlanPagoFechaDesdeFiltro() {
    if (this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaDesdeFiltro == null) {
      this.formReporteCxpPlanesPagoFiltro.controls.formFechaFinPlanPagoFechaHastaFiltro.clearValidators();
    } else {
      this.formReporteCxpPlanesPagoFiltro.controls.formFechaFinPlanPagoFechaHastaFiltro.setValidators([Validators.required]);
    }
    this.formReporteCxpPlanesPagoFiltro.controls.formFechaFinPlanPagoFechaHastaFiltro.updateValueAndValidity();
  }

  /**
   * Metodo que maneja el evento de selección de @formEstadoInformeFiltro
   */
  onSelectFormEstadoInformeFiltro() {
    this.reporteCxpPlanesPagoFiltro.idPasoInforme = (this.reporteCxpPlanesPagoFiltro.pasoInformeSeleccionado != null && this.reporteCxpPlanesPagoFiltro.pasoInformeSeleccionado.id != null ? this.reporteCxpPlanesPagoFiltro.pasoInformeSeleccionado.id : null);
  }

  /**
   * Metodo que invoca el servicio para consultar los pasos de informe de tipo CtoPasoInforme de las cuentas de cobro
   * @param event Evento de autocompletar
   */
  cargarPasosInforme(event) {
    const pasosInformeListFiltroAutoCompletar = this.inicializarVariablePasoInformeFiltro();
    this.contratacionService.getPasosInforme().subscribe(
      result => {
        if (result != null) {
          if (result === null) {
            this.pasosInformeListFiltro = this.inicializarVariablePasoInformeFiltro();
          }
          if (event != null) {
            let existeFiltro: boolean;
            result.forEach(
              item => {
                if (String(item.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                  pasosInformeListFiltroAutoCompletar.push(item);
                  existeFiltro = true;
                }
              });
            if (existeFiltro) {
              //Merging Two Arrays into a New Array
              this.pasosInformeListFiltro = [...pasosInformeListFiltroAutoCompletar];
            }
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO, error);
        this.principalComponent.cargarErrorServicio(error);
      },
    );
  }

  /**
   * Metodo que invoca el servicio para consultar los planes de pagos y su información con las cuentas de cobro        
     * @param filaDesde Valor Opcional >0 para consultar desde una fila en particular
     * @param cantidadRegistros Valor Opcional >0 para consultar X cantidad de registros
     * @param buscar Valor Opcional para consultar X dato dentro de la informacíon, se tiene definido en BD
     * @param campoOrden Valor Opcional para ordenar la información, se tiene definido en BD
     * @param ascDesc Valor Opcional númerico para ordenar los valores del listado a consultar de forma ascendente o descente
   */
  private cargarReporteCxpPlanesPago(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.loading = true;
    this.reporteCxpPlanesPago = [];

    this.reporteCxpPlanesPagoFiltro.filaDesde = filaDesde;
    this.reporteCxpPlanesPagoFiltro.cantidadRegistros = cantidadRegistros;
    this.reporteCxpPlanesPagoFiltro.buscar = null;
    this.reporteCxpPlanesPagoFiltro.campoOrden = campoOrden;
    this.reporteCxpPlanesPagoFiltro.ascDesc = ascDesc;

    this.reportesContratoCuentasCobroService.srvGetConsultarReporteCxpPlanesPagoCuentasCobro(
      this.reporteCxpPlanesPagoFiltro.vigencia,
      this.reporteCxpPlanesPagoFiltro.idPasoInforme,
      this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaDesdeFiltro,
      this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaHastaFiltro,
      this.reporteCxpPlanesPagoFiltro.filaDesde,
      this.reporteCxpPlanesPagoFiltro.cantidadRegistros,
      this.reporteCxpPlanesPagoFiltro.buscar,
      this.reporteCxpPlanesPagoFiltro.campoOrden,
      this.reporteCxpPlanesPagoFiltro.ascDesc
    ).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.reporteCxpPlanesPago = result.reportePlanesPagoCuentasCobro;
            this.totalRecords = this.reporteCxpPlanesPago != null && this.reporteCxpPlanesPago[0] != null ? this.reporteCxpPlanesPago[0].totalRegistros : 0;
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Metodo utilizado para invocar la funcionalidad de exportar excel la información de registros de plan de pagos
   */
  exportExcel() {
    this.loading = true;

    this.reportesContratoCuentasCobroService.srvGetConsultarReporteCxpPlanesPagoCuentasCobro(
      this.reporteCxpPlanesPagoFiltro.vigencia,
      this.reporteCxpPlanesPagoFiltro.idPasoInforme,
      this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaDesdeFiltro,
      this.reporteCxpPlanesPagoFiltro.fechaFinPlanPagoFechaHastaFiltro,
      null,//Para ser expotado en Excel se requieren todos los registros consultados, por eso se deja null
      null,//Para ser expotado en Excel se requieren todos los registros consultados, por eso se deja null
      this.reporteCxpPlanesPagoFiltro.buscar,
      this.reporteCxpPlanesPagoFiltro.campoOrden,
      this.reporteCxpPlanesPagoFiltro.ascDesc
    ).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            const reporteCxpPlanesPagoExcel = result.reportePlanesPagoCuentasCobro;
            // check array does not exist, is not an array, or is empty
            if (!Array.isArray(reporteCxpPlanesPagoExcel) || !reporteCxpPlanesPagoExcel.length) {
              this.lanzarMensajeError("No hay registros suficientes para exportar en Excel");
              return;
            }
            //flatten objects
            const rows = reporteCxpPlanesPagoExcel.map(row => ({
              numeroContrato: row.numeroContrato,
              idTipoDocumento: row.idTipoDocumentoContratista,
              numeroDocumentoContratista: row.numeroDocumentoContratista,
              nombreContratista: row.nombreContratista,
              supervisoresActuales: row.supervisoresActuales,
              fechaInicioPlanPago: this.datepipe.transform(row.fechaInicioPlanPago, 'dd/MM/yyyy'),
              fechaFinPlanPago: this.datepipe.transform(row.fechaFinPlanPago, 'dd/MM/yyyy'),
              valorPlanPago: row.valorPlanPago,
              esResponsableIva: (row.esResponsableIva ? 'Sí' : 'No'),
              estaObligadoFacturar: (row.esResponsableIva ? '' : (row.estaObligadoFacturar == null ? 'Sin Información' : row.estaObligadoFacturar ? 'Sí' : 'No')),
              estado: row.estado
            }));

            //generate worksheet and workbook
            import('xlsx')
              .then(xlsx => {
                const headings = [["No. Contrato", "Tipo documento", "No. Documento", "Contratista", "Supervisor (es)", "Fecha Inicio Plan Pago", "Fecha Fin Plan Pago", "Valor Periodo de Pago", "¿Es Responsable de Iva?", "¿Esta Obligado a Facturar?", "Estado"]];
                const worksheet = xlsx.utils.json_to_sheet(rows);
                /* fix headers */
                xlsx.utils.sheet_add_aoa(worksheet, headings);
                const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                this.saveAsExcelFile(excelBuffer, 'ctoReporteCxpPlanesPagoCuentasCobro');
              });

          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Metodo utilizado para descargar el archivo en formato xlsx generado
   * @param buffer 
   * @param fileName Nombre del archivo descargado
   */
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  /**
  * Metodo que redirige a la pagina principal de reportes de central de pagos
  */
  bntVolver() {
    const ruta = "/contratacionSupervisionHome/reportesCuentasCobroCxpHome";
    this.router.navigate([ruta]);
  }

  /**
  * Lanza mensaje de informacion
  */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
