import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { AlmacenamientoService } from '../../../comun/almacenamiento/almacenamiento.service';
import { InformacionGeneralService } from '../../../informacion-general/informacion-general.service';
import { NuevaTarifaPeajesService } from './nueva-tarifa.service';
import { PjeDetalleTarifa } from './PjeDetalleTarifa';
import { PjeEncabezadoTarifa } from './PjeEncabezadoTarifa';
import { PjeMaTarifa } from './PjeMaTarifa';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';



@Component({
    selector: 'app-nueva-tarifa',
    templateUrl: './nueva-tarifa.component.html',
    styleUrls: ['./nueva-tarifa.component.sass']
})

export class NuevaTarifaPeajesComponent implements OnInit {

    nombreProyecto: string;
    idProyecto: number;
    idPeaje: number;
    proyecto: any;
    fechaMaxima: Date;
    fechaInicioTarifaConvert: Date;
    fechaResolucionConvert: Date;
    nombrePeaje: string;
    esLectura: boolean;
    visibleConfirmarPublicarTarifa = false;
    visibleConfirmarEliminarRecaudosAsociados = false;
    visibleConfirmarCancelarBorrador = false;
    mensajeExito: string;


    // Variables Encabezado
    encabezadoGet: any = [];
    encabezado: PjeEncabezadoTarifa;
    visibleDialogoSubirArchivo = false;
    nombreArchivo: string;
    rutaArchivo: string;
    archivos: any = [];
    deroga: boolean;

    // Variables Detalle (Categorías)
    peajeMaTarifasList: PjeMaTarifa[] = [];
    private categorias: any = [];

    visibleDialogoAgregarCategoria = false;
    categoriaSeleccionada: PjeMaTarifa;
    valorCategoria: number;
    visibleConfirmarEliminarTarifa = false;
    idTarifaEliminar: number;
    detalleCategoria: PjeDetalleTarifa;

    // varaiables edicion
    idEncabezado: number;
    visibleDialogoExito = false;
    visibleEditarTarifa = false;
    tarifaEditable: PjeDetalleTarifa;

    // variables documentos
    visibleDialogoDocumentos = false;
    fechaExpedicionConvert: Date;
    idTipoSoporte: string;
    private documentos: any = [];
    displayedColumnsDoc: any[];
    visibleConfirmarEliminarDocumento = false;
    idDocumentoEliminar: number;
    modulo: string;
    filtroTiposSoporte: any[];
    tiposSoporte: any = [];
    tipoSoporteSeleccionado: any;
    private documentosHistoricos: any = [];
    displayedColumnsDocHistorico: any[];
    visibleTablaBuscar = false;
    visibleConfirmarAdjuntarDocumento = false;
    idDocumentoSeleccionado = 0;
    blockedDocument = false;
    visibleDocumentoExiste = false;
    idSoporteAdjuntar: number;

    // variables recaudo
    private recaudoPorEliminar: any = [];
    displayedColumnsRecaudoPorEliminar: any[];


    constructor(private nuevaTarifaService: NuevaTarifaPeajesService, private infoService: InformacionGeneralService, private router: Router, private principalComponent: PrincipalComponent,
        private route: ActivatedRoute, private messageService: MessageService, public datepipe: DatePipe, private svc: AlmacenamientoService) { }

    ngOnInit() {
        this.esLectura = false;

        this.fechaMaxima = new Date();
        this.encabezado = new PjeEncabezadoTarifa();
        this.tarifaEditable = new PjeDetalleTarifa();

        this.route.params.subscribe(params => {
            this.idProyecto = params.id;
            this.idPeaje = params.idPeaje;
            this.esLectura = (params.esLectura === 'true');
            this.idEncabezado = params.idEncabezado;
            this.cargaConfiguracion();
        });


        this.cargarProyectoCarretero(this.idProyecto);


        this.displayedColumnsDoc = [
            { field: 'tipoDocumento', header: 'TipoDocumento' },
            { field: 'fechaExpedicion', header: 'Fecha Expedicion' },
            { field: 'numeroDocumento', header: 'Numero Documento' }
        ];

        this.displayedColumnsDocHistorico = [
            { field: 'tipoDocumento', header: 'TipoDocumento' },
            { field: 'fechaExpedicion', header: 'Fecha Expedicion' },
            { field: 'numeroDocumento', header: 'Numero Documento' }
        ];

        this.displayedColumnsRecaudoPorEliminar = [
            { field: 'peaje', header: 'Peaje' },
            { field: 'fechaDesdeReporte', header: 'Fecha Desde' },
            { field: 'fechaHastaReporte', header: 'Fecha Hasta' }
        ];

    }



    cargarProyectoCarretero(id: number) {
        this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
            this.proyecto = data;
            this.nombreProyecto = this.proyecto.nombre;
        }, error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
    }

    validaDocumentosExistentes() {
        this.documentosHistoricos = [];
        this.blockedDocument = true;

        if (this.tipoSoporteSeleccionado != null) {
            this.idDocumentoSeleccionado = this.tipoSoporteSeleccionado.idTipoDocumentoAsociado;
            this.idSoporteAdjuntar = this.tipoSoporteSeleccionado.id;
        }

        let fechaExpedicionDoc: string;
        if (this.fechaExpedicionConvert != null) {
            fechaExpedicionDoc = this.datepipe.transform(this.fechaExpedicionConvert, 'yyyy') + this.datepipe.transform(this.fechaExpedicionConvert, 'MM') + this.datepipe.transform(this.fechaExpedicionConvert, 'dd');
        }

        forkJoin(
            this.nuevaTarifaService.consultaDocumentosHistoricos(fechaExpedicionDoc, this.encabezado.numeroDocumento, this.idDocumentoSeleccionado, 1)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio !== null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION || result.respuestaServicio.codigoSalida === 10) {
                    if (result.documentos != null) {
                        this.visibleDialogoDocumentos = false;
                        // Si el codigo de respuesta es 10, el documento ya existe y no permite agregarlo.
                        if (result.respuestaServicio.codigoSalida === 10) {
                            this.visibleDocumentoExiste = true;
                        } else {
                            this.visibleConfirmarAdjuntarDocumento = true;
                        }
                        this.visibleTablaBuscar = true;
                        this.documentosHistoricos = result.documentos;
                    } else {
                        this.visibleTablaBuscar = false;
                        this.irVerDialogoSubirArchivo();
                    }
                } else {

                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;

            }
        );

    }

    irValidarDocumentos() {
        this.visibleConfirmarAdjuntarDocumento = false;
        this.visibleDialogoDocumentos = true;
        this.visibleDocumentoExiste = false;
    }

    buscarDocumentosExistentes() {

        this.blockedDocument = true;

        if (this.fechaExpedicionConvert == null && this.encabezado.numeroDocumento == null && this.tipoSoporteSeleccionado == null) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar al menos un filtro para realizar la búsqueda', life: 10000 });
        } else {
            let fechaExpedicionDoc: string;

            // formatea fecha
            if (this.fechaExpedicionConvert != null) {
                fechaExpedicionDoc = this.datepipe.transform(this.fechaExpedicionConvert, 'yyyy') + this.datepipe.transform(this.fechaExpedicionConvert, 'MM') + this.datepipe.transform(this.fechaExpedicionConvert, 'dd');
            }
            // si tipo de soporte no es nulo setea variabla documento seleccionado
            if (this.tipoSoporteSeleccionado != null) {
                this.idDocumentoSeleccionado = this.tipoSoporteSeleccionado.idTipoDocumentoAsociado;
            }
            // formatea número resolucion
            if (this.encabezado.numeroDocumento != null) {
                if (this.encabezado.numeroDocumento.trim().length === 0) {
                    this.encabezado.numeroDocumento = null;
                }
            }
            forkJoin(
                this.nuevaTarifaService.consultaDocumentosHistoricos(fechaExpedicionDoc, this.encabezado.numeroDocumento, this.idDocumentoSeleccionado, 0)
            ).subscribe(([result]) => {
                if (result != null && result.respuestaServicio != null) {
                    // Si el resultado es exitoso...
                    if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                        if (result.documentos != null) {
                            this.visibleTablaBuscar = true;
                            this.documentosHistoricos = result.documentos;
                        } else {
                            this.visibleTablaBuscar = false;
                            this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                        }
                    } else {

                        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                    }
                } else {
                }
            },
                error => {
                    this.principalComponent.cargarErrorServicio(error);
                    this.blockedDocument = false;
                },
                () => {
                    // Desbloquear pantalla
                    this.blockedDocument = false;

                }
            );
        }

    }

    consultaDocumentos() {
        this.blockedDocument = true;
        forkJoin(
            this.nuevaTarifaService.consultaDocumentos(this.idPeaje, this.esLectura, this.idEncabezado)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    if (result.documentos != null) {
                        this.documentos = result.documentos;
                    }
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;

            }
        );
    }

    cargaDetalleConfiguracion() {

        this.blockedDocument = true;
        forkJoin(
            this.nuevaTarifaService.cargaDetalleTarifas(this.idPeaje, this.esLectura, this.idEncabezado)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    if (result.detalleTarifa != null) {
                        this.categorias = result.detalleTarifa;
                    }
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;

            }
        );

    }

    cargarTiposSoporte(event) {
        this.modulo = 'tarifa_peaje';

        this.filtroTiposSoporte = [];
        return this.svc.getTiposSoporte(this.modulo, 'undefined').subscribe((data: {}) => {
            this.tiposSoporte = data;
            let existeFiltro: boolean;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.tiposSoporte.length; i++) {
                const filtroTipoSoporte = this.tiposSoporte[i];
                const item: string = String(filtroTipoSoporte.nombre);
                if (item.toLowerCase().includes(event.query.toLowerCase())) {
                    this.filtroTiposSoporte.push(filtroTipoSoporte);
                    existeFiltro = true;
                }
            }
            if (existeFiltro) {
                this.tiposSoporte = this.filtroTiposSoporte;
            }
        }, error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
    }

    cargaConfiguracion() {
        this.blockedDocument = true;

        forkJoin(
            this.nuevaTarifaService.cargaEncabezado(this.idPeaje, this.esLectura, this.idEncabezado)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    if (result.encabezadoTarifa != null) {
                        this.encabezadoGet = result.encabezadoTarifa;
                        this.encabezado = this.encabezadoGet;
                        this.nombrePeaje = this.encabezado.nombrePeaje;
                        if (this.encabezado.fechaInicio != null) {
                            this.fechaInicioTarifaConvert = new Date(this.encabezado.fechaInicio);
                        }
                        if (this.encabezado.fechaExpedicionDocumento != null) {
                            this.fechaResolucionConvert = new Date(this.encabezado.fechaExpedicionDocumento);
                        }
                        if (this.encabezado.idEncabezado != null) {
                            this.cargaDetalleConfiguracion();
                        }
                    }
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;

            }
        );
    }

    cargarCategorias(event) {
        const filtroList = [];
        forkJoin(
            this.nuevaTarifaService.cargarTarifas()
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.peajeMaTarifasList = result.tarifas;
                    if (event != null) {
                        let existeFiltro: boolean;
                        for (const filtro of this.peajeMaTarifasList) {
                            if (String(filtro.descripcionCompleta).toLowerCase().includes(event.query.toLowerCase())) {
                                filtroList.push(filtro);
                                existeFiltro = true;
                            }
                        }
                        if (existeFiltro) {
                            this.peajeMaTarifasList = filtroList;
                        }
                    }
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    irVerDialogoAgregarCatetoria() {
        this.visibleDialogoAgregarCategoria = true;
        this.categoriaSeleccionada = new PjeMaTarifa();
        this.valorCategoria = 0;
    }

    cancelarDialogoAgregarCatetoria() {
        this.visibleDialogoAgregarCategoria = false;
    }

    aceptarAgregarCategoria() {

        if (this.categoriaSeleccionada.id == null) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe Seleccionar una Categoría', life: 10000 });
        } else {
            this.detalleCategoria = new PjeDetalleTarifa();
            this.detalleCategoria.idEncabezado = this.encabezado.idEncabezado;
            this.detalleCategoria.idTarifa = this.categoriaSeleccionada.id;
            this.detalleCategoria.valor = this.valorCategoria;


            this.nuevaTarifaService.guardarDetalleTarifa(this.detalleCategoria).subscribe((data: {}) => {
                const array: any = data;

                if (array.respuestaServicio.codigoSalida !== EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Información almacenada correctamente.', life: 10000 });
                    this.cargaDetalleConfiguracion();
                }

            });

            this.visibleDialogoAgregarCategoria = false;
        }

    }

    asociarDocumento(idDocumento: number) {

        forkJoin(
            this.nuevaTarifaService.asociarDocumento(this.encabezado.idEncabezado, idDocumento)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    // recarga tabla de Documentos asociados
                    this.consultaDocumentos();
                    this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla

            }
        );

    }

    idDialogoEliminarTarifa(id: number) {
        this.visibleConfirmarEliminarTarifa = true;
        this.idTarifaEliminar = id;
    }

    irDialogoEliminarDocumento(id: number) {
        this.visibleConfirmarEliminarDocumento = true;
        this.idDocumentoEliminar = id;
    }

    irDialogoEditarTarifa(id: number, idTarifa: string, valor: number, tipoCategoria: string) {
        this.visibleEditarTarifa = true;

        // Set Objeto Tarifa a editar
        this.tarifaEditable = new PjeDetalleTarifa();
        this.tarifaEditable.id = id;
        this.tarifaEditable.idTarifa = idTarifa + ' - ' + tipoCategoria;
        this.tarifaEditable.valor = valor;
        this.tarifaEditable.tipoCategoria = tipoCategoria;

    }

    cancelarDialogoEditarCatetoria() {
        this.visibleEditarTarifa = false;
    }

    cancelarDialogoGestionarDocumentos() {
        this.fechaExpedicionConvert = null;
        this.tipoSoporteSeleccionado = null;
        this.encabezado.numeroDocumento = null;
        this.visibleDialogoDocumentos = false;
        this.visibleTablaBuscar = false;
    }

    aceptarEditarCategoria() {

        this.nuevaTarifaService.guardarDetalleTarifa(this.tarifaEditable).subscribe((data: {}) => {
            const array: any = data;

            if (array.respuestaServicio.codigoSalida !== 1) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Información almacenada correctamente.', life: 10000 });
                this.cargaDetalleConfiguracion();
            }

        });

        this.visibleEditarTarifa = false;
    }

    aceptarEliminarTarifa() {
        this.blockedDocument = true;
        this.visibleConfirmarEliminarTarifa = false;
        this.nuevaTarifaService.eliminarDetalleTarifa(this.idTarifaEliminar).subscribe(result => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Tarifa eliminada correctamente', life: 10000 });
                    this.cargaDetalleConfiguracion();
                } else {
                    console.error(result.respuestaServicio.mensajeSalida);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
                console.error('Ocurrio un error al consumir el servicio');
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;
            }
        );

    }

    aceptarEliminarDocumento() {

        this.nuevaTarifaService.eliminarDocumentoTarifa(this.idDocumentoEliminar).subscribe(result => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Documento eliminado correctamente.', life: 10000 });
                    this.consultaDocumentos();
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;

            }
        );

        this.visibleConfirmarEliminarDocumento = false;
    }

    cancelarEliminarTarifa() {
        this.visibleConfirmarEliminarTarifa = false;
        this.idTarifaEliminar = 0;
    }

    cancelarEliminardocumento() {
        this.visibleConfirmarEliminarDocumento = false;
        this.idDocumentoEliminar = 0;
    }

    irVerDialogoSubirArchivo() {

        // set nombre archivo
        this.nombreArchivo = 'Documento_' + this.encabezado.numeroDocumento;
        // Set ruta de archivo
        this.rutaArchivo = 'DocumentosTarifas/';

        this.visibleDialogoSubirArchivo = true;
        this.visibleConfirmarAdjuntarDocumento = false;
        this.visibleDialogoDocumentos = false;

    }

    cancelarDialogoSubirArchivo() {
        this.nombreArchivo = '';
        this.rutaArchivo = '';
        this.visibleDialogoSubirArchivo = false;
        this.visibleDialogoDocumentos = true;
    }

    guardarEncabezado() {
        // Set objeto PjeEncabezadoTarifa
        this.encabezado.idPeaje = this.idPeaje;
        this.encabezado.fechaInicio = this.fechaInicioTarifaConvert;

        this.nuevaTarifaService.guardarEncabezdo(this.encabezado).subscribe((data: {}) => {
            const array: any = data;

            if (array.respuestaServicio.codigoSalida !== 1) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
                this.visibleDialogoDocumentos = true;
                this.cargaConfiguracion();
                this.cargaDetalleConfiguracion();
            }

        });

    }

    aceptarAdjuntoArchivo() {

        this.encabezado.rutaDocumentoNorma = this.archivos[0].nombreCompleto;
        this.visibleDialogoSubirArchivo = false;
        this.visibleDialogoDocumentos = true;

        // Set objeto PjeEncabezadoTarifa
        this.encabezado.fechaInicio = this.fechaInicioTarifaConvert;
        this.encabezado.fechaExpedicionDocumento = this.fechaExpedicionConvert;
        this.encabezado.idPeaje = this.idPeaje;
        this.encabezado.idTipoDocumentoSeleccionado = this.archivos[0].idTipoDocumento;


        this.nuevaTarifaService.guardarDocumento(this.encabezado).subscribe((data: {}) => {
            const array: any = data;

            if (array.respuestaServicio.codigoSalida !== 1) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Encabezado guardado correctamente. Por favor verifique las tarifas antes de publicar.', life: 10000 });
                this.consultaDocumentos();
                this.cargaConfiguracion();
                this.cargaDetalleConfiguracion();
                this.tipoSoporteSeleccionado = null;
                this.fechaExpedicionConvert = null;
            }

        });
        this.archivos = [];
        this.visibleDialogoSubirArchivo = false;
    }



    actualizarEncabezado() {

        // Set objeto PjeEncabezadoTarifa
        this.encabezado.fechaInicio = this.fechaInicioTarifaConvert;
        this.encabezado.fechaExpedicionDocumento = this.fechaResolucionConvert;
        this.encabezado.idPeaje = this.idPeaje;



        this.nuevaTarifaService.guardarEncabezdo(this.encabezado).subscribe((data: {}) => {
            const array: any = data;

            if (array.respuestaServicio.codigoSalida !== 1) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Encabezado guardado correctamente. Por favor verifique las tarifas antes de publicar.', life: 10000 });
                this.cargaConfiguracion();
                this.cargaDetalleConfiguracion();
            }

        });

    }

    irAVisualizarDialogoDocumentos() {
        // Verifica si es lectura para guardar encabezado en caso contrario solo consulta documentos.
        if (!this.esLectura) {
            this.guardarEncabezado();
            this.consultaDocumentos();
        } else {
            this.visibleDialogoDocumentos = true;
            this.consultaDocumentos();
        }

    }

    irAVisualizarDialogoPublicar() {
        this.visibleConfirmarPublicarTarifa = true;
    }

    cancelarPublicarTarifa() {
        this.visibleConfirmarPublicarTarifa = false;
    }

    aceptarPublicarTarifa() {
        this.publicarTarifa();
        this.visibleConfirmarPublicarTarifa = false;
    }

    cancelarEliminarRecaudos() {
        this.visibleConfirmarEliminarRecaudosAsociados = false;
    }

    aceptarEliminarRecaudos() {
        if (Object.keys(this.categorias).length === 0) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe existir al menos una tarifa para hacer una publicación', life: 10000 });

        } else {
            this.nuevaTarifaService.publicarTarifa(this.encabezado).subscribe((data: {}) => {
                const array: any = data;

                if (array.respuestaServicio.codigoSalida !== 1) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                    this.mensajeExito = 'La información se publicó exitosamente.';
                    this.visibleDialogoExito = true;
                }

            });
        }
        this.visibleConfirmarEliminarRecaudosAsociados = false;
    }

    irAVisualizarDialogoCancelar() {
        this.visibleConfirmarCancelarBorrador = true;
    }

    cancelarCancelarBorrador() {
        this.visibleConfirmarCancelarBorrador = false;
    }

    aceptarCancelarBorrador() {

        if (this.encabezado.idEncabezado == null) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe existir encabezado', life: 10000 });
        } else {
            this.nuevaTarifaService.cancelarBorrador(this.encabezado).subscribe((data: {}) => {
                const array: any = data;

                if (array.respuestaServicio.codigoSalida !== 1) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                    this.mensajeExito = 'El borrador ha sido cancelado exitosamente';
                    this.visibleDialogoExito = true;
                }

            });
        }

        this.visibleConfirmarCancelarBorrador = false;
    }

    publicarTarifa() {
        this.blockedDocument = true;
        forkJoin(
            this.nuevaTarifaService.consultaRecaudo(this.encabezado.idEncabezado)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio !== null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

                    if (Object.keys(this.categorias).length === 0) {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe existir al menos una tarifa para hacer una publicación', life: 10000 });

                    } else {
                        this.nuevaTarifaService.publicarTarifa(this.encabezado).subscribe((data: {}) => {
                            const array: any = data;

                            if (array.respuestaServicio.codigoSalida !== EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                                this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
                            } else {
                                this.mensajeExito = 'La información se publicó exitosamente.';
                                this.visibleDialogoExito = true;
                            }

                        });
                    }

                } else if (result.respuestaServicio.codigoSalida === 3) {

                    this.visibleConfirmarEliminarRecaudosAsociados = true;
                    this.recaudoPorEliminar = result.recaudoPorEliminar;

                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;

            }
        );


    }

    aceptarDialogoExito() {
        this.visibleDialogoExito = false;
        this.router.navigate(['/carreteros/infGeneral/proyectoHome/' + this.idProyecto + '/menu-peajes/' + this.idProyecto + '/tarifas-peajes/', this.idProyecto]);
    }

}
