import { TrnSiNo } from "../parametricas/trn-si-no";
import { UnidadFuncional } from "../unidad-funcional/unidad-funcional";

export interface CrrMaEstadoEquipoItsDTO {
    id?: number;
    nombre?: string;
}

export interface RptCrrMaEstadoEquipoItsDTO {
    codigoSalida?: number;
    mensajeSalida?: string;
    listaCrrMaEstadoEquipoIts?: CrrMaEstadoEquipoItsDTO[];
}

export interface CrrMaMedioTransmisionEquipoItsDTO {
    id?: number;
    nombre?: string;
}

export interface RptCrrMaMedioTransmisionEquipoItsDTO {
    codigoSalida?: number;
    mensajeSalida?: string;
    listaCrrMaMedioTransmisionEquipoIts?: CrrMaMedioTransmisionEquipoItsDTO[];
}

export interface CrrMaProtocoloComunicacionEquipoItsDTO {
    id?: number;
    nombre?: string;
}

export interface RptCrrMaProtocoloComunicacionEquipoItsDTO {
    codigoSalida?: number;
    mensajeSalida?: string;
    listaCrrMaProtocoloComunicacionEquipoIts?: CrrMaProtocoloComunicacionEquipoItsDTO[];
}

export interface CrrMaSentidoTraficoDTO {
    id?: number;
    nombre?: string;
}

export interface RptCrrMaSentidoTraficoDTO {
    codigoSalida?: number;
    mensajeSalida?: string;
    listaCrrMaSentidoTrafico?: CrrMaSentidoTraficoDTO[];
}

export interface CrrMaTipoEquipoItsDTO {
    id?: number;
    nombre?: string;
}

export class CrrMaPrKDTO {
    id?: number;
    nombre?: string;
}

export interface RptCrrMaTipoEquipoItsDTO {
    codigoSalida?: number;
    mensajeSalida?: string;
    listaCrrMaTipoEquipoIts?: CrrMaTipoEquipoItsDTO[];
}

export interface CrrMaTipoSuministroEnergeticoEquipoItsDTO {
    id?: number;
    nombre?: string;
}

export interface RptCrrMaTipoSuministroEnergeticoEquipoItsDTO {
    codigoSalida?: number;
    mensajeSalida?: string;
    listaCrrMaTipoSuministroEnergeticoEquipoIts?: CrrMaTipoSuministroEnergeticoEquipoItsDTO[];
}

export interface CrrEquipoItsDTO {
    id?: number;
    idProyecto?: number;
    idUnidadFuncional?: number;
    unidadFuncionalDto?: UnidadFuncional;
    unidadFuncional?: string;
    nombre?: string;
    idTipo?: number;
    tipo?: string;
    tipoEquipoIts?: CrrMaTipoEquipoItsDTO;
    idEstado?: number;
    estado?: string;
    proyecto?: string;
    estadoEquipoIts?: CrrMaEstadoEquipoItsDTO;
    fechaEntradaOperacion?: Date;
    idProtocoloComunicacion?: number;
    protocoloComunicacionEquipoIts?: CrrMaProtocoloComunicacionEquipoItsDTO;
    protocoloComunicacion?: string;
    tieneIpv6?: Boolean;
    Ipv6SiNo?: TrnSiNo;
    idTipoSuministroEnergetico?: number;
    tipoSuministroEnergeticoEquipoIts?: CrrMaTipoSuministroEnergeticoEquipoItsDTO;
    tipoSuministroEnergetico?: string;
    idMedioTransmision?: number;
    medioTransmisionEquipoIts?: CrrMaMedioTransmisionEquipoItsDTO;
    medioTransmision?: string;
    tipoUbicacion?: string;
    prKUbicacion?: CrrMaPrKDTO;
    punto?: number;
    distanciaAlPunto?: number;
    idDepartamento?: number;
    idMunicipio?: string;
    longitud?: number;
    latitud?: number;
    idSentidoTrafico?: number;
    sentidoTraficoDTO?: CrrMaSentidoTraficoDTO;
    sentidoTrafico?: string;
    idPeaje?: number;
    peaje?: string;
    tienePagoElectronico?: Boolean;
    pagoElectronicoSiNo?: TrnSiNo;
    fechaInicioPagoElectronico?: Date;
    observaciones?: string;
}

export interface RptCrrEquipoItsDTO {
    codigoSalida?: number;
    mensajeSalida?: string;
    listaCrrEquipoIts?: CrrEquipoItsDTO[];
}

export interface RptConsultaCrrEquipoItsDTO {
    codigoSalida?: number;
    mensajeSalida?: string;
    crrEquipoIts?: CrrEquipoItsDTO;
}

export interface CrrSentidoTraficoProyectoDTO {
    id?: number;
    idProyecto?: number;
    puntoA?: string;
    puntoB?: string;
}

export interface RptCrrSentidoTraficoProyectoDTO {
    codigoSalida?: number;
    mensajeSalida?: string;
    listaCrrSentidoTraficoProyecto?: CrrSentidoTraficoProyectoDTO[];
}
