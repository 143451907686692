import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AlmacenamientoService } from '../../comun/almacenamiento/almacenamiento.service';
import { DocumentosPeajesService } from './documentos-peajes.service';
import { forkJoin } from 'rxjs';
import { PjeDocumentos } from '../tarifas-peajes/nueva-tarifa/PjeDocumentos';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
    selector: 'app-documentos-peajes',
    templateUrl: './documentos-peajes.component.html',
    styleUrls: ['./documentos-peajes.component.sass']
})

export class DocumentosPeajesComponent implements OnInit {

    fechaMaxima: Date;

    // variables documentos
    fechaExpedicionConvert: Date;
    numeroDocumento: string;
    filtroTiposSoporte: any[];
    tiposSoporte: any = [];
    tipoSoporteSeleccionado: any;
    modulo: string;
    idDocumentoSeleccionado = 0;
    private documentosHistoricos: any = [];
    displayedColumnsDocHistorico: any[];
    visibleDialogoEditarDocumento = false;
    visibleDialogoConfirmarEliminar = false;
    idDocumentoEditar: number;
    idDocumentoEliminar: number;
    documentoEdit: PjeDocumentos;
    tipoEdit: any;
    visibleDialogoConfirmarEditar = false;
    visibleDialogoEliminarDocumento = false;
    blockedDocument = false;

    // variables adjunto
    nombreArchivo: string;
    rutaArchivo: string;
    visibleDialogoSubirArchivo = false;
    archivos: any = [];
    idSoporteAdjuntar: number;

    constructor(private documentoService: DocumentosPeajesService, private router: Router, private principalComponent: PrincipalComponent,
        private route: ActivatedRoute, private messageService: MessageService, public datepipe: DatePipe, private svc: AlmacenamientoService) { }

    ngOnInit() {

        this.fechaMaxima = new Date();
        this.documentoEdit = new PjeDocumentos();


        this.displayedColumnsDocHistorico = [
            { field: 'tipoDocumento', header: 'TipoDocumento' },
            { field: 'fechaExpedicion', header: 'Fecha Expedicion' },
            { field: 'numeroDocumento', header: 'Numero Documento' }
        ];

    }

    cargarTiposSoporte(event) {
        this.modulo = 'tarifa_peaje';

        this.filtroTiposSoporte = [];
        return this.svc.getTiposSoporte(this.modulo, 'undefined').subscribe((data: {}) => {
            this.tiposSoporte = data;
            let existeFiltro: boolean;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.tiposSoporte.length; i++) {
                const filtroTipoSoporte = this.tiposSoporte[i];
                const item: string = String(filtroTipoSoporte.nombre);
                if (item.toLowerCase().includes(event.query.toLowerCase())) {
                    this.filtroTiposSoporte.push(filtroTipoSoporte);
                    existeFiltro = true;
                }
            }
            if (existeFiltro) {
                this.tiposSoporte = this.filtroTiposSoporte;
            }
        }, error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
    }

    eliminarDocumento(idDocumento: number) {
        this.visibleDialogoConfirmarEliminar = true;
        this.idDocumentoEliminar = idDocumento;
    }

    editarDocumento(idDocumento: number, fecha: Date, numeroDocumento: string, tipoDocumento: string) {
        this.visibleDialogoEditarDocumento = true;
        this.idDocumentoEditar = idDocumento;
        this.documentoEdit = new PjeDocumentos();
        this.documentoEdit.idDocumentoTarifa = idDocumento;
        this.documentoEdit.fechaExpedicion = new Date(fecha);
        this.documentoEdit.numeroDocumento = numeroDocumento;
        this.documentoEdit.tipoDocumento = tipoDocumento;
        this.tipoEdit = this.tiposSoporte.find(x => x.nombre === tipoDocumento);

    }



    aceptarEliminarDocumento() {

        this.documentoService.eliminarDocumento(this.idDocumentoEliminar).subscribe(result => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === 1) {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Documento eliminado correctamente.', life: 10000 });
                    this.buscarDocumentosExistentes();
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
                console.error('Ocurrio un error al consumir el servicio');
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;

            }
        );

        this.visibleDialogoConfirmarEliminar = false;

    }

    irVerDialogoSubirArchivo() {

        // set nombre archivo
        this.nombreArchivo = 'Documento_' + this.documentoEdit.numeroDocumento;
        // Set ruta de archivo
        this.rutaArchivo = 'DocumentosTarifas/';

        this.visibleDialogoSubirArchivo = true;
        this.idSoporteAdjuntar = this.tipoSoporteSeleccionado.id;

    }
    aceptarAdjuntoArchivo() {
        this.documentoEdit.rutaDocumento = this.archivos[0].nombreCompleto;
        this.visibleDialogoSubirArchivo = false;
        this.archivos = [];
        this.visibleDialogoSubirArchivo = false;
    }

    cancelarDialogoEliminardocumento() {
        this.visibleDialogoConfirmarEliminar = false;
    }

    cancelarDialogoSubirArchivo() {
        this.visibleDialogoSubirArchivo = false;
    }

    cancelarEditarDocumento() {
        this.visibleDialogoEditarDocumento = false;
    }
    cancelarConfirmacion() {
        this.visibleDialogoConfirmarEditar = false;
    }

    aceptarEditarDocumento() {
        this.visibleDialogoConfirmarEditar = true;

    }

    actualizarDocumento() {
        this.blockedDocument = true;
        this.visibleDialogoConfirmarEditar = false;
        this.visibleDialogoEditarDocumento = false;
        this.documentoEdit.idTipoDocumento = this.tipoEdit.idTipoDocumentoAsociado;
        this.documentoService.actualizaDocumento(this.documentoEdit).subscribe((data: {}) => {
            const array: any = data;

            if (array.respuestaServicio.codigoSalida !== 1) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Documento actualizado correctamente.', life: 10000 });
                this.fechaExpedicionConvert = new Date(this.documentoEdit.fechaExpedicion);
                this.numeroDocumento = this.documentoEdit.numeroDocumento;
                this.tipoSoporteSeleccionado = this.tipoEdit;
                this.buscarDocumentosExistentes();
            }

        }
            ,
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;

            }
        );
    }

    limpiarFiltros() {
        this.fechaExpedicionConvert = null;
        this.numeroDocumento = null;
        this.tipoSoporteSeleccionado = null;
    }

    buscarDocumentosExistentes() {
        this.blockedDocument = true;
        if (this.fechaExpedicionConvert === null && this.numeroDocumento === null && this.tipoSoporteSeleccionado == null) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar al menos un filtro para realizar la búsqueda', life: 10000 });
        }
        let fechaExpedicionDoc: string;

        // formatea fecha
        if (this.fechaExpedicionConvert != null) {
            fechaExpedicionDoc = this.datepipe.transform(this.fechaExpedicionConvert, 'yyyy') + this.datepipe.transform(this.fechaExpedicionConvert, 'MM') + this.datepipe.transform(this.fechaExpedicionConvert, 'dd');
        }
        // si tipo de soporte no es nulo setea variabla documento seleccionado
        if (this.tipoSoporteSeleccionado != null) {
            this.idDocumentoSeleccionado = this.tipoSoporteSeleccionado.idTipoDocumentoAsociado;
        }
        // formatea número resolucion
        if (this.numeroDocumento != null) {
            if (this.numeroDocumento.trim().length === 0) {
                this.numeroDocumento = null;
            }
        }
        forkJoin(
            this.documentoService.consultaDocumentosHistoricos(fechaExpedicionDoc, this.numeroDocumento, this.idDocumentoSeleccionado, 0)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {
                    if (result.documentos != null) {
                        this.documentosHistoricos = result.documentos;
                    } else {
                        this.documentosHistoricos = [];
                        this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                    }
                } else {

                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;

            }
        );
    }
}

