import { Component, OnInit, ViewChild } from '@angular/core';
import { AmigablesService } from './amigables.service';
import { AmigablesDetalleService } from '../amigables-detalle/amigables-detalle.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator, MatDialogRef, MatDialog } from '@angular/material';
import { ProyectoAmigables } from './proyecto-amigables';
import { ProgressSpinnerDialogComponent } from '../../progress-spinner-dialog/progress-spinner-dialog.component';

@Component({
  selector: 'app-amigables',
  templateUrl: './amigables.component.html',
  styleUrls: ['./amigables.component.sass'],
  providers: [AmigablesService]
})

export class AmigablesComponent implements OnInit {

  tipoObligacion: any = [];
  proyectos: any = [];
  id: number;
  nombreProyecto;

  displayedColumns: string[] = [ 'obligacion', 'avance', 'convocado','etapa','cuantiaPeticionesPesos','cuantiaPeticionesDolares', 'acciones'];

  dataSource: MatTableDataSource<ProyectoAmigables>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(private infoAmbiental: AmigablesService,
              private infoDetalle: AmigablesDetalleService,
              private route: Router, private ruta: ActivatedRoute,
              private dialog: MatDialog, private principalComponent: PrincipalComponent) {
              }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'))
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarProyectosAmigables(this.id);
      localStorage.setItem('idP', JSON.stringify(this.id));

    });
    var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto);
  }

  applyFilter(filterValue: string) {
   this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onNew() {
    this.route.navigate([this.route.url+'/infAmigablesDetalle', 'new']);
  }

  onEdit(data, id) {
    this.route.navigate([this.route.url+'/infAmigablesDetalle', id]);
    this.infoDetalle.setDataAmigable(data);
  }

  onFollow(id: number) {
    this.route.navigate([this.route.url+'/infSeguimientoAmigables', id]);
  }

  onDelete(idRegistry: number) {
    return this.infoAmbiental.deleteDetalleAmbiental(idRegistry).subscribe((data: {}) => {
      alert('Se ha elimindado el Registro');
      this.cargarProyectosAmigables(this.id);
      },error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  cargarTipoObligacion() {
    return this.infoAmbiental.getTipoObligacion().subscribe((data: {}) => {
      this.tipoObligacion = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  getTipoObligacion(id) {
    const dataLength = this.tipoObligacion.length;
    for (let i = 0; i <= dataLength; i++) {
      if (this.tipoObligacion[i].idTipoObligacionAmbiental === id) {
        return this.tipoObligacion[i].descripcion;
      }
    }
  }

  getStyle(estado) {
    if (estado) {
      switch (estado) {
        case 'Verde':
            return 'badge badge-success';
        case 'Amarillo':
            return 'badge badge-warning';
        case 'Rojo':
            return 'badge badge-danger';
       default:
        // code...
        break;
        }
    }
}

  cargarProyectosAmigables(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    return this.infoAmbiental.getProyectosAmigables(id).subscribe((data: {}) => {
      this.proyectos = data;
      this.dataSource = new MatTableDataSource(this.proyectos);
      this.dataSource.paginator = this.paginator;
      this.dialogSpin.close();

    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  convertStringDateToTime(data) {
      const date = new Date(data);
      const localOffset = date.getTimezoneOffset() * 60000;
      const utc = date.getTime() + localOffset;
      return utc;
    }



}
