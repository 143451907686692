import { Component, OnInit, } from '@angular/core';
import { YoutubeSearchListService, } from './youtube-search-list.service';
import { Video } from '../search.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
    selector: 's-youtube-search-list',
    templateUrl: './youtube-search-list.component.html',
    styleUrls: ['./youtube-search-list.component.css'],
    providers: [YoutubeSearchListService]
})
export class YoutubeSearchListComponent implements OnInit {

    videos: Video[] = [];    
    visibleDialogoVideo: boolean;
    // Inicializaciones de Variables
    loading : boolean;
    inputTouched = false;
    urlVideo : any;
    idVideo : any;
    srcVideo : any;
    urlSafe: SafeResourceUrl;

    constructor(private _service: YoutubeSearchListService, public sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.handleSearch("DBZ");
        //Este código carga el reproductor de la API en un iframe de manera asíncrona, siguiendo las instrucciones:
        // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
        const tag = document.createElement('script');
    
        tag.src = "https://www.youtube.com/iframe_api";
        document.body.appendChild(tag);
    }

  /**
  * Metodo que siguiente
  */
  onVideo(urlVideo, idVideo) {    
    this.visibleDialogoVideo = true;  
    this.urlVideo = urlVideo;
    this.idVideo = idVideo;
    this.srcVideo = 'http://www.youtube.com/embed/'+this.idVideo;
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.srcVideo);
  }

  /**
  * Metodo cancelar
  */
  onNoClick(): void {
    this.visibleDialogoVideo = false;
  }
    
  handleSearch(inputValue: string) {
    this.loading = true;
    this._service.getListVideosCanal(inputValue)
      .subscribe((items: any) => {
        this.videos = items.map(item => {
          return {
            title: item.snippet.title,
            videoId: item.id.videoId,
            videoUrl: `https://www.youtube.com/watch?v=${item.id.videoId}`,
            channelId: item.snippet.channelId,
            channelUrl: `https://www.youtube.com/channel/${item.snippet.channelId}`,
            channelTitle: item.snippet.channelTitle,
            description: item.snippet.description,
            publishedAt: new Date(item.snippet.publishedAt),
            thumbnail: item.snippet.thumbnails.high.url
          };
        });
        this.inputTouched = true;
        this.loading = false;
      });
  }
}
