import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InformeContratistaService } from '../informe-contratista-registro/informe-contratista.service';
import { EnumRespuestaServicio, EnumTrnConstante, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { MessageService } from 'primeng/api';
import { EnumCtoPasoInforme } from '../../../constantes/contratacion-constantes';
import { CtoPlanPagoDetalleDTO } from 'src/app/contratacion/contratacion-dto/contrato-plan-pago-vigente';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoInformeContratistaCopiarInformeRpt } from '../../../contratacion-dto/informe-contratista-validacion-informe';
import { CtoInformeContratistaDTO } from '../../../contratacion-dto/informe-contratista';
import { TrnConstantesService } from 'src/app/comun/constantes/trn-constantes.service';


@Component({
  selector: 'app-contrato-plan-pago-contratista',
  templateUrl: './contrato-plan-pago-contratista.component.html',
  styles: []
})
export class ContratoPlanPagoContratistaComponent implements OnInit {

  public idContrato: number;
  public idPersona: number;
  public planPagos: CtoPlanPagoDetalleDTO[] = [];
  private ctoInformeContratista: CtoInformeContratistaDTO = {};
  private ctoInformeContratistaCopiarInformeRpt: CtoInformeContratistaCopiarInformeRpt = {};
  blockedDocument: boolean;
  visibleDialogoTengaenCuentaInforme = false;

  mapConstantes;

  msgPersonaNoValida = 'La persona no coincide con lo registrado en el plan de pagos del contrato';
  msgPersonaContratoNoValido = 'La persona y el contrato no coincide con lo registrado en el plan de pagos del contrato';
  msgContratoNoValido = 'El contrato no coincide con la persona registrada';


  constructor(
    private route: Router,
    private ruta: ActivatedRoute,
    private informeContratistaService: InformeContratistaService,
    private messageService: MessageService,
    private constantesService: TrnConstantesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {

    this.mapConstantes = new Map();

    this.cargarConstantesCuentasCobro();

    this.ruta.params.subscribe(params => {
      this.idContrato = params.idContrato;
      this.idPersona = params.idPersona;
      this.cargarPlanDePagos(this.idContrato, this.idPersona);
    });
  }

  /**
   * Metodo que muestra en pantalla el Plan de Pagos del coontrato y asociado a la persona que esta intentando tramitar el informe
   * @param idContrato Id del contrato 
   * @param idPersona Id de la Persona
   * @returns Resultado de la operación 
   */
  cargarPlanDePagos(idContrato: number, idPersona: number) {
    this.blockedDocument = true;
    return this.informeContratistaService.srvGetPlanPagosContratoContratista(idContrato, idPersona).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.planPagos = result.contratoPlanPagoVigente;
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que llama al servicio de constantes para mostrar en el dialogo de información de las cuentas de cobro
   */
  cargarConstantesCuentasCobro() {
    const nombresConstantes = `${EnumTrnConstante.GENERAL_CORREO_SOPORTE},${EnumTrnConstante.CTO_CONTRATACION_CORREO_SOPORTE},${EnumTrnConstante.CTO_PLAN_PAGOS_TESORERIA_SOPORTE}`;

    this.constantesService.srvGetConstantesListaPorNombre(nombresConstantes)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.visibleDialogoTengaenCuentaInforme = true;
              this.mapConstantes = new Map();
              result.constantesLista.forEach(cns => {
                this.mapConstantes.set(cns.nombre, cns.valor);
              });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
  }


  /**
   * Metodo que valida que el contratista y el plan de pagos este asociado a la persona que esta intentando tramitar la cuenta
   * @param contratoPlanPagoContratista Objeto de tipo CtoPlanPagoDetalleDTO con la información del contrato, pago, contratista e informe 
   * @returns Verdadero o Falso
   */
  validarContratoPersona(contratoPlanPagoContratista: CtoPlanPagoDetalleDTO) {
    let validacionCorrecta = true;

    if (this.idContrato != contratoPlanPagoContratista.idContrato) {
      validacionCorrecta = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.msgContratoNoValido, life: 10000 });
      return validacionCorrecta;
    }

    if (this.idPersona != contratoPlanPagoContratista.idPersona) {
      validacionCorrecta = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.msgPersonaNoValida, life: 10000 });
      return validacionCorrecta;
    }

    if (this.idPersona != contratoPlanPagoContratista.idPersona && this.idContrato != contratoPlanPagoContratista.idContrato) {
      validacionCorrecta = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.msgPersonaContratoNoValido, life: 10000 });
      return validacionCorrecta;
    }
    return validacionCorrecta;
  }

  /**
   * Metodo que llama al servicio para crea por primera vez un informe en borrador solo con unos datos basicos
   * @param contratoPlanPagoContratista Objeto de tipo CtoPlanPagoDetalleDTO con los datos basicos
   */
  irCrearInforme(contratoPlanPagoContratista: CtoPlanPagoDetalleDTO) {
    if (this.validarContratoPersona(contratoPlanPagoContratista)) {

      this.blockedDocument = true;

      //Se carga y llena el objeto ctoInformeContratista con la información básica, se agregan variables esResponsableIva y estaObligadoFacturar
      this.ctoInformeContratista = {
        idContrato: contratoPlanPagoContratista.idContrato,
        idPlanPago: contratoPlanPagoContratista.idPlanPago,
        idPersona: contratoPlanPagoContratista.idPersona,
        esResponsableIva: contratoPlanPagoContratista.esResponsableIva,
        estaObligadoFacturar: contratoPlanPagoContratista.estaObligadoFacturar,
      };

      this.informeContratistaService.srvGuardarInformeContratistaBorrador(this.ctoInformeContratista).subscribe(
        data => {
          if (data != null && data.respuestaServicio != null) {
            if (data.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.ctoInformeContratista = data.informeContratista;
              this.irInformeContratista(contratoPlanPagoContratista.idContrato, contratoPlanPagoContratista.idPersona, contratoPlanPagoContratista.idPlanPago, this.ctoInformeContratista.id, 1, 0, 0, (contratoPlanPagoContratista.esResponsableIva ? 1 : 0), (contratoPlanPagoContratista.estaObligadoFacturar != null && contratoPlanPagoContratista.estaObligadoFacturar ? 1 : 0), contratoPlanPagoContratista.numeroPago);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: data.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO.toString(), life: 10000 });
          }
        },
        error => {
          console.error(error)
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  /**
   * Metodo que redirige al informe contratista en borrador 
   * @param contratoPlanPagoContratista Objeto de tipo CtoPlanPagoDetalleDTO, con la información del periodo de pago
   */
  editarInformeBorrador(contratoPlanPagoContratista: CtoPlanPagoDetalleDTO) {
    this.blockedDocument = true;
    if (this.validarContratoPersona(contratoPlanPagoContratista)) {
      this.irInformeContratista(contratoPlanPagoContratista.idContrato, contratoPlanPagoContratista.idPersona, contratoPlanPagoContratista.idPlanPago, contratoPlanPagoContratista.idInformeBorrador, 1, 0, 0, (contratoPlanPagoContratista.esResponsableIva ? 1 : 0), (contratoPlanPagoContratista.estaObligadoFacturar != null && contratoPlanPagoContratista.estaObligadoFacturar ? 1 : 0), contratoPlanPagoContratista.numeroPago);
    }
    this.blockedDocument = false;
  }

  /**
   * Metodo que redirige al informe contratista definitivo. 
   * Sí el paso actual es RECHAZADO (por vobo/supervisor), se crea una nueva versión del informe. Si se ejecuta correctamente se redirige al formulario de informe-contratista-registro para que se realicen las correcciones pertinentes
   * Sí el paso actual es ANULADO_CORRECIONES (por central de pagos), el informe queda en Estado Anulado y se crea un nuevo informe copia fiel del anulado para trabajar los ajustes sobre el. Si se ejecuta correctamente se redirige al formulario de informe-contratista-registro para que se realicen las correcciones pertinentes
   * Sí el paso actual es diferente de los anteriores el informe se redirige al formulario de informe-contratista-registro para que se realicen las correcciones pertinentes   
   * @param contratoPlanPagoContratista Objeto de tipo CtoPlanPagoDetalleDTO, con la información del periodo de pago
   */
  editarInforme(contratoPlanPagoContratista: CtoPlanPagoDetalleDTO) {
    this.blockedDocument = true;
    if (this.validarContratoPersona(contratoPlanPagoContratista)) {
      if (contratoPlanPagoContratista.idPaso == EnumCtoPasoInforme.RECHAZADO) {
        const numeroNuevaVersion: number = contratoPlanPagoContratista.versionInforme + 1;
        this.informeContratistaService.guardarNuevaVersionInforme(contratoPlanPagoContratista.idInforme, numeroNuevaVersion).subscribe(
          data => {
            if (data != null && data.respuestaServicio != null) {
              if (data.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.irInformeContratista(contratoPlanPagoContratista.idContrato, contratoPlanPagoContratista.idPersona, contratoPlanPagoContratista.idPlanPago, contratoPlanPagoContratista.idInforme, 0, numeroNuevaVersion, contratoPlanPagoContratista.idPaso, (contratoPlanPagoContratista.esResponsableIva ? 1 : 0), (contratoPlanPagoContratista.estaObligadoFacturar != null && contratoPlanPagoContratista.estaObligadoFacturar ? 1 : 0), contratoPlanPagoContratista.numeroPago);
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: data.respuestaServicio.mensajeSalida, life: 10000 });
              }
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO.toString(), life: 10000 });
            }
          },
          error => {
            console.error(error)
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.blockedDocument = false;
          }
        );
      } else {
        if (contratoPlanPagoContratista.idPaso == EnumCtoPasoInforme.ANULADO_CORRECIONES) {
          this.informeContratistaService.copiarInforme(contratoPlanPagoContratista.idInforme).subscribe(
            data => {
              if (data != null && data.respuestaServicio != null) {
                if (data.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                  this.ctoInformeContratistaCopiarInformeRpt = data;
                  contratoPlanPagoContratista.idPaso = EnumCtoPasoInforme.EN_CORRECCIONES;
                  this.irInformeContratista(contratoPlanPagoContratista.idContrato, contratoPlanPagoContratista.idPersona, contratoPlanPagoContratista.idPlanPago, this.ctoInformeContratistaCopiarInformeRpt.idNuevoInforme, 0, 1, contratoPlanPagoContratista.idPaso, (contratoPlanPagoContratista.esResponsableIva ? 1 : 0), (contratoPlanPagoContratista.estaObligadoFacturar != null && contratoPlanPagoContratista.estaObligadoFacturar ? 1 : 0), contratoPlanPagoContratista.numeroPago);

                } else {
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: data.respuestaServicio.mensajeSalida, life: 10000 });
                }
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO.toString(), life: 10000 });
              }
            },
            error => {
              console.error(error)
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
              this.blockedDocument = false;
            }
          );
        } else {
          //Se redirige al registro de informe contratista para editar el informe actual, sin llamar un servicio que cree copia o versiones del informe
          this.blockedDocument = false;
          this.irInformeContratista(contratoPlanPagoContratista.idContrato, contratoPlanPagoContratista.idPersona, contratoPlanPagoContratista.idPlanPago, contratoPlanPagoContratista.idInforme, 0, contratoPlanPagoContratista.versionInforme, contratoPlanPagoContratista.idPaso, (contratoPlanPagoContratista.esResponsableIva ? 1 : 0), (contratoPlanPagoContratista.estaObligadoFacturar != null && contratoPlanPagoContratista.estaObligadoFacturar ? 1 : 0), contratoPlanPagoContratista.numeroPago);
        }
      }
    }
  }

  /**
   * Metodo generico para navegar al componente base del informe contratista (informe-contratista-registro), para que se relice el registro de los datos del informe para el contratista, teniendo en cuenta los parametros   
   * @param idPersona Id de la Persona que esta tramitando este informe dado que puede ser diferente durtante la ejecución del contrato de acuerdo a las novedades contractuales
   * @param idPlanPago Id del Plan de pago del contrato asociado a este informe
   * @param borrador 0 si No es un Informe en Borrador 1 en caso contrario
   * @param version Version del informe por defecto es 0 cuando es un borrador, e incrementa en 1 cuando se crea un nueva versión sí se rechaza 
   * @param idPaso Id del Paso actual de informe sí es un borrador aún no existe paso por lo cual por defecto debe ser 0
   * @param esResponsableIva Indica sí se es ResponsableIva debe enviarse 0 en caso de ser falso, y 1 en caso de ser verdadero 
   * @param estaObligadoFacturar Indica sí esta obligado a facturar por defecto es 0 en caso de ser falso y 1 en caso de ser verdadero
   * @param numeroPago Numero del pago que se esta realizando en relación al Plan de Pagos del contrato
   */
  irInformeContratista(idContrato: number, idPersona: number, idPlanPago: number, idInforme: number, borrador: number,
    versionInforme: number, idPaso: number, esResponsableIva: number, estaObligadoFacturar: number, numeroPago: number) {
    return this.route.navigate([this.route.url + '/informeContratista', idContrato, idPersona, idPlanPago, idInforme, borrador,
      versionInforme, idPaso, esResponsableIva, estaObligadoFacturar, numeroPago]);
  }

  get EnumTrnConstante() {
    return EnumTrnConstante;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCtoPasoInforme() {
    return EnumCtoPasoInforme;
  }

}
