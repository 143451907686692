import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AmbientalService {

  public END_POINT = '';

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public getTipoObligacion() {
    return this.http.get(this.END_POINT + '/ambiental/tipoObligacion')
      .pipe(
        retry(1));
  }

  public getProyectosAmbientales(id) {
    return this.http.get(this.END_POINT + '/ambiental/list/' + id)
      .pipe(
        retry(1));
  }

  public deleteDetalleAmbiental(id) {
    return this.http.delete(this.END_POINT + '/ambiental/' + id )
      .pipe(
        retry(1));
  }


}
