import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/config.service';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';

import { IntervencionDepartamentoUf, IntervencionUnidadFuncional } from './tipo-intervencion-carretero.dto';



@Injectable({
    providedIn: 'root'
})
export class TipoIntervencionCarreteroService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }



    public getIntervencionesPorUnidadFuncional(idUnidadFuncional, idProyecto, idConfiguracion, esborrador): Observable<ListaIntervencionUfRpt> {
        return this.http.get<ListaIntervencionUfRpt>(this.END_POINT + '/CrrIntervencionUnidadFuncional/list/' + idUnidadFuncional + '/' + idProyecto + '/' + idConfiguracion + '/' + esborrador)
            .pipe(
                retry(1));
    }

    public getIntervencionPorId(idIntervencion, esBorrador): Observable<ConsultaIntervencionUfRpt> {
        return this.http.get<ConsultaIntervencionUfRpt>(this.END_POINT + '/CrrIntervencionUnidadFuncional/' + idIntervencion + '/' + esBorrador)
            .pipe(
                retry(1));
    }

    public guardarNuevaIntervencionPorUnidadFuncional(intervencion: IntervencionUnidadFuncional, esBorrador): Observable<ConsultaIntervencionUfRpt> {
        return this.http.post<ConsultaIntervencionUfRpt>(this.END_POINT + '/CrrIntervencionUnidadFuncional/guardarIntervencionUf/' + esBorrador, intervencion)
            .pipe(
                retry(1));
    }


    public getListaIntervencionDepartamentoPorIdIntervencion(idIntervencion, esBorrador): Observable<ListaIntervencionUfDepartamentoRpt> {
        return this.http.get<ListaIntervencionUfDepartamentoRpt>(this.END_POINT + '/CrrIntervencionUfDepartamento/list/' + idIntervencion + '/' + esBorrador)
            .pipe(
                retry(1));
    }

    public getObtenerIntervencionDepartamentoBorradorPorId(id): Observable<ConsultaIntervencionUfDepartamentoRpt> {
        return this.http.get<ConsultaIntervencionUfDepartamentoRpt>(this.END_POINT + '/CrrIntervencionUfDepartamento/obtenerIntervencionDepartamentoBorrador/' + id)
            .pipe(
                retry(1));
    }

    public guardarIntervencionDepartamentoBorrador(intervencionDepartamentoUf: IntervencionDepartamentoUf): Observable<ConsultaIntervencionUfDepartamentoRpt> {
        return this.http.post<ConsultaIntervencionUfDepartamentoRpt>(this.END_POINT + '/CrrIntervencionUfDepartamento/guardarIntervencionDepartamentoBorrador', intervencionDepartamentoUf)
            .pipe(
                retry(1));
    }


    public editarIntervencionPorUnidadFuncional(data, idIntervencion) {
       return this.http.put(this.END_POINT + '/CrrIntervencionUnidadFuncional/' + idIntervencion, data)
            .pipe(
                retry(1));
    }

    public eliminarIntervencionUfDepartamento(id): Observable<EliminarIntervencionUfDepartamentoRpt> {
        return this.http.delete<EliminarIntervencionUfDepartamentoRpt>(this.END_POINT + '/CrrIntervencionUfDepartamento/' + id)
            .pipe(
                retry(1));
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }


}

export class ListaIntervencionUfRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public intervenciones?: IntervencionUnidadFuncional[]
    ) { }
}

export class ConsultaIntervencionUfRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public intervencion?: IntervencionUnidadFuncional
    ) { }
}

export class ListaIntervencionUfDepartamentoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public intervencionesDepartamento?: IntervencionDepartamentoUf[]
    ) { }
}

export class ConsultaIntervencionUfDepartamentoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public intervencionDepartamento?: IntervencionDepartamentoUf
    ) { }
}

export class EliminarIntervencionUfDepartamentoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}
