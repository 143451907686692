import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carretero-home',
  templateUrl: './carretero-home.component.html',
  styleUrls: ['./carretero-home.component.sass']
})
export class CarreteroHomeComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: any, private route: Router) { }

  ngOnInit() {
    document.getElementById("nameseccion").innerHTML = "<span class='smallicon'><img src='assets/img/icn/carreteras_500-01-01.svg'/></span> Carreteras";
    var element = document.getElementById("aniscopio_body");
    element.className = "ani public logeo carreteros";
  }
  seguimientoProyectos() {
    var usuario = localStorage.getItem("nomUsu")
    if (usuario == null) {
      localStorage.setItem("backProj", "1")
    }
    sessionStorage.setItem('tipoProy', '2')
    this.route.navigate(['carreteros/infGeneral']);

  }

  transversales() {
    var usuario = localStorage.getItem("nomUsu")
    if (usuario == null) {
      localStorage.setItem("backProj", "2")
    }
    this.route.navigate(['/cargue'])
  }

  reportes() {
    var usuario = localStorage.getItem("nomUsu")
    if (usuario == null) {
      localStorage.setItem("backProj", "3")
    }
    this.route.navigate(['carreteros/reportes'])
  }

  irACarretero() {
    this.route.navigate(['/carreteros'])
  }



}
