import { Injectable } from '@angular/core';
import { ConfigService } from '../../../../config.service';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SeguimientoComunicacionesDetalleService {

  public END_POINT = '';
  public habilitarSolicitante: true;

    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };


    // Constructor de servicios

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

    // Servicios

  public setHabilitarSolicitante(payload) {
    this.habilitarSolicitante = payload;
  }

  public getHabilitarSolicitante() {
    return this.habilitarSolicitante ;
  }


  public getOrigen() {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/origenSolicitud')
      .pipe(
        retry(1));
  }

  public getSolicitante(tipo) {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/solicitante/' + tipo)
      .pipe(
        retry(1));
  }

  public getTipoSolicitud() {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/tipoSolicitud')
      .pipe(
        retry(1));
  }

  public getRemitentes() {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/remitentes')
      .pipe(
        retry(1));
  }


  public getDependencia() {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/dependencia')
      .pipe(
        retry(1));
  }

  public getGrupoInterno(id) {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/grupoInterno/'  + id)
      .pipe(
        retry(1));
  }

  public getNumeroRadicado(id) {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/generarRadicado/Email')
      .pipe(
        retry(1));
  }

  public getModo() {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/modo')
      .pipe(
        retry(1));
  }

  public getProyectosList(id) {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/proyecto/' + id)
      .pipe(
        retry(1));
  }

  public getProfesionalesById(id, tipo) {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/profesionalACargo/'  + id+ "/"+tipo)
      .pipe(
        retry(1));
  }

  public getProfesionales() {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/profesionalACargo/'  + 1+ '/GRUPO')
      .pipe(
        retry(1));
  }


// Servicios de Guardado

  public guardarDetalle(data) {
    return this.http.put(this.END_POINT + '/SolicitudComunicaciones/' , data)
      .pipe(
        retry(1));
  }

  public guardarNuevo(data) {
    return this.http.post(this.END_POINT + '/SolicitudComunicaciones/', data)
      .pipe(
        retry(1));
  }


  public getProyectoDetalle(id) {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/principal/' + id)
      .pipe(
        retry(1));
  }

}
