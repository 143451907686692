import { Component, OnInit } from '@angular/core';
import { InformeVoboBaseComponent } from '../informe-vobo-base/informe-vobo-base.component';
import { InformeRevisionService } from '../../../informe-revision.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from '../../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoInformeRevisionEstadoPersonaAprobacionDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-aprobacion';

@Component({
  selector: 'app-informe-vobo-aprobacion',
  templateUrl: './informe-vobo-aprobacion.component.html',
  styles: []
})
export class InformeVoboAprobacionComponent implements OnInit {

  // asignacionInforme: CtoInformeRevisionAsignacion;
  estadoPersonasInformeRevision: CtoInformeRevisionEstadoPersonaAprobacionDTO[];
  totalPaginator: any;
  visibleDialogoConfirmarAprobarInforme = false;

  constructor(
    private lineaBase: InformeVoboBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    // this.cargarAsignacionInformeRevision(this.lineaBase.idAsignacionParam, this.lineaBase.idPasoInformeParam, this.lineaBase.idPersonaAsignacionParam);
    this.cargarPersonaEstadoInformeRevision(this.lineaBase.idInformeParam, this.lineaBase.idVersionInformeParam, this.lineaBase.idPasoParam);
  }


  cargarPersonaEstadoInformeRevision(idInforme: number, idVersionInforme: number, idPaso: number) {
    this.infCtaSupervisionService.ConsultarPersonaEstadoInformeRevision(idInforme, idVersionInforme, idPaso)
      .subscribe(
        result => {
          // console.log('cargarPersonaEstadoInformeRevision');
          // console.log(JSON.stringify(result));
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.estadoPersonasInformeRevision = result.estadoPersonasAprobacionInformeRevision;
              this.totalPaginator = this.estadoPersonasInformeRevision.length;
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  aprobarRechazarInformeRevision(aprobo: boolean) {
    this.visibleDialogoConfirmarAprobarInforme = false;
    // console.log('aprobarRechazarInformeRevision');
    this.lineaBase.blockedDocument = true;
    if (this.lineaBase.asignacionInforme != null) {
      this.lineaBase.asignacionInforme.aprobo = aprobo;
      // console.log(JSON.stringify(this.lineaBase.asignacionInforme));
      if (this.validarAprobarRechazarInformeRevision()) {
        this.infCtaSupervisionService.AprobarRechazarInformeRevision(this.lineaBase.idInformeParam, this.lineaBase.idPasoParam, this.lineaBase.idVersionInformeParam, this.lineaBase.asignacionInforme)
          .subscribe(
            result => {
              // console.log(JSON.stringify(result));
              if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                this.lineaBase.mensajeDialogo = result.respuestaServicio.mensajeSalida;
                this.lineaBase.visibleDialogoExito = true;
                // this.router.navigate(['/contratacionSupervisionHome/informesContratistaSupervisor/']);
              } else {
                this.lineaBase.mensajeDialogo = result.respuestaServicio.mensajeSalida;
                this.lineaBase.visibleDialogoError = true;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              }
            },
            error => {
              console.error(error);
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
              this.lineaBase.blockedDocument = false;
              this.ngOnInit();
            }
          );
      } else {
        this.lineaBase.blockedDocument = false;
      }
    }
  }

  validarAprobarRechazarInformeRevision(): boolean {
    let accionValida = true;
    if (!this.lineaBase.asignacionInforme.aprobo) {
      if (this.lineaBase.asignacionInforme.observacionDevolucion == null) {
        accionValida = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.lineaBase.msgValObservacionRechazo, life: 10000 });
        return accionValida;
      }
      if (this.lineaBase.asignacionInforme.observacionDevolucion != null &&
        (this.lineaBase.asignacionInforme.observacionDevolucion == "" || this.lineaBase.asignacionInforme.observacionDevolucion.length == 0 || this.lineaBase.asignacionInforme.observacionDevolucion.length < 10)) {
        accionValida = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.lineaBase.msgValObservacionRechazoMinimo, life: 10000 });
        return accionValida;
      }
    }
    return accionValida;
  }
}
