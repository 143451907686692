import { Component, OnInit, Inject  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InformacionGeneralService } from '../informacion-general/informacion-general.service';
import { environment } from '../../environments/environment';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-transversales',
  templateUrl: './transversales.component.html',
  styleUrls: ['./transversales.component.sass']
})
export class TransversalesComponent implements OnInit {
  proyecto: any;
  id: number;
  esProduccion: boolean = false;
  webUrlConcesiones : any;


  constructor(private infoService: InformacionGeneralService, private route: ActivatedRoute, private router: Router, @Inject(DOCUMENT) private document: any,
  private messageService: MessageService, private principalComponent: PrincipalComponent) {
   }

  ngOnInit() {
    this.esProduccion = environment.esProduccion
    this.webUrlConcesiones = environment.webUrlConcesiones;
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAGeneral() {
    this.router.navigate(['/infGeneral', this.id]);
  }

  irAAmbiental() {
      this.router.navigate(['/infAmbiental', this.id]);
  }

  irAPredial() {
    this.router.navigate(['/infPredial', this.id]);
  }

  irARegistro() {
    this.document.location = "https://concesionessherlockaniwebtest.azurewebsites.net/concesiones/" + this.id + "/dashboard";
  }
  
  irAInfInterventoriaMinTrans() {
    this.document.location = "https://gpi.mintransporte.gov.co/reports/loadReports"
  }

  irAUnidadesFuncionales() {
    this.router.navigate(['/uniFuncionales', this.id])
  }

  irAFinancieroHome(){
    this.router.navigate(['/financieroHome', this.id]);
  }
}
