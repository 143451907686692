import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comisiones-reporte-ejecutivo',
  templateUrl: './comisiones-reporte-ejecutivo.component.html',
  styles: []
})
export class ComisionesReporteEjecutivoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
  }

}
