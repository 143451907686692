import { Component, OnInit } from '@angular/core';
import { PrincipalComponent } from '../../home/principal/principal.component';
import { DatosabiertosService, ProyectoOcds } from '../datos-abiertos.service';
import * as FileSaver from 'file-saver';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';

@Component({
  selector: 'app-da-ocds',
  templateUrl: './da-ocds.component.html',
  styleUrls: ['./da-ocds.component.sass']
})
export class DaOcdsComponent implements OnInit {
  proyectoSeleccionado: any;
  proyectoFiltro: any = [];
  private proyectos: any[] = [];
  modoSeleccionado: any;
  modoFiltro: any = [];
  private modos: any[] = [];
  generacionSeleccionada: any;
  generacionFiltro: any = [];
  private generaciones: any[] = []

  proyectosOcds: ProyectoOcds[] = [];
  
  columnas: any[];
  exportColumns: any[];
  documentoBloqueado: boolean = true;
  loading: boolean = false;

  detalleProyecto: any;
  mostrarDetalle: boolean = false;

  constructor(private datosAbiertosService: DatosabiertosService, private componentePrincipal: PrincipalComponent) {
    this.datosAbiertosService.getProyectoOcds().subscribe(respuesta => {
      if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        console.error(respuesta.respuestaServicio.mensajeSalida);
        this.componentePrincipal.cargarErrorServicio(respuesta.respuestaServicio.mensajeSalida);
        return;
      }
    
      this.proyectos = respuesta.proyectos;
    },
    error => {
      this.documentoBloqueado = false;
      console.error(error);
      this.componentePrincipal.cargarErrorServicio(error);
    },
    () => {
      this.documentoBloqueado = false;
    });
    
    this.datosAbiertosService.getModo().subscribe(respuesta => {
      if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        console.error(respuesta.respuestaServicio.mensajeSalida);
        this.componentePrincipal.cargarErrorServicio(respuesta.respuestaServicio.mensajeSalida);
        return;
      }
    
      this.modos = respuesta.modoTransporte.filter(modo => modo.idModo != 3 && modo.idModo != 5);
    },
    error => {
      this.documentoBloqueado = false;
      console.error(error);
      this.componentePrincipal.cargarErrorServicio(error);      
    },
    () => {
      this.documentoBloqueado = false;
    });
    
    this.datosAbiertosService.getGeneracion().subscribe(respuesta => {
      if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        console.error(respuesta.respuestaServicio.mensajeSalida);
        this.componentePrincipal.cargarErrorServicio(respuesta.respuestaServicio.mensajeSalida);
        return;
      }
    
      this.generaciones = respuesta.generacion;     
    },
    error => {
      this.documentoBloqueado = false;
      console.error(error);
      this.componentePrincipal.cargarErrorServicio(error);      
    },
    () => {
      this.documentoBloqueado = false;
    });
  }

  ngOnInit() {
    this.documentoBloqueado = true;

    this.columnas = [{ field: 'nombreProyecto', header: 'Nombre' },
    { field: 'idOcds', header: 'ID OCDS' },
    { field: 'ubicacion', header: 'Ubicacion' },
    { field: 'descripcionServicios', header: 'Descripcion Servicios' },
    { field: 'entidad', header: 'Entidad' },
    { field: 'presupuesto', header: 'Presupuesto' }];
    
    this.exportColumns = this.columnas.map(col => ({ title: col.header, dataKey: col.field }));
  }

  public filtrarProyectos(event: any) {
    this.proyectoFiltro = this.proyectos.filter(p => p.nombre.toLowerCase().includes(event.query.toLowerCase()));
  }
  
  public filtrarModo(event: any){   
    this.modoFiltro = this.modos.filter(p => p.nombre.toLowerCase().includes(event.query.toLowerCase()));
  }

  public filtrarGeneracion(event: any) {
    this.generacionFiltro = this.generaciones.filter(p => p.nombre.toLowerCase().includes(event.query.toLowerCase()));
  }

  public consultarProyecto() {
    let idProyecto = this.proyectoSeleccionado != null ? this.proyectoSeleccionado.idProyecto : null;
    let modo = this.modoSeleccionado != null ? this.modoSeleccionado.nombre : null;
    let generacion = this.generacionSeleccionada != null ? this.generacionSeleccionada.nombre : null;

    this.documentoBloqueado = true;
    this.datosAbiertosService.getResumenProyectoOcds(idProyecto, modo, generacion).subscribe(dato => {
      if (dato.respuestaServicio.codigoSalida == EnumRespuestaServicio.CODIGO_ERROR_OPERACION) {
        this.componentePrincipal.cargarErrorServicio(dato.respuestaServicio.mensajeSalida);
        return;
      }

      this.proyectosOcds = dato.proyectos;
      this.mostrarListado();
    },
    error => {
      this.documentoBloqueado = false;
      console.error(error);
      this.componentePrincipal.cargarErrorServicio(error);  
    },
    () => {
     this.documentoBloqueado = false;
    });
  }

  public limpiarBusqueda() {
    this.proyectoSeleccionado = null;
    this.modoSeleccionado = null;
    this.generacionSeleccionada = null;
    this.proyectosOcds = [];
    this.mostrarListado();
  }

  public obtenerDetalleProyecto(idProyecto: number) {
    this.documentoBloqueado = true;
    this.datosAbiertosService.getOcdsJson(idProyecto).subscribe(respuesta => {
      if (respuesta.respuestaServicio.codigoSalida == EnumRespuestaServicio.CODIGO_ERROR_OPERACION) {
        this.componentePrincipal.cargarErrorServicio(respuesta.respuestaServicio.mensajeSalida);
        return;
      }

      this.detalleProyecto = JSON.parse(respuesta.ocdsJson).compiledRelease;
      let descripcion = this.detalleProyecto.description.split(' -- ');
      this.detalleProyecto.description = descripcion[0];
      this.detalleProyecto.intervencion = descripcion[1];
      this.detalleProyecto.idProyecto = idProyecto;
      this.mostrarDetalle = true;
    },
    error => {
      this.documentoBloqueado = false;
      console.error(error);
      this.componentePrincipal.cargarErrorServicio(error);
    },
    () => {
      this.documentoBloqueado = false;
    });
  }

  public downloadOcdsJson(idProyecto: number) {
    this.documentoBloqueado=true;
    if (this.mostrarDetalle) {
      var element = document.getElementById('loaddatos');
      element.classList.remove('none');
      this.loading = true;
    }

    this.datosAbiertosService.getOcdsJson(idProyecto).subscribe(respuesta => {
      if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        this.componentePrincipal.cargarErrorServicio(respuesta.respuestaServicio.mensajeSalida);
      }

      let blob = new Blob([respuesta.ocdsJson], { type: 'application/json; charset=utf-8'});
      FileSaver.saveAs(blob, `ocds-fx9mp0-${idProyecto}.json`);
    },
    error => {
      this.documentoBloqueado = false;
      if (this.mostrarDetalle) {
        var element = document.getElementById('loaddatos');
        element.classList.add('none');
        this.loading = false;
      }
      console.error(error);
      this.componentePrincipal.cargarErrorServicio(error);
    },
    () => {
      this.documentoBloqueado = false;
      if (this.mostrarDetalle) {
        var element = document.getElementById('loaddatos');
        element.classList.add('none');
        this.loading = false;
      }
    });
  }

  public exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.proyectosOcds.map(proyecto => {
        var proyectoMapeado = {};
        proyecto = Object.assign({}, proyecto);
        proyecto.descripcionServicios = `${proyecto.descripcionServicios} -- ${proyecto.tiposIntervencion}`;
        this.columnas.forEach(columna => {
          proyectoMapeado[columna.header] = proyecto[columna.field];
        });
        return proyectoMapeado;
      }));
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "proyectos-ocds");
    });
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public mostrarListado() {
    this.mostrarDetalle = false;
    this.detalleProyecto = null;
  }

  exportPaqueteOcds(): void {
    this.documentoBloqueado = true;

    this.datosAbiertosService.getPaqueteOcdsJson(this.proyectosOcds.map(proyecto => proyecto.idProyecto))
    .subscribe(respuesta => {
      if (respuesta.respuestaServicio.codigoSalida !== EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        this.componentePrincipal.cargarErrorServicio(respuesta.respuestaServicio.mensajeSalida);        
      }
      
      let blob = new Blob([respuesta.ocdsJson], { type: 'application/json; charset=utf-8'});
      FileSaver.saveAs(blob, 'paquete-OCDS.json');
    },
    error => {
      this.documentoBloqueado = false;
      console.error(error);
      this.componentePrincipal.cargarErrorServicio(error);
    },
    () => {
      this.documentoBloqueado = false;
    })
  }
}
