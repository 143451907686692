import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EjecucionProyectoService } from './ejecucion_proyecto.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ComponentesComunesService } from '../componentes-comunes/componentes-comunes.service';
import { ProyectoDTO, VwCrrAvanceUfDTO } from '../componentes-comunes/componentes-comunes.dto';
import { EjecucionDTO } from './ejecucion-proyecto.dto';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-avance-proyecto',
  templateUrl: './avance-proyecto.component.html',
  styleUrls: ['./avance-proyecto.component.sass']
})
export class AvanceProyectoComponent implements OnInit {

  vistaActual: string = "";
  idProyecto: number;
  blockedDocument: boolean = false;

  proyectoSeleccionado: ProyectoDTO;

  constructor(private route: Router, private ruta: ActivatedRoute,
    private ejecucionProyectoService: EjecucionProyectoService,
    private componentesComunesService: ComponentesComunesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    //Bloquear pantalla
    //this.blockedDocument = true;

    this.ruta.params.subscribe(params => {
      this.idProyecto = params.id;
      this.cargarInfoAvanceProyecto();
      this.vistaActual = "menus";
    });
  }

  cargarInfoAvanceProyecto() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(([data1]) => {
      this.proyectoSeleccionado = data1;
    }, error => {
    },
      () => {
        //this.blockedDocument = false;
      }
    );

  }

  outputIrAReportarAvance(event): void {
    //Bloquear pantalla
    //this.blockedDocument = true;
    //this.cargarInfoAvanceProyecto();
    this.vistaActual = "reportarAvance";
  }

  outputIrAReportarEtapas(event): void {
    //Bloquear pantalla
    //this.blockedDocument = true;
    //this.cargarInfoAvanceProyecto();
    this.vistaActual = "reportarEtapas";
  }

  outputIrAAvanceProyectoMenu(event): void {
    this.vistaActual = "menus";
  }





}
