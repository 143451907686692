import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatTableDataSource , MatPaginator} from '@angular/material';
import { SeguimientoTribunalesService } from './seguimiento-tribunales.service';
import { ProgressSpinnerDialogComponent } from '../../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

import { SeguimientoDTO,
  UnidadesFuncionales,
  Estados,
  Priorizaciones,
  Observaciones,
  Responsables } from '../../../../dtos/juridico/tribunales/seguimientoDTO';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'eliminar-dialog',
    templateUrl: '../eliminar/eliminar.html',
  })
  // tslint:disable-next-line:component-class-suffix
  export class EliminiarDialog {

  confirmacion = false;
  constructor(
    public dialogRef: MatDialogRef<EliminiarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onNoClick(payload): void {
      this.dialogRef.close();
  }

  onConfirm(): void {
      this.dialogRef.close(true);
  }

  onDismiss(): void {
      // Close the dialog, return false
      this.dialogRef.close(false);
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'actuacion-dialog',
  templateUrl: './actuacion.html',
})
// tslint:disable-next-line:component-class-suffix
export class ActuacionDialog {
  maxDate: Date;
  enableAdministrativo = false;

  constructor(
    public dialogRef: MatDialogRef<ActuacionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeEstado(event){
    if (event === 9) {
      this.enableAdministrativo = true;
    } else {
      this.enableAdministrativo = false;
      this.data.actoAdministrativo = null;

    }
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'notificacion-dialog',
  templateUrl: './notificacion.html',
})
// tslint:disable-next-line:component-class-suffix
export class NotificacionDialog {
  maxDate: Date;

  constructor(
    public dialogRef: MatDialogRef<NotificacionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'secretario-dialog',
  templateUrl: './secretario.html',
})
// tslint:disable-next-line:component-class-suffix

export class SecretarioDialog implements OnInit {

  municipios: any = [];
  temp;
  disabledEntidad = false;
  maxDate: Date;
  idPersona;
  namePersona;
  activeForm = true;

  constructor(
    public dialogRef: MatDialogRef<SecretarioDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private detalleService: SeguimientoTribunalesService,
    ) {
      this.temp = data;
      this.maxDate = new Date();
      this.namePersona = data.namePersona;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}


  displayCounter(count) {
    this.data.idPersona = count;
}

}

@Component({
  selector: 'app-seguimiento-tribunales',
  templateUrl: './seguimiento-tribunales.component.html',
  styleUrls: ['./seguimiento-tribunales.component.sass']
})

export class SeguimientoTribunalesComponent implements OnInit {

  estados: any = [];
  priorizaciones: any = [];
  observacionesDialog: any = [];
  responsables: any = [];
  departamentos: any = [];
  municipios: any = [];
  temporalEnviar;
  fechaReporte: Date;
  actoAdministrativo: string;
  observaciones: string;
  panelOpenState = false;
  actuacionTemporal: any = {
    idTribunal: null,
    idTipo: null,
    fecha: '',
    descripcion: '',
    idTipoProximaActuacion: '',
    idDocumento: 2,
    fechaSiguienteActuacion: '',
    descripcionProximaActuacion: ''
  };

  notificacionTemporal: any = {
    idTribunal: null,
    fecha: '',
    descripcion: '',
  };

  personaTemporal: any = {
    idEncabezado: null,
    idTribunal: null,
    idTipoPersona: null,
    fechaInicio: '',
    fechaFin: '',
    idDocumento: null,
    observaciones: '',
    personas : [
      {
            idEncabezado: null,
            idTribunal: null,
            idPersona : null,
            esNuevo : true
      }
  ]
  };

  proyectos: SeguimientoDTO = {};
  id: number;
  nombreProyecto;
  tipos: any = [];
  tipos_personas: any = [];
  enableArbitros = false;
  enableForm = true;

  displayedArbitros: string[] = ['correoElectronico', 'primerNombre', 'otrosNombres', 'primerApellido', 'segundoApellido', 'acciones'];
  displayedSecretario: string[] = ['correoElectronico', 'primerNombre', 'otrosNombres', 'primerApellido', 'segundoApellido', 'acciones'];
  displayedProcurador: string[] = ['correoElectronico', 'primerNombre', 'otrosNombres', 'primerApellido', 'segundoApellido', 'acciones'];
  displayedApoderado: string[] = ['correoElectronico', 'primerNombre', 'otrosNombres', 'primerApellido', 'segundoApellido', 'acciones'];
  displayedResponsable: string[] = ['correoElectronico', 'primerNombre', 'otrosNombres', 'primerApellido', 'segundoApellido', 'acciones'];
  displayedApoderadoContraparte: string[] = ['correoElectronico', 'primerNombre', 'otrosNombres', 'primerApellido', 'segundoApellido', 'acciones'];
  displayedActuacion: string[] = ['tipo', 'fecha', 'tipoProximaActuacion','descripcionProximaActuacion'];
  displayedNotificacion: string[] = ['fecha', 'descripcion'];

  dataSourceArbitro: MatTableDataSource<Priorizaciones>;
  dataSourceSecretario: MatTableDataSource<Priorizaciones>;
  dataSourceProcurador: MatTableDataSource<Priorizaciones>;
  dataSourceApoderado: MatTableDataSource<Priorizaciones>;
  dataSourceResponsable: MatTableDataSource<Priorizaciones>;
  dataSourceApoderadoContraparte: MatTableDataSource<Priorizaciones>;
  dataSourceActuacion: MatTableDataSource<Priorizaciones>;
  dataSourceNotificacion: MatTableDataSource<Priorizaciones>;

  @ViewChild(MatPaginator, { static: true }) paginatorArbitro: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortArbitro: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginatorSecretario: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortSecretario: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginatorProcurador: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortProcurador: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginatorApoderado: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortApoderado: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginatorResponsable: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortResponsable: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginatorApoderadoContraparte: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortApoderadoContraparte: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginatorActuacion: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortActuacion: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginatorNotificacion: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortNotificacion: MatSort;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(
    private service: SeguimientoTribunalesService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog, private principalComponent: PrincipalComponent) {
  }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarProyecto(this.id);
      this.cargarTipos();
      this.cargarTiposPersonas();
      // localStorage.setItem('idP', JSON.stringify(this.id));
    });
  }

  cargarProyecto(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.service.obtenerListaSeguimiento(id).subscribe((data: {}) => {
      if (data) {
        this.proyectos = data;
        this.initData(this.proyectos);

      }
      this.dialogSpin.close();
    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  initData(payload) {
    this.dataSourceArbitro = new MatTableDataSource(payload.arbitros ? payload.arbitros.personas : []);
    //this.enableArbitros = payload.arbitros.personas.length > 2;
    if (payload.arbitros) {
      payload.arbitros.personas.length >= 3 ? this.enableArbitros = true : this.enableArbitros = false;
    }
    this.dataSourceSecretario = new MatTableDataSource(payload.secretario ? payload.secretario.personas : []);
    this.dataSourceProcurador = new MatTableDataSource(payload.procuradorJudicial ? payload.procuradorJudicial.personas : []);
    this.dataSourceApoderado = new MatTableDataSource(payload.apoderado ? payload.apoderado.personas : []);
    this.dataSourceResponsable = new MatTableDataSource(payload.responsableGerencia ? payload.responsableGerencia.personas : []);
    this.dataSourceApoderadoContraparte = new MatTableDataSource(payload.apoderadoContraparte ? payload.apoderadoContraparte.personas : []);
    this.dataSourceActuacion = new MatTableDataSource(payload.actuacion ? payload.actuacion : []);
    this.dataSourceNotificacion = new MatTableDataSource(payload.notificacion ? payload.notificacion : []);
  }

  cargarTipos() {
    return this.service.getTiposActuacion('T').subscribe((data: {}) => {
      this.tipos = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarTiposPersonas() {
    return this.service.getTiposPersonas().subscribe((data: {}) => {
      this.tipos_personas = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarPriorizaciones() {
    return this.service.getPriorizacion().subscribe((data: {}) => {
      this.priorizaciones = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cancelar() {
    this.route.navigate(['/infTribunales', JSON.parse(localStorage.getItem('idP'))]);
  }

  getTipo(data) {
    if (data) {
      const dataLength = this.tipos_personas.length;
      for (let i = 0; i <= dataLength; i++) {
        if (this.tipos_personas[i].nombre === data) {
          return this.tipos_personas[i].id;
        }
      }
    } else {
      return '';
    }
  }

  getQuery(data) {
    if (data) {
      switch (data) {
        case 'Secretario':
          return 'secretario';
        case 'Arbitro':
            return 'arbitro';
        case 'Responsable gerencia':
            return 'responsableGerencia';
        case 'Apoderado':
            return 'apoderado';
        case 'Procurador judicial':
            return 'procuradorJudicial';
        case 'Apoderado Contraparte':
            return 'apoderadoContraparte';
       default:
        // code...
        break;
        }
    }
  }

  getFechaInicio(data) {
    if (data) {
      switch (data) {
        case 'Secretario':
          return '';
        case 'Arbitro':
            return 'arbitro';
        case 'Procurador judicial':
            return 'procuradorJudicial';
        case 'Responsable gerencia':
            return 'responsableJudicial';
        case 'Apoderado':
            return 'apoderado';
        case 'Procurador judicial':
            return 'procuradorJudicial';
        case 'Apoderado Contraparte':
            return 'apoderadoContraparte';
       default:
        // code...
        break;
        }
    }
  }

  modalActuacion() {
      const dialogRef = this.dialog.open(ActuacionDialog, {width: '600px', data: {tipos: this.tipos}});
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {
            if( result.idTipo === result.idTipoProximaActuacion) {
              alert('El tipo de Actuación no puede ser igual a la proxima actuación')
            } else {
              this.actuacionTemporal = {
                idTribunal: Number(this.id),
                idTipo: result.idTipo,
                fecha: result.fecha,
                descripcion: result.descripcion,
                idTipoProximaActuacion: result.idTipoProximaActuacion,
                idDocumento: 1,
                fechaSiguienteActuacion: result.fechaSiguienteActuacion,
                descripcionProximaActuacion: result.descripcionProximaActuacion,
              };
              this.temporalEnviar = {};
              this.temporalEnviar = this.actuacionTemporal;
              this.service.guardarSeguientoActuacion(this.temporalEnviar).subscribe((data: {}) => {
                if (data) {
                  this.initData(data);
                }
              },error => {
                this.principalComponent.cargarErrorServicio(error);
              });
            }
           }
          });

  }

  modalNotificacion() {
      const dialogRef = this.dialog.open(NotificacionDialog, {width: '600px', data: {}});
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {
            this.notificacionTemporal = {
              idTribunal: Number(this.id),
              fecha: result.fecha,
              descripcion: result.descripcion,

            };
            this.temporalEnviar = {};
            this.temporalEnviar = this.notificacionTemporal;
            this.service.guardarSeguientoNotificacion(this.temporalEnviar).subscribe((data: {}) => {
              if (data) {
                this.initData(data);
              }
            },error => {
              this.principalComponent.cargarErrorServicio(error);
            });
           }
          });

  }

  modalPersona(idPayload) {

    if (idPayload === 'Arbitro') {
      this.proyectos.arbitros === null ? this.enableForm = true : this.enableForm = false;
    } else {
      this.enableForm = true;
    }

    const dialogRef = this.dialog.open(SecretarioDialog, {width: '600px', data: {
      namePersona: idPayload,
      activeForm : this.enableForm,
    }});
    dialogRef.afterClosed().subscribe(result => {
        if (result != null && result !== '')  {

          this.personaTemporal = {
            idEncabezado: idPayload === 'Arbitro' && this.proyectos.arbitros ? this.proyectos.arbitros.idEncabezado : 0,
            idTribunal: Number(this.id),
            idTipoPersona: this.getTipo(idPayload),
            fechaInicio:  idPayload === 'Arbitro' && this.proyectos.arbitros ? this.proyectos.arbitros.fechaInicio : result.fechaInicio,
            fechaFin: idPayload === 'Arbitro' && this.proyectos.arbitros ? this.proyectos.arbitros.fechaFin : result.fechaFin,
            idDocumento: 1,
            observaciones: result.observaciones,
            personas : [
              {
                    idEncabezado: 0,
                    idTribunal: Number(this.id),
                    idPersona : result.idPersona,
                    esNuevo : true
              }
          ]
          };
          this.temporalEnviar = {};
          this.temporalEnviar = this.personaTemporal;
          this.service.guardarSeguientoAsignar(this.temporalEnviar, this.getQuery(idPayload)).subscribe((data: {}) => {
            if (data) {
              // this.initData(data);
              this.cargarProyecto(this.id);
            }
          },error => {
            this.principalComponent.cargarErrorServicio(error);
          });
         }
        });

}


}


