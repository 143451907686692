import { Component, OnInit,EventEmitter, ChangeDetectorRef,  } from '@angular/core';
import { NewEditRadicacionWebService, } from './new-edit-radicacion-web.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneral, InformacionGeneralRadicacion, ArchivoRemisorio, ArchivoAnexo, RwbDocumentoRadicadoDTO } from './informacionGeneral';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as _moment from 'moment';
import { EnumRegex } from 'src/app/comun/constantes/constantes-comunes';



@Component({
    selector: 's-new-edit-radicacion-web',
    templateUrl: './new-edit-radicacion-web.component.html',
    styleUrls: ['./new-edit-radicacion-web.component.css']
})
export class NewEditRadicacionWebComponent implements OnInit  {

    loading: boolean;
    modulo: string;
    esBlob: boolean = false;
    contenedor: string;
    
    isEditable: boolean;
    btnRadicar: boolean;
    btnGuardarBorrador: boolean;
    tabAnexosAdicionales: boolean;
    tabAnexoRemisorio: boolean;
    checkAnexoRemisorio: boolean;
    visibleConfirmarRadicacion: boolean;
    modoRadicado: boolean;
    isFullArchivo: boolean;
    isFullArchivoAnexo: boolean;
    mostrarCargue: boolean;
    idProyecto: number;
    listadoAnexosAdicionales: any = [];
    listadoRemisorio: any = [];
    listadoFirmantes: any = [];
    volverListado: any;
    temporal: any; 
    temporalListado: any; 


    informacion: InformacionGeneral = {};
    informacionGeneral : InformacionGeneralRadicacion = {};
    rwbDocumentoRadicado : RwbDocumentoRadicadoDTO = {};
    idDocumentoRadicado : any;
    textoRadicado : any;

    tipoDocumentoSeleccionada: any;
    listaTipoDocumento: any = [];

    //Archivo Remisorio
    tipoSoporteSeleccionado: any;
    tipoSoporteSeleccionadoAnexo: any;
    tiposSoporte: any = [];
    filtroTiposSoporte: any[];
    public files: NgxFileDropEntry[] = [];
    public _files: ArchivoRemisorio[] = [];
    public _filesTemp: ArchivoRemisorio[] = [];

    public filesAnexo: NgxFileDropEntry[] = [];
    public _filesAnexo: ArchivoAnexo[] = [];
    public _filesAnexoTemp: ArchivoAnexo[] = [];

    idArchivosChange = new EventEmitter<ArchivoRemisorio[]>();
    OutputValidacionArchivos = new EventEmitter();
    ocurrioAlgunError: boolean;
    ocurrioAlgunErrorAnexo: boolean;
    tamanioMegas :any;
    tamanioMegasStr :any;
    tamanioAnexoMegas :any;
    tamanioAnexoMegasStr :any;
    numeroArchivos :any;
    numeroArchivosAnexo :any;
    numeroArchivosCargadosAnexo :any;
    errorNumeroArchivo: boolean;
    errorNumeroArchivoAnexo: boolean;
    idArchivos: ArchivoRemisorio[];
    id: string;
    idAnexo: string;
    soloUnArchivo: boolean;
    nombreInicialArchivo: string;
    nombreInicialArchivoAnexo: string;
    displayedColumns1;
    listado: any = [];
    habilitaEnvio: boolean = true;

    constructor(private _service: NewEditRadicacionWebService,
        private router: Router,
        private ruta: ActivatedRoute,
        private messageService: MessageService,
        private principalComponent: PrincipalComponent) { }

    ngOnInit() {
        this.loading = true;
        this.isEditable = true;
        this.textoRadicado = "";
        this.btnRadicar = false;
        this.tabAnexosAdicionales = false;
        this.tabAnexoRemisorio = false;
        this.checkAnexoRemisorio = false;
        this.btnGuardarBorrador = true;
        this.visibleConfirmarRadicacion = false;
        this.isFullArchivo = false;
        this.isFullArchivoAnexo = false;
        this.modoRadicado = false;
        this.mostrarCargue = false;
        this.numeroArchivosCargadosAnexo = this.listadoAnexosAdicionales.length;
        this.displayedColumns1 = [
          { field: 'nombreArchivoUsuario', header: 'Nombre Archivo' },
          { field: 'fechaInicioCargaStr', header: 'Fecha Carga' },
          { field: 'rutaSoporte', header: 'Ruta Soporte' }
        ];


        this.idDocumentoRadicado = localStorage.getItem('idDocumentoRadicado');
        this.volverListado = localStorage.getItem('volverListado');

        this.modulo = "radicacion-web";
        this.nombreInicialArchivo = "RAD-WEB-REMISORIO-";
        this.nombreInicialArchivoAnexo = "RAD-WEB-ADICIONALES-";
        this.esBlob = true;
        this.contenedor = "radicacion-web";
        this.id = "radicacion-web_remisorio_";
        this.idAnexo = "radicacion-web_anexo_";

        //configuracion archivo remisorio
        this.tamanioMegas = 2268372; //2MB  --2268372
        this.numeroArchivos = 1;
        this.errorNumeroArchivo = false;
        this.tamanioMegasStr =  Math.round((this.tamanioMegas / (1024 * 1024 ))) + " Megas";
        this.tipoSoporteSeleccionado = { "formatoPermitido": ".pdf,.png,.jpg,.jpeg"};

        //configuracion archivo anexos
        this.tipoSoporteSeleccionadoAnexo = { "formatoPermitido": ".zip,.rar"};
        this.tamanioAnexoMegas = 524288000 ;   //500MB  --524288000  bynary    30MB ---31457280 bynary
        this.numeroArchivosAnexo = 3;
        this.errorNumeroArchivoAnexo = false;
        this.tamanioAnexoMegasStr =  Math.round((this.tamanioAnexoMegas / (1024 * 1024 ))) + " Megas";

        this.ruta.params.subscribe(params => {
            this.idProyecto = params.id;
        });
        this.getListaTipoDocumento();
        this.tipoDocumentoSeleccionada = { id: 0, nombre: 'Seleccione...'};

        this._service.getDatosInformacion(this.idProyecto).subscribe((data: {}) => {
            this.temporal = data;
            if(this.temporal != undefined && this.temporal != null){
                    this.informacion = this.temporal;     
            }            
            this.loading = false;
            }, (error)=>{
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });

        if(this.idDocumentoRadicado!= undefined && this.idDocumentoRadicado != null &&
          this.idDocumentoRadicado != "" && this.idDocumentoRadicado!= "0" && this.idDocumentoRadicado!= "null"){
              this._service.getDatosInformacionRadicado(this.idDocumentoRadicado).subscribe((data: {}) => {
                this.temporal = data;
                if(this.temporal != undefined && this.temporal != null ){
                  this.informacionGeneral = this.temporal;    
                  this.tipoDocumentoSeleccionada.id = this.informacionGeneral.idTipoDocumento;
                  this.tipoDocumentoSeleccionada.nombre = this.informacionGeneral.tipoDocumento;

                  if(this.informacionGeneral.numeroTemporal!= null && 
                    this.informacionGeneral.numeroRadicacion == null){
                    this.textoRadicado = "Borrador con Número Temporal "+ this.informacionGeneral.numeroTemporal;
                    this.btnRadicar = true;
                    this.tabAnexosAdicionales = true;
                    this.tabAnexoRemisorio = true;
                    this.btnGuardarBorrador = true;
                    this.isEditable = true;
                  }else{
                    this.textoRadicado = "Radicado con Número "+ this.informacionGeneral.numeroRadicacion;
                    this.btnRadicar = false;
                    this.tabAnexosAdicionales = true;
                    this.tabAnexoRemisorio = true;
                    this.btnGuardarBorrador = false;
                    this.isEditable = false;
                  }
                }            
                this.loading = false;
                }, (error)=>{
                this.loading = false;
                this.principalComponent.cargarErrorServicio(error);
              });
              this.cargarlistadoRemisorio();
          }else{
            this.textoRadicado = "Borrador Nuevo";
            this.btnRadicar = false;
            this.tabAnexosAdicionales = false;
            this.tabAnexoRemisorio = false;
            this.btnGuardarBorrador = true;
          }
    }
    

    /**
    * Metodo que devuelve la pagina
    */
    bntVolver() {    
      localStorage.setItem('idDocumentoRadicado', null);
      this.router.navigate([this.volverListado]);
    }

    /**
     *Metodo que carga la pantalla de los firmantes
     *
     * @memberof NewEditRadicacionWebComponent
     */
    editFirmantes(){
      localStorage.setItem('idProyecto', String(this.idProyecto) );
      localStorage.setItem('volverListadoRadicacionEdit', this.router.url );
      this.router.navigate([this.router.url + '/editFirmantesRwb']);
    }



    /**
     * Metodo que carga la lista de tipo solicitudd
     */
     getListaTipoDocumento(){
        this.loading = true;
        this._service.getListadoTipoDocumentoRadicado().subscribe((data: {}) => {
           this.listaTipoDocumento = data;
           this.loading = false;
        },error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     *Metodo que valida si guardo para activar el cargue de archivo de remisorio
     *
     * @param {*} isRemisorio
     * @memberof NewEditRadicacionWebComponent
     */
    preguntarGuardado(isRemisorio) {
        if(isRemisorio != null  && isRemisorio != false){

        }
    }

    /**
     * Metodo que guarda temporalmente el borrador.
     */
    bntGuardarBorrador(){
      if(this.informacionGeneral.asunto != null &&
        this.informacionGeneral.asunto != "" &&
        this.informacionGeneral.descripcion != null &&
        this.informacionGeneral.descripcion != "" &&
        this.tipoDocumentoSeleccionada != null &&
        this.tipoDocumentoSeleccionada.id != null &&
        this.tipoDocumentoSeleccionada.id != "0" &&
        this.informacionGeneral.correoElectronicoPersonal != null &&
        this.informacionGeneral.correoElectronicoPersonal != ""
        ){
          this.loading = true;
          this.informacionGeneral.idTipoDocumento = this.tipoDocumentoSeleccionada.id;
          this.informacionGeneral.idPersonaJuridica = this.informacion.idPersonaJuridica;
          this.informacionGeneral.tipoBorradorFinalizado = "B";
          this.informacionGeneral.idProyecto = this.idProyecto;

          var json = JSON.stringify(this.informacion);
          this.informacionGeneral.datosRemitenteJson = json.toString();

          this._service.postDocumentoBorrador(this.informacionGeneral).subscribe((data: {}) => {
            this.temporal = data;
            if(this.temporal != undefined && this.temporal != null){
              this.informacionGeneral = this.temporal;    
              if(this.informacionGeneral.numeroTemporal!= null && 
                this.informacionGeneral.numeroRadicacion == null){
                this.textoRadicado = "Borrador con Número Temporal "+ this.informacionGeneral.numeroTemporal;
                this.btnRadicar = true;
                this.tabAnexosAdicionales = true;
                this.tabAnexoRemisorio = true;
                this.btnGuardarBorrador = true;
                this.isEditable = true;
                this.idDocumentoRadicado = this.informacionGeneral.id;
              }else{
                this.textoRadicado = "Radicado con Número "+ this.informacionGeneral.numeroRadicacion;
                this.btnRadicar = false;
                this.tabAnexosAdicionales = true;
                this.tabAnexoRemisorio = true;
                this.btnGuardarBorrador = false;
                this.isEditable = false;
              }
            }       
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: "Se ha guardado la información del borrador.",
              life: 10000
            });
            this.loading = false;
          }, (error)=>{
              this.loading = false;
              this.principalComponent.cargarErrorServicio(error);
          });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: "Señor Usuario, por favor diligenciar todos los campos obligatorios.",
            life: 10000
          });
        }
    }

    /**
     * Metodo que realiza la radiación
     */
    bntRadicacion(){      
      this.rwbDocumentoRadicado = {};
      if(this.informacion != null && this.informacion.firmantes.length == 0 ){
        this.visibleConfirmarRadicacion = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "Señor Usuario, por favor agregar por lo menos un firmante.",
          life: 10000
        });
      }else{
        if(this.listadoAnexosAdicionales.length > 0 && this.listadoRemisorio.length > 0){
          this.visibleConfirmarRadicacion = true;
        }else{
          this.visibleConfirmarRadicacion = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: "Señor Usuario, por favor agregar el archivo remisorio y/o por lo menos un arhivo anexo",
            life: 10000
          });
        }
      }      
    }

    /**
     * Metodo que cancela la modal Radicar
     */
    cancelarRadicar(){
      this.visibleConfirmarRadicacion = false;
    }

    /**
     * Metodo que acepta la modal Radicar
     */
    aceptarRadicar(){
      this.loading = true;
      this.visibleConfirmarRadicacion = false;
      this.rwbDocumentoRadicado.id = this.idDocumentoRadicado  ; 
      this.rwbDocumentoRadicado.idProyecto = this.informacion.idProyecto  ; 
      this.rwbDocumentoRadicado.proyecto = this.informacion.proyecto  ; 
      this.rwbDocumentoRadicado.tipoBorradorFinalizado = "R"  ; 
      this.rwbDocumentoRadicado.idPersonaJuridica = this.informacion.idPersonaJuridica  ; 
      this.rwbDocumentoRadicado.correoElectronicoPersonal = this.informacionGeneral.correoElectronicoPersonal  ; 
      this.rwbDocumentoRadicado.datosRemitenteJson = this.informacionGeneral.datosRemitenteJson  ; 
              
      this.rwbDocumentoRadicado.personaJuridica = this.informacionGeneral.personaJuridica  ; 
      this.rwbDocumentoRadicado.numeroTemporal = this.informacionGeneral.numeroTemporal  ; 
      this.rwbDocumentoRadicado.fechaCreacion = this.informacionGeneral.fechaCreacion  ; 
      this.rwbDocumentoRadicado.fechaCreacionStr = this.informacionGeneral.fechaCreacionStr  ; 
      this.rwbDocumentoRadicado.numeroRadicacion = this.informacionGeneral.numeroRadicacion  ; 
      this.rwbDocumentoRadicado.fechaRadicacion = this.informacionGeneral.fechaRadicacion  ; 
      this.rwbDocumentoRadicado.fechaRadicacionStr = this.informacionGeneral.fechaRadicacionStr  ; 
      this.rwbDocumentoRadicado.idTipoDocumento = this.informacionGeneral.idTipoDocumento  ; 
      this.rwbDocumentoRadicado.tipoDocumento = this.informacionGeneral.tipoDocumento  ; 
      this.rwbDocumentoRadicado.descripcion = this.informacionGeneral.descripcion  ; 
      this.rwbDocumentoRadicado.asunto = this.informacionGeneral.asunto  ; 


      //Estos datos son del proyecto
      this.rwbDocumentoRadicado.idDepartamento = this.informacion.idDepartamentoRS  ; 
      this.rwbDocumentoRadicado.departamento = this.informacion.departamentoRS  ; 
      this.rwbDocumentoRadicado.idCiudad = this.informacion.idMunicipioRS  ; 
      this.rwbDocumentoRadicado.ciudad = this.informacion.municipioRS  ; 
      this.rwbDocumentoRadicado.direccion = this.informacion.direccion  ; 
      this.rwbDocumentoRadicado.telefono = this.informacion.telefonoRS  ; 
      this.rwbDocumentoRadicado.nit = this.informacion.nitRS  ; 
      this.rwbDocumentoRadicado.razonSocial = this.informacion.razonSocial  ; 
      this.rwbDocumentoRadicado.sigla = this.informacion.sigla  ; 
      this.rwbDocumentoRadicado.representanteLegal = this.informacion.representanteLegal  ; 

      this._service.postDocumentoRadicado(this.rwbDocumentoRadicado).subscribe((data: {}) => {
        this.temporal = data;

        if(this.temporal != undefined && this.temporal != null){
          this.informacionGeneral = this.temporal;    
          if(this.informacionGeneral.numeroRadicacion!= null && 
            this.informacionGeneral.numeroTemporal != null){
            this.textoRadicado = "Número Radicado "+ this.informacionGeneral.numeroRadicacion;
            this.btnRadicar = false;
            this.tabAnexosAdicionales = true;
            this.tabAnexoRemisorio = true;
            this.btnGuardarBorrador = false;
            this.isEditable = false;
          }
        }       
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: "Se ha guardado la información del radicado.",
          life: 10000
        });
        this.loading = false;
      }, (error)=>{
          this.loading = false;
          this.principalComponent.cargarErrorServicio(error);
      });
    }

    /**
     *Metodo que carga la informacion
     *
     * @param {NgxFileDropEntry[]} files
     * @memberof NewEditRadicacionWebComponent
     */
    public dropped(files: NgxFileDropEntry[]) {
        this.files = files;
    
        if (this.soloUnArchivo) {
          this._files = [];
          this._filesTemp = [];
        }
    
        let index: number = 0;
    
        this._filesTemp.forEach(element => {
          if (element.estado == 'Tamaño no valido') {
            this._filesTemp.splice(index, 1);
          }
          if (element.estado == 'Formato no válido') {
            this._filesTemp.splice(index, 1);
          }
          index++;
        });
    
        this.habilitaEnvio = true;
        this.ocurrioAlgunError = false;
        let sum = this._filesTemp.length + files.length;
        if(sum > this.numeroArchivos){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "No se puede cargar mas archivos al definido.", life: 10000 });
        }else{
          for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
              const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
              fileEntry.file((file: File) => {
                var archivoCargueBase = null;
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    archivoCargueBase = reader.result;
  
                    var archivo = new ArchivoRemisorio(droppedFile.relativePath, "Enviando...", droppedFile.relativePath, this.tipoSoporteSeleccionado.id, archivoCargueBase);
                    this._files.push(archivo);
                    this._filesTemp.push(archivo);
  
                    if (file.size <= this.tamanioMegas) {//Tamano maximo permito son 500MB
                      const fechaActual = _moment().format('YYYY-MM-DD HH:mm:ss');;
                      const uploadData = new FormData();
                      uploadData.append('file', file, droppedFile.relativePath);
                      uploadData.append('modulo', this.modulo);
                      uploadData.append('id', this.id);
                      uploadData.append('nombreInicialArchivo', file.name);
                      uploadData.append('fechaInicio', String(fechaActual));
                      uploadData.append('esBlob', String(this.esBlob));
                      uploadData.append('contenedor', this.contenedor);
                      uploadData.append('tipoArchivo', "1");  //Remisorio
                      uploadData.append('tamanioFile', file.size.toString());
                      uploadData.append('idDocumentoRadicado', this.idDocumentoRadicado);
                      this._service
                        .uploadFileRemisorio(uploadData)
                        .subscribe(
                          (response: any) => {
                            this.cargarlistadoRemisorio();
                            archivo.estado = "Cargado";
                            archivo.nombreCompleto = response.fileName;
                            archivo.idTipoDocumento = this.tipoSoporteSeleccionado.id;
                            archivo.nombreCompleto = response.fileName;
                            let todosEviados: boolean = true;
                            this._filesTemp.forEach(element => {
                              if (element.estado != "Cargado" ) {
                                todosEviados = false;
                              }
                            });
                            if (todosEviados) {
                              this.habilitaEnvio = false;
                            }
                          },
                          (error) => {
                            console.error(error);
                            this.principalComponent.cargarErrorServicio(error);
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
                            archivo.estado = "No se pudo adjuntar";
                            this.ocurrioAlgunError = true;
                            this.habilitaEnvio = true;
                          }
                        );
                    } else {  
                      archivo.estado = "Tamaño no valido";
                      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Tamaño no valido", life: 10000 });
                      this.ocurrioAlgunError = true;
                      this.habilitaEnvio = true;
                    }
                };            
              });
            } else {
              var archivo = new ArchivoRemisorio(droppedFile.relativePath, "Formato no válido", droppedFile.relativePath, this.tipoSoporteSeleccionado.id, null);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: "Formato no valido", life: 10000 });
              this.ocurrioAlgunError = true;
            }
          }
      
          if (!this.ocurrioAlgunError) {
            this.OutputValidacionArchivos.emit("OK");
          } else {
            this.OutputValidacionArchivos.emit("ERROR");
          }
          this.idArchivosChange.emit(this._filesTemp);
        }
      }
  
    /**
     * Metodo que carga los anexos adicionales
     *
     * @param {NgxFileDropEntry[]} files
     * @memberof NewEditRadicacionWebComponent
     */
    public droppedAnexo(files: NgxFileDropEntry[]) {
      this.filesAnexo = files;
      this._filesAnexo = [];

      if (this.soloUnArchivo) {
        this._filesAnexo = [];
        this._filesAnexoTemp = [];
      }
  
      let index: number = 0;
  
      this._filesAnexoTemp.forEach(element => {
        if (element.estado == 'Tamaño no valido') {
          this._filesAnexoTemp.splice(index, 1);
        }
        if (element.estado == 'Formato no válido') {
          this._filesAnexoTemp.splice(index, 1);
        }
        index++;
      });
  
      this.habilitaEnvio = true;
      this.ocurrioAlgunErrorAnexo = false;
      let sum = this._filesAnexoTemp.length + files.length + this.listadoAnexosAdicionales.length;
      if(sum > this.numeroArchivosAnexo){
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "No se puede cargar mas archivos al definido.", life: 10000 });
        this.errorNumeroArchivoAnexo = true;
      }else{
        this.errorNumeroArchivoAnexo = false;
        for (const droppedFile of files) {
          // Is it a file?
          if (droppedFile.fileEntry.isFile && this.isFileAllowedAnexo(droppedFile.fileEntry.name)) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
              var archivoCargueBase = null;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                  archivoCargueBase = reader.result;
                  
                  this._filesAnexoTemp = [];
                  var archivo = new ArchivoAnexo(droppedFile.relativePath, "Enviando...", droppedFile.relativePath, this.tipoSoporteSeleccionado.id, archivoCargueBase);
                  this._filesAnexo.push(archivo);
                  this._filesAnexoTemp.push(archivo);

                  if (file.size <= this.tamanioAnexoMegas) {//Tamano maximo permito son 500MB
                    this.loading = true;
                    this.mostrarCargue = true;
                    const fechaActual = _moment().format('YYYY-MM-DD HH:mm:ss');;
                    const uploadData = new FormData();
                    uploadData.append('file', file, droppedFile.relativePath);
                    uploadData.append('modulo', this.modulo);
                    uploadData.append('id', this.idAnexo);
                    uploadData.append('nombreInicialArchivo', file.name);
                    uploadData.append('fechaInicio', String(fechaActual));
                    uploadData.append('esBlob', String(this.esBlob));
                    uploadData.append('contenedor', this.contenedor);
                    uploadData.append('tipoArchivo', "2");  //Anexos adicionales
                    uploadData.append('tamanioFile', file.size.toString());
                    uploadData.append('idDocumentoRadicado', this.idDocumentoRadicado);
                    this._service
                      .uploadFileAnexos(uploadData)
                      .subscribe(
                        (response: any) => {
                          this.cargarlistadoRemisorio();
                          this.mostrarCargue = false;
                          archivo.estado = "Cargado";
                          this._filesAnexo = [];
                          archivo.nombreCompleto = response.fileName;
                          archivo.idTipoDocumento = this.tipoSoporteSeleccionado.id;
                          archivo.nombreCompleto = response.fileName;
                          let todosEviados: boolean = true;
                          this._filesAnexoTemp.forEach(element => {
                            if (element.estado != "Cargado" ) {
                              todosEviados = false;
                            }
                          });
                          if (todosEviados) {
                            this.habilitaEnvio = false;
                          }
                        },
                        (error) => {
                          console.error(error);
                          this.mostrarCargue = false;
                          this.loading = false;
                          this._filesAnexo = [];
                          this.principalComponent.cargarErrorServicio(error);
                          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
                          archivo.estado = "No se pudo adjuntar";
                          this.ocurrioAlgunErrorAnexo = true;
                          this.habilitaEnvio = true;
                        }
                      );
                  } else {  
                    archivo.estado = "Tamaño no valido";
                    this._filesAnexo = [];
                    this.mostrarCargue = false;
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: "Tamaño no valido", life: 10000 });
                    this.ocurrioAlgunErrorAnexo = true;
                    this.habilitaEnvio = true;
                  }
              };            
            });
          } else {
            var archivo = new ArchivoAnexo(droppedFile.relativePath, "Formato no válido", droppedFile.relativePath, this.tipoSoporteSeleccionado.id, null);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Formato no valido", life: 10000 });
            this.ocurrioAlgunErrorAnexo = true;
          }
        }
    
        if (!this.ocurrioAlgunErrorAnexo) {
          this.OutputValidacionArchivos.emit("OK");
        } else {
          this.OutputValidacionArchivos.emit("ERROR");
        }
        this.idArchivosChange.emit(this._filesAnexoTemp);
      }
    }



      /**
       * Metodo que valida los tamaños
       */
      validarArchivos(){
        if(this._files.length > this.numeroArchivos){
          this.errorNumeroArchivo = true;
          return true;
        }else{
          this.errorNumeroArchivo = false;
          return false;
        }
      }
    
      limpiarArchivos() {
        //this.tipoSoporteSeleccionado = null;
        this._files = [];
        this.habilitaEnvio = true;
      }
    
      isFileAllowed(fileName: string) {
        let isFileAllowed = false;
        const allowedFiles = this.tipoSoporteSeleccionado.formatoPermitido.split(',');
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
    
        if (undefined !== extension && null !== extension) {
          for (const ext of allowedFiles) {
            if (ext === extension[0]) {
              isFileAllowed = true;
            }
          }
        }
        return isFileAllowed;
      }

      isFileAllowedAnexo(fileName: string) {
        let isFileAllowed = false;
        const allowedFiles = this.tipoSoporteSeleccionadoAnexo.formatoPermitido.split(',');
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
    
        if (undefined !== extension && null !== extension) {
          for (const ext of allowedFiles) {
            if (ext === extension[0]) {
              isFileAllowed = true;
            }
          }
        }
        return isFileAllowed;
      }
    

    /**
     * Metodo que carga el listado
     */
    cargarlistadoRemisorio(){
      this.loading = true;
      this._service.getListadoArchivoDocumentoRadicado(this.idDocumentoRadicado).subscribe((data: {}) => {
        this.temporalListado = data;
        
        if(this.temporalListado != undefined && this.temporalListado != null &&
          this.temporalListado.length > 0 ){
          this._files = [];
          this._filesAnexo = [];
          this._filesAnexoTemp = [];
          this.listadoRemisorio = [];
          this.listadoAnexosAdicionales = [];

          for(var i=0; i<this.temporalListado.length; i++)
          {
              if(this.temporalListado[i].tipo == "Remisorio")
              {
                this.listadoRemisorio.push(this.temporalListado[i]);
                var archivo = new ArchivoRemisorio(this.temporalListado[i].nombreArchivoUsuario, "Cargado", this.temporalListado[i].rutaSoporte, 
                this.tipoSoporteSeleccionado.id, null);
                this._files.push(archivo);
                this.isFullArchivo = true;
              }else{
                this.listadoAnexosAdicionales.push(this.temporalListado[i]);
                if(this.numeroArchivosAnexo <= this.listadoAnexosAdicionales.length ){
                  this.isFullArchivoAnexo = true;
                }
              }
          }
          this.numeroArchivosCargadosAnexo = this.listadoAnexosAdicionales.length;
        }            
        this.loading = false;
        }, (error)=>{
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
      });       
    }

    /**
     * NMetodo get de EnumRegex
     */
    get EnumRegex() {
      return EnumRegex;
    }

}
