export enum EnumCmsConstantes {
    CMS_CARPETA_LEGALIZACION_BLOB_STORAGE = "legalizacion",
    CMS_CARPETA_GESTION_PRESUPUESTAL_BLOB_STORAGE = "gestionPresupuestal",
    CMS_CARPETA_CANCELACION_BLOB_STORAGE = "cancelacion",

    CMS_PASO_COMISION_EN_BORRADOR = 1,
    CMS_PASO_COMISION_REVISION_VISTO_BUENO_APROBADOR_1 = 2,
    CMS_PASO_COMISION_REVISION_APROBADOR_1 = 3,
    CMS_PASO_COMISION_REVISION_VISTO_BUENO_APROBADOR_2 = 4,
    CMS_PASO_COMISION_REVISION_APROBADOR_2 = 5,
    CMS_PASO_COMISION_VAF = 6,
    CMS_PASO_COMISION_APROBADO = 9,
    CMS_PASO_COMISION_REVISION_LEGALIZACION = 21,
    CMS_PASO_COMISION_REVISION_LEGALIZACION_VGCOR = 22,
    CMS_PASO_COMISION_REVISION_LEGALIZACION_VGCOR_DEVOLUCION = 23,
    CMS_PASO_COMISION_REVISION_CENTRAL_PAGOS = 24,
    CMS_PASO_COMISION_REVISION_CENTRAL_PAGOS_DEVOLUCION = 25,
    CMS_PASO_COMISION_APROBADO_PARA_PAGO = 26,
    CMS_PASO_COMISION_PAGADO = 29,
    CMS_PASO_COMISION_DEVULETO_OBSERVACIONES = 98,
    CMS_PASO_COMISION_DEVULETO_OBSERVACIONES_LEGALIZACION = 99,
    CMS_PASO_COMISION_DESISTIDA = 100,
    CMS_PASO_COMISION_CANCELADA = 101,
    CMS_PASO_COMISION_CANCELADA_GESTIONADA_VAF = 102,

    CMS_ESTADO_TIQUETE_AEREO_SOLICITADO = 1,
    CMS_ESTADO_TIQUETE_AEREO_PENDIENTE_GESTIONAR = 2,
    CMS_ESTADO_TIQUETE_AEREO_RESERVADO = 3,
    CMS_ESTADO_TIQUETE_AEREO_RESERVA_APROBADA = 4,
    CMS_ESTADO_TIQUETE_AEREO_RESERVA_RECHAZADA = 5,
    CMS_ESTADO_TIQUETE_AEREO_COMPRADO = 6,
    CMS_ESTADO_TIQUETE_AEREO_SOLICITUD_CANCELACION = 7,
    CMS_ESTADO_TIQUETE_AEREO_CANCELADO = 8,
}

export enum EnumCmsTipoAnexo {
    SUBTIPO_GESTION_LEGALIZACION = 'LG',
    SUBTIPO_GESTION_LEGALIZACION_ANEXOS = 'AX',
    SUBTIPO_GESTION_CANCELACION_ANEXOS = 'CN',
    SUBTIPO_GESTION_PRESUPUESTAL = 'GP',
    SUBTIPO_GESTION_TIQUETES = 'TQ',
    INFORME_LEGALIZACION = 1,
    FACTURA = 2,
    PASABORDO = 3,
    FACTURA_TRANSPORTE = 4,
    SOPORTE_PEAJES = 5,
    GASTO_COMBUSTIBLE = 6,
    REGISTRO_PRESUPUESTAL_OBLIGACION_PAGO_ANI = 7,
    CERTIFICADO_PAGO_ANI = 8,
    TIQUETE_COMPRA = 9,
    TIQUETE_RESERVA = 10,
    ACTO_ADMINISTRATIVO = 11,
    AUTORIZACION_SIIF = 12,
    LEGALIZACION_USOS_CDP = 13,
    CANCELACION_TIQUETES = 14,
    CANCELACION_RP = 15,
    CANCELACION_ACTO_ADMINISTRATIVO = 16,

}

//TODO: JENNY REVISAR USO YA NO APLICA
export enum EnumCmsMaTipoModificacionComision {
    CMS_TIPO_MODIFICACION_INICIAL = 1,
    CMS_TIPO_MODIFICACION_AMPLIACION = 2,
    CMS_TIPO_MODIFICACION_REDUCCION = 3,
    CMS_TIPO_MODIFICACION_CANCELACION = 4,
}

export enum EnumCmsCodigosErrorComision {
    CMS_ERROR_OBTENER_DEPENDENCIA_CODIGO = 100,
    CMS_ERROR_EXISTE_COMISION_RANGO_FECHA_CODIGO = 101,
    CMS_ERROR_COMISION_APROBADA_CODIGO = 102,
    CMS_ERROR_COMISION_VENCIDAS_LEGALIZACIONES_CODIGO = 103
}