import { Component, OnInit } from '@angular/core';
import { ContratacionService } from '../contratacion.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-obligaciones-contrato',
  templateUrl: './obligaciones-contrato.component.html',
  styleUrls: []
})
export class ObligacionesContratoComponent implements OnInit {
  displayedColumns: any[];
  obligaciones: any = [];
  obligacion: any;
  visibleDialogoAgregarObligacion: boolean;
  visibleConfirmarEliminar: boolean;
  idContrato: number;

  blockedDocument = false;

  constructor(
    private svc: ContratacionService,
    private ruta: ActivatedRoute,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.displayedColumns = [
      { field: 'obligacion', header: 'Obligación' }
    ];
    this.ruta.params.subscribe(params => {
      this.idContrato = params.id;
    });
    this.cargarObligaciones();
  }

  cargarObligaciones() {

    this.blockedDocument = true;
    this.svc.ConsultarObligacionesPorContrato(this.idContrato)
      .subscribe((data: {}) => {
        this.obligaciones = data;
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );

  }

  onNew() {
    this.obligacion = { idContrato: this.idContrato, obligacion: '' };
    this.visibleDialogoAgregarObligacion = true;
  }

  agregarObligacion() {
    this.svc.GuardarObligacionContrato(this.obligacion).subscribe(() => {
      this.visibleDialogoAgregarObligacion = false;
      this.cargarObligaciones();
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );

  }

  cancelar() {
    this.visibleDialogoAgregarObligacion = false;
    this.cargarObligaciones();
  }
  onEdit(obligacion) {
    this.obligacion = obligacion;
    this.visibleDialogoAgregarObligacion = true;
  }
  onDelete(obligacion) {
    this.obligacion = obligacion;
    this.visibleConfirmarEliminar = true;
  }
  cancelarEliminar() {
    this.visibleConfirmarEliminar = false;
  }
  aceptarEliminar() {
    this.svc.EliminarObligacion(this.obligacion.id).subscribe(() => {
      this.visibleConfirmarEliminar = false;
      this.cargarObligaciones();
    });
  }

  onObligacionesIngresada(event: any) {
  }
}
