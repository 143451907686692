import { Component, OnInit } from '@angular/core';
import { InformeContratistaBaseComponent } from '../informe-contratista-base/informe-contratista-base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { InformeContratistaService } from '../informe-contratista.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { CtoInformeObservacionDTO } from 'src/app/contratacion/contratacion-dto/cto-Informe-observacion';

@Component({
  selector: 'app-informe-contratista-observaciones',
  templateUrl: './informe-contratista-observaciones.component.html',
  styles: []
})
export class InformeContratistaObservacionesComponent implements OnInit {

  observaciones: CtoInformeObservacionDTO[] = [];

  constructor(
    private lineaBase: InformeContratistaBaseComponent,
    private route: Router,
    private ruta: ActivatedRoute,
    private informeContratistaService: InformeContratistaService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    let versionObservaciones: number = this.lineaBase.version - 1;
    this.cargarObservaciones(this.lineaBase.idInforme, versionObservaciones);
  }

  /**
     * Metodo para consultar las observaciones de un informe definitivo cuando ha sido revisado por los involucrados
     * @param idInforme Id del informe
     * @param version Número de la version del informe por defecto es 0
     * @returns Resultado de la operación
     */
  cargarObservaciones(idInforme, version) {
    return this.informeContratistaService.srvGetObservacionesInforme(idInforme, version).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.observaciones = result.observaciones;
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      });
  }
}
