import { Component, OnInit } from '@angular/core';
import { loadModules } from "esri-loader";
import {MenubarModule} from 'primeng/menubar';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-modelo-uat',
  templateUrl: './modelo-uat.component.html',
  styleUrls: ['../public-uat.component.sass']
})
export class ModeloUatComponent implements OnInit {

  items: MenuItem[];

  ngOnInit() {
      this.items = [
          {
              label: 'UAT'
              , "routerLink": "/uat"
          },
          {
              label: 'Servicios',
              items: [
                {label: 'Repositorio de Información de tráfico y recaudo', "routerLink": "/uat/repositorio"},
                {label: 'Modelo Nacional de Transporte', "routerLink": "/uat/modelo"},
                {label: 'Mesa Nacional de modelación de transporte', "routerLink": "/uat/mesa"}
            ]
          },
          {
              label: 'Aliados'
              , "routerLink": "/uat/aliados"
          },
          {
              label: 'Investigación'
              , "routerLink": "/uat/investigacion"
          }
      ];

      loadModules(['esri/views/MapView', 'esri/WebMap'])
        .then(([MapView, WebMap]) => {
            // then we load a web map from an id
            var webmap = new WebMap({
            portalItem: { // autocasts as new PortalItem()
                id: '984279fcae7246149928707f5d1e85ac'
            }
            });
            // and we show that map in a container w/ id #viewDiv
            var view = new MapView({
            map: webmap,
            container: 'viewDiv'
            });
        })
        .catch(err => {
            // handle any errors
            console.error(err);
        });
      
  }
  

}
