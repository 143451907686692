import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProyectoDTO } from '../componentes-comunes/componentes-comunes.dto';
import { ComponentesComunesService } from '../componentes-comunes/componentes-comunes.service';

@Component({
  selector: 'app-trafico-recaudo',
  templateUrl: './trafico-recaudo.component.html',
  styleUrls: ['./trafico-recaudo.component.sass']
})
export class TraficoRecaudoComponent implements OnInit {

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  peajes: any[];
  peajeSeleccionado = { id: '-1', nombre: '' };

  fechaDesde: Date;
  fechaHasta: Date;

  categorias: any[];
  categoriaSeleccionado = { id: '-1', nombre: '' };

  tiposDePago: any[];
  tipoDePagoSeleccionado = { id: '-1', nombre: '' };

  sentidos: any[];
  sentidoSeleccionado = { id: '-1', nombre: '' };

  trafico: number;
  recaudoTotal: number;



  constructor(private route: Router, private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.id;
      this.srvObtenerProyecto(this.idProyecto);
    });

    this.peajes = [
      { id: '0', nombre: 'Peaje 001' },
      { id: '0', nombre: 'Peaje 002' },
      { id: '0', nombre: 'Peaje 003' }

    ];

    this.categorias = [
      { id: '0', nombre: 'Categoria A' },
      { id: '0', nombre: 'Categoria B' },
      { id: '0', nombre: 'Categoria C' }

    ];

    this.tiposDePago = [
      { id: '0', nombre: 'Tipo de Pago X' },
      { id: '0', nombre: 'Tipo de Pago Y' },
      { id: '0', nombre: 'Tipo de Pago Z' }

    ];

    this.sentidos = [
      { id: '0', nombre: 'Sentido A-B' },
      { id: '0', nombre: 'Sentido C-D' }

    ];

    //this.fechaDesde = new Date();
  }

  ///////////////////////////////LLamar Servicios//////////////////////////////
  srvObtenerProyecto(_idProyecto: number) {
    return this.componentesComunesService.obtenerProyecto(_idProyecto).subscribe(data => {
      this.proyectoSeleccionado = data;
    });
  }

}
