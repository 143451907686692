
import { TrnRespuestaServicio } from '../../parametricas/trn-respuesta-servicio';

export class PjeDetallePeriodoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public detallePeriodo?: PjeDetallePeriodoDTO[],
    ) { }
}

export class PjeDetallePeriodoDTO {
    constructor(
        public idPjeTrafico?: number,
        public categoria?: string,
        public tipoCategoria?: string,
        public tipoTarifa?: number,
        public fechaDesde?: Date,
        public fechaHasta?: Date,
        public valorTarifa?: number,
        public trafico?: number,
        public traficoEvasores?: number,
        public traficoExentos?: number,
        public valorRecaudo?: number,
        public valorRecaudoEvasores?: number,
        public orden?: number

    ) { }
}
