import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ProyectoCarretero } from '../informacion-proyecto-carretero/proyectoCarretero';


@Injectable({
    providedIn: 'root'
})
export class ConcesionarioCarreteroService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public guardarNuevoContratoConcesionario(data) {
        return this.http.post(this.END_POINT + '/CntContrato', data)
            .pipe(
                retry(1));
    }

    public editarContratoConcesionario(data, idContrato) {
        return this.http.put(this.END_POINT + '/CntContrato/' + idContrato, data)
            .pipe(
                retry(1));
    }


    public getContratoConcesionarioPorIdProyecto(id, idTipo) {
        return this.http.get(this.END_POINT + '/CntContrato/proyecto/' + id + '/' + idTipo)
            .pipe(
                retry(1));
    }

    public guardarNuevoContratistaConcesionario(data) {
        return this.http.post(this.END_POINT + '/CntContratista', data)
            .pipe(
                retry(1));
    }

    public editarContratistaConcesionario(data, idContratista) {
        return this.http.put(this.END_POINT + '/CntContratista/' + idContratista, data)
            .pipe(
                retry(1));
    }

    public getContratistaConcesionarioPorIdContrato(id) {
        return this.http.get(this.END_POINT + '/CntContratista/' + id)
            .pipe(
                retry(1));
    }

    public guardarNuevoConcesionarioAccionista(data) {
        return this.http.post(this.END_POINT + '/CntAccionistaContratista', data)
            .pipe(
                retry(1),);
    }

    public editarConcesionarioAccionista(data, idConcesionarioAccionista) {
        return this.http.put(this.END_POINT + '/CntAccionistaContratista/' + idConcesionarioAccionista, data)
            .pipe(
                retry(1));
    }

    public getAccionistaPorId(id) {
        return this.http.get(this.END_POINT + '/CntAccionistaContratista/' + id)
            .pipe(
                retry(1));
    }

    public getAccionistaPorIdEncabezado(id) {
        return this.http.get(this.END_POINT + '/CntAccionistaContratista/composicionAccionaria/' + id)
            .pipe(
                retry(1));
    }

    public eliminarAccionistaPorIdContratista(id) {
        return this.http.delete(this.END_POINT + '/CntAccionistaContratista/' + id)
            .pipe(
                retry(1));
    }


    public getComposicionAccionariaPorIdContratista(id) {
        return this.http.get(this.END_POINT + '/CntAccionistaContratista/composicionAccionaria/list/' + id)
            .pipe(
                retry(1));
    }

    public getComposicionAccionariaPorId(id) {
        return this.http.get(this.END_POINT + '/CntAccionistaContratista/composicionAccionaria/encabezado/' + id)
            .pipe(
                retry(1));
    }

    public guardarNuevaComposicionAccionaria(data) {
        return this.http.post(this.END_POINT + '/CntAccionistaContratista/composicionAccionaria', data)
            .pipe(
                retry(1));
    }

    public editarComposicionAccionaria(data, idEncabezado) {
        return this.http.put(this.END_POINT + '/CntAccionistaContratista/composicionAccionaria/' + idEncabezado, data)
            .pipe(
                retry(1));
    }

    public eliminarComposicionAccionaria(id, idContratista) {
        return this.http.delete(this.END_POINT + '/CntAccionistaContratista/composicionAccionaria/' + id + '/' + idContratista)
            .pipe(
                retry(1));
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }


}
