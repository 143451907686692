import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { CtoInformeContratistaObservacionDTO } from "src/app/contratacion/contratacion-dto/informe-contratista-observacion";
import { PrincipalComponent } from "src/app/home/principal/principal.component";

import { RpteCxpBaseComponent } from "../rpte-cxp-base/rpte-cxp-base.component";
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";

@Component({
  selector: 'app-rpte-cxp-obs',
  templateUrl: './rpte-cxp-obs.component.html',
  styles: []
})
export class RpteCxpObsComponent implements OnInit {

  observacionesInforme: CtoInformeContratistaObservacionDTO[];

  constructor(
    private lineaBase: RpteCxpBaseComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarObservacionesInforme(this.lineaBase.idInformeParam);

  }

  cargarObservacionesInforme(idInforme: number) {
    this.reporteCuentaCobroCxpService.srvConsultarObservacionesInforme(idInforme)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.observacionesInforme = result.observacionesInforme;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }
}
