import { Component, OnInit, Input } from '@angular/core';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { NgForm, FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { SelectItem, MessageService, ConfirmationService } from 'primeng/api';
import { C19ReinicioObra, C19ReinicioArticulacion, EstadoReinicioObra, EntidadArticulacion, C19TrabajadoresPorDepartamento } from '../covid-reportar.dto';
import { CovidReportarReinicioObraService } from '../covid-reportar-reinicio-obra.service';
import { Location } from '@angular/common';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-covid-reportar-reinicio-obra',
  templateUrl: './covid-reportar-reinicio-obra.component.html',
  styleUrls: ['./covid-reportar-reinicio-obra.component.sass']
})
export class CovidReportarReinicioObraComponent implements OnInit {

  blockedDocument: boolean = false;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  reinicioObraCovid: C19ReinicioObra = {};
  ultimoReinicioObraCovid: C19ReinicioObra = {};


  listaArticulaciones: C19ReinicioArticulacion[];
  articulacionCovid: C19ReinicioArticulacion = {};
  tipoEntidadArticulacionList: EntidadArticulacion[];
  tipoEntidadArticulacionSeleccionado: EntidadArticulacion;

  listaTrabajadoresPorDepartamento: C19TrabajadoresPorDepartamento[] = [];
  trabajadoresPorDepartamento: C19TrabajadoresPorDepartamento = {}

  estadosReinicioObra: EstadoReinicioObra[];
  estadoReinicioObraSeleccionado: EstadoReinicioObra = {};

  fechaEstadoReinicioObra: Date;

  estadoSeleccionadoEsAprobado: boolean;
  ultimoRegistroEsAprobado: boolean;
  ultimoRegistroEsActualizacion: boolean;

  disabledFecha: Boolean;
  disabledEstado: Boolean;
  disabledPublicar: Boolean;



  isEditReinicioObra;



  visibleDialogoAgregarArticulacion: boolean = false;
  visibleDialogoAgregar: boolean = false;
  articulacionForm: FormGroup;

  visibleDialogoAgregarTrabajadoresEstimados: boolean = false;
  trabajadoresEstimadosForm: FormGroup;


  proyectoNoSeDetuvo: boolean;
  proyectoNoAplicaRadicado: boolean;
  fechaPorDefecto: Date;

  idReinicioObraEditando: number;

  //visibleBioseguridad: boolean = false;
  msj;

  c19ReinicioObraListEncontrados: C19ReinicioObra[]

  seEstaEditando: boolean;



  constructor(private router: Router, private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private covidReportarReinicioObraService: CovidReportarReinicioObraService,
    private _location: Location,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {

    this.fechaPorDefecto = new Date(2020, 0, 1);

    this.reinicioObraCovid = {}

    this.ruta.params.subscribe(params => {
      this.idProyecto = params.id;
      forkJoin(
        this.componentesComunesService.obtenerProyecto(this.idProyecto),
        this.covidReportarReinicioObraService.srvConsultarEstadosReinicioObra(),
        this.covidReportarReinicioObraService.srvConsultarEntidadArticulacion()

      ).subscribe(([proyecto, estados, entidadArticulacionList]) => {

        //Obtener proyecto seleccionado
        this.proyectoSeleccionado = proyecto;

        //Cargar listas parametricas
        this.estadosReinicioObra = estados;
        this.tipoEntidadArticulacionList = entidadArticulacionList.c19EntidadArticulacion;

        //Consultar el Reinicio de Obra
        this.consultarReinicioDeObra();

        //Consultar las Articulaciones por Proyecto
        this.consultarArticulacionesPorProyecto();

      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    });
  }

  visibleAgregarArticulacion() {
    this.visibleDialogoAgregarArticulacion = true;
    this.articulacionCovid = {};
    this.tipoEntidadArticulacionSeleccionado = {};
  }

  cancelarAgregarArticulacion(articulacionForm: NgForm) {
    this.visibleDialogoAgregarArticulacion = false;
    //articulacionForm.resetForm();
  }

  visibleAgregarTrabajadoresEstimados() {
    /**if (this.ultimoReinicioObraCovid != null && this.ultimoReinicioObraCovid.id != null) {
      //Consultar Trabajadores Por Departamento
      this.consultarTrabajadoresPorDepartamento();
    }**/

    this.seEstaEditando = false;
    this.visibleDialogoAgregarTrabajadoresEstimados = true;
    this.trabajadoresPorDepartamento = {};
    this.trabajadoresPorDepartamento.idDepartamento = null;
  }

  cancelarAgregarTrabajadoresEstimados(articulacionForm: NgForm) {
    this.visibleDialogoAgregarTrabajadoresEstimados = false;
  }

  changeEstado(event) {
    if (event != null && event.id == 2) {
      this.estadoSeleccionadoEsAprobado = true;
    } else {
      this.estadoSeleccionadoEsAprobado = false;
    }
    //Deshabilitar el boton publicar si el ultimo registro ya fue publicado o si el estado seleccionado NO ES aprobado
    this.disabledPublicar = (this.ultimoReinicioObraCovid != null && this.ultimoReinicioObraCovid.publicado) || !this.estadoSeleccionadoEsAprobado;
  }

  outputRequiereProtocolo(sinoSeleccion) {
    this.reinicioObraCovid.requiereProtocolo = sinoSeleccion.valor;
  }

  onChangeProyectoNoSeDetuvo(event) {
    this.proyectoNoSeDetuvo = event;
    if (this.proyectoNoSeDetuvo) {
      this.reinicioObraCovid.fechaReinicio = this.fechaPorDefecto;
    } else {
      this.reinicioObraCovid.fechaReinicio = null;
    }
  }

  onChangeProyectoNoAplicaRadicado(event) {
    this.proyectoNoAplicaRadicado = event;
    if (this.proyectoNoAplicaRadicado) {
      this.reinicioObraCovid.numeroRadicado = "N/A"
      this.reinicioObraCovid.fechaAprobacion = this.fechaPorDefecto;
    } else {
      this.reinicioObraCovid.fechaAprobacion = null;
      this.reinicioObraCovid.numeroRadicado = null;
    }
  }

  consultarArticulacionesPorProyecto() {
    forkJoin(
      this.covidReportarReinicioObraService.srvConsultarAccionArticulacion(this.proyectoSeleccionado.id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.listaArticulaciones = result.c19accionArticulacion;
          //Consultar nuevamente la lista
          //this.consultarMedidas();
          //Lanzar mensaje de exito
          //this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  consultarTrabajadoresPorDepartamento() {
    forkJoin(
      this.covidReportarReinicioObraService.srvConsultarTrabajadoresPorDepartamento(this.idProyecto)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.listaTrabajadoresPorDepartamento = result.c19TrabajadoresPorDepartamento;
          //Consultar nuevamente la lista
          //this.consultarMedidas();
          //Lanzar mensaje de exito
          //this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  guardarAccionArticulacion(articulacionForm: NgForm) {
    this.articulacionCovid.idProyecto = this.proyectoSeleccionado.id;
    this.articulacionCovid.idEntidadArticulacion = this.tipoEntidadArticulacionSeleccionado.id;

    forkJoin(
      this.covidReportarReinicioObraService.srvGuardarAccionArticulacion(this.articulacionCovid)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          //Consultar nuevamente la lista
          this.consultarArticulacionesPorProyecto();
          //Lanzar mensaje de exito
          this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          //Cerrar el dialogo
          this.visibleDialogoAgregarArticulacion = false;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  guardarTrabajadoresPorDepartamento(trabajadoresPorDeptoForm: NgForm) {

    let departamentoYaIngresado = false;

    for (let index = 0; index < this.listaTrabajadoresPorDepartamento.length; index++) {
      const trabajadorPorDeptoVar = this.listaTrabajadoresPorDepartamento[index];
      if (this.trabajadoresPorDepartamento.idDepartamento == trabajadorPorDeptoVar.idDepartamento) {
        departamentoYaIngresado = true;
      }
    }

    if (!this.seEstaEditando) {
      if (!departamentoYaIngresado) {
        this.listaTrabajadoresPorDepartamento.push(this.trabajadoresPorDepartamento)
        this.trabajadoresPorDepartamento = {};
        trabajadoresPorDeptoForm.resetForm();
      } else {
        this.lanzarMensajeWarning("Ya se ingresó información para el departamento seleccionado")
      }
    } else {
      this.lanzarMensajeInfo("El registro se editó correctamente");
      this.trabajadoresPorDepartamento = {};
      trabajadoresPorDeptoForm.resetForm();
    }
  }

  ///////////////////////////////Servicios/////////////////////////////
  consultarReinicioDeObra() {

    forkJoin(
      this.covidReportarReinicioObraService.srvConsultarReinicioObra(this.idProyecto)
    ).subscribe(([consultaReinicio]) => {
      this.c19ReinicioObraListEncontrados = consultaReinicio.c19ReinicioObraList;

      if (this.c19ReinicioObraListEncontrados != null && this.c19ReinicioObraListEncontrados.length > 0) {
        this.ultimoReinicioObraCovid = this.c19ReinicioObraListEncontrados[0];

        //Consultar Trabajadores Por Departamento
        this.consultarTrabajadoresPorDepartamento();

        //Obtener el minDate con la fecha de inicio del ultimo reinicio de obra
        this.ultimoReinicioObraCovid.fechaInicio = new Date(this.ultimoReinicioObraCovid.fechaInicio);

        //Verificar si el ultimo registro de reinicio de obra esta en estado "Aprobado"
        this.ultimoRegistroEsAprobado = this.ultimoReinicioObraCovid.idEstadoReinicioObra == 2;
        this.ultimoRegistroEsActualizacion = this.ultimoReinicioObraCovid.idEstadoReinicioObra == 6;

        //Validar si el estado seleccionado es aprobado
        this.estadoSeleccionadoEsAprobado = this.estadoReinicioObraSeleccionado == 2;

        //Deshabilitar el boton publicar si el ultimo registro ya fue publicado o si el estado seleccionado NO ES aprobado
        this.disabledPublicar = this.ultimoReinicioObraCovid.publicado || !this.estadoSeleccionadoEsAprobado;

        if ((this.ultimoRegistroEsAprobado || this.ultimoRegistroEsActualizacion) && this.ultimoReinicioObraCovid.publicado) {

          //Cargar el objeto del dropdown con el estado "Actualización"
          this.estadoReinicioObraSeleccionado = this.estadosReinicioObra.find(t => t.id === 6);
          //Activar los otros campos
          this.estadoSeleccionadoEsAprobado = true;
        }

        //Mantener informacion del ultimoReincioObraCovid
        this.reinicioObraCovid = {};
        this.reinicioObraCovid.requiereProtocolo = this.ultimoReinicioObraCovid.requiereProtocolo;
        this.reinicioObraCovid.publicado = this.ultimoReinicioObraCovid.publicado;

        //Activar o desactivar fecha y estado
        this.disabledFecha = !this.reinicioObraCovid.publicado && this.ultimoRegistroEsAprobado;
        this.disabledEstado = (this.ultimoRegistroEsAprobado || this.ultimoRegistroEsActualizacion);
      } else {
        this.ultimoReinicioObraCovid = null;
        this.ultimoRegistroEsAprobado = false;
        this.ultimoRegistroEsActualizacion = false;
        this.disabledPublicar = true;
      }
    }, e => {
    });
  }


  guardarReinicioDeObra(form: NgForm) {
    if (this.reinicioObraCovid.requiereProtocolo != null) {

      let permitirPublicar = true;

      //Si el estado seleccionado es "Aprobado" o "Actualiacion"
      if (this.estadoReinicioObraSeleccionado.id == 2 || this.estadoReinicioObraSeleccionado.id == 6) {
        if (this.listaTrabajadoresPorDepartamento == null || (this.listaTrabajadoresPorDepartamento != null && this.listaTrabajadoresPorDepartamento.length == 0)) {
          this.lanzarMensajeWarning("Por favor agregue los 'Trabajadores Estimados'");
          permitirPublicar = false;
        }
        if (this.reinicioObraCovid.fechaReinicio == null) {
          this.lanzarMensajeWarning("Por favor ingrese 'Fecha Reinicio'");
          permitirPublicar = false;
        }
        if (this.reinicioObraCovid.fechaAprobacion == null) {
          this.lanzarMensajeWarning("Por favor ingrese 'Fecha Radicado ANI'");
          permitirPublicar = false;
        }
        if (this.reinicioObraCovid.numeroRadicado == null) {
          this.lanzarMensajeWarning("Por favor ingrese 'No. Radicado ANI'");
          permitirPublicar = false;
        }
      }

      if (this.fechaEstadoReinicioObra == null) {
        this.lanzarMensajeWarning("Por favor ingrese la fecha 'A partir del'");
        permitirPublicar = false;
      }

      if (permitirPublicar) {
        //Bloquear pantalla
        this.blockedDocument = true;

        if (this.idReinicioObraEditando) {
          this.reinicioObraCovid.id = this.idReinicioObraEditando;
        }

        this.reinicioObraCovid.idProyecto = this.proyectoSeleccionado.id;
        this.reinicioObraCovid.fechaInicio = this.fechaEstadoReinicioObra;
        this.reinicioObraCovid.idEstadoReinicioObra = this.estadoReinicioObraSeleccionado.id;
        forkJoin(
          this.covidReportarReinicioObraService.srvGuardarReinicioObra(this.reinicioObraCovid)
        ).subscribe(([result]) => {
          if (result != null && result.respuestaServicio != null) {
            //Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {

              //Limpiar campos
              this.fechaEstadoReinicioObra = null;
              this.estadoReinicioObraSeleccionado = {};
              this.idReinicioObraEditando = null;
              form.reset();

              if (this.listaTrabajadoresPorDepartamento.length > 0) {
                var itemsProcessed = 0;

                //Recorrer y guardar los trabajadores por departamento
                for (let index = 0; index < this.listaTrabajadoresPorDepartamento.length; index++) {
                  const trabajadorPorDeptoVar = this.listaTrabajadoresPorDepartamento[index];
                  if (trabajadorPorDeptoVar.id == null) {
                    trabajadorPorDeptoVar.id = 0;
                  }
                  trabajadorPorDeptoVar.idProyecto = this.idProyecto;
                  forkJoin(
                    this.covidReportarReinicioObraService.srvGuardarTrabajadoresPorDepartamento(trabajadorPorDeptoVar)
                  ).subscribe(([result]) => {
                    if (result != null && result.respuestaServicio != null) {
                      //Si el resultado es exitoso...
                      if (result.respuestaServicio.codigoSalida === 1) {
                      } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                      }
                    } else {
                    }

                    itemsProcessed++;

                    if (itemsProcessed === this.listaTrabajadoresPorDepartamento.length) {
                      //Consultar nuevamente la lista
                      this.consultarReinicioDeObra();
                    }
                  },
                    error => {
                    },
                    () => {
                      //Desbloquear pantalla
                      this.blockedDocument = false;
                    }
                  );
                }
              } else {
                //Consultar nuevamente la lista
                this.consultarReinicioDeObra();
              }


              //Lanzar mensaje de exito
              this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
          error => {
          },
          () => {
            //Desbloquear pantalla
            this.blockedDocument = false;
          }
        );
      }

    } else {
      this.lanzarMensajeWarning("Por favor indique si requiere Protocolo de Bioseguridad");
    }
  }

  publicarReinicioDeObra(form: NgForm) {
    if (this.reinicioObraCovid.requiereProtocolo != null) {

      //LANZAR MENSAJE DE ADVERTENCIA...
      this.confirmationService.confirm({
        message: 'Una vez publicado el Reinicio de Obra tendrá máximo una semana para poder anular esta publicación y realizar las modificaciones que considere necesarias. Despues una semana de publicado NO podrá ser anulada y por ende NO se podrá modificar la información. Por favor verifique bien los datos ingresados. ¿Desea continuar?',
        accept: () => {

          //Bloquear pantalla
          this.blockedDocument = true;
          this.reinicioObraCovid.idProyecto = this.proyectoSeleccionado.id;

          let permitirPublicar = true;

          //Si NO requiere protocolo de bioseguridad...
          if (!this.reinicioObraCovid.requiereProtocolo) {
            this.reinicioObraCovid.numeroRadicado = null;
            this.reinicioObraCovid.fechaReinicio = null;
            this.reinicioObraCovid.fechaAprobacion = null;
            this.reinicioObraCovid.fechaInicio = new Date();
            this.reinicioObraCovid.fechaFin = null;
            this.reinicioObraCovid.cantidadTrabajadoresEstimados = 0;
            this.reinicioObraCovid.idEstadoReinicioObra = 1;
          } else {//Si requiere protocolo de bioseguridad,
            //Si el estado seleccionado es "Aprobado" o "Actualiacion"
            if (this.estadoReinicioObraSeleccionado.id == 2 || this.estadoReinicioObraSeleccionado.id == 6) {
              if (this.listaTrabajadoresPorDepartamento == null || (this.listaTrabajadoresPorDepartamento != null && this.listaTrabajadoresPorDepartamento.length == 0)) {
                this.lanzarMensajeWarning("Por favor agregue los 'Trabajadores Estimados'");
                permitirPublicar = false;
              }
              if (this.reinicioObraCovid.fechaReinicio == null) {
                this.lanzarMensajeWarning("Por favor ingrese 'Fecha Reinicio'");
                permitirPublicar = false;
              }
              if (this.reinicioObraCovid.fechaAprobacion == null) {
                this.lanzarMensajeWarning("Por favor ingrese 'Fecha Radicado ANI'");
                permitirPublicar = false;
              }
              if (this.reinicioObraCovid.numeroRadicado == null) {
                this.lanzarMensajeWarning("Por favor ingrese 'No. Radicado ANI'");
                permitirPublicar = false;
              }
            }

            if (this.fechaEstadoReinicioObra == null) {
              this.lanzarMensajeWarning("Por favor ingrese la fecha 'A partir del'");
              permitirPublicar = false;
            }


            //Si el estado es actualizacion y se ingreso una fecha
            if (this.estadoReinicioObraSeleccionado.id == 6 && this.fechaEstadoReinicioObra != null) {
              //Guardar NUEVO estado con la actualizacion
            } else {
              if (this.ultimoRegistroEsAprobado) {
                //tomar el ultimo registro que ya esta aprobado para publicar
                this.reinicioObraCovid = this.ultimoReinicioObraCovid;
              }
            }

            this.reinicioObraCovid.idEstadoReinicioObra = this.estadoReinicioObraSeleccionado.id;
            this.reinicioObraCovid.fechaInicio = this.fechaEstadoReinicioObra;
          }

          if (permitirPublicar) {
            //Colocar publicado en TRUE
            this.reinicioObraCovid.publicado = true;
            ///////////////////////////////////////////////////////////////////////////////////
            forkJoin(
              this.covidReportarReinicioObraService.srvGuardarReinicioObra(this.reinicioObraCovid)
            ).subscribe(([result]) => {
              if (result != null && result.respuestaServicio != null) {
                //Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {

                  //Limpiar campos
                  this.fechaEstadoReinicioObra = null;
                  this.estadoReinicioObraSeleccionado = {};

                  if (this.reinicioObraCovid.requiereProtocolo) {
                    form.reset();
                  }

                  if (this.listaTrabajadoresPorDepartamento.length > 0) {
                    var itemsProcessed = 0;

                    //Recorrer y guardar los trabajadores por departamento
                    for (let index = 0; index < this.listaTrabajadoresPorDepartamento.length; index++) {
                      const trabajadorPorDeptoVar = this.listaTrabajadoresPorDepartamento[index];
                      if (trabajadorPorDeptoVar.id == null) {
                        trabajadorPorDeptoVar.id = 0;
                      }
                      trabajadorPorDeptoVar.idProyecto = this.idProyecto;
                      forkJoin(
                        this.covidReportarReinicioObraService.srvGuardarTrabajadoresPorDepartamento(trabajadorPorDeptoVar)
                      ).subscribe(([result]) => {
                        if (result != null && result.respuestaServicio != null) {
                          //Si el resultado es exitoso...
                          if (result.respuestaServicio.codigoSalida === 1) {
                          } else {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                          }
                        } else {
                        }

                        itemsProcessed++;

                        if (itemsProcessed === this.listaTrabajadoresPorDepartamento.length) {
                          //Consultar nuevamente la lista
                          this.consultarReinicioDeObra();
                        }
                      },
                        error => {
                        },
                        () => {
                          //Desbloquear pantalla
                          this.blockedDocument = false;
                        }
                      );
                    }
                  } else {
                    //Consultar nuevamente la lista
                    this.consultarReinicioDeObra();
                  }

                  //Lanzar mensaje de exito
                  this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
              } else {
              }
            },
              error => {
              },
              () => {
                //Desbloquear pantalla
                this.blockedDocument = false;
              }
            );
          }

        }
      });








    } else {
      this.lanzarMensajeWarning("Por favor indique si requiere Protocolo de Bioseguridad");
    }
  }

  rowSelectReincioObra(_c19ReinicioObra: C19ReinicioObra) {
    this.fechaEstadoReinicioObra = new Date(_c19ReinicioObra.fechaInicio);
    this.estadoReinicioObraSeleccionado = this.estadosReinicioObra.find(t => t.id === _c19ReinicioObra.idEstadoReinicioObra);
    this.idReinicioObraEditando = _c19ReinicioObra.id;
    this.disabledPublicar = true;
  }

  obtenerMinDateAPartirDel(): Date {
    if (this.idReinicioObraEditando != null) {
      return null;
    } else {
      if (this.ultimoReinicioObraCovid != null) {
        return this.ultimoReinicioObraCovid.fechaInicio;
      } else {
        return null;
      }
    }
  }

  rowSelectArticulacion(_c19Articulacion: C19ReinicioArticulacion) {
    this.articulacionCovid = _c19Articulacion;
    this.tipoEntidadArticulacionSeleccionado = this.tipoEntidadArticulacionList.find(t => t.id === this.articulacionCovid.idEntidadArticulacion);
    this.visibleDialogoAgregarArticulacion = true;
    /**this.fechaEstadoReinicioObra = new Date(_c19ReinicioObra.fechaInicio);
    this.estadoReinicioObraSeleccionado = this.estadosReinicioObra.find(t => t.id === _c19ReinicioObra.idEstadoReinicioObra);
    this.idReinicioObraEditando = _c19ReinicioObra.id;
    this.disabledPublicar = true;**/
  }

  rowSelectTrabajadoresPorDepartamento(_c19TrabajadoresPorDepto: C19TrabajadoresPorDepartamento) {
    this.seEstaEditando = true;
    this.trabajadoresPorDepartamento = _c19TrabajadoresPorDepto;
    //this.tipoEntidadArticulacionSeleccionado = this.tipoEntidadArticulacionList.find(t => t.id === this.articulacionCovid.idEntidadArticulacion);

  }

  outputSelectorMunicipio(_departamentoObj) {
    this.trabajadoresPorDepartamento.idDepartamento = _departamentoObj.departamento.id;
    this.trabajadoresPorDepartamento.nombreDepartamento = _departamentoObj.departamento.nombre;
  }

  anularUltimaPublicacion() {
    //Lanzar mensaje de advertencia...
    this.confirmationService.confirm({
      message: 'Esto le permitirá únicamente anular una publicación realizada en la última semana y sus respectivas actualizaciones. Si la publicación la realizó hace mas de una semana no podra anularse. ¿Desea continuar?',
      accept: () => {

        //Bloquear pantalla
        this.blockedDocument = true;
        //Guardar los cambios en BD
        forkJoin(
          this.covidReportarReinicioObraService.srvReactivarReinicioObra(this.idProyecto)
        ).subscribe(([result]) => {
          if (result != null && result.respuestaServicio != null) {
            //Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              //Limpiar campos
              this.fechaEstadoReinicioObra = null;
              this.estadoReinicioObraSeleccionado = null;
              //Consultar nuevamente la lista
              this.consultarReinicioDeObra();
              //Colocar publicado en false (en memoria)
              this.reinicioObraCovid.publicado = false;
              //Volver a seguimientos reportados
              //this.irASeguimientosReportados();
              //Lanzar mensaje de exito
              this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
          error => {
          },
          () => {
            //Desbloquear pantalla
            this.blockedDocument = false;
          }
        );

      }
    });
  }

  ///////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje, life: 10000 });
  }

  lanzarMensajeError(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje, life: 10000 });
  }

  lanzarMensajeWarning(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje, life: 10000 });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  irACovidReportar() {
    this._location.back();
  }

}
