import { Component, OnInit, ViewChild  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PronunciamientoService } from './pronunciamiento.service';
import { Ambiental } from '../pronunciamiento/ambiental';
import { MatTableDataSource, MatSort, MatPaginator, MatToolbar,  MatDialog, MatDialogRef } from '@angular/material';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


export interface Pronunciamiento {
  fechaPronunciamiento: string;
  pronunciamiento: string;

}

const ELEMENT_DATA: Pronunciamiento[] = [
  {fechaPronunciamiento: '02/01/2019', pronunciamiento: 'Pronunciamiento 1'},
  {fechaPronunciamiento: '01/04/2019', pronunciamiento: 'Pronunciamiento 2'},
  {fechaPronunciamiento: '03/07/2019', pronunciamiento: 'Pronunciamiento 3'},
  {fechaPronunciamiento: '10/09/2019', pronunciamiento: 'Pronunciamiento 4'},
  {fechaPronunciamiento: '09/10/2019', pronunciamiento: 'Pronunciamiento 5'},
  {fechaPronunciamiento: '02/11/2019', pronunciamiento: 'Pronunciamiento 6'},
  {fechaPronunciamiento: '07/12/2019', pronunciamiento: 'Pronunciamiento 7'},
  {fechaPronunciamiento: '08/11/2019', pronunciamiento: 'Pronunciamiento 8'},
  {fechaPronunciamiento: '12/03/2019', pronunciamiento: 'Pronunciamiento 9'},
  {fechaPronunciamiento: '04/01/2019', pronunciamiento: 'Pronunciamiento 10'},
];

@Component({
  selector: 'app-pronunciamiento',
  templateUrl: './pronunciamiento.component.html',
  styleUrls: ['./pronunciamiento.component.sass']
})




export class PronunciamientoComponent implements OnInit {

  proyecto: Ambiental = {};
  tipoObligacion: any = [];
  idAmbientalDetalle: any;


  displayedColumns: string[] = [
    'fechaPronunciamiento',
    'pronunciamiento',
];

dataSource: MatTableDataSource<Pronunciamiento>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private detalleService: PronunciamientoService, private route: ActivatedRoute, private ruta: Router, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(ELEMENT_DATA);
    this.route.params.subscribe(params => {
      this.idAmbientalDetalle = params.id;
     // this.idAmbientalDetalle === 'new' ? this.cargarNuevo(this.idAmbientalDetalle) : this.cargarProyectoAmbiental(this.idAmbientalDetalle);
    });
    this.cargarNuevo(this.idAmbientalDetalle)
    // this.cargarTipoObligacion();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  cargarTipoObligacion() {
    return this.detalleService.getTipoObligacion().subscribe((data: {}) => {
      this.tipoObligacion = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarNuevo(id) {
    this.proyecto = {};
    // this.proyecto.idProyecto = JSON.parse(localStorage.getItem('idP'));
  }

  cargarProyectoAmbiental(id: number) {
    this.detalleService.getProyectoAmbiental(id).subscribe((data: {}) => {
      this.proyecto = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  guardarDetalle(payload) {
    if (this.idAmbientalDetalle === 'new') {
        return this.detalleService.guardarNuevoAmbiental(payload).subscribe((data: {}) => {
          this.ruta.navigate(['/infAmbiental', JSON.parse(localStorage.getItem('idP'))]);
          },error => {
            this.principalComponent.cargarErrorServicio(error);
          });
    } else {
        return this.detalleService.guardarDetalleAmbiental(payload).subscribe((data: {}) => {
          this.ruta.navigate(['/infAmbiental', JSON.parse(localStorage.getItem('idP'))]);
          },error => {
            this.principalComponent.cargarErrorServicio(error);
          });
    }
  }

}
