import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ValidacionInformacionProyectoService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public getValidacionInformacionProyecto(idProyecto) {
        return this.http.get(this.END_POINT + '/TrnProyecto/validacionInformacionProyecto/' + idProyecto)
            .pipe(
                retry(1));
    }

    public publicarProyecto(idProyecto) {
        return this.http.put(this.END_POINT + '/TrnProyecto/publicarProyecto/'+idProyecto, null)
            .pipe(
                retry(1));
    }

    public getDatosConfiguracionProyecto(idProyecto) {
        return this.http.get(this.END_POINT + '/TrnProyecto/datosConfiguracionProyecto/' + idProyecto)
            .pipe(
                retry(1));
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }


}
