import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { PredialService } from "./predial.service";
import {
  MatTableDataSource,
  MatSort,
  MatPaginator,
  MatDialog,
  MatDialogRef,
} from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { Chart } from "angular-highcharts";
import { UnidadFuncional } from "../../../dtos/predial/unidad.funcional";
import { DisponibilidadPredialUF } from "../../../dtos/predial/disponibilidad-predial-uf";
import { ProgressSpinnerDialogComponent } from "../../../progress-spinner-dialog/progress-spinner-dialog.component";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { MessageService } from "primeng/api";
import * as Highcharts from "highcharts";

@Component({
  selector: "app-predial",
  templateUrl: "./predial.component.html",
  styleUrls: ["./predial.component.sass"],
  providers: [PredialService],
})
export class PredialComponent implements OnInit {
  // general
  public id: number;
  listPrediosT: any;

  // graphics
  public chart: Chart;
  public idConcesion: number;
  public highchartKm: any;
  public highchartPredios: any;
  public listUnidadesFuncionales: Array<UnidadFuncional>;
  public consolidado;
  public listPredios: Array<DisponibilidadPredialUF>;
  public chartKm: any;
  public chartPredios: any;
  public optionsKm: any;
  public optionsPredios: any;

  // Variables de Gráficas
  public porcentajeAvancePredios;
  public porcentajeAvanceLongitud;
  public totalPrediosRequerido: number;
  public totalPrediosDisponibles: number;
  public totalPrediosAdquiridos: number;
  public totalLongitudRequerida: number;
  public totalLongitudDisponible: number;

  displayedColumns: string[] = [
    "unidadFuncionalObj",
    "subsector",
    "fechaObtuvoDisponibilidad",
    "longitud",
    "longitudRequerida",
    "longitudDisponible",
    "areaRequerida",
    "areaDisponible",
    "areaAdquirida",
    /*'lRequeridaCalc',*/
    /*'aRequeridaCalc',*/
    "prediosEnajenados",
    /*'aAdquiridaCalc',*/
    "fechaCorte",
    "LongitudMetrosSubtramo",
    "acciones",
  ];

  dataSource: MatTableDataSource<DisponibilidadPredialUF>;
  dialogRef: MatDialogRef<ProgressSpinnerDialogComponent>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private infoPredial: PredialService,
    private route: Router,
    private messageService: MessageService,
    private ruta: ActivatedRoute,
    private dialog: MatDialog,
    private principalComponent: PrincipalComponent,
    @Inject(DOCUMENT) private document: any
  ) {
    this.porcentajeAvanceLongitud = 0;
    this.porcentajeAvancePredios = 0;
  }
  displayModal: boolean;
  displayModal2: boolean;
  showModalDialog() {
    this.displayModal = true;
  }
  showModalDialog2() {
    this.displayModal2 = true;
  }
  ngOnInit() {
    this.ruta.params.subscribe((params) => {
      this.id = params.id;
    });

    this.dialogRef = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: "transparent",
      disableClose: true,
    });

    setTimeout(() => {
      this.obtenerDatosForm();
    }, 5000);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onEdit(row) {
    localStorage.setItem("dataPredial", JSON.stringify(row));
    this.route.navigate([this.route.url + "/infPredialDetalle", row.concesion]);
  }

  onNew() {
    this.route.navigate([this.route.url + "/infPredialDetalle", "new"]);
  }

  obtenerDatosForm() {
    this.idConcesion = 1;
    this.cargarLista();
  }

  // goToPredial(id) {
  // tslint:disable-next-line:max-line-length
  //   window.open(`https://animapas.maps.arcgis.com/apps/webappviewer/index.html?id=8a579d230aa24d65b340f542fa46a09d&query=predial_temp_9069,COD_PR_IRE,${id}`, '_blank');
  //}

  cargarLista() {
    this.infoPredial.getPrediosDisponibles(this.id).subscribe(
      (datosPredios: {}) => {
        this.consolidado = datosPredios;
        this.listPredios = this.consolidado
          ? this.consolidado.disponibilidadesPorUF
          : new Array<DisponibilidadPredialUF>();
        this.listPrediosT = this.listPredios;
        //this.dataSource.paginator = this.paginator;

        if (
          !this.consolidado ||
          !this.listPredios.length ||
          this.listPredios.length === 0
        ) {
          alert(
            "Resultado de la operación: No hay registros relacionados con la consulta"
          );
        } else {
          this.construirGraficas();
        }
        this.dialogRef.close();
      },
      (error) => {
        this.dialogRef.close();
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  loadData() {
    this.infoPredial.getPrediosDisponibles(this.id).subscribe(
      (datosPredios: {}) => {
        this.consolidado = datosPredios;
        this.listPredios = this.consolidado
          ? this.consolidado.disponibilidadesPorUF
          : new Array<DisponibilidadPredialUF>();
        this.dataSource = new MatTableDataSource(this.listPredios);
        this.dataSource.paginator = this.paginator;

        if (
          !this.consolidado ||
          !this.listPredios.length ||
          this.listPredios.length === 0
        ) {
          alert(
            "Resultado de la operación: No hay registros relacionados con la consulta"
          );
        } else {
          this.construirGraficas();
        }
        this.dialogRef.close();
      },
      (error) => {
        this.dialogRef.close();
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  private construirGraficas() {
    if (this.consolidado && this.consolidado.indicadores) {
      this.porcentajeAvancePredios =
        ((this.consolidado.indicadores.conteoDisponibles
          ? this.consolidado.indicadores.conteoDisponibles
          : 0) *
          100) /
        (this.consolidado.indicadores.conteoRequeridos
          ? this.consolidado.indicadores.conteoRequeridos
          : 1);
      this.porcentajeAvanceLongitud =
        this.consolidado.indicadores.longitudAvance;

      // Graficas con Prime NG

      this.chartKm = {
        labels: ["Longitudes (km)"],
        datasets: [
          {
            label: "Longitud Disponible",
            backgroundColor: "#7DCEEB",
            borderColor: "#7DCEEB",
            data: new Array(
              this.consolidado.indicadores.longitudDisponible.toFixed(0)
            ),
          },
          {
            label: "Longitud Efectiva",
            backgroundColor: "#E4C267",
            borderColor: "#E4C267",
            data: new Array(
              this.consolidado.indicadores.longitudRequerida.toFixed(0)
            ),
          },
        ],
      };

      this.optionsKm = {
        scales: {
          yAxes: [
            {
              ticks: {
                //stepSize: 10,
                beginAtZero: true,
                // callback: (label, index, labels) => {
                //   return label + '%';}
              },
            },
          ],
        },
        title: {
          display: true,
          text:
            "Gráfica de Kilómetros   " +
            this.consolidado.indicadores.longitudAvance.toFixed(0) +
            "% Avance",
          fontSize: 14,
        },
        legend: {
          position: "right",
        },
      };

      this.chartPredios = {
        labels: ["Predios"],
        datasets: [
          {
            label: "Predios Requeridos",
            backgroundColor: "#FC8383",
            borderColor: "#FC8383",
            data: new Array(
              this.consolidado.indicadores.conteoRequeridos.toFixed(0)
            ),
          },
          {
            label: "Predios Disponibles",
            backgroundColor: "#39BB98",
            borderColor: "#39BB98",
            data: new Array(
              this.consolidado.indicadores.conteoDisponibles.toFixed(0)
            ),
          },
          {
            label: "Predios Adquiridos",
            backgroundColor: "#F9D82F",
            borderColor: "#F9D82F",
            data: new Array(
              this.consolidado.indicadores.conteoAdquiridos.toFixed(0)
            ),
          },
        ],
      };
      this.optionsPredios = {
        scales: {
          yAxes: [
            {
              ticks: {
                //stepSize: 10,
                beginAtZero: true,
                // callback: (label, index, labels) => {
                //   return label + '%';}
              },
            },
          ],
        },
        title: {
          display: true,
          text:
            "Gráfica de Predios   " +
            this.porcentajeAvancePredios.toFixed(0) +
            "% Avance",
          fontSize: 14,
        },
        legend: {
          position: "right",
        },
      };

      this.highchartPredios = {
        chart: {
          type: "column",
        },
        xAxis: {
          title: {
            text: "Predios",
          },
          categories: [""],
        },
        yAxis: {
          title: {
            text: "Avance",
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: this.porcentajeAvancePredios.toFixed(0) + " % Avance",
        },

        legend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
        },
        series: [
          {
            name: "Predios Requeridos",
            color: "#FC8383",
            data: [
              {
                // tslint:disable-next-line:max-line-length
                name:
                  '<div style="font-size:12px;font-weigh:100;color:#8C6B6B;">Requeridos</div><br><div style="font-size:16px;font-weight:400;color:#8C6B6B; ">' +
                  this.consolidado.indicadores.conteoRequeridos +
                  "</div> ",
                color: "#FC8383",
                y: this.consolidado.indicadores.conteoRequeridos,
              },
            ],
          },
          {
            name: "Predios Disponible",
            color: "#39BB98",
            data: [
              {
                // tslint:disable-next-line:max-line-length
                name:
                  '<div style="font-size:12px;font-weigh:100;color:#8C6B6B;" >Disponibles</div><br><div style="font-size:16px;font-weight:bold;color:#8C6B6B;">' +
                  this.consolidado.indicadores.conteoDisponibles +
                  "</div>",
                color: "#39BB98",
                y: this.consolidado.indicadores.conteoDisponibles,
              },
            ],
          },
          {
            name: "Predios Adquiridos",
            color: "#F9D82F",
            data: [
              {
                // tslint:disable-next-line:max-line-length
                name:
                  '<div style="font-size:12px;font-weigh:100;color:#8C6B6B;" >Adquiridos</div><br><div style="font-size:16px;font-weight:400;color:#8C6B6B;">' +
                  this.consolidado.indicadores.conteoAdquiridos +
                  "</div> ",
                color: "#F9D82F",
                y: this.consolidado.indicadores.conteoAdquiridos,
              },
            ],
          },
        ],
      };

      let chart1 = Highcharts.chart("chartPredios", this.highchartPredios);

      // adquirido
      this.highchartKm = {
        chart: {
          type: "column",

          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
        },
        xAxis: {
          title: {
            text: "Longitudes",
          },
          categories: [""],
        },
        yAxis: {
          title: {
            text: "Kilómetros",
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text:
            this.consolidado.indicadores.longitudAvance.toFixed(0) +
            " % Avance",
        },

        legend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
        },
        series: [
          {
            innerSize: "75%",
            name: "Longitud Disponible",
            color: "#7DCEEB",
            data: [
              {
                // tslint:disable-next-line:max-line-length
                name:
                  '<div style="font-size:13px;font-weigh:100;color:#6C8690;" >Longitud Disponible</div><br><div style="font-size:13px;font-weight:bold;color:#6C8690;">' +
                  this.consolidado.indicadores.longitudDisponible +
                  " Km</div> ",
                color: "#7DCEEB",
                y: this.consolidado.indicadores.longitudDisponible,
              },
            ],
          },
          {
            name: "Longitud Efectiva",
            color: "#E4C267",
            data: [
              {
                // tslint:disable-next-line:max-line-length
                name:
                  '<div style="font-size:13px;font-weigh:100;color:#6C8690;" >Longitud Efectiva</div><br><div style="font-size:13px;font-weight:bold;color:#6C8690;">' +
                  this.consolidado.indicadores.longitudRequerida +
                  " Km</div> ",
                color: "#E4C267",
                y: this.consolidado.indicadores.longitudRequerida,
              },
            ],
          },
        ],
      };
      let chart2 = Highcharts.chart("chartKm", this.highchartKm);
    } else {
      // const precarga = this._alert.showPreloader();
      // const dialog = this._alert.showInformation('Resultado de la operación', 'No hay registros relacionados con la consulta');
      // dialog.afterClosed().subscribe(() => {
      // precarga.close();
      // });
    }
  }

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }
}
