import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { CtoNovedadContratoFuenteDTO, CtoNovedadLiberacionFuenteDTO } from './cto-novedad-contrato-fuente';



export class CtoNovedadContratoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public idNovedad?: number,
        public novedadesContrato?: CtoNovedadContratoDTO[],
        public novedadContrato?: CtoNovedadContratoDTO,
    ) { }
}

export class CtoNovedadContratoDTO {

    constructor(
        public id?: number,
        public idContrato?: number,
        public idNovedadPadre?: number,
        public idTipoNovedad?: number,
        public tipoNovedad?: string,
        public fechaSuscripcion?: Date,
        public fechaAplicacion?: Date,
        public idPersona?: number,
        public idPersonaJuridica?: number,
        public idImputacionRecurso?: number,
        public idTipoRecurso?: number,
        public objetoContrato?: string,
        public esResponsableIva?: boolean,
        public estaObligadoFacturar?: boolean,
        public honorariosMensuales?: number,
        public plazoDias?: number,
        public plazoMeses?: number,
        public plazoAnos?: number,
        public nuevaFechaTerminacion?: Date,
        public estadoAplicacionNovedad?: string,
        public observaciones?: string,
        public usuarioActualizacion?: number,
        public fechaCreacion?: Date,
        public fechaUltimaActualizacion?: Date,
        public fechaActual?: Date,
        public diferenciaHoras?: number,
        public habilitarEdicion?: boolean,
        public novedadContratoFuentes?: CtoNovedadContratoFuenteDTO[],
        public novedadLiberacionFuente?: CtoNovedadLiberacionFuenteDTO[]
    ) { }
}
