import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-visualizar-archivo',
  templateUrl: './visualizar-archivo.component.html',
  styleUrls: ['./visualizar-archivo.component.sass']
})
export class VisualizarArchivoComponent implements OnInit {

  @Input()
  nombreArchivo: string;
  @Input()
  nombreModulo: string;
  @Output()
  inicioVisualizarArchivoEmiter = new EventEmitter();
  @Output()
  finVisualizarArchivoEmiter = new EventEmitter();
  @Input()
  nombreContenedor: string;

  msgValidacionPopupBlocker: string = 'Por favor deshabilite su bloqueador de ventanas emergentes e intente nuevamente.';


  constructor(
    private http: HttpClient,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
  }

  calcularTipoArchivo(nombreArchivo): string {
    switch (nombreArchivo.substr(nombreArchivo.lastIndexOf('.') + 1, nombreArchivo.length + 1 - nombreArchivo.lastIndexOf('.'))) {
      case "pdf":
        return 'application/pdf';
      case 'jpg':
        return 'image/jpg';
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'zip':
        return 'application/zip';
      case 'rar':
        return 'application/zip';
      case 'txt':
        return 'text/plain';
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'csv':
        return 'application/vnd.ms-excel';
      case "PDF":
        return 'application/pdf';
      case 'JPG':
        return 'image/jpg';
      case 'JPEG':
        return 'image/jpeg';
      case 'PNG':
        return 'image/png';
      case 'ZIP':
        return 'application/zip';
      case 'RAR':
        return 'application/zip';
      case 'TXT':
        return 'text/plain';
      case 'DOC':
        return 'application/msword';
      case 'DOCX':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'CSV':
        return 'application/vnd.ms-excel';
      case 'XLS': case 'xls':
        return 'application/vnd.ms-excel';
      case 'XLSX': case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return 'application/pdf';
    }
  }

  verAchivo() {
    this.inicioVisualizarArchivoEmiter.emit();
    this.http.post(environment.baseUrl + '/Comun/getFile', { nombre: this.nombreArchivo, nombreContenedor: this.nombreContenedor },
      {
        responseType: 'arraybuffer'
      }
    ).subscribe(
      response => {
        console.error(response);
        this.downLoadFile(response, this.calcularTipoArchivo(this.nombreArchivo))
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);    
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert(this.msgValidacionPopupBlocker);
    }
    this.finVisualizarArchivoEmiter.emit();
  }

}
