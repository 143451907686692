import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';
import { InformacionGeneralService } from '../informacion-general/informacion-general.service';

@Component({
  selector: 'app-menu-financiero',
  templateUrl: './menu-financiero.component.html',
  styleUrls: ['./menu-financiero.component.sass']
})
export class MenuFinancieroComponent implements OnInit {
  id: number;
  proyecto: any;

  constructor(private route: ActivatedRoute, private infoService: InformacionGeneralService, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.cargarProyectoCarretero(this.id)
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }
  vigenciasFuturas() {
    window.open("http://apptransicionaniscopio-dev-as.azurewebsites.net/FIN_VIGENCIA_FUTURA/Index/" + this.id, "_blank")
  }
  vpip() {
    window.open("http://apptransicionaniscopio-dev-as.azurewebsites.net/FIN_CONTRAPRESTACION/Index/" + this.id, "_blank")

  }
  retribuciones() {
    window.open("http://apptransicionaniscopio-dev-as.azurewebsites.net/FIN_RETRIBUCION/Index/" + this.id, "_blank")

  }
  saldos() {
    window.open("http://apptransicionaniscopio-dev-as.azurewebsites.net/FIN_SALDO/Index/" + this.id, "_blank")

  }
  financieraGeneral() {
    window.open("http://apptransicionaniscopio-dev-as.azurewebsites.net/CCS_CONCESION/Details/" + this.id, "_blank")
  }
}
