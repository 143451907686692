import { Component, OnInit, EventEmitter, ChangeDetectorRef, } from '@angular/core';
import { UploadFileService, } from './upload-file.service';
import { Video } from '../search.interface';
import { Archivo } from '../upload-file/archivo';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';


import {
    HttpClient,
    HttpEventType,
    HttpHeaders
  } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';
import * as _moment from 'moment';
  

@Component({
    selector: 's-upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.sass','./upload-file.component.css'],
    providers: [UploadFileService]
})
export class UploadFileComponent implements OnInit {
  
  modulo: string;
  idArchivosChange = new EventEmitter<Archivo[]>();
  idArchivos: Archivo[];
  id: string;
  soloUnArchivo: boolean;
  nombreInicialArchivo: string;
  displayedColumns1;
  listado: any = [];
    
  idTiposSoporte: string;
  habilitaEnvio: boolean = true;
  esBlob: boolean = false;
  contenedor: string;

  public files: NgxFileDropEntry[] = [];
  tipoSoporteSeleccionado: any;
  tiposSoporte: any = [];
  filtroTiposSoporte: any[];
  public _files: Archivo[] = [];
  public _filesTemp: Archivo[] = [];

  OutputValidacionArchivos = new EventEmitter();
  ocurrioAlgunError: boolean;



  videos: Video[] = [];
  progress: number;


  yaSeVisualizo: boolean;
  tamanioMegas :any;
  tamanioMegasStr :any;
  numeroArchivos :any;
  errorNumeroArchivo: boolean;

    // Inicializaciones de Variables
    loading : boolean;
    inputTouched = false;

    constructor(private svc: UploadFileService, private http: HttpClient,
        private messageService: MessageService,
        private principalComponent: PrincipalComponent,
        private cdr: ChangeDetectorRef ) { }

    ngOnInit() {
        this.loading = true;
        this.modulo = "radicacion-web";
        this.nombreInicialArchivo = "carguePrueba1";
        this.esBlob = true;
        this.contenedor = "radicacion-web";
        this.id = "radicacion-web_";

        this.displayedColumns1 = [
          { field: 'nombreArchivo', header: 'Nombre Archivo' },
          { field: 'tiempoInicial', header: 'Tiempo Inicial' },
          { field: 'tiempoFinal', header: 'Tiempo Final' },
          { field: 'totalEjecutado', header: 'Total Ejecutado' },
          { field: 'tamanioArchivo', header: 'Tamaño Archivo' },
          { field: 'rutaCreada', header: 'Ruta Creada' }
        ];

        this.cargarlistado();

        this.tamanioMegas = 524288000;   //500MB  --524288000
        this.numeroArchivos = 3;
        this.errorNumeroArchivo = false;
        this.tamanioMegasStr = (this.tamanioMegas / (1000 * 1000 )) + " Megas";


        //this.cargarTiposSoporte("");
        this.tipoSoporteSeleccionado = { "formatoPermitido": ".avi,.mp4"}
        this.loading = false;
    }

    
    /**
     * Metodo que carga el listado
     */
     cargarlistado(){
      return this.svc.getListado().subscribe((data: {}) => {
        this.responseData(data);
      }, (error)=>{
          this.loading = false;
          this.principalComponent.cargarErrorServicio(error);
      });    
    }

    /**
     * Metodo que racorre la info
     * @param payload 
     */
     responseData(payload) {
      if (payload === null) {
        this.lanzarMensajeError("No se econtraron datos");
        this.loading = false;
      } else {
        this.listado = payload;
        this.loading = false;
      }
    }
  

    ngAfterViewChecked(){
      //your code to update the model
      this.cdr.detectChanges();
    }

    public dropped(files: NgxFileDropEntry[]) {
      this.files = files;
  
      if (this.soloUnArchivo) {
        this._files = [];
        this._filesTemp = [];
      }
  
      let index: number = 0;
  
      this._filesTemp.forEach(element => {
        if (element.estado == 'Tamaño no valido') {
          this._filesTemp.splice(index, 1);
        }
        if (element.estado == 'Formato no válido') {
          this._filesTemp.splice(index, 1);
        }
        index++;
      });
  
      this.habilitaEnvio = true;
      this.ocurrioAlgunError = false;
      let sum = this._filesTemp.length + files.length;
      if(sum > this.numeroArchivos){
          this.errorNumeroArchivo = true;
          this.habilitaEnvio = true;
          this.ocurrioAlgunError = false;

          if(this.numeroArchivos > 0){
            if (!this.ocurrioAlgunError) {
              this.OutputValidacionArchivos.emit("OK");
            } else {
              this.OutputValidacionArchivos.emit("ERROR");
            }
            this.idArchivosChange.emit(this._filesTemp);
            this.idArchivosChange.emit(this._files);
          }
      }else{
        this.errorNumeroArchivo = false;
        for (const droppedFile of files) {
          // Is it a file?
          if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
              var archivoCargueBase = null;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                  archivoCargueBase = reader.result;

                  var archivo = new Archivo(droppedFile.relativePath, "Enviando...", droppedFile.relativePath, this.tipoSoporteSeleccionado.id, archivoCargueBase);
                  this._files.push(archivo);
                  this._filesTemp.push(archivo);

                  if (file.size <= this.tamanioMegas) {//Tamano maximo permito son 500MB

                    const fechaActual = _moment().format('YYYY-MM-DD HH:mm:ss');;
                    const uploadData = new FormData();
                    uploadData.append('file', file, droppedFile.relativePath);
                    uploadData.append('modulo', this.modulo);
                    uploadData.append('id', this.id);
                    uploadData.append('nombreInicialArchivo', file.name);
                    uploadData.append('fechaInicio', String(fechaActual));
                    uploadData.append('esBlob', String(this.esBlob));
                    uploadData.append('contenedor', this.contenedor);
                    this.svc
                      .uploadFile(uploadData)
                      .subscribe(
                        (response: any) => {
                          this.cargarlistado();
                          archivo.estado = "Cargado";
                          archivo.nombreCompleto = response.fileName;
                          archivo.idTipoDocumento = this.tipoSoporteSeleccionado.id;
                          archivo.nombreCompleto = response.fileName;
                          let todosEviados: boolean = true;
                          this._filesTemp.forEach(element => {
                            if (element.estado != "Cargado" ) {
                              todosEviados = false;
                            }
                          });
                          if (todosEviados) {
                            this.habilitaEnvio = false;
                          }
                        },
                        (error) => {
                          console.error(error);
                          this.principalComponent.cargarErrorServicio(error);
                          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
                          archivo.estado = "No se pudo adjuntar";
                          this.ocurrioAlgunError = true;
                          this.habilitaEnvio = true;
                        }
                      );
                  } else {  
                    archivo.estado = "Tamaño no valido";
                    this.ocurrioAlgunError = true;
                    this.habilitaEnvio = true;
                  }
              };            
            });
          } else {
            var archivo = new Archivo(droppedFile.relativePath, "Formato no válido", droppedFile.relativePath, this.tipoSoporteSeleccionado.id, null);
            this.ocurrioAlgunError = true;
          }
        }
    
        if (!this.ocurrioAlgunError) {
          this.OutputValidacionArchivos.emit("OK");
        } else {
          this.OutputValidacionArchivos.emit("ERROR");
        }
        this.idArchivosChange.emit(this._filesTemp);
      }
    }

    /**
     * Metodo que valida los tamaños
     */
    validarArchivos(){
      if(this._files.length > this.numeroArchivos){
        this.errorNumeroArchivo = true;
        return true;
      }else{
        this.errorNumeroArchivo = false;
        return false;
      }
    }
  
    limpiarArchivos() {
      //this.tipoSoporteSeleccionado = null;
      this._files = [];
      this.habilitaEnvio = true;
    }
  
    isFileAllowed(fileName: string) {
      let isFileAllowed = false;
      const allowedFiles = this.tipoSoporteSeleccionado.formatoPermitido.split(',');
      const regex = /(?:\.([^.]+))?$/;
      const extension = regex.exec(fileName);
  
      if (undefined !== extension && null !== extension) {
        for (const ext of allowedFiles) {
          if (ext === extension[0]) {
            isFileAllowed = true;
          }
        }
      }
      return isFileAllowed;
    }
  
    public fileOver(event) {
      this.validarArchivos();
    }
  
    public fileLeave(event) {
      this.validarArchivos();
    }
  
    cargarTiposSoporte(event) {
      if (this.idTiposSoporte == null) {
      } else {
      }
      this.filtroTiposSoporte = [];
      this.svc.getTiposSoporte(this.modulo, this.idTiposSoporte).subscribe((data: {}) => {
        this.tiposSoporte = data;
        this.tipoSoporteSeleccionado = {
            id :  this.tiposSoporte[0].id,
            modulo :  this.tiposSoporte[0].modulo,
            formatoPermitido :  this.tiposSoporte[0].formatoPermitido,
            idTipoDocumentoAsociado :  this.tiposSoporte[0].idTipoDocumentoAsociado,
            nombre :  this.tiposSoporte[0].nombre,
            titulo :  this.tiposSoporte[0].titulo
        }

      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });		  
      });
    }

    eliminarAnexo(evento){
        let index: number = 0;
        this.errorNumeroArchivo = false;

        this._files.forEach(element => {
            if (element.nombre == evento.nombre && element.nombreCompleto == evento.nombreCompleto) {
              this._files.splice(index, 1);
            }
            index++;
          });

          if(this._files.length == 0){
            this.habilitaEnvio = false;
          }
    }

     // /////////////////////////////Mensajes//////////////////////////////
     lanzarMensajeInfo(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
    }
    lanzarMensajeError(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    }
    lanzarMensajeWarning(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
    }

    limpiarMensajes() {
        this.messageService.clear();
    }


}
