import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData, CommonModule, DatePipe } from '@angular/common';


import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import localePy from '@angular/common/locales/es-PY';
import localeEsAr from '@angular/common/locales/es-AR';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule, MatTableModule, MatTabsModule, MatFormFieldModule, MatPaginatorModule, MatOptionModule, MatCheckboxModule,
  MatInputModule, MatIconModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatToolbarModule,
  MatDividerModule, MatAutocompleteModule, MatRadioModule, MatGridListModule, MatProgressSpinnerModule, MatCardModule,
  MatPaginatorIntl, MatDialogModule, MatListModule, MatTooltipModule, MatExpansionModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS
} from '@angular/material';

import { ChartModule } from 'angular-highcharts';
// import {ChartModule} from 'primeng/chart';

import { RecaptchaModule } from 'ng-recaptcha';

// Import PrimeNG modules
import { CarouselModule, ConfirmationService, MessageService } from 'primeng/primeng';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PaginatorModule } from 'primeng/primeng';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { CardModule } from 'primeng/card';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';


import { ReportesComponent } from './home/home-carreteros/proyecto-carreteras/reportes.component';
import { PrincipalComponent } from './home/principal/principal.component';
import { HomeTramitesComponent } from './home/home-tramites/home-tramites.component';
import { InformacionGeneralComponent } from './informacion-general/informacion-general.component';
import { AmbientalComponent } from './componentes/ambiental/lista/ambiental.component'
import { AmbientalDetalleComponent } from './componentes/ambiental/detalle/ambiental-detalle.component';

import { PredialComponent } from './componentes/predial/lista/predial.component';
import { PredialDetalleComponent } from './componentes/predial/detalle/predial-detalle.component';

import { UnidadFuncionalComponent } from './unidad-funcional/unidad-funcional.component';
import { HomeComponent } from './home/home/home.component';
import { ProyectoComponent } from './proyecto/proyecto.component';
import { ProyectoHomeComponent } from './proyecto-home/proyecto-home.component';
import { UnidadFuncionalDetalleComponent } from './unidad-funcional-detalle/unidad-funcional-detalle.component';
import { InversionComponent } from './financiero/inversion/inversion.component';
import { InversionDetalleComponent } from './financiero/inversion-detalle/inversion-detalle.component';
import { FinancieroHomeComponent } from './financiero/financiero-home/financiero-home.component';
import { ProgressSpinnerDialogComponent } from './progress-spinner-dialog/progress-spinner-dialog.component';
import { InversionProyectoComponent } from './financiero/inversion-proyecto/inversion-proyecto.component';
import { InversionProyectoDetalleComponent } from './financiero/inversion-proyecto-detalle/inversion-proyecto-detalle.component';
import { ModuloJuridicoComponent } from './modulo-juridico/modulo-juridico.component';
import { NuevoIntervencionComponent } from './modulo-juridico/nuevo-intervencion/nuevo-intervencion.component';
import { MenuPrincipalComponent } from './modulo-juridico/menu-principal/menu-principal.component';
import { AmigablesComponedoresComponent } from './modulo-juridico/amigables-componedores/amigables-componedores.component';
import { PronunciamientoComponent } from './modulo-juridico/pronunciamiento/pronunciamiento.component';
import { AudienciaPreliminarComponent } from './modulo-juridico/audiencia-preliminar/audiencia-preliminar.component';
import { AudienciaPruebasComponent } from './modulo-juridico/audiencia-pruebas/audiencia-pruebas.component';
import { AudienciaAlegatosComponent } from './modulo-juridico/audiencia-alegatos/audiencia-alegatos.component';
import { AudienciaDecisionComponent } from './modulo-juridico/audiencia-decision/audiencia-decision.component';
import { SolicitudAclaracionComponent } from './modulo-juridico/solicitud-aclaracion/solicitud-aclaracion.component';
import { DecisionAclaracionComponent } from './modulo-juridico/decision-aclaracion/decision-aclaracion.component';
import { TerminacionAnticipadaComponent } from './modulo-juridico/terminacion-anticipada/terminacion-anticipada.component';
import { InformacionGeneralFinancieroComponent } from './financiero/informacion-general/informacion-general.component';
import { MsAdalAngular6Module, AuthenticationGuard } from 'microsoft-adal-angular6';
import { environment } from '../environments/environment';
import { InformacionGeneralDetalleComponent } from './financiero/informacion-general-detalle/informacion-general-detalle.component';
import { TransversalesComponent } from './transversales/transversales.component';
import { ContratoCreditoComponent } from './financiero/contrato-credito/contrato-credito.component';
import { ContratoCreditoDetalleComponent } from './financiero/contrato-credito-detalle/contrato-credito-detalle.component';
import { MenuJuridicoComponent } from './modulo-juridico/menu-juridico/menu-juridico.component';
import { TribunalesComponent } from './componentes/juridico/tribunales/lista/tribunales.component';
import { SeguimientoTribunalesComponent } from './componentes/juridico/tribunales/seguimiento/seguimiento-tribunales.component';
import { AmigablesComponent } from './modulo-juridico/amigables/amigables.component';
import { AmigablesDetalleComponent } from './modulo-juridico/amigables-detalle/amigables-detalle.component';
import { TribunalesDetalleComponent } from './componentes/juridico/tribunales/detalle/tribunales-detalle.component';
import { getSpaPaginatorIntl } from './comun/spa-paginator-intl';
import { CarreteroHomeComponent } from './home/home-carreteros/carretero-home.component';
import { MenuFinancieroComponent } from './menu-financiero/menu-financiero.component';
import { ProyectoLineaBaseComponent } from './proyecto-linea-base/proyecto-linea-base.component';
//REFACTORING
import { CarreteroVisorMapaComponent } from './reportes/internos/carretero-home-visor-mapa/carretero-home-visor-mapa.component';
import { AeropuertosHomeVisorMapaComponent } from './reportes/internos/aeropuertos-home-visor-mapa/aeropuertos-home-visor-mapa.component';
import { PuertoHomeVisorMapaComponent } from './reportes/internos/puerto-home-visor-mapa/puerto-home-visor-mapa.component';
import { FerreoVisorMapaComponent } from './reportes/internos/reportes-ferreo-visor-mapa/reportes-ferreo-visor-mapa.component';
import { VisorReportePredialComponent } from './reportes/internos/visor-reporte-predial/visor-reporte-predial.component';
import { InformacionProyectoCarreteroComponent } from './informacion-proyecto-carretero/informacion-proyecto-carretero.component';
import { ConcesionarioCarreteroComponent } from './concesionario-carretero/concesionario-carretero.component';
import { InterventoriaCarreteroComponent } from './interventoria-carretero/interventoria-carretero.component';
import { InterventoriaCarreteroDetalleComponent } from './interventoria-carretero-detalle/interventoria-carretero-detalle.component';
import { UnidadFuncionalCarreteroComponent } from './unidad-funcional-carretero/unidad-funcional-carretero.component';
import { UnidadFuncionalCarreteroDetalleComponent } from './unidad-funcional-carretero-detalle/unidad-funcional-carretero-detalle.component';
import { ContratoCreditoCarreteroComponent } from './contrato-credito-carretero/contrato-credito-carretero.component';
import { TemasGestionComponent } from './componentes/temas-gestion/lista/temas-gestion.component';
import { TemasGestionDetalleComponent } from './componentes//temas-gestion/detalle/temas-gestion-detalle.component';
import { TemasGestionRegistroComponent } from './componentes//temas-gestion/registro/temas-gestion-registro.component';
import { SeguimientoDialog } from './componentes/temas-gestion/lista/temas-gestion.component';

import { InteresadosListaComponent } from './componentes/interesados/lista/interesados-lista.component';
import { InteresadosExternosComponent } from './componentes/interesados-externos/lista/interesados-externos.component';


import { ComponedoresDialog } from './modulo-juridico/amigables-detalle/amigables-detalle.component';
import { ApoderadosDialog } from './modulo-juridico/amigables-detalle/amigables-detalle.component';
import { ArbitrosDialog } from './modulo-juridico/tribunales-detalle/tribunales-detalle.component';
import { ConfirmacionDialog } from './componentes/tramites-ambientales/detalle/tramite-ambiental-detalle.component';
import { EliminiarDialog } from './componentes/tramites-ambientales/lista/tramite-ambiental.component';
import { TramiteAmbientalComponent } from './componentes/tramites-ambientales/lista/tramite-ambiental.component';
import { TramiteAmbientalDetalleComponent } from './componentes/tramites-ambientales/detalle/tramite-ambiental-detalle.component';
import { SeguimientoComponent } from './componentes/tramites-ambientales/seguimiento/seguimiento.component';
import { Interceptor } from './interceptor';
import { CanActivateViaAuthGuard } from './CanActivateViaAuthGuard';
import { DisableIfUnauthorizedDirectiveDirective } from './disable-if-unauthorized-directive.directive';
import { DisableLinkADirectiveDirective } from './disable-link-a-directive.directive';
import { FerreoHomeComponent } from './home/home-ferreo/ferreo-home.component';
import { PuertosHomeComponent } from './home/home-puertos/puertos-home.component';
import { AeropuertosHomeComponent } from './home/home-aeropuerto/aeropuertos-home.component';
import { AppInsightsService } from './app-insights.service';
import { ReportesAeropuertoComponent } from './reportes/internos/reportes-aeropuerto/reportes-aeropuerto.component';
import { ReportesPuertosComponent } from './reportes/internos/reportes-puertos/reportes-puertos.component';
import { ReportesFerroComponent } from './reportes/internos/pagina-menu-reportes-ferro/reportes-ferro.component';
import { ProyectoHomeAeroComponent } from './proyecto-home-aero/proyecto-home-aero.component';
import { ProyectoHomeFerreoComponent } from './proyecto-home-ferreo/proyecto-home-ferreo.component';
import { ProyectoHomePuertoComponent } from './proyecto-home-puerto/proyecto-home-puerto.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ListarPlaneacionesComponent } from './modulo-planeacion-proyecto/listar-planeaciones/listar-planeaciones.component';
import { CrearPlaneacionComponent } from './modulo-planeacion-proyecto/crear-planeacion/crear-planeacion.component';
import { SeleccionarUnidadfuncionalComponent } from './componentes-comunes/seleccionar-unidadfuncional/seleccionar-unidadfuncional.component';
import { SeleccionarProyectoComponent } from './componentes-comunes/seleccionar-proyecto/seleccionar-proyecto.component';
import { FuncionalesDialog } from './componentes/tramites-ambientales/detalle/tramite-ambiental-detalle.component';
import { EstadosDialog } from './componentes/tramites-ambientales/seguimiento/seguimiento.component';
import { PriorizacionesDialog } from './componentes/tramites-ambientales/seguimiento/seguimiento.component';
import { ResponsablesDialog } from './componentes/tramites-ambientales/seguimiento/seguimiento.component';
import { ObservacionesDialog } from './componentes/tramites-ambientales/seguimiento/seguimiento.component';
import { ActuacionDialog } from './componentes/juridico/tribunales/seguimiento/seguimiento-tribunales.component';
import { ActuacionADialog, PersonaAmigableDialog } from './modulo-juridico/seguimiento-amigable/seguimiento-amigable.component';
import { NotificacionDialog } from './componentes/juridico/tribunales/seguimiento/seguimiento-tribunales.component';
import { SecretarioDialog } from './componentes/juridico/tribunales/seguimiento/seguimiento-tribunales.component';
import { SeleccionarIntervencionComponent } from './componentes-comunes/seleccionar-intervencion/seleccionar-intervencion.component';
import { PolizasComponent } from './polizas/principal/polizas.component';
import { PolizaEditComponent } from './polizas/poliza-edit/poliza-edit.component';

import { TramitesAmbientalesListadoComponent } from './tramites-ambientales-aeropuerto/tramites-ambientales-listado/tramites-ambientales-listado.component';
import { TramitesAmbientalesComponent } from './tramites-ambientales-aeropuerto/tramites-ambientales/tramites-ambientales.component';
import { TramitesAmbientalesSeguimientoComponent } from './tramites-ambientales-aeropuerto/tramites-ambientales-seguimiento/tramites-ambientales-seguimiento.component';

import { TramitesAmbientalesListadoFerreoComponent } from './tramites-ambientales-ferreo/tramites-ambientales-listado/tramites-ambientales-listado.component';
import { TramitesAmbientalesFerreoComponent } from './tramites-ambientales-ferreo/tramites-ambientales/tramites-ambientales.component';
import { TramitesAmbientalesSeguimientoFerreoComponent } from './tramites-ambientales-ferreo/tramites-ambientales-seguimiento/tramites-ambientales-seguimiento.component';

import { TramitesAmbientalesListadoPuertoComponent } from './tramites-ambientales-puerto/tramites-ambientales-listado/tramites-ambientales-listado.component';
import { TramitesAmbientalesPuertoComponent } from './tramites-ambientales-puerto/tramites-ambientales/tramites-ambientales.component';
import { TramitesAmbientalesSeguimientoPuertoComponent } from './tramites-ambientales-puerto/tramites-ambientales-seguimiento/tramites-ambientales-seguimiento.component';



import { AmparosComponent } from './polizas/amparos/amparos.component';
import { AmparosEditComponent } from './polizas/amparos/amparos-edit/amparos-edit.component';
import { InformacionGeneralBaseComponent } from './financiero/informacion-general-base/informacion-general-base.component';
import { SeleccionarEjecucionComponent } from './componentes-comunes/seleccionar-ejecucion/seleccionar-ejecucion.component';
import { SaldoFinancieroComponent } from './financiero/saldo-financiero/saldo-financiero.component';
import { RetribucionFinancieraComponent } from './financiero/retribucion-financiera/retribucion-financiera.component';
import { VigenciasFuturasComponent } from './financiero/vigencias-futuras/vigencias-futuras.component';
import { VpipFinancieroComponent } from './financiero/vpip-financiero/vpip-financiero.component';
import { InversionIndexadaComponent } from './financiero/inversion-indexada/inversion-indexada.component';

import { SelectorFechaQuincenaComponent } from './componentes-comunes/selector-fecha-quincena/selector-fecha-quincena.component';
import { AppDateAdapter, APP_DATE_FORMATS } from './comun/format-datepicker';
import { TipoIntervencionCarreteroComponent } from './tipo-intervencion-carretero/tipo-intervencion-carretero.component';
import { TipoIntervencionCarreteroDetalleComponent } from './tipo-intervencion-carretero-detalle/tipo-intervencion-carretero-detalle.component';
import { AccionistasCarreteroComponent } from './accionistas-carretero/accionistas-carretero.component';
import { PersonaComponent } from './comun/persona/persona.component';
import { PersonaSeguimientoComponent } from './comun/persona-seguimiento/persona-seguimiento.component';
import { PersonaJuridicaSeguimientoComponent } from './comun/persona-juridica-seguimiento/persona-juridica-seguimiento.component';

import { SeguimientoAmigableComponent } from './modulo-juridico/seguimiento-amigable/seguimiento-amigable.component';
import { ContratoCreditoBaseComponent } from './financiero/contrato-credito-base/contrato-credito-base.component';
import { TraficoRecaudoComponent } from './trafico-recaudo/trafico-recaudo.component';
import { EtapaProyectoCarreteroComponent } from './etapa-proyecto-carretero/etapa-proyecto-carretero.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ContratoCreditoPuenteBaseComponent } from './financiero/contrato-credito-puente/contrato-credito-puente-base/contrato-credito-puente-base.component';
import { ContratoCreditoPuenteComponent } from './financiero/contrato-credito-puente/contrato-credito-puente/contrato-credito-puente.component';
import { ContratoCreditoPuenteIndexadoComponent } from './financiero/contrato-credito-puente/contrato-credito-puente-indexado/contrato-credito-puente-indexado.component';
import { ContratoCreditoPuenteIndexadoDetalleComponent } from './financiero/contrato-credito-puente/contrato-credito-puente-indexado-detalle/contrato-credito-puente-indexado-detalle.component';
import { ContratacionHomeComponent } from './contratacion/contratacion-home/contratacion-home/contratacion-home.component';
import { ContratosComponent } from './contratacion/contrato/contrato-borrador/contratos/contratos.component';
import { PersonaJuridicaComponent } from './comun/persona-juridica/persona-juridica.component';
import { PersonaBuscarAgregarComponent } from './comun/persona-buscar-agregar/persona-buscar-agregar.component';
import { PersonaBuscarSeguimientoComponent } from './comun/persona-buscar-seguimiento/persona-buscar-seguimiento.component';
import { ContratoDetalleComponent } from './contratacion/contrato/contrato-borrador/contrato-borrador-registro/contrato-detalle/contrato-detalle.component';
import { ContratoBaseComponent } from './contratacion/contrato/contrato-borrador/contrato-borrador-registro/contrato-base/contrato-base.component';
import { ContratoPolizaComponent } from './contratacion/contrato/contrato-borrador/contrato-borrador-registro/contrato-poliza/contrato-poliza.component';
import { ContratoContratistaComponent } from './contratacion/contrato/contrato-borrador/contrato-borrador-registro/contrato-contratista/contrato-contratista.component';
import { ContratoFuenteFinanciacionComponent } from './contratacion/contrato/contrato-borrador/contrato-borrador-registro/contrato-fuente-financiacion/contrato-fuente-financiacion.component';

import { VisorAlmacenamientoComponent } from './comun/visor-almacenamiento/visor-almacenamiento.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AdjuntarAlmacenamimentoComponent } from './comun/adjuntar-almacenamimento/adjuntar-almacenamimento.component';
import { AdjuntarAlmacenamientoPublicComponent } from './comun/adjuntar-almacenamiento-public/adjuntar-almacenamiento-public.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ValidacionInformacionProyectoComponent } from './informacion-general-carretero/validacion-informacion-proyecto/validacion-informacion-proyecto.component';

import { EstadosGestionDialog } from './componentes//temas-gestion/registro/temas-gestion-registro.component';
import { PriorizacionGestionDialog } from './componentes//temas-gestion/registro/temas-gestion-registro.component';
import { AcuacionGestionDialog } from './componentes//temas-gestion/registro/temas-gestion-registro.component';

import { PersonasComponent } from './admin-personas/personas/personas.component';
import { ContratoSupervisorBrrComponent } from './contratacion/contrato/contrato-borrador/contrato-borrador-registro/contrato-supervisor-brr/contrato-supervisor-brr.component';
import { PersonaBaseComponent } from './admin-personas/persona/persona-base/persona-base.component';
import { PersonaDatosAdicionalesComponent } from './admin-personas/persona/persona-datos-adicionales/persona-datos-adicionales.component';
import { PersonaDatosBasicosComponent } from './admin-personas/persona/persona-datos-basicos/persona-datos-basicos.component';
import { ObligacionesContratoComponent } from './contratacion/obligaciones-contrato/obligaciones-contrato.component';
import { ContratosPublicadosComponent } from './contratacion/contrato/contrato-publicado/contratos-publicados/contratos-publicados.component';
import { VistoBuenoContratoComponent } from './contratacion/visto-bueno-contrato/visto-bueno-contrato.component';
import { ContratosOpcionesAdicionalesComponent } from './contratacion/contratos-opciones-adicionales/contratos-opciones-adicionales.component';
import { ContratoObligacioBrrComponent } from './contratacion/contrato/contrato-borrador/contrato-borrador-registro/contrato-obligacio-brr/contrato-obligacio-brr.component';
import { CovidReportarComponent } from './covid-reportar/covid-reportar.component';
import { HideIfUnauthorizedDirectiveDirective } from './hide-if-unauthorized.directive';
import { PaginaInicialComponent } from './publico/pagina-inicial/pagina-inicial.component';
import { CovidReportarReinicioObraComponent } from './covid-reportar/covid-reportar-reinicio-obra/covid-reportar-reinicio-obra.component';
import { CovidReportarSeguimientoComponent } from './covid-reportar/covid-reportar-seguimiento/covid-reportar-seguimiento.component';
import { CovidReportarProyectoComponent } from './covid-reportar/covid-reportar-seguimiento/covid-reportar-proyecto/covid-reportar-proyecto.component';
import { CovidReportarTrabajadoresComponent } from './covid-reportar/covid-reportar-seguimiento/covid-reportar-trabajadores/covid-reportar-trabajadores.component';
import { CovidReportarMedidasComponent } from './covid-reportar/covid-reportar-seguimiento/covid-reportar-medidas/covid-reportar-medidas.component';
import { InformeContratistaEncabezadoComponent } from './contratacion/informe-contratista/informe-contratista-encabezado/informe-contratista-encabezado.component';
import { ContratosContratistaComponent } from './contratacion/informe-contratista/informe-contratista/contratos-contratista/contratos-contratista.component';
import { ContratoPlanPagoContratistaComponent } from './contratacion/informe-contratista/informe-contratista/contrato-plan-pago-contratista/contrato-plan-pago-contratista.component';
import { InformeContratistaBaseComponent } from './contratacion/informe-contratista/informe-contratista/informe-contratista-registro/informe-contratista-base/informe-contratista-base.component';
import { InformeContratistaObligacionesComponent } from './contratacion/informe-contratista/informe-contratista/informe-contratista-registro/informe-contratista-obligaciones/informe-contratista-obligaciones.component';
import { InformeContratistaBeneficiosTributariosComponent } from './contratacion/informe-contratista/informe-contratista/informe-contratista-registro/informe-contratista-beneficios-tributarios/informe-contratista-beneficios-tributarios.component';
import { InformeContratistaAnexosComponent } from './contratacion/informe-contratista/informe-contratista/informe-contratista-registro/informe-contratista-anexos/informe-contratista-anexos.component';
import { InformeContratistaObservacionesComponent } from './contratacion/informe-contratista/informe-contratista/informe-contratista-registro/informe-contratista-observaciones/informe-contratista-observaciones.component';
import { InformesVoboComponent } from './contratacion/informe-contratista/informe-vobo/informes-vobo/informes-vobo.component';
import { InformeVoboBaseComponent } from './contratacion/informe-contratista/informe-vobo/informe-vobo-registro/informe-vobo-base/informe-vobo-base.component';
import { InformeVoboObligacionesComponent } from './contratacion/informe-contratista/informe-vobo/informe-vobo-registro/informe-vobo-obligaciones/informe-vobo-obligaciones.component';
import { InformeVoboBeneficiosTributariosComponent } from './contratacion/informe-contratista/informe-vobo/informe-vobo-registro/informe-vobo-beneficios-tributarios/informe-vobo-beneficios-tributarios.component';
import { InformeVoboAnexosComponent } from './contratacion/informe-contratista/informe-vobo/informe-vobo-registro/informe-vobo-anexos/informe-vobo-anexos.component';
import { InformeVoboObservacionesComponent } from './contratacion/informe-contratista/informe-vobo/informe-vobo-registro/informe-vobo-observaciones/informe-vobo-observaciones.component';
import { InformesSupervisorComponent } from './contratacion/informe-contratista/informe-supervisor/informes-supervisor/informes-supervisor.component';
import { InformeSupervisorBaseComponent } from './contratacion/informe-contratista/informe-supervisor/informe-supervisor-registro/informe-supervisor-base/informe-supervisor-base.component';
import { InformeSupervisorObligacionesComponent } from './contratacion/informe-contratista/informe-supervisor/informe-supervisor-registro/informe-supervisor-obligaciones/informe-supervisor-obligaciones.component';
import { InformeSupervisorBeneficiosTributariosComponent } from './contratacion/informe-contratista/informe-supervisor/informe-supervisor-registro/informe-supervisor-beneficios-tributarios/informe-supervisor-beneficios-tributarios.component';
import { InformeSupervisorAnexosComponent } from './contratacion/informe-contratista/informe-supervisor/informe-supervisor-registro/informe-supervisor-anexos/informe-supervisor-anexos.component';
import { InformeSupervisorObservacionesComponent } from './contratacion/informe-contratista/informe-supervisor/informe-supervisor-registro/informe-supervisor-observaciones/informe-supervisor-observaciones.component';
import { ContratosHomeComponent } from './contratacion/contratacion-home/contratos-home/contratos-home.component';
import { ContratacionSupervisionHomeComponent } from './contratacion/contratacion-home/contratacion-supervision-home/contratacion-supervision-home.component';
import { VisualizarArchivoComponent } from './comun/visualizar-archivo/visualizar-archivo.component';
import { ContratoInformacionVigenteEncabezadoComponent } from './contratacion/contrato-informacion-vigente-encabezado/contrato-informacion-vigente-encabezado.component';
import { PersonaEducacionComponent } from './admin-personas/persona/persona-educacion/persona-educacion.component';
import { CertificadoPdfComponent } from './componentes/certificado-pdf/certificado-pdf.component';
import { SeleccionarSiNoComponent } from './comun/seleccionar-si-no/seleccionar-si-no.component';
import { AnonimoCarreterosComponent } from './publico/anonimo-carreteros/anonimo-carreteros.component';
import { SeleccionarDepartamentoMunicipioComponent } from './comun/seleccionar-departamento-municipio/seleccionar-departamento-municipio.component';
import { PublicCarreterasComponent } from './publico/public-carreteras/public-carreteras.component';
import { PublicCarreterasConsultaComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-consulta.component';
import { PublicCarreterasMapaComponent } from './reportes/publicos/public-carreteras-mapa/public-carreteras-mapa.component';
import { PublicCarreterasConsultaResumenComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-resumen/public-carreteras-consulta-resumen.component';
import { PublicCarreterasConsultaFicha4gComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-ficha4g/public-carreteras-consulta-ficha4g.component';
import { PublicCarreterasConsultaFicha3g2gComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-ficha3g2g/public-carreteras-consulta-ficha3g2g.component';
import { PublicCarreterasConsultaPeajesComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-peajes/public-carreteras-consulta-peajes.component';
import { PublicAeropuertosComponent } from './publico/public-aeropuertos/public-aeropuertos.component';
import { PublicAeropuertosMapaComponent } from './reportes/publicos/public-aeropuertos-mapa/public-aeropuertos-mapa.component';
import { PublicPuertosComponent } from './publico/public-puertos/public-puertos.component';
import { PublicPuertosMapaComponent } from './reportes/publicos/public-puertos-mapa/public-puertos-mapa.component';
import { PublicPuertosFichaProyectoComponent } from './reportes/publicos/public-puertos-ficha-proyecto/public-puertos-ficha-proyecto.component';
import { PublicPuertosFichaProyectoHomeComponent } from './reportes/publicos/public-puertos-ficha-proyecto-home/public-puertos-ficha-proyecto-home.component';
import { PublicPuertosFichaProyectoDrupalComponent } from './reportes/publicos/public-puertos-ficha-proyecto-drupal/public-puertos-ficha-proyecto-drupal.component';

import { PuertoSeguimientoGestionComponent } from './reportes/internos/puerto-seguimiento-gestion/puerto-seguimiento-gestion.component';
import { PuertoHomeFichaProyectoComponent } from './reportes/internos/puerto-home-ficha-proyecto/puerto-home-ficha-proyecto.component';
import { PuertoHomeRegistrarComponent } from './reportes/internos/puerto-home-registrar/puerto-home-registrar.component';

import { PqrsHomeServiciosComponent } from './pqrs/pqrs-home-servicios/pqrs-home-servicios.component';
import { PqrsReporteEncuestaSatisfaccionComponent } from './pqrs/pqrs-reporte-encuesta-satisfaccion/pqrs-reporte-encuesta-satisfaccion.component';

import { PqrsEncuestaSatisfaccionComponent } from './pqrs/pqrs-encuesta-satisfaccion/pqrs-encuesta-satisfaccion.component';
import { PqrsConsultaComponent } from './pqrs/pqrs-consulta/pqrs-consulta.component';
import { PqrsRadicacionComponent } from './pqrs/pqrs-radicacion/pqrs-radicacion.component';

import { OrfeoConsultaComponent } from './orfeo/orfeo-consulta/orfeo-consulta.component';



import { PublicFerrocarrilesComponent } from './publico/public-ferrocarriles/public-ferrocarriles.component';
import { PublicFerrocarrilesConsultaComponent } from './reportes/publicos/public-ferrocarriles-consulta/public-ferrocarriles-consulta.component';
import { PublicFerrocarrilesConsultaEstadisticaComponent } from './reportes/publicos/public-ferrocarriles-consulta-estadistica/public-ferrocarriles-consulta-estadistica.component';
import { PublicFerrocarrilesMapaComponent } from './reportes/publicos/public-ferrocarriles-mapa/public-ferrocarriles-mapa.component';
import { InformeVoboAprobacionComponent } from './contratacion/informe-contratista/informe-vobo/informe-vobo-registro/informe-vobo-aprobacion/informe-vobo-aprobacion.component';
import { InformeSupervisorAprobacionComponent } from './contratacion/informe-contratista/informe-supervisor/informe-supervisor-registro/informe-supervisor-aprobacion/informe-supervisor-aprobacion.component';
import { AgendamientoCitaComponent } from './componentes/agendamiento-cita/agendamiento-cita.component';
import { AgendamientoConsultasComponent } from './componentes/agendamiento-consultas/agendamiento-consultas.component';
import { CertificacionContratistaComponent } from './certificaciones-laborales-contratista/certificacion-contratista/certificacion-contratista.component';



import { PublicMapComponent } from './reportes/publicos/public-map/public-map.component';
import { SigComponent } from './reportes/internos/sig/sig.component';
import { VisorPlanesAgenciaComponent } from './reportes/internos/visor-planes-agencia/visor-planes-agencia.component';
import { ReporteDepartamentoComponent } from './covid-reportar/covid-reportar-reportes/reporte-departamento/reporte-departamento.component';
import { ReporteSintomasComponent } from './covid-reportar/covid-reportar-reportes/reporte-sintomas/reporte-sintomas.component';
import { CovidReportesComponent } from './covid-reportar/covid-reportar-reportes/covid-reportes/covid-reportes.component';
import { ContratosNovedadesComponent } from './contratacion/novedades-contractuales/contratos-novedades/contratos-novedades.component';
import { NovedadesContratoComponent } from './contratacion/novedades-contractuales/novedades/novedades-contrato/novedades-contrato.component';
import { NovedadContratoBaseComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-base/novedad-contrato-base.component';
import { NovedadContratoAdicionComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-adicion/novedad-contrato-adicion.component';
import { CovidReportesInternoComponent } from './covid-reportar/covid-reportar-reportes/covid-reportar-reportes-interno/covid-reportes-interno/covid-reportes-interno.component';
import { ReporteDepartamentoInternoComponent } from './covid-reportar/covid-reportar-reportes/covid-reportar-reportes-interno/reporte-departamento-interno/reporte-departamento-interno.component';
import { ReporteSintomasInternoComponent } from './covid-reportar/covid-reportar-reportes/covid-reportar-reportes-interno/reporte-sintomas-interno/reporte-sintomas-interno.component';
import { NovedadContratoAdicionProrrogaComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-adicion-prorroga/novedad-contrato-adicion-prorroga.component';
import { NovedadContratoFuenteFinanciacionComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-fuente-financiacion/novedad-contrato-fuente-financiacion.component';
import { NovedadContratoProrrogaComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-prorroga/novedad-contrato-prorroga.component';
import { NovedadContratoSuspensionComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-suspension/novedad-contrato-suspension.component';
import { NovedadContratoTerminacionAnticipadaComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-terminacion-anticipada/novedad-contrato-terminacion-anticipada.component';
import { NovedadContratoCesionComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-cesion/novedad-contrato-cesion.component';
import { NovedadContratoOtroSiComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-otro-si/novedad-contrato-otro-si.component';
import { InformesCxpComponent } from './contratacion/informe-contratista/informe-cxp/informes-cxp/informes-cxp.component';
import { InformeCxpBaseComponent } from './contratacion/informe-contratista/informe-cxp/informe-cxp-registro/informe-cxp-base/informe-cxp-base.component';
import { InformeCxpAnexosComponent } from './contratacion/informe-contratista/informe-cxp/informe-cxp-registro/informe-cxp-anexos/informe-cxp-anexos.component';
import { InformeCxpAprobacionComponent } from './contratacion/informe-contratista/informe-cxp/informe-cxp-registro/informe-cxp-aprobacion/informe-cxp-aprobacion.component';
import { InformeCxpBeneficiosTributariosComponent } from './contratacion/informe-contratista/informe-cxp/informe-cxp-registro/informe-cxp-beneficios-tributarios/informe-cxp-beneficios-tributarios.component';
import { InformeCxpObligacionesComponent } from './contratacion/informe-contratista/informe-cxp/informe-cxp-registro/informe-cxp-obligaciones/informe-cxp-obligaciones.component';
import { InformeCxpObservacionesComponent } from './contratacion/informe-contratista/informe-cxp/informe-cxp-registro/informe-cxp-observaciones/informe-cxp-observaciones.component';
import { InformesSpaComponent } from './contratacion/informe-contratista/informe-spa/informes-spa/informes-spa.component';
import { InformeSpaBaseComponent } from './contratacion/informe-contratista/informe-spa/informe-spa-registro/informe-spa-base/informe-spa-base.component';
import { InformeSpaAnexosComponent } from './contratacion/informe-contratista/informe-spa/informe-spa-registro/informe-spa-anexos/informe-spa-anexos.component';
import { InformeSpaAprobacionComponent } from './contratacion/informe-contratista/informe-spa/informe-spa-registro/informe-spa-aprobacion/informe-spa-aprobacion.component';
import { InformeSpaBeneficiosTributariosComponent } from './contratacion/informe-contratista/informe-spa/informe-spa-registro/informe-spa-beneficios-tributarios/informe-spa-beneficios-tributarios.component';
import { InformeSpaObligacionesComponent } from './contratacion/informe-contratista/informe-spa/informe-spa-registro/informe-spa-obligaciones/informe-spa-obligaciones.component';
import { InformeSpaObservacionesComponent } from './contratacion/informe-contratista/informe-spa/informe-spa-registro/informe-spa-observaciones/informe-spa-observaciones.component';
import { PublicFluvialComponent } from './publico/public-fluvial/public-fluvial.component';
import { PublicFluvialConsultaComponent } from './reportes/publicos/public-fluvial-consulta/public-fluvial-consulta.component';
import { PublicFluvialConsultaFicha5gComponent } from './reportes/publicos/public-fluvial-consulta/public-fluvial-consulta-ficha5g/public-fluvial-consulta-ficha5g.component';
import { PublicCarreterasConsultaFicha5gComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-ficha5g/public-carreteras-consulta-ficha5g.component';
//REFACTORING
import { PublicCarreterasVisorResumenEjecutivoComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-visor-resumen-ejecutivo/public-carreteras-visor-resumen-ejecutivo.component';
import { CarreterasVisorFichaProyecto4gComponent } from './reportes/internos/carreteras-visor-ficha-proyecto4g/carreteras-visor-ficha-proyecto4g.component';
import { PublicCarreterasVisorFichaProyectoComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-visor-ficha-proyecto/public-carreteras-visor-ficha-proyecto.component';
import { PublicCarreterasVisorInversionPorDepartamentoComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-visor-inversion-por-departamento/public-carreteras-visor-inversion-por-departamento.component';
import { PublicCarreterasVisorAvancePredialComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-visor-avance-predial/public-carreteras-visor-avance-predial.component';
import { PublicCarreterasVisorTramitesAmbientalesComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-visor-tramites-ambientales/public-carreteras-visor-tramites-ambientales.component';
import { PublicCarreterasVisorPeajesComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-visor-peajes/public-carreteras-visor-peajes.component';
import { PublicCarreterasVisorEerComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-visor-eer/public-carreteras-visor-eer.component';
import { PublicCarreterasVisorAreasDeInfluenciaComponent } from './reportes/publicos/public-carreteras-consulta/public-carreteras-visor-areas-de-influencia/public-carreteras-visor-areas-de-influencia.component';
import { FuncionarioInviasPrincipalListComponent } from './invias/funcionario/principal/funcionario-invias-principal-list.component';
import { ConcesionarioInviasPrincipalListComponent } from './invias/concesionario/principal/concesionario-invias-principal-list.component';
import { FuncionarioInviasDetalleComponent } from './invias/funcionario/detalle/funcionario-invias-detalle.component';
import { ConcesionarioInviasDetalleComponent } from './invias/concesionario/detalle/concesionario-invias-detalle.component';
import { ConcesionarioRadicacionRespuestaComponent } from './invias/concesionario/respuesta/concesionario-radicacion-respuesta.component';
import { FuncionarioRadicacionRespuestaComponent } from './invias/funcionario/respuesta/funcionario-radicacion-respuesta.component';
import { ListadoRadicacionWebComponent } from './radicacion-web/listado-radicacion-web/listado-radicacion-web.component';
import { NewEditRadicacionWebComponent } from './radicacion-web/new-edit-radicacion-web/new-edit-radicacion-web.component';
import { ConsultaArchivoRadicacionWebComponent } from './radicacion-web/consulta-archivo-radicacion-web/consulta-archivo-radicacion-web.component';
import { EditFirmantesRadicacionWebComponent } from './radicacion-web/edit-firmantes-radicacion-web/edit-firmantes-radicacion-web.component';

import { PublicAeropuertosConsultaComponent } from './reportes/publicos/public-aeropuertos-consulta/public-aeropuertos-consulta.component';
import { PublicAeropuertosConsultaFicha5gComponent } from './reportes/publicos/public-aeropuertos-consulta/public-aeropuertos-consulta-ficha5g/public-aeropuertos-consulta-ficha5g.component';
import { PublicFerrocarrilesConsultaFicha5gComponent } from './reportes/publicos/public-ferrocarriles-consulta/public-ferrocarriles-consulta-ficha5g/public-ferrocarriles-consulta-ficha5g.component';
import { PublicProyectos5gComponent } from './publico/public-proyectos5g/public-proyectos5g.component';
import { ReporteCovidBiInternoComponent } from './covid-reportar/covid-reportar-reportes/covid-reportar-reportes-interno/reporte-covid-bi-interno/reporte-covid-bi-interno.component';
import { PublicProyectos5gFichaOla2Component } from './publico/public-proyectos5g/public-proyectos5g-ficha-ola2/public-proyectos5g-ficha-ola2.component';
import { ContratacionReportesHomeComponent } from './contratacion/contratacion-home/contratacion-reportes-home/contratacion-reportes-home.component';
import { ReporteInformesContratistaComponent } from './contratacion/reportes/reporte-informes-contratista/reporte-informes-contratista.component';
import { PublicProyectos5gFichaOla1Component } from './publico/public-proyectos5g/public-proyectos5g-ficha-ola1/public-proyectos5g-ficha-ola1.component';
import { ReporteContratosComponent } from './contratacion/reportes/reporte-contrato/reporte-contratos/reporte-contratos.component';
import { ReporteContratoBaseComponent } from './contratacion/reportes/reporte-contrato/reporte-contrato-detalle/reporte-contrato-base/reporte-contrato-base.component';
import { ReporteContratoPlanPagoComponent } from './contratacion/reportes/reporte-contrato/reporte-contrato-detalle/reporte-contrato-plan-pago/reporte-contrato-plan-pago.component';
import { ContratoBalanceFinancieroComponent } from './contratacion/contrato-balance-financiero/contrato-balance-financiero.component';
import { InformeCxpObligacionPagoComponent } from './contratacion/informe-contratista/informe-cxp/informe-cxp-registro/informe-cxp-obligacion-pago/informe-cxp-obligacion-pago.component';
import { InformeSpaObligacionPagoComponent } from './contratacion/informe-contratista/informe-spa/informe-spa-registro/informe-spa-obligacion-pago/informe-spa-obligacion-pago.component';
import { AlcanceProyectoBaseComponent } from './informacion-general-carretero/alcance-proyecto/alcance-proyecto-base/alcance-proyecto-base.component';
import { EtapaProyectoCarreteroComponent2 } from './informacion-general-carretero/alcance-proyecto/etapa-proyecto-carretero/etapa-proyecto-carretero.component';
import { LongitudConcesionadaProyectoComponent } from './informacion-general-carretero/alcance-proyecto/longitud-concesionada-proyecto/longitud-concesionada-proyecto.component';
import { TipoIntervencionCarreteroDetalleComponent2 } from './informacion-general-carretero/alcance-proyecto/tipo-intervencion-carretero-detalle/tipo-intervencion-carretero-detalle.component';
import { TipoIntervencionCarreteroComponent2 } from './informacion-general-carretero/alcance-proyecto/tipo-intervencion-carretero/tipo-intervencion-carretero.component';
import { UnidadFuncionalCarreteroDetalleComponent2 } from './informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero-detalle/unidad-funcional-carretero-detalle.component';
import { UnidadFuncionalCarreteroComponent2 } from './informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero/unidad-funcional-carretero.component';
import { ValidacionInformacionProyectoComponent2 } from './informacion-general-carretero/alcance-proyecto/validacion-informacion-proyecto/validacion-informacion-proyecto.component';
import { AccionistasCarretero2Component } from './informacion-general-carretero/informacion-contractual/contrato-contratista-carretero/accionistas-carretero2/accionistas-carretero2.component';
import { ContratistaCarreteroComponent } from './informacion-general-carretero/informacion-contractual/contrato-contratista-carretero/contratista-carretero/contratista-carretero.component';
import { ContratoCarreteroComponent } from './informacion-general-carretero/informacion-contractual/contrato-contratista-carretero/contrato-carretero/contrato-carretero.component';
import { ContratoContratistaCarreteroBaseComponent } from './informacion-general-carretero/informacion-contractual/contrato-contratista-carretero/contrato-contratista-carretero-base/contrato-contratista-carretero-base.component';
import { ContratosCarreteroComponent } from './informacion-general-carretero/informacion-contractual/contratos-carretero/contratos-carretero.component';
import { ModificacionContractualCarreteroComponent } from './informacion-general-carretero/informacion-contractual/modificacion-contractual-carretero/modificacion-contractual-carretero.component';
import { InformacionGeneralProyectoComponent } from './informacion-general-carretero/informacion-general-proyecto/informacion-general-proyecto.component';

import { SeguimientoComunicacionesComponent } from './componentes/seguimiento/seguimiento-comunicaciones/lista/seguimiento-comunicaciones.component';
import { SeguimientoComunicacionesDetalleComponent } from './componentes/seguimiento/seguimiento-comunicaciones/detalle/seguimiento-comunicaciones-detalle.component';
import { SeguimientoComunicacionesRegistroComponent } from './componentes/seguimiento/seguimiento-comunicaciones/registro/seguimiento-comunicaciones-registro.component';
import { EstadosComunicacionesDialogComponent } from './componentes/seguimiento/seguimiento-comunicaciones/registro/seguimiento-comunicaciones-registro.component';
import { RespuestaEmitidaDialogComponent } from './componentes/seguimiento/seguimiento-comunicaciones/registro/seguimiento-comunicaciones-registro.component';
import { SeguimientoHomeComponent } from './componentes/seguimiento/seguimiento-home/seguimiento-home.component';
import { SeguimientoReporteComponent } from './reportes/internos/seguimiento-reporte/seguimiento-reporte.component';
import { SeguimientoConsultaComponent } from './componentes/seguimiento/seguimiento-consulta/seguimiento-consulta.component';
import { SeguimientoVerComunicacionComponent } from './componentes/seguimiento/seguimiento-ver-comunicacion/seguimiento-ver-comunicacion.component';

import { CertificacionHomeComponent } from './certificaciones-laborales/certificacion-home/certificacion-home.component';
import { CertificacionesLaboralesValidacionComponent } from './certificaciones-laborales/certificaciones-laborales-validacion/certificaciones-laborales-validacion.component';
import { CertificacionContratistaValidacionComponent } from './certificaciones-laborales-contratista/certificacion-contratista-validacion/certificacion-contratista-validacion.component';
import { CertificacionRetiradosComponent } from './certificaciones-laborales/certificacion-retirados/certificacion-retirados.component';
import { CertificacionActivosComponent } from './certificaciones-laborales/certificacion-activos/certificacion-activos.component';
import { CertificacionGeneralComponent } from './certificaciones-laborales/certificacion-general/certificacion-general.component';
import { CertificacionConfiguracionComponent } from './certificaciones-laborales/certificacion-configuracion/certificacion-configuracion.component';

import { TramitesVisorSeguimientoComponent } from './reportes/internos/tramites-visor-seguimiento/tramites-visor-seguimiento.component';
import { YoutubePlaylistComponent } from './video/youtube-playlist.component/youtube-playlist.component';
import { YoutubeSearchListComponent } from './video/youtube-search-list.component/youtube-search-list.component';
import { YoutubeUploadFileComponent } from './video/youtube-upload-file.component/youtube-upload-file.component';
import { YoutubeHomePublicComponent } from './video/youtube-home-public.component/youtube-home-public.component';
import { YoutubeHomeComponent } from './video/youtube-home.component/youtube-home.component';
import { YoutubeProyectoComponent } from './video/youtube-proyecto.component/youtube-proyecto.component';
import { YoutubeProyectoVideoComponent } from './video/youtube-proyecto-video.component/youtube-proyecto-video.component';

import { UploadFileComponent } from './radicacion-web/upload-file/upload-file.component';

import { AvanceProyectoComponent } from './avance-proyecto/avance-proyecto.component';
import { AvanceProyectoMenusComponent } from './avance-proyecto/avance-proyecto-menus/avance-proyecto-menus.component';

import { AvanceProyectoReportarAvanceComponent } from './avance-proyecto/avance-proyecto-reportar-avance/avance-proyecto-reportar-avance.component';
import { AvanceProyectoEjecucionesComponent } from './avance-proyecto/avance-proyecto-reportar-avance/avance-proyecto-ejecuciones/avance-proyecto-ejecuciones.component';
import { AvanceProyectoUnidadesFuncionalesComponent } from './avance-proyecto/avance-proyecto-reportar-avance/avance-proyecto-unidades-funcionales/avance-proyecto-unidades-funcionales.component';
import { AvanceProyectoIntervencionesComponent } from './avance-proyecto/avance-proyecto-reportar-avance/avance-proyecto-intervenciones/avance-proyecto-intervenciones.component';
import { AvanceProyectoCrearEjecucionComponent } from './avance-proyecto/avance-proyecto-reportar-avance/avance-proyecto-crear-ejecucion/avance-proyecto-crear-ejecucion.component';

import { AvanceProyectoReportarEtapasComponent } from './avance-proyecto/avance-proyecto-reportar-etapas/avance-proyecto-reportar-etapas.component';
import { AvanceProyectoConstruccionComponent } from './avance-proyecto/avance-proyecto-reportar-etapas/avance-proyecto-construccion/avance-proyecto-construccion.component';
import { InventarioEquiposComponent } from './inventario-equipos/inventario-equipos.component';
import { RegistrarModeloComponent } from './inventario-equipos/registrar-modelo/registrar-modelo.component';
import { RegistrarBienComponent } from './inventario-equipos/registrar-bien/registrar-bien.component';
import { MisBienesComponent } from './inventario-equipos/mis-bienes/mis-bienes.component';
import { ContratoPlanPagoComponent } from './contratacion/contrato-plan-pago/contrato-plan-pago.component';
import { ContratoSupervisorComponent } from './contratacion/contrato-supervisor/contrato-supervisor.component';
import { PublicUatComponent } from './publico/public-uat/public-uat.component';
import { RepositorioUatComponent } from './publico/public-uat/repositorio-uat/repositorio-uat.component';
import { SobreUatComponent } from './publico/public-uat/sobre-uat/sobre-uat.component';
import { InvestigacionUatComponent } from './publico/public-uat/investigacion-uat/investigacion-uat.component';
import { MenuLateralComponent } from './comun/menu-lateral/menu-lateral.component';
import { AliadosUatComponent } from './publico/public-uat/aliados-uat/aliados-uat.component';
import { MesaUatComponent } from './publico/public-uat/mesa-uat/mesa-uat.component';
import { ModeloUatComponent } from './publico/public-uat/modelo-uat/modelo-uat.component';
import { ReporteCuentasCxpComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuentas-cxp/reporte-cuentas-cxp.component';

import { TraficoPeajesComponent } from './peajes-gestion/trafico-peajes/trafico-peajes-carga/trafico-peajes-carga.component';
import { MenuPeajesComponent } from './peajes-gestion/peajes-menu.component';
import { TarifasPeajesComponent } from './peajes-gestion/tarifas-peajes/tarifas-peajes.component';
import { NuevaTarifaPeajesComponent } from './peajes-gestion/tarifas-peajes/nueva-tarifa/nueva-tarifa.component';
import { CompensacionesAmbientalesComponent } from './compensaciones-ambientales/compensaciones-ambientales.component';
import { ObligacionesCompensacionComponent } from './compensaciones-ambientales/obligaciones-compensacion/obligaciones-compensacion.component';
import { SeguimientoCompensacionesComponent } from './compensaciones-ambientales/seguimiento-compensaciones/seguimiento-compensaciones.component';
import { SolicitudPagoAprobadasSupervisorComponent } from './contratacion/informe-contratista/solicitud-pago-aprobadas-supervisor/solicitud-pago-aprobadas-supervisor.component';
import { RpteCxpBenTribComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-ben-trib/rpte-cxp-ben-trib.component';
import { RpteCxpOblComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-obl/rpte-cxp-obl.component';
import { RpteCxpBaseComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-base/rpte-cxp-base.component';
import { RpteCxpAnxComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-anx/rpte-cxp-anx.component';
import { RpteCxpOblPagoComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-obl-pago/rpte-cxp-obl-pago.component';
import { RpteCxpObsComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-obs/rpte-cxp-obs.component';
import { RpteCxpAprobComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-aprob/rpte-cxp-aprob.component';
import { RpteCxpPagoComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-pago/rpte-cxp-pago.component';
import { PersonaJuridicaExtranjeraComponent } from './comun/persona-juridica-extranjera/persona-juridica-extranjera.component';
import { DocumentosPeajesComponent } from './peajes-gestion/documentos-peajes/documentos-peajes.component';
import { MenuPeajesExternosComponent } from './peajes-gestion/menu-peajes-externos/menu-peajes-externos.component';
import { ReportesPeajesExternosComponent } from './reportes/internos/interno-carreteras-consulta/interno-peajes-externos/interno-peajes-externos.component';
import { ReporteInformesContratistaEjecutivoComponent } from './contratacion/reportes/reporte-informes-contratista-ejecutivo/reporte-informes-contratista-ejecutivo.component';
import { RecaudoPeajesComponent } from './peajes-gestion/recaudo-peajes/recaudo-peajes.component';
import { DetalleRecaudoPeajesComponent } from './peajes-gestion/recaudo-peajes/detalle-recaudo-peajes/detalle-recaudo-peajes.component';

import { ObligacionAmbientalComponent } from './compensaciones-ambientales/obligaciones-compensacion/obligacion-ambiental/obligacion-ambiental.component';
import { GestionarLocalizacionComponent } from './compensaciones-ambientales/obligaciones-compensacion/gestionar-localizacion/gestionar-localizacion.component';
import { EjecucionesObligacionComponent } from './compensaciones-ambientales/seguimiento-compensaciones/ejecuciones-obligacion/ejecuciones-obligacion.component';
import { ArchivoShpComponent } from './comun/archivo-shp/archivo-shp.component';
import { GestionarLocalizacionAvanceComponent } from './compensaciones-ambientales/seguimiento-compensaciones/ejecuciones-obligacion/gestionar-localizacion-avance/gestionar-localizacion-avance.component';
import { RpteCxpTotalComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuentas-cxp-consolidado/rpte-cxp-total/rpte-cxp-total.component';
import { RpteCxpTotalDpnComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuentas-cxp-consolidado/rpte-cxp-total-dpn/rpte-cxp-total-dpn.component';
import { RpteCxpCmpvoComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuentas-cxp-comparativo/rpte-cxp-cmpvo/rpte-cxp-cmpvo.component';
import { ContratacionReportesCuentasCobroHomeComponent } from './contratacion/contratacion-home/contratacion-reportes-cuentas-cobro-home/contratacion-reportes-cuentas-cobro-home.component';
import { ConsultarLocalizacionComponent } from './compensaciones-ambientales/obligaciones-compensacion/consultar-localizacion/consultar-localizacion.component';
import { SafePipe } from './pipes/safe.pipe';
import { ConsultarLocalizacionAvanceComponent } from './compensaciones-ambientales/seguimiento-compensaciones/ejecuciones-obligacion/consultar-localizacion-avance/consultar-localizacion-avance.component';
import { ReportesInventarioComponent } from './inventario-equipos/reportes-inventario/reportes-inventario.component';
import { PersonaNaturalComponent } from './comun/persona-natural/persona-natural.component';
import { ReportesPeajesRecaudoComponent } from './reportes/internos/interno-carreteras-consulta/interno-peajes-externos/interno-peajes-recaudo/interno-peajes-recaudo.component';
import { PrediosComponent } from './predios/predios.component';
import { RegistroDetalladoPrediosComponent } from './predios/registro-detallado-predios/registro-detallado-predios.component';
import { CrearEditarPredioComponent } from './predios/registro-detallado-predios/crear-editar-predio/crear-editar-predio.component';
import { AdjuntosPredioComponent } from './predios/registro-detallado-predios/adjuntos-predio/adjuntos-predio.component';
import { InformacionGeneralPeajesComponent } from './peajes-gestion/informacion-general-peajes/informacion-general-peajes.component';
import { ConsultarMapaInformacionGeneralComponent } from './peajes-gestion/informacion-general-peajes/informacion-general-peajes-consultar-mapa/informacion-general-peajes-consultar-mapa';
import { AniAppsComponent } from './comun/ani-apps/ani-apps.component';
import { DatosAbiertosComponent } from './datos-abiertos/datos-abiertos/datos-abiertos.component';
import { DaAvanceProyectoComponent } from './datos-abiertos/da-avance-proyecto/da-avance-proyecto/da-avance-proyecto.component';
import { PuestosTrabajoComponent } from './puestos-trabajo/puestos-trabajo.component';
import { CrearEditarReservaComponent } from './puestos-trabajo/crear-editar-reserva/crear-editar-reserva.component';
import { DaInformacionGeneralComponent } from './datos-abiertos/da-informacion-general/da-informacion-general/da-informacion-general.component';
import { TarifasPeajesReportesComponent } from './peajes-gestion/tarifas-peajes/tarifas-peajes-reportes/tarifas-peajes-reportes.component';
import { PublicAeroFichaProyectoDrupalComponent } from './reportes/publicos/public-aero-ficha-proyecto-drupal/public-aero-ficha-proyecto-drupal.component';
import { PublicFerroFichaProyectoDrupalComponent } from './reportes/publicos/public-ferro-ficha-proyecto-drupal/public-ferro-ficha-proyecto-drupal.component';
import { AeropuertosHomeFichaProyectoDrupalComponent } from './reportes/internos/aeropuertos-home-ficha-proyecto-drupal/aeropuertos-home-ficha-proyecto-drupal.component';
import { FerreoFichaProyectoDrupalComponent } from './reportes/internos/ferreo-ficha-proyecto-drupal/ferreo-ficha-proyecto-drupal.component';
import { RegistrarReservaComponent } from './puestos-trabajo/registrar-reserva/registrar-reserva.component';
import { ListarCuposComponent } from './puestos-trabajo/administracion/listar-cupos/listar-cupos.component';
import { CrearEditarCuposComponent } from './puestos-trabajo/administracion/crear-editar-cupos/crear-editar-cupos.component';
import { PuestosTrabajoHomeComponent } from './puestos-trabajo/puestos-trabajo-home/puestos-trabajo-home.component';
import { ListarFranjasComponent } from './puestos-trabajo/administracion/listar-franjas/listar-franjas.component';
import { CrearEditarFranjasComponent } from './puestos-trabajo/administracion/crear-editar-franjas/crear-editar-franjas.component';
import { AdministracionHomeComponent } from './puestos-trabajo/administracion/administracion-home/administracion-home.component';
import { ConsultarLocalizacionUfComponent } from './informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero/consultar-localizacion-uf/consultar-localizacion-uf.component';
import { GestionarLocalizacionUfComponent } from './informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero/gestionar-localizacion-uf/gestionar-localizacion-uf.component';
import { ConsultarLocalizacionPredioComponent } from './predios/registro-detallado-predios/consultar-localizacion-predio/consultar-localizacion-predio.component';
import { GestionarLocalizacionPredioComponent } from './predios/registro-detallado-predios/gestionar-localizacion-predio/gestionar-localizacion-predio.component';
import { CargaMasivaPrediosComponent } from './predios/carga-masiva-predios/carga-masiva-predios.component';
import { DatosMovimientoComponent } from './datos-movimiento/datos-movimiento.component';
import { InformeContratistaPazSalvoComponent } from './contratacion/informe-contratista/informe-contratista/informe-contratista-registro/informe-contratista-paz-salvo/informe-contratista-paz-salvo.component';
import { InformeVoboPazSalvoComponent } from './contratacion/informe-contratista/informe-vobo/informe-vobo-registro/informe-vobo-paz-salvo/informe-vobo-paz-salvo.component';
import { InformeSupervisorPazSalvoComponent } from './contratacion/informe-contratista/informe-supervisor/informe-supervisor-registro/informe-supervisor-paz-salvo/informe-supervisor-paz-salvo.component';
import { InformeCxpPazSalvoComponent } from './contratacion/informe-contratista/informe-cxp/informe-cxp-registro/informe-cxp-paz-salvo/informe-cxp-paz-salvo.component';
import { InformeSpaPazSalvoComponent } from './contratacion/informe-contratista/informe-spa/informe-spa-registro/informe-spa-paz-salvo/informe-spa-paz-salvo.component';
import { RpteCxpPazSalvoComponent } from './contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-paz-salvo/rpte-cxp-paz-salvo.component';
import { ComisionesHomeComponent } from './comisiones/comisiones-home/comisiones-home.component';
import { CrearEditarSolicitudComisionComponent } from './comisiones/crear-editar-solicitud-comision/crear-editar-solicitud-comision.component';
import { ComisionesLegalizacionesRegistroComponent } from './comisiones/comisiones-legalizaciones/comisiones-legalizaciones-registro/comisiones-legalizaciones-registro.component';
import { ComisionesLegalizacionesRevisionComponent } from './comisiones/comisiones-legalizaciones/comisiones-legalizaciones-revision/comisiones-legalizaciones-revision.component';
import { ComisionesTiquetesRevisionComponent } from './comisiones/comisiones-tiquetes/comisiones-tiquetes-revision/comisiones-tiquetes-revision.component';
import { ComisionesTiquetesRegistroComponent } from './comisiones/comisiones-tiquetes/comisiones-tiquetes-registro/comisiones-tiquetes-registro.component';
import { CrearEditarTiqueteComisionComponent } from './comisiones/comisiones-tiquetes/comisiones-tiquetes-registro/crear-editar-tiquete-comision/crear-editar-tiquete-comision.component';
import { ComisionesConfiguracionComponent } from './comisiones/comisiones-configuracion/comisiones-configuracion.component';
import { ComisionesSolicitudesComponent } from './comisiones/comisiones-solicitudes/comisiones-solicitudes.component';
import { ComisionesConfiguracionDependenciasResponsableComponent } from './comisiones/comisiones-configuracion-dependencias-responsable/comisiones-configuracion-dependencias-responsable.component';
import { ComisionesConfiguracionFuncionarioComponent } from './comisiones/comisiones-configuracion-funcionario/comisiones-configuracion-funcionario.component';
import { ComisionesRevisarSolicitudesComponent } from './comisiones/comisiones-revisar-solicitudes/comisiones-revisar-solicitudes.component';
import { NovedadContratoReduccionPlazoComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-reduccion-plazo/novedad-contrato-reduccion-plazo.component';
import { NovedadContratoLiberacionRecursosComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-liberacion-recursos/novedad-contrato-liberacion-recursos.component';
import { NovedadContratoFuenteFinanciacionLiberacionComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-fuente-financiacion-liberacion/novedad-contrato-fuente-financiacion-liberacion.component';
import { VisorCompensacionesAmbientalesComponent } from './reportes/internos/visor-compensaciones-ambientales/visor-compensaciones-ambientales.component';
import { VisorPolizasComponent } from './reportes/internos/visor-polizas/visor-polizas.component';
import { RptCrrProyectoEncabezadoComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-encabezado/rpt-crr-proyecto-encabezado.component';
import { RptCrrFichaProyectosComponent } from './reportes/carreteros/rpt-crr-ficha-proyectos/rpt-crr-ficha-proyectos.component';
import { RptCrrProyectosComponent } from './reportes/carreteros/rpt-crr-proyectos/rpt-crr-proyectos.component';
import { RptCrrProyectoDetalleComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-detalle.component';
import { RptCrrProyectoVideosComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-videos/rpt-crr-proyecto-videos.component';
import { RptCrrProyectoAvancesComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-avances/rpt-crr-proyecto-avances.component';
import { RptCrrProyectoInversionComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-inversion/rpt-crr-proyecto-inversion.component';
import { RptCrrProyectoFinancieroComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-financiero/rpt-crr-proyecto-financiero.component';
import { RptCrrProyectoPeajeComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-peaje/rpt-crr-proyecto-peaje.component';
import { RptCrrProyectoAccionistasComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-accionistas/rpt-crr-proyecto-accionistas.component';
import { RptCrrProyectoTemasgestionComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-temasgestion/rpt-crr-proyecto-temasgestion.component';
import { RptCrrProyectoPredialComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-predial/rpt-crr-proyecto-predial.component';
import { RptCrrProyectoMetasComponent } from './reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-metas/rpt-crr-proyecto-metas.component';
import { PublicProyectos5gFichaSostenibilidadComponent } from './publico/public-proyectos5g/public-proyectos5g-ficha-sostenibilidad/public-proyectos5g-ficha-sostenibilidad.component';
import { DaOcdsComponent } from './datos-abiertos/da-ocds/da-ocds.component';
import { ComisionesGestionPresupuestalRegistroComponent } from './comisiones/comisiones-gestion-presupuestal/comisiones-gestion-presupuestal-registro/comisiones-gestion-presupuestal-registro.component';
import { ComisionesGestionPresupuestalComisionesComponent } from './comisiones/comisiones-gestion-presupuestal/comisiones-gestion-presupuestal-comisiones/comisiones-gestion-presupuestal-comisiones.component';
import { ComisionInformacionGeneralComponent } from './comisiones/comision-informacion-general/comision-informacion-general.component';
import { RptCrrPeajesComponent } from './reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes.component';
import { RptCrrPeajesProyectoComponent } from './reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-proyecto/rpt-crr-peajes-proyecto.component';
import { RptCrrPeajesDetalleComponent } from './reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-detalle/rpt-crr-peajes-detalle.component';
import { RptCrrPeajesTarifasComponent } from './reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-tarifas/rpt-crr-peajes-tarifas.component';
import { SeleccionarMunicipiosComponent } from './comun/seleccionar-municipios/seleccionar-municipios.component';
import { RptCrrPeajesTraficoRecaudoComponent } from './reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-trafico-recaudo/rpt-crr-peajes-trafico-recaudo.component';
import { RptCrrPeajesTraficoRecaudoDetalleComponent } from './reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-trafico-recaudo-detalle/rpt-crr-peajes-trafico-recaudo-detalle.component';
import { PersonaDependenciaComponent } from './admin-personas/persona/persona-dependencia/persona-dependencia.component';
import { ComisionesRegistroPresupuestalObligacionComisionesComponent } from './comisiones/comisiones-registro-presupuestal-obligacion/comisiones-registro-presupuestal-obligacion-comisiones/comisiones-registro-presupuestal-obligacion-comisiones.component';
import { ComisionesRegistroPresupuestalObligacionRegistroComponent } from './comisiones/comisiones-registro-presupuestal-obligacion/comisiones-registro-presupuestal-obligacion-registro/comisiones-registro-presupuestal-obligacion-registro.component';
import { ComisionesOrdenPagoPresupuestalRegistroComponent } from './comisiones/comisiones-orden-pago-presupuestal/comisiones-orden-pago-presupuestal-registro/comisiones-orden-pago-presupuestal-registro.component';
import { ComisionesOrdenPagoPresupuestalComisionesComponent } from './comisiones/comisiones-orden-pago-presupuestal/comisiones-orden-pago-presupuestal-comisiones/comisiones-orden-pago-presupuestal-comisiones.component';
import { ComisionesReportesHomeComponent } from './comisiones/comisiones-reportes/comisiones-reportes-home/comisiones-reportes-home.component';
import { ComisionesReporteEjecutivoComponent } from './comisiones/comisiones-reportes/comisiones-reporte-ejecutivo/comisiones-reporte-ejecutivo.component';
import { RptCrrFicha5gComponent } from './reportes/carreteros/rpt-crr-ficha5g/rpt-crr-ficha5g.component';
import { VisualizarComisionesCancelacionComponent } from './comisiones/comisiones-revisar-solicitudes/visualizar-comisiones-cancelacion/visualizar-comisiones-cancelacion.component';
import { DireccionComponent } from './comun/direccion/direccion.component';
import { ContratoDependenciaComponent } from './contratacion/contrato-dependencia/contrato-dependencia.component';
import { ComisionesReporteDetalleVersionesComponent } from './comisiones/comisiones-reportes/comisiones-reporte-detalle/comisiones-reporte-detalle-versiones/comisiones-reporte-detalle-versiones.component';
import { ComisionesReporteDetalleRegistroVersionesComponent } from './comisiones/comisiones-reportes/comisiones-reporte-detalle/comisiones-reporte-detalle-registro-versiones/comisiones-reporte-detalle-registro-versiones.component';
import { EquiposItsComponent } from './equipos-its/equipos-its.component';
import { RegistroEquipoItsComponent } from './equipos-its/registro-equipo-its/registro-equipo-its.component';
import { VisorMapaSocialRiesgosPeajesComponent } from './reportes/internos/visor-mapa-social-riesgos-peajes/visor-mapa-social-riesgos-peajes.component';
import { VisorMapaConflictividadProyectosComponent } from './reportes/internos/visor-mapa-conflictividad-proyectos/visor-mapa-conflictividad-proyectos.component';
import { VisorDispositivosItsComponent } from './reportes/internos/visor-dispositivos-its/visor-dispositivos-its.component';
import { ApiServiceComponent } from './comun/api-service/api-service.component';
import { TraficoAniComponent } from './home/trafico-ani/trafico-ani.component';
import { PublicMapModosComponent } from './reportes/publicos/public-map/public-map-modos/public-map-modos.component';
import { PublicMapPeajesComponent } from './reportes/publicos/public-map/public-map-peajes/public-map-peajes.component';
import { PublicMapAreasInfluenciaComponent } from './reportes/publicos/public-map/public-map-areas-influencia/public-map-areas-influencia.component';
import { PublicMapAreasAtlasComponent } from './reportes/publicos/public-map/public-map-areas-atlas/public-map-areas-atlas.component';
import { SigCriticidadPeajesComponent } from './reportes/internos/sig/sig-criticidad-peajes/sig-criticidad-peajes.component';
import { SigDispositivosITSComponent } from './reportes/internos/sig/sig-dispositivos-its/sig-dispositivos-its.component';
import { SigPredialComponent } from './reportes/internos/sig/sig-predial/sig-predial.component';
import { SigCompensacionesAmbientalesComponent } from './reportes/internos/sig/sig-compensaciones-ambientales/sig-compensaciones-ambientales.component';
import { SigPuertosComponent } from './reportes/internos/sig/sig-puertos/sig-puertos.component';
import { SigTraficoWazeComponent } from './reportes/internos/sig/sig-trafico-waze/sig-trafico-waze.component';
import { SigCanalDelDiqueComponent } from './reportes/internos/sig/sig-canal-del-dique/sig-canal-del-dique.component';
import { PublicMapServiciosAyudaComponent } from './reportes/publicos/public-map/public-map-servicios-ayuda/public-map-servicios-ayuda.component';

import { PublicNovedadesComponent } from './publico/public-novedades/public-novedades.component';
import { PortafolioComponent } from './publico/public-novedades/portafolio/portafolio.component';
import { ProyectosAeroportuarioComponent } from './publico/public-novedades/proyectos-aeroportuario/proyectos-aeroportuario.component';
import { RutaFerrecaribeComponent } from './publico/public-novedades/ruta-ferrecaribe/ruta-ferrecaribe.component';
import { ProyectosEducacionComponent } from './publico/public-novedades/proyectos-educacion/proyectos-educacion.component';
import { ProyectosSaludComponent } from './publico/public-novedades/proyectos-salud/proyectos-salud.component';
import { ProyectosSocialComponent } from './publico/public-novedades/proyectos-social/proyectos-social.component';
import { OlaInvernalRegistroListadoComponent } from './ola-invernal/ola-invernal-registro/ola-invernal-registro-listado/ola-invernal-registro-listado.component';
import { OlaInvernalRegistroComponent } from './ola-invernal/ola-invernal-registro/ola-invernal-registro-registro/ola-invernal-registro.component';
import { ProyectoInformacionVigenteComponent } from './proyecto-informacion-vigente/proyecto-informacion-vigente.component';
import { OlaInvernalReporteDetalleListadoComponent } from './ola-invernal/ola-invernal-reporte-detalle/ola-invernal-reporte-detalle-listado/ola-invernal-reporte-detalle-listado.component';
import { OlaInvernalReporteDetalleComponent } from './ola-invernal/ola-invernal-reporte-detalle/ola-invernal-reporte-detalle/ola-invernal-reporte-detalle.component';
import { NovedadContratoCambioHonorariosComponent } from './contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-cambio-honorarios/novedad-contrato-cambio-honorarios.component';
import { TramitesAmbientalesCarreteroComponent } from './tramites-ambientales-carretero/tramites-ambientales-carretero/tramites-ambientales-carretero.component';
import { TramitesAmbientalesListadoCarreteroComponent } from './tramites-ambientales-carretero/tramites-ambientales-listado-carretero/tramites-ambientales-listado-carretero.component';
import { TramitesAmbientalesSeguimientoCarreteroComponent } from './tramites-ambientales-carretero/tramites-ambientales-seguimiento-carretero/tramites-ambientales-seguimiento-carretero.component';
import { FluvialComponent } from './fluvial/fluvial.component';

import { OlaInvernalFerreoRegistroListadoComponent } from './ola-invernal-ferreo/ola-invernal-ferreo-registro/ola-invernal-ferreo-registro-listado/ola-invernal-ferreo-registro-listado.component';
import { OlaInvernalFerreoRegistroRegistroComponent } from './ola-invernal-ferreo/ola-invernal-ferreo-registro/ola-invernal-ferreo-registro-registro/ola-invernal-ferreo-registro-registro.component';
import { OlaInvernalFerreoReporteDetalleListadoComponent } from './ola-invernal-ferreo/ola-invernal-ferreo-reporte-detalle/ola-invernal-ferreo-reporte-detalle-listado/ola-invernal-ferreo-reporte-detalle-listado.component';
import { OlaInvernalFerreoReporteDetalleComponent } from './ola-invernal-ferreo/ola-invernal-ferreo-reporte-detalle/ola-invernal-ferreo-reporte-detalle/ola-invernal-ferreo-reporte-detalle.component';


import { CtoReporteCxpDevolucionesTesoreriaComponent } from './contratacion/reportes/cto-reporte-cxp-devoluciones-tesoreria/cto-reporte-cxp-devoluciones-tesoreria.component';
import { CtoReporteCxpPlanesPagoCuentasCobroComponent } from './contratacion/reportes/cto-reporte-cxp-planes-pago-cuentas-cobro/cto-reporte-cxp-planes-pago-cuentas-cobro.component';
import { CtoReporteCxpContratosComponent } from './contratacion/reportes/cto-reporte-cxp-contratos/cto-reporte-cxp-contratos.component';
import { SigFaunaniComponent } from './reportes/internos/sig/sig-faunani/sig-faunani.component';
import { ProyectoHomeFluvialComponent } from './proyecto-home-fluvial/proyecto-home-fluvial.component';
import { TramitesAmbientalesFluvialComponent } from './tramites-ambientales-fluvial/tramites-ambientales-fluvial/tramites-ambientales-fluvial.component';
import { TramitesAmbientalesListadoFluvialComponent } from './tramites-ambientales-fluvial/tramites-ambientales-listado-fluvial/tramites-ambientales-listado-fluvial.component';
import { TramitesAmbientalesSeguimientoFluvialComponent } from './tramites-ambientales-fluvial/tramites-ambientales-seguimiento-fluvial/tramites-ambientales-seguimiento-fluvial.component';
import { RptCrrPeajesTpdComponent } from './reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-trafico-recaudo/rpt-crr-peajes-tpd/rpt-crr-peajes-tpd.component';

registerLocaleData(localePy, 'es');
registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
  declarations: [
    AppComponent,
    ReportesComponent,
    PrincipalComponent,
    InformacionGeneralComponent,
    AmbientalComponent,
    AmbientalDetalleComponent,
    PredialComponent,
    PredialDetalleComponent,
    UnidadFuncionalComponent,
    HomeComponent,
    HomeTramitesComponent,
    ProyectoComponent,
    ProyectoHomeComponent,
    UnidadFuncionalDetalleComponent,
    InversionComponent,
    InversionDetalleComponent,
    FinancieroHomeComponent,
    ProgressSpinnerDialogComponent,
    InversionProyectoComponent,
    InversionProyectoDetalleComponent,
    ModuloJuridicoComponent,
    NuevoIntervencionComponent,
    MenuPrincipalComponent,
    AmigablesComponedoresComponent,
    PronunciamientoComponent,
    AudienciaPreliminarComponent,
    AudienciaPruebasComponent,
    AudienciaAlegatosComponent,
    AudienciaDecisionComponent,
    SolicitudAclaracionComponent,
    DecisionAclaracionComponent,
    TerminacionAnticipadaComponent,
    InformacionGeneralFinancieroComponent,
    InformacionGeneralDetalleComponent,
    TransversalesComponent,
    ContratoCreditoComponent,
    ContratoCreditoDetalleComponent,
    MenuJuridicoComponent,
    TribunalesComponent,
    SeguimientoTribunalesComponent,
    AmigablesComponent,
    AmigablesDetalleComponent,
    TribunalesDetalleComponent,
    CarreteroHomeComponent,
    MenuFinancieroComponent,
    ProyectoLineaBaseComponent,
    InformacionProyectoCarreteroComponent,
    ConcesionarioCarreteroComponent,
    InterventoriaCarreteroComponent,
    InterventoriaCarreteroDetalleComponent,
    UnidadFuncionalCarreteroComponent,
    UnidadFuncionalCarreteroDetalleComponent,
    ContratoCreditoCarreteroComponent,
    TemasGestionComponent,
    TemasGestionDetalleComponent,
    SeguimientoDialog,
    ComponedoresDialog,
    ApoderadosDialog,
    ArbitrosDialog,
    TemasGestionRegistroComponent,
    DisableIfUnauthorizedDirectiveDirective,
    HideIfUnauthorizedDirectiveDirective,
    DisableLinkADirectiveDirective,
    FerreoHomeComponent,
    PuertosHomeComponent,
    AeropuertosHomeComponent,
    TramiteAmbientalComponent,
    TramiteAmbientalDetalleComponent,
    SeguimientoComponent,
    ReportesAeropuertoComponent,
    ReportesPuertosComponent,
    ReportesFerroComponent,
    ProyectoHomeAeroComponent,
    ProyectoHomeFerreoComponent,
    ProyectoHomePuertoComponent,
    FuncionalesDialog,
    EstadosDialog,
    PriorizacionesDialog,
    ResponsablesDialog,
    ObservacionesDialog,
    ActuacionDialog,
    ActuacionADialog,
    NotificacionDialog,
    SecretarioDialog,
    PersonaAmigableDialog,
    ListarPlaneacionesComponent,
    CrearPlaneacionComponent,
    SeleccionarUnidadfuncionalComponent,
    SeleccionarProyectoComponent,
    SeleccionarIntervencionComponent,
    PolizasComponent,
    PolizaEditComponent,
    TramitesAmbientalesListadoComponent,
    AmparosComponent,
    AmparosEditComponent,
    ConfirmacionDialog,
    EliminiarDialog,
    InformacionGeneralBaseComponent,
    SeleccionarEjecucionComponent,
    SaldoFinancieroComponent,
    RetribucionFinancieraComponent,
    VigenciasFuturasComponent,
    VpipFinancieroComponent,
    InversionIndexadaComponent,

    SelectorFechaQuincenaComponent,
    TipoIntervencionCarreteroComponent,
    TipoIntervencionCarreteroDetalleComponent,
    AccionistasCarreteroComponent,
    PersonaComponent,
    PersonaSeguimientoComponent,
    PersonaJuridicaSeguimientoComponent,
    SeguimientoAmigableComponent,
    ContratoCreditoBaseComponent,
    TraficoRecaudoComponent,
    EtapaProyectoCarreteroComponent,
    ContratoCreditoPuenteBaseComponent,
    ContratoCreditoPuenteComponent,
    ContratoCreditoPuenteIndexadoComponent,
    ContratoCreditoPuenteIndexadoDetalleComponent,
    ContratacionHomeComponent,
    ContratosComponent,
    PersonaJuridicaComponent,
    PersonaBuscarAgregarComponent,
    PersonaBuscarSeguimientoComponent,
    ContratoDetalleComponent,
    ContratoBaseComponent,
    ContratoPolizaComponent,
    ContratoContratistaComponent,
    ContratoFuenteFinanciacionComponent,
    EstadosGestionDialog,
    PriorizacionGestionDialog,
    AcuacionGestionDialog,
    VisorAlmacenamientoComponent,
    AdjuntarAlmacenamimentoComponent,
    AdjuntarAlmacenamientoPublicComponent,
    ValidacionInformacionProyectoComponent,
    AvanceProyectoMenusComponent,
    PersonasComponent,
    ContratoSupervisorBrrComponent,
    PersonaBaseComponent,
    PersonaDatosAdicionalesComponent,
    PersonaDatosBasicosComponent,
    ObligacionesContratoComponent,
    ContratosPublicadosComponent,
    VistoBuenoContratoComponent,
    ContratosOpcionesAdicionalesComponent,
    ContratoObligacioBrrComponent,
    CovidReportarComponent,
    CovidReportarProyectoComponent,
    CovidReportarTrabajadoresComponent,
    PaginaInicialComponent,
    CovidReportarReinicioObraComponent,
    CovidReportarSeguimientoComponent,
    CovidReportarMedidasComponent,
    InformeContratistaEncabezadoComponent,
    ContratosContratistaComponent,
    ContratoPlanPagoContratistaComponent,
    InformeContratistaBaseComponent,
    InformeContratistaObligacionesComponent,
    InformeContratistaBeneficiosTributariosComponent,
    InformeContratistaAnexosComponent,
    InformeContratistaObservacionesComponent,
    InformesVoboComponent,
    InformeVoboBaseComponent,
    InformeVoboObligacionesComponent,
    InformeVoboBeneficiosTributariosComponent,
    InformeVoboAnexosComponent,
    InformeVoboObservacionesComponent,
    InformesSupervisorComponent,
    InformeSupervisorBaseComponent,
    InformeSupervisorObligacionesComponent,
    InformeSupervisorBeneficiosTributariosComponent,
    InformeSupervisorAnexosComponent,
    InformeSupervisorObservacionesComponent,
    ContratosHomeComponent,
    ContratacionSupervisionHomeComponent,
    VisualizarArchivoComponent,
    ContratoInformacionVigenteEncabezadoComponent,
    PersonaEducacionComponent,
    CertificadoPdfComponent,
    SeleccionarSiNoComponent,
    AnonimoCarreterosComponent,
    SeleccionarDepartamentoMunicipioComponent,
    PublicCarreterasComponent,
    PublicCarreterasConsultaComponent,
    PublicCarreterasMapaComponent,
    PublicCarreterasConsultaResumenComponent,
    PublicCarreterasConsultaFicha4gComponent,
    PublicCarreterasConsultaFicha3g2gComponent,
    PublicCarreterasConsultaPeajesComponent,
    PublicAeropuertosComponent,
    PublicAeropuertosMapaComponent,
    PublicPuertosComponent,
    PublicPuertosMapaComponent,
    PublicPuertosFichaProyectoComponent,
    PublicPuertosFichaProyectoHomeComponent,
    PublicPuertosFichaProyectoDrupalComponent,
    PuertoSeguimientoGestionComponent,
    PuertoHomeFichaProyectoComponent,
    PuertoHomeRegistrarComponent,
    PublicFerrocarrilesComponent,
    PqrsEncuestaSatisfaccionComponent,
    PqrsConsultaComponent,
    PqrsHomeServiciosComponent,
    PqrsReporteEncuestaSatisfaccionComponent,
    PqrsRadicacionComponent,
    OrfeoConsultaComponent,
    PublicFerrocarrilesConsultaComponent,
    PublicFerrocarrilesMapaComponent,
    PublicFerrocarrilesConsultaEstadisticaComponent,
    PublicCarreterasConsultaPeajesComponent,
    InformeVoboAprobacionComponent,
    InformeSupervisorAprobacionComponent,
    AgendamientoCitaComponent,
    AgendamientoConsultasComponent,
    CertificacionContratistaComponent,
    PublicMapComponent,
    SigComponent,
    VisorPlanesAgenciaComponent,
    ReporteDepartamentoComponent,
    ReporteSintomasComponent,
    CovidReportesComponent,
    ContratosNovedadesComponent,
    NovedadesContratoComponent,
    NovedadContratoBaseComponent,
    NovedadContratoAdicionComponent,
    CovidReportesInternoComponent,
    ReporteDepartamentoInternoComponent,
    ReporteSintomasInternoComponent,
    NovedadContratoAdicionProrrogaComponent,
    NovedadContratoFuenteFinanciacionComponent,
    NovedadContratoProrrogaComponent,
    NovedadContratoSuspensionComponent,
    NovedadContratoTerminacionAnticipadaComponent,
    NovedadContratoCesionComponent,
    NovedadContratoOtroSiComponent,
    InformesCxpComponent,
    InformeCxpBaseComponent,
    InformeCxpAnexosComponent,
    InformeCxpAprobacionComponent,
    InformeCxpBeneficiosTributariosComponent,
    InformeCxpObligacionesComponent,
    InformeCxpObservacionesComponent,
    InformesSpaComponent,
    InformeSpaBaseComponent,
    InformeSpaAnexosComponent,
    InformeSpaAprobacionComponent,
    InformeSpaBeneficiosTributariosComponent,
    InformeSpaObligacionesComponent,
    InformeSpaObservacionesComponent,
    InteresadosListaComponent,
    InteresadosExternosComponent,
    ReporteCovidBiInternoComponent,
    InteresadosExternosComponent,
    PublicFluvialComponent,
    PublicFluvialConsultaComponent,
    PublicFluvialConsultaFicha5gComponent,
    PublicCarreterasConsultaFicha5gComponent,
    //REFACTORING
    PublicCarreterasVisorResumenEjecutivoComponent,
    CarreterasVisorFichaProyecto4gComponent,
    PublicCarreterasVisorFichaProyectoComponent,
    PublicCarreterasVisorInversionPorDepartamentoComponent,
    PublicCarreterasVisorAvancePredialComponent,
    PublicCarreterasVisorTramitesAmbientalesComponent,
    PublicCarreterasVisorPeajesComponent,
    PublicCarreterasVisorEerComponent,
    PublicCarreterasVisorAreasDeInfluenciaComponent,
    CarreteroVisorMapaComponent,
    AeropuertosHomeVisorMapaComponent,
    PuertoHomeVisorMapaComponent,
    FerreoVisorMapaComponent,
    VisorReportePredialComponent,

    PublicAeropuertosConsultaComponent,
    PublicAeropuertosConsultaFicha5gComponent,
    PublicFerrocarrilesConsultaFicha5gComponent,
    PublicProyectos5gComponent,
    PublicProyectos5gFichaOla2Component,
    ContratacionReportesHomeComponent,
    ReporteInformesContratistaComponent,
    PublicProyectos5gFichaOla1Component,
    TraficoPeajesComponent,
    ReporteContratosComponent,
    ReporteContratoBaseComponent,
    SeguimientoComunicacionesComponent,
    SeguimientoComunicacionesDetalleComponent,
    SeguimientoComunicacionesRegistroComponent,
    EstadosComunicacionesDialogComponent,
    RespuestaEmitidaDialogComponent,
    SeguimientoHomeComponent,
    SeguimientoReporteComponent,
    SeguimientoConsultaComponent,
    SeguimientoVerComunicacionComponent,
    CertificacionHomeComponent,
    CertificacionesLaboralesValidacionComponent,
    CertificacionContratistaValidacionComponent,
    CertificacionActivosComponent,
    CertificacionRetiradosComponent,
    CertificacionGeneralComponent,
    CertificacionConfiguracionComponent,

    YoutubePlaylistComponent,
    YoutubeSearchListComponent,
    YoutubeUploadFileComponent,
    YoutubeHomePublicComponent,
    YoutubeHomeComponent,
    YoutubeProyectoComponent,
    YoutubeProyectoVideoComponent,
    UploadFileComponent,

    TramitesVisorSeguimientoComponent,
    TramitesAmbientalesComponent,
    TramitesAmbientalesSeguimientoComponent,
    TramitesAmbientalesListadoFerreoComponent,
    TramitesAmbientalesFerreoComponent,
    TramitesAmbientalesSeguimientoFerreoComponent,
    TramitesAmbientalesListadoPuertoComponent,
    TramitesAmbientalesPuertoComponent,
    TramitesAmbientalesSeguimientoPuertoComponent,

    PublicProyectos5gFichaOla1Component,
    ReporteInformesContratistaComponent,
    InformeSpaObservacionesComponent,
    NovedadContratoOtroSiComponent,
    AvanceProyectoComponent,
    AvanceProyectoMenusComponent,
    AvanceProyectoReportarAvanceComponent,
    AvanceProyectoEjecucionesComponent,
    AvanceProyectoUnidadesFuncionalesComponent,
    AvanceProyectoIntervencionesComponent,
    AvanceProyectoCrearEjecucionComponent,
    AvanceProyectoReportarEtapasComponent,
    AvanceProyectoConstruccionComponent,
    ReporteContratoBaseComponent,
    ReporteContratoPlanPagoComponent,
    ContratoBalanceFinancieroComponent,
    InformeCxpObligacionPagoComponent,
    InformeSpaObligacionPagoComponent,
    InventarioEquiposComponent,
    RegistrarModeloComponent,
    RegistrarBienComponent,
    MisBienesComponent,
    InformeSpaObligacionPagoComponent,
    MenuPeajesComponent,
    TarifasPeajesComponent,
    NuevaTarifaPeajesComponent,
    ContratosCarreteroComponent,
    EtapaProyectoCarreteroComponent2,
    ContratoContratistaCarreteroBaseComponent,
    ContratoCarreteroComponent,
    ContratistaCarreteroComponent,
    ModificacionContractualCarreteroComponent,
    AlcanceProyectoBaseComponent,
    LongitudConcesionadaProyectoComponent,
    UnidadFuncionalCarreteroComponent2,
    ValidacionInformacionProyectoComponent2,
    AccionistasCarretero2Component,
    UnidadFuncionalCarreteroDetalleComponent2,
    TipoIntervencionCarreteroComponent2,
    TipoIntervencionCarreteroDetalleComponent2,
    InformacionGeneralProyectoComponent,
    InformeSpaObligacionPagoComponent,
    ContratoPlanPagoComponent,
    ContratoSupervisorComponent,
    InformeSpaObligacionPagoComponent,
    PublicUatComponent,
    RepositorioUatComponent,
    SobreUatComponent,
    InvestigacionUatComponent,
    MenuLateralComponent,
    AliadosUatComponent,
    MesaUatComponent,
    ModeloUatComponent,
    ReporteCuentasCxpComponent,
    CompensacionesAmbientalesComponent,
    ObligacionesCompensacionComponent,
    SeguimientoCompensacionesComponent,
    SolicitudPagoAprobadasSupervisorComponent,
    RpteCxpBenTribComponent,
    RpteCxpOblComponent,
    RpteCxpBaseComponent,
    RpteCxpAnxComponent,
    RpteCxpOblPagoComponent,
    RpteCxpObsComponent,
    RpteCxpAprobComponent,
    RpteCxpPagoComponent,
    SolicitudPagoAprobadasSupervisorComponent,
    PersonaJuridicaExtranjeraComponent,
    DocumentosPeajesComponent,
    MenuPeajesExternosComponent,
    ReportesPeajesExternosComponent,
    RecaudoPeajesComponent,
    DetalleRecaudoPeajesComponent,
    ObligacionAmbientalComponent,
    GestionarLocalizacionComponent,
    EjecucionesObligacionComponent,
    ArchivoShpComponent,
    GestionarLocalizacionAvanceComponent,
    ReporteInformesContratistaEjecutivoComponent,
    RpteCxpTotalComponent,
    RpteCxpTotalDpnComponent,
    RpteCxpCmpvoComponent,
    ContratacionReportesCuentasCobroHomeComponent,
    ConsultarLocalizacionComponent,
    SafePipe,
    ConsultarLocalizacionAvanceComponent,
    ReportesInventarioComponent,
    PersonaNaturalComponent,
    ReportesPeajesRecaudoComponent,
    PrediosComponent,
    RegistroDetalladoPrediosComponent,
    CrearEditarPredioComponent,
    AdjuntosPredioComponent,
    InformacionGeneralPeajesComponent,
    ConsultarMapaInformacionGeneralComponent,
    AdjuntosPredioComponent,
    AniAppsComponent,
    DatosAbiertosComponent,
    DaAvanceProyectoComponent,
    DaInformacionGeneralComponent,
    TarifasPeajesReportesComponent,
    DaInformacionGeneralComponent,
    PublicAeroFichaProyectoDrupalComponent,
    AeropuertosHomeFichaProyectoDrupalComponent,
    FerreoFichaProyectoDrupalComponent,
    PublicFerroFichaProyectoDrupalComponent,
    ConsultarMapaInformacionGeneralComponent,
    PuestosTrabajoComponent,
    CrearEditarReservaComponent,
    RegistrarReservaComponent,
    ListarCuposComponent,
    CrearEditarCuposComponent,
    PuestosTrabajoHomeComponent,
    ListarFranjasComponent,
    CrearEditarFranjasComponent,
    AdministracionHomeComponent,
    PublicFerroFichaProyectoDrupalComponent,
    ConsultarLocalizacionUfComponent,
    GestionarLocalizacionUfComponent,
    ConsultarLocalizacionPredioComponent,
    FuncionarioInviasPrincipalListComponent,
    ConcesionarioInviasPrincipalListComponent,
    FuncionarioInviasDetalleComponent,
    ConcesionarioInviasDetalleComponent,
    ConcesionarioRadicacionRespuestaComponent,
    FuncionarioRadicacionRespuestaComponent,
    ListadoRadicacionWebComponent,
    NewEditRadicacionWebComponent,
    ConsultaArchivoRadicacionWebComponent,
    EditFirmantesRadicacionWebComponent,

    CargaMasivaPrediosComponent,
    DatosMovimientoComponent,
    GestionarLocalizacionPredioComponent,
    CargaMasivaPrediosComponent,
    DatosMovimientoComponent,
    InformeContratistaPazSalvoComponent,
    InformeVoboPazSalvoComponent,
    InformeSupervisorPazSalvoComponent,
    InformeCxpPazSalvoComponent,
    InformeSpaPazSalvoComponent,
    RpteCxpPazSalvoComponent,
    InformeSpaPazSalvoComponent,
    AdministracionHomeComponent,
    PublicFerroFichaProyectoDrupalComponent,
    ComisionesHomeComponent,
    CrearEditarSolicitudComisionComponent,
    ComisionesLegalizacionesRegistroComponent,
    ComisionesLegalizacionesRevisionComponent,
    ComisionesTiquetesRevisionComponent,
    ComisionesTiquetesRegistroComponent,
    CrearEditarTiqueteComisionComponent,
    ComisionesConfiguracionComponent,
    ComisionesSolicitudesComponent,
    ComisionesConfiguracionDependenciasResponsableComponent,
    ComisionesConfiguracionFuncionarioComponent,
    ComisionesRevisarSolicitudesComponent,
    NovedadContratoReduccionPlazoComponent,
    NovedadContratoLiberacionRecursosComponent,
    NovedadContratoFuenteFinanciacionLiberacionComponent,
    VisorCompensacionesAmbientalesComponent,
    VisorPolizasComponent,
    RptCrrProyectosComponent,
    RptCrrProyectoDetalleComponent,
    RptCrrProyectoEncabezadoComponent,
    RptCrrFichaProyectosComponent,
    RptCrrProyectoVideosComponent,
    RptCrrProyectoAvancesComponent,
    RptCrrProyectoInversionComponent,
    RptCrrProyectoFinancieroComponent,
    RptCrrProyectoPeajeComponent,
    RptCrrProyectoAccionistasComponent,
    RptCrrProyectoTemasgestionComponent,
    RptCrrProyectoPredialComponent,
    RptCrrProyectoMetasComponent,
    VisorPolizasComponent,
    PublicProyectos5gFichaSostenibilidadComponent,
    DaOcdsComponent,
    ComisionesGestionPresupuestalRegistroComponent,
    ComisionesGestionPresupuestalComisionesComponent,
    ComisionInformacionGeneralComponent,
    RptCrrPeajesComponent,
    RptCrrPeajesProyectoComponent,
    RptCrrPeajesDetalleComponent,
    RptCrrPeajesTarifasComponent,
    ComisionInformacionGeneralComponent,
    SeleccionarMunicipiosComponent,
    RptCrrPeajesTraficoRecaudoComponent,
    RptCrrPeajesTraficoRecaudoDetalleComponent,

    PersonaDependenciaComponent,
    ComisionesRegistroPresupuestalObligacionComisionesComponent,
    ComisionesRegistroPresupuestalObligacionRegistroComponent,
    ComisionesOrdenPagoPresupuestalRegistroComponent,
    ComisionesOrdenPagoPresupuestalComisionesComponent,
    ComisionesReportesHomeComponent,
    ComisionesReporteEjecutivoComponent,
    RptCrrFicha5gComponent,
    VisualizarComisionesCancelacionComponent,
    DireccionComponent,
    ContratoDependenciaComponent,
    ComisionesReporteDetalleVersionesComponent,
    ComisionesReporteDetalleRegistroVersionesComponent,
    EquiposItsComponent,
    RegistroEquipoItsComponent,
    VisorMapaSocialRiesgosPeajesComponent,
    VisorMapaConflictividadProyectosComponent,
    VisorDispositivosItsComponent,
    ApiServiceComponent,
    TraficoAniComponent,
    PublicMapModosComponent,
    PublicMapPeajesComponent,
    PublicMapAreasInfluenciaComponent,
    PublicMapAreasAtlasComponent,
    SigCriticidadPeajesComponent,
    SigDispositivosITSComponent,
    SigPredialComponent,
    SigCompensacionesAmbientalesComponent,
    SigPuertosComponent,
    SigTraficoWazeComponent,
    SigCanalDelDiqueComponent,
    PublicMapServiciosAyudaComponent,
    PublicNovedadesComponent,
    PortafolioComponent,
    ProyectosAeroportuarioComponent,
    RutaFerrecaribeComponent,
    ProyectosEducacionComponent,
    ProyectosSaludComponent,
    ProyectosSocialComponent,
    ProyectoInformacionVigenteComponent,
    OlaInvernalRegistroComponent,
    OlaInvernalRegistroListadoComponent,
    OlaInvernalReporteDetalleListadoComponent,
    OlaInvernalReporteDetalleComponent,
    NovedadContratoCambioHonorariosComponent,
    OlaInvernalFerreoRegistroRegistroComponent,
    OlaInvernalFerreoRegistroListadoComponent,
    OlaInvernalFerreoReporteDetalleListadoComponent,
    OlaInvernalFerreoReporteDetalleComponent,
    ComisionesHomeComponent,
    CtoReporteCxpDevolucionesTesoreriaComponent,
    CtoReporteCxpPlanesPagoCuentasCobroComponent,
    CtoReporteCxpContratosComponent,
    SigFaunaniComponent,
    TramitesAmbientalesCarreteroComponent,
    TramitesAmbientalesListadoCarreteroComponent,
    TramitesAmbientalesSeguimientoCarreteroComponent,
    FluvialComponent,
    TramitesAmbientalesFluvialComponent,
    TramitesAmbientalesListadoFluvialComponent,
    TramitesAmbientalesSeguimientoFluvialComponent,
    ProyectoHomeFluvialComponent,
    RptCrrPeajesTpdComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule, MatTableModule, MatTabsModule, MatFormFieldModule, MatPaginatorModule, MatInputModule, MatIconModule,
    MatOptionModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatToolbarModule, MatCheckboxModule,
    MatDividerModule, MatAutocompleteModule, MatRadioModule, MatGridListModule, MatProgressSpinnerModule, MatCardModule, MatExpansionModule,
    HttpClientModule, ChartModule, RecaptchaModule, CardModule, MenubarModule, FieldsetModule, DataViewModule,
    MatDialogModule, MatListModule, CurrencyMaskModule, MatTooltipModule,

    // PrimeNG Modules
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BlockUIModule,
    ButtonModule,
    CalendarModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ConfirmDialogModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    PaginatorModule,
    PanelModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    ScrollPanelModule,
    StepsModule,
    TableModule,
    TabViewModule,
    ToastModule,
    TooltipModule,
    TreeTableModule,
    CardModule,

    MsAdalAngular6Module.forRoot({
      tenant: environment.tenant,
      clientId: environment.clientId,
      redirectUri: window.location.origin,
      //endpoints: environment.baseUrl,
      endpoints: {
        "https://graph.microsoft.com": "https://graph.microsoft.com"
      },
      navigateToLoginRequestUrl: false,
      extraQueryParameter: environment.extraQueryParameter,
      cacheLocation: 'sessionStorage',
      audience: 'api://ae41e71b-79ec-463f-9a2f-04b9fefd4eef/aniapi',//environment.audience,
      authority: 'https://login.microsoftonline.com/common/oauth2/authorize',
      resourceId: "https://ADNewFei.onmicrosoft.com/webAPIFei"
    }),
    BreadcrumbModule,
    PdfViewerModule,
    NgxFileDropModule,
    CarouselModule
  ],
  providers: [
    AuthenticationGuard,
    CanActivateViaAuthGuard,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: MatPaginatorIntl, useValue: getSpaPaginatorIntl() },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    AppInsightsService,
    MessageService,
    ConfirmationService,
    { provide: MAT_DATE_LOCALE, useValue: 'es-CO' },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],


  bootstrap: [AppComponent],
  entryComponents: [ProgressSpinnerDialogComponent,
    SeguimientoDialog,
    ComponedoresDialog,
    ApoderadosDialog,
    ArbitrosDialog,
    FuncionalesDialog,
    EstadosDialog,
    ConfirmacionDialog,
    EliminiarDialog,
    PriorizacionesDialog,
    NotificacionDialog,
    ObservacionesDialog,
    ResponsablesDialog,
    ActuacionDialog,
    ActuacionADialog,
    SecretarioDialog,
    PersonaAmigableDialog,
    EstadosGestionDialog,
    PriorizacionGestionDialog,
    AcuacionGestionDialog,
    EstadosComunicacionesDialogComponent,
    RespuestaEmitidaDialogComponent
  ]
})

export class AppModule { }



