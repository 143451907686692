export enum EnumRespuestaServicio {
    CODIGO_ERROR_OPERACION = 0,
    CODIGO_EXITO_OPERACION = 1,
    CODIGO_ERROR_LECTURA_PARAMETROS = 2,
    ERROR_CONSUMO_SERVICIO = 'Ocurrió un error al consumir el servicio',
    ERROR_OPERACION = 'Ocurrió un error al realizar la operación',
    ERROR_VALIDAR_ADMINISTRADOR = 'Por favor validar con el Administrador indicando el error en pantalla'

}

export enum EnumFechaFormato {
    DD_MM_YYYY_SLASH = 'dd/MM/yyyy',
    YYYY_MM_DD_GUION = 'yyyy-MM-dd',
    YYYY = 'yyyy',
    DD_MM_YYYY_HH_MM_SS_SLASH = 'dd/MM/yyyy HH:mm:ss',
    RANGO_FECHA_ESTANDAR = '2000:3000',
    YYYY_MM_DD_T_HH_MM_SS_GUION = 'yyyy-MM-ddTHH:mm:ss'
}

export enum EnumTipoDocumento {
    CC = 'CC',
    CE = 'CE',
    NIT = 'NIT',
    TI = 'TI',
    RC = 'RC',
    NIEE = 'NIEE',
    PEP = 'PEP'// Solo aplica para Covid
}

export enum EnumRegex {
    REGEX_MAYOR_A_CERO = "^[1-9][0-9]*$",
    REGEX_MAYOR_A_CERO_DECIMAL = "[0-9]+(.[0-9][0-9]?)?",
    REGEX_MAYOR_A_CERO_DECIMAL_MAS_DECIMAS = "^-?([0-8]?[0-9]?[0-9]?[0-9]|9000)(.[0-9]{1,10})?$",
    REGEX_MAYOR_A_CERO_DECIMAL_MAS_DECIMAS_FERREO = "^-?([0-8]?[0-9]|9000)(.[0-9]{1,10})?$",
    REGEX_ALFANUMERICO_ESPACIO_PUNTO_GUION_COMA_SLASH = "^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ._,\- ]*",
    REGEX_ALFABETO_SPACIOS = "^[A-Za-z\sñÑáéíóúÁÉÍÓÚ]*",
    REGEX_CORREO_ELECTRONICO = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$",
    REGEX_CORREO_ELECTRONICO_ANI = "[a-z0-9._%+-]+@ani.gov.co$",
    REGEX_PAGINA_WEB = "^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)",
    REGEX_NUMERO_CELULAR = "^\d{10}$",
    REGEX_NUMERO_FIJO_O_CELULAR = "((60[1245678])|(3[0-9]{2}))[1-9][0-9]{6}",
    REGEX_PERSONA_NATURAL_NUMERO_DOCUMENTO = "^[1-9][0-9]*$",
    REGEX_PERSONA_NATURAL_PRIMER_NOMBRE = "^[A-Za-z\ ñÑáéíóúÁÉÍÓÚ]*",
    REGEX_CTO_NUMERO_CONTRATO = "^([A-Za-z0-9ñÑáéíóúÁÉÍÓÚ])+[-_.,A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]*",
    REGEX_ALFABETICO = "^[a-zA-Z\s ñÑ.,]+",
    REGEX_ALFANUMERICOS = "^[A-Za-za-z0-9\s ñÑ.,]+",
    REGEX_ALFANUMERICOS_CON_TILDE = "^[A-Za-za-z0-9\s ñÑáéíóúÁÉÍÓÚ.,]+",
    REGEX_LATITUD = "^-?([0-8]?[0-9]|90)(.[0-9]{1,10})?$",
    REGEX_LONGITUD = "^-?([0-9]{1,2}|1[0-7][0-9]|180)(.[0-9]{1,10})$",
    REGEX_LONGITUD_FERREO = "^-?([0-8]?[0-9]|90)(.[0-9]{1,10})?$",
}

export enum EnumTrnMaTipoSoporte {

    TIPO_SOPORTE_CONTENEDOR_CONTRATACION = 'contratacion',
    TIPO_SOPORTE_CONTENEDOR_COMISIONES = 'comisiones',
    TIPO_SOPORTE_CONTENEDOR_OLA_INVERNAL = 'ola-invernal',

    TIPO_SOPORTE_ACTUALIZACION_POLIZA = 1,
    TIPO_SOPORTE_CONCEPTO_APROBACION_NEGACION_INTERVENTORIA = 2,
    TIPO_SOPORTE_TIPO_MEMORANDO_APROBACION = 3,
    TIPO_SOPORTE_POLIZA_CON_OTROSI = 4,
    TIPO_SOPORTE_RADICACION_DE_APROBACION_DE_POLIZA = 5,
    TIPO_SOPORTE_SOPORTE_DEVOLUCION = 6,
    TIPO_SOPORTE_RADICACION_DE_POLIZA = 7,
    TIPO_SOPORTE_EVIDENCIA_MEDIDA = 8,
    TIPO_SOPORTE_EVIDENCIA_OBLIGACION = 9,
    TIPO_SOPORTE_ANEXO = 10,
    TIPO_SOPORTE_CERTIFICADO_DE_CUMPLIMIENTO = 11,
    TIPO_SOPORTE_CERTIFICADO_DE_PAGO = 12,
    TIPO_SOPORTE_TRAFICO_PEAJE = 13,
    TIPO_SOPORTE_REGISTRO_PRESUPUESTAL_DE_OBLIGACION = 14,
    TIPO_SOPORTE_ANEXO_RESOLUCION = 15,
    TIPO_SOPORTE_OFICIO = 16,
    TIPO_SOPORTE_ACTO_ADMINISTRATIVO = 17,
    TIPO_SOPORTE_SOPORTE = 18,
    TIPO_SOPORTE_ANEXO_PAZ_Y_SALVO = 19,
    TIPO_SOPORTE_CMS_SOPORTE_OBJETO_COMISION = 21,
    TIPO_SOPORTE_CMS_RESERVA_TIQUETE = 22,
    TIPO_SOPORTE_CMS_CERTIFICADO_OBLIGACION_PAGO = 23,
    TIPO_SOPORTE_CMS_CERTIFICADO_PAGO = 24,
    TIPO_SOPORTE_CMS_COMPRA_TIQUETE = 25,
    TIPO_SOPORTE_CMS_INFORME_LEGALIZACION = 26,
    TIPO_SOPORTE_CMS_INFORME_FACTURA = 27,
    TIPO_SOPORTE_CMS_INFORME_PASABORDO = 28,
    TIPO_SOPORTE_CMS_INFORME_FACTURA_TRANSPORTE = 29,
    TIPO_SOPORTE_CMS_INFORME_SOPORTE_PEAJES = 30,
    TIPO_SOPORTE_CMS_INFORME_GASTO_COMBUSTIBLE = 31,
    TIPO_SOPORTE_CMS_ACTO_ADMINISTRATIVO = 33,
    TIPO_SOPORTE_CMS_AUTORIZACION_SIIF = 34,
    TIPO_SOPORTE_CMS_MEMORANDO_USOS_LEGALIZACION = 35,
    TIPO_SOPORTE_CMS_SOLICITUD_CANCELACION_TIQUETES = 36,
    TIPO_SOPORTE_CMS_SOLICITUD_CANCELACION_RP = 37,
    TIPO_SOPORTE_CMS_SOLICITUD_CANCELACION_ACTO_ADMINISTRATIVO = 38,
    TIPO_SOPORTE_CRR_OLA_INVERNAL_FOTOGRAFIA = 39,


    TIPO_SOPORTE_MODULO_POLIZAS = 'Polizas',
    TIPO_SOPORTE_MODULO_INFORME_CONTRATISTAS = 'informe_contratistas',
    TIPO_SOPORTE_MODULO_TRAFICO_PEAJE = 'trafico_peaje',
    TIPO_SOPORTE_MODULO_TARIFA_PEAJE = 'tarifa_peaje',
    TIPO_SOPORTE_MODULO_OBLIGACION_AMBIENTAL = 'obligacion_ambiental',
    TIPO_SOPORTE_MODULO_COVID = 'covid',
    TIPO_SOPORTE_MODULO_PREDIAL = 'predial',
    TIPO_SOPORTE_MODULO_COMISIONES = 'comisiones',
    TIPO_SOPORTE_MODULO_OLA_INVERNAL = 'ola_invernal'
}

export enum EnumTrnConstante {
    GENERAL_CORREO_SOPORTE = "GENERAL_CORREO_SOPORTE",
    //CONTRATACION
    CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE = "CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE",
    CTO_CONTRATACION_CORREO_SOPORTE = "CTO_CONTRATACION_CORREO_SOPORTE",	//infocontratos@ani.gov.co    
    CTO_PLAN_PAGOS_TESORERIA_SOPORTE = 'CTO_PLAN_PAGOS_TESORERIA_SOPORTE',
    //Esta sección se agrego a partir del periodo de Diciembre 2021
    CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA = "CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA", //2021
    //Esta constantes se agregó en Enero 2024
    CTO_NOVEDADES_CONTRACTUALES_DIAS_HABILITA_GESTION_NOVEDADES = "CTO_NOVEDADES_CONTRACTUALES_DIAS_HABILITA_GESTION_NOVEDADES",
    //COMISIONES        
    CMS_COMISIONES_CORREO_CENTRAL_PAGOS = "CMS_COMISIONES_CORREO_CENTRAL_PAGOS", //odaza@ani.gov.co
    CMS_COMISIONES_CORREO_TESORERIA = "CMS_COMISIONES_CORREO_TESORERIA", //jcarvajal@ani.gov.co
    CMS_COMISIONES_CORREO_SOPORTE_FLUJO_APROBACION = "CMS_COMISIONES_CORREO_SOPORTE_FLUJO_APROBACION"	//hvargas@ani.gov.co
}
export enum EnumAmbMaTipoTramite {
    TIPO_TRAMITE_CESION = 6,
    TIPO_TRAMITE_CIERRE_ARCHIVO = 7,
    TIPO_TRAMITE_DESISTIMIENTO = 10,
    TIPO_TRAMITE_MODIFICACION = 17,
    TIPO_TRAMITE_OTRO = 19
}

export enum EnumAmbMaTramiteRelacionado {
    TRAMITE_RELACIONADO_LICENCIA_AMBIENTAL = 1,
    TRAMITE_RELACIONADO_PERMISO = 2
}

export enum EnumTrnMaTipoProyecto {
    MODO_AEROPUERTO = 1,
    MODO_CARRETERO = 2,
    MODO_CENTRO_LOGISTICO = 3,
    MODO_FERREO = 4,
    MODO_PUERTO = 5,
    MODO_FLUVIAL = 6
}

