import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visor-polizas',
  templateUrl: './visor-polizas.component.html',
  styleUrls: ['./visor-polizas.component.sass']
})
export class VisorPolizasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
  }

}
