import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config.service';
import { environment } from 'src/environments/environment';
import { map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { PjePeajeProyectoTarifaRpt } from './PjePeajeProyectoTarifa';
import { PjeConfiguracionesPeajeRpt } from './PjeConfiguracionesPeaje';
import { PjePeajePorProyecto, PjePeajePorProyectoRpt } from '../trafico-peajes/PjePeajePorProyecto';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';


@Injectable({
    providedIn: 'root'
})
export class TarifasPeajesService {


    public END_POINT = '';
    fechaActual: Date;

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient, public datepipe: DatePipe) {
        this.END_POINT = environment.baseUrl;
    }

    cargarTarifasPeajes(idProyecto: number) {
        return this.http.get<PjePeajeProyectoTarifaRpt>(this.END_POINT + '/PjeTarifasPeajes/getPeajesTarifas/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    cargarConfiguraciones(idPeaje: number) {
        return this.http.get<PjeConfiguracionesPeajeRpt>(this.END_POINT + '/PjeTarifasPeajes/getConfiguracionesPeaje/' + idPeaje)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    cargarPeajesReportes() {
        return this.http.get<PjePeajePorProyectoRpt>(this.END_POINT + '/PjeTarifasPeajes/getPeajesTarifasReporte')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    verArchivoTarifas(peaje: PjePeajePorProyecto) {


        this.http.get(this.END_POINT + '/PjeTarifasPeajes/GetArchivoTarifas/' + peaje.idPeaje,
            {
                responseType: 'arraybuffer'
            }
        ).subscribe(response => this.downLoadFile(response, 'application/zip', peaje));



    }

    downLoadFile(data: any, type: string, peaje: PjePeajePorProyecto) {

        this.fechaActual = new Date();
        var fechaArchivo = this.datepipe.transform(this.fechaActual, 'yyyy-MM-dd HH:mm');
        console.log(fechaArchivo);

        let blob = new Blob([data], { type: type });

        if (peaje.idPeaje === 0) {

            saveAs.saveAs(blob, 'tarifasPeajes ' + fechaArchivo + '.csv');
        }
        else{
            saveAs.saveAs(blob, peaje.peaje+' '+ fechaArchivo + '.csv');
        }
    }



}

export class RespuestaServicioRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}