import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contratacion-home',
  templateUrl: './contratacion-home.component.html',
  styleUrls: ['./contratacion-home.component.sass']
})
export class ContratacionHomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
      element.className = "ani public logeo contratacion";
  }
}
