import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { DatosabiertosService } from '../../datos-abiertos.service';
import { InformacionGeneralTabla } from '../../datos-abiertos/informacionGeneralTabla';
import { InformacionGeneralDA } from '../../datos-abiertos/informacionGeneral';
import { ProyectoCarretero } from 'src/app/informacion-general/proyecto-carretero';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-da-informacion-general',
  templateUrl: './da-informacion-general.component.html',
  styleUrls: ['./da-informacion-general.component.sass']
})
export class DaInformacionGeneralComponent implements OnInit {

  informacionGeneral: InformacionGeneralDA[];
  informacionGeneralTabla: InformacionGeneralTabla[];  
  selectedInformacionGeneral: InformacionGeneralDA[];
  proyectoCarretero: ProyectoCarretero[]=[];
  proyectoCarreteroSeleccionado: any;
  cols: any[];
  exportColumns: any[];
  blockedDocument: boolean = true;
  proyectoFiltro: any = []; 
  modoSeleccionado:any;
  modo:any[];
  modoFiltro: any =[];

  constructor(private datosAbiertosService: DatosabiertosService, private principalComponent : PrincipalComponent) {    
    datosAbiertosService.getProyecto().subscribe((dato)=>{
      this.proyectoCarretero=dato.proyectoCarretero;       
    },
    error=>{
      this.blockedDocument = false;
      console.error(error);
      principalComponent.cargarErrorServicio(error);  
    },
    ()=>{      
      this.blockedDocument=false;
    })

    datosAbiertosService.getModo().subscribe((dato)=>{
      this.modo=dato.modoTransporte;     
    },
    error=>{
      this.blockedDocument = false;
      console.error(error);
      principalComponent.cargarErrorServicio(error);      
    },
    ()=>{
      this.blockedDocument=false;
    })
   }
  
  ngOnInit() {       
    this.blockedDocument = true;    
    this.cols = [
     // { field: 'id', header: 'Id' },
      { field: 'nombre', header: 'Nombre' },
      //{ field: 'descripcion', header: 'descripcion' },
      { field: 'tipo', header: 'Modo' },
      { field: 'generacion', header: 'Generación' },
      { field: 'ola', header: 'Ola' },
      { field: 'etapaProyecto', header: 'Etapa Proyecto' }, 
      // { field: 'longitudConcesionada', header: 'longitudConcesionada' }, 
      // { field: 'longitudConcesionadaKm ', header: 'longitudConcesionadaKm' }, 
      // { field: 'longitudACargo', header: 'longitudACargo' },
      // { field: 'longitudACargoKm', header: 'longitudACargoKm' },
      // { field: 'numeroIdentificacionContratistaConcesion', header: 'Code' },
      // { field: 'contratistaConcesion', header: 'Code' },
      // { field: 'numeroContratoConcesion', header: 'Code' },  
      // { field: 'fechaSuscripcionContratoConcesion', header: 'Code' }, 
      // { field: 'numeroIdentificacionContratistaInterventor', header: 'Code' },
      // { field: 'contratistaInterventor', header: 'Code' },
      // { field: 'numeroContratoInterventor', header: 'Code' },
      // { field: 'fechaSuscripcionContratoInterventor', header: 'Code' },
      // { field: 'fechaReferencia ', header: 'Code' },
      // { field: 'plazoAnios', header: 'Code' }, 
      // { field: 'valorContratoFechaReferencia', header: 'Code' }, 
      // { field: 'fechaUltimaIndexacion', header: 'Code' },
      // { field: 'valorContratoIndexado', header: 'Code' },
      // { field: 'capexIndexado', header: 'Code' },
      // { field: 'opexIndexado', header: 'Code' },
      // { field: 'FechaSuscripcion ', header: 'Code' },
      // { field: 'fechaPreconstruccion ', header: 'Code' },
      // { field: 'fechaConstruccion ', header: 'Code' },
      // { field: 'fechaOperacion ', header: 'Code' },
       //{ field: 'fechaUltimoReporteAvance', header: 'Fecha Ultimo Reporte' }
  ];

  this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  // Filtrar por nombre de proyecto
  filtrarProyectos(event){   
    this.proyectoFiltro=this.proyectoCarretero.filter(d=> { return d.nombre.toLowerCase().includes(event.query.toLowerCase())});
  }

    // Filtrar por modo de transporte
    filtrarModo(event){   
    this.modoFiltro=this.modo.filter(d=> { return d.nombre.toLowerCase().includes(event.query.toLowerCase())});
  }

  // Consultar el proyecto seleccionado
  consultarProyecto(){

    var idProyecto=null;
    var idModo = null;
    if(this.proyectoCarreteroSeleccionado != null){
      idProyecto= this.proyectoCarreteroSeleccionado.idProyecto;
    }   
       
    if(this.modoSeleccionado != null){     
      idModo= this.modoSeleccionado.idModo;
    }          

    this.blockedDocument = true;
    this.datosAbiertosService.getInformacionGeneral(idProyecto, idModo).subscribe((dato)=>{      
      this.informacionGeneral = dato.informacionGeneral;
      this.informacionGeneralTabla = dato.informacionGeneral.map(d => new InformacionGeneralTabla(
         d.nombre, d.tipo, d.generacion, d.ola, d.etapaProyecto));
    },
    error =>{ 
      this.blockedDocument = false;
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);  
    },
    ()=>{
     this.blockedDocument = false;
    }
    );
  }

  public limpiarBusqueda() {
    this.proyectoCarreteroSeleccionado = null;
    this.modoSeleccionado = null;
    this.informacionGeneral = null;
    this.informacionGeneralTabla = null;
  }

  //Exportar PDF
    exportPdf() {
      import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
           const doc = new jsPDF.default(0,0);
            doc.autoTable(this.exportColumns, this.informacionGeneral);
            doc.save('informacionGeneral.pdf');
        })
    })

    }

    //Exportar Excel
  exportExcel() {
      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(this.informacionGeneral);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "informacionGeneral");
      });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}