import { Component, OnInit, ViewChild } from '@angular/core';
import { VpipIndexado } from './vpip-indexado';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { InformacionGeneralService } from 'src/app/informacion-general/informacion-general.service';
import { VpipFinancieroService } from './vpip-financiero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-vpip-financiero',
  templateUrl: './vpip-financiero.component.html',
  styleUrls: ['./vpip-financiero.component.sass']
})
export class VpipFinancieroComponent implements OnInit {

  vpipList: any = [];

  displayedColumns: any[];

  dataSource: MatTableDataSource<VpipIndexado>;

  vpipIndexadoSeleccionado: VpipIndexado;

  idProyecto: number;
  proyecto: any;

  idVpip: any;

  visibleAgregarVpipIndexado: boolean = false;
  visibleDialogoAdvertenciaFecha: boolean = false;
  visibleDialogoExito: boolean = false;
  visibleConfirmarEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarVpip: boolean = false;

  datosVpipIndexado: VpipIndexado = {};

  origenVpips: any = [];
  filtroVpip: any[];
  origenVpipSeleccionado: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private infoService: InformacionGeneralService, private vpipFinancieroService: VpipFinancieroService, private route: Router, private ruta: ActivatedRoute, private parametricasService: ParametricasService,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
      this.cargarVpip(this.idProyecto);
    });
    this.displayedColumns = [
      { field: 'nombreTipo', header: 'Tipo contraprestación' },
      { field: 'formatoValorContractual', header: 'Valor contractual' },
      { field: 'formatoFechaValorAcumulado', header: 'Fecha valor acumulado' },
      { field: 'formatoValorAcumulado', header: 'Valor acumulado' },
      { field: 'cumplimiento', header: '% Cumplimiento' }
    ];
    /* var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto); */
  }

  cargarOrigenVpip(event) {
    this.filtroVpip = [];
    return this.parametricasService.getOrigenVpip().subscribe((data: {}) => {
      this.origenVpips = data;
       let existeFiltro: boolean;
      for (let i = 0; i < this.origenVpips.length; i++) {
        let filtroOrigenVpip = this.origenVpips[i];
        let item: string = String(filtroOrigenVpip.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroVpip.push(filtroOrigenVpip);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.origenVpips = this.filtroVpip;
      }
    });
  }

  cargarFiltroOrigenVpip(event) {
    this.filtroVpip = [];
    for (let i = 0; i < this.origenVpips.length; i++) {
      let filtro = this.origenVpips[i];
     let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroVpip.push(filtro);
      }
    }
  }

  cargarVpip(id: number) {
    return this.vpipFinancieroService.getVpipIndexado(id).subscribe((data: {}) => {
      this.vpipList = data;
      this.dataSource = new MatTableDataSource(this.vpipList);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAgregarVpipIdexado() {
    this.visibleAgregarVpipIndexado = true;
    this.idVpip = "new";
    this.datosVpipIndexado = {};
    this.origenVpipSeleccionado = null;
  }

  cancelarAgregarVpipIdexado() {
    this.visibleAgregarVpipIndexado = false;
  }

  guardarVpipIndexado(forma: NgForm) {
   this.datosVpipIndexado.idTipo = this.origenVpipSeleccionado.id;
    if (this.idVpip === 'new') {
      this.datosVpipIndexado.idProyecto = this.idProyecto;
      let vara: any = this.vpipFinancieroService.guardarVpipIndexado(this.datosVpipIndexado).subscribe((data: {}) => {
        let respuesta: VpipIndexado = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
          this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.vpipFinancieroService.editarVpipIndexado(this.datosVpipIndexado, this.idVpip).subscribe((data: {}) => {
        let respuesta: VpipIndexado = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
          this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  aceptarDialogoAdvertenciaFecha() {
    this.visibleDialogoAdvertenciaFecha = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.cargarVpip(this.idProyecto);
    this.visibleAgregarVpipIndexado = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editarVpipIndexado(vpipIndexado: VpipIndexado) {
    this.vpipIndexadoSeleccionado = vpipIndexado;
    this.idVpip = this.vpipIndexadoSeleccionado.id;
    this.visibleAgregarVpipIndexado = true;
   return this.vpipFinancieroService.getVpipIndexadoPorId(this.vpipIndexadoSeleccionado.id).subscribe((data: {}) => {
      this.datosVpipIndexado = data;
      this.origenVpipSeleccionado = this.datosVpipIndexado.idTipoNavigation;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irEliminarVpipIndexado(vpipIndexado: VpipIndexado) {
    this.vpipIndexadoSeleccionado = vpipIndexado;
    this.visibleConfirmarEliminarRegistro = true;
    this.idVpip = this.vpipIndexadoSeleccionado.id;
  }

  cancelarEliminarVpipIndexado() {
    this.visibleConfirmarEliminarRegistro = false;
  }

  aceptarEliminarVpipIndexado() {
    return this.vpipFinancieroService.eliminarVpipIndexadoPorId(this.idVpip).subscribe((data: {}) => {
      this.cargarVpip(this.idProyecto);
      this.visibleDialogoExitoEliminarRegistro = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarVpipIndexada(){
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleConfirmarEliminarRegistro = false;
  }

}
