import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AudienciaDecisionService {

  public END_POINT = '';

    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public getTipoObligacion() {
    return this.http.get(this.END_POINT + '/ambiental/tipoObligacion')
      .pipe(
        retry(1));
  }

  public guardarDetalleAmbiental(data) {
    return this.http.put(this.END_POINT + '/ambiental/' , data)
      .pipe(
        retry(1));
  }

  public guardarNuevoAmbiental(data) {
    return this.http.post(this.END_POINT + '/ambiental/', data)
      .pipe(
        retry(1));
  }

  public getProyectosCarreteros() {
    return this.http.get(this.END_POINT + '/proyectoscarreteros')
      .pipe(
        retry(1));
  }

  public getProyectoAmbiental(id: number) {
    return this.http.get(this.END_POINT + '/ambiental/' + id)
      .pipe(
        retry(1));
  }


}
