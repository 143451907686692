import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PublicFerrocarrilesConsultaFicha5gService } from './public-ferrocarriles-consulta-ficha5g.service';
import { ProyectoEstructuracion } from '../../proyectoEstructuracion';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-public-ferrocarriles-consulta-ficha5g',
  templateUrl: './public-ferrocarriles-consulta-ficha5g.component.html',
  styleUrls: ['./public-ferrocarriles-consulta-ficha5g.component.sass']
})
export class PublicFerrocarrilesConsultaFicha5gComponent implements OnInit {
  displayedColumnsT1: any[];
  DatosTabla: any = [];  
  proyectoSeleccionado:ProyectoEstructuracion = new ProyectoEstructuracion(0,'',0,'','','',0,0,0,0,0,0,0,0,0,0,'',0,0,0,0,0,'','',0,'','','','','','','','','','',0,'',0,new Date(),new Date(),new Date());
  estadosProyectos: any=[];
  items: MenuItem[]=[];
  itemsPasos:MenuItem[]=[];
  activarPasos:number=1;
  blockedDocument = false;

  ngOnInit() {
    this.blockedDocument = true;  
  }

  //formato moneda en billones
  formatoMoneda(valor: number) {
    return valor? "$" + (valor/1000000000000).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " Bn" : "$0";
  } 
  
  //formato separador miles
  formatoNumeros(valor: number) {
    return valor? valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  }

  constructor(private publicFerrocarrilesConsultaFicha5gService: PublicFerrocarrilesConsultaFicha5gService, private messageService: MessageService, private principalComponent: PrincipalComponent) {
    publicFerrocarrilesConsultaFicha5gService.getDatosProyectos5GBD().subscribe((dato) =>{          
      if (dato != null && dato.respuestaServicio != null) {
        if (dato.respuestaServicio.codigoSalida === 1){         
          dato.proyectos.forEach(e => {
            if(!this.estadosProyectos.includes(e.estado)){
              if(e.estado != 'No Aplica'){
                this.estadosProyectos.push(e.estado);
                this.itemsPasos.push({label:e.estado});
              }    
              
            }
          });    
          this.itemsPasos.sort(function(a,b){
            if(a.label > b.label) { return -1; }
                if(a.label < b.label) { return 1; }
        return 0;
    
          });   
          this.CargarDatos(dato.proyectos.filter(p=>p.tipoModo==4  && (p.idOla==1 || p.idOla==2)))
        }
        else{         
          this.messageService.add({severity: 'error', summary: 'Error', detail: dato.respuestaServicio.mensajeSalida, life: 10000 });
        }
      }
    },
    error => {
      this.blockedDocument = false;
      console.error(error);
      principalComponent.cargarErrorServicio(error);
      
    },
    () => {
      this.blockedDocument = false;
    });
    }
   
  // Cargar los datos de los proyectos
  CargarDatos(proyectos: ProyectoEstructuracion[]){   
    this.activarPasos=1;
    proyectos.forEach( p => 
      this.items.push({label: p.nombre + " " + this.formatoMoneda(p.capex) , icon: 'pi pi-fw pi-plus', command:(event)=>{this.actualizarDatos(p)}})
     );

 

    this.displayedColumnsT1 = [
      { field: 'Caracteristica', header: 'Característica' },
      { field: 'Valor', header: 'Valor' }    
    ];
   
    this.actualizarDatos(proyectos[0]);
  }

  //Actualizar el dato seleccionado
  actualizarDatos(proyecto: ProyectoEstructuracion){


    this.proyectoSeleccionado=proyecto;  
    this.activarPasos=proyecto.id -1,    
    this.DatosTabla = [      
      { Caracteristica: 'Longitud Origen – Destino: ', Valor: proyecto.longitudOrigenDestino/1000  + " km"},
      { Caracteristica: 'Capex:', Valor:  this.formatoMoneda(proyecto.capex)},   
      { Caracteristica: 'Opex:', Valor:  this.formatoMoneda(proyecto.opex)},
      { Caracteristica: 'Longitud Operacion y Mantenimiento:', Valor: proyecto.longitudOperacionYMantenimiento},         
    ]; 

     //Activas estados del proyecto
     for(var i=0; i<this.itemsPasos.length; i++)
     {
         if(this.itemsPasos[i].label == proyecto.estado)
       {
          this.activarPasos=i;      
          i=this.itemsPasos.length;
       }
     }            
  };

  onNavigate(link) {    
    window.open(link, '_blank');    
  }
}