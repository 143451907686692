import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';


@Component({
  selector: 'app-datos-abiertos',
  templateUrl: './datos-abiertos.component.html',
  styleUrls: ['./datos-abiertos.component.sass']
})
export class DatosAbiertosComponent implements OnInit {

  items: MenuItem[]=[];
  visibleItem: Boolean[]=[true,false,false,false,false,false,false,false];

  constructor() { 
   }
  
  ngOnInit() {  
    
    this.items = [
      {
        id:'0',
        label: 'Avance Proyectos'
        , command:(event)=>{this.onSeleccionarOpcion(event)}
      },
      {
        id:'1',
        label: 'OCDS'
        , command:(event)=>{this.onSeleccionarOpcion(event)}
      },
      {
          
          label: 'SIG',
          items: [
            {id:'2',label: 'SIG Carreteras', command:(event)=>{this.onSeleccionarOpcion(event)}},
            {id:'3',label: 'SIG Puertos', command:(event)=>{this.onSeleccionarOpcion(event)}},
            {id:'4',label: 'SIG Aeropuertos', command:(event)=>{this.onSeleccionarOpcion(event)}},
            {id:'5',label: 'SIG Férreo', command:(event)=>{this.onSeleccionarOpcion(event)}},
            {id:'6',label: 'SIG Peajes', command:(event)=>{this.onSeleccionarOpcion(event)}},
            {id:'7',label: 'SIG Unidad Funcional',command:(event)=>{this.onSeleccionarOpcion(event)}},
        ]
      },
      {
        id:'8',
        label: 'datos.gov.co'
        , command:(event)=>{this.onNavegarDatosAbiertos()}
      }
  ];
       
  }

  // Navegar Datos Abiertos
  onNavegarDatosAbiertos(){
    window.open('https://datos.gov.co/browse?q=ani&sortBy=relevance', '_blank');   
  }


  onSeleccionarOpcion(event){  
   
    for(var i=0; i<this.visibleItem.length; i++){
      this.visibleItem[i]=false;
    }
    this.visibleItem[event.item.id] = true;    
  }
}
