import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatTableDataSource , MatPaginator} from '@angular/material';
import { TramiteAmbientalService } from './tramite-ambiental.service';
import { TAmbientales } from '../../../dtos/tramites-ambientales/proyecto-tramite';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'eliminar-dialog',
  templateUrl: '../eliminar/eliminar.html',
})
// tslint:disable-next-line:component-class-suffix
export class EliminiarDialog {


  confirmacion = false;

  constructor(
    public dialogRef: MatDialogRef<EliminiarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    }

  onNoClick(payload): void {
    // this.data.confirmacion = payload;
    this.dialogRef.close();
  }


  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
}

onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
}

}

@Component({
  selector: 'app-tramite-ambiental',
  templateUrl: './tramite-ambiental.component.html',
  styleUrls: ['./tramite-ambiental.component.sass'],
  providers: [TramiteAmbientalService]
})

export class TramiteAmbientalComponent implements OnInit {

  objectTipoCompromiso: any = [];
  objectConcesion: any = [];
  objectResponsable: any = [];
  objectEntidad: any = [];
  proyectos: any = [];
  priorizacion: any = [];
  id: number;
  nombreProyecto;
  estadoEliminar;
  displayedColumns: string[] = ['idCompromiso', 'priorizacion','radicado','compromiso', 'entidad', 'estado', 'fechaRegistro', 'acciones'];
  dataSource: MatTableDataSource<TAmbientales>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(
    private info: TramiteAmbientalService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog, private principalComponent: PrincipalComponent) {
  }

  ngOnInit() {
    this.cargarPriorizacion();
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarProyecto(this.id);
      localStorage.setItem('idP', JSON.stringify(this.id));
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onNew() {
    this.route.navigate([this.route.url+'/infTramitesADetalle', 'new']);
  }

  onEdit(id: number) {
    this.route.navigate([this.route.url+'/infTramitesADetalle', id]);
  }

  onFollow(id: number) {
    this.route.navigate([this.route.url+'/infSeguimientoAmbiental', id]);
  }

  getPrioridad(id) {
    if (id) {
      const dataLength = this.priorizacion.length;
      for (let i = 0; i <= dataLength; i++) {
        if (this.priorizacion[i].id === id) {
          return this.priorizacion[i].nombre;
        }
      }
    } else {
      return '';
    }
  }


  cargarProyecto(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.info.obtenerListaTramiteAmbiental(id).subscribe((data: {}) => {
      this.proyectos = data;
      this.dataSource = new MatTableDataSource(this.proyectos);
      this.dataSource.paginator = this.paginator;
      this.dialogSpin.close();
    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarPriorizacion() {
    return this.info.getPriorizacion().subscribe((data: {}) => {
      this.priorizacion = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  convertStringDateToTime(data) {
    const date = new Date(data);
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = date.getTime() + localOffset;
    return utc;
}

  openDialogEliminar(id): void {
    const dialogRef = this.dialog.open(EliminiarDialog, {
      height: '200px', width: '300px', data: {message: "¿Esta seguro de eliminar este registro?"}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result)  {
        this.info.eliminarProyecto(id).subscribe((res: {}) => {
          let dataTemp: any;
          dataTemp = res;
          if (dataTemp.tipo === 'ok') {
            this.cargarProyecto(this.id);
            alert(dataTemp.mensaje);
          } else {
            alert(dataTemp.mensaje);
          }
          this.dialogSpin.close();
        },error => {
          this.dialogSpin.close();
          this.principalComponent.cargarErrorServicio(error);
        });
      }
    });
  }
}
