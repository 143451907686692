import { Component, OnInit } from '@angular/core';
import { VwCmsComision } from '../../comisiones.dto';
import { Router } from '@angular/router';
import { PrincipalComponent } from '../../../home/principal/principal.component';
import { MessageService } from 'primeng/api';
import { ComisionesService } from '../../comisiones.service';
import { EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCmsMaTipoModificacionComision, EnumCmsConstantes } from '../../comisiones-constantes';

@Component({
  selector: 'app-comisiones-orden-pago-presupuestal-comisiones',
  templateUrl: './comisiones-orden-pago-presupuestal-comisiones.component.html',
  styleUrls: ['./comisiones-orden-pago-presupuestal-comisiones.component.sass']
})
export class ComisionesOrdenPagoPresupuestalComisionesComponent implements OnInit {

  solicitudList: VwCmsComision[];
  blockedDocument = true;

  constructor(private router: Router,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService,
    private comisionesService: ComisionesService,) { }

  ngOnInit() {
    this.cargarComisiones();
  }

  cargarComisiones() {
    this.comisionesService.srvConsultarComisionesParaPagar().subscribe(
      result => {
        if (result != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.solicitudList = result.comisiones;
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  irCargarPago(solicitud: VwCmsComision) {
    if (solicitud.idAsignacion != null) {
      this.guardarFechaInicioRevision(solicitud.idAsignacion);
    }

    this.router.navigate([this.router.url + "/comisiones-orden-pago-presupuestal-registro/" + solicitud.idComision + "/" + solicitud.idComisionVersion + '/' + solicitud.idAsignacion]);
  }

  guardarFechaInicioRevision(idAsignacion: number) {
    this.comisionesService.srvGuardarFechaInicioRevision(idAsignacion).subscribe(
      (result) => {
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            //  Consultar nuevamente la lista

            //  Lanzar mensaje de exito

          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  bntVolver() {
    const ruta = "/comisiones";
    this.router.navigate([ruta]);
  }


  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

}
