import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';
import { InformacionGeneralService } from '../informacion-general/informacion-general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proyecto-home-fluvial',
  templateUrl: './proyecto-home-fluvial.component.html',
  styleUrls: ['./proyecto-home-fluvial.component.sass']
})
export class ProyectoHomeFluvialComponent implements OnInit {

  proyecto: any;
  id: number;
  esProduccion: boolean = false;
  webUrlConcesiones : any;

  constructor(private infoService: InformacionGeneralService, private route: ActivatedRoute, private router: Router, @Inject(DOCUMENT) private document: any,
  private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.cargarProyectoFluvial(this.id);
    });
    localStorage.removeItem('idP');
    this.esProduccion = environment.esProduccion;
    this.webUrlConcesiones = environment.webUrlConcesiones;
  }

  cargarProyectoFluvial(id: number) {
    this.infoService.getProyectoFluvial(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irATramitesAmbientales(){
    this.router.navigate([this.router.url+'/tramites-fluvial-listado', this.id])
  }

}
