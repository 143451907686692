import { Component, OnInit } from '@angular/core';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-public-proyectos5g-ficha-sostenibilidad',
  templateUrl: './public-proyectos5g-ficha-sostenibilidad.component.html',
  styleUrls: ['./public-proyectos5g-ficha-sostenibilidad.component.sass']
})
export class PublicProyectos5gFichaSostenibilidadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
