import { TrnRespuestaServicio } from "../../parametricas/trn-respuesta-servicio";
import { PjeMaOperadorPagoElectronico } from "./PjeMaOperadorPagoElectronico";


export class PjePeajeOperadorPagoElectronicoDTORpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public peajeOperadorPagoElectronico?: PjePeajeOperadorPagoElectronicoDTO[],
    ) { }
}

export class PjePeajeOperadorPagoElectronicoDTO {
    constructor(
        public id?: number,
        public idPeaje?: number,        
        public fechaInicio?: Date,
        public horaInicio?: number,
        public minutoInicio?: number,
        public fechaFin?: Date,
        public horaFin?: number,
        public minutoFin?: number,        
        public fechaFinFormat?: string,
        public idOperador?: PjeMaOperadorPagoElectronico

    ) { }
}
