import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable()
export class CertificacionesLaboralesValidacionService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
      this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo que carga la info del radicado
     */
     public getInfoCertificado(radicado) {
        return this.http.get(this.END_POINT + '/ClbCertificadoLaboral/activos/validarCertificado/'+radicado)
        .pipe(
        retry(1));
    }

    /**
     * Metodo que carga la info del radicado
     */
     public getDescargarPdfInfoCertificado(radicado) {
        return this.http.get(this.END_POINT + '/ClbCertificadoLaboral/descargarRadicadoPdfActivoGeneral/'+radicado)
        .pipe(
        retry(1));
    }
    
}
