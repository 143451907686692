import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { forkJoin, Subscription } from "rxjs";
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { UnidadFuncionalCarreteroService } from "src/app/unidad-funcional-carretero/unidad-funcional-carretero.service";
import { PrediosService } from "../../predios.service";

@Component({
  selector: "app-gestionar-localizacion-predio",
  templateUrl: "./gestionar-localizacion-predio.component.html",
  styleUrls: ["./gestionar-localizacion-predio.component.sass"],
})
export class GestionarLocalizacionPredioComponent implements OnInit {
  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  idPredio: number;
  fichaPredial: string;
  esBorrador;

  constructor(
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private prediosService: PrediosService,
    private principalComponent: PrincipalComponent
  ) {}

  ngOnInit() {
    // Obtener parametros
    const idParam = "id";
    const idPredio = "idPredio";
    const borrador = "borrador";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.idPredio = params[idPredio];
      this.esBorrador = params[borrador];
      console.log("param idPredio-------->", idPredio);
    });

    this.inicializar();
  }

  inicializar() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.prediosService.srvObtenerPredioPorIdYBorrador(this.idPredio, this.esBorrador)
    ).subscribe(
      ([proyectoData, predioData]) => {
        this.proyectoSeleccionado = proyectoData;
        console.log("predioData", predioData);
        this.fichaPredial = predioData.predio.fichaPredial;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }
}
