import { Injectable, } from '@angular/core';
import { ConfigService } from '../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Generacion } from './generacion';
import { ProyectoCarretero } from './proyecto-carretero';
import { EnumTipoProyecto } from '../../comun/enum-tipo-proyecto';

@Injectable({
    providedIn: 'root'
})
export class YoutubeProyectoService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
        
    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
      }

      
  public getGeneraciones(): Observable<Generacion> {
    return this.http.get<Generacion>(environment.baseUrl + '/Generaciones/youtube', this.httpOptions)
      .pipe(
        retry(1));
  }

  
  public getProyectos(tipoProyecto: EnumTipoProyecto): Observable<ProyectoCarretero> {
    return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectos/youtube/' + tipoProyecto)
      .pipe(retry(1));
  }

 
  public getProyectosYoutube() {
    return this.http.get(environment.baseUrl + '/vdoVideo/playlistItem')
      .pipe(retry(1));
  }

}
