import { Component, Input, OnInit } from "@angular/core";
import * as datalocal from "src/assets/img/proyect.json";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import * as Highcharts from "highcharts/highstock";
import { formatDate } from "@angular/common";
import {
  InformacionTemasGestionMetasDTO,
  CrrTemasGestionMetasDTO,
  CrrInformacionProyectoDTO,
  CrrAvanceUfDTO,
  CrrAvanceProyectoDTO,
  CrrAvanceProyectoTiposIntervencionDTO,CrrTemasGestionMetasCsvDTO
} from "src/app/reportes/carreteros/reportes-carreteros.dto";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { ngxCsv } from 'ngx-csv/ngx-csv';


@Component({
  selector: "app-rpt-crr-proyecto-temasgestion",
  templateUrl: "./rpt-crr-proyecto-temasgestion.component.html",
  styleUrls: ["./rpt-crr-proyecto-temasgestion.component.sass"],
})
export class RptCrrProyectoTemasgestionComponent implements OnInit {
  blockedDocument = false;
  idProyecto: number;
  infoTemasGestion: CrrTemasGestionMetasDTO[];
  infoTemasGestionFiltro: CrrTemasGestionMetasDTO[];
  infoTemasGestionGroupBy: any = [];
  listTiposTemas: any = [];
  listTiposTemasArray: any = [];
  tipoTemaSeleccionado: any;
  infoTemasGestionFiltroCsv: CrrTemasGestionMetasCsvDTO [] = [];
  temasGestionCsv: CrrTemasGestionMetasCsvDTO;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent,
    private ruta: ActivatedRoute
  ) {}

  ngOnInit() {
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params.id;
    });

    this.blockedDocument = true;

    this.reportesCarreteroService
      .informacionTemasGestion(this.idProyecto)
      .subscribe(
        (dato) => {
          if (dato != null && dato.respuestaServicio != null) {
            if (dato.respuestaServicio.codigoSalida === 1) {
              this.infoTemasGestion = dato.infoTemasGestion;
              this.infoTemasGestionFiltro = this.infoTemasGestion;

              this.infoTemasGestionGroupBy = this.infoTemasGestion.forEach(
                (element) => {
                  const found = this.listTiposTemas.find(
                    (find) => find.tipoTema === element.tipoTema
                  );

                  if (found == null) {
                    this.listTiposTemas.push(element);
                  }
                }
              );
              console.log("this.listTiposTemas");
              console.log(this.listTiposTemas);
              this.listTiposTemasArray = this.listTiposTemas.map((element) => ({
                id: element.idTipoTema,
                tipoTema: element.tipoTema,
              }));
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: dato.respuestaServicio.mensajeSalida,
                life: 10000,
              });
            }
          }
        },
        (error) => {
          this.blockedDocument = false;
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {}
      );
  }

  actualizarTemasGestion(event) {

    if (this.tipoTemaSeleccionado == null) {
      this.infoTemasGestionFiltro = this.infoTemasGestion;
    } else {
      this.infoTemasGestionFiltro = this.infoTemasGestion.filter(
        (element) => element.idTipoTema === this.tipoTemaSeleccionado.id
      );
    }

  }


  exportCsvTemasGestionFiltrados() {
    this.infoTemasGestionFiltroCsv = [] ;
    this.infoTemasGestionFiltro.forEach((element) => {
      this.temasGestionCsv = new CrrTemasGestionMetasCsvDTO();
      this.temasGestionCsv.nombreProyecto = element.nombreProyecto;
      this.temasGestionCsv.fechaInicio = formatDate(element.fechaInicio, "dd/MM/yyyy", "es-ES");

      this.temasGestionCsv.tipoTema = element.tipoTema;
      if(element.descripcionCorta != null && element.descripcionCorta!="" ){
        this.temasGestionCsv.descripcionCorta = element.descripcionCorta;
      }else{
        this.temasGestionCsv.descripcionCorta = "Sin información";
      }
      if(element.actuacion != null && element.actuacion!="" ){
        this.temasGestionCsv.actuacion = element.actuacion;
      }else{
        this.temasGestionCsv.actuacion ="Sin información";
      }

      if(element.accionesASeguir != null && element.accionesASeguir!=""){
        this.temasGestionCsv.accionesASeguir = element.accionesASeguir;
      }else{
        this.temasGestionCsv.accionesASeguir ="Sin información";
      }
      this.infoTemasGestionFiltroCsv.push(this.temasGestionCsv);
    });

    
    var options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: [ "Proyecto", "Fecha Inicio", "Tipo Tema", "Descripcion","Actuacion","AccionASeguir"]
    };
   
   

    


    new ngxCsv(this.infoTemasGestionFiltroCsv, 'temasGestion' + '_export_' + new Date().getTime(), options);
  }


}
