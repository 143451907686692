import { Component, OnInit, ViewChild } from '@angular/core';
import { ContratoCreditoService } from './contrato-credito.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ContratoCreditoIndexado } from './ContratoCreditoIndexado';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { NgForm } from '@angular/forms';
import { ContratoCreditoCarreteroService } from 'src/app/contrato-credito-carretero/contrato-credito-carretero.service';
import { ContratoCredito } from 'src/app/contrato-credito-carretero/contratoCredito';
import { ContratoCreditoBaseComponent } from '../contrato-credito-base/contrato-credito-base.component';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-contrato-credito',
  templateUrl: './contrato-credito.component.html',
  styleUrls: ['./contrato-credito.component.sass']
})
export class ContratoCreditoComponent implements OnInit {

  contratoCreditoList: any = [];

  displayedColumns: any[];

  dataSource: MatTableDataSource<ContratoCreditoIndexado>;

  contratoCreditoIndexadoSeleccionado: ContratoCreditoIndexado

  idProyecto: number;
  proyecto: any;

  idContratoCredito: any;

  visibleAgregarContratoCreditoIndexado: boolean = false;
  visibleDialogoAdvertenciaFecha: boolean = false;
  visibleDialogoExito: boolean = false;
  visibleConfirmarEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarContratoCredito: boolean = false;

  datosContratoCreditoIndexado: ContratoCreditoIndexado = {};

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  habilitaCrear: boolean = false;

  constructor(private contratoCreditoBase: ContratoCreditoBaseComponent, private infoService: InformacionGeneralService, private infoContratoCredito: ContratoCreditoService,
    private contratoCreditoCarreteroService: ContratoCreditoCarreteroService, private route: Router, private ruta: ActivatedRoute, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
      this.cargarContratoCredito(this.idProyecto);
    });
    this.displayedColumns = [
      { field: 'formatoFecha', header: 'Fecha de referencia' },
      { field: 'formatoValorPesos', header: 'Valor en pesos' },
      { field: 'formatoValorDolares', header: 'Valor en dolares' }
    ];
    /* var nombreProyecto = document.getElementById("nombre_proyecto");
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto);         */
  }

  cargarContratoCredito(id: number) {
    return this.infoContratoCredito.getContratoCreditoIndexado(id).subscribe((data: {}) => {
      this.contratoCreditoList = data;
      this.dataSource = new MatTableDataSource(this.contratoCreditoList);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
      this.contratoCreditoBase.nombreProyecto = this.proyecto.nombre;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    })
  }

  irAgregarContratoCreditoIdexado() {
    this.visibleAgregarContratoCreditoIndexado = true;
    this.idContratoCredito = "new";
    this.datosContratoCreditoIndexado = {};
  }

  cancelarAgregarContratoCreditoIdexado() {
    this.visibleAgregarContratoCreditoIndexado = false;
  }

  guardarInformacionContratoCreditoIndexado(forma: NgForm) {
    if (this.idContratoCredito === 'new') {
      this.datosContratoCreditoIndexado.idProyecto = this.idProyecto;
      let vara: any = this.infoContratoCredito.guardarContratoCreditoIndexado(this.datosContratoCreditoIndexado).subscribe((data: {}) => {
        let respuesta: ContratoCreditoIndexado = data;
        if (respuesta.mensajeError == "401") {
          this.visibleDialogoAdvertenciaFecha = true;
        }
        if (respuesta.mensajeError == "200") {
          this.visibleDialogoExito = true;
        }
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.infoContratoCredito.editarContratoCreditoIndexado(this.datosContratoCreditoIndexado, this.idContratoCredito).subscribe((data: {}) => {
        let respuesta: ContratoCreditoIndexado = data;
        this.contratoCreditoBase.visibleContratoCreditoIndexado = true;
        if (respuesta.mensajeError == "401") {
          this.visibleDialogoAdvertenciaFecha = true;
        }
        if (respuesta.mensajeError == "200") {
          this.visibleDialogoExito = true;
        }
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  aceptarDialogoAdvertenciaFecha() {
    this.visibleDialogoAdvertenciaFecha = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.cargarContratoCredito(this.idProyecto);
    this.visibleAgregarContratoCreditoIndexado = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editarContratoCreditoIndexado(contratoCreditoIndexado: ContratoCreditoIndexado) {
    this.contratoCreditoIndexadoSeleccionado = contratoCreditoIndexado;
    this.idContratoCredito = this.contratoCreditoIndexadoSeleccionado.id;
    this.visibleAgregarContratoCreditoIndexado = true;
    return this.infoContratoCredito.getContratoCreditoIndexadoPorId(this.contratoCreditoIndexadoSeleccionado.id).subscribe((data: {}) => {
      this.datosContratoCreditoIndexado = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irEliminarContratoCreditoIndexado(contratoCreditoIndexado: ContratoCreditoIndexado) {
    this.contratoCreditoIndexadoSeleccionado = contratoCreditoIndexado;
    this.visibleConfirmarEliminarRegistro = true;
    this.idContratoCredito = this.contratoCreditoIndexadoSeleccionado.id;
  }

  cancelarEliminarContratoCreditoIndexado() {
    this.visibleConfirmarEliminarRegistro = false;
  }

  aceptarEliminarContratoCreditoIndexado() {
    return this.infoContratoCredito.eliminarContratoCreditoIndexadoPorId(this.idContratoCredito).subscribe((data: {}) => {
      this.cargarContratoCredito(this.idProyecto);
      this.visibleDialogoExitoEliminarRegistro = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarContratoCreditoIndexada() {
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleConfirmarEliminarRegistro = false;
  }

}
