import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PredialDetalleService {

    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

  constructor(private config: ConfigService, private http: HttpClient) {
  }


  public guardarDetallePredial(data) {
    return this.http.put( environment.baseUrl + '/Predial/' + data.concesion + '/unidades/' + data.unidadFuncional, data)
      .pipe(
        retry(1));
  }

  public guardarNuevoPredial(data) {
    return this.http.post(environment.baseUrl+ '/Generaciones/', data)
      .pipe(
        retry(1));
  }

}
