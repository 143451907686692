import { Component, OnInit, Input } from '@angular/core';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoContratoDTO } from '../../../../contratacion-dto/contrato';
import { ContratacionBorradorService } from '../../contratacion-brr.service';
import { ContratoBaseComponent } from '../contrato-base/contrato-base.component';
import { CtoContatoObligacionBorradorDTO } from '../../../../contratacion-dto/cto-contrato-obligacion-borrador';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';

@Component({
  selector: 'app-contrato-obligacio-brr',
  templateUrl: './contrato-obligacio-brr.component.html',
  styles: []
})
export class ContratoObligacioBrrComponent implements OnInit {

  @Input()
  contratoObligacion: CtoContratoDTO;

  obligacionesPorContrato: CtoContatoObligacionBorradorDTO[];
  contratoObligacionSeleccionado: CtoContatoObligacionBorradorDTO = {};
  colsObligacion: any[];
  visibleDialogoConfirmarEliminarRegistro = false;
  visualizarDialogObligacion = false;

  constructor(
    private lineaBase: ContratoBaseComponent,
    private contratacionBorradorService: ContratacionBorradorService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    if (this.contratoObligacion != null && this.contratoObligacion.esBorrador) {
      this.cargarObligacionesContrato();
    }

    // Columnas del table de Obligaciones
    this.colsObligacion = [
      { field: 'obligacion', header: 'Obligación' }
    ];
  }

  cargarObligacionesContrato() {
    if (this.contratoObligacion != null && this.contratoObligacion.id != null) {
      this.contratacionBorradorService.ConsultarContratoObligacionBorradorPorContratoBorrador(this.contratoObligacion.id).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.obligacionesPorContrato = result.obligacionesPorContrato;
              this.lineaBase.blockedDocument = false;
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }


        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.lineaBase.blockedDocument = false;
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
    }
  }

  cancelarAgregarObligacion() {
    this.visualizarDialogObligacion = false;
    this.contratoObligacionSeleccionado = {};
  }

  guardarObligacionesContrato() {
    this.visualizarDialogObligacion = false;
    this.lineaBase.blockedDocument = true;
    if (this.contratoObligacion != null && this.contratoObligacionSeleccionado != null) {
      this.contratoObligacionSeleccionado.idContrato = this.contratoObligacion.id;

      this.contratacionBorradorService.GuardarContratoObligacionBorrador(this.contratoObligacionSeleccionado).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.contratoObligacionSeleccionado = {};
              this.lineaBase.blockedDocument = false;
              this.lineaBase.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.contratoObligacionSeleccionado = {};
        },
        () => {
          this.cargarObligacionesContrato();
        }
      );
    }
  }

  aceptarEliminarObligacionContrato() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoConfirmarEliminarRegistro = false;
    if (this.contratoObligacionSeleccionado != null) {

      this.contratacionBorradorService.EliminarContratoObligacionBorrador(this.contratoObligacionSeleccionado.id).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo("El registro se eliminó exitosamente.")
              this.contratoObligacionSeleccionado = {};
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.visibleDialogoConfirmarEliminarRegistro = false;
          this.lineaBase.blockedDocument = false;
          this.ngOnInit();
        }
      );
    }
  }
  // Eliminacion - Cancelar
  cancelarEliminarObligacionContrato() {
    this.visibleDialogoConfirmarEliminarRegistro = false;
    this.contratoObligacionSeleccionado = {};
  }

  irEliminarObligacionContrato(obligacion: CtoContatoObligacionBorradorDTO) {
    this.contratoObligacionSeleccionado = obligacion;
    this.visibleDialogoConfirmarEliminarRegistro = true;
  }

  irEditarObligacionContrato(obligacion: CtoContatoObligacionBorradorDTO) {
    this.contratoObligacionSeleccionado = obligacion;
    this.visualizarDialogObligacion = true;
  }
}
