import { Component, OnInit } from '@angular/core';
import { ProyectoDTO, PeriodicidadDTO, PlaneacionDTO } from '../planeacion-proyecto.dto';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaneacionProyectoService } from '../planeacion_proyecto.service';

@Component({
  selector: 'app-crear-planeacion',
  templateUrl: './crear-planeacion.component.html',
  styleUrls: ['./crear-planeacion.component.sass']
})
export class CrearPlaneacionComponent implements OnInit {

  public planeacionProyectoDTO: PlaneacionDTO = {};
  public proyectoDTOList: ProyectoDTO[];
  public proyectoDTOSelectedList: ProyectoDTO[];
  public periodicidadDTOList: PeriodicidadDTO[];
  public periodicidadDTO: PeriodicidadDTO = {};

  constructor(private route: Router, private ruta: ActivatedRoute,
    private planeacionProyectoService: PlaneacionProyectoService) { }

  ngOnInit() {
    this.listarProyectos();
    this.listarPeriodicidad();
  }

  listarProyectos() {
    return this.planeacionProyectoService.listarProyectos().subscribe(data => {
      this.proyectoDTOList = data;
    });
  }

  listarPeriodicidad() {
    return this.planeacionProyectoService.listarPediodicidad().subscribe(data => {
      this.periodicidadDTOList = data;
      this.periodicidadDTOList.unshift({});
    });
  }

  crearPlaneacion() {
 }

}
