import { Component, OnInit } from '@angular/core';
import { InformeRevisionService } from '../../../informe-revision.service';
import { MessageService } from 'primeng/api';
import { InformeCxpBaseComponent } from '../informe-cxp-base/informe-cxp-base.component';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { EnumRespuestaServicio } from '../../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoInformeRevisionActividadDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-actividad';

@Component({
  selector: 'app-informe-cxp-obligaciones',
  templateUrl: './informe-cxp-obligaciones.component.html',
  styles: []
})
export class InformeCxpObligacionesComponent implements OnInit {

  actividadesInformeRevision: CtoInformeRevisionActividadDTO[];
  adjuntosActividadesInforme: CtoAdjuntoInformeBorradorDTO[];
  visibleDialogoVerAdjuntos: boolean;

  constructor(
    private lineaBase: InformeCxpBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarActividades(this.lineaBase.idInformeParam, this.lineaBase.idPersonaAsignacionParam, this.lineaBase.idPasoParam);
  }

  cargarActividades(idInforme: number, idPersonaAsignacion: number, idPaso: number) {
    this.lineaBase.blockedDocument = true;
    this.infCtaSupervisionService.ConsultarActividadesInformeRevision(idInforme, idPersonaAsignacion, idPaso)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.actividadesInformeRevision = result.actividadesInformeRevision;
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  irVerDialogoVerAdjuntosAnexos(idActvidad: number) {
    this.visibleDialogoVerAdjuntos = true;
    this.ConsultarAdjuntosPorActividad(idActvidad, this.lineaBase.idPersonaAsignacionParam);
  }

  ConsultarAdjuntosPorActividad(idActvidad: number, idPersonaAsignacion: number) {
    this.infCtaSupervisionService.ConsultarAdjuntosPorActividadInformeRevision(idActvidad, idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosActividadesInforme = result.adjuntos;
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

}


