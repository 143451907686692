import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { ReportesCuentasCobroCxpService } from '../reporte-contratacion-cuentas-cobro.service';

import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { CtoReporteCxpDevolucionesTesoreriaDTO, CtoReporteCxpDevolucionesTesoreriaFiltroDTO } from '../../contratacion-dto/cto-reporte-cxp-devoluciones-tesoreria.dto';

@Component({
  selector: 'app-cto-reporte-cxp-devoluciones-tesoreria',
  templateUrl: './cto-reporte-cxp-devoluciones-tesoreria.component.html',
  styles: []
})
export class CtoReporteCxpDevolucionesTesoreriaComponent implements OnInit {

  // Informacion del datatable
  reporteCxpDevoluciones: CtoReporteCxpDevolucionesTesoreriaDTO[] = [];
  totalRecords: number;
  loading: boolean;

  //Filtros
  reporteCxpDevolucionesFiltro: CtoReporteCxpDevolucionesTesoreriaFiltroDTO;

  formReporteCxpDevolucionesFiltro;

  DATATABLE_CANTIDAD_REGISTROS = 10;
  DATATABLE_ASCDESC = 1;//1=asc, !=1 desc
  DATATABLE_CAMPO_ORDEN = 'idContrato';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private datepipe: DatePipe,
    private principalComponent: PrincipalComponent,
    private reportesContratoCuentasCobroService: ReportesCuentasCobroCxpService,
    private parametricasService: ParametricasService
  ) {

    this.formReporteCxpDevolucionesFiltro = formBuilder.group({
      formfechaInicioFiltro: [null, null],
      formfechaFinFiltro: [null, null]
    });
  }

  /**
   * Metodo que incializa invoca el listado de parametricas
   */
  ngOnInit() {
    this.reporteCxpDevolucionesFiltro = {};
    this.inicializarVariablesFiltros();
  }

  /**
   * Metodo utilizado para la paginación y el uso de filtros
   * @param event 
   */
  loadDataLazy(event: LazyLoadEvent) {
    if (this.formReporteCxpDevolucionesFiltro.valid) {
      this.resetearPaginacionVariablesFiltros();
      this.cargarCuentasCobroDevolucionesTesoreria(event.first, event.rows, ((event.sortField === null || event.sortField === undefined) ? this.reporteCxpDevolucionesFiltro.campoOrden : event.sortField), ((event.sortOrder === null || event.sortOrder === undefined) ? this.reporteCxpDevolucionesFiltro.ascDesc : event.sortOrder));
    }
  }

  /**
   * Metodo consultar la información del datatable de acuerdo a los filtros definidos   
   */
  buscar() {
    if (this.validarFormularioFiltros()) {
      // Validar filtros ya que el disabled se puede cambiar
      this.resetearPaginacionVariablesFiltros();
      this.cargarCuentasCobroDevolucionesTesoreria(this.reporteCxpDevolucionesFiltro.filaDesde, this.reporteCxpDevolucionesFiltro.cantidadRegistros, this.reporteCxpDevolucionesFiltro.campoOrden, this.reporteCxpDevolucionesFiltro.ascDesc);
    }
  }

  /**
   * Metodo para inciializar las variables del modelo usadas para los filtros selecionados
   */
  inicializarVariablesFiltros() {
    this.reporteCxpDevolucionesFiltro.fechaInicioFiltro = null;
    this.reporteCxpDevolucionesFiltro.fechaFinFiltro = null;

    this.resetearPaginacionVariablesFiltros();
  }

  /**
   * Metodo para inciializar las variables del modelo usadas para la paginación del Datatable 
   */
  resetearPaginacionVariablesFiltros() {
    this.reporteCxpDevolucionesFiltro.filaDesde = 0;
    this.reporteCxpDevolucionesFiltro.cantidadRegistros = this.DATATABLE_CANTIDAD_REGISTROS;
    this.reporteCxpDevolucionesFiltro.buscar = null;
    this.reporteCxpDevolucionesFiltro.campoOrden = this.DATATABLE_CAMPO_ORDEN;
    this.reporteCxpDevolucionesFiltro.ascDesc = this.DATATABLE_ASCDESC;
  }

  /**
   * Metodo que valida que el formulario de filtros este correctamente diligenciado
   * @returns Verdado o Falso
   */
  validarFormularioFiltros() {
    let formularioValido = true;
    if (!this.formReporteCxpDevolucionesFiltro.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Para realizar la búsqueda debe indicar alguno de los filtros");
    }
    //Fecha Devolucion
    if ((this.reporteCxpDevolucionesFiltro.fechaInicioFiltro != null && this.reporteCxpDevolucionesFiltro.fechaFinFiltro == null)
      || (this.reporteCxpDevolucionesFiltro.fechaInicioFiltro == null && this.reporteCxpDevolucionesFiltro.fechaFinFiltro != null)) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha Devolución: Debe seleccionar el rango de fechas");
    }
    if (this.reporteCxpDevolucionesFiltro.fechaInicioFiltro != null && this.reporteCxpDevolucionesFiltro.fechaFinFiltro != null
      && this.reporteCxpDevolucionesFiltro.fechaInicioFiltro > this.reporteCxpDevolucionesFiltro.fechaFinFiltro) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha Devolución: La fecha inicio no puede ser mayor a la fecha fin");
    }
    return formularioValido;
  }


  /**
   * Metodo utilizado para limpiar los campos del formulario
   */
  limpiarFiltros() {
    this.inicializarVariablesFiltros();
    this.formReporteCxpDevolucionesFiltro.controls.formfechaInicioFiltro.clearValidators();
    this.formReporteCxpDevolucionesFiltro.controls.formfechaFinFiltro.clearValidators();
    this.formReporteCxpDevolucionesFiltro.reset();

    this.cargarCuentasCobroDevolucionesTesoreria(this.reporteCxpDevolucionesFiltro.filaDesde, this.reporteCxpDevolucionesFiltro.cantidadRegistros, this.reporteCxpDevolucionesFiltro.campoOrden, this.reporteCxpDevolucionesFiltro.ascDesc);
  }

  /**
     * Metodo que maneja el evento de selección de @formfechaInicioFiltro se obliga a selesccionar la fecha @formfechaFinFiltro
     */
  onSelectFormFechaInicioFiltro() {
    if (this.reporteCxpDevolucionesFiltro.fechaInicioFiltro == null) {
      this.formReporteCxpDevolucionesFiltro.controls.formfechaFinFiltro.clearValidators();
    } else {
      this.formReporteCxpDevolucionesFiltro.controls.formfechaFinFiltro.setValidators([Validators.required]);
    }
    this.formReporteCxpDevolucionesFiltro.controls.formfechaFinFiltro.updateValueAndValidity();
  }

  /**
   * Metodo que invoca el servicio para consultar las cuentas de cobro con devoluciones desde tesorería
   * @param filaDesde Valor Opcional >0 para consultar desde una fila en particular
   * @param cantidadRegistros Valor Opcional >0 para consultar X cantidad de registros
   * @param buscar Valor Opcional para consultar X dato dentro de la informacíon, se tiene definido en BD
   * @param campoOrden Valor Opcional para ordenar la información, se tiene definido en BD
   * @param ascDesc Valor Opcional númerico para ordenar los valores del listado a consultar de forma ascendente o descente
   */
  private cargarCuentasCobroDevolucionesTesoreria(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.loading = true;
    this.reporteCxpDevoluciones = [];

    this.reporteCxpDevolucionesFiltro.filaDesde = filaDesde;
    this.reporteCxpDevolucionesFiltro.cantidadRegistros = cantidadRegistros;
    this.reporteCxpDevolucionesFiltro.buscar = null;
    this.reporteCxpDevolucionesFiltro.campoOrden = campoOrden;
    this.reporteCxpDevolucionesFiltro.ascDesc = ascDesc;

    this.reportesContratoCuentasCobroService.srvConsultarReporteCuentasCobroCxpDevolucionesTesoreria(
      this.reporteCxpDevolucionesFiltro.fechaInicioFiltro,
      this.reporteCxpDevolucionesFiltro.fechaFinFiltro,
      this.reporteCxpDevolucionesFiltro.filaDesde,
      this.reporteCxpDevolucionesFiltro.cantidadRegistros,
      this.reporteCxpDevolucionesFiltro.buscar,
      this.reporteCxpDevolucionesFiltro.campoOrden,
      this.reporteCxpDevolucionesFiltro.ascDesc
    ).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.reporteCxpDevoluciones = result.reporteCxpDevolucionesTesoreria;
            this.totalRecords = this.reporteCxpDevoluciones != null && this.reporteCxpDevoluciones[0] != null ? this.reporteCxpDevoluciones[0].totalRegistros : 0;
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.loading = false;
      }
    );
  }


  /**
   * Metodo utilizado para invocar la funcionalidad de exportar excel la información de devoluciones
   */
  exportExcel() {
    this.loading = true;

    this.reportesContratoCuentasCobroService.srvConsultarReporteCuentasCobroCxpDevolucionesTesoreria(
      this.reporteCxpDevolucionesFiltro.fechaInicioFiltro,
      this.reporteCxpDevolucionesFiltro.fechaFinFiltro,
      null,//Para ser expotado en Excel se requieren todos los registros consultados, por eso se deja null
      null,//Para ser expotado en Excel se requieren todos los registros consultados, por eso se deja null
      this.reporteCxpDevolucionesFiltro.buscar,
      this.reporteCxpDevolucionesFiltro.campoOrden,
      this.reporteCxpDevolucionesFiltro.ascDesc
    ).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            const reporteCxpDevolucionesExcel = result.reporteCxpDevolucionesTesoreria;
            // check array does not exist, is not an array, or is empty
            if (!Array.isArray(reporteCxpDevolucionesExcel) || !reporteCxpDevolucionesExcel.length) {
              this.lanzarMensajeError("No hay registros suficientes para exportar en Excel");
              return;
            }
            //flatten objects
            const rows = reporteCxpDevolucionesExcel.map(row => ({
              consecutivoTurno: row.consecutivoTurno,
              fechaInicioPlanPago: this.datepipe.transform(row.fechaInicioPago, 'dd/MM/yyyy'),
              fechaFinPlanPago: this.datepipe.transform(row.fechaFinPago, 'dd/MM/yyyy'),
              numeroContrato: row.numeroContrato,
              nombreContratista: row.nombreContratista,
              ultimoPasoInforme: row.ultimoPasoInforme,
              numeroObligacionPago: row.numeroObligacionPago,
              fechaDevolucion: this.datepipe.transform(row.fechaDevolucion, 'dd/MM/yyyy HH:mm:ss'),
              observacionesDevolucion: row.observacionesDevolucion
            }));

            //generate worksheet and workbook
            import('xlsx')
              .then(xlsx => {
                const headings = [
                  [
                    "Turno",
                    "Fecha Inicio Plan Pago",
                    "Fecha Fin Plan Pago",
                    "No. Contrato",
                    "Contratista",
                    "Estado Actual",
                    "Número Registro prespuestal de obligación",
                    "Fecha Devolución",
                    "Oservaciones",
                  ]];
                const worksheet = xlsx.utils.json_to_sheet(rows);
                /* fix headers */
                xlsx.utils.sheet_add_aoa(worksheet, headings);
                const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                this.saveAsExcelFile(excelBuffer, 'ctoReporteCxpCuentasDevolucionesTesoreria');
              });

          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.loading = false;
      }
    );

  }

  /**
   * Metodo utilizado para descargar el archivo en formato xlsx generado
   * @param buffer 
   * @param fileName Nombre del archivo descargado
   */
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  /**
   * Metodo que redirige a la pagina principal de reportes de central de pagos
   */
  bntVolver() {
    const ruta = "/contratacionSupervisionHome/reportesCuentasCobroCxpHome";
    this.router.navigate([ruta]);
  }

  /**
  * Lanza mensaje de informacion
  */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
