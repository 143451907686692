import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TrnPersona } from '../../admin-personas/trn-persona';
import { Data } from '@angular/router';
import { PrsDatosAdicionalesPersona } from 'src/app/admin-personas/prs-datos-adicionales-persona';
import { PrsFuncionario } from 'src/app/admin-personas/prs-funcionario';
import { PrsEducacionRpt, PrsEducacion } from '../../admin-personas/prs-educacion';
import { PrsNivelEducativo } from 'src/app/admin-personas/prs-nivel-educativo';
import { PrsProgamaAcademico } from '../../admin-personas/prs-programa-academico';


@Injectable({
    providedIn: 'root'
})
export class PersonaSeguimientoService {

    public END_POINT = '';


    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public getTiposDocumento() {
        return this.http.get(this.END_POINT + '/TrnMaTipoDocumentoes')
            .pipe(
                retry(1),
                catchError(this.handleError));
    }

    public getPersonas() {
        return this.http.get(this.END_POINT + '/SgdPersona')
            .pipe(retry(1), catchError(this.handleError));
    }

    public getPersona(idPersona) {
        return this.http.get(this.END_POINT + '/SgdPersona/' + idPersona)
            .pipe(retry(1), catchError(this.handleError));
    }

    public setPersona(persona) {
        return this.http.post(environment.baseUrl + '/SgdPersona', JSON.stringify(persona), this.httpOptions);
    }

    public getPersonasJurdicas() {
        return this.http.get(this.END_POINT + '/SgdPersonaJuridica')
            .pipe(retry(1),
                catchError(this.handleError));
    }

    public getPersonaJurdicaPorId(idPersona) {
        return this.http.get(this.END_POINT + '/SgdPersonaJuridica/' + idPersona)
            .pipe(retry(1), catchError(this.handleError));
    }

    public setPersonaJuridica(persona) {
        return this.http.post(environment.baseUrl + '/SgdPersonaJuridica/GuardarPersonaJuridica',
            JSON.stringify(persona), this.httpOptions);
    }


   
    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }


}
