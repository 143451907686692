import { Component, OnInit } from '@angular/core';
import { InformeSpaBaseComponent } from '../informe-spa-base/informe-spa-base.component';
import { InformeRevisionService } from '../../../informe-revision.service';
import { MessageService } from 'primeng/api';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoInformeRevisionAsignacionDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-asignacion';
import { CtoInformeRevisionEstadoPersonaAprobacionDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-aprobacion';

@Component({
  selector: 'app-informe-spa-aprobacion',
  templateUrl: './informe-spa-aprobacion.component.html',
  styles: []
})
export class InformeSpaAprobacionComponent implements OnInit {

  estadoPersonasInformeRevision: CtoInformeRevisionEstadoPersonaAprobacionDTO[];
  totalPaginator: any;
  rutaCloudStorageCertificadoPago: string;

  visualizarDialogObservacionRechazo = false;
  visibleDialogoConfirmarAprobarInforme = false;

  cantidadMaximaAdjuntos = 3;
  adjuntosComponente: any = [];
  adjuntosCertificadoObligacionPago: CtoAdjuntoInformeBorradorDTO[] = [];
  visibleDialogoVerAdjuntos = false;
  visibleDialogoSubirCertificadoObligacionPago = false;

  constructor(
    private lineaBase: InformeSpaBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarAsignacionInformeRevision(this.lineaBase.idAsignacionParam, this.lineaBase.idPasoInformeParam, this.lineaBase.idPersonaAsignacionParam);
    this.cargarCertificadoPago(this.lineaBase.idInformeParam);
    this.lineaBase.informeContratista.fechaInicioPago = new Date(this.lineaBase.informeContratista.fechaInicioPago);
    this.rutaCloudStorageCertificadoPago = this.lineaBase.informeContratista.vigencia + '/'
      + this.lineaBase.informeContratista.idContrato + '_' + this.lineaBase.informeContratista.numeroContrato + '/'
      + this.lineaBase.informeContratista.numeroDocumento + '/'
      + this.lineaBase.informeContratista.numeroPago + '/anexos/';
  }

  cargarAsignacionInformeRevision(idAsignacion: number, idPasoInforme: number, idPersonaAsignacion: number) {
    this.infCtaSupervisionService.ConsultarAsignacionInformeRevision(idAsignacion, idPasoInforme, idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.lineaBase.asignacionInforme = result.asignacionInforme;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }


  irVerDialogoSubirAdjuntos() {

    if (this.adjuntosCertificadoObligacionPago == null || (this.adjuntosCertificadoObligacionPago != null && this.adjuntosCertificadoObligacionPago.length < this.cantidadMaximaAdjuntos)) {
      this.visibleDialogoSubirCertificadoObligacionPago = true;
      this.adjuntosComponente = [];
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Elimina algun adjunto para continuar. Recuerda que son máximo ' + this.cantidadMaximaAdjuntos + ' adjuntos', life: 10000 });
    }

  }

  irVerDialogoVerAdjuntos() {
    this.visibleDialogoVerAdjuntos = true;
  }

  verDialogoEliminarAdjunto(adj: CtoAdjuntoInformeBorradorDTO) {

    if (adj != null) {
      if (adj.id != null && adj.id != 0) {
        // Adjunto guardado en BD
        return this.infCtaSupervisionService.EliminarAdjuntoInforme(adj.id)
          .subscribe(
            result => {
              if (result != null && result != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.adjuntosCertificadoObligacionPago = this.adjuntosCertificadoObligacionPago.filter(item => item.rutaSoporte != adj.rutaSoporte);
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              }
            },
            error => {
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
              this.ngOnInit();
              this.lineaBase.blockedDocument = false;
            }
          );
      } else {
        // Adjunto guardado en memoria
        this.adjuntosCertificadoObligacionPago = this.adjuntosCertificadoObligacionPago.filter(item => item.rutaSoporte != adj.rutaSoporte);
      }
    }
  }


  cancelarDialogoVerAdjunto() {
    this.visibleDialogoVerAdjuntos = false;
    this.adjuntosComponente = [];
  }

  aceptarAdjuntoCertificadoObligacionPago() {
    const adjuntosList: CtoAdjuntoInformeBorradorDTO[] = [];
    this.adjuntosComponente.forEach(arc => {
      const adj: CtoAdjuntoInformeBorradorDTO = {};
      adj.idInforme = this.lineaBase.idInformeParam;
      adj.nombreArchivoUsuario = arc.nombre;
      adj.rutaSoporte = arc.nombreCompleto;
      adjuntosList.push(adj);
    });

    const tamanioFinalAdjuntos: number = adjuntosList.length + (this.adjuntosCertificadoObligacionPago != null ? this.adjuntosCertificadoObligacionPago.length : 0);
    if (tamanioFinalAdjuntos > this.cantidadMaximaAdjuntos) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Solo se permiten ' + this.cantidadMaximaAdjuntos + ' archivos máximo.', life: 10000 });
    } else {
      adjuntosList.forEach(adj => {
        this.adjuntosCertificadoObligacionPago.push(adj);
      });
    }
    this.visibleDialogoSubirCertificadoObligacionPago = false;
  }

  cancelarAdjuntoCertificadoObligacionPago() {
    this.visibleDialogoSubirCertificadoObligacionPago = false;
    this.adjuntosComponente = [];
  }

  cargarCertificadoPago(idInforme: number) {
    this.infCtaSupervisionService.ConsultarCertificadoPago(idInforme)
      .subscribe(
        result => {
          // console.log('ConsultarCertificadoPago');
          // console.log(JSON.stringify(result));
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              if (result.adjuntos != null) {
                this.adjuntosCertificadoObligacionPago = result.adjuntos;
              }
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  guardarCertificadoPago() {
    this.lineaBase.blockedDocument = true;
    if (this.lineaBase.asignacionInforme != null) {
      this.infCtaSupervisionService.GuardarCertificadoPago(this.lineaBase.idInformeParam, this.adjuntosCertificadoObligacionPago)
        .subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              }
            } else {
              console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.lineaBase.blockedDocument = false;
            this.cargarCertificadoPago(this.lineaBase.idInformeParam);
          }
        );
    }
  }

  aprobarRechazarInformeRevision(aprobo: boolean) {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoConfirmarAprobarInforme = false;
    if (this.lineaBase.asignacionInforme != null) {
      this.lineaBase.asignacionInforme.aprobo = aprobo;
      if (this.validarAprobarRechazarInformeRevision()) {
        this.infCtaSupervisionService.AprobarRechazarInformeRevision(this.lineaBase.idInformeParam, this.lineaBase.idPasoParam, this.lineaBase.idVersionInformeParam, this.lineaBase.asignacionInforme)
          .subscribe(
            result => {
              if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                  this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                  this.lineaBase.mensajeDialogo = result.respuestaServicio.mensajeSalida;
                  this.lineaBase.visibleDialogoExito = true;
                } else {
                  this.lineaBase.mensajeDialogo = result.respuestaServicio.mensajeSalida;
                  this.lineaBase.visibleDialogoError = true;
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
              } else {
                console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
              }
            },
            error => {
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
              this.lineaBase.blockedDocument = false;
              this.ngOnInit();
            }
          );
      } else {
        this.lineaBase.blockedDocument = false;
      }
    }
  }

  validarAprobarRechazarInformeRevision(): boolean {
    let accionValida = true;
    //Rechazó
    if (!this.lineaBase.asignacionInforme.aprobo) {
      if (this.lineaBase.asignacionInforme.observacionDevolucion == null) {
        accionValida = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.lineaBase.msgValObservacionRechazo, life: 10000 });
        return accionValida;
      }
      if (this.lineaBase.asignacionInforme.observacionDevolucion != null &&
        (this.lineaBase.asignacionInforme.observacionDevolucion == "" || this.lineaBase.asignacionInforme.observacionDevolucion.length == 0 || this.lineaBase.asignacionInforme.observacionDevolucion.length < 10)) {
        accionValida = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.lineaBase.msgValObservacionRechazoMinimo, life: 10000 });
        return accionValida;
      }
    } else {
      //Aprobó
      if (this.lineaBase.asignacionInforme.aprobo) {
        if (this.adjuntosCertificadoObligacionPago == null) {
          accionValida = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.lineaBase.msgValAdjuntarCertificadoPago, life: 10000 });
        }
        if (this.adjuntosCertificadoObligacionPago != null && this.adjuntosCertificadoObligacionPago.length == 0) {
          accionValida = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.lineaBase.msgValAdjuntarCertificadoPago, life: 10000 });
        }
      }
    }
    return accionValida;
  }

  guardarAsignacionObservacion(asignacion: CtoInformeRevisionAsignacionDTO) {
    this.lineaBase.blockedDocument = true;
    this.visualizarDialogObservacionRechazo = false;
    this.infCtaSupervisionService.GuardarAsignacionObservacionInformeRevision(asignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              this.aprobarRechazarInformeRevision(false);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
          this.lineaBase.blockedDocument = false;
          this.ngOnInit();
        },
        () => {
          // this.lineaBase.blockedDocument = false;
          // this.ngOnInit();
        }
      );
  }

  cancelarRechazarCuenta() {
    this.visualizarDialogObservacionRechazo = false;
    this.lineaBase.cargarAsignacionInformeRevision(this.lineaBase.idAsignacionParam, this.lineaBase.idPasoInformeParam, this.lineaBase.idPersonaAsignacionParam);

  }
}
