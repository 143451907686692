import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-reporte-contrato-base',
  templateUrl: './reporte-contrato-base.component.html',
  styles: []
})
export class ReporteContratoBaseComponent implements OnInit {

  idContratoParam: number;
  ctoContratoInformacionVigenteBase: CtoContratoInformacionVigenteEncabezadoDTO;
  blockedDocument = false;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.idContratoParam = params.idContrato;
    });
  }

  actualizarContratoInformacionVigente(contrato: CtoContratoInformacionVigenteEncabezadoDTO) {
    this.ctoContratoInformacionVigenteBase = contrato;
    this.ctoContratoInformacionVigenteBase.fechainicio = (new Date(this.ctoContratoInformacionVigenteBase.fechainicio));
    this.ctoContratoInformacionVigenteBase.fechaTerminacionContrato = (new Date(this.ctoContratoInformacionVigenteBase.fechaTerminacionContrato));
  }

}
