import { Component, OnInit, } from '@angular/core';

@Component({
    selector: 's-youtube-home',
    templateUrl: './youtube-home.component.html',
    styleUrls: ['./youtube-home.component.css']
})
export class YoutubeHomeComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}
