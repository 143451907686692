import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-public-carreteras-consulta',
  templateUrl: './public-carreteras-consulta.component.html'
})
export class PublicCarreterasConsultaComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
  }

  reportes(){
    this.route.navigate(['aeropuertos-public/consulta']);
  }
 
}
