import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InformacionGeneralService } from '../../../informacion-general/informacion-general.service';
import { DetalleRecaudoPeajesService } from './detalle-recaudo-peajes.service';
import { forkJoin } from 'rxjs';
import { PjeDetallePeriodoDTO } from '../PjeDetallePeriodo';
import { PjeEncabezadoPeriodoDTO } from '../PjeEncabezadoPeriodo';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { EnumPjeConstantes } from '../../peajes-constantes';


@Component({
    selector: 'app-detalle-recaudo-peajes',
    templateUrl: './detalle-recaudo-peajes.component.html',
    styleUrls: ['./detalle-recaudo-peajes.component.sass']
})

export class DetalleRecaudoPeajesComponent implements OnInit {

    idProyecto: number;
    proyecto: any;
    nombreProyecto: string;
    idPeriodo: number;
    blockedDocument = false;

    // variables detalle periodo
    detallePeriodoPeaje: PjeDetallePeriodoDTO[] = [];
    detalleEdita: PjeDetallePeriodoDTO;

    // variables encabezado periodo
    encabezadoGet: any = [];
    encabezadoActual: PjeEncabezadoPeriodoDTO;
    TPDConExentos = 0;
    TPDSinExentos = 0;

    // Variables enviar a revisión
    visibleConfirmarEnviarRevisionPeriodo = false;

    // Variables publicar
    visibleConfirmarPublicarPeriodo = false;
    visibleDialogoExito = false;
    visibleRecaudoAdvertencia = false;
    mensajeAdvertencia: string;
    visibleConfirmarRechazarReporte = false;

    // Variable borrador
    visibleCancelarBorrador = false;

    // Variables tabs
    indiceTab: number;

    // variables detalle periodo sin recaudo
    detallePeriodoSinRecaudoPeaje: PjeDetallePeriodoDTO[] = [];
    displayedColumnsSinRecaudo: any[];
    detalleSinRecaudoEdita: PjeDetallePeriodoDTO;
    visibleError;

    esErrorGeneral = false;


    mensajeAccionReporte: string;


    constructor(private router: Router, private route: ActivatedRoute, private messageService: MessageService, private principalComponent: PrincipalComponent,
        private infoService: InformacionGeneralService, private detalleRecaudoService: DetalleRecaudoPeajesService) { }

    ngOnInit() {
        this.encabezadoActual = new PjeEncabezadoPeriodoDTO();
        this.route.params.subscribe(params => {
            this.idProyecto = params.id;
            this.idPeriodo = params.idPeriodo;
        });

        this.cargarProyectoCarretero(this.idProyecto);
        this.cargarDetallePeriodo();
        this.indiceTab = 0;

    }

    onTabChange(event) {
        const tab: number = event.index;
        if (tab === 1) {
            if (this.encabezadoActual.autorizaTraficoSinRecaudo) {
                this.cargaDetallePeriodoSinRecaudo();
            }
        }
    }

    /**
     * Metodo que llama al servicio de consulta de proyecto dado su id
     * @param id IdProyecto a consultar
     */
    cargarProyectoCarretero(id: number) {
        this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
            this.proyecto = data;
            this.nombreProyecto = this.proyecto.nombre;
        }, error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
    }

    /**
     * Metodo que llama al servicio de consulta del encabezado de un repoirte dado el idPeriodo
     */
    cargarEncabezadoPeriodo() {
        this.encabezadoActual = new PjeEncabezadoPeriodoDTO();
        this.blockedDocument = true;

        forkJoin(
            this.detalleRecaudoService.cargaEncabezadoPeriodo(this.idPeriodo)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    // recarga tabla según proyecto seleccionado
                    this.encabezadoGet = result.encabezadoPeriodo;
                    this.encabezadoActual = this.encabezadoGet;
                    if (this.encabezadoActual != null) {
                        if (this.encabezadoActual.fechaDesde != null) {
                            this.encabezadoActual.fechaDesde = new Date(this.encabezadoActual.fechaDesde);
                        }
                        if (this.encabezadoActual.fechaHasta != null) {
                            this.encabezadoActual.fechaHasta = new Date(this.encabezadoActual.fechaHasta);
                        }
                        if (this.encabezadoActual.tpdsinExentos != null) {
                            this.TPDSinExentos = this.encabezadoActual.tpdsinExentos;
                        }
                        if (this.encabezadoActual.tpdconExentos != null) {
                            this.TPDConExentos = this.encabezadoActual.tpdconExentos;
                        }
                    }
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    cargaDetallePeriodoSinRecaudo() {
        // Bloquear pantalla
        this.blockedDocument = true;
        forkJoin(
            this.detalleRecaudoService.cargaDetallePeriodoSinRecaudo(this.idPeriodo)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.detallePeriodoSinRecaudoPeaje = result.detallePeriodo;
                    if (!Array.isArray(this.detallePeriodoSinRecaudoPeaje) || !this.detallePeriodoSinRecaudoPeaje.length) {
                        this.detallePeriodoSinRecaudoPeaje.forEach(tarifa => {
                            if (tarifa.fechaDesde != null) {
                                tarifa.fechaDesde = new Date(tarifa.fechaDesde);
                            }
                            if (tarifa.fechaHasta != null) {
                                tarifa.fechaHasta = new Date(tarifa.fechaHasta);
                            }
                        });
                    }

                    // consulta encabezado
                    this.cargarEncabezadoPeriodo();
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    cargarDetallePeriodo() {
        this.blockedDocument = true;
        forkJoin(
            this.detalleRecaudoService.cargaDetallePeriodo(this.idPeriodo)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.detallePeriodoPeaje = result.detallePeriodo;
                    if (!Array.isArray(this.detallePeriodoPeaje) || !this.detallePeriodoPeaje.length) {
                        this.detallePeriodoPeaje.forEach(tarifa => {
                            if (tarifa.fechaDesde != null) {
                                tarifa.fechaDesde = new Date(tarifa.fechaDesde);
                            }
                            if (tarifa.fechaHasta != null) {
                                tarifa.fechaHasta = new Date(tarifa.fechaHasta);
                            }
                        });
                    }
                    // consulta encabezado
                    this.cargarEncabezadoPeriodo();
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    /**
     * Metodo que valida el Trafico por categoria sin recaudo
     * @param recaudo PjeDetallePeriodo
     * @returns Registro valido
     */
    validaTraficoSinRecaudoPorCategoria(recaudo: PjeDetallePeriodoDTO) {
        let esValido = true;
        if (recaudo.trafico == null || recaudo.trafico < 0) {
            esValido = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tráfico debe ser mayor a 0', life: 10000 });
        }
        return esValido;
    }

    /**
     * Metodo que guarda el Tráfico por categoria, sin recaudo    
     * @param recaudo PjeDetallePeriodo
     */
    guardaTraficoSinRecaudo(recaudo: PjeDetallePeriodoDTO) {

        if (this.validaTraficoSinRecaudoPorCategoria(recaudo)) {
            this.blockedDocument = true;
            this.detalleEdita = new PjeDetallePeriodoDTO();
            this.detalleEdita = recaudo;
            this.guardarEncabezadoPeriodo();

            forkJoin(
                this.detalleRecaudoService.editaDetallePeriodoFilaSinRecaudo(this.detalleEdita)
            ).subscribe(([result]) => {
                if (result != null && result.respuestaServicio != null) {
                    if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                        this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                        this.cargaDetallePeriodoSinRecaudo();
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                    }
                }
            },
                error => {
                    this.principalComponent.cargarErrorServicio(error);
                    this.blockedDocument = false;
                },
                () => {
                    // Desbloquear pantalla
                    this.blockedDocument = false;
                }
            );
        }

    }

    /**
     * Metodo que valida el Trafico por categoria
     * @param recaudo PjeDetallePeriodo
     * @returns Registro valido
     */
    validaTraaficoPorCategoria(recaudo: PjeDetallePeriodoDTO) {
        let esValido = true;
        if (recaudo.tipoTarifa === EnumPjeConstantes.PJE_TIPO_TARIFA_PLENA) {
            if (recaudo.trafico == null || recaudo.trafico < 0) {
                esValido = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tráfico debe ser mayor a 0', life: 10000 });
            }
            if (recaudo.traficoEvasores == null || recaudo.traficoEvasores < 0) {
                esValido = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tráfico evasores debe ser mayor a 0', life: 10000 });
            }
            if (recaudo.traficoExentos == null || recaudo.traficoExentos < 0) {
                esValido = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tráfico exentos debe ser mayor a 0', life: 10000 });
            }
        } else {
            if (recaudo.trafico == null || recaudo.trafico < 0) {
                esValido = false;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El tráfico debe ser mayor a 0', life: 10000 });
            }
        }
        return esValido;
    }



    /**
     * Metodo que llama el servicio de guardar recaudo por cada tarifa 
     * @param recaudo PjeDetallePeriodo
     */
    guardarRecaudo(recaudo: PjeDetallePeriodoDTO) {
        if (this.validaTraaficoPorCategoria(recaudo)) {
            // Bloquear pantalla
            this.blockedDocument = true;
            this.detalleEdita = new PjeDetallePeriodoDTO();
            this.detalleEdita = recaudo;
            this.guardarBorrador();

            forkJoin(
                this.detalleRecaudoService.editaDetallePeriodoFila(this.detalleEdita)
            ).subscribe(([result]) => {
                if (result != null && result.respuestaServicio != null) {
                    if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                        this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                        this.cargarDetallePeriodo();
                    } else {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                    }
                }
            },
                error => {
                    this.principalComponent.cargarErrorServicio(error);
                    this.blockedDocument = false;
                },
                () => {
                    // Desbloquear pantalla
                    this.blockedDocument = false;
                }
            );
        }
    }

    /**
     * Metodo que cambia el estado del reporte y llama al servidio de guardarEncabezado
     */
    guardarBorrador() {
        if (this.encabezadoActual.idEstado === EnumPjeConstantes.PJE_ESTADO_REPORTE_TRAFICO_SIN_INICIAR) {
            this.encabezadoActual.idEstado = EnumPjeConstantes.PJE_ESTADO_REPORTE_TRAFICO_EN_BORRADOR;
            this.encabezadoActual.autorizaTraficoSinRecaudo = false;
        }
        this.guardarEncabezadoPeriodo();
    }

    /**
     * Metodo que llama al servicio de edición del encabezado del periodo
     */
    guardarEncabezadoPeriodo() {
        this.blockedDocument = true;
        forkJoin(
            this.detalleRecaudoService.editaEncabezadoPeriodo(this.encabezadoActual)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.cargarDetallePeriodo();
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.visibleDialogoExito = false;
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }


    irRechazarReporteRecaudo() {
        this.visibleConfirmarRechazarReporte = true;
    }

    rechazarReporteRecaudo() {
        this.encabezadoActual.idEstado = EnumPjeConstantes.PJE_ESTADO_REPORTE_TRAFICO_EN_BORRADOR;
        this.visibleConfirmarRechazarReporte = false;
        this.guardarEncabezadoPeriodo();
        this.mensajeAccionReporte = 'Se rechazó el reporte exitosamente';
        this.visibleDialogoExito = true;
    }



    publicarRecaudo() {
        this.guardarEncabezadoPeriodo();
        if (this.encabezadoActual.ingresoNeto > 0 && this.encabezadoActual.recaudoFsv <= 0) {
            this.visibleError = true;
        }
        else {
            this.visibleConfirmarPublicarPeriodo = true;
            if (this.encabezadoActual.ingresoNeto === 0) {
                this.visibleRecaudoAdvertencia = true;
                this.mensajeAdvertencia = 'El ingreso Neto es igual a 0, por favor verifique el recaudo que desea reportar. Una vez publicado no podrá realizar cambios.';
            } else if (this.encabezadoActual.ingresoNeto < 0) {
                this.visibleRecaudoAdvertencia = true;
                this.mensajeAdvertencia = 'El ingreso Neto es negativo, por favor verifique el recaudo que desea reportar. Una vez publicado no podrá realizar cambios.';
            }
        }
    }

    aceptarError() {
        this.visibleError = false;
    }

    cancelarBorrador() {
        this.visibleCancelarBorrador = true;
    }

    aceptarCancelarBorrador() {
        this.visibleCancelarBorrador = false;
        this.blockedDocument = true;
        forkJoin(
            this.detalleRecaudoService.cancelaRecaudo(this.encabezadoActual)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                    this.mensajeAccionReporte = 'Se canceló el borrador exitosamente';
                    this.visibleDialogoExito = true;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }
    cancelarCancelarBorrador() {
        this.visibleCancelarBorrador = false;
    }

    /**
    * Metodo que publica el reporte y llama a guardar el cambio de estado    
    */
    aceptarPublicarPeriodo() {
        this.encabezadoActual.idEstado = EnumPjeConstantes.PJE_ESTADO_REPORTE_TRAFICO_PUBLICADO;
        this.visibleConfirmarPublicarPeriodo = false;
        this.visibleRecaudoAdvertencia = false;
        this.mensajeAccionReporte = 'Publicación Exitosa';
        this.guardarEncabezadoPeriodo();
        this.visibleDialogoExito = true;
    }

    cancelarPublicarPeriodo() {
        this.visibleConfirmarPublicarPeriodo = false;
    }
    cancelarPublicarPeriodoAdvertencia() {
        this.visibleRecaudoAdvertencia = false;
    }

    aceptarDialogoExito() {
        this.visibleDialogoExito = false;
        this.volver();
    }

    /**
     * Metodo que valida los valoes ingresados para habilitar el envio del reporte a revisión
     */
    irEnviarARevisionRecaudo() {
        this.guardarEncabezadoPeriodo();
        if (this.encabezadoActual.ingresoNeto > 0 && this.encabezadoActual.recaudoFsv <= 0) {
            this.visibleError = true;
        }
        else {
            this.visibleConfirmarEnviarRevisionPeriodo = true;
            if (this.encabezadoActual.ingresoNeto === 0) {
                this.visibleRecaudoAdvertencia = true;
                this.mensajeAdvertencia = 'El ingreso Neto es igual a 0, por favor verifique el recaudo que desea reportar. Una vez enviado a revisión no podrá realizar cambios.';
            } else if (this.encabezadoActual.ingresoNeto < 0) {
                this.visibleRecaudoAdvertencia = true;
                this.mensajeAdvertencia = 'El ingreso Neto es negativo, por favor verifique el recaudo que desea reportar. Una vez enviado a revisión no podrá realizar cambios.';
            }
        }
    }

    /**
     * Metodo que envia el reporte a revisión y llama a guardar el cambio de estado    
     */
    enviarARevisionRecaudo() {
        this.encabezadoActual.idEstado = EnumPjeConstantes.PJE_ESTADO_REPORTE_TRAFICO_ENVIADO_A_REVISION;
        this.encabezadoActual.idProyecto = this.idProyecto;
        this.visibleConfirmarEnviarRevisionPeriodo = false;
        this.visibleRecaudoAdvertencia = false;
        this.mensajeAccionReporte = 'Se envió a revisión exitosamente';
        this.guardarEncabezadoPeriodo();
        this.visibleDialogoExito = true;
    }



    volver() {
        this.router.navigate(['/carreteros/infGeneral/proyectoHome/' + this.idProyecto + '/menu-peajes/' + this.idProyecto + '/recaudo-peajes/', this.idProyecto]);
    }

    get EnumPjeConstantes() {
        return EnumPjeConstantes;
    }
}

