import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { MenusService } from '../../menus.service';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { interval } from 'rxjs';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { SgrMessageError } from 'src/app/parametricas/sgr-message-error';
import { DialogModule } from 'primeng/dialog';
import { HttpClient } from '@angular/common/http';
import { AppInsightsService } from 'src/app/app-insights.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.sass']
})

export class PrincipalComponent implements OnInit {

  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  public nombreUsuario: string = "";
  public timeStampExpiration: string = "";;
  private isAuthenticated: boolean;
  private errorServicio: SgrMessageError = {};
  visibleDialogoErrorServicio = false;
  redirigirHome = false;
  blockedDocument = false;

  menuItems: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    public route: Router,
    public _location: Location,
    private adalSvc: MsAdalAngular6Service,
    private activatedRoute: ActivatedRoute,
    private menusvc: MenusService,
    private appInsightsService: AppInsightsService) {

    if (this.adalSvc.userInfo != null) {
      this.nombreUsuario = this.adalSvc.userInfo.profile.name;
    }
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[PrincipalComponent.ROUTE_DATA_BREADCRUMB];
      if (!isNullOrUndefined(label)) {
        url = url.replace('#', '')
        breadcrumbs.push({ label, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  ngOnInit() {
    this.isAuthenticated = this.adalSvc.isAuthenticated;
    if (this.adalSvc.isAuthenticated) {
      this.timeStampExpiration = this.adalSvc.userInfo ? this.adalSvc.userInfo.profile.exp : null;
      let dataTimeExpiration = this.timeStampExpiration ? Number(String(this.timeStampExpiration) + '000') : null;
      const source = interval(300000);
      source.subscribe(val => {
        if (this.getTimerTime(dataTimeExpiration) <= 600000) {
          this.adalSvc.RenewToken('api://ae41e71b-79ec-463f-9a2f-04b9fefd4eef');
          this.adalSvc.acquireToken('api://ae41e71b-79ec-463f-9a2f-04b9fefd4eef')
            .subscribe((token: string) => {
              sessionStorage.setItem('authtoken', token);
            })
        }
      });

      if (sessionStorage.getItem('paginas') == null) {
        this.menusvc.getMenus().subscribe((data: {}) => {
          sessionStorage.setItem('paginas', JSON.stringify(data));
          window.location.reload();
        },
          error => {
            this.cargarErrorServicio(error);
          });
      }
      var element = document.getElementById("aniscopio_body");
      element.className = "ani public logeo";
    } else {
      var element = document.getElementById("aniscopio_body");
      element.className = "ani public";
    }
    this.route.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.menuItems = this.createBreadcrumbs(this.activatedRoute.root)
    });
  }


  menulateral() {
    var element = document.getElementById("menu_lateral");
    element.classList.toggle("close");
  }
  irAMapas(): void {
    this.document.location = "https://sig.ani.gov.co/mapasinterno/";
  }
  atras() {
    this._location.back()
  }
  ayuda() {
    this.document.location = "https://www.ani.gov.co/videoguias";
    //this.document.location = "https://anionline.sharepoint.com/Gestion%20VPRE/Sistemas/Sitio_ANIscopio/ArqANIscopio/Documentos%20compartidos/Forms/AllItems.aspx?id=%2FGestion%20VPRE%2FSistemas%2FSitio%5FANIscopio%2FArqANIscopio%2FDocumentos%20compartidos%2FAplicacionTransiccion%2FManual%20Ficha%20Proyecto%2Epdf&parent=%2FGestion%20VPRE%2FSistemas%2FSitio%5FANIscopio%2FArqANIscopio%2FDocumentos%20compartidos%2FAplicacionTransiccion&p=true&cid=78011530-f7a6-46ef-9c90-4a73b5240538";
  }
  cerrarSesion() {
    sessionStorage.clear();
    this.adalSvc.logout();
  }
  iniciarSesion() {
    this.adalSvc.login();
  }

  getTimerTime(payload) {
    let dateAfter = payload;
    let dateNow = new Date().getTime();
    let resultTimer = dateAfter - dateNow;
    return resultTimer;
  }

  cargarErrorServicio(errorServicio) {
    // this.blockedDocument = false;    
    this.visibleDialogoErrorServicio = true;
    this.redirigirHome = false;
    this.appInsightsService.logException(errorServicio);
    if (errorServicio.error instanceof ErrorEvent) {
      this.errorServicio = errorServicio.error;
    } else {
      if (errorServicio.error != null && errorServicio.error.message != undefined
        && errorServicio.error.logEvento != undefined) {
        this.errorServicio = errorServicio.error;

      } else {
        if (errorServicio.status == 401) {
          this.redirigirHome = true;
          this.errorServicio.message = EnumRespuestaServicio.ERROR_OPERACION + '. ' + "Usuario no autorizado o tiempo de inactividad superado, por favor vuelva a ingresar";
          this.errorServicio.logEvento = "Usuario no autorizado o tiempo de inactividad superado, por favor vuelva a ingresar";
        } else {
          this.errorServicio.message = EnumRespuestaServicio.ERROR_OPERACION + '. ' + EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO;
          this.errorServicio.logEvento = EnumRespuestaServicio.ERROR_VALIDAR_ADMINISTRADOR;
        }
      }
    }
  }

  aceptarDialogoErrorServicio() {
    this.visibleDialogoErrorServicio = false;
    // console.log("this.redirigirHome:" + this.redirigirHome);
    if (this.redirigirHome) {
      this.ngOnInit();
      this.route.navigate(['/']);
    }
  }

  display: boolean = false;
  displayModal: boolean;
  showDialog() {
    this.display = true;
  }
  showModalDialog() {
    this.displayModal = true;
    var element = document.getElementById("aniscopio_body");
    element.classList.add("modalsuper");
  }
  noShowModalDialog() {
    this.displayModal = false;
    var element = document.getElementById("aniscopio_body");
    element.classList.remove("modalsuper");
  }

}
