import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Contratista } from 'src/app/concesionario-carretero/contratista';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { forkJoin } from 'rxjs';
import { ContratoContratistaCarreteroBaseComponent } from '../contrato-contratista-carretero-base/contrato-contratista-carretero-base.component';
import { MessageService } from 'primeng/api';
import { InformacionContractualCarreteroService } from '../../informacion-contractual-carretero.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContratistaCarretero, ContratoContratista } from '../../informacion-contractual-carretero.dto';
import { Departamento } from 'src/app/parametricas/departamento';
import { Ciudad } from 'src/app/parametricas/ciudad';
import { AccionistaContratistaEncabezado } from 'src/app/concesionario-carretero/accionista-contratista-encabezado';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-contratista-carretero',
  templateUrl: './contratista-carretero.component.html',
  styleUrls: ['./contratista-carretero.component.sass']
})
export class ContratistaCarreteroComponent implements OnInit {

  blockedDocument: boolean = false;
  contratista: ContratistaCarretero = {};

  departamentos: any = [];
  filtroDepartamentos: any[];
  departamentoSeleccionado: any;

  ciudades: any = [];
  filtroCiudades: any[];
  ciudadSeleccionada: any;

  existeContratista: boolean;
  composicionAccionariaLista: any = [];
  visibleDialogoAgregarComposicion: boolean = false;
  accionistaContratistaEncabezado: AccionistaContratistaEncabezado = {};
  fechaInicioAccionistasEncabezado: Date;
  visibleConfirmarEliminarComposicion: boolean;
  visibleDialogoExitoEliminarComposicion: boolean;

  filtroPersonaJuridicaLista: any[];
  personasJuridicaLista: any = [];
  personaJuridicaSeleccionado: any;


  constructor(private lineaBase: ContratoContratistaCarreteroBaseComponent, private informacionContractualCarreteroService: InformacionContractualCarreteroService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private parametricasService: ParametricasService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.contratista = {};
    this.personaJuridicaSeleccionado = {};    
    if (this.lineaBase.idContratista != 0) {
      this.cargarContratista();
    }
  }

  cargarPersonasJuridicas(event) {
    this.filtroPersonaJuridicaLista = [];
    forkJoin(
      this.parametricasService.getPersonasJuridicas()
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.personasJuridicaLista = result.accionistas;
          let existeFiltro: boolean;
          for (let i = 0; i < this.personasJuridicaLista.length; i++) {
            let filtro = this.personasJuridicaLista[i];
            let item: string = String(filtro.nombre);
            if (item.toLowerCase().includes(event.query.toLowerCase())) {
              this.filtroPersonaJuridicaLista.push(filtro);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.personasJuridicaLista = this.filtroPersonaJuridicaLista;
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  onSelectPersonasJuridicas(event) {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.consultarContratistaPorId(this.personaJuridicaSeleccionado.id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.contratista = result.contratista;
          this.departamentoSeleccionado = this.contratista.idDepartamentoUbicacionNavigation;
          this.ciudadSeleccionada = this.contratista.idMunicipioUbicacionNavigation;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarContratista() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.consultarContratistaPorId(this.lineaBase.idContratista)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.personaJuridicaSeleccionado = result.contratista;
          this.departamentoSeleccionado = this.personaJuridicaSeleccionado.idDepartamentoUbicacionNavigation;
          this.ciudadSeleccionada = this.personaJuridicaSeleccionado.idMunicipioUbicacionNavigation;
          this.existeContratista = true;
          this.cargarAccionistasEncabezado();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarAccionistasEncabezado() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.listarAccionistasEncabezadoPorIdContratista(this.lineaBase.idContratista)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.composicionAccionariaLista = result.accionistasEncabezado;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }



  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.departamentos.length; i++) {
        let filtro = this.departamentos[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDepartamentos.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.departamentos = this.filtroDepartamentos;
      }
    });
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      let filtro = this.departamentos[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  limpiarCiudades(event) {
    this.ciudadSeleccionada = null;
  }

  cargarCiudades(event) {
    this.filtroCiudades = [];
    return this.parametricasService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id).subscribe((data: {}) => {
      this.ciudades = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.ciudades.length; i++) {
        let filtro = this.ciudades[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroCiudades.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.ciudades = this.filtroCiudades;
      }
    });
  }

  cargarFiltroCiudades(event) {
    this.filtroCiudades = [];
    for (let i = 0; i < this.ciudades.length; i++) {
      let filtro = this.ciudades[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudades.push(filtro);
      }
    }
  }


  guardarPersonaJuridicaContrato(forma: NgForm) {
    if (this.personaJuridicaSeleccionado.id != null) {
      this.blockedDocument = true;
      let contratoContratista: ContratoContratista = {};
      contratoContratista.idContratista = this.personaJuridicaSeleccionado.id;
      this.lineaBase.idContratista = contratoContratista.idContratista;      
      contratoContratista.idContrato = this.lineaBase.contrato.id;
      contratoContratista.fechaInicio = this.lineaBase.contrato.fechaInicio;
      contratoContratista.anios = this.lineaBase.contrato.anios;
      contratoContratista.meses = this.lineaBase.contrato.meses;
      this.guardarContratoContratista(contratoContratista);
    }else{
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Se debe seleccionar una persona juridica", life: 10000 });
    }
  }


  guardarDetalleContratista(forma: NgForm) {
    this.blockedDocument = true;
    this.contratista.idDepartamento = this.departamentoSeleccionado.id;
    this.contratista.idMunicipioUbicacion = this.ciudadSeleccionada.id;
    this.contratista.anios = this.lineaBase.contrato.anios;
    this.contratista.meses = this.lineaBase.contrato.meses;
    this.contratista.fechaInicio = this.lineaBase.contrato.fechaInicio;
    forkJoin(
      this.informacionContractualCarreteroService.guardarContratista(this.contratista)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {

        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          let contratoContratista: ContratoContratista = {};
          contratoContratista.idContratista = result.contratista.id;
          this.lineaBase.idContratista = contratoContratista.idContratista;
          contratoContratista.idContrato = this.lineaBase.contrato.id;
          contratoContratista.fechaInicio = result.contratista.fechaInicio;
          contratoContratista.fechaFin = result.contratista.fechaFin;
          this.guardarContratoContratista(contratoContratista);
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El contratista se guardó exitosamente.', life: 10000 });
          this.existeContratista = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  guardarContratoContratista(contratoContratista: ContratoContratista) {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.guardarContratoContratista(contratoContratista)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.existeContratista = true;
          this.lineaBase.idContratista = contratoContratista.idContratista;
          this.cargarAccionistasEncabezado();
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El contratista se guardó exitosamente.', life: 10000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }


  visibleAgregarComposicion() {
    this.visibleDialogoAgregarComposicion = true;
    this.accionistaContratistaEncabezado = {};
    this.lineaBase.idEncabezado = "new";
    this.fechaInicioAccionistasEncabezado = null;
  }

  cancelarAgregarComposicion() {
    this.visibleDialogoAgregarComposicion = false;
  }

  agregarComposicionAccionaria(forma: NgForm) {
    this.blockedDocument = true;
    if (this.visibleDialogoAgregarComposicion) {
      this.accionistaContratistaEncabezado.idContratista = this.lineaBase.idContratista;
      this.accionistaContratistaEncabezado.fechaInicio = this.fechaInicioAccionistasEncabezado;
      this.blockedDocument = true;
      forkJoin(
        this.informacionContractualCarreteroService.guardarAccionistasEncabezadoPorIdContratista(this.accionistaContratistaEncabezado)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El registro se guardó exitosamente.', life: 10000 });
            this.visibleDialogoAgregarComposicion = false;
            this.cargarAccionistasEncabezado();
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
        },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }
  }

  editarComposicionAccionariaPorId(idEncabezado) {
    this.lineaBase.idEncabezado = idEncabezado;
    forkJoin(
      this.informacionContractualCarreteroService.consultarAccionistasEncabezadoPorId(idEncabezado)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.accionistaContratistaEncabezado = result.accionistasEncabezado;
          this.fechaInicioAccionistasEncabezado = new Date(this.accionistaContratistaEncabezado.fechaInicio);
          this.visibleDialogoAgregarComposicion = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  editarComposicionAccionaria(idEncabezado) {
    this.blockedDocument = true;
    this.lineaBase.idEncabezado = idEncabezado;
    forkJoin(
      this.informacionContractualCarreteroService.listarAccionistasPorIdEncabezado(idEncabezado, this.lineaBase.idContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.lineaBase.accionistas = result.accionistas;
          this.lineaBase.totalParticipacionAccionistas = 0;
          this.lineaBase.accionistas.forEach(element => {
            this.lineaBase.totalParticipacionAccionistas = this.lineaBase.totalParticipacionAccionistas + element.porcentajeParticipacion;
          });
          this.lineaBase.visibleListaAccionistas = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irEliminarComposicion(id) {
    this.visibleConfirmarEliminarComposicion = true;
    this.lineaBase.idEncabezado = id;
  }

  aceptarEliminarComposicion() {
    forkJoin(
      this.informacionContractualCarreteroService.eliminarAccionistasEncabezadoPorIdContratista(this.lineaBase.idEncabezado, this.lineaBase.idContratista)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.visibleDialogoExitoEliminarComposicion = true;
          this.visibleConfirmarEliminarComposicion = false;
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El registro se eliminó exitosamente.', life: 10000 });
          this.cargarAccionistasEncabezado();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cancelarEliminarComposicion() {
    this.visibleConfirmarEliminarComposicion = false;
  }

  aceptarDialogoEliminarComposicion() {
    this.visibleDialogoExitoEliminarComposicion = false;
  }

}
