import { Injectable } from "@angular/core";
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { DatePipe } from "@angular/common";
import { Observable } from "rxjs-compat";
import { AmbMaEntidadAmbientalRpt, AmbMaTipoTramiteRpt, AmbMaTramiteRelacionadoRpt, AmbMaPriorizacionRpt, AmbMaEstadoCompromisoRpt, TrnMaEtapaProyectoRpt, AmbTramitesAmbientalesRpt, AmbTramiteAmbientalRpt, AmbUnidadFuncionalRpt, AmbEstadoTramiteRpt, AmbPriorizacionRpt, AmbResponsableRpt, AmbObservacionesRpt, TramiteAmbientalDTO } from './tramites-ambientales-fluvial.dto';
import { TrnRespuestaServicio } from "../parametricas/trn-respuesta-servicio";
import { throwError } from 'rxjs';


@Injectable({
  providedIn: "root",
})
export class TramitesAmbientalesService {
  public END_POINT = "";
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  /**
   * Constructor de la clase
   * @param http
   * @param datepipe
   */
  constructor(private http: HttpClient, private datepipe: DatePipe) {
    this.END_POINT = environment.baseUrl;
  }

  /**
   * Metodo que consulta las entidades ambientales
   * @returns  AmbMaEntidadAmbientalRpt
   */
  srvConsultarMaEntidadAmbiental(): Observable<AmbMaEntidadAmbientalRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbMaTramiteAmbiental/AmbMaEntidadAmbiental";
    return this.http.get<AmbMaEntidadAmbientalRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta los tipos de tramite
   * @returns  AmbMaTipoTramiteRpt
   */
  srvConsultarMaTipoTramite(): Observable<AmbMaTipoTramiteRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbMaTramiteAmbiental/AmbMaTipoTramite";
    return this.http.get<AmbMaTipoTramiteRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta la parametrica de tramites relacionados
   * @returns  AmbMaTramiteRelacionadoRpt
   */
  srvConsultarMaTramiteRelacionado(): Observable<AmbMaTramiteRelacionadoRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbMaTramiteAmbiental/AmbMaTramiteRelacionado";
    return this.http.get<AmbMaTramiteRelacionadoRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta la parametrica de tramites relacionados
   * @returns  AmbMaTramiteRelacionadoRpt
   */
  srvConsultarMaPriorizacion(): Observable<AmbMaPriorizacionRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbMaTramiteAmbiental/AmbMaPriorizacion";
    return this.http.get<AmbMaPriorizacionRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta la parametrica de estados
   * @returns  AmbMaTramiteRelacionadoRpt
   */
  srvConsultarMaEstadoCompromiso(): Observable<AmbMaEstadoCompromisoRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbMaTramiteAmbiental/AmbMaEstadoCompromiso";
    return this.http.get<AmbMaEstadoCompromisoRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta la parametrica de estados
   * @returns  AmbMaTramiteRelacionadoRpt
   */
  srvConsultarMaEstadoCompromisoActivos(): Observable<AmbMaEstadoCompromisoRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbMaTramiteAmbiental/AmbMaEstadoCompromisoActivos";
    return this.http.get<AmbMaEstadoCompromisoRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta la parametrica de estados
   * @returns  AmbMaTramiteRelacionadoRpt
   */
  srvConsultarMaEtapaProyecto(idTipoProyecto: number): Observable<TrnMaEtapaProyectoRpt> {
    const urlEndpoint =
      this.END_POINT + "/TrnMaEtapaProyecto/TrnMaEtapaProyecto/" + idTipoProyecto;
    return this.http.get<TrnMaEtapaProyectoRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta los tramites ambientales por proyecto
   * @returns  AmbMaTramiteRelacionadoRpt
   */
  srvListarTramitesAmbientalesPorProyecto(
    idProyecto: number
  ): Observable<AmbTramitesAmbientalesRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbTramiteAmbiental/TramitesAmbientales/" + idProyecto;
    return this.http.get<AmbTramitesAmbientalesRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
  * Metodo que consulta los tramites ambientales historicos por proyecto
  * @returns  AmbMaTramiteRelacionadoRpt
  */
  srvListarTramitesAmbientalesPorProyectoHistorico(
    idProyecto: number
  ): Observable<AmbTramitesAmbientalesRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbTramiteAmbiental/TramitesAmbientalesHistorico/" + idProyecto;
    return this.http.get<AmbTramitesAmbientalesRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta la parametrica de estados
   * @returns  AmbMaTramiteRelacionadoRpt
   */
  srvConsultarTramiteAmbientalPorId(
    id: number
  ): Observable<AmbTramiteAmbientalRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbTramiteAmbiental/TramiteAmbiental/" + id;
    return this.http.get<AmbTramiteAmbientalRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta la parametrica de estados
   * @returns  AmbMaTramiteRelacionadoRpt
   */
  srvConsultarTramiteAmbientalPorIdHistorico(
    id: number
  ): Observable<AmbTramiteAmbientalRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbTramiteAmbiental/TramiteAmbientalHistorico/" + id;
    return this.http.get<AmbTramiteAmbientalRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta las unidades funcionales por tramite
   * @returns  AmbUnidadFuncionalRpt
   */
  srvListarUnidadesFuncionalesPorIdTramite(idTramite: number): Observable<AmbUnidadFuncionalRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbTramiteAmbiental/AmbCompromisoUf/" + idTramite;
    return this.http.get<AmbUnidadFuncionalRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta los estados por tramite
   * @returns  AmbEstadoTramiteRpt
   */
  srvListarEstadosPorIdTramite(idTramite: number): Observable<AmbEstadoTramiteRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbTramiteAmbiental/AmbCompromisoEstado/" + idTramite;
    return this.http.get<AmbEstadoTramiteRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta los estados por tramite
   * @returns  AmbPriorizacionRpt
   */
  srvListarPriorizacionesPorIdTramite(idTramite: number): Observable<AmbPriorizacionRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbTramiteAmbiental/AmbCompromisoPriorizacion/" + idTramite;
    return this.http.get<AmbPriorizacionRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta los responsables por tramite
   * @returns  AmbResponsableRpt
   */
  srvListarResponsablesPorIdTramite(idTramite: number): Observable<AmbResponsableRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbTramiteAmbiental/AmbCompromisoResponsable/" + idTramite;
    return this.http.get<AmbResponsableRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Metodo que consulta las observaciones por tramite
   * @returns  AmbObservacionesRpt
   */
  srvListarObservacionesPorIdTramite(idTramite: number): Observable<AmbObservacionesRpt> {
    const urlEndpoint =
      this.END_POINT + "/AmbTramiteAmbiental/AmbCompromisoObservaciones/" + idTramite;
    return this.http.get<AmbObservacionesRpt>(urlEndpoint).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * 
   * @param cierreOlaInvernal 
   * @returns 
   */
  srvGuardarTramiteAmbiental(tramiteAmbiental: TramiteAmbientalDTO): Observable<AmbTramiteAmbientalRpt> {
    const urlEndpoint = this.END_POINT + '/AmbTramiteAmbiental/GuardarAmbCompromisoPorProyecto';
    return this.http.post<AmbTramiteAmbientalRpt>(urlEndpoint, tramiteAmbiental)
      .pipe(
        map(data => {
          return data;
        }));
  }

  /**
     * Elimina un tramite
    */
  public srvEliminarTramite(idTramite: number): Observable<RespuestaGenericaRpt> {
    // console.log('idPredioHistorico', idPredioHistorico);
    // console.log('borrador', borrador);
    let url = this.END_POINT + "/AmbTramiteAmbiental/eliminarTramite/" + idTramite;
    return this.http.delete<RespuestaGenericaRpt>(url).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

export class RespuestaGenericaRpt {
  constructor(public respuestaServicio?: TrnRespuestaServicio) { }
}
