import { Component, OnInit, } from '@angular/core';
import { EditFirmantesRadicacionWebService, } from './edit-firmantes-radicacion-web.service';
import { InformacionGeneral, Firmantes } from './informacionGeneral';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';



@Component({
    selector: 's-edit-firmantes-radicacion-web',
    templateUrl: './edit-firmantes-radicacion-web.component.html',
    styleUrls: ['./edit-firmantes-radicacion-web.component.css']
})
export class EditFirmantesRadicacionWebComponent implements OnInit {

    constructor(private _service: EditFirmantesRadicacionWebService,
        private router: Router,
        private ruta: ActivatedRoute,
        private messageService: MessageService,
        private principalComponent: PrincipalComponent) { }

    // Inicializaciones de Variables
    loading : boolean;
    isEdit  : boolean;
    visibleConfirmarElimnacion  : boolean;
    visibleModalEdicionFirmante : boolean;
    displayedColumns1;
    listado: any = [];
    informacion: InformacionGeneral = {};
    firmante: Firmantes = {};
    firmanteEdit : Firmantes = {};
    idProyecto: any;
    listadoFirmantes: any = [];
    volverListado: any;
    temporal: any; 
    temporalListado: any; 

    idEliminar : any;

    solicitantes: any = [];
    filtroSolicitantes: any[];
    solicitanteSeleccionado: any = null;
    

    ngOnInit() {
        this.loading = true;
        this.isEdit = false;
        this.visibleConfirmarElimnacion = false;

        this.visibleModalEdicionFirmante = false;
        this.displayedColumns1 = [
          { field: 'persona', header: 'Nombre Completo' },
          { field: 'tipoDocumento', header: 'Tipo Documento' },
          { field: 'numeroDocumento', header: 'Número Documento' },
          { field: 'cargo', header: 'Cargo' }
        ];

        this.volverListado = localStorage.getItem('volverListadoRadicacionEdit');
        this.idProyecto = localStorage.getItem('idProyecto');
        this.cargarInformacion();
    }


    /**
     *Metodo que carga la informacion de la tabla de firmantes
     *
     * @memberof EditFirmantesRadicacionWebComponent
     */
    cargarInformacion(){
        this._service.getDatosInformacion(this.idProyecto).subscribe((data: {}) => {
            this.temporal = data;
            if(this.temporal != undefined && this.temporal != null ){
                    this.informacion = this.temporal;     
            }            
            this.loading = false;
            }, (error)=>{
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });

    }
    

    /**
     *Metodo que carga la persona firmante
     *
     * @memberof EditFirmantesRadicacionWebComponent
     */
    cargarSolicitante(query) {
        this.filtroSolicitantes = [];

        if(query=="" || query.length > 3 ){
            this._service.getPersonaFirmantes(query).subscribe((data: {}) => {
                this.solicitantes = data;
                let existeFiltro: boolean;
                for (const row of this.solicitantes) {
                  this.filtroSolicitantes.push(row);
                }
              },error => {
                this.principalComponent.cargarErrorServicio(error);
              });
        }
    }

    /**
     * Metodo que carga la informacion firmante
     */
    cargarInformacionFirmante(event){
        this.firmante.idPersona = this.solicitanteSeleccionado.id;
        this.firmante.persona = this.solicitanteSeleccionado.nombreCompleto.replaceAll(this.solicitanteSeleccionado.numeroDocumento+" - ", "");
        this.firmante.numeroDocumento = this.solicitanteSeleccionado.numeroDocumento.trim();
        this.firmante.tipoDocumento = "CC";
    }


    /**
     * Metodo que oculat el modal de confirmacion de eliminacion
     */
    cancelarEliminar(){
        this.visibleConfirmarElimnacion = false;
    }

    /**
     *Metodo que muestra el modal de confirmacion de eliminacion
     *
     * @memberof EditFirmantesRadicacionWebComponent
     */
    aceptarEliminar(){
        this.loading = true;
        this.visibleConfirmarElimnacion = false;        
        this._service.deleteFirmante(this.idEliminar).subscribe((data: {}) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: "Se eliminó el registro.", life: 10000 });
            this.cargarInformacion();
            this.visibleConfirmarElimnacion = false;
        }, (error)=>{
            this.loading = false;
            this.visibleConfirmarElimnacion = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     *Metodo que carga la modal de confirmacion de eliminacion
     *
     * @param {*} rowData
     * @memberof EditFirmantesRadicacionWebComponent
     */
    onDelete(rowData){
        this.visibleConfirmarElimnacion = true;
        this.idEliminar = rowData.id;
    }

    /**
     *Metodo que carga la modal de agregar nuevo
     *
     * @memberof EditFirmantesRadicacionWebComponent
     */
    onAdd(){
        this.firmante = {};
        this.solicitanteSeleccionado = null;
        this.visibleModalEdicionFirmante = true;
        this.isEdit = false;
        this.firmante.id = "0";
        this.firmante.idProyecto = this.informacion.idProyecto;
        this.firmante.idPersonaJuridica = this.informacion.idPersonaJuridica;    
    }
    

    /**
     *Metodo que carga la modal de edicion
     *
     * @param {*} rowData
     * @memberof EditFirmantesRadicacionWebComponent
     */
    onEdit(rowData){
        this.isEdit = true;
        this.firmanteEdit = {};
        this.visibleModalEdicionFirmante = true;
        this.firmanteEdit.id = rowData.id;
        this.firmanteEdit.idProyecto = rowData.idProyecto;
        this.firmanteEdit.idPersonaJuridica = rowData.idPersonaJuridica;
        this.firmanteEdit.idPersona = rowData.idPersona;
        this.firmanteEdit.proyecto = rowData.proyecto;
        this.firmanteEdit.personaJuridica = rowData.personaJuridica;
        this.firmanteEdit.persona = rowData.persona;
        this.firmanteEdit.numeroDocumento = rowData.numeroDocumento;
        this.firmanteEdit.tipoDocumento = rowData.tipoDocumento;
        this.firmanteEdit.cargo = rowData.cargo;
    }

    /**
     *metodo que cierra la modal de edicion
     *
     * @memberof EditFirmantesRadicacionWebComponent
     */
    cancelarEditFirmante(){
        this.visibleModalEdicionFirmante = false;
    }

    /**
     *metodo que cierra la modal de edicion y guarda la informacion.¿
     *
     * @memberof EditFirmantesRadicacionWebComponent
     */
    aceptarEditFirmante(){
        if(!this.isEdit){
            if(this.firmante.cargo != null && this.firmante.cargo != ""){
                this.loading = true;
                this.visibleModalEdicionFirmante = false;
                this._service.postFirmante(this.firmante).subscribe((data: {}) => {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: "Se guardo la información.", life: 10000 });
                    this.cargarInformacion();
                    this.visibleModalEdicionFirmante = false;
                }, (error)=>{
                    this.loading = false;
                    this.visibleModalEdicionFirmante = false;
                    this.principalComponent.cargarErrorServicio(error);
                });
            }else{
                this.messageService.add({ severity: 'error', summary: 'Error', detail: "Se debe escribir un cargo.", life: 10000 });
            }
        }else{
            if(this.firmanteEdit.cargo != null && this.firmanteEdit.cargo != ""){
                this.loading = true;
                this.visibleModalEdicionFirmante = false;
                this._service.postFirmante(this.firmanteEdit).subscribe((data: {}) => {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: "Se actualizó la información.", life: 10000 });
                    this.cargarInformacion();
                    this.visibleModalEdicionFirmante = false;
                }, (error)=>{
                    this.loading = false;
                    this.visibleModalEdicionFirmante = false;
                    this.principalComponent.cargarErrorServicio(error);
                });
            }else{
                this.messageService.add({ severity: 'error', summary: 'Error', detail: "Se debe escribir un cargo.", life: 10000 });
            }
        }
        
    }



    /**
    * Metodo que devuelve la pagina
    */
   bntVolver() {    
    localStorage.setItem('volverListadoRadicacionEdit', null);
    this.router.navigate([this.volverListado]);
  }


}
