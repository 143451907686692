import { Injectable, } from '@angular/core';
import { ConfigService } from '../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EditFirmantesRadicacionWebService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    
    /**
     * Metodo que carga la lista de la info de la tabla radicado
     */
    public getListadoFirmantes(idProyecto) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/listado/firmantes/'+idProyecto)
            .pipe(retry(1));
    }

        /**
     * Metodo que carga la lista de la info de la tabla firmantes
     */
    public getDatosInformacion(idProyecto) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/datos/informacion/' + idProyecto)
            .pipe(retry(1));
    }

    
    /**
     * Metodoq ue consulta el la persona
     */
    public getPersonaFirmantes(query) {
        if(query ==""){
            query = "_null_";
        }else{
            query = query.toUpperCase();
        }
        return this.http.get(this.END_POINT + '/RadicacionWeb/lista/firmante/CC/'+query)
        .pipe(
            retry(1));
    }

    /**
     *Metodo que guarda el firmante
     *
     * @param {*} data
     * @returns
     * @memberof EditFirmantesRadicacionWebService
     */
    public postFirmante(data){
        return this.http.post(this.END_POINT + '/RadicacionWeb/firmantes', data)
            .pipe(
                retry(1));
    }

    /**
     *Metodo que guarda el firmante
     *
     * @param {*} data
     * @returns
     * @memberof EditFirmantesRadicacionWebService
     */
    public deleteFirmante(data){
        return this.http.get(this.END_POINT + '/RadicacionWeb/firmantes/eliminar/'+ data)
            .pipe(
                retry(1));
    }

}
