import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { UnidadFuncional } from './unidad-funcional'
import { environment } from 'src/environments/environment';
import { CodigoUnidadFuncional } from './codigo-unidad-funcional';

@Injectable({
  providedIn: 'root'
})
export class UnidadFuncionalService {

  constructor(private http: HttpClient) {
  }

  public getUnidadesFuncionales(): Observable<UnidadFuncional> {
    return this.http.get<UnidadFuncional>(environment.baseUrl + '/unidadesfuncionales')
      .pipe(
        retry(1));
  }

  public getCodigosUnidadesFuncionales(): Observable<CodigoUnidadFuncional> {
    return this.http.get<CodigoUnidadFuncional>(environment.baseUrl + '/unidadesfuncionales/CodigosUnidadesFuncionales')
      .pipe(
        retry(1));
  }

  public getUnidadesFuncionalesPorProyecto(idProyecto:number): Observable<UnidadFuncional> {
    return this.http.get<UnidadFuncional>(environment.baseUrl + '/unidadesfuncionales/UnidadesFuncionalesByProj/'+idProyecto)
      .pipe(
        retry(1));
  }

  public getUnidadFuncional(idUnidad:number) : Observable<UnidadFuncional> {
      return this.http.get<UnidadFuncional>(environment.baseUrl + '/unidadesfuncionales/' + idUnidad)
        .pipe(retry(1));
  }

  public setUnidadFuncional(unidadFuncional: UnidadFuncional) {
    if (unidadFuncional.idUnidadFuncional > 0) {
      return this.http.put<UnidadFuncional>(environment.baseUrl + '/unidadesfuncionales/' + unidadFuncional.idUnidadFuncional, JSON.stringify(unidadFuncional), this.httpOptions)
    } else {
      unidadFuncional.idUnidadFuncionalPadreNavigation = null
      return this.http.post<UnidadFuncional>(environment.baseUrl + '/unidadesfuncionales', JSON.stringify(unidadFuncional), this.httpOptions)
    }

  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
