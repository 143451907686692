import { Component } from '@angular/core';

@Component({
  selector: 'app-ruta-ferrecaribe',
  templateUrl: './ruta-ferrecaribe.component.html',
  styleUrls: ['./ruta-ferrecaribe.component.sass']
})
export class RutaFerrecaribeComponent {

}
