import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visor-mapa-conflictividad-proyectos',
  templateUrl: './visor-mapa-conflictividad-proyectos.component.html',
  styleUrls: ['./visor-mapa-conflictividad-proyectos.component.sass']
})
export class VisorMapaConflictividadProyectosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
