import { Component, OnInit } from '@angular/core';
import { TramitesAmbientalesFerreoService } from '../tramites-ambientales.service';
import { TramitesAmbientalesDTO, TramoDTO, ObjectEstadoTramitelDTO, ObjectPriorizacionDTO,
   ObjectResponsableEntidadDTO, ObjectObservacionesDTO  } from '../tramites-ambientales.dto.js';
import { Router, ActivatedRoute } from '@angular/router';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { forkJoin } from "rxjs";



@Component({
  selector: 'app-tramites-ambientales-seguimiento',
  templateUrl: './tramites-ambientales-seguimiento.component.html',
  styleUrls: ['./tramites-ambientales-seguimiento.component.css']
})
export class TramitesAmbientalesSeguimientoFerreoComponent implements OnInit {

  loading: boolean;
  idProyecto: number;
  idTramiteAmbiental: number;
  proyectoSeleccionado: ProyectoDTO;

	nombreProyecto;
  tramiteAmbiental: TramitesAmbientalesDTO;
  tramo: TramoDTO;
  dialogoEliminar: boolean;

  //Listas en la presentacion
  tramosAsociadosList: TramoDTO[];
  seguimientoEstados: ObjectEstadoTramitelDTO[];
  seguimientoPriorizaciones: ObjectPriorizacionDTO[];
  seguimientoResponsables: ObjectResponsableEntidadDTO[];
  seguimientoObservaciones: ObjectObservacionesDTO[];

  constructor(
    private router: Router,
		private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private service: TramitesAmbientalesFerreoService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.loading = true;
    this.tramiteAmbiental = {};

    this.ruta.params.subscribe(params => {
			this.idTramiteAmbiental = params.id;
      this.getCargarListadoUnidades();
      this.cargarInformacionTramite(this.idTramiteAmbiental);
		});

    this.idProyecto = (localStorage.getItem('idProyectoFerreo')!= null) ? Number(localStorage.getItem('idProyectoFerreo')) : 0;
    this.ruta.params.subscribe(params => {
			this.idTramiteAmbiental = params.id;
      forkJoin(
        this.componentesComunesService.obtenerProyecto(this.idProyecto)
      ).subscribe(
        ([proyectoData]) => {
          this.proyectoSeleccionado = proyectoData;
        },
        (e) => {}
      );
		});


  }

  /**
   * Metodo que consulta la información del tramite
   *
   * @param idTramiteAmbiental
   */
  cargarInformacionTramite(idTramiteAmbiental){
    forkJoin(
      this.service.getTramiteById(idTramiteAmbiental),
      this.service.getTramiteEstados(idTramiteAmbiental),
      this.service.getTramiteObservaciones(idTramiteAmbiental),
      this.service.getTramitePriorizaciones(idTramiteAmbiental),
      this.service.getTramiteResponsables(idTramiteAmbiental)
    ).subscribe(
      ([tramiteData, estadoData, observacionesData, priorizacionData, responsableData]) => {

        this.tramiteAmbiental = tramiteData.tramiteAmbiental;
        this.seguimientoEstados = estadoData.listaEstados;
        this.seguimientoObservaciones = observacionesData.listaObservaciones;
        this.seguimientoPriorizaciones = priorizacionData.listaPriorizaciones;
        this.seguimientoResponsables = responsableData.listaResponsables;
        this.loading = false;
      },
      (e) => {}
    );
  }

  /**
   * Metodo que consulta el listado de tramites vigentes
   */
  getCargarListadoUnidades(){
    this.tramosAsociadosList = [];
    //TODO: colocar logica de cargue de listados
  }

   /**
   * metodo que devuelve la pagina
   */
   bntVolver(){
    const ruta = "ferrocarriles/infGeneral/proyectoHome/" + this.idProyecto +"/tramites-ambientales-listado/"+ this.idProyecto;
    this.router.navigate([ruta]);
  }

}
