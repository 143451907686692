/* eslint-disable @typescript-eslint/no-loss-of-precision */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrsFuncionario } from 'src/app/admin-personas/prs-funcionario';
import { TrnPersona } from 'src/app/admin-personas/trn-persona';
import { EnumRegex, EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumCmsConstantes, EnumCmsMaTipoModificacionComision, EnumCmsTipoAnexo } from '../../comisiones-constantes';
import { CmsCdpDTO, CmsComisionDTO, CmsComisionLegalizacionDTO, CmsRpDTO, VwCmsAnexoComisionDTO, VwCmsComision, VwCmsTiqueteAereoDTO, CmsTiqueteAereoDTO, CmsArchivoAnexoComisionDTO, CmsAnexoComisionDTO, CmsModosDTO } from '../../comisiones.dto';
import { ComisionesService } from '../../comisiones.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-comisiones-gestion-presupuestal-registro',
  templateUrl: './comisiones-gestion-presupuestal-registro.component.html',
  styles: []
})
export class ComisionesGestionPresupuestalRegistroComponent implements OnInit {

  blockedDocument: boolean;
  fechaActual: Date;
  persona: TrnPersona;
  detalleFuncionario: PrsFuncionario;

  //Parametros URL
  idComision: number;
  idComisionVersion: number;
  idAsignacion: number;
  idPersona: number;

  //Pestana Informacion Basica
  comision: CmsComisionDTO;
  archivos: any = [];

  //Pestana Liquidacion
  nombreTabLiquidacion: string;
  habilitarLiquidacionLegalizacion = false;

  //Pestana legalizacion
  legalizacion: CmsComisionLegalizacionDTO;


  // rutaCloudStorageInformeLegalzaion: string;
  // visibleDialogoSubirInformeLegalizacion = false;
  // adjuntosInformeLegalizacion: any = [];
  // adjuntosPasabordo: any = [];
  // adjuntosAnexos: any = [];
  // tipoSoporteSeleccionado: any;
  anexosConsolidado: VwCmsAnexoComisionDTO[];//Anexos de Legalizacion
  // visibleDialogoSubirAnexos = false;

  // visibleDialogoEliminarDocumentoLegalizacion = false;
  // idDocumentoLegalizacion: number;
  // idsTipoSoporteLegalizacion: string;
  // visibleDialogoEnviarLegalizacion: boolean;
  // responderLegalizacionDto: CmsReasignarComisionDTO;
  // visibleDialogoAceptarLegalizacion = false;
  // visibleDialogoRechazarLegalizacion = false;
  // legalizado: boolean;

  //Pestana tiquetes

  itinerarioAereoList: VwCmsTiqueteAereoDTO[];
  observacionesList: VwCmsTiqueteAereoDTO[];
  dialogoObservacionesTiquete: boolean;
  tiqueteSeleccionado: CmsTiqueteAereoDTO;

  //Pestana Rp  
  cdpList: CmsCdpDTO[];
  rpAsociadosList: CmsRpDTO[];
  rp: CmsRpDTO;
  totalValorRpComision = 0;

  anexoComision: CmsAnexoComisionDTO;
  anexosConsolidadoGestionPresupuestal: VwCmsAnexoComisionDTO[];
  adjuntosComponente: any = [];
  cantidadMaximaAdjuntos = 3;
  idsTipoSoporteGestionPresupuestal: any;
  nombreInicialArchivo: string;
  anexoRequiereNumero: boolean;

  visibleGuardarAnexoDialog = false;
  visibleConsultaAnexoDialog = false;
  visibleSubirAnexoDialog = false;
  visibleAdjuntosDialog = false;

  rutaCloudStorageComisionesGestionPresupuestal: string;



  formRp;
  formAnexo;

  formRpBtnSubmit = false;
  visibleGuardarRpDialog = false;
  visibleConfirmarEliminarRegistroDialogo = false;
  esEdicion = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService
  ) {
    this.fechaActual = new Date();

    // Crear formulario Obligacion
    this.formAnexo = this.formBuilder.group({
      numeroAnexo: [null, [Validators.required, Validators.min(1), Validators.maxLength(20), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
      archivos: this.formBuilder.array([Validators.required]),
    });

    // Crear formulario RP
    this.formRp = this.formBuilder.group({
      cdpComision: [null, [Validators.required]],
      numeroRp: [null, [Validators.required, Validators.min(1), Validators.maxLength(20), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
      valorPagadoRp: [null, [Validators.required, Validators.max(99999999999999.99), Validators.min(1), Validators.maxLength(16), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO_DECIMAL)]],
      fechaRp: [null, [Validators.required]]
    });
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.comision = {};
    this.comision.viaticos = {};
    this.legalizacion = {};
    this.persona = {};
    this.detalleFuncionario = {};

    this.rp = {};
    this.formRpBtnSubmit = false;
    this.esEdicion = false;
    this.fechaActual = new Date();
    this.blockedDocument = true;


    // Obtener parametros url
    const idComisionParam = "idComision";
    const idComisionVersionParam = "idComisionVersion";
    const idAsignacionParam = "idAsignacion";
    const idPersonaParam = "idPersona";

    this.ruta.params.subscribe((params) => {
      this.idComision = Number(params[idComisionParam]);
      this.idAsignacion = Number(params[idAsignacionParam]);
      this.idPersona = Number(params[idPersonaParam]);
      this.idComisionVersion = Number(params[idComisionVersionParam]);
    });

    this.inicializarVariables();

    this.obtenerComisionPorId(this.idComision);
    this.tiqueteSeleccionado = {};

  }

  //Pestaña Rp
  inicializarVariables() {
    this.rp = {};
    this.rpAsociadosList = [];
    this.formRpBtnSubmit = false;
    this.esEdicion = false;
    this.fechaActual = new Date();
    this.blockedDocument = true;

    this.anexoComision = {};
    this.adjuntosComponente = [];
    this.cantidadMaximaAdjuntos = 3;
    this.anexosConsolidadoGestionPresupuestal = [];
    this.cargarCdpList();
  }


  obtenerComisionPorId(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarComisionPorId(idComision)
    ).subscribe(
      ([comisionData]) => {
        this.comision = comisionData.comisionDetalle;

        if (this.comision !== null) {
          if (this.comision.fechaSalida != null) {
            this.comision.fechaSalida = new Date(this.comision.fechaSalida);
          }
          if (this.comision.fechaRegreso != null) {
            this.comision.fechaRegreso = new Date(this.comision.fechaRegreso);
          }
          this.comision.objeto = this.comision.objeto.replace('<p>', '').replace('</p>', '');

          if (this.comision.valorTentativoTerrestre === 0) {
            this.comision.valorTentativoTerrestre = null;
          }
          if (this.comision.valorTentativoFluvial === 0) {
            this.comision.valorTentativoFluvial = null;
          }
          if (this.comision.valorTentativoAereo === 0) {
            this.comision.valorTentativoAereo = null;
          }

          if (this.comision.itinerarioAereoLista !== null && this.comision.itinerarioAereoLista.length > 0) {
            this.comision.itinerarioAereoLista.forEach(itAer => {
              if (itAer.fechaVuelo != null) {
                itAer.fechaVuelo = new Date(itAer.fechaVuelo);
              }
            });
          }

          if (this.comision.esContratista) {
            this.persona = {};
            this.detalleFuncionario = {};
          } else {
            this.persona = {};
            this.detalleFuncionario = {};
            this.cargarPersona(this.comision.idPersona);
          }

          //Actualizamos la variable de sumatoria de modos para visualizarlo en el Datatable
          this.actualizarSumatoriaModos();

          //Siempre se debe mostrar la liquidación aprobada puesto que el RP que se carga es sobre la liquidación aprobada
          this.habilitarLiquidacionLegalizacion = false;

          if (this.comision.idPasoActual >= EnumCmsConstantes.CMS_PASO_COMISION_REVISION_LEGALIZACION
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES) {
            this.obtenerLegalizacionPorComision(idComision);
          }

          this.cargarItinerarios();

          this.cargarRpAsociados(this.idComisionVersion);

        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarPersona(idPersona) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarFuncionarioComisionPorIdPersona(idPersona)
    ).subscribe(
      ([
        funcionarioData
      ]) => {

        this.persona.id = funcionarioData.configuracionFuncionario.id;
        this.persona.nombre = funcionarioData.configuracionFuncionario.nombre;
        this.persona.numeroDocumento = funcionarioData.configuracionFuncionario.numeroDocumento;
        this.persona.correoElectronicoAni = funcionarioData.configuracionFuncionario.correoElectronico;
        this.detalleFuncionario.honorarios = funcionarioData.configuracionFuncionario.ingresos;
        this.detalleFuncionario.dependenciaPrimerNivel = funcionarioData.configuracionFuncionario.dependenciaPrimerNivel;
        this.detalleFuncionario.idDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.idDependenciaPrimerNivel;
        this.detalleFuncionario.liderDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.liderDependenciaPrimerNivel;
        this.detalleFuncionario.dependenciaEspecifica = funcionarioData.configuracionFuncionario.dependencia;
        this.detalleFuncionario.idDependenciaEspecifica = funcionarioData.configuracionFuncionario.idDependencia;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  /**
  * Metodo para actualizar la variable global que muestra el sumatoria de modos
  */
  actualizarSumatoriaModos() {
    if (Array.isArray(this.comision.modoLista) && this.comision.modoLista.length) {
      return this.comision.modoLista.reduce<number>((sum: number, modoVar: CmsModosDTO) => {
        return Number(sum + Number(modoVar.porcentajeRecursos));
      }, Number(0));
    }
  }

  obtenerLegalizacionPorComision(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarLegalizacionPorComision(idComision)
    ).subscribe(
      ([legalizacionData]) => {
        this.legalizacion = legalizacionData.legalizacion;
        this.listarAnexosPorLegalizar(this.legalizacion.idComisionVersion);
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  listarAnexosPorLegalizar(idComisionVersion) {
    forkJoin(
      this.comisionesService.srvConsultarAnexoAdjuntosConsolidado(idComisionVersion, EnumCmsTipoAnexo.SUBTIPO_GESTION_LEGALIZACION_ANEXOS + ',' + EnumCmsTipoAnexo.SUBTIPO_GESTION_TIQUETES, false)
    ).subscribe(
      ([comisionesPorLegalizarData]) => {
        this.anexosConsolidado = comisionesPorLegalizarData.adjuntosAnexosConsolidado;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  //Pestana tiquetes
  irVerObservaciones(tiquete: VwCmsTiqueteAereoDTO) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarObservacionesPorIdTiquete(tiquete.idTiqueteAereo)
    ).subscribe(
      ([
        observacionesData
      ]) => {
        this.observacionesList = observacionesData.tiquetes;
        this.dialogoObservacionesTiquete = true;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarItinerarios() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarTiquetesSolicitadosPorIdComision(this.idComision, this.idComisionVersion)
    ).subscribe(
      ([
        tiquetesData
      ]) => {
        this.itinerarioAereoList = tiquetesData.transporteAereoLista;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarCdpList() {
    this.comisionesService.srvConsultarListadoCdpMovimientos(this.idComision, this.idComisionVersion).subscribe(
      result => {
        if (result != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cdpList = result.cdpList;
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }


  cargarAnexoPorTipoAnexo(idComisionVersion: number, idTipoAnexo: number) {
    this.comisionesService.srvConsultarAnexoPorTipoAnexo(idComisionVersion, idTipoAnexo, false)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              if (result.anexoComision != null) {
                this.anexoComision = result.anexoComision;
              }
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {

          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );

  }

  irCargarAnexo(cms: VwCmsComision, idTipoAnexo: number) {
    this.blockedDocument = true;
    this.comision = cms;

    this.anexoComision = {};
    this.anexoComision.adjuntos = [];
    this.anexoComision.idTipoAnexo = idTipoAnexo;
    this.anexoComision.idComisionVersion = this.idComisionVersion;

    this.cargarAnexoPorTipoAnexo(this.idComisionVersion, idTipoAnexo);
    this.visibleConsultaAnexoDialog = true;
  }

  irAnexosConsolidadoComision() {
    this.anexosConsolidadoGestionPresupuestal = [];
    this.visibleAdjuntosDialog = true;
    this.cargarAnexoAdjuntosConsolidado(this.idComisionVersion, EnumCmsTipoAnexo.SUBTIPO_GESTION_LEGALIZACION + ',' + EnumCmsTipoAnexo.SUBTIPO_GESTION_LEGALIZACION_ANEXOS);
  }

  irGuardarAnexo(idTipoAnexo: number) {
    this.blockedDocument = true;

    this.anexoComision = {};
    this.anexoComision.adjuntos = [];
    this.anexoComision.idTipoAnexo = idTipoAnexo;
    this.anexoComision.idComisionVersion = this.idComisionVersion;

    switch (idTipoAnexo) {
      case EnumCmsTipoAnexo.ACTO_ADMINISTRATIVO: {
        this.idsTipoSoporteGestionPresupuestal = EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_ACTO_ADMINISTRATIVO;
        this.anexoComision.nombreInicialArchivo = 'ActoAdministrativo';
        this.anexoComision.requiereNumero = false;
        this.formAnexo.get('numeroAnexo').required = this.anexoComision.requiereNumero;
        break;
      }
      case EnumCmsTipoAnexo.AUTORIZACION_SIIF: {
        this.idsTipoSoporteGestionPresupuestal = EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_AUTORIZACION_SIIF;
        this.anexoComision.nombreInicialArchivo = 'AutorizacionComisionSiif';
        this.anexoComision.requiereNumero = true;
        this.formAnexo.get('numeroAnexo').required = this.anexoComision.requiereNumero;
        break;
      }
    }
    this.cargarAnexoPorTipoAnexo(this.idComisionVersion, idTipoAnexo);
    this.visibleGuardarAnexoDialog = true;
  }

  irSubirAdjuntos() {
    this.rutaCloudStorageComisionesGestionPresupuestal = this.comision.idComision + '/' + EnumCmsConstantes.CMS_CARPETA_GESTION_PRESUPUESTAL_BLOB_STORAGE + '/';
    if (this.anexoComision.adjuntos == null || (this.anexoComision.adjuntos != null && this.anexoComision.adjuntos.length < this.cantidadMaximaAdjuntos)) {
      this.visibleSubirAnexoDialog = true;
      this.adjuntosComponente = [];
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Elimina algún adjunto para continuar. Recuerda que son máximo ' + this.cantidadMaximaAdjuntos + ' adjuntos', life: 10000 });
    }
  }

  validarInfoAnexo(): boolean {
    let validacionExitosa = true;
    if (this.anexoComision == null) {
      validacionExitosa = false;
      this.lanzarMensajeError('No ha relacionado el ' + this.nombreInicialArchivo + ' de la comisión');
    } else {

      if (this.anexoRequiereNumero && (this.anexoComision.numero == null || this.anexoComision.numero.length == 0)) {
        validacionExitosa = false;
        this.lanzarMensajeError('Debes relacionar el número de ' + this.nombreInicialArchivo + ' para continuar');
      }

      if (this.anexoComision.adjuntos == null || this.anexoComision.adjuntos.length == 0) {
        validacionExitosa = false;
        this.lanzarMensajeError('Debes adjuntar los documentos que relacionan el ' + this.nombreInicialArchivo + ' para continuar');
      }
    }

    return validacionExitosa;
  }

  submitGuardarAnexo() {
    this.blockedDocument = true;
    this.visibleGuardarAnexoDialog = false;
    if (this.validarInfoAnexo()) {
      this.comisionesService.srvGuardarAnexoArchivoComision(this.anexoComision).subscribe(
        result => {
          if (result != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
          this.ngOnInit();
        }
      );
    } else {
      this.blockedDocument = false;
    }
  }

  cancelarSubirAdjunto() {
    this.visibleSubirAnexoDialog = false;
    this.adjuntosComponente = [];
  }

  aceptarSubirAdjunto() {
    const adjuntosList: CmsArchivoAnexoComisionDTO[] = [];
    this.adjuntosComponente.forEach(arc => {
      const adj: CmsArchivoAnexoComisionDTO = {};
      adj.nombreArchivoUsuario = arc.nombre;
      adj.rutaSoporte = arc.nombreCompleto;
      adjuntosList.push(adj);
    });

    const tamanioFinalAdjuntos: number = adjuntosList.length + (this.anexoComision.adjuntos == null ? 0 : this.anexoComision.adjuntos.length);
    if (tamanioFinalAdjuntos > this.cantidadMaximaAdjuntos) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Solo se permiten ' + this.cantidadMaximaAdjuntos + ' archivos máximo.', life: 10000 });
    } else {
      adjuntosList.forEach(adj => {
        this.anexoComision.adjuntos.push(adj);
      });
    }
    this.visibleSubirAnexoDialog = false;
  }

  cargarAnexoAdjuntosConsolidado(idComisionVersion: number, idsSubtipoAnexo: string) {
    this.comisionesService.srvConsultarAnexoAdjuntosConsolidado(idComisionVersion, idsSubtipoAnexo, false)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.anexosConsolidadoGestionPresupuestal = result.adjuntosAnexosConsolidado;
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {

          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  aceptarEliminarAdjunto(adj: CmsArchivoAnexoComisionDTO) {

    if (adj != null) {
      // Adjunto guardado en memoria
      this.anexoComision.adjuntos = this.anexoComision.adjuntos.filter(item => item.rutaSoporte != adj.rutaSoporte);
    }
  }



  cargarRpAsociados(idComisionVersion: number) {
    this.comisionesService.srvConsultarListadoRpComision(idComisionVersion).subscribe(
      result => {
        if (result != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.rpAsociadosList = result.rpComisionList;
            this.totalValorRpComision = 0;
            if (this.rpAsociadosList != null && this.rpAsociadosList.length > 0) {
              this.totalValorRpComision = this.rpAsociadosList.map(rp => rp.valorPagado).reduce((prev, next) => prev + next);
            }
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  irAgregarRP() {
    this.rp = {
      idComision: this.idComision,
      idComisionVersion: this.idComisionVersion
    };
    this.formRpBtnSubmit = false;
    this.esEdicion = false;
    this.visibleGuardarRpDialog = true;
  }

  irEditarRP(rp: CmsRpDTO) {
    this.rp = rp;
    this.formRpBtnSubmit = false;
    this.esEdicion = true;
    this.rp.fecha = new Date(this.rp.fecha);
    if (this.rp.cdp != null) {
      this.rp.cdp = this.cdpList.find(cdpMov => cdpMov.id == this.rp.cdp.id);
    }
    this.visibleGuardarRpDialog = true;
  }

  irEliminarRP(rp: CmsRpDTO) {
    this.blockedDocument = true;
    this.rp = rp;
    this.visibleConfirmarEliminarRegistroDialogo = true;
  }


  validarRP() {
    let esRpValido = true;
    if (this.formRp.valid) {
      if (this.rp.cdp == null) {
        esRpValido = false;
        this.lanzarMensajeError("Debe seleccionar el CDP asociado a este RP");
      }
      if (this.rp.valorPagado > this.rp.valor) {
        esRpValido = false;
        this.lanzarMensajeError("El valor a pagar por este RP debe ser menor o igual al valor del RP");
      }
    } else {
      esRpValido = false;
      this.lanzarMensajeError("Debe diligenciar los campos requeridos marcados con asterisco (*)");
    }
    return esRpValido;
  }

  submitGuardarRP() {
    this.blockedDocument = true;
    this.formRpBtnSubmit = true;
    this.rp.valor = this.rp.valorPagado;
    if (this.validarRP()) {
      if (this.esEdicion) {
        this.rpAsociadosList.forEach((item, index) => {
          if (item.cdp.id == this.rp.cdp.id && item.numero === this.rp.numero) {
            this.rpAsociadosList.splice(index, 1);
          }
        });
        this.rpAsociadosList.push(this.rp);
      } else {
        this.rpAsociadosList.push(this.rp);
      }
      this.blockedDocument = false;
      this.visibleGuardarRpDialog = false;
    }
    this.formRpBtnSubmit = false;
    this.esEdicion = false;
  }

  aceptarEliminarRegistro() {
    this.visibleConfirmarEliminarRegistroDialogo = false;

    this.rpAsociadosList.forEach((item, index) => {
      if (item.cdp.id == this.rp.cdp.id && item.numero === this.rp.numero) this.rpAsociadosList.splice(index, 1);
    });

    this.blockedDocument = false;
  }

  GuardarAsociarRps() {
    this.blockedDocument = true;

    if (this.rpAsociadosList == null && this.rpAsociadosList.length == 0) {
      this.lanzarMensajeError("Debe relacionar los RPs a la comisión");
    } else {
      this.comisionesService.srvGuardarRPComision(this.idComision, this.idComisionVersion, this.rpAsociadosList).subscribe(
        result => {
          if (result != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);

              this.blockedDocument = false;
              this.cargarCdpList();
              this.cargarRpAsociados(this.idComisionVersion);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              this.cargarCdpList();
              // this.cargarRpAsociados(this.idComision);
              this.blockedDocument = false;
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
    }
  }

  limpiarFormularios() {
    this.formRp.reset();
  }

  bntVolver() {
    const ruta = "/comisiones/comisiones-gestion-presupuestal-comisiones";
    this.router.navigate([ruta]);
  }

  // // // // /Mensajes// // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

  get EnumCmsTipoAnexo() {
    return EnumCmsTipoAnexo;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

}
