import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PersonaService } from '../persona/persona.service';
import { ParametricasService } from '../../parametricas/parametricas.service';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { EnumTipoDocumento } from '../constantes/constantes-comunes';
import { TrnPersona } from '../../admin-personas/trn-persona';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-persona-juridica-extranjera',
  templateUrl: './persona-juridica-extranjera.component.html',
  styles: []
})
export class PersonaJuridicaExtranjeraComponent implements OnInit {

  @Input()
  idTipoDocumento: string;
  @Output()
  idPersonaJuridicaExtranjeraVar = new EventEmitter<number>();
  @Output()
  cancelarComponenteVar = new EventEmitter<boolean>();

  persona: any = {}; //TrnPersona
  numeroDocumentoBuscar: number;
  tipoDocumentoVar: any = [];
  personasList: any = [];
  habilitarCampos = false;
  personaExistente = false;

  // Informacion para los dialogos
  visibleDialogoExito = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;

  // Listas
  tiposDocumentoList: any = [];
  paises: any[];
  paisSeleccionado: any;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private svcPersonas: PersonaService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent
  ) { }


  ngOnInit() {
    this.cargarTiposDocumento();
    this.cargarPaises(null);

    if (this.idTipoDocumento != null) {
      this.persona.idTipoDocumento = this.idTipoDocumento;
    }
  }



  cargarTiposDocumento() {
    this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.NIEE)
      .subscribe((data: any) => {
        this.tiposDocumentoList = data;
        if (this.tiposDocumentoList != null && this.idTipoDocumento != null) {
          this.tipoDocumentoVar = this.tiposDocumentoList.find(t => String(t.id).toLowerCase().includes(this.idTipoDocumento.toLowerCase()));
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );

  }

  cargarPaises(event) {
    const filtroPaises = [];
    return this.parametricasService.getTrnPaises().subscribe(
      (result: any) => {
        this.paises = result;
        if (event != null) {
          let existeFiltro: boolean;
          for (const pais of this.paises) {
            if (String(pais.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroPaises.push(pais);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.paises = filtroPaises;
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }

  cargarPersonasJuridicaExtranjera() {
    return this.svcPersonas.getPersonasJurdicas()
      .subscribe(data => {
        this.personasList = data;
      });
  }

  validarNumeroDocumento() {
    let errorValidacion = false;
    if (this.numeroDocumentoBuscar == null) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar un número de documento' });
    }
    if (this.numeroDocumentoBuscar != null && String(this.numeroDocumentoBuscar).length == 0) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar un número de documento' });
    }

    return errorValidacion;
  }

  buscarPersonaJuridicaExtranjeraPorId(event) {
    if (!this.validarNumeroDocumento()) {
      if (this.numeroDocumentoBuscar != null) {
        this.svcPersonas.getPersonaJurdicaPorTipoNumeroDocumento(EnumTipoDocumento.NIEE, String(this.numeroDocumentoBuscar))
          .subscribe(result => {
            this.persona = result;
            //console.log(JSON.stringify(this.persona));
            if (this.persona == null) {
              this.persona = {};
              this.paisSeleccionado = {};
              this.persona.idTipoDocumento = this.idTipoDocumento;
              this.persona.numeroDocumento = this.numeroDocumentoBuscar;
              this.habilitarCampos = true;
              this.personaExistente = false;
            } else {
              this.personaExistente = true;
              this.paisSeleccionado = {
                id: this.persona.idPaisOrigenDocumento,
                nombre: this.persona.paisOrigenDocumento
              };
              this.habilitarCampos = false;
            }

            return this.persona;
          },
            error => {
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
            }
          );
      }
    }
  }

  guardarPersonaJuridicaExtranjera() {

    if (this.persona != null && !this.personaExistente) {

      const personJuridicaPost = {
        Id: (this.persona.id != null && this.persona.id !== 0 ? this.persona.id : 0),
        IdTipoDocumento: this.persona.idTipoDocumento,
        NumeroDocumento: this.persona.numeroDocumento,
        Nombre: this.persona.nombre,
        RepresentanteLegal: this.persona.representanteLegal,
        IdPaisOrigenDocumento: this.paisSeleccionado.id,
        DireccionDomicilio: this.persona.direccionDomicilio,
        NumeroCelular: this.persona.numeroCelular,
        CorreoElectronico: this.persona.correoElectronico,
        PaginaWeb: this.persona.paginaWeb,
        Observaciones: this.persona.observaciones
      };
      this.svcPersonas.setPersonaJuridica(personJuridicaPost)
        .subscribe((data: any) => {
          this.messageService.add({ severity: 'success', summary: 'Información', detail: data.resultado.message });
          this.habilitarCampos = false;
          this.idPersonaJuridicaExtranjeraVar.emit(data.resultado.id);
        },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
          }
        );
    } else {
      this.habilitarCampos = false;
      this.idPersonaJuridicaExtranjeraVar.emit(this.persona.id);
    }
  }


  cancelarAgregarPersonaJuridicaExtranjera(cancelar: boolean) {
    this.idTipoDocumento = null;
    this.persona = {};
    this.cancelarComponenteVar.emit(cancelar);
  }

}
