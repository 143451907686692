import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { EnumRespuestaServicio, EnumFechaFormato } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { UnidadFuncionalCarreteroService } from 'src/app/informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero/unidad-funcional-carretero.service';
import { ProyectoDTO } from 'src/app/modulo-planeacion-proyecto/planeacion-proyecto.dto';
import { CrrViaAfectadaDTO } from '../../ola-invernal.dto';
import { OlaInvernalService } from '../../ola-invernal.service';


@Component({
  selector: 'app-ola-invernal-registro-listado',
  templateUrl: './ola-invernal-registro-listado.component.html',
  styles: []
})
export class OlaInvernalRegistroListadoComponent implements OnInit {


  blockedDocument: boolean;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;
  listaCrrOlaInvernal: CrrViaAfectadaDTO[];
  cierreViaAfectada: CrrViaAfectadaDTO;


  dialogoEliminar: boolean;


  constructor(
    formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private olaInvernalService: OlaInvernalService,
    private componentesComunesService: ComponentesComunesService
  ) {
  }

  ngOnInit() {
    const idParam = "idProyecto";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
    });
    this.cargarinfoProyecto();

    this.cargarlistaCrrOlaInvernal();
  }

  cargarinfoProyecto() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }



  cargarlistaCrrOlaInvernal() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarCierresPorProyecto(this.idProyecto).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.listaCrrOlaInvernal = result.listaCrrOlaInvernal;
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  irAgregar() {
    this.router.navigate([this.router.url + "/ola-invernal-registro-registro", + this.idProyecto, 0, false]);
  }

  irConsultar(cierreViaAfectada: CrrViaAfectadaDTO) {
    this.router.navigate([this.router.url + "/ola-invernal-registro-registro", this.idProyecto, cierreViaAfectada.id, true]);
  }

  irEditar(cierreViaAfectada: CrrViaAfectadaDTO) {
    this.router.navigate([this.router.url + "/ola-invernal-registro-registro", this.idProyecto, cierreViaAfectada.id, false]);
  }

  irEliminar(cierreViaAfectada: CrrViaAfectadaDTO) {
    this.cierreViaAfectada = cierreViaAfectada;
    this.blockedDocument = true;
    this.dialogoEliminar = true;
  }

  eliminarReporteCierreOlaInvernal() {
    this.dialogoEliminar = false;
    if (this.cierreViaAfectada != null && this.cierreViaAfectada.id != 0) {
      this.olaInvernalService.srvEliminarCierreOlaInvernalPorId(this.cierreViaAfectada.id).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarlistaCrrOlaInvernal();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  bntVolver() {
    const ruta = "/carreteros/infGeneral/proyectoHome";
    this.router.navigate([ruta, this.idProyecto]);
  }

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }


}
