import { Injectable } from '@angular/core';
import { map, retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PjeEncabezadoTarifaRpt } from './PjeEncabezadoTarifa';
import { PjeDetalleTarifaRpt } from './PjeDetalleTarifa';
import { PjeMaTarifaRpt } from './PjeMaTarifa';
import { PjeDocumentosRpt } from './PjeDocumentos';
import { TrnRespuestaServicioRpt } from '../../../parametricas/rpt-respuesta-operacion';
import { TrnRespuestaServicio } from '../../../parametricas/trn-respuesta-servicio';
import { environment } from '../../../../environments/environment';
import { ConfigService } from '../../../config.service';
import { PjeRecaudoPorEliminarRpt } from './PjeRecaudoPorEliminar';

@Injectable({
    providedIn: 'root'
})
export class NuevaTarifaPeajesService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    cargaEncabezado(idPeaje: number, esLectura: boolean, idEncabezado: number) {
        return this.http.get<PjeEncabezadoTarifaRpt>(this.END_POINT + '/PjeTarifasPeajes/getEncabezadoTarifa/' + idPeaje + '/' + esLectura + '/' + idEncabezado)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    cargaDetalleTarifas(idPeaje: number, esLectura: boolean, idEncabezado: number) {
        return this.http.get<PjeDetalleTarifaRpt>(this.END_POINT + '/PjeTarifasPeajes/getDetalleTarifa/' + idPeaje + '/' + esLectura + '/' + idEncabezado)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    consultaDocumentos(idPeaje: number, esLectura: boolean, idEncabezado: number) {
        return this.http.get<PjeDocumentosRpt>(this.END_POINT + '/PjeTarifasPeajes/getDocumentos/' + idPeaje + '/' + esLectura + '/' + idEncabezado)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    consultaDocumentosHistoricos(fechaExpedicion: string, numeroResolucion: string, tipoSoporte: number, busquedaFlexible: number) {
        return this.http.get<PjeDocumentosRpt>(this.END_POINT + '/PjeTarifasPeajes/getDocumentosHistorico/' + fechaExpedicion + '/' + numeroResolucion + '/' + tipoSoporte + '/' + busquedaFlexible)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    eliminarDetalleTarifa(idTarifa: number): Observable<TrnRespuestaServicioRpt> {
        return this.http.delete<TrnRespuestaServicioRpt>(this.END_POINT + '/PjeTarifasPeajes/eliminaCategoria/' + idTarifa)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    eliminarDocumentoTarifa(idDocumentoTarifa: number): Observable<TrnRespuestaServicioRpt> {
        return this.http.delete<TrnRespuestaServicioRpt>(this.END_POINT + '/PjeTarifasPeajes/eliminaDocumento/' + idDocumentoTarifa)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    consultaRecaudo(idEncabezado: number) {
        return this.http.get<PjeRecaudoPorEliminarRpt>(this.END_POINT + '/PjeTarifasPeajes/consultaRecaudos/' + idEncabezado)
        .pipe(
            map(data => {
                return data;
            }),
            retry(1));
    }

    cargarTarifas() {
        return this.http.get<PjeMaTarifaRpt>(this.END_POINT + '/PjeTarifasPeajes/getTarifas/')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    public guardarDetalleTarifa(data) {
        return this.http.post(this.END_POINT + '/PjeTarifasPeajes/guardarDetalleTarifa', data)
            .pipe(
                retry(1));
    }

    public guardarEncabezdo(data) {
        return this.http.post(this.END_POINT + '/PjeTarifasPeajes/guardarEncabezado', data)
            .pipe(
                retry(1));
    }

    public guardarDocumento(data) {
        return this.http.post(this.END_POINT + '/PjeTarifasPeajes/guardarDocumento', data)
            .pipe(
                retry(1));
    }

    public publicarTarifa(data) {
        return this.http.post(this.END_POINT + '/PjeTarifasPeajes/publicarTarifa', data)
            .pipe(
                retry(1));
    }

    public cancelarBorrador(data) {
        return this.http.post(this.END_POINT + '/PjeTarifasPeajes/cancelarBorrador', data)
            .pipe(
                retry(1));
    }


    public asociarDocumento(idEncabezado: number, idDocumento: number): Observable<RespuestaServicioRpt> {
        return this.http.put(this.END_POINT + '/PjeTarifasPeajes/asociarDocumento/' + idEncabezado + '/' + idDocumento, idEncabezado)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    

}

export class RespuestaServicioRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}
