import { Component, OnInit, Input } from '@angular/core';
import { InformeContratistaEncabezadoService } from './informe-contratista-encabezado.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CtoInformeContratistaEncabezadoDTO } from '../../contratacion-dto/informe-contratista-encabezado';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio, EnumTipoDocumento, EnumTrnConstante, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { EnumInvInventario } from 'src/app/inventario-equipos/inventario-constantes';
@Component({
  selector: 'app-informe-contratista-encabezado',
  templateUrl: './informe-contratista-encabezado.component.html',
  styles: []
})
export class InformeContratistaEncabezadoComponent implements OnInit {

  @Input()
  idInforme: number;
  @Input()
  idPersonaAsignacion: number;
  ctoInformeContratistaEncabezado: CtoInformeContratistaEncabezadoDTO = {};

  constructor(
    private informeContratistaEncabezadoService: InformeContratistaEncabezadoService,
    private route: Router,
    private ruta: ActivatedRoute,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarEncabezado(this.idInforme, this.idPersonaAsignacion);
  }

  cargarEncabezado(idInforme: number, idPersonaAsignacion: number) {
    this.informeContratistaEncabezadoService.ConsultarInformeContratista(idInforme, idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.ctoInformeContratistaEncabezado = result.informeContratista;
            } else {
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  get EnumTipoDocumento() {
    return EnumTipoDocumento;
  }

  get EnumTrnConstante() {
    return EnumTrnConstante;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumInvInventario() {
    return EnumInvInventario;
  }

}