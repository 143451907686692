import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ProyectoDTO } from '../componentes-comunes/componentes-comunes.dto';
import { ComponentesComunesService } from '../componentes-comunes/componentes-comunes.service';
import { PrincipalComponent } from '../home/principal/principal.component';
import { ContratoCarretero } from '../informacion-general-carretero/informacion-contractual/informacion-contractual-carretero.dto';
import { InformacionContractualCarreteroService } from '../informacion-general-carretero/informacion-contractual/informacion-contractual-carretero.service';

@Component({
  selector: 'app-proyecto-informacion-vigente',
  templateUrl: './proyecto-informacion-vigente.component.html',
  styles: []
})
export class ProyectoInformacionVigenteComponent implements OnInit, OnChanges {

  @Input()
  idProyecto: number;
  @Input()
  fijarAcordeon = true;
  @Output()
  proyectoOutput = new EventEmitter<ProyectoDTO>();

  proyecto: ProyectoDTO;
  contratoConcesionario: ContratoCarretero;
  contratoInterventoria: ContratoCarretero

  constructor(
    private componentesComunesService: ComponentesComunesService,
    private informacionContractualCarreteroService: InformacionContractualCarreteroService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarinfoProyecto();
  }


  cargarinfoProyecto() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.informacionContractualCarreteroService.listarContratosPorProyecto(this.idProyecto),
    ).subscribe(
      ([proyectoData, contratosData]) => {
        this.proyecto = proyectoData;
        if (contratosData != null) {
          this.contratoConcesionario = contratosData.contratos.find(item => item.idTipoContrato == 1);
          this.contratoInterventoria = contratosData.contratos.find(item => item.idTipoContrato == 2);
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }


  ngOnChanges() {
    this.cargarinfoProyecto();
  }
}
