import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { InformacionContractualCarreteroService } from '../informacion-contractual-carretero.service';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ContratoCarretero } from '../informacion-contractual-carretero.dto';
import { InformacionGeneralCarreteroService } from '../../informacion-general-carretero.service';
import { ProyectoCarretero } from 'src/app/informacion-proyecto-carretero/proyectoCarretero';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-contratatos-carretero',
  templateUrl: './contratos-carretero.component.html',
  styleUrls: ['./contratos-carretero.component.sass']
})
export class ContratosCarreteroComponent implements OnInit {

  blockedDocument: boolean = false;
  public contratos: ContratoCarretero[] = [];
  private idProyecto: number;
  public nombreProyecto: string;
  proyectoCarretero: ProyectoCarretero = {};

  constructor(private informacionContractualCarreteroService: InformacionContractualCarreteroService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private informacionGeneralCarreteroService: InformacionGeneralCarreteroService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idProyecto = params.id;
      this.cargarProyecto(this.idProyecto);
      this.cargarContratos();
    });
  }

  cargarProyecto(id: number) {
    this.informacionGeneralCarreteroService.getProyectoPorId(id).subscribe((data: {}) => {
      this.proyectoCarretero = data;
      this.nombreProyecto = this.proyectoCarretero.nombre;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarContratos() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.listarContratosPorProyecto(this.idProyecto)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.contratos = result.contratos;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irAgregarNuevoContrato() {
    this.ruta.navigate([this.ruta.url + '/contrato-contratista-carretero', this.idProyecto, 0, 0]);
  }

  gestionarContrato(idContrato, idContratista) {
    if (idContratista == null) {
      idContratista = 0;
    }
    this.ruta.navigate([this.ruta.url + '/contrato-contratista-carretero', this.idProyecto, idContrato, idContratista]);
  }

  irModificacionesContractuales(idContrato) {
    this.ruta.navigate([this.ruta.url + '/modificacion-contractual-carretero', this.idProyecto, idContrato]);
  }

}
