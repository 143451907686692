import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PersonaService } from "../persona/persona.service";
import { ParametricasService } from "../../parametricas/parametricas.service";
import { MessageService } from "primeng/api";
import { EnumRegex, EnumRespuestaServicio, EnumTipoDocumento } from "src/app/comun/constantes/constantes-comunes";
import { NgForm } from "@angular/forms";
import { TrnPersona } from "src/app/admin-personas/trn-persona";
import { PrsFuncionarioDTO } from "src/app/admin-personas/prs-funcionario";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-persona-natural",
  templateUrl: "./persona-natural.component.html",
  styles: [],
})
export class PersonaNaturalComponent implements OnInit {
  @Input()
  idTipoDocumento: string;
  @Input()
  habilitarComponenteFuncionario: boolean;
  @Output()
  idPersonaNaturalVar = new EventEmitter<number>();
  @Output()
  cancelarComponenteVar = new EventEmitter<boolean>();

  persona: TrnPersona = {};
  funcionario: PrsFuncionarioDTO;

  numeroDocumentoBuscar: string;
  tipoDocumentoVar: any = [];
  personasList: any = [];
  habilitarCampos = false;
  deshabilitarCamposFuncionario = false;
  personaExistente = false;
  esFuncionario = false;
  esFuncionarioConsulta = false;

  // Informacion para los dialogos
  visibleDialogoExito = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;

  // Listas
  tiposDocumentoList: any = [];
  // tiposDocumentoSeleccionado: any;
  filtroDepartamentos: any[];
  departamentos: any[];
  departamentoSeleccionado: any;
  filtroCiudades: any[];
  ciudadSeleccionada: any;
  ciudades: any[] = [];
  dependenciasPrimerNivel: any[] = [];
  dependenciaPrimerNivel: any = null;
  dependenciasSegundoNivel: any[] = [];
  dependenciaSegundoNivel: any = null;

  dependenciasPrimerNivel2: any[] = [];
  dependenciaPrimerNivel2: any = null;
  dependenciasSegundoNivel2: any[] = [];
  dependenciaSegundoNivel2: any = null;

  cargos: any[];
  cargoSeleccionado: any = null;
  fechaIngreso: Date;
  asignacionBasicaMensual: number = null;

  ngOnInit() {
    this.funcionario = {};
    this.funcionario.funcionarioHistorico = {};
    this.dependenciaPrimerNivel = {};
    this.dependenciaSegundoNivel = {};
    this.dependenciaPrimerNivel2 = {};
    this.dependenciaSegundoNivel2 = {};
    this.cargoSeleccionado = {};
    this.cargarDependenciasPrimerNivel(null);

    this.cargarCargos(null);
    this.cargarTiposDocumento();
    if (this.idTipoDocumento != null) {
      this.persona.idTipoDocumento = this.idTipoDocumento;
    }
  }

  constructor(
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private svcPersonas: PersonaService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent,
    public datepipe: DatePipe
  ) { }

  cargarTiposDocumento() {
    this.parametricasService
      .getTrnTiposDocumentoPorIds(EnumTipoDocumento.NIT)
      .subscribe(
        (data) => {
          this.tiposDocumentoList = data;
          if (this.tiposDocumentoList != null && this.idTipoDocumento != null) {
            this.tipoDocumentoVar = this.tiposDocumentoList.find((t) => String(t.id).toLowerCase().includes(this.idTipoDocumento.toLowerCase())
            );
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }


  validarComponenteHabilitaFuncionario(sinoSeleccion) {
    if (sinoSeleccion != null && sinoSeleccion != undefined) {
      this.esFuncionario = sinoSeleccion.valor;
    }
  }

  validarNumeroDocumento() {
    let errorValidacion = false;
    if (this.numeroDocumentoBuscar == null) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar un número de documento' });
    }
    if (this.numeroDocumentoBuscar != null && String(this.numeroDocumentoBuscar).length == 0) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar un número de documento' });
    }
    return errorValidacion;
  }

  buscarPersonaNaturalPorTipoNumeroDocumento(event) {
    if (!this.validarNumeroDocumento()) {
      if (this.numeroDocumentoBuscar != null) {
        this.svcPersonas.getPersonaNaturalPorTipoNumeroDocumento(EnumTipoDocumento.CC, String(this.numeroDocumentoBuscar)).subscribe(
          (result) => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                if (result.persona == null) {
                  this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'La persona con Número de Documento: ' + this.numeroDocumentoBuscar + ', NO se encuentra registrada en el sistema. Por favor registrelo ', life: 10000 });
                  this.persona = {};
                  this.persona.idTipoDocumento = this.idTipoDocumento;
                  this.persona.numeroDocumento = this.numeroDocumentoBuscar;
                  this.habilitarCampos = true;
                  this.personaExistente = false;
                  //Campos info funcionario
                  this.deshabilitarCamposFuncionario = false;
                  this.dependenciaPrimerNivel = {};
                  this.dependenciaSegundoNivel = {};
                  this.cargoSeleccionado = {};
                  this.fechaIngreso = null;
                  this.asignacionBasicaMensual = null;
                } else {
                  this.persona = result.persona;
                  this.personaExistente = true;
                  this.habilitarCampos = false;

                  //Logica info funcionario
                  this.svcPersonas.GetInformacionFuncionarioIdPersona(this.persona.id).subscribe(
                    (result) => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          if (result.funcionarioHistorico != null) {
                            this.esFuncionario = true;
                            this.esFuncionarioConsulta = true;
                            this.deshabilitarCamposFuncionario = true;
                            this.fechaIngreso = new Date(result.funcionarioHistorico.fechaIngreso);

                            this.dependenciaPrimerNivel = this.dependenciasPrimerNivel.filter((p) => p.id === result.funcionarioHistorico.idDependencia)[0];
                            this.cargarDependenciasSegundoNivelFiltro(result.funcionarioHistorico.idDependenciaEspecifica);

                            this.cargoSeleccionado = this.cargos.filter((p) => p.id === result.funcionarioHistorico.idCargo)[0];
                            this.asignacionBasicaMensual = result.funcionarioHistorico.ingresosMensuales;
                          } else {
                            //Campos info funcionario
                            this.dependenciaPrimerNivel = {};
                            this.dependenciaSegundoNivel = {};
                            this.cargoSeleccionado = {};
                            this.fechaIngreso = null;
                            this.asignacionBasicaMensual = null;
                            this.deshabilitarCamposFuncionario = false;
                          }
                        } else {
                          console.error(result.respuestaServicio.mensajeSalida);
                          this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                        }
                      }
                    },
                    (error) => {
                      console.error(error);
                      this.principalComponent.cargarErrorServicio(error);
                    },
                    () => {
                    }
                  );
                }
              } else {
                console.error(result.respuestaServicio.mensajeSalida);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              }
            }
          },
          (error) => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
          }
        );
      }
    }
  }

  validarPersona() {
    let errorValidacion = false;
    if (this.numeroDocumentoBuscar == null) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar un número de documento' });
    }
    if (this.numeroDocumentoBuscar != null && String(this.numeroDocumentoBuscar).length == 0) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar un número de documento' });
    }
    if (this.numeroDocumentoBuscar != null && String(this.numeroDocumentoBuscar).length <= 4) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El número de documento solo acepta entre 5 y 10 caracteres' });
    }
    if (this.numeroDocumentoBuscar != null && String(this.numeroDocumentoBuscar).length <= 4) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El número de documento solo acepta números y no puede iniciar en 0.' });
    }
    if (this.persona.primerNombre != null && String(this.persona.primerNombre).length == 0) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar el primer nombre' });
    }
    if (this.persona.primerApellido != null && String(this.persona.primerApellido).length == 0) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar el primer apellido' });
    }
    return errorValidacion;
  }


  guardarPersonaNatural(forma: NgForm) {
    if (!this.validarPersona()) {
      if (this.persona != null && !this.personaExistente) {
        this.svcPersonas.GuardarPersonaNatural(this.persona).subscribe(
          (result) => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.habilitarCampos = false;
                this.persona = result.persona;
                this.idPersonaNaturalVar.emit(result.persona.id);
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });

                //Campos info funcionario
                if (this.esFuncionario && !this.deshabilitarCamposFuncionario) {
                  this.funcionario.fechaIngreso = this.fechaIngreso;
                  this.funcionario.idPersona = this.persona.id;
                  this.funcionario.funcionarioHistorico.fechaIngreso = this.fechaIngreso;
                  this.funcionario.funcionarioHistorico.idDependencia =
                    this.dependenciaPrimerNivel.id;
                  this.funcionario.funcionarioHistorico.idDependenciaEspecifica =
                    this.dependenciaSegundoNivel.id;
                  this.funcionario.funcionarioHistorico.ingresosMensuales =
                    this.asignacionBasicaMensual;
                  this.funcionario.funcionarioHistorico.idCargo =
                    this.cargoSeleccionado.id;

                  this.svcPersonas.GuardarFuncionario(this.funcionario).subscribe(
                    (result) => {
                      console.log("result");
                      console.log(result);
                      if (result != null && result.respuestaServicio != null) {
                        if (
                          result.respuestaServicio.codigoSalida ===
                          EnumRespuestaServicio.CODIGO_EXITO_OPERACION
                        ) {
                          this.habilitarCampos = false;
                          this.funcionario = result.funcionario;
                          this.idPersonaNaturalVar.emit(result.funcionario.idPersona);
                          //this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                        } else {
                          console.error(result.respuestaServicio.mensajeSalida);
                          this.messageService.add({
                            severity: "error",
                            summary: "Error",
                            detail: result.respuestaServicio.mensajeSalida,
                            life: 10000,
                          });
                        }
                      }
                    },
                    (error) => {
                      console.error(error);
                      this.principalComponent.cargarErrorServicio(error);
                    },
                    () => { }
                  );
                }
              } else {
                console.error(result.respuestaServicio.mensajeSalida);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              }
            }
          },
          error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
          }
        );
      } else {
        this.habilitarCampos = false;
        this.idPersonaNaturalVar.emit(this.persona.id);

        //Campos info funcionario
        if (this.esFuncionario && !this.deshabilitarCamposFuncionario) {
          this.funcionario.fechaIngreso = this.fechaIngreso;
          this.funcionario.idPersona = this.persona.id;
          this.funcionario.funcionarioHistorico.fechaIngreso = this.fechaIngreso;
          this.funcionario.funcionarioHistorico.idDependencia =
            this.dependenciaPrimerNivel.id;
          this.funcionario.funcionarioHistorico.idDependenciaEspecifica =
            this.dependenciaSegundoNivel.id;
          this.funcionario.funcionarioHistorico.ingresosMensuales =
            this.asignacionBasicaMensual;
          this.funcionario.funcionarioHistorico.idCargo =
            this.cargoSeleccionado.id;

          this.svcPersonas.GuardarFuncionario(this.funcionario).subscribe(
            (result) => {
              console.log("result");
              console.log(result);
              if (result != null && result.respuestaServicio != null) {
                if (
                  result.respuestaServicio.codigoSalida ===
                  EnumRespuestaServicio.CODIGO_EXITO_OPERACION
                ) {
                  this.habilitarCampos = false;
                  this.funcionario = result.funcionario;
                  this.idPersonaNaturalVar.emit(result.funcionario.idPersona);
                  //this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                  console.error(result.respuestaServicio.mensajeSalida);
                  this.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: result.respuestaServicio.mensajeSalida,
                    life: 10000,
                  });
                }
              }
            },
            (error) => {
              console.error(error);
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {

            }
          );
        }

      }


    }
  }

  limpiarSegundoNivel() {
    this.dependenciaSegundoNivel = null;
  }

  cargarCargos(event: any) {
    const filtro = [];
    return this.parametricasService.getTrnCargos().subscribe(
      (result: any) => {
        this.cargos = result.cargos;
        if (this.cargos != null && event != null) {
          let existeFiltro: boolean;
          this.cargos.forEach((cargo) => {
            if (
              String(cargo.nombre)
                .toLowerCase()
                .includes(event.query.toLowerCase())
            ) {
              filtro.push(cargo);
              existeFiltro = true;
            }
          });

          if (existeFiltro) {
            this.cargos = filtro;
          }
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => { }
    );
  }

  cargarDependenciasPrimerNivel(event: any) {
    const filtroDependencias = [];
    return this.parametricasService
      .srvListarDependenciasPorIdPadre(1)
      .subscribe(
        (respuesta: any) => {
          if (
            respuesta.respuestaServicio.codigoSalida ==
            EnumRespuestaServicio.CODIGO_ERROR_OPERACION
          ) {
            this.principalComponent.cargarErrorServicio(
              respuesta.respuestaServicio.mensajeSalida
            );
            return;
          }

          this.dependenciasPrimerNivel = respuesta.dependencias;

          if (this.dependenciasPrimerNivel != null && event != null) {
            let existeFiltro: boolean;
            this.dependenciasPrimerNivel.forEach((dependencia) => {
              if (
                String(dependencia.nombre)
                  .toLowerCase()
                  .includes(event.query.toLowerCase())
              ) {
                filtroDependencias.push(dependencia);
                existeFiltro = true;
              }
            });

            if (existeFiltro) {
              this.dependenciasPrimerNivel = filtroDependencias;
            }
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarDependenciasSegundoNivel(event: any) {
    if (this.dependenciaPrimerNivel != null) {
      const filtroDependencias = [];

      this.parametricasService
        .ConsultarDependenciasAniPorIdDependenciaPrimerNivel(
          this.dependenciaPrimerNivel.id
        )
        .subscribe(
          (respuesta: any) => {
            this.dependenciasSegundoNivel = respuesta;

            console.log("respuesta;");
            console.log(respuesta);
            if (this.dependenciaSegundoNivel != null && event != null) {
              let existeFiltro: boolean;
              this.dependenciasSegundoNivel.forEach((dependencia) => {
                if (
                  String(dependencia.nombre)
                    .toLocaleLowerCase()
                    .includes(event.query.toLowerCase())
                ) {
                  filtroDependencias.push(dependencia);
                  existeFiltro = true;
                }
              });

              if (existeFiltro) {
                this.dependenciasSegundoNivel = filtroDependencias;
              }
            }
          },
          (error) => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => { }
        );
    }
  }

  cargarDependenciasSegundoNivelFiltro(idDependencia: any) {
    if (this.dependenciaPrimerNivel != null) {
      const filtroDependencias = [];

      this.parametricasService
        .ConsultarDependenciasAniPorIdDependenciaPrimerNivel(
          this.dependenciaPrimerNivel.id
        )
        .subscribe(
          (respuesta: any) => {
            this.dependenciasSegundoNivel = respuesta;

            console.log("this.dependenciasSegundoNivel");
            console.log(this.dependenciasSegundoNivel);

            this.dependenciaSegundoNivel = this.dependenciasSegundoNivel.filter(
              (p) => p.id === idDependencia
            )[0];

            console.log("this.dependenciaSegundoNivel");
            console.log(this.dependenciaSegundoNivel);
          },
          (error) => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
          }
        );
    }
  }

  cancelarAgregarPersonaNatural(cancelar: boolean) {
    this.idTipoDocumento = null;
    this.persona = {};
    this.cancelarComponenteVar.emit(cancelar);
  }

  get EnumRegex() {
    return EnumRegex;
  }
}
