
export class InformacionGeneral {
    constructor(
        public id?: number, 
        public idPoliza?: number, 
        public fechaExpedicion?: Date,
        public idNovedadContrato?: number, 
        public novedadContrato?: string,               
        public numero?: string,        
        public orden?: number,        
        public objeto?: string,        
        public observacion?: string,
        public idMonedaPoliza?: string,
        public amparos?: Amparos []
        ) {}

}

export class InformacionGeneralEnvioEdit {
    constructor(
        public id?: number, 
        public idPoliza?: number, 
        public idNovedadContrato?: number, 
        public fechaExpedicion?: Date,
        public observacion?: string,
        public amparos?: AmparosEnvio []
        ) {}
}


export class AmparosEnvio {
    constructor(
        public idTipoAmparo?: number,         
        public descripcion?: string, 
        public valorAmparo?: number, 
        public fechaInicioCobertura?: Date, 
        public fechaFinCobertura?: Date
        ) {}
}


export class InformacionGeneralEnvio {
    constructor(
        public idPoliza?: number, 
        public idNovedadContrato?: number, 
        public fechaExpedicion?: Date,
        public observacion?: string,
        public amparos?: AmparosEnvio []
        ) {}
}

export class Amparos {
    constructor(
        public id?: number, 
        public idAnexo?: number, 
        public idTipoAmparo?: number, 
        public tipoAmparo?: string, 
        public descripcion?: string, 
        public valorAmparo?: number, 
        public valorAmparoStr?: string, 
        public fechaInicioCobertura?: Date, 
        public fechaFinCobertura?: Date,
        public fechaFinCoberturaStr?: string, 
        public fechaInicioCoberturaStr?: string
        ) {}
}