import { Component, OnInit, ViewChild } from '@angular/core';
import { SaldoFinancieroIndexado } from './saldo-financiero-indexado';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { InformacionGeneralService } from 'src/app/informacion-general/informacion-general.service';
import { SaldoFinancieroService } from './saldo-financiero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-saldo-financiero',
  templateUrl: './saldo-financiero.component.html',
  styleUrls: ['./saldo-financiero.component.sass']
})
export class SaldoFinancieroComponent implements OnInit {

  saldosFinancierosList: any = [];

  displayedColumns: any[];

  dataSource: MatTableDataSource<SaldoFinancieroIndexado>;

  saldoFinancieroIndexadoSeleccionado: SaldoFinancieroIndexado;

  idProyecto: number;
  proyecto: any;

  idSaldoFinanciero: any;

  visibleAgregarSaldoFinancieroIndexado: boolean = false;
  visibleDialogoAdvertenciaFecha: boolean = false;
  visibleDialogoExito: boolean = false;
  visibleConfirmarEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarSaldoFinanciero: boolean = false;

  datosSaldoFinancieroIndexado: SaldoFinancieroIndexado = {};

  origenSaldos: any = [];
  filtroOrigenSaldos: any[];
  origenSaldoSeleccionado: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private infoService: InformacionGeneralService, private saldoFinancieroService: SaldoFinancieroService, private route: Router, private ruta: ActivatedRoute, private parametricasService: ParametricasService,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
      this.cargarSaldoFinanciero(this.idProyecto);
    });
    this.displayedColumns = [
      { field: 'nombreOrigen', header: 'Fuente' },
      { field: 'formatoFecha', header: 'Fecha de corte' },
      { field: 'formatoValorPesos', header: 'Valor en pesos' },
      { field: 'formatoValorDolares', header: 'Valor en dolares' }
    ];
    /* var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto); */
  }

  cargarOrigenSaldos(event) {
    this.filtroOrigenSaldos = [];
    return this.parametricasService.getOrigenSaldo().subscribe((data: {}) => {
      this.origenSaldos = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.origenSaldos.length; i++) {
        let filtroOrigenSaldo = this.origenSaldos[i];
        let item: string = String(filtroOrigenSaldo.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
         this.filtroOrigenSaldos.push(filtroOrigenSaldo);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.origenSaldos = this.filtroOrigenSaldos;
      }
    });
  }

  cargarFiltroOrigenSaldos(event) {
    this.filtroOrigenSaldos = [];
    for (let i = 0; i < this.origenSaldos.length; i++) {
      let filtro = this.origenSaldos[i];
       let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroOrigenSaldos.push(filtro);
      }
    }
  }

  cargarSaldoFinanciero(id: number) {
    return this.saldoFinancieroService.getSaldoFinancieroIndexado(id).subscribe((data: {}) => {
      this.saldosFinancierosList = data;
     this.dataSource = new MatTableDataSource(this.saldosFinancierosList);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAgregarSaldoFinancieroIdexado() {
    this.visibleAgregarSaldoFinancieroIndexado = true;
    this.idSaldoFinanciero = "new";
    this.datosSaldoFinancieroIndexado = {};
    this.origenSaldoSeleccionado = null;
  }

  cancelarAgregarSaldoFinanceroIdexado() {
    this.visibleAgregarSaldoFinancieroIndexado = false;
  }

  guardarInformacionContratoCreditoIndexado(forma: NgForm) {
    this.datosSaldoFinancieroIndexado.idOrigen = this.origenSaldoSeleccionado.id;
    if (this.idSaldoFinanciero === 'new') {
       this.datosSaldoFinancieroIndexado.idProyecto = this.idProyecto;
      let vara: any = this.saldoFinancieroService.guardarSaldoFinancieroIndexado(this.datosSaldoFinancieroIndexado).subscribe((data: {}) => {
        let respuesta: SaldoFinancieroIndexado = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
          this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.saldoFinancieroService.editarSaldoFinancieroIndexado(this.datosSaldoFinancieroIndexado, this.idSaldoFinanciero).subscribe((data: {}) => {
        let respuesta: SaldoFinancieroIndexado = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
          this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  aceptarDialogoAdvertenciaFecha() {
    this.visibleDialogoAdvertenciaFecha = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.cargarSaldoFinanciero(this.idProyecto);
    this.visibleAgregarSaldoFinancieroIndexado = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editarSaldoFinancieroIndexado(saldoFinancieroIndexado: SaldoFinancieroIndexado) {
    this.saldoFinancieroIndexadoSeleccionado = saldoFinancieroIndexado;
    this.idSaldoFinanciero = saldoFinancieroIndexado.id;
    this.visibleAgregarSaldoFinancieroIndexado = true;
    return this.saldoFinancieroService.getSaldoFinancieroIndexadoPorId(saldoFinancieroIndexado.id).subscribe((data: {}) => {
      this.datosSaldoFinancieroIndexado = data;
      this.origenSaldoSeleccionado = this.datosSaldoFinancieroIndexado.idOrigenNavigation;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irEliminarSaldoFinancieroIndexado(saldoFinancieroIndexado: SaldoFinancieroIndexado) {
    this.saldoFinancieroIndexadoSeleccionado = saldoFinancieroIndexado;
    this.visibleConfirmarEliminarRegistro = true;
    this.idSaldoFinanciero = this.saldoFinancieroIndexadoSeleccionado.id;
  }

  cancelarEliminarSaldoFinancieroIndexado() {
    this.visibleConfirmarEliminarRegistro = false;
  }

  aceptarEliminarSaldoFinancieroIndexado() {
    return this.saldoFinancieroService.eliminarSaldoFinancieroIndexadoPorId(this.idSaldoFinanciero).subscribe((data: {}) => {
      this.cargarSaldoFinanciero(this.idProyecto);
      this.visibleDialogoExitoEliminarRegistro = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarSaldoFinancieroIndexada(){
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleConfirmarEliminarRegistro = false;
  }

}
