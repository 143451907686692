export class InformacionGeneralAnexos {
    constructor(
        public id?: number, 
        public idPoliza?: number, 
        public idNovedadContrato?: number,  
        public orden?: number,
        public novedadContrato?: string,
        public numero?: string,
        public objeto?: string,               
        public observacion?: string,   
        public fechaExpedicion?: Date) {}
}



export class Anexo {
    constructor(
        public id?: number, 
        public idPoliza?: number, 
        public fechaExpedicion?: Date,
        public fechaExpedicionStr?: string,
        public idMonedaPoliza?: string, 
        public idNovedadContrato?: number,               
        public monedaPoliza?: string,        
        public novedadContrato?: string,        
        public numero?: string,        
        public objeto?: string,
        public observacion?: string,
        public orden?: string,
        public habilitarModificar?: boolean,
        public habilitarEditar?: boolean,
        public habilitarEliminar?: boolean
        ) {}

}
