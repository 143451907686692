import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-menu-peajes-externos',
  templateUrl: './menu-peajes-externos.component.html',
  styleUrls: ['./menu-peajes-externos.component.sass']
})

export class MenuPeajesExternosComponent implements OnInit {

  idProyecto: number;

  constructor(private router: Router, private route: ActivatedRoute, private messageService: MessageService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idProyecto = params.id;
    });


  }


  irAReportarTraficoPeajes() {
    this.router.navigate([this.router.url + '/peajes-externos', this.idProyecto]);
  }

  irATarifas() {
    this.router.navigate([this.router.url + '/tarifas-peajes', this.idProyecto]);
  }

  irAInformacionGeneralPeajes(){
    this.router.navigate([this.router.url + '/informacion-general-peajes', this.idProyecto]);
  }
}
