import { Component, OnInit } from '@angular/core';
import { loadModules } from "esri-loader";
import { Router, ActivatedRoute } from "@angular/router";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { CrrPeajesTarifasDTO, CrrPeajesTarifasEncDTO, CrrPeajesDTO } from "src/app/reportes/carreteros/reportes-peajes.dto";
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { formatDate } from "@angular/common";
import * as Highcharts from "highcharts/highstock";

@Component({
  selector: 'app-rpt-crr-peajes-detalle',
  templateUrl: './rpt-crr-peajes-detalle.component.html',
  styleUrls: ['./rpt-crr-peajes-detalle.component.sass']
})
export class RptCrrPeajesDetalleComponent implements OnInit {
  blockedDocument = false;
  idPeaje: number = 0;
  idEncabezado: number = 0;
  optionschart2: any;
  informacionTarifasCompleta: CrrPeajesTarifasDTO[] = [];
  informacionTarifasCategoriaI: CrrPeajesTarifasDTO[] = [];
  informacionTarifasCategoriaII: CrrPeajesTarifasDTO[] = [];
  informacionTarifasCategoriaIII: CrrPeajesTarifasDTO[] = [];
  informacionTarifasCategoriaIV: CrrPeajesTarifasDTO[] = [];
  informacionTarifasCategoriaV: CrrPeajesTarifasDTO[] = [];
  informacionTarifasCategoriaVI: CrrPeajesTarifasDTO[] = [];
  informacionTarifasCategoriaVII: CrrPeajesTarifasDTO[] = [];

  informacionTarifasAnio1: CrrPeajesTarifasDTO[] = [];
  informacionTarifasAnio2: CrrPeajesTarifasDTO[] = [];
  informacionTarifasAnio3: CrrPeajesTarifasDTO[] = [];

  informacionGeneralPeajes: CrrPeajesDTO[] = [];
  informacionTarifasEnc: CrrPeajesTarifasEncDTO[] = [];
  valorCategoriaI: number = 0;
  valorCategoriaII: number = 0;
  valorCategoriaIII: number = 0;
  valorCategoriaIV: number = 0;
  valorCategoriaV: number = 0;
  valorCategoriaVI: number = 0;
  valorCategoriaVII: number = 0;

  valorCategoriaIE: number = 0;
  valorCategoriaIIE: number = 0;
  valorCategoriaIIIE: number = 0;
  valorCategoriaIVE: number = 0;
  valorCategoriaVE: number = 0;
  valorCategoriaVIE: number = 0;
  valorCategoriaVIIE: number = 0;

  fechaCategoriaI: string;

  fechaCategoriaII: string;
  fechaCategoriaIII: string;
  fechaCategoriaIV: string;
  fechaCategoriaV: string;
  fechaCategoriaVI: string;
  fechaCategoriaVII: string;
  

  nombrePeaje: string;
  fechaInicioTarifa: Date;
  fechaFinTarifa: Date;


  constructor(private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent,
  
    private ruta: ActivatedRoute) { }

  ngOnInit() {

    this.blockedDocument = true;
    this.ruta.params.subscribe((params) => {
      this.idPeaje = params.idPeaje;
      this.idEncabezado = params.idEncabezado;
    });

    
    this.reportesCarreteroService.getInformacionCrrPeajesTarifasEnc(this.idPeaje).subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.informacionTarifasEnc = dato.encTarifas;
         
      
            const tarifaVigente = this.informacionTarifasEnc.filter(vig => vig.id==this.idEncabezado )[0]

           
           
            this.fechaInicioTarifa = tarifaVigente.fechaInicio;
            this.fechaFinTarifa = tarifaVigente.fechaFin;

          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
       
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        
      }
    );


    this.reportesCarreteroService.getInformacionCrrPeajesPorId(this.idPeaje).subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.informacionGeneralPeajes = dato.infoPeajes;

            const infoPeajes = this.informacionGeneralPeajes[0];
            this.nombrePeaje = infoPeajes.peaje;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
       
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
       
      }
    );

    this.reportesCarreteroService.getInformacionCrrPeajesTarifas(this.idPeaje).subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.informacionTarifasCompleta = dato.infoTarifas;
            this.informacionTarifasCategoriaI = this.informacionTarifasCompleta.filter(encabezado => encabezado.idCategoriaTarifa === "I");
            this.informacionTarifasCategoriaII = this.informacionTarifasCompleta.filter(encabezado => encabezado.idCategoriaTarifa === "II");
            this.informacionTarifasCategoriaIII = this.informacionTarifasCompleta.filter(encabezado => encabezado.idCategoriaTarifa === "III");
            this.informacionTarifasCategoriaIV = this.informacionTarifasCompleta.filter(encabezado => encabezado.idCategoriaTarifa === "IV");
            this.informacionTarifasCategoriaV = this.informacionTarifasCompleta.filter(encabezado => encabezado.idCategoriaTarifa === "V");
            this.informacionTarifasCategoriaVI = this.informacionTarifasCompleta.filter(encabezado => encabezado.idCategoriaTarifa === "VI");
            this.informacionTarifasCategoriaVII = this.informacionTarifasCompleta.filter(encabezado => encabezado.idCategoriaTarifa === "VII");


          

            const valoresVigentesIIE = this.informacionTarifasCompleta.filter(vigente =>  vigente.idCategoriaTarifa === "IIE")[0];

            if (valoresVigentesIIE == null) {
              this.valorCategoriaIIE = 0
            } else {
              this.valorCategoriaIIE = valoresVigentesIIE.valor;

            }


            

            const valoresVigentesIIIE = this.informacionTarifasCompleta.filter(vigente =>  vigente.idCategoriaTarifa === "IIIE")[0];

            if (valoresVigentesIIIE == null) {
              this.valorCategoriaIIIE = 0
            } else {
              this.valorCategoriaIIIE = valoresVigentesIIIE.valor;

            }
            const valoresVigentesIVE = this.informacionTarifasCompleta.filter(vigente =>  vigente.idCategoriaTarifa === "IVE")[0];

            if (valoresVigentesIVE == null) {
              this.valorCategoriaIVE = 0
            } else {
              this.valorCategoriaIVE = valoresVigentesIVE.valor;

            }



            const valoresVigentesVE = this.informacionTarifasCompleta.filter(vigente => vigente.idCategoriaTarifa === "VE")[0];

            if (valoresVigentesVE == null) {
              this.valorCategoriaVE = 0
            } else {
              this.valorCategoriaVE = valoresVigentesVE.valor;

            }
            const valoresVigentesVIE = this.informacionTarifasCompleta.filter(vigente => vigente.idCategoriaTarifa === "VIE")[0];

            if (valoresVigentesVIE == null) {
              this.valorCategoriaVIE = 0
            } else {
              this.valorCategoriaVIE = valoresVigentesVIE.valor;

            }
            const valoresVigentesVIIE = this.informacionTarifasCompleta.filter(vigente =>  vigente.idCategoriaTarifa === "VIIE")[0];

            if (valoresVigentesVIIE == null) {
              this.valorCategoriaVIIE = 0
            } else {
              this.valorCategoriaVIIE = valoresVigentesVIIE.valor;

            }
         


            this.optionschart2 = {
              chart: {
                zoomType: "x",
                type: "column",
              },
              title: {
                text: "",
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                type: "category"
               
              },
              yAxis: {
                min: 0,
                title: { text: "Tarifa" }
              },
           
             
              tooltip: {
                headerFormat: '<span style="font-size:10px"> <strong>Categoría:</strong> {point.key} </span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>$ {point.y:.2f} </b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true
              },
              plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '$ {point.y:,.2f}',
                        align: 'right',
                        color: '#11528a',
                        x: -10
                    },
                    pointPadding: 0.1,
                    groupPadding: 0
                }
              },
              series: [
                {
                  name: "2022",
                  color: "#11528a",
                  data: this.informacionTarifasAnio1.map((e) =>[e.idCategoriaTarifa, e.valor] ),
                 
                },
                {
                  name: "2021",
                  color: "#01d3c1",
                  data: this.informacionTarifasAnio2.map((e) =>[e.idCategoriaTarifa, e.valor] ),
                
                },
                {
                  name: "2020",
                  
                  data: this.informacionTarifasAnio3.map((e) =>[e.idCategoriaTarifa, e.valor] ),
                
                }
                /*{
                  type: "scatter",
                  name: "Novedades",
                  color: "#eb6d20",
                  data: datanovedades,
                  pointStart: Date.UTC(2017, 0, 1), //primer dia
                  pointInterval: 24 * 3600 * 1000, // 10 dias
                  marker: {
                    radius: 4,
                  },
                  tooltip: {
                    headerFormat:
                      '<b style="color:{series.color};padding:0" >{series.name}</b><br>{point.point.name}<br>{point.point.date}',
                    pointFormat: "{point.options.myData}",
                  },
                },*/
              ],
              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 800,
                    },
                    chartOptions: {
                      rangeSelector: {
                        inputEnabled: false,
                      },
                    },
                  },
                ],
              },
            };
           
            let chart2 = Highcharts.chart("chartHistoriaTarifas", this.optionschart2);



          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
        this.blockedDocument = false;
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }
  atras() {
    window.history.back();
  }
}
