import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CtoContratoDTO } from '../../../../contratacion-dto/contrato';
import { EnumTipoDocumento } from '../../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-contrato-contratista',
  templateUrl: './contrato-contratista.component.html',
  styles: []
})
export class ContratoContratistaComponent implements OnInit {


  @Input()
  contratoContratista: CtoContratoDTO;
  @Output()
  contratoContratistaOutput = new EventEmitter<CtoContratoDTO>();

  visualizarDialogoContratista = false;

  constructor(
  ) { }


  ngOnInit() {
  }

  asociarContratista(persona) {
    if (persona != null && persona.idPersona != null && persona.idPersona !== -1 && persona.tipoDocumento != null) {
      switch (persona.tipoDocumento.id) {
        case EnumTipoDocumento.CC:
          this.contratoContratista.idPersona = persona.idPersona;
          this.contratoContratista.idPersonaJuridica = null;
          break;
        case EnumTipoDocumento.NIT:
        case EnumTipoDocumento.NIEE:
          this.contratoContratista.idPersona = null;
          this.contratoContratista.idPersonaJuridica = persona.idPersona;
          break;
      }
      this.guardarContrato();
      this.visualizarDialogoContratista = false;
    }
  }

  guardarContrato() {
    this.contratoContratistaOutput.emit(this.contratoContratista);
  }

  get EnumTipoDocumento() {
    return EnumTipoDocumento;
  }
}
