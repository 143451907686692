import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio, EnumTipoDocumento, EnumTrnConstante, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { CtoReporteCuentasCobroCxpDTO } from '../../../../contratacion-dto/reporte-cuentas-cxp';
import { EnumCtoPasoInforme, EnumCtoTipoAnexoInforme } from 'src/app/contratacion/constantes/contratacion-constantes';
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumInvInventario } from 'src/app/inventario-equipos/inventario-constantes';
import { CtoInformeRevisionAsignacionDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-asignacion';
import { TrnConstantesService } from 'src/app/comun/constantes/trn-constantes.service';

@Component({
  selector: 'app-rpte-cxp-base',
  templateUrl: './rpte-cxp-base.component.html',
  styles: []
})
export class RpteCxpBaseComponent implements OnInit {

  idContrato: number;
  idAsignacionParam: number;
  idInformeParam: number;
  idVersionInformeParam: number;
  idPasoInformeParam: number;
  idPasoParam: number;
  idPersonaAsignacionParam: number;
  visibleDialogoExito = false;
  visibleDialogoError = false;
  mensajeDialogo;

  mapConstantes;

  habilitaTabPazSalvo = false;
  habilitaAnexosPazSalvo2021 = false;
  habilitaAnexosPazSalvo2023 = false;
  fechaValidacionAnexosPazSalvo2023: Date;
  dateMesDiciembre = 11;//Debe ser 0-11, 11 Es Diciembre

  habilitaTabRegistroPresupuestalObligacion = false;
  habilitaTabCertificadoPago = false;


  cuentaCobro: CtoReporteCuentasCobroCxpDTO;
  asignacionInforme: CtoInformeRevisionAsignacionDTO;
  blockedDocument = false;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private constantesService: TrnConstantesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.idContrato = params.idContrato;
      this.idInformeParam = params.idInforme;
      this.cargarInformeContratista(this.idInformeParam);
    });
  }

  /**
   * Metodo que se encarga de llamar el servicio de consulta del informe de cuenta de cobro en revisión 
   * @param idInforme Id del informe
   */
  cargarInformeContratista(idInforme: number) {
    this.blockedDocument = true;
    this.reporteCuentaCobroCxpService.srvConsultarReporteCuentasCobroCxpPorIdInforme(idInforme)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.cuentaCobro = result.reporteCuentaCobroCxp;
              this.cargarConstantesCuentasCobro();
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  /**
 * Metodo que llama al servicio de constantes para mostrar en el dialogo de información de las cuentas de cobro
 */
  cargarConstantesCuentasCobro() {
    const nombresConstantes = `${EnumTrnConstante.CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA},${EnumTrnConstante.CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE}`;
    this.constantesService.srvGetConstantesListaPorNombre(nombresConstantes)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.mapConstantes = new Map();
              result.constantesLista.forEach(cns => {
                this.mapConstantes.set(cns.nombre, cns.valor);
              });
              this.validarTabsCuentaCobro();
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
  }

  /**
   * Metodo que valida los tabs que se muestran en el informe
   */
  validarTabsCuentaCobro() {
    if (this.cuentaCobro != null && this.mapConstantes.get(EnumTrnConstante.CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA)) {

      //Esta sección se agrego a partir del periodo de Diciembre 2021, aplica para el último pago de un contrato y solo a partir de la fecha de despligue de los ajustes Diciembre 2021      
      if (this.cuentaCobro.numeroPago == this.cuentaCobro.totalPagosFuente
        && this.cuentaCobro.vigencia >= this.mapConstantes.get(EnumTrnConstante.CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA)) {
        this.habilitaTabPazSalvo = true;

        //Esta sección se agrego a partir del periodo de Diciembre 2023, aplica para el último pago de un contrato y solo a partir de la fecha de despligue de los ajustes Diciembre 2023. Se elimina el formato 081 y solo es obligatorio el 014 para contratos que terminan antes de la fecha de corte 18Dic CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE
        const fechaActual = new Date();
        if (this.cuentaCobro.vigencia >= this.mapConstantes.get(EnumTrnConstante.CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA)
          && this.cuentaCobro.vigencia < fechaActual.getFullYear()) {
          this.habilitaAnexosPazSalvo2021 = true;
        } else {
          //Esta regla se agregó a partir del periodo de Diciembre 2023.
          //Se obliga anexar los formatos 014 para la útlima cuenta de cobro del contrato siempre y cuando la FechaFin del contrato sea menor o igual a 18/Dic          
          this.fechaValidacionAnexosPazSalvo2023 = new Date(this.cuentaCobro.fechaFinPago.getFullYear(), this.dateMesDiciembre, this.mapConstantes.get(EnumTrnConstante.CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE));
          console.log('FechaValidación:' + this.fechaValidacionAnexosPazSalvo2023);
          if (this.cuentaCobro.fechaFinPago <= this.fechaValidacionAnexosPazSalvo2023) {
            this.habilitaAnexosPazSalvo2023 = true;
          }
        }
      }


      if (this.cuentaCobro.idUltimoPaso == EnumCtoPasoInforme.REVISION_CENTRAL_PAGOS
        || this.cuentaCobro.idUltimoPaso == EnumCtoPasoInforme.REVISION_CENTRAL_PAGOS_DEVOLUCION
        || this.cuentaCobro.idUltimoPaso == EnumCtoPasoInforme.APROBACION_PAGO_TESORERIA
        || this.cuentaCobro.idUltimoPaso == EnumCtoPasoInforme.PAGADO) {
        this.habilitaTabRegistroPresupuestalObligacion = true;
      }

      if (this.cuentaCobro.idUltimoPaso == EnumCtoPasoInforme.PAGADO) {
        this.habilitaTabCertificadoPago = true;
      }
    }
  }

  irReporteCuentasCxp() {
    this.router.navigate(['/reportesCuentasCxp/']);
  }

  get EnumCtoPasoInforme() {
    return EnumCtoPasoInforme;
  }

  get EnumTipoDocumento() {
    return EnumTipoDocumento;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCtoTipoAnexoInforme() {
    return EnumCtoTipoAnexoInforme;
  }

  get EnumInvInventario() {
    return EnumInvInventario;
  }


}