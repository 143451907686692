import { Injectable, } from '@angular/core';
import { ConfigService } from '../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ConsultaArchivoRadicacionWebService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    
    /**
     * Metodo que carga la lista de la info de la tabla radicado
     */
    public getListadoArchivoConsulta() {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/listado/consultaArchivo/')
            .pipe(retry(1));
    }

}

