import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informacion-general-base',
  templateUrl: './informacion-general-base.component.html',
  styleUrls: ['./informacion-general-base.component.sass']
})
export class InformacionGeneralBaseComponent implements OnInit {

  nombreProyecto: any;
  idProyecto: number;

  constructor() { }

  ngOnInit() {
    /* var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto); */
  }

}
