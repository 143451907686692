import { Component, OnInit } from '@angular/core';
import { PublicProyectos5GService } from '../public-proyectos5g.service';
import { ProyectoEstructuracion } from 'src/app/reportes/publicos/proyectoEstructuracion';
import { MessageService } from 'primeng/primeng';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import * as Highcharts from "highcharts";

@Component({
  selector: 'app-public-proyectos5g-ficha-ola2',
  templateUrl: './public-proyectos5g-ficha-ola2.component.html',
  styleUrls: ['./public-proyectos5g-ficha-ola2.component.sass']
})
export class PublicProyectos5gFichaOla2Component implements OnInit {
  datosProyectos2Ola: any;
  datosProyectos2OlaUS: any;  
  proyectos2Ola: ProyectoEstructuracion[]=[];  
  valorTotal:number=0;
  valorTotalFormato:string;
  titulosTablaOla2: any[];
  datosTablaOla2: any = [];
  opcionGrafica: any;
  blockedDocument = false;
  optionschart2Ola: any;
  
//formato moneda en billones
formatoMoneda(valor: number) {
  return valor? "$" + (valor/1000000000000).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " Bn" : "$0";
}

  constructor(private publicProyectos5GService: PublicProyectos5GService ,
    private messageService: MessageService, private principalComponent: PrincipalComponent) {

      publicProyectos5GService.getDatosProyectos5GBD().subscribe((dato) =>{          
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1){          
            this.CargarDatos(dato.proyectos)
          }
          else{         
            this.messageService.add({severity: 'error', summary: 'Error', detail: dato.respuestaServicio.mensajeSalida, life: 10000 });
          }
        }
      },
      error => {     
        this.blockedDocument = false;         
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);      
      },
      ()=>{
        this.blockedDocument = false;
      });
    }

  /// Cargar datos 
  CargarDatos(proyectos: ProyectoEstructuracion[]){

    this.proyectos2Ola= proyectos.filter(p=>p.idOla == 2);
    this.valorTotal =this.proyectos2Ola.reduce((sum, p) => sum + p.capex,0);
    this.valorTotalFormato = this.formatoMoneda(this.valorTotal);
 
// Grafica de barras de los proyectos Ola 2
    this.datosProyectos2Ola = {
      labels: this.proyectos2Ola.map(e => e.nombre),
      datasets: [
          {
              label: '',
              backgroundColor: '#42A5F5',
              borderColor: '#1E88E5',
              data: this.proyectos2Ola.map(e => e.capex)
          }
          ]
      }
    this.opcionGrafica =  {
      scales: {
        yAxes: [{
          ticks: {
            //stepSize: 10,
            beginAtZero: true,
            callback: (label, index, labels) => {
              return this.formatoMoneda(label);}
          }
        }]
      }
    }; 

      
        //migración a grafica highchart
        this.optionschart2Ola = {
          chart: {
            zoomType: "x",
            type: "column",
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          xAxis: {
            categories: this.proyectos2Ola.map((e) => e.nombre),
            type: "category",
          },
          yAxis: {
            min: 0,
            title: {
              text: "",
            },
            labels: {
              format: "{value} Bn",
            },
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:10px"> <strong>Proyecto:</strong> {point.key}</span><table>',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>$ {point.y} Bn</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                align: "right",
                color: "#438DF7",
                x: -10,
                format: "$ {point.y} Bn",
              },
              pointPadding: 0.1,
              groupPadding: 0,
            },
          },
          series: [
            { 
              
              color: "#42A5F5",
              data: this.proyectos2Ola.map((e) => e.capex / 1000000000000),
            },
          ],
        };
    
        let chart4 = Highcharts.chart("chart2Ola", this.optionschart2Ola);
  
// Datos tabla Ola2
    this.titulosTablaOla2 = [
      { field: 'Proyecto', header: 'Proyecto' },
      { field: 'Modo', header: 'Modo' },
      { field: 'Tipo', header: 'Tipo de Proyecto' },
      { field: 'Capex', header: 'Capex (Cifras preliminares)' }    
    ];  

    this.proyectos2Ola.forEach( p => 
      this.datosTablaOla2.push({Proyecto: p.nombre , Modo: p.nombreModo, Tipo: p.tipoIniciativa,  Capex: this.formatoMoneda(p.capex)})
     );
   }
  displayModal: boolean;
  displayModal2: boolean;
  displayModal3: boolean;
  showModalDialog() {
    this.displayModal = true;
  }
  showModalDialog2() {
    this.displayModal2 = true;
  }
  showModalDialog3() {
    this.displayModal3 = true;
  }

  ngOnInit() {
    this.blockedDocument = true;   
    }
}
