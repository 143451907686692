import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs-compat";
import { map, retry } from 'rxjs/operators';
import { environment } from "../../../environments/environment";
import { ConfigService } from "../../config.service";
import { PjeMaSentidoCobroPeajeRpt } from "./PjeMaSentidoCobroPeaje";
import { PjeInformacionGeneralPeajeDTO, PjeInformacionGeneralPeajeDTORpt } from "./PjeInformacionGeneralPeajeDTO";
import { PjeMaOperadorPagoElectronicoRpt } from "./PjeMaOperadorPagoElectronico";
import { TrnRespuestaServicio } from "../../parametricas/trn-respuesta-servicio";
import { PjePeajeOperadorPagoElectronicoDTO, PjePeajeOperadorPagoElectronicoDTORpt } from "./PjePeajeOperadorPagoElectronicoDTO";
import { TrnRespuestaServicioRpt } from "../../parametricas/rpt-respuesta-operacion";
import { PjeUbicacionPeajeDTO, PjeUbicacionPeajeDTORpt } from "./PjeUbicacionPeajeDTO";
import { from } from 'rxjs';
import { PjePeajePorProyectoRpt } from "../trafico-peajes/PjePeajePorProyecto";


@Injectable({
    providedIn: 'root'
})

export class InformacionGeneralPeajesService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    cargaInformacionGeneralPeaje(idPeaje: number, idProyecto: number) {
        return this.http.get<PjeInformacionGeneralPeajeDTORpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/GetInformacionGeneral/' + idPeaje + '/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    cargaOperadoresElectronicosPeaje(idPeaje: number) {
        return this.http.get<PjePeajeOperadorPagoElectronicoDTORpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/GetPeajeOperadorPagoElectronico/' + idPeaje)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    cargaUbicacionesPeaje(idPeaje: number) {
        return this.http.get<PjeUbicacionPeajeDTORpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/GetUbicacionesPeaje/' + idPeaje)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    getTipoSentido(): Observable<PjeMaSentidoCobroPeajeRpt> {
        return this.http.get<PjeMaSentidoCobroPeajeRpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/GetTipoSentido/')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    getOperadores(): Observable<PjeMaOperadorPagoElectronicoRpt> {
        return this.http.get<PjeMaOperadorPagoElectronicoRpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/GetOperadores/')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }



    eliminarOperador(idOperador: number): Observable<TrnRespuestaServicioRpt> {
        return this.http.delete<TrnRespuestaServicioRpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/EliminaOperador/' + idOperador)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    eliminarUbicacion(idUbicacion: number): Observable<TrnRespuestaServicioRpt> {
        return this.http.delete<TrnRespuestaServicioRpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/EliminaUbicacion/' + idUbicacion)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public guardaInformacionGeneral(pjeInformacionGeneralPeajeDTO: PjeInformacionGeneralPeajeDTO): Observable<GuardarInformacionGeneralPeajeRpt> {

        return this.http.post<GuardarInformacionGeneralPeajeRpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/GuardaInformacionGeneral', pjeInformacionGeneralPeajeDTO)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public guardaOperadorElectronico(pjePeajeOperadorPagoElectronicoDTO: PjePeajeOperadorPagoElectronicoDTO): Observable<GuardarOperadorPeajeRpt> {

        return this.http.post<GuardarOperadorPeajeRpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/GuardaOperadorElectronico', pjePeajeOperadorPagoElectronicoDTO)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    public guardaUbicacionPeaje(pjeUbicacionPeajeDTO: PjeUbicacionPeajeDTO): Observable<GuardarUbicacionPeajeRpt> {
        return this.http.post<GuardarOperadorPeajeRpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/GuardaUbicacionPeaje', pjeUbicacionPeajeDTO)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public sincronizarZonasPeajes(): Observable<any> {
        return from(
            fetch(
                'https://sig.ani.gov.co/arcgissrv/rest/services/10_Funciones/PeajeMaestro/GPServer/Guardar%20peajes/submitJob?env%3AoutSR=&env%3AprocessSR=&returnZ=false&returnM=false&returnTrueCurves=false&f=pjson', // the url you are trying to access
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'GET', // GET, POST, PUT, DELETE
                    mode: 'no-cors', // the most important option
                }
            )
        );
    }

    ConsultarPjePeajePorId(idPeaje: number): Observable<PjeUbicacionPeajeDTORpt> {
        return this.http.get<PjeUbicacionPeajeDTORpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/ConsultarPjePeajePorId/' + idPeaje)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    srvConsultarPeajesPorProyecto(idProyecto: number): Observable<PjePeajePorProyectoRpt> {
        return this.http.get<PjePeajePorProyectoRpt>(this.END_POINT + '/PjeInformacionGeneralPeajes/GetPeajesPorProyecto/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }
}

export class GuardarInformacionGeneralPeajeRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class GuardarOperadorPeajeRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class GuardarUbicacionPeajeRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

