import { Component, OnInit } from '@angular/core';
import { TramitesAmbientalesPuertoService } from '../tramites-ambientales.service';
import { TramitesAmbientalesDTO, JustificacionModificacionDTO,
  EntidadDTO, TipoTramiteDTO, TramiteRelacionadoDTO, EtapaProyectoDTO, PriorizacionDTO, EstadoDTO  } from '../tramites-ambientales.dto.js';
import { Router, ActivatedRoute } from '@angular/router';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { forkJoin } from "rxjs";
import { FormBuilder, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { EnumAmbMaTipoTramite, EnumAmbMaTramiteRelacionado, EnumTrnMaTipoProyecto } from "src/app/comun/constantes/constantes-comunes.js";


@Component({
  selector: 'app-tramites-ambientales',
  templateUrl: './tramites-ambientales.component.html',
  styleUrls: ['./tramites-ambientales.component.css']
})
export class TramitesAmbientalesPuertoComponent implements OnInit {

  loading: boolean;
  idProyecto: number;
  idTramiteAmbiental: number;
  proyectoSeleccionado: ProyectoDTO;
  formTramiteAmbiental;
  formUnidadFuncionalAsociado;
  formJustificacionModificacion;

  lstEntidad : EntidadDTO[];
  lstTipoTramite : TipoTramiteDTO[];
  lstTramiteRelacionado : TramiteRelacionadoDTO[];
  lstEtapaProyecto : EtapaProyectoDTO[];
  lstPriorizacion : PriorizacionDTO[];
  lstEstado : EstadoDTO[];

  nombreProyecto;
  tipoSolicitudTA: string;
  tipoSolicitudStr: string;
  tramiteAmbiental: TramitesAmbientalesDTO;
  justificacionModificacion: JustificacionModificacionDTO;

  dialogoUnidadFuncionalEliminar: boolean;
  dialogoGuardarTramiteAmbiental: boolean;
  dialogoJustificacionModificacion: boolean;
  isView: boolean;
  isEdit: boolean;
  habilitaEnFirme: boolean;

  habilitaTramitesRelacionados: boolean;
  habilitaOtroTramite: boolean;
  habilitaPermisoTramiteRelacionado: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
		private ruta: ActivatedRoute,
    private messageService: MessageService,
    private componentesComunesService: ComponentesComunesService,
    private service: TramitesAmbientalesPuertoService,
    private principalComponent: PrincipalComponent
  ) {
    // Crear formulario tramite ambiental
    this.formTramiteAmbiental = formBuilder.group({
      entidadFormAtributte: [null, [Validators.required]],
      tipoTramiteFormAtributte: [null, [Validators.required]],
      tramiteRelacionadoFormAtributte: [null, [Validators.required]],
      otroTramiteRelacionadoFormAtributte: [null],
      tramiteRelacionadoCualFormAtributte: [null],
      etapaProyectoFormAtributte: [null, [Validators.required]],
      priorizacionFormAtributte: [null, [Validators.required]],
      estadoTramiteFormAtributte: [null, [Validators.required]],
      radicadoConcesionarioFormAtributte: [null, [Validators.required]],
      fechaRadicadoConcesionarioFormAtributte: [null, [Validators.required]],
      radicadoEntidadFormAtributte: [null, [Validators.maxLength(14),Validators.required]],
      fechaEntidadFormAtributte: [null, [Validators.required]],
      expedienteFormAtributte: [null, [Validators.maxLength(10),  Validators.minLength(5), Validators.required]],
      descripcionFormAtributte: [null, [Validators.maxLength(100), Validators.minLength(5), Validators.required]],
      enFirmeFormAtributte: [null, null],
    });

    this.formJustificacionModificacion = formBuilder.group({
      fechaActuacionFormAtributte: [null, [Validators.required]],
      observacionesFormAtributte: [null, [Validators.maxLength(100), Validators.minLength(5), Validators.required]],
    })
  }

  ngOnInit() {
    this.tramiteAmbiental = {};
    this.tramiteAmbiental.entidadDTO = {};
    this.tramiteAmbiental.tipoTramiteDTO = {};
    this.tramiteAmbiental.tramiteRelacionadoDTO = {};
    this.tramiteAmbiental.etapaProyectoDTO = {};
    this.tramiteAmbiental.priorizacionDTO = {};
    this.tramiteAmbiental.estadoDTO = {};
    this.justificacionModificacion = {};

    this.loading = true;
    this.dialogoJustificacionModificacion = false;
    this.isEdit = false;
    this.tipoSolicitudStr = "";
    this.dialogoUnidadFuncionalEliminar = false;
    this.dialogoGuardarTramiteAmbiental = false;
    this.idProyecto = (localStorage.getItem('idProyectoPuerto')!= null) ? Number(localStorage.getItem('idProyectoPuerto')) : 0;

    this.ruta.params.subscribe(params => {
			this.idTramiteAmbiental = params.id;
      // Inicializar listas parametricas y DTOs
      forkJoin(
        this.componentesComunesService.obtenerProyecto(this.idProyecto),
        this.service.getEntidad(),
        this.service.getEstadoCompromiso(),
        this.service.getPiorizacion(),
        this.service.getTipoTramite(),
        this.service.getEtapasProyecto(EnumTrnMaTipoProyecto.MODO_PUERTO), //this.idProyecto 2 para probar
        this.service.getTramiteRelacionado()
      ).subscribe(
        ([proyectoData, entidadData, estadoCompromisoData, priorizacionData, tipoTramiteData, etapaProyectoData, tramiteRelacionadoData]) => {
          this.proyectoSeleccionado = proyectoData;
          this.lstEntidad = entidadData.listaAmbMaEntidadAmbiental;
          this.lstTramiteRelacionado = tramiteRelacionadoData.listaAmbMaTramiteRelacionado;
          this.lstTipoTramite = tipoTramiteData.listaAmbMaTipoTramite;
          this.lstPriorizacion = priorizacionData.listaAmbMaPriorizacion;
          this.lstEstado = estadoCompromisoData.listaAmbMaEstadoCompromiso;
          this.lstEtapaProyecto = etapaProyectoData.listaEtapasProyecto;
          this.getCargarValidacionVisualizacion();
        },
        (e) => {}
      );

			this.tipoSolicitudTA = localStorage.getItem('tipoSolicitudTA');

		});
  }

   /**
   * Metodo que valida si esta modo edicion, consulta o nuevo y asi habilita el componente de tramo
   *
   */
   getCargarValidacionVisualizacion(){
    if(this.tipoSolicitudTA === "onNew"){
      this.tipoSolicitudStr = "Modo Creación";
      this.formTramiteAmbiental.enable();
      this.formJustificacionModificacion.disable();
      this.isView = true;
      this.isEdit = false;
      this.loading = false;
    }
    if(this.tipoSolicitudTA === "onView"){
      this.tipoSolicitudStr = "Modo Consultar";
      this.formTramiteAmbiental.disable();
      this.formJustificacionModificacion.disable();
      this.isView = false;
      this.isEdit = false;
      this.habilitaEnFirme = true;
      this.cargaDatosConsulta();
    }
    if(this.tipoSolicitudTA === "onEdit"){
      this.tipoSolicitudStr = "Modo Edición";
      this.formTramiteAmbiental.enable();
      this.formJustificacionModificacion.enable();
      this.isView = true;
      this.isEdit = true;
      this.habilitaEnFirme = true;
      this.cargaDatosConsulta();
    }

    if (this.tipoSolicitudTA === "onViewHistorico") {
      this.tipoSolicitudStr = "Modo Consultar";
      this.formTramiteAmbiental.disable();
      this.formJustificacionModificacion.disable();
      this.isView = false;
      this.habilitaTramitesRelacionados = true;
      this.isEdit = false;
      this.habilitaEnFirme = true;
      this.cargarTramiteAmbientalPorIdHistorico();
    }

    if (this.idTramiteAmbiental != 0) {
      console.log(this.idTramiteAmbiental);
      if (this.tipoSolicitudTA === "onViewHistorico") {
        this.tipoSolicitudStr = "Modo Consultar";
        this.formTramiteAmbiental.disable();
        this.formUnidadFuncionalAsociado.disable();
        this.formJustificacionModificacion.disable();
        this.isView = false;
        this.habilitaTramitesRelacionados = true;
        this.isEdit = false;
        this.habilitaEnFirme = true;
        this.cargarTramiteAmbientalPorIdHistorico();
        this.cargarMaEstadoTramites();
      } else {
        this.cargarMaEstadoTramitesActivos();
      }
    }

  }

  /**
   * Metodo que consulta la información parametrica de priorización de los tramites ambientales
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaEstadoTramites() {
    return this.service
      .getEstadoCompromiso()
      .subscribe((result) => {
        this.lstEstado = result.listaAmbMaEstadoCompromiso;

        if (this.lstEstado === null) {
          this.lstEstado = [];
        }

        if (this.tramiteAmbiental.idEstadoTramite != null) {
          this.tramiteAmbiental.estadoDTO = this.lstEstado.find(
            (item) => item.id == this.tramiteAmbiental.idEstadoTramite
          );
          this.formTramiteAmbiental
            .get("estadoTramiteFormAtributte")
            .setValue(this.tramiteAmbiental.estadoDTO);
        }
      });
  }

  /**
   * Metodo que consulta la información parametrica de priorización de los tramites ambientales
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaEstadoTramitesActivos() {
    return this.service
      .getEstadoCompromisoActivos()
      .subscribe((result) => {
        this.lstEstado = result.listaAmbMaEstadoCompromiso;

        if (this.lstEstado === null) {
          this.lstEstado = [];
        }

        if (this.tramiteAmbiental.idEstadoTramite != null) {
          this.tramiteAmbiental.estadoDTO = this.lstEstado.find(
            (item) => item.id == this.tramiteAmbiental.idEstadoTramite
          );
          this.formTramiteAmbiental
            .get("estadoTramiteFormAtributte")
            .setValue(this.tramiteAmbiental.estadoDTO);
        }
      });
  }

  /**
   * Metodo que consulta la información del tramite ambiental por id
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarTramiteAmbientalPorIdHistorico() {
    this.loading = true;
    return this.service
      .getConsultarTramiteAmbientalPorIdHistorico(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.tramiteAmbiental = result.tramiteAmbiental;
        this.cargarInfoTramite();
        this.habilitaTramitesRelacionados = true;
        this.loading = false;
      });
  }

  /**
   * Metodo que carga l tramite y las unidades modo consulta
   */
  cargaDatosConsulta(){
    forkJoin(
      this.service.getTramiteById(this.idTramiteAmbiental)
    ).subscribe(
      ([ tramiteData]) => {
        this.tramiteAmbiental = tramiteData.tramiteAmbiental;
        this.cargarInfoTramite();
        this.loading = false;
      },
      (e) => {}
    );
  }

  /**
   * Metodoo que carga la informacion
   */
  cargarInfoTramite(){
    if (this.lstEntidad === null) {
      this.lstEntidad = [];
    }
    if (this.lstTramiteRelacionado === undefined || this.lstTramiteRelacionado === null) {
      this.lstTramiteRelacionado = [];
    }
    if (this.lstTipoTramite === undefined || this.lstTipoTramite === null) {
      this.lstTipoTramite = [];
    }
    if (this.lstEtapaProyecto === undefined || this.lstEtapaProyecto === null) {
      this.lstEtapaProyecto = [];
    }
    if (this.lstPriorizacion === undefined || this.lstPriorizacion === null) {
      this.lstPriorizacion = [];
    }
    if (this.lstEstado === undefined || this.lstEstado === null) {
      this.lstEstado = [];
    }

    if (this.tramiteAmbiental.idEntidad != null) {
      this.tramiteAmbiental.entidadDTO = this.lstEntidad.find(
        (item) => item.id == this.tramiteAmbiental.idEntidad
      );
      this.formTramiteAmbiental
        .get("entidadFormAtributte")
        .setValue(this.tramiteAmbiental.entidadDTO);
    }

    if (this.tramiteAmbiental.idTipoTramite != null) {
      this.tramiteAmbiental.tipoTramiteDTO = this.lstTipoTramite.find(
        (item) => item.id == this.tramiteAmbiental.idTipoTramite
      );
      this.formTramiteAmbiental
        .get("tipoTramiteFormAtributte")
        .setValue(this.tramiteAmbiental.tipoTramiteDTO);
    }

    if (this.tramiteAmbiental != null && this.tramiteAmbiental.idTipoTramite != null) {
      if (EnumAmbMaTipoTramite.TIPO_TRAMITE_CESION === this.tramiteAmbiental.idTipoTramite
        || EnumAmbMaTipoTramite.TIPO_TRAMITE_CIERRE_ARCHIVO === this.tramiteAmbiental.idTipoTramite
        || EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === this.tramiteAmbiental.idTipoTramite
        || EnumAmbMaTipoTramite.TIPO_TRAMITE_MODIFICACION === this.tramiteAmbiental.idTipoTramite) {
          this.habilitaTramitesRelacionados = false;
          this.tramiteAmbiental.tramiteRelacionadoDTO = {};
          this.habilitaPermisoTramiteRelacionado = false;
          this.habilitaOtroTramite = false;
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === this.tramiteAmbiental.idTipoTramite) {
          this.habilitaOtroTramite = true;
        }
      } else {
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_OTRO === this.tramiteAmbiental.idTipoTramite) {
          this.habilitaOtroTramite = true;
        } else {
          this.habilitaOtroTramite = false;
        }
        this.tramiteAmbiental.tramiteRelacionadoDTO = {};
        this.habilitaPermisoTramiteRelacionado = false;
      }
    }

    if (this.tramiteAmbiental.idTramiteRelacionado != null) {
      this.tramiteAmbiental.tramiteRelacionadoDTO = this.lstTramiteRelacionado.find(
        (item) => item.id == this.tramiteAmbiental.idTramiteRelacionado
      );
      this.formTramiteAmbiental
        .get("tramiteRelacionadoFormAtributte")
        .setValue(this.tramiteAmbiental.tramiteRelacionadoDTO);
      if(this.tramiteAmbiental.tramiteRelacionadoDTO != null && this.tramiteAmbiental.tramiteRelacionadoDTO.id != null){
        this.habilitaTramitesRelacionados = false;
      }else{
        this.habilitaTramitesRelacionados = true;
      }

      if (this.tramiteAmbiental.idTramiteRelacionado === EnumAmbMaTramiteRelacionado.TRAMITE_RELACIONADO_PERMISO) {
        this.habilitaPermisoTramiteRelacionado = true;
      } else {
        this.habilitaPermisoTramiteRelacionado = false;
      }
    }

    if (this.tramiteAmbiental.idEtapaProyecto != null) {
      this.tramiteAmbiental.etapaProyectoDTO = this.lstEtapaProyecto.find(
        (item) => item.id == this.tramiteAmbiental.idEtapaProyecto
      );
      this.formTramiteAmbiental
        .get("etapaProyectoFormAtributte")
        .setValue(this.tramiteAmbiental.etapaProyectoDTO);
    }

    if (this.tramiteAmbiental.idPriorizacion != null) {
      this.tramiteAmbiental.priorizacionDTO = this.lstPriorizacion.find(
        (item) => item.id == this.tramiteAmbiental.idPriorizacion
      );
      this.formTramiteAmbiental
        .get("priorizacionFormAtributte")
        .setValue(this.tramiteAmbiental.priorizacionDTO);
    }

    if (this.tramiteAmbiental.idEstadoTramite != null) {
      this.tramiteAmbiental.estadoDTO = this.lstEstado.find(
        (item) => item.id == this.tramiteAmbiental.idEstadoTramite
      );
      this.formTramiteAmbiental
        .get("estadoTramiteFormAtributte")
        .setValue(this.tramiteAmbiental.estadoDTO);
    }

    if (this.tramiteAmbiental.fechaRadicado != null) {
      this.tramiteAmbiental.fechaRadicado = new Date(this.tramiteAmbiental.fechaRadicado);
    }

    if (this.tramiteAmbiental.fechaRadicadoEntidad != null) {
      this.tramiteAmbiental.fechaRadicadoEntidad = new Date(this.tramiteAmbiental.fechaRadicadoEntidad);
    }

    if (this.tipoSolicitudTA === "onViewHistorico" || this.tipoSolicitudTA === "onView") {
      this.habilitaTramitesRelacionados = true;
    }
  }

   /**
   * metodo que devuelve la pagina
   */
  bntVolver(){
    const ruta = "puertos/infGeneral/proyectoHome/" + this.idProyecto +"/tramites-ambientales-listado/"+ this.idProyecto;
    this.router.navigate([ruta]);
  }

  /**
   * Metodo que realiza el guardado
   */
  guardarTramiteAmbiental() {
    this.dialogoJustificacionModificacion = false;
    if(this.tipoSolicitudTA === "onNew"){
      if (this.formTramiteAmbiental.valid) {
        this.submitGuardarTramiteAmbiental();
      } else {
        this.lanzarMensajeWarning(
          "Debe diligenciar los campos requeridos marcados con asterisco (*)"
        );
      }
    }else{
      if(this.tipoSolicitudTA === "onEdit"){
        this.dialogoJustificacionModificacion = true;
        this.tramiteAmbiental.observaciones = null;
        this.tramiteAmbiental.fechaActuacion = null;
      }
    }
  }

  /**
   * Metodo que guarda la justificacion de la modificacion
   */
  submitJustificacionModificacion(){
    if (this.formJustificacionModificacion.valid) {
      this.dialogoJustificacionModificacion = false;
      this.submitGuardarTramiteAmbiental();
    } else {
      this.lanzarMensajeWarning( "Debe diligenciar los campos requeridos marcados con asterisco (*)" );
    }
  }

  /**
   * Metodo que realiza el guardado
   */
  submitGuardarTramiteAmbiental(){
    this.loading = true;
    this.tramiteAmbiental.idProyecto = this.idProyecto;
    this.tramiteAmbiental.nombreProyecto = this.proyectoSeleccionado.nombre;
    this.tramiteAmbiental.idEntidad = this.tramiteAmbiental.entidadDTO.id;
    this.tramiteAmbiental.nombreEntidad = this.tramiteAmbiental.entidadDTO.nombre;
    this.tramiteAmbiental.idTipoTramite = this.tramiteAmbiental.tipoTramiteDTO.id;
    this.tramiteAmbiental.nombreTipoTramite = this.tramiteAmbiental.tipoTramiteDTO.nombre;
    this.tramiteAmbiental.idTramiteRelacionado = this.tramiteAmbiental.tramiteRelacionadoDTO.id;
    this.tramiteAmbiental.nombreTramiteRelacionado = this.tramiteAmbiental.tramiteRelacionadoDTO.nombre;
    this.tramiteAmbiental.idEtapaProyecto = this.tramiteAmbiental.etapaProyectoDTO.id;
    this.tramiteAmbiental.etapaProyecto = this.tramiteAmbiental.etapaProyectoDTO.nombre;
    this.tramiteAmbiental.idEstadoTramite = this.tramiteAmbiental.estadoDTO.id;
    this.tramiteAmbiental.estadoTramite = this.tramiteAmbiental.estadoDTO.nombre;
    this.tramiteAmbiental.idPriorizacion = this.tramiteAmbiental.priorizacionDTO.id;
    this.tramiteAmbiental.nombrePriorizacion = this.tramiteAmbiental.priorizacionDTO.nombre;
    this.tramiteAmbiental.idResponsable = this.tramiteAmbiental.entidadDTO.id;
    this.tramiteAmbiental.nombreResponsable = this.tramiteAmbiental.entidadDTO.nombre;
    if(this.tipoSolicitudTA === "onEdit"){
      this.tramiteAmbiental.observaciones = this.justificacionModificacion.observaciones;
      this.tramiteAmbiental.fechaActuacion = this.justificacionModificacion.fechaActuacion;
    }

    forkJoin(
      this.service.postGuardarEditarTramite(this.tramiteAmbiental)
    ).subscribe(
      ([resultData]) => {
        if(resultData!= null && resultData.respuestaServicio.codigoSalida == 1){
          this.lanzarMensajeInfo(resultData.respuestaServicio.mensajeSalida);
          const ruta = "puertos/infGeneral/proyectoHome/" + this.idProyecto +"/tramites-ambientales-listado/"+ this.idProyecto;
          this.router.navigate([ruta]);
          this.dialogoGuardarTramiteAmbiental = true;
        }else{
          this.lanzarMensajeError(resultData.respuestaServicio.mensajeSalida);
        }
        this.loading = false;
      },
      (e) => {
        this.loading = false;
      }
    );

  }

  /**
   * Metodo que controla el evento al momento de seleccionar un Tipo de tramite
   * @param tipoTramite
   */
  onSelectTipoTramite(tipoTramite) {
    if (tipoTramite != null && tipoTramite.value != null && tipoTramite.value.id != null) {
      this.tramiteAmbiental.idTipoTramite = tipoTramite.value.id;
      if (EnumAmbMaTipoTramite.TIPO_TRAMITE_CESION === tipoTramite.value.id || EnumAmbMaTipoTramite.TIPO_TRAMITE_CIERRE_ARCHIVO === tipoTramite.value.id
        || EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === tipoTramite.value.id || EnumAmbMaTipoTramite.TIPO_TRAMITE_MODIFICACION === tipoTramite.value.id) {
        this.habilitaTramitesRelacionados = false;
        this.tramiteAmbiental.tramiteRelacionadoDTO = {};
        this.habilitaPermisoTramiteRelacionado = false;
        this.habilitaOtroTramite = false;
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === tipoTramite.value.id) {
          this.habilitaOtroTramite = true;
          this.tramiteAmbiental.tipoTramiteCual = null;
        }
      } else {
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_OTRO === tipoTramite.value.id) {
          this.habilitaOtroTramite = true;
          this.tramiteAmbiental.tipoTramiteCual = null;
        } else {
          this.habilitaOtroTramite = false;
          this.tramiteAmbiental.tipoTramiteCual = null;
        }
        this.tramiteAmbiental.tramiteRelacionadoDTO = {};
        this.habilitaPermisoTramiteRelacionado = false;
        this.habilitaTramitesRelacionados = true;
      }
    }
  }

   /**
   * Metodo que controla el evento al momento de seleccionar un Tramite relacionado
   * @param tramiteRelacionado
   */
  onSelectTramiteRelacionado(tramiteRelacionado) {
    if (tramiteRelacionado!= null && tramiteRelacionado.value.id === EnumAmbMaTramiteRelacionado.TRAMITE_RELACIONADO_PERMISO) {
      this.habilitaPermisoTramiteRelacionado = true;
      this.tramiteAmbiental.tramiteRelacionadoCual = null;
    } else {
      this.habilitaPermisoTramiteRelacionado = false;
      this.tramiteAmbiental.tramiteRelacionadoCual = null;
    }
  }

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: "success", summary: "Información", detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: "error", summary: "Error", detail: mensaje, });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: "warn", summary: "Advertencia", detail: mensaje, });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
