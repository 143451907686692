import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sig-puertos',
  templateUrl: './sig-puertos.component.html',
  styleUrls: ['./sig-puertos.component.sass']
})
export class SigPuertosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
