import { Component, OnInit, Inject } from '@angular/core';
import { AmigablesService } from '../amigables/amigables.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SeguimientoTribunalesService } from 'src/app/componentes/juridico/tribunales/seguimiento/seguimiento-tribunales.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-seguimiento-amigable',
  templateUrl: './seguimiento-amigable.component.html',
  styleUrls: ['./seguimiento-amigable.component.sass']
})
export class SeguimientoAmigableComponent implements OnInit {
  idAmigablesDetalle: any;
  seguimiento: any;
  tiposPersona: any = [];
  tipos: any = [];
  displayedActuacion: string[] = ['tipo', 'fecha', 'tipoProximaActuacion', 'descripcionProximaActuacion'];
  dataSourceActuacion: MatTableDataSource<any>;
  displayedComponedores: string[] = ['primerNombre', 'fechaInicio', 'fechaFin', 'acciones'];
  dataSourceComponedores: MatTableDataSource<any>;
  dataSourceApoderadoConvocante: MatTableDataSource<any>;
  displayedConvocante: string[] = ['primerNombre', 'fechaInicio', 'fechaFin'];
  dataSourceApoderadoConvocado: MatTableDataSource<any>;
  displayedConvocado: string[] = ['primerNombre', 'fechaInicio', 'fechaFin'];
  actuacionTemporal: any = {
    idAmigable: null,
    idTipo: null,
    fecha: '',
    descripcion: '',
    idTipoProximaActuacion: '',
    idDocumento: 2,
    fechaSiguienteActuacion: '',
    descripcionProximaActuacion: ''
  };
  temporalEnviar;

  personaTemporal: any = {
    idEncabezado: null,
    idTribunal: null,
    idTipoPersona: null,
    fechaInicio: '',
    fechaFin: '',
    idDocumento: null,
    observaciones: '',
    personas: [
      {
        idEncabezado: null,
        idTribunal: null,
        idPersona: null,
        esNuevo: true
      }
    ]
  };
  constructor(private svcAmigable: AmigablesService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog, private service: SeguimientoTribunalesService
    , private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idAmigablesDetalle = params.id;
      this.cargarSeguimientoAmigable();
      //this.cargarTiposPersona();
      this.cargarTipos();
    });
  }
  idPersona: any;
  cargarSeguimientoAmigable() {
    this.svcAmigable.getSeguimientoAmigable(this.idAmigablesDetalle).subscribe((data: {}) => {
      this.seguimiento = data;
     this.dataSourceActuacion = new MatTableDataSource(this.seguimiento ? this.seguimiento.amigablesActuaciones : []);
      this.dataSourceComponedores = new MatTableDataSource(this.seguimiento != null && this.seguimiento.personasAmigables != null ? this.seguimiento.personasAmigables.filter(p => p.idTipoPersona == 1) : []);
      this.dataSourceApoderadoConvocado = new MatTableDataSource(this.seguimiento != null && this.seguimiento.personasAmigables != null ? this.seguimiento.personasAmigables.filter(p => p.idTipoPersona == 2) : []);
      this.dataSourceApoderadoConvocante = new MatTableDataSource(this.seguimiento != null && this.seguimiento.personasAmigables != null ? this.seguimiento.personasAmigables.filter(p => p.idTipoPersona == 3) : []);

    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }
  onInhabilitarPersona(id) {
   this.svcAmigable.finalizarPersona(id).subscribe((data: {}) => {
      this.initData(data);
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }
  cancelar() {
    this.router.navigate(['/infAmigables', JSON.parse(localStorage.getItem('idP'))]);
  }
  cargarTipos() {
    return this.service.getTiposActuacion('A').subscribe((data: {}) => {
     this.tipos = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }
  cargarTiposPersona() {
    this.svcAmigable.getTiposPersona().subscribe((data: {}) => {
      this.tiposPersona = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }
  modalActuacion() {
    const dialogRef = this.dialog.open(ActuacionADialog, { width: '600px', data: { tipos: this.tipos } });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result !== '') {
        if (result.idTipo === result.idTipoProximaActuacion) {
          alert('El tipo de Actuación no puede ser igual a la proxima actuación')
        } else {
          this.actuacionTemporal = {
            idAmigable: Number(this.idAmigablesDetalle),
            idTipo: result.idTipo,
            fecha: result.fecha,
            descripcion: result.descripcion,
            idTipoProximaActuacion: result.idTipoProximaActuacion,
            idDocumento: 1,
            fechaSiguienteActuacion: result.fechaSiguienteActuacion,
            descripcionProximaActuacion: result.descripcionProximaActuacion,
          };
          this.temporalEnviar = {};
          this.temporalEnviar = this.actuacionTemporal;
          this.svcAmigable.guardarSeguientoActuacion(this.temporalEnviar).subscribe((data: {}) => {
            if (data) {
              this.initData(data);
            }
          },error => {
            this.principalComponent.cargarErrorServicio(error);
          });
        }
      }
    });


  }

  modalPersona(nombreTipoPersona, tipoPersona) {
    // todo: este es el criterio para saber si ya tiene amigables
    var personasPorTipo = this.seguimiento.personasAmigables.filter(p => p.idTipoPersona == tipoPersona).length;

    const dialogRef = this.dialog.open(PersonaAmigableDialog, {
      width: '600px', data: {
        namePersona: nombreTipoPersona,
        detalle: {
          idPersona: 0
        },
        activeForm: true,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
     if (result != null && result !== '') {

        this.personaTemporal = {
          idAmigable: this.idAmigablesDetalle,
          amigablePersonas: [{
            idEncabezado: 0,
            idPersona: result.detalle.idPersona

          }],
          fechaInicio: result.fechaInicio,
          fechaFin: result.fechaFin,
          observaciones: result.observaciones,
          IdTipoPersona: tipoPersona

        };
        this.temporalEnviar = {};
        this.temporalEnviar = this.personaTemporal;
        this.svcAmigable.guardarPersona(this.temporalEnviar).subscribe((data: {}) => {
          this.cargarSeguimientoAmigable();
        },error => {
          this.principalComponent.cargarErrorServicio(error);
        });
      }
    });

  }



  initData(payload) {
    this.cargarSeguimientoAmigable();
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'actuacion-dialog',
  templateUrl: './actuacion.html',
})
// tslint:disable-next-line:component-class-suffix
export class ActuacionADialog {
  maxDate: Date;
  enableAdministrativo = false;

  constructor(
    public dialogRef: MatDialogRef<ActuacionADialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.maxDate = new Date();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeEstado(event) {
    if (event === 9) {
      this.enableAdministrativo = true;
    } else {
      this.enableAdministrativo = false;
      this.data.actoAdministrativo = null;

    }
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'personaamigable-dialog',
  templateUrl: './persona.html',
})
export class PersonaAmigableDialog implements OnInit {

  municipios: any = [];
  temp;
  disabledEntidad = false;
  maxDate: Date;
  idPersona;
  namePersona;
  activeForm = true;

  constructor(
    public dialogRef: MatDialogRef<PersonaAmigableDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private detalleService: SeguimientoTribunalesService,
  ) {
    this.temp = data;
    this.maxDate = new Date();
    this.namePersona = data.namePersona;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() { }


  displayCounter(count) {
    this.data.idPersona = count;
  }

}
