import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeguimientoComunicacionesDetalleService } from './seguimiento-comunicaciones-detalle.service';
import { Proyecto, ProyectoLineaBase } from '../../../../dtos/seguimiento-comunicaciones/proyecto-comunicaciones';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

  // Componente de Detalle - Seguimiento

@Component({
  selector: 'app-seguimiento-comunicaciones-detalle',
  templateUrl: './seguimiento-comunicaciones-detalle.component.html',
  styleUrls: ['./seguimiento-comunicaciones-detalle.component.sass']
})

export class SeguimientoComunicacionesDetalleComponent implements OnInit {

  // Inicializaciones de Variables

  proyecto: Proyecto = {};
  proyectoTemporal: Proyecto = {};
  lineabase: ProyectoLineaBase;

  origenSolicitudes: any = [];
  filtroOrigenSolicitudes: any[];
  origenSolicitudesSeleccionado: any = null;

  solicitantes: any = [];
  filtroSolicitantes: any[];
  solicitanteSeleccionado: any = null;

  tipoSolicitudes: any = [];
  filtroTipoSolicitud: any[];
  tipoSolicitudSeleccionado: any = null;

  remitentes: any = [];
  filtroRemitente: any[];
  remitenteSeleccionado: any = null;

  tipoDocumentoSolicitante;

  dependencias: any = [];
  filtroDependencias: any[];
  dependenciaSeleccionado: any = null;

  gruposInternos: any = [];
  filtroGruposInternos: any[];
  grupoInternoSeleccionado: any = null;

  profesionalesCargo: any = [];
  filtroProfesionales: any[];
  profesionalSeleccionado: any = null;

  modos: any = [];
  filtroModos: any[];
  modoSeleccionado: any = null;

  proyectosComunicaciones: any = [];
  filtroProyectos: any[];
  proyectoSeleccionado: any = null;

  nombreProyecto: string;
  visibleDialogoExito: boolean;
  idSolicitud: any;
  enableEdit = false;
  dependenciaObject: any = {};
  dependeciaArray: any = [];
  dependeciaArrayNames: any = [];
  proyectosObject: any = {};
  proyectosArray: any = [];
  proyectosArrayNames: any = [];
  numeroRadicadoObject: any = [];
  habilitarRadicado: boolean;
  habilitarProyecto: boolean;
  isNit: boolean;
  isSave: boolean;

  habilitarAgregarSolicitante: boolean;
  visibleDialogoAgregarSolicitante: boolean;


  dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent>;

  constructor(
    private detalleService: SeguimientoComunicacionesDetalleService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public location: Location,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.habilitarRadicado = false;
    this.habilitarProyecto = false;
    this.isNit = false;
    this.isSave = false;
    this.habilitarAgregarSolicitante = false;
    this.visibleDialogoAgregarSolicitante = false;
    this.route.params.subscribe(params => {
      this.idSolicitud = params.id;
      this.idSolicitud === undefined ? this.cargarNuevo(this.idSolicitud) : this.cargarProyectoComunicaciones(this.idSolicitud);
    });
  }

  // Funciones del componente
  cargarOrigen(event) {
    this.filtroOrigenSolicitudes = [];
    return this.detalleService.getOrigen().subscribe((data: {}) => {
      this.origenSolicitudes = data;
      let existeFiltro: boolean;

      for (const row of this.origenSolicitudes) {
        const item: string = String(row.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroOrigenSolicitudes.push(row);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.origenSolicitudes = this.filtroOrigenSolicitudes;
      }
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarSolicitante(event) {
    this.filtroSolicitantes = [];
    const eventDocument = this.proyecto.tipoDocumento;

    if (eventDocument !== undefined && eventDocument !== null && eventDocument !== '') {
      return this.detalleService.getSolicitante(eventDocument).subscribe((data: {}) => {
        this.solicitantes = data;
        let existeFiltro: boolean;
        for (const row of this.solicitantes) {
          const item: string = String(row.nombreCompleto);
          if (item.toLowerCase().includes(event.query.toLowerCase())) {
            this.filtroSolicitantes.push(row);
            existeFiltro = true;
          }
        }
        if (existeFiltro) {
          this.solicitantes = this.filtroSolicitantes;
        }
      },error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    } else {
      this.lanzarMensajeWarning('Debe seleccionar un tipo de documento');
    }
  }

  cargarDependencias(event) {
    this.filtroDependencias = [];
    return this.detalleService.getDependencia().subscribe((data: {}) => {
      this.dependencias = data;
      let existeFiltro: boolean;
      for (const row of this.dependencias) {
        const item: string = String(row.nombreCompleto);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDependencias.push(row);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.dependencias = this.filtroDependencias;
      }
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }


  limpiarGrupos(event) {
    this.grupoInternoSeleccionado = null;
    this.profesionalSeleccionado = null;
  }


  cargarGrupoInterno(event) {
    this.filtroGruposInternos = [];
    if (this.dependenciaSeleccionado.id) {
      return this.detalleService.getGrupoInterno(this.dependenciaSeleccionado.id).subscribe((data: {}) => {
        this.gruposInternos = data;
        let existeFiltro: boolean;
        for (const row of this.gruposInternos) {
          const item: string = String(row.nombre);
          if (item.toLowerCase().includes(event.query.toLowerCase())) {
            this.filtroGruposInternos.push(row);
            existeFiltro = true;
          }
        }
        if (existeFiltro) {
          this.gruposInternos = this.filtroGruposInternos;
        }
      },error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

  }

  cargarTipoSolicitud(event) {
    this.filtroTipoSolicitud = [];
    return this.detalleService.getTipoSolicitud().subscribe((data: {}) => {
      this.tipoSolicitudes = data;
      let existeFiltro: boolean;
      for (const row of this.tipoSolicitudes) {
        const item: string = String(row.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroTipoSolicitud.push(row);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.tipoSolicitudes = this.filtroTipoSolicitud;
      }
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }


  cargarDependenciasRemitentes(event) {
    this.filtroRemitente = [];
    return this.detalleService.getDependencia().subscribe((data: {}) => {
      this.remitentes = data;
      let existeFiltro: boolean;
      for (const row of this.remitentes) {
        const item: string = String(row.nombrDEPENDENCIAe);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroRemitente.push(row);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.remitentes = this.filtroRemitente;
      }
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }


  cargarProfesionales(event) {
    var datoSeleccionado = null;
    var tipo = null;
    if(this.grupoInternoSeleccionado != undefined && this.grupoInternoSeleccionado.id != undefined){
      datoSeleccionado = this.grupoInternoSeleccionado.id;
      tipo = "GRUPO";
    }else{
      datoSeleccionado = this.dependenciaSeleccionado.id;
      tipo = "DEPENDENCIA";
    }
    
    this.filtroProfesionales = [];
    return this.detalleService.getProfesionalesById(datoSeleccionado, tipo).subscribe((data: {}) => {
      this.profesionalesCargo = data;
      let existeFiltro: boolean;
      for (const row of this.profesionalesCargo) {
        const item: string = String(row.nombreCompleto);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroProfesionales.push(row);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.profesionalesCargo = this.filtroProfesionales;
      }
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarModos(event) {
    this.filtroModos = [];
    return this.detalleService.getModo().subscribe((data: {}) => {
      this.modos = data;
      let existeFiltro: boolean;
      for (const row of this.modos) {
        const item: string = String(row.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroModos.push(row);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.modos = this.filtroModos;
      }
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  limpiarProyectos(event) {
    this.proyectoSeleccionado = null;
  }

  cargarProyectos(event) {
    this.filtroProyectos = [];
    return this.detalleService.getProyectosList(this.modoSeleccionado.id).subscribe((data: {}) => {
      this.proyectosComunicaciones = data;
      let existeFiltro: boolean;
      for (const row of this.proyectosComunicaciones) {
        const item: string = String(row.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroProyectos.push(row);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.proyectosComunicaciones = this.filtroProyectos;
      }
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarNumeroRadicado(payload) {
    return this.detalleService.getNumeroRadicado(payload).subscribe((data: {}) => {
      this.numeroRadicadoObject = data;
      this.proyecto.numeroRadicado = this.numeroRadicadoObject.objeto;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  procesaPropagar(mensaje) {
    this.visibleDialogoAgregarSolicitante = false;
  }


  onChangeInternoExterno(event) {
    if (event) {
      this.proyecto.tipoDocumento = null;
      this.proyecto.idSolicitante = null;
      this.proyecto.idPersonaJuridicaRemitente = null;
      this.solicitanteSeleccionado = null;
    } else {
      this.proyecto.idDependenciaRemitente = null;
      this.remitenteSeleccionado = null;
      this.proyecto.tipoDocumento = '';
    }
  }

  cargarNuevo(id) {
    this.enableEdit = false;
    this.proyecto = {};
    this.proyecto.idOrigenSolicitud = '';
    this.proyecto.idSolicitante = '';
    this.proyecto.idTipoSolicitud = '';
    this.proyecto.idDependenciaRemitente = null;
    this.proyecto.idPersonaJuridicaRemitente = null;
    this.proyecto.idDependencia = '';
    this.proyecto.idProfesionalACargo = '';
    this.proyecto.idGrupoInterno = '';
    this.proyecto.idModo = '';
    this.proyecto.idProyecto = '';
    this.proyecto.asunto = null;
    this.proyecto.linkSolicitud = null;
    this.proyecto.tipoDocumento = '';
    this.proyecto.estadoFinalizado = false;
    this.proyecto.respuestaDefinitiva = false;
    }

  cargarProyectoComunicaciones(id) {
    this.enableEdit = true;
    forkJoin([
      this.detalleService.getProyectoDetalle(id),
      this.detalleService.getOrigen(),
      this.detalleService.getSolicitante('CC'),
      this.detalleService.getTipoSolicitud(),
      this.detalleService.getDependencia(),
      this.detalleService.getModo(),
      this.detalleService.getProfesionales(),
    ]).subscribe(([proyecto, origenList, solicitantesList, tipoSolicitudesList, dependenciasList, modosList, profesionalesCargoList]) => {
      
      this.proyectoTemporal = proyecto;
      this.origenSolicitudes = origenList;
      this.tipoSolicitudes = tipoSolicitudesList;
      this.dependencias = dependenciasList;
      this.modos = modosList;
      this.profesionalesCargo = profesionalesCargoList;

      const origenIdEdit = this.origenSolicitudes.filter(t => t.id === this.proyectoTemporal.idOrigenSolicitud);
      this.proyecto.idOrigenSolicitud = origenIdEdit[0] ?  origenIdEdit[0].id : null;
      this.origenSolicitudesSeleccionado = origenIdEdit[0];

      const tipoSolicitudIdEdit = this.tipoSolicitudes.filter(t => t.id === this.proyectoTemporal.idTipoSolicitud);
      this.proyecto.idTipoSolicitud = tipoSolicitudIdEdit[0] ?  tipoSolicitudIdEdit[0].id : null;
      this.tipoSolicitudSeleccionado = tipoSolicitudIdEdit[0];

      const remitenteDepenenciaEdit = this.dependencias.filter(t => t.id === this.proyectoTemporal.idDependenciaRemitente);
      this.proyecto.idTipoSolicitud = remitenteDepenenciaEdit[0] ?  remitenteDepenenciaEdit[0].id : null;
      this.remitenteSeleccionado = remitenteDepenenciaEdit[0];
      this.proyecto.numeroRadicado = this.proyectoTemporal.numeroRadicado;
      this.proyecto.fechaRadicado = this.proyectoTemporal.fechaRadicado;

      this.proyecto.idTipoSolicitud = this.proyectoTemporal.idTipoSolicitud;
      this.proyecto.asunto = this.proyectoTemporal.asunto;
      this.proyecto.linkSolicitud = this.proyectoTemporal.linkSolicitud;
      this.proyecto.estadoFinalizado = this.proyectoTemporal.estadoFinalizado;
      this.proyecto.respuestaDefinitiva = this.proyectoTemporal.respuestaDefinitiva;
      this.proyecto.tipoDocumento = this.proyectoTemporal.tipoDocumento;
      this.proyecto.isInterno = this.proyectoTemporal.isInterno;
      this.isSave = false;
      this.uploadEditDataDependencias(proyecto);
      this.uploadEditDataProyectos(proyecto);
      if (!this.proyecto.isInterno) {
        this.detalleService.getSolicitante(this.proyectoTemporal.tipoDocumento).subscribe((data: {}) => {
          this.solicitantes = data;
          const tipoDocumentoEdit = this.proyectoTemporal.tipoDocumento;
          let idEditTipo;
          if (tipoDocumentoEdit === 'CC') {
            idEditTipo = this.proyectoTemporal.idSolicitante;
          } else {
            idEditTipo = this.proyectoTemporal.idPersonaJuridicaRemitente;
          }
          const solicitanteIdEdit = this.solicitantes.filter(t => t.id === idEditTipo);
          this.proyecto.idSolicitante = solicitanteIdEdit[0] ?  solicitanteIdEdit[0].id : null;
          this.solicitanteSeleccionado = solicitanteIdEdit[0];
        },error => {
          this.dialogSpin.close();
          this.principalComponent.cargarErrorServicio(error);
      });
      }
    }, error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  // Informativo - Dialogo
  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.location.back();
  }

  addSolicitante() {
    this.habilitarAgregarSolicitante = true;
  }

  onChangeProyecto(event) {
    if (event !== undefined && event !== null && event !== '') {
      this.cargarProyectos(event);
    } else if (event === '') {
      this.proyecto.idProyecto = '';
    }
  }

  onChangeDependecia(event) {
    if (event !== undefined && event !== null  && event !== '') {
      this.cargarGrupoInterno(event);
    } else if (event === '') {
      this.proyecto.idGrupoInterno = '';
    }
   }

   onChangeOrigen(event) {
    if (event !== undefined && event !== null  && event !== '' && event.id === 1) {
      this.cargarNumeroRadicado(event);
      this.habilitarRadicado = true;
    } else {
      this.proyecto.numeroRadicado = '';
      this.habilitarRadicado = false;
    }
   }

   onChangeModo(event) {
    if (event !== undefined && event !== null  && event !== '' && event.id === 0) {
      this.habilitarProyecto = true;
    } else {
      this.habilitarProyecto = false;
    }
   }


   onChangeTipoDocumento(event) {
     this.solicitanteSeleccionado = '';
     if (event !== undefined && event !== null  && event !== '' && event === 'CC') {
       this.isNit = false;
       this.proyecto.idPersonaJuridicaRemitente = null;
     } else {
       this.isNit = true;
       this.proyecto.idSolicitante = '';
     }
   }


   addDependencia(idDependencia, dataInterno, idProfesionalACargo, formSeguimiento: NgForm) {
    const idGrupoInterno = dataInterno;
    const idProfesionalACargoObject =  idProfesionalACargo !== undefined && idProfesionalACargo !== null && idProfesionalACargo !== '' ? idProfesionalACargo.id : null;
    const dependenciaObject = {
      idDependencia,
      idGrupoInterno: idGrupoInterno !== undefined && idGrupoInterno !== null && idGrupoInterno !== '' ? idGrupoInterno.id : 0,
      idProfesionalACargo: idProfesionalACargoObject
    };

    const dependencianame = this.dependencias.filter(res => res.id === idDependencia);
    const grupoInternoName = this.gruposInternos.filter(res => res.id === idGrupoInterno.id);
    const profesionalACargo = this.profesionalesCargo.filter(res => res.id === idProfesionalACargoObject);

    const dependenciaObjectNames = {
      dependenciaName: dependencianame[0].nombre,
      grupoInternoName: grupoInternoName.length === 0 ? '' : grupoInternoName[0].nombre,
      profesionalACargo: profesionalACargo.length === 0 ? '' : profesionalACargo[0].nombreCompleto
      };

    this.dependeciaArray.push(dependenciaObject);
    this.dependeciaArrayNames.push(dependenciaObjectNames);
    this.proyecto.idDependencia = '';
    this.proyecto.idProfesionalACargo = '';
    this.proyecto.idGrupoInterno = '';

    this.dependenciaSeleccionado = null;
    this.profesionalSeleccionado = null;
    this.grupoInternoSeleccionado = null;
    formSeguimiento.resetForm();
  }


  addProyecto(idModo, idProyecto, formSeguimiento: NgForm) {
     const proyectosObject = {
       idModo,
       idProyecto : this.proyectoSeleccionado ? this.proyectoSeleccionado.id : 0
     };
     let encontro = false;
     for (const row of this.proyectosArray) {
       const noAplica = row;
       if (noAplica.idModo === 0) {
         encontro = true;
       }
     }
     if (idModo === 0 && this.proyectosArray.length > 0) {
       this.lanzarMensajeError('No puede agregar el modo no aplica si tiene un modo diferente o igual');
       return ;
     }
     if (encontro && this.proyectosArray.length > 0) {
       this.lanzarMensajeError('No puede agregar el modo; tiene un no aplica');
       return ;
     }
     const modoName = this.modos.filter(res => res.id === idModo);
     const proyectoName = this.proyectoSeleccionado ? this.proyectosComunicaciones.filter(res => res.id === idProyecto.id) : '';

     const proyectosObjectNames = {
        modoName: modoName[0].nombre,
        proyectoName: proyectoName.length === 0 ? '' : proyectoName[0].nombre
     };
     this.proyectosArray.push(proyectosObject);
     this.proyectosArrayNames.push(proyectosObjectNames);
     this.proyecto.idModo = '';
     this.proyecto.idProyecto = '';
     this.modoSeleccionado = null;
     this.proyectoSeleccionado = null;
     formSeguimiento.resetForm();
   }

  getDisabledDependecia() {
    this.proyecto.idDependencia = this.dependenciaSeleccionado ? this.dependenciaSeleccionado.id : '';
    this.proyecto.idProfesionalACargo = this.profesionalSeleccionado ? this.profesionalSeleccionado.id : '';
    if (this.idSolicitud === undefined) {
      if (this.proyecto.idDependencia !== '') {
        return false;
      } else {
        return true;
      }
    } else {
      if ((!this.proyecto.estadoFinalizado || !this.proyecto.respuestaDefinitiva) && this.proyecto.idDependencia !== '') {
        return false;
      } else {
        return true;
      }
    }
  }

  getDisabledProyecto() {
    this.proyecto.idModo = this.modoSeleccionado ? this.modoSeleccionado.id : '';
    this.proyecto.idProyecto = this.proyectoSeleccionado ? this.proyectoSeleccionado.id : '';
    if (this.idSolicitud === undefined) {
      if (this.modoSeleccionado !== null && this.modoSeleccionado.id === 0) {
        return false;
      } else {
        if (this.proyecto.idProyecto !== '') {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if ((!this.proyecto.estadoFinalizado || !this.proyecto.respuestaDefinitiva) && this.proyecto.idModo !== null ) {
        if (this.modoSeleccionado !== null && this.modoSeleccionado.id === 0) {
          return false;
        } else {
          if (this.proyecto.idProyecto !== '') {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    }
  }

  getDisabledGuardar() {
    this.assignId();
    if (this.idSolicitud === undefined) {
      if (this.proyecto.isInterno ) {
        if (this.proyecto.idOrigenSolicitud !== '' && this.proyecto.numeroRadicado !== null && this.proyecto.fechaRadicado !== '' && this.proyecto.idTipoSolicitud !== '' && this.proyecto.asunto !== null && this.proyecto.idDependenciaRemitente !== '' && this.proyectosArray.length > 0 && this.dependeciaArray.length > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        if (this.proyecto.idOrigenSolicitud !== '' && this.proyecto.numeroRadicado !== null && this.proyecto.fechaRadicado !== '' && this.proyecto.idSolicitante !== '' && this.proyecto.idTipoSolicitud !== '' && this.proyecto.asunto !== null && this.proyectosArray.length > 0 && this.dependeciaArray.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (this.proyecto.isInterno ) {
        if ((!this.proyecto.estadoFinalizado || !this.proyecto.respuestaDefinitiva) && ( this.proyecto.idOrigenSolicitud !== null && this.proyecto.numeroRadicado !== null && this.proyecto.fechaRadicado !== null  && this.proyecto.asunto !== null && this.proyecto.idDependenciaRemitente !== '' && this.proyectosArray.length > 0 && this.dependeciaArray.length > 0)) {
          return false;
        } else {
          return true;
        }
      } else {
        if ((!this.proyecto.estadoFinalizado || !this.proyecto.respuestaDefinitiva) && this.proyecto.idOrigenSolicitud !== '' && this.proyecto.numeroRadicado !== null && this.proyecto.fechaRadicado !== '' && this.proyecto.idSolicitante !== '' && this.proyecto.idTipoSolicitud !== '' && this.proyecto.asunto !== null && this.proyectosArray.length > 0 && this.dependeciaArray.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  assignId() {
    this.proyecto.idOrigenSolicitud = this.origenSolicitudesSeleccionado ? this.origenSolicitudesSeleccionado.id : '';
    this.proyecto.idSolicitante = this.solicitanteSeleccionado ? this.solicitanteSeleccionado.id : '';
    this.proyecto.idTipoSolicitud = this.tipoSolicitudSeleccionado ? this.tipoSolicitudSeleccionado.id : '';
    this.proyecto.idDependenciaRemitente = this.remitenteSeleccionado ? this.remitenteSeleccionado.id : '';
    this.proyecto.idDependencia =  this.dependenciaSeleccionado ? this.dependenciaSeleccionado.id : '';
    this.proyecto.idModo = this.modoSeleccionado ? this.modoSeleccionado.id : '';
  }

  deleteDependecia(index) {
    if (index !== undefined && index !== null) {
        this.dependeciaArrayNames.splice(index, 1);
        this.dependeciaArray.splice(index, 1);
    }
  }

  deleteProyecto(index) {
    if (index !== undefined && index !== null) {
        this.proyectosArrayNames.splice(index, 1);
        this.proyectosArray.splice(index, 1);
    }
  }

  guardarDetalle(payload) {
    this.isSave = true;
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.assignId();
    if (this.proyecto.tipoDocumento !== undefined && this.proyecto.tipoDocumento !== null
      && this.proyecto.tipoDocumento !== '' && this.proyecto.tipoDocumento === 'CC') {
      this.isNit = false;
    } else {
      this.isNit = true;
    }

    const sendData = {
      id : this.idSolicitud !== undefined ? this.proyectoTemporal.id : 0,
      idOrigenSolicitud : this.proyecto.idOrigenSolicitud,
      numeroRadicado : this.proyecto.numeroRadicado,
      fechaRadicado : this.proyecto.fechaRadicado,
      idSolicitante : this.proyecto.isInterno ? null : (this.isNit ? null : this.proyecto.idSolicitante),
      idTipoSolicitud : this.proyecto.idTipoSolicitud,
      idDependenciaRemitente : this.proyecto.isInterno ? this.proyecto.idDependenciaRemitente : null,
      idPersonaJuridicaRemitente : this.proyecto.isInterno ? null : (this.isNit ? this.proyecto.idSolicitante : null),
      isInterno : this.proyecto.isInterno,
      tipoDocumento : this.proyecto.isInterno ? null : this.proyecto.tipoDocumento,
      asunto : this.proyecto.asunto,
      linkSolicitud : this.proyecto.linkSolicitud,
      proyectos: this.proyectosArray ? this.proyectosArray : null,
      dependencias: this.dependeciaArray ? this.dependeciaArray : null
      };
    if (this.idSolicitud === undefined) {
      return this.detalleService.guardarNuevo(sendData).subscribe((data: {}) => {
        this.responseGuardar(data);
      },error => {
          this.dialogSpin.close();
          this.principalComponent.cargarErrorServicio(error);
        });
    } else {
      return this.detalleService.guardarDetalle(sendData).subscribe((data: {}) => {
        this.responseGuardar(data);
      },error => {
          this.dialogSpin.close();
          this.principalComponent.cargarErrorServicio(error);
      });
    }
  }

  responseGuardar(payload) {
    if (payload.objeto === null) {
      this.lanzarMensajeError(payload.respuestaServicio.mensajeSalida);
      this.dialogSpin.close();
      this.isSave = false;
    } else {
      this.visibleDialogoExito = true;
      this.dialogSpin.close();
      this.isSave = false;
    }
  }
  cancelar() {
    this.location.back();
  }
  uploadEditDataDependencias(proyecto) {
    this.dependeciaArray = [];
    this.dependeciaArrayNames = [];
    if (proyecto.dependencias) {
    const resultIds = proyecto.dependencias.map(data => {
      const objectPush = {
        id: data.id,
        idDependencia:  data.idDependencia,
        idGrupoInterno:  data.idGrupoInterno,
        idProfesionalACargo:  data.idProfesionalACargo
    };
      return objectPush;
    });
    const resultNames = proyecto.dependencias.map(data => {
      const objectPushNames = {
        dependenciaName:  data.dependencia,
        grupoInternoName:  data.grupoInterno,
        profesionalACargo:  data.profesionalACargo
    };
      return objectPushNames;
    });
    this.dependeciaArray = resultIds;
    this.dependeciaArrayNames = resultNames;
    }
  }

  uploadEditDataProyectos(proyecto) {
    this.proyectosArray = [];
    this.proyectosArrayNames = [];
    if (proyecto.proyectos) {
    const resultIds = proyecto.proyectos.map(data => {
      const objectPush = {
        id: data.id,
        idModo:  data.idModo,
        idProyecto:  data.idProyecto
    };
      return objectPush;
    });

    const resultNames = proyecto.proyectos.map(data => {
      const objectPushNames = {
        modoName:  data.modo,
        proyectoName:  data.proyecto
    };
      return objectPushNames;
    });
    this.proyectosArray = resultIds;
    this.proyectosArrayNames = resultNames;
    }
  }

    lanzarMensajeInfo(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
    }
    lanzarMensajeError(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    }

    lanzarMensajeWarning(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
    }
    limpiarMensajes() {
      this.messageService.clear();
    }
}


