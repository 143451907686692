import { Component, OnInit, Input } from '@angular/core';
import { AlmacenamientoService } from '../almacenamiento/almacenamiento.service';

@Component({
  selector: 'app-visor-almacenamiento',
  templateUrl: './visor-almacenamiento.component.html',
  styleUrls: ['./visor-almacenamiento.component.sass']
})
export class VisorAlmacenamientoComponent implements OnInit {

  constructor(private svc: AlmacenamientoService) { }
  @Input() pdfSrc:any;
  ngOnInit() {
  }


}
