import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { retry  } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AdjuntarAlmacenamientoPublicService {
    
    public END_POINT = '';


    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public downloadFile(data) {
        return this.http.get(this.END_POINT + '/Comun/getFilePublic/' + data.nombreArchivo + '/' + data.modulo, this.httpOptions)
            .pipe(
                retry(1));
    }

    public uploadFile(data) {
        return this.http.post(this.END_POINT + '/Comun/setFilePublic', data)
            .pipe(
                retry(1));
    }

    public getTiposSoporte(modulo, idTiposSoporte) {
        return this.http.get(this.END_POINT + '/TipoSoportes/' + modulo + '/' + idTiposSoporte)
            .pipe(
                retry(1));
    }
}