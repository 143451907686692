import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TraficoPeajesCargaService } from './trafico-peajes-carga.service';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { PjePeajePorProyectoRpt, PjePeajePorProyecto } from '../PjePeajePorProyecto';
import { DatePipe } from '@angular/common';
import { CargaTraficoArchivoTraficoPeaje } from '../CargaTraficoArchivoTraficoPeaje';
import { forkJoin } from 'rxjs';
import { InformacionGeneralService } from '../../../informacion-general/informacion-general.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumCmsConstantes } from 'src/app/comisiones/comisiones-constantes';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';


@Component({
    selector: 'app-trafico-peajes',
    templateUrl: './trafico-peajes-carga.component.html',
    styleUrls: ['./trafico-peajes-carga.component.sass']
})

export class TraficoPeajesComponent implements OnInit {

    // Variables Componente
    visibleDialogoSubirPlano: boolean;
    rutaPlano: string;
    archivos: any = [];
    id: string;
    nombreArchivo: string;
    idPeajeArchivo: string;
    rutaTrafico: string;
    displayedColumns: any[];
    private archivosCargados: any = [];
    peajesPorProyecto: any[];
    peajeSeleccionado: PjePeajePorProyecto;
    fechaInicioConvert: Date;
    fechaFinConvert: Date;
    fechaMaxima: Date;
    cargaTraficoArchivoPeaje: CargaTraficoArchivoTraficoPeaje;
    idProyecto: number;
    blockedDocument = false;
    visibleConfirmarAnular = false;
    idArchivoAnular: string;
    proyecto: any;
    nombreProyecto: string;



    // variables para cargar lista de peajes
    filtroPeajes: any[];

    // variables hora
    horaInicio: number;
    horaFin: number;
    minutosInicio: number;
    minutosFin: number;


    constructor(private traficoPeajesCargaService: TraficoPeajesCargaService, private infoService: InformacionGeneralService, private router: Router, private ruta: ActivatedRoute, private messageService: MessageService, private principalComponent: PrincipalComponent, public datepipe: DatePipe) { }


    ngOnInit() {
        this.cargaTraficoArchivoPeaje = new CargaTraficoArchivoTraficoPeaje();
        this.fechaMaxima = new Date();
        this.horaFin = 23;
        this.minutosFin = 59;
        this.horaInicio = 0;
        this.minutosInicio = 0;

        this.ruta.params.subscribe(params => {
            this.idProyecto = params.id;
        });

        this.cargarProyectoCarretero(this.idProyecto);

        this.displayedColumns = [
            { field: 'id', header: 'IdArchivo' },
            { field: 'nombreArchivo', header: 'Nombre Archivo' },
            { field: 'fechaCarga', header: 'Fecha Carga' },
            { field: 'registros', header: 'Cantidad Registros' },
            { field: 'fechaDesde', header: 'Fecha Desde' },
            { field: 'fechaHasta', header: 'Fecha Hasta' },
            { field: 'estado', header: 'Estado' }
        ];
    }

    onNew(form: NgForm) {
        this.fechaInicioConvert = new Date();
        this.fechaFinConvert = new Date();
        this.fechaMaxima = new Date();

    }

    cargarProyectoCarretero(id: number) {
        this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
            this.proyecto = data;
            this.nombreProyecto = this.proyecto.nombre;
        }, error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
    }


    cargarPeajes(event) {
        this.blockedDocument = true;

        this.filtroPeajes = [];
        return this.traficoPeajesCargaService.getPeajes(this.idProyecto).subscribe(
            result => {
                if (result != null && result.respuestaServicio != null) {
                    if (result.respuestaServicio.codigoSalida !== 0) {
                        this.peajesPorProyecto = result.peajes;
                    } else {
                    }
                } else {
                }
            },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;
            }
        );
    }


    // Carga lista de archivos de acuerdo a peaje seleccionado
    cargarArchivosCargados(event) {
        this.blockedDocument = true;
        return this.traficoPeajesCargaService.getArchivosPorIdPeaje(this.peajeSeleccionado.idPeaje).subscribe(
            result => {
                if (result != null && result.respuestaServicio != null) {
                    if (result.respuestaServicio.codigoSalida !== 0) {
                        this.archivosCargados = result.archivosCargados;
                    } else {
                    }
                } else {
                }
            },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;
            }
        );
    }




    irVerDialogoSubirPlano() {

        // Set ruta de archivo
        if (this.idProyecto === 0) {
            this.id = 'Peajes Externos/' + this.peajeSeleccionado.idPeaje + '/' + this.datepipe.transform(this.fechaInicioConvert, 'yyyy') + '/' + this.datepipe.transform(this.fechaInicioConvert, 'MM') + '/';
        } else {
            this.id = 'Proyecto_' + this.peajeSeleccionado.idProyecto + '/' + this.peajeSeleccionado.idPeaje + '/' + this.datepipe.transform(this.fechaInicioConvert, 'yyyy') + '/' + this.datepipe.transform(this.fechaInicioConvert, 'MM') + '/';
        }


        // Set idPeaje
        switch (this.peajeSeleccionado.idPeaje.toString().length) {
            case (1):
                this.idPeajeArchivo = '00' + this.peajeSeleccionado.idPeaje.toString();
                break;
            case (2):
                this.idPeajeArchivo = '0' + this.peajeSeleccionado.idPeaje.toString();
                break;
            case (3):
                this.idPeajeArchivo = this.peajeSeleccionado.idPeaje.toString();
                break;
        }
        // Set hora fechas
        this.fechaInicioConvert.setHours(this.horaInicio);
        this.fechaInicioConvert.setMinutes(this.minutosInicio);
        this.fechaFinConvert.setHours(this.horaFin);
        this.fechaFinConvert.setMinutes(this.minutosFin);
        // Valida que la fecha fin no sea mayor a la fecha actual
        if (this.fechaFinConvert < this.fechaMaxima) {
            // Set nombre archivo
            const fechaDesde: string = this.datepipe.transform(this.fechaInicioConvert, 'yyyy') + this.datepipe.transform(this.fechaInicioConvert, 'MM') + this.datepipe.transform(this.fechaInicioConvert, 'dd') + ' ' + this.datepipe.transform(this.fechaInicioConvert, 'HH' + this.datepipe.transform(this.fechaInicioConvert, 'mm'));
            const fechaHasta: string = this.datepipe.transform(this.fechaFinConvert, 'yyyy') + this.datepipe.transform(this.fechaFinConvert, 'MM') + this.datepipe.transform(this.fechaFinConvert, 'dd') + ' ' + this.datepipe.transform(this.fechaFinConvert, 'HH' + this.datepipe.transform(this.fechaFinConvert, 'mm'));
            this.nombreArchivo = 'TrafPje' + this.idPeajeArchivo + '_' + this.datepipe.transform(this.fechaInicioConvert, 'yyyy') + this.datepipe.transform(this.fechaInicioConvert, 'MM') + this.datepipe.transform(this.fechaInicioConvert, 'dd')
                + '-' + this.datepipe.transform(this.fechaFinConvert, 'yyyy') + this.datepipe.transform(this.fechaFinConvert, 'MM') + this.datepipe.transform(this.fechaFinConvert, 'dd');

            // Valida información de fechas

            this.traficoPeajesCargaService.validarFechas(fechaDesde, fechaHasta, this.peajeSeleccionado.idPeaje.toString(), this.cargaTraficoArchivoPeaje).subscribe((data: {}) => {
                const array: any = data;
                if (array.respuestaServicio.codigoSalida !== EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                    this.visibleDialogoSubirPlano = true;
                }
            });
        } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Fecha Fin no puede ser mayor a la actual', life: 10000 });
        }


    }

    cancelarDialogoSubirPlano() {
        this.visibleDialogoSubirPlano = false;
    }

    aceptarAdjuntoTrafico() {

        // Bloquear pantalla
        this.blockedDocument = true;

        this.rutaTrafico = this.archivos[0].nombreCompleto;
        this.visibleDialogoSubirPlano = false;

        // Set objeto CargaTraficoArchivoTraficoPeaje
        this.cargaTraficoArchivoPeaje.idPeaje = this.peajeSeleccionado.idPeaje;
        this.cargaTraficoArchivoPeaje.fechaDesde = this.datepipe.transform(this.fechaInicioConvert, 'yyyyMMdd HH:mm');
        this.cargaTraficoArchivoPeaje.fechaHasta = this.datepipe.transform(this.fechaFinConvert, 'yyyyMMdd HH:mm');
        this.cargaTraficoArchivoPeaje.fuenteAplicacion = 'trafico_peajes';
        this.cargaTraficoArchivoPeaje.extension = 'csv';
        this.cargaTraficoArchivoPeaje.nombreArchivo = this.archivos[0].nombreCompleto;

        // consume servicio post para procesar archivo y crear en bd
        this.traficoPeajesCargaService.guardarArchivoTrafico(this.cargaTraficoArchivoPeaje).subscribe((data: {}) => {
            this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Procesando información...', life: 10000 });
            const array: any = data;


            if (array.respuestaServicio.codigoSalida !== EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: array.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Archivo procesado, favor recargue la página.', life: 10000 });
            }

        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla

                this.cargarArchivosCargados(evento);
                this.blockedDocument = false;

            }
        );


        // recarga tabla según peaje seleccionado
        // tslint:disable-next-line:prefer-const
        let evento: any;
        this.cargarArchivosCargados(evento);

        this.archivos = [];
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Se inician validaciones del archivo.', life: 10000 });

    }

    verAchivoErrores(idArchivo: string) {

        this.traficoPeajesCargaService.verAchivo(idArchivo, 'traficopeajes');

    }

    cancelarAnular() {
        this.visibleConfirmarAnular = false;
    }

    aceptarAnular() {

        // Bloquear pantalla
        this.blockedDocument = true;
        // Cerrar Dialog
        this.visibleConfirmarAnular = false;
        // Guardar los cambios en BD
        forkJoin(
            this.traficoPeajesCargaService.anularArchivo(this.idArchivoAnular)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio !== null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    // recarga tabla según peaje seleccionado
                    // tslint:disable-next-line:prefer-const
                    let evento: any;
                    this.cargarArchivosCargados(evento);
                    this.messageService.add({ severity: 'success', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;

            }
        );


    }

    anularArchivo(idArchivo: string) {

        this.visibleConfirmarAnular = true;
        this.idArchivoAnular = idArchivo;

    }




}
