import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProyectoCarretero } from 'src/app/informacion-proyecto-carretero/proyectoCarretero';
import { AlcanceProyectoBaseComponent } from '../alcance-proyecto-base/alcance-proyecto-base.component';
import { LogintudConcesionadaProyectoService } from './longitud-concesionada-proyecto.service';
import { LongitudConcesionadaProyecto } from './longitudConcesionadaProyecto';
import { MatTableDataSource } from '@angular/material';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-longitud-concesionada-proyecto',
  templateUrl: './longitud-concesionada-proyecto.component.html',
  styleUrls: ['./longitud-concesionada-proyecto.component.sass']
})
export class LongitudConcesionadaProyectoComponent implements OnInit {

  proyectoCarretero: ProyectoCarretero = {};
  //longitudConcesionadaProyecto: LongitudConcesionadaProyecto;
  historicoProyecto: any = [];
  displayedColumns: string[] = ['longitudConcesionada', 'longitudACargo', 'acciones'];
  dataSourceLongitudConcesionada: MatTableDataSource<LongitudConcesionadaProyecto>;
  visibleDialogoExito: boolean = false;
  idLongitud: number;
  fechaPrueba: Date;
  /* longitudC: number;
  longitudACargo: number;
  idLongitudConcesionadaProyecto: number;
  esEditable: Boolean; */

  constructor(private lineaBase: AlcanceProyectoBaseComponent, private messageService: MessageService, private logintudConcesionadaProyectoService: LogintudConcesionadaProyectoService, private route: ActivatedRoute, private ruta: Router,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.lineaBase.idProyecto = params.id;
      this.lineaBase.idProyecto === 'new' ? this.cargarNuevo() : this.cargarProyecto(this.lineaBase.idProyecto);
    });
    // this.cargarLongitudConcesionadaProyecto(this.lineaBase.idProyecto);


  }

  cargarNuevo() { }

  guardarLongitudes() {

    this.lineaBase.longitudConcesionadaProyecto.id = this.lineaBase.idLongitudConcesionadaProyecto;
    this.lineaBase.longitudConcesionadaProyecto.longitudConcesionada = this.lineaBase.longitudC;
    this.lineaBase.longitudConcesionadaProyecto.longitudAcargo = this.lineaBase.longitudACargo;
    //Si es proyecto 1G o 3G
    if (this.lineaBase.aplicaCapexOpex) {
      this.lineaBase.longitudConcesionadaProyecto.tieneCapexOpexPorProyecto = !this.lineaBase.tieneCapexOpexPorProyecto;
      this.lineaBase.longitudConcesionadaProyecto.capex = this.lineaBase.capex;
      this.lineaBase.longitudConcesionadaProyecto.fechaReferenciaCapex = this.lineaBase.fechaReferenciaCapex;
      this.lineaBase.longitudConcesionadaProyecto.opex = this.lineaBase.opex;
      this.lineaBase.longitudConcesionadaProyecto.fechaReferenciaOpex = this.lineaBase.fechaReferenciaOpex;
    }
    forkJoin(
      this.logintudConcesionadaProyectoService.guardarLongitudConcesionada(this.lineaBase.longitudConcesionadaProyecto, this.lineaBase.esBorrador, this.lineaBase.idProyecto, this.lineaBase.idConfiguracion)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.lineaBase.longitudConcesionadaProyecto = new LongitudConcesionadaProyecto();
          this.lineaBase.longitudConcesionadaProyecto[0] = result.longitudConcesionada;
          this.lineaBase.longitudC = this.lineaBase.longitudConcesionadaProyecto[0].longitudConcesionada;
          this.lineaBase.longitudACargo = this.lineaBase.longitudConcesionadaProyecto[0].longitudAcargo;
          this.lineaBase.idLongitudConcesionadaProyecto = this.lineaBase.longitudConcesionadaProyecto[0].id;
          this.lineaBase.esEditable = this.lineaBase.longitudConcesionadaProyecto[0].editable;
          this.visibleDialogoExito = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla

        this.lineaBase.cargarLongitudConcesionada(this.lineaBase.idProyecto, this.lineaBase.esBorrador, this.lineaBase.idConfiguracion);
      }
    );

  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
  }

  cargarProyecto(id: number) {
    this.logintudConcesionadaProyectoService.getProyectoPorId(id).subscribe((data: {}) => {
      this.proyectoCarretero = data;
      this.lineaBase.nombreProyecto = this.proyectoCarretero.nombre;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

}
