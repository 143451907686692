import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { ConfigService } from '../../../config.service';
import { environment } from '../../../../environments/environment';
import { PjeDetallePeriodoDTO, PjeDetallePeriodoRpt } from '../PjeDetallePeriodo';
import { TrnRespuestaServicio } from '../../../parametricas/trn-respuesta-servicio';
import { PjeEncabezadoPeriodoDTO, PjeEncabezadoPeriodoRpt } from '../PjeEncabezadoPeriodo';

@Injectable({
    providedIn: 'root'
})
export class DetalleRecaudoPeajesService {


    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    cargaDetallePeriodoSinRecaudo(idPeriodo: number) {
        return this.http.get<PjeDetallePeriodoRpt>(this.END_POINT + '/PjeRecaudoPeajes/GetDetalleSinRecaudo/' + idPeriodo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    cargaDetallePeriodo(idPeriodo: number) {
        return this.http.get<PjeDetallePeriodoRpt>(this.END_POINT + '/PjeRecaudoPeajes/GetDetallePeriodo/' + idPeriodo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    cargaEncabezadoPeriodo(idPeriodo: number) {
        return this.http.get<PjeEncabezadoPeriodoRpt>(this.END_POINT + '/PjeRecaudoPeajes/GetEncabezadoPeriodo/' + idPeriodo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    public editaDetallePeriodoFila(pjeDetallePeriodoDTO: PjeDetallePeriodoDTO): Observable<GuardarDetalleRecaudoRpt> {

        return this.http.post<GuardarDetalleRecaudoRpt>(this.END_POINT + '/PjeRecaudoPeajes/EditaRecaudo', pjeDetallePeriodoDTO)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public editaDetallePeriodoFilaSinRecaudo(pjeDetallePeriodoDTO: PjeDetallePeriodoDTO): Observable<GuardarDetalleRecaudoRpt> {

        return this.http.post<GuardarDetalleRecaudoRpt>(this.END_POINT + '/PjeRecaudoPeajes/EditaTraficoSinRecaudo', pjeDetallePeriodoDTO)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public editaEncabezadoPeriodo(pjeEncabezadoPeriodoDTO: PjeEncabezadoPeriodoDTO): Observable<GuardarEncabezadoRecaudoRpt> {
        return this.http.post<GuardarDetalleRecaudoRpt>(this.END_POINT + '/PjeRecaudoPeajes/EditaRecaudoEncabezado', pjeEncabezadoPeriodoDTO)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public apruebaTraficoSinRecaudo(idPeriodo: number): Observable<ApruebaRecaudoSinTraficoRpt> {
        return this.http.post<ApruebaRecaudoSinTraficoRpt>(this.END_POINT + '/PjeRecaudoPeajes/ApruebaTraficoSinRecaudo', idPeriodo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public cancelaRecaudo(pjeEncabezadoPeriodoDTO: PjeEncabezadoPeriodoDTO): Observable<CancelaBorradorRpt> {
        return this.http.post<CancelaBorradorRpt>(this.END_POINT + '/PjeRecaudoPeajes/CancelaBorrador', pjeEncabezadoPeriodoDTO)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


}

export class GuardarDetalleRecaudoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class GuardarEncabezadoRecaudoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class CancelaBorradorRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class ApruebaRecaudoSinTraficoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}
