import { Injectable } from '@angular/core';
import { map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { InformacionGeneral, InformacionGeneralEnvio } from './informacionGeneral';


@Injectable({
    providedIn: 'root'
})

export class AmparosEditService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo que carga la informacion del Anexo
     * @param idAnexo 
     */
    public obtenerInformacionAnexo(idAnexo) {
        return this.http.get(this.END_POINT + '/PlzPoliza/ConsultarAnexo/' + idAnexo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo para guradar el Anexo
     * @param informacionGeneralDTO 
     * @param idProyecto 
     */
    public guardarInformacionAnexo(informacionGeneralDTO: InformacionGeneralEnvio){
        return this.http.post<ConsultaInformacionGeneralRpt>(this.END_POINT + '/PlzPoliza/GuardarAnexoPoliza', informacionGeneralDTO)
        .pipe(
            map(data => {
                return data;
            }),
            retry(1));
    }

    /**
     * Metodo que consulta los tipos de amparos
     * @param idPoliza 
     */
     public getPlzMaTipoAmparo(idPoliza) {
        let service = "";
        if(idPoliza== null){
            service = '/PlzMaTipoAmparo/ConsultarTiposAmparoParaAgregar';
        }else{
            service = '/PlzMaTipoAmparo/ConsultarTiposAmparoParaAgregar?idPoliza='+idPoliza;
        }
        return this.http.get(this.END_POINT + service)
            .pipe(
                retry(1));
    }

    /**
   * Metodo que carga la lista de las asociados a
   * @param idPoliza 
   */
   public getAmparosModificacion(idPoliza) {
    return this.http.get(this.END_POINT + '/PlzPoliza/ConsultarAmparosParaModificacion/' + idPoliza ) 
    .pipe(
        retry(1));
    }
    

    /**
   * Metodo que carga la lista de las asociados a
   * @param id 
   */
   public getNovedadesContractuales(idProyecto) {
    return this.http.get(this.END_POINT + '/PlzMaNovedadesContractuales/ConsultarNovedadesContractualesPolizas/' + idProyecto ) 
    .pipe(
        retry(1));
    } 

}

export class ConsultaInformacionGeneralProyectoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public informacionGeneralProyecto?: InformacionGeneral[]
    ) { }
}


export class ConsultaInformacionGeneralRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}