import { Component, OnInit } from '@angular/core';
import { CovidReportarService } from 'src/app/covid-reportar/covid-reportar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-reporte-sintomas-interno',
  templateUrl: './reporte-sintomas-interno.component.html',
  styleUrls: ['./reporte-sintomas-interno.component.sass']
})
export class ReporteSintomasInternoComponent implements OnInit {

  private reporte: any = [];
  fechaCorte: Date;
  idProyecto: number;

  constructor(private route: Router, private ruta: ActivatedRoute, private covidReportarService: CovidReportarService, private location: Location, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
    });
  }

  accionConsultar() {
    let fecha: any;
    if (this.fechaCorte == null) {
      fecha = "01-01-1900";
    } else {
      if (typeof this.fechaCorte === 'undefined') {
        fecha = "01-01-1900";
      } else {
        let mes = this.fechaCorte.getMonth() + 1;
        if (mes < 10) {
          fecha = '0' + mes + '-' + this.fechaCorte.getDate() + '-' + this.fechaCorte.getFullYear();
        } else {
          fecha = mes + '-' + this.fechaCorte.getDate() + '-' + this.fechaCorte.getFullYear();
        }
      }
    }
    this.cargarReporte(fecha);
  }

  cargarReporte(fecha) {
    return this.covidReportarService.getReporteCovidSintomas(fecha, this.idProyecto, 0).subscribe((data: {}) => {
      this.reporte = data;
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.reporte);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "reporteCovidSintomas");
      });
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  exportExcel() {
    this.accionConsultar();

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  irAReportesCovid() {
    this.location.back();
  }

}
