import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ComponentesComunesService } from '../componentes-comunes/componentes-comunes.service';
import { ProyectoDTO } from '../modulo-planeacion-proyecto/planeacion-proyecto.dto';
import { PrediosService } from '../predios/predios.service';
import { forkJoin } from 'rxjs';
import { CrrEquipoItsDTO } from './equipos-its.dto';
import { EquiposItsService } from './equipos-its.service';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-equipos-its',
  templateUrl: './equipos-its.component.html',
  styleUrls: ['./equipos-its.component.sass']
})
export class EquiposItsComponent implements OnInit {

  blockedDocument: boolean;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  equipoIts: CrrEquipoItsDTO;
  equipoItsList: CrrEquipoItsDTO[];

  dialogoEliminar: boolean;

  constructor(private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private equiposItsService: EquiposItsService,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService) { }

  ngOnInit() {
    // Obtener el proyecto seleccionado
    const idParam = "idProyecto";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
    });
    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
        this.listarEquiposItsPorProyecto();
      },
      (e) => { }
    );
  }

  listarEquiposItsPorProyecto() {
    this.blockedDocument = true;
    forkJoin(
      this.equiposItsService.srvListarEquipoItsPorProyecto(this.idProyecto)
    ).subscribe(
      ([equiposItsData]) => {
        var resultado = equiposItsData.listaCrrEquipoIts;
        this.equipoItsList = resultado;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  btnAgregar() {
    this.router.navigate([
      this.router.url + "/registro-equipos-its/" + this.idProyecto + "/0/false",
    ]);
  }

  btnEditar(equipoSelected: CrrEquipoItsDTO) {
    this.router.navigate([
      this.router.url +
      "/registro-equipos-its/" +
      this.idProyecto +
      "/" +
      equipoSelected.id + "/" + false,
    ]);
  }

  btnVer(equipoSelected: CrrEquipoItsDTO) {
    this.router.navigate([
      this.router.url +
      "/registro-equipos-its/" +
      this.idProyecto +
      "/" +
      equipoSelected.id + "/" + true,
    ]);
  }

  btnEliminar(equipoSelected: CrrEquipoItsDTO) {
    this.equipoIts = equipoSelected;
    this.dialogoEliminar = true;
  }

  submitEliminar() {
    forkJoin(
      this.equiposItsService.srvEliminarEquipoIts(
        this.equipoIts.id
      )
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // console.log('----------srvEliminarPredio');
            // console.log(result.respuestaServicio.mensajeSalida);
            //  Consultar nuevamente la lista
            this.listarEquiposItsPorProyecto();
            //  Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            // // console.log(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // // console.log('Ocurrio un error al consumir el servicio srvEliminarPredio');
        }
      },
      (error) => {
        // // console.log(error);
      },
      () => {
        // Desbloquear pantalla
        this.dialogoEliminar = false;
        this.blockedDocument = false;
      }
    );
  }

  bntVolver() {
    const ruta =
      "carreteros/infGeneral/proyectoHome/" +
      this.idProyecto;
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
