import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AmigablesDetalleService } from './amigables-detalle.service';
import { AmigablesService } from '../amigables/amigables.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Amigables } from '../amigables-detalle/amigables';
import { MatTableDataSource } from '@angular/material';
import { ProgressSpinnerDialogComponent } from '../../progress-spinner-dialog/progress-spinner-dialog.component';
import { FormControl } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
		

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'componedores-dialog',
  templateUrl: 'componedores.html',
})
// tslint:disable-next-line:component-class-suffix
export class ComponedoresDialog {

  constructor(
    public dialogRef: MatDialogRef<ComponedoresDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'apoderados-dialog',
  templateUrl: 'apoderados.html',
})
// tslint:disable-next-line:component-class-suffix
export class ApoderadosDialog {

  constructor(
    public dialogRef: MatDialogRef<ApoderadosDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'app-amigables-detalle',
  templateUrl: './amigables-detalle.component.html',
  styleUrls: ['./amigables-detalle.component.sass']
})

export class AmigablesDetalleComponent implements OnInit {

  proyecto: Amigables = {};
  aTemporal: any = [];
  etapas: any = [];
  idAmigablesDetalle: any;
  datosAmigables: any = [];
  datosApoderadosConvocados: any = [];
  datosApoderadosConvocantes: any = [];
  convocantes: any = [];
  convocados: any = [];
  tiposPersona: any = [];

  amigableTemporal: any = {
    primerNombre: '', idAmigable: null, id: null
  }

  apoderadoTemporal: any = {
    primerNombre: '', idAmigable: null, id: null
  }
  primerNombre: string;
  otrosNombres: string;
  primerApellido: string;
  segundoApellido: string;
  idAmigable: number;
  id: number;
  nombreProyecto;
  contratos: any = [];

  dataSource: MatTableDataSource<any>;
  dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent>
  constructor(private detalleService: AmigablesDetalleService, private route: ActivatedRoute, private ruta: Router, public dialog: MatDialog
    , private principalComponent: PrincipalComponent) {
  }
  idProyecto: any;

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'))
    //this.cargarEtapa();
    this.idProyecto = sessionStorage.getItem('idProy');
    this.cargarConvocadosProyecto(this.idProyecto);
    this.cargarConvocantesProyecto(this.idProyecto);
  //  this.cargarTiposPersona();
    this.route.params.subscribe(params => {
      this.idAmigablesDetalle = params.id;
      this.idAmigablesDetalle === 'new' ? this.cargarNuevo(this.idAmigablesDetalle) : this.cargarProyectoAmigables(this.idAmigablesDetalle);
    });
    var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto);

  }
  /*cargarTiposPersona() {
    return this.detalleService.getTiposPersona().subscribe((data: {}) => {
      this.tiposPersona = data;
    });
  }*/
  cargarConvocadosProyecto(idProj) {
    return this.detalleService.getConvocadosProyecto(idProj).subscribe((data: {}) => {
      this.convocados = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }
  cargarConvocantesProyecto(idProj) {
    return this.detalleService.getConvocantesProyecto(idProj).subscribe((data: {}) => {
      this.convocantes = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }
  cargarEtapa() {
    return this.detalleService.getEtapa().subscribe((data: {}) => {
      this.etapas = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarNuevo(id) {
    this.proyecto = {};
    this.reset();
  }

  reset() {
    this.proyecto.idProyecto = Number(JSON.parse(localStorage.getItem('idP')));
    this.proyecto.vgcVe = '';
    this.proyecto.proyecto = '';
    this.proyecto.cto = '';
    this.proyecto.anioCto = 1970;
    this.proyecto.cuantiaPeticiones = 0;
    this.proyecto.amigablesComponedores = '';
    this.proyecto.ultimaActuacion = '';
    this.proyecto.proximaActuacion = '';
    this.proyecto.apoderadosConvocantes = [];
    this.proyecto.apoderadosConvocados = [];
    this.proyecto.componedores = [];
  }

  cargarProyectoAmigables(id: number) {
    if (this.etapas) {
      this.detalleService.getAmigable(id).subscribe((data: {}) => {
        this.proyecto = data;
      },error => {
        this.principalComponent.cargarErrorServicio(error);
      });
      if (this.proyecto) {
        this.datosApoderadosConvocados = this.proyecto.apoderadosConvocados;
        this.datosApoderadosConvocantes = this.proyecto.apoderadosConvocantes;
        this.datosAmigables = this.proyecto.componedores;
      }
    }
  }

  guardarDetalle(payload) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });


        if (this.proyecto.fechaRadicacion) {
          if (this.proyecto.controversia) {
            if (this.proyecto.peticiones) {
              const date = this.convertStringDateToTime(new Date());
              if (this.idAmigablesDetalle === 'new') {
                this.detalleService.guardarNuevoAmigable(payload).subscribe((data: {}) => {
                  this.ruta.navigate(['/infAmigables', JSON.parse(localStorage.getItem('idP'))]);
                  this.dialogSpin.close();
                },error => {
                  this.dialogSpin.close();
                  this.principalComponent.cargarErrorServicio(error);
                });
              } else {
                this.detalleService.guardarDetalleAmigable(payload).subscribe((data: {}) => {
                  if (data) {
                    this.ruta.navigate(['/infAmigables', JSON.parse(localStorage.getItem('idP'))]);
                    this.dialogSpin.close();
                  }
                },error => {
                  this.dialogSpin.close();
                  this.principalComponent.cargarErrorServicio(error);
                });
              }
            } else {
              alert('El campo Peticiones es Obligatorio');
            }
          } else {
            alert('El campo Controversia es Obligatorio');
          }
        } else {
          alert('El campo Fecha Radicación es Obligatorio');
        }

  }

  getTipoEtapa(payload) {
    const dataLength = this.etapas.length;
    for (let i = 0; i <= dataLength; i++) {
      if (this.etapas[i].id === payload) {
        return this.etapas[i].nombre;
      }
    }
  }


  convertStringDateToTime(data) {
    const date = new Date(data);
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = date.getTime() + localOffset;
    return utc;
  }

  cancelar() {
    this.ruta.navigate(['/infAmigables', JSON.parse(localStorage.getItem('idP'))]);
  }

  deleteComponedores(isEdit, isDelete, payload, idProyecto, index) {
    if (isDelete) {
      if (this.proyecto.componedores[index].id === 0) {
        this.proyecto.componedores.splice(index, 1);
      } else {
        this.proyecto.componedores[index].eliminar = true;
      }
    }
  }

  deleteApoderados(isEdit, isDelete, payload, idProyecto, index) {
    if (isDelete) {
      if (this.proyecto.apoderadosConvocados[index].id === 0) {
        this.proyecto.apoderadosConvocados.splice(index, 1);
      } else {
        this.proyecto.apoderadosConvocados[index].eliminar = true;
      }
    }
  }

  deleteApoderadosConvocantes(isEdit, isDelete, payload, idProyecto, index) {
    if (isDelete) {
      if (this.proyecto.apoderadosConvocantes[index].id === 0) {
        this.proyecto.apoderadosConvocantes.splice(index, 1);
      } else {
        this.proyecto.apoderadosConvocantes[index].eliminar = true;
      }
    }
  }




  openDialogAmigables(isEdit, isDelete, payload, idProyecto, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(ComponedoresDialog, {
        width: '300px',
        data: {
          primerNombre: this.primerNombre, otrosNombres: this.otrosNombres,
          primerApellido: this.primerApellido,
          segundoApellido: this.segundoApellido,
          idAmigable: this.idAmigable, id: this.id
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result !== '') {
          let count = 0;
          for (let i = 0; i < this.proyecto.componedores.length; i++) {
            if (this.proyecto.componedores[i].eliminar === false) {
              count = count + 1;
            }
          }
          if (count === 4) {
            window.alert('No puede agregar mas de 4 componedores');
          } else {
            this.amigableTemporal = {
              id: 0, idAmigable: idProyecto ? idProyecto : 0, primerNombre: result.primerNombre, eliminar: false,
              otrosNombres: result.otrosNombres,
              primerApellido: result.primerApellido,
              segundoApellido: result.segundoApellido
            };
            this.proyecto.componedores.push(this.amigableTemporal);
          }
        }
      });
    } else if (isEdit) {
      const dialogRef = this.dialog.open(ComponedoresDialog, {
        width: '300px',
        data: {
          primerNombre: payload.primerNombre, otrosNombres: payload.otrosNombres,
          primerApellido: payload.primerApellido, segundoApellido: payload.segundoApellido,
          idAmigable: payload.idAmigable, id: payload.id
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result !== '') {
          this.amigableTemporal = {
            primerNombre: result.primerNombre,
            otrosNombres: result.otrosNombres,
            primerApellido: result.primerApellido,
            segundoApellido: result.segundoApellido,
            idAmigable: payload ? payload : null, id: null, eliminar: false
          };
          for (let i = 0; i < this.proyecto.componedores.length; i++) {
            if (i === index) {
              this.proyecto.componedores[i] = result;
            }
          }
        }
      });
    }

  }
  idPersona: number = 2;
  test() {
 }
  personaSeleccionada(idPersona) {
  }

  openDialogApoderados(isEdit, isDelete, payload, idProyecto, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(ApoderadosDialog, {
        width: '300px',
        data: {
          primerNombre: this.primerNombre, otrosNombres: this.otrosNombres,
          primerApellido: this.primerApellido, segundoApellido: this.segundoApellido,
          idAmigable: this.idAmigable, id: this.id,
          tipoApoderado: 0
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result !== '') {
          let count = 0;
          for (let i = 0; i < this.proyecto.apoderadosConvocantes.length; i++) {
            if (this.proyecto.apoderadosConvocantes[i].eliminar === false) {
              count = count + 1;
            }
          }
          if (count === 4) {
            window.alert('No puede agregar mas de 4 apoderados');
          } else {
            this.amigableTemporal = {
              id: 0, idAmigable: idProyecto ? idProyecto : 0, primerNombre: result.primerNombre, eliminar: false,
              otrosNombres: result.otrosNombres, primerApellido: result.primerApellido, segundoApellido: result.segundoApellido,
              tipoApoderado: 0
            };
            this.proyecto.apoderadosConvocantes.push(this.amigableTemporal);
          }
        }
      });
    } else if (isEdit) {
      const dialogRef = this.dialog.open(ApoderadosDialog, {
        width: '300px',
        data: {
          primerNombre: payload.primerNombre,
          otrosNombres: payload.otrosNombres,
          primerApellido: payload.primerApellido,
          segundoApellido: payload.segundoApellido,
          idAmigable: payload.idAmigable, id: payload.id,
          tipoApoderado: 0
        }
      });
      dialogRef.afterClosed().subscribe(result => {
       if (result != null && result !== '') {
          this.amigableTemporal = {
            primerNombre: result.primerNombre,
            otrosNombres: result.otrosNombres,
            primerApellido: result.primerApellido,
            segundoApellido: result.segundoApellido,
            idAmigable: payload ? payload : null, id: null, eliminar: false
          };
          for (let i = 0; i < this.proyecto.apoderadosConvocantes.length; i++) {
            if (i === index) {
              this.proyecto.apoderadosConvocantes[i] = result;
            }
          }
        }
      });
    }

  }

  openDialogApoderadosConvocados(isEdit, isDelete, payload, idProyecto, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(ApoderadosDialog, {
        width: '300px',
        data: {
          primerNombre: this.primerNombre, otrosNombres: this.otrosNombres,
          primerApellido: this.primerApellido, segundoApellido: this.segundoApellido,
          idAmigable: this.idAmigable, id: this.id, tipoApoderado: 1
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result !== '') {
          let count = 0;
          for (let i = 0; i < this.proyecto.apoderadosConvocados.length; i++) {
            if (this.proyecto.apoderadosConvocados[i].eliminar === false) {
              count = count + 1;
            }
          }
          if (count === 4) {
            window.alert('No puede agregar mas de 4 apoderados');
          } else {
            this.amigableTemporal = {
              id: 0, idAmigable: idProyecto ? idProyecto : 0, primerNombre: result.primerNombre, eliminar: false,
              tipoApoderado: 1, otrosNombres: result.otrosNombres,
              primerApellido: result.primerApellido, segundoApellido: result.segundoApellido
            };
            this.proyecto.apoderadosConvocados.push(this.amigableTemporal);
          }
        }
      });
    } else if (isEdit) {
      const dialogRef = this.dialog.open(ApoderadosDialog, {
        width: '300px',
        data: {
          primerNombre: payload.primerNombre,
          otrosNombres: payload.otrosNombres,
          primerApellido: payload.primerApellido,
          segundoApellido: payload.segundoApellido,
          idAmigable: payload.idAmigable, id: payload.id,
          tipoApoderado: 1
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != null && result !== '') {
          this.amigableTemporal = {
            primerNombre: result.primerNombre,
            otrosNombres: result.otrosNombres,
            primerApellido: result.primerApellido,
            segundoApellido: result.segundoApellido, tipoApoderado: 1,
            idAmigable: payload ? payload : null, id: null, eliminar: false
          };
          for (let i = 0; i < this.proyecto.apoderadosConvocados.length; i++) {
            if (i === index) {
              this.proyecto.apoderadosConvocados[i] = result;
            }
          }
        }
      });
    }

  }


}



