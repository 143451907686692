import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { NgForm } from '@angular/forms';
import { TarifasPeajesService } from './tarifas-peajes.service';
import { forkJoin } from 'rxjs';
import { PjeConfiguracionesPeaje } from './PjeConfiguracionesPeaje';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';


@Component({
  selector: 'app-tarifas-peajes',
  templateUrl: './tarifas-peajes.component.html',
  styleUrls: ['./tarifas-peajes.component.sass']
})


export class TarifasPeajesComponent implements OnInit {

  idProyecto: number;
  proyecto: any;
  nombreProyecto: string;
  peajesProyecto: any = [];
  eslectura: boolean;
  blockedDocument = false;
  // variables configuraciones
  configuracionVista: boolean;
  nombrePeaje: string;
  configuracionesPeaje: any = [];
  // dataSourceConfiguracionesPeaje: MatTableDataSource<PjeConfiguracionesPeaje>;
  dataSourceConfiguracionesPeaje: PjeConfiguracionesPeaje[];
  existeBorrador: boolean;
  peajeSeleccionado: number;
  idEncabezadoEdita: number;



  constructor(private tarifasPeajesService: TarifasPeajesService, private infoService: InformacionGeneralService, private router: Router, private route: ActivatedRoute,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.blockedDocument = true;
    this.route.params.subscribe(params => {
      this.idProyecto = params.id;
    });
    this.cargarProyectoCarretero(this.idProyecto);
    this.cargarPeajesProyecto(this.idProyecto);
  }

  cargarPeajesProyecto(idProyecto: number) {
    forkJoin(
      this.tarifasPeajesService.cargarTarifasPeajes(idProyecto)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          // recarga tabla según proyecto seleccionado
          this.peajesProyecto = result.peajesProyectoTarifa;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      }
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;

      }
    );

  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
      this.nombreProyecto = this.proyecto.nombre;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irANuevaTarifa() {
    this.eslectura = false;
    if (this.idEncabezadoEdita == null) {
      this.idEncabezadoEdita = 0;
    }
    this.router.navigate([this.router.url + '/nueva-tarifa', this.idProyecto, this.peajeSeleccionado, this.eslectura, this.idEncabezadoEdita]);
  }

  irANuevaConfiguracion() {
    this.eslectura = false;
    this.router.navigate([this.router.url + '/nueva-tarifa', this.idProyecto, this.peajeSeleccionado, this.eslectura, 0]);
  }

  irAVisualizar(idEncabezado: number) {
    this.eslectura = true;
    this.router.navigate([this.router.url + '/nueva-tarifa', this.idProyecto, this.peajeSeleccionado, this.eslectura, idEncabezado]);
  }

  irAEditarTarifa(idEncabezado: number) {
    this.eslectura = false;
    this.router.navigate([this.router.url + '/nueva-tarifa', this.idProyecto, this.peajeSeleccionado, this.eslectura, idEncabezado]);
  }

  irAListarConfiguraciones(idPeaje: number, nombrePeaje: string, existeBorrador: boolean, idEncahezadoEdita: number) {
    this.dataSourceConfiguracionesPeaje = [];
    this.configuracionVista = true;
    this.nombrePeaje = nombrePeaje;
    this.existeBorrador = existeBorrador;
    this.peajeSeleccionado = idPeaje;
    this.idEncabezadoEdita = idEncahezadoEdita;
    this.blockedDocument = true;

    forkJoin(
      this.tarifasPeajesService.cargarConfiguraciones(idPeaje)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          // recarga configuraciones
          if (result.configuracionesPeaje.length > 0) {
            // this.dataSourceConfiguracionesPeaje = new MatTableDataSource(result.configuracionesPeaje);
            this.dataSourceConfiguracionesPeaje = result.configuracionesPeaje;
          }

        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      }
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;

      }
    );




  }

}
