import { EtapaProyecto } from './etapa-proyecto';

export class ProyectoEtapa {
    constructor(
        public idProyectoEtapa: number,
        public idProyecto: number,
        public idEtapa: number,
        public idEtapaNavigation?: EtapaProyecto,
        public fechaPlaneado?: Date,
        public fechaReal?: Date) { }
}