import { Injectable } from '@angular/core';
import { Observable, throwError, ObservedValueOf } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Generacion } from './generacion';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProyectoCarretero } from './proyecto-carretero';
import { EtapaProyecto } from './etapa-proyecto';
import { environment } from 'src/environments/environment';
import { ProyectoEtapa } from './proyecto-etapa';
import { EnumTipoProyecto } from '../comun/enum-tipo-proyecto';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Injectable({
  providedIn: 'root'
})
export class InformacionGeneralService {

  constructor(private http: HttpClient, private adalSvc: MsAdalAngular6Service) {
    sessionStorage.setItem('authtoken', this.adalSvc.accessToken);
  }


  public getGeneraciones(): Observable<Generacion> {
    return this.http.get<Generacion>(environment.baseUrl + '/Generaciones', this.httpOptions)
      .pipe(
        retry(1));
  }

  public getOlas(): Observable<Generacion> {
    return this.http.get<Generacion>(environment.baseUrl + '/Olas')
      .pipe(
        retry(1));
  }

  public getProyectos(tipoProyecto: EnumTipoProyecto): Observable<ProyectoCarretero> {
    return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectos/' + tipoProyecto)
      .pipe(retry(1));
  }

  public getProyectoCarretero(id: number): Observable<ProyectoCarretero> {
    return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectos/2/' + id)
      .pipe(retry(1));
  }

  public getProyectoAeropuerto(id: number): Observable<ProyectoCarretero> {
    return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectos/1/' + id)
      .pipe(retry(1));
  }

  public getProyectoFerreo(id: number): Observable<ProyectoCarretero> {
    return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectos/4/' + id)
      .pipe(retry(1));
  }

  public getProyectoPortuario(id: number): Observable<ProyectoCarretero> {
    return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectos/5/' + id)
      .pipe(retry(1));
  }

  public getProyectoFluvial(id: number): Observable<ProyectoCarretero> {
    return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectos/6/' + id)
      .pipe(retry(1));
  }

  public getEtapasProyecto(): Observable<EtapaProyecto> {
    return this.http.get<EtapaProyecto>(environment.baseUrl + '/etapasproyecto')
      .pipe(retry(1));
  }

  public setProyectoCarretero(proyecto: ProyectoCarretero) {
    if (proyecto.idProyecto > 0) {
      return this.http.put<ProyectoCarretero>(environment.baseUrl + '/proyectoscarreteros/' + proyecto.idProyecto, JSON.stringify(proyecto), this.httpOptions)

    } else {
      return this.http.post<ProyectoCarretero>(environment.baseUrl + '/proyectoscarreteros', JSON.stringify(proyecto), this.httpOptions)
    }
  }

  public setProyectoEtapa(proyecto: ProyectoEtapa): any {
    if (proyecto.idProyectoEtapa > 0) {
      return this.http.put<ProyectoEtapa>(environment.baseUrl + '/ProyectoEtapas/' + proyecto.idProyectoEtapa, JSON.stringify(proyecto), this.httpOptions)

    } else {
      return this.http.post<ProyectoEtapa>(environment.baseUrl + '/ProyectoEtapas', JSON.stringify(proyecto), this.httpOptions)

    }
  }

  public deleteProyectoEtapa(idProyectoEtapa: number): any {
    return this.http.delete<ProyectoEtapa>(environment.baseUrl + '/ProyectoEtapas/' + idProyectoEtapa)
  }

  public getTramitesA() {
    return this.http.get(environment.baseUrl + '/juridico/ambientales/list/prueba' )
      .pipe(
        retry(1));
  }

  public getSelectsAero() {
    return this.http.get(environment.baseUrl + '/TrnProyecto/aero' )
      .pipe(
        retry(1));
  }

  public getProyectosAeroSelect(payload) {
    return this.http.get(environment.baseUrl + '/TrnProyecto/aero/' + payload )
      .pipe(
        retry(1));
  }

  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
}
