
import { Injectable } from '@angular/core';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs-compat';
import { TrnRespuestaServicio } from '../parametricas/trn-respuesta-servicio';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TramitesAmbientalesFerreoService {
    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    /**
     * Constructor de la clase
     * @param http
     * @param datepipe
     */
    constructor(
        private http: HttpClient,
        private datepipe: DatePipe) {

        this.END_POINT = environment.baseUrl;
    }


    /**
     * Metodo que trae las entidades
     * @returns
     */
    public getEntidad(): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbMaTramiteAmbiental/AmbMaEntidadAmbiental')
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae los estados del compromiso
     * @returns
     */
    public getEstadoCompromiso(): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbMaTramiteAmbiental/AmbMaEstadoCompromiso')
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae los tipos de tramites
     * @returns
     */
    public getTipoTramite(): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbMaTramiteAmbiental/AmbMaTipoTramite')
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae las priorizaciones
     * @returns
     */
    public getPiorizacion(): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbMaTramiteAmbiental/AmbMaPriorizacion')
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae los tramites relacionados
     * @returns
     */
    public getTramiteRelacionado(): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbMaTramiteAmbiental/AmbMaTramiteRelacionado')
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
    * Metodo que trae las etapas del proyecto
    *
    * @param idProyecto
    * @returns
    */
    public getEtapasProyecto(idProyecto: number): Observable<any> {
      return this.http.get(this.END_POINT + '/TrnMaEtapaProyecto/TrnMaEtapaProyecto/'+idProyecto)
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae las unidades funcionales
     *
     * @param idTramiteCompromiso
     * @returns
     */
    public getUnidadesFuncionales(idTramiteCompromiso): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbTramiteAmbiental/AmbCompromisoUf/'+idTramiteCompromiso)
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae los estados de un tramite
     *
     * @param idTramiteCompromiso
     * @returns
     */
    public getTramiteEstados(idTramiteCompromiso): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbTramiteAmbiental/AmbCompromisoEstado/'+idTramiteCompromiso)
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae las priorizaciones de un tramite
     *
     * @param idTramiteCompromiso
     * @returns
     */
    public getTramitePriorizaciones(idTramiteCompromiso): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbTramiteAmbiental/AmbCompromisoPriorizacion/'+idTramiteCompromiso)
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae las observaciones de un tramite
     *
     * @param idTramiteCompromiso
     * @returns
     */
    public getTramiteObservaciones(idTramiteCompromiso): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbTramiteAmbiental/AmbCompromisoObservaciones/'+idTramiteCompromiso)
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae los responsables de un tramite
     * @param idTramiteCompromiso
     * @returns
     */
    public getTramiteResponsables(idTramiteCompromiso): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbTramiteAmbiental/AmbCompromisoResponsable/'+idTramiteCompromiso)
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae el listado de los tramites
     * @param idProyecto
     * @returns
     */
    public getListadoTramites(idProyecto): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbTramiteAmbiental/TramitesAmbientales/'+idProyecto)
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae el listado de los tramites
     * @param idTramiteCompromiso
     * @returns
     */
    public getTramiteById(idTramiteCompromiso): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbTramiteAmbiental/TramiteAmbiental/'+idTramiteCompromiso)
          .pipe(map((data) => {
                return data;
              }),
              retry(1));
    }

    /**
     * Metodo que trae las unidades funcionales
     *
     * @param idProyecto
     * @returns
     */
    public getUnidades(idProyecto: number): Observable<any> {
      return this.http.get(this.END_POINT + '/tramiteAmbiental/unidadFuncional/' + idProyecto)
        .pipe(
          retry(1));
    }

    /**
     * Metodo que trae los tramos
     *
     * @param idProyecto
     * @returns
     */
    public getTramo(idProyecto: number): Observable<any> {
      return this.http.get(this.END_POINT + '/tramiteAmbiental/subtramo/' + idProyecto)
      .pipe(
        retry(1));
    }

    /**
     * Metodo que guarda o edita.
     *
     * @param objetoTramite
     * @returns
     */
    public postGuardarEditarTramite(objetoTramite: any): Observable<any> {
      return this.http.post(this.END_POINT + '/AmbTramiteAmbiental/GuardarAmbCompromisoPorProyecto', objetoTramite)
      .pipe(
        retry(1));
    }

     /**
     * Metodo que consulta los tramites ambientales historicos por proyecto
     * @returns  AmbMaTramiteRelacionadoRpt
     */
    public getListarTramitesAmbientalesPorProyectoHistorico(idProyecto: number): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbTramiteAmbiental/TramitesAmbientalesHistorico/'+ idProyecto)
      .pipe(
        retry(1));
    }

    /**
     * Metodo que consula
     * @param id
     * @returns
     */
    public getConsultarTramiteAmbientalPorIdHistorico( id: number ): Observable<any> {
      return this.http.get(this.END_POINT + '/AmbTramiteAmbiental/TramiteAmbientalHistorico/'+ id)
      .pipe(
        retry(1));
    }

    /**
   * Metodo que trae los estados del compromiso
   * @returns
   */
  public getEstadoCompromisoActivos(): Observable<any> {
    return this.http.get(this.END_POINT + '/AmbMaTramiteAmbiental/AmbMaEstadoCompromisoActivos')
      .pipe(map((data) => {
        return data;
      }),
        retry(1));
  }

  /**
     * Elimina un tramite
    */
  public srvEliminarTramite(idTramite: number): Observable<RespuestaGenericaRpt> {
    // console.log('idPredioHistorico', idPredioHistorico);
    // console.log('borrador', borrador);
    let url = this.END_POINT + "/AmbTramiteAmbiental/eliminarTramite/" + idTramite;
    return this.http.delete<RespuestaGenericaRpt>(url).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }


}

export class RespuestaGenericaRpt {
  constructor(public respuestaServicio?: TrnRespuestaServicio) { }
}
