/* eslint-disable no-debugger */
import { Component, OnInit } from '@angular/core';
import { CertificacionContratistaService, } from './certificacion-contratista.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { DatePipe } from "@angular/common";
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-certificacion-contratista',
  templateUrl: './certificacion-contratista.component.html',
  styleUrls: ['./certificacion-contratista.component.css']
})
export class CertificacionContratistaComponent implements OnInit {

  loading: boolean;
  respuestaRecaptcha: any = null;
  numeroIdentificacion: any = null;

  mostrarResultado: boolean = false;
  visibleDialogoError: boolean = false;
  mostrarCabecera: boolean = true;
  tipoDocumentos;
  tipoDocumentoSeleccionado;
  listadoRespuestas: any = [];
  listadoTemp: any = [];
  datosArchivoTemp: any = null;

  respuestas: any = { id: null, nContrato: null, vigencia: null, fechaSuscripcion: null, fechaInicio: null, fechaTerminacion : null};


  displayedRespuestas = [
    { field: 'nContrato', header: 'Número de contrato' },
    { field: 'vigencia', header: 'Vigencia' },
    { field: 'fechaSuscripcion', header: 'Fecha de suscripción' },
    { field: 'fechaInicio', header: 'Fecha de inicio' },
    { field: 'fechaTerminacion', header: 'Fecha de terminación' }
];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private _service: CertificacionContratistaService,
    private route: Router,
    public location: Location,
    public datepipe: DatePipe,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit() {
    this.loading = true;
    this.numeroIdentificacion = null;
    this.mostrarResultado = false;
    this.mostrarCabecera = true;
    this.cargarTipoDocumento();
  }

  /**
   * Metodo que carga el tipo de documento
   */
  cargarTipoDocumento(){
    this._service.getTipoDocumento().subscribe((data: {}) => {
      this.tipoDocumentos = data;
       this.loading = false;
    },error => {
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**
   * Metodo que limpia
   */
  limpiar(){
    this.loading = true;
    this.loading = false;
    this.respuestaRecaptcha = false;
    this.mostrarResultado = false;
    this.tipoDocumentoSeleccionado = null;
    this.numeroIdentificacion = null;
  }

  /**
   * Metodo de busqueda
   */
  onFind(){
    this.mostrarResultado = false;
    if(this.numeroIdentificacion!= undefined &&
      this.numeroIdentificacion!= null &&
      this.numeroIdentificacion!= ""  &&
      this.tipoDocumentoSeleccionado != null &&
      this.tipoDocumentoSeleccionado.id != null &&
      this.tipoDocumentoSeleccionado.id != "" &&
      this.respuestaRecaptcha){

        this._service.getlistaContratosPorContratista(this.tipoDocumentoSeleccionado.id,
          this.numeroIdentificacion).subscribe((data: {}) => {

          this.listadoTemp = data;
          this.listadoRespuestas = [];
          if(this.listadoTemp != undefined
               && this.listadoTemp != null){
                this.numeroIdentificacion = null;
                this.tipoDocumentoSeleccionado = null;
                this.mostrarResultado = true;
                this.mostrarCabecera = false;
                this.tipoDocumentoSeleccionado = null;
                this.respuestas = { id: null, nContrato: null, vigencia: null, fechaSuscripcion: null, fechaInicio: null, fechaTerminacion : null};

                this.listadoTemp.forEach(element => {
                  this.respuestas = { id: null, nContrato: null, vigencia: null, fechaSuscripcion: null, fechaInicio: null, fechaTerminacion : null};
                  this.respuestas.id = element.id;
                  this.respuestas.nContrato = element.numeroContrato;
                  this.respuestas.vigencia = this.datepipe.transform(element.fechaSuscripcion, 'yyyy');
                  this.respuestas.fechaSuscripcion = this.datepipe.transform(element.fechaSuscripcion, 'yyyy-MM-dd');
                  this.respuestas.fechaInicio = this.datepipe.transform(element.fechaInicioContratista, 'yyyy-MM-dd');
                  this.respuestas.fechaTerminacion = this.datepipe.transform(element.fechaTerminacionContrato, 'yyyy-MM-dd');
                  this.listadoRespuestas.push( this.respuestas);
                });

           }else{
               this.visibleDialogoError = true;
               this.numeroIdentificacion = null;
               this.mostrarResultado = false;
               this.mostrarCabecera = true;
           }
           this.loading = false;
       },error => {
           this.loading = false;
           this.numeroIdentificacion = null;
           this.mostrarResultado = false;
           this.mostrarCabecera = true;
           this.visibleDialogoError = true;
           this.principalComponent.cargarErrorServicio(error);
         });
      }else{
        console.log("prueba");
      }
  }

  /**
   * Metodo que valida los campos
   * @returns
   */
  validarCampo(){
    if(this.numeroIdentificacion!= undefined &&
      this.numeroIdentificacion!= null &&
      this.numeroIdentificacion!= ""  &&
      this.tipoDocumentoSeleccionado != null &&
      this.tipoDocumentoSeleccionado.id != null &&
      this.tipoDocumentoSeleccionado.id != "" &&
      this.respuestaRecaptcha){
        return false;
    }else{
        return true;
    }
  }

  /**
   * Metodo que captura la seleccion de captcha
   *
   * @param {string} captchaResponse
   * @memberof PqrsRadicacionComponent
   */
  public resolved(captchaResponse: string) {
    this.respuestaRecaptcha = captchaResponse;
  }

  /**
	 * Metodo que captura el evento del teclado solo numeros 0-9
	 * @param evento
	 */
	soloNumeros(evento){
		if(evento.code == "Backspace"
		|| evento.code == "Enter"
		|| evento.code == "Home"
		|| evento.code == "End"
		|| evento.code == "Slash"
		|| evento.code == "Dash"
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"
    || evento.ctrlKey == true){
            return true;
    }

    if(evento.ctrlKey == true && evento.keyCode == 67){
    return true;
    }
    if(evento.ctrlKey == true && evento.keyCode == 86){
    return true;
    }
    if (evento.keyCode >= 48 &&  evento.keyCode <= 57 ||
        evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
        return true;
    }else{
        return false;
    }
  }

  /**
   * metoo que realiza la validacion de la longitud
   */
  validarCampoNumeroIdentificacion(numeroIdentificacion :any){
    if(numeroIdentificacion!= undefined && numeroIdentificacion!= null && numeroIdentificacion!= ""){
        if(numeroIdentificacion.length < 6){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
  }

  /**
   * Metodo volver a la pantalla principal de la busqueda
   */
  volver(){
    this.loading = true;
    this.numeroIdentificacion = null;
    this.mostrarCabecera = true;
    this.mostrarResultado = false;
    this.visibleDialogoError = false;
    this.respuestas = { id: null, nContrato: null, vigencia: null, fechaSuscripcion: null, fechaInicio: null, fechaTerminacion : null};
    this.listadoRespuestas = [];
    this.loading = false;
    this.respuestaRecaptcha = false;
  }

  /**
   *
   * @param objeto
   */
  onRespuesta(objeto){
    this.loading = true;
    if(objeto.id!= null){
      this._service.getGeneracionPdfContratista(objeto.id).subscribe((data: {}) => {
        this.datosArchivoTemp = data;
        if(this.datosArchivoTemp != undefined && this.datosArchivoTemp != null){
            this.onDownload(this.datosArchivoTemp);
         }
         this.loading = false;
      },error => {
          this.loading = false;
          this.principalComponent.cargarErrorServicio(error);
      });
        //this.onDownload(objeto);
    }else{
        this.loading = false;
    }
  }

  /**
   * Metodo que descarga el archivo
   *
   * @param data
   */
   onDownload(data) {
    var a = document.createElement("a");
    var fileName = data.nombreArchivo;
    var base64str = data.archivo;
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    var blob = new Blob( [view], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    this.loading = false;
    a.click();
   }

}
