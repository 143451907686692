import { Component, OnInit, ViewChild } from '@angular/core';
import { UnidadFuncionalService } from './unidad-funcional.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { UnidadFuncional } from '../dtos/predial/unidad.funcional';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-unidad-funcional',
  templateUrl: './unidad-funcional.component.html',
  styleUrls: ['./unidad-funcional.component.sass']
})
export class UnidadFuncionalComponent implements OnInit {
  id: number;
  unidades: any = [];
  displayedColumns: string[] = ['nombre', 'longitud', 'ufPadre'];
  dataSource: MatTableDataSource<UnidadFuncional>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private unidadService: UnidadFuncionalService, private route: Router, private router: ActivatedRoute,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.id = params['id'];
      this.cargarUnidadesFuncionales()
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  cargarUnidadesFuncionales() {
    return this.unidadService.getUnidadesFuncionalesPorProyecto(this.id).subscribe((data: {}) => {
      this.unidades = data;
      this.dataSource = new MatTableDataSource(this.unidades);
      this.dataSource.paginator = this.paginator;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    })
  }

  onEdit(id: number) {
    this.route.navigate(['/uniFuncional', id, this.id]);
  }

  nuevoProyecto() {
    this.route.navigate(['/uniFuncional', 'n', this.id]);
  }
}
