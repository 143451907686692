import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeguimientoVerComunicacionService } from './seguimiento-ver-comunicaciones.service';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { Location } from '@angular/common';
import { SeguimientoDTO } from '../../../dtos/seguimiento-comunicaciones/seguimientoDTO';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
    selector: 'app-seguimiento-ver-comunicacion',
    templateUrl: './seguimiento-ver-comunicacion.component.html',
    styleUrls: ['./seguimiento-ver-comunicacion.component.sass']
})

export class SeguimientoVerComunicacionComponent implements OnInit {

    // Inicializaciones de Variables

    proyectos: SeguimientoDTO = {};
    id: number;
    nombreProyecto: string;
    estados: any = [];
    tiposRespuesta: any = [];
    responsables: any = [];
    fechaReporte: Date;
    descripcion: string;
    accionesAseguir: string;
    conceptoEmitido: string;
    linkRespuesta: string;
    numeroRadicado: string;
    visibleDialogoExito: boolean;
    estadosTemporal: any = {};
    respuestasTemporal: any = {};
    seguimientoEstados: any = [];
    seguimientoRespuestas: any = [];


    // Arrays de Columnas de Tablas

    displayedEstados = [
      { field: 'estado', header: 'Estado de la Comunicación' },
      { field: 'fechaStr', header: 'Fecha de Reporte' },
      { field: 'descripcion', header: 'Descripción del Estado' },
      { field: 'accionesAseguir', header: 'Accion a Seguir' }
    ];

    displayedRespuestas = [
      { field: 'fechaStr', header: 'Fecha de Respuesta' },
      { field: 'numeroRadicado', header: 'Radicado de Respuesta' },
      { field: 'conceptoEmitido', header: 'Respuesta Emitida' },
      { field: 'linkRespuesta', header: 'Link de la Respuesta' },
      { field: 'tipoRespuesta', header: 'Tipo de Respuesta' }

    ];


    dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent>;

    constructor(
      private service: SeguimientoVerComunicacionService,
      private route: Router,
      private ruta: ActivatedRoute,
      public dialog: MatDialog,
      public location: Location,
      private messageService: MessageService,
      private principalComponent: PrincipalComponent
      ) {
    }

    ngOnInit() {
        // this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
        this.ruta.params.subscribe(params => {
        this.id = params.id;
        this.cargarProyecto(this.id);
        this.cargarEstados();
        this.cargarTipoRespuesta();
      });
    }

      // Funciones del componente

    cargarProyecto(id) {
      this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true
      });
      this.service.obtenerListaSeguimiento(id).subscribe((data: {}) => {
        if (data) {
          this.proyectos = data;
          this.initData(this.proyectos);
        }
        this.dialogSpin.close();
      }, (error)=>{
        this.dialogSpin.close();
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    initData(payload) {
      this.proyectos = payload;
      this.seguimientoEstados = payload.estados;
      this.seguimientoRespuestas = payload.respuestas;

      if (payload.proyectos.length > 0) {
        this.nombreProyecto = payload.proyectos[0].proyecto;
      } else {
        this.nombreProyecto = '';
    }
    }

    cargarEstados() {
      return this.service.getEstadoSeguimiento().subscribe((data: {}) => {
        this.estados = data;
      }, (error)=>{
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    cargarTipoRespuesta() {
      return this.service.getTipoRespuesta().subscribe((data: {}) => {
        this.tiposRespuesta = data;
      }, (error)=>{
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    cancelar() {
      this.location.back();
      this.dialogSpin.close();
    }


    // Informativo - Dialogo

    aceptarDialogoExito() {
        this.visibleDialogoExito = false;
    }

    // /////////////////////////////Mensajes//////////////////////////////
    lanzarMensajeInfo(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
    }

    lanzarMensajeError(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    }

    lanzarMensajeWarning(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
    }

    limpiarMensajes() {
      this.messageService.clear();
    }
}
