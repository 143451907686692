import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { CtoContratoInformacionVigenteEncabezadoDTO } from '../../../contratacion-dto/contrato-informacion-vigente-encabezado';
import { TrnDependenciaAni } from 'src/app/parametricas/trn-dependencia-ani';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { DatePipe } from '@angular/common';
import { EnumCtoEstadoContrato } from 'src/app/contratacion/constantes/contratacion-constantes';
import { EnumRegex } from 'src/app/comun/constantes/constantes-comunes';
import { EnumRespuestaServicio } from '../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-reporte-contratos',
  templateUrl: './reporte-contratos.component.html',
  styles: []
})
export class ReporteContratosComponent implements OnInit {

  // Informacion del datatable
  reporteContratos: CtoContratoInformacionVigenteEncabezadoDTO[] = [];
  totalRecords: number;

  // Filtros
  buscarFiltro?: string;
  fechaCorteFiltro?: Date;
  dependenciaFiltro?: TrnDependenciaAni;
  blockedDocument = false;

  //Listas
  trnDependenciasAni: TrnDependenciaAni[];

  constructor(
    private datepipe: DatePipe,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private parametricasService: ParametricasService,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent
  ) { }


  ngOnInit() {
    this.cargarListadosFiltros();
  }

  loadDataLazy(event: LazyLoadEvent) {
    this.blockedDocument = true;
    this.reporteContratos = [];
    this.cargarContratos(event.first, event.rows, event.sortField, event.sortOrder);
  }

  buscar() {
    const filaDesde = 0;
    const cantidadRegistros = 30;
    this.blockedDocument = true;
    this.cargarContratos(filaDesde, cantidadRegistros, 'Id', 1);
  }

  cargarListadosFiltros() {
    this.parametricasService.getTrnDependenciaAni()
      .subscribe(data => {
        this.trnDependenciasAni = data;
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  autocompletarDependenciasAni(event) {
    const filtroTrnDependenciasAni = [];
    let existeFiltro: boolean;
    for (const trnDepAni of this.trnDependenciasAni) {
      if (String(trnDepAni.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroTrnDependenciasAni.push(trnDepAni);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.trnDependenciasAni = filtroTrnDependenciasAni;
    }
    return this.trnDependenciasAni;
  }

  cargarContratos(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.blockedDocument = true;
    this.contratacionService.srvConsultarContratosInformacionVigente(null, null, null, null, (this.dependenciaFiltro == null ? null : this.dependenciaFiltro.id), null, this.fechaCorteFiltro, filaDesde, cantidadRegistros, this.buscarFiltro, campoOrden, ascDesc)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.reporteContratos = result.contratosInformacionVigente;
              this.totalRecords = this.reporteContratos.length == 0 ? 0 : this.reporteContratos[0].totalRegistros;

              this.contratacionService.srvCalcularEstadoVigenciaContrato(this.reporteContratos);

            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  limpiarFiltros() {
    this.fechaCorteFiltro = null;
    this.dependenciaFiltro = null;
    this.buscarFiltro = null;
    this.cargarListadosFiltros();
    this.buscar();
  }

  irVerDetalleContrato(contrato: CtoContratoInformacionVigenteEncabezadoDTO) {
    this.router.navigate([this.router.url + '/reporteContratoDetalle', contrato.id]);
  }

  get EnumCtoEstadoContrato() {
    return EnumCtoEstadoContrato;
  }

  get EnumRegex() {
    return EnumRegex;
  }

}
