import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoContratoDTO } from '../../../contratacion-dto/contrato';
import { ContratacionBorradorService } from '../contratacion-brr.service';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.sass']
})
export class ContratosComponent implements OnInit {

  contratos: CtoContratoDTO[] = [];
  contratoSeleccionado: CtoContratoDTO;

  // Informacion para los dialogos

  visibleDialogoMensajeAdvertencia = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;
  visualizarDialogoContratista = false;
  visualizarFiduciasPorContrato = false;
  visualizarDialogoFiducias = false;
  blockedDocument = false;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private contratacionBorradorService: ContratacionBorradorService,
    private principalComponent: PrincipalComponent
  ) { }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.blockedDocument = true;
    this.cargarContratos();
  }

  /**
   * Metodo que consulta el listado de contratos en borrador creados
   */
  cargarContratos() {
    this.contratacionBorradorService.srvListarContratosBorrador()
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.contratos = result.contratosLista;
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        });
  }

  /**
   * Metodo que redirige al formulario de creacion de contrato, la variable idContrato es 0 para determinar un nuevo contrato, 
   */
  agregarNuevoContratoBorrador() {
    this.router.navigate([this.router.url + '/contrato-base/', 0, 1]);
  }

  /**
   * Metodo que redirige al formulario de edición de contrato
   * @param contrato Objeto de tipo CtoContratoDTO creado previamente,, la variable idContrato no puede ser 0
   */
  irEditarContrato(contrato: CtoContratoDTO) {
    this.router.navigate([this.router.url + '/contrato-base/', contrato.id, 1]);
  }


  /**
   * Metodo que gestiona el formulario de eliminación de contrato borrador
   * @param contrato Objeto de tipo CtoContratoDTO para realizar la eliminacion
   */
  irEliminarContrato(contrato: CtoContratoDTO) {
    this.contratoSeleccionado = contrato;
    this.visibleDialogoConfirmarEliminarRegistro = true;
  }

  /**
   * Metodo que llamar el servicio de eliminación de un contrato en borrador seleccionado por su id
   * @returns El resultado de la acción realizada
   */
  aceptarEliminarContrato() {
    this.visibleDialogoConfirmarEliminarRegistro = false;
    this.blockedDocument = true;
    return this.contratacionBorradorService.EliminarContratoBorrador(this.contratoSeleccionado.id)
      .subscribe(
        () => {
          this.visibleDialogoExitoEliminarRegistro = true;
        },
        error => {
          console.error(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  /**
   * Metodo que controla el evento de cierre del dialogo e inicializa las variables del formulario de eliminacion de contrato
   */
  cancelarEliminarContrato() {
    this.visibleDialogoConfirmarEliminarRegistro = false;
  }

  /**
   * Metodo que controla el evento de cierre del dialogo de confirmación exitosa al elmiminar un contrato
   */
  aceptarDialogoEliminarContrato() {
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleDialogoConfirmarEliminarRegistro = false;
    this.ngOnInit();
  }

  /**
   *  Metodo que redirige al menu del home del modulo de contratación
   */
  irContratosHome() {
    this.router.navigate(['/contratosHome']);
  }


  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

}
