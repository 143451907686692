import { Component, OnInit, ViewChild } from '@angular/core';
import { InformacionGeneralFinancieraService } from './informacion-general.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { InformacionGeneralFinanciera } from './informacionGeneralFinanciera';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { DatosGeneralesFinanciero } from '../informacion-general/datos-generales-financiero';
import { DatosGeneralesFinancieroIndexado } from '../informacion-general/datos-generales-financiero-indexado';
import { NgForm } from '@angular/forms';
import { InformacionGeneralBaseComponent } from '../informacion-general-base/informacion-general-base.component';
import { ConcesionarioCarreteroService } from 'src/app/concesionario-carretero/concesionario-carretero.service';
import { Contrato } from 'src/app/concesionario-carretero/contrato';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-informacion-general-financiera',
  templateUrl: './informacion-general.component.html',
  styleUrls: ['./informacion-general.component.sass']
})
export class InformacionGeneralFinancieroComponent implements OnInit {

  datosGeneralesFinanciero: DatosGeneralesFinanciero = {};


  proyecto: any;

  visibleDialogoExito: boolean = false;

  idContratoConcesionario: any;

  contrato: Contrato = {};

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private lineaBase: InformacionGeneralBaseComponent, private infoService: InformacionGeneralService,
    private concesionarioService: ConcesionarioCarreteroService, private infoGeneralFinanciera: InformacionGeneralFinancieraService, private route: Router, private ruta: ActivatedRoute,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.lineaBase.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.lineaBase.idProyecto);
      this.cargarInformacionGeneralFinanciera(this.lineaBase.idProyecto);
      this.cargarConcesionario(this.lineaBase.idProyecto);
    });
  }

  cargarInformacionGeneralFinanciera(id: number) {
    return this.infoGeneralFinanciera.getInformacionGeneralFinanciera(id).subscribe((data: {}) => {
      if (data != null) {
        this.datosGeneralesFinanciero = data;
        this.datosGeneralesFinanciero.tdi = this.datosGeneralesFinanciero.tdi * 100;
      }else{
        this.datosGeneralesFinanciero = {};
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
      this.lineaBase.nombreProyecto = this.proyecto.nombre;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarConcesionario(id) {
    this.concesionarioService.getContratoConcesionarioPorIdProyecto(id, 1).subscribe((data: {}) => {
      this.contrato = data;
      if (this.contrato != null) {
        this.idContratoConcesionario = this.contrato.id;
      }
    });
  }


  guardarInformacionGeneral(forma: NgForm) {
   this.datosGeneralesFinanciero.idContrato = this.idContratoConcesionario;
    this.infoGeneralFinanciera.guardarInformacionGeneralFinanciera(this.datosGeneralesFinanciero, this.lineaBase.idProyecto).subscribe((data: {}) => {
      this.visibleDialogoExito = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoExitoInformacionGeneralFinanciera() {
    this.visibleDialogoExito = false;
  }


}
