import { Component, OnInit, Input } from '@angular/core';
import { PrsDatosAdicionalesPersona } from '../../prs-datos-adicionales-persona';
import { TrnPersona } from '../../trn-persona';
import { EnumRegex } from 'src/app/comun/constantes/constantes-comunes';
import { Router } from '@angular/router';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { NgForm } from '@angular/forms';
import { TrnSexo } from '../../../parametricas/trn-sexo';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-persona-datos-adicionales',
  templateUrl: './persona-datos-adicionales.component.html',
  styles: []
})
export class PersonaDatosAdicionalesComponent implements OnInit {

  @Input()
  personaDatos: TrnPersona = {};

  personaDatosAdicionales: PrsDatosAdicionalesPersona = {};
  personaTipoDocumento: any;
  trnSexoList: TrnSexo[];
  personaDatosAdicionalesSexo: TrnSexo = {};
  departamentos: any[];
  personaDatosAdicionalesDepartamento: any = {};
  ciudades: any[];
  personaDatosAdicionalesMunicipio: any = {};
  blockedDocument = false;

  resultadoGuardarDatosAdicionales: any;
  visibleDialogoExito: boolean;

  crearDireccion: boolean = false;

  constructor(
    private router: Router,
    private parametricasService: ParametricasService,
    private personaService: PersonaService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.blockedDocument = true;

    if (this.personaDatos != null) {
      this.cargarPrsDatosAdicionales();
    }
  }

  cargarListasParametricas() {
    this.parametricasService.getTrnSexo().subscribe(
      result => {
        this.trnSexoList = result;
      },
      error => {
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  cargarPrsDatosAdicionales() {
    this.personaService.ConsultarPersonaDatosAdicionales(this.personaDatos.id).subscribe(
      result => {
        if (result != null) {
          this.personaDatosAdicionales = result;
          if (this.personaDatosAdicionales.idSexo != null && this.personaDatosAdicionales.sexo != null) {
            this.personaDatosAdicionalesSexo = {
              id: this.personaDatosAdicionales.idSexo,
              nombre: this.personaDatosAdicionales.sexo
            };
          }

          if (this.personaDatosAdicionales.idDepartamentoMunicipioDomicilio != null && this.personaDatosAdicionales.departamentoMunicipioDomicilio != null) {
            this.personaDatosAdicionalesDepartamento = {
              id: this.personaDatosAdicionales.idDepartamentoMunicipioDomicilio,
              nombre: this.personaDatosAdicionales.departamentoMunicipioDomicilio
            };
          }

          if (this.personaDatosAdicionales.idMunicipioDomicilio != null && this.personaDatosAdicionales.municipioDomicilio != null) {
            this.personaDatosAdicionalesMunicipio = {
              id: this.personaDatosAdicionales.idMunicipioDomicilio,
              nombre: this.personaDatosAdicionales.municipioDomicilio
            };
          }

          if (this.personaDatosAdicionales.fechaNacimiento != null) {
            this.personaDatosAdicionales.fechaNacimiento = new Date(this.personaDatosAdicionales.fechaNacimiento);
          }
        } else {
          this.personaDatosAdicionales.idPersona = this.personaDatos.id;
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  guardarPersonaDatosAdicionales(form: NgForm) {
    this.blockedDocument = true;
    if (this.personaDatosAdicionales.idPersona == null || this.personaDatosAdicionales.idPersona === 0) {
      this.personaDatosAdicionales.idPersona = this.personaDatos.id;
    }
    this.personaDatosAdicionales.idSexo = this.personaDatosAdicionalesSexo.id;
    this.personaDatosAdicionales.idMunicipioDomicilio = this.personaDatosAdicionalesMunicipio.id;

    this.personaService.GuardarPersonaDatosAdicionales(this.personaDatosAdicionales).subscribe(
      result => {
        this.visibleDialogoExito = true;
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  // Informativo - Dialogo
  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.router.navigate([this.router.url]);
  }

  cargarDepartamentos(event) {
    const filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe(
      (data: any) => {
        this.departamentos = data;
        if (this.departamentos != null) {
          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.departamentos.length; i++) {
              const filtro = this.departamentos[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroDepartamentos.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.departamentos = filtroDepartamentos;
            }
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }


  limpiarCiudades(event) {
    this.personaDatosAdicionalesMunicipio = null;
  }

  cargarCiudades(event) {
    if (this.personaDatosAdicionalesDepartamento != null) {
      const filtroCiudades = [];
      return this.parametricasService.getCiudadesPorDepartamento(this.personaDatosAdicionalesDepartamento.id).subscribe((data: any) => {
        this.ciudades = data;
        if (this.ciudades != null) {
          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.ciudades.length; i++) {
              const filtro = this.ciudades[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroCiudades.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.ciudades = filtroCiudades;
            }
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
    }
  }

  cargarSexo(event) {
    const filtroSexo = [];
    this.parametricasService.getTrnSexo().subscribe(
      result => {
        this.trnSexoList = result;
        if (this.trnSexoList != null) {
          let existeFiltro: boolean;
          for (let i = 0; i < this.trnSexoList.length; i++) {
            const filtro = this.trnSexoList[i];
            if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroSexo.push(filtro);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.trnSexoList = filtroSexo;
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }

  public agregarDireccion(direccion: string) {
    this.personaDatosAdicionales.direccionDomicilio = direccion;
    this.crearDireccion = false;
  }

  get EnumRegex() {
    return EnumRegex;
  }
}
