import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  InvMaTipoBien,
  InvModeloDTO,
  InvMaMarca,
  InvMaSistemaOperativo,
  InvBienDTO,
  InvAsignacionDTO,
  InvMaTipoAsignacion,
  VwInvAsignacionesDTO,
} from '../inventario-equipos.dto';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { InventarioEquiposService } from 'src/app/inventario-equipos/inventario-equipos.service';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { TrnPersona } from 'src/app/admin-personas/trn-persona';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-registrar-bien',
  templateUrl: './registrar-bien.component.html',
  styleUrls: ['./registrar-bien.component.sass'],
})
export class RegistrarBienComponent implements OnInit {
  blockedDocument: boolean;

  form;
  formAsignar;
  formDesasignar;

  modelo: InvModeloDTO = {};
  modeloList: InvModeloDTO[];

  personaVigenteLista: any = [];
  personaVigenteListaBackup: any = [];
  filtroPersonaVigenteLista: any[];


  bien: InvBienDTO = {};
  bienList: InvBienDTO[];

  asignacion: InvAsignacionDTO = {};
  asignacionList: VwInvAsignacionesDTO[];

  dialogoGuardarBien: boolean;
  dialogoAsignarBien: boolean;
  dialogoDesasignarBien: boolean;
  dialogoAnularAsignacionBien: boolean;

  tipoAsignacionList: InvMaTipoAsignacion[];

  renderEsPcOPortatil = false;
  superoCantidadModelo = false;

  displayModal: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private inventarioEquiposService: InventarioEquiposService,
    private personaService: PersonaService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
    const regexMayorACero = '^[1-9][0-9]*$';
    //  Crear formulario Guardar Bien
    this.form = formBuilder.group({
      idModelo: [null, [Validators.required]],
      placa: [
        null,
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(regexMayorACero),
        ],
      ],
    });
    //  Crear formulario Asignar Bien
    this.formAsignar = formBuilder.group({
      nombreEquipo: [null, [Validators.maxLength(20)]],
      idTipoAsignacion: [null, [Validators.required]],
      idPersona: [null, [Validators.required]],
      fechaInicio: [null, [Validators.required]],
    });
    //  Crear formulario Desasignar Bien
    this.formDesasignar = formBuilder.group({
      idPersona: [null, [Validators.required]],
      fechaInicio: [null, [Validators.required]],
      fechaFin: [null, [Validators.required]],
    });
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    //  Inicializar listas parametricas
    forkJoin(
      this.inventarioEquiposService.srvListarModelo(),
      this.personaService.getPersonasVigentes(),
      this.inventarioEquiposService.srvListarTipoAsignacion()
    ).subscribe(
      ([modeloListData, personaListData, tipoAsignacionListData]) => {
        this.modeloList = modeloListData.modelos;
        this.personaVigenteLista = personaListData.personasVigentes;
        this.personaVigenteListaBackup = personaListData.personasVigentes;
        this.tipoAsignacionList = tipoAsignacionListData.tiposAsignacion;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    //  Listar los bienes guardados
    this.listarBienes();
    this.listarAsignaciones();
  }

  /**
   * Muestra el dialogo para agregar bien
   */
  agregarBien() {
    this.bien = {};
    this.dialogoGuardarBien = true;
  }

  /**
   * Inicializa la lista de bienes
   */
  listarBienes() {
    forkJoin(this.inventarioEquiposService.srvListarBien()).subscribe(
      ([modeloListData]) => {
        this.bienList = modeloListData.bienes;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**
   * Inicializa la lista de bienes
   */
  listarAsignaciones() {
    forkJoin(this.inventarioEquiposService.srvListarAsignaciones()).subscribe(
      ([asignacionListData]) => {
        this.asignacionList = asignacionListData.asignaciones;
        // console.log(this.asignacionList);
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**
   * Abre el dialogo para editar un bien
   */
  editarBien(bienL: InvBienDTO) {
    this.bien = bienL;
    //  Cargar el objeto del dropdown Modelo
    this.bien.idModeloDTO = this.modeloList.find(
      (t) => t.id === this.bien.idModelo
    );
    //  Mostrar el dialogo
    this.dialogoGuardarBien = true;
  }

  /**
   * Abre el dialogo para eliminar un bien
   */
  eliminarBien(bienL: InvBienDTO) {
    this.bien = bienL;
    this.displayModal = true;
  }

  /**
   * Abre el dialogo para asignar un bien a una persona
   */
  asignarBien(asignacionL: VwInvAsignacionesDTO) {
    //  Crear formulario Asignar Bien
    this.formAsignar.reset(this.formAsignar.value);

    this.asignacion = asignacionL;
    this.asignacion.id = asignacionL.idAsignacion;
    this.asignacion.fechaInicio = new Date();
    //  console.log(this.bien);

    this.cargarDatosAsignacion();

    this.filtroPersonaVigenteLista = [];

    //  Mostrar el dialogo
    this.dialogoAsignarBien = true;
  }

  /**
   * Abre el dialogo para desasignar un bien de una persona
   */
  desasignarBien(asignacionL: VwInvAsignacionesDTO) {
    this.asignacion = asignacionL;
    this.asignacion.id = asignacionL.idAsignacion;
    this.cargarDatosAsignacion();

    this.filtroPersonaVigenteLista = [];
    //  Mostrar el dialogo
    this.dialogoDesasignarBien = true;
  }

  /**
  * Abre el dialogo para confirmar la anulacion de la asignacion de un bien
  */
  anularAsignacionBien(asignacionL: VwInvAsignacionesDTO) {
    this.asignacion = asignacionL;
    console.log(this.asignacion);
    this.asignacion.fechaInicio = new Date(asignacionL.fechaInicio);
    this.asignacion.fechaFin = new Date(asignacionL.fechaFin);

    //this.asignacion.id = asignacionL.idAsignacion;
    //this.asignacion.fechaInicio = new Date(asignacionL.fechaInicio);

    //  Mostrar el dialogo
    this.dialogoAnularAsignacionBien = true;
  }

  /**
   * Precarga los datos del objeto asignacion
   */
  cargarDatosAsignacion() {

    //  Cargar el objeto del dropdown Bien
    this.asignacion.idBienDTO = this.bienList.find(
      (t) => t.id === this.asignacion.idBien
    );

    // console.log('this.asignacion', this.asignacion);
    // console.log('this.asignacion.idBienDTO', this.asignacion.idBienDTO);
    // Simular que elegi un item del dropdown TipoBien
    //this.formDesasignar.get('idBien').setValue(this.asignacion.idBienDTO);


    if (this.asignacion.fechaInicio != null) {
      this.asignacion.fechaInicio = new Date(this.asignacion.fechaInicio);
    } else {
      this.asignacion.fechaInicio = new Date();
    }

    if (this.asignacion.fechaFin != null) {
      this.asignacion.fechaFin = new Date(this.asignacion.fechaFin);
      if (this.asignacion.fechaFin.getFullYear() === 9999) {
        this.asignacion.fechaFin = null;
      }
    }

    //  Cargar el objeto del dropdown Tipo Asignacion
    this.asignacion.idTipoAsignacionDTO = this.tipoAsignacionList.find(
      (t) => t.id === this.asignacion.idTipoAsignacion
    );

    //  Cargar el objeto del dropdown Persona
    if (this.asignacion.idPersonaRecibe != null) {
      this.asignacion.idPersonaDTO = this.personaVigenteListaBackup.find(
        (t) => t.idPersona === this.asignacion.idPersonaRecibe
      );
    } else {
      this.asignacion.idPersonaDTO = {};
      this.filtroPersonaVigenteLista = [];
      //  this.personaVigenteLista = this.personaVigenteListaBackup;
    }

    const nombreEquipoControl = this.formAsignar.get('nombreEquipo');

    if (
      this.asignacion.idBienDTO.idTipoBien === 1 ||
      this.asignacion.idBienDTO.idTipoBien === 2
    ) {
      //  console.log('SI ES PC O PORTATIL');
      this.renderEsPcOPortatil = true;
      nombreEquipoControl.setValidators([
        Validators.required,
        Validators.maxLength(10),
      ]);
    } else {
      //  console.log('NO ES PC O PORTATIL');
      this.renderEsPcOPortatil = false;
      nombreEquipoControl.setValidators(null);
      this.asignacion.nombreEquipo = null;
    }

    nombreEquipoControl.updateValueAndValidity();

    //console.log('this.asignacion final', this.asignacion);
  }

  /**
   *  Anula la asignacion del bien
   */
  aceptarAnularAsignacionBien() {
    if (this.asignacion != null && this.asignacion.idBien != null) {
      forkJoin(
        this.inventarioEquiposService.srvAnularAsignacionBien(
          this.asignacion.idBien
        )
      ).subscribe(
        ([result]) => {
          //  console.log(result);
          if (result != null && result.respuestaServicio != null) {
            //  Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              //  Consultar nuevamente la lista
              this.listarAsignaciones();
              //  Lanzar mensaje de exito
              this.messageService.add({
                severity: 'info',
                summary: 'Información',
                detail: result.respuestaServicio.mensajeSalida,
                life: 10000,
              });
              // Cerrar el dialogo
              this.dialogoAnularAsignacionBien = false;
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'No fue posible realizar la acción. Por favor comuniquese con el area de tecnología',
                life: 10000,
              });
            }
          } else {
            //  console.log('Ocurrio un error al consumir el servicio srvAnularAsignacionBien');
          }
        }, error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          //  Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }
  }

  /**
   * Guarda los cambios al crear o editar un bien
   */
  submitGuardarBien() {
    if (this.superoCantidadModelo) {
      this.lanzarMensajeWarning(
        'Ya se supero la cantidad de bienes por modelo'
      );
    } else {
      //  console.log(this.form.value);
      if (this.form.valid) {
        //  Completar el DTO a guardar
        if (this.bien.idModeloDTO != null) {
          this.bien.idModelo = this.bien.idModeloDTO.id;
        }
        //  Imprimir el DTO a guardar
        //  console.log(this.bien);
        //  Guardar el DTO
        forkJoin(
          this.inventarioEquiposService.srvGuardarBien(this.bien)
        ).subscribe(
          ([result]) => {
            //  console.log(result);
            if (result != null && result.respuestaServicio != null) {
              //  Si el resultado es exitoso...
              if (result.respuestaServicio.codigoSalida === 1) {
                //  console.log('----------srvGuardarBien');
                //  console.log(result.respuestaServicio.mensajeSalida);
                //  Consultar nuevamente la lista
                this.listarAsignaciones();
                //  Lanzar mensaje de exito
                this.messageService.add({
                  severity: 'info',
                  summary: 'Información',
                  detail: result.respuestaServicio.mensajeSalida,
                  life: 10000,
                });
                //  Limpiar Formulario
                this.form.reset();
                this.form.markAsPristine();
                this.form.markAsUntouched();

                //  Cerrar el dialogo
                this.dialogoGuardarBien = false;
              } else {
                // console.log(result.respuestaServicio.mensajeSalida);
                this.lanzarMensajeWarning(
                  result.respuestaServicio.mensajeSalida
                );
              }
            } else {
              // console.log('Ocurrio un error al consumir el servicio srvGuardarBien');
            }
          }, error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            // Desbloquear pantalla
            this.blockedDocument = false;
          }
        );
      } else {
        this.lanzarMensajeWarning('Debe diligenciar los campos requeridos');
      }
    }
  }

  /**
   *  Guarda la asignacion de un bien a una persona
   */
  submitAsignarBien() {
    // console.log(this.formAsignar.value);
    // console.log(this.formAsignar.valid);
    // console.log(this.formAsignar);
    if (this.formAsignar.valid) {

      this.blockedDocument = true;

      this.asignacion.idPersonaRecibe = this.asignacion.idPersonaDTO.idPersona;
      // Completar el DTO a guardar
      if (this.asignacion.idBienDTO != null) {
        this.asignacion.idBien = this.asignacion.idBienDTO.id;
      }
      if (this.asignacion.idTipoAsignacionDTO != null) {
        this.asignacion.idTipoAsignacion = this.asignacion.idTipoAsignacionDTO.id;
      }
      this.asignacion.fechaFin = new Date();

      // console.log(this.asignacion);
      // console.log(this.asignacion.idPersonaDTO);
      this.guardarAsignacionBien();

      // Limpiar Formulario
      this.formAsignar.reset();
      this.formAsignar.markAsPristine();
      this.formAsignar.markAsUntouched();

      // Cerrar el dialogo
      this.dialogoAsignarBien = false;

    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos');
    }
  }

  /**
   * Guarda la desasignacion de un bien de una persona
   */
  submitDesasignarBien() {
    const idPersonaControl = this.formDesasignar.get('idPersona');
    idPersonaControl.setValue(this.asignacion.idPersonaRecibe);

    // console.log(this.formDesasignar.value);
    if (this.formDesasignar.valid) {
      //this.asignacion.idPersonaRecibe = this.asignacion.idPersonaRecibe;
      // Completar el DTO a guardar
      if (this.asignacion.idBienDTO != null) {
        this.asignacion.idBien = this.asignacion.idBienDTO.id;
      }
      if (this.asignacion.idTipoAsignacionDTO != null) {
        this.asignacion.idTipoAsignacion = this.asignacion.idTipoAsignacionDTO.id;
      }

      // console.log(this.asignacion);

      this.guardarRegistroAsignacion();

      // Limpiar Formulario
      this.formDesasignar.reset();
      this.formDesasignar.markAsPristine();
      this.formDesasignar.markAsUntouched();

      // Cerrar el dialogo
      this.dialogoDesasignarBien = false;
    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos');
    }
  }

  /**
   * Guarda la asignacion de un bien a una persona
   */
  guardarAsignacionBien() {
    //console.log(this.asignacion.idPersonaDTO);
    console.log('this.asignacion ANTES DE GUARDAR', this.asignacion);
    this.blockedDocument = true;

    // Guardar el DTO
    forkJoin(
      this.inventarioEquiposService.srvGuardarAsignacionBien(this.asignacion)
    ).subscribe(
      ([result]) => {
        console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            console.log('----------srvGuardarAsignacionBien');
            console.log(result.respuestaServicio.mensajeSalida);
            // Consultar nuevamente la lista
            this.listarAsignaciones();
            // Lanzar mensaje de exito
            this.messageService.add({
              severity: 'info',
              summary: 'Información',
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          } else {
            console.log(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.log('Ocurrio un error al consumir el servicio srvGuardarAsignacionBien');
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );

  }

  /**
   * Edita el registro de asignacion
   */
  guardarRegistroAsignacion() {
    // Guardar el DTO
    forkJoin(
      this.inventarioEquiposService.srvGuardarRegistroAsignacion(this.asignacion)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // console.log('----------srvGuardarAsignacionBien');
            // console.log(result.respuestaServicio.mensajeSalida);
            // Consultar nuevamente la lista
            this.listarAsignaciones();
            // Lanzar mensaje de exito
            this.messageService.add({
              severity: 'info',
              summary: 'Información',
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          } else {
            // console.log(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // console.log('Ocurrio un error al consumir el servicio srvGuardarAsignacionBien');
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  asociarPersona(persona) {
    // console.log('persona' + JSON.stringify(persona));
  }

  /**
   * Se llama cada vez que se elige un registro del selector 'Modelo'
   */
  onChangeModelo(event) {
    forkJoin(
      this.inventarioEquiposService.srvObtenerBienPorModelo(event.value.id)
    ).subscribe(
      ([bienPorModeloListData]) => {
        const cantidadModelo = event.value.cantidad;
        const cantidadBienesPorModelo = bienPorModeloListData.bienes.length;
        if (cantidadBienesPorModelo < cantidadModelo) {
          this.superoCantidadModelo = false;
        } else {
          this.superoCantidadModelo = true;
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  cancelarEliminarBien() {
    this.displayModal = false;
  }

  /**
   * Elimina el bien seleccionado
   */
  aceptarEliminarBien() {
    // Guardar el DTO
    forkJoin(
      this.inventarioEquiposService.srvEliminarBien(this.bien)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // console.log('----------srvEliminarBien');
            // console.log(result.respuestaServicio.mensajeSalida);
            // Consultar nuevamente la lista
            this.listarBienes();
            // Lanzar mensaje de exito
            this.messageService.add({
              severity: 'info',
              summary: 'Información',
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
            // Limpiar Formulario
            this.form.reset();
            this.form.markAsPristine();
            this.form.markAsUntouched();
            // Cerrar el dialogo
            this.displayModal = false;
          } else {
            // console.log(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // console.log('Ocurrio un error al consumir el servicio srvEliminarBien');
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Carga el listado de personas vigentes a las cuales se les puede asignar un bien
   */
  cargarPersonasVigentes(event) {
    this.filtroPersonaVigenteLista = [];
    this.personaVigenteLista = this.personaVigenteListaBackup;
    // console.log('entro aca a cargarPersonasVigentes');

    // console.log(this.asignacion.idPersonaDTO);
    // console.log('Query', event.query);
    let existeFiltro: boolean;
    for (let i = 0; i < this.personaVigenteLista.length; i++) {
      const filtro = this.personaVigenteLista[i];
      const item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        // console.log(filtro.nombre);
        this.filtroPersonaVigenteLista.push(filtro);
        existeFiltro = true;
      }
    }

    // console.log(existeFiltro);

    if (existeFiltro) {
      this.personaVigenteLista = this.filtroPersonaVigenteLista;
    } else {
      // this.personaVigenteLista = this.personaVigenteListaBackup;
    }
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
