import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { PrdMaTipoDocumentoDTO, PredioDTO, PrdDocumentoDTO } from '../../predios.dto';
import { PrediosService } from '../../predios.service';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { UnidadFuncionalCarreteroService } from 'src/app/informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero/unidad-funcional-carretero.service';
import { MessageService } from 'primeng/api';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { AdjuntarAlmacenamimentoComponent } from 'src/app/comun/adjuntar-almacenamimento/adjuntar-almacenamimento.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-adjuntos-predio',
  templateUrl: './adjuntos-predio.component.html',
  styleUrls: ['./adjuntos-predio.component.sass']
})
export class AdjuntosPredioComponent implements OnInit {

  blockedDocument: boolean;
  subscription: Subscription;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  prdMaTipoDocumentoDTO: PrdMaTipoDocumentoDTO;
  prdMaTipoDocumentoDTOList: PrdMaTipoDocumentoDTO[];

  idPredioHistorico: number;
  idPredio: number;
  predio: PredioDTO;

  borrador: boolean;

  unidadesFuncionales: any = [];
  subtramos: any[];


  prdDocumento: PrdDocumentoDTO;
  prdDocumentoList: PrdDocumentoDTO[];

  dialogoCargarDocumento: boolean;
  dialogoEliminarDocumento: boolean;
  dialogoAprobarDesaprobarDocumento: boolean;

  formCargarDocumento;
  formAprobarDesaprobarDocumento;

  archivos: any = []; //Los archivos que yo suba van a quedar en este arreglo
  visibleDialogoVerEvidencia: Boolean;

  @ViewChild(AdjuntarAlmacenamimentoComponent, { static: false }) adjuntarAlmacenamimentoComponent: AdjuntarAlmacenamimentoComponent;


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private prediosService: PrediosService,
    private unidadFuncionalCarreteroService: UnidadFuncionalCarreteroService,
    private messageService: MessageService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent
  ) {
    // Crear formulario CargarDocumento
    this.formCargarDocumento = formBuilder.group({
      tipoDocumento: [null, [Validators.required]],
      fechaDocumento: [null],
      observaciones: [null, [Validators.required]]
    });

    // Crear formulario AprobarDesaprobar Documento
    this.formAprobarDesaprobarDocumento = formBuilder.group({
      observaciones: [null, [Validators.required]]
    });
  }

  /**
  * Inicializa el componente
  */
  ngOnInit() {
    this.predio = {};
    this.predio.propietarios = [];
    this.prdDocumento = {};
    this.prdDocumento.idProyecto = this.idProyecto;
    this.prdMaTipoDocumentoDTO = {};

    // Obtener el proyecto seleccionado
    const idParam = 'id';
    const idPredioHistoricoParam = 'idPredioHistorico';
    const idPredioParam = 'idPredio';
    const idBorradorParam = 'borrador';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.idPredioHistorico = params[idPredioHistoricoParam];
      this.idPredio = params[idPredioParam];
      this.borrador = params[idBorradorParam];
    });

    this.blockedDocument = true;

    this.cargarListadoDocumentosPorPredio(this.idPredio);

    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.prediosService.srvObtenerPredioPorIdYBorrador(this.idPredioHistorico, this.borrador),
      this.prediosService.srvListarTipoDocumento(),
      this.unidadFuncionalCarreteroService.getUnidadesFuncionalesPorSoloProyecto(this.idProyecto),
    ).subscribe(
      ([proyectoData, predioData, tipoDocumentoData, unidadFuncionalData]) => {

        // console.log('predioData', predioData);
        this.proyectoSeleccionado = proyectoData;

        //Obtener Predio
        this.predio = predioData.predio;
        if (this.predio.propietarios == null) {
          this.predio.propietarios = [];
        }

        this.unidadesFuncionales = unidadFuncionalData.unidadesFuncionales;
        // console.log(this.unidadesFuncionales);


        // Cargar el objeto del dropdown UnidadFuncional
        this.predio.unidadFuncional = this.unidadesFuncionales.find(
          (t) => t.idUnidadFuncional === this.predio.idUnidadFuncional
        );

        // console.log(this.predio.unidadFuncional);
        if (this.predio.unidadFuncional != null) {
          this.cargarSubtramosPorUnidadFuncional(this.predio.unidadFuncional.id);
        }


        this.prdMaTipoDocumentoDTOList = tipoDocumentoData.tiposDocumento;
        this.blockedDocument = false;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      }
    );
  }

  cargarSubtramosPorUnidadFuncional(idUnidadFuncional) {
    // console.log(idUnidadFuncional);

    this.blockedDocument = true;
    forkJoin(
      this.unidadFuncionalCarreteroService.getListarSubtramosPorUnidadFuncional(idUnidadFuncional, false)
    ).subscribe(([result]) => {
      // console.log(result);

      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.subtramos = result.subtramos;

          // Cargar el objeto del dropdown Subtramo
          this.predio.subtramo = this.subtramos.find(
            (t) => t.idSubtramo === this.predio.idSubtramo
          );
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }


  btnCargarDocumento() {
    this.prdDocumento = {};
    this.prdDocumento.idProyecto = this.idProyecto;
    this.prdMaTipoDocumentoDTO = {};
    //Limpiar archivos y errores del componente almacenamiento
    this.adjuntarAlmacenamimentoComponent.limpiarArchivos();
    // Abrir el dialogo
    this.dialogoCargarDocumento = true;
  }

  cargarListadoDocumentosPorPredio(idPredio: number) {
    forkJoin(
      this.prediosService.srvListarDocumentosPorPredio(idPredio)
    ).subscribe(([documentosData]: any) => {
      // Cargar el objeto de propietarios ya registrados
      // console.log('documentosData', documentosData);
      this.prdDocumentoList = documentosData.documentos;
    },
      (e) => { }
    );
  }

  btnEliminarDocumento(documentoSelected: PrdDocumentoDTO) {
    this.prdDocumento = documentoSelected;
    this.prdDocumento.idProyecto = this.idProyecto;
    this.prdDocumento.fechaDocumento = new Date(this.prdDocumento.fechaDocumento);
    this.dialogoEliminarDocumento = true;
  }

  btnAprobarDocumento(documentoSelected: PrdDocumentoDTO) {
    this.prdDocumento = documentoSelected;
    this.prdDocumento.idProyecto = this.idProyecto;
    this.prdDocumento.fechaDocumento = new Date(this.prdDocumento.fechaDocumento);
    this.prdDocumento.publicado = true;
    this.dialogoAprobarDesaprobarDocumento = true;
  }

  btnDesaprobarDocumento(documentoSelected: PrdDocumentoDTO) {
    this.prdDocumento = documentoSelected;
    this.prdDocumento.idProyecto = this.idProyecto;
    this.prdDocumento.fechaDocumento = new Date(this.prdDocumento.fechaDocumento);
    this.prdDocumento.publicado = false;
    this.dialogoAprobarDesaprobarDocumento = true;
  }


  submitEliminarDocumento() {
    this.blockedDocument = true;
    this.dialogoEliminarDocumento = false;
    forkJoin(
      this.prediosService.srvEliminarDocumento(this.prdDocumento.id)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // console.log('----------srvEliminarDocumento');
            // console.log(result.respuestaServicio.mensajeSalida);
            // Consultar nuevamente la lista
            this.cargarListadoDocumentosPorPredio(this.idPredio);
            // Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            // console.log(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // console.log('Ocurrio un error al consumir el servicio srvEliminarDocumento');
        }
      },
      (error) => {
        // console.log(error);
      },
      () => {
        // Desbloquear pantalla       
        this.blockedDocument = false;
      }
    );
  }

  submitAprobarDesaprobarDocumento() {
    if (this.formAprobarDesaprobarDocumento.valid) {
      this.blockedDocument = true;
      this.dialogoAprobarDesaprobarDocumento = false;
      forkJoin(
        this.prediosService.srvaAprobarDesaprobarDocumento(this.prdDocumento)
      ).subscribe(
        ([result]) => {
          // console.log(result);
          if (result != null && result.respuestaServicio != null) {
            // Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              console.log('----------srvAprobarDocumento');
              console.log(result.respuestaServicio.mensajeSalida);
              // Consultar nuevamente la lista
              this.cargarListadoDocumentosPorPredio(this.idPredio);
              // Lanzar mensaje de exito
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            // console.log('Ocurrio un error al consumir el servicio srvAprobarDocumento');
          }
        },
        (error) => {
          // console.log(error);
        },
        () => {
          // Desbloquear pantalla        
          this.blockedDocument = false;
        }
      );
    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos marcados con asterisco (*)');
    }
  }


  /**
  * Guarda un nuevo documento asociado al predio
  */
  submitCargarDocumento() {
    // console.log(this.formCargarDocumento);
    if (this.formCargarDocumento.valid) {
      // console.log('Submit Cargar Documento');

      this.archivos.forEach(element => {
        // console.log('aceptarAdjuntoEnvidencia', element);
        if (element.estado == "OK") {
          this.prdDocumento.ruta = element.nombreCompleto;
          this.prdDocumento.nombre = element.nombre;
        }
      });

      // Completar el DTO a guardar
      this.prdDocumento.idTipoDocumento = this.prdMaTipoDocumentoDTO.id;
      this.prdDocumento.idPredio = this.idPredio;

      // console.log(this.prdDocumento);

      this.blockedDocument = true;
      this.dialogoCargarDocumento = false;
      // Guardar el DTO
      forkJoin(
        this.prediosService.srvGuardarDocumento(this.prdDocumento)
      ).subscribe(
        ([result]) => {
          // console.log(result);
          if (result != null && result.respuestaServicio != null) {
            // Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              // console.log('----------srvGuardarDocumento');
              // console.log(result.respuestaServicio.mensajeSalida);
              // Consultar nuevamente la lista
              this.cargarListadoDocumentosPorPredio(this.idPredio);
              // Lanzar mensaje de exito
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              // Limpiar Formulario
              this.formCargarDocumento.reset();
              this.formCargarDocumento.markAsPristine();
              this.formCargarDocumento.markAsUntouched();
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            // console.log('Ocurrio un error al consumir el servicio srvGuardarDocumento');
          }
        },
        (error) => {
          // console.log(error);
        },
        () => {
          // Desbloquear pantalla        
          this.blockedDocument = false;
        }
      );
    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos marcados con asterisco (*)');
    }
  }

  bntVolver() {
    const ruta =
      'carreteros/infGeneral/proyectoHome/' +
      this.idProyecto +
      '/predios/' +
      this.idProyecto +
      '/registro-detallado-predios/' +
      this.idProyecto;
    this.router.navigate([ruta]);
  }

  // // // // // // // // /Mensajes// // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
