import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-seguimiento-home',
    templateUrl: './seguimiento-home.component.html',
    styleUrls: ['./seguimiento-home.component.sass']
})

export class SeguimientoHomeComponent implements OnInit {

    constructor(private router: Router) { }

    ngOnInit() {
        const element = document.getElementById('aniscopio_body');
        element.className = 'ani public logeo contratacion';
      }

      onNavigate(link) {
        window.open(link, '_blank');
      }
}
