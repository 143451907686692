import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { FilterMetadata } from 'primeng/api';
import { Observable } from 'rxjs-compat/Observable';
import { Data, Router } from '@angular/router';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { TrnTipoDocumento } from 'src/app/parametricas/trn-tipo-documento';
import { ParametricasService } from '../../parametricas/parametricas.service';
import { EnumRegex, EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-personas',
  templateUrl: './personas.component.html'
})
export class PersonasComponent implements OnInit {

  // Informacion del datatable
  colsPersonas: any[] = [];
  personas: any[] = [];
  totalRecords: number;
  loading: boolean;

  // Filtros
  trnTiposDocumento: TrnTipoDocumento[];
  idTipoDocumentoFiltro: TrnTipoDocumento;
  numeroDocumentoFiltro: string;
  buscarFiltro: string;

  visualizarDialogoCrearPersona: boolean = false;
  visibleDialogoExito: boolean = false;
  personaCreada: any;

  constructor(
    private router: Router,
    private personaService: PersonaService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarListasParamtericas();
    var element = document.getElementById("aniscopio_body");
    element.className = "ani public logeo adminpersonas";
    this.loading = true;
  }

  cargarListasParamtericas() {
    this.loading = true;
    // Cargar tipos de documento
    this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.CC + ',' + EnumTipoDocumento.NIT + ',' + EnumTipoDocumento.NIEE).subscribe(
      data => {
        if (data != null) {
          this.trnTiposDocumento = data;
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }

  loadDataLazy(event: LazyLoadEvent) {
    this.loading = true;
    this.personas = [];
    const idTipoDocSeleccionado = this.idTipoDocumentoFiltro != null ? this.idTipoDocumentoFiltro.id : null;

    this.personaService.getPersonasAllPaginado(idTipoDocSeleccionado, this.numeroDocumentoFiltro, event.first, event.rows, this.buscarFiltro).subscribe(
      data => {
        if (data != null) {
          this.personas = data;
          this.totalRecords = data[0] != null ? data[0].totalRegistros : 0;
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  buscar(buscarTodosRegistros: boolean) {
    const idTipoDocSeleccionado = this.idTipoDocumentoFiltro != null ? this.idTipoDocumentoFiltro.id : null;

    const filaDesde = buscarTodosRegistros ? null : 0;
    const cantidadRegistros = buscarTodosRegistros ? null : 20;
    this.loading = true;
    this.personas = [];
    this.personaService.getPersonasAllPaginado(idTipoDocSeleccionado, this.numeroDocumentoFiltro, filaDesde, cantidadRegistros, this.buscarFiltro).subscribe(
      data => {
        if (data != null) {
          this.personas = data;
          this.totalRecords = data[0] != null ? data[0].totalRegistros : 0;
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  limpiarFiltros() {
    this.idTipoDocumentoFiltro = null;
    this.numeroDocumentoFiltro = null;
    this.buscarFiltro = null;
    this.buscar(false);
  }

  irEditarPersona(persona: any) {
    this.router.navigate([this.router.url + '/persona-base/', persona.id, persona.idTipoDocumento]);
  }

  nuevaPersonaCreada(persona: any) {
    this.personaCreada = persona
    this.visibleDialogoExito = true;
  }

  // Informativo - Dialogo
  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.router.navigate([this.router.url + '/persona-base/', this.personaCreada.idPersona, this.personaCreada.tipoDocumento.id]);
  }

  get EnumRegex() {
    return EnumRegex;
  }

}
