import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { InformacionGeneral } from './informacionGeneral';

@Injectable({
    providedIn: 'root'
})

export class InformacionGeneralProyectoService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public obtenerInformacionGeneral(idProyecto): Observable<ConsultaInformacionGeneralProyectoRpt> {
        return this.http.get<ConsultaInformacionGeneralProyectoRpt>(this.END_POINT + '/CrrInformacionGeneralProyecto/obtenerInformacionGeneralProyecto/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public guardarInformacionGeneral(informacionGeneralDTO: InformacionGeneral, idProyecto){
        return this.http.post<ConsultaInformacionGeneralProyectoRpt>(this.END_POINT + '/CrrInformacionGeneralProyecto/guardarInformacionGeneral/'+ idProyecto, informacionGeneralDTO)
        .pipe(
            map(data => {
                return data;
            }),
            retry(1));
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
}

export class ConsultaInformacionGeneralProyectoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public informacionGeneralProyecto?: InformacionGeneral[]
    ) { }
}