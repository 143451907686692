import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TrnRespuestaServicio } from '../parametricas/trn-respuesta-servicio';
import { UnidadFuncional } from 'src/app/unidad-funcional-carretero/unidadFuncional';


@Injectable({
    providedIn: 'root'
})
export class UnidadFuncionalCarreteroService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }



    public getUnidadesFuncionalesPorProyecto(idProyecto): Observable<ListaUnidadFuncionalRpt> {
        return this.http.get<ListaUnidadFuncionalRpt>(this.END_POINT + '/CrrUnidadFuncional/list/' + idProyecto)
            .pipe(
                retry(1));
    }


    public getUnidadFuncionalPorId(id): Observable<ConsultaUnidadFuncionalRpt> {
        let esBorrador:Boolean = false;
        return this.http.get<ConsultaUnidadFuncionalRpt>(this.END_POINT + '/CrrUnidadFuncional/' + id + '/' + esBorrador)
            .pipe(
                retry(1));
    }

    public getIntervencionesPorUnidadFuncional(idUnidadFuncional) {
        return this.http.get(this.END_POINT + '/CrrIntervencionUnidadFuncional/list/' + idUnidadFuncional)
            .pipe(
                retry(1));
    }

    public getIntervencionPorId(idIntervencion) {
        return this.http.get(this.END_POINT + '/CrrIntervencionUnidadFuncional/' + idIntervencion)
            .pipe(
                retry(1));
    }

    public guardarNuevaIntervencionPorUnidadFuncional(data) {
        return this.http.post(this.END_POINT + '/CrrIntervencionUnidadFuncional', data)
            .pipe(
                retry(1));
    }

    public editarIntervencionPorUnidadFuncional(data, idIntervencion) {
        return this.http.put(this.END_POINT + '/CrrIntervencionUnidadFuncional/' + idIntervencion, data)
            .pipe(
                retry(1));
    }

    public getSubtramosPorUnidadFuncional(idUnidadFuncional) {
        return this.http.get(this.END_POINT + '/CrrSubtramo/list/' + idUnidadFuncional)
            .pipe(
                retry(1));
    }

    public getSubtramosPorId(idSubtramo) {
        return this.http.get(this.END_POINT + '/CrrSubtramo/' + idSubtramo)
            .pipe(
                retry(1));
    }

    public guardarNuevoSubtramoPorUnidadFuncional(data) {
        return this.http.post(this.END_POINT + '/CrrSubtramo', data)
            .pipe(
                retry(1));
    }

    public editarSubtramoPorUnidadFuncional(data, idSubtramo) {
        return this.http.put(this.END_POINT + '/CrrSubtramo/' + idSubtramo, data)
            .pipe(
                retry(1));
    }

    public eliminarSubtramoPorUnidadFuncional(idSubtramo) {
        return this.http.delete(this.END_POINT + '/CrrSubtramo/' + idSubtramo)
            .pipe(
                retry(1));
    }


    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }





}

export class ListaUnidadFuncionalRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public unidadesFuncionales?: UnidadFuncional[]
    ) { }
}

export class ConsultaUnidadFuncionalRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public unidadFuncional?: UnidadFuncional
    ) { }
}
