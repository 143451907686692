import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PrsFuncionario } from 'src/app/admin-personas/prs-funcionario';
import { environment } from 'src/environments/environment';
import { CtoFuenteFinanciacionDTO } from '../../contratacion-dto/cto-fuente-financiacion';
import { CtoContratoDTO, CtoContratoRpt } from '../../contratacion-dto/contrato';
import { RpUspCtoFinalizaContratoBorradorDTO } from '../../contratacion-dto/rp-usp-finaliza-contrato-borrador';
import { CtoContatoObligacionBorradorDTO, CtoContatoObligacionBorradorRpt } from '../../contratacion-dto/cto-contrato-obligacion-borrador';
import { TrnRespuestaServicioRpt } from '../../../parametricas/rpt-respuesta-operacion';

@Injectable({
  providedIn: 'root'
})
export class ContratacionBorradorService {
  public END_POINT = '';
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  /**
  * Metodo que consulta el listado de contratos en borrador creados
  * @returns CtoContratoRpt
  */
  srvListarContratosBorrador(): Observable<CtoContratoRpt> {
    return this.http.get<CtoContratoRpt>(this.END_POINT + '/CtoContratoBorrador/ListarContratosBorrador')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  /**
   * Metodo que consulta un contrato borrador previamente creado por su Id
   * @param idContrato Id del contrato
   * @returns CtoContratoRpt
   */
  srvGetContratoBorradorPorId(idContrato: number): Observable<CtoContratoRpt> {
    return this.http.get<CtoContratoRpt>(this.END_POINT + '/CtoContratoBorrador/GetContratoBorradorPorId/' + idContrato)
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  /**
   * Metodo que guardar un contrato borrador
   * @param contrato Objeto CtoContratoDTO con la información a guardar
   * @returns CtoContratoRpt
   */
  srvGuardarContratoBorrador(contrato: CtoContratoDTO): Observable<CtoContratoRpt> {
    return this.http.post<CtoContratoRpt>(this.END_POINT + '/CtoContratoBorrador/GuardarContratoBorrador', contrato)
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  /**
   * Metodo que publica un contrato borrador a definitivo
   * @param idContratoBorrador Id del contrato borrador
   * @returns RpUspCtoFinalizaContratoBorrador
   */
  srvGuardarContratoDefinitivo(idContratoBorrador: number): Observable<RpUspCtoFinalizaContratoBorradorDTO> {
    return this.http.post<RpUspCtoFinalizaContratoBorradorDTO>(this.END_POINT + '/CtoContratoBorrador/GuardarContratoBorradorADefinitivo?idContratoBorrador=' + idContratoBorrador, idContratoBorrador, this.httpOptions)
      .pipe(
        retry(1));
  }

  /**
   * Metodo que elimina un contrato borrador por su id
   * @param idContratoBorrador Id del contrato borrador
   * @returns
   */
  EliminarContratoBorrador(idContratoBorrador: number) {
    return this.http.delete(this.END_POINT + '/CtoContratoBorrador/EliminarCtoContratoBorrador/' + idContratoBorrador)
      .pipe(
        retry(1));
  }

  GetFuentesFinanciacionPorContratoBorrador(idContrato: number) {
    return this.http.get<CtoFuenteFinanciacionDTO[]>(this.END_POINT + '/CtoContratoBorrador/FuentesFinanciacionPorContratoBorrador/' + idContrato)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  GuardarContratoFuenteBorrador(fiduciaContrato): Observable<any> {
    return this.http.post(this.END_POINT + '/CtoContratoBorrador/GuardarContratoFuenteBorrador', fiduciaContrato, this.httpOptions)
      .pipe(
        retry(1));
  }

  EliminarContratoBorradorFuente(fiduciaContrato): Observable<any> {
    return this.http.post(this.END_POINT + '/CtoContratoBorrador/EliminarContratoBorradorFuente', fiduciaContrato)
      .pipe(
        retry(1));
  }

  ConsultarSupervisoresPorContratoBorrador(idContrato: number): Observable<PrsFuncionario[]> {
    return this.http.get<PrsFuncionario[]>(this.END_POINT + '/CtoContratoBorrador/ConsultarSupervisoresPorContratoBorrador/' + idContrato)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  GuardarContratoBorradorSupervisor(supervisorContrato): Observable<any> {
    return this.http.post(this.END_POINT + '/CtoContratoBorrador/GuardarContratoBorradorSupervisor', supervisorContrato, this.httpOptions)
      .pipe(
        retry(1));
  }

  EliminarContratoBorradorSupervisor(supervisorContrato): Observable<any> {
    return this.http.post(this.END_POINT + '/CtoContratoBorrador/EliminarContratoBorradorSupervisor', supervisorContrato)
      .pipe(
        retry(1));
  }


  getContrato(idContrato: number): Observable<CtoContratoDTO> {
    return this.http.get<CtoContratoDTO>(this.END_POINT + '/CtoContrato/' + idContrato)
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  /**
   * Metodo que consulta las obligaciones asociadas a un contrato en borrador
   * @param idContrato Id del contrato
   * @returns Resultado de la operación de tipo CtoContatoObligacionBorradorRpt
   */
  ConsultarContratoObligacionBorradorPorContratoBorrador(idContrato: number): Observable<CtoContatoObligacionBorradorRpt> {
    return this.http.get<CtoContatoObligacionBorradorRpt>(this.END_POINT + '/CtoContratoBorrador/ConsultarContratoObligacionBorradorPorContrato/' + idContrato)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  /**
   * Metodo que guarda una obligacion asociada a un contrato en borrador
   * @param contratoObligacion Objeto de tipo  CtoContatoObligacionBorradorDTO
   * @returns Resultado de la operación de tipo TrnRespuestaServicioRpt
   */
  GuardarContratoObligacionBorrador(contratoObligacion: CtoContatoObligacionBorradorDTO): Observable<TrnRespuestaServicioRpt> {
    return this.http.post<TrnRespuestaServicioRpt>(this.END_POINT + '/CtoContratoBorrador/GuardarContratoObligacionBorrador', contratoObligacion, this.httpOptions)
      .pipe(
        retry(1));
  }

  /**
   * Metodo que elimina una obligación de contrato a asociada a un contrato en borrador
   * @param idContratoObligacion Id de la Obligacion a eliminar
   * @returns Resultado de la operación de tipo TrnRespuestaServicioRpt
   */
  EliminarContratoObligacionBorrador(idContratoObligacion: number): Observable<TrnRespuestaServicioRpt> {
    return this.http.delete<TrnRespuestaServicioRpt>(this.END_POINT + '/CtoContratoBorrador/EliminarCtoObligacionContratoBorrador/' + idContratoObligacion, this.httpOptions)
      .pipe(
        retry(1));
  }


  CalcularFechaTerminacionContratoBorradorPorPlazosFechaInicio(contrato: CtoContratoDTO): Observable<any> {
    return this.http.post<any>(this.END_POINT + '/CtoContratoBorrador/CalcularFechaTerminacionContratoBorradorPorPlazosFechaInicio', contrato, this.httpOptions)
      .pipe(
        retry(1));
  }
}
