import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporte-informes-contratista-ejecutivo',
  templateUrl: './reporte-informes-contratista-ejecutivo.component.html',
  styles: []
})
export class ReporteInformesContratistaEjecutivoComponent implements OnInit {

  constructor() { }

  ngOnInit() {    
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
  }

}
