import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-anonimo-carreteros',
  templateUrl: './anonimo-carreteros.component.html',
  styleUrls: []
})
export class AnonimoCarreterosComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
  element.classList.add("public");
  }


}
