import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicPuertoFichaProyectoDrupalService } from './public-puertos-ficha-proyecto-drupal.service';
import { Data } from '../public-puertos-ficha-proyecto-drupal/objeto';
import { Imagenes } from '../public-puertos-ficha-proyecto-drupal/objeto';
import { Documentos } from '../public-puertos-ficha-proyecto-drupal/objeto';


@Component({
  selector: 'app-public-puertos-ficha-proyecto-drupal',
  templateUrl: './public-puertos-ficha-proyecto-drupal.component.html'
})
export class PublicPuertosFichaProyectoDrupalComponent implements OnInit {

  data: Data = {};
  imagen: Imagenes = {};
  documento: Documentos = {};

  imagenes: any = [];
  documentos: any = [];
  datos: any = [];
  banner1s: any = [];

  loading: boolean;
  select: any = { nombre: '', id : ''};
  lstSelect: any = [];
  datosCompletos: any = [];
  datosAeropuertoContratista: any = [];
  contratoSeleccionada: any = { id: 0, nombre: ''};


  constructor(private info: PublicPuertoFichaProyectoDrupalService, private route: Router,
    private ruta: ActivatedRoute) { }

  /**
   * Metodo inicializador
   */  
  ngOnInit() {
    this.loading = true;
    this.select = { id: 0, nombre: ''};
    this.contratoSeleccionada = { id: 0, nombre: ''};
    this.lstSelect = [];
    this.cargarInformacion();
  }


  /**
   * Metodo que carga la informacion
   */
  morebtn(id){
    document.getElementsByClassName('more')[0].className = 'none';
    document.getElementById('more'+id).classList.remove("none");
    document.getElementById('botonver'+id).className = 'none';
    document.getElementsByClassName('botonmore'+id)[0].classList.remove("none");
    document.getElementById('more'+id).style.display = 'block';
  }

  /**
  Metodo que cierra el ver mas
   */
  cerrarbtn(id){
    document.getElementById('more'+id).className = 'none' ;
    //document.getElementsByClassName('botonmore'+id)[0].className = 'none';
    document.getElementById('botonver'+id).classList.remove("none");
    document.getElementById('botonver'+id).style.display = 'block';
    
    document.getElementsByClassName('botonmore'+id)[0].classList.add("none");
    document.getElementById('more'+id).classList.add("more");
    document.getElementById('botonver'+id).className = 'boton ';
    document.getElementById('more'+id).style.display = 'none';
  }

  /**
   * Metodo que carga la informacion
   */
  cargarInformacion() {
    this.info.getInfoFichaProyectoPuertosDrupal().subscribe((data: {}) => {
      this.responseData(data);
    });
  }

  /**
	 * Metodo que actualiza el cargue de la informacion
	 */
   onChangeContratista(){
		if( this.contratoSeleccionada.nombre != "Seleccione..."){
      this.datos = [];
      this.datosAeropuertoContratista.forEach(element => {
         if(this.contratoSeleccionada.nombre == element.field_municipio){
           this.datos.push(element);
         }
      });
		}else{
       this.datos = [];
    }
	}

  /**
   * metodo que transforma el objeto
   * @param payload 
   */
  responseData(payload) {
    console.log(payload);
    
    payload.data.forEach(element => {
      this.data = new Data();
      var imagendos = '';
      if(element.banner1s == null){
        imagendos = '';
      }else{
        imagendos = element.banner1s;
      }
      if (element.categoria == 296) {
        this.data.id = element.id;
        this.data.objeto = element.objeto['value'];
        this.data.label = element.label;
        this.data.value = element.value;
        this.data.zona = element.zona;
        this.data.categoria = element.categoria;
        this.data.banner1s = imagendos['filename'];
        this.data.numero_de_contrato = element.numero_de_contrato;
        this.data.estado_proyecto = element.estado_proyecto;
        this.data.contratista = element.contratista;
        this.data.field_municipio = element.field_municipio;
        this.data.fecha_publicacion = new Date(element.fecha_publicacion)
        this.data.fecha_publicacion_str = new Date(element.fecha_publicacion).toLocaleDateString();
        this.data.field_fecha_suscripcion_del_cont = (element.field_fecha_suscripcion_del_cont!= null && element.field_fecha_suscripcion_del_cont!= "null")? element.field_fecha_suscripcion_del_cont+"000": null;
        this.data.field_fecha_inicio_proyecto = (element.field_fecha_inicio_proyecto!= null && element.field_fecha_inicio_proyecto!= "null")? element.field_fecha_inicio_proyecto+"000": null;
        this.data.field_fecha_fin_proyecto = (element.field_fecha_fin_proyecto!= null && element.field_fecha_fin_proyecto!= "null")? element.field_fecha_fin_proyecto+"000": null;
        this.data.field_interventor_proyecto = element.field_interventor_proyecto;
        this.data.field_tipo_de_carga = element.field_tipo_de_carga;
        this.data.field_tipo_de_permiso = element.field_tipo_de_permiso;
        this.data.field_valor_proyecto = element.field_valor_proyecto;

        this.imagenes = [];
        element.imagenes.forEach(ima => {
          this.imagen = new Imagenes();
          this.imagen.fid = ima.fid;
          this.imagen.uid = ima.uid;
          this.imagen.filename = ima.filename;
          this.imagen.filemime = ima.filemime;
          this.imagenes.push(this.imagen);
        });

        this.data.imagenes = this.imagenes;

        this.documentos = [];
        element.documentos.forEach(doc => {
          this.documento = new Documentos();
          this.documento.fid = doc.fid;
          this.documento.uid = doc.uid;
          this.documento.filename = doc.filename;
          this.documento.filemime = doc.filemime;
          this.documentos.push(this.documento);
        });
        this.data.documentos = this.documentos;

        this.datosAeropuertoContratista.push(this.data);
      }
    });

    
    this.lstSelect = [];
    this.select = { id: 0, nombre: 'Seleccione...'};
    this.lstSelect.push(this.select);
    payload.data.forEach(element => {
      if(element.field_municipio != null){
        if(this.lstSelect.length == 0){
          this.select = {  id: element.id, nombre: element.field_municipio };
          this.lstSelect.push(this.select);
        }else{
          var encontro = false;
          this.lstSelect.forEach(objeto => {
            if(objeto.nombre == element.field_municipio){
              encontro = true;
            }
          });
          if(!encontro){
            this.select = {  id: element.id, nombre: element.field_municipio };
            this.lstSelect.push(this.select);
          }
        }
      }
    });
    this.loading = false;

  }

}
