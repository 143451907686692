import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';

export class TrnPersonaRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public persona?: TrnPersona,
        public aceptoCondiciones?: boolean
    ) { }
}

export class TrnPersona {
    constructor(
        public id?: number,
        public idTipoDocumento?: string,
        public tipoDocumento?: string,
        public numeroDocumento?: string,
        public digitoVerificacion?: number,
        public nombre?: string,
        public primerNombre?: string,
        public otrosNombres?: string,
        public primerApellido?: string,
        public segundoApellido?: string,
        public tipoPersona?: string,
        public representanteLegal?: string,
        public correoElectronicoAni?: string,//TrnPersona
        public correoElectronicoPersonal?: string,//PrsPersonaDatosAdicionales
        public direccionDomicilio?: string,
        public idMunicipioDomicilio?: number,
        public municipioDomicilio?: string,
        public idDepartamentoMunicipioDomicilio?: number,
        public departamentoMunicipioDomicilio?: string,
        public observaciones?: string,
        public numeroCelular?: string,
        public paginaWeb?: string,
        // Info adicional
        public idSgrUsuario?: number,
        public sgrUsuario?: string, //SgrUsuario
        public usuarioActualizacion?: string
    ) { }
}
