import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CtoNovedadContratoRpt, CtoNovedadContratoDTO } from '../../contratacion-dto/cto-novedad-contrato';
import { CtoNovedadContratoFuenteRpt } from '../../contratacion-dto/cto-novedad-contrato-fuente';
import { TrnRespuestaServicioRpt } from 'src/app/parametricas/rpt-respuesta-operacion';

@Injectable({
    providedIn: 'root'
})
export class NovedadesConstractualesService {

    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    ConsultarNovedadesPorContrato(idContrato: number): Observable<CtoNovedadContratoRpt> {
        return this.http.get<CtoNovedadContratoRpt>(this.END_POINT + '/CtoNovedadesContractuales/ConsultarNovedadesPorContrato/' + idContrato)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    ConsultarNovedad(idNovedad: number): Observable<CtoNovedadContratoRpt> {
        return this.http.get<CtoNovedadContratoRpt>(this.END_POINT + '/CtoNovedadesContractuales/ConsultarNovedadPorNovedad/' + idNovedad)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    ConsultarNovedadContratoFuentesFinanciacionPorNovedad(idNovedad: number): Observable<CtoNovedadContratoFuenteRpt> {
        return this.http.get<CtoNovedadContratoFuenteRpt>(this.END_POINT + '/CtoNovedadesContractuales/ConsultarFuentesFinanciacionPorNovedad/' + idNovedad)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    ConsultarNovedadContratoFuentesFinanciacionIniciales(idContrato: number): Observable<CtoNovedadContratoFuenteRpt> {
        return this.http.get<CtoNovedadContratoFuenteRpt>(this.END_POINT + '/CtoNovedadesContractuales/ConsultarFuentesFinanciacionInicialesNovedades/' + idContrato)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    ConsultarContratoFuentesFinanciacionNovedadLiberacion(idContrato: number): Observable<CtoNovedadContratoFuenteRpt> {
        return this.http.get<CtoNovedadContratoFuenteRpt>(this.END_POINT + '/CtoNovedadesContractuales/ConsultarContratoFuentesFinanciacionNovedadLiberacion/' + idContrato)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }



    srvEliminarNovedad(idNovedad: number): Observable<TrnRespuestaServicioRpt> {
        return this.http.delete<TrnRespuestaServicioRpt>(this.END_POINT + '/CtoNovedadesContractuales/EliminarNovedad/' + idNovedad)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvGuardarNovedad(novedad: CtoNovedadContratoDTO): Observable<CtoNovedadContratoRpt> {
        return this.http.post<CtoNovedadContratoRpt>(this.END_POINT + '/CtoNovedadesContractuales/GuardarNovedad', novedad)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


}
