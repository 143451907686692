import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InformacionGeneralService } from '../informacion-general/informacion-general.service';
import { environment } from '../../environments/environment';
import { ValidacionInformacionProyectoService } from '../informacion-general-carretero/validacion-informacion-proyecto/validacion-informacion-proyecto.service';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';
import { CrrDatosConfiguracionProyecto } from '../informacion-proyecto-carretero/crrDatosConfiguracionProyecto';

@Component({
  selector: 'app-proyecto-home',
  templateUrl: './proyecto-home.component.html',
  styleUrls: ['./proyecto-home.component.sass'],
})
export class ProyectoHomeComponent implements OnInit {
  proyecto: any;
  id: number;
  idSherlock: number;
  esProduccion: boolean;
  webUrlConcesiones: any;
  datosConfigProyecto: CrrDatosConfiguracionProyecto;

  constructor(
    private infoService: InformacionGeneralService,
    private validacionInformacionProyectoService: ValidacionInformacionProyectoService,
    private route: ActivatedRoute,
    private router: Router, private messageService: MessageService, private principalComponent: PrincipalComponent,
    @Inject(DOCUMENT) private document: any
  ) { }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.cargarProyectoCarretero(this.id);
    });
    localStorage.removeItem('idP');
    this.esProduccion = environment.esProduccion;
    this.webUrlConcesiones = environment.webUrlConcesiones;

    // Valida si el proyecto fue publicado
    this.validacionInformacionProyectoService
      .getDatosConfiguracionProyecto(this.id)
      .subscribe((data: {}) => {
        this.datosConfigProyecto = data;
        console.log(this.datosConfigProyecto);
        if (this.datosConfigProyecto.publicado) {
        } else {
        }
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
      this.idSherlock = this.proyecto.idSherlock;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAInformacionContractual() {
    this.router.navigate([this.router.url + '/contratosProyecto', this.id]);
  }

  irAInformacionGeneralProyecto() {
    this.router.navigate([
      this.router.url + '/informacion-general-proyecto',
      this.id,
    ]);
  }
  irALineaBase() {
    this.router.navigate([this.router.url + '/alcance-proyecto', this.id]);
  }

  irAGeneral() {
    this.router.navigate([this.router.url + '/infGeneral', this.id]);
  }

  irAAmbiental() {
    this.router.navigate([this.router.url + '/infAmbiental', this.id]);
  }

  irAPredial() {
    this.router.navigate([
      this.router.url + '/predios',
      this.id,
    ]);
    //this.router.navigate([this.router.url + '/infPredial', this.id]);
  }

  irARegistro() {
    this.document.location =
      'https://concesionessherlockaniwebtest.azurewebsites.net/concesiones/' +
      this.id +
      '/dashboard';
  }

  irAInfInterventoriaMinTrans() {
    this.document.location =
      'https://gpi.mintransporte.gov.co/reports/loadReports';
  }

  irAUnidadesFuncionales() {
    this.router.navigate([this.router.url + '/uniFuncionales', this.id]);
  }

  irAFinancieroHome() {
    this.router.navigate([this.router.url + '/financieroHome', this.id]);
  }

  irAJuridico() {
    this.router.navigate([this.router.url + '/menuJuridico', this.id]);
  }

  irAFinanciero() {
    this.router.navigate([this.router.url + '/menuFinanciero', this.id]);
  }

  irATramitesA() {
    this.router.navigate([this.router.url + '/tramites-carretero-listado', this.id]);

    /*return this.infoService.getTramitesA().subscribe((data: {}) => {
      localStorage.setItem('datosPrueba', JSON.stringify(data));
      });*/
  }

  irATemasG() {
    this.router.navigate([this.router.url + '/infTemasG', this.id]);
  }

  irAPeajes() {
    this.router.navigate([this.router.url + '/infPeajes', this.id]);
  }

  irAPolizas() {
    this.router.navigate([this.router.url + '/polizas', this.id]);
  }

  irCargaExtrapesadaConcesionario() {
    localStorage.setItem('volverHomeMenuProyectos', this.router.url);
    this.router.navigate([this.router.url + '/cargaExtrapesadaConc', this.id]);
  }

  irRadicacionWeb() {
    localStorage.setItem('volverHomeMenuProyectos', this.router.url);
    this.router.navigate([this.router.url + '/listRadicacionWeb', this.id]);
  }

  irAAvanceProyecto() {
    this.router.navigate([this.router.url + '/avance-proyecto', this.id]);
  }

  irATraficoRecaudo() {
    this.router.navigate([this.router.url + '/trafico-recaudo', this.id]);
  }

  irACovidReportar() {
    this.router.navigate([this.router.url + '/covid-reportar', this.id]);
  }

  irAServicioCarga() {
    this.router.navigate([this.router.url + '/servicioCarga', this.id]);
  }

  irAMenuPeajes() {
    this.router.navigate([this.router.url + '/menu-peajes', this.id]);
  }

  irACompensacionesAmbientales() {
    this.router.navigate([
      this.router.url + '/compensaciones-ambientales',
      this.id,
    ]);
  }

  irAEquiposIts() {
    this.router.navigate([this.router.url + '/equiposIts', this.id]);
  }


  irOlaInvernal() {
    this.router.navigate([this.router.url + '/ola-invernal-registro', this.id]);

  }
}


