import { Component, OnInit, OnDestroy, } from '@angular/core';
import { OrfeoConsultaService, } from './orfeo-consulta.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneral } from './informacionGeneral';

@Component({
    selector: 'app-orfeo-consulta',
    templateUrl: './orfeo-consulta.component.html',
    styleUrls: ['./orfeo-consulta.component.css']
})
export class OrfeoConsultaComponent implements OnInit {
    
    loading: boolean;
    mostrarCabecera : boolean;
    numeroRadicado: any = null;
    listadoTemp: any = null;
    urlTree : any;
    informacionGeneral: InformacionGeneral = {};

    /**
     * Constructor
     */
    constructor(private _service: OrfeoConsultaService, 
        private route: Router,
        public location: Location,
        private messageService: MessageService,
        private principalComponent: PrincipalComponent) { }

    /**
     * Inicializador
     */
    ngOnInit() {
      this.urlTree =  this.route.parseUrl(this.route.url)
      this.numeroRadicado = this.urlTree.queryParams['Radicado'];
      this.mostrarCabecera = false;
      this.loading = true;
      if(this.numeroRadicado != undefined && this.numeroRadicado != null && this.numeroRadicado != ""){
        this.onFind(this.numeroRadicado);
      }else{
        this.mostrarCabecera = false;
        this.loading = false;
      }      
    }

    /**
     * Buscador de radicado
     */
    onFind(radicado :any){
        this.loading = true;
        this.informacionGeneral = new InformacionGeneral();

        if(this.numeroRadicado != null && this.numeroRadicado.trim() != ""){

            this._service.getConsultaRadicado(this.numeroRadicado).subscribe((data: {}) => {
               this.listadoTemp = data;
               if(this.listadoTemp != undefined 
                    && this.listadoTemp != null){
                    if(this.listadoTemp.radicado!= ""){
                        this.informacionGeneral.asunto = this.listadoTemp.asunto;
                        this.informacionGeneral.fecha = this.listadoTemp.fecha;
                        this.informacionGeneral.firmantes = this.listadoTemp.firmantes;
                        this.informacionGeneral.radicado = this.listadoTemp.radicado;
                        this.mostrarCabecera = true;
                    }else{
                        this.numeroRadicado = null;
                        this.mostrarCabecera = false;
                    }
                }else{
                    this.numeroRadicado = null;
                    this.mostrarCabecera = false;
                }
                this.loading = false;
            },error => {
                this.loading = false;
                this.numeroRadicado = null;
                this.principalComponent.cargarErrorServicio(error);
              });
        }
        
    }

}
