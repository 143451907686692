import { TrnRespuestaServicio } from "src/app/parametricas/trn-respuesta-servicio";

export interface RespuestaServicio {
    codigoSalida?: number;
    mensajeSalida?: string;

}
export interface InformacionPeajesDTO {
    respuestaServicio?: RespuestaServicio;
    infoPeajes?: CrrPeajesDTO[];

}


export class CrrPeajesDTO {
    id?: number;
    idPeaje?: number;
    peaje?: string;
    idAdministradoPor?: number;
    administradorEspecifico?: string;
    administradorPor?: string;
    idEstado?: number;
    estado?: string;
    idProyecto?: number;
    proyecto?: string;
    sentidoPeaje?: string;
    sentidoPuntoA?: string;
    sentidoPuntoB?: string;
    fechaInicioEstado?: any;
    fechaFinEstado?: any;
    fechaInicioOperacion?: any;
    idUnidadFuncional?: number;
    unidadFuncional?: string;
    tienePagoElectronico?: boolean;
    tieneTarifaEspecial?: number;
    departamento: string;
    municipio: string;
    posicionX: number;
    posicionY: number;
    ubicacionPrKm: string;
}


export interface InformacionPeajesTarifasDTO {
    respuestaServicio?: RespuestaServicio;
    infoTarifas?: CrrPeajesTarifasDTO[];

}


export class CrrPeajesTarifasDTO {
    id?: number;
    idPeaje?: number;
    fechaInicio?: Date;
    fechaFin?: Date;
    aniofin?: number;
    idCategoriaTarifa?: string;
    ordenCategoriaTarifa?: number;
    idTipoTarifa?: number;
    tipoTarifa?: string;
    idCategoriaVehiculo?: number;
    categoriaVehiculo?: string;
    valor?: number;

}


export interface InformacionPeajesTarifasEncDTO {
    respuestaServicio?: RespuestaServicio;
    encTarifas?: CrrPeajesTarifasEncDTO[];

}


export class CrrPeajesTarifasEncDTO {
    id?: number;
    idPeaje?: number;
    peaje?: string;
    ordenDesc?: number;
    fechaInicio?: Date;
    fechaFin?: Date;
    documentos?: CrrPeajesDocumentoTarifasDTO[];

}

export class CrrPeajesDocumentoTarifasDTO {
    idDocumentoTarifaPeajeEnc?: number;
    idEncabezado?: number;
    idDocumento?: number;
    numero?: string;
    fechaExpedicion?: Date;
    idTipo?: number;
    tipoDocumento?: string;
    descripcion?: string;
    rutaDocumento?: string;
    fechaInicio?: Date;
    fechaFin?: Date;
}


export class InformacionPeajesRecaudoEncDTO {

    public respuestaServicio?: RespuestaServicio;
    public encabezadoPeriodo?: CrrPeajesRecaudoEncDTO[];

}

export class CrrPeajesRecaudoEncDTO {

    public id?: number;
    public peaje?: string;
    public fechaDesde?: Date;
    public fechaHasta?: Date;
    public idEstado?: number;
    public recaudoSobrante?: number;
    public recaudoFsv?: number;
    public traficoPoliciaCarreteras?: number;
    public diasPeriodo?: number;
    public autorizaTraficoSinRecaudo?: boolean;
    public tpdconExentos?: number;
    public tpdsinExentos?: number;
    public totalRecaudoReal?: number;
    public ingresoNeto?: number;


}



export class InformacionPeajesRecaudoDetalleDTO {

    public respuestaServicio?: RespuestaServicio;
    public detallePeriodo?: CrrPeajesRecaudoDetalleDTO[];

}

export class CrrPeajesRecaudoDetalleDTO {

    public idPjeTrafico?: number;
    public categoria?: string;
    public tipoCategoria?: string;
    public tipoTarifa?: string;
    public fechaDesde?: Date;
    public fechaHasta?: Date;
    public valorTarifa?: number;
    public trafico?: number;
    public traficoEvasores?: number;
    public traficoExentos?: number;
    public valorRecaudo?: number;
    public valorRecaudoEvasores?: number;
    public orden?: number;

}



export class InformacionPeajesRecaudoAgrupadoMesDTO {

    public respuestaServicio?: RespuestaServicio;
    public traficoRecaudo?: CrrPeajesRecaudoAgrupadoMesDTO[];

}

export class CrrPeajesRecaudoAgrupadoMesDTO {

    public id?: number;
    public idPeaje?: number;
    public idCategoriaTarifa?: string;
    public ordenTarifa?: number;
    public anioReporte?: number;
    public mesReporte?: number;
    public cantidadVehiculos?: number;
    public recaudo?: number;
    public recaudoEvasores?: number;



}

export class InformacionPeajesRecaudoAgrupadoCategoriaDTO {

    public respuestaServicio?: RespuestaServicio;
    public traficoRecaudo?: CrrPeajesRecaudoAgrupadoCategoriaDTO[];

}

export class CrrPeajesRecaudoAgrupadoCategoriaDTO {
    //VwPjeDetalleTraficoRecaudoAgrupadoCategoriaTarifa
    public id?: number;
    public idPeaje?: number;
    public anioReporte?: number;
    public idCategoriaVehiculo?: number;
    public idCategoriaTarifa?: string;
    public ordenCategoriaTarifa?: number;
    public cantidadVehiculos?: number;
    public recaudo?: number;
    public recaudoEvasores?: number;
}

export interface PjePeajeConsultaTPDRptDTO {

    respuestaServicio?: TrnRespuestaServicio;
    tpdPeaje?: PjePeajeConsultaTPDDTO;
}

//UspPjeConsultaTPDDTO
export interface PjePeajeConsultaTPDDTO {
    cantidadVehiculosMes?: number;
    diasReportadosMes?: number;
    tpdm?: number;
    cantidadVehiculosAnio?: number;
    diasReportadosAnio?: number;
    tpda?: number;
}


