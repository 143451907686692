import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCtoTipoAnexoInforme } from 'src/app/contratacion/constantes/contratacion-constantes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumInvInventario } from 'src/app/inventario-equipos/inventario-constantes';
import { VwInvBienAniYpersonalDTO } from 'src/app/inventario-equipos/inventario-equipos.dto';
import { InventarioEquiposService } from 'src/app/inventario-equipos/inventario-equipos.service';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { InformeRevisionService } from '../../../informe-revision.service';
import { InformeSupervisorBaseComponent } from '../informe-supervisor-base/informe-supervisor-base.component';
import { forkJoin } from 'rxjs';
import { CtoInformeRevisionAnexoDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-anexo';

@Component({
  selector: 'app-informe-supervisor-paz-salvo',
  templateUrl: './informe-supervisor-paz-salvo.component.html',
  styles: []
})
export class InformeSupervisorPazSalvoComponent implements OnInit {

  anexosInformeRevision: CtoInformeRevisionAnexoDTO[];
  adjuntosAnexoInforme: CtoAdjuntoInformeBorradorDTO[];
  visibleDialogoVerAdjuntos: boolean;
  anexoInforme014_081: CtoInformeRevisionAnexoDTO;

  //Bienes Asignados
  bienesAniPersonal: VwInvBienAniYpersonalDTO[];

  constructor(
    private lineaBase: InformeSupervisorBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private inventarioEquiposService: InventarioEquiposService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarAnexos(EnumCtoTipoAnexoInforme.SUBTIPO_PAZ_SALVO, this.lineaBase.idInformeParam, this.lineaBase.idPersonaAsignacionParam, this.lineaBase.idPasoParam);
    this.cargarInventarioAsignadoPersona();
  }

  cargarAnexos(idsSubTipoAnexos: string, idInforme: number, idPersonaAsignacion: number, idPaso: number) {
    this.lineaBase.blockedDocument = true;
    this.infCtaSupervisionService.ConsultarAnexosPorSubTipoInformeRevision(idsSubTipoAnexos, idInforme, idPersonaAsignacion, idPaso)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.anexosInformeRevision = result.anexosInformeRevision;
              //Validar los anexos de Paz y Salvo
              this.validarAnexosPazySalvo();
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  irVerDialogoVerAdjuntosAnexos(idAnexo: number) {
    this.visibleDialogoVerAdjuntos = true;
    this.cargarAdjuntosPorAnexosAdicionales(idAnexo, this.lineaBase.idPersonaAsignacionParam);
  }

  cargarAdjuntosPorAnexosAdicionales(idAnexo: number, idPersonaAsignacion: number) {
    this.infCtaSupervisionService.ConsultarAdjuntosPorAnexoInformeRevision(idAnexo, idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosAnexoInforme = result.adjuntos;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  validarAnexosPazySalvo() {
    if (this.anexosInformeRevision != null) {
      this.anexosInformeRevision.forEach(anx => {
        if (anx.idTipoAnexo == EnumCtoTipoAnexoInforme.GADF_F_014 || anx.idTipoAnexo == EnumCtoTipoAnexoInforme.GADF_F_081) {
          this.anexoInforme014_081 = anx;
        }
      });
    }
  }

  /**
   * Lista las asignaciones asociadas al contratista
   */
  cargarInventarioAsignadoPersona() {
    forkJoin(
      this.inventarioEquiposService.srvConsultarBienesAniYPersonalPorPersona(this.lineaBase.informeContratista.idPersona, EnumInvInventario.INV_BIEN_TIPO_PROCEDENCIA_ANI)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.bienesAniPersonal = result.bienesAniPersonal;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO.toString(), life: 10000 });
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      }
    );
  }

}