import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aeropuertos-home',
  templateUrl: './aeropuertos-home.component.html',
  styleUrls: ['./aeropuertos-home.component.sass']
})
export class AeropuertosHomeComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: any, private route: Router) { }

  ngOnInit() {
    document.getElementById("nameseccion").innerHTML = "<span class='smallicon'><img src='assets/img/icn/aeropuerto_500-02-02.svg'/></span> Aeropuertos";
    var element = document.getElementById("aniscopio_body");
    element.classList.remove("home");
    element.className = "ani public logeo aeropuertos";
  }
  seguimientoProyectos() {
    var usuario = localStorage.getItem("nomUsu")
    if (usuario == null) {
      localStorage.setItem("backProj", "1")
    }
    sessionStorage.setItem('tipoProy','1')
    this.route.navigate(['aeropuertos/infGeneral']);
  }

  reportes() {
    this.route.navigate(['aeropuertos/reportesAero'])
  }


  //irAMapas(): void {
  // this.document.location = "https://sig.ani.gov.co/mapasinterno/";
  //}
}
