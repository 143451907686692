import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
@Injectable()
export class FuncionarioRadicacionRespuestaService {
    
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo que carga la informacion de la consulta de los conceptos que tienen o no respuestas
     * @param idSolicitudInvias 
     */
    public getConsultaListaRespuestasSolicitud(idSolicitudInvias) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/tramite/consulta/respuesta/' + idSolicitudInvias ) 
        .pipe(
        retry(1));
    }

    /**
     * Metodo que carga la informacion de la consulta de los conceptos que tienen o no respuestas, seleccionados
     * @param idSolicitudInvias 
     */
    public getConsultaListaRespuestasSolicitudSeleccionados(idSolicitudInvias) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/tramite/consulta/respuesta/seleccionados/' + idSolicitudInvias ) 
        .pipe(
        retry(1));
    }
   
    
    /**
     * Metodo que carga la informacion de la consulta del radicado principal
     * @param numeroRadicado 
     */
    public getConsultaRadicado(numeroRadicado) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/consultaRadicado/' + numeroRadicado ) 
        .pipe(
        retry(1));
    }

    
     /**
     * Metodo que guarda la informacion de la respuesta    
     * @param objeto 
     */
    public guardarRespuestaConceptos(objeto){
        return this.http.post(this.END_POINT + '/CargaExtrapesada/invias/respuesta/conceptos/guardar', objeto)
        .pipe(
            retry(1));
    }

     /**
    * Metodo que carga realiza la validacion de una finalizacion de un concepto
    * @param numeroRadicado 
    */
      public getConsultaValidarFinalizacion(idSolicitudInvias) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/invias/validar/finalizacion/' + idSolicitudInvias ) 
        .pipe(
            retry(1));
    }

    

}
