import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PjePeajePorProyecto } from '../trafico-peajes/PjePeajePorProyecto';
import { TraficoPeajesCargaService } from '../trafico-peajes/trafico-peajes-carga/trafico-peajes-carga.service';
import { RecaudoPeajesService } from './recaudo-peajes.service';
import { forkJoin } from 'rxjs';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { PjeEncabezadoPeriodoDTO } from './PjeEncabezadoPeriodo';
import { DetalleRecaudoPeajesService } from './detalle-recaudo-peajes/detalle-recaudo-peajes.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { EnumPjeConstantes } from '../peajes-constantes';

@Component({
    selector: 'app-recaudo-peajes',
    templateUrl: './recaudo-peajes.component.html',
    styleUrls: ['./recaudo-peajes.component.sass']
})

export class RecaudoPeajesComponent implements OnInit {

    idProyecto: number;
    proyecto: any;
    nombreProyecto: string;
    blockedDocument = false;
    // variables para cargar lista de peajes
    filtroPeajes: any[];
    peajesPorProyecto: any[];
    peajeSeleccionado: PjePeajePorProyecto;
    fechaActual: Date;

    // variables configuración
    periodosPeaje: any = [];
    displayedColumns: any[];

    // variables anular
    visibleConfirmarAnular = false;
    idPeriodoAnular: number;
    encabezadoGet: any = [];
    encabezadoAnular: PjeEncabezadoPeriodoDTO;

    // variables aprobar tráfico sin recaudo
    visibleConfirmarAprobarTraficoSinRecaudo = false;
    idPeriodoAAprobar: number;


    constructor(private router: Router, private route: ActivatedRoute, private messageService: MessageService, private traficoPeajesCargaService: TraficoPeajesCargaService,
        private recaudoPeajesService: RecaudoPeajesService, private infoService: InformacionGeneralService, private detalleRecaudoService: DetalleRecaudoPeajesService,
        private principalComponent: PrincipalComponent) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.idProyecto = params.id;
        });

        this.cargarProyectoCarretero(this.idProyecto);

        this.displayedColumns = [
            { field: 'id', header: 'IdPeriodo' },
            { field: 'idPeaje', header: 'IdPeaje' },
            { field: 'peaje', header: 'Peaje' },
            { field: 'periodo', header: 'Periodo' },
            { field: 'publicado', header: 'Publicado' },
            { field: 'fechaDesde', header: 'Fecha Desde' },
            { field: 'fechaHasta', header: 'Fecha Hasta' },
            { field: 'estado', header: 'Estado' },
            { field: 'autorizaTraficoSinRecaudo', header: 'Autoriza Tráfico Sin Recaudo' }
        ];

        this.fechaActual = new Date();
        this.fechaActual.setHours(23);
        this.fechaActual.setMinutes(59);
        this.fechaActual.setSeconds(59);

    }

    cargarPeajes(event) {

        this.filtroPeajes = [];
        return this.traficoPeajesCargaService.getPeajes(this.idProyecto).subscribe(
            result => {
                if (result != null && result.respuestaServicio != null) {
                    if (result.respuestaServicio.codigoSalida !== 0) {
                        this.peajesPorProyecto = result.peajes;
                    }
                }
            },
            error => {
                this.principalComponent.cargarErrorServicio(error);
            },
            () => {
                this.blockedDocument = false;
            }
        );
    }

    cargarProyectoCarretero(id: number) {
        this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
            this.proyecto = data;
            this.nombreProyecto = this.proyecto.nombre;
        }, error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
    }

    cargarPeriodos(event) {
        // Bloquear pantalla
        this.blockedDocument = true;
        forkJoin(
            this.recaudoPeajesService.cargarPeriodos(this.peajeSeleccionado.idPeaje)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.periodosPeaje = result.periodosPeaje;
                    this.periodosPeaje.forEach(element => {
                        element.fechaHasta = new Date(element.fechaHasta);
                    });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    irDetallePeriodo(idPeriodo: number) {
        this.router.navigate([this.router.url + '/detalle-recaudo-peajes', this.idProyecto, idPeriodo]);
    }

    anularRecaudo(idPeriodo: number) {
        this.idPeriodoAnular = idPeriodo;
        // this.cargarEncabezadoPeriodo();

        // Bloquear pantalla
        this.blockedDocument = true;
        this.encabezadoAnular = new PjeEncabezadoPeriodoDTO();
        forkJoin(
            this.detalleRecaudoService.cargaEncabezadoPeriodo(this.idPeriodoAnular)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.encabezadoGet = result.encabezadoPeriodo;
                    this.encabezadoAnular = this.encabezadoGet;
                    this.visibleConfirmarAnular = true;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    apruebaRecaudoSinTrafico(idPeriodo: number) {
        this.visibleConfirmarAprobarTraficoSinRecaudo = true;
        this.idPeriodoAAprobar = idPeriodo;
    }

    cancelarRecaudoSinTrafico() {
        this.visibleConfirmarAprobarTraficoSinRecaudo = false;
    }

    aceptarRecaudoSinTrafico() {
        this.visibleConfirmarAprobarTraficoSinRecaudo = false;
        // Bloquear pantalla
        this.blockedDocument = true;
        forkJoin(
            this.detalleRecaudoService.apruebaTraficoSinRecaudo(this.idPeriodoAAprobar)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Tráfico sin recaudo aprobado Exitosamente', life: 10000 });

                    let evento: any;
                    this.cargarPeriodos(evento);
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    cancelarAnular() {
        this.visibleConfirmarAnular = false;
    }

    aceptarAnular() {
        this.encabezadoAnular.idEstado = EnumPjeConstantes.PJE_ESTADO_REPORTE_TRAFICO_ANULADO;
        this.visibleConfirmarAnular = false;

        // Bloquear pantalla
        this.blockedDocument = true;
        forkJoin(
            this.detalleRecaudoService.editaEncabezadoPeriodo(this.encabezadoAnular)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Recaudo Anulado Exitosamente', life: 10000 });
                    let evento: any;
                    this.cargarPeriodos(evento);
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }



    get EnumPjeConstantes() {
        return EnumPjeConstantes;
    }

}
