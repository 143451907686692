import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicioRpt } from '../../../parametricas/rpt-respuesta-operacion';


@Injectable({
  providedIn: 'root'
})
export class ContratacionContratoPublicadoService {
  public END_POINT = '';
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  AnularPublicacionContrato(idContrato: number): Observable<TrnRespuestaServicioRpt> {
    return this.http.get<TrnRespuestaServicioRpt>(this.END_POINT + '/CtoContratoPublicado/AnularPublicacionContrato/' + idContrato)
    .pipe(
      map(obj => {
        return obj;
      }),
      retry(1));
  }
}
