import { Injectable } from '@angular/core';
import { ConfigService } from '../../../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TribunalesDetalleService {

  public END_POINT = '';
  public tribunales;


    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public getContrato(id) {
    return this.http.get(this.END_POINT + '/juridicotribunal/contratos/' + id)
      .pipe(
        retry(1));
  }

  public getContratistaConvocado(id) {
    return this.http.get(this.END_POINT + '/juridicotribunal/contratista/convocado/' + id)
      .pipe(
        retry(1));
  }

  public getContratistaConvocante(id) {
    return this.http.get(this.END_POINT + '/juridicotribunal/contratista/convocante/' + id)
      .pipe(
        retry(1));
  }

  public guardarDetalleTribunal(data) {
    return this.http.put(this.END_POINT + '/juridicotribunal/' , data)
      .pipe(
        retry(1));
  }

  public guardarNuevoTribunal(data) {
    return this.http.post(this.END_POINT + '/juridicotribunal/', data)
      .pipe(
        retry(1));
  }

  public getproyectoTribunales(id: number) {
    return this.http.get(this.END_POINT + '/juridicotribunal/' + id )
      .pipe(
        retry(1));
  }

}
