import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ferreo-home',
  templateUrl: './ferreo-home.component.html',
  styleUrls: ['./ferreo-home.component.sass']
})
export class FerreoHomeComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: any, private route: Router) { }

  ngOnInit() {
    document.getElementById("nameseccion").innerHTML = "<span class='smallicon'><img src='assets/img/icn/frrocaril_500-04.svg'/></span> Ferrocarriles";
    var element = document.getElementById("aniscopio_body");
    element.classList.remove("home");
    element.className = "ani public logeo ferreo";
  }
  seguimientoProyectos() {
    var usuario = localStorage.getItem("nomUsu")
    if (usuario == null) {
      localStorage.setItem("backProj", "1")
    }
    sessionStorage.setItem('tipoProy','4')
    this.route.navigate(['ferrocarriles/infGeneral']);
  }

  reportes() {
    this.route.navigate(['ferrocarriles/reportesFerro'])
  }
  
}
