import { Component, OnInit } from '@angular/core';
import { YoutubeProyectoService, } from './youtube-proyecto.service';
import { PrincipalComponent } from '../../home/principal/principal.component';
import { MessageService } from 'primeng/api';
import { EnumTipoProyecto } from '../../comun/enum-tipo-proyecto'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 's-youtube-proyecto',
    templateUrl: './youtube-proyecto.component.html',
    styleUrls: ['./youtube-proyecto.component.css'],
    providers: [YoutubeProyectoService]
})
export class YoutubeProyectoComponent implements OnInit {

    displayedColumns1;
    // Inicializaciones de Variables
    loading : boolean;
    proyectos: any = [];
    proyectosTemp: any = [];
    urlSafe: SafeResourceUrl;

    constructor(private _service: YoutubeProyectoService, private messageService: MessageService, 
      private principalComponent: PrincipalComponent, public sanitizer: DomSanitizer,
      private router: Router) { }

    ngOnInit() {
        this.loading = true;
        this.displayedColumns1 = [{ field: 'nombre', header: 'Nombre Proyecto' }];
        this.cargarProtectosYoutube();

    }


  /**
   * Metodo que carga los proyectos
   */  
  cargarProtectos() {
    return this._service.getProyectos(EnumTipoProyecto.Carretero).subscribe((data: {}) => {
      this.proyectos = data;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }


/**
   * Metodo que carga los proyectos
   */  
  cargarProtectosYoutube() {
    return this._service.getProyectosYoutube().subscribe((data: {}) => {
      this.proyectos = data;
      this.proyectosTemp = data;
      this.loading = false;
    }, error => {
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  /**
   * metodo para transformar la url
   * @param objeto 
   */
  VideoURL(objeto) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+objeto);
     return this.urlSafe ;
  }

  /**
   * Metodo que pasa al detalle de los videos
   * @param objeto 
   */
  verVideos(objeto){
    sessionStorage.setItem('videoProyecto', JSON.stringify(objeto));
    this.router.navigate(['/youtube/video-proyecto/']);
  }

  onChange(event) {
    this.proyectos = [];
    if(event!= null && event!= ""){
      this.proyectosTemp.forEach(element => {
        if (element.infoPlayList.proyecto.toLowerCase().includes(event.toLowerCase())) {
          this.proyectos.push(element);
        }
      });
    }else{
      this.proyectos = this.proyectosTemp;
    }
    
  }

 


}
