import { TrnRespuestaServicio } from '../../parametricas/trn-respuesta-servicio';


export class PjeEncabezadoPeriodoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public encabezadoPeriodo?: PjeEncabezadoPeriodoDTO[],
    ) { }
}

export class PjeEncabezadoPeriodoDTO {//TRANSACCCIONAL
    constructor(

        public id?: number,//Id del encabezado
        public idPeaje?: number,
        public peaje?: string,
        public fechaDesde?: Date,
        public fechaHasta?: Date,
        public idEstado?: number,
        public estado?: string,
        public recaudoSobrante?: number,
        public recaudoFsv?: number,
        public traficoPoliciaCarreteras?: number,
        public diasPeriodo?: number,
        public autorizaTraficoSinRecaudo?: boolean,
        public tpdconExentos?: number,
        public tpdsinExentos?: number,
        public totalRecaudoReal?: number,
        public ingresoNeto?: number,
        public idProyecto?: number

    ) { }
}



