import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ProyectoDTO } from '../../../modulo-planeacion-proyecto/planeacion-proyecto.dto';
import { ComponentesComunesService } from '../../../componentes-comunes/componentes-comunes.service';


@Component({
    selector: 'app-consultar-mapa-informacion-general',
    templateUrl: './informacion-general-peajes-consultar-mapa.html',
    styleUrls: ['./informacion-general-peajes-consultar-mapa.sass']
})
export class ConsultarMapaInformacionGeneralComponent implements OnInit {

    idProyecto: number;
    proyectoSeleccionado: ProyectoDTO;

    idPeaje: number;

    rutaIframe: string;

    constructor(        
        private router: Router,
        private ruta: ActivatedRoute,
        private componentesComunesService: ComponentesComunesService
    ) {

    }

    ngOnInit() {
        // Obtener parametros
        const idProyecto = 'idProyecto';
        const idPeaje = 'idPeaje';
        this.ruta.params.subscribe((params) => {
            this.idProyecto = params[idProyecto];
            this.idPeaje = params[idPeaje];            
            this.rutaIframe = 'https://animapas.maps.arcgis.com/apps/webappviewer/index.html?id=1c3ac1b82153411c9cac46b9e77f7347&query=OpenDataICDE_9931%2CID_PEA_IRE%2C' +
                this.idPeaje;
        });

        this.inicializar();
    }

    inicializar() {
        if (this.idProyecto != 0) {
            forkJoin(
                this.componentesComunesService.obtenerProyecto(this.idProyecto)
            ).subscribe(
                ([
                    proyectoData
                ]) => {
                    this.proyectoSeleccionado = proyectoData;
                },
                (e) => { }
            );
        }
    }

    bntVolver() {

        if (this.idProyecto == 0) {
            this.router.navigate(['/menu-peajes-externos/' + this.idProyecto + '/informacion-general-peajes/', this.idProyecto]);
        }
        else {
            this.router.navigate(['/carreteros/infGeneral/proyectoHome/' + this.idProyecto + '/menu-peajes/' + this.idProyecto + '/informacion-general-peajes/', this.idProyecto]);
        }


    }

}
