import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FerreoFichaProyectoDrupalService } from './ferreo-ficha-proyecto-drupal.service';
import { Data } from '../ferreo-ficha-proyecto-drupal/objeto';
import { Imagenes } from '../ferreo-ficha-proyecto-drupal/objeto';
import { Banner1s } from  '../ferreo-ficha-proyecto-drupal/objeto';
import { Documentos } from '../ferreo-ficha-proyecto-drupal/objeto';
import { Field_accionistas_contratista_s } from '../ferreo-ficha-proyecto-drupal/objeto';
import { Field_accionistas_interventores_s } from '../ferreo-ficha-proyecto-drupal/objeto';
import { Field_accionistas_interventordos_s } from '../ferreo-ficha-proyecto-drupal/objeto';


@Component({
    selector: 's-ferreo-ficha-proyecto-drupal',
    templateUrl: './ferreo-ficha-proyecto-drupal.component.html'
})
export class FerreoFichaProyectoDrupalComponent implements OnInit {

  data: Data = {};
  imagen: Imagenes = {};
  documento: Documentos = {};
  banner1: Banner1s = {};
  loading: boolean;
  imagenes: any = [];
  documentos: any = [];
  datos: any = [];
  banner1s: any = [];
  field_accionistas_contratista: Field_accionistas_contratista_s = {};
  field_accionistas_contratista_s: any = [];
  field_accionistas_interventores: Field_accionistas_interventores_s = {};
  field_accionistas_interventores_s: any = [];
  field_accionistas_interventordos: Field_accionistas_interventordos_s = {};
  field_accionistas_interventordos_s: any = [];

  constructor(private info: FerreoFichaProyectoDrupalService, private route: Router,
    private ruta: ActivatedRoute) { }

  ngOnInit() {
    this.loading = true;
    this.cargarInformacion();
  }

    morebtn(id){
    document.getElementsByClassName('more')[0].className = 'none';
    document.getElementById('more'+id).classList.remove("none");
    document.getElementById('botonver'+id).className = 'none';
    document.getElementsByClassName('botonmore'+id)[0].classList.remove("none");
    document.getElementById('more'+id).style.display = 'block';
  
  }
  cerrarbtn(id){
    document.getElementById('more'+id).className = 'none' ;
    //document.getElementsByClassName('botonmore'+id)[0].className = 'none';
    document.getElementById('botonver'+id).classList.remove("none");
    document.getElementById('botonver'+id).style.display = 'block';
    
    document.getElementsByClassName('botonmore'+id)[0].classList.add("none");
    document.getElementById('more'+id).classList.add("more");
    document.getElementById('botonver'+id).className = 'boton ';
    document.getElementById('more'+id).style.display = 'none';
  }
  cargarInformacion() {
    this.info.getInfoFichaProyectoFerreoDrupal().subscribe((data: {}) => {
      this.responseData(data);
      this.loading = false;
    });
  }


    

  /**
   * metodo que transforma el objeto
   * @param payload 
   */
  responseData(payload) {

    payload.data.forEach(element => {
      this.data = new Data();
      var imagendos = '';
      if(element.banner1s == null){
        imagendos = '';
      }else{
        imagendos = element.banner1s;
      }
      if (element.categoria == 295) {
        this.data.id = element.id;
        this.data.objeto = element.objeto['value'];
        this.data.label = element.label;
        this.data.value = element.value;
        this.data.zona = element.zona;
        this.data.categoria = element.categoria;
        this.data.banner1s = imagendos['filename'];
        this.data.numero_de_contrato = element.numero_de_contrato;
        this.data.estado_proyecto = element.estado_proyecto;
        this.data.contratista = element.contratista;
        this.data.url_contratista = element.url_contratista;
        this.data.field_municipio = element.field_municipio;
        this.data.fecha_publicacion = new Date(element.fecha_publicacion);
        this.data.fecha_publicacion_str = new Date(element.fecha_publicacion).toLocaleDateString();
        this.data.field_fecha_suscripcion_del_cont = (element.field_fecha_suscripcion_del_cont!= null && element.field_fecha_suscripcion_del_cont!= "null")? element.field_fecha_suscripcion_del_cont+"000": null;
        this.data.field_fecha_inicio_proyecto = (element.field_fecha_inicio_proyecto!= null && element.field_fecha_inicio_proyecto!= "null")? element.field_fecha_inicio_proyecto+"000": null;
        this.data.field_fecha_fin_proyecto = (element.field_fecha_fin_proyecto!= null && element.field_fecha_fin_proyecto!= "null")? element.field_fecha_fin_proyecto+"000": null;
        this.data.field_interventor_proyecto = element.field_interventor_proyecto;
        this.data.field_url_interventor_proyecto = element.field_url_interventor_proyecto;
        this.data.field_tipo_de_carga = element.field_tipo_de_carga;
        this.data.field_tipo_de_permiso = element.field_tipo_de_permiso;
        this.data.field_valor_proyecto = element.field_valor_proyecto;
        this.data.field_interventor_proyecto_2 = element.field_interventor_proyecto_2;


        this.imagenes = [];
        element.imagenes.forEach(ima => {
          this.imagen = new Imagenes();
          this.imagen.fid = ima.fid;
          this.imagen.uid = ima.uid;
          this.imagen.filename = ima.filename;
          this.imagen.filemime = ima.filemime;
          this.imagenes.push(this.imagen);
        });

        this.data.imagenes = this.imagenes;

        this.documentos = [];
        element.documentos.forEach(doc => {
          this.documento = new Documentos();
          this.documento.fid = doc.fid;
          this.documento.uid = doc.uid;
          this.documento.filename = doc.filename;
          this.documento.filemime = doc.filemime;
          this.documentos.push(this.documento);
        });
        this.data.documentos = this.documentos;

        this.field_accionistas_contratista_s = [];
        element.field_accionistas_contratista_s.forEach(doc => {
          this.field_accionistas_contratista = new Field_accionistas_contratista_s();
          this.field_accionistas_contratista.field_contratista_listado = doc.field_contratista_listado;
          this.field_accionistas_contratista.field_porcentaje_participacion = doc.field_porcentaje_participacion;
          this.field_accionistas_contratista.field_nacionalidad = doc.field_nacionalidad;
          this.field_accionistas_contratista_s.push(this.field_accionistas_contratista);
        });
        this.data.field_accionistas_contratista_s = this.field_accionistas_contratista_s;

        this.field_accionistas_interventores_s = [];
        element.field_accionistas_interventores_s.forEach(doc => {
          this.field_accionistas_interventores = new Field_accionistas_interventores_s();
          this.field_accionistas_interventores.field_interventor_listado = doc.field_interventor_listado;
          this.field_accionistas_interventores.field_porcentaje_participacion = doc.field_porcentaje_participacion;
          this.field_accionistas_interventores.field_nacionalidad = doc.field_nacionalidad;
          this.field_accionistas_interventores_s.push(this.field_accionistas_interventores);
        });
        this.data.field_accionistas_interventores_s = this.field_accionistas_interventores_s;

        this.field_accionistas_interventordos_s = [];
        element.field_accionistas_interventordos_s.forEach(doc => {
          this.field_accionistas_interventordos = new Field_accionistas_interventordos_s();
          this.field_accionistas_interventordos.field_contratista_interventor = doc.field_contratista_interventor;
          this.field_accionistas_interventordos.field_porcentaje_participacion = doc.field_porcentaje_participacion;
          this.field_accionistas_interventordos.field_nacionalidad = doc.field_nacionalidad;
          this.field_accionistas_interventordos_s.push(this.field_accionistas_interventordos);
        });
        this.data.field_accionistas_interventordos_s = this.field_accionistas_interventordos_s;

        this.datos.push(this.data);
      }

    });

  }

}
