import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-covid-reportes',
  templateUrl: './covid-reportes.component.html',
  styleUrls: []
})
export class CovidReportesComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  reporteSintomas() {
    this.router.navigate([this.router.url + '/covidSintomas']);
  }

  reporteDepartamento() {
    this.router.navigate([this.router.url + '/covidDepartamento']);
  }
  reporteBI() {
    this.router.navigate([this.router.url + '/covidTablero']);
  }
}
