import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';

export class PjeMaTarifaRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public tarifas?: PjeMaTarifa[],
    ) { }
}

export class PjeMaTarifa {
    constructor(
        public id?: string,
        public descripcion?: string,
        public descripcionCompleta?: string
    ) { }
}
