import { Component, OnInit, } from '@angular/core';
import { ConcesionarioInviasPrincipalListService, } from './concesionario-invias-principal-list.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneral } from './informacionGeneral';


@Component({
    selector: 'app-concesionario-invias-principal-list.component',
    templateUrl: './concesionario-invias-principal-list.component.html',
    styleUrls: ['./concesionario-invias-principal-list.component.css'],
	providers: [ConcesionarioInviasPrincipalListService]
})
export class ConcesionarioInviasPrincipalListComponent implements OnInit {

  loading: boolean;
  visibleDialogoRespuesta: boolean;
  displayedColumns1;
  idProyecto: number;
  volverHomeMenuProyectos: any;
  listaTramites: any = [];
  temporal: any; 
  informacionGeneral: InformacionGeneral = {};


  constructor(private _service: ConcesionarioInviasPrincipalListService,
      private router: Router,
      private ruta: ActivatedRoute,
      private messageService: MessageService,
      private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.loading = true;
    this.ruta.params.subscribe(params => {
			this.idProyecto = params.id;
			localStorage.setItem('idP', String(this.idProyecto));
		});
    this.displayedColumns1 = [
        { field: 'idTramite', header: 'N° Trámite' },
        { field: 'numeroRadicado', header: 'N° Radicado ANI de Salida a Concesionario' },
        { field: 'fechaRadicadoStr', header: 'Fecha Radicado de Salida a Concesionario' },
        { field: 'asunto', header: 'Asunto de Salida' },
        { field: 'estado', header: 'Estado' },
        { field: 'rutaAdjunto', header: 'Solicitud de Concepto' }
      ];
    
    this.visibleDialogoRespuesta = false;

    this._service.getConsultaListado(this.idProyecto).subscribe((data: {}) => {
      this.temporal = data;
      if(this.temporal != undefined && this.temporal != null 
          && this.temporal.solicitudes != undefined && this.temporal.solicitudes != null
          && this.temporal.solicitudes.length > 0 ){
              this.listaTramites = this.temporal.solicitudes;
              this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: "Se cargo la información.",
                  life: 10000
                });
      }else{
          this.messageService.add({
              severity: 'warn',
              summary: 'Advertencia',
              detail: "El sistema no encontró información.",
              life: 10000
            });
      }            
      this.loading = false;
    }, (error)=>{
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  /**
   * Metodo que oculta el modal de la respuesta
   */
  cancelarRespuesta(){
    this.visibleDialogoRespuesta = false;
  }

  /**
   * Metodo que devuelve la pagina
   */
  bntVolver() {    
    this.volverHomeMenuProyectos = localStorage.getItem('volverHomeMenuProyectos');
    this.router.navigate([this.volverHomeMenuProyectos]);
  }

  /**
   * metodo que carga el detalle
   * @param registro 
   */
  onResponse(registro){
    localStorage.setItem('idConcesionarioConcepto', registro.id );
    localStorage.setItem('idTramite', registro.idTramite  );   
    localStorage.setItem('radicadoRespuesta', registro.numeroRadicado  );
    localStorage.setItem('volverListado', this.router.url );

    this.router.navigate([this.router.url + '/crearRespuesta']);
  }

  /**
   * Metodo para ver la respuesta
   * @param registro 
   */
  onView(registro){
    this.informacionGeneral = {};    
    this.loading = true;
    this._service.getConsultaRespuesta(registro.id).subscribe((data: {}) => {
      this.temporal = data;
      this.informacionGeneral.idTramite = this.temporal.idTramite;
      this.informacionGeneral.asunto = this.temporal.asunto;
      this.informacionGeneral.fechaRadicado = this.temporal.fechaRadicado;
      this.informacionGeneral.fechaRadicadoStr = this.temporal.fechaRadicadoStr;
      this.informacionGeneral.numeroRadicado = this.temporal.numeroRadicado;
      this.informacionGeneral.rutaAdjunto = this.temporal.rutaAdjunto;
      this.informacionGeneral.adjunto = this.temporal.rutaAdjunto;
      this.visibleDialogoRespuesta = true;
      this.loading = false;
    }, (error)=>{
      this.loading = false;
      this.visibleDialogoRespuesta = false;
      this.principalComponent.cargarErrorServicio(error);
    });    
  }


  /**
   * Metod que realiza el descargue del archivo del radicado
   * @param objeto 
   */
  onDescargarAdjunto(objeto){
    if(objeto.adjunto != null){
      window.open(objeto.adjunto);
    }else{
      if(objeto.rutaAdjunto != null){
        window.open(objeto.rutaAdjunto);
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El sistema no encontró el archivo a descargar",
          life: 10000
        });
      }
    }
  }


}
