import { Injectable } from '@angular/core';
import { Observable, throwError, ObservedValueOf } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Generacion } from './generacion';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProyectoCarretero } from './proyecto-carretero';
import { EtapaProyecto } from './etapa-proyecto';
import { environment } from 'src/environments/environment';
import { ProyectoEtapa } from './proyecto-etapa';

@Injectable({
  providedIn: 'root'
})
export class ModuloJuridicoService {

  constructor(private http: HttpClient) {
  }

  public getGeneraciones(): Observable<Generacion> {
    return this.http.get<Generacion>(environment.baseUrl + '/Generaciones')
      .pipe(
        retry(1)
      );
  }

  public getOlas(): Observable<Generacion> {
    return this.http.get<Generacion>(environment.baseUrl + '/Olas')
      .pipe(
        retry(1)
      );
  }

  public getProyectosCarreteros(): Observable<ProyectoCarretero> {
    return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectoscarreteros')
      .pipe(retry(1));
  }

  public getProyectoCarretero(id: number): Observable<ProyectoCarretero> {
    return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectoscarreteros/' + id)
      .pipe(retry(1));
  }

  public getEtapasProyecto(): Observable<EtapaProyecto> {
    return this.http.get<EtapaProyecto>(environment.baseUrl + '/etapasproyecto')
      .pipe(retry(1));
  }

  public setProyectoCarretero(proyecto: ProyectoCarretero) {
    if (proyecto.idProyecto > 0) {
      return this.http.put<ProyectoCarretero>(environment.baseUrl + '/proyectoscarreteros/' + proyecto.idProyecto, JSON.stringify(proyecto), this.httpOptions)
       
    } else {
      return this.http.post<ProyectoCarretero>(environment.baseUrl + '/proyectoscarreteros', JSON.stringify(proyecto), this.httpOptions)
    }
  }

  public setProyectoEtapa(proyecto: ProyectoEtapa): any {
    if (proyecto.idProyectoEtapa > 0) {
      return this.http.put<ProyectoEtapa>(environment.baseUrl + '/ProyectoEtapas/' + proyecto.idProyectoEtapa, JSON.stringify(proyecto), this.httpOptions)
        
    } else {
      return this.http.post<ProyectoEtapa>(environment.baseUrl + '/ProyectoEtapas', JSON.stringify(proyecto), this.httpOptions)

    }
  }

  public deleteProyectoEtapa(idProyectoEtapa: number):any{
    return this.http.delete<ProyectoEtapa>(environment.baseUrl + '/ProyectoEtapas/' + idProyectoEtapa)
  }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
}
