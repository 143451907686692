import { Injectable, } from '@angular/core';
import { ConfigService } from '../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, retry } from 'rxjs/operators';
import { ProyectoCarretero } from './proyecto-carretero';
import { EnumTipoProyecto } from '../../comun/enum-tipo-proyecto';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class YoutubePlaylistService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
        
    private API_URL = 'https://www.googleapis.com/youtube/v3/search';
    private API_TOKEN = 'AIzaSyBsUN92ivGA20TZu9zyKbVPDcaw6fSHa2A';
    private channel = 'UC_x5XG1OV2P6uZZ5FSM9Ttw'; //Canal Rodrigo
    private maxResults = '10';


    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
      }

    /**
     * Metodo que carga la info del radicado
     */
     public getListVideosCanal(query: string) {
        const url = `${this.API_URL}?key=${this.API_TOKEN}&channelId=${this.channel}&order=date&part=snippet&type=video&maxResults=${this.maxResults}`;
        return this.http.get(url)
          .pipe(
            map((response: any) => response.items)
          );
    }

    /**
     * Metodo que arga la lista de la info de la tabla vdoVideo
     */
    public getListado() {
      return this.http.get(environment.baseUrl + '/vdoVideo/list/playlist')
        .pipe(retry(1));
    }

    
    /**
     * Metodo que arga la lista de la info de la tabla vdoVideo
     */
    public deleteVideo(id) {
      return this.http.delete(environment.baseUrl + '/vdoVideo/playlist/'+id)
        .pipe(retry(1));
    }

    /**
     * Metodo que guarda o actualiza el video
     * @param data 
     */
    public guardarVideo(data) {
      return this.http.post(this.END_POINT + '/vdoVideo/playlist' , data)
        .pipe(
          retry(1));
    }

    /**
     * metodo qye carga los proyectos
     * @param tipoProyecto 
     */
    public getProyectos(tipoProyecto: EnumTipoProyecto): Observable<ProyectoCarretero> {
      return this.http.get<ProyectoCarretero>(environment.baseUrl + '/proyectos/youtube/' + tipoProyecto)
        .pipe(retry(1));
    }

     /**
     * Metodo que carga la lista de la info de todas las listas
     */
    public getListadoPlayList() {
      return this.http.get(environment.baseUrl + '/vdoVideo/list/playlistAll')
        .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de la info de todas las listas
     */
    public getListadoItemsxPlayList(idPlaylist) {
      return this.http.get(environment.baseUrl + '/vdoVideo/playlist/'+idPlaylist)
        .pipe(retry(1));
    }

    
    /**
     * Metodo que carga el pbjeto de video
     */
     public getVideoId(id) {
      return this.http.get(environment.baseUrl + '/vdoVideo/video/'+id)
        .pipe(retry(1));
    }
  
    /**
     * Metodo que carga el avance y la fecha de corte
     */
     public getAvance(idProyecto) {
      return this.http.get(environment.baseUrl + '/vdoVideo/avanceProyecto/'+idProyecto)
        .pipe(retry(1));
    }

    
    
}
