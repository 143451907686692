import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { PublicProyectos5GService } from "../public-proyectos5g.service";
import { ProyectoEstructuracion } from "src/app/reportes/publicos/proyectoEstructuracion";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import * as Highcharts from "highcharts";

@Component({
  selector: "app-public-proyectos5g-ficha-ola1",
  templateUrl: "./public-proyectos5g-ficha-ola1.component.html",
  styleUrls: ["./public-proyectos5g-ficha-ola1.component.sass"],
})
export class PublicProyectos5gFichaOla1Component implements OnInit {
  datosCarretero: any;
  datosAeropuerto: any;
  datosFerreo: any;
  datosFluvial: any;
  opcionGrafica: any;
  valorTotal: number = 0;
  valorTotalFormato: string;
  blockedDocument = false;

  informacionCarretero: ProyectoEstructuracion[] = [];
  informacionAeropuerto: ProyectoEstructuracion[] = [];
  informacionFerreo: ProyectoEstructuracion[] = [];
  informacionFluvial: ProyectoEstructuracion[] = [];

  optionschartCarretero: any;
  optionschartAeropuerto: any;
  optionschartFerreo: any;
  optionschartFluvial: any;
  //formato moneda en billones
  formatoMoneda(valor: number) {
    return valor
      ? "$" +
          (valor / 1000000000000)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
          " Bn"
      : "$0";
  }

  constructor(
    private publicProyectos5GService: PublicProyectos5GService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
    publicProyectos5GService.getDatosProyectos5GBD().subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.CargarDatos(dato.proyectos);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
        this.blockedDocument = false;
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /// Cargar datos
  CargarDatos(proyectos: ProyectoEstructuracion[]) {
    this.informacionCarretero = proyectos.filter(
      (p) => p.tipoModo == 2 && p.idOla == 1
    );
    this.informacionAeropuerto = proyectos.filter(
      (p) => p.tipoModo == 1 && p.idOla == 1
    );
    this.informacionFerreo = proyectos.filter(
      (p) => p.tipoModo == 4 && p.idOla == 1
    );
    this.informacionFluvial = proyectos.filter(
      (p) => p.tipoModo == 6 && p.idOla == 1
    );
    this.valorTotal = proyectos
      .filter((p) => p.idOla == 1)
      .reduce((sum, p) => sum + p.capex, 0);
    this.valorTotalFormato = this.formatoMoneda(this.valorTotal);

    //grafica Carretero
    this.datosCarretero = {
      labels: this.informacionCarretero.map((e) => e.nombre),
      datasets: [
        {
          label: "",
          backgroundColor: "#42A5F5",
          borderColor: "#1E88E5",
          data: this.informacionCarretero.map((e) => e.capex),
        },
      ],
    };

    //migración a grafica highchart
    this.optionschartCarretero = {
      chart: {
        zoomType: "x",
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: this.informacionCarretero.map((e) => e.nombre),
        type: "category",
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          format: "{value} Bn",
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        headerFormat:
          '<span style="font-size:10px"> <strong>Proyecto:</strong> {point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>$ {point.y} Bn</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: "right",
            color: "#438DF7",
            x: -10,
            format: "$ {point.y} Bn",
          },
          pointPadding: 0.1,
          groupPadding: 0,
        },
      },
      series: [
        { 

          color: "#42A5F5",
          data: this.informacionCarretero.map((e) => e.capex / 1000000000000),
        },
      ],
    };

    let chart = Highcharts.chart("chartCarretero", this.optionschartCarretero);

    //grafica Aeropuertos
    this.datosAeropuerto = {
      labels: this.informacionAeropuerto.map((e) => e.nombre),
      datasets: [
        {
          label: "",
          backgroundColor: "#9CCC65",
          borderColor: "#7CB342",
          data: this.informacionAeropuerto.map((e) => e.capex),
        },
      ],
    };

    //migración a grafica highchart
    this.optionschartAeropuerto = {
      chart: {
        zoomType: "x",
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: this.informacionAeropuerto.map((e) => e.nombre),
        type: "category",
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
        labels: {
          format: "{value} Bn",
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        headerFormat:
          '<span style="font-size:10px"> <strong>Proyecto:</strong> {point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>$ {point.y} Bn</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: "right",
            color: "#438DF7",
            x: -10,
            format: "$ {point.y} Bn",
          },
          pointPadding: 0.1,
          groupPadding: 0,
        },
      },
      series: [
        { 
          
          color: "#9CCC65",
          data: this.informacionAeropuerto.map((e) => e.capex / 1000000000000),
        },
      ],
    };

    let chart2 = Highcharts.chart("chartAeropuerto", this.optionschartAeropuerto);

    //grafica Ferreo
    this.datosFerreo = {
      labels: this.informacionFerreo.map((e) => e.nombre),
      datasets: [
        {
          label: "",
          backgroundColor: "#7D3C98",
          borderColor: "#7D3C98",
          data: this.informacionFerreo.map((e) => e.capex),
        },
      ],
    };


        //migración a grafica highchart
        this.optionschartFerreo = {
          chart: {
            zoomType: "x",
            type: "column",
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          xAxis: {
            categories: this.informacionFerreo.map((e) => e.nombre),
            type: "category",
          },
          yAxis: {
            min: 0,
            title: {
              text: "",
            },
            labels: {
              format: "{value} Bn",
            },
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:10px"> <strong>Proyecto:</strong> {point.key}</span><table>',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>$ {point.y} Bn</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                align: "right",
                color: "#438DF7",
                x: -10,
                format: "$ {point.y} Bn",
              },
              pointPadding: 0.1,
              groupPadding: 0,
            },
          },
          series: [
            { 
              
              color: "#7D3C98",
              data: this.informacionFerreo.map((e) => e.capex / 1000000000000),
            },
          ],
        };
    
        let chart3 = Highcharts.chart("chartFerreo", this.optionschartFerreo);

    //grafica Fluvial
    this.datosFluvial = {
      labels: this.informacionFluvial.map((e) => e.nombre),
      datasets: [
        {
          label: "",
          backgroundColor: "#ff6701",
          borderColor: "#ff6701",
          data: this.informacionFluvial.map((e) => e.capex),
        },
      ],
    };

    this.opcionGrafica = {
      scales: {
        yAxes: [
          {
            ticks: {
              //stepSize: 10,
              beginAtZero: true,
              callback: (label, index, labels) => {
                return this.formatoMoneda(label);
              },
            },
          },
        ],
      },
    };


    
        //migración a grafica highchart
        this.optionschartFluvial = {
          chart: {
            zoomType: "x",
            type: "column",
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          xAxis: {
            categories: this.informacionFluvial.map((e) => e.nombre),
            type: "category",
          },
          yAxis: {
            min: 0,
            title: {
              text: "",
            },
            labels: {
              format: "{value} Bn",
            },
          },
          legend: {
            enabled: false,
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:10px"> <strong>Proyecto:</strong> {point.key}</span><table>',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>$ {point.y} Bn</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                align: "right",
                color: "#438DF7",
                x: -10,
                format: "$ {point.y} Bn",
              },
              pointPadding: 0.1,
              groupPadding: 0,
            },
          },
          series: [
            { 
              
              color: "#ff6701",
              data: this.informacionFluvial.map((e) => e.capex / 1000000000000),
            },
          ],
        };
    
        let chart4 = Highcharts.chart("chartFluvial", this.optionschartFluvial);
  }

  displayModal: boolean;
  displayModal2: boolean;
  displayModal3: boolean;
  displayModal4: boolean;
  showModalDialog() {
    this.displayModal = true;
  }
  showModalDialog2() {
    this.displayModal2 = true;
  }
  showModalDialog3() {
    this.displayModal3 = true;
  }
  showModalDialog4() {
    this.displayModal4 = true;
  }

  ngOnInit() {
    this.blockedDocument = true;
  }

  ///////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: _mensaje,
    });
  }

  lanzarMensajeError(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: _mensaje,
    });
  }

  lanzarMensajeWarning(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: _mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
