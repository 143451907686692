import { Component, OnInit } from '@angular/core';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { forkJoin, Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { CompensacionesAmbientalesService } from '../../../compensaciones-ambientales.service';
import { MessageService } from 'primeng/api';
import {
  AmbEjecucionDTO,
  AmbSeguimientoObligacionDTO,
  AmbSubCuentaDTO,
  AmbUbicacionCompensadaDTO,
} from '../../../compensaciones-ambientales.dto';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-gestionar-localizacion-avance',
  templateUrl: './gestionar-localizacion-avance.component.html',
  styleUrls: ['./gestionar-localizacion-avance.component.sass'],
})
export class GestionarLocalizacionAvanceComponent implements OnInit {
  subscription: Subscription;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  subCuenta: AmbSubCuentaDTO;

  idObligacion: number;
  idEjecucion: number;

  ejecucionSelected: AmbEjecucionDTO;

  ubicacionesCompensadasList: AmbUbicacionCompensadaDTO[];
  ubicacionCompensada: AmbUbicacionCompensadaDTO;

  dialogoUbicacionCompensada: boolean;
  dialogoUbicacionCompensadaEliminar: boolean;

  formUbicacionCompensada;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private compensacionesAmbientalesService: CompensacionesAmbientalesService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
    //  Crear formulario Ubicacion Compensada
    this.formUbicacionCompensada = formBuilder.group({
      idMunicipio: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.ubicacionCompensada = {};

    // Obtener el proyecto seleccionado
    const idParam = 'id';
    const idParamOblig = 'idOblig';
    const idParamEjecucion = 'idEjecucion';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.idObligacion = params[idParamOblig];
      this.idEjecucion = params[idParamEjecucion];
    });

    // Inicializar Listas
    this.listarUbicacionesCompensadas();
  }

  listarUbicacionesCompensadas() {
    console.log('this.idSeguimientoObligacion', this.idObligacion);
    console.log('this.idObligacionEjecucion', this.idEjecucion);

    // Inicializar listas
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.compensacionesAmbientalesService.srvObtenerValorSubcuentaPorProyecto(
        this.idProyecto
      ),
      this.compensacionesAmbientalesService.srvObtenerEjecucionDetallePorId(
        this.idObligacion,
        this.idEjecucion
      ),
      this.compensacionesAmbientalesService.srvListarUbicacionesCompensadasPorEjecucion(
        this.idEjecucion
      )
    ).subscribe(
      ([
        proyectoData,
        subCuentaData,
        ejecucionData,
        ubicacionesCompensadasData,
      ]) => {
        this.proyectoSeleccionado = proyectoData;
        this.subCuenta = subCuentaData.valorSubCuenta;
        this.ejecucionSelected = ejecucionData.obligacionEjecucion;
        // console.log('ejecucionSelected', this.ejecucionSelected);
        this.ubicacionesCompensadasList =
          ubicacionesCompensadasData.ubicacionesCompensadas;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });

  }

  btnAgregarUbicacion() {
    this.dialogoUbicacionCompensada = true;
    this.ubicacionCompensada = {};
    this.ubicacionCompensada.idObligacionEjecucion = this.idEjecucion;
  }

  btnEditarUbicacion(ubicacionSelected: AmbUbicacionCompensadaDTO) {
    this.dialogoUbicacionCompensada = true;
    this.ubicacionCompensada = ubicacionSelected;
  }

  btnEliminarUbicacion(ubicacionSelected: AmbUbicacionCompensadaDTO) {
    this.dialogoUbicacionCompensadaEliminar = true;
    this.ubicacionCompensada = ubicacionSelected;
  }

  outputSelectorMunicipio(departamentoObj) {
    const idMunicipioControl = this.formUbicacionCompensada.get('idMunicipio');
    if (departamentoObj.municipio != null) {
      idMunicipioControl.setValue(departamentoObj.municipio.id);
    }
  }

  /**
   * Guarda los cambios de la Ubicacion Compensada
   */
  submitGuardarUbicacionCompensada() {
    if (this.formUbicacionCompensada.valid) {
      this.ubicacionCompensada.idMunicipio = this.formUbicacionCompensada.value.idMunicipio;
      //  Completar el DTO a guardar
      if (this.ubicacionCompensada != null) {
        this.ubicacionCompensada.nombreMunicipio = null;
        this.ubicacionCompensada.idObligacionEjecucion = this.idEjecucion;
      }
      //  Guardar el DTO
      forkJoin(
        this.compensacionesAmbientalesService.srvGuardarUbicacionCompensadaPorEjecucion(
          this.ubicacionCompensada
        )
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            //  Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              //  Consultar nuevamente la lista
              this.listarUbicacionesCompensadas();
              //  Lanzar mensaje de exito
              this.messageService.add({
                severity: 'info',
                summary: 'Información',
                detail: result.respuestaServicio.mensajeSalida,
                life: 10000,
              });
              //  Limpiar Formulario
              this.formUbicacionCompensada.reset();
              this.formUbicacionCompensada.markAsPristine();
              this.formUbicacionCompensada.markAsUntouched();

              //  Cerrar el dialogo
              this.dialogoUbicacionCompensada = false;
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
          }
        }, error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => { }
      );
    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos');
    }
  }

  /**
   * Eliminar la Ubicacion Compensada
   */
  submitEliminarUbicacionCompensada() {
    forkJoin(
      this.compensacionesAmbientalesService.srvEliminarUbicacionCompensada(
        this.ubicacionCompensada
      )
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            //  Consultar nuevamente la lista
            this.listarUbicacionesCompensadas();
            //  Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            //  Cerrar el dialogo
            this.dialogoUbicacionCompensadaEliminar = false;
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        // this.blockedDocument = false;
      }
    );
  }

  bntVolver() {
    const ruta =
      'carreteros/infGeneral/proyectoHome/' +
      this.proyectoSeleccionado.id +
      '/compensaciones-ambientales/' +
      this.proyectoSeleccionado.id +
      '/seguimiento-compensaciones/' +
      this.proyectoSeleccionado.id +
      '/ejecuciones-obligacion/' +
      this.proyectoSeleccionado.id +
      '/' +
      this.idObligacion;
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
