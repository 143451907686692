import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EnumCmsConstantes } from 'src/app/comisiones/comisiones-constantes';
import { CmsModosDTO, CmsTiqueteDTO } from 'src/app/comisiones/comisiones.dto';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { forkJoin } from 'rxjs';
import { ComisionesService } from 'src/app/comisiones/comisiones.service';
import { CmsTiqueteAereoDTO, VwCmsTiqueteAereoDTO, CmsComisionDTO, CmsComisionLegalizacionDTO, VwCmsAnexoComisionDTO, CmsAprobacionDTO, CmsSolicitudCancelacionTiqueteDTO } from '../../../comisiones.dto';
import { EnumRegex, EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCmsTipoAnexo } from '../../../comisiones-constantes';
import { TrnPersona } from 'src/app/admin-personas/trn-persona';
import { PrsFuncionario } from 'src/app/admin-personas/prs-funcionario';

@Component({
  selector: 'app-crear-editar-tiquete-comision',
  templateUrl: './crear-editar-tiquete-comision.component.html',
  styleUrls: ['./crear-editar-tiquete-comision.component.sass']
})
export class CrearEditarTiqueteComisionComponent implements OnInit {

  //Informacion comision
  comision: CmsComisionDTO;
  persona: TrnPersona;
  detalleFuncionario: PrsFuncionario;
  legalizacion: CmsComisionLegalizacionDTO;
  anexosConsolidado: VwCmsAnexoComisionDTO[];
  habilitarLiquidacionLegalizacion = false;

  blockedDocument = true;
  idComision: number;
  idItinerario: number;
  itinerarioAereoList: VwCmsTiqueteAereoDTO[];
  observacionesList: VwCmsTiqueteAereoDTO[];
  dialogoCargarTiquete: boolean;
  dialogoCargarCompraTiquete: boolean;
  dialogoObservacionesTiquete: boolean;
  rutaCloudStorageTiquete: string;
  fechaMinima: Date;
  fechaMaxima: Date;

  adjuntosComponente: any = [];
  tiquetes: CmsTiqueteDTO[] = [];
  tiqueteSeleccionado: CmsTiqueteAereoDTO;
  tiquetesCancelados: CmsSolicitudCancelacionTiqueteDTO[] = [];
  tiquetesCanceladoSeleccionado: CmsSolicitudCancelacionTiqueteDTO;
  nombreTipoDocumento: string;
  cancelarItinerarioAereoDialog: boolean;
  formSolicitudCancelacionTiquete;
  observacionesCancelacion: string;
  observacionesReserva: string;
  archivos: any = []; //Los archivos que yo suba van a quedar en este arreglo

  formTiqueteReserva;
  formTiqueteCompra;



  constructor(formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService) {
    const regexMayorACero = "^[1-9][0-9]*$";
    // Crear formulario tiquete
    this.formTiqueteReserva = formBuilder.group({
      costoReserva: [null, [Validators.required, Validators.min(1), Validators.maxLength(14), Validators.pattern(regexMayorACero)]],
      observacionesReserva: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(1000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
      horaVuelo: [null, [Validators.required]]
    });

    // Crear formulario tiquete
    this.formTiqueteCompra = formBuilder.group({
      costoCompra: [null, [Validators.required, Validators.min(1), Validators.maxLength(14), Validators.pattern(regexMayorACero)]],
    });

    // Crear formulario solicitud cancelacion tiquete
    this.formSolicitudCancelacionTiquete = formBuilder.group({
      observacionesCancelacion: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(2000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });

  }

  ngOnInit() {
    this.comision = {};
    this.comision.viaticos = {};
    this.legalizacion = {};
    this.persona = {};
    this.detalleFuncionario = {};
    this.habilitarLiquidacionLegalizacion = false;

    // Obtener parametros url
    const idComisionParam = "idComision";

    this.ruta.params.subscribe(params => {
      this.idComision = Number(params[idComisionParam]);
    });

    this.obtenerComisionPorId(this.idComision);
    this.tiqueteSeleccionado = {};
  }

  obtenerComisionPorId(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarComisionPorId(idComision)
    ).subscribe(
      ([comisionData]) => {
        this.comision = comisionData.comisionDetalle;
        if (this.comision !== null) {
          if (this.comision.fechaSalida != null) {
            this.comision.fechaSalida = new Date(this.comision.fechaSalida);
          }
          if (this.comision.fechaRegreso != null) {
            this.comision.fechaRegreso = new Date(this.comision.fechaRegreso);
          }

          if (this.comision.esContratista) {
            this.persona = {};
            this.detalleFuncionario = {};
          } else {
            this.persona = {};
            this.detalleFuncionario = {};
            this.cargarPersona(this.comision.idPersona);
          }

          this.comision.objeto = this.comision.objeto.replace('<p>', '').replace('</p>', '');

          if (this.comision.valorTentativoTerrestre === 0) {
            this.comision.valorTentativoTerrestre = null;
          }
          if (this.comision.valorTentativoFluvial === 0) {
            this.comision.valorTentativoFluvial = null;
          }
          if (this.comision.valorTentativoAereo === 0) {
            this.comision.valorTentativoAereo = null;
          }

          if (this.comision.itinerarioAereoLista !== null && this.comision.itinerarioAereoLista.length > 0) {
            this.comision.itinerarioAereoLista.forEach(itAer => {
              if (itAer.fechaVuelo != null) {
                itAer.fechaVuelo = new Date(itAer.fechaVuelo);
              }
            });
          }


          //Actualizamos la variable de sumatoria de modos para visualizarlo en el Datatable
          this.actualizarSumatoriaModos();

          this.obtenerLegalizacionPorComision(idComision);
          this.cargarItinerarios();
          this.cargarItinerariosCancelados();
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarPersona(idPersona) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarFuncionarioComisionPorIdPersona(idPersona)
    ).subscribe(
      ([
        funcionarioData
      ]) => {
        this.persona.id = funcionarioData.configuracionFuncionario.id;
        this.persona.nombre = funcionarioData.configuracionFuncionario.nombre;
        this.persona.numeroDocumento = funcionarioData.configuracionFuncionario.numeroDocumento;
        this.persona.correoElectronicoAni = funcionarioData.configuracionFuncionario.correoElectronico;
        this.detalleFuncionario.honorarios = funcionarioData.configuracionFuncionario.ingresos;
        this.detalleFuncionario.dependenciaPrimerNivel = funcionarioData.configuracionFuncionario.dependenciaPrimerNivel;
        this.detalleFuncionario.idDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.idDependenciaPrimerNivel;
        this.detalleFuncionario.liderDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.liderDependenciaPrimerNivel;
        this.detalleFuncionario.dependenciaEspecifica = funcionarioData.configuracionFuncionario.dependencia;
        this.detalleFuncionario.idDependenciaEspecifica = funcionarioData.configuracionFuncionario.idDependencia;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo para actualizar la variable global que muestra el sumatoria de modos
   */
  actualizarSumatoriaModos() {
    if (Array.isArray(this.comision.modoLista) && this.comision.modoLista.length) {
      return this.comision.modoLista.reduce<number>((sum: number, modoVar: CmsModosDTO) => {
        return Number(sum + Number(modoVar.porcentajeRecursos));
      }, Number(0));
    }
  }

  obtenerLegalizacionPorComision(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarLegalizacionPorComision(idComision)
    ).subscribe(
      ([legalizacionData]) => {
        this.legalizacion = legalizacionData.legalizacion;
        this.listarAnexosPorLegalizar(this.legalizacion.idComisionVersion);
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  //Obtiene los anexos cargados para la legalización
  listarAnexosPorLegalizar(idComisionVersion) {
    forkJoin(
      this.comisionesService.srvConsultarAnexoAdjuntosConsolidado(idComisionVersion, EnumCmsTipoAnexo.SUBTIPO_GESTION_LEGALIZACION_ANEXOS + ',' + EnumCmsTipoAnexo.SUBTIPO_GESTION_TIQUETES, false)
    ).subscribe(
      ([comisionesPorLegalizarData]) => {
        this.anexosConsolidado = comisionesPorLegalizarData.adjuntosAnexosConsolidado;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }
  cargarItinerarios() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarTiquetesSolicitadosPorIdComision(this.idComision, this.comision.idComisionVersion)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.itinerarioAereoList = result.transporteAereoLista;
            if (this.itinerarioAereoList !== null && this.itinerarioAereoList.length > 0) {
              this.itinerarioAereoList.forEach(itAer => {
                if (itAer.fechaVuelo != null) {
                  itAer.fechaVuelo = new Date(itAer.fechaVuelo);
                }
              });
            }

          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          this.blockedDocument = false;
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarItinerariosCancelados() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarTiquetesCanceladosPorComision(this.comision.idComisionVersion)
    ).subscribe(
      ([
        tiquetesData
      ]) => {
        this.tiquetesCancelados = tiquetesData.tiquetes;
        if (this.tiquetesCancelados !== null && this.tiquetesCancelados.length > 0) {
          this.tiquetesCancelados.forEach(itAer => {
            if (itAer.fechaVuelo != null) {
              itAer.fechaVuelo = new Date(itAer.fechaVuelo);
            }
          });
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irConfirmarSolicitudCancelacionTiqueteComision(itinerarioL: CmsSolicitudCancelacionTiqueteDTO) {
    this.tiquetesCanceladoSeleccionado = itinerarioL;
    this.cancelarItinerarioAereoDialog = true;
  }

  confirmaSolicitudCancelacionTiqueteComision() {
    this.blockedDocument = true;
    const cmsSolicitudCancelacionTiqueteDTO: CmsSolicitudCancelacionTiqueteDTO = {
      observacionesRespuesta: this.observacionesCancelacion,
      idTiqueteSolicitado: this.tiquetesCanceladoSeleccionado.idTiqueteSolicitado
    }
    if (this.formSolicitudCancelacionTiquete.valid) {
      forkJoin(
        this.comisionesService.srvConfirmaSolicitudCancelacionTiqueteComision(cmsSolicitudCancelacionTiqueteDTO)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {

            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.cancelarItinerarioAereoDialog = false;
              this.cargarItinerariosCancelados();
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            this.blockedDocument = false;
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    } else {
      this.messageService.add({ severity: "error", summary: "Error", detail: "El campo justificación de cancelación es obligatorio.", life: 10000, });
      this.blockedDocument = false;
    }

  }

  aceptarSubirAdjunto() {
    this.tiqueteSeleccionado.rutaSoporteReserva = this.archivos[0].nombreCompleto;
  }

  irCargarTiquete(tiquete: VwCmsTiqueteAereoDTO) {
    if (tiquete.idTiqueteAereo != null) {
      this.tiqueteSeleccionado.id = tiquete.idTiqueteAereo;
      this.tiqueteSeleccionado.idTiqueteAereoSolicitado = tiquete.idTiqueteAereoSolicitado;
      this.tiqueteSeleccionado.costoReserva = tiquete.costoReserva;
      this.tiqueteSeleccionado.costoCompra = tiquete.costoCompra;
      this.tiqueteSeleccionado.fechaSalida = new Date(tiquete.fechaSalida);
      this.fechaMinima = new Date(tiquete.fechaVuelo);
      this.fechaMaxima = new Date(this.fechaMinima.getFullYear(), this.fechaMinima.getMonth(), this.fechaMinima.getDate(), 23, 59, 59, 59);
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo = {};
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo.idEstadoTiqueteAereo = tiquete.idEstado;
      this.idItinerario = tiquete.idTiqueteAereoSolicitado;
      this.archivos = [];
    } else {
      this.tiqueteSeleccionado = {};
      this.tiqueteSeleccionado.fechaSalida = new Date(tiquete.fechaVuelo);
      this.fechaMinima = new Date(tiquete.fechaVuelo);
      this.fechaMaxima = new Date(this.fechaMinima.getFullYear(), this.fechaMinima.getMonth(), this.fechaMinima.getDate(), 23, 59, 59, 59);

      this.archivos = [];
      this.idItinerario = tiquete.idTiqueteAereoSolicitado;
    }
    this.observacionesReserva = "Tiquete reservado";
    this.dialogoCargarTiquete = true;
  }

  irCargarCompraTiquete(tiquete: VwCmsTiqueteAereoDTO) {
    if (tiquete.idTiqueteAereo != null) {
      this.tiqueteSeleccionado.id = tiquete.idTiqueteAereo;
      this.tiqueteSeleccionado.idTiqueteAereoSolicitado = tiquete.idTiqueteAereoSolicitado;
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo = {};
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo.idEstadoTiqueteAereo = tiquete.idEstado;
      this.tiqueteSeleccionado.costoReserva = tiquete.costoReserva;
      this.tiqueteSeleccionado.costoCompra = tiquete.costoCompra;
      this.idItinerario = tiquete.idTiqueteAereoSolicitado;
      this.archivos = [];
    }
    this.dialogoCargarCompraTiquete = true;
  }

  irVerObservaciones(tiquete: VwCmsTiqueteAereoDTO) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarObservacionesPorIdTiquete(tiquete.idTiqueteAereoSolicitado)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.observacionesList = result.tiquetes;
            this.dialogoObservacionesTiquete = true;
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          this.blockedDocument = false;
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  guardarTiquete() {
    if (!this.formTiqueteReserva.valid) {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    } else {
      this.blockedDocument = true;
      this.dialogoCargarTiquete = false;
      let nombreArchivo = this.archivos[0].nombreCompleto;
      this.tiqueteSeleccionado.rutaSoporteReserva = nombreArchivo;
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo = {};
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo.observaciones = this.observacionesReserva;
      this.tiqueteSeleccionado.idTiqueteAereoSolicitado = this.idItinerario;
      this.archivos = [];
      forkJoin(
        this.comisionesService.srvReservarTiqueteComision(this.tiqueteSeleccionado)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.cargarItinerarios();
              this.lanzarMensajeInfo("La reserva se guardó exitosamente.");
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            this.blockedDocument = false;
          }
        },
        (error) => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
          this.dialogoCargarTiquete = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
          this.dialogoCargarTiquete = false;
        }
      );
    }
  }

  comprarTiquete() {
    if (!this.formTiqueteCompra.valid) {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    } else {
      this.blockedDocument = true;
      this.dialogoCargarCompraTiquete = false;
      let nombreArchivo = this.archivos[0].nombreCompleto;
      this.tiqueteSeleccionado.rutaSoporteCompra = nombreArchivo;
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo = {};
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo.observaciones = "Tiquete comprado";
      forkJoin(
        this.comisionesService.srvComprarTiqueteComision(this.tiqueteSeleccionado)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.cargarItinerarios();
              this.lanzarMensajeInfo("La compra se guardó exitosamente.");
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            this.blockedDocument = false;
          }
        },
        (error) => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
          this.dialogoCargarCompraTiquete = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
          this.dialogoCargarCompraTiquete = false;
        }
      );
    }
  }

  cancelarDialogoSubirAdjuntos() {
    this.dialogoCargarTiquete = false;
  }

  cancelarDialogoSubirCompraAdjuntos() {
    this.dialogoCargarCompraTiquete = false;
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsTipoAnexo() {
    return EnumCmsTipoAnexo;
  }

  bntVolver() {
    const ruta = "/comisiones/comisiones-tiquetes-registro";
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
