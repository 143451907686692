import { Component, OnInit } from "@angular/core";
import { AlcanceProyectoBaseComponent } from "../alcance-proyecto-base/alcance-proyecto-base.component";
import { forkJoin } from "rxjs";
import {
  AlcanceProyectoBaseService,
  UspCrrValidaInformacionProyectoDTO,
} from "../alcance-proyecto-base/alcance-proyecto-base.service";
import { MessageService } from "primeng/api";
import { UnidadFuncionalCarreteroService } from "../unidad-funcional-carretero/unidad-funcional-carretero.service";

@Component({
  selector: "app-validacion-informacion-proyecto2",
  templateUrl: "./validacion-informacion-proyecto.component.html",
  styleUrls: ["./validacion-informacion-proyecto.component.sass"],
})
// tslint:disable-next-line: component-class-suffix
export class ValidacionInformacionProyectoComponent2 implements OnInit {
  visibleConfirmarPublicacion: boolean;
  blockedDocument: boolean;
  visibleDialogoExitoPublicacionProyecto: boolean;
  listaErrores: UspCrrValidaInformacionProyectoDTO[];

  constructor(
    public lineaBase: AlcanceProyectoBaseComponent,
    private alcanceProyectoBaseService: AlcanceProyectoBaseService,
    private messageService: MessageService,
    private unidadFuncionalService: UnidadFuncionalCarreteroService
  ) {}

  ngOnInit() {}

  accionVisibleConfirmacionPublicar() {
    this.visibleConfirmarPublicacion = true;
  }

  accionCancelarVisibleConfirmacionPublicar() {
    this.visibleConfirmarPublicacion = false;
  }

  accionPublicarProyecto() {
    this.blockedDocument = true;
    forkJoin(
      this.alcanceProyectoBaseService.finalizarConfiguracionProyecto(
        this.lineaBase.idProyecto
      ),
      this.unidadFuncionalService.sincronizarProyecto(this.lineaBase.idProyecto)
    ).subscribe(
      ([result, resultSincronizacion]) => {
        console.log("resultSincronizacion POR PROYECTO----->", resultSincronizacion);
        if (result != null && result != null) {
          // Si el resultado es exitoso...
          if (result.codigoSalida === 1) {
            this.listaErrores = result.uspCrrValidaInformacionProyectoDTO;
            this.lineaBase.idConfiguracion = result.idConfiguracion;
            this.lineaBase.esBorrador = false;
            this.visibleConfirmarPublicacion = false;
            this.visibleDialogoExitoPublicacionProyecto = true;
            this.messageService.add({
              severity: "success",
              summary: "Exito",
              detail: "El proyecto se publicó exitosamente.",
              life: 10000,
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: result.mensajeSalida,
              life: 10000,
            });
            this.listaErrores = result.uspCrrValidaInformacionProyectoDTO;
            this.visibleConfirmarPublicacion = false;
          }
        } else {
        }
      },
      (error) => {},
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  aceptarDialogoExitoPublicacion() {
    this.lineaBase.indiceTab = 0;
    this.lineaBase.limpiarTabUnidadFuncional();
    this.visibleDialogoExitoPublicacionProyecto = false;
  }
}
