import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { InformacionGeneral } from './informacionGeneral';
import { AmparosEditService } from './amparos-edit.service';
import { NgForm } from '@angular/forms';
import { InformacionGeneralEnvio } from './informacionGeneral';
import { InformacionGeneralEnvioEdit } from './informacionGeneral';
import { Amparos } from './informacionGeneral';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { Location } from '@angular/common';

@Component({
	selector: 'app-amparos-edit',
	templateUrl: './amparos-edit.component.html',
	styleUrls: ['./amparos-edit.component.sass']
})
export class AmparosEditComponent implements OnInit {

	loading: boolean;
	resultadoInfo: any;
	visibleDialogoAgregarAmparo: boolean = false;
	habilitarModificar: boolean = false;
	disableModificar: boolean = true;
	habilitarModificarTemp: any;
	visibleNegocio: boolean = false;
	visibleModificarAmparos: boolean = false;
	visibleDialogoModificarAmparo: boolean = false;
	enviarInfo: InformacionGeneralEnvio = {};
	enviarInfoEdit: InformacionGeneralEnvioEdit = {};
	amparoEnvio: Amparos = {};
	amparos: any = [];
	tipoSolicitud: any;
	title: any;
	visibleAnexo: boolean = false;
	validarVisualizacionContratoIni: boolean = false;
	amparo: Amparos = {};
	amparoTempEdit: Amparos = {};
	amparoNew: Amparos = {};
	amparoMod: Amparos = {};
	listaAmparos: any = [];
	moneda: any;
	//Lista Contratos - Principal
	asociadosa: any = [];
	filtroAsociadosa: any[];
	asociadosaSeleccionada: any;
	nombreProyecto;
	informacionGeneral: InformacionGeneral = {};
	informacionGeneralGet: any = [];
	
	idAnexo: any;
	idProyecto: any;
	idPoliza: any;
	idContrato: any;
	blockedDocument: boolean;
	visibleDialogoExito: boolean = false;


	//Lisa de tipo amparos - modal amparos
	tipoAmparos: any = [];
	tipoAmparosTemp: any = [];
	filtroTipoAmparos: any[];
	tipoAmparoSeleccionada: any;

	//Lisa de amparos - modal amparos
	amparosAModificados: any = [];
	amparosAModificadosTemp: any = [];
	filtroAmparosAModificados: any[];
	amparosAModificadosSeleccionada: any;

	displayedAmparos = [
		{ field: 'tipoAmparo', header: 'Tipo Amparo' },
		{ field: 'fechaInicioCoberturaStr', header: 'Fecha Inicio' },
		{ field: 'fechaFinCoberturaStr', header: 'Fecha Fin' },
		{ field: 'valorAmparoStr', header: 'Valor Asegurado' }
	];

	constructor(private messageService: MessageService, private parametricasService: ParametricasService, private amparosEditService: AmparosEditService,
		private route: ActivatedRoute, private ruta: Router, private principalComponent: PrincipalComponent, public location: Location) {}


	ngOnInit() {
		this.loading = true;
		this.moneda = "";		
		this.moneda = localStorage.getItem('monedaAnexo');
		localStorage.removeItem('monedaAnexo');
		this.idProyecto = localStorage.getItem('idProyecto');
		this.idPoliza = localStorage.getItem('idPoliza');
		this.idContrato = localStorage.getItem('idContrato');
		this.cargarAsociadosa();
		this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));

		this.informacionGeneral = new InformacionGeneral();	
		this.tipoSolicitud = localStorage.getItem('tipoSolicitud');
		this.habilitarModificarTemp = localStorage.getItem('habilitarModificar');
		
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};
		this.amparosAModificadosSeleccionada = { id: 0, nombre: ''};
		this.asociadosaSeleccionada = { id: 0, nombre: ''};

		if(this.tipoSolicitud == "onViewAmparo"){
			this.visibleAnexo = false;
			this.title = "Visualización";
			this.habilitarModificar = false;
			this.disableModificar = true;
		}else{
			if(this.tipoSolicitud == "onNew"){
				this.title = "Creación";
				this.visibleAnexo = true;
				this.habilitarModificar = true;
				this.disableModificar = false;
			}else{
				this.title = "Edición";
				if(this.habilitarModificarTemp=="false"){
					this.habilitarModificar = false;
					this.disableModificar = true;
				}else{
					this.habilitarModificar = true;
					this.disableModificar = false;
				}
			}
			this.validarVisualizacionContratoIni = true;
			this.visibleAnexo = true;		
		}

		

		this.route.params.subscribe(params => {
			this.idAnexo = params.id;
			if(this.idAnexo != "new"){
				this.tipoAmparoSeleccionada = { id: 0, nombre: ''};
				this.cargarSoloTipoAmparo(this.idPoliza);
				this.cargarInformacionAnexo(this.idAnexo);	
			}else{
				this.cargarSoloTipoAmparo(null);
			}
		});
		this.loading = false;
	}

	/**
	 * Metodo que carga la informacion
	 * @param idAnexo 
	 */
	 cargarInformacionAnexo(idAnexo: number) {
		this.cargarSoloTipoAmparo(this.idPoliza);
		this.loading = true;
		forkJoin(
			this.amparosEditService.obtenerInformacionAnexo(idAnexo)
		).subscribe(([result]) => {
				this.resultadoInfo = result;
				if (this.resultadoInfo != null && this.resultadoInfo.respuestaServicio != null) {
					//Si el resultado es exitoso...
					if (this.resultadoInfo.respuestaServicio.codigoSalida === 1) {
						this.informacionGeneralGet = this.resultadoInfo.anexo;
						this.informacionGeneral = new InformacionGeneral();
						this.informacionGeneral.id = this.informacionGeneralGet.id;
						this.informacionGeneral.idPoliza = this.informacionGeneralGet.idPoliza;
						this.informacionGeneral.idNovedadContrato = this.informacionGeneralGet.idNovedadContrato;
						this.informacionGeneral.novedadContrato = this.informacionGeneralGet.novedadContrato;
						this.informacionGeneral.fechaExpedicion =  new Date(this.informacionGeneralGet.fechaExpedicion);
						this.informacionGeneral.numero = this.informacionGeneralGet.numero;
						this.informacionGeneral.objeto = this.informacionGeneralGet.objeto;
						this.informacionGeneral.orden = this.informacionGeneralGet.orden;
						this.informacionGeneral.observacion = this.informacionGeneralGet.observacion;
						this.informacionGeneral.idMonedaPoliza = this.informacionGeneralGet.idMonedaPoliza;				
						this.asociadosaSeleccionada = { id: 0, nombre: ''};
						if(this.informacionGeneralGet.idNovedadContrato!= undefined && this.informacionGeneralGet.idNovedadContrato!= null){
							this.asociadosaSeleccionada.nombre = this.informacionGeneralGet.novedadContrato;
							this.asociadosaSeleccionada.id = this.informacionGeneralGet.idNovedadContrato;
							this.validarVisualizacionContratoIni = this.visibleAnexo;
						}else{
							this.validarVisualizacionContratoIni = false;
							this.asociadosaSeleccionada.nombre = "Contrato Inicial";
							this.asociadosaSeleccionada.id = null;
						}

						if(this.informacionGeneralGet.amparos!=undefined && this.informacionGeneralGet.amparos != null 
							&& this.informacionGeneralGet.amparos.length > 0){
							this.transformar(this.informacionGeneralGet.amparos);
						}	
						this.loading = false;
					} else {
						this.loading = false;
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: this.resultadoInfo.respuestaServicio.mensajeSalida,
							life: 10000
						});
					}
				} else {}
			},error => {
				this.loading = false;
				this.principalComponent.cargarErrorServicio(error);
			  },
			() => {
				this.loading = false;
			}
		);
	}


	/**
	 * Metodo que hace la transformacion de amparos
	 * @param amparos 
	 */
	 transformar(amparos :any[]){
		this.listaAmparos = [];
		amparos.forEach(element => {
			this.amparo = new Amparos();
			this.amparo.descripcion = element.descripcion;
			this.amparo.fechaFinCobertura = element.fechaFinCobertura;
			this.amparo.fechaFinCoberturaStr = new Date(element.fechaFinCobertura).toLocaleDateString();
			this.amparo.fechaInicioCoberturaStr = new Date(element.fechaInicioCobertura).toLocaleDateString();
			this.amparo.fechaInicioCobertura = element.fechaInicioCobertura;
			this.amparo.id = element.id;
			this.amparo.idAnexo = element.idAnexo;
			this.amparo.idTipoAmparo = element.idTipoAmparo;
			this.amparo.tipoAmparo = element.tipoAmparo;
			this.amparo.valorAmparo = element.valorAmparo;
			this.amparo.valorAmparoStr = (( this.moneda!= null &&  this.moneda!= undefined)?  this.moneda :""  ) +" "+this.formatearNumero(element.valorAmparo);
			this.listaAmparos.push(this.amparo);
		  });
	}

	/**
	 * Metodo que hace la transformacion de valor numerico formateo
	 * @param nStr 
	 */
	 formatearNumero(nStr:any) {
		nStr += '';
		let x = nStr.split('.');
		let x1 = x[0];
		let x2 = x.length > 1 ? ',' + x[1] : '';
		var rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + '.' + '$2');
		}
		return x1 + x2;
	}

	/**
	 * Metodo que realiza el guardado de la informacion
	 */
	 guardarInformacionGeneral() {
		this.loading = true;
		this.enviarInfo = new InformacionGeneralEnvio();
		this.enviarInfoEdit = new InformacionGeneralEnvioEdit();
		this.amparos = [];
		
		this.listaAmparos.forEach(element => {
			this.amparoEnvio = new Amparos();
			this.amparoEnvio.descripcion  = element.descripcion;
			this.amparoEnvio.idTipoAmparo  = element.idTipoAmparo;
			this.amparoEnvio.valorAmparo  = element.valorAmparo;
			this.amparoEnvio.fechaInicioCobertura  = new Date(element.fechaInicioCobertura);
			this.amparoEnvio.fechaFinCobertura  = new Date(element.fechaFinCobertura);
			this.amparos.push(this.amparoEnvio);
		});

		if(this.tipoSolicitud=="onNew"){
			this.enviarInfo.idPoliza  = this.idPoliza;
			this.enviarInfo.idNovedadContrato  = this.asociadosaSeleccionada.id;
			this.enviarInfo.fechaExpedicion  = new Date(this.informacionGeneral.fechaExpedicion);
			this.enviarInfo.observacion  = this.informacionGeneral.observacion;
			this.enviarInfo.amparos = this.amparos
		}else{
			this.enviarInfoEdit.id  = this.informacionGeneral.id;
			this.enviarInfoEdit.idPoliza  = this.idPoliza;
			this.enviarInfoEdit.idNovedadContrato  = this.informacionGeneral.idNovedadContrato;
			this.enviarInfoEdit.fechaExpedicion  = new Date(this.informacionGeneral.fechaExpedicion);
			this.enviarInfoEdit.observacion  = this.informacionGeneral.observacion;
			this.enviarInfoEdit.amparos = this.amparos
		}
		
		forkJoin(
			this.amparosEditService.guardarInformacionAnexo((this.tipoSolicitud=="onEdit")? this.enviarInfoEdit :  this.enviarInfo)
		).subscribe(([result]) => {
				if (result != null && result.respuestaServicio != null) {
					if (result.respuestaServicio.codigoSalida === 1) {
						this.visibleDialogoExito = true;
					} else {
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: result.respuestaServicio.mensajeSalida,
							life: 10000
						});
					}
				} else {}
			},error => {
				this.loading = false;
				this.principalComponent.cargarErrorServicio(error);
			  },
			() => {
				this.loading = false;
			}
		);
	}

	/**
	 * Metodo que visualiza el Agregar amparo modal
	 */
	 visibleAgregarAmparo() {
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};	
		this.moneda = ((this.moneda)!= undefined && (this.moneda)!= null) ? this.moneda+" ": "";
		this.amparoNew = new Amparos();
		this.visibleDialogoAgregarAmparo = true;
		if(this.idPoliza != "new"){
			this.cargarSoloTipoAmparo(this.idPoliza);
			this.cargarTipoAmparo(this.idPoliza);
		}else{
			this.cargarSoloTipoAmparo(null);
			this.cargarTipoAmparo(null);
		}
	}

	/**
	 *Metodo 
	 *
	 * @param {*} valor
	 */
	onChangeAmparoMod(event){
		let amparoSelect = event.value;
		this.amparoMod.descripcion = amparoSelect.descripcion;
		this.amparoMod.id = amparoSelect.id;
		this.amparoMod.idTipoAmparo = amparoSelect.idTipoAmparo;
		this.amparoMod.tipoAmparo = amparoSelect.tipoAmparo;
		this.amparoMod.idAnexo = amparoSelect.idAnexo;
		this.amparoMod.valorAmparo = amparoSelect.valorAmparo;
		this.amparoMod.fechaInicioCobertura = new Date(amparoSelect.fechaInicioCobertura);
		this.amparoMod.fechaFinCobertura = new Date(amparoSelect.fechaFinCobertura);
	}

	/**
	 * Metodo que visualiza el Modificar amparo modal
	 */
	 visibleModificarAmparo() {
		this.amparosAModificadosSeleccionada = { id: 0, nombre: 'Seleccione...'};
		this.amparosAModificados = [];
		this.filtroAmparosAModificados = [];
		this.amparosEditService.getAmparosModificacion(this.idPoliza).subscribe((data: {}) => {
			this.amparosAModificadosTemp = data;
			let existeFiltro: boolean;
			let encontro: boolean = false;
			for (let i = 0; i < this.amparosAModificadosTemp.amparos.length; i++) {
				let filtro = this.amparosAModificadosTemp.amparos[i];
				for (let j = 0; j < this.listaAmparos.length; j++) {
					let filtro2 = this.listaAmparos[j];
				}
					filtro.nombre = filtro.tipoAmparo;
					this.filtroAmparosAModificados.push(filtro);
			}
			this.amparosAModificados = this.filtroAmparosAModificados;
			
		},error => {
            this.principalComponent.cargarErrorServicio(error);
          });
			
		this.moneda = ((this.moneda)!= undefined && (this.moneda)!= null) ? this.moneda+" ": "";
		this.amparoMod = new Amparos();
		this.visibleDialogoModificarAmparo = true;
		
	}

	/**
	 * Metodo que cancela el Agregar amparo modal
	 */
	 cancelarAgregarAmparo(){
		if(this.amparoTempEdit!= null && this.amparoTempEdit != undefined && this.amparoTempEdit.valorAmparo != undefined){
			this.onReplaceAmparo(this.amparoTempEdit);
			this.amparoTempEdit = new Amparos(); 
		}		
		this.amparoNew = new Amparos();
		this.visibleDialogoAgregarAmparo = false;
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};
	}

	/**
	 * Metodo que cancela el Modificar amparo modal
	 */
	 cancelarModificarAmparo(){
		if(this.amparoTempEdit!= null && this.amparoTempEdit != undefined && this.amparoTempEdit.valorAmparo != undefined){
			this.onReplaceAmparo(this.amparoTempEdit);
			this.amparoTempEdit = new Amparos(); 
		}		
		this.amparoMod = new Amparos();
		this.visibleDialogoModificarAmparo = false;
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};
		this.amparosAModificadosSeleccionada = { id: 0, nombre: ''};
	}

	/**
	 * Metodo que cancela la creacion de la poliza
	 */
	 cancelarPoliza(){
		//this.ruta.navigate(['/carreteros/infGeneral/proyectoHome/'+this.idProyecto+'/polizas/'+this.idProyecto+'/anexos', this.idPoliza]);
		this.location.back();
	}

	/**
	 * Metodo que regresa a la poliza visualizacion
	 */
	volverPoliza(){
		//this.ruta.navigate(['/carreteros/infGeneral/proyectoHome/'+this.idProyecto+'/polizas/'+this.idProyecto+'/polizaEdit', this.idPoliza]);
		this.location.back();
	}
	

	/**
	 * Metodo que visualiza el modal del guardado exitoso
	 */
	aceptarDialogoExito() {
		this.visibleDialogoExito = false;
		//this.ruta.navigate(['/carreteros/infGeneral/proyectoHome/'+this.idProyecto+'/polizas/'+this.idProyecto+'/anexos', this.idPoliza]);
		this.location.back();
	}

	/**
	* Metodo que guarda el Agregar Amparo modal
	*/
	guardarAmparo(forma: NgForm){
		this.visibleDialogoAgregarAmparo = false;
		this.amparo = new Amparos();
		if(this.amparoNew.id== undefined){
			this.amparoNew.id = this.generarUid();
		}

		this.amparo.id = this.amparoNew.id;
		this.amparo.idAnexo = this.amparoNew.idAnexo;
		this.amparo.valorAmparo = this.amparoNew.valorAmparo;
		this.amparo.idTipoAmparo = this.tipoAmparoSeleccionada.id;
		this.amparo.tipoAmparo = this.tipoAmparoSeleccionada.nombre;
		this.amparo.descripcion = this.amparoNew.descripcion;
		this.amparo.fechaFinCobertura	 = new Date(this.amparoNew.fechaFinCobertura);
		this.amparo.fechaInicioCobertura = new Date(this.amparoNew.fechaInicioCobertura);
		this.amparo.fechaFinCoberturaStr	 = new Date(this.amparoNew.fechaFinCobertura).toLocaleDateString();
		this.amparo.fechaInicioCoberturaStr = new Date(this.amparoNew.fechaInicioCobertura).toLocaleDateString();
		this.amparo.valorAmparoStr = (( this.moneda!= null &&  this.moneda!= undefined)?  this.moneda :""  ) +" "+this.formatearNumero(this.amparoNew.valorAmparo);
		
		if(this.amparoTempEdit!= null && this.amparoTempEdit != undefined && this.amparoTempEdit.valorAmparo != undefined){
			this.onReplaceAmparo(this.amparo);
			this.amparoTempEdit = new Amparos(); 
		}else{
			this.listaAmparos.push(this.amparo);
		}
		forma.reset();
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};
	}

	/**
	* Metodo que guarda el Modificar Amparo modal
	*/
	guardarModificarAmparo(forma: NgForm){
		this.visibleDialogoModificarAmparo = false;
		this.amparo = new Amparos();
		if(this.amparoMod.id== undefined){
			this.amparoMod.id = this.generarUid();
		}

		this.amparo.id = this.amparoMod.id;
		this.amparo.idAnexo = this.amparoMod.idAnexo;
		this.amparo.valorAmparo = this.amparoMod.valorAmparo;
		this.amparo.idTipoAmparo = this.amparoMod.idTipoAmparo;
		this.amparo.tipoAmparo = this.amparoMod.tipoAmparo;
		this.amparo.descripcion = this.amparoMod.descripcion;
		this.amparo.fechaFinCobertura	 = new Date(this.amparoMod.fechaFinCobertura);
		this.amparo.fechaInicioCobertura = new Date(this.amparoMod.fechaInicioCobertura);
		this.amparo.fechaFinCoberturaStr	 = new Date(this.amparoMod.fechaFinCobertura).toLocaleDateString();
		this.amparo.fechaInicioCoberturaStr = new Date(this.amparoMod.fechaInicioCobertura).toLocaleDateString();
		this.amparo.valorAmparoStr = (( this.moneda!= null &&  this.moneda!= undefined)?  this.moneda :""  ) +" "+this.formatearNumero(this.amparoMod.valorAmparo);

		if(this.amparoTempEdit!= null && this.amparoTempEdit != undefined && this.amparoTempEdit.valorAmparo != undefined){
			this.onReplaceAmparo(this.amparo);
			this.amparoTempEdit = new Amparos(); 
		}else{
			this.onReplaceAmparo(this.amparo);
			//this.listaAmparos.push(this.amparo);
		}
		forma.reset();
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};
		this.amparosAModificadosSeleccionada = { id: 0, nombre: ''};
	}
	
	/**
	 * Metodo que elimina un registro en la lista de amparos
	 * @param amparo 
	 */
	 onDeleteAmparo(amparo){
		let i = 0;
		this.listaAmparos.forEach(element => {
			if(element.id===amparo.id){
			   this.listaAmparos.splice(i, 1);
			}
		   i++;
		});
   }


   /**
	 * Metodo que reemplaza un registro en la lista de amparos
	 * @param amparo 
	 */
	 onReplaceAmparo(amparo){
		let i = 0;
		let encontro = false;
		if(this.listaAmparos.length > 0){
			for (let i = 0; i < this.listaAmparos.length; i++) {
				let filtro = this.listaAmparos[i];
				if(filtro.id===amparo.id || filtro.idTipoAmparo== amparo.idTipoAmparo){
					encontro = true;
					this.listaAmparos.splice(i, 1, amparo);
				}
			}
			if(!encontro){
				amparo.id = this.generarUid();
				amparo.idAnexo = undefined;
				this.listaAmparos.push(amparo);
			}
		}else{
			this.listaAmparos.push(amparo);
		}
		
   }

	/**
	* Metodo que genera un identificador cuando el id es null
	*/  
   	generarUid(){
		return Math.floor(Math.random() * 999999);
	}
	   
	/**
	 * Metodo que captura el evento del teclado solo numeros 0-9
	 * @param evento 
	 */
	soloNumeros(evento){
		if(evento.code == "Backspace" 
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"
		|| evento.ctrlKey == true){
				return true;
		}
		
		if(evento.ctrlKey == true && evento.keyCode == 67){
		  return true;
		}
		if(evento.ctrlKey == true && evento.keyCode == 86){
		  return true;
		}
	
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || 
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}
	}
	
	/**
	 * Metodo que captura el evento del teclado solo numeros 0-9 y , .
	 * @param evento 
	 */
	 soloNumerosDecimal(evento){
		if(evento.code == "Backspace" 
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"
		|| evento.code == "Comma"
		|| evento.key == "." ){
			return true;
		}
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || 
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}
	}

   /**
	* Metodo que carga la edicion de un amparo
	* @param amparo 
	*/
   onEdit(amparo){
		this.amparoTempEdit = amparo;
		this.visibleDialogoAgregarAmparo = true;
		this.amparoNew = new Amparos();
		this.amparoNew.valorAmparo = amparo.valorAmparo;
		this.amparoNew.id = amparo.id;
		this.amparoNew.idAnexo = amparo.idAnexo;
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};
		this.tipoAmparoSeleccionada.nombre = amparo.tipoAmparo;
		this.tipoAmparoSeleccionada.id = amparo.idTipoAmparo;
		this.amparoNew.descripcion = amparo.descripcion;
		this.amparoNew.fechaFinCobertura	 = new Date(amparo.fechaFinCobertura);
		this.amparoNew.fechaInicioCobertura = new Date(amparo.fechaInicioCobertura);
   }


   /**
	* Metodo que carga las asociados a
	*/
   cargarAsociadosa() {
		this.filtroAsociadosa = [];
		return this.amparosEditService.getNovedadesContractuales(this.idContrato).subscribe((data: {}) => {
			this.asociadosa = data;
			let existeFiltro: boolean;
			if(this.tipoSolicitud == "onNew"){
				this.asociadosaSeleccionada = { id: 0, nombre: 'Seleccione...'};
			}
				
			for (let i = 0; i < this.asociadosa.novedadesContractuales.length; i++) {
				let filtroContrato = this.asociadosa.novedadesContractuales[i];
				let item: string = String(filtroContrato.nombre);
				this.filtroAsociadosa.push(filtroContrato);
				existeFiltro = true;
			}
			if (existeFiltro) {
				this.asociadosa = this.filtroAsociadosa;
			}
		},error => {
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	cargarFiltroAsociadosa(event) {
		this.filtroAsociadosa = [];
		for (let i = 0; i < this.asociadosa.length; i++) {
			let filtro = this.asociadosa[i];
			let item: string = String(filtro.nombre);
			if (item.toLowerCase().includes(event.query.toLowerCase())) {
				this.filtroAsociadosa.push(filtro);
			}
		}
	}

	/**
	 * Metodo que carga la lista de los tipos de amparos
	 * @param idPoliza 
	 */
	 cargarTipoAmparo(idPoliza) {
		return this.amparosEditService.getPlzMaTipoAmparo(idPoliza).subscribe((data: {}) => {
			this.tipoAmparos = data;
			let existeFiltro: boolean;
			let encontro: boolean;
			this.filtroTipoAmparos = [];
			if(this.listaAmparos.length ==0 ){
				for (let i = 0; i < this.tipoAmparos.tipoAmparos.length; i++) {
					let filtro = this.tipoAmparos.tipoAmparos[i];
					this.filtroTipoAmparos.push(filtro);
					existeFiltro = true;
				}
				if (existeFiltro) {
					this.tipoAmparos = this.filtroTipoAmparos;
				}
			}else{
				for (let i = 0; i < this.tipoAmparos.tipoAmparos.length; i++) {
					let filtro = this.tipoAmparos.tipoAmparos[i];
					for (let j = 0; j < this.listaAmparos.length; j++) {
						let filtro2 = this.listaAmparos[j];
						if(filtro.id == filtro2.idTipoAmparo){
							encontro = true;
						}
					}
					if (!encontro) {
						this.filtroTipoAmparos.push(filtro);
						existeFiltro = true;
					}
				}
				if (existeFiltro) {
					this.tipoAmparos = this.filtroTipoAmparos;
				}
			}
		},error => {
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	/**
	 * Metodo que carga la lista de los tipos de amparos
	 * @param idPoliza 
	 */
	 cargarSoloTipoAmparo(idPoliza) {
		return this.amparosEditService.getPlzMaTipoAmparo(idPoliza).subscribe((data: {}) => {
			this.tipoAmparosTemp = data;
			this.tipoAmparoSeleccionada = { id: 0, nombre: 'Seleccione..'};
			/*for (let i = 0; i < this.tipoAmparosTemp.tipoAmparos.length; i++) {
				let filtro = this.tipoAmparosTemp.tipoAmparos[i];
				this.tipoAmparoSeleccionada.id = filtro.id;
				this.tipoAmparoSeleccionada.nombre = filtro.nombre ;
				break;
			}*/
		},error => {
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	/**
	 * Metodo que carga el filtro de los tipos de personas
	 * @param event 
	 */
	cargarFiltroTipoAmparos(event) {
		this.loading = true;
		this.filtroTipoAmparos = [];
		for (let i = 0; i < this.tipoAmparos.tipoAmparos.length; i++) {
			let filtro = this.tipoAmparos.tipoAmparos[i];
			let item: string = String(filtro.nombre);
			if (item.toLowerCase().includes(event.query.toLowerCase())) {
				this.filtroTipoAmparos.push(filtro);
			}
		}
		this.loading = false;
	}



}