import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { ContratacionService } from '../contratacion.service';
import { TrnPersona } from 'src/app/admin-personas/trn-persona';
import { NgForm } from '@angular/forms';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { CtoContratoDTO } from '../contratacion-dto/contrato';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumFechaFormato, EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { DatePipe } from '@angular/common';
import { EnumCtoEstadoContrato } from '../constantes/contratacion-constantes';

@Component({
  selector: 'app-visto-bueno-contrato',
  templateUrl: './visto-bueno-contrato.component.html',
  styles: []
})
export class VistoBuenoContratoComponent implements OnInit {

  listaContratos: CtoContratoInformacionVigenteEncabezadoDTO[] = [];

  funcionariosVistoBuenoPorContrato: TrnPersona[];
  funcionarioVistoBuenoSeleccionado: TrnPersona;
  funcionariosVistoBueno: TrnPersona[] = [];
  blockedDocument = false;

  personasVistoBueno: any = [];
  visibleDialogoAsignarVistoBueno = false;
  listaContratosSeleccionados: CtoContratoInformacionVigenteEncabezadoDTO[] = [];

  visibleListaContratosPublicados = true;

  constructor(
    private datepipe: DatePipe,
    private messageService: MessageService,
    private personaService: PersonaService,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.blockedDocument = true;
    this.cargarContratos();
  }

  cargarContratos() {
    this.contratacionService.ListarContratosPublicadosPorSupervisor()
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.listaContratos = result.contratosInformacionVigente;
              if (this.listaContratos == null || this.listaContratos.length == 0) {
                this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'No tienes contratos asignados como supervisor', life: 10000 });
              } else {
                this.contratacionService.srvCalcularEstadoVigenciaContrato(this.listaContratos);
              }
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  cargarPersonas() {
    this.personaService.getPersonas().subscribe((data: {}) => {
      this.personasVistoBueno = data;
    })
  }

  filtrarPersonas(event) {
    const filtroFuncionariosVistoBueno = [];
    let idsContrato: number[] = [];
    this.listaContratosSeleccionados.forEach(idContrato => {
      idsContrato.push(idContrato.id);
    });


    this.contratacionService.GetConsultarPersonasDisponiblesParaVistoBuenoInforme(idsContrato).subscribe((data: {}) => {

      this.personasVistoBueno = data;
      let existeFiltro: boolean;
      for (const funVisBno of this.personasVistoBueno) {
        if (String(funVisBno.primerNombre).toLowerCase().includes(event.query.toLowerCase())
          || String(funVisBno.otrosNombres).toLowerCase().includes(event.query.toLowerCase())
          || String(funVisBno.primerApellido).toLowerCase().includes(event.query.toLowerCase())
          || String(funVisBno.segundoApellido).toLowerCase().includes(event.query.toLowerCase())
          || String(funVisBno.correoElectronico).toLowerCase().includes(event.query.toLowerCase())) {
          filtroFuncionariosVistoBueno.push(funVisBno);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.personasVistoBueno = filtroFuncionariosVistoBueno;
      }
    })
  }

  asignarVistoBueno() {
    this.funcionarioVistoBuenoSeleccionado = null;
    this.cargarPersonas();
    this.visibleDialogoAsignarVistoBueno = true;
  }

  validarAsignacionVobo() {
    let voboValido = true;

    if (this.funcionarioVistoBuenoSeleccionado == null || this.funcionarioVistoBuenoSeleccionado.id == null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar de la lista el funcionario para ser visto bueno', life: 10000 });
      voboValido = false;
    }
    if (this.listaContratosSeleccionados == null || this.listaContratosSeleccionados.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar al menos un contrato para asignar el visto bueno', life: 10000 });
    }
    return voboValido;
  }

  aceptarAsignarVistoBueno(vistoBuenoForm: NgForm) {
    if (this.validarAsignacionVobo()) {

      this.listaContratosSeleccionados.forEach(contratoSeleccionadoVar => {
        const funcionarioVistoBueno = {
          IdContrato: contratoSeleccionadoVar.id,
          IdPersona: this.funcionarioVistoBuenoSeleccionado.id
        };
        this.contratacionService.GuardarContratoFuncionarioVistoBueno(funcionarioVistoBueno).subscribe(
          result => {
            this.lanzarMensajeInfo("Se asignaron los contratos al funcionario seleccionado como visto bueno")
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.funcionarioVistoBuenoSeleccionado = null;
            this.listaContratosSeleccionados = null;
            this.cargarContratos();
            this.visibleDialogoAsignarVistoBueno = false;
            vistoBuenoForm.resetForm();
          }
        );
      });
    }
  }


  cancelarAsignarVistoBueno(vistoBuenoForm: NgForm) {
    this.visibleDialogoAsignarVistoBueno = false;
    vistoBuenoForm.resetForm();
  }

  eliminarAsignacionVistoBueno(_contrato: CtoContratoDTO) {
    this.contratacionService.EliminarContratoFuncionarioVistoBuenoPorIdContrato(_contrato.id).subscribe(
      result => {
        this.lanzarMensajeInfo("Se desvincularon los funcionarios visto bueno asignados al contrato")
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.cargarContratos();
      }
    );
  }

  ///////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
  }

  lanzarMensajeError(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
  }

  lanzarMensajeWarning(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  get EnumCtoEstadoContrato() {
    return EnumCtoEstadoContrato;
  }

}
