
import { Injectable } from '@angular/core';
import { map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { TrnRespuestaServicioRpt } from '../parametricas/rpt-respuesta-operacion';
import { CrrMaTipoCierreViaRpt, CrrViaAfectadaRpt, CrrViaAfectadaDTO, CrrImagenViaAfectadaRpt, CrrImagenViaAfectadaDTO, CrrViaAfectadaReporteDTO, CrrGeneracionRpt, CrrMaClasificacionContingenciaRpt, CrrTipoAfectacionViaRpt, CrrTipoAfectacionViaDTO, CrrMaTipoAfectacionCierreViaRpt } from './ola-invernal.dto';
import { Observable } from 'rxjs-compat';

@Injectable({
    providedIn: 'root'
})
export class OlaInvernalService {
    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    /**
     * Constructor de la clase
     * @param http 
     * @param datepipe 
     */
    constructor(
        private http: HttpClient,
        private datepipe: DatePipe) {

        this.END_POINT = environment.baseUrl;
    }

    //#region "Parametricas"


    /**
     * Metodo que consulta los tipos de cierre parametricas ola invernal
     * @returns  CrrMaTipoCierreViaRpt
     */
    srvConsultarMaTiposCierreOlaInvernal(): Observable<CrrMaTipoCierreViaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrMaOlaInvernal/CrrMaTipoCierreVia';
        return this.http.get<CrrMaTipoCierreViaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que consulta las clasificaciones parametricas de contigencias de ola invernal
     * @returns  CrrMaTipoCierreViaRpt
     */
    srvConsultarMaClasificacionContingenciaOlaInvernal(): Observable<CrrMaClasificacionContingenciaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrMaOlaInvernal/CrrMaClasificacionContingenciaCierreVia';
        return this.http.get<CrrMaClasificacionContingenciaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
    * Metodo que consulta los tipos de afectación de ola invernal
    * @returns  CrrMaTipoAfectacionCierreViaRpt
    */
    srvConsultarMaTipoAfectacionCierreVia(): Observable<CrrMaTipoAfectacionCierreViaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrMaOlaInvernal/CrrMaTipoAfectacionCierreVia';
        return this.http.get<CrrMaTipoAfectacionCierreViaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
    //#endregion "Parametricas"

    //#region "Información General"    
    srvConsultarCierresPorProyecto(idProyecto: number): Observable<CrrViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/ListarCrrOlaInvernalPorProyecto/' + idProyecto;
        return this.http.get<CrrViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvConsultarCierreOlaInvernalPorId(idOlaInvernal: number): Observable<CrrViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/ConsultarCrrOlaInvernalPorId/' + idOlaInvernal;
        return this.http.get<CrrViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvGuardarCierreOlaInvernal(cierreOlaInvernal: CrrViaAfectadaDTO): Observable<CrrViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/GuardarCrrOlaInvernalPorProyecto';
        return this.http.post<CrrViaAfectadaRpt>(urlEndpoint, cierreOlaInvernal)
            .pipe(
                map(data => {
                    return data;
                }));
    }

    srvEliminarCierreOlaInvernalPorId(idOlaInvernal: number): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/eliminarCrrOlaInvernal/' + idOlaInvernal;
        return this.http.delete<TrnRespuestaServicioRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
    //#endregion "Información General"

    //#region "Afectaciones"
    srvConsultarAfectacionesPorIdViaAfectada(idOlaIinvernal: number): Observable<CrrTipoAfectacionViaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/ListarCrrTipoAfectacionViaPorIdViaAfectada/' + idOlaIinvernal;
        return this.http.get<CrrImagenViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvConsultarAfectacionPorId(idAfectacion: number): Observable<CrrTipoAfectacionViaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/ConsultarCrrTipoAfectacionViaPorIdViaAfectadaPorId/' + idAfectacion;
        return this.http.get<CrrTipoAfectacionViaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvGuardarAfectacionViaAfectada(afectacion: CrrTipoAfectacionViaDTO): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/GuardarCrrTipoAfectacionViaPorIdViaAfectada';
        return this.http.post<TrnRespuestaServicioRpt>(urlEndpoint, afectacion)
            .pipe(
                map(data => {
                    return data;
                }));
    }

    srvEliminarAfectacionViaAfectadaPorId(idAfectacionOlaInvernal: number): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/eliminarCrrTipoAfectacionVia/' + idAfectacionOlaInvernal;
        return this.http.delete<TrnRespuestaServicioRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
    //#endregion "Afectaciones"

    //#region "Registro fotografico"
    srvConsultarImagenesPorIdViaAfectada(idOlaIinvernal: number): Observable<CrrImagenViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/ListarImagenesPorIdViaAfectada/' + idOlaIinvernal;
        return this.http.get<CrrImagenViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvConsultarImagenOlaInvernalPorId(idImagenOlaIinvernal: number): Observable<CrrImagenViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/ConsultarCrrImagenOlaInvernalPorId/' + idImagenOlaIinvernal;
        return this.http.get<CrrImagenViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvGuardarImagenViaAfectada(imagenViaAfectada: CrrImagenViaAfectadaDTO): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/GuardarCrrImagenViaAfectada';
        return this.http.post<TrnRespuestaServicioRpt>(urlEndpoint, imagenViaAfectada)
            .pipe(
                map(data => {
                    return data;
                }));
    }

    srvEliminarImagenViaAfectadaPorId(idFotografiaOlaInvernal: number): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/CrrOlaInvernal/eliminarCrrImagenViaAfectada/' + idFotografiaOlaInvernal;
        return this.http.delete<TrnRespuestaServicioRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
    //#endregion "Registro fotografico"

    //#region "Reporte Ola Invernal"
    srvConsultarReporteDetalleOlaInvernal(filtroReporteOlaInvernal: CrrViaAfectadaReporteDTO): Observable<CrrViaAfectadaRpt> {
        const urlEndpoint = '/CrrOlaInvernal/ListarCrrOlaInvernal';
        return this.http.post<CrrViaAfectadaRpt>(this.END_POINT + urlEndpoint, filtroReporteOlaInvernal)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que genera el pdf de ola invernal
     * 
     * @param filtroReporteOlaInvernal 
     * @returns 
     */
    GeneracionPdfOlaInvernal(filtroReporteOlaInvernal: CrrViaAfectadaReporteDTO): Observable<CrrGeneracionRpt> {
        return this.http.post<CrrGeneracionRpt>(this.END_POINT + '/CrrOlaInvernal/GeneracionPdf-Ola', filtroReporteOlaInvernal)
            .pipe(
                retry(1));
    }
    //#endregion "Reporte Ola Invernal"
}