import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { CtoReporteCuentasCobroCxpTotalDTO } from '../../../../contratacion-dto/rpte-cxp-total';
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-rpte-cxp-total',
  templateUrl: './rpte-cxp-total.component.html',
  styles: []
})
export class RpteCxpTotalComponent implements OnInit {

  // Informacion del datatable
  reporteCuentasCobroCxpConsolidadoTotal: CtoReporteCuentasCobroCxpTotalDTO[] = [];
  reporteExcelCuentasCobroCxpConsolidadoTotal: CtoReporteCuentasCobroCxpTotalDTO[] = [];
  totalRecords: number;
  loading: boolean;

  // Filtros
  fechaInicioPasoFiltro?: Date;
  fechaFinPasoFiltro?: Date;

  msgValFechaInicioPasoFiltro: string = 'Debe seleccionar la Fecha desde para realizar la consulta';
  msgValFechaFinPasoFiltro: string = 'Debe seleccionar la Fecha hasta para realizar la consulta';
  msgValFechaFinPasoMayorFiltro: string = 'La fecha desde debe ser mayor a la fecha hasta';
  msgValReporteNullVacio: string = 'No se encontrarón registros, revise la información de los filtros de búsqueda';

  constructor(
    private messageService: MessageService,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.buscar();
  }

  buscar() {
    this.consultarReporteCuentasCobroCxpConsolidado();
  }

  limpiarFiltros() {
    this.fechaInicioPasoFiltro = null;
    this.fechaFinPasoFiltro = null;
    this.buscar();
  }

  private validarFiltros(): boolean {
    let accionValida = true;
    if (this.fechaInicioPasoFiltro != null && this.fechaFinPasoFiltro != null) {
      if (this.fechaInicioPasoFiltro > this.fechaFinPasoFiltro) {
        accionValida = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.msgValFechaFinPasoMayorFiltro, life: 10000 });
        return accionValida;
      }
    }
    return accionValida;
  }

  private consultarReporteCuentasCobroCxpConsolidado() {
    this.reporteCuentasCobroCxpConsolidadoTotal = [];
    this.loading = true;
    if (this.validarFiltros()) {
      this.reporteCuentaCobroCxpService.srvConsultarReporteCuentasCobroCxpConsolidado(this.fechaInicioPasoFiltro, this.fechaFinPasoFiltro).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

              this.reporteCuentasCobroCxpConsolidadoTotal = result.reporteCuentasCobroCxpConsolidado;
              this.totalRecords = this.reporteCuentasCobroCxpConsolidadoTotal != null ? this.reporteCuentasCobroCxpConsolidadoTotal.length : 0;
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
          this.loading = false;
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.loading = false;
        }
      );
    } else {
      this.loading = false;
    }
  }

  private validarExportExcel(): boolean {
    let accionValida = true;
    if (this.reporteCuentasCobroCxpConsolidadoTotal == null) {
      accionValida = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.msgValReporteNullVacio, life: 10000 });
      return accionValida;
    }
    if (this.reporteCuentasCobroCxpConsolidadoTotal != null && this.reporteCuentasCobroCxpConsolidadoTotal.length == 0) {
      accionValida = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.msgValReporteNullVacio, life: 10000 });
      return accionValida;
    }
    return accionValida;
  }

  exportExcel() {
    this.loading = true;
    this.reporteExcelCuentasCobroCxpConsolidadoTotal = [];
    if (this.validarExportExcel()) {
      this.reporteExcelCuentasCobroCxpConsolidadoTotal = this.reporteCuentasCobroCxpConsolidadoTotal;
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.reporteExcelCuentasCobroCxpConsolidadoTotal);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'ctoReporteCuentasCxpConsolidadoTotal');
        this.loading = false;
      });
    } else {
      this.loading = false;
    }

  }

  /**
   * Metodo utilizado para descargar el archivo en formato xlsx generado
   * @param buffer 
   * @param fileName Nombre del archivo descargado
   */
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      this.loading = false;
    });
  }
}
