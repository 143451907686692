import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { PrsFuncionario } from 'src/app/admin-personas/prs-funcionario';
import { TrnPersona } from 'src/app/admin-personas/trn-persona';
import { EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumCmsTipoAnexo, EnumCmsConstantes } from '../../comisiones-constantes';
import { CmsComisionDTO, VwCmsAnexoComisionDTO, CmsAprobacionDTO, VwCmsTiqueteAereoDTO, CmsTiqueteDTO, CmsTiqueteAereoDTO, CmsAnexoComisionDTO, CmsArchivoAnexoComisionDTO, CmsComisionModificacionDTO, CmsModosDTO } from '../../comisiones.dto';
import { ComisionesService } from '../../comisiones.service';

@Component({
  selector: 'app-visualizar-comisiones-cancelacion',
  templateUrl: './visualizar-comisiones-cancelacion.component.html',
  styleUrls: ['./visualizar-comisiones-cancelacion.component.sass']
})
export class VisualizarComisionesCancelacionComponent implements OnInit {
  //Informacion comision
  comision: CmsComisionDTO;
  persona: TrnPersona;
  detalleFuncionario: PrsFuncionario;

  anexosConsolidado: VwCmsAnexoComisionDTO[];
  nombreTabLiquidacion: string;

  nombreTipoDocumentoCancelacion: string;
  rutaCloudStorageInformeCancelacion: string;
  visibleDialogoSubirAnexos = false;
  visibleDialogoFinalizarCancelacion = false;
  formCancelarComision;

  adjuntosAnexos: any = [];
  tipoSoporteSeleccionado: any;
  idsTipoSoporteCancelacion: string;
  observacionesCancelacion: string;

  blockedDocument = true;
  idComision: number;
  idItinerario: number;
  itinerarioAereoList: VwCmsTiqueteAereoDTO[];
  observacionesList: VwCmsTiqueteAereoDTO[];
  dialogoCargarTiquete: boolean;
  dialogoCargarCompraTiquete: boolean;
  dialogoObservacionesTiquete: boolean;
  rutaCloudStorageTiquete: string;
  fechaMinima: Date;
  fechaMaxima: Date;

  adjuntosComponente: any = [];
  tiquetes: CmsTiqueteDTO[] = [];
  tiqueteSeleccionado: CmsTiqueteAereoDTO;
  nombreTipoDocumento: string;
  archivos: any = []; //Los archivos que yo suba van a quedar en este arreglo

  visibleDialogoEliminarDocumentoCancelacion = false;
  idDocumentoCancelacion: number;
  cmsComisionModificacion: CmsComisionModificacionDTO;


  formTiquete;

  constructor(formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService) {

    // Crear formulario tiquete
    this.formTiquete = formBuilder.group({
      costo: [null, [Validators.required]],
      horaVuelo: [null, [Validators.required]]
    });

    this.formCancelarComision = formBuilder.group({
      observacionesCancelacion: [null, [Validators.required]],
    });

    this.idsTipoSoporteCancelacion =
      EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_SOLICITUD_CANCELACION_TIQUETES + ',' +
      EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_SOLICITUD_CANCELACION_RP + ',' +
      EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_SOLICITUD_CANCELACION_ACTO_ADMINISTRATIVO;
  }

  ngOnInit() {
    this.comision = {};
    this.comision.viaticos = {};
    this.persona = {};
    this.detalleFuncionario = {};



    this.ruta.params.subscribe(params => {
      this.idComision = params.idComision;

    });
    this.obtenerComisionPorId(this.idComision);
    this.tiqueteSeleccionado = {};

  }

  obtenerComisionPorId(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarComisionPorId(idComision)
    ).subscribe(
      ([comisionData]) => {
        this.comision = comisionData.comisionDetalle;
        if (this.comision !== null) {
          if (this.comision.fechaSalida != null) {
            this.comision.fechaSalida = new Date(this.comision.fechaSalida);
          }
          if (this.comision.fechaRegreso != null) {
            this.comision.fechaRegreso = new Date(this.comision.fechaRegreso);
          }



          this.comision.objeto = this.comision.objeto.replace('<p>', '').replace('</p>', '');

          if (this.comision.valorTentativoTerrestre === 0) {
            this.comision.valorTentativoTerrestre = null;
          }
          if (this.comision.valorTentativoFluvial === 0) {
            this.comision.valorTentativoFluvial = null;
          }
          if (this.comision.valorTentativoAereo === 0) {
            this.comision.valorTentativoAereo = null;
          }

          if (this.comision.itinerarioAereoLista !== null && this.comision.itinerarioAereoLista.length > 0) {
            this.comision.itinerarioAereoLista.forEach(itAer => {
              if (itAer.fechaVuelo != null) {
                itAer.fechaVuelo = new Date(itAer.fechaVuelo);
              }
            });
          }


          this.listarAnexosPorCancelar(this.comision.idComisionVersion);

          if (this.comision.esContratista) {
            this.persona = {};
            this.detalleFuncionario = {};
          } else {
            this.persona = {};
            this.detalleFuncionario = {};
            this.cargarPersona(this.comision.idPersona);
          }


          //Actualizamos la variable de sumatoria de modos para visualizarlo en el Datatable
          this.actualizarSumatoriaModos();

          this.cargarItinerarios();
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }


  cargarPersona(idPersona) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarFuncionarioComisionPorIdPersona(idPersona)
    ).subscribe(
      ([
        funcionarioData
      ]) => {
        this.persona.id = funcionarioData.configuracionFuncionario.id;
        this.persona.nombre = funcionarioData.configuracionFuncionario.nombre;
        this.persona.numeroDocumento = funcionarioData.configuracionFuncionario.numeroDocumento;
        this.persona.correoElectronicoAni = funcionarioData.configuracionFuncionario.correoElectronico;
        this.detalleFuncionario.honorarios = funcionarioData.configuracionFuncionario.ingresos;
        this.detalleFuncionario.dependenciaPrimerNivel = funcionarioData.configuracionFuncionario.dependenciaPrimerNivel;
        this.detalleFuncionario.idDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.idDependenciaPrimerNivel;
        this.detalleFuncionario.liderDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.liderDependenciaPrimerNivel;
        this.detalleFuncionario.dependenciaEspecifica = funcionarioData.configuracionFuncionario.dependencia;
        this.detalleFuncionario.idDependenciaEspecifica = funcionarioData.configuracionFuncionario.idDependencia;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  /**
    * Metodo para actualizar la variable global que muestra el sumatoria de modos
    */
  actualizarSumatoriaModos() {
    if (Array.isArray(this.comision.modoLista) && this.comision.modoLista.length) {
      return this.comision.modoLista.reduce<number>((sum: number, modoVar: CmsModosDTO) => {
        return Number(sum + Number(modoVar.porcentajeRecursos));
      }, Number(0));
    }
  }


  listarAnexosPorCancelar(idComisionVersion) {
    forkJoin(
      this.comisionesService.srvConsultarAnexoAdjuntosConsolidado(idComisionVersion, EnumCmsTipoAnexo.SUBTIPO_GESTION_CANCELACION_ANEXOS, false)
    ).subscribe(
      ([comisionesPorLegalizarData]) => {
        this.anexosConsolidado = comisionesPorLegalizarData.adjuntosAnexosConsolidado;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }


  cargarItinerarios() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarTiquetesSolicitadosPorIdComision(this.idComision, this.comision.idComisionVersion)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.itinerarioAereoList = result.transporteAereoLista;
            if (this.itinerarioAereoList !== null && this.itinerarioAereoList.length > 0) {
              this.itinerarioAereoList.forEach(itAer => {
                if (itAer.fechaVuelo != null) {
                  itAer.fechaVuelo = new Date(itAer.fechaVuelo);
                }
              });
            }

          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          this.blockedDocument = false;
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  aceptarSubirAdjunto() {
    const nombreArchivo = this.archivos[0].nombreCompleto;
    this.tiqueteSeleccionado.rutaSoporteReserva = nombreArchivo;
  }

  irCargarTiquete(tiquete: VwCmsTiqueteAereoDTO) {
    if (tiquete.idTiqueteAereo != null) {
      this.tiqueteSeleccionado.idTiqueteAereoSolicitado = tiquete.idTiqueteAereoSolicitado;
      this.tiqueteSeleccionado.id = tiquete.idTiqueteAereo;
      this.tiqueteSeleccionado.costoReserva = tiquete.costoReserva;
      this.tiqueteSeleccionado.costoCompra = tiquete.costoCompra;
      this.tiqueteSeleccionado.fechaSalida = new Date(tiquete.fechaSalida);
      this.fechaMinima = new Date(tiquete.fechaVuelo);
      this.fechaMaxima = new Date(this.fechaMinima.getFullYear(), this.fechaMinima.getMonth(), this.fechaMinima.getDate(), 23, 59, 59, 59);
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo = {};
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo.idEstadoTiqueteAereo = tiquete.idEstado;
      this.idItinerario = tiquete.idTiqueteAereoSolicitado;
      this.archivos = [];
    } else {
      this.tiqueteSeleccionado = {};
      this.tiqueteSeleccionado.fechaSalida = new Date(tiquete.fechaVuelo);
      this.fechaMinima = new Date(tiquete.fechaVuelo);
      this.fechaMaxima = new Date(this.fechaMinima.getFullYear(), this.fechaMinima.getMonth(), this.fechaMinima.getDate(), 23, 59, 59, 59);

      this.archivos = [];
      this.idItinerario = tiquete.idTiqueteAereoSolicitado;
    }
    this.dialogoCargarTiquete = true;
  }

  irCargarCompraTiquete(tiquete: VwCmsTiqueteAereoDTO) {
    if (tiquete.idTiqueteAereo != null) {
      this.tiqueteSeleccionado.id = tiquete.idTiqueteAereo;
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo = {};
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo.idEstadoTiqueteAereo = tiquete.idEstado;
      this.idItinerario = tiquete.idTiqueteAereoSolicitado;
      this.archivos = [];
    }
    this.dialogoCargarCompraTiquete = true;
  }

  irVerObservaciones(tiquete: VwCmsTiqueteAereoDTO) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarObservacionesPorIdTiquete(tiquete.idTiqueteAereo)
    ).subscribe(
      ([
        observacionesData
      ]) => {
        this.observacionesList = observacionesData.tiquetes;
        this.dialogoObservacionesTiquete = true;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  guardarTiquete() {
    if (!this.formTiquete.valid) {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    } else {
      this.blockedDocument = true;
      this.dialogoCargarTiquete = false;
      const nombreArchivo = this.archivos[0].nombreCompleto;
      this.tiqueteSeleccionado.rutaSoporteReserva = nombreArchivo;
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo = {};
      this.tiqueteSeleccionado.cmsEstadoTiqueteAereo.observaciones = "Tiquete reservado";
      this.tiqueteSeleccionado.idTiqueteAereoSolicitado = this.idItinerario;
      this.archivos = [];
      forkJoin(
        this.comisionesService.srvReservarTiqueteComision(this.tiqueteSeleccionado)
      ).subscribe(
        () => {
          this.cargarItinerarios();
          this.lanzarMensajeInfo("La reserva se guardó exitosamente.");
        },
        (error) => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
          this.dialogoCargarTiquete = false;
        },
        () => {
          this.blockedDocument = false;
          this.dialogoCargarTiquete = false;
        }
      );
    }
  }

  irAgregarAdjuntosAnexosCancelacion(idSolicitud: number, idComisionVersion: number) {
    this.idComision = idSolicitud;
    this.nombreTipoDocumentoCancelacion = EnumCmsConstantes.CMS_CARPETA_CANCELACION_BLOB_STORAGE;
    this.rutaCloudStorageInformeCancelacion = this.idComision + '/' + EnumCmsConstantes.CMS_CARPETA_CANCELACION_BLOB_STORAGE + '/';
    this.listarAnexosPorCancelar(idComisionVersion);
    this.visibleDialogoSubirAnexos = true;
  }

  irEliminarDocumento(id) {
    this.visibleDialogoEliminarDocumentoCancelacion = true;
    this.idDocumentoCancelacion = id;
  }

  aceptarEliminarDocumento() {
    this.visibleDialogoEliminarDocumentoCancelacion = false;
    this.blockedDocument = true;
    if (this.idDocumentoCancelacion != null) {
      this.comisionesService.srvEliminarAnexoArchivoComision(this.idDocumentoCancelacion).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.listarAnexosPorCancelar(this.comision.idComisionVersion);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
          this.ngOnInit();
        }
      );
    } else {
      this.blockedDocument = false;
    }

  }

  cancelarDialogoSubirAnexos() {
    this.visibleDialogoSubirAnexos = false;
  }

  aceptarAdjuntoAnexos() {
    this.guardarSoporteCancelacion(this.comision.idComisionVersion, this.tipoSoporteSeleccionado.id, this.adjuntosAnexos[0].nombreCompleto, this.adjuntosAnexos[0].nombre);
    this.listarAnexosPorCancelar(this.comision.idComisionVersion);
  }

  guardarSoporteCancelacion(idComisionVersion: number, idTipoSoporte: number, rutaSoporte: string, nombreArchivoUsuario: string) {

    const anexosComisionDTO: CmsAnexoComisionDTO = {};
    const archivosAnexosComisionDTO: CmsArchivoAnexoComisionDTO = {};
    anexosComisionDTO.idComisionVersion = idComisionVersion;
    anexosComisionDTO.id = 0;
    archivosAnexosComisionDTO.rutaSoporte = rutaSoporte;
    archivosAnexosComisionDTO.nombreArchivoUsuario = nombreArchivoUsuario;
    anexosComisionDTO.adjuntos = [];
    anexosComisionDTO.adjuntos.push(archivosAnexosComisionDTO);
    switch (idTipoSoporte) {
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_SOLICITUD_CANCELACION_TIQUETES: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.CANCELACION_TIQUETES;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_SOLICITUD_CANCELACION_RP: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.CANCELACION_RP;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_SOLICITUD_CANCELACION_ACTO_ADMINISTRATIVO: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.CANCELACION_ACTO_ADMINISTRATIVO;
        break;
      }
      default: {
        anexosComisionDTO.idTipoAnexo = null;
        break;
      }
    }

    //Validar datos anexos archivos
    let esAnexoValido = true;
    if (anexosComisionDTO.idTipoAnexo == null || anexosComisionDTO.idTipoAnexo == 0) {
      esAnexoValido = false;
      this.lanzarMensajeWarning("Debe relacionar el tipo de Anexo: " + anexosComisionDTO.idTipoAnexo)
    }
    if (anexosComisionDTO.idComisionVersion == null || anexosComisionDTO.idComisionVersion == 0) {
      esAnexoValido = false;
      this.lanzarMensajeWarning("Debe relacionar el IdComisionVersion: " + anexosComisionDTO.idComisionVersion);
    }
    if (anexosComisionDTO.adjuntos == null || anexosComisionDTO.adjuntos.length <= 0) {
      esAnexoValido = false;
      this.lanzarMensajeWarning("Debe relacionar al menos un adjunto al Anexo: " + (anexosComisionDTO.adjuntos == null ? 0 : anexosComisionDTO.adjuntos.length));

    }

    if (esAnexoValido) {
      forkJoin(
        this.comisionesService.srvGuardarAnexoArchivoComision(anexosComisionDTO)
      ).subscribe(
        ([result]) => {

          if (result != null && result.respuestaServicio != null) {

            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

              this.listarAnexosPorCancelar(this.comision.idComisionVersion);

              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);

              this.visibleDialogoSubirAnexos = false;

            } else if (result.respuestaServicio.codigoSalida === 102) {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }

  cancelarDialogoSubirAdjuntos() {
    this.dialogoCargarTiquete = false;
  }

  cancelarComision() {
    this.blockedDocument = true;
    this.visibleDialogoFinalizarCancelacion = false;
    this.cmsComisionModificacion = {
      idComision: this.comision.idComision,
      idComisionVersion: this.comision.idComisionVersion,
      observaciones: this.observacionesCancelacion
    }
    this.cmsComisionModificacion.idPaso = EnumCmsConstantes.CMS_PASO_COMISION_CANCELADA_GESTIONADA_VAF;

    if (this.formCancelarComision.valid) {
      forkJoin(
        this.comisionesService.srvCancelarComision(this.cmsComisionModificacion)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {

            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.bntVolver();
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            this.blockedDocument = false;
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        },
        () => {
          this.blockedDocument = false;
        }
      );
    } else {
      this.messageService.add({ severity: "error", summary: "Error", detail: "El campo justificación de cancelación es obligatorio.", life: 10000, });
      this.blockedDocument = false;
    }

  }

  cancelarDialogoSubirCompraAdjuntos() {
    this.dialogoCargarCompraTiquete = false;
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsTipoAnexo() {
    return EnumCmsTipoAnexo;
  }

  bntVolver() {
    const ruta = "/comisiones/comisiones-revisar-solicitudes/";
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
