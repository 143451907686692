import { TrnDependenciaAni } from 'src/app/parametricas/trn-dependencia-ani';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { CtoOrigenRecursoDTO } from './cto-origen-recurso';
import { CtoImputacionRecursoDTO } from './cto-imputacion-recurso';
import { CtoTipoRecursoDTO } from './cto-tipo-recurso';
import { CtoTipoGarantiaDTO } from './cto-tipo-garantia';
import { CtoAmparosGarantiaDTO } from './cto-amparos-garantia';
import { CtoAseguradoraDTO } from './cto-aseguradora';
import { CtoRegimenContratacionDTO } from './cto-regimen-contratacion';
import { CtoModalidadSeleccionDTO } from './cto-modalidad-seleccion';
import { CtoTipoContratoDTO } from './cto-tipo-contrato';

export class CtoContratoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public contrato?: CtoContratoDTO,
        public contratosLista?: CtoContratoDTO[],
        public idContrato?: number,
    ) { }
}
export class CtoContratoDTO {
    constructor(
        public idRegistro?: string,
        public id?: number,
        public idPersona?: number,
        public idPersonaJuridica?: number,
        public idTipoDocumento?: string,
        public numeroDocumento?: string,
        public digitoVerificacion?: number,
        public nombreContratista?: string,
        public representanteLegal?: string,
        public esResponsableIva?: boolean,
        public estaObligadoFacturar?: boolean,
        public codigoColombiaCompraEficiente?: string,
        public idResponsableContrato?: number,
        public responsableContrato?: string,
        public idResponsableContratoObject?: any,
        public idOrigenRecurso?: number,
        public idOrigenRecursoObject?: CtoOrigenRecursoDTO,
        public origenRecurso?: string,
        public idImputacionRecurso?: number,
        public idImputacionRecursoObject?: CtoImputacionRecursoDTO,
        public imputacionRecurso?: string,
        public idTipoRecurso?: number,
        public idTipoRecursoObject?: CtoTipoRecursoDTO,
        public tipoRecurso?: string,
        public idDependenciaRecursos?: number,
        public idDependenciaRecursosObject?: TrnDependenciaAni,
        public dependenciaRecursos?: string,
        public idPersonaOrdenador?: number,
        public personaOrdenador?: string,
        public idPersonaOrdenadorObject?: any,
        public fechaSuscripcion?: Date,
        public fechaSuscripcionFormat?: string,
        public numeroContrato?: string,
        public objetoContrato?: string,
        public valorContrato?: number,
        public honorariosMensuales?: number,
        public fechaInicio?: Date,
        public vigencia?: number,
        public plazoDias?: number,
        public plazoMeses?: number,
        public plazoAnos?: number,
        public fechaTerminacionContrato?: Date,
        public idTipoGarantia?: number,
        public idTipoGarantiaObject?: CtoTipoGarantiaDTO,
        public tipoGarantia?: string,
        public fechaExpedicionGarantia?: Date,
        public idAmparosGarantia?: number,
        public idAmparosGarantiaObject?: CtoAmparosGarantiaDTO,
        public amparosGarantia?: string,
        public idAseguradora?: number,
        public aseguradora?: string,
        public idAseguradoraObject?: CtoAseguradoraDTO,
        public numeroPoliza?: string,
        public tieneSupervisor?: boolean,
        public idRegimenContratacion?: number,
        public idRegimenContratacionObject?: CtoRegimenContratacionDTO,
        public regimenContratacion?: string,
        public idModalidadSeleccion?: number,
        public idModalidadSeleccionObject?: CtoModalidadSeleccionDTO,
        public modalidadSeleccion?: string,
        public idTipoContrato?: number,
        public idTipoContratoObject?: CtoTipoContratoDTO,
        public tipoContrato?: string,
        public numeroCdp?: number,
        public fechaCdp?: Date,
        public numeroRp?: number,
        public fechaRp?: Date,
        public vistosBuenos?: string,
        public observaciones?: string,
        public usuarioActualizacion?: number,
        public esBorrador?: boolean,
        public esTiempoEjecucionContratoEnFecha?: boolean,
        public tiempoEjecucionFechaTerminacionCalculada?: Date
    ) { }
}


