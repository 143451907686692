import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ProyectoCarretero } from '../../../informacion-proyecto-carretero/proyectoCarretero';
import { EtapaProyectoCarretero } from './etapaProyectoCarretero';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { EtapasProyecto } from './etapaProyecto';


@Injectable({
    providedIn: 'root'
})
export class EtapaProyectoCarreteroService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public guardarEtapa(data: EtapaProyectoCarretero, idProyecto, idConfiguracion, esBorrador) {
        return this.http.put(this.END_POINT + '/CrrEtapaProyecto/guardarEtapa/' + idProyecto + '/' + idConfiguracion + '/' + esBorrador, data)
            .pipe(
                retry(1));
    }

    public obtenerEtapas(idProyecto, esBorrador, idConfiguracion): Observable<ConsultaEtapasProyectoRpt> {
        return this.http.get<ConsultaEtapasProyectoRpt>(this.END_POINT + '/CrrEtapaProyecto/obtenerEtapas/' + idProyecto + '/' + esBorrador + '/' + idConfiguracion)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }


}

export class ConsultaEtapasProyectoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public etapasProyecto?: EtapasProyecto[]
    ) { }
}
