import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { ContratacionService } from '../contratacion.service';
import { PrsFuncionario } from '../../admin-personas/prs-funcionario';
import { TrnDependenciaAni, EnumTrnDependenciaAni } from '../../parametricas/trn-dependencia-ani';
import { CtoContratoInformacionVigenteSupervisorDTO } from '../contratacion-dto/cto-contrato-supervisor';
import { ContratoSupervisorService } from './contrato-supervisor.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumFechaFormato, EnumRegex, EnumRespuestaServicio } from '../../comun/constantes/constantes-comunes';
import { DatePipe } from '@angular/common';
import { EnumCtoEstadoContrato } from '../constantes/contratacion-constantes';

@Component({
  selector: 'app-contrato-supervisor',
  templateUrl: './contrato-supervisor.component.html',
  styles: []
})
export class ContratoSupervisorComponent implements OnInit {

  blockedDocument = false;

  // Filtros
  buscarFiltro?: string;

  dependenciasAniPrimerNivelFiltro: TrnDependenciaAni[];
  dependenciasAniSegundoNivelFiltro: TrnDependenciaAni[] = [];
  dependenciaAniPrimerNivelSeleccionadaFiltro: TrnDependenciaAni;
  dependenciaAniSegundoNivelSeleccionadaFiltro: TrnDependenciaAni;
  supervisoresFuncionariosAniFiltro: PrsFuncionario[];
  supervisorActualFiltro?: PrsFuncionario;

  // Contratos del supervisor supervisorActualFiltro
  contratos: CtoContratoInformacionVigenteSupervisorDTO[] = [];
  totalRecords: number;
  contratosSeleccionados: CtoContratoInformacionVigenteSupervisorDTO[] = [];

  supervisoresPorContratosActual: PrsFuncionario[];
  // supervisoresPorContratosActual: CtoContratoSupervisor[];

  dependenciasAniPrimerNivelParaHacerCambio: TrnDependenciaAni[];
  dependenciasAniSegundoNivelParaHacerCambio: TrnDependenciaAni[];
  dependenciaAniPrimerNivelSeleccionadaParaHacerCambio?: TrnDependenciaAni;
  dependenciaAniSegundoNivelSeleccionadaParaHacerCambio?: TrnDependenciaAni;

  dependenciaAniSupervisorParaHacerCambio?: TrnDependenciaAni;
  supervisoresParaHacerCambio: PrsFuncionario[];
  supervisorActualParaHacerCambio: PrsFuncionario;
  supervisorNuevoParaHacerCambio: PrsFuncionario;

  funcionariosSupervisoresMix: any[] = [];

  visualizarSeccionCambioSupervisor = false;
  visualizarDialogoSupervisorNuevo = false;

  validarSeleccionContratosMasivamente = false;

  visibleDialogoExito = false;
  mensajeDialogo = "";

  msgRecuerdeContratosMasUnoSupervisor = 'Recuerde que los contratos con más de un supervisor los deberá modificar uno por uno';
  msgRecuerdeContratosVigentes = 'Recuerde que se listarán solo los contratos vigentes a la fecha';

  constructor(
    private datepipe: DatePipe,
    private messageService: MessageService,
    private contratacionService: ContratacionService,
    private contratoSupervisorService: ContratoSupervisorService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent
  ) { }


  ngOnInit() {
  }

  buscar() {
    this.limpiarVariablesContratos();
    this.limpiarVariablesSupervisor();

    if (!this.validarBuscarFiltros()) {

      this.dependenciaAniSupervisorParaHacerCambio = this.dependenciaAniPrimerNivelSeleccionadaFiltro;

      const filaDesde = null;
      const cantidadRegistros = null;
      this.blockedDocument = true;
      this.cargarContratos(filaDesde, cantidadRegistros, 'Id', 2);


    }
  }


  validarBuscarFiltros() {
    let error = false;
    if (this.dependenciaAniPrimerNivelSeleccionadaFiltro == null || (this.dependenciaAniPrimerNivelSeleccionadaFiltro != null && this.dependenciaAniPrimerNivelSeleccionadaFiltro.idDependenciaPrimerNivel == null)) {
      error = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No ha seleccionado la primera dependencia de los filtros de búsqueda', life: 10000 });
    }
    if (this.dependenciaAniSegundoNivelSeleccionadaFiltro == null || (this.dependenciaAniSegundoNivelSeleccionadaFiltro != null && this.dependenciaAniSegundoNivelSeleccionadaFiltro.id == null)) {
      error = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No ha seleccionado la segunda dependencia de los filtros de búsqueda', life: 10000 });
    }
    if (this.supervisorActualFiltro == null || (this.supervisorActualFiltro != null && this.supervisorActualFiltro.id == null)) {
      error = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No ha seleccionado el supervisor actual de la lista', life: 10000 });
    }

    return error;
  }

  limpiarFiltros() {
    this.dependenciaAniPrimerNivelSeleccionadaFiltro = null;
    this.dependenciaAniSegundoNivelSeleccionadaFiltro = null;
    this.supervisoresFuncionariosAniFiltro = null;
    this.supervisorActualFiltro = null;
    this.buscarFiltro = null;
    this.limpiarVariablesContratos();
  }

  limpiarVariablesSupervisor() {
    this.visualizarSeccionCambioSupervisor = false;

    this.supervisoresPorContratosActual = null;

    this.limpiarDialogoSupervisor();
  }


  limpiarVariablesContratos() {
    this.contratos = [];
    this.contratosSeleccionados = [];
  }

  cargarDependenciasAniPrimerNivel(event) {
    const filtroDependenciasAniPrimerNivelFiltro = [];
    this.parametricasService.ConsultarDependenciasAniPorTipo(EnumTrnDependenciaAni.TRN_DEPENDENCIA_ANI_PRESIDENCIA_TIPO + ',' + EnumTrnDependenciaAni.TRN_DEPENDENCIA_ANI_VICEPRESIDENCIA_TIPO + ',' + EnumTrnDependenciaAni.TRN_DEPENDENCIA_ANI_OFICINA_TIPO)
      .subscribe(data => {
        this.dependenciasAniPrimerNivelFiltro = data;
        if (event != null) {
          let existeFiltro: boolean;
          for (const dpn of this.dependenciasAniPrimerNivelFiltro) {
            if (String(dpn.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroDependenciasAniPrimerNivelFiltro.push(dpn);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.dependenciasAniPrimerNivelFiltro = filtroDependenciasAniPrimerNivelFiltro;
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarDependenciasAniSegundoNivel(event) {
    const filtroDependenciasAniSegundoNivelFiltro = [];
    this.parametricasService.ConsultarDependenciasAniPorIdDependenciaPrimerNivel(this.dependenciaAniPrimerNivelSeleccionadaFiltro.idDependenciaPrimerNivel)
      .subscribe(data => {
        this.dependenciasAniSegundoNivelFiltro = data;
        if (event != null) {
          let existeFiltro: boolean;
          for (const dpn of this.dependenciasAniSegundoNivelFiltro) {
            if (String(dpn.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroDependenciasAniSegundoNivelFiltro.push(dpn);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.dependenciasAniSegundoNivelFiltro = filtroDependenciasAniSegundoNivelFiltro;
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarSupervisoresAniFiltro(event) {
    const filtroPrsFuncionario = [];
    this.contratacionService.ConsultarPersonasSupervisoresPorDependencia(this.dependenciaAniSegundoNivelSeleccionadaFiltro.id)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.supervisoresFuncionariosAniFiltro = result.funcionarios;
              if (event != null) {
                let existeFiltro: boolean;
                for (const fun of this.supervisoresFuncionariosAniFiltro) {
                  if (String(fun.nombre).toLowerCase().includes(event.query.toLowerCase()) || String(fun.correoElectronico).toLowerCase().includes(event.query.toLowerCase())) {
                    filtroPrsFuncionario.push(fun);
                    existeFiltro = true;
                  }
                }
                if (existeFiltro) {
                  this.supervisoresFuncionariosAniFiltro = filtroPrsFuncionario;
                }
              }
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
          return this.supervisoresFuncionariosAniFiltro;
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarContratos(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.blockedDocument = true;
    const fechaActual = new Date();
    this.contratacionService.srvConsultarContratosInformacionVigente(null, null, null, this.supervisorActualFiltro.idPersona, null, fechaActual.getFullYear().toString(), null, filaDesde, cantidadRegistros, this.buscarFiltro, campoOrden, ascDesc)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.contratos = result.contratosInformacionVigente;
              this.contratos.forEach(cto => {
                cto.habilitarModificarSupervisor = false;
              });
              this.contratacionService.srvCalcularEstadoVigenciaContrato(this.contratos);
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  onRowSelect(event) {
    const ctoSeleccionado = event.data;
    if (this.contratosSeleccionados != null && this.contratosSeleccionados.length == 1) {
      ctoSeleccionado.habilitarModificarSupervisor = true;
    } else {
      this.contratosSeleccionados.forEach(cto => {
        cto.habilitarModificarSupervisor = false;
      });
    }
  }

  onRowUnselect(event) {
    const ctoSeleccionado = event.data;
    ctoSeleccionado.habilitarModificarSupervisor = false;
    if (!ctoSeleccionado.habilitarModificarSupervisorMasivamente) {
      ctoSeleccionado.habilitarModificarSupervisorMasivamente = true;
    }
  }

  irModificarSupervisorMasivamente() {

    const contratosMasUnSupervisor = this.contratosSeleccionados.filter(cto => cto.cantidadSupervisores > 1).length;

    if (contratosMasUnSupervisor > 0) {
      this.contratosSeleccionados.forEach(cto => {
        if (cto.cantidadSupervisores > 1) {
          cto.habilitarModificarSupervisorMasivamente = false;
        }
      });
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Para modificar masivamente el supervisor de los contratos seleccionados estos deben tener un solo supervisor asociado, por favor deseleccione estos contratos e intenta nuevamente. Los contratos con más de un supervisor los debe modificar uno por uno', life: 10000 });
    } else {
      this.visualizarDialogoSupervisorNuevo = true;
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: this.msgRecuerdeContratosMasUnoSupervisor });
      this.cargarSupervisoresPorContratosSeleccionados();
    }
  }

  irModificarSupervisorParticular() {
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: this.msgRecuerdeContratosMasUnoSupervisor });
    this.visualizarDialogoSupervisorNuevo = true;
    this.cargarSupervisoresPorContratosSeleccionados();


  }

  cargarSupervisoresPorContratosSeleccionados() {
    const idsContrato: number[] = [];
    this.contratosSeleccionados.forEach(cto => {
      idsContrato.push(cto.id);
    });

    this.supervisoresPorContratosActual = null;

    if (idsContrato != null && idsContrato.length > 0) {
      this.contratoSupervisorService.ConsultarFuncionariosSupervisoresAgrupadosParaCambioPorContratos(idsContrato).subscribe(
        result => {
          if (result != null && result.length > 0) {
            this.supervisoresPorContratosActual = result;
            // Llenar listado para hacer cambio
            this.cargarInfoCambioSupervisor();
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
    }
  }

  cargarInfoCambioSupervisor() {
    this.funcionariosSupervisoresMix = [];
    const idsContrato: number[] = [];
    this.contratosSeleccionados.forEach(cto => {
      idsContrato.push(cto.id);
    });
    this.supervisoresPorContratosActual.forEach(sup => {
      const funcionarioSupervisor = {
        supervisorActual: sup,
        supervisorNuevo: null,
        idsContrato,
        observacionAsignacion: null
      };
      this.funcionariosSupervisoresMix.push(funcionarioSupervisor);
    });


  }

  irCambiarSupervisor(supervisor: PrsFuncionario) {
    this.visualizarDialogoSupervisorNuevo = true;
    this.supervisorActualParaHacerCambio = {
      id: supervisor.id
    };
    this.cargarSupervisoresPorDependenciaParaHacerCambio(null);
  }

  cargarSupervisoresPorDependenciaParaHacerCambio(event) {
    const filtroSupervisoresPorDependenciaParaHacerCambio = [];
    this.contratacionService.ConsultarFuncionariosSupervisoresPorDependencia(this.dependenciaAniSupervisorParaHacerCambio.idDependenciaPrimerNivel, new Date())
      .subscribe(data => {
        this.supervisoresParaHacerCambio = data;
        if (event != null) {
          let existeFiltro: boolean;
          for (const fun of this.supervisoresParaHacerCambio) {
            if (String(fun.nombre).toLowerCase().includes(event.query.toLowerCase()) || String(fun.correoElectronico).toLowerCase().includes(event.query.toLowerCase())) {
              filtroSupervisoresPorDependenciaParaHacerCambio.push(fun);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.supervisoresParaHacerCambio = filtroSupervisoresPorDependenciaParaHacerCambio;
          }
        }
        return this.supervisoresParaHacerCambio;
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  validarAsignacionSupervisor() {
    let error = false;
    if (this.contratosSeleccionados == null || this.contratosSeleccionados.length == 0) {
      error = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar al menos un contrato para asignar el visto bueno', life: 10000 });
    }
    let cantidadSupervisoresActuales = 0;
    let cantidadSupervisoresNuevos = 0;
    this.funcionariosSupervisoresMix.forEach(cambio => {
      if (cambio.supervisorActual != null) {
        cantidadSupervisoresActuales++;
      }
      if (cambio.supervisorNuevo != null) {
        cantidadSupervisoresNuevos++;
      }
    });

    if (cantidadSupervisoresNuevos == 0) {
      error = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar al menos un supervisor diferente a los asignados al contrato', life: 10000 });
    } else {
      this.funcionariosSupervisoresMix.forEach(cambio => {
        cambio.observacionAsignacion = '';
        if (cambio == null) {
          error = true;
          cambio.observacionAsignacion = cambio.observacionAsignacion + 'Debe cambiar al menos un supervisor';
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe cambiar al menos un supervisor', life: 10000 });
        }
        if (cambio.supervisorActual == null) {
          error = true;
          cambio.observacionAsignacion = cambio.observacionAsignacion + 'El supervisor actual no ha sido seleccionado';
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El supervisor actual no ha sido seleccionado', life: 10000 });
        }
        if (cambio.idsContrato == null) {
          error = true;
          cambio.observacionAsignacion = cambio.observacionAsignacion + 'No seleccionó los contratos a los cuales se les asignará el nuevo supervisor';
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No seleccionó los contratos a los cuales se les asignará el nuevo supervisor', life: 10000 });
        }
        if (cambio.supervisorActual != null && cambio.supervisorNuevo != null && cambio.supervisorActual.id == cambio.supervisorNuevo.id) {
          error = true;
          cambio.observacionAsignacion = cambio.observacionAsignacion + 'Ya se encuentra relacionado el supervisor: ' + cambio.supervisorActual.nombre + ' - ' + cambio.supervisorActual.correoElectronico;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ya se encuentra relacionado el supervisor: ' + cambio.supervisorActual.nombre + ' - ' + cambio.supervisorActual.correoElectronico, life: 10000 });
        }

      });

      let idsNuevosSupervisores = this.funcionariosSupervisoresMix.filter(cambio => cambio.supervisorNuevo != null && cambio.supervisorActual.id != null).map(cambio => cambio.supervisorNuevo.id);
      this.funcionariosSupervisoresMix.forEach(cambio => {
        if (cambio.supervisorActual != null && cambio.supervisorNuevo != null && cambio.supervisorActual.id != null && idsNuevosSupervisores.includes(cambio.supervisorActual.id)) {
          error = true;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ya se encuentra relacionado el supervisor: ' + cambio.supervisorActual.nombre + ' - ' + cambio.supervisorActual.correoElectronico, life: 10000 });
        }
      });

    }




    return error;
  }

  guardarSupervisorContrato() {
    this.blockedDocument = true;
    this.visualizarDialogoSupervisorNuevo = false;
    if (!this.validarAsignacionSupervisor()) {
      this.funcionariosSupervisoresMix = this.funcionariosSupervisoresMix.filter(cambio => cambio.supervisorActual != null && cambio.supervisorNuevo != null && cambio.supervisorActual.id != null && cambio.supervisorNuevo.id != null);

      this.contratoSupervisorService.GuardarContratosSupervisor(this.funcionariosSupervisoresMix).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

              this.visibleDialogoExito = true;

              this.mensajeDialogo = result.respuestaServicio.mensajeSalida;
              this.limpiarDialogoSupervisor();

              this.buscar();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    } else {
      this.blockedDocument = false;
    }
  }

  cancelarModificarSupervisor() {
    this.buscar();
  }

  cancelarDialogoSupervisor() {
    this.blockedDocument = false;
    this.visualizarDialogoSupervisorNuevo = false;
    this.limpiarDialogoSupervisor();
  }

  limpiarDialogoSupervisor() {
    this.dependenciaAniPrimerNivelSeleccionadaParaHacerCambio = null;
    this.dependenciaAniSegundoNivelSeleccionadaParaHacerCambio = null;
    this.dependenciasAniPrimerNivelParaHacerCambio = null;
    this.dependenciasAniSegundoNivelParaHacerCambio = null;
    this.supervisoresParaHacerCambio = null;

    this.supervisorNuevoParaHacerCambio = null;
    this.supervisorActualParaHacerCambio = null;
    this.funcionariosSupervisoresMix = [];
  }

  get EnumCtoEstadoContrato() {
    return EnumCtoEstadoContrato;
  }

  get EnumRegex() {
    return EnumRegex;
  }

}
