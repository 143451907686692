import { Component, OnInit, ViewChild } from '@angular/core';
import { TramitesAmbientalesPuertoService } from '../tramites-ambientales.service';
import { TramitesAmbientalesVigentesDTO, TramitesAmbientalesHistoricoDTO } from '../tramites-ambientales.dto.js';
import { Router, ActivatedRoute } from '@angular/router';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { forkJoin, Subscription } from "rxjs";
import { MessageService } from 'primeng/api';



@Component({
  selector: 'app-tramites-ambientales-listado',
  templateUrl: './tramites-ambientales-listado.component.html',
  styleUrls: ['./tramites-ambientales-listado.component.css']
})
export class TramitesAmbientalesListadoPuertoComponent implements OnInit {

  loading: boolean;
  idProyecto: number;
  idTramite: number;
  proyectoSeleccionado: ProyectoDTO;

	nombreProyecto;
  tramitesAmbientalesList: TramitesAmbientalesVigentesDTO[];
  tramitesAmbientalVigente: TramitesAmbientalesVigentesDTO;
  tramitesAmbientalesHistoricoList: TramitesAmbientalesHistoricoDTO[];
  tramitesAmbientalesHistorico: TramitesAmbientalesHistoricoDTO;
  dialogoEliminar: boolean;

  constructor(
    private router: Router,
		private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private service: TramitesAmbientalesPuertoService,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.listarTramitesAmbientalesPorProyecto();
  }

  listarTramitesAmbientalesPorProyecto() {
    this.loading = true;
    this.ruta.params.subscribe(params => {
			this.idProyecto = params.id;
      // Inicializar listas parametricas y DTOs
      forkJoin(
        this.componentesComunesService.obtenerProyecto(this.idProyecto),
        this.service.getListadoTramites(this.idProyecto),
        this.service.getListarTramitesAmbientalesPorProyectoHistorico(this.idProyecto)
      ).subscribe(
        ([proyectoData, lstTramites, lstTramitesHistorico]) => {
          this.tramitesAmbientalesList = [];
          this.proyectoSeleccionado = proyectoData;
          this.tramitesAmbientalesList = lstTramites.listaTramitesAmbientales;
          this.tramitesAmbientalesHistoricoList = lstTramitesHistorico.listaTramitesAmbientales;
          this.loading = false;
        },
        (e) => {}
      );
			localStorage.setItem('idProyectoPuerto', String(this.idProyecto));
		});
  }

	/**
	 * Metodo para crear una nuevo tramite ambiental
	 */
	btnAgregar() {
		localStorage.setItem('tipoSolicitudTA', "onNew");
		this.router.navigate([this.router.url + '/tramites-ambientales-edit', 'new']);
	}

	/**
	 * Metodo para edita un tramite ambiental
	 */
	btnConsultar(tramiteAmbientalSelected: TramitesAmbientalesVigentesDTO) {
    localStorage.setItem('tipoSolicitudTA', "onView");
		this.router.navigate([this.router.url + '/tramites-ambientales-edit', tramiteAmbientalSelected.idTramite]);
	}

	/**
	 * Metodo para editar un tramite ambiental
	 */
  btnEditar(tramiteAmbientalSelected: TramitesAmbientalesVigentesDTO) {
    localStorage.setItem('tipoSolicitudTA', "onEdit");
		this.router.navigate([this.router.url + '/tramites-ambientales-edit', tramiteAmbientalSelected.idTramite]);
	}

  /**
	 * Metodo para visualizar el seguimiento de un tramite ambiental
	 */
  btnSeguimiento(tramiteAmbientalSelected: TramitesAmbientalesVigentesDTO) {
		this.router.navigate([this.router.url + '/tramites-ambientales-seguimiento', tramiteAmbientalSelected.idTramite]);
	}

  /**
	 * Metodo para cargar modal de una confirmacion de eliminacion
	 */
  btnEliminar(tramiteAmbientalSelected: TramitesAmbientalesVigentesDTO) {
		this.idTramite = tramiteAmbientalSelected.idTramite;
    this.dialogoEliminar = true;
	}

	/**
	 * Metodo para el seguimiento de un tramite ambiental
	 * @param tramiteAmbientalSelected
	 */
	btnConsultarHistorico(tramiteAmbientalSelected: TramitesAmbientalesVigentesDTO) {
    localStorage.setItem('tipoSolicitudTA', "onViewHistorico");
		this.router.navigate([this.router.url + '/tramites-ambientales-edit', tramiteAmbientalSelected.idTramite]);
	}

  /**
   * Metodo que acepta la eliminacion del registro
   */
  submitEliminar(){
    this.loading = true;
    forkJoin(
      this.service.srvEliminarTramite(
        this.idTramite)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // console.log('----------srvEliminarPredio');
            // console.log(result.respuestaServicio.mensajeSalida);
            //  Consultar nuevamente la lista
            this.listarTramitesAmbientalesPorProyecto();
            //  Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            // // console.log(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // // console.log('Ocurrio un error al consumir el servicio srvEliminarPredio');
        }
      },
      (error) => {
        // // console.log(error);
      },
      () => {
        // Desbloquear pantalla
        this.dialogoEliminar = false;
        this.loading = false;
      }
    );
  }

  /**
   * metodo que devuelve la pagina
   */
  bntVolver(){
    const ruta = "puertos/infGeneral/proyectoHome/" + this.idProyecto;
    this.router.navigate([ruta]);
  }

// // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
