import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IngCuposPisoDTO } from '../../puestos-trabajo.dto';
import { PuestosTrabajoService } from '../../puestos-trabajo.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-listar-cupos',
  templateUrl: './listar-cupos.component.html',
  styleUrls: ['./listar-cupos.component.sass']
})
export class ListarCuposComponent implements OnInit {

  blockedDocument: boolean;
  cuposList: IngCuposPisoDTO[];

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private puestosTrabajoService: PuestosTrabajoService,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService) { }

  ngOnInit() {
    this.listarCupos();
  }

  listarCupos() {
    this.blockedDocument = true;
    forkJoin(
      this.puestosTrabajoService.srvListarCupos()
    ).subscribe(
      ([cuposData]) => {
        console.log(cuposData);
        this.cuposList = cuposData.cupos;
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  btnAgregar() {
    this.router.navigate([this.router.url + "/crear-editar-cupos/0"]);
  }

  btnEditar(cupoSelected: IngCuposPisoDTO) {
    this.lanzarMensajeInfo("Editar!!!");
    this.router.navigate([
      this.router.url +
      "/crear-editar-cupos/" +
      cupoSelected.id,
    ]);

  }

  btnEliminar(cupoSelected: IngCuposPisoDTO) {
    this.lanzarMensajeInfo("Editar!!!");

  }

  btnVolverHomeAdmin() {
    this.router.navigate(["/home-puestos-trabajo/administracion-home"]);
  }


  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
