import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { EnumTipoDocumento } from '../constantes/constantes-comunes';
import { PersonaService } from './persona.service';

@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.sass'],
  providers: [PersonaService]
})
export class PersonaComponent implements OnInit {
  tiposDocumento: any = [];
  personas: any = [];
  numeroIdentificacion: string;
  primerNombre: string;
  otrosNombres: string;
  primerApellido: string;
  segundoApellido: string;
  tipoDocumento: string;
  correoElectronico: string;
  personaCargada: Boolean;

  

  @Output() idPersonaChange = new EventEmitter<number>();



  /**
   * Indica el tipo de persona. 1 Persona Externa, 2. Persona Interna
   */
  @Input() tipoPersona: number = 2;
  @Input() idPersona: number = -1;
  @Input() idTipoDocumento: string;
  constructor(
    private svcPersonas: PersonaService,
    private parametricasService: ParametricasService) { }

  ngOnInit() {
    this.cargarTiposDocumento();
    if (this.idTipoDocumento != null) {
      this.tipoDocumento = this.idTipoDocumento;
    }
    this.cargarPersonas();
    if (this.idPersona > 0) {
      this.consultarPersona();
    }
  }

  consultarPersona() {
    this.svcPersonas.getPersona(this.idPersona).subscribe((data: any) => {
      if (data != null) {
        this.primerNombre = data.primerNombre;
        this.otrosNombres = data.otrosNombres;
        this.primerApellido = data.primerApellido;
        this.segundoApellido = data.segundoApellido;
        this.numeroIdentificacion = data.numeroDocumento;
        this.tipoDocumento = data.idTipoDocumento;
        this.personaCargada = true;
      }
    });
  }
  resetearCamposPersonaExterna() {
    this.primerNombre = '';
    this.otrosNombres = '';
    this.primerApellido = '';
    this.segundoApellido = '';
  }
  cargarTiposDocumento() {
    this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.CC + ',' + EnumTipoDocumento.NIT + ',' + EnumTipoDocumento.NIEE).subscribe((data: {}) => {
      this.tiposDocumento = data;
    });
  }

  cargarPersonas() {
    this.svcPersonas.getPersonas().subscribe((data: {}) => {
      this.personas = data;
    })
  }

  cambioNumeroIdentificacion() {
    if (this.tipoPersona == 2) {
      var persona = this.personas.filter(p => p.numeroDocumento == this.numeroIdentificacion && p.idTipoDocumento == this.tipoDocumento);
      if (persona != null && persona.length > 0) {
        this.personaCargada = true;
        this.primerNombre = persona[0].primerNombre
        this.otrosNombres = persona[0].otrosNombres
        this.primerApellido = persona[0].primerApellido;
        this.segundoApellido = persona[0].segundoApellido;
        this.idPersona = persona[0].id;
        // this.idPersonaChange.emit(this.idPersona);
      } else {
        this.idPersona = -1;
        this.idPersonaChange.emit(this.idPersona);
        this.personaCargada = false;
        this.resetearCamposPersonaExterna();
      }
    }
  }

  retornarPersonaCargada() {
    this.idPersonaChange.emit(this.idPersona);
  }

  guardarPersona() {
    this.svcPersonas.setPersona({
      'primerNombre': this.primerNombre,
      'otrosNombres': this.otrosNombres,
      'primerApellido': this.primerApellido,
      'segundoApellido': this.segundoApellido,
      'numeroDocumento': this.numeroIdentificacion,
      'idTipoDocumento': this.tipoDocumento,
      'correoElectronico': this.correoElectronico,
      'idFuenteDatos': 1
    }).subscribe((data: any) => {
      this.idPersona = data.id;
      this.idPersonaChange.emit(this.idPersona);
      this.personaCargada = true;
    });
  }
}
