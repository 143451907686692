import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AmparosService {

  public END_POINT = '';

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  /**
   * Servicio que carga la lista de anexo dependiendo una poliza
   * @param idPoliza 
   */
  public getAnexosPoliza(idPoliza) {
    return this.http.get(this.END_POINT + '/PlzPoliza/ConsultarAnexosPoliza/' + idPoliza )
      .pipe(
        retry(1));
  }

  
  /**
   * Metodo que elimina un anexo
   * @param idAnexo 
   */
   public deleteAnexoPoliza(idAnexo) {
    return this.http.delete(this.END_POINT + '/PlzPoliza/EliminarAnexoPoliza/' + idAnexo )
      .pipe(
        retry(1));
  }

}
