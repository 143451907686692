import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConcesionarioInviasDetalleService {
  
  public END_POINT = '';

  // Http Options
  httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  };

  constructor(private http: HttpClient) {
      this.END_POINT = environment.baseUrl;
  }

   /**
   * Metodo que carga la informacion de un concepto
   * @param idConceptoSolicitado 
   */
  public getConsultaInformacionConcepto(idConceptoSolicitado) {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/concesionario/conceptoSolicitado/' + idConceptoSolicitado ) 
    .pipe(
        retry(1));
    }

  /**
   * Metodo que carga la informacion de la consulta del radicado concepto
   * @param numeroRadicado 
   */
  public getConsultaRadicadoResuesta(numeroRadicado) {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/consultaRadicado/' + numeroRadicado ) 
    .pipe(
        retry(1));
    }

    
    /**
    * Metodo que carga la informacion de departamentos
    *
    * @returns
    * @memberof ConcesionarioInviasDetalleService
    */
   public getListaRespuestaConceptoTecnico() {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/lista/respuestaConceptos') 
    .pipe(
        retry(1));
    } 

        /**
    * Metodo que realiza la radicacion
    *
    * @returns
    * @memberof ConcesionarioInviasDetalleService
    */
   public postGuardarRespuestaConcesionario(data) {
    return this.http.post(this.END_POINT + '/CargaExtrapesada/guardarRespuestaConcesionario', data) 
    .pipe(
        retry(1));
    } 

     /**
    * Metodo que carga realiza la validacion de una finalizacion de un concepto
    * @param numeroRadicado 
    */
      public getConsultaValidarFinalizacion(idSolicitudInvias) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/invias/validar/finalizacion/' + idSolicitudInvias ) 
        .pipe(
            retry(1));
    }

}
