import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-public-ferrocarriles-consulta',
  templateUrl: './public-ferrocarriles-consulta.component.html'
})
export class PublicFerrocarrilesConsultaComponent implements OnInit {

  constructor(private route: Router) { }
  
  /**Metodo que inicializa el componente */
  ngOnInit() {
   
  }

}
