import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-map-peajes',
  templateUrl: './public-map-peajes.component.html',
  styleUrls: ['./public-map-peajes.component.sass']
})
export class PublicMapPeajesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
