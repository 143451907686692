import { Component, OnInit, Input } from '@angular/core';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { C19ReporteCovid, C19Persona } from '../covid-reportar.dto';
import { CovidReportarService } from '../covid-reportar.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-covid-reportar-seguimiento',
  templateUrl: './covid-reportar-seguimiento.component.html',
  styleUrls: ['./covid-reportar-seguimiento.component.sass']
})
export class CovidReportarSeguimientoComponent implements OnInit {

  blockedDocument: boolean = false;

  vistaActual: string;
  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  reporteCovid: C19ReporteCovid;
  reporteCovidList: C19ReporteCovid[];

  fechaMinNuevoReporte: Date;

  persona: C19Persona;

  tiposDocumento: any[];
  tipoDocumentoSeleccionado = { codigo: '-1', nombre: '' };

  constructor(private router: Router, private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private covidReportarService: CovidReportarService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    //Obtener el parametro idProyecto
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.id;
      forkJoin(
        this.componentesComunesService.obtenerProyecto(this.idProyecto)
      ).subscribe(([data1]) => {
        this.proyectoSeleccionado = data1;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    });

    //Construir array
    this.tiposDocumento = [
      { codigo: 'CC', nombre: 'Cedula' }
    ];

    this.consultarReporteEncabezadoPorProyecto();
    this.irASeguimientosReportados();
  }

  irAPeriodo() {
    //Verificar si falta algun reporte por publicar
    let faltaAlgunoPorPublicar = false;
    for (let i = 0; i < this.reporteCovidList.length; i++) {
      let reporteCovidVar = this.reporteCovidList[i];
      if (!reporteCovidVar.publicado) {
        faltaAlgunoPorPublicar = true;
      }
    }

    //Si NO falta alguno por publicar...
    if (!faltaAlgunoPorPublicar) {
      this.reporteCovid = {};
      this.persona = {};
      this.vistaActual = "periodo";
    } else {//Si falta alguno por publicar...
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Aun tiene reportes sin publicar', life: 10000 });
    }

  }

  obtenerPersona() {
    if (this.tipoDocumentoSeleccionado != null && this.reporteCovid.numeroDocumentoEncargado != null) {
      forkJoin(
        this.covidReportarService.srvObtenerPersona(this.tipoDocumentoSeleccionado.codigo, this.reporteCovid.numeroDocumentoEncargado)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.persona = result.personaEncargada;
            if (this.persona != null) {
              this.reporteCovid.primerNombreEncargado = this.persona.primerNombre;
              this.reporteCovid.otrosNombresEncargado = this.persona.otrosNombres;
              this.reporteCovid.primerApellidoEncargado = this.persona.primerApellido;
              this.reporteCovid.segundoApellidoEncargado = this.persona.segundoApellido;
            }
          } else {
            //this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            this.reporteCovid.primerNombreEncargado = null;
            this.reporteCovid.otrosNombresEncargado = null;
            this.reporteCovid.primerApellidoEncargado = null;
            this.reporteCovid.segundoApellidoEncargado = null;
          }
        } else {
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
        () => {
          // this.blockedDocument = false;
        }
      );
    }
  }

  consultarReporteEncabezadoPorProyecto() {
    forkJoin(
      this.covidReportarService.srvConsultarReporteEncabezadoPorProyecto(this.idProyecto)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.reporteCovidList = result.reporteCovid;
          if (this.reporteCovidList.length > 0) {
            this.fechaMinNuevoReporte = new Date(this.reporteCovidList[0].fechaHasta);
            this.fechaMinNuevoReporte.setDate(this.fechaMinNuevoReporte.getDate() + 1);
          } else {
            this.fechaMinNuevoReporte = null;
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        // this.blockedDocument = false;
      }
    );
  }

  guardarReporteEncabezadoPorProyecto(form: NgForm) {
    //Bloquear pantalla
    this.blockedDocument = true;
    this.reporteCovid.idProyecto = this.idProyecto;
    this.reporteCovid.idTipoDocumentoEncargado = this.tipoDocumentoSeleccionado.codigo;
    this.reporteCovid.idPersona = this.persona.id;
    forkJoin(
      this.covidReportarService.srvGuardarReporteEncabezado(this.reporteCovid)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          //Obtener el reporteCovid recien creado
          this.reporteCovid = result.reporteCovid;
          //Consultar nuevamente la lista
          this.consultarReporteEncabezadoPorProyecto();
          //Volver a reportes
          this.irAReportarSeguimiento();
          //Lanzar mensaje de exito
          this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  publicarReporteEncabezadoPorProyecto() {

    //Si el reporte aun no esta publicado...
    if (!this.reporteCovid.publicado) {
      //Lanzar mensaje de advertencia...
      this.confirmationService.confirm({
        message: '¿Esta seguro publicar el reporte? Una vez publicado no podra modificarlo',
        accept: () => {
          //Bloquear pantalla
          this.blockedDocument = true;
          this.reporteCovid.idProyecto = this.idProyecto;
          //Colocar publicado en TRUE
          this.reporteCovid.publicado = true;
          //Guardar los cambios en BD
          forkJoin(
            this.covidReportarService.srvPublicarReporteEncabezado(this.reporteCovid)
          ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
              //Si el resultado es exitoso...
              if (result.respuestaServicio.codigoSalida === 1) {
                //Consultar nuevamente la lista
                this.consultarReporteEncabezadoPorProyecto();
                //Volver a seguimientos reportados
                this.irASeguimientosReportados();
                //Lanzar mensaje de exito
                this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              }
            } else {
            }
          }, error => {
            this.principalComponent.cargarErrorServicio(error);
          },
            () => {
              //Desbloquear pantalla
              this.blockedDocument = false;
            }
          );
        }
      });
    }
  }

  irAReportarSeguimiento() {
    this.tipoDocumentoSeleccionado = null;
    this.vistaActual = "ReportarSeguimiento";
  }

  irASeguimientosReportados() {
    this.vistaActual = "seguimientosReportados";
  }

  rowSelectReporteCovid(_reporteCovid: C19ReporteCovid) {
    this.reporteCovid = _reporteCovid;
    this.vistaActual = "ReportarSeguimiento";
  }

  anularUltimaPublicacion() {

    //Verificar si falta algun reporte por publicar
    let faltaAlgunoPorPublicar = false;
    for (let i = 0; i < this.reporteCovidList.length; i++) {
      let reporteCovidVar = this.reporteCovidList[i];
      if (!reporteCovidVar.publicado) {
        faltaAlgunoPorPublicar = true;
      }
    }

    //Si NO falta alguno por publicar...
    if (!faltaAlgunoPorPublicar) {
      //Lanzar mensaje de advertencia...
      this.confirmationService.confirm({
        message: 'Esto le permitira únicamente anular una publicación realizada en la última semana. Si la publicación la realizó hace mas de una semana no podra anularse. ¿Desea continuar?',
        accept: () => {

          //Bloquear pantalla
          this.blockedDocument = true;
          //Guardar los cambios en BD
          forkJoin(
            this.covidReportarService.srvReactivarReporteEncabezado(this.idProyecto)
          ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
              //Si el resultado es exitoso...
              if (result.respuestaServicio.codigoSalida === 1) {
                //Consultar nuevamente la lista
                this.consultarReporteEncabezadoPorProyecto();
                //Volver a seguimientos reportados
                //this.irASeguimientosReportados();
                //Lanzar mensaje de exito
                this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              }
            } else {
            }
          }, error => {
            this.principalComponent.cargarErrorServicio(error);
          },
            () => {
              //Desbloquear pantalla
              this.blockedDocument = false;
            }
          );
        }
      });
    } else {//Si falta alguno por publicar...
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Aun tiene reportes sin publicar', life: 10000 });
    }
  }


}
