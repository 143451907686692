import { Component, OnInit } from '@angular/core';
import { PlaneacionDTO } from '../planeacion-proyecto.dto';
import { PlaneacionProyectoService } from '../planeacion_proyecto.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listar-planeaciones',
  templateUrl: './listar-planeaciones.component.html',
  styleUrls: ['./listar-planeaciones.component.sass']
})
export class ListarPlaneacionesComponent implements OnInit {

  public planeacionProyectoDTOList: PlaneacionDTO[];

  constructor(private route: Router, private ruta: ActivatedRoute,
    private planeacionProyectoService: PlaneacionProyectoService) { }

  ngOnInit() {
    this.listarPlaneaciones();
  }

  listarPlaneaciones() {
    return this.planeacionProyectoService.listarPlaneaciones().subscribe(data => {
      this.planeacionProyectoDTOList = data;
    });
  }

  irACrearPlaneacion() {    
    this.route.navigate(['/planeacion-proyecto/crear-planeacion']);
  }

}
