import { Component, OnInit } from '@angular/core';
import { ContratoCreditoPuente } from '../contrato-credito-puente/contratoCreditoPuente';

@Component({
  selector: 'app-contrato-credito-puente-base',
  templateUrl: './contrato-credito-puente-base.component.html',
  styleUrls: ['./contrato-credito-puente-base.component.sass']
})
export class ContratoCreditoPuenteBaseComponent implements OnInit {

  nombreProyecto: any;
  idProyecto: number;
  visibleContratoCreditoIndexado: boolean = false;
  idContratoCredito: any;
  contratoCreditoPuente: ContratoCreditoPuente; 
  habilitaEliminarContratoCreditoPuente: boolean;
  tieneContrato: string;
  idContratoConcesionario: any;
  habilitaIndexacionContrato: boolean;

  constructor() { }

  ngOnInit() {
  }

}
