import { Component, OnInit, ViewChild } from '@angular/core';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { ParametricasService } from '../parametricas/parametricas.service';
import { TipoIntervencionCarreteroDetalleService } from './tipo-intervencion-carretero-detalle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { IntervencionDepartamentoUf } from './IntervencionDepartamentoUf';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-tipo-intervencion-carretero-detalle',
  templateUrl: './tipo-intervencion-carretero-detalle.component.html',
  styleUrls: ['./tipo-intervencion-carretero-detalle.component.sass']
})
export class TipoIntervencionCarreteroDetalleComponent implements OnInit {


  idIntervencionDepartamento: any;
  tiposIntervencion: any = [];
  filtroTiposIntervencion: any[];
  tipoIntervencionSeleccionado: any;

  intervencionDepartamentos: any = [];
  intervencionDepartamentoUfSeleccionada: IntervencionDepartamentoUf;

  displayedColumnsIntervencionDepartamentos: any = [];
  dataSourceIntervencion: MatTableDataSource<IntervencionDepartamentoUf>;
  @ViewChild(MatPaginator, { static: true }) paginatorIntervencion: MatPaginator;

  visibleDialogoExito: boolean = false;
  visibleConfirmarEliminarDepartamento: boolean = false;
  visibleDialogoExitoEliminarDepartamento: boolean = false;
  visibleDialogoAgregarDepartamento: boolean = false;
  intervencionDepartamento: IntervencionDepartamentoUf = {};
  departamentos: any = [];
  filtroDepartamentos: any[];
  departamentoSeleccionado: any;
  tieneDepartamentos: boolean = false;
  totalFactor: number = 0.0;
  fechaInicioConstruccion: Date;
  fechaFinConstruccion: Date;

  habilitaBotonEliminar: boolean = false;

  constructor(private lineaBase: ProyectoLineaBaseComponent, private parametricasService: ParametricasService,
    private tipoIntervencionCarreteroDetalleService: TipoIntervencionCarreteroDetalleService,
    private route: ActivatedRoute, private ruta: Router, private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.cargarIntervencionDepartamentoPorIntervencion(this.lineaBase.idIntervencion);
    this.displayedColumnsIntervencionDepartamentos = [
      { field: 'nombreDepartamento', header: 'Departamento' },
      { field: 'longitudConcesionada', header: 'Longitud concesionada en kilómetros' },
      { field: 'longitudReal', header: 'Longitud real en kilómetros' },
      { field: 'factorPonderacion', header: 'Factor de ponderación %' }];
    if (this.lineaBase.intervencion.fechaInicio != null) {
      this.fechaInicioConstruccion = new Date(this.lineaBase.intervencion.fechaInicio)
    }
    if (this.lineaBase.intervencion.fechaFin != null) {
      this.fechaFinConstruccion = new Date(this.lineaBase.intervencion.fechaFin)
    }
  }

  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.departamentos.length; i++) {
        let filtro = this.departamentos[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDepartamentos.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.departamentos = this.filtroDepartamentos;
      }
    });
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      let filtro = this.departamentos[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  agregarDepartamento(forma: NgForm) {
    if (this.visibleDialogoAgregarDepartamento) {
      if (this.fechaInicioConstruccion > this.fechaFinConstruccion) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de construcción debe ser menor que la fecha fin de construcción', life: 10000 });
      } else {
        this.intervencionDepartamento.idDepartamento = this.departamentoSeleccionado.id;
        this.intervencionDepartamento.idProyecto = this.lineaBase.idProyecto;
        this.lineaBase.intervencion.fechaInicio = this.fechaInicioConstruccion;
        this.lineaBase.intervencion.fechaFin = this.fechaFinConstruccion;
        if (this.lineaBase.idIntervencion === 'new') {
          this.lineaBase.intervencion.idTipoIntervencion = this.lineaBase.tipoIntervencionSeleccionado.id;
          this.lineaBase.intervencion.idProyecto = this.lineaBase.idProyecto;
          this.intervencionDepartamento.idIntervencionUf = 0;
          this.lineaBase.intervencion.crrIntervencionUfDepartamento = [this.intervencionDepartamento];
          let vara: any = this.tipoIntervencionCarreteroDetalleService.guardarNuevaIntervencionPorUnidadFuncional(this.lineaBase.intervencion).subscribe((data: {}) => {
            this.lineaBase.intervencion = data;
            this.lineaBase.intervencion.factorPonderacion = this.lineaBase.intervencion.factorPonderacion * 100;
            this.lineaBase.idIntervencion = this.lineaBase.intervencion.id;
            this.visibleDialogoExito = true;
            this.cargarIntervencionDepartamentoPorIntervencion(this.lineaBase.idIntervencion);
          }, error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          });
        } else {
          this.intervencionDepartamento.idIntervencionUf = this.lineaBase.idIntervencion;
          if (this.idIntervencionDepartamento === 'new') {
            let vara: any = this.tipoIntervencionCarreteroDetalleService.guardarIntervencionDepartamentoPorIntervencion(this.intervencionDepartamento).subscribe((data: {}) => {
              this.visibleDialogoExito = true;
              this.cargarIntervencionDepartamentoPorIntervencion(this.lineaBase.idIntervencion);
            }, error => {
              console.error(error);
              this.principalComponent.cargarErrorServicio(error);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
            });
          } else {
            this.tipoIntervencionCarreteroDetalleService.editarIntervencionDepartamentoPorIntervencion(this.intervencionDepartamento, this.idIntervencionDepartamento).subscribe((data: {}) => {
              this.visibleDialogoExito = true;
              this.cargarIntervencionDepartamentoPorIntervencion(this.lineaBase.idIntervencion);
            }, error => {
              console.error(error);
              this.principalComponent.cargarErrorServicio(error);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
            });
          }
        }
      }
    }
  }

  editarIntervencionDepartamento(intervencionDepartamentoUf: IntervencionDepartamentoUf) {
    this.intervencionDepartamentoUfSeleccionada = intervencionDepartamentoUf;
    this.idIntervencionDepartamento = this.intervencionDepartamentoUfSeleccionada.id;
    this.visibleDialogoAgregarDepartamento = true;
    return this.tipoIntervencionCarreteroDetalleService.getIntervencionDepartamentosPorId(this.intervencionDepartamentoUfSeleccionada.id).subscribe((data: {}) => {
      this.intervencionDepartamento = data;
      this.intervencionDepartamento.factorPonderacion = this.intervencionDepartamento.factorPonderacion * 100;
      this.intervencionDepartamento.longitudConcesionada = this.intervencionDepartamento.longitudConcesionada;
      this.intervencionDepartamento.longitudReal = this.intervencionDepartamento.longitudReal;
      this.intervencionDepartamento.idDepartamentoOrigenNavigation = {};
      this.intervencionDepartamento.idDepartamentoOrigenNavigation.id = this.intervencionDepartamento.idDepartamento;
      this.intervencionDepartamento.idDepartamentoOrigenNavigation.nombre = this.intervencionDepartamento.nombreDepartamento;
      if (this.intervencionDepartamento.idDepartamentoOrigenNavigation != null) {
        if (this.intervencionDepartamento.idDepartamentoOrigenNavigation.id != null) {
          this.departamentoSeleccionado = this.intervencionDepartamento.idDepartamentoOrigenNavigation;
        } else {
          this.departamentoSeleccionado = null;
        }
      } else {
        this.departamentoSeleccionado = null;
      }

    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  visibleAgregarIntervencionDepartamento() {
    this.visibleDialogoAgregarDepartamento = true;
    this.intervencionDepartamento = {};
    this.departamentoSeleccionado = null;
    this.idIntervencionDepartamento = "new";
  }

  applyFilter(filterValue: string) {
    this.dataSourceIntervencion.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceIntervencion.paginator) {
      this.dataSourceIntervencion.paginator.firstPage();
    }
  }

  cargarIntervencionDepartamentoPorIntervencion(idIntervencion) {
    this.totalFactor = 0.0;
    return this.tipoIntervencionCarreteroDetalleService.getIntervencionDepartamentosPorIdIntervencion(idIntervencion).subscribe((data: {}) => {
      this.intervencionDepartamentos = data;
      if (this.intervencionDepartamentos != null && this.intervencionDepartamentos.length != 0) {
        this.tieneDepartamentos = true;
        if (this.intervencionDepartamentos.length == 1) {
          this.habilitaBotonEliminar = true;
        } else {
          this.habilitaBotonEliminar = false;
        }
      } else {
        this.tieneDepartamentos = false;
      }
      this.intervencionDepartamentos.forEach(element => {
        this.totalFactor = this.totalFactor + element.factorPonderacion;
      });
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarTipoIntervencion(event) {
    this.filtroTiposIntervencion = [];
    return this.parametricasService.getTiposIntervencion().subscribe((data: {}) => {
      this.tiposIntervencion = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.tiposIntervencion.length; i++) {
        let filtro = this.tiposIntervencion[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroTiposIntervencion.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.tiposIntervencion = this.filtroTiposIntervencion;
      }
    });
  }



  cargarFiltroTiposIntervencion(event) {
    this.filtroTiposIntervencion = [];
    for (let i = 0; i < this.tiposIntervencion.length; i++) {
      let filtro = this.tiposIntervencion[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroTiposIntervencion.push(filtro);
      }
    }
  }

  agregarTipoIntervencion(forma: NgForm) {

    if (this.fechaInicioConstruccion > this.fechaFinConstruccion) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de construcción debe ser menor que la fecha fin de construcción', life: 10000 });
    } else {
      this.lineaBase.intervencion.idTipoIntervencion = this.lineaBase.tipoIntervencionSeleccionado.id;
      this.lineaBase.intervencion.idProyecto = this.lineaBase.idProyecto;
      this.lineaBase.intervencion.fechaInicio = this.fechaInicioConstruccion;
      this.lineaBase.intervencion.fechaFin = this.fechaFinConstruccion;
      if (this.lineaBase.idIntervencion === 'new') {
        let vara: any = this.tipoIntervencionCarreteroDetalleService.guardarNuevaIntervencionPorUnidadFuncional(this.lineaBase.intervencion).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        let vara: any = this.tipoIntervencionCarreteroDetalleService.editarIntervencionPorUnidadFuncional(this.lineaBase.intervencion, this.lineaBase.idIntervencion).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      }
    }
  }

  irEliminarIntervencionDepartamento(intervencionDepartamentoUf: IntervencionDepartamentoUf) {
    this.intervencionDepartamentoUfSeleccionada = intervencionDepartamentoUf;
    this.visibleConfirmarEliminarDepartamento = true;
    this.idIntervencionDepartamento = this.intervencionDepartamentoUfSeleccionada.id;
  }

  cancelarEliminarDepartamento() {
    this.visibleConfirmarEliminarDepartamento = false;
  }

  aceptarEliminarDepartamento() {
    return this.tipoIntervencionCarreteroDetalleService.eliminarIntervencionDepartamento(this.idIntervencionDepartamento).subscribe((data: {}) => {
      this.visibleConfirmarEliminarDepartamento = false;
      this.cargarIntervencionDepartamentoPorIntervencion(this.lineaBase.idIntervencion);
      this.visibleDialogoExitoEliminarDepartamento = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarDepartamento() {
    this.visibleDialogoExitoEliminarDepartamento = false;
  }



  cancelarNuevoTipoIntervencion() {
    this.lineaBase.visibleTiposIntervencionDetalle = false;
    this.lineaBase.visibleTiposIntervencion = true;
    this.lineaBase.intervencion = {};
  }

  aceptarDialogoExitoTipoIntervencion() {
    //this.visibleDialogoExitoTipoIntervencion = false;
    // this.cargarIntervencionesPorUnidadFuncional(this.idUnidadFuncionalSeleccionada);
    this.cancelarNuevoTipoIntervencion();
  }

  cancelarAgregarIntervencionDepartamento() {
    this.visibleDialogoAgregarDepartamento = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.visibleDialogoAgregarDepartamento = false;
  }

}
