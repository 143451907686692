import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visor-compensaciones-ambientales',
  templateUrl: './visor-compensaciones-ambientales.component.html',
  styleUrls: ['./visor-compensaciones-ambientales.component.sass']
})
export class VisorCompensacionesAmbientalesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
  }

}
