import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class SeguimientoConsultaService {

    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
      this.END_POINT = environment.baseUrl;
    }

    public getEstado() {
        return this.http.get(this.END_POINT + '/SolicitudEstado/lista/estados')
        .pipe(
        retry(1));
    }

    public getTipoSolicitud() {
        return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/tipoSolicitud')
          .pipe(
            retry(1));
    }

    public getTipoRespuesta() {
        return this.http.get(this.END_POINT + '/SolicitudRespuesta/lista/tipoRespuesta')
          .pipe(
            retry(1));
    }

    public getModo() {
        return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/modo')
            .pipe(
            retry(1));
    }

    public getProyectoDetalle(id) {
      return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/principal/' + id)
        .pipe(
          retry(1));
    }

    public postBusqueda(data) {
      return this.http.post(this.END_POINT + '/SolicitudComunicaciones/busqueda/comunicado/', data)
        .pipe(
          retry(1));
    }

    public getDependencia() {
      return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/dependencia')
        .pipe(
          retry(1));
    }

    public getGrupoInterno(id) {
      return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/grupoInterno/'  + id)
        .pipe(
          retry(1));
    }

  }
