import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { InterventoriaCarreteroDetalleService } from './interventoria-carretero-detalle.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Contrato } from '../concesionario-carretero/contrato';
import { Contratista } from '../concesionario-carretero/contratista';
import { Accionista } from '../concesionario-carretero/accionista';
import { AccionistaContratista } from '../concesionario-carretero/accionista-contratista';
import { ParametricasService } from './../parametricas/parametricas.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { InterventoriaCarreteroService } from '../interventoria-carretero/interventoria-carretero.service';
import { ConcesionarioCarreteroService } from '../concesionario-carretero/concesionario-carretero.service';
import { AccionistaContratistaEncabezado } from '../concesionario-carretero/accionista-contratista-encabezado';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-interventoria-carretero-detalle',
  templateUrl: './interventoria-carretero-detalle.component.html',
  styleUrls: ['./interventoria-carretero-detalle.component.sass']
})
export class InterventoriaCarreteroDetalleComponent implements OnInit {

  idInterventoriaAccionista: any;



  departamentos: any = [];
  filtroDepartamentos: any[];


  ciudades: any = [];
  filtroCiudades: any[];

  displayedColumnsComposicionAccionaria: string[] = ['fechaInicio', 'fechaFin', 'acciones'];
  dataSourceComposicionAccionaria: MatTableDataSource<AccionistaContratistaEncabezado>;
  composicionAccionariaLista: any = [];
  composicionAccionaria: AccionistaContratistaEncabezado = {};
  accionistaContratistaEncabezado: AccionistaContratistaEncabezado = {};

  visibleConfirmarEliminarComposicion: boolean = false;
  visibleDialogoExitoEliminarComposicion: boolean = false;

  anios: number;
  meses: number;

  visibleDialogoExito: boolean = false;
  visibleDialogoExitoInterventoria: boolean = false;
  visibleDialogoAgregarComposicion: boolean = false;



  visibleFormularioContrato: boolean = true;

  visibleConfirmarEliminarAccionista: boolean = false;
  visibleDialogoExitoEliminarAccionistaConcesionario: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private lineaBase: ProyectoLineaBaseComponent, private parametricasService: ParametricasService,
    private interventoriaCarreteroService: InterventoriaCarreteroService, private detalleService: InterventoriaCarreteroDetalleService,
    private route: ActivatedRoute, private ruta: Router, private concesionarioService: ConcesionarioCarreteroService,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
  }

  cargarNuevo() {
    this.lineaBase.contrato = {};
  }



  visibleFormularioInterventoria() {
    this.lineaBase.visibleFormularioContrato = false;
    if (this.lineaBase.interventoria.id != 0) {
      this.lineaBase.idInterventoria = this.lineaBase.interventoria.id;
    } else {
      this.lineaBase.idInterventoria = "new";
    }
  }

  visibleFormularioContratoInterventoria() {
    this.lineaBase.visibleFormularioContrato = true;
  }

  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.departamentos.length; i++) {
        let filtro = this.departamentos[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDepartamentos.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.departamentos = this.filtroDepartamentos;
      }
    });
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      let filtro = this.departamentos[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  limpiarCiudades(event) {
    this.lineaBase.ciudadSeleccionada = null;
  }

  cargarCiudades(event) {
    this.filtroCiudades = [];
    return this.parametricasService.getCiudadesPorDepartamento(this.lineaBase.departamentoSeleccionado.id).subscribe((data: {}) => {
      this.ciudades = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.ciudades.length; i++) {
        let filtro = this.ciudades[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroCiudades.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.ciudades = this.filtroCiudades;
      }
    });
  }

  cargarFiltroCiudades(event) {
    this.filtroCiudades = [];
    for (let i = 0; i < this.ciudades.length; i++) {
      let filtro = this.ciudades[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudades.push(filtro);
      }
    }
  }

  guardarDetalle(forma: NgForm) {
    this.lineaBase.contrato.idProyecto = this.lineaBase.idProyecto;
    this.lineaBase.contrato.idTipo = 2;
    if (this.lineaBase.idContratoInterventoria === 'new') {
      let vara: any = this.detalleService.guardarNuevoContratoInterventoria(this.lineaBase.contrato).subscribe((data: {}) => {
        this.visibleDialogoExito = true;
        let contratoNuevo: Contrato = data;
        this.lineaBase.idContratoInterventoria = contratoNuevo.id;
        this.lineaBase.idInterventoria = "new";
        this.lineaBase.interventoria.id = 0;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.detalleService.editarContratoInterventoria(this.lineaBase.contrato, this.lineaBase.idContratoInterventoria).subscribe((data: {}) => {
        this.visibleDialogoExito = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }



  guardarDetalleInterventoria(forma: NgForm) {
    if (this.lineaBase.idInterventoria === 'new') {
      this.lineaBase.interventoria.idTipo = 2;
      this.lineaBase.interventoria.idContrato = this.lineaBase.idContratoInterventoria;
      this.lineaBase.interventoria.IdMunicipioUbicacion = this.lineaBase.ciudadSeleccionada.id;
      let vara: any = this.detalleService.guardarNuevoContratistaInterventoria(this.lineaBase.interventoria).subscribe((data: {}) => {
        this.visibleDialogoExitoInterventoria = true;
        let interventoriaNuevo: Contratista = data;
        this.lineaBase.idInterventoria = interventoriaNuevo.id;
        this.lineaBase.nombreInterventoria = interventoriaNuevo.nombre;
        this.lineaBase.existeInterventoria = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.detalleService.editarContratistaInterventoria(this.lineaBase.interventoria, this.lineaBase.idInterventoria).subscribe((data: {}) => {
        this.visibleDialogoExitoInterventoria = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  cargarComposicionAccionariaLista(idContratista) {
    return this.concesionarioService.getComposicionAccionariaPorIdContratista(idContratista).subscribe((data: {}) => {
      this.lineaBase.composicionAccionariaLista = data;
      this.lineaBase.dataSourceComposicionAccionaria = new MatTableDataSource(this.lineaBase.composicionAccionariaLista);
    });
  }

  agregarComposicionAccionaria(forma: NgForm) {
    if (this.visibleDialogoAgregarComposicion) {
      this.accionistaContratistaEncabezado.idContratista = this.lineaBase.idInterventoria;
      if (this.lineaBase.idEncabezado === 'new') {
        let vara: any = this.concesionarioService.guardarNuevaComposicionAccionaria(this.accionistaContratistaEncabezado).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
          this.cargarComposicionAccionariaLista(this.lineaBase.idInterventoria);
          this.lineaBase.habilitaTabInterventoria = true;
        });
      } else {
        this.concesionarioService.editarComposicionAccionaria(this.accionistaContratistaEncabezado, this.lineaBase.idEncabezado).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
          this.cargarComposicionAccionariaLista(this.lineaBase.idInterventoria);
        });
      }
    }
  }

  editarComposicionAccionariaPorId(idEncabezado) {
    this.lineaBase.idEncabezado = idEncabezado;
    return this.concesionarioService.getComposicionAccionariaPorId(idEncabezado).subscribe((data: {}) => {
      this.accionistaContratistaEncabezado = data;
      this.accionistaContratistaEncabezado.fechaInicio = new Date(this.accionistaContratistaEncabezado.fechaInicio);
      this.visibleDialogoAgregarComposicion = true;
    });
  }

  editarComposicionAccionaria(idEncabezado) {
    this.lineaBase.idEncabezado = idEncabezado;
    this.lineaBase.visibleListaAccionistasInterventoria = true;
    return this.concesionarioService.getAccionistaPorIdEncabezado(idEncabezado).subscribe((data: {}) => {
      this.lineaBase.accionistas = data;
      this.lineaBase.dataSourceAccionistas = new MatTableDataSource(this.lineaBase.accionistas);
    });
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.visibleDialogoAgregarComposicion = false;
    this.lineaBase.habilitaBotonCrearInterventoria = true;
    if (this.lineaBase.interventoria.id != 0) {
      this.lineaBase.idInterventoria = this.lineaBase.interventoria.id;
    } else {
      this.lineaBase.idInterventoria = "new";
    }
  }

  aceptarDialogoExitoInterventoria() {
    this.visibleDialogoExitoInterventoria = false;
    this.visibleDialogoAgregarComposicion = false;
    this.lineaBase.existeInterventoria = true;
  }

  visibleAgregarComposicion() {
    this.visibleDialogoAgregarComposicion = true;
    this.accionistaContratistaEncabezado = {};
    this.lineaBase.idEncabezado = "new";
  }

  cancelarAgregarComposicion() {
    this.visibleDialogoAgregarComposicion = false;
  }

  irEliminarComposicion(id) {
    this.visibleConfirmarEliminarComposicion = true;
    this.lineaBase.idEncabezado = id;
  }

  aceptarEliminarComposicion() {
    return this.concesionarioService.eliminarComposicionAccionaria(this.lineaBase.idEncabezado, this.lineaBase.idConcesionario).subscribe((data: {}) => {
      this.visibleConfirmarEliminarComposicion = false;
      this.cargarComposicionAccionariaLista(this.lineaBase.idInterventoria);
      this.visibleDialogoExitoEliminarComposicion = true;
    });
  }

  aceptarDialogoEliminarComposicion() {
    this.visibleDialogoExitoEliminarComposicion = false;
  }


  cancelarEliminarComposicion() {
    this.visibleConfirmarEliminarComposicion = false;
  }

  regresarListaContratos() {
    this.lineaBase.visibleListaContratos = true;
    this.cargarContratos(this.lineaBase.idProyecto);
  }

  cargarContratos(id: number) {
    return this.interventoriaCarreteroService.getContratoInterventoriaPorIdProyecto(id, 2).subscribe((data: {}) => {
      let contratos: any = [];
      contratos = data;
      this.lineaBase.dataSourceContratos = new MatTableDataSource(contratos);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

}
