import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";
import { EnumFechaFormato } from '../../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoReporteCuentasCobroCxpComparativoDTO } from 'src/app/contratacion/contratacion-dto/rpte-cxp-cmpvo';

@Component({
  selector: 'app-rpte-cxp-cmpvo',
  templateUrl: './rpte-cxp-cmpvo.component.html',
  styles: []
})
export class RpteCxpCmpvoComponent implements OnInit {

  // Informacion del datatable
  reporteCuentasCobroCxpComparativo: CtoReporteCuentasCobroCxpComparativoDTO[] = [];
  reporteExcelCuentasCobroCxpComparativo: CtoReporteCuentasCobroCxpComparativoDTO[] = [];
  totalRecords: number;
  loading: boolean;

  // Filtros
  fechaInicioPasoFiltro?: Date;
  fechaFinPasoFiltro?: Date;

  msgValFechaInicioPasoFiltro = 'Debe seleccionar la Fecha desde para realizar la consulta';
  msgValFechaFinPasoFiltro = 'Debe seleccionar la Fecha hasta para realizar la consulta';
  msgValFechaFinPasoMayorFiltro = 'La fecha desde debe ser mayor a la fecha hasta';
  msgValReporteNullVacio = 'No se encontrarón registros, revise la información de los filtros de búsqueda';

  constructor(
    private messageService: MessageService,
    private datepipe: DatePipe,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private principalComponent: PrincipalComponent
  ) { }

  /**
  * Metodo que incializa invoca el listado de parametricas
  */
  ngOnInit() { }

  /**
  * Metodo consultar la información del datatable de acuerdo a los filtros definidos   
  */
  buscar() {
    this.consultarReporteCuentasCobroCxpComparativo();
  }

  /**
 * Metodo utilizado para limpiar los campos del formulario
 */
  limpiarFiltros() {
    this.fechaInicioPasoFiltro = null;
    this.fechaFinPasoFiltro = null;
  }

  private validarFiltros(): boolean {
    let accionValida = true;
    if (this.fechaInicioPasoFiltro == null) {
      accionValida = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.msgValFechaInicioPasoFiltro, life: 10000 });
      return accionValida;
    }
    if (this.fechaFinPasoFiltro == null) {
      accionValida = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.msgValFechaFinPasoFiltro, life: 10000 });
      return accionValida;
    }
    if (this.fechaInicioPasoFiltro != null && this.fechaFinPasoFiltro != null) {
      if (this.fechaInicioPasoFiltro > this.fechaFinPasoFiltro) {
        accionValida = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.msgValFechaFinPasoMayorFiltro, life: 10000 });
        return accionValida;
      }
    }
    return accionValida;
  }

  /**
   * Metodo que invoca el servicio para consultar los el comparativo de cuentas de cobro   
   */
  private consultarReporteCuentasCobroCxpComparativo() {
    this.reporteCuentasCobroCxpComparativo = [];
    this.loading = true;
    if (this.validarFiltros()) {
      this.reporteCuentaCobroCxpService.srvConsultarReporteCuentasCobroCxpComparativo(this.fechaInicioPasoFiltro, this.fechaFinPasoFiltro).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.reporteCuentasCobroCxpComparativo = result.reporteCuentasCobroCxpComparativo;
              this.totalRecords = this.reporteCuentasCobroCxpComparativo != null ? this.reporteCuentasCobroCxpComparativo.length : 0;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.loading = false;
        }
      );
    } else {
      this.loading = false;
    }
  }

  private validarExportExcel(): boolean {
    let accionValida = true;
    // check array does not exist, is not an array, or is empty
    if (!Array.isArray(this.reporteCuentasCobroCxpComparativo) || !this.reporteCuentasCobroCxpComparativo.length) {
      accionValida = false;
      this.lanzarMensajeError("No hay registros suficientes para exportar en Excel");
    }
    return accionValida;
  }

  /**
   * Metodo utilizado para invocar la funcionalidad de exportar excel la información de comparativo de cuentas
   */
  exportExcel() {
    this.reporteExcelCuentasCobroCxpComparativo = [];
    this.loading = true;
    if (this.validarExportExcel()) {
      //flatten objects
      const rows = this.reporteCuentasCobroCxpComparativo.map(row => ({
        contrato: row.numeroContrato,
        periodoPago: this.datepipe.transform(row.fechaInicioPeriodoPago, EnumFechaFormato.DD_MM_YYYY_SLASH) + ' - ' + this.datepipe.transform(row.fechaFinPeriodoPago, EnumFechaFormato.DD_MM_YYYY_SLASH),
        fechaPublicacionInforme: this.datepipe.transform(row.fechaPublicacion, EnumFechaFormato.DD_MM_YYYY_HH_MM_SS_SLASH),
        fechaApruebaVobo: this.datepipe.transform(row.fechaApruebaVobo, EnumFechaFormato.DD_MM_YYYY_HH_MM_SS_SLASH),
        fechaApruebaSupervisor: this.datepipe.transform(row.fechaApruebaSupervisor, EnumFechaFormato.DD_MM_YYYY_HH_MM_SS_SLASH),
        fechaApruebaCentralCuentas: this.datepipe.transform(row.fechaApruebaCentralCuentas, EnumFechaFormato.DD_MM_YYYY_HH_MM_SS_SLASH),
        fechaAnulaCentralCuentas: this.datepipe.transform(row.fechaAnulaCentralCuentas, EnumFechaFormato.DD_MM_YYYY_HH_MM_SS_SLASH),
        fechaPago: this.datepipe.transform(row.fechaPago, EnumFechaFormato.DD_MM_YYYY_HH_MM_SS_SLASH),
      }));

      //generate worksheet and workbook
      import('xlsx').then(xlsx => {
        const headings = [["No. Contrato", "Periodo de Pago", "Fecha de Publicación de informe", "Fecha aprueba Vobo", "Fecha aprueba Supervisor", "Fecha aprueba Central de Cuentas", "Fecha rechaza Central de Cuentas", "Fecha pago"]];
        const worksheet = xlsx.utils.json_to_sheet(rows);
        /* fix headers */
        xlsx.utils.sheet_add_aoa(worksheet, headings);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'ctoReporteCxpPlanesPagoCuentasCobro');
      });
    } else {
      this.loading = false;
    }
  }

  /**
   * Metodo utilizado para descargar el archivo en formato xlsx generado
   * @param buffer 
   * @param fileName Nombre del archivo descargado
   */
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      this.loading = false;
    });
  }

  /**
  * Lanza mensaje de informacion
  */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
