import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PqrsEncuestaSatisfaccionService } from './pqrs-encuesta-satisfaccion.service';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-pqrs-encuesta-satisfaccion',
  templateUrl: './pqrs-encuesta-satisfaccion.component.html',
  styleUrls: ['./pqrs-encuesta-satisfaccion.component.css']
})
export class PqrsEncuestaSatisfaccionComponent implements OnInit {

  lstPreguntasOpciones: any = [];
  dataIn:any;
  loading: any = false;
  selectedCategory: any = null;
  visibleDialogoSuccess = false;
  visibleDialogoError = false;
  mensajeError = null;
  error = false;

  constructor(
    private consultaService: PqrsEncuestaSatisfaccionService,
    private route: Router,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.loading = true;
    this.visibleDialogoSuccess = false;
    this.visibleDialogoError = false;
    this.error = false;
    this.mensajeError = "";
    this.dataIn = JSON.parse(localStorage.getItem('pqrs-encuesta-satisfaccion'));
    this.consultaService.getPreguntas().subscribe((data: {}) => {
      this.lstPreguntasOpciones = data;
      this.lstPreguntasOpciones.forEach(element => {
        element.idSolicitud = this.dataIn.idSolicitud;
      });
      this.loading = false;
    }, (error)=>{
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
    });

  }

  /**
   * Metodo que realiza la validacion y el guardado del formulario
   */
  enviarFormulario(){
    this.mensajeError = "";
    this.error = false;
    console.log( this.lstPreguntasOpciones);

    this.lstPreguntasOpciones.forEach(element => {
      if(element.acronimoTP== "SU"){
        element.opcionesRespuesta.forEach(opcion => {
          if(opcion.respuestaOpcionSi == false && opcion.respuestaOpcionNo == false){
            this.error = true;
            //this.mensajeError = this.mensajeError+"La opción de respuesta, \""+opcion.texto+"\", seleccionar una opción de respuesta.\r\r"
          }

          if(opcion.respuestaOpcionSi == true && opcion.respuestaOpcionNo == true){
            this.error = true;
            //this.mensajeError = this.mensajeError+"La opción de respuesta, \""+opcion.texto+"\", seleccionar una unica opción de respuesta.\n\n"
          }
        });
      }

      if(element.acronimoTP== "TA"){
        element.opcionesRespuesta.forEach(opcion => {
          if(opcion.respuestaTexto == undefined || opcion.respuestaTexto == null){
            this.error = true;
            this.mensajeError = this.mensajeError+"La opción de respuesta, \""+opcion.texto+"\", agregar una respuesta.\n\n"
          }
        });
      }
    });

    if(this.error){
      this.mensajeError = "Señor Usuario, por favor diligenciar el formulario correctamente. Tener encuenta selececcionar una única opción en cada respuesta."
      this.visibleDialogoError = true;
    }else{


      this.lstPreguntasOpciones.forEach(element => {
        if(element.acronimoTP== "SU"){
          element.opcionesRespuesta.forEach(opcion => {
            if(opcion.respuestaOpcionSi == false && opcion.respuestaOpcionNo == true){
              opcion.respuestaOpcion = false;
            }
            if(opcion.respuestaOpcionSi == true && opcion.respuestaOpcionNo == false){
              opcion.respuestaOpcion = true;
            }
          });
        }

        if(element.acronimoTP== "TA"){
          element.opcionesRespuesta.forEach(opcion => {
            opcion.respuestaOpcionSi = null;
            opcion.respuestaOpcionNo = null;
            opcion.respuestaOpcion = null;
          });
        }
      });

      this.consultaService.postGuardarFormulario(this.lstPreguntasOpciones).subscribe((data: {}) => {
        this.visibleDialogoSuccess = true;
        this.loading = false;
      }, (error)=>{
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
      });
    }
  }

  /**
   * Metodo que cierra modal de error y limpia error
   */
  aceptarDialogoError(){
    this.visibleDialogoError = false;
    this.mensajeError = "";
    this.error = false;
  }

  /**
   * Metodo que cierra modal de success y limpia si hay datos de error
   */
  aceptarDialogoSuccess(){
    this.visibleDialogoError = false;
    this.mensajeError = "";
    this.error = false;
    this.visibleDialogoSuccess = false;

    localStorage.setItem("pqrs-encuesta-satisfaccion", null)
    this.route.navigate(["pqrs-consulta-public"]);
  }

}
