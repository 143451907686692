import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'reportes-puertos',
  templateUrl: './reportes-puertos.component.html',
  styleUrls: ['./reportes-puertos.component.sass']
})
export class ReportesPuertosComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  onNavigate(link){
    window.open(link, '_blank');
  }
  irAReportesCovid(){
    this.router.navigate([this.router.url+'/reportesCovid']);
  }
  seg_gest_p_l() {
    this.router.navigate(['puertos-privado/seguimiento-gestion'])
  }
  irInformacionGeneral() {
    this.router.navigate(['puertos-public/ficha-proyecto'])
  }
}
