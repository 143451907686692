import { Component, OnInit, Input } from '@angular/core';

import { NgForm } from '@angular/forms';
import { SelectItem, MessageService } from 'primeng/api';
import { C19ReporteCovidTrabajador, C19Persona, C19ReporteCovid, TipoDocumento, EstadoPersona, EstadoCovid, LugarTrabajo, Sintoma } from '../../covid-reportar.dto';
import { CovidReportarService } from '../../covid-reportar.service';
import { forkJoin } from 'rxjs';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-covid-reportar-trabajadores',
  templateUrl: './covid-reportar-trabajadores.component.html',
  styleUrls: ['./covid-reportar-trabajadores.component.sass']
})
export class CovidReportarTrabajadoresComponent implements OnInit {

  blockedDocument: boolean = false;

  @Input() reporteCovid: C19ReporteCovid;

  vistaActual: string = "";

  trabajador: C19Persona;
  trabajadorList: C19Persona[];

  reporteCovidTrabajadorList: C19ReporteCovidTrabajador[];
  reporteCovidTrabajador: C19ReporteCovidTrabajador;

  trnTiposDocumentoList: any[];

  estadoPersonaList: EstadoPersona[];
  estadoPersonaSeleccionado: EstadoPersona;

  sintomasList: Sintoma[];
  sintomasSeleccionadosList: Sintoma[];

  estadoCovidList: EstadoCovid[];
  estadoCovidSeleccionado: EstadoCovid;

  lugarTrabajoList: LugarTrabajo[];
  lugarTrabajoSeleccionado: LugarTrabajo;

  disabledSintomas: boolean;
  disabledEstadoCovid: boolean;
  disabledLugarTratado: boolean;


  constructor(
    private covidReportarService: CovidReportarService,
    private parametricasService: ParametricasService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    //Si la fechaDesde es diferente de NULL
    if (this.reporteCovid != null && this.reporteCovid.fechaDesde != null) {
      //Castearse a si misma como new Date para que se cargue correctamente en el p-calendar
      this.reporteCovid.fechaDesde = new Date(this.reporteCovid.fechaDesde);
    }

    //this.trabajadorCovidList = [];
    //this.trabajadorCovid = {};
    this.trabajador = {};

    this.cargarListasParametricas();


    this.consultarEstadoPersona();
    this.consultarEstadoCovid();
    this.consultarLugarTrabajo();
    this.consultarSintoma();
    this.consultarTrabajadoresPorReporte();
    this.vistaActual = "listaTrabajadores";
  }

  cargarListasParametricas() {
    this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.CC + ',' + EnumTipoDocumento.CE + ',' + EnumTipoDocumento.NIT + ',' + EnumTipoDocumento.PEP).subscribe(
      result => {
        this.trnTiposDocumentoList = result;
      },
      error => {
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  outputSelectorMunicipioDomicilio(_departamentoObj) {
    if (_departamentoObj != null && _departamentoObj.municipio != null) {
      this.reporteCovidTrabajador.idMunicipioDomicilio = _departamentoObj.municipio.id;
    }
  }

  outputSelectorMunicipioTrabajo(_departamentoObj) {
    if (_departamentoObj != null && _departamentoObj.municipio != null) {
      this.reporteCovidTrabajador.idMunicipioTrabajo = _departamentoObj.municipio.id;
    }
  }

  rowSelectTrabajador(_c19Trabajador: C19Persona) {
    this.trabajador = _c19Trabajador;
    this.trabajador.tipoDocumentoObj = this.trnTiposDocumentoList.find(t => t.id === this.trabajador.idTipoDocumento);
    //this.tipoDocumentoSeleccionado = _c19Trabajador.tipoDocumentoObj;
    this.vistaActual = "reportarTrabajador";
  }

  rowSelectSintomas(_c19Trabajador: C19Persona) {
    this.trabajador = _c19Trabajador;
    this.trabajador.tipoDocumentoObj = this.trnTiposDocumentoList.find(t => t.id === this.trabajador.idTipoDocumento);
    this.consultarSintomasPorReporteYTrabajador();
    this.irASintomas();
  }

  rowSelectEliminar(_c19Trabajador: C19Persona) {
    if (this.reporteCovid != null && this.reporteCovid.id != null) {
      forkJoin(
        this.covidReportarService.srvEliminarTrabajadorPorReporte(_c19Trabajador.id, this.reporteCovid.id)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            //Volver a consultar lista
            this.consultarTrabajadoresPorReporte();
            //Lanzar mensaje de exito
            this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }

  }

  rowSelectEliminarSintoma(_reporteCovidTrabajador: C19ReporteCovidTrabajador) {
    forkJoin(
      this.covidReportarService.srvEliminarSintomas(_reporteCovidTrabajador.id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          //Volver a consultar lista
          this.consultarSintomasPorReporteYTrabajador();
          //Lanzar mensaje de exito
          this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irATrabajadores() {
    this.vistaActual = "listaTrabajadores";
  }

  irAReportarSintomas() {
    /**this.disabledSintomas = true;
    this.disabledEstadoCovid = true;
    this.disabledLugarTratado = true;**/
    this.reporteCovidTrabajador = {};
    this.estadoPersonaSeleccionado = null;
    this.habilitarDeshabilitarSelectores();
    this.sintomasSeleccionadosList = [];
    this.estadoCovidSeleccionado = null;
    this.lugarTrabajoSeleccionado = null;
    this.vistaActual = "reportarSintomas";
  }

  irASintomas() {
    this.vistaActual = "sintomas";
  }

  irAReportarTrabajador() {
    this.trabajador = {};
    /**this.departamentoSeleccionado = null
    this.municipioSeleccionado = null
    this.estadoCovidSeleccionado = null;
    this.sintomasCovidSeleccionados = [];**/
    this.vistaActual = "reportarTrabajador";
  }

  /**guardarTrabajador(form: NgForm) {
    this.trabajadorCovid.idPersona = this.persona.id;
    //this.trabajadorCovid.id = this.trabajadorCovidList.length + 1;
    //this.trabajadorCovid.idEstadoPersona = this.estadoCovidSeleccionado.numero;
    //this.trabajadorCovid.estadoActual = this.estadoCovidSeleccionado.nombre;
    this.trabajadorCovidList.push(this.trabajadorCovid);
    //Logica de Guardar
    this.irATrabajadores();
  }**/

  reportarSintoma(form: NgForm) {
  }

  onChangeEstadoPersona(event) {
    this.habilitarDeshabilitarSelectores();
  }

  habilitarDeshabilitarSelectores() {
    if (this.estadoPersonaSeleccionado != null &&
      (this.estadoPersonaSeleccionado.id == 1 || this.estadoPersonaSeleccionado.id == 2)) {
      this.disabledSintomas = false;
      this.disabledEstadoCovid = true;
      this.disabledLugarTratado = true;
      this.estadoCovidSeleccionado = null;
      this.lugarTrabajoSeleccionado = null;
    } else {
      this.disabledSintomas = true;
      this.sintomasSeleccionadosList = [];
      if (this.estadoPersonaSeleccionado != null && this.estadoPersonaSeleccionado.id == 4) {
        this.disabledEstadoCovid = false;
        this.disabledLugarTratado = false;
      } else {
        this.disabledEstadoCovid = true;
        this.disabledLugarTratado = true;
        this.estadoCovidSeleccionado = null;
        this.lugarTrabajoSeleccionado = null;
      }
    }
  }

  validarEstadoPersona(idEstadoPersona): boolean {
    if (this.estadoPersonaSeleccionado != null) {
      return this.estadoPersonaSeleccionado.id == idEstadoPersona
    }
    return true;
  }

  consultarTrabajadoresPorReporte() {

    if (this.reporteCovid != null && this.reporteCovid.id != null) {
      //Bloquear pantalla
      this.blockedDocument = true;

      forkJoin(
        this.covidReportarService.srvConsultarTrabajadoresPorReporte(this.reporteCovid.id)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.trabajadorList = result.reporteTrabajador;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }
  }

  guardarTrabajador(form: NgForm) {
    //Bloquear pantalla
    this.blockedDocument = true;
    this.trabajador.idTipoDocumento = this.trabajador.tipoDocumentoObj.id;
    this.trabajador.esEncargadoReporte = false;
    //this.consolidadoCovid.id = this.consolidadoCovidList.length + 1;
    //this.trabajador.idReporte = this.reporteCovid.id;
    //this.trabajador.idMunicipio = '05001';
    forkJoin(
      this.covidReportarService.srvGuardarTrabajadorPorReporte(this.trabajador, this.reporteCovid.id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          //Consultar nuevamente la lista
          this.consultarTrabajadoresPorReporte();
          //Volver a trabajadores
          this.irATrabajadores();
          //Lanzar mensaje de exito
          this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );

  }

  consultarSintomasPorReporteYTrabajador() {

    if (this.reporteCovid != null && this.reporteCovid.id != null && this.trabajador != null && this.trabajador.id != null) {
      //Bloquear pantalla
      this.blockedDocument = true;

      forkJoin(
        this.covidReportarService.srvConsultarSintomasPorReporteYTrabajador(this.reporteCovid.id, this.trabajador.id)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.reporteCovidTrabajadorList = result.reporteTrabajador;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }
  }

  guardarSintoma(form: NgForm) {

    var permitirGuardar = true;

    if (this.reporteCovidTrabajador.idMunicipioTrabajo == null) {
      permitirGuardar = false;
      this.lanzarMensajeWarning("Debe seleccionar el Lugar donde labora el trabajador")
    }

    if (this.reporteCovidTrabajador.idMunicipioDomicilio == null) {
      permitirGuardar = false;
      this.lanzarMensajeWarning("Debe seleccionar el Lugar donde vive el trabajador")
    }

    if (permitirGuardar) {
      //Bloquear pantalla
      this.blockedDocument = true;

      this.reporteCovidTrabajador.idReporte = this.reporteCovid.id;
      this.reporteCovidTrabajador.idPersona = this.trabajador.id;
      if (this.estadoPersonaSeleccionado != null) {
        this.reporteCovidTrabajador.idEstadoPersona = this.estadoPersonaSeleccionado.id;
      }
      if (this.estadoCovidSeleccionado != null) {
        this.reporteCovidTrabajador.idEstadoCovid = this.estadoCovidSeleccionado.id;
      }
      if (this.lugarTrabajoSeleccionado != null) {
        this.reporteCovidTrabajador.idLugarTratado = this.lugarTrabajoSeleccionado.id;
      }
      this.reporteCovidTrabajador.sintomas = [];
      for (const sintomaSeleccionadoVar of this.sintomasSeleccionadosList) {
        this.reporteCovidTrabajador.sintomas.push({ idSintoma: sintomaSeleccionadoVar.id })
      }


      forkJoin(
        this.covidReportarService.srvGuardarSintomas(this.reporteCovidTrabajador)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            //Consultar nuevamente la lista
            this.consultarSintomasPorReporteYTrabajador();
            //Volver a sintomas
            this.irASintomas();
            //Lanzar mensaje de exito
            this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }
  }



  ////////////////CONSULTAR PARAMETRICAS///////////////////////////
  consultarEstadoPersona() {
    forkJoin(
      this.covidReportarService.srvConsultarEstadoPersona()
    ).subscribe(([result]) => {
      if (result != null) {
        this.estadoPersonaList = result;
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        // this.blockedDocument = false;
      }
    );
  }

  consultarSintoma() {
    forkJoin(
      this.covidReportarService.srvConsultarSintoma()
    ).subscribe(([result]) => {
      if (result != null) {
        this.sintomasList = result;
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        // this.blockedDocument = false;
      }
    );
  }

  consultarEstadoCovid() {
    forkJoin(
      this.covidReportarService.srvConsultarEstadoCovid()
    ).subscribe(([result]) => {
      if (result != null) {
        this.estadoCovidList = result;
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        // this.blockedDocument = false;
      }
    );
  }

  consultarLugarTrabajo() {
    forkJoin(
      this.covidReportarService.srvConsultarLugarTrabajo()
    ).subscribe(([result]) => {
      if (result != null) {
        this.lugarTrabajoList = result;
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        // this.blockedDocument = false;
      }
    );
  }

  ///////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
  }

  lanzarMensajeError(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
  }

  lanzarMensajeWarning(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }



}
