import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-fluvial',
  templateUrl: './public-fluvial.component.html',
  styleUrls: ['./public-fluvial.component.sass']
})
export class PublicFluvialComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
  }

  reportes() {

    this.route.navigate(['fluvial-public/consulta'])
  }

  irAMapas(): void {
    this.route.navigate(['carreteras-public/mapa'])
    //    this.document.location = "https://sig.ani.gov.co/mapasinterno/";
    //window.open('https://animapas.maps.arcgis.com/apps/webappviewer/index.html?id=8782c0e22b3140f293fdce88d7ab8fcb', '_blank');
  }

}
