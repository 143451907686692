import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/config.service';
import { environment } from 'src/environments/environment';
import { CtoInformeContratistaEncabezadoDTO, CtoInformeContratistaEncabezadoRpt } from '../../contratacion-dto/informe-contratista-encabezado';


@Injectable({
    providedIn: 'root'
})
export class InformeContratistaEncabezadoService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private config: ConfigService,
        private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public ConsultarInformeContratista(idInforme: number, idPersonaAsignacion: number): Observable<CtoInformeContratistaEncabezadoRpt> {
        return this.http.get<CtoInformeContratistaEncabezadoRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarInformeContratista/' + idInforme + '/' + idPersonaAsignacion)
            .pipe(
                map(data => {
                    //Mapear fechas 
                    if (data.informeContratista != null) {
                        data.informeContratista.fechaInicioPago = new Date(data.informeContratista.fechaInicioPago);
                        data.informeContratista.fechaFinPago = new Date(data.informeContratista.fechaFinPago);
                        data.informeContratista.fechaCreacion = new Date(data.informeContratista.fechaCreacion);
                        data.informeContratista.fechaGeneracion = new Date(data.informeContratista.fechaGeneracion);
                    }
                    return data;
                }),
                retry(1));
    }
}
