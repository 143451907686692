import { DatePipe } from "@angular/common";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/config.service';
import { PjePeriodoTraficoRpt } from 'src/app/peajes-gestion/recaudo-peajes/PjePeriodoTrafico';
import { ConsolidadosProyectosDTO, RespuestaValoresIndexadosDTO, ConsultarProyectoEstructuracionRpt, InformacionMetasDTO, InformacionTemasGestionMetasDTO, InformacionGeneralCarreterosDTO, InformacionCarreterosDTO, CrrAvanceUfDTO, InformacionAvanceUfDTO, InformacionAvanceProyectoDTO, InformacionAvanceProyectoTiposIntervencionDTO, InformacionCapexOpexDTO, FechasReferenciaCapexOpexDTO } from "./reportes-carreteros.dto";
import { InformacionPeajesRecaudoDetalleDTO, InformacionPeajesRecaudoEncDTO, InformacionPeajesTarifasEncDTO, InformacionPeajesTarifasDTO, InformacionPeajesDTO } from "./reportes-peajes.dto";
import { ConsultaInformacionGeneralProyectoRpt } from "src/app/polizas/poliza-edit/poliza-edit.service";


@Injectable({
  providedIn: 'root'
})
export class ReportesCarreteroService {
  public END_POINT = '';
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private datepipe: DatePipe) {

    this.END_POINT = environment.baseUrl;
  }

  public obtenerInformacionGeneral(idProyecto): Observable<ConsultaInformacionGeneralProyectoRpt> {
    return this.http.get<ConsultaInformacionGeneralProyectoRpt>(this.END_POINT + '/RptFichaGeneralCarretero/obtenerInformacionGeneralProyecto/' + idProyecto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getInformacionProyectos() {
    return this.http.get<InformacionCarreterosDTO>(this.END_POINT + '/RptFichaGeneralCarretero/informacionProyectos')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  public getinformacionGeneralProyectos() {
    return this.http.get<InformacionGeneralCarreterosDTO>(this.END_POINT + '/RptFichaGeneralCarretero/informacionGeneralProyectos')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getConsolidadoProyectos() {
    return this.http.get<ConsolidadosProyectosDTO>(this.END_POINT + '/RptFichaGeneralCarretero/consolidadoProyectos')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  public getinformacionGeneralProyectosMosaico() {
    return this.http.get<InformacionGeneralCarreterosDTO>(this.END_POINT + '/RptFichaGeneralCarretero/informacionGeneralProyectosMosaico')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getInformacionCrrAvanceUf(idProyecto: number) {
    return this.http.get<InformacionAvanceUfDTO>(this.END_POINT + '/RptFichaGeneralCarretero/informacionCrrAvanceUf/' + idProyecto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getInformacionCrrAvanceProyecto(idProyecto: number) {
    return this.http.get<InformacionAvanceProyectoDTO>(this.END_POINT + '/RptFichaGeneralCarretero/informacionCrrAvanceProyecto/' + idProyecto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getInformacionCrrAvanceProyectoTiposIntervencion(idProyecto: number) {
    return this.http.get<InformacionAvanceProyectoTiposIntervencionDTO>(`${this.END_POINT}/RptFichaDetalleCarretero/ObtenerAvancePorTipoIntervencion/${idProyecto}`)
      .pipe(map(data => data), retry(1));
  }

  public getInformacionCrrPeajes() {
    return this.http.get<InformacionPeajesDTO>(this.END_POINT + '/RptFichaGeneralCarretero/informacionPeajes')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getInformacionCrrPeajesPorId(idPeaje: number) {
    return this.http.get<InformacionPeajesDTO>(this.END_POINT + '/RptFichaGeneralCarretero/informacionPeajes/' + idPeaje)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getInformacionCrrPeajesPorIdProyecto(idProyecto: number) {
    return this.http.get<InformacionPeajesDTO>(this.END_POINT + '/RptFichaGeneralCarretero/informacionPeajesPorIdProyecto/' + idProyecto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  public getInformacionCrrPeajesTarifas(idPeaje: number) {
    return this.http.get<InformacionPeajesTarifasDTO>(this.END_POINT + '/RptFichaGeneralCarretero/informacionTarifasPeajes/' + idPeaje)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  public getInformacionCrrPeajesTarifasEnc(idPeaje: number) {
    return this.http.get<InformacionPeajesTarifasEncDTO>(this.END_POINT + '/RptFichaGeneralCarretero/encTarifasPeajes/' + idPeaje)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  //TODO: JENNY PARA ELIMINAR PORQUE SE ENCUENTRA EN REPORTES-PEAJES-SERVICE y debe apuntar a RptPeajes
  // public cargaEncRecaudoPeriodo(idPeaje: number) {
  //   return this.http.get<InformacionPeajesRecaudoEncDTO>(this.END_POINT + '/RptFichaGeneralCarretero/GetEncabezadoPeriodo/' + idPeaje)
  //     .pipe(
  //       map(data => {
  //         return data;
  //       }),
  //       retry(1));
  // }


  // public cargaEncRecaudoPeriodoPorId(idPeriodo: number) {
  //   return this.http.get<InformacionPeajesRecaudoEncDTO>(this.END_POINT + '/RptFichaGeneralCarretero/GetEncabezadoPeriodoPorId/' + idPeriodo)
  //     .pipe(
  //       map(data => {
  //         return data;
  //       }),
  //       retry(1));
  // }

  // public cargaDetallePeriodo(idPeriodo: number) {
  //   return this.http.get<InformacionPeajesRecaudoDetalleDTO>(this.END_POINT + '/RptFichaGeneralCarretero/GetDetallePeriodo/' + idPeriodo)
  //     .pipe(
  //       map(data => {
  //         return data;
  //       }),
  //       retry(1));
  // }

  public getCrrValoresPorProyecto(idProyecto: number, fechaReferencia?: Date): Observable<InformacionCapexOpexDTO> {
    let end_point = `${this.END_POINT}/RptFichaDetalleCarretero/ObtenerCapexOpexTotal/${idProyecto}`;

    if (fechaReferencia != null) {
      end_point += `?fechaReferencia=${fechaReferencia}`;
    }

    return this.http.get<InformacionCapexOpexDTO>(end_point)
      .pipe(map(data => data), retry(1));
  }

  public getCrrValoresPorUnidadFuncional(idProyecto: number, fechaReferencia?: Date): Observable<InformacionCapexOpexDTO> {
    let end_point = `${this.END_POINT}/RptFichaDetalleCarretero/ObtenerCapexOpexPorUnidadFuncional/${idProyecto}`;

    if (fechaReferencia != null) {
      end_point += `?fechaReferencia=${fechaReferencia}`;
    }

    return this.http.get<InformacionCapexOpexDTO>(end_point)
      .pipe(map(data => data), retry(1));
  }

  public getFechasReferenciaCapexOpex(idProyecto: number): Observable<FechasReferenciaCapexOpexDTO> {
    return this.http.get<FechasReferenciaCapexOpexDTO>(`${this.END_POINT}/RptFichaDetalleCarretero/ObtenerFechasReferencia/${idProyecto}`)
      .pipe(map(data => data), retry(1));
  }

  public informacionTemasGestion(idProyecto: number) {
    return this.http.get<InformacionTemasGestionMetasDTO>(this.END_POINT + '/RptFichaDetalleCarretero/informacionTemasGestion/' + idProyecto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public informacionMetas(idProyecto: number) {
    return this.http.get<InformacionMetasDTO>(this.END_POINT + '/RptFichaDetalleCarretero/informacionMetas/' + idProyecto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getAccionistas(idProyecto, idTipo) {
    return this.http.get<any>(this.END_POINT + '/RptFichaDetalleCarretero/accionistas/' + idProyecto + '/' + idTipo)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }



  public getDatosProyectos5GBD() {
    return this.http.get<ConsultarProyectoEstructuracionRpt>(this.END_POINT + '/TrnProyectoEstructuracion')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  public getValoresIndexadosPorProyecto(idProyecto: number) {
    return this.http.get<RespuestaValoresIndexadosDTO>(this.END_POINT + '/RptFichaGeneralCarretero/valoresIndexadosContratoPorProyecto/' + idProyecto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  public getProyectosYoutube(idProyecto: number) {
    return this.http.get(environment.baseUrl + '/RptFichaGeneralCarretero/playlistItem/' + idProyecto)
      .pipe(retry(1));
  }



}


