import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reporte-covid-bi-interno',
  templateUrl: './reporte-covid-bi-interno.component.html',
  styleUrls: ['./reporte-covid-bi-interno.component.sass']
})
export class ReporteCovidBiInternoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
  }

}
