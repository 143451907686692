import { Directive, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHideIfUnauthorized]'
})
export class HideIfUnauthorizedDirectiveDirective implements OnInit {
  @Input('appHideIfUnauthorized') permission: string;
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    if (sessionStorage.getItem('paginas') != null) {
      var paginas = JSON.parse(sessionStorage.getItem('paginas'))
      var habilitar = false
      paginas.forEach(pagina => {
        if (pagina.acciones != null) {
          var result = pagina.acciones.filter(accion => {
            if (accion.id == this.permission) {
              habilitar = true
            }
          })

        }
      });
      if (!habilitar){
        this.deshabilitar()
      }
    } else {
      this.deshabilitar();
    }
  }

  deshabilitar() {
    this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
  }

}
