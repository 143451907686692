import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeguimientoConsultaService } from './seguimiento-consulta.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Busqueda } from '../../../dtos/seguimiento-comunicaciones/proyecto-comunicaciones';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
    selector: 'app-seguimiento-consulta',
    templateUrl: './seguimiento-consulta.component.html',
    styleUrls: ['./seguimiento-consulta.component.sass']
})

export class SeguimientoConsultaComponent implements OnInit {

    // Inicializaciones de Variables
    displayedColumns1;
    listaSeguimiento: any = [];

    dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent>;

    busqueda: Busqueda = {};
    estado: any = [];
    filtroEstado: any[];
    estadoSeleccionado: any = null;

    tipoSolicitud: any = [];
    filtroTipoSolicitud: any[];
    tipoSolicitudSeleccionado: any = null;

    tipoRespuesta: any = [];
    filtroTipoRespuesta: any[];
    tipoRespuestaSeleccionado: any = null;

    modo: any = [];
    filtroModo: any[];
    modoSeleccionado: any = null;

    dependencias: any = [];
    filtroDependencias: any[];
    dependenciaSeleccionado: any = null;

    gruposInternos: any = [];
    filtroGruposInternos: any[];
    grupoInternoSeleccionado: any = null;

    numeroRadicado: any = null;
    solicitante: any = null;

    constructor(
        private consultaService: SeguimientoConsultaService,
        private route: Router,
        public dialog: MatDialog,
        public location: Location,
        private messageService: MessageService,
        private principalComponent: PrincipalComponent
    ) { }

    ngOnInit() {
        this.displayedColumns1 = [
            { field: 'numeroRadicado', header: 'Número de Radicado' },
            { field: 'estado', header: 'Estado' },
            { field: 'tipoSolicitud', header: 'Tipo de Solicitud' },
            { field: 'tipoRespuesta', header: 'Tipo de Respuesta' },
            { field: 'solicitante', header: 'Solicitante' },
            { field: 'modoProyecto', header: 'Modo' }
         ];
    }


    /**
     * Metodo que carga la lista de estados
     */
    cargarEstado(event) {
        this.filtroEstado = [];
        return this.consultaService.getEstado().subscribe((data: {}) => {
          this.estado = data;
          let existeFiltro: boolean;
          for (const row of this.estado) {
            const item: string = String(row.nombre);
            if (item.toLowerCase().includes(event.query.toLowerCase())) {
             this.filtroEstado.push(row);
             existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.estado = this.filtroEstado;
          }
        }, (error)=>{
          this.principalComponent.cargarErrorServicio(error);
        });
    }


    // Metodo que carga la lista de tipo de solicitud
    cargarTipoSolicitud(event) {
        this.filtroTipoSolicitud = [];
        return this.consultaService.getTipoSolicitud().subscribe((data: {}) => {
          this.tipoSolicitud = data;
          let existeFiltro: boolean;
          for (const row of this.tipoSolicitud) {
            const item: string = String(row.nombre);
            if (item.toLowerCase().includes(event.query.toLowerCase())) {
              this.filtroTipoSolicitud.push(row);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.tipoSolicitud = this.filtroTipoSolicitud;
          }
        }, (error)=>{
          this.principalComponent.cargarErrorServicio(error);
        });
    }

    // Metodo que carga la lista los tipos de respuesta
    cargarTipoRespuesta(event) {
    this.filtroTipoRespuesta = [];
    return this.consultaService.getTipoRespuesta().subscribe((data: {}) => {
          this.tipoRespuesta = data;
          let existeFiltro: boolean;
          for (const row of this.tipoRespuesta) {
            const item: string = String(row.nombre);
            if (item.toLowerCase().includes(event.query.toLowerCase())) {
              this.filtroTipoRespuesta.push(row);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.tipoRespuesta = this.filtroTipoRespuesta;
         }
        }, (error)=>{
          this.principalComponent.cargarErrorServicio(error);
        });
    }


    // Metodo que carga la lista de modos

    cargarModo(event) {
        this.filtroModo = [];
        return this.consultaService.getModo().subscribe((data: {}) => {
          this.modo = data;
          let existeFiltro: boolean;
          for (const row of this.modo) {
            const item: string = String(row.nombre);
            if (item.toLowerCase().includes(event.query.toLowerCase())) {
             this.filtroModo.push(row);
             existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.modo = this.filtroModo;
         }
        }, (error)=>{
          this.principalComponent.cargarErrorServicio(error);
        });
    }


    cargarDependencias(event) {
        this.filtroDependencias = [];

        return this.consultaService.getDependencia().subscribe((data: {}) => {
          this.dependencias = data;
          let existeFiltro: boolean;
          for (const row of this.dependencias) {
            const item: string = String(row.nombreCompleto);
            if (item.toLowerCase().includes(event.query.toLowerCase())) {
              this.filtroDependencias.push(row);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.dependencias = this.filtroDependencias;
         }
        }, (error)=>{
          this.principalComponent.cargarErrorServicio(error);
        });
      }


      limpiarGrupos(event) {
        this.grupoInternoSeleccionado = null;
      }


      cargarGrupoInterno(event) {
        this.filtroGruposInternos = [];
        if (this.dependenciaSeleccionado.id) {
          return this.consultaService.getGrupoInterno(this.dependenciaSeleccionado.id).subscribe((data: {}) => {
            this.gruposInternos = data;
            let existeFiltro: boolean;
            for (const row of this.gruposInternos) {
              const item: string = String(row.nombre);
              if (item.toLowerCase().includes(event.query.toLowerCase())) {
                this.filtroGruposInternos.push(row);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.gruposInternos = this.filtroGruposInternos;
            }
          }, (error)=>{
            this.principalComponent.cargarErrorServicio(error);
          });
        }

      }

    onFind() {
        if (this.estadoSeleccionado !== undefined && this.estadoSeleccionado !== null 
            && this.estadoSeleccionado !== '') {
             this.busqueda.estado = this.estadoSeleccionado.nombre;
        }

        if (this.tipoRespuestaSeleccionado !== undefined && this.tipoRespuestaSeleccionado !== null 
            && this.tipoRespuestaSeleccionado !== '') {
             this.busqueda.tipoRespuesta = this.tipoRespuestaSeleccionado.nombre;
        }

        if (this.tipoSolicitudSeleccionado !== undefined && this.tipoSolicitudSeleccionado !== null 
            && this.tipoSolicitudSeleccionado !== '') {
             this.busqueda.tipoSolicitud = this.tipoSolicitudSeleccionado.nombre;
        }

        if (this.modoSeleccionado !== undefined && this.modoSeleccionado !== null 
            && this.modoSeleccionado !== '') {
             this.busqueda.modo = this.modoSeleccionado.nombre;
        }

        if (this.dependenciaSeleccionado !== undefined && this.dependenciaSeleccionado !== null 
            && this.dependenciaSeleccionado !== '') {
             this.busqueda.dependencia = this.dependenciaSeleccionado.nombre;
        }

        if (this.grupoInternoSeleccionado !== undefined && this.grupoInternoSeleccionado !== null 
            && this.grupoInternoSeleccionado !== '') {
             this.busqueda.grupoInterno = this.grupoInternoSeleccionado.nombre;
        }

        if (this.solicitante !== undefined && this.solicitante !== null  
            && this.solicitante !== '') {
             this.busqueda.solicitante = this.solicitante;
        }

        if (this.numeroRadicado !== undefined && this.numeroRadicado !== null
            && this.numeroRadicado !== '') {
              this.busqueda.numeroRadicado = this.numeroRadicado;
        }

        this.cargarBusqueda(this.busqueda);
        this.limpiarBusqueda();
    }


    // Metodo que carga la busqueda
    cargarBusqueda(objeto) {
        this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
            disableClose: true
        });
        return this.consultaService.postBusqueda(objeto).subscribe((data: {}) => {
            this.responseData(data);
        }, (error)=>{
          this.dialogSpin.close();
          this.principalComponent.cargarErrorServicio(error);
        });
    }


    // Metodo que carga la respuesta
    responseData(payload) {
        if (payload.objeto === null) {
            this.lanzarMensajeError(payload.respuestaServicio.mensajeSalida);
        } else {
             this.listaSeguimiento = payload;
             this.dialogSpin.close();
             if (payload.length === 0) {
               this.lanzarMensajeError('No se encontró información');
             } else {
               this.lanzarMensajeInfo('Se encontraron registros con la búsqueda');
            }
        }
    }


    // Metodo que limpia la busqueda
    limpiarBusqueda() {
        this.estadoSeleccionado = null;
        this.busqueda.estado = null;
        this.tipoSolicitudSeleccionado = null;
        this.busqueda.tipoSolicitud = null;
        this.tipoRespuestaSeleccionado = null;
        this.busqueda.tipoRespuesta = null;
        this.modoSeleccionado = null;
        this.busqueda.modo = null;
        this.numeroRadicado = null;
        this.busqueda.numeroRadicado = null;
        this.solicitante = null;
        this.busqueda.solicitante = null;
    }

    /**
     * Metodo que siguiente
     */
    onFollow(id: number) {
        this.route.navigate(['/seguimiento-comunicaciones/consulta-seguimiento-comunicaciones/ver-seguimiento-comunicaciones', id]);
    }

    /**
     * Metodo que realiza el retorno
     */
    onReturn() {
        this.route.navigate(['/seguimiento-comunicaciones/']);
    }

    // /////////////////////////////Mensajes//////////////////////////////
    lanzarMensajeInfo(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
    }

    lanzarMensajeError(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    }

    lanzarMensajeWarning(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
    }

    limpiarMensajes() {
        this.messageService.clear();
    }

}
