import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { TrnRespuestaServicio } from "../../parametricas/trn-respuesta-servicio";


@Injectable({
    providedIn: 'root'
})
export class DireccionService {
    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public ObtenerListasDireccion(): Observable<RespuestaListasDireccion> {
        return this.http.get<RespuestaListasDireccion>(`${this.END_POINT}/PrsMaDireccion/ObtenerListasDireccion`)
        .pipe(map(data => data), retry(1));
    }
}

export class RespuestaListasDireccion {
    respuestaServicio?: TrnRespuestaServicio;
    listadoNomenclaturas?: Nomenclatura[];
    listadoComplementos?: Nomenclatura[];
}

export class Nomenclatura {
    texto: string;
    nomenclatura: string;
}