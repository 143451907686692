import { Injectable } from "@angular/core";
import { ConfigService } from "../config.service";
import { from, Observable, throwError } from "rxjs";
import { map, retry, catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { PredioDTO, PrdPersonaDTO, PrdDisponibilidadPredioDTO, PrdDocumentoDTO } from "./predios.dto";
import { TrnRespuestaServicio } from "../parametricas/trn-respuesta-servicio";

@Injectable({
  providedIn: "root",
})
export class PrediosService {
  private mensajeCompartidoSource = new BehaviorSubject("default message");
  mensajeCompartido = this.mensajeCompartidoSource.asObservable();

  public END_POINT = "";

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  intercambiarMensaje(mensaje: string) {
    this.mensajeCompartidoSource.next(mensaje);
  }

  /**
  * Listar predios por proyecto
  */
  public srvListarPrediosPorProyecto(idProyecto: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdPredio/listarPrediosPorProyecto/" + idProyecto)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
  * Obtener predio por id y borrador
  */
  public srvObtenerPredioPorIdYBorrador(idPredio: number, borrador: boolean): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdPredio/obtenerPredioPorId/" + idPredio + "/" + borrador)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Obtener listado parametrico de Naturaleza Juridica
  */
  public srvListarNaturalezaJuridica(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdMaNaturalezaJuridica/")
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Obtener listado parametrico de Proceso Adquisición
  */
  public srvListarProcesoAdquisicion(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdMaProcesoAdquisicion/")
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Obtener listado parametrico de Tipo Documento
  */
  public srvListarTipoDocumento(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdMaTipoDocumento/")
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Obtener listado parametrico de Uso de Suelo
  */
  public srvListarUsoSuelo(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdMaUsoSuelo/")
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Obtener listado parametrico de tipo de margen predial
  */
  public srvListarTipoMargenPredio(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdMaPredial/PrdMaTipoMargenPredio/")
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Obtener listado parametrico de Personas por Tipo
  */
  public srvListarPersonasPorTipo(tipo: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdPersona/listarPersonasPorTipo/" + tipo)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Guarda una persona asociada al predio
  */
  public srvGuardarPersona(prdPersonaDTO: PrdPersonaDTO
  ): Observable<RespuestaGenericaRpt> {
    // console.log(prdPersonaDTO);
    let url = this.END_POINT + "/PrdPersona/guardarPersona";
    return this.http.post<RespuestaGenericaRpt>(url, prdPersonaDTO).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Guarda un predio
  */
  public srvGuardarPredio(predio: PredioDTO
  ): Observable<RespuestaGenericaPredioRpt> {
    // console.log(predio);
    let url = this.END_POINT + "/PrdPredio/guardarPredio";
    return this.http.post<RespuestaGenericaRpt>(url, predio).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Guarda la disponibilidad del predio
  */
  public srvGuardarDiponibilidadPredio(disponibilidadPredio: PrdDisponibilidadPredioDTO
  ): Observable<RespuestaGenericaRpt> {
    // console.log(disponibilidadPredio);
    let url = this.END_POINT + "/PrdPredio/guardarDisponibilidadPredio";
    return this.http.post<RespuestaGenericaRpt>(url, disponibilidadPredio).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Obtener listado de disponiblidad por predio
  */
  public srvObtenerDisponibilidadPorPredio(idPredioHistorico: number, borrador: boolean): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdPredio/obtenerDisponibilidadPredioPorId/" + idPredioHistorico + "/" + borrador)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Obtener listado de documentos por predio
  */
  public srvListarDocumentosPorPredio(idPredio: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/PrdDocumento/listarDocumentosPorPredio/" + idPredio)
      .pipe(retry(1), catchError(this.handleError));
  }

  /**
   * Guarda un documento asociado a un predio
  */
  public srvGuardarDocumento(documento: PrdDocumentoDTO
  ): Observable<RespuestaGenericaPredioRpt> {
    // console.log(documento);
    let url = this.END_POINT + "/PrdDocumento/guardarDocumento";
    return this.http.post<RespuestaGenericaRpt>(url, documento).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Elimina un documento asociado a un predio
  */
  public srvEliminarDocumento(idDocumento: number): Observable<RespuestaGenericaPredioRpt> {
    // console.log(idDocumento);
    let url = this.END_POINT + "/PrdDocumento/eliminarDocumento/" + idDocumento;
    return this.http.delete<RespuestaGenericaRpt>(url).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
  * Aprueba o desaprueba un documento asociado a un predio
 */
  public srvaAprobarDesaprobarDocumento(documento: PrdDocumentoDTO
  ): Observable<RespuestaGenericaPredioRpt> {
    // console.log(documento);
    let url = this.END_POINT + "/PrdDocumento/aprobarDesaprobarDocumento";
    return this.http.post<RespuestaGenericaRpt>(url, documento).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Publica un previo que esta en borrador
  */
  public srvPublicarPredio(idPredio: number, idProyecto: number
  ): Observable<RespuestaGenericaPredioRpt> {
    // console.log(idPredio);
    let url = this.END_POINT + "/PrdPredio/publicarPredio/" + idPredio + '/' + idProyecto;
    return this.http.post<RespuestaGenericaRpt>(url, {}).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Elimina un predio
  */
  public srvEliminarPredio(idPredioHistorico: number, borrador: boolean): Observable<RespuestaGenericaPredioRpt> {
    // console.log('idPredioHistorico', idPredioHistorico);
    // console.log('borrador', borrador);
    let url = this.END_POINT + "/PrdPredio/eliminarPredio/" + idPredioHistorico + '/' + borrador;
    return this.http.delete<RespuestaGenericaRpt>(url).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Devolver un predio a concesionario
  */
  public srvDevolverPredio(predio: PredioDTO
  ): Observable<RespuestaGenericaPredioRpt> {
    // console.log(predio);
    let url = this.END_POINT + "/PrdPredio/devolverPredio";
    return this.http.post<RespuestaGenericaRpt>(url, predio).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Notificar un predio a interventoria
  */
  public srvNotificarInterventoria(predio: PredioDTO
  ): Observable<RespuestaGenericaPredioRpt> {
    // console.log(predio);
    let url = this.END_POINT + "/PrdPredio/notificarInterventoria";
    return this.http.post<RespuestaGenericaRpt>(url, predio).pipe(
      map((data) => {
        return data;
      }),
      retry(1),
      catchError(this.handleError)
    );
  }

  /**
   * Metodo que retorna la lista del informe de predial agrupado
   * @returns
   */
  public getPredialAgrupadoReporte() : Observable<any>  {
    return this.http.get(this.END_POINT + '/Predial/ListarInformeEjecutivoAgrupado').pipe(
        map((data) => {
          return data;
        }),
        retry(1),
        catchError(this.handleError)
      );
  }

  public getDepartamentos() {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/departamento')
      .pipe(
        catchError(this.handleError));
  }

  public getCiudadesPorDepartamento(idDepartamento) {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/municipio/' + idDepartamento)
      .pipe(
        catchError(this.handleError));
  }

  public sincronizarPredio(
    fichaPredial: string
  ): Observable<any> {
    console.log(
      "sincronizarPredio fichaPredial---->",
      fichaPredial
    );
    let urlSincronizacion =
      "https://sig.ani.gov.co/arcgissrv/rest/services/1_Predial/PredSinc/GPServer/Sincronizacion%20predios/submitJob?IDPRED=" +
      fichaPredial +
      "&env%3AoutSR=&env%3AprocessSR=&returnZ=false&returnM=false&returnTrueCurves=false&f=pjson"; // the url you are trying to access

    console.log("urlSincronizacion", urlSincronizacion);

    return from(
      fetch(urlSincronizacion, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET", // GET, POST, PUT, DELETE
        mode: "no-cors", // the most important option
      })
    );
  }


  /////////////////////////////////////////////////////////////////////////////////////////

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

export class RespuestaGenericaRpt {
  constructor(public respuestaServicio?: TrnRespuestaServicio) { }
}

export class RespuestaGenericaPredioRpt {
  constructor(public respuestaServicio?: TrnRespuestaServicio,
    public predio?: PredioDTO) { }
}
