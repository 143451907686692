import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contratos-home',
  templateUrl: './contratos-home.component.html',
  styles: []
})
export class ContratosHomeComponent implements OnInit {

  constructor(private router: Router) { }


  ngOnInit() {
  }

  // irAContratosBorrador() {
  //   this.router.navigate(['contratos']);
  // }

  irAContratos() {
    this.router.navigate(['/contratosPublicados', false]);
  }

  irACuentasBanco() {
    this.router.navigate(['/ctoAdicional/bnc']);
  }

  irAObligaciones() {
    this.router.navigate(['/ctoAdicional/obl']);
  }

  irAPlanPagos() {
    this.router.navigate(['/ctoAdicional/pln']);
  }
}
