import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable } from '@angular/core'
import { map, retry } from 'rxjs/operators';
import { ConfigService } from 'src/app/config.service';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { environment } from 'src/environments/environment';
import { avanceProyecto } from './datos-abiertos/avanceProyecto';
import { InformacionGeneralDA } from './datos-abiertos/informacionGeneral';
import { ProyectoCarretero } from '../informacion-general/proyecto-carretero';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs-compat';

@Injectable(
    {providedIn: 'root'}
)

export class DatosabiertosService {
    public END_POINT ='';
    //Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient, public datepipe: DatePipe){
        this.END_POINT=environment.baseUrl;
    }

    //Consultar la informacion general de todos los proyectos
    public getInformacionGeneral(idProyecto : number, idModo: number){
        let urlEndpoint = '/DatosAbiertos/GetDAInformacionGeneralProyecto/?'
        
        if (idProyecto != null) {
            urlEndpoint += `&idProyecto=${idProyecto}`;
        }

        if (idModo != null) {
            urlEndpoint += `&idModo=${idModo}`;
        }

        return this.http.get<ConsultarDAInfomacionGeneral>(this.END_POINT + urlEndpoint)
        .pipe(map(data => data), retry(1));
    }

    //Consultar los avances de los proyectos
    public getAvanceProyecto(idProyecto: number, fechaInicio: Date, fechaFin:Date){

        let urlEndpoint = '/DatosAbiertos/GetDAAvanceProyecto/?'
        
        if(idProyecto != null){
            urlEndpoint= urlEndpoint + '&idProyecto='+idProyecto;
        }       

        if (fechaInicio != null) {
            urlEndpoint = urlEndpoint + '&fechaInicio=' + this.datepipe.transform(fechaInicio, 'yyyy/MM/dd');
        }
        if (fechaFin != null) {
            urlEndpoint = urlEndpoint + '&fechaFin=' + this.datepipe.transform(fechaFin, 'yyyy/MM/dd');
        }
        return this.http.get<ConsultarDAAvanceProyectosRpt>(this.END_POINT + urlEndpoint)
        .pipe(
            map(data => {
                return data;
            }), 
            retry(1));    
    }

    // Consultar informacion resumida de proyectos
    public getResumenProyectoOcds(idProyecto: number, modo: string, generacion: string) {
        let urlEndpoint = `${this.END_POINT}/DaOcds/GetInformacionGeneralProyecto`;
        let params = []

        if (idProyecto != null) {
            params.push(`idProyecto=${idProyecto}`);
        }

        if (modo != null && modo != '') {
            params.push(`modo=${modo}`);
        }

        if (generacion != null) {
            params.push(`generacion=${generacion}`);
        }

        return this.http.get<ConsultarProyectoOcds>(`${urlEndpoint}?${params.join('&')}`)
        .pipe(map(data => data), retry(1));
    }

    //Consultar los proyectos carreteros
    public getProyecto(){
        return this.http.get<ConsultarDAProyectos>(this.END_POINT+'/DatosAbiertos/GetDAConsultarProyecto')
        .pipe(
            map(data =>{
                return data;
            }),
            retry(1));
    }

    //Consultar los proyectos OCDS
    public getProyectoOcds(){
        return this.http.get<ConsultarDAProyectosOcds>(this.END_POINT+'/DatosAbiertos/GetDAProyectosOcds')
        .pipe(
            map(data =>{
                return data;
            }),
            retry(1));
    }

    // Consultar los modos de transporte
    public getModo(){
        return this.http.get<ConsultarDAModo>(this.END_POINT + '/DatosAbiertos/GetDAConsultarModo')
        .pipe(
            map(data=>{                          
                return data;
            }),
            retry(1));

    }
    
    // Consultar las generaciones
    public getGeneracion() {
        return this.http.get<ConsultarDAGeneracion>(`${this.END_POINT}/DatosAbiertos/GetDAConsultarGeneracion`)
        .pipe(map(respuesta => respuesta), retry(1));
    }

    // Obtener el archivo json del proyecto en estandar OCDS
    public getOcdsJson(idProyecto: number) {
        return this.http.get<ConsultarOcdsJson>(`${this.END_POINT}/DaOcds/GetProyectoJsonOcds?idProyecto=${idProyecto}`)
        .pipe(map(respuesta => respuesta), retry(1));
    }

    public getPaqueteOcdsJson(idProyectos: number[]): Observable<ConsultarOcdsJson> {
        let idsParam = idProyectos.map(id => `idProyectos=${id}`).join('&');
        return this.http.get<ConsultarOcdsJson>(`${this.END_POINT}/DaOcds/GetPaqueteProyectosJsonOcds?${idsParam}`)
        .pipe(map(respuesta => respuesta), retry(1));
    }
}

export class ConsultarDAAvanceProyectosRpt{
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public avanceProyecto?: avanceProyecto[]      
    ){}
}

export class ConsultarDAInfomacionGeneral{
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public informacionGeneral?: InformacionGeneralDA[]
    ){}
}

export class ConsultarDAProyectos{
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public proyectoCarretero?: ProyectoCarretero[]
    ){}
}

export class ConsultarDAProyectosOcds{
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public proyectos?: ProyectoCarretero[]
    ){}
}

export class ConsultarDAModo{
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public modoTransporte?: any[]
    ){}
}

export class ConsultarDAGeneracion {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public generacion?: any[]
    ){}
}

export class ConsultarProyectoOcds {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public proyectos?: ProyectoOcds[]
    ) {}
}

export class ProyectoOcds {
    constructor(
        public idProyecto?: number,
        public idOcds?: string,
        public nombreProyecto?: string,
        public ubicacion?: string,
        public descripcionServicios?: string,
        public tiposIntervencion?: string,
        public entidad?: string,
        public presupuesto?: string
    ) {}
}

export class ConsultarOcdsJson {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public ocdsJson?: string
    ) {}
}