import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ProyectoDTO } from 'src/app/modulo-planeacion-proyecto/planeacion-proyecto.dto';
import { FrrViaAfectadaDTO } from '../../ola-invernal-ferreo.dto';
import { OlaInvernalFerreoService } from '../../ola-invernal-ferreo.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ola-invernal-ferreo-registro-listado',
  templateUrl: './ola-invernal-ferreo-registro-listado.component.html',
  styles: []
})
export class OlaInvernalFerreoRegistroListadoComponent implements OnInit {

  blockedDocument: boolean;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;
  listaFrrOlaInvernal: FrrViaAfectadaDTO[];
  cierreViaAfectada: FrrViaAfectadaDTO;


  dialogoEliminar: boolean;

  constructor(
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private olaInvernalService: OlaInvernalFerreoService,
    private componentesComunesService: ComponentesComunesService,
    private datepipe: DatePipe
  ) { }

  /**
   * Metodo de inicio del componente
   */
  ngOnInit() {
    const idParam = "idProyecto";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
    });
    this.cargarInfoProyecto();

    this.cargarListaFrrOlaInvernal();
  }

  /**
   * Metodo que carga la informacion del proyecto
   */
  cargarInfoProyecto() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }


  /**
   * Metodo que carga la lista de la ola invernal
   * @returns
   */
  cargarListaFrrOlaInvernal() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarCierresPorProyecto(this.idProyecto).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.listaFrrOlaInvernal = result.listaFrrOlaInvernal;
            this.listaFrrOlaInvernal.forEach(element => {
              element.longitudAfectacionSTR = element.longitudAfectacion /1000;
              element.distanciaPuntoInicialSTR = element.puntoInicial + " + " + element.distanciaAlPuntoInicial;
              element.fechaReporteSTR = this.datepipe.transform(element.fechaReporte, 'dd/MM/yyyy HH:mm').toString();
            });
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo para agregar un reporte de ola invernal ferreo
   */
  irAgregar() {
    this.router.navigate([this.router.url + "/ola-invernal-ferreo-registro-registro", + this.idProyecto, 0, false]);
  }

  /**
   * Metodo para consultar un reporte de ola invernal ferreo
   *
   * @param cierreViaAfectada
   */
  irConsultar(cierreViaAfectada: FrrViaAfectadaDTO) {
    this.router.navigate([this.router.url + "/ola-invernal-ferreo-registro-registro", this.idProyecto, cierreViaAfectada.id, true]);
  }

  /**
   * Metodo para editar un reporte de ola invernal ferreo
   *
   * @param cierreViaAfectada
   */
  irEditar(cierreViaAfectada: FrrViaAfectadaDTO) {
    this.router.navigate([this.router.url + "/ola-invernal-ferreo-registro-registro", this.idProyecto, cierreViaAfectada.id, false]);
  }

  /**
   * Metodo para validar la eliminación de un reporte de ola invernal ferreo
   *
   * @param cierreViaAfectada
   */
  irEliminar(cierreViaAfectada: FrrViaAfectadaDTO) {
    this.cierreViaAfectada = cierreViaAfectada;
    this.blockedDocument = true;
    this.dialogoEliminar = true;
  }

  /**
   * Metodo para eliminar un reporte de ola invernal ferreo
   */
  eliminarReporteCierreOlaInvernal() {
    this.dialogoEliminar = false;
    if (this.cierreViaAfectada != null && this.cierreViaAfectada.id != 0) {
      this.olaInvernalService.srvEliminarCierreOlaInvernalPorId(this.cierreViaAfectada.id).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.cargarListaFrrOlaInvernal();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  /**
   * Metodo para volver al reporte de ola invernal ferreo
   */
  bntVolver() {
    const ruta = "/ferrocarriles/infGeneral/proyectoHome";
    this.router.navigate([ruta, this.idProyecto]);
  }

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Metodo que limpia los mensajes
   */
  limpiarMensajes() {
    this.messageService.clear();
  }

}
