import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-public-map',
  templateUrl: './public-map.component.html',
  styleUrls: ['./public-map.component.sass']
})
export class PublicMapComponent implements OnInit {


  constructor(private route: Router) { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
    element.classList.add("p_mapas");
  }

}
