import { Component, Input, OnInit } from "@angular/core";
import * as datalocal from "src/assets/img/proyect.json";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import * as Highcharts from "highcharts/highstock";
import { formatDate } from "@angular/common";
import {
  CrrInformacionProyectoDTO,
  CrrAvanceUfDTO,
  CrrAvanceProyectoDTO,
  CrrAvanceProyectoTiposIntervencionDTO, CrrInformacionGeneralProyectoDTO
} from "src/app/reportes/carreteros/reportes-carreteros.dto";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import colorchart from "src/assets/img/coloreschart";
@Component({
  selector: "app-rpt-crr-proyecto-avances",
  templateUrl: "./rpt-crr-proyecto-avances.component.html",
  styleUrls: [
    "./rpt-crr-proyecto-avances.component.sass",
    "./rpt-crr-proyecto-avances.css",
  ],
})

export class RptCrrProyectoAvancesComponent implements OnInit {
  basicData: any;
  options: any;
  optionschart2: any;
  optionschart3: any;

  optionsChartConstruccionVias: any;
  chartConstruccionVias: Highcharts.ChartObject;

  optionsChartOtrasObras: any;
  chartOtrasObras: Highcharts.ChartObject;

  multiAxisData: any;
  multiAxisOptions: any;
  lineStylesData: any;
  basicOptions: any;

  fechaReferencia: any;
  fechaActual: Date;
  fechasUltimoReporte: any;
  fechaUltimoReporte: Date;
  blockedDocument = false;
  datoslocal: any = datalocal; //temporal

  @Input() informacionGeneralProyecto: CrrInformacionGeneralProyectoDTO;

  avanceUf: CrrAvanceUfDTO[] = [];
  avanceProyecto: CrrAvanceProyectoDTO[] = [];

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent
  ) {
    //ajustes 
    Highcharts.setOptions({
      lang: {
        loading: 'Cargando...',
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],

        rangeSelectorFrom: "Desde",
        rangeSelectorTo: "Hasta",
        rangeSelectorZoom: "Período",
        downloadPNG: 'Descargar imagen PNG',
        downloadJPEG: 'Descargar imagen JPEG',
        downloadPDF: 'Descargar imagen PDF',
        downloadSVG: 'Descargar imagen SVG',
        printChart: 'Imprimir',
        resetZoom: 'Reiniciar zoom',
        resetZoomTitle: 'Reiniciar zoom',
        thousandsSep: ".",
        decimalPoint: ','
      }
    });
  }

  ngOnInit() {

    this.fechaActual = new Date();

    console.log("avances");

    this.init();

    this.blockedDocument = true;

    console.log(this.informacionGeneralProyecto);
    this.fechaReferencia = formatDate(
      this.informacionGeneralProyecto.fechaPreconstruccionContratada,
      "dd/MM/yyyy",
      "es-ES"
    );

    this.reportesCarreteroService
      .getInformacionCrrAvanceUf(this.informacionGeneralProyecto.id)
      .subscribe(
        (dato) => {
          if (dato != null && dato.respuestaServicio != null) {
            if (dato.respuestaServicio.codigoSalida === 1) {
              this.avanceUf = dato.avanceUf.filter(e => e.factorPonderacion !== 0);
              this.avanceUf.forEach(element => {
                let valor = (100 * element.porcentajeAvance) / element.porcentajePlaneado;
                if (valor >= 0 && valor <= 30) {
                  element.estilo = "e_rojo";
                } else {
                  if (valor >= 31 && valor <= 60) {
                    element.estilo = "e_naranja";
                  } else {
                    if (valor >= 61 && valor <= 99.999999) {
                      element.estilo = "e_amarillo";
                    } else {
                      if (valor >= 100) {
                        element.estilo = "e_verde";
                      }
                    }
                  }
                }
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: dato.respuestaServicio.mensajeSalida,
                life: 10000,
              });
            }
          }
        },
        (error) => {
          this.blockedDocument = false;
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => { }
      );

    this.reportesCarreteroService
      .getInformacionCrrAvanceProyecto(this.informacionGeneralProyecto.id)
      .subscribe(
        (dato) => {
          if (dato != null && dato.respuestaServicio != null) {
            if (dato.respuestaServicio.codigoSalida === 1) {

              this.avanceProyecto = dato.listAvances;
              let fechas = this.avanceProyecto.map(e => {
                return new Date(e.fechaReporte).getTime();
              });

              this.fechasUltimoReporte = this.avanceProyecto.map(e => {
                return new Date(e.fechaReporte);
              });

              this.fechaUltimoReporte = this.fechasUltimoReporte[this.fechasUltimoReporte.length - 1];


              let novedades = this.avanceProyecto.filter(e => e.idConfiguracionProyecto !== null && e.porcentajeAvance !== null).map(e => [e.orden - 1, e.porcentajeAvance * 100]).concat(this.avanceProyecto.filter(e => e.idConfiguracionProyecto !== null && e.porcentajePlaneado !== null).map(e => [e.orden - 1, e.porcentajePlaneado * 100]));
              let novedadesPlaneadas = this.avanceProyecto.filter(e => e.idConfiguracionProyecto !== null).map(e => [e.orden - 1, e.porcentajePlaneado * 100]);

              let novedadesDescripcion = this.avanceProyecto.map(e => e.nombreNovedad);
              let tipoNovedades = this.avanceProyecto.map(e => e.tipoNovedad);

              let dataAvance = this.avanceProyecto.map((e) => e.porcentajeAvance * 100)
              dataAvance.forEach(function (element, index) {
                if (element === 0) {
                  []
                  dataAvance[index] = null;
                }
              });

              let dataPlaneado = this.avanceProyecto.map((e) => e.porcentajePlaneado * 100)

              let DataPleaneadoAux = dataPlaneado;
              dataPlaneado.forEach(function (element, index) {
                if (element === 0) {


                  for (let i = index; i < DataPleaneadoAux.length; i++) {
                    if (DataPleaneadoAux[i] !== 0) {
                      dataPlaneado[index] = DataPleaneadoAux[i];
                      DataPleaneadoAux[index] = DataPleaneadoAux[i];
                      i = DataPleaneadoAux.length;
                    }
                  }



                }
              });





              this.optionschart2 = {


                chart: {
                  zoomType: "x",
                  events: {
                    load() {
                      this.yAxis[0].setExtremes(0, 100)
                    }
                  }
                },
                title: {
                  text: "",
                },
                credits: {
                  enabled: false,
                }, yAxis: {
                  min: 0,
                  title: { text: "Porcentaje" },
                  labels: {
                    format: '{value} %'
                  }

                },
                xAxis: {
                  type: "datetime",
                  categories: this.avanceProyecto.map(e => {
                    return new Date(e.fechaReporte).getTime();
                  }),

                  labels: {
                    formatter: function () {

                      var month = Highcharts.dateFormat('%b', this.value);
                      var year = Highcharts.dateFormat('%Y', this.value);
                      //this.axis.defaultLableFormatter.call(this).
                      var label = month == 'Jun' ? year : ' ';
                      return label;
                    }
                  }







                },
                /*
                navigator: {
                  enabled: true,
                },
                scrollbar: {
                  enabled: true,
                },
                rangeSelector: {
                  floating: false,
                  enabled: true,
                  inputEnabled: false,
                  allButtonsEnabled: true,
                  buttons: [
                    {
                      type: "month",
                      count: 1,
                      text: "1mes",
                    },
                      {
                        type: "month",
                        count: 3,
                        text: "3meses",
                      },
                      {
                        type: "all",
                        text: "Todo",
                      },
                    ],
                    selected: 2,

                  }, */

                plotOptions: {
                  area: {

                    marker: {
                      enabled: false,
                      symbol: 'circle',
                      radius: 2,
                      states: {
                        hover: {
                          enabled: true
                        }
                      }
                    }
                  },


                },
                series: [
                  {
                    name: "Planeado",
                    color: colorchart.color3,
                    /*
                    data: this.avanceProyecto.map((e) => e.porcentajePlaneado * 100),
                    pointStart: Date.UTC(2017, 0, 1), //primer dia
                    pointInterval: 24 * 3600 * 1000, // 10 dias
                    valueDecimals: 2,
                    */
                    data: dataPlaneado,
                    tooltip: {
                      valueDecimal: 2,
                      headerFormat: '',
                      pointFormatter() {
                        return '<b style="color:{series.color};padding:0">' + this.series.name + '</b><br/>' +
                          Highcharts.dateFormat('%d - %b - %Y',
                            fechas[this.x])
                          + '  <br/>' + parseFloat(this.y).toFixed(2).replace('.', ',') + '%';
                      },
                      useHTML: true,
                      share: true,
                    },

                  },
                  {
                    name: "Ejecutado",
                    color: colorchart.color1,
                    /*
                  data: this.avanceProyecto.map((e) => e.porcentajePlaneado * 100),
                  pointStart: Date.UTC(2017, 0, 1), //primer dia
                  pointInterval: 24 * 3600 * 1000, // 10 dias
                  valueDecimals: 2,
                  */
                    data: dataAvance,
                    tooltip: {
                      valueDecimal: 2,
                      headerFormat: '',
                      pointFormatter() {
                        return '<b style="color:{series.color};padding:0">' + this.series.name + '</b><br/>' +
                          Highcharts.dateFormat('%d - %b - %Y',
                            fechas[this.x])
                          + '  <br/>' + parseFloat(this.y).toFixed(2).replace('.', ',') + '%';
                      },
                      useHTML: true,
                      share: true,
                    },

                  },
                  {
                    type: "scatter",
                    name: "Novedad contractual",
                    color: "#eb6d20",
                    data: novedades,

                    marker: {
                      radius: 4,
                    },
                    tooltip: {
                      pointFormatter() {
                        return '<b style="color:{series.color};padding:0">' + Highcharts.dateFormat('%d - %b - %Y',
                          fechas[this.x]) + '</b><br/> <strong>Tipo novedad: </strong>' + tipoNovedades[this.x] + ' <br/> <strong>Nombre novedad: </strong> ' + novedadesDescripcion[this.x]
                      }
                    },
                  }
                ],
                responsive: {
                  rules: [
                    {
                      condition: {
                        maxWidth: 800,
                      },
                      chartOptions: {
                        rangeSelector: {
                          inputEnabled: false,
                        },
                      },
                    },
                  ],
                },
              };

              let chart2 = Highcharts.chart("chartHistoria", this.optionschart2);

              // fin ajustes

              //this old
              /*this.options = {
                title: {
                  text: "",
                },
                credits: {
                  enabled: false,
                },

                xAxis: {
                  categories: this.avanceProyecto.map((e) =>
                    formatDate(e.fechaReporte, "dd/MM/yyyy", "es-ES")
                  ),
                  crosshair: true,
                },
                series: [
                  {
                    name: "Planeado",
                    data: this.avanceProyecto.map(
                      (e) => e.porcentajePlaneado * 100
                    ),
                  },
                  {
                    name: "Ejecutado",
                    data: this.avanceProyecto.map(
                      (e) => e.porcentajeAvance * 100
                    ),
                  },
                ],
              };

              Highcharts.chart("chart2", this.options);
              */
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: dato.respuestaServicio.mensajeSalida,
                life: 10000,
              });
              console.log("errordatos");

              //temporal local
            }
          }
        },
        (error) => {
          this.blockedDocument = false;
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );

    this.reportesCarreteroService.getInformacionCrrAvanceProyectoTiposIntervencion(this.informacionGeneralProyecto.id)
      .subscribe(respuesta => {
        if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.principalComponent.cargarErrorServicio({
            error: {
              message: EnumRespuestaServicio.ERROR_OPERACION,
              logEvento: respuesta.respuestaServicio.mensajeSalida
            }
          });
          return;
        }

        this.cargarDatosTipoIntervencion(respuesta.avancesCarretera, respuesta.avanceOtros);
      },
        (error) => {
          this.blockedDocument = false;
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => { });
  }

  private cargarDatosTipoIntervencion(avancesCarretera: CrrAvanceProyectoTiposIntervencionDTO[], avanceOtros: CrrAvanceProyectoTiposIntervencionDTO[]) {
    let longitudConcesioanda = [];
    let longitudReal = [];
    let avanceLongitud = [];

    let planeado = [];
    let ejecutado = [];

    avancesCarretera.forEach(avance => {
      this.optionsChartConstruccionVias.xAxis.categories.push(avance.tipoIntervencion);
      longitudConcesioanda.push(avance.longitudConcesionada / 1000);
      longitudReal.push(avance.longitudReal / 1000);
      avanceLongitud.push(avance.avanceLongitud / 1000);
    });

    avanceOtros.forEach(avance => {
      this.optionsChartOtrasObras.xAxis.categories.push(avance.tipoIntervencion);
      planeado.push(avance.cantidad);
      ejecutado.push(avance.cantidadTerminados);
    });

    this.chartConstruccionVias.addSeries({
      color: colorchart.color1,
      name: "Longitud concesionada",
      data: longitudConcesioanda
    });
    this.chartConstruccionVias.addSeries({
      color: colorchart.color2,
      name: "Longitud Real",
      data: longitudReal
    });
    this.chartConstruccionVias.addSeries({
      color: colorchart.color3,
      name: "Avance longitud",
      data: avanceLongitud
    });

    this.chartOtrasObras.addSeries({
      color: colorchart.color1,
      name: "Contratado",
      data: planeado
    });
    this.chartOtrasObras.addSeries({
      color: colorchart.color3,
      name: "Ejecutado",
      data: ejecutado
    });

    this.chartConstruccionVias.redraw();
    this.chartOtrasObras.redraw();
  }

  init() {
    this.optionschart3 = {
      chart: {
        type: "column",
        events: {
          load() {
            this.yAxis[0].setExtremes(0, 100)
          }
        }
      },
      credits: { enabled: false },
      title: { text: "" },
      xAxis: {
        categories: [""],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: { text: "Porcentaje" }, labels: {
          format: '{value} %'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} %</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },


      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.y:.2f} %',
            align: 'right',
            color: '#11528a',
            x: -10
          },
          pointPadding: 0.1,
          groupPadding: 0
        }
      },
      series: [
        {
          name: "Planeado",
          color: colorchart.color3,
          data: [
            {
              color: colorchart.color3,
              y: this.informacionGeneralProyecto.porcentajePlaneado * 100,
            },
          ],
        },
        {
          name: "Ejecutado",
          color: colorchart.color1,
          data: [
            {
              color: colorchart.color1,
              y: this.informacionGeneralProyecto.porcentajeAvance * 100,
            },
          ],
        },
      ],
    };

    let chart3 = Highcharts.chart("chart3", this.optionschart3);

    // Construccion Vías
    this.optionsChartConstruccionVias = {
      chart: { type: "bar", height: 600 },
      title: { text: "" },
      xAxis: {
        categories: [],
        title: {
          text: null,
        },
      },
      yAxis: {
        min: 0,
        title: { text: "Kilómetros" },
        labels: { overflow: "justify" },
      },
      tooltip: {
        valueSuffix: " km",
        shared: true,
      },

      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.y:,.2f}',


          },
          pointPadding: 0.1,
          groupPadding: 0
        }
      },

      credits: { enabled: false },
      series: [],
    };

    this.chartConstruccionVias = Highcharts.chart("chartConstruccionVias", this.optionsChartConstruccionVias);

    // Otras Obras
    this.optionsChartOtrasObras = {
      chart: { type: "bar", height: 600 },
      title: { text: "" },
      xAxis: {
        categories: [],
        title: { text: null },
      },
      yAxis: {
        min: 0,
        title: { text: "Unidades" },
        labels: { overflow: "justify" },
      },
      tooltip: {
        valueSuffix: "",
        shared: true,
      },

      plotOptions: {
        bar: {
          dataLabels: { enabled: true },
        },
      },
      credits: { enabled: false },
      series: [],
    };

    this.chartOtrasObras = Highcharts.chart("chartOtrasObras", this.optionsChartOtrasObras);
  }

  compararFecha(valor: any) {
    const date = new Date(valor);




    if (date < this.fechaActual) {

      return true;
    } else {
      return false;
    }


  }

}

