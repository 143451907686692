import { Component, OnInit } from '@angular/core';
import { UnidadFuncionalService } from '../unidad-funcional/unidad-funcional.service';
import { ActivatedRoute } from '@angular/router';
import { UnidadFuncional } from '../unidad-funcional/unidad-funcional';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Condicion, Condiciones } from '../comun/condicion';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';

export interface User {
  name: string;
}

@Component({
  selector: 'app-unidad-funcional-detalle',
  templateUrl: './unidad-funcional-detalle.component.html',
  styleUrls: ['./unidad-funcional-detalle.component.sass']
})
export class UnidadFuncionalDetalleComponent implements OnInit {
  unidadFuncionalPadreControl = new FormControl()
  unidadFuncional: any
  unidadesFuncionales: any = []
  unidadesFuncionalesFiltradas: Observable<UnidadFuncional>
  condiciones: Condicion[] = (new Condiciones()).condiciones
  id: number
  idProyecto: number
  codigos: any = []

  constructor(private unidadFuncionalService: UnidadFuncionalService, private route: ActivatedRoute,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['idUni'] != 'n') {
        this.id = params['idUni'];
        this.idProyecto = params['idProj'];
        this.cargarUnidadFuncional(this.id)
      } else {
        this.idProyecto = params['idProj'];
        this.unidadFuncional = new UnidadFuncional(0, this.idProyecto, '', 0, 0)
      }
      this.cargarUnidadesFuncionales()
      this.cargarCodigosUnidadesFuncionales()
    });
  }

  cargarUnidadFuncional(id: number) {
    this.unidadFuncionalService.getUnidadFuncional(id).subscribe((data: {}) => {
      this.unidadFuncional = data;
      if (this.unidadFuncional != null && this.unidadFuncional.idUnidadFuncionalPadreNavigation != null) {
        this.unidadFuncional.NombreUnidadFuncionalPadre = this.unidadFuncional.idUnidadFuncionalPadreNavigation.nombre
        this.unidadFuncionalPadreControl.setValue(this.unidadFuncional.NombreUnidadFuncionalPadre)
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });		  
    });
  }

  cargarUnidadesFuncionales() {
    this.unidadFuncionalService.getUnidadesFuncionalesPorProyecto(this.idProyecto).subscribe((data: {}) => {
      this.unidadesFuncionales = data;
      this.unidadesFuncionales.unshift({ nombre: "--Seleccione Unidad--"})

      this.unidadesFuncionalesFiltradas = this.unidadFuncionalPadreControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.nombre),
          map(nombre => nombre ? this._filter(nombre) : this.unidadesFuncionales.slice())
        );
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });		  
    });
  }

  cargarCodigosUnidadesFuncionales(){
    this.unidadFuncionalService.getCodigosUnidadesFuncionales().subscribe((data:{})=>{
      this.codigos = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });		  
    });
  }

  displayFn(unidadFunc?: UnidadFuncional): string | undefined {
    return unidadFunc ? unidadFunc.Nombre : undefined;
  }

  private _filter(name: string): UnidadFuncional[] {
    const filterValue = name.toLowerCase();
    return this.unidadesFuncionales.filter(option => option.nombre.toLowerCase().includes(filterValue));
  }

  grabarUnidadFuncional() {
    this.unidadFuncionalService.setUnidadFuncional(this.unidadFuncional).subscribe((data: any) => {
      if (data != null) {
        this.unidadFuncional.idUnidadFuncional = data.idUnidadFuncional
      }
      window.alert("Unidad Funcional Creada o actualizada");
    })
  }

  selected(elemento) {
    this.unidadFuncional.IdUnidadFuncionalPadre = elemento.idUnidadFuncional
    this.unidadFuncional.idUnidadFuncionalPadreNavigation = elemento
    this.unidadFuncional.NombreUnidadFuncionalPadre = elemento.nombre
  }
}
