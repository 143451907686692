import { Directive, OnInit, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDisableIfUnauthorized]'
})
export class DisableIfUnauthorizedDirectiveDirective implements OnInit {
  @Input('appDisableIfUnauthorized') permission: string; // Required permission passed in
  constructor(private el: ElementRef) { }

  ngOnInit() {
    if (sessionStorage.getItem('paginas') != null) {
      var paginas = JSON.parse(sessionStorage.getItem('paginas'))
      var habilitar = false
      paginas.forEach(pagina => {
        if (pagina.acciones != null) {
          var result = pagina.acciones.filter(accion => {
            if (accion.id == this.permission) {
              habilitar = true
            }
          })

        }
      });
      if (!habilitar){
        this.deshabilitar()
      }
    } else {
      this.deshabilitar();
    }
  }

  deshabilitar() {
    this.el.nativeElement.disabled = true;
    this.el.nativeElement.className += ' estiloBotonDeshabilitado'
  }

}
