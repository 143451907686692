import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeguimientoSeguimientoComunicacionesService } from './seguimiento-comunicaciones-registro.service';
import { ProgressSpinnerDialogComponent } from '../../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { Location } from '@angular/common';
import { SeguimientoDTO } from '../../../../dtos/seguimiento-comunicaciones/seguimientoDTO';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

  // Componente de Estados - Seguimiento

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'estado-comunicaciones-dialog',
  templateUrl: './estado-comunicaciones.html',
})
// tslint:disable-next-line:component-class-suffix

export class EstadosComunicacionesDialogComponent {
  maxDate: Date;
  visibleDialogoAgregar: boolean;

  constructor(
    public dialogRef: MatDialogRef<EstadosComunicacionesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
      this.visibleDialogoAgregar = true;
    }

  onNoClick(): void {
    this.dialogRef.close();
    this.visibleDialogoAgregar = false;
  }

}

  // Componente de Respuestas - Seguimiento

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'respuesta-emitida-dialog',
  templateUrl: './respuesta-emitida.html',
})
// tslint:disable-next-line:component-class-suffix
export class RespuestaEmitidaDialogComponent {
  maxDate: Date;
  enableAdministrativo = false;
  visibleDialogoAgregar: boolean;
  disabledNoRequiere: boolean;

  constructor(
    public dialogRef: MatDialogRef<RespuestaEmitidaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
      this.visibleDialogoAgregar = true;
      this.disabledNoRequiere = false;
    }

  onNoClick(): void {
    this.dialogRef.close();
    this.visibleDialogoAgregar = false;
  }


  onChangeTipoRespuesta(valor) {
     if (valor !==  undefined && valor !==  null  && valor !==  '' && valor === 3) {
        this.data.numeroRadicado = '';
        this.disabledNoRequiere = true;
        this.data.conceptoEmitido = 'Informativo - No requiere respuesta';
     } else {
        this.disabledNoRequiere = false;
        this.data.conceptoEmitido = '';
        this.data.numeroRadicado = '';
     }
    }
}

  // Componente de Seguimiento - Maestro


@Component({
  selector: 'app-seguimiento-comunicaciones-registro',
  templateUrl: './seguimiento-comunicaciones-registro.component.html',
  styleUrls: ['./seguimiento-comunicaciones-registro.component.sass']
})


export class SeguimientoComunicacionesRegistroComponent implements OnInit {

  // Inicializaciones de Variables

  proyectos: SeguimientoDTO = {};
  id: number;
  nombreProyecto: string;
  estados: any = [];
  tiposRespuesta: any = [];
  responsables: any = [];
  fechaReporte: Date;
  descripcion: string;
  accionesAseguir: string;
  conceptoEmitido: string;
  linkRespuesta: string;
  numeroRadicado: string;
  visibleDialogoExito: boolean;
  estadosTemporal: any = {};
  respuestasTemporal: any = {};
  seguimientoEstados: any = [];
  seguimientoRespuestas: any = [];


  // Arrays de Columnas de Tablas

  displayedEstados = [
    { field: 'estado', header: 'Estado de la Comunicación' },
    { field: 'fechaStr', header: 'Fecha de Reporte' },
    { field: 'descripcion', header: 'Descripción del Estado' },
    { field: 'accionesAseguir', header: 'Accion a Seguir' }
  ];

  displayedRespuestas = [
    { field: 'fechaStr', header: 'Fecha de Respuesta' },
    { field: 'numeroRadicado', header: 'Radicado de Respuesta' },
    { field: 'conceptoEmitido', header: 'Respuesta Emitida' },
    { field: 'linkRespuesta', header: 'Link de la Respuesta' },
    { field: 'tipoRespuesta', header: 'Tipo de Respuesta' }

  ];


  dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent>;

  constructor(
    private service: SeguimientoSeguimientoComunicacionesService,
    private route: Router, private ruta: ActivatedRoute, public dialog: MatDialog,
    public location: Location, private messageService: MessageService,
    private principalComponent: PrincipalComponent) {
  }

  ngOnInit() {
    // this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarProyecto(this.id);
      this.cargarEstados();
      this.cargarTipoRespuesta();
    });
  }

    // Funciones del componente

  cargarProyecto(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.service.obtenerListaSeguimiento(id).subscribe((data: {}) => {
      if (data) {
        this.proyectos = data;
        this.initData(this.proyectos);
      }
      this.dialogSpin.close();
    }, (error)=>{
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  initData(payload) {
    this.proyectos = payload;
    this.seguimientoEstados = payload.estados;
    this.seguimientoRespuestas = payload.respuestas;

    if (payload.proyectos.length > 0) {
      this.nombreProyecto = payload.proyectos[0].proyecto;
    } else {
      this.nombreProyecto = '';
    }


  }

  cargarEstados() {
    return this.service.getEstadoSeguimiento().subscribe((data: {}) => {
      this.estados = data;
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarTipoRespuesta() {
    return this.service.getTipoRespuesta().subscribe((data: {}) => {
      this.tiposRespuesta = data;
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cancelar() {
    this.location.back();
    this.dialogSpin.close();
  }

  modalEstados(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(EstadosComunicacionesDialogComponent, {
      data: {estados: this.estados,
        fecha: this.fechaReporte,
        descripcion: this.descripcion,
        accionesAseguir: this.accionesAseguir
      }

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result !==  null && result !== '')  {
            this.estadosTemporal = {
              idSolicitud: Number(this.id),
              idEstado: String(result.idEstado),
              fecha: result.fecha,
              descripcion: result.descripcion,
              accionesAseguir: result.accionesAseguir
            };
            this.service.guardarSeguimientoEstado(this.estadosTemporal).subscribe(data => {
              this.responseGuardar(data);
            }, (error)=>{
              this.principalComponent.cargarErrorServicio(error);
            });
           }
          });
    }
  }

  responseGuardar(payload) {
    if (payload.objeto === null) {
      this.lanzarMensajeError(payload.respuestaServicio.mensajeSalida);
    } else {
      this.visibleDialogoExito = true;
      this.cargarProyecto(this.id);
    }
  }

  modalRespuestas(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(RespuestaEmitidaDialogComponent, {
      data: {tiposRespuesta: this.tiposRespuesta,
        numeroRadicado: this.numeroRadicado,
        fecha: this.fechaReporte,
        conceptoEmitido: this.conceptoEmitido,
        linkRespuesta: this.linkRespuesta
      }

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result !==  null && result !== '')  {
            this.respuestasTemporal = {
              idSolicitud: Number(this.id),
              numeroRadicado: result.numeroRadicado,
              fecha: result.fecha,
              idTipoRespuesta: result.idTipoRespuesta,
              conceptoEmitido: result.conceptoEmitido,
              linkRespuesta: result.linkRespuesta
            };

            this.service.guardarSeguimientoRespuesta(this.respuestasTemporal).subscribe( data => {
              this.responseGuardar(data);
            }, (error)=>{
              this.principalComponent.cargarErrorServicio(error);
            });
           }
          });
    }
  }

  // Informativo - Dialogo

  aceptarDialogoExito() {
      this.visibleDialogoExito = false;
  }

  // /////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
  }

  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
  }

  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }


}



