import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CntTipoNovedad } from 'src/app/parametricas/cnt-tipo-novedad';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { ContratoCarretero, NovedadContrato } from '../informacion-contractual-carretero.dto';
import { InformacionContractualCarreteroService, EliminarNovedadContratoCarreteroRpt } from '../informacion-contractual-carretero.service';
import { forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { InformacionGeneralCarreteroService } from '../../informacion-general-carretero.service';
import { ProyectoCarretero } from 'src/app/informacion-proyecto-carretero/proyectoCarretero';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-modificacion-contractual-carretero',
  templateUrl: './modificacion-contractual-carretero.component.html',
  styleUrls: ['./modificacion-contractual-carretero.component.sass']
})
export class ModificacionContractualCarreteroComponent implements OnInit {

  blockedDocument: boolean = false;
  public idNovedad: number;
  public idContrato: number;
  public idProyecto: number;
  public modificacionesContractuales: NovedadContrato[] = [];
  public crearModificacion: boolean;
  public modificacionContractual: NovedadContrato = {};
  public fechaSuscripcion: Date;
  public fechaAplicacion: Date;
  public listaTiposNovedad: any = [];
  public tipoNovedadSeleccionada: any = {};
  visibleDialogoSubirArchivo: boolean;
  archivos: any = [];
  rutaArchivo: string;
  public nombreProyecto: string;
  proyectoCarretero: ProyectoCarretero = {};
  contrato: ContratoCarretero = {};
  visibleConfirmarEliminarNovedadContrato: boolean;
  visibleDialogoExitoEliminarNovedadContrato: boolean;
  visibleDialogoErrorEliminarNovedadContrato: boolean;
  eliminarNovedadContratoCarreteroRpt: EliminarNovedadContratoCarreteroRpt = {};

  constructor(private informacionContractualCarreteroService: InformacionContractualCarreteroService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private parametricasService: ParametricasService, private informacionGeneralCarreteroService: InformacionGeneralCarreteroService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.idContrato = params.idContrato;
    });
    this.cargarTiposNovedadContrato();
    this.cargarNovedadesContractuales();
    this.cargarProyecto(this.idProyecto);
    this.cargarContrato();
    this.eliminarNovedadContratoCarreteroRpt.respuestaServicio = {};
  }

  cargarProyecto(id: number) {
    this.informacionGeneralCarreteroService.getProyectoPorId(id).subscribe((data: {}) => {
      this.proyectoCarretero = data;
      this.nombreProyecto = this.proyectoCarretero.nombre;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarContrato() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.consultarContratoPorId(this.idContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.contrato = result.contratoCarretero;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarTiposNovedadContrato() {
    return this.parametricasService.getTiposNovedadContrato().subscribe((data: {}) => {
      this.listaTiposNovedad = data;
    });
  }

  cargarNovedadesContractuales() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.listarNovedadesContractualesPorContrato(this.idContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.modificacionesContractuales = result.novedadesContractuales;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irEditarNovedadContrato(idNovedad) {
    this.crearModificacion = true;
    this.cargarNovedadContrato(idNovedad);
  }

  irEliminarNovedadContrato(id) {
    this.visibleConfirmarEliminarNovedadContrato = true;
    this.idNovedad = id;
  }

  cancelarEliminarNovedadContrato() {
    this.visibleConfirmarEliminarNovedadContrato = false;
  }

  aceptarEliminarNovedadContrato() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.eliminarNovedadContractual(this.idNovedad)
    ).subscribe(([result]) => {
      this.eliminarNovedadContratoCarreteroRpt = result;
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.visibleConfirmarEliminarNovedadContrato = false;
          this.visibleDialogoExitoEliminarNovedadContrato = true;
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El registro se eliminó exitosamente.', life: 10000 });
          this.cargarNovedadesContractuales();
        } else {
          this.visibleDialogoErrorEliminarNovedadContrato = true;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarNovedadContrato(idNovedad) {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.consultarNovedadContractualPorId(idNovedad)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.modificacionContractual = result.novedadContrato;
          this.fechaSuscripcion = new Date(this.modificacionContractual.fechaSuscripcion);
          this.fechaAplicacion = new Date(this.modificacionContractual.fechaAplicacion);
          this.tipoNovedadSeleccionada = this.modificacionContractual.idTipoNovedadNavigation;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irCrearModificacionContractual() {
    this.crearModificacion = true;
    this.modificacionContractual = {};
    this.fechaSuscripcion = null;
    this.tipoNovedadSeleccionada = null;
    this.modificacionContractual.idContrato = this.idContrato;
  }

  cancelarModificacionContractual() {
    this.crearModificacion = false;
    this.cargarNovedadesContractuales();
  }

  guardarModificacionContractual(forma: NgForm) {
    this.blockedDocument = true;
    this.modificacionContractual.fechaSuscripcion = this.fechaSuscripcion;
    this.modificacionContractual.idTipoNovedad = this.tipoNovedadSeleccionada.id;
    forkJoin(
      this.informacionContractualCarreteroService.GuardarNovedadContrato(this.modificacionContractual)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'La novedad se registró exitosamente.', life: 10000 });
          this.crearModificacion = false;
          this.cargarNovedadesContractuales();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  aceptarAdjuntoArchivo() {
  }

  irVerDialogoSubirArchivo() {
    this.visibleDialogoSubirArchivo = true;
    this.rutaArchivo = 'modificacion_contrato/proyecto_' + this.idProyecto + '/contrato_' + this.idContrato + '/';
  }

  cancelarDialogoSubirArchivo() {
    this.visibleDialogoSubirArchivo = false;
  }

  outputInicioVisualizarArchivo() {
    this.blockedDocument = true;
  }

  outputFinVisualizarArchivo() {
    this.blockedDocument = false;
  }

}
