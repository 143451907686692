import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-public-aeropuertos',
  templateUrl: './public-aeropuertos.component.html'
})
export class PublicAeropuertosComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    // document.getElementById("nameseccion").innerHTML = "<span class='smallicon'><img src='assets/img/icn/aeropuerto_500-02-02.svg'/></span> Aeropuertos";
    // var element = document.getElementById("aniscopio_body");
    // element.classList.add("public");
    // element.classList.add("p_aeropuertos");
  }
  reportes(){    
    this.route.navigate(['aeropuertos-public/consulta']);                           
  }

}
