import { Injectable, } from '@angular/core';
import { ConfigService } from '../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { retry  } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UploadFileService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };


    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
      }

      public uploadFile(data) {
        return this.http.post(this.END_POINT + '/RadicacionWeb/setFilePublic', data)
            .pipe(
                retry(1));
    }

    public getTiposSoporte(modulo, idTiposSoporte) {
        return this.http.get(this.END_POINT + '/TipoSoportes/' + modulo + '/' + idTiposSoporte)
            .pipe(
                retry(1));
    }


    /**
     * Metodo que arga la lista de la info de la tabla vdoVideo
     */
     public getListado() {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/cargarPruebaConcepto')
          .pipe(retry(1));
      }
  



}
