import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EnumCtoEstadoContrato } from 'src/app/contratacion/constantes/contratacion-constantes';
import { EnumFechaFormato } from 'src/app/comun/constantes/constantes-comunes';
import { CtoContratoContratistaDTO } from '../../../contratacion-dto/contratos-contratista';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from '../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ContratacionService } from '../../../contratacion.service';

@Component({
  selector: 'app-contratos-contratista',
  templateUrl: './contratos-contratista.component.html',
  styles: []
})
export class ContratosContratistaComponent implements OnInit {

  contratos: CtoContratoContratistaDTO[] = [];
  blockedDocument = false;
  vigenciasPermitidasConInforme = 2020;
  datosArchivoTemp: any = null;

  constructor(
    private route: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private datepipe: DatePipe,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarContratos();
  }

  cargarContratos() {
    this.blockedDocument = true;
    return this.contratacionService.ConsultarContratosPorIdContratista().subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.contratos = result.contratosContratistas;
            if (this.contratos != null) {
              this.contratos.forEach(cto => {
                cto.habilitarGestionarInformes = false;
                const fechaTerminacionContrato = this.datepipe.transform(new Date(cto.fechaTerminacionContrato), EnumFechaFormato.YYYY_MM_DD_GUION);
                const fechaActual = this.datepipe.transform(new Date(), EnumFechaFormato.YYYY_MM_DD_GUION);
                if (fechaTerminacionContrato >= fechaActual) {
                  cto.estadoVigenciaContrato = EnumCtoEstadoContrato.EN_EJECUCION;
                  cto.habilitarGestionarInformes = true;
                } else {
                  cto.estadoVigenciaContrato = EnumCtoEstadoContrato.FINALIZADO;
                  if (cto.vigencia == this.vigenciasPermitidasConInforme) {
                    cto.habilitarGestionarInformes = false;
                  } else {
                    cto.habilitarGestionarInformes = true;
                  }
                }
              });
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }


  irInformesContrato(contrato: CtoContratoContratistaDTO) {
    this.route.navigate([this.route.url + '/planPagosContratista', contrato.idContrato, contrato.idPersona]);
  }

  irBalanceFinancieroContrato(contrato: CtoContratoContratistaDTO) {
    this.route.navigate([this.route.url + '/balanceFinancieroContrato', contrato.idContrato, contrato.idPersona]);
  }

  /**
   * Metodo que realiza la descarga del certificado del contrato
   * @param contrato
   */
  descargarCertificadoContrato(contrato: CtoContratoContratistaDTO) {

    this.blockedDocument = true;
    if(contrato.idContrato!= null){
      this.contratacionService.getGeneracionPdfContratista(contrato.idContrato).subscribe((data: {}) => {
        this.datosArchivoTemp = data;
        if(this.datosArchivoTemp != undefined && this.datosArchivoTemp != null){
            this.onDownload(this.datosArchivoTemp);
         }
         this.blockedDocument = false;
      },error => {
          this.blockedDocument = false;
          this.principalComponent.cargarErrorServicio(error);
      });
    }else{
        this.blockedDocument = false;
    }
  }

  get EnumCtoEstadoContrato() {
    return EnumCtoEstadoContrato;
  }

  /**
   * Metodo que descarga el archivo
   *
   * @param data
   */
  onDownload(data) {
    var a = document.createElement("a");
    var fileName = data.nombreArchivo;
    var base64str = data.archivo;
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    var blob = new Blob( [view], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    this.blockedDocument = false;
    a.click();
  }

}
