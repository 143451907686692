import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  InvBienDTO,
  InvAsignacionDTO,
  InvMaTipoAsignacion,
} from '../inventario-equipos.dto';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { InventarioEquiposService } from 'src/app/inventario-equipos/inventario-equipos.service';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-mis-bienes',
  templateUrl: './mis-bienes.component.html',
  styleUrls: ['./mis-bienes.component.sass'],
})
export class MisBienesComponent implements OnInit {
  blockedDocument: boolean;

  form;

  asignacion: InvAsignacionDTO = {};
  asignacionList: InvAsignacionDTO[];

  dialogoConfirmaRecibido: boolean;

  tipoAsignacionList: InvMaTipoAsignacion[];
  personaItems: SelectItem[];
  bienList: InvBienDTO[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private inventarioEquiposService: InventarioEquiposService,
    private personaService: PersonaService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
    // Crear formulario Guardar Bien
    this.form = formBuilder.group({
      idPersona: [null, [Validators.required]],
      idTipoAsignacion: [null, [Validators.required]],
      fechaInicio: [null, [Validators.required]],
      confirmaRecibido: [null, [Validators.required]],
    });
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    // Inicializar listas parametricas
    forkJoin(
      this.inventarioEquiposService.srvListarBien(),
      this.personaService.getPersonasUsuarios2(),
      this.inventarioEquiposService.srvListarTipoAsignacion()
    ).subscribe(
      ([bienListData, personaListData, tipoAsignacionListData]) => {
        this.bienList = bienListData.bienes;

        // console.log(this.bienList);

        this.personaItems = personaListData.map((item) => ({
          label: item.nombre,
          value: item.id,
        }));

        this.tipoAsignacionList = tipoAsignacionListData.tiposAsignacion;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    // Listar mis asignaciones
    this.listarMisAsignaciones();
  }

  /**
   * Lista las asignaciones asociadas al usuario logueado
   */
  listarMisAsignaciones() {
    forkJoin(
      this.inventarioEquiposService.srvListarAsignacionesPorPersona()
    ).subscribe(
      ([asignacionesListData]) => {
        // console.log(asignacionesListData);
        this.asignacionList = asignacionesListData.asignaciones;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**
   * Metodo que se llama cuando en la datatable se llama la opcion de Confirmar Recibido
   */
  confirmarRecibido(asignacionL: InvAsignacionDTO) {
    this.asignacion = asignacionL;
    this.asignacion.fechaInicio = new Date(this.asignacion.fechaInicio);
    //this.asignacion.fechaFin = new Date(this.asignacion.fechaFin);
    this.asignacion.fechaConfirmaRecibido = new Date(
      this.asignacion.fechaConfirmaRecibido
    );

    // Cargar el objeto del dropdown Bien
    this.asignacion.idBienDTO = this.bienList.find(
      (t) => t.id === this.asignacion.idBien
    );

    // Cargar el objeto del dropdown Tipo Asignacion
    this.asignacion.idTipoAsignacionDTO = this.tipoAsignacionList.find(
      (t) => t.id === this.asignacion.idTipoAsignacion
    );

    // console.log(this.asignacion.idBienDTO);

    this.dialogoConfirmaRecibido = true;
  }

  /**
   * Metodo que se llama cuando se confirma el recibido en el dialog
   */
  submitConfirmarRecibido() {
    console.log(this.form);
    if (this.form.valid) {
      // Completar el DTO a guardar
      if (this.asignacion.idBienDTO != null) {
        this.asignacion.idBien = this.asignacion.idBienDTO.id;
      }
      if (this.asignacion.idTipoAsignacionDTO != null) {
        this.asignacion.idTipoAsignacion = this.asignacion.idTipoAsignacionDTO.id;
      }

      this.asignacion.fechaConfirmaRecibido = new Date();

      // console.log(this.asignacion);

      this.guardarRegistroAsignacion();
    } else {
      this.lanzarMensajeWarning('Debe confirmar el recibido');
    }
  }

  /**
   * Guarda la relacion de asignacion con bien
   */
  guardarRegistroAsignacion() {
    // Guardar el DTO
    forkJoin(
      this.inventarioEquiposService.srvGuardarRegistroAsignacion(this.asignacion)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // console.log('----------srvGuardarAsignacionBien');
            // console.log(result.respuestaServicio.mensajeSalida);
            // Consultar nuevamente la lista
            this.listarMisAsignaciones();
            // Limpiar Formulario
            this.form.reset();
            this.form.markAsPristine();
            this.form.markAsUntouched();
            // Cerrar el dialogo
            this.dialogoConfirmaRecibido = false;
            // Lanzar mensaje de exito
            this.messageService.add({
              severity: 'info',
              summary: 'Información',
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });

            // Generar PDF Inventario
            forkJoin(
              this.inventarioEquiposService.srvGeneracionPdfInventario(this.asignacion.id)
            ).subscribe(
              ([result]) => {
                // console.log(result);
                if (result != null) {
                  console.log('----------srvGeneracionPdfInventario');
                  // console.log(result);
                } else {
                  console.log(
                    'Ocurrio un error al consumir el servicio srvGeneracionPdfInventario'
                  );
                }
              }, error => {
                this.principalComponent.cargarErrorServicio(error);
              },
              () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
              }
            );

          } else {
            // console.log(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.log(
            'Ocurrio un error al consumir el servicio srvGuardarAsignacionBien'
          );
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
