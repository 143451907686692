import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NovedadesConstractualesService } from '../novedades-contractuales.service';
import { CtoNovedadContratoDTO } from '../../../contratacion-dto/cto-novedad-contrato';
import { ParametricasService } from '../../../../parametricas/parametricas.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { ContratacionService } from '../../../contratacion.service';
import { CtoTipoNovedadDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-novedad';

@Component({
  selector: 'app-novedades-contrato',
  templateUrl: './novedades-contrato.component.html',
  styles: []
})
export class NovedadesContratoComponent implements OnInit {

  idContrato: number;
  novedadesContrato: CtoNovedadContratoDTO[];
  novedadSeleccionada: CtoNovedadContratoDTO;
  tipoNovedades: CtoTipoNovedadDTO[];
  tipoNovedadSeleccionada: CtoTipoNovedadDTO;
  totalPaginator: any;
  blockedDocument = false;
  visibleDialogoAgregarRegistro = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private novedadesConstractualesService: NovedadesConstractualesService,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.idContrato = params.idContrato;
      this.cargarNovedadesPorContrato();
    });
  }

  /**
   * Metodo que consulta la parametrica de tipos de novedad contractual
   * @param event Evento autocompletar para buscar sobre los tipos de novedades listadas
   */
  cargarTipoNovedades(event) {
    const filtroTipoNovedades = [];
    this.contratacionService.getCtoTipoNovedadActivos()
      .subscribe(
        result => {
          if (event != null) {
            if (result != null) {
              this.tipoNovedades = result;
              let existeFiltro: boolean;
              for (const tip of this.tipoNovedades) {
                if (String(tip.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                  filtroTipoNovedades.push(tip);
                  existeFiltro = true;
                }
              }
              if (existeFiltro) {
                this.tipoNovedades = filtroTipoNovedades;
              }
            }
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        }
      );
  }

  /**
   * Metodo que carga las novedades contractuales del contrato dado su id
   */
  cargarNovedadesPorContrato() {
    this.blockedDocument = true;
    this.novedadesConstractualesService.ConsultarNovedadesPorContrato(this.idContrato)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.novedadesContrato = result.novedadesContrato;
              this.totalPaginator = this.novedadesContrato.length;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  /**
   * Metodo que actualiza el id del contrato de acuerdo al componente generico 
   * @param contrato Objeto con la información del contrato
   */
  actualizarContratoInformacionVigente(contrato: CtoContratoInformacionVigenteEncabezadoDTO) {
    this.idContrato = contrato.id;
  }

  /**
   * Metodo que inicializa el formulario para agregar una novedad contractual
   */
  irAgregarNovedadContrato() {
    this.visibleDialogoAgregarRegistro = true;
    this.tipoNovedadSeleccionada = null;
  }

  /**
   * Metodo que redirecciona a al formulario y componente de la novedad seleccionada.
   * En caso que no seleccione una novedad saldrá mensaje de error
   */
  aceptarAgregarNovedadContrato() {
    if (this.tipoNovedadSeleccionada != null && this.tipoNovedadSeleccionada.id != null) {
      this.router.navigate([this.router.url + '/novedadesContractualesRegistro', this.tipoNovedadSeleccionada.id, 0]);
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Seleccione un tipo de novedad valido', life: 10000 });
    }

  }

  /**
   * Metodo que cancela la acción de del formulario para agregar una novedad contractual
   */
  cancelarAgregarNovedadContrato() {
    this.visibleDialogoAgregarRegistro = false;
    this.tipoNovedadSeleccionada = null;
  }

  /**
   * Metodo que redirecciona al componente de la novedad contractual previamente registrada para edición
   * @param novedad Novedad seleccionada de tipo CtoNovedadContratoDTO
   */
  irEditarNovedadContrato(novedad: CtoNovedadContratoDTO) {
    this.router.navigate([this.router.url + '/novedadesContractualesRegistro', novedad.idTipoNovedad, novedad.id]);
  }

  irEliminarNovedadContrato(novedad: CtoNovedadContratoDTO) {
    this.novedadSeleccionada = novedad;
    this.visibleDialogoConfirmarEliminarRegistro = true;
  }

  aceptarEliminarRegistro() {
    this.blockedDocument = true;
    this.visibleDialogoConfirmarEliminarRegistro = false;
    this.visibleDialogoExitoEliminarRegistro = false;
    this.novedadesConstractualesService.srvEliminarNovedad(this.novedadSeleccionada.id).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.visibleDialogoExitoEliminarRegistro = true;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
        this.ngOnInit();
      }
    );
  }


  irContratosNovedades() {
    this.router.navigate(['/contratosHome/novedadesContractuales/']);
  }

}
