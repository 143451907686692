import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Serie } from '../rpt-crr-peajes-trafico-recaudo.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ReportesCarreteroService } from '../../../reportes-carreteros.service';
import { ReportesPeajesService } from '../../../reportes-peajes.service';
import { PjePeajeConsultaTPDDTO } from '../../../reportes-peajes.dto';
import { EnumTipoProyecto } from 'src/app/comun/enum-tipo-proyecto';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { PjePeajePorProyecto } from 'src/app/peajes-gestion/trafico-peajes/PjePeajePorProyecto';
import { TraficoPeajesCargaService } from 'src/app/peajes-gestion/trafico-peajes/trafico-peajes-carga/trafico-peajes-carga.service';
import { FormBuilder, Validators } from '@angular/forms';
import { InformacionGeneralPeajesService } from 'src/app/peajes-gestion/informacion-general-peajes/informacion-general-peajes.service';

@Component({
  selector: 'app-rpt-crr-peajes-tpd',
  templateUrl: './rpt-crr-peajes-tpd.component.html',
  styleUrls: ['./rpt-crr-peajes-tpd.component.sass']
})
export class RptCrrPeajesTpdComponent implements OnInit {

  private isAuthenticated: boolean;
  blockedDocument = false;

  //General gráficas
  colores: any[];

  fechaActual: Date;

  formReporte;

  //Proyectos
  proyectos: any[] = [];
  proyectoFiltroDTO: any;
  //Peajes
  peajesPorProyecto: any[];
  peajeFiltro: PjePeajePorProyecto;

  //TPD
  fechaCorteTPD: Date;
  optionschartTPD: any;
  seriesTPD: Serie[];



  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private parametricasService: ParametricasService,
    private reportesPeajesService: ReportesPeajesService,
    private informacionGeneralPeajesService: InformacionGeneralPeajesService,
    private principalComponent: PrincipalComponent,
    private adalSvc: MsAdalAngular6Service,
    private ruta: ActivatedRoute) {

    this.formReporte = formBuilder.group({
      proyectoFiltro: [null, [Validators.required]],
      peajeFiltro: [null, [Validators.required]],
      fechaCorteFiltro: [null, [Validators.required]],
    });

    this.colores = ["#11528a", "#01d3c1", "#00c0ff"];
    Highcharts.setOptions({
      lang: {
        loading: 'Cargando...',
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],

        rangeSelectorFrom: "Desde",
        rangeSelectorTo: "Hasta",
        rangeSelectorZoom: "Período",
        downloadPNG: 'Descargar imagen PNG',
        downloadJPEG: 'Descargar imagen JPEG',
        downloadPDF: 'Descargar imagen PDF',
        downloadSVG: 'Descargar imagen SVG',
        printChart: 'Imprimir',
        resetZoom: 'Reiniciar zoom',
        resetZoomTitle: 'Reiniciar zoom',
        thousandsSep: ".",
        decimalPoint: ',',
        drillUpText: 'Volver a Categorias'
      }
    });


  }

  ngOnInit() {
    this.isAuthenticated = this.adalSvc.isAuthenticated;

    this.fechaActual = new Date();
    this.fechaCorteTPD = this.fechaActual;


    this.cargarProyectos(null);
  }

  //
  /**
     * Metodo que invoca el servicio para consultar la tabla parametrica del listado de proyectos carreteros
     * @param event Evento
     * @returns Listado Observable con el resultado de la operación
     */
  cargarProyectos(event) {
    const filtroList = [];
    return this.parametricasService.getProyectosPorTipo(EnumTipoProyecto.Carretero)
      .subscribe(
        (result) => {
          this.proyectos = result;
          if (this.proyectos === null) {
            this.proyectos = [];
          }

          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.proyectos.length; i++) {
              const filtro = this.proyectos[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroList.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.proyectos = filtroList;
            }
          }
        });
  }

  cargarPeajes(event) {
    this.blockedDocument = true;
    this.peajeFiltro = null;
    const filtroList = [];
    this.informacionGeneralPeajesService.srvConsultarPeajesPorProyecto(this.proyectoFiltroDTO.id).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.peajesPorProyecto = result.peajes;
            if (this.peajesPorProyecto === null) {
              this.peajesPorProyecto = [];
            }

            if (event != null) {
              let existeFiltro: boolean;
              for (let i = 0; i < this.peajesPorProyecto.length; i++) {
                const filtro = this.peajesPorProyecto[i];
                if (String(filtro.peaje).toLowerCase().includes(event.query.toLowerCase())) {
                  filtroList.push(filtro);
                  existeFiltro = true;
                }
              }
              if (existeFiltro) {
                this.peajesPorProyecto = filtroList;
              }
            }
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * TPD
  */
  //#region <TPD>

  /**
   * Metodo que llama al servicio de consulta TPDA y el TPDM de un peaje por fecha de corte y de acuerdo al resultado genera el chart
   */
  cargarPeajeTPD() {
    this.blockedDocument = true;
    this.reportesPeajesService.srvConsultarTPDPeaje(this.peajeFiltro.idPeaje, this.fechaCorteTPD).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.generarChartTPD(result.tpdPeaje);
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que controla el evento de seleccion de la @fechaCorteTPD
   */
  onSelectFechaCorteTPD() {
    this.cargarPeajeTPD();
  }

  /**
   * Metodo que genera el char para el TPDA y TPDM de un Peaje
   * 
   * @param tpdPeaje Info del peaje
   */
  generarChartTPD(tpdPeaje: PjePeajeConsultaTPDDTO) {

    let mensajeCalculoTPD = '';

    if (tpdPeaje.tpda == null && tpdPeaje.tpdm == null) {
      mensajeCalculoTPD = 'No se puede calcular el TPDA ni el TPDM porque no se registra reporte del mes según fecha de corte';
    } else {
      if (tpdPeaje.tpda == null && tpdPeaje.tpdm != null) {
        mensajeCalculoTPD = 'No se puede calcular el TPDA porque no se registra reporte de todos los meses para la fecha de corte';
      }
    }


    const dataEjeXtpdaArr = [];

    //Data del eje X, se arma cada dato el valor de Y, y el nombre X    
    const dataEjeXtpdm = {
      y: tpdPeaje.tpdm,//Valor eje Y
      name: 'TPDM',//Leyenda eje x Valor      
      custom: {
        mensajeInformativo: 'Tráfico promedio diario mensual calculado con base en los días calendario del mes consultado.',
      }
    };
    dataEjeXtpdaArr.push(dataEjeXtpdm);

    const dataEjeXtpda = {
      y: tpdPeaje.tpda,//Valor eje Y
      name: 'TPDA', //Leyenda eje x Valor
      custom: {
        mensajeInformativo: 'Tráfico promedio diario anual calculado con base en los días calendario de los últimos 12 meses anteriores al mes consultado.',
      }
    };
    dataEjeXtpdaArr.push(dataEjeXtpda);

    //Data del eje X
    const infoSerieTPD = {
      name: this.fechaCorteTPD.getMonth() + 1 + '-' + this.fechaCorteTPD.getFullYear().toString(), //Leyenda eje X
      color: this.colores[0],
      data: dataEjeXtpdaArr
    };

    this.seriesTPD = [];
    this.seriesTPD.push(infoSerieTPD);

    this.optionschartTPD = {
      chart: {
        type: "column",
      },
      credits: { enabled: false },
      title: { text: "Tráfico promedio diario" },
      subtitle: { text: mensajeCalculoTPD },
      xAxis: {
        type: "category"
      },
      yAxis: {
        min: 0,
        title: { text: "" }, labels: {
          format: '{value} '
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr>' +
          '<td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f}</b></td>' +
          '</tr>' +
          '<tr>' +
          '<td style="">{point.custom.mensajeInformativo}</td>' +
          '<td></td>' +
          '</tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },


      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.y:.0f}',
            align: 'right',
            color: '#11528a',
            x: -10
          },
          pointPadding: 0.1,
          groupPadding: 0
        }
      },
      series: this.seriesTPD,
    };
    Highcharts.chart("chartTPD", this.optionschartTPD);
  }

  //#endregion 


  //Utilidades

  formatoMoneda(valor: number) {
    return valor
      ? "$" +
      (valor / 1000000).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
      " mill."
      : "$0";
  }
  formatoCantidad(valor: number) {
    return valor
      ? "" +
      (valor / 1000000).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
      " mill."
      : "0";
  }

  atras() {
    window.history.back();
  }


  /**
  * Lanza mensaje de informacion
  */
  lanzarMensajeInfo(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.messageService.add({ severity: "error", summary: "Error", detail: mensaje, life: 10000, });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}