import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DireccionService, Nomenclatura } from './direccion.service';
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-direccion',
  templateUrl: './direccion.component.html',
  styleUrls: ['./direccion.component.sass']
})
export class DireccionComponent implements OnInit {
  @Input()
  mostrarDialogo: boolean = true;
  @Output()
  direccionCreada = new EventEmitter<string>();
  @Output()
  cancelado = new EventEmitter<string>();

  principal: string;
  principalNumero: string;
  principalLetra1: string;
  principalModificador1: string;
  principalLetra2: string;
  principalModificador2: string;


  principales: Nomenclatura[] = [];
  letras: string[] = [];
  modificadores1: string[] = [];
  modificadores2: string[] = [];

  secundarioNumero: string;
  secundarioLetra: string;
  secundarioComp: string;
  secundarioModificador: string;

  complemento: string;
  complementoValor: string;
  complementos: Nomenclatura[] = [];

  direccion: string = '';
  direccionComplemento: string = '';

  cargando: boolean = true;

  constructor(
    private servDireccion: DireccionService,
    private componentePrincipal: PrincipalComponent,
    private messageService: MessageService) { }

  ngOnInit() {
    this.servDireccion.ObtenerListasDireccion().subscribe(respuesta => {
      if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        this.componentePrincipal.cargarErrorServicio({
          error: {
            message: EnumRespuestaServicio.ERROR_OPERACION,
            logEvento: respuesta.respuestaServicio.mensajeSalida
          }
        });
        return;
      }

      this.principales = respuesta.listadoNomenclaturas;
      this.letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      this.modificadores1 = ['BIS'];
      this.modificadores2 = ['SUR', 'ESTE'];
      this.complementos = respuesta.listadoComplementos;
    },
    error => {
      console.error(error);
      this.componentePrincipal.cargarErrorServicio(error);
    },
    () => {
      this.cargando = false;
    });
  }

  public construirDireccion() {
    this.direccion = `${this.principal != null ? this.principal + ' ' : ''}${this.principalNumero != null ? this.principalNumero : ''}` +
    `${this.principalLetra1 != null ? this.principalLetra1 : ''} ` +
    `${this.principalModificador1 != null ? this.principalModificador1 + ' ' : ''}${this.principalLetra2 != null ? this.principalLetra2 + ' ' : ''}` +
    `${this.principalModificador2 != null ? this.principalModificador2 + '  ' : ''} ${this.secundarioNumero != null ? this.secundarioNumero : ''}` +
    `${this.secundarioLetra != null ? this.secundarioLetra : ''} ${this.secundarioComp != null ? this.secundarioComp + ' ' : ''}` +
    `${this.secundarioModificador != null ? this.secundarioModificador + '  ' : ''} ${this.direccionComplemento}`;
  }

  public completar() {
    this.direccionCreada.emit(this.direccion.trim());
  }

  public limpiar() {
    this.direccion = '';
    this.principal = null;
    this.principalNumero = null;
    this.principalLetra1 = null;
    this.principalModificador1 = null;
    this.principalLetra2 = null;
    this.principalModificador2 = null;

    this.secundarioNumero = null;
    this.secundarioLetra = null;
    this.secundarioComp = null;
    this.secundarioModificador = null;
  }

  public cancelar() {
    this.cancelado.emit('cancelado');
  }

  public agregarComplemento() {
    if (this.complemento == '' || this.complemento == null || this.complementoValor == '' || this.complementoValor == null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Seleccione el tipo de complemento y su valor. Ej. Apartamento 307.", life: 2000 });
      return;
    }

    this.direccionComplemento += `${this.complemento} ${this.complementoValor} `;
    this.complemento = null;
    this.complementoValor = null;
    this.construirDireccion();
  }
}
