import { Component } from '@angular/core';

@Component({
  selector: 'app-proyectos-salud',
  templateUrl: './proyectos-salud.component.html',
  styleUrls: ['./proyectos-salud.component.sass']
})
export class ProyectosSaludComponent {

}
