import { Component, OnInit, ViewChild } from '@angular/core';
import { UnidadFuncionalCarreteroService } from './unidad-funcional-carretero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { UnidadFuncional } from './unidadFuncional';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { IntervencionUnidadFuncional } from './intervencionUnidadFuncional';
import { Subtramo } from './subtramo';
import { ParametricasService } from './../parametricas/parametricas.service';
import { NgForm } from '@angular/forms';
import { UnidadFuncionalCarreteroDetalleService } from '../unidad-funcional-carretero-detalle/unidad-funcional-carretero-detalle.service';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-unidad-funcional-carretero',
  templateUrl: './unidad-funcional-carretero.component.html',
  styleUrls: ['./unidad-funcional-carretero.component.sass']
})
export class UnidadFuncionalCarreteroComponent implements OnInit {

  unidadesFuncionales: any = [];
  intervencionUnidadFuncional: IntervencionUnidadFuncional = {};

  idUnidadFuncionalSeleccionada: any;
  subtramo: Subtramo = {};
  subtramos: any = [];
  visibleConfirmarEliminarSubtramo: boolean = false;



  departamentos: any = [];
  filtroDepartamentos: any[];
  departamentoSeleccionado: any;

  ciudadesOrigen: any = [];
  filtroCiudadesOrigen: any[];
  ciudadSeleccionadaOrigen: any;


  ciudadesDestino: any = [];
  filtroCiudadesDestino: any[];
  ciudadSeleccionadaDestino: any;

  displayedColumns: any[];

  dataSource: MatTableDataSource<UnidadFuncional>;
  unidadFuncionalSeleccionada: UnidadFuncional;



  displayedColumnsSubtramo: string[] = ['nombre', 'longitud', 'acciones'];
  dataSourceSubtramo: MatTableDataSource<Subtramo>;
  visibleListaSubtramo: boolean;
  visibleNuevoSubtramoPorUnidadFuncional: boolean;
  visibleDialogoExitoSubtramo: boolean;
  visibleDialogoExitoEliminarSubtramo: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  totalUnidadesPaginator: any;
  elementosPorPagina: any;

  referenciaLinealOrigen: any = [];
  filtroReferenciaLinealOrigen: any[];
  itemReferenciaLinealOrigen: any;
  referenciaLinealxOrigen: any;
  referenciaLinealyOrigen: any;

  referenciaLinealDestino: any = [];
  filtroReferenciaLinealDestino: any[];
  itemReferenciaLinealDestino: any;
  referenciaLinealxDestino: any;
  referenciaLinealyDestino: any;
  totalFactor: number = 0.0;


  constructor(private lineaBase: ProyectoLineaBaseComponent, private parametricasService: ParametricasService, private unidadFuncionalCarreteroDetalleService: UnidadFuncionalCarreteroDetalleService,
    private unidadFuncionalService: UnidadFuncionalCarreteroService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  applyFilter(valor) {
    // TODO: Implementar
  }

  ngOnInit() {
    this.lineaBase.idProyecto === 'new' ? this.cargarNuevo() : this.cargarUnidadesFuncionales(this.lineaBase.idProyecto);
    this.displayedColumns = [
      { field: 'codigo', header: 'Código' },
      { field: 'nombre', header: 'Nombre' },
      { field: 'formatoFactorPonderacion', header: 'Factor de ponderación' }
    ];
  }

  cargarNuevo() {
    this.unidadesFuncionales = [];
  }

  cargarUnidadesFuncionales(id: number) {
    this.totalFactor = 0.0;
    return this.unidadFuncionalService.getUnidadesFuncionalesPorProyecto(id).subscribe((data: {}) => {
      this.unidadesFuncionales = data;
      this.lineaBase.habilitaTabContratoCredito = true;
      this.unidadesFuncionales.forEach(element => {
        this.totalFactor = this.totalFactor + element.factorPonderacion;
      });
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }



  cargarSubtramosPorUnidadFuncional(idUnidadFuncional) {
    return this.unidadFuncionalService.getSubtramosPorUnidadFuncional(idUnidadFuncional).subscribe((data: {}) => {
      this.subtramos = data;
      this.dataSourceSubtramo = new MatTableDataSource(this.subtramos);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  onNew() {
    this.lineaBase.visibleListaUnidadFuncional = false;
    this.lineaBase.visibleUnidadFuncionalDettalle = true;
    this.lineaBase.idUnidadFuncional = "new";
    this.lineaBase.unidadFuncional = {};
    this.lineaBase.ciudadSeleccionadaOrigen = {};
    this.lineaBase.ciudadSeleccionadaDestino = {};
    this.lineaBase.departamentoSeleccionadoOrigen = {};
    this.lineaBase.departamentoSeleccionadoDestino = {};
  }

  irAgregarSubtramo(unidadFuncional: UnidadFuncional) {
    this.unidadFuncionalSeleccionada = unidadFuncional;
    this.idUnidadFuncionalSeleccionada = this.unidadFuncionalSeleccionada.id;
    this.visibleListaSubtramo = true;
    this.visibleNuevoSubtramoPorUnidadFuncional = false;
    this.cargarSubtramosPorUnidadFuncional(this.idUnidadFuncionalSeleccionada);
  }

  nuevoSubtramoUnidadFuncional() {
    this.subtramo.idUnidadFuncional = this.idUnidadFuncionalSeleccionada;
    this.visibleNuevoSubtramoPorUnidadFuncional = true;
    this.lineaBase.idSubtramo = "new";
  }

  editarSubtramoUnidadFuncional(idSubtramo) {
    this.subtramo.idUnidadFuncional = this.idUnidadFuncionalSeleccionada;
    this.visibleNuevoSubtramoPorUnidadFuncional = true;
    this.lineaBase.idSubtramo = idSubtramo;
    return this.unidadFuncionalService.getSubtramosPorId(idSubtramo).subscribe((data: {}) => {
      this.subtramo = data;
      this.subtramo.longitud = this.subtramo.longitud / 1000;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cancelarAgregarSubtramo() {
    this.visibleListaSubtramo = false;
    this.subtramo = {};
  }



  irAgregarTipoIntervencion(unidadFuncional: UnidadFuncional) {
    this.unidadFuncionalSeleccionada = unidadFuncional;
    this.lineaBase.visibleTiposIntervencion = true;
    this.lineaBase.visibleListaUnidadFuncional = false;
    this.lineaBase.idUnidadFuncional = this.unidadFuncionalSeleccionada.id;
    return this.unidadFuncionalCarreteroDetalleService.getUnidadFuncionalPorId(this.unidadFuncionalSeleccionada.id).subscribe((data: {}) => {
      this.lineaBase.unidadFuncional = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
    /*this.visibleListaTipoIntervencion = true;
    this.visibleNuevaIntervencionPorUnidadFuncional = false; */

  }







  cancelarNuevoSubtramo() {
    this.visibleNuevoSubtramoPorUnidadFuncional = false;
    this.subtramo = {};
  }





  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.departamentos.length; i++) {
        let filtro = this.departamentos[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDepartamentos.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.departamentos = this.filtroDepartamentos;
      }
    });
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      let filtro = this.departamentos[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  cargarCiudadesOrigen(event) {
    this.filtroCiudadesOrigen = [];
    return this.parametricasService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id).subscribe((data: {}) => {
      this.ciudadesOrigen = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.ciudadesOrigen.length; i++) {
        let filtro = this.ciudadesOrigen[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroCiudadesOrigen.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.ciudadesOrigen = this.filtroCiudadesOrigen;
      }
    });
  }

  cargarFiltroCiudadesOrigen(event) {
    this.filtroCiudadesOrigen = [];
    for (let i = 0; i < this.ciudadesOrigen.length; i++) {
      let filtro = this.ciudadesOrigen[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudadesOrigen.push(filtro);
      }
    }
  }

  cargarCiudadesDestino(event) {
    this.filtroCiudadesDestino = [];
    return this.parametricasService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id).subscribe((data: {}) => {
      this.ciudadesDestino = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.ciudadesDestino.length; i++) {
        let filtro = this.ciudadesDestino[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroCiudadesDestino.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.ciudadesDestino = this.filtroCiudadesDestino;
      }
    });
  }

  cargarFiltroCiudadesDestino(event) {
    this.filtroCiudadesDestino = [];
    for (let i = 0; i < this.ciudadesDestino.length; i++) {
      let filtro = this.ciudadesDestino[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudadesDestino.push(filtro);
      }
    }
  }

  cargarListaReferenciaLinealOrigen(event) {
    this.filtroReferenciaLinealOrigen = [];
    this.referenciaLinealOrigen = [
      { label: 'PR', value: 'PR' },
      { label: 'K', value: 'K' },
    ];
    let existeFiltro: boolean;
    for (let i = 0; i < this.referenciaLinealOrigen.length; i++) {
      let filtro = this.referenciaLinealOrigen[i];
      let item: string = String(filtro.value);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroReferenciaLinealOrigen.push(filtro);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.referenciaLinealOrigen = this.filtroReferenciaLinealOrigen;
    }

  }

  cargarFiltroReferenciaLinealOrigen(event) {
    this.filtroReferenciaLinealOrigen = [];
    for (let i = 0; i < this.referenciaLinealOrigen.length; i++) {
      let filtro = this.referenciaLinealOrigen[i];
      let item: string = String(filtro.value);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroReferenciaLinealOrigen.push(filtro);
      }
    }
  }

  cargarListaReferenciaLinealDestino(event) {
    this.filtroReferenciaLinealDestino = [];
    this.referenciaLinealDestino = [
      { label: 'PR', value: 'PR' },
      { label: 'K', value: 'K' },
    ];
    let existeFiltro: boolean;
    for (let i = 0; i < this.referenciaLinealDestino.length; i++) {
      let filtro = this.referenciaLinealDestino[i];
      let item: string = String(filtro.value);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroReferenciaLinealDestino.push(filtro);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.referenciaLinealDestino = this.filtroReferenciaLinealDestino;
    }

  }

  cargarFiltroReferenciaLinealDestino(event) {
    this.filtroReferenciaLinealDestino = [];
    for (let i = 0; i < this.referenciaLinealDestino.length; i++) {
      let filtro = this.referenciaLinealDestino[i];
      let item: string = String(filtro.value);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroReferenciaLinealDestino.push(filtro);
      }
    }
  }







  agregarSubtramo(forma: NgForm) {
    if (this.lineaBase.idSubtramo == "new") {
      this.subtramo.idTema = 1;
      let vara: any = this.unidadFuncionalService.guardarNuevoSubtramoPorUnidadFuncional(this.subtramo).subscribe((data: {}) => {
        this.visibleDialogoExitoSubtramo = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      let vara: any = this.unidadFuncionalService.editarSubtramoPorUnidadFuncional(this.subtramo, this.lineaBase.idSubtramo).subscribe((data: {}) => {
        this.visibleDialogoExitoSubtramo = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  aceptarDialogoExitoSubtramo() {
    this.visibleDialogoExitoSubtramo = false;
    this.cargarSubtramosPorUnidadFuncional(this.idUnidadFuncionalSeleccionada);
    this.cancelarNuevoSubtramo();
  }

  editarUnidadFuncional(unidadFuncional: UnidadFuncional) {
    this.unidadFuncionalSeleccionada = unidadFuncional;
    return this.unidadFuncionalCarreteroDetalleService.getUnidadFuncionalPorId(this.unidadFuncionalSeleccionada.id).subscribe((data: {}) => {
      this.lineaBase.unidadFuncional = data;
      this.lineaBase.unidadFuncional.longitudConcesionada = this.lineaBase.unidadFuncional.longitudConcesionada / 1000;
      this.lineaBase.unidadFuncional.longitudReal = this.lineaBase.unidadFuncional.longitudReal / 1000;
      this.lineaBase.unidadFuncional.factorPonderacion = this.lineaBase.unidadFuncional.factorPonderacion * 100;
      this.lineaBase.visibleListaUnidadFuncional = false;
      this.lineaBase.visibleUnidadFuncionalDettalle = true;
      this.lineaBase.idUnidadFuncional = this.unidadFuncionalSeleccionada.id;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irEliminarSubtramo(id) {
    this.visibleConfirmarEliminarSubtramo = true;
    this.lineaBase.idSubtramo = id;
  }

  cancelarEliminarSubtramo() {
    this.visibleConfirmarEliminarSubtramo = false;
  }

  aceptarEliminarSubtramo() {
    return this.unidadFuncionalService.eliminarSubtramoPorUnidadFuncional(this.lineaBase.idSubtramo).subscribe((data: {}) => {
      this.visibleConfirmarEliminarSubtramo = false;
      this.cargarSubtramosPorUnidadFuncional(this.idUnidadFuncionalSeleccionada);
      this.visibleDialogoExitoEliminarSubtramo = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarSubtramo() {
    this.visibleDialogoExitoEliminarSubtramo = false;
  }

}
