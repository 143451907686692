import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-inventario-equipos',
  templateUrl: './inventario-equipos.component.html',
  styleUrls: ['./inventario-equipos.component.sass']
})
export class InventarioEquiposComponent implements OnInit {

  constructor(private router: Router, private ruta: ActivatedRoute) { }

  ngOnInit() {
    const element = document.getElementById('aniscopio_body');
      element.className = 'ani public logeo contratacion';
  }

  irARegistrarModelo() {
    this.router.navigate([this.router.url + '/registrar-modelo']);
  }

  irARegistrarBien() {
    this.router.navigate([this.router.url + '/registrar-bien']);
  }

  irAMisBienes() {
    this.router.navigate([this.router.url + '/mis-bienes']);
  }

  irAReportesInventario() {
    this.router.navigate([this.router.url + '/reportes-inventario']);
  }

}
