import { Component, OnInit } from '@angular/core';
import { CtoReporteCxpContratosDTO, CtoReporteCxpContratosFiltroDTO } from '../../contratacion-dto/cto-reporte-cxp-contratos.dto';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { ReportesCuentasCobroCxpService } from '../reporte-contratacion-cuentas-cobro.service';

@Component({
  selector: 'app-cto-reporte-cxp-contratos',
  templateUrl: './cto-reporte-cxp-contratos.component.html',
  styles: []
})
export class CtoReporteCxpContratosComponent implements OnInit {

  // Informacion del datatable
  reporteCxpContratos: CtoReporteCxpContratosDTO[] = [];
  totalRecords: number;
  loading: boolean;

  //Filtros
  reporteCxpContratosFiltro: CtoReporteCxpContratosFiltroDTO;

  formReporteCxpContratosFiltro;

  DATATABLE_CANTIDAD_REGISTROS = 10;
  DATATABLE_ASCDESC = 1;//1=asc, !=1 desc
  DATATABLE_CAMPO_ORDEN = 'id';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private datepipe: DatePipe,
    private principalComponent: PrincipalComponent,
    private reportesContratoCuentasCobroService: ReportesCuentasCobroCxpService,
    private parametricasService: ParametricasService
  ) {
    const regexMayorACero = "^[1-9][0-9]*$";

    this.formReporteCxpContratosFiltro = formBuilder.group({
      formVigenciaFiltro: [null, [Validators.min(1), Validators.max(9999), Validators.minLength(1), Validators.maxLength(4), Validators.pattern(regexMayorACero)]],
      formFechaActaInicioRangoDesdeFiltro: [null, null],
      formFechaActaInicioRangoHastaFiltro: [null, null],
      formFechaSuscripcionRangoDesdeFiltro: [null, null],
      formFechaSuscripcionRangoHastaFiltro: [null, null],
      formFechaTerminacionRangoDesdeFiltro: [null, null],
      formFechaTerminacionRangoHastaFiltro: [null, null]
    });
  }

  /**
   * Metodo que incializa invoca el listado de parametricas
   */
  ngOnInit() {
    this.reporteCxpContratosFiltro = {};
    this.inicializarVariablesFiltros();
  }

  /**
   * Metodo utilizado para la paginación y el uso de filtros
   * @param event 
   */
  loadDataLazy(event: LazyLoadEvent) {
    if (this.formReporteCxpContratosFiltro.valid) {
      this.resetearPaginacionVariablesFiltros();
      this.cargarReporteCxpContratos(event.first, event.rows, ((event.sortField === null || event.sortField === undefined) ? this.reporteCxpContratosFiltro.campoOrden : event.sortField), ((event.sortOrder === null || event.sortOrder === undefined) ? this.reporteCxpContratosFiltro.ascDesc : event.sortOrder));
    }
  }

  /**
   * Metodo consultar la información del datatable de acuerdo a los filtros definidos   
   */
  buscar() {
    if (this.validarFormularioFiltros()) {
      this.resetearPaginacionVariablesFiltros();
      this.cargarReporteCxpContratos(this.reporteCxpContratosFiltro.filaDesde, this.reporteCxpContratosFiltro.cantidadRegistros, this.reporteCxpContratosFiltro.campoOrden, this.reporteCxpContratosFiltro.ascDesc);
    }
  }

  /**
   * Metodo para inciializar las variables del modelo usadas para los filtros selecionados
   */
  inicializarVariablesFiltros() {
    this.reporteCxpContratosFiltro.vigencia = null;
    this.reporteCxpContratosFiltro.fechaActaInicioRangoDesde = null;
    this.reporteCxpContratosFiltro.fechaActaInicioRangoHasta = null;
    this.reporteCxpContratosFiltro.fechaSuscripionRangoDesde = null;
    this.reporteCxpContratosFiltro.fechaSuscripionRangoHasta = null;
    this.reporteCxpContratosFiltro.fechaTerminacionRangoDesde = null;
    this.reporteCxpContratosFiltro.fechaTerminacionRangoHasta = null;

    this.resetearPaginacionVariablesFiltros();
  }

  /**
   * Metodo para inciializar las variables del modelo usadas para la paginación del Datatable 
   */
  resetearPaginacionVariablesFiltros() {
    this.reporteCxpContratosFiltro.filaDesde = 0;
    this.reporteCxpContratosFiltro.cantidadRegistros = this.DATATABLE_CANTIDAD_REGISTROS;
    this.reporteCxpContratosFiltro.buscar = null;
    this.reporteCxpContratosFiltro.campoOrden = this.DATATABLE_CAMPO_ORDEN;
    this.reporteCxpContratosFiltro.ascDesc = this.DATATABLE_ASCDESC;
  }

  /**
   * Metodo que valida que el formulario de filtros este correctamente diligenciado
   * @returns Verdado o Falso
   */
  validarFormularioFiltros() {
    let formularioValido = true;
    if (!this.formReporteCxpContratosFiltro.valid) {
      formularioValido = false;
      this.lanzarMensajeError("Para realizar la búsqueda debe indicar alguno de los filtros");
    }

    //FechaActaInicio
    if ((this.reporteCxpContratosFiltro.fechaActaInicioRangoDesde != null && this.reporteCxpContratosFiltro.fechaActaInicioRangoHasta == null)
      || (this.reporteCxpContratosFiltro.fechaActaInicioRangoDesde == null && this.reporteCxpContratosFiltro.fechaActaInicioRangoHasta != null)) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha Acta de Inicio: Debe seleccionar el rango de fechas");
    }
    if (this.reporteCxpContratosFiltro.fechaActaInicioRangoDesde != null && this.reporteCxpContratosFiltro.fechaActaInicioRangoHasta != null
      && this.reporteCxpContratosFiltro.fechaActaInicioRangoDesde > this.reporteCxpContratosFiltro.fechaActaInicioRangoHasta) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha Acta de Inicio: La fecha desde no puede ser mayor a la fecha hasta");
    }
    //FechaSuscripcion
    if ((this.reporteCxpContratosFiltro.fechaSuscripionRangoDesde != null && this.reporteCxpContratosFiltro.fechaSuscripionRangoHasta == null)
      || (this.reporteCxpContratosFiltro.fechaSuscripionRangoDesde == null && this.reporteCxpContratosFiltro.fechaSuscripionRangoHasta != null)) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha de Suscripción: Debe seleccionar el rango de fechas");
    }
    if (this.reporteCxpContratosFiltro.fechaSuscripionRangoDesde != null && this.reporteCxpContratosFiltro.fechaSuscripionRangoHasta != null
      && this.reporteCxpContratosFiltro.fechaSuscripionRangoDesde > this.reporteCxpContratosFiltro.fechaSuscripionRangoHasta) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha de Suscripción: La fecha desde no puede ser mayor a la fecha hasta");
    }
    //FechaTerminacion
    if ((this.reporteCxpContratosFiltro.fechaTerminacionRangoDesde != null && this.reporteCxpContratosFiltro.fechaTerminacionRangoHasta == null)
      || (this.reporteCxpContratosFiltro.fechaTerminacionRangoDesde == null && this.reporteCxpContratosFiltro.fechaTerminacionRangoHasta != null)) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha de Terminación: Debe seleccionar el rango de fechas");
    }
    if (this.reporteCxpContratosFiltro.fechaTerminacionRangoDesde != null && this.reporteCxpContratosFiltro.fechaTerminacionRangoHasta != null
      && this.reporteCxpContratosFiltro.fechaTerminacionRangoDesde > this.reporteCxpContratosFiltro.fechaTerminacionRangoHasta) {
      formularioValido = false;
      this.lanzarMensajeError("Fecha de Terminación: La fecha desde no puede ser mayor a la fecha hasta");
    }
    return formularioValido;
  }

  /**
   * Metodo utilizado para limpiar los campos del formulario
   */
  limpiarFiltros() {
    this.inicializarVariablesFiltros();
    this.formReporteCxpContratosFiltro.controls.formFechaActaInicioRangoHastaFiltro.clearValidators();
    this.formReporteCxpContratosFiltro.controls.formFechaSuscripcionRangoHastaFiltro.clearValidators();
    this.formReporteCxpContratosFiltro.controls.formFechaTerminacionRangoHastaFiltro.clearValidators();
    this.formReporteCxpContratosFiltro.reset();

    this.cargarReporteCxpContratos(this.reporteCxpContratosFiltro.filaDesde, this.reporteCxpContratosFiltro.cantidadRegistros, this.reporteCxpContratosFiltro.campoOrden, this.reporteCxpContratosFiltro.ascDesc);
  }

  /**
   * Metodo que maneja el evento de selección de @formFechaActaInicioRangoDesdeFiltro se obliga a selesccionar la fecha @formFechaActaInicioRangoHastaFiltro
   */
  onSelectFormFechaActaInicioRangoDesdeFiltro() {
    if (this.reporteCxpContratosFiltro.fechaActaInicioRangoDesde == null) {
      this.formReporteCxpContratosFiltro.controls.formFechaActaInicioRangoHastaFiltro.clearValidators();
    } else {
      this.formReporteCxpContratosFiltro.controls.formFechaActaInicioRangoHastaFiltro.setValidators([Validators.required]);
    }
    this.formReporteCxpContratosFiltro.controls.formFechaActaInicioRangoHastaFiltro.updateValueAndValidity();
  }

  /**
  * Metodo que maneja el evento de selección de @formFechaSuscripcionRangoDesdeFiltro se obliga a selesccionar la fecha @formFechaSuscripcionRangoHastaFiltro
  */
  onSelectFormFechaSuscripcionRangoDesdeFiltro() {
    if (this.reporteCxpContratosFiltro.fechaSuscripionRangoDesde == null) {
      this.formReporteCxpContratosFiltro.controls.formFechaSuscripcionRangoHastaFiltro.clearValidators();
    } else {
      this.formReporteCxpContratosFiltro.controls.formFechaSuscripcionRangoHastaFiltro.setValidators([Validators.required]);
    }
    this.formReporteCxpContratosFiltro.controls.formFechaSuscripcionRangoHastaFiltro.updateValueAndValidity();
  }

  /**
  * Metodo que maneja el evento de selección de @formFechaTerminacionRangoDesdeFiltro se obliga a selesccionar la fecha @formFechaTerminacionRangoHastaFiltro
  */
  onSelectFormFechaTerminacionRangoDesdeFiltro() {
    if (this.reporteCxpContratosFiltro.fechaTerminacionRangoDesde == null) {
      this.formReporteCxpContratosFiltro.controls.formFechaTerminacionRangoHastaFiltro.clearValidators();
    } else {
      this.formReporteCxpContratosFiltro.controls.formFechaTerminacionRangoHastaFiltro.setValidators([Validators.required]);
    }
    this.formReporteCxpContratosFiltro.controls.formFechaTerminacionRangoHastaFiltro.updateValueAndValidity();
  }

  /**
   * Metodo que invoca el servicio para consultar los contratos
     * @param filaDesde Valor Opcional >0 para consultar desde una fila en particular
     * @param cantidadRegistros Valor Opcional >0 para consultar X cantidad de registros
     * @param buscar Valor Opcional para consultar X dato dentro de la informacíon, se tiene definido en BD
     * @param campoOrden Valor Opcional para ordenar la información, se tiene definido en BD
     * @param ascDesc Valor Opcional númerico para ordenar los valores del listado a consultar de forma ascendente o descente
   */
  private cargarReporteCxpContratos(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.loading = true;
    this.reporteCxpContratos = [];

    this.reporteCxpContratosFiltro.filaDesde = filaDesde;
    this.reporteCxpContratosFiltro.cantidadRegistros = cantidadRegistros;
    this.reporteCxpContratosFiltro.buscar = null;
    this.reporteCxpContratosFiltro.campoOrden = campoOrden;
    this.reporteCxpContratosFiltro.ascDesc = ascDesc;

    this.reportesContratoCuentasCobroService.srvConsultarReporteCxpContratos(
      this.reporteCxpContratosFiltro.vigencia,
      this.reporteCxpContratosFiltro.fechaActaInicioRangoDesde,
      this.reporteCxpContratosFiltro.fechaActaInicioRangoHasta,
      this.reporteCxpContratosFiltro.fechaSuscripionRangoDesde,
      this.reporteCxpContratosFiltro.fechaSuscripionRangoHasta,
      this.reporteCxpContratosFiltro.fechaTerminacionRangoDesde,
      this.reporteCxpContratosFiltro.fechaTerminacionRangoHasta,
      this.reporteCxpContratosFiltro.filaDesde,
      this.reporteCxpContratosFiltro.cantidadRegistros,
      this.reporteCxpContratosFiltro.buscar,
      this.reporteCxpContratosFiltro.campoOrden,
      this.reporteCxpContratosFiltro.ascDesc
    ).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.reporteCxpContratos = result.reporteCxpContratos;
            this.totalRecords = this.reporteCxpContratos != null && this.reporteCxpContratos[0] != null ? this.reporteCxpContratos[0].totalRegistros : 0;
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Metodo utilizado para invocar la funcionalidad de exportar excel la información de registros de contratos
   */
  exportExcel() {
    this.loading = true;

    this.reportesContratoCuentasCobroService.srvConsultarReporteCxpContratos(
      this.reporteCxpContratosFiltro.vigencia,
      this.reporteCxpContratosFiltro.fechaActaInicioRangoDesde,
      this.reporteCxpContratosFiltro.fechaActaInicioRangoHasta,
      this.reporteCxpContratosFiltro.fechaSuscripionRangoDesde,
      this.reporteCxpContratosFiltro.fechaSuscripionRangoHasta,
      this.reporteCxpContratosFiltro.fechaTerminacionRangoDesde,
      this.reporteCxpContratosFiltro.fechaTerminacionRangoHasta,
      null,//Para ser expotado en Excel se requieren todos los registros consultados, por eso se deja null
      null,//Para ser expotado en Excel se requieren todos los registros consultados, por eso se deja null
      this.reporteCxpContratosFiltro.buscar,
      this.reporteCxpContratosFiltro.campoOrden,
      this.reporteCxpContratosFiltro.ascDesc
    ).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            const reporteCxpContratosExcel = result.reporteCxpContratos;
            // check array does not exist, is not an array, or is empty
            if (!Array.isArray(reporteCxpContratosExcel) || !reporteCxpContratosExcel.length) {
              this.lanzarMensajeError("No hay registros suficientes para exportar en Excel");
              return;
            }
            //flatten objects
            const rows = reporteCxpContratosExcel.map(row => ({
              numeroContrato: row.numeroContrato,
              vigencia: row.vigencia,
              fechaSuscripcion: this.datepipe.transform(row.fechaSuscripcion, 'dd/MM/yyyy'),
              fechaActaInicio: this.datepipe.transform(row.fechaActaInicio, 'dd/MM/yyyy'),
              fechaActualTerminacionContrato: this.datepipe.transform(row.fechaActualTerminacionContrato, 'dd/MM/yyyy'),
              honorariosMensualesActuales: row.honorariosMensualesActuales,
              supervisoresActuales: row.supervisoresActuales,
              tipoDocumentoContratistaActual: row.tipoDocumentoContratistaActual,
              numeroDocumentoContratistaActual: row.numeroDocumentoContratistaActual,
              nombreContratistaActual: row.nombreContratistaActual,
              rps: row.rps,
              valorFinalContrato: row.valorFinalContrato,
              valorFinalAni: row.valorFinalAni,
              valorFinalFiducias: row.valorFinalFiducias,
            }));

            //generate worksheet and workbook
            import('xlsx')
              .then(xlsx => {
                const headings = [
                  [
                    "No. Contrato",
                    "Vigencia",
                    "Fecha de Suscripción",
                    "Fecha Acta de Inicio",
                    "Fecha Terminacion Actual",
                    "Honorarios Mensuales",
                    "Supervisor (es)",
                    "Tipo documento Contratista",
                    "No. Documento Contratista",
                    "Contratista",
                    "Número Rp(s)",
                    "Valor total del Contrato",
                    "Valor contrato actual ANI",
                    "Valor contrato actual Fiducias"
                  ]];
                const worksheet = xlsx.utils.json_to_sheet(rows);
                /* fix headers */
                xlsx.utils.sheet_add_aoa(worksheet, headings);
                const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                this.saveAsExcelFile(excelBuffer, 'ctoReporteCxpContratos');
              });

          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  /**
    * Metodo utilizado para descargar el archivo en formato xlsx generado
    * @param buffer 
    * @param fileName Nombre del archivo descargado
    */
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  /**
  * Metodo que redirige a la pagina principal de reportes de central de pagos
  */
  bntVolver() {
    const ruta = "/contratacionSupervisionHome/reportesCuentasCobroCxpHome";
    this.router.navigate([ruta]);
  }

  /**
  * Lanza mensaje de informacion
  */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
