import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificacion-home',
  templateUrl: './certificacion-home.component.html',
  styleUrls: ['./certificacion-home.component.sass']
})
export class CertificacionHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
