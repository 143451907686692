import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { forkJoin } from 'rxjs';
import { ComisionesService } from "../../comisiones.service";
import { VwCmsComision } from "../../comisiones.dto";
import { EnumCmsConstantes, EnumCmsMaTipoModificacionComision } from "../../comisiones-constantes";
import { EnumTrnMaTipoSoporte } from "src/app/comun/constantes/constantes-comunes";

@Component({
  selector: "app-comisiones-tiquetes-registro",
  templateUrl: "./comisiones-tiquetes-registro.component.html",
  styleUrls: ["./comisiones-tiquetes-registro.component.sass"],
})
export class ComisionesTiquetesRegistroComponent implements OnInit {
  solicitudList: VwCmsComision[];
  blockedDocument = true;

  constructor(
    private router: Router,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService,
    private comisionesService: ComisionesService,
  ) { }

  ngOnInit() {
    this.listarComisiones();
  }

  /**
   * Inicializa el listado de modelos registrados
   */
  listarComisiones() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarComisionesTiquetesSolicitados()
    ).subscribe(
      ([
        comisionesData
      ]) => {
        this.solicitudList = comisionesData.comisionesPersona;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irCargarTiquetes(solicitud: VwCmsComision) {
    this.router.navigate([this.router.url + "/crear-editar-tiquete-comision/" + solicitud.idComision]);
  }

  bntVolver() {
    const ruta = "/comisiones";
    this.router.navigate([ruta]);
  }

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }


  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }
}
