import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ParametricasService } from '../../../parametricas/parametricas.service';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { TrnPersona } from '../../trn-persona';
import { EnumRegex, EnumRespuestaServicio, EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { SgrUsuarioPerfil } from '../../sgr-usuario-perfil';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-persona-datos-basicos',
  templateUrl: './persona-datos-basicos.component.html',
  styles: []
})
export class PersonaDatosBasicosComponent implements OnInit {

  @Input()
  personaDatosBasicos: TrnPersona;
  @Input()
  modoAdmin: boolean;

  personaTipoDocumento: any;
  trnTiposDocumentoList: any[];
  departamentos: any[];
  personaDepartamento: any;
  ciudades: any[];
  personaMunicipio: any;
  resultadoGuardarDatosBasicos: any;
  visibleDialogoExito: boolean;
  blockedDocument = false;
  perfilesAsociados: SgrUsuarioPerfil[] = [];
  esFuncionario = false;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private parametricasService: ParametricasService,
    private personaService: PersonaService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.blockedDocument = true;
    this.cargarListasParametricas();
    this.personaDatosBasicos.correoElectronicoAni = this.personaDatosBasicos.correoElectronicoAni;

    if (this.trnTiposDocumentoList != null) {
      this.personaTipoDocumento = this.trnTiposDocumentoList.find(tipDoc => String(tipDoc.id) === String(this.personaDatosBasicos.idTipoDocumento));
    }

    if (this.personaDatosBasicos.idDepartamentoMunicipioDomicilio != null && this.personaDatosBasicos.departamentoMunicipioDomicilio != null) {
      this.personaDepartamento = {
        id: this.personaDatosBasicos.idDepartamentoMunicipioDomicilio,
        nombre: this.personaDatosBasicos.departamentoMunicipioDomicilio
      };
    }

    if (this.personaDatosBasicos.idMunicipioDomicilio != null && this.personaDatosBasicos.municipioDomicilio != null) {
      this.personaMunicipio = {
        id: this.personaDatosBasicos.idMunicipioDomicilio,
        nombre: this.personaDatosBasicos.municipioDomicilio
      };
    }

    //Obtener perfiles asociados
    this.consultarUsuarioPerfil(this.personaDatosBasicos.id);

  }

  cargarListasParametricas() {
    this.parametricasService.getTrnTipoDocumento().subscribe(
      result => {
        this.trnTiposDocumentoList = result;
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  consultarUsuarioPerfil(idPersona: number) {
    this.personaService.ConsultarUsuarioPerfil(idPersona).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.perfilesAsociados = result.usuarioPerfiles;
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );

  }


  guardarPersona(form: NgForm) {
    this.blockedDocument = true;
    if (this.personaMunicipio != null && this.personaMunicipio.id != null && this.personaMunicipio.id !== 0) {
      this.personaDatosBasicos.idMunicipioDomicilio = this.personaMunicipio.id;
    }
    this.personaService.GuardarPersonaDatosBasicos(this.personaDatosBasicos, this.modoAdmin).subscribe(
      result => {
        this.resultadoGuardarDatosBasicos = result;
        this.visibleDialogoExito = true;
        this.consultarUsuarioPerfil(this.personaDatosBasicos.id);
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  // Informativo - Dialogo
  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.router.navigate([this.router.url]);
  }

  cargarDepartamentos(event) {
    const filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe(
      (data: any) => {
        this.departamentos = data;
        if (this.departamentos != null) {
          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.departamentos.length; i++) {
              const filtro = this.departamentos[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroDepartamentos.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.departamentos = filtroDepartamentos;
            }
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }

  limpiarCiudades(event) {
    this.personaMunicipio = null;
  }

  cargarCiudades(event) {
    if (this.personaDepartamento != null) {
      const filtroCiudades = [];
      return this.parametricasService.getCiudadesPorDepartamento(this.personaDepartamento.id).subscribe((data: any) => {
        this.ciudades = data;
        if (this.ciudades != null) {
          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.ciudades.length; i++) {
              const filtro = this.ciudades[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroCiudades.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.ciudades = filtroCiudades;
            }
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
    }
  }

  get EnumRegex() {
    return EnumRegex;
  }

  get EnumTipoDocumento() {
    return EnumTipoDocumento;
  }
}
