import { Component, OnInit } from '@angular/core';
import { AgendamientoConsultasService } from './agendamiento-consultas.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../progress-spinner-dialog/progress-spinner-dialog.component';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-agendamiento-consultas',
  templateUrl: './agendamiento-consultas.component.html',
  styleUrls: ['./agendamiento-consultas.component.sass']
})


export class AgendamientoConsultasComponent implements OnInit {

  displayedColumns1;
  listaValidadores: any = [];
  proyectos: any = [];
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>
  fechaVisita;

  constructor(
    private info: AgendamientoConsultasService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
    ) { 
    }

  ngOnInit() {
    this.displayedColumns1 = [
      { field: 'diaVisitaMostrar', header: 'Fecha' },
      { field: 'horario', header: 'Hora' },    
      { field: 'identificacion', header: 'Tipo Documento' },
      { field: 'numeroIdentificacion', header: 'No. Identificación' },
      { field: 'nombreCompleto', header: 'Nombre Completo' },    
      { field: 'temaVisita', header: 'Tema Visita' }  

    ];
    //this.cargarDataValidador(2);
  }


  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  cargarDataValidador() {

    let dataSend = {
      diaVisita: this.fechaVisita
    }
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    
    return this.info.getAgendamientoList(dataSend).subscribe((data: {}) => {
      this.proyectos = data;
      if (this.proyectos.length === 0) {
        this.lanzarMensajeError('No existen citas programadas para este día.');
      }
      //this.dataSource = new MatTableDataSource(this.proyectos);
      //this.dataSource.paginator = this.paginator;
      this.listaValidadores = this.proyectos;
      this.dialogSpin.close();
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  limpiar() {
    this.fechaVisita = '';
    this.listaValidadores = [];
  }

  convertStringDateToTime(data) {
      const date = new Date(data);
      const localOffset = date.getTimezoneOffset() * 60000;
      const utc = date.getTime() + localOffset;
      return utc;
    }

  ///////////////////////////////Mensajes//////////////////////////////
    lanzarMensajeInfo(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
      
    }
  
    lanzarMensajeError(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });

    }
  
    lanzarMensajeWarning(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
    }
  
    limpiarMensajes() {
      this.messageService.clear();
    }

}






