const colorchart = {
    color1: '#313CBC',
    color2: '#00C0FF',
    color3: '#00BFC4',
    color4: '#FC7F25',
    color5: '#FFC438',
    color6: '#800CE5',
    color7: '#BB89F4',
    color8: '#D1D5FA',
  }
  export default colorchart