import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCtoTipoAnexoInforme } from 'src/app/contratacion/constantes/contratacion-constantes';
import { CtoAdjuntoInformeBorradorDTO } from 'src/app/contratacion/contratacion-dto/adjunto-informe-borrador';

import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumInvInventario } from 'src/app/inventario-equipos/inventario-constantes';
import { VwInvBienAniYpersonalDTO } from 'src/app/inventario-equipos/inventario-equipos.dto';
import { InventarioEquiposService } from 'src/app/inventario-equipos/inventario-equipos.service';
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";
import { RpteCxpBaseComponent } from '../rpte-cxp-base/rpte-cxp-base.component';
import { forkJoin } from 'rxjs';
import { CtoInformeRevisionAnexoDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-anexo';

@Component({
  selector: 'app-rpte-cxp-paz-salvo',
  templateUrl: './rpte-cxp-paz-salvo.component.html',
  styles: []
})
export class RpteCxpPazSalvoComponent implements OnInit {

  anexosInformeRevision: CtoInformeRevisionAnexoDTO[];
  adjuntosAnexoInforme: CtoAdjuntoInformeBorradorDTO[];
  visibleDialogoVerAdjuntos: boolean;
  anexoInforme014_081: CtoInformeRevisionAnexoDTO;

  //Bienes Asignados
  bienesAniPersonal: VwInvBienAniYpersonalDTO[];

  constructor(
    private lineaBase: RpteCxpBaseComponent,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private inventarioEquiposService: InventarioEquiposService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarAnexos(this.lineaBase.idInformeParam);
    this.cargarInventarioAsignadoPersona();
  }

  cargarAnexos(idInforme: number) {
    this.lineaBase.blockedDocument = true;
    this.reporteCuentaCobroCxpService.srvConsultarAnexosInforme(idInforme, EnumCtoTipoAnexoInforme.SUBTIPO_PAZ_SALVO, null)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.anexosInformeRevision = result.anexosInformeRevision;
              //Validar los anexos de Paz y Salvo
              this.validarAnexosPazySalvo();
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  irVerDialogoVerAdjuntosAnexos(idAnexo: number) {
    this.visibleDialogoVerAdjuntos = true;
    this.cargarAdjuntosPorAnexosAdicionales(idAnexo);
  }

  cargarAdjuntosPorAnexosAdicionales(idAnexo: number) {
    this.reporteCuentaCobroCxpService.srvConsultarAdjuntosPorAnexoInforme(idAnexo)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosAnexoInforme = result.adjuntos;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }


  validarAnexosPazySalvo() {
    if (this.anexosInformeRevision != null) {
      this.anexosInformeRevision.forEach(anx => {
        if (anx.idTipoAnexo == EnumCtoTipoAnexoInforme.GADF_F_014 || anx.idTipoAnexo == EnumCtoTipoAnexoInforme.GADF_F_081) {
          this.anexoInforme014_081 = anx;
        }
      });
    }
  }

  /**
   * Lista las asignaciones asociadas al contratista
   */
  cargarInventarioAsignadoPersona() {
    forkJoin(
      this.inventarioEquiposService.srvConsultarBienesAniYPersonalPorPersona(this.lineaBase.cuentaCobro.idPersona, EnumInvInventario.INV_BIEN_TIPO_PROCEDENCIA_ANI)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.bienesAniPersonal = result.bienesAniPersonal;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO.toString(), life: 10000 });
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      }
    );
  }

}