import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CtoContratoDTO } from '../../../../contratacion-dto/contrato';
import { ContratacionBorradorService } from '../../contratacion-brr.service';
import { RpUspCtoFinalizaContratoBorradorDTO } from '../../../../contratacion-dto/rp-usp-finaliza-contrato-borrador';
import { EnumFechaFormato, EnumRegex, EnumRespuestaServicio } from '../../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-contrato-base',
  templateUrl: './contrato-base.component.html'
})
export class ContratoBaseComponent implements OnInit {

  contratoBase: CtoContratoDTO;
  idContratoborradorGuardado: number;
  respuestaFinalizaContratoBorrador: RpUspCtoFinalizaContratoBorradorDTO;

  visibleTabContratoDetalle = false;
  visibleTabContratoPoliza = false;
  habilitaEliminarContratoBorrador: boolean;

  visibleDialogoExito = false;
  visibleDialogoConfirmarPublicarContrato = false;
  visiblePestanaValidacionPublicarContrato = false;
  visibleDialogoValidacionPublicarContrato = false;

  msgRecuerdeGuardarCambios = '***Recuerda guardar los cambios cada vez que agregues o modifiques la información del contrato en el botón "Guardar" que encuentras al final de las secciones***';
  msgRecuerdeTiempoEjecucionContrato = 'La fecha de terminación estimada se calcula automáticamente con la FechaInicio y los Plazos (años, meses y días)';
  msgRecuerdeReduccionTiempoEjecucionContratoFecha = 'Recuerde, esta opción se utiliza solo sí no se cumple que cada periodo sea de 30 días calendario o que se requiere que el contrato termine un 31 del mes por excepciones particulares, por lo tanto no es posible definirlo en cuestión de plazos (años, meses y días).';

  blockedDocument = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private contratacionBorradorService: ContratacionBorradorService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.blockedDocument = true;

    this.activatedRoute.params.subscribe(params => {
      if (params.idContrato != null && Number(params.idContrato) !== 0) {
        this.cargarContrato(params.idContrato, params.esBorrador);
      } else {
        this.contratoBase = {
          esBorrador: true,
          esTiempoEjecucionContratoEnFecha: false
        };
        console.log(this.contratoBase);
        this.blockedDocument = false;
      }
    });
  }

  /**
   * Metodo que carga la información del contrato cuando es un borrador
   * @param idContrato Id del contrato
   * @param esBorrador 1 sí es un contrato en borrador, 0 en caso contario
   */
  cargarContrato(idContrato: number, esBorrador: any) {
    if (esBorrador == 1) {
      this.contratacionBorradorService.srvGetContratoBorradorPorId(idContrato)
        .subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.contratoBase = result.contrato;

                if (this.contratoBase != null) {
                  this.contratoBase.esBorrador = true;
                  if (this.contratoBase.fechaSuscripcion != null) {
                    this.contratoBase.fechaSuscripcion = new Date(this.contratoBase.fechaSuscripcion);
                  }
                  if (this.contratoBase.fechaInicio != null) {
                    this.contratoBase.fechaInicio = new Date(this.contratoBase.fechaInicio);
                  }
                  if (this.contratoBase.fechaTerminacionContrato != null) {
                    this.contratoBase.fechaTerminacionContrato = new Date(this.contratoBase.fechaTerminacionContrato);
                    this.contratoBase.esTiempoEjecucionContratoEnFecha = true;
                  } else {
                    this.actualizarCalcularFechaTerminacionContratoCalculada();
                    this.contratoBase.esTiempoEjecucionContratoEnFecha = false;
                  }
                  if (this.contratoBase.fechaCdp != null) {
                    this.contratoBase.fechaCdp = new Date(this.contratoBase.fechaCdp);
                  }
                  if (this.contratoBase.fechaRp != null) {
                    this.contratoBase.fechaRp = new Date(this.contratoBase.fechaRp);
                  }
                  if (this.contratoBase.fechaExpedicionGarantia != null) {
                    this.contratoBase.fechaExpedicionGarantia = new Date(this.contratoBase.fechaExpedicionGarantia);
                  }
                }
              }
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.blockedDocument = false;
          }
        );
    }
  }

  calcularFechaTerminacionContratoBorradorPorPlazosFechaInicio() {
    if (!this.contratoBase.esTiempoEjecucionContratoEnFecha) {
      this.contratacionBorradorService.CalcularFechaTerminacionContratoBorradorPorPlazosFechaInicio(this.contratoBase).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.contratoBase.tiempoEjecucionFechaTerminacionCalculada = new Date(result.tiempoEjecucionFechaTerminacionCalculada);
              this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            } else if (result.respuestaServicio.codigoSalida === 2) {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error('Ocurrió un error al consumir el servicio');
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }

  actualizarCalcularFechaTerminacionContratoCalculada() {
    if (this.contratoBase.fechaTerminacionContrato == null
      && this.contratoBase.fechaInicio != null && this.contratoBase.plazoAnos != null && this.contratoBase.plazoMeses != null && this.contratoBase.plazoDias != null) {
      this.calcularFechaTerminacionContratoBorradorPorPlazosFechaInicio();
    }
  }


  validarInformacionContrato(): boolean {
    let contratoExitoso = true;
    if (this.contratoBase.fechaSuscripcion != null && this.contratoBase.fechaInicio != null) {
      if (this.contratoBase.fechaInicio < this.contratoBase.fechaSuscripcion) {
        contratoExitoso = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha del acta de inicio debe ser mayor o igual a la fecha de suscripción', life: 10000 });
      }
    }

    if (this.contratoBase.esTiempoEjecucionContratoEnFecha) {
      if (this.contratoBase.fechaTerminacionContrato < this.contratoBase.fechaInicio) {
        contratoExitoso = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha de terminación del contrato debe ser mayor o igual a la fecha del acta inicio', life: 10000 });
      }
    }

    if (this.contratoBase.fechaRp != null && this.contratoBase.fechaCdp != null) {
      if (this.contratoBase.fechaRp < this.contratoBase.fechaCdp) {
        contratoExitoso = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha del RP debe ser mayor o igual a la fecha de CDP', life: 10000 });
      }
    }

    return contratoExitoso;
  }

  /**
   * Metodo que valida y guarda la información del contrato al dar clic en el botón guardar
   * @param contrato Objeto de tipo CtoContratoDTO
   */
  guardarContrato(contrato) {
    if (this.validarInformacionContrato()) {
      this.blockedDocument = true;
      if (contrato.idTipoContratoObject != null) {
        contrato.idTipoContrato = contrato.idTipoContratoObject.id;
      }
      if (contrato.idPersonaOrdenadorObject != null) {
        contrato.idPersonaOrdenador = contrato.idPersonaOrdenadorObject.idPersona;
      }
      if (contrato.idDependenciaRecursosObject != null) {
        contrato.idDependenciaRecursos = contrato.idDependenciaRecursosObject.id;
      }
      if (contrato.idTipoGarantiaObject != null) {
        contrato.idTipoGarantia = contrato.idTipoGarantiaObject.id;
      }
      if (contrato.idAmparosGarantiaObject != null) {
        contrato.idAmparosGarantia = contrato.idAmparosGarantiaObject.id;
      }
      if (contrato.idTipoRecursoObject != null) {
        contrato.idTipoRecurso = contrato.idTipoRecursoObject.id;
      }
      if (contrato.idImputacionRecursoObject != null) {
        contrato.idImputacionRecurso = contrato.idImputacionRecursoObject.id;
      }
      if (contrato.idPersonaSupervisorObject != null) {
        contrato.idPersonaSupervisor = contrato.idPersonaSupervisorObject.id;
      }
      if (contrato.idRegimenContratacionObject != null) {
        contrato.idRegimenContratacion = contrato.idRegimenContratacionObject.id;
      }
      if (contrato.idModalidadSeleccionObject != null) {
        contrato.idModalidadSeleccion = contrato.idModalidadSeleccionObject.id;
      }
      if (contrato.idAseguradoraObject != null) {
        contrato.idAseguradora = contrato.idAseguradoraObject.id;
      }

      if (contrato.esBorrador) {
        contrato.esBorrador = 1;
      }
      // Guardar los cambios en BD
      this.contratacionBorradorService.srvGuardarContratoBorrador(contrato)
        .subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.visibleDialogoExito = true;
                this.idContratoborradorGuardado = result.idContrato;
                if (contrato.esBorrador === 1) {
                  contrato.esBorrador = true;
                }
              } else {
                this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              }
            } else {
              console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
              this.blockedDocument = false;
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.blockedDocument = false;
          }
        );
    }
  }

  guardarContratoDefinitivo() {
    // Validar variables
    this.blockedDocument = true;
    this.visibleDialogoConfirmarPublicarContrato = false;

    if (this.contratoBase.esBorrador) {
      // se guarda como definitivo el contrato borrador
      this.contratacionBorradorService.srvGuardarContratoDefinitivo(this.contratoBase.id)
        .subscribe(
          result => {
            if (result != null) {
              this.respuestaFinalizaContratoBorrador = result;
              if (this.respuestaFinalizaContratoBorrador.codigoSalida == EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.visibleDialogoConfirmarPublicarContrato = false;
                this.router.navigate(['/contratosHome/contratos']);
              } else {
                this.visibleDialogoConfirmarPublicarContrato = false;
                this.visiblePestanaValidacionPublicarContrato = true;
                this.visibleDialogoValidacionPublicarContrato = true;
              }
            }
            this.blockedDocument = false;
          },
          error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.blockedDocument = false;
          }
        );
    }
  }


  irContratosBorrador() {
    this.router.navigate(['/contratosHome/contratos']);
  }

  // Informativo - Dialogo
  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    if (this.idContratoborradorGuardado == null) {
      // this.ngOnInit();
    } else {
      this.cargarContrato(this.idContratoborradorGuardado, 1);
    }
  }



  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.messageService.add({ severity: "error", summary: "Error", detail: mensaje, life: 10000, });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }




  get EnumFechaFormato() {
    return EnumFechaFormato;
  }



}
