import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CtoInformeContratistaObservacionRpt } from '../contratacion-dto/informe-contratista-observacion';
import { TrnRespuestaServicioRpt } from '../../parametricas/rpt-respuesta-operacion';
import { CtoAdjuntoInformeBorradorDTO, CtoAdjuntoInformeBorradorRpt } from '../contratacion-dto/adjunto-informe-borrador';
import { CtoSolicitudPagoAprobadaSupervisorRpt } from '../contratacion-dto/solicitud-pago-aprobadas-supervisor';
import { CtoInformeContratistaRevisionRpt } from '../contratacion-dto/informe-revision';
import { CtoInformeRevisionActividadesRpt } from '../contratacion-dto/informe-revision-actividad';
import { CtoInformeRevisionAnexosRpt } from '../contratacion-dto/informe-revision-anexo';
import { CtoInformeRevisionAsignacionDTO, CtoInformeRevisionAsignacionRpt } from '../contratacion-dto/informe-revision-asignacion';
import { CtoInformeRevisionEstadoPersonaAprobacionRpt } from '../contratacion-dto/informe-revision-aprobacion';
import { CtoInformeRevisionObligacionPagoRpt } from '../contratacion-dto/informe-revision-obligacion-pago';


@Injectable({
  providedIn: 'root'
})
export class InformeRevisionService {

  public END_POINT = '';
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  ConsultarInformesContratistasAsignadosVoBo(): Observable<CtoInformeContratistaRevisionRpt> {
    return this.http.get<CtoInformeContratistaRevisionRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarInformesContratistasAsignadosVoBo')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarInformesContratistasAsignadosSupervisor(): Observable<CtoInformeContratistaRevisionRpt> {
    return this.http.get<CtoInformeContratistaRevisionRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarInformesContratistasAsignadosSupervisor')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarInformesContratistasAsignadosCxP(): Observable<CtoInformeContratistaRevisionRpt> {
    return this.http.get<CtoInformeContratistaRevisionRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarInformesContratistasAsignadosCxP')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarInformesContratistasAsignadosSpa(): Observable<CtoInformeContratistaRevisionRpt> {
    return this.http.get<CtoInformeContratistaRevisionRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarInformesContratistasAsignadosSpa')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  ConsultarActividadesInformeRevision(idInforme: number, idPersonaAsignacion: number, idPaso: number): Observable<CtoInformeRevisionActividadesRpt> {
    return this.http.get<CtoInformeRevisionActividadesRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarActividadesInformeRevision/' + idInforme + '/' + idPersonaAsignacion + '/' + idPaso)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarAdjuntosPorActividadInformeRevision(idActividad: number, idPersonaAsignacion: number): Observable<CtoAdjuntoInformeBorradorRpt> {
    return this.http.get<CtoAdjuntoInformeBorradorRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarAdjuntosPorActividadInformeRevision/' + idActividad + '/' + idPersonaAsignacion)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarPlanillasInformeRevision(idInforme: number, idPersonaAsignacion: number): Observable<CtoAdjuntoInformeBorradorRpt> {
    return this.http.get<CtoAdjuntoInformeBorradorRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarPlanillasInformeRevision/' + idInforme + '/' + idPersonaAsignacion)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  /**
    * 
    * @param idsSubTipoAnexos EnumCtoTipoAnexoInforme.SUBTIPO, pueden ir separados por coma
    * @param idInforme Id del informe
    * @param idPersonaAsignacion Id de la persona asignada para la revisión
    * @param idPaso Id del paso del informe
    * @returns Objeto de Respuesta CtoInformeRevisionAnexosRpt
    */
  ConsultarAnexosPorSubTipoInformeRevision(idsSubTipoAnexos: string, idInforme: number, idPersonaAsignacion: number, idPaso: number): Observable<CtoInformeRevisionAnexosRpt> {
    return this.http.get<CtoInformeRevisionAnexosRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarAnexosPorSubTipoInformeRevision/' + idsSubTipoAnexos + '/' + idInforme + '/' + idPersonaAsignacion + '/' + idPaso)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  ConsultarAnexosTributariosInformeRevision(idInforme: number, idPersonaAsignacion: number, idPaso: number): Observable<CtoInformeRevisionAnexosRpt> {
    return this.http.get<CtoInformeRevisionAnexosRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarAnexosTributariosInformeRevision/' + idInforme + '/' + idPersonaAsignacion + '/' + idPaso)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarAnexosInformeRevision(idInforme: number, idPersonaAsignacion: number, idPaso: number): Observable<CtoInformeRevisionAnexosRpt> {
    return this.http.get<CtoInformeRevisionAnexosRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarAnexosInformeRevision/' + idInforme + '/' + idPersonaAsignacion + '/' + idPaso)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarAdjuntosPorAnexoInformeRevision(id: number, idPersonaAsignacion: number): Observable<CtoAdjuntoInformeBorradorRpt> {
    return this.http.get<CtoAdjuntoInformeBorradorRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarAdjuntosPorAnexoInformeRevision/' + id + '/' + idPersonaAsignacion)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarAsignacionInformeRevision(idAsignacion: number, idPasoInforme: number, idPersonaAsignacion: number): Observable<CtoInformeRevisionAsignacionRpt> {
    return this.http.get<CtoInformeRevisionAsignacionRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarAsignacionInformeRevision/' + idAsignacion + '/' + idPasoInforme + '/' + idPersonaAsignacion)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  GuardarAsignacionObservacionInformeRevision(asignacion: CtoInformeRevisionAsignacionDTO): Observable<CtoInformeRevisionAsignacionRpt> {
    return this.http.post<CtoInformeRevisionAsignacionRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/GuardarAsignacionObservacionInformeRevision', asignacion)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarObservacionesInformeRevision(idInforme: number, idVersionInforme: number, idPaso: number): Observable<CtoInformeContratistaObservacionRpt> {
    return this.http.get<CtoInformeContratistaObservacionRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarObservacionesInformeRevision/' + idInforme + '/' + idVersionInforme + '/' + idPaso)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  AprobarRechazarInformeRevision(idInforme: number, idPaso: number, idVersionInforme: number, asignacion: CtoInformeRevisionAsignacionDTO) {
    return this.http.post<CtoInformeRevisionAsignacionRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/AprobarRechazarInformeRevision/' + idInforme + '/' + idPaso + '/' + idVersionInforme, asignacion)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarPersonaEstadoInformeRevision(idInforme: number, idVersionInforme: number, idPaso: number): Observable<CtoInformeRevisionEstadoPersonaAprobacionRpt> {
    return this.http.get<CtoInformeRevisionEstadoPersonaAprobacionRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarPersonaEstadoInformeRevision/' + idInforme + '/' + idVersionInforme + '/' + idPaso)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  GuardarAsignacionFechaInicialRevisionInformeRevision(idAsignacion: number, idPersonaAsignacion?: number): Observable<CtoInformeRevisionAsignacionRpt> {
    let url = this.END_POINT + '/CtoInformeContratistaSupervision/GuardarAsignacionFechaInicialRevisionInformeRevision?idAsignacion=' + idAsignacion;
    if (idPersonaAsignacion != null) {
      url = url + '&idPersonaAsignacionParam=' + idPersonaAsignacion;
    }

    return this.http.post<CtoInformeRevisionAsignacionRpt>(url, null)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  AprobarRechazarInformeRevisionCentralPagos(idPlanPagoRechazo: number, rechazoSolicitud: boolean, observacionRechazoSolicitudPago: string) {
    const aprobacion = {
      idPlanPago: idPlanPagoRechazo,
      aprobo: !rechazoSolicitud,
      Observacion: observacionRechazoSolicitudPago
    };
    return this.http.post<TrnRespuestaServicioRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/AprobarRechazarInformeRevisionCentralPagos', aprobacion)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  GuardarCertificadoPago(idInforme: number, anexoInforme: CtoAdjuntoInformeBorradorDTO[]): Observable<CtoAdjuntoInformeBorradorRpt> {
    return this.http.post<CtoAdjuntoInformeBorradorRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/GuardarCertificadoPago/' + idInforme, anexoInforme)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarCertificadoPago(idInforme: number): Observable<CtoAdjuntoInformeBorradorRpt> {
    return this.http.get<CtoAdjuntoInformeBorradorRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarCertificadoPago/' + idInforme)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarObligacionPago(idInforme: number, idPlanPagoPeriodo: number): Observable<CtoInformeRevisionObligacionPagoRpt> {
    return this.http.get<CtoInformeRevisionObligacionPagoRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarObligacionPago/' + idInforme + '/' + idPlanPagoPeriodo)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  GuardarObligacionPago(numeroObligacionPago: string, idPlanPagoPeriodo: number, idInforme: number, anexoInforme: CtoAdjuntoInformeBorradorDTO[]): Observable<CtoInformeRevisionObligacionPagoRpt> {
    return this.http.post<CtoInformeRevisionObligacionPagoRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/GuardarObligacionPago/' + numeroObligacionPago + '/' + idPlanPagoPeriodo + '/' + idInforme, anexoInforme)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  EliminarAdjuntoInforme(idAdjunto: number): Observable<CtoInformeRevisionObligacionPagoRpt> {
    const url = this.END_POINT + '/CtoInformeContratistaSupervision/EliminarAdjuntoInforme/' + idAdjunto;
    return this.http.delete(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarSolicitudPagoAprobadasFirmadosSupervisor(): Observable<CtoSolicitudPagoAprobadaSupervisorRpt> {
    return this.http.get<CtoSolicitudPagoAprobadaSupervisorRpt>(this.END_POINT + '/CtoInformeContratistaSupervision/ConsultarSolicitudPagoAprobadasFirmadosSupervisor')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }



}
