import { Component, OnInit } from '@angular/core';
import { ContratoCreditoPuenteBaseComponent } from '../contrato-credito-puente-base/contrato-credito-puente-base.component';
import { ConcesionarioCarreteroService } from 'src/app/concesionario-carretero/concesionario-carretero.service';
import { ContratoCreditoPuenteService } from './contrato-credito-puente.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ContratoCreditoPuente } from './contratoCreditoPuente';
import { Contrato } from 'src/app/concesionario-carretero/contrato';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-contrato-credito-puente',
  templateUrl: './contrato-credito-puente.component.html',
  styleUrls: ['./contrato-credito-puente.component.sass']
})
export class ContratoCreditoPuenteComponent implements OnInit {

  contratoCreditoPuente: ContratoCreditoPuente = {};
  visibleDialogoExito: boolean = false;

  visibleConfirmarEliminarContratoCreditoPuente: boolean = false;
  visibleDialogoExitoEliminarContratoCreditoPuente: boolean = false;

  contrato: Contrato = {};

  idProyecto: number;

  constructor(public lineaBase: ContratoCreditoPuenteBaseComponent, private concesionarioService: ConcesionarioCarreteroService,
    private contratoCreditoPuenteService: ContratoCreditoPuenteService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarContratoCreditoPuente(this.idProyecto);
      this.cargarConcesionario(this.idProyecto);
    });
  }

  cargarContratoCreditoPuente(id) {
    this.contratoCreditoPuenteService.getContratoCreditoPuentePorProyecto(id).subscribe((data: {}) => {
      this.lineaBase.contratoCreditoPuente = data;
      if (this.lineaBase.contratoCreditoPuente == null) {
        this.lineaBase.idContratoCredito = "new";
        this.lineaBase.contratoCreditoPuente = {};
        this.lineaBase.tieneContrato = "No";
        this.lineaBase.habilitaEliminarContratoCreditoPuente = false;
        this.lineaBase.habilitaIndexacionContrato = false;
      } else {
        this.lineaBase.idContratoCredito = this.lineaBase.contratoCreditoPuente.id;
        this.lineaBase.tieneContrato = "Si";
        this.lineaBase.habilitaEliminarContratoCreditoPuente = true;
        this.lineaBase.habilitaIndexacionContrato = true;
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarConcesionario(id) {
    this.concesionarioService.getContratoConcesionarioPorIdProyecto(id, 1).subscribe((data: {}) => {
      this.contrato = data;
      if (this.contrato != null) {
        this.lineaBase.idContratoConcesionario = this.contrato.id;
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  guardarContratoCreditoPuente(forma: NgForm) {
    if (this.lineaBase.contratoCreditoPuente.valorPesos == 0 && this.lineaBase.contratoCreditoPuente.valorDolares == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Al menos un valor debe ser diferente de 0.', life: 10000 });
    } else {
      if (this.lineaBase.idContratoCredito === 'new') {
        this.lineaBase.contratoCreditoPuente.idContrato = this.lineaBase.idContratoConcesionario;
        let vara: any = this.contratoCreditoPuenteService.guardarNuevoContratoCreditoPuente(this.lineaBase.contratoCreditoPuente).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
          let contratoNuevo: ContratoCreditoPuente = data;
          this.lineaBase.idContratoCredito = contratoNuevo.id;
          this.lineaBase.contratoCreditoPuente = contratoNuevo;
          this.lineaBase.tieneContrato = "Si";
          this.lineaBase.habilitaEliminarContratoCreditoPuente = true;
          this.lineaBase.habilitaIndexacionContrato = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        let vara: any = this.contratoCreditoPuenteService.editarContratoCreditoPuente(this.lineaBase.contratoCreditoPuente, this.lineaBase.idContratoCredito).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      }
    }
  }

  cargarNuevo() {
    this.lineaBase.contratoCreditoPuente = {};
    this.lineaBase.idContratoCredito = "new";
    this.lineaBase.tieneContrato = "No";
    this.lineaBase.habilitaEliminarContratoCreditoPuente = false;
    this.lineaBase.habilitaIndexacionContrato = false;
  }

  irEliminarContratoCredito() {
    this.visibleConfirmarEliminarContratoCreditoPuente = true;
  }

  cancelarEliminarContratoCredito() {
    this.visibleConfirmarEliminarContratoCreditoPuente = false;
  }

  aceptarEliminarContratoCredito() {
    return this.contratoCreditoPuenteService.eliminarContratoCreditoPuente(this.lineaBase.contratoCreditoPuente.id).subscribe((data: {}) => {
      this.visibleConfirmarEliminarContratoCreditoPuente = false;
      this.visibleDialogoExitoEliminarContratoCreditoPuente = true;
      this.cargarNuevo();
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarContratoCredito() {
    this.visibleDialogoExitoEliminarContratoCreditoPuente = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
  }

}
