export enum EnumCtoOrigenRecurso {
    ANI = 1,
    PATRIMONIO_AUTONOMO = 2,
    MIXTO = 3
}

export enum EnumCtoPasoInforme {
    EN_CORRECCIONES = 1,
    REVISION_VISTO_BUENO = 2,
    REVISION_SUPERVISOR = 3,
    REVISION_CENTRAL_PAGOS = 4,
    REVISION_CENTRAL_PAGOS_DEVOLUCION = 5,
    APROBACION_PAGO_TESORERIA = 6,
    PAGADO = 9,
    ANULADO_CORRECIONES = 97,
    ANULADO = 98,
    RECHAZADO = 99
}

export enum EnumCtoTipoAnexoInforme {
    SUBTIPO_BENEFICIO_TRIBUTARIO = 'BT',
    SUBTIPO_ANEXOS_ADICIONALES = 'AC',
    SUBTIPO_CUENTAS_REGISTRO = 'CR',
    SUBTIPO_PAZ_SALVO = 'PS',
    CERTIFICACION_AFC = 1,
    CERTIFICACION_CREDITO_HITPOTECARIO = 2,
    CERTIFICADO_DEPENDIENTES = 3,
    CERTIFICADO_PENSIONES_VOLUNTARIAS = 4,
    CERTIFICADO_MEDICINA_PREPAGADA = 5,
    INFORME_ADICIONAL = 6,
    PRODUCTO = 7,
    INFORME_FINAL_SUPERVISION = 8,
    CERTIFICADO_PARAFISCALES = 9,
    RUT = 10,
    ENTRADA_ALMACEN = 11,
    CERTIFICADO_PAGO_ANI = 12,
    CERTIFICADO_AFILIACION_ARL = 13,
    ACTA_INICIO = 14,
    MODIFICACION_CONTRACTUAL = 15,
    CERTIFICADO_OBLIGACION_PAGO_ANI = 16,
    GADF_F_014 = 17,
    GADF_F_081 = 18,
    GADF_F_014_NOMBRE = "GADF_F_014",
    GADF_F_081_NOMBRE = "GADF_F_081"
}

export enum EnumCtoEstadoContrato {
    VIGENTE = 'VIGENTE',
    EN_EJECUCION = 'EN EJECUCIÓN',
    FINALIZADO = 'FINALIZADO',
    TERMINADO_ANTICIPADAMENTE = 'TERMINADO ANTICIPADAMENTE'
}

export enum EnumCtoTipoNovedadContrato {
    CTO_TIPO_NOVEDAD_ADICION_PRORROGA = 1,
    CTO_TIPO_NOVEDAD_ADICION = 2,
    CTO_TIPO_NOVEDAD_PRORROGA = 3,
    CTO_TIPO_NOVEDAD_SUSPENSION = 4,
    CTO_TIPO_NOVEDAD_REANUDACION_SUSPENSION = 5,
    CTO_TIPO_NOVEDAD_PRORROGA_SUSPENSION = 6,
    CTO_TIPO_NOVEDAD_TERMINACION_ANTICIPADA = 7,
    CTO_TIPO_NOVEDAD_CESION = 8,
    CTO_TIPO_NOVEDAD_LIBERACION_RECURSOS = 9,
    CTO_TIPO_NOVEDAD_REDUCCION_PLAZO = 10,
    CTO_TIPO_NOVEDAD_CAMBIO_HONORARIOS_CONDICIONES_TRIBUTARIAS = 11,
    CTO_TIPO_NOVEDAD_OTROSI = 21
}