import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/config.service';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { LongitudConcesionadaProyecto } from './longitudConcesionadaProyecto'



@Injectable({
    providedIn: 'root'
})
export class LogintudConcesionadaProyectoService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    

    public listarLongitudConcesionadaProyectoPorId (idProyecto,  esBorrador, idConfiguracion): Observable<ConsultaLongitudConcesionadaProyectoRpt>{
        return this.http.get<ConsultaLongitudConcesionadaProyectoRpt>(this.END_POINT + '/CrrLongitudConcesionada/obtenerLongitudConcesionada/' + idProyecto+'/'+esBorrador+'/'+idConfiguracion)
        .pipe(
            map(data => {
                return data;
            }),
            retry(1));
    }

    public guardarLongitudConcesionada(longitudConcesionadaDTO: LongitudConcesionadaProyecto, esBorrador,idProyecto,idConfiguracion): Observable<ConsultaLongitudConcesionadaProyectoRpt> {
        return this.http.post<ConsultaLongitudConcesionadaProyectoRpt>(this.END_POINT + '/CrrLongitudConcesionada/guardarProyecto/'+ esBorrador+'/'+idProyecto+'/'+idConfiguracion, longitudConcesionadaDTO)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    

    public getProyectoPorId(id) {
        return this.http.get(this.END_POINT + '/TrnProyecto/' + id)
            .pipe(
                retry(1));
    }

    

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }

    

}

export class ConsultaLongitudConcesionadaProyectoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public longitudConcesionada?: LongitudConcesionadaProyecto[]
    ) { }
}
