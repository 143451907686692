import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-sig',
  templateUrl: './sig.component.html',
  styleUrls: ['./sig.component.sass']
})
export class SigComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
    element.className = "ani public logeo sig";
  }

}
