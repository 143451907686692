
import { forkJoin } from 'rxjs';
import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { Chart } from "angular-highcharts";
import { ChartModule } from "angular-highcharts";
import * as Highcharts from "highcharts";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import {
    CrrPeajesRecaudoDetalleDTO,
    CrrPeajesRecaudoEncDTO,
} from "src/app/reportes/carreteros/reportes-peajes.dto";
import { ReportesPeajesService } from "src/app/reportes/carreteros/reportes-peajes.service";
import { EnumPjeConstantes } from 'src/app/peajes-gestion/peajes-constantes';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PjeDetallePeriodoDTO } from 'src/app/peajes-gestion/recaudo-peajes/PjeDetallePeriodo';

@Component({
    selector: 'app-rpt-crr-peajes-trafico-recaudo-detalle',
    templateUrl: './rpt-crr-peajes-trafico-recaudo-detalle.component.html',
    styleUrls: ['./rpt-crr-peajes-trafico-recaudo-detalle.component.sass']
})
export class RptCrrPeajesTraficoRecaudoDetalleComponent implements OnInit {
    blockedDocument = false;
    idEncabezado: number = 0;
    detallePeriodoPeaje: CrrPeajesRecaudoDetalleDTO[] = [];
    periodosPeajes: CrrPeajesRecaudoEncDTO[] = [];
    encabezado: CrrPeajesRecaudoEncDTO;
    detallePeriodoSinRecaudoPeaje: PjeDetallePeriodoDTO[] = [];

    TPDConExentos = 0;
    TPDSinExentos = 0;


    constructor(
        private messageService: MessageService,
        private principalComponent: PrincipalComponent,
        private reportesPeajesService: ReportesPeajesService,
        private ruta: ActivatedRoute
    ) { }

    ngOnInit() {

        this.ruta.params.subscribe((params) => {
            this.idEncabezado = params.idEncabezado;
        });

        this.encabezado = new CrrPeajesRecaudoEncDTO;
        // Bloquear pantalla
        this.blockedDocument = true;
        forkJoin(
            this.reportesPeajesService.cargaEncRecaudoPeriodoPorId(this.idEncabezado),
            this.reportesPeajesService.cargaDetallePeriodo(this.idEncabezado),
            this.reportesPeajesService.cargaDetallePeriodoSinRecaudo(this.idEncabezado)
        ).subscribe(([resultEncabezado, resultDetalleRecaudo, resultDetalleSinRecaudo]) => {

            // Encabezado
            if (resultEncabezado != null && resultEncabezado.respuestaServicio != null) {
                if (resultEncabezado.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.encabezado = resultEncabezado.encabezadoPeriodo[0];
                    if (this.encabezado != null) {
                        if (this.encabezado.tpdsinExentos != null) {
                            this.TPDSinExentos = this.encabezado.tpdsinExentos;
                        }
                        if (this.encabezado.tpdconExentos != null) {
                            this.TPDConExentos = this.encabezado.tpdconExentos;
                        }
                    }
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: resultEncabezado.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }

            // DetalleRecaudo
            if (resultDetalleRecaudo != null && resultDetalleRecaudo.respuestaServicio != null) {
                if (resultDetalleRecaudo.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.detallePeriodoPeaje = resultDetalleRecaudo.detallePeriodo;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: resultDetalleRecaudo.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }

            // DetalleSinRecaudo
            if (resultDetalleSinRecaudo != null && resultDetalleSinRecaudo.respuestaServicio != null) {
                if (resultDetalleSinRecaudo.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                    this.detallePeriodoSinRecaudoPeaje = resultDetalleSinRecaudo.detallePeriodo;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: resultDetalleSinRecaudo.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.principalComponent.cargarErrorServicio(error);
            },
            () => {
                this.blockedDocument = false;
            }
        );
    }

    get EnumPjeConstantes() {
        return EnumPjeConstantes;
    }

    atras() {
        window.history.back();
    }
}
