import { ProyectoCarretero } from '../informacion-general/proyecto-carretero';
import { CodigoUnidadFuncional } from './codigo-unidad-funcional';

export class UnidadFuncional {
    constructor(
        public idUnidadFuncional: number,
        public IdProyecto: number,
        public Nombre: string,
        public OrigenX: number,
        public OrigenY: number,
        public Codigo?: number,
        public DestinoX?: number,
        public DestinoY?: number,
        public LongitudMetros?: number,
        public IdUnidadFuncionalPadre?: number,
        public NombreUnidadFuncionalPadre?: string,
        public Activo?: boolean,
        //public string TipoIntervencion : string ,
        public FechaInicioConstruccion?: Date,
        public FechaFinConstruccion?: Date,
        public FechaInicio?: Date,
        public FechaFin?: Date,
        public AudUsuarioCreacion?: string,
        public AudFechaCreacion?: string,
        public AudUsuarioEdicion?: string,
        public AudFechaEdicion?: Date,

        public IdProyectoNavigation?: ProyectoCarretero,
        public idUnidadFuncionalPadreNavigation?: UnidadFuncional,
        public CodigoNavigation?: CodigoUnidadFuncional
    ) { }
}