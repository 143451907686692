import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, LazyLoadEvent, TreeNode } from 'primeng/api';
import { EnumCmsConstantes, EnumCmsMaTipoModificacionComision } from 'src/app/comisiones/comisiones-constantes';
import { CmsReporteComisionesDetalleDTO } from 'src/app/comisiones/comisiones.dto';
import { ComisionesService } from 'src/app/comisiones/comisiones.service';
import { EnumRespuestaServicio, EnumRegex } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';

@Component({
  selector: 'app-comisiones-reporte-detalle-versiones',
  templateUrl: './comisiones-reporte-detalle-versiones.component.html',
  styleUrls: ['./comisiones-reporte-detalle-versiones.component.sass']
})
export class ComisionesReporteDetalleVersionesComponent implements OnInit {

  // Informacion del datatable
  reporteComisiones: CmsReporteComisionesDetalleDTO[] = [];
  reporteExcelComisiones: CmsReporteComisionesDetalleDTO[] = [];
  totalRecords: number;
  loading: boolean;


  // Filtros
  idComisionFiltro?: number;
  nombreComisionistaFiltro?: string;
  numeroDocumentoComisionistaFiltro?: string;
  fechaInicioPasoFiltro?: Date;
  fechaFinPasoFiltro?: Date;
  idPersonaRevisaInformeFiltro?: number;
  buscarFiltro: string;
  pasoComisionFiltro?: any;

  pasosComisionList: any[] = [];

  comisionesTreeNode: TreeNode[];
  constructor(
    private router: Router,
    private messageService: MessageService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService
  ) { }

  ngOnInit() {
    this.cargarPasosComisiones();
  }

  loadDataLazy(event: LazyLoadEvent) {
    this.loading = true;
    this.reporteComisiones = [];
    this.consultarReporteComisiones(event.first, event.rows, event.sortField, event.sortOrder);
  }

  buscar() {
    const filaDesde = 0;
    const cantidadRegistros = 10;
    this.loading = true;
    this.consultarReporteComisiones(filaDesde, cantidadRegistros, "FechaSalida", 2);
  }

  private consultarReporteComisiones(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.comisionesService.srvConsultarReporteComisionesDetalleVersion(
      this.idComisionFiltro, this.pasoComisionFiltro != null ? this.pasoComisionFiltro.id : null, this.nombreComisionistaFiltro,
      null, null,
      null, null,
      filaDesde, cantidadRegistros, this.buscarFiltro, campoOrden, ascDesc).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

              this.comisionesTreeNode = result.reporteComisionesDetalladoTreeNodeList;

              this.totalRecords = result.reporteComisionesDetalladoTreeNodeList != null && result.reporteComisionesDetalladoTreeNodeList[0] != null ? result.reporteComisionesDetalladoTreeNodeList[0].data.totalRegistros : 0;

            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
          this.loading = false;
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.loading = false;
        }
      );
  }

  limpiarFiltros() {
    this.idComisionFiltro = null;
    this.numeroDocumentoComisionistaFiltro = null;
    this.fechaInicioPasoFiltro = null;
    this.fechaFinPasoFiltro = null;
    this.buscarFiltro = null;
    this.pasoComisionFiltro = null;
    this.buscar();
  }


  cargarPasosComisiones() {
    this.comisionesService.srvListarEstadosComision().subscribe(
      result => {
        if (result != null) {
          this.pasosComisionList = result.estados;
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO, error);
        this.principalComponent.cargarErrorServicio(error);
        this.loading = false;
      },
      () => {
      }
    );
  }

  irConsultarComision(comision: CmsReporteComisionesDetalleDTO) {
    this.router.navigate([this.router.url + '/comisiones-reporte-detallado-registro-versiones/' + comision.id + '/' + comision.idComisionVersion]);
  }

  autocompletarPasoComision(event) {
    const filtroCtoPasoComision = [];
    let existeFiltro: boolean;
    for (const paso of this.pasosComisionList) {
      if (String(paso.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroCtoPasoComision.push(paso);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.pasosComisionList = filtroCtoPasoComision;
    }
    return this.pasosComisionList;
  }

  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

  get EnumRegex() {
    return EnumRegex;
  }

}
