import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { InformeRevisionService } from '../../informe-revision.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { CtoInformeContratistaRevisionDTO } from 'src/app/contratacion/contratacion-dto/informe-revision';

@Component({
  selector: 'app-informes-supervisor',
  templateUrl: './informes-supervisor.component.html',
  styles: []
})
export class InformesSupervisorComponent implements OnInit {

  colsInformes: any[];
  informesContratistasSupervisor: CtoInformeContratistaRevisionDTO[];
  totalPaginator: any;
  blockedDocument = false;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private infCtaSupervisionService: InformeRevisionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarInformes();
  }

  cargarInformes() {
    this.blockedDocument = true;
    this.infCtaSupervisionService.ConsultarInformesContratistasAsignadosSupervisor()
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.informesContratistasSupervisor = result.informesContratistaRevision;
              this.totalPaginator = this.informesContratistasSupervisor.length;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  irRevisarInforme(informe: CtoInformeContratistaRevisionDTO) {
    this.router.navigate([this.router.url + '/informeContratistaSupervisorRegistro', informe.idContrato, informe.idAsignacion, informe.idInforme, informe.idVersionInforme, informe.idPasoInforme, informe.idPaso, informe.idPersonaAsignacion]);
  }

}
