import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reportes-aeropuerto',
  templateUrl: './reportes-aeropuerto.component.html',
  styleUrls: ['./reportes-aeropuerto.component.sass']
})
export class ReportesAeropuertoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  irAReportesCovid(){
    this.router.navigate([this.router.url+'/reportesCovid']);
  }
}
