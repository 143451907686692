import { Component, OnInit, OnDestroy, } from '@angular/core';
import { YoutubePlaylistService } from './youtube-playlist.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { Video } from '../search.interface';
import { NgForm } from '@angular/forms';
import { EnumTipoProyecto } from '../../comun/enum-tipo-proyecto'
import { InformacionGeneral } from '../youtube-playlist.component/proyecto-carretero';

@Component({
    selector: 's-youtube-playlist',
    templateUrl: './youtube-playlist.component.html',
    styleUrls: ['./youtube-playlist.component.css'],
    providers: [YoutubePlaylistService]
})
export class YoutubePlaylistComponent implements OnInit {

    videos: Video[] = [];

    // Inicializaciones de Variables
    loading : boolean;
    inputTouched = false;
    displayedColumns1;
    listado: any = [];
    respuestaServicios: any ;
    datosEdit: any ;

    visibleConfirmarEliminar: boolean;
    visibleDialogoAgregar: boolean;
    dataDelete;
    proyectos: any = [];
    proyectosTemp: any = [];
    listadoPlaylist: any = [];
    listadoItemsPlaylist: any = [];
    informacionGeneral: InformacionGeneral = {};
    accion : any = "";
    avanceFechaCorte : any;

    proyectosSeleccionada: any = { id: '', nombre: ''};
    playlistSeleccionada: any = { canalId: '', playListId: '', title: '', description: ''};
    itemPlaylistSeleccionada: any = { canalId: '', playListId: '', videoId: '', nombreVideo : ''};

    constructor(private _service: YoutubePlaylistService,
        private route: Router,
        private ruta: ActivatedRoute,
        public dialog: MatDialog,
        private messageService: MessageService,
        private principalComponent: PrincipalComponent) { }

    ngOnInit() {
        this.loading = true;
        this.accion = "";
        this.visibleDialogoAgregar = false;
        this.playlistSeleccionada = { canalId: '', playListId: '', title: '', description: ''};
        this.itemPlaylistSeleccionada = { canalId: '', playListId: '', videoId: '', nombreVideo : ''};
        this.proyectosSeleccionada = { id: '', nombre: ''};
        this.informacionGeneral = new InformacionGeneral();
        this.cargarlistado();
        this.cargarProyectos();
        this.cargarListaPlaylistAll();
        
        this.displayedColumns1 = [
            { field: 'proyecto', header: 'Proyecto' },
            { field: 'canal', header: 'Canal' },
            { field: 'playList', header: 'PlayList' },
            { field: 'idVideo', header: 'Id Video' },
            { field: 'avance', header: 'Avance' }
        ];
    }
    

    /**
     * Metodo que carga el listado
     */
    cargarlistado(){
        return this._service.getListado().subscribe((data: {}) => {
          this.responseData(data);
        }, (error)=>{
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });    
      }
    

    // Funciones del componente
    /**
     * Metodo que carga la creacion
     */
    onNew() {
        this.loading = true;
        this.accion = "new";
        this.visibleDialogoAgregar = true; 
        this.informacionGeneral = new InformacionGeneral();
        this.proyectosSeleccionada = { id: 0, nombre: 'Seleccione...'};		
        this.playlistSeleccionada = { canalId: '', playListId: '', title: 'Seleccione...', description: ''};
        this.itemPlaylistSeleccionada = { canalId: '', playListId: '', videoId: '', nombreVideo : 'Seleccione...'};
        this.informacionGeneral.descripcion = '';
        this.informacionGeneral.rutaVideo = '';
        this.informacionGeneral.id = null;
        this.loading = false;
    }

    /**
     * Metodo que edita
     * @param id 
     */
    onEdit(id: number, title) {
        this.accion = "edit";
        this.loading = true;
        this.informacionGeneral = new InformacionGeneral();
       
        this._service.getVideoId(id).subscribe((data: {}) => {
            if(data != undefined && data != null){
                this.datosEdit = data;
                this.visibleDialogoAgregar = true; 
                this.proyectosSeleccionada = { id: this.datosEdit.idProyecto, nombre: this.datosEdit.proyecto };
                this.playlistSeleccionada = { canalId: this.datosEdit.canal, playListId: this.datosEdit.playList, title: title, description: ''};
                this.itemPlaylistSeleccionada = { canalId: this.datosEdit.canal, playListId: this.datosEdit.playList, videoId: this.datosEdit.idVideo, nombreVideo : this.datosEdit.idVideo };
                this.informacionGeneral.descripcion = this.datosEdit.descripcion;
                this.informacionGeneral.rutaVideo = this.datosEdit.rutaVideo;
                this.informacionGeneral.id = this.datosEdit.id;
                this.informacionGeneral.avance = this.datosEdit.avance;
                this.informacionGeneral.playList = this.datosEdit.playList;
                this.informacionGeneral.fechaCorte = new Date(this.datosEdit.fechaCorte);
            }else{
                this.lanzarMensajeError("No se encontró la información.");
            }
            this.loading = false;
        }, error => {
            this.loading = false;
            this.visibleDialogoAgregar = false; 
            this.principalComponent.cargarErrorServicio(error);
        });


    }
    
    /**
     * Metodo que carga el modal de liminar
     * @param data 
     */
    onDelete(data) {
        this.accion = "";
        this.dataDelete = data;
        this.visibleConfirmarEliminar = true;
    }
    
    /**
     * Metodo que cancela en el modal
     */
    cancelarEliminar() {
        this.visibleConfirmarEliminar = false;
    }
    
    /**
     * Metodo que elimina el dato
     */
    aceptarEliminar() {
        this.accion = "";
        this.loading = true;
        this._service.deleteVideo(this.dataDelete.id).subscribe((data: {}) => {
            this.visibleConfirmarEliminar = false;
            this.lanzarMensajeInfo("Se ha eliminado el registro correctamente.");
            this.cargarlistado();
        }, error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }
    
    /**
	 * Metodo que captura el evento del teclado solo alfanumerico
	 * @param evento 
	 */
	 soloNumeros(evento){

		if(evento.shiftKey || evento.ctrlKey || evento.altKey || evento.code =="BracketLeft"){
			return false;
		}

		if(evento.code == "Backspace" 
		|| evento.code == "Enter"
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"){
			return true;
		}
		
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || evento.key == "." ||
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}
	}	


    /**
	 * Metodo que guarda el Agregar asegurado o beneficiario modal
	 */
	guardarVideo(forma: NgForm){
        if(this.informacionGeneral.avance!= null && this.informacionGeneral.avance != undefined && this.informacionGeneral.avance.toString()!= ""){
            if((Number(this.informacionGeneral.avance) > 100)){
                this.lanzarMensajeError("El avance no puede ser mayor de 100.");
            }else{
                if(this.accion == "new"){
                    this.informacionGeneral.canal = this.itemPlaylistSeleccionada.canalId;
                    this.informacionGeneral.idProyecto = this.proyectosSeleccionada.id;
                    this.informacionGeneral.playList = this.itemPlaylistSeleccionada.playListId;
                    this.informacionGeneral.idVideo = this.itemPlaylistSeleccionada.videoId;
                    this.informacionGeneral.rutaVideo = "https://www.youtube.com/watch?v="+ this.itemPlaylistSeleccionada.videoId;
                    this.informacionGeneral.id = 0;
    
                    this._service.guardarVideo(this.informacionGeneral).subscribe((data: {}) => {
                        this.respuestaServicios = data;
                        if(this.respuestaServicios != undefined 
                            && this.respuestaServicios.respuestaServicio != undefined 
                            && this.respuestaServicios.respuestaServicio.mensajeSalida != undefined 
                            && this.respuestaServicios.respuestaServicio.mensajeSalida != ""){
                            this.lanzarMensajeWarning(this.respuestaServicios.respuestaServicio.mensajeSalida);
                        }else{
                            this.visibleDialogoAgregar = false;
                            this.lanzarMensajeInfo("Se a realizado el registro correctamente.");
                            this.cargarlistado();
                        }
                    }, error => {
                        this.visibleDialogoAgregar = false; 
                        this.principalComponent.cargarErrorServicio(error);
                    });
                }

                if(this.accion == "edit"){
                    this.informacionGeneral.canal = this.itemPlaylistSeleccionada.canalId;
                    this.informacionGeneral.idProyecto = this.proyectosSeleccionada.id;
                    this.informacionGeneral.playList = this.itemPlaylistSeleccionada.playListId;
                    this.informacionGeneral.idVideo = this.itemPlaylistSeleccionada.videoId;
                    this.informacionGeneral.rutaVideo = "https://www.youtube.com/watch?v="+ this.itemPlaylistSeleccionada.videoId;
    
                    this._service.guardarVideo(this.informacionGeneral).subscribe((data: {}) => {
                        this.visibleDialogoAgregar = false;
                        this.lanzarMensajeInfo("Se ha realizado la actualización correctamente.");
                        this.cargarlistado();
                    }, error => {
                        this.visibleDialogoAgregar = false; 
                        this.principalComponent.cargarErrorServicio(error);
                    });
                }
            }
        }
    }

    /**
     * Metodo que cancela el agragar o editar modal
     */
    cancelarVideo(){
        this.visibleDialogoAgregar = false; 
    }

    /**
     * Metodo que carga los proyectos
     */  
    cargarProyectos() {
        this.loading = true;
        return this._service.getProyectos(EnumTipoProyecto.Carretero).subscribe((data: {}) => {
            this.proyectos = data;
            this.loading = false;
        }, error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }
    
    /**
     * Metodo que carga los proyectos
     */  
    cargarListaPlaylistAll() {
        this.loading = true;
        return this._service.getListadoPlayList().subscribe((data: {}) => {
            this.listadoPlaylist = data;
            this.loading = false;
        }, error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     * Metodo que realiza el ochenge
     */
    onChangeItems(){
        this.loading = true;
        return this._service.getListadoItemsxPlayList(this.playlistSeleccionada.playListId).subscribe((data: {}) => {
            this.listadoItemsPlaylist = data;
            this.loading = false;
        }, error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        }); 
    }

    onChangeProyecto(){
        if(this.proyectosSeleccionada!= undefined 
            && this.proyectosSeleccionada.id != undefined 
            && this.proyectosSeleccionada.id != null){
            this.loading = true;
            return this._service.getAvance(this.proyectosSeleccionada.id).subscribe((data: {}) => {
                this.avanceFechaCorte = data;
                if(this.avanceFechaCorte != undefined && this.avanceFechaCorte.avance == null){
                    this.informacionGeneral.avance = "0";
                    this.informacionGeneral.fechaCorte = new Date();
                }else{
                    this.informacionGeneral.avance = this.avanceFechaCorte.avance;
                    this.informacionGeneral.fechaCorte = new Date(this.avanceFechaCorte.fechaCorte);
                }
                this.loading = false;
            }, error => {
                this.loading = false;
                this.principalComponent.cargarErrorServicio(error);
            }); 
        }
    }
    

    /**
     * Metodo que racorre la info
     * @param payload 
     */
    responseData(payload) {
        if (payload === null) {
          this.lanzarMensajeError("No se econtraron datos");
          this.loading = false;
        } else {
          this.listado = payload;
          this.loading = false;
        }
      }

    // /////////////////////////////Mensajes//////////////////////////////
    lanzarMensajeInfo(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
    }
    lanzarMensajeError(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    }
    lanzarMensajeWarning(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
    }

    limpiarMensajes() {
        this.messageService.clear();
    }

}
