import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { CtoFuenteFinanciacionDTO } from 'src/app/contratacion/contratacion-dto/cto-fuente-financiacion';
import { ContratacionBorradorService } from '../../contratacion-brr.service';
import { CtoContratoDTO } from 'src/app/contratacion/contratacion-dto/contrato';
import { EnumCtoOrigenRecurso } from '../../../../constantes/contratacion-constantes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ContratoBaseComponent } from '../contrato-base/contrato-base.component';
@Component({
  selector: 'app-contrato-fuente-financiacion',
  templateUrl: './contrato-fuente-financiacion.component.html',
  styleUrls: []
})
export class ContratoFuenteFinanciacionComponent implements OnInit {

  @Input()
  contratoFiducia: CtoContratoDTO;

  fiduciasPorContrato: CtoFuenteFinanciacionDTO[];
  colsFiducia: any[];
  idFiduciaGuardar: number;
  fiduciaSeleccionada: CtoFuenteFinanciacionDTO;
  fiduciasList: CtoFuenteFinanciacionDTO[];
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;
  visualizarDialogFiducia = false;
  valorFiduciaContrato: number;

  constructor(
    private lineaBase: ContratoBaseComponent,
    private messageService: MessageService,
    private contratacionBorradorService: ContratacionBorradorService,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    if (this.contratoFiducia != null) {
      this.cargarFiduciasContrato();
    }

    // Columnas del table de Fiducias
    this.colsFiducia = [
      { field: 'nit', header: 'Nit' },
      { field: 'digitoVerificacion', header: 'Digito de Verificación' },
      { field: 'razonSocial', header: 'Razón Social' },
      { field: 'valor', header: 'Valor' }
    ];
  }


  cargarFuentesFinanciacion() {
    let cargarFiducias = false;
    if (this.contratoFiducia.idOrigenRecurso != null) {

      if (this.contratoFiducia.idOrigenRecurso == EnumCtoOrigenRecurso.ANI) {
        // Ani
        cargarFiducias = false;
      } else if (this.contratoFiducia.idOrigenRecurso == EnumCtoOrigenRecurso.PATRIMONIO_AUTONOMO) {
        // Patrimonio autónomo
        cargarFiducias = true;
      } else {
        cargarFiducias = null;
      }

      this.contratacionService.GetCtoMaFuenteFinanciacionFiltroFiducias(cargarFiducias).subscribe(
        result => {
          this.fiduciasList = result;
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
    }
  }

  cargarFiduciasContrato() {
    this.lineaBase.blockedDocument = true;
    if (this.contratoFiducia != null && this.contratoFiducia.id != null) {
      if (this.contratoFiducia.esBorrador) {
        this.contratacionBorradorService.GetFuentesFinanciacionPorContratoBorrador(this.contratoFiducia.id).subscribe(
          result => {
            this.fiduciasPorContrato = result;
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.lineaBase.blockedDocument = false;
          }
        );
      }
    }
  }

  asociarFiduciaContrato(idFiduciaGuardar: number) {
    this.idFiduciaGuardar = idFiduciaGuardar;
  }

  cancelarAgregarFiducia() {
    this.visualizarDialogFiducia = false;
    this.fiduciaSeleccionada = null;
    this.valorFiduciaContrato = null;
  }

  validarAgregarFuente(): boolean {
    let validacionFuente = true;
    let sumaValoresFuente = 0;
    if (this.fiduciasPorContrato != null) {
      this.fiduciasPorContrato.forEach(val => {
        if (this.fiduciaSeleccionada.id === val.id) {
          validacionFuente = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fuente ya se encuentra relacionada al contrato', life: 10000 });
        }
        sumaValoresFuente = sumaValoresFuente + val.valor;
      });
      sumaValoresFuente = sumaValoresFuente + this.valorFiduciaContrato;
      if (sumaValoresFuente > this.contratoFiducia.valorContrato) {
        validacionFuente = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El valor de las fuentes supera el valor del contrato', life: 10000 });
      }
    }

    return validacionFuente;
  }

  guardarContratoFuenteBorrador() {
    this.lineaBase.blockedDocument = true;
    this.visualizarDialogFiducia = false;
    if (this.validarAgregarFuente()) {
      if (this.contratoFiducia != null && this.fiduciaSeleccionada != null) {
        const fiduciaContrato = {
          IdContrato: this.contratoFiducia.id,
          IdFiducia: this.fiduciaSeleccionada.id,
          valor: this.valorFiduciaContrato == null ? 0 : this.valorFiduciaContrato,
          EsBorrador: this.contratoFiducia.esBorrador
        };

        this.contratacionBorradorService.GuardarContratoFuenteBorrador(fiduciaContrato).subscribe(
          result => {
            this.idFiduciaGuardar = null;
            this.fiduciaSeleccionada = null;
            this.valorFiduciaContrato = null;
          },
          error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.visualizarDialogFiducia = false;
            this.idFiduciaGuardar = null;
            this.fiduciaSeleccionada = null;
            this.valorFiduciaContrato = null;
          },
          () => {
            this.visualizarDialogFiducia = false;
            this.idFiduciaGuardar = null;
            this.fiduciaSeleccionada = null;
            this.valorFiduciaContrato = null;
            this.cargarFiduciasContrato();
            this.lineaBase.blockedDocument = false;
          }
        );
      }
    }
  }

  aceptarEliminarFiduciaContrato() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoConfirmarEliminarRegistro = false;
    if (this.fiduciaSeleccionada != null) {
      const fiduciaContrato = {
        IdContrato: this.contratoFiducia.id,
        IdFiducia: this.fiduciaSeleccionada.id,
        EsBorrador: this.contratoFiducia.esBorrador
      };
      this.contratacionBorradorService.EliminarContratoBorradorFuente(fiduciaContrato).subscribe(
        result => {
          this.visibleDialogoExitoEliminarRegistro = true;
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
          this.visibleDialogoConfirmarEliminarRegistro = false;
        }
      );
    }
  }
  // Eliminacion - Cancelar
  cancelarEliminarFiduciaContrato() {
    this.visibleDialogoConfirmarEliminarRegistro = false;
    this.fiduciaSeleccionada = null;
    this.valorFiduciaContrato = null;
  }

  aceptarExitoEliminarFiduciaContrato() {
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleDialogoConfirmarEliminarRegistro = false;
    this.fiduciaSeleccionada = null;
    this.valorFiduciaContrato = null;
    this.ngOnInit();
  }

  irEliminarFiduciaContrato(fiducia: CtoFuenteFinanciacionDTO) {
    this.fiduciaSeleccionada = fiducia;
    this.visibleDialogoConfirmarEliminarRegistro = true;
  }
}
