import { Component, OnInit, ViewChild } from '@angular/core';
import { InformacionGeneralService } from './informacion-general.service'
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ProyectoCarretero } from './proyecto-carretero';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { EnumTipoProyecto } from '././../comun/enum-tipo-proyecto'
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-informacion-general',
  templateUrl: './informacion-general.component.html',
  styleUrls: ['./informacion-general.component.sass'],
  providers: [InformacionGeneralService]
})
export class InformacionGeneralComponent implements OnInit {
  generaciones: any = [];
  proyectos: any = [];
  tiposAeropuertos: any = [];
  proyectosAeroSelected: any = [];
  selectAeropueros: any = [];
  tipoProy: string;
  esProduccion: boolean = false;
  displayedColumnsAeropuertos;
  displayedColumns1;
  listaValidadores: any = [];

  constructor(private infoService: InformacionGeneralService, private route: Router, private messageService: MessageService, private principalComponent: PrincipalComponent) {
  }

  ngOnInit() {
    this.displayedColumns1 = [{ field: 'nombre', header: 'Nombre Proyecto' }];
    this.displayedColumnsAeropuertos = [{ field: 'nombre', header: 'Nombre Proyecto' }];
    localStorage.removeItem('idConcesionAero');
    localStorage.removeItem('nombreP');
    this.esProduccion = environment.production;
    this.cargarGeneraciones();
    this.tipoProy = sessionStorage.getItem('tipoProy')
    if (this.tipoProy != null) {
      switch (this.tipoProy) {
        case '1':
          this.aeropuertos()
          break
        case '2':
          this.carretero()
          break
        case '3':
          break
        case '4':
          this.ferrocariles()
          break
        case '5':
          this.puertos()
          break
        case '6':
          this.fluvial()
          break
      }
    }
  }


  onEdit(id: number, item) {
    localStorage.setItem('nombreP', JSON.stringify(item.nombre));
    sessionStorage.setItem('idProy', id.toString());


    if (this.tipoProy != null) {
      switch (this.tipoProy) {
        case '1':
          this.route.navigate(['aeropuertos/infGeneral/proyectoHome', id]);
          break
        case '2':
          this.route.navigate(['carreteros/infGeneral/proyectoHome', id]);
          break
        case '3':
          break
        case '4':
          this.route.navigate(['ferrocarriles/infGeneral/proyectoHome', id]);
          break
        case '5':
          this.route.navigate(['puertos/infGeneral/proyectoHome', id]);
          break
        case '6':
          this.route.navigate(['fluvial/infGeneral/proyectoHome', id]);
          break
      }
    }
  }

  carretero() {
    this.cargarProyectos(EnumTipoProyecto.Carretero);
  }

  aeropuertos() {
    //    this.proyectos = [];
    this.cargarProyectos(EnumTipoProyecto.Aeroportuario);

  }

  puertos() {
    //this.proyectos = [];
    this.cargarProyectos(EnumTipoProyecto.Portuario);
  }

  ferrocariles() {
    //this.proyectos = [];
    this.cargarProyectos(EnumTipoProyecto.Ferreo);
  }

  fluvial() {
    //this.proyectos = [];
    this.cargarProyectos(EnumTipoProyecto.Fluvial);
  }

  cargarGeneraciones() {
    return this.infoService.getGeneraciones().subscribe((data: {}) => {
      this.generaciones = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectos(_tipoproyecto: EnumTipoProyecto) {
    return this.infoService.getProyectos(_tipoproyecto).subscribe((data: {}) => {
      this.proyectos = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectosAeropuertos(_tipoproyecto: EnumTipoProyecto) {
    return this.infoService.getProyectosAeroSelect(_tipoproyecto).subscribe((data: {}) => {
      this.proyectosAeroSelected = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  nuevoProyecto() {
    this.route.navigate([this.route.url+'/proyectoLineaBase', 'new']);
  }

  onChangeSelectAeropuerto(event) {
    if(event != null){
      this.cargarProyectosAeropuertos(event.codigo);
      localStorage.setItem('idConcesionAero', JSON.stringify(event.id));
    }
  }

  cargarTiposAeropuertos(event) {
    const filtroTiposAeropuertos = [];
    this.infoService.getSelectsAero().subscribe((data: {}) => {
      this.tiposAeropuertos = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.tiposAeropuertos.length; i++) {
        const filtro = this.tiposAeropuertos[i];
        const item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          filtroTiposAeropuertos.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.tiposAeropuertos = filtroTiposAeropuertos;
      }
      return this.tiposAeropuertos;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }
}
