import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import {
  AmbObligacionEncabezadoDTO,
  AmbSubCuentaDTO,
} from '../compensaciones-ambientales.dto';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { CompensacionesAmbientalesService } from '../compensaciones-ambientales.service';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-obligaciones-compensacion',
  templateUrl: './obligaciones-compensacion.component.html',
  styleUrls: ['./obligaciones-compensacion.component.sass'],
})
export class ObligacionesCompensacionComponent implements OnInit {
  blockedDocument: boolean;
  subscription: Subscription;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  form;

  subCuenta: AmbSubCuentaDTO = {};

  obligacionAmbiental: AmbObligacionEncabezadoDTO;
  obligacionesAmbientalesList: AmbObligacionEncabezadoDTO[];

  dialogoCompensaciones: boolean;

  fechaActual: Date;

  ////////////// ACTO ADMINISTRATIVO ////////////////////
  visibleDialogoSubirActo: boolean;
  rutaActoAdmin: string;
  archivos: any = [];
  adjuntarActo: boolean;
  //////////////////////////////////////////////////////

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private compensacionesAmbientalesService: CompensacionesAmbientalesService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private principalComponent: PrincipalComponent
  ) {
    const regexMayorACero = '^[1-9][0-9]*$';
    // Crear formulario Guardar Bien
    this.form = formBuilder.group({
      valor: [
        null,
        [
          Validators.required,
          Validators.maxLength(14),
          Validators.pattern(regexMayorACero),
        ],
      ],
      fechaReferencia: [null, [Validators.required]],
    });
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.fechaActual = new Date();
    // Obtener el proyecto seleccionado
    const idParam = 'id';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
    });
    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.compensacionesAmbientalesService.srvObtenerValorSubcuentaPorProyecto(
        this.idProyecto
      )
    ).subscribe(
      ([proyectoData, subCuentaData]) => {
        this.proyectoSeleccionado = proyectoData;
        this.subCuenta = subCuentaData.valorSubCuenta;
        if (this.subCuenta == null) {
          this.subCuenta = {};
        } else {
          this.subCuenta.fechaReferencia = new Date(
            this.subCuenta.fechaReferencia
          );
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });

    this.listarObligacionesAmbientales();
  }

  listarObligacionesAmbientales() {
    forkJoin(
      this.compensacionesAmbientalesService.srvListarObligacionesAmbientalesPorProyecto(
        this.idProyecto
      )
    ).subscribe(
      ([obligacionesData]) => {
        this.obligacionesAmbientalesList =
          obligacionesData.obligacionesAmbientales;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**
   * Metodo que se llama cuando se guarda el valor de la subcuenta
   */
  submitGuardarValorSubcuenta() {
    if (this.form.valid) {
      this.guardarValorSubcuenta();
    } else {
      this.lanzarMensajeWarning(
        'Debe diligenciar el valor de la subcuenta y la fecha de referencia'
      );
    }
  }

  /**
   * Guardar el valor de la subcuenta
   */
  guardarValorSubcuenta() {
    this.subCuenta.idProyecto = this.idProyecto;
    // Guardar el DTO
    forkJoin(
      this.compensacionesAmbientalesService.srvGuardarValorSubcuenta(
        this.subCuenta
      ),
      this.compensacionesAmbientalesService.sincronizarZonaAfectada()
    ).subscribe(
      ([result, resultSincronizacion]) => {
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        }
        if (resultSincronizacion != null) {
          console.log('resultSincronizacion', resultSincronizacion);
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  btnAgregarCompensacion() {
    // Enviar Mensaje
    //this.setObligacionAmbiental(null);

    this.router.navigate([
      this.router.url + '/obligacion-ambiental/' + this.idProyecto + '/0',
    ]);
  }

  obtenerObligacionAmbientalPorId(idObligacionAmbiental: number) {
    // Obtener Obligacion Ambiental
    forkJoin(
      this.compensacionesAmbientalesService.srvObtenerObligacionAmbientalPorId(
        idObligacionAmbiental
      )
    ).subscribe(
      ([obligacionAmbientalData]) => {
        this.obligacionAmbiental = obligacionAmbientalData.obligacionAmbiental;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  btnCompensaciones(obligacionSelected: AmbObligacionEncabezadoDTO) {
    this.obtenerObligacionAmbientalPorId(obligacionSelected.id);

    //Abrir el dialogo
    this.dialogoCompensaciones = true;
  }

  btnEditar(obligacionSelected: AmbObligacionEncabezadoDTO) {
    // Enviar Mensaje
    //this.setObligacionAmbiental(obligacionSelected);

    this.router.navigate([
      this.router.url +
      '/obligacion-ambiental/' +
      this.idProyecto +
      '/' +
      obligacionSelected.id,
    ]);
  }

  btnVerAdjunto(obligacionSelected: AmbObligacionEncabezadoDTO) { }

  btnGestionarLocalizacion(obligacionSelected: AmbObligacionEncabezadoDTO) {
    // Enviar Mensaje
    //this.setObligacionAmbiental(obligacionSelected);

    this.router.navigate([
      this.router.url +
      '/gestionar-localizacion/' +
      this.idProyecto +
      '/' +
      obligacionSelected.id,
    ]);
  }

  btnConsultarLocalizacion(obligacionSelected: AmbObligacionEncabezadoDTO) {
    // Enviar Mensaje
    //this.setObligacionAmbiental(obligacionSelected);

    this.router.navigate([
      this.router.url +
      '/consultar-localizacion/' +
      this.idProyecto +
      '/' +
      obligacionSelected.id,
    ]);
  }

  bntVolver() {
    const ruta =
      'carreteros/infGeneral/proyectoHome/' +
      this.idProyecto +
      '/compensaciones-ambientales/' +
      this.idProyecto;
    this.router.navigate([ruta]);
  }

  ////////////////////// ACTO ADMINISTRATIVO /////////////////////////////////

  irVerDialogoSubirActo(obligacionSelected: AmbObligacionEncabezadoDTO) {
    this.rutaActoAdmin =
      'obligacionesAmbientales/' +
      this.proyectoSeleccionado.id +
      '/' +
      obligacionSelected.id +
      '/';
    this.obtenerObligacionAmbientalPorId(obligacionSelected.id);
    this.visibleDialogoSubirActo = true;
  }

  outputInicioVisualizarArchivo() {
    this.blockedDocument = true;
  }

  outputFinVisualizarArchivo() {
    this.blockedDocument = false;
  }

  get TIPO_SOPORTE_ANEXO() {
    return '17';
  }

  cancelarDialogoSubirActo() {
    this.visibleDialogoSubirActo = false;
  }

  aceptarAdjuntoActo() {
    this.obligacionAmbiental.dcmDocumentoDTO.rutaDocumento = this.archivos[0].nombreCompleto;

    //  Guardar el DTO
    forkJoin(
      this.compensacionesAmbientalesService.srvGuardarObligacionAmbiental(
        this.obligacionAmbiental
      )
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            //  Consultar nuevamente la lista
            this.listarObligacionesAmbientales();
            //  Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            //  Cerrar el dialogo
            this.visibleDialogoSubirActo = false;
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => { }
    );
  }
  ///////////////////////////////////////////////////////////////////////////////////////////

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  getObtenerURLMapa(idObligacion, tieneLocalizaciones) {
    let url = '#';
    if (tieneLocalizaciones) {
      url =
        'https://www.arcgis.com/apps/webappviewer/index.html?id=7acedd3ddb5b481b8909ad7a6f47bc67&query=ACServicios_6538%2CIdObligacion%2C' +
        idObligacion;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
