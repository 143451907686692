import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';

export class TrnSiNoRpt {

    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public sinoList?: TrnSiNo[],
        public sino?: TrnSiNo
    ) { }
}

export class TrnSiNo {
    constructor(
        public id?: number,
        public nombre?: string,
        public valor?: boolean) { }
}
