import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ParametricasService } from '../../parametricas/parametricas.service';
import { TrnSiNo } from '../../parametricas/trn-si-no';
import { EnumCmsConstantes } from 'src/app/comisiones/comisiones-constantes';
import { EnumRespuestaServicio } from '../constantes/constantes-comunes';

@Component({
  selector: 'app-seleccionar-si-no',
  templateUrl: './seleccionar-si-no.component.html',
  styles: []
})
export class SeleccionarSiNoComponent implements OnInit, OnChanges {

  @Input()
  esObligatorio = false;
  @Input()
  deshabilitar = false;
  @Input()
  placeholder = 'Seleccione';
  @Input()
  valoresLista: number[];
  @Input()
  valorAlmacenado: boolean;

  @Output()
  outputTrnSiNo = new EventEmitter();

  sinoSeleccionado: TrnSiNo;
  sinoList: TrnSiNo[];

  constructor(
    private parametricasService: ParametricasService
  ) { }

  ngOnInit() {
    this.cargarParametricas();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['valorAlmacenado']) {
      let variableChange = changes['valorAlmacenado'];
      //do something with variableChange.currentValue
      this.cargarParametricas();
    }
  }

  cargarParametricas() {
    this.parametricasService.getTrnSiNo().subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.sinoList = result.sinoList;
            this.cargarValoresLista();
            this.cargarValorSeleccionado();
          } else {
          }
        } else {
        }
      },
      error => {
      },
      () => {
      }
    );
  }

  retornarValorSeleccionado(event) {
    this.outputTrnSiNo.emit(this.sinoSeleccionado);
  }

  cargarValoresLista() {
    if (this.sinoList && this.valoresLista != null) {
      this.sinoList = this.sinoList.filter(dato => this.valoresLista.includes(dato.id));
    }
  }

  cargarValorSeleccionado() {
    if (this.valorAlmacenado != null) {
      this.sinoSeleccionado = this.sinoList.find(dato => dato.valor == this.valorAlmacenado);
    } else {
      this.sinoSeleccionado = this.sinoList[0];
    }
    this.outputTrnSiNo.emit(this.sinoSeleccionado);
  }

}
