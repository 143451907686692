import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
  })
export class AeropuertosHomeFichaProyectoDrupalService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
  constructor( private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }
  
  public getInfoFichaProyectoAeroDrupal() {
    return this.http.get(this.END_POINT + '/ServiciosDrupal/servicioAero')
        .pipe(
            retry(1));
  }

}