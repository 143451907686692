import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumCmsConstantes } from '../../comisiones-constantes';
import { CmsComisionAsignadaDTO } from '../../comisiones.dto';
import { ComisionesService } from '../../comisiones.service';
import { EnumTrnMaTipoSoporte } from '../../../comun/constantes/constantes-comunes';

@Component({
  selector: 'app-comisiones-legalizaciones-revision',
  templateUrl: './comisiones-legalizaciones-revision.component.html',
  styleUrls: ['./comisiones-legalizaciones-revision.component.sass']
})
export class ComisionesLegalizacionesRevisionComponent implements OnInit {

  blockedDocument: boolean;
  solicitud: CmsComisionAsignadaDTO = {};
  solicitudList: CmsComisionAsignadaDTO[];

  constructor(
    private router: Router,
    private messageService: MessageService,
    private comisionesService: ComisionesService,
    private principalComponent: PrincipalComponent
  ) { }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.blockedDocument = true;
    this.obtenerLegalizacionesAsignadas();
  }

  obtenerLegalizacionesAsignadas() {
    forkJoin(
      this.comisionesService.srvConsultarLegalizacionesAsignadas()
    ).subscribe(
      ([legalizacionesAsignadasData]) => {
        this.solicitudList = legalizacionesAsignadasData.comisionesAsignadas;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  guardarFechaInicioRevision(idAsignacion: number) {
    forkJoin(
      this.comisionesService.srvGuardarFechaInicioRevision(idAsignacion)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        } else {
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  bntVolver() {
    const ruta = "/comisiones";
    this.router.navigate([ruta]);
  }

  verLegalizacionComision(solicitudL: CmsComisionAsignadaDTO) {
    this.solicitud = solicitudL;
    this.guardarFechaInicioRevision(this.solicitud.idCmsAsignacionComision);
    this.router.navigate([this.router.url.replace('comisiones-legalizaciones-revision', '') + "/comisiones-crear-editar-solicitud/" + this.solicitud.idComision + "/" + this.solicitud.idComisionVersion + "/" + this.solicitud.idCmsAsignacionComision + "/true/true/" + 0 + "/true"]);
  }

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

}