import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContratoCreditoDetalleService } from './contrato-credito-detalle.service';
import { ContratoCreditoIndexado } from '../contrato-credito/ContratoCreditoIndexado';
import { MatTableDataSource } from '@angular/material';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-contrato-credito-detalle',
  templateUrl: './contrato-credito-detalle.component.html',
  styleUrls: ['./contrato-credito-detalle.component.sass']
})
export class ContratoCreditoDetalleComponent implements OnInit {

  contratoCredito: ContratoCreditoIndexado = {};

  idContratoCreditoDetalle: any;

  idProyecto: number;
  proyecto: any;

  visibleDialogoExito: boolean = false;

  constructor(private infoService: InformacionGeneralService, private detalleService: ContratoCreditoDetalleService, private route: ActivatedRoute, private ruta: Router, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idContratoCreditoDetalle = params.id;
      this.idContratoCreditoDetalle === 'new' ? this.cargarNuevo(this.idContratoCreditoDetalle) : this.cargarContratoCredito(this.idContratoCreditoDetalle);
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
    });
  }

  cargarNuevo(id) {
    this.contratoCredito = {};
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarContratoCredito(id: number) {
    this.detalleService.getContratoCreditoPorId(id).subscribe((data: {}) => {
      this.contratoCredito = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.ruta.navigate(['/finContratoCredito', this.idProyecto]);
  }

  guardarDetalle(forma: NgForm) {
    this.visibleDialogoExito = true;

    if (this.idContratoCreditoDetalle === 'new') {
      // this.contratoCredito.idProyecto = this.idProyecto;
      this.detalleService.guardarNuevoContratoCredito(this.contratoCredito).subscribe((data: {}) => {
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.detalleService.editarContratoCredito(this.contratoCredito).subscribe((data: {}) => {
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

}
