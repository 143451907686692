import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TrnDependenciaAni } from './trn-dependencia-ani';
import { TrnTipoDocumento } from './trn-tipo-documento';
import { TrnSexo } from './trn-sexo';
import { TrnSiNoRpt } from './trn-si-no';
import { TrnRespuestaServicio } from './trn-respuesta-servicio';
import { PersonaJuridica } from '../informacion-general-carretero/informacion-contractual/informacion-contractual-carretero.dto';
import { Ciudad } from './ciudad';
import { EnumTipoProyecto } from '../comun/enum-tipo-proyecto';
import { CtoAseguradoraRpt } from '../contratacion/contratacion-dto/cto-aseguradora';
import { TrnMaTipoProyectoRptDTO } from './trn-ma-tipo-proyecto';


@Injectable({
    providedIn: 'root'
})
export class ParametricasService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public getGeneraciones() {
        return this.http.get(this.END_POINT + '/Generaciones')
            .pipe(
                retry(1));
    }

    public getOlas() {
        return this.http.get(this.END_POINT + '/CrrMaOla')
            .pipe(
                retry(1));
    }

    public getTrnMoneda() {
        return this.http.get(this.END_POINT + '/TrnMaMoneda')
            .pipe(
                retry(1));
    }

    public getTrnUnidadMedida() {
        return this.http.get(this.END_POINT + '/TrnMaUnidadMedida')
            .pipe(
                retry(1));
    }

    public getTrnUnidadMedidaPorTipo(tipo: string) {
        return this.http.get(this.END_POINT + '/TrnMaUnidadMedida/GetConsultarUnidadMedidaPorTipo/' + tipo)
            .pipe(
                retry(1));
    }

    public getPlzMaTipoPersonaPoliza() {
        return this.http.get(this.END_POINT + '/PlzMaTipoPersonaPoliza')
            .pipe(
                retry(1));
    }

    public getPlzMaTipoAmparo() {
        return this.http.get(this.END_POINT + '/PlzMaTipoAmparo')
            .pipe(
                retry(1));
    }

    public getModAmparos() {
        return this.http.get(this.END_POINT + '/PlzMaTipoAmparo')
            .pipe(
                retry(1));
    }

    public getTrnPersonasAll(tipoDocumento) {
        return this.http.get(this.END_POINT + '/TrnPersonasAll/ConsultarPersonasSelector/' + tipoDocumento)
            .pipe(
                retry(1));
    }

    public getDepartamentos() {
        return this.http.get(this.END_POINT + '/TrnMaDepartamento')
            .pipe(
                retry(1));
    }

    public getCiudadesPorDepartamento(idDepartamento) {
        return this.http.get(this.END_POINT + '/TrnMaMunicipio/list/' + idDepartamento)
            .pipe(
                retry(1));
    }

    public getMunicipiosConDepartamentos(): Observable<Ciudad[]> {
        return this.http.get<Ciudad[]>(this.END_POINT + '/TrnMaDepartamento/GetTrnMunicipioConDepartamentos')
            .pipe(
                retry(1));
    }

    public getAccionistas() {
        return this.http.get(this.END_POINT + '/CntAccionista')
            .pipe(
                retry(1));
    }

    public getAccionistasSinContratista(idContratista, idEncabezado): Observable<ConsultaAccionistasSinContratistaRpt> {
        return this.http.get<ConsultaAccionistasSinContratistaRpt>(this.END_POINT + '/CntAccionistaContratista/noTieneContratista/' + idContratista + '/' + idEncabezado)
            .pipe(
                retry(1));
    }

    public getPersonasJuridicas(): Observable<ConsultaPersonasJuridicasRpt> {
        return this.http.get<ConsultaPersonasJuridicasRpt>(this.END_POINT + '/CrrInformacionContractual/listaPersonasJuridicas')
            .pipe(
                retry(1));
    }

    public getTiposIntervencion() {
        return this.http.get(this.END_POINT + '/CrrMaTipoIntervencion')
            .pipe(
                retry(1));
    }

    public getOrigenSaldo() {
        return this.http.get(this.END_POINT + '/FncMaOrigenSaldo')
            .pipe(
                retry(1));
    }

    public getOrigenVpip() {
        return this.http.get(this.END_POINT + '/FncMaTipoVpip')
            .pipe(
                retry(1));
    }

    public getTrnDependenciaAni(): Observable<TrnDependenciaAni[]> {
        return this.http.get<TrnDependenciaAni[]>(this.END_POINT + '/TrnMaDependenciaAni')
            .pipe(
                map(cto => {
                    return cto;
                }),
                retry(1));
    }

    public getTrnDependenciaAniPorId(idDependencia: number): Observable<any> {
        return this.http.get<any>(`${this.END_POINT}/TrnMaDependenciaAni/${idDependencia}`)
            .pipe(map(cto => cto), retry(1));
    }

    public ConsultarDependenciasAniPorTipo(tipo: string): Observable<TrnDependenciaAni[]> {
        return this.http.get<TrnDependenciaAni[]>(this.END_POINT + '/TrnMaDependenciaAni/ConsultarDependenciasAniPorTipo/' + tipo)
            .pipe(
                map(cto => {
                    return cto;
                }),
                retry(1));
    }

    /**
   * Listar dependencias por idPadre
   */
    public srvListarDependenciasPorIdPadre(idPadre): Observable<any> {
        return this.http.get(this.END_POINT + "/TrnMaDependenciaAni/listarDependenciasPorIdPadre/" + idPadre)
            .pipe(retry(1));
    }

    public srvListarDependenciasAniTreeNode(): Observable<any> {
        return this.http
            .get(this.END_POINT + "/TrnMaDependenciaAni/ListarDependenciasAniTreeNode")
            .pipe(retry(1));
    }

    public ConsultarDependenciasAniPorIdDependenciaPrimerNivel(idDependenciaPrimerNivel: number): Observable<TrnDependenciaAni[]> {
        return this.http.get<TrnDependenciaAni[]>(this.END_POINT + '/TrnMaDependenciaAni/ConsultarDependenciasAniPorIdDependenciaPrimerNivel/' + idDependenciaPrimerNivel)
            .pipe(
                map(cto => {
                    return cto;
                }),
                retry(1));
    }

    public getTrnTipoDocumento(): Observable<TrnTipoDocumento[]> {
        return this.http.get<TrnTipoDocumento[]>(this.END_POINT + '/TrnMaTipoDocumentoes')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getTrnTiposDocumentoPorIds(idsTiposDocumento: string): Observable<TrnTipoDocumento[]> {
        return this.http.get<TrnTipoDocumento[]>(this.END_POINT + '/TrnMaTipoDocumentoes/GetTipoDocumentoPorIds/' + idsTiposDocumento)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


    public getTrnSexo(): Observable<TrnSexo[]> {
        return this.http.get<TrnSexo[]>(this.END_POINT + '/PrsMaSexo')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


    public getTrnSiNo(): Observable<TrnSiNoRpt> {
        return this.http.get<TrnSiNoRpt>(this.END_POINT + '/TrnMa/ConsultarListadoSiNo')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getTrnMaSiNo() {
        return this.http.get(this.END_POINT + '/TrnMaSiNo')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


    public getTiposNovedadContrato() {
        return this.http.get(this.END_POINT + '/CntMaTipoNovedad')
            .pipe(
                retry(1));
    }

    public getEstadosContrato() {
        return this.http.get(this.END_POINT + '/CntMaEstadoContrato')
            .pipe(
                retry(1));
    }

    public getTrnPaises() {
        return this.http.get(this.END_POINT + '/TrnMaPais')
            .pipe(
                retry(1));
    }

    public getTrnCargos() {
        return this.http.get(this.END_POINT + '/TrnMaCargoAni/ConsultarListadoCargos')
            .pipe(
                retry(1));
    }


    public getApiService() {
        return this.http.get(this.END_POINT + '/Comun/AppService')
            .pipe(retry(1));
    }

    public getProyectos() {
        return this.http.get(this.END_POINT + '/Proyectos')
            .pipe(
                retry(1));
    }

    public getProyectosPorTipo(tipoProyecto: EnumTipoProyecto): Observable<any> {
        return this.http.get<any>(this.END_POINT + '/Proyectos/ProyectosPorTipo/' + tipoProyecto)
            .pipe(retry(1));
    }

    public getCtoAseguradoraActivas(): Observable<CtoAseguradoraRpt> {
        return this.http.get<CtoAseguradoraRpt>(this.END_POINT + '/CtoMaAseguradora/ConsultarAseguradorasActivas')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que consulta el servicio con la información de la tabla parametrica de tipos de proyectos (Modos de transporte)
     * @returns Objeto de respuesta de tipo TrnMaTipoProyectoRptDTO
     */
    public srvGetTrnMaTiposProyecto(): Observable<TrnMaTipoProyectoRptDTO> {
        return this.http
            .get<TrnMaTipoProyectoRptDTO>(this.END_POINT + "/TrnMaTipoProyecto/GetConsultarTiposProyecto/")
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

}

export class ConsultaAccionistasSinContratistaRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public accionistas?: PersonaJuridica[]
    ) { }
}

export class ConsultaPersonasJuridicasRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public accionistas?: PersonaJuridica[]
    ) { }
}