import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { retry } from 'rxjs/operators';

@Injectable()
export class ConcesionarioInviasPrincipalListService {
    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }


  /**
   * Metodo que carga la informacion de la consulta
   * @param idProyecto 
   */
  public getConsultaListado(idProyecto :any) {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/concesionario/listadoTramites/'+idProyecto ) 
    .pipe(
        retry(1));
    }

  /**
   * Metodo que carga la informacion de la consulta
   * @param idConceptoSolicitado 
   */
  public getConsultaRespuesta(idConceptoSolicitado) {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/concesionario/consulta/respuesta/'+ idConceptoSolicitado) 
    .pipe(
        retry(1));
    }
    
    
}
