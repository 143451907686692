import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AeropuertosHomeFichaProyectoDrupalService, } from './aeropuertos-home-ficha-proyecto-drupal.service';
import { Data } from '../aeropuertos-home-ficha-proyecto-drupal/objeto';
import { Banner1s } from '../aeropuertos-home-ficha-proyecto-drupal/objeto';
import { Imagenes } from '../aeropuertos-home-ficha-proyecto-drupal/objeto';
import { Documentos } from '../aeropuertos-home-ficha-proyecto-drupal/objeto';
import { Field_accionistas_contratista_s } from '../aeropuertos-home-ficha-proyecto-drupal/objeto';
import { Field_accionistas_interventores_s } from '../aeropuertos-home-ficha-proyecto-drupal/objeto';
import { Field_accionistas_interventordos_s } from '../aeropuertos-home-ficha-proyecto-drupal/objeto';
import { Field_accionistas_interventortres_s } from '../aeropuertos-home-ficha-proyecto-drupal/objeto';

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';

@Component({
    selector: 's-aeropuertos-home-ficha-proyecto-drupal',
    templateUrl: './aeropuertos-home-ficha-proyecto-drupal.component.html'
})
export class AeropuertosHomeFichaProyectoDrupalComponent implements OnInit {

    data: Data = {};
    imagen: Imagenes = {};
    documento: Documentos = {};
    banner1: Banner1s = {};
    loading: boolean;
    select: any = { nombre: '', id : ''};
    lstSelect: any = [];
    imagenes: any = [];
    documentos: any = [];
    datos: any = [];
    datosCompletos: any = [];
    datosAeropuertoContratista: any = [];
    contratoSeleccionada: any = { id: 0, nombre: ''};
    nombreSelect: any ;
    banner1s: any = [];
    field_accionistas_contratista: Field_accionistas_contratista_s = {};
    field_accionistas_contratista_s: any = [];
    field_accionistas_interventores: Field_accionistas_interventores_s = {};
    field_accionistas_interventores_s: any = [];
    field_accionistas_interventordos: Field_accionistas_interventordos_s = {};
    field_accionistas_interventordos_s: any = [];
    field_accionistas_interventortres: Field_accionistas_interventortres_s = {};
    field_accionistas_interventortres_s: any = [];
  
    constructor(private info: AeropuertosHomeFichaProyectoDrupalService, private route: Router,
      private ruta: ActivatedRoute) { }
  
  
    /**
     *  Inicializador.
     * @memberof PublicAeroFichaProyectoDrupalComponent
     */
    ngOnInit() {
      this.loading = true;
      this.nombreSelect = "";
      this.select = { id: 0, nombre: ''};
      this.contratoSeleccionada = { id: 0, nombre: ''};
      this.lstSelect = [];
      this.cargarInformacion();
    }
  
  
    /**
     * Metodo que carga la informacion
     */
    morebtn(id){
      document.getElementsByClassName('more')[0].className = 'none';
      document.getElementById('more'+id).classList.remove("none");
      document.getElementById('botonver'+id).className = 'none';
      document.getElementsByClassName('botonmore'+id)[0].classList.remove("none");
      document.getElementById('more'+id).style.display = 'block';
      
      //this.isShow = !this.isShow;
    }
    
    /**
     * Metodo que cierra la info
     * @param {*} id
     * @memberof PublicAeroFichaProyectoDrupalComponent
     */
    cerrarbtn(id){
      document.getElementById('more'+id).className = 'none' ;
      //document.getElementsByClassName('botonmore'+id)[0].className = 'none';
      document.getElementById('botonver'+id).classList.remove("none");
      document.getElementById('botonver'+id).style.display = 'block';
        
      document.getElementsByClassName('botonmore'+id)[0].classList.add("none");
      document.getElementById('more'+id).classList.add("more");
      document.getElementById('botonver'+id).className = 'boton ';
      document.getElementById('more'+id).style.display = 'none';
    }
    /**
     * Metodo que carga la informacion
     * @memberof PublicAeroFichaProyectoDrupalComponent
     */
    cargarInformacion() {
      this.info.getInfoFichaProyectoAeroDrupal().subscribe((data: {}) => {
         this.responseData(data);
      });
    }
  
    /**
	 * Metodo que actualiza el cargue de la informacion
	 */
    onChangeContratista(valor){
      this.datos = [];
      this.nombreSelect = valor;
      this.datosAeropuertoContratista.forEach(element => {
      if(valor == element.contratista){
          this.datos.push(element);
        }
      });
    }
  
    /**
     * metodo que transforma el objeto
     * @param payload 
     */
    responseData(payload) {
      payload.data.forEach(element => {
        this.data = new Data();
        var imagendos = '';
        if(element.banner1s == null){
          imagendos = '';
        }else{
          imagendos = element.banner1s;
        }
        if (element.categoria == 297) {
        this.data.id = element.id;
        this.data.objeto = element.objeto['value'];
        this.data.label = element.label;
        this.data.value = element.value;
        this.data.zona = element.zona;
        this.data.categoria = element.categoria;
        this.data.banner1s = imagendos['filename'];
        this.data.numero_de_contrato = element.numero_de_contrato;
        this.data.estado_proyecto = element.estado_proyecto;
        this.data.contratista = element.contratista;
        this.data.url_contratista = element.url_contratista;
        this.data.field_municipio = element.field_municipio;
        this.data.fecha_publicacion = new Date(element.fecha_publicacion);
        this.data.fecha_publicacion_str = new Date(element.fecha_publicacion).toLocaleDateString();
        this.data.field_fecha_suscripcion_del_cont =  (element.field_fecha_suscripcion_del_cont!= null && element.field_fecha_suscripcion_del_cont!= "null")? element.field_fecha_suscripcion_del_cont+"000": null;
        this.data.field_fecha_inicio_proyecto =  (element.field_fecha_inicio_proyecto!= null && element.field_fecha_inicio_proyecto!= "null")? element.field_fecha_inicio_proyecto+"000": null;
        this.data.field_fecha_fin_proyecto =  (element.field_fecha_fin_proyecto!= null && element.field_fecha_fin_proyecto!= "null")? element.field_fecha_fin_proyecto+"000": null;
        this.data.field_interventor_proyecto = element.field_interventor_proyecto;
        this.data.field_url_interventor_proyecto = element.field_url_interventor_proyecto;
        this.data.field_tipo_de_carga = element.field_tipo_de_carga;
        this.data.field_tipo_de_permiso = element.field_tipo_de_permiso;
        this.data.field_valor_proyecto = element.field_valor_proyecto;
        this.data.field_interventor_proyecto_2 = element.field_interventor_proyecto_2;
        this.data.field_interventor_proyecto_3 = element.field_interventor_proyecto_3;
        

        this.imagenes = [];
        element.imagenes.forEach(ima => {
          this.imagen = new Imagenes();
          this.imagen.fid = ima.fid;
          this.imagen.uid = ima.uid;
          this.imagen.filename = ima.filename;
          this.imagen.filemime = ima.filemime;
          this.imagenes.push(this.imagen);
        });
  
          this.data.imagenes = this.imagenes;      
  
          this.documentos = [];
          element.documentos.forEach(doc => {
            this.documento = new Documentos();
            this.documento.fid = doc.fid;
            this.documento.uid = doc.uid;
            this.documento.filename = doc.filename;
            this.documento.filemime = doc.filemime;
            this.documentos.push(this.documento);
          });
          this.data.documentos = this.documentos;
  
          this.field_accionistas_contratista_s = [];
          element.field_accionistas_contratista_s.forEach(doc => {
            this.field_accionistas_contratista = new Field_accionistas_contratista_s();
            this.field_accionistas_contratista.field_contratista_listado = doc.field_contratista_listado;
            this.field_accionistas_contratista.field_porcentaje_participacion = doc.field_porcentaje_participacion;
            this.field_accionistas_contratista.field_nacionalidad = doc.field_nacionalidad;
            this.field_accionistas_contratista_s.push(this.field_accionistas_contratista);
          });
          this.data.field_accionistas_contratista_s = this.field_accionistas_contratista_s;

          this.field_accionistas_interventores_s = [];
          element.field_accionistas_interventores_s.forEach(doc => {
            this.field_accionistas_interventores = new Field_accionistas_interventores_s();
            this.field_accionistas_interventores.field_interventor_listado = doc.field_interventor_listado;
            this.field_accionistas_interventores.field_porcentaje_participacion = doc.field_porcentaje_participacion;
            this.field_accionistas_interventores.field_nacionalidad = doc.field_nacionalidad;
            this.field_accionistas_interventores_s.push(this.field_accionistas_interventores);
          });
          this.data.field_accionistas_interventores_s = this.field_accionistas_interventores_s;

          this.field_accionistas_interventordos_s = [];
          element.field_accionistas_interventordos_s.forEach(doc => {
            this.field_accionistas_interventordos = new Field_accionistas_interventordos_s();
            this.field_accionistas_interventordos.field_contratista_interventor = doc.field_contratista_interventor;
            this.field_accionistas_interventordos.field_porcentaje_participacion = doc.field_porcentaje_participacion;
            this.field_accionistas_interventordos.field_nacionalidad = doc.field_nacionalidad;
            this.field_accionistas_interventordos_s.push(this.field_accionistas_interventordos);
          });
          this.data.field_accionistas_interventordos_s = this.field_accionistas_interventordos_s;

          this.field_accionistas_interventortres_s = [];
          element.field_accionistas_interventortres_s.forEach(doc => {
            this.field_accionistas_interventortres = new Field_accionistas_interventortres_s();
            this.field_accionistas_interventortres.field_contratista_interventor = doc.field_contratista_interventor;
            this.field_accionistas_interventortres.field_porcentaje_participacion = doc.field_porcentaje_participacion;
            this.field_accionistas_interventortres.field_nacionalidad = doc.field_nacionalidad;
            this.field_accionistas_interventortres_s.push(this.field_accionistas_interventortres);
          });
          this.data.field_accionistas_interventortres_s = this.field_accionistas_interventortres_s;
          
          this.datosAeropuertoContratista.push(this.data);
          this.datos.push(this.data);
        }
  
      });
  
      this.lstSelect = [];
      payload.data.forEach(element => {
        if(element.contratista != null){
          if(this.lstSelect.length == 0){
            this.select = {  id: element.id, nombre: element.contratista };
            this.lstSelect.push(this.select);
          }else{
            var encontro = false;
            this.lstSelect.forEach(objeto => {
              if(objeto.nombre == element.contratista){
                encontro = true;
              }
            });
            if(!encontro){
              this.select = {  id: element.id, nombre: element.contratista };
              this.lstSelect.push(this.select);
            }
          }
        }
      });
      this.loading = false;
    }
  
  }
  