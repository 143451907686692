import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router'; 
import { CertificacionGeneralService } from './certificacion-general.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BusquedaActivo } from '../../dtos/certificaciones-laborales/certificacion-laboral';
import { ProgressSpinnerDialogComponent } from '../../progress-spinner-dialog/progress-spinner-dialog.component';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-certificacion-general',
  templateUrl: './certificacion-general.component.html',
  styleUrls: ['./certificacion-general.component.sass'],
  providers: [CertificacionGeneralService]
})
export class CertificacionGeneralComponent implements OnInit {
  // Inicializaciones de Variables
  loading: boolean;
  displayedColumns1;
  displayedColumnsHistorico;
  listaCertificaciones: any = [];
  listaCertificacionesHistorico: any = [];
  busqueda: BusquedaActivo = {};
  tipoCertificado: any = [];
  tempTipoCertificado: any = [];
  filtrotipoCertificado: any[];
  tipoCertificadoSeleccionado: any = null;
  objetoGeneracion: any = null;
  
  visibleDialogoEnviar: boolean;
  visibleDialogoHistorico: boolean;
  visibleError: boolean;
  message : any;
  evento : any;
  dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent> ;

  constructor(
    private consultaService: CertificacionGeneralService,
    private route: Router,
    public dialog: MatDialog,
    public location: Location,
    private messageService: MessageService,
    private http: HttpClient,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.objetoGeneracion = { error: null, archivo: null, nombreArchivo : null, rutaArchivo: null }
    this.loading = true;	
    this.tipoCertificadoSeleccionado = {nombre : '' , valor: ''};
    this.cargarTipoCertificado();
    this.onFind();
    this.displayedColumns1 = [
      { field: 'numeroDocumento', header: 'Número Documento' },
      { field: 'nombreEmpleado', header: 'Nombre del Empleado' },
      { field: 'cargo', header: 'Cargo' },
      { field: 'radicado', header: 'Radicado' },
      { field: 'fechaHasta', header: 'Fecha Creación' },
      { field: 'tipoCertificado', header: 'Tipo Certificado' }
    ];
    this.displayedColumnsHistorico = [
      { field: 'nombreEmpleado', header: 'Nombre del Empleado' },
      { field: 'radicado', header: 'Radicado' },
      { field: 'fechaHasta', header: 'Fecha Creación' },
      { field: 'tipoCertificado', header: 'Tipo Certificado' }
    ];
  }

  /**
   * Metodo que carga la lista de tipo de certificados
   */
  cargarTipoCertificado() {
    return this.consultaService.getTipoCertificacion().subscribe((data: {}) => {
      const temp = { nombre : 'Seleccione...', valor : 'Seleccione...'};
      this.tipoCertificado = [];
      this.tipoCertificado.push(temp);
      this.tempTipoCertificado = data;

      this.tempTipoCertificado.forEach(element => {
        const temp = { nombre : element.nombre, valor : element.valor};
            this.tipoCertificado.push(temp);
        });
    });
  }

  /**
   * Metodo que realiza la busqueda de la información
   */
  onFind() {
    this.cargarPrincipal();
    this.cargarhistorico();
  }

  /**
  * Metodo que siguiente
  */
  onFollow() {    
    this.visibleDialogoEnviar = true;  
  }

  /**
   * Metodo que carga la visualizacion del historico
   *
   * @memberof CertificacionGeneralComponent
   */
  onViewHistory(){
    this.visibleDialogoHistorico = true;  
  }

  /**
   * Metodo que genera y guarda en el sistema la certificacion laboral generada.
   *
   * @param {NgForm} forma
   * @memberof CertificacionGeneralComponent
   */
  guardarCertificado(forma: NgForm){
    this.loading = true;
    if(this.tipoCertificadoSeleccionado!= "" && this.tipoCertificadoSeleccionado.nombre!= null && this.tipoCertificadoSeleccionado.nombre != ""){
      this.visibleDialogoEnviar = false;  
      this.consultaService.postGenerarPdfGeneral(this.tipoCertificadoSeleccionado.valor).subscribe((data: {}) => {
        this.tipoCertificadoSeleccionado = {nombre : '' , valor: ''};
        this.objetoGeneracion = data;
        this.loading = false;
        this.visibleDialogoEnviar = false;  

        if(this.objetoGeneracion!= null && this.objetoGeneracion.error == null){
          this.lanzarMensajeInfo("Se ha generado un certificado.");
          this.cargarPrincipal();
          this.cargarhistorico();
        }else{
          this.lanzarMensajeWarning(this.objetoGeneracion.error);
        }
      }, (error)=>{
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
        this.visibleDialogoEnviar = false;  
      });
    }else{
      this.loading = false;
      this.lanzarMensajeWarning("Seleccionar un tipo de certificado");
    }
  }
  
  /**
  * Metodo cancelar
  */
  onNoClick(): void {
    this.visibleDialogoEnviar = false;
  }

   /**
  * Metodo cancelar modal hisotrico
  */
  onNoClickHistory(){
    this.visibleDialogoHistorico = false;
  }

  /**
   * Metodo que carga la lista de generaciones
   * @param id 
   */
  onFollowCertificado(id: number){
    this.loading = true;
    return this.consultaService.getDescargarPdfActivoGeneral(id).subscribe((data: {}) => {
      this.onDownload(data);
      this.loading = false;
    }, (error)=>{
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  /**
   *Metodo que descarga el archivo
   *
   * @param {*} data
   * @memberof CertificacionGeneralComponent
   */
  onDownload(data) {
    var a = document.createElement("a");
    var fileName = data.nombreArchivo;
    var base64str = data.archivo;
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    var blob = new Blob( [view], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }


  // Metodo que carga la busqueda
  cargarPrincipal() {
    return this.consultaService.getBusqueda().subscribe((data: {}) => {
        this.responseDataPrincipal(data);
        this.loading = false;
    }, (error)=>{
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
    });
  }

   // Metodo que carga la busqueda
   cargarhistorico() {
    return this.consultaService.getHistorico().subscribe((data: {}) => {
        this.responseData(data);
    }, (error)=>{
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
    });
  }


  // Metodo que carga la respuesta
  responseData(payload) {
    if (payload.objeto === null) {
        this.loading = false;
        this.lanzarMensajeError(payload.respuestaServicio.mensajeSalida);
    } else {
        this.listaCertificacionesHistorico = payload;
        this.dialogSpin.close();
        this.loading = false;
    }
  }

   // Metodo que carga la respuesta
   responseDataPrincipal(payload) {
    if (payload.objeto === null) {
        this.loading = false;
        this.lanzarMensajeError(payload.respuestaServicio.mensajeSalida);
    } else {
        this.listaCertificaciones = payload;
        this.loading = false;
    }
  }


  // Metodo que limpia la busqueda
  limpiarBusqueda() {
    this.tipoCertificadoSeleccionado = null;
  }

  /**
  * Metodo que realiza el retorno
  */
  onReturn() {
    this.route.navigate(['/home-certificaciones-laborales/']);
  }

  // /////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
  }

  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
  }

  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }






}
