import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ComponentesComunesService } from '../componentes-comunes.service';
import { IntervencionDTO } from '../componentes-comunes.dto';
import { VwCrrAvanceIntervencionUfDepartamentoDTO } from 'src/app/avance-proyecto/ejecucion-proyecto.dto';
import { EjecucionProyectoService } from 'src/app/avance-proyecto/ejecucion_proyecto.service';

@Component({
  selector: 'app-seleccionar-intervencion',
  templateUrl: './seleccionar-intervencion.component.html',
  styleUrls: ['./seleccionar-intervencion.component.sass']
})
export class SeleccionarIntervencionComponent implements OnInit {

  blockedDocument: boolean = false;

  vwCrrAvanceIntervencionUfDeptoDTOList: VwCrrAvanceIntervencionUfDepartamentoDTO[];
  vwCrrAvanceIntervencionUfDeptoDTOSeleccionado: VwCrrAvanceIntervencionUfDepartamentoDTO;

  vwCrrAvanceIntervencionUfDeptoConEtapasDTOList: VwCrrAvanceIntervencionUfDepartamentoDTO[];

  vwCrrAvanceIntervencionUfDeptoConEtapaConstruccionDTOList: VwCrrAvanceIntervencionUfDepartamentoDTO[];

  ETAPA_CONSTRUCCION: number = 3;
  ETAPA_OPERACION_Y_MANTENIMIENTO: number = 4;


  @Output() OutputIntervencionSeleccionado = new EventEmitter();
  @Output() OutputYaCargoComponente = new EventEmitter();
  @Output() OutputYaCargoEtapaConstruccion = new EventEmitter();

  @Input() idUnidadFuncional: number;
  @Input() idEjecucion: number;
  @Input() idEtapa: number;

  @Input() renderedVerDetalles: boolean;
  @Input() labelVerDetalles: string;
  @Input() iconoVerDetalles: string;

  @Input() soloAvancePublicado: boolean;

  @Input() verEtapas: boolean;

  @Input() porcentajeMinimo: number;

  constructor(private route: Router, private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private ejecucionProyectoService: EjecucionProyectoService) { }

  ngOnInit() {
    //Bloquear pantalla
    this.blockedDocument = true;


    if (this.verEtapas) {
      this.avanceIntervencionUfPublicadoConEtapasPorUfYEjecucion();
      this.avanceIntervencionUfPublicadoConEtapasPorUfYEjecucionConstruccion();
      this.avanceIntervencionUfPublicado(false);
    } else {
      if (this.idUnidadFuncional != null && this.idEjecucion != null) {
        if (this.soloAvancePublicado) {
          this.avanceIntervencionUfPublicado(true);
        } else {
          this.avanceIntervencionUf();
        }
      }
    }
  }

  avanceIntervencionUf() {
    return this.ejecucionProyectoService.srvAvanceIntervencionUf(this.idUnidadFuncional, this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceIntervencionUfDeptoDTOList = data;
      //Desbloquear pantalla
      this.blockedDocument = false;
      this.OutputYaCargoComponente.emit("");
    });
  }

  avanceIntervencionUfPublicado(lanzarOutput: boolean) {
    return this.ejecucionProyectoService.srvAvanceIntervencionUfPublicado(this.idUnidadFuncional, this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceIntervencionUfDeptoDTOList = data;

      //Desbloquear pantalla
      this.blockedDocument = false;

      if (lanzarOutput) {
        this.OutputYaCargoComponente.emit("");
      }
    });
  }

  avanceIntervencionUfPublicadoConEtapasPorUfYEjecucion() {
    return this.ejecucionProyectoService.srvAvanceIntervencionUfPublicadoConEtapasPorUfYEjecucion(this.idUnidadFuncional, this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceIntervencionUfDeptoConEtapasDTOList = data;
      this.validarEtapa();
    });
  }

  avanceIntervencionUfPublicadoConEtapasPorUfYEjecucionConstruccion() {
    return this.ejecucionProyectoService.srvAvanceIntervencionUfPublicadoConEtapasPorUfYEjecucionYEtapa(this.idUnidadFuncional, this.idEjecucion, 3).subscribe(data => {
      this.vwCrrAvanceIntervencionUfDeptoConEtapaConstruccionDTOList = data;
      this.validarEtapaConstruccion();
    });
  }

  /**etapaConstruccionIntervenciones() {
    return this.ejecucionProyectoService.srvEtapaConstruccionIntervenciones(this.idUnidadFuncional, this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceIntervencionUfDeptoDTOList = data;
      this.validarEtapa();
    });
  }

  etapaOperYMantIntervenciones() {
    return this.ejecucionProyectoService.srvEtapaOperYMantIntervenciones(this.idUnidadFuncional, this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceIntervencionUfDeptoDTOList = data;
      this.validarEtapa();
    });
  }**/

  private validarEtapa() {
    let todasAl100 = true;
    let todasConFechaFin = true;
    for (let index = 0; index < this.vwCrrAvanceIntervencionUfDeptoConEtapasDTOList.length; index++) {
      let avanceVar = this.vwCrrAvanceIntervencionUfDeptoConEtapasDTOList[index];
      if (avanceVar.porcentajeAvance * 100 < this.porcentajeMinimo) {
        todasAl100 = false;
      }
      if (avanceVar.idEtapa == null) {
        todasConFechaFin = false;
      } else {
        if (avanceVar.fechaFinEtapa == null) {
          todasConFechaFin = false;
        }
      }
    }


    let todasAl100YConFechaFin = todasAl100 && todasConFechaFin;



    //Desbloquear pantalla
    this.blockedDocument = false;
    this.OutputYaCargoComponente.emit(todasAl100YConFechaFin);
  }

  private validarEtapaConstruccion() {
    let todasAl100 = true;
    let todasConFechaFin = true;
    for (let index = 0; index < this.vwCrrAvanceIntervencionUfDeptoConEtapaConstruccionDTOList.length; index++) {
      let avanceVar = this.vwCrrAvanceIntervencionUfDeptoConEtapaConstruccionDTOList[index];
      if (avanceVar.porcentajeAvance * 100 < this.porcentajeMinimo) {
        todasAl100 = false;
      }
      if (avanceVar.idEtapa == null) {
        todasConFechaFin = false;
      } else {
        if (avanceVar.fechaFinEtapa == null) {
          todasConFechaFin = false;
        }
      }
    }


    let todasAl100YConFechaFin = todasAl100 && todasConFechaFin;



    //Desbloquear pantalla
    this.blockedDocument = false;
    this.OutputYaCargoEtapaConstruccion.emit(todasAl100YConFechaFin);
  }

  /**listarIntervencionesPorUnidadFuncional(_idUnidadFuncional: number) {
    return this.componentesComunesService.listarIntervencionesPorUnidadFuncional(_idUnidadFuncional).subscribe(data => {
      this.intervencionDTOList = data;
    });
  }**/

  rowSelectIntervencion(_intervencionDTOSeleccionado: VwCrrAvanceIntervencionUfDepartamentoDTO) {
    this.vwCrrAvanceIntervencionUfDeptoDTOSeleccionado = _intervencionDTOSeleccionado;
    //Emitir la intervencionDTOSeleccionado afuera del componente
    this.OutputIntervencionSeleccionado.emit(this.vwCrrAvanceIntervencionUfDeptoDTOSeleccionado);
  }

}
