import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TribunalesService {

  public END_POINT = '';

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public obtenerListaTribunales(id) {
    return this.http.get(this.END_POINT + '/juridicotribunal/list/' + id )
      .pipe(
        retry(1));
  }

  public eliminarTramiteAmbiental(id) {
    return this.http.delete(this.END_POINT + '/tramiteAmbiental/' + id )
      .pipe(
        retry(1));
  }

  public getDepartamentos() {
    return this.http.get(this.END_POINT + '/TrnMaDepartamento')
        .pipe(
            retry(1));
}

public getCiudadesPorDepartamento(idDepartamento) {
    return this.http.get(this.END_POINT + '/TrnMaMunicipio/list/' + idDepartamento)
        .pipe(
            retry(1));
}

public getPriorizacion() {
  return this.http.get(this.END_POINT + '/tramiteAmbiental/priorizacion')
       .pipe(
           retry(1));
}


}
