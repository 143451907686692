import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InversionDetalleService } from './inversion-detalle.service';
import { Inversion } from '../inversion/inversion';
import { MatTableDataSource } from '@angular/material';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-inversion-detalle',
  templateUrl: './inversion-detalle.component.html',
  styleUrls: ['./inversion-detalle.component.sass']
})
export class InversionDetalleComponent implements OnInit {

  inversion: Inversion = {};

  idInversionDetalle: any;



  filteredBrands: any[];

  unidadesFuncionales: any = [];

  elementoSeleccionado: any;

  idProyecto: number;
  fechaReferencia: string;
  proyecto: any;

  visibleDialogoExito: boolean = false;

  constructor(private infoService: InformacionGeneralService, private detalleService: InversionDetalleService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idInversionDetalle = params.idInversion;
      this.fechaReferencia = params.fechaReferencia;
      this.idInversionDetalle === 'new' ? this.cargarNuevo(this.idInversionDetalle) : this.cargarInversion(this.idInversionDetalle);
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
    });

  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarNuevo(id) {
    this.inversion = { fechaReferencia: new Date(this.fechaReferencia) };
  }

  guardarDetalle(forma: NgForm) {
    this.visibleDialogoExito = true;
    this.inversion.idUnidadFuncional = this.elementoSeleccionado.idUnidadFuncional;
    if (this.idInversionDetalle === 'new') {
      this.inversion.idUnidadFuncional = this.elementoSeleccionado.idUnidadFuncional;
      this.detalleService.guardarNuevoInversion(this.inversion).subscribe((data: {}) => {
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.inversion.idUnidadFuncional = this.elementoSeleccionado.idUnidadFuncional;
      this.detalleService.editarInversion(this.inversion).subscribe((data: {}) => {
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.ruta.navigate(['/finInversion', this.idProyecto, this.fechaReferencia]);
  }

  cargarInversion(id: number) {
    this.detalleService.getInversionPorId(id).subscribe((data: {}) => {
      this.inversion = data;
      this.detalleService.getUnidadFuncionalPorId(this.inversion.idUnidadFuncional).subscribe((data: {}) => {
        this.elementoSeleccionado = data;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    });
  }

  cargarUnidadesFuncionales(event) {
    this.filteredBrands = [];
    return this.detalleService.getUnidadesFuncionalesPorProyecto(this.idProyecto).subscribe((data: {}) => {
      this.unidadesFuncionales = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.unidadesFuncionales.length; i++) {
        let brand = this.unidadesFuncionales[i];
        let item: string = String(brand.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filteredBrands.push(brand);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.unidadesFuncionales = this.filteredBrands;
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.unidadesFuncionales.length; i++) {
      let brand = this.unidadesFuncionales[i];
      let item: string = String(brand.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filteredBrands.push(brand);
      }
    }
  }

}
