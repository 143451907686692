import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PublicCarreterasConsultaFicha5gService } from './public-carreteras-consulta-ficha5g.service';
import { MessageService } from 'primeng/api';
import { ProyectoEstructuracion } from '../../proyectoEstructuracion';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-public-carreteras-consulta-ficha5g',
  templateUrl: './public-carreteras-consulta-ficha5g.component.html',
  styleUrls: ['./public-carreteras-consulta-ficha5g.component.sass']
})
export class PublicCarreterasConsultaFicha5gComponent implements OnInit {

  displayedColumnsT1: any[];
  DatosTabla: any = [];  
  DatosTablaTotal: any = [];  
  proyectoSeleccionado:ProyectoEstructuracion = new ProyectoEstructuracion(0,'',0,'','','',0,0,0,0,0,0,0,0,0,0,'',0,0,0,0,0,'','',0,'','','','','','','','','','',0,'',0,new Date(),new Date(),new Date());
  estadosProyectos: any=[];
  items: MenuItem[]=[];
  itemsPasos:MenuItem[]=[];
  activarPasos:number=1;
  blockedDocument = false;
  
  constructor(private publicCarreterasConsultaFicha5gService: PublicCarreterasConsultaFicha5gService, private messageService: MessageService, private principalComponent: PrincipalComponent) {
    publicCarreterasConsultaFicha5gService.getDatosProyectos5GBD().subscribe((dato) =>{          
      if (dato != null && dato.respuestaServicio != null) {
        if (dato.respuestaServicio.codigoSalida === 1){       
           //cargar los estados del proyecto
           dato.proyectos.forEach(e => {
        if(!this.estadosProyectos.includes(e.estado)){
          if(e.estado != 'No Aplica'){
            this.estadosProyectos.push(e.estado);
            this.itemsPasos.push({label:e.estado});
          }    
          
        }
      });   
          this.CargarDatos(dato.proyectos.filter(p=>p.tipoModo==2 && p.idOla==1))          
        }
        else{         
          this.messageService.add({severity: 'error', summary: 'Error', detail: dato.respuestaServicio.mensajeSalida, life: 10000 });
        }
      }
    },
    error => {
      this.blockedDocument = false;         
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
    },
    () => {
      this.blockedDocument = false;
    });
    }

  ngOnInit() {
      this.activarPasos=1;
      this.blockedDocument = true;         
  }

  //formato moneda en billones
   formatoMoneda(valor: number) {
    return valor? "$" + (valor/1000000000000).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " Bn" : "$0";
  } 
  
  //formato separador miles 
  formatoNumeros(valor: number) {
    return valor? valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  }

    //formato kilometros 
    formatoKilometros(valor: number) {
      return valor? valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +" km": "0 km";
    }

  // Cargar los datos de los proyectos
  CargarDatos(proyectos: ProyectoEstructuracion[]){

    proyectos.forEach( p => 
                    this.items.push({label: p.nombre + " " + this.formatoMoneda(p.capex),icon: 'pi pi-fw pi-plus', command:(event)=>{this.actualizarDatos(p)}})
           );
    
        
      this.itemsPasos.sort(function(a,b){
        if(a.label < b.label) { return -1; }
            if(a.label > b.label) { return 1; }
    return 0;

      });     

    this.displayedColumnsT1 = [
      { field: 'Caracteristica', header: 'Característica' },
      { field: 'Valor', header: 'Valor' }    
    ];   

    this.actualizarDatos(proyectos[0]);
  }

  //Actualizar el dato seleccionado
  actualizarDatos(proyecto: ProyectoEstructuracion ){  
   this.proyectoSeleccionado=proyecto;   

   this.DatosTabla=[];
    this.DatosTablaTotal = [     
      { Caracteristica: 'Longitud Origen – Destino', Valor: this.formatoKilometros(proyecto.longitudOrigenDestino/1000) },
      { Caracteristica: 'Construcción Calzada Sencilla', Valor: this.formatoKilometros(proyecto.longitudConstruccionCalzadaSencilla/1000) },      
      { Caracteristica: 'Construcción Doble Calzada', Valor: this.formatoKilometros(proyecto.longitudConstruccionDobleCalzada/1000) },
      { Caracteristica: 'Construcción Segunda Calzada', Valor: this.formatoKilometros(proyecto.longitudConstruccionSegundaCalzada/1000)},    
      { Caracteristica: 'Construcción Vía Nueva', Valor: this.formatoKilometros(proyecto.longitudConstruccionViaNueva/1000)},      
      { Caracteristica: 'Construcción Túneles', Valor: this.formatoKilometros(proyecto.longitudConstruccionTuneles/1000)},      
      { Caracteristica: 'Mejoramiento Calzada Existente', Valor: this.formatoKilometros(proyecto.longitudMejoramientoCalzadaExistente/1000)},
      { Caracteristica: 'Rehabilitación Calzada Existente', Valor: this.formatoKilometros(proyecto.longitudRehabilitacionCalzadaExistente/1000)},      
      { Caracteristica: 'Puesta a punto Calzada Existente', Valor: this.formatoKilometros(proyecto.longitudPuestaPuntoCalzadaExistente/1000)},
      { Caracteristica: 'Ampliación', Valor: proyecto.ampliacion},
      { Caracteristica: 'Duración Pre-Construcción:', Valor: proyecto.duracionPreConstruccion/12 +  " año(s)"},
      { Caracteristica: 'Duración Construcción:', Valor: proyecto.duracionConstruccion/12 +  " año(s)" },
      { Caracteristica: 'Duración Concesión:', Valor: proyecto.duracionConcesion/12 +  " año(s)" },
      { Caracteristica: 'Capex:', Valor: this.formatoMoneda(proyecto.capex)},
      { Caracteristica: 'Opex:', Valor:this.formatoMoneda(proyecto.opex)}      
    ]; 
   
    //Solo mostrar los que tienen informacion
    for(var j in this.DatosTablaTotal){
      if(this.DatosTablaTotal[j].Valor !="" && this.DatosTablaTotal[j].Valor !=0 && this.DatosTablaTotal[j].Valor !="0 km" ){
        this.DatosTabla.push(this.DatosTablaTotal[j]);     
      }
    };
    
    //Activas estados del proyecto
    for(var i=0; i<this.itemsPasos.length; i++){        
          if(this.itemsPasos[i].label == proyecto.estado){            
           this.activarPasos=i;      
           i=this.itemsPasos.length;
        }
      }            
  };

  // Navegar al sitio de cuarto de datos
  onNavigateCuartoDatos() {    
    if(this.proyectoSeleccionado.urlCuartoDatos  != null) 
        window.open( this.proyectoSeleccionado.urlCuartoDatos, '_blank');    
  }

  // Navegar al sitio de secop del proyecto
  onNavigateSecop() {    
    if(this.proyectoSeleccionado.urlSecop  != null) 
        window.open( this.proyectoSeleccionado.urlSecop, '_blank');    
  }
}