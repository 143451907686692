import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
@Injectable()
export class PqrsRadicacionService {
    
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

   /**
    * Metodo que carga la informacion de la tipo solicitud
    *
    * @returns
    * @memberof PqrsRadicacionService
    */
   public getListaTipoSolicitud() {
    return this.http.get(this.END_POINT + '/pqrs/radicacion/lista/tipo') 
    .pipe(
        retry(1));
    } 

   /**
    * Metodo que carga la informacion de la tipo Solicitante
    *
    * @returns
    * @memberof PqrsRadicacionService
    */
   public getListaTipoSolicitante() {
    return this.http.get(this.END_POINT + '/pqrs/radicacion/lista/tipoSolicitante') 
    .pipe(
        retry(1));
    } 

   /**
    * Metodo que carga la informacion de la tipo documento
    *
    * @returns
    * @memberof PqrsRadicacionService
    */
   public getListaTipoDocumento() {
    return this.http.get(this.END_POINT + '/pqrs/radicacion/lista/tipoDocumento') 
    .pipe(
        retry(1));
    } 

   /**
    * Metodo que carga la informacion de paises
    *
    * @returns
    * @memberof PqrsRadicacionService
    */
   public getListaPaises() {
    return this.http.get(this.END_POINT + '/pqrs/radicacion/lista/pais') 
    .pipe(
        retry(1));
    } 

   /**
    * Metodo que carga la informacion de departamentos
    *
    * @returns
    * @memberof PqrsRadicacionService
    */
   public getListaDepartamentos() {
    return this.http.get(this.END_POINT + '/pqrs/radicacion/lista/departamentos') 
    .pipe(
        retry(1));
    } 

   /**
    * Metodo que carga la informacion de departamentos
    *
    * @param {*} idCiudad
    * @returns
    * @memberof PqrsRadicacionService
    */
   public getListaCiudadxDepartamentos(idCiudad) {
    return this.http.get(this.END_POINT + '/pqrs/radicacion/lista/ciudad/'+idCiudad) 
    .pipe(
        retry(1));
    } 
    
   /**
    * Metodo que carga la informacion de departamentos
    *
    * @returns
    * @memberof PqrsRadicacionService
    */
   public getListaMedioRespuesta() {
    return this.http.get(this.END_POINT + '/pqrs/radicacion/lista/medioRespuesta') 
    .pipe(
        retry(1));
    } 

    /**
    * Metodo que realiza la radicacion
    *
    * @returns
    * @memberof PqrsRadicacionService
    */
   public postRadicar(data) {
    return this.http.post(this.END_POINT + '/pqrs/radicar', data) 
    .pipe(
        retry(1));
    } 

    /**
     * Metodo que descarga los archivos
     *
     * @param {*} data
     * @returns
     * @memberof PqrsRadicacionService
     */
    public downloadFile(nombreArchivo) {
        return this.http.get(this.END_POINT + '/Comun/getFilePublic/' + nombreArchivo + '/pqrsd', this.httpOptions)
            .pipe(
                retry(1));
    }


}
