import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InformacionGeneralService } from '../informacion-general/informacion-general.service';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-menu-peajes',
  templateUrl: './peajes-menu.component.html',
  styleUrls: ['./peajes-menu.component.sass']
})

export class MenuPeajesComponent implements OnInit {

  idProyecto: number;
  proyecto: any;
  nombreProyecto: string;

  constructor(private infoService: InformacionGeneralService, private router: Router, private route: ActivatedRoute, private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idProyecto = params.id;
    });
    this.cargarProyectoCarretero(this.idProyecto);

  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
      this.nombreProyecto = this.proyecto.nombre;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAReportarTraficoPeajes() {
    this.router.navigate([this.router.url + '/trafico-peajes', this.idProyecto]);
  }

  irATarifas() {
    this.router.navigate([this.router.url + '/tarifas-peajes', this.idProyecto]);
  }

  irAReportarRecaudoPeajes() {
    this.router.navigate([this.router.url + '/recaudo-peajes', this.idProyecto]);
  }

  irAInformacionGeneralPeajes(){
    this.router.navigate([this.router.url + '/informacion-general-peajes', this.idProyecto]);
  }
}
