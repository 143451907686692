import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { forkJoin } from "rxjs";
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { InformacionGeneralCarreteroService } from "src/app/informacion-general-carretero/informacion-general-carretero.service";
import { UnidadFuncionalCarreteroService } from "../unidad-funcional-carretero.service";

@Component({
  selector: "app-gestionar-localizacion-uf",
  templateUrl: "./gestionar-localizacion-uf.component.html",
  styleUrls: ["./gestionar-localizacion-uf.component.sass"],
})
export class GestionarLocalizacionUfComponent implements OnInit {
  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;
  esBorrador;

  ufId: number;
  ufIdUnidadFuncional: number;
  nombreUnidadFuncional: string;

  constructor(
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private unidadFuncionalService: UnidadFuncionalCarreteroService,
    private principalComponent: PrincipalComponent,
    private informacionGeneralCarreteroService: InformacionGeneralCarreteroService
  ) {}

  ngOnInit() {
    // Obtener parametros
    const idParam = "id";
    const paramUfId = "ufId";
    const paramUfIdUnidadFuncional = "ufIdUnidadFuncional";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.ufId = params[paramUfId];
      this.ufIdUnidadFuncional = params[paramUfIdUnidadFuncional];
    });

    this.inicializar();
  }

  inicializar() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.informacionGeneralCarreteroService.getObtenerConfiguracionActualProyecto(
        this.idProyecto
      )
    ).subscribe(
      ([proyectoData, proyectoData2]) => {
        this.esBorrador = proyectoData2.configuracionProyecto.esBorrador;
        this.proyectoSeleccionado = proyectoData;

        console.log(
          "consultar por idUnidadFuncional--->",
          this.ufId
        );
        console.log("consultar por esBorrador--->", this.esBorrador);

        forkJoin(
          this.unidadFuncionalService.getUnidadFuncionalPorId(
            this.ufId,
            this.esBorrador
          )
        ).subscribe(
          ([unidadFuncionalData]) => {
            console.log("unidadFuncionalData--->", unidadFuncionalData);

            this.nombreUnidadFuncional =
              unidadFuncionalData.unidadFuncional.nombre;
          },
          (error) => {
            this.principalComponent.cargarErrorServicio(error);
          }
        );
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }
}
