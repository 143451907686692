import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'reporte-trafico-recaudo',
  templateUrl: './interno-peajes-recaudo.component.html'
})
export class ReportesPeajesRecaudoComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    document.getElementById('nameseccion').innerHTML = '<span class=\'smallicon\'><img src=\'assets/img/icn/carreteras_500-01-01.svg\'/></span> Carreteras';
    const element = document.getElementById('aniscopio_body');
    element.classList.add('public');
  }

  reportes() {
    this.route.navigate(['aeropuertos-public/consulta']);
  }

}
