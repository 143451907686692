import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'puerto-home-visor-mapa',
  templateUrl: './puerto-home-visor-mapa.component.html'
})
export class PuertoHomeVisorMapaComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
  }

  reportes(){
    this.route.navigate(['/puertos-home/visor/mapa']);
  }

}
