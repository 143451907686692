import { TrnRespuestaServicio } from "../../parametricas/trn-respuesta-servicio";
import { PjeMaSentidoCobroPeaje } from "./PjeMaSentidoCobroPeaje";


export class PjeInformacionGeneralPeajeDTORpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public informacionGeneralPeajes?: PjeInformacionGeneralPeajeDTO[],
    ) { }
}
export class PjeInformacionGeneralPeajeDTO {
    constructor(
        public idPeaje?: number,
        public idProyecto?: number,
        public nombrePeaje?: string,
        public cantidadCarriles?: number,
        public numeroCasetas?: number,
        public tienePagoElectronico?: Boolean,
        public fechaInicioPagoElectronico?: Date,
        public horaFechaInicio?: number,
        public minutoFechaInicio?: number,
        public puntoA?: string,
        public puntoB?: string,
        public tipoSentido?: PjeMaSentidoCobroPeaje

    ) { }
}