import { Component, OnInit } from '@angular/core';
import { ProyectoDTO } from '../componentes-comunes/componentes-comunes.dto';
import { ComponentesComunesService } from '../componentes-comunes/componentes-comunes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CompensacionesAmbientalesService } from './compensaciones-ambientales.service';
import { AmbSubCuentaDTO } from './compensaciones-ambientales.dto';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-compensaciones-ambientales',
  templateUrl: './compensaciones-ambientales.component.html',
  styleUrls: ['./compensaciones-ambientales.component.sass'],
})
export class CompensacionesAmbientalesComponent implements OnInit {
  idProyecto: number;

  proyectoSeleccionado: ProyectoDTO;
  subCuenta: AmbSubCuentaDTO;

  constructor(
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private compensacionesAmbientalesService: CompensacionesAmbientalesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    const idParam = 'id';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      forkJoin(
        this.componentesComunesService.obtenerProyecto(this.idProyecto),
        this.compensacionesAmbientalesService.srvObtenerValorSubcuentaPorProyecto(
          this.idProyecto
        )
      ).subscribe(
        ([proyectoSeleccionadoData, subCuentaData]) => {
          this.proyectoSeleccionado = proyectoSeleccionadoData;
          this.setProyectoSeleccionado(this.proyectoSeleccionado);
          this.subCuenta = subCuentaData.valorSubCuenta;
          if (this.subCuenta == null) {
            this.subCuenta = {};
          } else {
            this.subCuenta.fechaReferencia = new Date(
              this.subCuenta.fechaReferencia
            );
          }
          this.setSubcuenta(this.subCuenta);
        }, error => {
          this.principalComponent.cargarErrorServicio(error);
        });
    });
  }

  irAObligacionesCompensacion() {
    this.router.navigate([
      this.router.url + '/obligaciones-compensacion/' + this.idProyecto,
    ]);
  }

  irASeguimientoCompensaciones() {
    this.router.navigate([
      this.router.url + '/seguimiento-compensaciones/' + this.idProyecto,
    ]);
  }

  setProyectoSeleccionado(proyectoSeleccionadoL: any) {
    this.compensacionesAmbientalesService.setProyectoSeleccionado(
      proyectoSeleccionadoL
    );
  }

  setSubcuenta(subCuentaL: any) {
    this.compensacionesAmbientalesService.setSubCuenta(subCuentaL);
  }
}
