import { Injectable, } from '@angular/core';
import { ConfigService } from '../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class YoutubeUploadFileService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
        
    private API_URL = 'https://www.googleapis.com/youtube/v3/search';
    private API_TOKEN = 'AIzaSyBsUN92ivGA20TZu9zyKbVPDcaw6fSHa2A';
    private channel = 'UC_x5XG1OV2P6uZZ5FSM9Ttw'; //Canal Rodrigo
    private maxResults = '10';


    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
      }

    /**
     * Metodo que carga la info del radicado
     */
     public getListVideosCanal(query: string) {
        const url = `${this.API_URL}?key=${this.API_TOKEN}&channelId=${this.channel}&order=date&part=snippet&type=video&maxResults=${this.maxResults}`;
        return this.http.get(url)
          .pipe(
            map((response: any) => response.items)
          );
    }
}
