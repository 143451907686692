import { TrnRespuestaServicio } from "../parametricas/trn-respuesta-servicio";
import { PjePeajePorProyecto } from "../peajes-gestion/trafico-peajes/PjePeajePorProyecto";

export interface FrrMaTipoUbicacionDTO {
    nombre?: string,
}
export interface FrrMaTipoCierreViaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaFrrMaClasificacionContingenciaCierreVia?: FrrMaTipoCierreViaDTO[]
}
export interface FrrMaTipoCierreViaDTO {
    id?: number,
    nombre?: string,
}
export interface FrrMaClasificacionContingenciaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaFrrMaClasificacionContingenciaCierreVia?: FrrMaClasificacionContingenciaCierreViaDTO[]
}

export interface FrrMaClasificacionContingenciaCierreViaDTO {
    id?: number,
    nombre?: string,
}

export interface FrrMaRutaAlternaDTO {
  id?: boolean,
  nombre?: string,
}

export interface FrrMaEtapaProyectoDTO {
  id?: number,
  nombre?: string,
}

export interface FrrMaRecaudoAfectadoDTO {
  id?: boolean,
  nombre?: string,
}


export interface FrrMaTipoAfectacionCierreViaDTO {
    id?: number,
    nombre?: string,
}
export interface FrrMaTipoAfectacionCierreViaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaFrrMaTipoAfectacionCierreVia?: FrrMaTipoAfectacionCierreViaDTO[]
}

export interface FrrTipoAfectacionViaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaFrrTipoAfectacionVia?: FrrTipoAfectacionViaDTO[],
    frrTipoAfectacionVia?: FrrTipoAfectacionViaDTO,
    id?: number,
}
export interface FrrTipoAfectacionViaDTO {
    id?: number,
    idViaAfectada?: number,
    idTipo?: number,
    tipo?: string,
    tipoAfectacionDTO?: FrrMaTipoAfectacionCierreViaDTO,
    puntoInicial?: number,
    distanciaAlPuntoInicial?: number,
    puntoFinal?: number,
    distanciaAlPuntoFinal?: number,
    longitud?: number,
    latitud?: number,
    observaciones?: string
}

export enum EnumFrrMaTipoAfectacion {
    PJE_TIPO_AFECTACION_PUENTE_PEATONAL = 1,
    PJE_TIPO_AFECTACION_PUENTE_VEHICULAR = 2,
    PJE_TIPO_AFECTACION_MURO_CONTENCION = 3,
    PJE_TIPO_AFECTACION_PEAJE = 4,
}

export interface FrrViaAfectadaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaFrrOlaInvernal?: FrrViaAfectadaDTO[],
    frrViaAfectada?: FrrViaAfectadaDTO,
    id?: number,
}
export interface FrrViaAfectadaDTO {
    id?: number,
    idMunicipio?: string,
    municipio?: string,
    idDepartamento?: string,
    departamento?: string,
    idClasificacionContingencia?: number,
    clasificacionContingencia?: string,
    clasificacionContingenciaDTO?: FrrMaClasificacionContingenciaCierreViaDTO,
    fechaReporte?: Date,
    fechaReporteSTR?: string,
    puntoInicial?: number,
    distanciaAlPuntoInicial?: number,
    puntoFinal?: number,
    distanciaAlPuntoFinal?: number,
    longitudAfectacion?: number,
    longitudAfectacionSTR?: number,
    distanciaPuntoInicialSTR?: string,
    motivoEvento?: string,
    planAccion?: string,
    tieneRutaAlterna?: boolean,
    tieneRutaAlternaStr?: string,
    tieneRutaAlternaDTO?: FrrMaRutaAlternaDTO,
    idEtapa?: number,
    etapaProyecto?: string,
    etapa?: string,
    etapaProyectoDTO?: FrrMaEtapaProyectoDTO,
    recaudoAfectado?: boolean,
    recaudoAfectadoStr?: string,
    recaudoAfectadoDTO?: FrrMaRecaudoAfectadoDTO,
    fechaCierre?: Date
    fechaEstimadaApertura?: Date
    observacionesApertura?: string,
    fechaRealApertura?: Date,
    personalOperativo?: number,
    maquinariaAmarillaDisponible?: number,
    idProyecto?: number,
    nombreProyecto?: string,
    unidadesEventosGrandesMagnitudes?: number,
    rutaImagen1?: string,
    rutaImagen2?: string,
    totalRegistros?: number,
}

export interface FrrImagenViaAfectadaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaFrrImagenViaAfectada?: FrrImagenViaAfectadaDTO[],
    frrImagenViaAfectada?: FrrImagenViaAfectadaDTO,
}

export interface FrrImagenViaAfectadaDTO {
    id?: number,
    idViaAfectada?: number,
    fechaFotografia?: Date,
    tituloFotografia?: string,
    descripcionFotografia?: string,
    ruta?: string,
}

export interface FrrViaAfectadaReporteDTO {
    fechaInicioReporte?: Date,
    fechaFinReporte?: Date,
    idProyecto?: number,
    filaDesde?: number,
    buscar?: string,
    cantidadRegistros?: number
    campoOrden?: string,
    ascDesc?: string
}

export interface FrrGeneracionRpt {
    respuestaServicio?: TrnRespuestaServicio,
    datos?: OlaInvernalDatosDTO
}

export interface OlaInvernalDatosDTO {
    archivo?: string,
    archivoUpload?: string,
    nombreArchivo?: string,
    rutaArchivo?: string,
    fechaCreacion?: string
}
