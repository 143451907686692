import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { RpteCxpBaseComponent } from "../rpte-cxp-base/rpte-cxp-base.component";
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { CtoInformeRevisionEstadoPersonaAprobacionDTO } from "src/app/contratacion/contratacion-dto/informe-revision-aprobacion";

@Component({
  selector: 'app-rpte-cxp-aprob',
  templateUrl: './rpte-cxp-aprob.component.html',
  styles: []
})
export class RpteCxpAprobComponent implements OnInit {

  estadoPersonasInformeRevision: CtoInformeRevisionEstadoPersonaAprobacionDTO[];

  constructor(
    private lineaBase: RpteCxpBaseComponent,
    private messageService: MessageService,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarPersonaEstadoInformeRevision(this.lineaBase.idInformeParam);
  }
  cargarPersonaEstadoInformeRevision(idInforme: number) {
    this.reporteCuentaCobroCxpService.srvConsultarPersonaEstadoInforme(idInforme)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.estadoPersonasInformeRevision = result.estadoPersonasAprobacionInformeRevision;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }
}