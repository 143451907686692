import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-covid-reportes-interno',
  templateUrl: './covid-reportes-interno.component.html',
  styleUrls: ['./covid-reportes-interno.component.sass']
})
export class CovidReportesInternoComponent implements OnInit {

  idProyecto: number;

  constructor(private route: Router, private ruta: ActivatedRoute) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
    });
  }

  reporteSintomas() {
    this.route.navigate([this.route.url + '/covid-sintomas-interno', this.idProyecto]);
  }

  reporteDepartamento() {
    this.route.navigate([this.route.url + '/covid-departamento-interno', this.idProyecto]);
  }

  reporteBI() {
    this.route.navigate([this.route.url + '/reporte-covid-bi-interno', this.idProyecto]);
  }

}
