import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatTableDataSource , MatPaginator} from '@angular/material';
import { TribunalesService } from './tribunales.service';
import { TAmbientales } from '../../../../dtos/tramites-ambientales/proyecto-tramite';
import { ProgressSpinnerDialogComponent } from '../../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-tribunales',
  templateUrl: './tribunales.component.html',
  styleUrls: ['./tribunales.component.sass'],
  providers: [TribunalesService]
})

export class TribunalesComponent implements OnInit {

  objectTipoCompromiso: any = [];
  objectConcesion: any = [];
  objectResponsable: any = [];
  objectEntidad: any = [];
  proyectos: any = [];
  priorizacion: any = [];
  id: number;
  nombreProyecto;
  estadoEliminar;
  displayedColumns: string[] = [ 'numeroCcb', 'contratistaConvocante', 'contratistaConvocado', 'cuantiaPesos', 'cuantiaDolares', 'acciones'];

  dataSource: MatTableDataSource<TAmbientales>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(
    private info: TribunalesService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog, private principalComponent: PrincipalComponent) {
  }

  ngOnInit() {
    this.cargarPriorizacion();
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarProyecto(this.id);
      localStorage.setItem('idP', JSON.stringify(this.id));
    });
    var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onNew() {
    this.route.navigate([this.route.url+'/infTribunalesDetalle', 'new']);
  }

  onEdit(id: number) {
    this.route.navigate([this.route.url+'/infTribunalesDetalle', id]);
  }

  onFollow(id: number) {
    this.route.navigate([this.route.url+'/infSeguimientoTribunales', id]);
  }

  getPrioridad(id) {
    if (id) {
      const dataLength = this.priorizacion.length;
      for (let i = 0; i <= dataLength; i++) {
        if (this.priorizacion[i].id === id) {
          return this.priorizacion[i].nombre;
        }
      }
    } else {
      return '';
    }
  }


  cargarProyecto(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.info.obtenerListaTribunales(id).subscribe((data: {}) => {
      this.proyectos = data;
      this.dataSource = new MatTableDataSource(this.proyectos);
      this.dataSource.paginator = this.paginator;
      this.dialogSpin.close();
    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarPriorizacion() {
    return this.info.getPriorizacion().subscribe((data: {}) => {
      this.priorizacion = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  convertStringDateToTime(data) {
    const date = new Date(data);
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = date.getTime() + localOffset;
    return utc;
}
}
