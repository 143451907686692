import { Injectable, } from '@angular/core';
import { ConfigService } from '../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ListadoRadicacionWebService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };


    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo que carga un archivo de prueba
     * @param data 
     */
    public uploadFile(data) {
        return this.http.post(this.END_POINT + '/RadicacionWeb/setFilePublic', data)
            .pipe(
                retry(1));
    }

    /**
     * metodo que consulta los tipos de soporte
     * @param modulo 
     * @param idTiposSoporte 
     */
    public getTiposSoporte(modulo, idTiposSoporte) {
        return this.http.get(this.END_POINT + '/TipoSoportes/' + modulo + '/' + idTiposSoporte)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que arga la lista de la info de la tabla vdoVideo
     */
    public getListado() {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/cargarPruebaConcepto')
            .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de radicados en estado Borrado
     */
    public getListadoBorradores(idProyecto) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/listado/documentoRadicado/' + idProyecto + "/B")
            .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de radicados en estado Radicacion
     */
    public getListadoRadicacion(idProyecto) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/listado/documentoRadicado/' + idProyecto + "/R")
            .pipe(retry(1));
    }

    /**
     * Metodo que consulta el radicado
     */
    public getConsultaRadicado(numeroRadicado) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/consulta/' + numeroRadicado)
            .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de la info de la tabla firmantes
     */
     public getListadoFirmantes(idPersonaJuridica) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/listado/firmantes/' + idPersonaJuridica)
            .pipe(retry(1));
    }

    
    /**
     * Metodo que carga la lista de la info de  datos basicos
     */
     public getDatosBasicos(idProyecto) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/datos/informacion/' + idProyecto)
            .pipe(retry(1));
    }

        /**
     * Metodo que carga la lista de la info de  datos basicos
     */
    public getDocumentoEliminar(idEliminar) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/documentoRadicado/eliminar/' + idEliminar)
            .pipe(retry(1));
    }


    


}
