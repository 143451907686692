import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, TreeNode } from 'primeng/api';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneralService } from 'src/app/informacion-general/informacion-general.service';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { PrsFuncionario } from '../../admin-personas/prs-funcionario';
import { TrnDependenciaFuncionarioAni } from '../../parametricas/trn-dependencia-ani';
import { forkJoin } from 'rxjs';
import { ComisionesService } from '../comisiones.service';
import { CmsConfiguracionPersonasFlujoAprobacionDependenciaDTO } from '../comisiones.dto';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';

@Component({
  selector: 'app-comisiones-configuracion-dependencias-responsable',
  templateUrl: './comisiones-configuracion-dependencias-responsable.component.html',
  styleUrls: ['./comisiones-configuracion-dependencias-responsable.component.sass']
})
export class ComisionesConfiguracionDependenciasResponsableComponent implements OnInit {

  blockedDocument: boolean;
  formGroupAsignarFuncionario;
  flujoAprobacionTreeNode: TreeNode[];


  visualizarFuncionarioDialog: boolean = false;

  dependenciaFuncionarioSeleccionado: TrnDependenciaFuncionarioAni = {
    dependencia: {},
    funcionarioEncargado: {},
    funcionarioTitular: {},
    vistoBueno: null
  };
  funcionarios: PrsFuncionario[];
  funcionarioSeleccionado: PrsFuncionario;
  funcionariosContratistas: PrsFuncionario[];
  funcionarioContratistasSeleccionado: PrsFuncionario;

  constructor(
    formBuilder: FormBuilder,
    private messageService: MessageService,
    private router: Router,
    private principalComponent: PrincipalComponent,
    private parametricasService: ParametricasService,
    private personaService: PersonaService,
    private comisionesService: ComisionesService
  ) {
    this.formGroupAsignarFuncionario = formBuilder.group({
      idPersonaTitular: [null, [Validators.required]],
      idPersonaEncargado: [null, [Validators.required]],
      idPersonaVistoBueno: [null, null]
    });


  }

  ngOnInit() {
    this.blockedDocument = true;
    this.cargarDependenciasAniTreeNode();
  }

  /**
   * Metodo que se encarga del llamado al servicio para listar las dependencias de la entidad agrupadas en forma de arbol para presentación
   */
  cargarDependenciasAniTreeNode() {
    this.parametricasService.srvListarDependenciasAniTreeNode().subscribe(data => {
      this.flujoAprobacionTreeNode = data.data;
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que se encarga de validar, consultar y relacionar los objetod necesarios relacionados con la dependencia seleccionada y los funcionarios
   * @param dependenciaFuncionario 
   */
  irAsignarFuncionarioDependencia(dependenciaFuncionario: any) {
    this.blockedDocument = true;

    this.dependenciaFuncionarioSeleccionado = {};
    this.dependenciaFuncionarioSeleccionado.dependencia = dependenciaFuncionario;

    if (dependenciaFuncionario.idPersonaTitular == null) {
      this.dependenciaFuncionarioSeleccionado.funcionarioTitular = {};
    }
    if (dependenciaFuncionario.idPersonaEncargado == null) {
      this.dependenciaFuncionarioSeleccionado.funcionarioEncargado = {};
    }
    if (dependenciaFuncionario.idPersonaVistoBueno == null) {
      this.dependenciaFuncionarioSeleccionado.vistoBueno = {};
    }

    if (dependenciaFuncionario.idPersonaTitular != null && dependenciaFuncionario.idPersonaEncargado != null) {
      forkJoin(
        this.personaService.getPrsFuncionarioPorId(dependenciaFuncionario.idPersonaTitular),
        this.personaService.getPrsFuncionarioPorId(dependenciaFuncionario.idPersonaEncargado)
      ).subscribe(
        ([
          titularResult,
          encargadoResult
        ]) => {
          this.dependenciaFuncionarioSeleccionado.funcionarioTitular = titularResult;
          this.dependenciaFuncionarioSeleccionado.funcionarioEncargado = encargadoResult;
          this.visualizarFuncionarioDialog = true;
        },
        (error) => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
        });
    } else {
      this.visualizarFuncionarioDialog = true;
      this.lanzarMensajeWarning("No se relaciona un Titular ni Encargado para esta dependencia, por favor asigne las personas correspondientes.");
    }

    if (dependenciaFuncionario.idPersonaVistoBueno != null) {
      this.dependenciaFuncionarioSeleccionado.vistoBueno = null;
      this.personaService.getPrsPersonaPorId(dependenciaFuncionario.idPersonaVistoBueno)
        .subscribe(data => {
          if (data != null && data.idPersona != null && data.idPersona != 0) {

            this.dependenciaFuncionarioSeleccionado.vistoBueno = data;
          }
        },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
          }
        );
    }
  }

  cargarFuncionarios(event) {
    const filtrofuncionarios = [];
    this.personaService.getPrsFuncionarios()
      .subscribe(data => {
        this.funcionarios = data;
        if (event != null) {
          let existeFiltro: boolean;
          for (const dpn of this.funcionarios) {
            if (String(dpn.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtrofuncionarios.push(dpn);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.funcionarios = filtrofuncionarios;
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarFuncionariosContartistas(event) {
    const filtrofuncionarios = [];
    this.personaService.getPrsPersonasNaturales()
      .subscribe(data => {
        this.funcionariosContratistas = data.personas;
        if (event != null) {
          let existeFiltro: boolean;
          for (const dpn of this.funcionariosContratistas) {
            if (String(dpn.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtrofuncionarios.push(dpn);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.funcionariosContratistas = filtrofuncionarios;
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cancelarAsignarFuncionarioDependencia() {
    this.blockedDocument = false;
    this.visualizarFuncionarioDialog = false;

    this.dependenciaFuncionarioSeleccionado.funcionarioTitular = {};
    this.dependenciaFuncionarioSeleccionado.funcionarioEncargado = {};
    this.dependenciaFuncionarioSeleccionado.vistoBueno = {};
  }


  validaInformacion() {
    let informacionValida = true;

    //Valida titular
    if (!this.formGroupAsignarFuncionario.valid) {
      informacionValida = false;
      this.lanzarMensajeError("Debe diligenciar los campos requeridos marcados con asterisco (*)");
    } else {

      if (this.dependenciaFuncionarioSeleccionado.dependencia == null
        || this.dependenciaFuncionarioSeleccionado.dependencia.id === undefined
        || this.dependenciaFuncionarioSeleccionado.dependencia.id === null
        || this.dependenciaFuncionarioSeleccionado.dependencia.id === 0) {
        informacionValida = false;
        this.lanzarMensajeError("Debe seleccionar la dependencia");
      }

      if (this.dependenciaFuncionarioSeleccionado.funcionarioTitular == null
        || this.dependenciaFuncionarioSeleccionado.funcionarioTitular.idPersona === undefined
        || this.dependenciaFuncionarioSeleccionado.funcionarioTitular.idPersona === null
        || this.dependenciaFuncionarioSeleccionado.funcionarioTitular.idPersona === 0) {
        informacionValida = false;
        this.lanzarMensajeError("Debe seleccionar el titular de la dependencia");
      }

      //Valida encargado
      if (this.dependenciaFuncionarioSeleccionado.funcionarioEncargado == null
        || this.dependenciaFuncionarioSeleccionado.funcionarioEncargado.idPersona === undefined
        || this.dependenciaFuncionarioSeleccionado.funcionarioEncargado.idPersona === null
        || this.dependenciaFuncionarioSeleccionado.funcionarioEncargado.idPersona === 0) {
        informacionValida = false;
        this.lanzarMensajeError("Debe seleccionar el encargado de la dependencia");
      }

      //Valida Vobo
      if (this.dependenciaFuncionarioSeleccionado.vistoBueno != null && this.dependenciaFuncionarioSeleccionado.vistoBueno
        && this.dependenciaFuncionarioSeleccionado.vistoBueno.id != null) {
        if (this.dependenciaFuncionarioSeleccionado.vistoBueno.idPersona == null
          || this.dependenciaFuncionarioSeleccionado.vistoBueno.idPersona == 0) {
          informacionValida = false;
          this.lanzarMensajeError("Debe seleccionar el visto bueno de la lista");
        }
      }
    }
    return informacionValida;
  }



  asignarFuncionarioDependencia() {
    this.visualizarFuncionarioDialog = false;
    this.blockedDocument = true;

    if (this.validaInformacion()) {

      //Se arma el objeto que recibe el servicio      
      const cmsDependenciaFuncionarioVoBoComisionDTO = {
        idDependencia: this.dependenciaFuncionarioSeleccionado.dependencia.id,
        idPersonaEncargado: this.dependenciaFuncionarioSeleccionado.funcionarioEncargado.idPersona,
        idPersonaTitular: this.dependenciaFuncionarioSeleccionado.funcionarioTitular.idPersona,
        idPersonaVoBo: null
      };

      if (this.dependenciaFuncionarioSeleccionado.vistoBueno != null
        && this.dependenciaFuncionarioSeleccionado.vistoBueno.idPersona != null && this.dependenciaFuncionarioSeleccionado.vistoBueno.idPersona != 0) {
        cmsDependenciaFuncionarioVoBoComisionDTO.idPersonaVoBo = this.dependenciaFuncionarioSeleccionado.vistoBueno.idPersona;
      }

      // (cmsDependenciaFuncionarioVoBoComisionDTO);

      forkJoin(
        this.comisionesService.srvGuardarAsignacionDependencia(cmsDependenciaFuncionarioVoBoComisionDTO)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.cargarDependenciasAniTreeNode();

              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error('Ocurrió un error al consumir el servicio srvGuardarAsignacionDependencia');
          }
        }, error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
          this.visualizarFuncionarioDialog = false;
        }
      );
    }
    // } else {
    //   this.lanzarMensajeWarning("Debe diligenciar los campos requeridos marcados con asterisco (*)");
    // }


  }

  bntVolver() {
    const ruta = "/comisiones";
    this.router.navigate([ruta]);
  }


  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }


}
