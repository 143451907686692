export enum EnumPjeConstantes {
    PJE_ESTADO_REPORTE_TRAFICO_SIN_INICIAR = 1,
    PJE_ESTADO_REPORTE_TRAFICO_EN_BORRADOR = 2,
    PJE_ESTADO_REPORTE_TRAFICO_PUBLICADO = 3,
    PJE_ESTADO_REPORTE_TRAFICO_ANULADO = 4,
    PJE_ESTADO_REPORTE_TRAFICO_SIAC_7 = 5,
    PJE_ESTADO_REPORTE_TRAFICO_ENVIADO_A_REVISION = 6,

    PJE_ESTADO_PEAJE_FUNCIONAMIENTO = 1,
    PJE_ESTADO_PEAJE_POR_INSTALAR = 2,
    PJE_ESTADO_PEAJE_SUSPENDIDO = 3,
    PJE_ESTADO_PEAJE_SUSPENDIDO_CONTEO = 4,

    PJE_TIPO_TARIFA_PLENA = 1,
    PJE_TIPO_TARIFA_ESPECIAL = 2,

    PJE_CATEGORIA_VEHICULO_EJE_ADICIONAL = 14,
    PJE_CATEGORIA_VEHICULO_EJE_GRUA = 15,
    PJE_CATEGORIA_VEHICULO_EJE_REMOLQUE = 16,
    PJE_CATEGORIA_VEHICULO_EJE_CANERO = 17,
}