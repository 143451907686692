import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { InformacionGeneral, InformacionGeneralEnvio } from './informacionGeneral';

@Injectable({
    providedIn: 'root'
})

export class PolizaEditService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

   /**
   * Metodo que carga la lista de contratos por proyecto
   * @param id 
   */
    public getContratosPorProyecto(idProyecto) {
        return this.http.get(this.END_POINT + '/CrrInformacionContractual/contratosPorProyecto/' + idProyecto ) 
        .pipe(
            retry(1));
    } 

    /**
     * Metodo que carga la informacion
     * @param idPoliza 
     */
    public obtenerInformacionGeneral(idPoliza): Observable<InformacionGeneral>  {
        return this.http.get<InformacionGeneral>(this.END_POINT + '/PlzPoliza/ConsultarPoliza/' + idPoliza)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que guarda la informacion de la poliza    
     * @param informacionGeneralDTO 
     */
    public guardarInformacionGeneral(informacionGeneralDTO: InformacionGeneralEnvio){
        return this.http.post<ConsultaInformacionGeneralRpt>(this.END_POINT + '/PlzPoliza/GuardarPoliza', informacionGeneralDTO)
        .pipe(
            map(data => {
                return data;
            }),
            retry(1));
    }

    /**
     * Metodo que consulta los tipos de amparos
     * @param idPoliza 
     */
    public getPlzMaTipoAmparo(idPoliza) {
        let service = "";
        if(idPoliza== null){
            service = '/PlzMaTipoAmparo/ConsultarTiposAmparoParaAgregar';
        }else{
            service = '/PlzMaTipoAmparo/ConsultarTiposAmparoParaAgregar?idPoliza='+idPoliza;
        }
        return this.http.get(this.END_POINT + service)
            .pipe(
                retry(1));
    }
}

export class ConsultaInformacionGeneralProyectoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public informacionGeneralProyecto?: InformacionGeneral[]
    ) { }
}



export class ConsultaInformacionGeneralRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}