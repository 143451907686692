import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ComponentesComunesService } from '../componentes-comunes.service';
import { VwCrrAvanceUfDTO } from '../componentes-comunes.dto';
import { EjecucionProyectoService } from 'src/app/avance-proyecto/ejecucion_proyecto.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-seleccionar-unidadfuncional',
  templateUrl: './seleccionar-unidadfuncional.component.html',
  styleUrls: ['./seleccionar-unidadfuncional.component.sass']
})
export class SeleccionarUnidadfuncionalComponent implements OnInit {

  blockedDocument: boolean = false;

  public vwCrrAvanceUfDTOList: VwCrrAvanceUfDTO[];
  public vwCrrAvanceUfDTOSeleccionado: VwCrrAvanceUfDTO;

  public vwCrrAvanceUfConEtapasDTOList: VwCrrAvanceUfDTO[];
  public vwCrrAvanceUfConEtapasConstruccionDTOList: VwCrrAvanceUfDTO[];

  ETAPA_CONSTRUCCION: number = 3;
  ETAPA_OPERACION_Y_MANTENIMIENTO: number = 4;


  // Usamos el decorador Output
  @Output() OutputUnidadFuncionalSeleccionado = new EventEmitter();
  @Output() OutputYaCargoComponente = new EventEmitter();
  @Output() OutputYaCargoEtapaConstruccion = new EventEmitter();
  @Output() OutputPorcentajeAvance = new EventEmitter();

  @Input() idProyecto: number;
  @Input() idEjecucion: number;

  @Input() renderedVerDetalles: boolean;
  @Input() labelVerDetalles: string;
  @Input() iconoVerDetalles: string;

  @Input() soloAvancePublicado: boolean;

  @Input() verEtapas: boolean;
  @Input() idEtapa: number;

  @Input() porcentajeMinimo: number;

  constructor(private route: Router, private ruta: ActivatedRoute,
    private principalComponent: PrincipalComponent,
    private ejecucionProyectoService: EjecucionProyectoService) { }

  ngOnInit() {
    //Bloquear pantalla
    this.blockedDocument = true;


    if (this.verEtapas) {
      this.avanceUfPublicadoConEtapasPorEjecucion();
      this.avanceUfPublicadoConEtapasPorEjecucionConstruccion();
      this.avanceUnidadFuncionalPublicadoPorEjecucion();
    } else {
      if (this.idEjecucion != null) {
        if (this.soloAvancePublicado) {
          this.avanceUnidadFuncionalPublicadoPorEjecucion();
        } else {
          this.avanceUnidadFuncionalPorEjecucion();
        }
      } else {
        this.avanceUnidadFuncionalPorProyecto();
      }
    }


  }

  avanceUnidadFuncionalPorProyecto() {
    return this.ejecucionProyectoService.srvAvanceUfPorProyecto(this.idProyecto).subscribe(data => {
      this.vwCrrAvanceUfDTOList = data;
      //Desbloquear pantalla
      this.blockedDocument = false;
      this.OutputYaCargoComponente.emit("");
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  avanceUnidadFuncionalPorEjecucion() {
    return this.ejecucionProyectoService.srvAvanceUfPorEjecucion(this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceUfDTOList = data;

      //Calcular avance en memoria
      let porcentajeAvance = 0;
      for (let index = 0; index < this.vwCrrAvanceUfDTOList.length; index++) {
        let avanceVar = this.vwCrrAvanceUfDTOList[index];
        porcentajeAvance = porcentajeAvance + (avanceVar.porcentajeAvance * avanceVar.factorPonderacion)
      }

      this.OutputPorcentajeAvance.emit(porcentajeAvance);
      //Desbloquear pantalla
      this.blockedDocument = false;
      this.OutputYaCargoComponente.emit("");
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  avanceUnidadFuncionalPublicadoPorEjecucion() {
    return this.ejecucionProyectoService.srvAvanceUfPublicadoPorEjecucion(this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceUfDTOList = data;

      //Desbloquear pantalla
      this.blockedDocument = false;
      this.OutputYaCargoComponente.emit("");
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  avanceUfPublicadoConEtapasPorEjecucion() {
    return this.ejecucionProyectoService.srvAvanceUfPublicadoConEtapasPorEjecucion(this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceUfConEtapasDTOList = data;
      this.validarEtapa();
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  avanceUfPublicadoConEtapasPorEjecucionConstruccion() {
    return this.ejecucionProyectoService.srvAvanceUfPublicadoConEtapasPorEjecucionYEtapa(this.idEjecucion, 3).subscribe(data => {
      this.vwCrrAvanceUfConEtapasConstruccionDTOList = data;
      this.validarEtapaConstruccion();
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  /**etapaConstruccionUnidadesFuncionales() {
    return this.ejecucionProyectoService.srvEtapaConstruccionUnidadesFuncionales(this.idProyecto, this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceUfDTOList = data;
      this.validarEtapa();
    });
  }

  etapaOperYMantUnidadesFuncionales() {
    return this.ejecucionProyectoService.srvEtapaOperYMantUnidadesFuncionales(this.idProyecto, this.idEjecucion).subscribe(data => {
      this.vwCrrAvanceUfDTOList = data;
      this.validarEtapa();
    });
  }**/

  private validarEtapa() {
    let todasAl100 = true;
    let todasConFechaFin = true;
    for (let index = 0; index < this.vwCrrAvanceUfConEtapasDTOList.length; index++) {
      let avanceVar = this.vwCrrAvanceUfConEtapasDTOList[index];
      if (avanceVar.porcentajeAvance * 100 < this.porcentajeMinimo) {
        todasAl100 = false;
      }
      if (avanceVar.idEtapa == null) {
        todasConFechaFin = false;
      } else {
        if (avanceVar.fechaFinEtapa == null) {
          todasConFechaFin = false;
        }
      }
    }


    let todasAl100YConFechaFin = todasAl100 && todasConFechaFin;

    //Desbloquear pantalla
    this.blockedDocument = false;
    this.OutputYaCargoComponente.emit(todasAl100YConFechaFin);
  }

  private validarEtapaConstruccion() {
    let todasAl100 = true;
    let todasConFechaFin = true;
    for (let index = 0; index < this.vwCrrAvanceUfConEtapasConstruccionDTOList.length; index++) {
      let avanceVar = this.vwCrrAvanceUfConEtapasConstruccionDTOList[index];
      if (avanceVar.porcentajeAvance * 100 < this.porcentajeMinimo) {
        todasAl100 = false;
      }
      if (avanceVar.idEtapa == null) {
        todasConFechaFin = false;
      } else {
        if (avanceVar.fechaFinEtapa == null) {
          todasConFechaFin = false;
        }
      }
    }


    let todasAl100YConFechaFin = todasAl100 && todasConFechaFin;

    //Desbloquear pantalla
    this.blockedDocument = false;
    this.OutputYaCargoEtapaConstruccion.emit(todasAl100YConFechaFin);
  }

  rowSelectUnidadFuncional(_unidadFuncionalSeleccionado: VwCrrAvanceUfDTO) {
    this.vwCrrAvanceUfDTOSeleccionado = _unidadFuncionalSeleccionado;
    //Emitir la unidadFuncionaSeleccionado afuera del componente
    this.OutputUnidadFuncionalSeleccionado.emit(this.vwCrrAvanceUfDTOSeleccionado);
  }

}
