import { TrnRespuestaServicio } from "../../../parametricas/trn-respuesta-servicio";


export class PjeDocumentosRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public documentos?: PjeDocumentos[],
    ) { }
}

export class PjeDocumentos {
    constructor(
        public idDocumentoTarifa?: number,
        public idDocumento?: number,
        public idEncabezado?: number,
        public numeroDocumento?: string,
        public fechaExpedicion?: Date,
        public rutaDocumento?: string,
        public tipoDocumento?: string,
        public idTipoDocumento?: number
    ) { }
}