import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import {
  CmsDatosAdicionalesContratoDTO,
  CmsProyectosContratistaDTO,
} from "../comisiones.dto";
import { InformacionGeneralService } from "src/app/informacion-general/informacion-general.service";
import { ProyectoCarretero } from "src/app/informacion-general/proyecto-carretero";
import { ComisionesService } from "../comisiones.service";

@Component({
  selector: 'app-comisiones-configuracion',
  templateUrl: './comisiones-configuracion.component.html',
  styleUrls: ['./comisiones-configuracion.component.sass']
})
export class ComisionesConfiguracionComponent implements OnInit {
  blockedDocument: boolean;
  formGroupClausuladoContratista;
  formProyecto;

  clausulaContratista: CmsDatosAdicionalesContratoDTO = {};
  proyectosContratista: CmsProyectosContratistaDTO[] = [];
  proyectosAniscopio: any;

  visualizarProyectoDialog = false;
  visualizarEliminarProyectoDialog = false;

  filtroProyectos: any[];
  proyectos: any[];
  proyectoSeleccionado: any;



  constructor(
    formBuilder: FormBuilder,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService
  ) {
    this.formGroupClausuladoContratista = formBuilder.group({
      clausulaViajesDesplazamiento: [null, [Validators.required]],
      linkSecopClausula: [null, [Validators.required]],
    });

    // Crear formulario Proyecto
    this.formProyecto = formBuilder.group({
      autoCompletarProyecto: [null, [Validators.required]],
    });

  }

  ngOnInit() {
    this.blockedDocument = true;
    this.obtenerProyectosContratistaConfiguracion()
    this.obtenerDatosAdicionalesContrato();
  }

  /**
   * Consulta los datos adicionales del contrato del usuario logueado
   */
  obtenerDatosAdicionalesContrato() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvObtenerDatosAdicionalesContrato()
    ).subscribe(
      ([datosAdicionalesContratoData]) => {
        if (datosAdicionalesContratoData.datosAdicionalesContratoDTO != null) {
          this.clausulaContratista.clausulaComisiones = datosAdicionalesContratoData.datosAdicionalesContratoDTO.clausulaComisiones;
          this.clausulaContratista.linkSecopComisiones = datosAdicionalesContratoData.datosAdicionalesContratoDTO.linkSecopComisiones;
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irGuardarClausulaViajesDesplazamiento() {
    // (this.formGroupClausuladoContratista);
    if (this.formGroupClausuladoContratista.valid) {
      this.submitGuardarClausulaViajesDesplazamiento();
    } else {
      this.lanzarMensajeWarning(
        "La información ingresada no es valida. Por favor revisar el formulario nuevamente."
      );
    }
  }

  submitGuardarClausulaViajesDesplazamiento() {
    if (this.formGroupClausuladoContratista.valid) {
      this.guardarDatosAdicionalesContrato();
    } else {
      this.lanzarMensajeWarning(
        "La información ingresada no es valida. Por favor revisar el formulario nuevamente."
      );
    }
  }

  /**
   * Consulta los datos adicionales del contrato del usuario logueado
   */
  guardarDatosAdicionalesContrato() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvGuardarDatosAdicionalesContrato(this.clausulaContratista)
    ).subscribe(
      () => {
        this.lanzarMensajeInfo("El registro se guardó exitosamente.");
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irAgregarProyecto() {
    this.proyectoSeleccionado = null;
    this.visualizarProyectoDialog = true;
  }

  obtenerProyectosContratistaConfiguracion() {
    forkJoin(this.comisionesService.srvListarProyectosContratistaConfiguracion()).subscribe(
      ([proyectosData]) => {
        this.proyectosContratista = proyectosData.proyectosLista;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  cargarProyectos(event) {
    this.filtroProyectos = [];
    return this.comisionesService.srvListarProyectos(1).subscribe((data: any) => {

      this.proyectos = data.proyectosLista;
      if (event != null) {
        let existeFiltro: boolean;
        for (let i = 0; i < this.proyectos.length; i++) {
          const filtro = this.proyectos[i];
          if (String(filtro.nombreProyecto).toLowerCase().includes(event.query.toLowerCase())) {
            this.filtroProyectos.push(filtro);
            existeFiltro = true;
          }
        }
        if (existeFiltro) {
          this.proyectos = this.filtroProyectos;
        }
      }
    });
  }





  guardarProyecto() {
    if (this.formProyecto.valid) {
      let yaExiste = false;
      if (this.proyectosContratista === null) {
        this.proyectosContratista = []
      }
      this.proyectosContratista.forEach(proyectoVar => {
        if (proyectoVar.id === this.proyectoSeleccionado.id) {
          yaExiste = true;
        }
      });
      if (!yaExiste) {
        forkJoin(
          this.comisionesService.srvGuardarProyectoContrato(this.proyectoSeleccionado)
        ).subscribe(
          ([result]) => {
            if (result != null && result.respuestaServicio != null) {
              //  Si el resultado es exitoso...
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
                // Cerrar el dialogo
                this.visualizarProyectoDialog = false;
              } else {
                this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              }
            } else {
              console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            }
          }, error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            //  Consultar nuevamente la lista
            this.obtenerProyectosContratistaConfiguracion();
            //  Desbloquear pantalla
            this.blockedDocument = false;
          }
        );
      } else {
        this.lanzarMensajeWarning(
          "El proyecto seleccionado ya fue agregado"
        );
      }
    } else {
      this.lanzarMensajeWarning(
        "Debe seleccionar un proyecto"
      );
    }
  }

  irEliminarProyecto(proyecto: ProyectoCarretero) {
    this.blockedDocument = true;
    this.visualizarEliminarProyectoDialog = true;
    this.proyectoSeleccionado = proyecto;
  }

  aceptarEliminarProyecto() {
    this.blockedDocument = false;
    forkJoin(
      this.comisionesService.srvEliminarProyectoContrato(this.proyectoSeleccionado.id)
    ).subscribe(
      ([result]) => {

        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            // Cerrar el dialogo
            this.visualizarEliminarProyectoDialog = false;
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //  Consultar nuevamente la lista           
        this.obtenerProyectosContratistaConfiguracion();
        //  Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }



  // // // // /Mensajes// // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
