import { Component, OnInit, Input } from '@angular/core';
import { UnidadFuncional } from '../unidad-funcional-carretero/unidadFuncional';
import { ContratoCredito } from '../contrato-credito-carretero/contratoCredito';
import { Contrato } from '../concesionario-carretero/contrato';
import { Contratista } from '../concesionario-carretero/contratista';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Accionista } from '../concesionario-carretero/accionista';
import { IntervencionUnidadFuncional } from '../unidad-funcional-carretero/intervencionUnidadFuncional';
import { AccionistaContratistaEncabezado } from '../concesionario-carretero/accionista-contratista-encabezado';
import { ProyectoCarretero } from '../informacion-proyecto-carretero/proyectoCarretero';
import { EtapaProyectoCarretero } from '../etapa-proyecto-carretero/etapaProyectoCarretero';
import { ValidacionInformacionProyectoService } from '../informacion-general-carretero/validacion-informacion-proyecto/validacion-informacion-proyecto.service';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';
import { CrrDatosConfiguracionProyecto } from '../informacion-proyecto-carretero/crrDatosConfiguracionProyecto';

@Component({
  selector: 'app-proyecto-linea-base',
  templateUrl: './proyecto-linea-base.component.html',
  styleUrls: ['./proyecto-linea-base.component.sass']
})
export class ProyectoLineaBaseComponent implements OnInit {

  habilitaTabConcesionario: boolean;
  habilitaTabInterventoria: boolean;
  habilitaTabUnidadFuncional: boolean;
  habilitaTabContratoCredito: boolean;
  seleccionarTabProyecto: boolean;
  seleccionarTabConcesionario: boolean;
  seleccionarTabInterventoria: boolean;
  seleccionarTabUnidadFuncional: boolean;
  seleccionarTabContratoCredito: boolean;
  seleccionarTabEtapasProyecto: boolean;
  idProyecto: any;
  nombreProyecto: any;
  idContratoConcesionario: any;
  idContratoInterventoria: any;
  interventoriaVigente: any;
  idUnidadFuncional: any;
  idContratoCredito: any;
  visibleListaUnidadFuncional: boolean;
  visibleUnidadFuncionalDettalle: boolean;
  visibleTiposIntervencion: boolean;
  visibleTiposIntervencionDetalle: boolean;
  visibleListaContratos: boolean;
  unidadFuncional: UnidadFuncional = {};
  departamentoSeleccionadoOrigen: any;
  departamentoSeleccionadoDestino: any;
  ciudadSeleccionadaOrigen: any;
  ciudadSeleccionadaDestino: any;
  contratoCredito: ContratoCredito;
  idIntervencion: any;
  intervencion: IntervencionUnidadFuncional = {};
  tipoIntervencionSeleccionado: any;
  idSubtramo: any;
  contrato: Contrato = {};
  interventoria: Contratista = {};
  habilitaBotonCrearInterventoria: boolean = false;
  accionistas: any = [];
  idInterventoria: any = [];
  nombreInterventoria: any;
  existeInterventoria: boolean = false;
  departamentoSeleccionado: any;
  ciudadSeleccionada: any;
  dataSourceAccionistas: MatTableDataSource<Accionista>;
  dataSourceContratos: MatTableDataSource<Contrato>;
  visibleListaAccionistas: boolean;
  idEncabezado: any;
  visibleFormularioContrato: boolean = true;
  idConcesionario: any;
  dataSourceComposicionAccionaria: MatTableDataSource<AccionistaContratistaEncabezado>;
  composicionAccionariaLista: any = [];
  visibleListaAccionistasInterventoria: boolean = false;
  tieneContrato: string;
  habilitaEliminarContratoCredito: boolean;
  etapasProyecto: EtapaProyectoCarretero;
  uspCrrValidaInformacionProyectoDtoList: any = [];
  displayedColumns: any[];
  proyectoValido: boolean = false;
  crrDatosConfiguracionProyecto: CrrDatosConfiguracionProyecto = {};
  proyectoPublicado: boolean = false;
  tieneCapexOpexPorUnidadFuncional: boolean = false;
  generacionSeleccionada: any;







  constructor(private validacionInformacionProyectoService: ValidacionInformacionProyectoService, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.habilitaTabConcesionario = false;
    this.habilitaTabInterventoria = false;
    this.habilitaTabUnidadFuncional = false;
    this.habilitaTabContratoCredito = false;
    this.seleccionarTabProyecto = true;
    this.seleccionarTabConcesionario = false;
    this.seleccionarTabInterventoria = false;
    this.seleccionarTabUnidadFuncional = false;
    this.seleccionarTabContratoCredito = false;
    this.seleccionarTabEtapasProyecto = false;
    this.visibleListaUnidadFuncional = true;
    this.visibleListaContratos = true;
  }

  onTabChange(event) {
    let tab: number = event.index;
    if (tab == 5) {
      if (this.idProyecto != "new") {
        this.validacionInformacionProyectoService.getValidacionInformacionProyecto(this.idProyecto).subscribe((data: {}) => {
          this.uspCrrValidaInformacionProyectoDtoList = data;
          if (this.uspCrrValidaInformacionProyectoDtoList.length == 0) {
            this.proyectoValido = true;
          }
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });          
        });
      }
    }

  }
}

