import { Component } from '@angular/core';

@Component({
  selector: 'app-proyectos-social',
  templateUrl: './proyectos-social.component.html',
  styleUrls: ['./proyectos-social.component.sass']
})
export class ProyectosSocialComponent {

}
