import { Component, OnInit, OnDestroy, } from '@angular/core';
import { PqrsConsultaService, } from './pqrs-consulta.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneral } from './informacionGeneral';

@Component({
    selector: 's-pqrs-consulta',
    templateUrl: './pqrs-consulta.component.html',
    styleUrls: ['./pqrs-consulta.component.css']
})
export class PqrsConsultaComponent implements OnInit {

    loading: boolean;
    respuestaRecaptcha: any = null;
    mostrarCabecera: boolean = true;
    mostrarResultado: boolean = true;
    mostrarResultadoTabla: boolean = true;
    visibleDialogoError: boolean = false;
    visibleDialogoVerAdjuntosAnexos: boolean = false;
    errorSolicitud : any = null;
    numeroRadicado: any = null;
    listadoTemp: any = null;
    informacionGeneral: InformacionGeneral = {};
    listadoRespuestas: any = [];
    archivos: any = [];
    respuestas: any = { anexo: null, codigo: null, descripcion: null, respuesta: null};

    displayedRespuestas = [
        { field: 'respuesta', header: 'No. Respuesta' }
    ];

    /**
     * Constructor
     */
    constructor(private _service: PqrsConsultaService,
        private route: Router,
        public location: Location,
        private messageService: MessageService,
        private principalComponent: PrincipalComponent) { }

    /**
     * Inicializador
     */
    ngOnInit() {
        this.loading = true;
        this.numeroRadicado = null;
        this.mostrarCabecera = true;
        this.mostrarResultado = false;
        this.mostrarResultadoTabla = false;
        this.loading = false;
        this.errorSolicitud = "";
    }

    /**
     * Buscador de radicado
     */
    onFind(){
        this.loading = true;
        this.informacionGeneral = new InformacionGeneral();

        if(this.numeroRadicado != null && this.numeroRadicado.trim() != ""){
            this._service.getConsultaRadicado(this.numeroRadicado).subscribe((data: {}) => {
               this.listadoTemp = data;
               if(this.listadoTemp != undefined
                    && this.listadoTemp != null
                    && this.listadoTemp.consulta != undefined
                    && this.listadoTemp.consulta != null){

                    if(this.listadoTemp.consulta.numeroRadicado!= ""){
                        this.numeroRadicado = null;
                        this.mostrarCabecera = false;
                        if(this.listadoTemp.consulta.respuesta!= null){
                            this.mostrarResultadoTabla = true;
                            this.respuestas.respuesta = this.listadoTemp.consulta.respuesta;
                            this.respuestas.codigo = this.listadoTemp.consulta.codigo;
                            this.respuestas.descripcion = this.listadoTemp.consulta.descripcion;
                            this.respuestas.anexo = this.listadoTemp.consulta.anexo;
                            this.listadoRespuestas.push( this.respuestas);
                        }else{
                            this.mostrarResultadoTabla = false;
                        }
                        this.mostrarResultado = true;
                        this.visibleDialogoError = false;
                        this.informacionGeneral.asunto = this.listadoTemp.consulta.asunto;
                        this.informacionGeneral.dependencia = this.listadoTemp.consulta.dependencia;
                        this.informacionGeneral.email = this.listadoTemp.consulta.email;
                        this.informacionGeneral.idSolicitud = this.listadoTemp.consulta.id;
                        this.informacionGeneral.tieneEncuestaSatisfaccion = this.listadoTemp.consulta.tieneEncuestaSatisfaccion;

                        if(this.listadoTemp.consulta.estadoActual != undefined
                            && this.listadoTemp.consulta.estadoActual != null
                            && this.listadoTemp.consulta.estadoActual != "" ){
                                this.informacionGeneral.estadoActual = this.listadoTemp.consulta.estadoActual;
                            }else{
                                this.informacionGeneral.estadoActual = "EN TRAMITE";
                            }
                        this.informacionGeneral.numeroRadicado = this.listadoTemp.consulta.numeroRadicado;
                        this.informacionGeneral.remitente = this.listadoTemp.consulta.remitente;
                    }else{
                        this.visibleDialogoError = true;
                        this.numeroRadicado = null;
                        this.mostrarCabecera = true;
                        this.mostrarResultado = false;
                    }
                }else{
                    this.visibleDialogoError = true;
                    this.numeroRadicado = null;
                    this.mostrarCabecera = true;
                    this.mostrarResultado = false;
                }
                this.loading = false;
            },error => {
                this.errorSolicitud = error.error;
                this.loading = false;
                this.numeroRadicado = null;
                this.mostrarCabecera = true;
                this.mostrarResultado = false;
                this.visibleDialogoError = true;
                //this.principalComponent.cargarErrorServicio(error);
              });
        }

    }

    /**
     * metoo que realiza la validacion de la longitud
     */
    validarCampoRadicado(radicado :any){
        if(radicado!= undefined && radicado!= null && radicado!= ""){
            if(radicado.length < 14){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }

    }

    /**
     * Metodo que valida el campo
     *
     * @returns
     * @memberof PqrsConsultaComponent
     */
    validarCampo(){
        if(this.numeroRadicado!= undefined &&
            this.numeroRadicado!= null &&
            this.numeroRadicado!= ""  &&
            this.respuestaRecaptcha){
                return false;
        }else{
                return true;
        }
    }

    /**
     * Metodo volver a la pantalla principal de la busqueda
     */
    volver(){
        this.loading = true;
        this.numeroRadicado = null;
        this.mostrarCabecera = true;
        this.mostrarResultado = false;
        this.mostrarResultadoTabla = false;
        this.visibleDialogoError = false;
        this.respuestas = { anexo: null, codigo: null, descripcion: null, respuesta: null};
        this.listadoRespuestas = [];
        this.archivos = [];
        this.visibleDialogoVerAdjuntosAnexos = false;
        this.loading = false;
        this.respuestaRecaptcha = false;
    }

    /**
     * Metodo para ir a la pantalla principal de la encuesta de satisfaccion
     */
    encuestaSatisfaccion(){
      localStorage.setItem("pqrs-encuesta-satisfaccion", JSON.stringify(this.informacionGeneral))
      this.route.navigate(["pqrs-consulta-public/pqrs-encuesta-satisfaccion"]);
    }

    /**
     * Metodo que limpia
     */
    limpiar(){
        this.loading = true;
        this.numeroRadicado = null;
        this.mostrarCabecera = true;
        this.mostrarResultado = false;
        this.visibleDialogoError = false;
        this.archivos = [];
        this.loading = false;
        this.respuestaRecaptcha = false;
    }

    /**
     * Metodo que ceierra el modal
     */
    aceptarDialogoError(){
        this.visibleDialogoError = false;
    }

    /**
     * Metodo que realiza el descargue del archivo.
     *
     * @memberof PqrsConsultaComponent
     */
    onRespuesta(objeto){
        this.loading = true;
        if(objeto.anexo!= null){
            this.onDownload(objeto);
        }else{
            this.loading = false;
        }
    }


    /**
    * Metodo que descarga el archivo
    *
    * @param {*} data
    * @memberof PqrsConsultaComponent
    */
    onDownload(data) {
        var a = document.createElement("a");
        var fileName = data.respuesta;
        var base64str = data.anexo;
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        var blob = new Blob( [view], { type: "application/pdf" });
        var url = URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        this.loading = false;
        a.click();
    }

    /**
     * Metodo que habilita los anexos
     *
     * @memberof PqrsConsultaComponent
     */
    onAnexos(){
        this.visibleDialogoVerAdjuntosAnexos = true;
    }

    /**
     * Metodo que cierra el modal de los adjuntos
     *
     * @memberof PqrsConsultaComponent
     */
    cancelarDialogoVerAdjunto(){
        this.visibleDialogoVerAdjuntosAnexos = false;
    }

        /**
     * Metodo que captura la seleccion de captcha
     *
     * @param {string} captchaResponse
     * @memberof PqrsRadicacionComponent
     */
    public resolved(captchaResponse: string) {
        this.respuestaRecaptcha = captchaResponse;
    }

    /**
	 * Metodo que captura el evento del teclado solo numeros 0-9
	 * @param evento
	 */
	soloNumeros(evento){
		if(evento.code == "Backspace"
		|| evento.code == "Enter"
		|| evento.code == "Home"
		|| evento.code == "End"
		|| evento.code == "Slash"
		|| evento.code == "Dash"
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"
        || evento.ctrlKey == true){
                return true;
        }

        if(evento.ctrlKey == true && evento.keyCode == 67){
        return true;
        }
        if(evento.ctrlKey == true && evento.keyCode == 86){
        return true;
        }
        if (evento.keyCode >= 48 &&  evento.keyCode <= 57 ||
            evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
            return true;
        }else{
            return false;
        }
    }


}
