import { Injectable } from "@angular/core";
import { ConfigService } from "../config.service";
import { Observable, throwError } from "rxjs";
import { map, retry, catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";

import { TrnRespuestaServicio } from "../parametricas/trn-respuesta-servicio";
import { TrnRespuestaServicioRpt } from '../parametricas/rpt-respuesta-operacion';
import {
  // ConsultaIngReservaDiasPermitidosRpt,
  IngBienPersonalDTO,
  IngCuposPisoDTO,
  IngFranjaHorariaDTO,
  IngIngresoReservaDTO,
  IngReservaPuestoTrabajoDTO,
} from "./puestos-trabajo.dto";
import { VwInvBienAniYpersonalRpt } from "../inventario-equipos/inventario-equipos.dto";

@Injectable({
  providedIn: "root",
})
export class PuestosTrabajoService {
  private mensajeCompartidoSource = new BehaviorSubject("default message");
  mensajeCompartido = this.mensajeCompartidoSource.asObservable();

  public END_POINT = "";

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  intercambiarMensaje(mensaje: string) {
    this.mensajeCompartidoSource.next(mensaje);
  }

  /**
     * Obtener listado de reservas de puestos de trabajo por fecha
     */
  public srvConsultarReservasPuestoTrabajo(): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        "/IngReservaPuestosTrabajo/ConsultarReservasPuestoTrabajo"
      )
      .pipe(retry(1));
  }

  /**
   * Obtener listado de reservas de puestos de trabajo por fecha
   */
  public srvConsultarReservasPuestoTrabajoPorFecha(fecha: string): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        "/IngReservaPuestosTrabajo/ConsultarReservasPuestoTrabajo?fechaReserva=" + fecha
      )
      .pipe(retry(1));
  }

  /**
  * Obtener listado de reservas de puestos de trabajo numero de documento y fecha
  */
  public srvConsultarReservasPuestoTrabajoPorNumeroYFecha(numeroDocumento: string, fecha: string): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        "/IngReservaPuestosTrabajo/ConsultarReservasPuestoTrabajoFiltros?fechaReserva=" + fecha + "&numeroDocumento=" + numeroDocumento
      )
      .pipe(retry(1));
  }

  /**
   * Consulta la reserva del puesto de trabajo por Id
   */
  public srvConsultarReservaPuestoTrabajoPorId(idReservaPuestoTrabajo: number): Observable<any> {
    return this.http.get(this.END_POINT + "/IngReservaPuestosTrabajo/ConsultarReservaPuestoTrabajoPorId?idReservaPuestoTrabajo=" + idReservaPuestoTrabajo)
      .pipe(retry(1));
  }

  /**
   * Consulta la reserva del puesto de trabajo por numero documento
   */
  public srvConsultarReservaPuestoTrabajoPorNumeroDocumento(numeroDocumento: string): Observable<any> {
    return this.http.get(this.END_POINT + "/IngReservaPuestosTrabajo/ConsultarReservaPuestoTrabajoPorId?numeroDocumento=" + numeroDocumento)
      .pipe(retry(1));
  }

  /**
     * Consulta los puestos de trabajo disponibles para reserva fecha y franja
     */
  public srvConsultarPuestosTrabajoDisponiblesPorFranja(fecha: string, franjaHoraria: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/IngReservaPuestosTrabajo/ConsultarPuestosTrabajoDisponibles/?fecha=" + fecha + "&idFranjaHoraria=" + franjaHoraria
      )
      .pipe(retry(1));
  }

  /**
   * Consulta los puestos de trabajo disponibles para reserva por piso
   */
  public srvConsultarPuestosTrabajoDisponiblesPorPiso(idPiso: number, fecha: string, franjaHoraria: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/IngReservaPuestosTrabajo/ConsultarPuestosTrabajoDisponibles?idPiso=" + idPiso + "&fecha=" + fecha + "&idFranjaHoraria=" + franjaHoraria
      )
      .pipe(retry(1));
  }

  /**
   * Consultar los Bienes relacionados a una reserva por IdReservaPuestoTrabajo
   */
  public srvConsultarBienesReserva(idReservaPuestoTrabajo: number): Observable<any> {
    return this.http.get(this.END_POINT + "/IngReservaPuestosTrabajo/ConsultarBienesReserva/" + idReservaPuestoTrabajo)
      .pipe(retry(1));
  }

  /**
   * Elimina una reserva por Id
   */
  public srvEliminarReserva(idReservaPuestoTrabajo: number): Observable<any> {
    return this.http.delete(this.END_POINT + "/IngReservaPuestosTrabajo/EliminarReserva/" + idReservaPuestoTrabajo)
      .pipe(retry(1));
  }

  /**
   * Obtener listado parametrico de Franja Horaria
   */
  public srvListarFranjaHoraria(): Observable<any> {
    return this.http.get(this.END_POINT + "/IngAdminPuestosTrabajo/ConsultarFranjasHorarias")
      .pipe(retry(1));
  }

  /**
   * Obtener listado parametrico de Franja Horaria
   */
  public srvListarPiso(): Observable<any> {
    return this.http.get(this.END_POINT + "/IngMaPiso/").pipe(retry(1));
  }

  public srvGuardarReserva(ingReservaPuestoTrabajoDTO: IngReservaPuestoTrabajoDTO): Observable<TrnRespuestaServicioRpt> {
    let url = this.END_POINT + "/IngReservaPuestosTrabajo/GuardarReserva";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, ingReservaPuestoTrabajoDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  public srvGuardarBienPersonal(ingBienPersonal: IngBienPersonalDTO): Observable<TrnRespuestaServicioRpt> {
    let url = this.END_POINT + "/InvBien/GuardarBienPersonal";
    return this.http.post<TrnRespuestaServicioRpt>(url, ingBienPersonal).pipe(
      map((data) => {
        return data;
      }),
      retry(1)
    );
  }

  /**
   * Obtener qr reserva
   */
  public srvObtenerQrReserva(idReservaPuestoTrabajo: number) {
    return this.http.get(this.END_POINT + "/IngIngresosPuestosTrabajo/GenerarQrReserva/" + idReservaPuestoTrabajo, {
      responseType: 'blob'
    });
  }

  public srvGuardarIngreso(ingIngresoReservaDTO: IngIngresoReservaDTO): Observable<TrnRespuestaServicioRpt> {
    let url = this.END_POINT + "/IngIngresosPuestosTrabajo/GuardarIngresoReservaPuestoTrabajo";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, ingIngresoReservaDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  public srvGuardarSalida(ingIngresoReservaDTO: IngIngresoReservaDTO): Observable<TrnRespuestaServicioRpt> {
    let url = this.END_POINT + "/IngIngresosPuestosTrabajo/GuardarSalidaReservaPuestoTrabajo";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, ingIngresoReservaDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  /**
   * Obtener fechas disponibles para reservar segun la semana
   */
  public srvObtenerFechasDisponibles(fecha: string): Observable<any> {
    return this.http.get<any>(this.END_POINT + "/IngReservaPuestosTrabajo/ConsultarReservaFechasPermitidas?fecha=" + fecha).pipe(retry(1));
  }

  /**
   * Obtener franja horaria disponible segun la fecha seleccionada
   */
  public srvConsultarFranjaHorariaDisponible(fecha: string): Observable<any> {
    return this.http.get<any>(this.END_POINT + "/IngReservaPuestosTrabajo/ConsultarFranjaHorariaDisponible/" + fecha).pipe(retry(1));
  }

  /**
   * Obtener listado de cupos
   */
  public srvListarCupos(): Observable<any> {
    return this.http.get<any>(this.END_POINT + "/IngAdminPuestosTrabajo/ConsultarCupos").pipe(retry(1));
  }

  /**
   * Obtener si una persona cuenta con puesto fijo
   */
  public srvConsultarPersonaTienePuestoPermanente(): Observable<any> {
    return this.http.get<any>(this.END_POINT + "/IngReservaPuestosTrabajo/ConsultarPersonaTienePuestoPermanente").pipe(retry(1));
  }

  /**
   * Obtener listado de cupos por id
   */
  public srvObtenerCupoPorId(idCupo: number): Observable<any> {
    return this.http.get<any>(this.END_POINT + "/IngAdminPuestosTrabajo/ConsultarCupoPorId/" + idCupo).pipe(retry(1));
  }

  /**
   * Obtiene la reserva de una persona segun la fecha y franja horaria
   */
  public srvConsultarReservaPuestoParaIngresoSalida(): Observable<any> {
    return this.http.get<any>(this.END_POINT + "/IngReservaPuestosTrabajo/ConsultarReservaPuestoParaIngresoSalida").pipe(retry(1));
  }

  /**
   * Guarda cupos por piso
   */
  public srvGuardarCupoPiso(
    cupoPiso: IngCuposPisoDTO
  ): Observable<TrnRespuestaServicioRpt> {
    let url = this.END_POINT + "/IngAdminPuestosTrabajo/GuardarCupoPiso";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, cupoPiso)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  /**
   * Obtener listado de franjas
   */
  public srvListarFranjas(): Observable<any> {
    return this.http.get<any>(this.END_POINT + "/IngAdminPuestosTrabajo/ConsultarFranjasHorarias").pipe(retry(1));
  }

  /**
   * Obtener franja por id
   */
  public srvObtenerFranjaPorId(idFranja: number): Observable<any> {
    return this.http.get<any>(this.END_POINT + "/IngAdminPuestosTrabajo/ConsultarFranjaHorariaPorId/" + idFranja).pipe(retry(1));
  }

  /**
   * Guarda franja horaria
   */
  public srvGuardarFranjaHoraria(
    franjaHoraria: IngFranjaHorariaDTO
  ): Observable<TrnRespuestaServicioRpt> {
    let url = this.END_POINT + "/IngAdminPuestosTrabajo/GuardarFranjaHoraria";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, franjaHoraria)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }
}
