import { Component, OnInit, Input } from '@angular/core';
import { TrnPersona } from '../../trn-persona';
import { Router } from '@angular/router';
import { PersonaService } from '../../../comun/persona/persona.service';
import { MessageService } from 'primeng/api';
import { PrsNivelEducativo } from '../../prs-nivel-educativo';
import { PrsProgamaAcademico } from '../../prs-programa-academico';
import { PrsEducacion } from '../../prs-educacion';
import { ParametricasService } from '../../../parametricas/parametricas.service';
import { TrnSiNo } from '../../../parametricas/trn-si-no';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-persona-educacion',
  templateUrl: './persona-educacion.component.html',
  styles: []
})
export class PersonaEducacionComponent implements OnInit {

  @Input()
  personaDatos: TrnPersona;
  educacionList: PrsEducacion[];
  totalPaginator: any;
  educacionSeleccionada: PrsEducacion = {};
  nivelEducativoSeleccionado: PrsNivelEducativo = {};
  nivelesEducativos: PrsNivelEducativo[] = [];
  programaAcademicoSeleccionado: PrsProgamaAcademico = {};
  programasAcademicos: PrsProgamaAcademico[] = [];
  mensaje: string;
  esTituloExtranjero: boolean = null;
  sinoTituloExtranjero: TrnSiNo;
  mostrarEsTituloExtranjero = false;
  obligatorioTituloExtranjero = true;

  // Informacion para los dialogos
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;
  visibleDialogoGuardarRegistro = false;
  visualizarDialogEducacion = false;
  blockedDocument = false;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private parametricasService: ParametricasService,
    private personaService: PersonaService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.blockedDocument = true;
    if (this.personaDatos != null) {
      this.cargarEducacion();
    }
  }

  cargarEducacion() {
    this.personaService.ConsultarPersonaEducacion(this.personaDatos.id)
      .subscribe(
        result => {

          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === 1) {
              this.educacionList = result.educacionList;
              this.totalPaginator = this.educacionList.length;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }


  aceptarEliminarEducacion() {
    this.blockedDocument = true;
    this.visibleDialogoConfirmarEliminarRegistro = false;
    if (this.educacionSeleccionada != null && this.educacionSeleccionada.id !== 0) {


      this.personaService.EliminarPersonaEducacion(this.educacionSeleccionada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === 1) {
              this.visibleDialogoExitoEliminarRegistro = true;
              // this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.educacionSeleccionada = null;
          this.blockedDocument = false;
        }
      );
    }
  }

  // Eliminacion - Cancelar
  cancelarEliminarRegistro() {
    this.blockedDocument = false;
    this.visibleDialogoConfirmarEliminarRegistro = false;
    this.educacionSeleccionada = null;
  }

  aceptarExitoEliminarRegistro() {
    this.blockedDocument = false;
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleDialogoConfirmarEliminarRegistro = false;
    this.ngOnInit();
  }

  irEliminarRegistro(educacion: PrsEducacion) {
    this.blockedDocument = true;
    this.educacionSeleccionada = educacion;
    this.visibleDialogoConfirmarEliminarRegistro = true;
  }

  irEditarRegistro(educacion: PrsEducacion) {
    this.blockedDocument = true;
    if (educacion != null) {
      this.educacionSeleccionada = {};
      this.educacionSeleccionada = educacion;
      if (this.educacionSeleccionada.idNivelEducativo != null && this.educacionSeleccionada.idNivelEducativo !== 0) {
        this.nivelEducativoSeleccionado = {
          id: this.educacionSeleccionada.idNivelEducativo,
          nombre: this.educacionSeleccionada.nivelEducativo
        };
      }
      this.mostrarEsTituloExtranjero = true;

      if (this.educacionSeleccionada.idProgramaAcademico != null && this.educacionSeleccionada.idProgramaAcademico !== 0) {
        this.esTituloExtranjero = false;
        this.programaAcademicoSeleccionado = {
          id: this.educacionSeleccionada.idProgramaAcademico,
          nombre: this.educacionSeleccionada.programaAcademico
        };
      } else {
        if (this.educacionSeleccionada.tituloExtranjero != null) {
          this.esTituloExtranjero = true;
          this.programaAcademicoSeleccionado = null;
          this.programasAcademicos = null;
        }
      }
      this.visualizarDialogEducacion = true;
      this.blockedDocument = false;
    }
  }

  irAgregarRegistro() {
    this.visualizarDialogEducacion = true;
    this.educacionSeleccionada = {
      idPersona: this.personaDatos.id,
      graduado: false
    };
    this.nivelEducativoSeleccionado = null;
    this.programaAcademicoSeleccionado = null;
    this.mostrarEsTituloExtranjero = false;
    this.sinoTituloExtranjero = null;
  }

  cancelarRegistro() {
    this.visualizarDialogEducacion = false;
    this.educacionSeleccionada = null;
    this.nivelEducativoSeleccionado = null;
    this.programaAcademicoSeleccionado = null;
    this.sinoTituloExtranjero = null;
    this.cargarEducacion();
  }

  cargarNivelesEducativos(event) {
    const filtroNivelEducativo = [];
    this.personaService.CargarNivelesEducativos().subscribe(
      data => {
        this.nivelesEducativos = data;
        if (event != null) {
          let existeFiltro: boolean;
          for (const nvlEdu of this.nivelesEducativos) {
            if (String(nvlEdu.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroNivelEducativo.push(nvlEdu);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.nivelesEducativos = filtroNivelEducativo;
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error)
      });
  }

  cargarProgramasAcademicos(event) {
    if (this.nivelEducativoSeleccionado != null && this.nivelEducativoSeleccionado.id != null && this.nivelEducativoSeleccionado.id !== 0) {
      const filtroProgramasAcademicos = [];
      this.personaService.CargarProgramasAcademicosPorNivelEducativo(this.nivelEducativoSeleccionado.id)
        .subscribe(data => {
          this.programasAcademicos = data;
          if (event != null) {
            let existeFiltro: boolean;
            for (const pgrAcd of this.programasAcademicos) {
              if (String(pgrAcd.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtroProgramasAcademicos.push(pgrAcd);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.programasAcademicos = filtroProgramasAcademicos;
            }
          }
        },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
          }
        );
    }
  }

  validarCamposAcademicosPorNivelEducativo(event) {
    if (this.nivelEducativoSeleccionado != null && this.nivelEducativoSeleccionado.id != null && this.nivelEducativoSeleccionado.id !== 0) {
      this.mostrarEsTituloExtranjero = true;

      if (this.nivelEducativoSeleccionado.id === 1 || this.nivelEducativoSeleccionado.id === 2 || this.nivelEducativoSeleccionado.id === 3) {
        this.mostrarEsTituloExtranjero = false;
        this.sinoTituloExtranjero == null;
      }
    }
  }

  validarCamposAcademicosPorTituloExtranjero(sinoSeleccion) {
    this.sinoTituloExtranjero = sinoSeleccion;
    if (this.sinoTituloExtranjero != null) {
      if (this.sinoTituloExtranjero.valor) {
        this.programaAcademicoSeleccionado = null;
      } else {
        this.educacionSeleccionada.tituloExtranjero = null;
      }
    } else {
      this.programaAcademicoSeleccionado = null;
      this.educacionSeleccionada.tituloExtranjero = null;
    }
  }

  validarSemestresCursados(sinoSeleccion) {
    this.educacionSeleccionada.graduado = sinoSeleccion.valor;
    if (sinoSeleccion.valor == null || sinoSeleccion.valor) {
      this.educacionSeleccionada.semestresCursados = null;
    }
  }

  guardarEducacionPersona() {
    if (this.educacionSeleccionada != null) {

      if (this.nivelEducativoSeleccionado != null) {
        this.educacionSeleccionada.idNivelEducativo = this.nivelEducativoSeleccionado.id;
      }
      if (this.programaAcademicoSeleccionado != null) {
        this.educacionSeleccionada.idProgramaAcademico = this.programaAcademicoSeleccionado.id;
      }

      this.personaService.GuardarPersonaEducacion(this.educacionSeleccionada).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === 1) {
              this.visibleDialogoGuardarRegistro = true;
              // this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.visualizarDialogEducacion = false;
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.visualizarDialogEducacion = false;
          this.educacionSeleccionada = null;
          this.nivelEducativoSeleccionado = null;
          this.programaAcademicoSeleccionado = null;
          this.sinoTituloExtranjero = null;
          this.cargarEducacion();
        }
      );
    }
  }
}
