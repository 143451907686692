import { Component, OnInit, ViewChild } from '@angular/core';
import { UnidadFuncionalCarreteroDetalleService } from './unidad-funcional-carretero-detalle.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { UnidadFuncional } from '../unidad-funcional-carretero/unidadFuncional';
import { ParametricasService } from './../parametricas/parametricas.service';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-unidad-funcional-carretero-detalle',
  templateUrl: './unidad-funcional-carretero-detalle.component.html',
  styleUrls: ['./unidad-funcional-carretero-detalle.component.sass']
})
export class UnidadFuncionalCarreteroDetalleComponent implements OnInit {

  unidadFuncional: UnidadFuncional = {};

  departamentosOrigen: any = [];
  filtroDepartamentosOrigen: any[];


  departamentosDestino: any = [];
  filtroDepartamentosDestino: any[];


  ciudadesOrigen: any = [];
  filtroCiudadesOrigen: any[];


  ciudadesDestino: any = [];
  filtroCiudadesDestino: any[];

  referenciaLinealOrigen: any = [];
  filtroReferenciaLinealOrigen: any[];
  itemReferenciaLinealOrigen: any;
  referenciaLinealxOrigen: any;
  referenciaLinealyOrigen: any;

  referenciaLinealDestino: any = [];
  filtroReferenciaLinealDestino: any[];
  itemReferenciaLinealDestino: any;
  referenciaLinealxDestino: any;
  referenciaLinealyDestino: any;


  visibleDialogoExito: boolean = false;

  iniciaEnOperacion: boolean = false;
  fechaInicioConstruccion: Date;
  fechaFinConstruccion: Date;
  fechaReferenciaCapex: Date;
  fechaReferenciaOpex: Date;

  constructor(public lineaBase: ProyectoLineaBaseComponent, private parametricasService: ParametricasService, private unidadFuncionalDetalleService: UnidadFuncionalCarreteroDetalleService, private route: ActivatedRoute, private ruta: Router, private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.iniciaEnOperacion = this.lineaBase.unidadFuncional.iniciaEnOperacion;
    if (this.lineaBase.unidadFuncional.fechaInicio != null) {
      this.fechaInicioConstruccion = new Date(this.lineaBase.unidadFuncional.fechaInicio);
    }
    if (this.lineaBase.unidadFuncional.fechaFin != null) {
      this.fechaFinConstruccion = new Date(this.lineaBase.unidadFuncional.fechaFin);
    }
    if (this.lineaBase.unidadFuncional.fechaReferenciaCapex != null) {
      this.fechaReferenciaCapex = new Date(this.lineaBase.unidadFuncional.fechaReferenciaCapex);
    }
    if (this.lineaBase.unidadFuncional.fechaReferenciaOpex != null) {
      this.fechaReferenciaOpex = new Date(this.lineaBase.unidadFuncional.fechaReferenciaOpex);
    }
  }

  accionCheckIniciaEnOperacion() {
  }

  cargarDepartamentosOrigen(event) {
    this.filtroDepartamentosOrigen = [];
    return this.parametricasService.getDepartamentos().subscribe((data: {}) => {
      this.departamentosOrigen = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.departamentosOrigen.length; i++) {
        let filtro = this.departamentosOrigen[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDepartamentosOrigen.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.departamentosOrigen = this.filtroDepartamentosOrigen;
      }
    });
  }

  cargarFiltroDepartamentosOrigen(event) {
    this.filtroDepartamentosOrigen = [];
    for (let i = 0; i < this.departamentosOrigen.length; i++) {
      let filtro = this.departamentosOrigen[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentosOrigen.push(filtro);
      }
    }
  }

  cargarDepartamentosDestino(event) {
    this.filtroDepartamentosDestino = [];
    return this.parametricasService.getDepartamentos().subscribe((data: {}) => {
      this.departamentosDestino = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.departamentosDestino.length; i++) {
        let filtro = this.departamentosDestino[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDepartamentosDestino.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.departamentosDestino = this.filtroDepartamentosDestino;
      }
    });
  }

  cargarFiltroDepartamentosDestino(event) {
    this.filtroDepartamentosDestino = [];
    for (let i = 0; i < this.departamentosDestino.length; i++) {
      let filtro = this.departamentosDestino[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentosDestino.push(filtro);
      }
    }
  }

  cargarCiudadesOrigen(event) {
    this.filtroCiudadesOrigen = [];
    return this.parametricasService.getCiudadesPorDepartamento(this.lineaBase.departamentoSeleccionadoOrigen.id).subscribe((data: {}) => {
      this.ciudadesOrigen = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.ciudadesOrigen.length; i++) {
        let filtro = this.ciudadesOrigen[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroCiudadesOrigen.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.ciudadesOrigen = this.filtroCiudadesOrigen;
      }
    });
  }

  cargarFiltroCiudadesOrigen(event) {
    this.filtroCiudadesOrigen = [];
    for (let i = 0; i < this.ciudadesOrigen.length; i++) {
      let filtro = this.ciudadesOrigen[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudadesOrigen.push(filtro);
      }
    }
  }

  cargarListaReferenciaLinealOrigen(event) {
    this.filtroReferenciaLinealOrigen = [];
    this.referenciaLinealOrigen = [
      { label: 'PR', value: 'PR' },
      { label: 'K', value: 'K' },
    ];
    let existeFiltro: boolean;
    for (let i = 0; i < this.referenciaLinealOrigen.length; i++) {
      let filtro = this.referenciaLinealOrigen[i];
      let item: string = String(filtro.value);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroReferenciaLinealOrigen.push(filtro);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.referenciaLinealOrigen = this.filtroReferenciaLinealOrigen;
    }

  }

  cargarFiltroReferenciaLinealOrigen(event) {
    this.filtroReferenciaLinealOrigen = [];
    for (let i = 0; i < this.referenciaLinealOrigen.length; i++) {
      let filtro = this.referenciaLinealOrigen[i];
      let item: string = String(filtro.value);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroReferenciaLinealOrigen.push(filtro);
      }
    }
  }

  cargarListaReferenciaLinealDestino(event) {
    this.filtroReferenciaLinealDestino = [];
    this.referenciaLinealDestino = [
      { label: 'PR', value: 'PR' },
      { label: 'K', value: 'K' },
    ];
    let existeFiltro: boolean;
    for (let i = 0; i < this.referenciaLinealDestino.length; i++) {
      let filtro = this.referenciaLinealDestino[i];
      let item: string = String(filtro.value);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroReferenciaLinealDestino.push(filtro);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.referenciaLinealDestino = this.filtroReferenciaLinealDestino;
    }

  }

  cargarFiltroReferenciaLinealDestino(event) {
    this.filtroReferenciaLinealDestino = [];
    for (let i = 0; i < this.referenciaLinealDestino.length; i++) {
      let filtro = this.referenciaLinealDestino[i];
      let item: string = String(filtro.value);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroReferenciaLinealDestino.push(filtro);
      }
    }
  }



  cargarCiudadesDestino(event) {
    this.filtroCiudadesDestino = [];
    return this.parametricasService.getCiudadesPorDepartamento(this.lineaBase.departamentoSeleccionadoDestino.id).subscribe((data: {}) => {
      this.ciudadesDestino = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.ciudadesDestino.length; i++) {
        let filtro = this.ciudadesDestino[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroCiudadesDestino.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.ciudadesDestino = this.filtroCiudadesDestino;
      }
    });
  }

  cargarFiltroCiudadesDestino(event) {
    this.filtroCiudadesDestino = [];
    for (let i = 0; i < this.ciudadesDestino.length; i++) {
      let filtro = this.ciudadesDestino[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudadesDestino.push(filtro);
      }
    }
  }

  guardarUnidadFuncional(forma: NgForm) {
    if (this.fechaInicioConstruccion > this.fechaFinConstruccion) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de construcción debe ser menor que la fecha fin de construcción', life: 10000 });
    } else {
      this.lineaBase.unidadFuncional.idProyecto = this.lineaBase.idProyecto;
      this.lineaBase.unidadFuncional.iniciaEnOperacion = this.iniciaEnOperacion;
      this.lineaBase.unidadFuncional.fechaReferenciaOpex = this.fechaReferenciaOpex;
      if (!this.iniciaEnOperacion) {
        this.lineaBase.unidadFuncional.fechaInicio = this.fechaInicioConstruccion;
        this.lineaBase.unidadFuncional.fechaFin = this.fechaFinConstruccion;
        this.lineaBase.unidadFuncional.fechaReferenciaCapex = this.fechaReferenciaCapex;
      }
      if (this.lineaBase.idUnidadFuncional === 'new') {
        let vara: any = this.unidadFuncionalDetalleService.guardarNuevaUnidadFuncional(this.lineaBase.unidadFuncional).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
          let unidadFuncionalNueva: UnidadFuncional = data;
          this.lineaBase.idUnidadFuncional = unidadFuncionalNueva.id;
          this.lineaBase.habilitaTabContratoCredito = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        let vara: any = this.unidadFuncionalDetalleService.editarUnidadFuncional(this.lineaBase.unidadFuncional, this.lineaBase.idUnidadFuncional).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      }
    }
  }

  cargarUnidadFuncional(idUnidadFuncional) {
  }

  actionRegresar() {
    this.lineaBase.visibleListaUnidadFuncional = true;
    this.lineaBase.visibleUnidadFuncionalDettalle = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.lineaBase.visibleUnidadFuncionalDettalle = false;
    this.lineaBase.visibleListaUnidadFuncional = true;
  }

}
