import { Component, OnInit  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NuevoIntervencionService } from './nuevo-intervencion.service';
import { Ambiental } from '../nuevo-intervencion/ambiental';
import { MatTableDataSource } from '@angular/material';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-nuevo-intervencion',
  templateUrl: './nuevo-intervencion.component.html',
  styleUrls: ['./nuevo-intervencion.component.sass']
})

export class NuevoIntervencionComponent implements OnInit {

  proyecto: Ambiental = {};
  tipoObligacion: any = [];
  idAmbientalDetalle: any;

  dataSource: MatTableDataSource<any>;

  constructor(private detalleService: NuevoIntervencionService, private route: ActivatedRoute, private ruta: Router, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idAmbientalDetalle = params.id;
     // this.idAmbientalDetalle === 'new' ? this.cargarNuevo(this.idAmbientalDetalle) : this.cargarProyectoAmbiental(this.idAmbientalDetalle);
    });
    this.cargarNuevo(this.idAmbientalDetalle)
    // this.cargarTipoObligacion();
  }

  cargarTipoObligacion() {
    return this.detalleService.getTipoObligacion().subscribe((data: {}) => {
      this.tipoObligacion = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarNuevo(id) {
    this.proyecto = {};
    // this.proyecto.idProyecto = JSON.parse(localStorage.getItem('idP'));
  }

  cargarProyectoAmbiental(id: number) {
    this.detalleService.getProyectoAmbiental(id).subscribe((data: {}) => {
      this.proyecto = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  guardarDetalle(payload) {
    if (this.idAmbientalDetalle === 'new') {
        return this.detalleService.guardarNuevoAmbiental(payload).subscribe((data: {}) => {
          this.ruta.navigate(['/infAmbiental', JSON.parse(localStorage.getItem('idP'))]);
          },error => {
            this.principalComponent.cargarErrorServicio(error);
          });
    } else {
        return this.detalleService.guardarDetalleAmbiental(payload).subscribe((data: {}) => {
          this.ruta.navigate(['/infAmbiental', JSON.parse(localStorage.getItem('idP'))]);
          },error => {
            this.principalComponent.cargarErrorServicio(error);
          });
    }
  }

}
