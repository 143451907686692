
import { ProyectoEstructuracion } from 'src/app/reportes/publicos/proyectoEstructuracion';


export interface RespuestaServicio {
    codigoSalida?: number;
    mensajeSalida?: string;
    
  }

  export interface InformacionGeneralCarreterosDTO {
    respuestaServicio?: RespuestaServicio;
    proyectos?: CrrInformacionGeneralProyectoDTO[];
    departamentos?: CrrInformacionDepartamentosDTO[];
    peajes?: CrrInformacionPeajesDTO[];
    
  }

   
export class  CrrInformacionGeneralProyectoDTO {
    
  
  id?: number;
  codProyecto?: string;
  proyecto?: string;
  ola?: string;
  idGeneracion?: number;
  generacion?: string;
  longitudConcesionada?: number;
  longitudConcesionadaKm?: number;
  longitudACargo?: number;
  longitudACargoKm?: number;
  empleosGeneradosConcesionario?: number;
  empleosGeneradosInterventoria?: number;
  idEtapaProyecto?: number;
  etapaProyecto?: string;    
  fechaSuscripcionContrato: Date;
  fechaReferenciaContrato: Date;
  plazoAniosContrato?: number;
  valorContratoFechaReferencia?: number;
  fechaPreconstruccionContratada: Date;
  fechaConstruccionContratada: Date;
  fechaOperacionContratada: Date;
  fechaPreconstruccionEjecutada: Date;
  fechaConstruccionEjecutada: Date;
  fechaOperacionEjecutada: Date;
  fechaIndexacionValorContrato: Date;
  valorContratoIndexado?: number;
  fechaIndexacionCapexOpex: Date;
  capexIndexado?: number;
  opexIndexado?: number;
  fechaUltimoReporteAvance: Date;
  porcentajeAvance?: number;
  porcentajePlaneado?: number;
  playList?: string;    
  videoId?: string;    
  urlImg?: string;    
  tieneDatosPlanObra?: boolean;
  
    
  }

  export class  CrrInformacionDepartamentosDTO {
    
    id?: number;
    idProyecto?: number;
    idGeneracion?: number;
    generacion?: string;
    idEtapaProyecto?: number;
    etapaProyecto?: string;
    idDepartamento?: string;
    departamento?: string;        
    
  }

  export class  CrrInformacionPeajesDTO {
    
    id?: number;
    peaje?: string;
    idEstado?: number;
    estado?: string;   
    idProyecto?: number;
    idGeneracion?: number;
    generacion?: string;
    idEtapaProyecto?: number;
    etapaProyecto?: string;
        
    
  }
  

  export interface InformacionCarreterosDTO {
    respuestaServicio?: RespuestaServicio;
    fichaCarretero?: CrrInformacionProyectoDTO[];
   
    
  }

  export class  CrrInformacionProyectoDTO {
    
   
    id?: number;
    codProyecto?: string;
    proyecto?: string;
    ola?: string;
    idGeneracion?: number;
    generacion?: string;
    longitudConcesionada?: number;
    longitudConcesionadaKm?: number;
    longitudACargo?: number;
    longitudACargoKm?: number;
    empleosGeneradosConcesionario?: number;
    empleosGeneradosInterventoria?: number;
    idEtapaProyecto?: number;
    etapaProyecto?: string;  

  }

  
  export class  CrrInformacionProyectoCsvDTO {
    
   
    id?: number;
    codProyecto?: string;
    proyecto?: string;
    ola?: string;
    idGeneracion?: number;
    generacion?: string;
    longitudConcesionada?: number;
    longitudConcesionadaKm?: number;
    longitudACargo?: number;
    longitudACargoKm?: number;
    empleosGeneradosConcesionario?: number;
    empleosGeneradosInterventoria?: number;
    idEtapaProyecto?: number;
    etapaProyecto?: string;  
    porcentajeAvance?: string;
    porcentajePlaneado?: number;

  }



  export interface InformacionAvanceUfDTO {
    respuestaServicio?: RespuestaServicio;
    avanceUf?: CrrAvanceUfDTO[];
    
  }

  
  export class  CrrAvanceUfDTO {
    id?: number;
    idProyecto?: number;
    codigoUf?: string;
    porcentajeAvance?: number;
    porcentajePlaneado?: number;
    factorPonderacion?: number;
    fechaInicioConstruccion: Date;
    fechaFinConstruccion: Date;
    fechaInicioConstruccionEjecutada : Date;
    fechaFinConstruccionEjecutada: Date;
    nombreUf: string;
    estilo: string;
  }

  
  export interface InformacionAvanceProyectoDTO {
    respuestaServicio?: RespuestaServicio;
    listAvances?: CrrAvanceProyectoDTO[];
    
  }

  export class  CrrAvanceProyectoDTO {
    id?: number;
    idProyecto?: number;
    fechaReporte: Date;
    porcentajeAvance?: number;
    porcentajePlaneado?: number;
    orden: number;
    idConfiguracionProyecto?: number;
    idNovedadContrato?: number;
    nombreNovedad: string;
    tipoNovedad: string;
  }

  export class InformacionAvanceProyectoTiposIntervencionDTO {
    respuestaServicio?: RespuestaServicio;
    avancesCarretera: CrrAvanceProyectoTiposIntervencionDTO[];
    avanceOtros: CrrAvanceProyectoTiposIntervencionDTO[];
  }
  
  export class CrrAvanceProyectoTiposIntervencionDTO {
    idProyecto: number;
    idTipoIntervencion: number;
    tipoIntervencion?: string;
    ultimaFechaReporte?: Date;
    cantidad?: number;
    cantidadTerminados?: number;
    longitudConcesionada?: number;
    longitudReal?: number;
    avanceLongitud?: number;
  }

  export class InformacionCapexOpexDTO {
    respuestaServicio?: RespuestaServicio;
    inversionTotal?: ValoresPorProyectoDTO;
    inversionPorUF?: ValoresPorProyectoDTO[];
  }

  export class ValoresPorProyectoDTO {
    nombre: string;
    fechaReferencia: Date;
    capex: number;
    opex: number;
    longitudConcesionada: number;
  }

  export class FechasReferenciaCapexOpexDTO {
    respuestaServicio?: RespuestaServicio;
    fechasReferencia?: Date[];
  }  


  export interface InformacionTemasGestionMetasDTO {
    respuestaServicio?: RespuestaServicio;
    infoTemasGestion?: CrrTemasGestionMetasDTO[];
    
  }
  export interface InformacionMetasDTO {
    respuestaServicio?: RespuestaServicio;
    infoMetas?: CrrTemasGestionMetasDTO[];
    
  }
  export class  CrrTemasGestionMetasDTO {
    id: number;
    idProyecto?: number;
    nombreProyecto?: string;
    fechaInicio: Date;
    idTipoTema?: number;
    tipoTema?: string;
    etapaActual?: string;
    descripcionCorta?: string;
    actuacion?: string;
    accionesASeguir?: string;
    
  }

  export class  CrrTemasGestionMetasCsvDTO {
 
   
    nombreProyecto?: string;
    fechaInicio: string;
    tipoTema?: string;
    descripcionCorta?: string;
    actuacion?: string;
    accionesASeguir?: string;
    
  }


  

export class ConsultarProyectoEstructuracionRpt {
  
    public respuestaServicio?: RespuestaServicio;
    public proyectos?: ProyectoEstructuracion[];
 
}


export interface RespuestaValoresIndexadosDTO {
  respuestaServicio?: RespuestaServicio;
  valoresIndexados?: ValoresIndexadosDTO[];
  
}
export class  ValoresIndexadosDTO {
  id: number;
  idProyecto?: number;
  fechaIndexacion?: Date;
  valorContratoIndexado?: number;
  capex?: number;
  opex?: number;

  
}

export class  UnidadFuncionalDTO {
  id: number;
  nombre?: string;

  constructor(nombre: string){
    this.nombre = nombre;
  }
}


export interface ConsolidadosProyectosDTO {
  respuestaServicio?: RespuestaServicio;
  carreteros?: ProyectosDTO[];
  aeropuertos?: ProyectosDTO[];
  puertos?: ProyectosDTO[];
  ferreo?: ProyectosDTO[];
}


     
export class  ProyectosDTO {
    
  Id: number;
  IdProyecto?: number;
  Codigo?: string;
  Nombre?: string;
  NombreCorto?: string;
  IdTipo?: number;
  Tipo?: string;
  
    
  }