export class InformacionGeneralTabla{
    constructor(
      //  public id : number,
        public nombre :string,     
        //public idTipo: number, 
        public tipo:string,
        public generacion  :string,
        public ola  :string,
        public etapaProyecto :string, 
        // public longitudConcesionada: number, 
        // public longitudConcesionadaKm : number, 
        // public longitudACargo: number,
        // public longitudACargoKm: number,       
        // public fechaReferencia : Date,
        // public plazoAnios : number, 
        // public valorContratoFechaReferencia : number, 
        // public fechaUltimaIndexacion : Date,
        // public valorContratoIndexado: number,
        // public capexIndexado: number,
        // public opexIndexado: number,
        // public FechaSuscripcion : Date,
        // public fechaPreconstruccion? : Date,
        // public fechaConstruccion? : Date,
        // public fechaOperacion? : Date,
        // public fechaUltimoReporteAvance? : Date
    ){}
}