import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { InformacionGeneralService } from "../../informacion-general/informacion-general.service";
import { PjePeajePorProyecto } from "../trafico-peajes/PjePeajePorProyecto";
import { TraficoPeajesCargaService } from "../trafico-peajes/trafico-peajes-carga/trafico-peajes-carga.service";
import { forkJoin } from 'rxjs';
import { InformacionGeneralPeajesService } from "./informacion-general-peajes.service";
import { PjeMaSentidoCobroPeaje } from "./PjeMaSentidoCobroPeaje";
import { PjeInformacionGeneralPeajeDTO } from "./PjeInformacionGeneralPeajeDTO";
import { PjeMaOperadorPagoElectronico } from "./PjeMaOperadorPagoElectronico";
import { PjePeajeOperadorPagoElectronicoDTO } from "./PjePeajeOperadorPagoElectronicoDTO";
import { ParametricasService } from "../../parametricas/parametricas.service";
import { PjeUbicacionPeajeDTO } from "./PjeUbicacionPeajeDTO";


@Component({
    selector: 'app-informacion-general-peajes',
    templateUrl: './informacion-general-peajes.component.html',
    styleUrls: ['./informacion-general-peajes.component.sass']
})



export class InformacionGeneralPeajesComponent implements OnInit {

    idProyecto: number;
    proyecto: any;
    nombreProyecto: string;
    blockedDocument = false;
    fechaMaxima: Date;
    fechaInicioOperadorConvert: Date;

    // variables para cargar lista de peajes
    filtroPeajes: any[];
    peajesPorProyecto: any[];
    peajeSeleccionado: PjePeajePorProyecto;

    // variables peaje
    informacionPeajeGet: any = [];
    informacionGeneralPeaje: PjeInformacionGeneralPeajeDTO;

    // variables para cargar lista de tipo sentido
    filtroTipoSentido: any[];
    tiposSentido: any[];
    tipoSentidoSeleccionado: PjeMaSentidoCobroPeaje;

    // variables Operador
    visibleDialogoAgregarOperador = false;
    filtroOperador: any[];
    operadores: any[];
    operadorSeleccionado: PjeMaOperadorPagoElectronico;
    peajeOperadorPagoElectronico: PjePeajeOperadorPagoElectronicoDTO;
    mensajeOperadorDialog: string;
    operadorEditable;
    visibleConfirmarEliminarOperador;
    operadorElimina: number;

    // variables listar operadores
    operadoresElectronicos: any = [];
    displayedColumns: any[];
    operadorElectronicoEdita: PjePeajeOperadorPagoElectronicoDTO;

    // variables listar ubicaciones peajes
    ubicacionesPeaje: any = [];
    displayedColumnsUbicacion: any[];
    ubicacionPeajeEdita: PjeUbicacionPeajeDTO;


    // variables ubicación
    visibleDialogoAgregarUbicacion = false;
    filtroUbicacion: any[];
    ubicaciones: any[];
    mensajeUbicacionDialog: string;
    pjeUbicacionPeajeDTO: PjeUbicacionPeajeDTO;
    tipoUbicacion: any[];
    tipoUbicacionSeleccionada = { id: '-1', nombre: '' };
    visibleConfirmarEliminarUbicacion;
    ubicacionElimina: number;
    visibleInformacionUbicacion;
    mensajeUbicacionAdvertenciaDialog: string;


    //variables departamento y ciudades
    filtroDepartamentos: any[];
    departamentos: any[];
    departamentoSeleccionado: any;
    filtroCiudades: any[];
    ciudadSeleccionada: any;
    ciudades: any[] = [];




    constructor(private router: Router, private route: ActivatedRoute, private messageService: MessageService, private traficoPeajesCargaService: TraficoPeajesCargaService,
        private infoService: InformacionGeneralService, private parametricasService: ParametricasService, private informacionGeneralPeajesService: InformacionGeneralPeajesService) { }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.idProyecto = params.id;
        });

        this.cargarProyectoCarretero(this.idProyecto);
        this.informacionGeneralPeaje = new PjeInformacionGeneralPeajeDTO();
        this.peajeOperadorPagoElectronico = new PjePeajeOperadorPagoElectronicoDTO();
        this.pjeUbicacionPeajeDTO = new PjeUbicacionPeajeDTO();
        this.fechaMaxima = new Date();

        this.displayedColumns = [
            { field: 'id', header: 'Id' },
            { field: 'idOperador', header: 'Nombre Operador' },
            { field: 'fechaInicio', header: 'Fecha Inicio Operador' },
            { field: 'fechaFinFormat', header: 'Fecha Fin Operador' }
        ];

        this.displayedColumnsUbicacion = [
            { field: 'id', header: 'Id' },
            { field: 'idDepartamento', header: 'Id Departamento' },
            { field: 'nombreDepartamento', header: 'Nombre Departamento' },
            { field: 'idMunicipio', header: 'Id Municipio' },
            { field: 'nombreMunicipio', header: 'Nombre Municipio' },
            { field: 'posicionX', header: 'Longitud' },
            { field: 'posicionY', header: 'Latitud' },
            { field: 'tipoUbicacion', header: 'PR/K' },
            { field: 'punto', header: 'Punto' },
            { field: 'distanciaAlPunto', header: 'Distancia' }
        ];



        this.tipoUbicacion = [
            { id: 'KM', nombre: 'KM' },
            { id: 'PR', nombre: 'PR' }
        ];

        const a: any = 0;
        this.cargarDepartamentos(a);


    }

    setTipoUbicacion(event) {

        this.pjeUbicacionPeajeDTO.tipoUbicacion = this.tipoUbicacionSeleccionada.id;
    }

    cargarProyectoCarretero(id: number) {
        this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
            this.proyecto = data;
            this.nombreProyecto = this.proyecto.nombre;
        });
    }

    cargarPeajes(event) {
        this.blockedDocument = true;

        this.filtroPeajes = [];
        return this.traficoPeajesCargaService.getPeajes(this.idProyecto).subscribe(
            result => {
                if (result != null && result.respuestaServicio != null) {
                    if (result.respuestaServicio.codigoSalida !== 0) {
                        this.peajesPorProyecto = result.peajes;
                    } else {
                    }
                } else {
                }
            },
            error => {
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;
            }
        );
    }

    cargarDepartamentos(event) {
        this.filtroDepartamentos = [];
        return this.parametricasService.getDepartamentos().subscribe((data: any) => {
            this.departamentos = data;
            if (event != null) {
                let existeFiltro: boolean;
                for (let i = 0; i < this.departamentos.length; i++) {
                    const filtro = this.departamentos[i];
                    if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                        this.filtroDepartamentos.push(filtro);
                        existeFiltro = true;
                    }
                }
                if (existeFiltro) {
                    this.departamentos = this.filtroDepartamentos;
                }
            }
        });
    }

    cargarCiudades(event) {

        if (this.departamentoSeleccionado != null) {
            this.filtroCiudades = [];
            return this.parametricasService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id).subscribe((data: any) => {
                this.ciudades = data;
                if (event != null) {
                    let existeFiltro: boolean;
                    for (let i = 0; i < this.ciudades.length; i++) {
                        const filtro = this.ciudades[i];
                        if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                            this.filtroCiudades.push(filtro);
                            existeFiltro = true;
                        }
                    }
                    if (existeFiltro) {
                        this.ciudades = this.filtroCiudades;
                    }
                }
            });
        }

    }


    limpiarCiudades(event) {
        this.ciudadSeleccionada = null;
    }

    cargarTipoSentido(event) {
        this.blockedDocument = true;
        this.filtroTipoSentido = [];
        return this.informacionGeneralPeajesService.getTipoSentido().subscribe(
            result => {
                if (result != null && result.respuestaServicio != null) {
                    if (result.respuestaServicio.codigoSalida !== 0) {
                        this.tiposSentido = result.sentidoCobroPeaje;
                    } else {
                    }
                } else {
                }
            },
            error => {
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;
            }
        );

    }

    cargarOperadores(event) {
        this.blockedDocument = true;
        this.filtroOperador = [];
        return this.informacionGeneralPeajesService.getOperadores().subscribe(
            result => {
                if (result != null && result.respuestaServicio != null) {
                    if (result.respuestaServicio.codigoSalida !== 0) {
                        this.operadores = result.operadoresPeaje;
                    } else {
                    }
                } else {
                }
            },
            error => {
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;
            }
        );
    }
    irAgregarUbicacion() {
        this.mensajeUbicacionDialog = "Agregar Ubicación";
        this.visibleDialogoAgregarUbicacion = true;
        this.pjeUbicacionPeajeDTO = new PjeUbicacionPeajeDTO();
    }

    irInformacionUbicacion() {
        if (this.ubicacionesPeaje.length === 0) {
            this.irAgregarUbicacion();
        }
        else if (this.ubicacionesPeaje.length === 1) {
            this.mensajeUbicacionAdvertenciaDialog = 'Ya existe una ubicación registrada para el peaje, recuerde que solo puede agregar máximo 2 puntos';
            this.visibleInformacionUbicacion = true;
        }
        else {
            this.mensajeUbicacionAdvertenciaDialog = 'Solo puede tener 2 ubicaciones por peaje.';
            this.visibleInformacionUbicacion = true;
        }
    }

    aceptarAdvertenciaUbicacion() {
        this.visibleInformacionUbicacion = false;
        if (this.ubicacionesPeaje.length === 1) {
            this.irAgregarUbicacion();
        }
    }

    cancelarDialogoAgregarUbicacion() {
        this.visibleDialogoAgregarUbicacion = false;
    }


    aceptarAgregarUbicacion() {
        this.visibleDialogoAgregarUbicacion = false;
        this.blockedDocument = true;
        this.pjeUbicacionPeajeDTO.idPeaje = this.peajeSeleccionado.idPeaje;
        this.pjeUbicacionPeajeDTO.idMunicipio = this.ciudadSeleccionada.id;
        forkJoin(
            this.informacionGeneralPeajesService.guardaUbicacionPeaje(this.pjeUbicacionPeajeDTO),
            this.informacionGeneralPeajesService.sincronizarZonasPeajes()
        ).subscribe(([result, resultSincronizacion]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                    this.cargarUbicacionPeajes();
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
            
        },
            error => {
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    irAgregarOperador() {
        this.peajeOperadorPagoElectronico = new PjePeajeOperadorPagoElectronicoDTO();
        this.operadorSeleccionado = new PjeMaOperadorPagoElectronico();
        this.mensajeOperadorDialog = "Agregar Operador";
        this.operadorEditable = false;
        this.guardarInformacionGeneralPeaje();
        this.visibleDialogoAgregarOperador = true;
        this.peajeOperadorPagoElectronico.horaInicio = 0;
        this.peajeOperadorPagoElectronico.minutoInicio = 0;

    }



    irEditarUbicacion(id: number) {
        this.ubicacionPeajeEdita = new PjeUbicacionPeajeDTO();
        this.ubicacionPeajeEdita = this.ubicacionesPeaje.find(x => x.id === id);
        this.departamentoSeleccionado = this.departamentos.find(x => x.id === this.ubicacionPeajeEdita.idDepartamento);
        let event;
        if (this.departamentoSeleccionado != null) {
            this.filtroCiudades = [];
            return this.parametricasService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id).subscribe((data: any) => {
                this.ciudades = data;
                this.cargaUbicacionEdita();
                if (event != null) {
                    let existeFiltro: boolean;
                    for (let i = 0; i < this.ciudades.length; i++) {
                        const filtro = this.ciudades[i];
                        if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                            this.filtroCiudades.push(filtro);
                            existeFiltro = true;
                        }
                    }
                    if (existeFiltro) {
                        this.ciudades = this.filtroCiudades;
                    }
                }
            });
        }

    }

    cargaUbicacionEdita() {
        this.mensajeUbicacionDialog = "Editar Ubicación";
        this.ciudadSeleccionada = this.ciudades.find(x => x.id === this.ubicacionPeajeEdita.idMunicipio);
        this.tipoUbicacionSeleccionada = this.tipoUbicacion.find(x => x.id === this.ubicacionPeajeEdita.tipoUbicacion);
        this.pjeUbicacionPeajeDTO = this.ubicacionPeajeEdita;
        this.visibleDialogoAgregarUbicacion = true;
    }

    irEditarOperador(id: number) {

        this.mensajeOperadorDialog = "Editar Operador";
        this.operadorEditable = true;
        this.operadorElectronicoEdita = this.operadoresElectronicos.find(x => x.id === id);
        // crea nuevo objeto PjePeajeOperadorPagoElectronicoDTO
        this.peajeOperadorPagoElectronico = new PjePeajeOperadorPagoElectronicoDTO();
        this.peajeOperadorPagoElectronico.id = this.operadorElectronicoEdita.id;
        this.peajeOperadorPagoElectronico.idOperador = this.operadorElectronicoEdita.idOperador;
        this.peajeOperadorPagoElectronico.fechaInicio = new Date(this.operadorElectronicoEdita.fechaInicio);
        this.peajeOperadorPagoElectronico.horaInicio = this.operadorElectronicoEdita.horaInicio;
        this.peajeOperadorPagoElectronico.minutoInicio = this.operadorElectronicoEdita.minutoInicio;
        if (this.operadorElectronicoEdita.fechaFinFormat != 'Vigente') {
            this.peajeOperadorPagoElectronico.fechaFin = new Date(this.operadorElectronicoEdita.fechaFin);
            this.peajeOperadorPagoElectronico.horaFin = this.operadorElectronicoEdita.horaFin;
            this.peajeOperadorPagoElectronico.minutoFin = this.operadorElectronicoEdita.minutoFin;
        }
        this.operadorSeleccionado = this.operadorElectronicoEdita.idOperador;
        this.visibleDialogoAgregarOperador = true;
    }

    irVerMapa(id: number) {
        this.router.navigate([this.router.url + '/consultar-mapa-informacion-general', this.idProyecto, this.peajeSeleccionado.idPeaje]);
    }

    irConfirmarEliminarUbicacion(id: number) {
        this.visibleConfirmarEliminarUbicacion = true;
        this.ubicacionElimina = id;
    }
    cancelarEliminarUbicacion() {
        this.visibleConfirmarEliminarUbicacion = false;
    }
    aceptarEliminarUbicacion() {

        this.blockedDocument = true;

        this.informacionGeneralPeajesService.eliminarUbicacion(this.ubicacionElimina).subscribe(result => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === 1) {
                    this.cargarUbicacionPeajes();
                    this.messageService.add({ severity: 'success', summary: 'Información', detail: 'Registro Eliminado Correctamente', life: 10000 });
                } else {                    
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } 
        },
            error => {
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;
            }
        );



        this.visibleConfirmarEliminarUbicacion = false;
    }

    irConfirmarEliminarOperador(id: number) {
        this.visibleConfirmarEliminarOperador = true;
        this.operadorElimina = id;
    }

    cancelarEliminarOperador() {
        this.visibleConfirmarEliminarOperador = false;
    }

    aceptarEliminarOperador() {

        this.blockedDocument = true;

        this.informacionGeneralPeajesService.eliminarOperador(this.operadorElimina).subscribe(result => {
            if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === 1) {
                    this.cargarOperadoresElectronicos();
                    this.messageService.add({ severity: 'success', summary: 'Información', detail: 'Registro Eliminado Correctamente', life: 10000 });
                } else {
                    console.error(result.respuestaServicio.mensajeSalida);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            }
        },
            error => {
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;
            }
        );


        this.visibleConfirmarEliminarOperador = false;
    }

    cancelarDialogoAgregarOperador() {
        this.visibleDialogoAgregarOperador = false;
    }

    aceptarAgregarOperador() {
        this.visibleDialogoAgregarOperador = false;
        this.peajeOperadorPagoElectronico.idOperador = this.operadorSeleccionado;
        this.peajeOperadorPagoElectronico.idPeaje = this.peajeSeleccionado.idPeaje;
        this.guardarOperadorElectronico();

    }



    guardarOperadorElectronico() {
        this.blockedDocument = true;
        forkJoin(
            this.informacionGeneralPeajesService.guardaOperadorElectronico(this.peajeOperadorPagoElectronico)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                    this.cargarOperadoresElectronicos();
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    guardarInformacionGeneralPeaje() {
        this.informacionGeneralPeaje.fechaInicioPagoElectronico = this.fechaInicioOperadorConvert;
        this.informacionGeneralPeaje.tipoSentido = this.tipoSentidoSeleccionado;
        this.blockedDocument = true;

        forkJoin(
            this.informacionGeneralPeajesService.guardaInformacionGeneral(this.informacionGeneralPeaje)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {
                    this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    cargarUbicacionPeajes() {

        // Bloquear pantalla
        this.blockedDocument = true;

        forkJoin(
            this.informacionGeneralPeajesService.cargaUbicacionesPeaje(this.peajeSeleccionado.idPeaje)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {
                    // recarga información según peaje seleccionado                    
                    this.ubicacionesPeaje = result.peajeUbicaciones;

                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    cargarOperadoresElectronicos() {
        // Bloquear pantalla
        this.blockedDocument = true;

        forkJoin(
            this.informacionGeneralPeajesService.cargaOperadoresElectronicosPeaje(this.peajeSeleccionado.idPeaje)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {
                    // recarga información según peaje seleccionado                    
                    this.operadoresElectronicos = result.peajeOperadorPagoElectronico;

                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }

    cargarInformacionGeneral(event) {
        this.informacionGeneralPeaje = new PjeInformacionGeneralPeajeDTO();
        this.informacionGeneralPeaje.idPeaje = this.peajeSeleccionado.idPeaje;
        // Bloquear pantalla
        this.blockedDocument = true;
        forkJoin(
            this.informacionGeneralPeajesService.cargaInformacionGeneralPeaje(this.peajeSeleccionado.idPeaje, this.idProyecto)
        ).subscribe(([result]) => {
            if (result != null && result.respuestaServicio != null) {
                // Si el resultado es exitoso...
                if (result.respuestaServicio.codigoSalida === 1) {
                    // recarga información según peaje seleccionado                    
                    this.informacionPeajeGet = result.informacionGeneralPeajes;
                    this.informacionGeneralPeaje = this.informacionPeajeGet;
                    if (this.informacionGeneralPeaje.fechaInicioPagoElectronico != null) {
                        this.fechaInicioOperadorConvert = new Date(this.informacionGeneralPeaje.fechaInicioPagoElectronico);
                        this.informacionGeneralPeaje.horaFechaInicio = this.fechaInicioOperadorConvert.getHours();
                        this.informacionGeneralPeaje.minutoFechaInicio = this.fechaInicioOperadorConvert.getMinutes();
                        this.cargarOperadoresElectronicos();
                    }else{
                        this.fechaInicioOperadorConvert = null;
                        this.operadoresElectronicos = [];
                    }
                    this.cargarUbicacionPeajes();
                    this.tipoSentidoSeleccionado = this.informacionGeneralPeaje.tipoSentido;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
            } else {
            }
        },
            error => {
                this.blockedDocument = false;
            },
            () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
            }
        );
    }
}



