import { Injectable, } from '@angular/core';
import { ConfigService } from '../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NewEditRadicacionWebService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };


    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo que carga un archivo de prueba
     * @param data 
     */
    public uploadFileRemisorio(data) {
        return this.http.post(this.END_POINT + '/RadicacionWeb/cargueArchivo/remisorio', data)
            .pipe(
                retry(1));
    }

        /**
     * Metodo que carga un archivo de prueba
     * @param data 
     */
    public uploadFileAnexos(data) {
        return this.http.post(this.END_POINT + '/RadicacionWeb/cargueArchivo/anexos', data)
            .pipe(
                retry(1));
    }

    /**
     * metodo que consulta los tipos de soporte
     * @param modulo 
     * @param idTiposSoporte 
     */
    public getTiposSoporte(modulo, idTiposSoporte) {
        return this.http.get(this.END_POINT + '/TipoSoportes/' + modulo + '/' + idTiposSoporte)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que arga la lista de la info de la tabla vdoVideo
     */
    public getListado() {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/cargarPruebaConcepto')
            .pipe(retry(1));
    }

    /**
     * Metodo que realiza la radicacion
     * @param data 
     */
    public postDocumentoRadicado(data) {
        return this.http.post(this.END_POINT + '/RadicacionWeb/documentoRadicado', data)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que realiza la radicacion
     * @param data 
     */
    public postDocumentoBorrador(data) {
        return this.http.post(this.END_POINT + '/RadicacionWeb/documentoRadicado/borrador', data)
            .pipe(
                retry(1));
    }

    /**
     * Metodo que carga la lista de la info de la tabla tipoArchivoDocumentoRadicado
     */
    public getListadoTipoArchivoDocumentoRadicado() {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/lista/tipoArchivoDocumentoRadicado')
            .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de la info de la tabla tipoDocumentoRadicado
     */
    public getListadoTipoDocumentoRadicado() {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/lista/tipoDocumentoRadicado')
            .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de la info de la tabla personaDocumentoRadicado
     */
    public getListadoPersonaDocumentoRadicado(idDocumentoRadicado) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/personaDocumentoRadicado/' + idDocumentoRadicado)
            .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de la info de la tabla archivoDocumentoRadicado
     */
    public getListadoArchivoDocumentoRadicado(idDocumentoRadicado) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/archivoDocumentoRadicado/' + idDocumentoRadicado)
            .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de la info de la tabla consultaArchivo
     */
    public getListadoConsultaArchivo(idArchivoDocumentoRadicado) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/listado/consultaArchivo/' + idArchivoDocumentoRadicado)
            .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de la info de la tabla firmantes
     */
    public getListadoFirmantes(idProyecto) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/listado/firmantes/' + idProyecto)
            .pipe(retry(1));
    }

    /**
     * Metodo que carga la lista de la info de la tabla firmantes
     */
     public getDatosInformacion(idProyecto) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/datos/informacion/' + idProyecto)
            .pipe(retry(1));
    }

    
    /**
     * Metodo que carga la lista de la info de la tabla radicado
     */
    public getDatosInformacionRadicado(idRadicado) {
        return this.http.get(environment.baseUrl + '/RadicacionWeb/datos/radicado/' + idRadicado)
            .pipe(retry(1));
    }

    /**
     * Metodo que realiza la Firmantes
     * @param data 
     */
    public postFirmantes(data) {
        return this.http.post(this.END_POINT + '/RadicacionWeb/firmantes', data)
            .pipe(retry(1));
    }



}
