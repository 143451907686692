export class ProyectoCarretero {
    constructor(
        public idProyecto: number,
        public codigo: string,
        public nombre: string,
        public nombreCorto: string,
        public rutaNacional: string,
        public idGeneracion?: number,
        public idOla?: number,
        public idTipo?: number) { }
}