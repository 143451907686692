import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { ConcesionarioCarreteroService } from './concesionario-carretero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Contrato } from './contrato';
import { Contratista } from './contratista';
import { Accionista } from './accionista';
import { AccionistaContratista } from './accionista-contratista';
import { ParametricasService } from './../parametricas/parametricas.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { AccionistaContratistaEncabezado } from './accionista-contratista-encabezado';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-concesionario-carretero',
  templateUrl: './concesionario-carretero.component.html',
  styleUrls: ['./concesionario-carretero.component.sass']
})
export class ConcesionarioCarreteroComponent implements OnInit {




  nombreConcesionario: any;


  accionistas: any = [];

  displayedColumns: string[] = ['nit', 'nombre', 'participacion', 'acciones'];

  dataSource: MatTableDataSource<Accionista>;

  existeConcesionario: boolean = false;

  departamentos: any = [];
  filtroDepartamentos: any[];
  departamentoSeleccionado: any;

  ciudades: any = [];
  filtroCiudades: any[];
  ciudadSeleccionada: any;



  contrato: Contrato = {};
  concesionario: Contratista = {};
  accionistaContratistaEncabezado: AccionistaContratistaEncabezado = {};



  displayedColumnsComposicionAccionaria: string[] = ['fechaInicio', 'fechaFin', 'acciones'];
  dataSourceComposicionAccionaria: MatTableDataSource<AccionistaContratistaEncabezado>;
  composicionAccionariaLista: any = [];
  composicionAccionaria: AccionistaContratistaEncabezado = {};

  anios: number;
  meses: number;

  visibleDialogoExito: boolean = false;
  visibleDialogoExitoConcesionario: boolean = false;
  visibleDialogoAgregarComposicion: boolean = false;
  habilitaBotonCrearConcesionario: boolean = false;



  visibleConfirmarEliminarComposicion: boolean = false;
  visibleDialogoExitoEliminarComposicion: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private lineaBase: ProyectoLineaBaseComponent, private parametricasService: ParametricasService, private detalleService: ConcesionarioCarreteroService, private route: ActivatedRoute, private ruta: Router, private principalComponent: PrincipalComponent, private messageService: MessageService) { }

  ngOnInit() {
    this.lineaBase.idProyecto === 'new' ? this.cargarNuevo() : this.cargarContrato(this.lineaBase.idProyecto);
  }

  cargarNuevo() {
    this.contrato = {};
    this.lineaBase.idContratoConcesionario = "new";
  }

  public cargarContrato(id: number) {
    this.detalleService.getContratoConcesionarioPorIdProyecto(id, 1).subscribe((data: {}) => {
      this.contrato = data;
      if (this.contrato != null) {
        this.lineaBase.idContratoConcesionario = this.contrato.id;
        this.detalleService.getContratistaConcesionarioPorIdContrato(this.contrato.id).subscribe((data: {}) => {
          this.concesionario = data;
          this.habilitaBotonCrearConcesionario = true;
          if (this.concesionario.id != 0) {
            this.lineaBase.idConcesionario = this.concesionario.id;
            this.nombreConcesionario = this.concesionario.nombre;
            this.existeConcesionario = true;
            this.departamentoSeleccionado = this.concesionario.idDepartamentoNavigation;
            this.ciudadSeleccionada = this.concesionario.idMunicipioUbicacionNavigation;
            this.cargarComposicionAccionariaLista(this.concesionario.id);
            if (this.accionistas != null) {
              this.lineaBase.habilitaTabInterventoria = true;
            }
          } else {
            this.lineaBase.idConcesionario = "new";
          }
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        this.cargarNuevo();
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  visibleFormularioConcesionario() {
    this.lineaBase.visibleFormularioContrato = false;
  }

  visibleFormularioContratoConcesionario() {
    this.lineaBase.visibleFormularioContrato = true;
  }

  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.departamentos.length; i++) {
        let filtro = this.departamentos[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDepartamentos.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.departamentos = this.filtroDepartamentos;
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      let filtro = this.departamentos[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  limpiarCiudades(event) {
    this.ciudadSeleccionada = null;
  }

  cargarCiudades(event) {
    this.filtroCiudades = [];
    return this.parametricasService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id).subscribe((data: {}) => {
      this.ciudades = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.ciudades.length; i++) {
        let filtro = this.ciudades[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroCiudades.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.ciudades = this.filtroCiudades;
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarFiltroCiudades(event) {
    this.filtroCiudades = [];
    for (let i = 0; i < this.ciudades.length; i++) {
      let filtro = this.ciudades[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudades.push(filtro);
      }
    }
  }

  guardarDetalle(forma: NgForm) {
    this.contrato.idProyecto = this.lineaBase.idProyecto;
    this.contrato.idTipo = 1;
    if (this.lineaBase.idContratoConcesionario === 'new') {
      let vara: any = this.detalleService.guardarNuevoContratoConcesionario(this.contrato).subscribe((data: {}) => {
        this.visibleDialogoExito = true;
        let contratoNuevo: Contrato = data;
        this.lineaBase.idContratoConcesionario = contratoNuevo.id;
        this.lineaBase.idConcesionario = "new";
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.detalleService.editarContratoConcesionario(this.contrato, this.lineaBase.idContratoConcesionario).subscribe((data: {}) => {
        this.visibleDialogoExito = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }



  guardarDetalleConcesionario(forma: NgForm) {
    this.concesionario.idTipo = 1;
    this.concesionario.idContrato = this.lineaBase.idContratoConcesionario;
    this.concesionario.IdMunicipioUbicacion = this.ciudadSeleccionada.id;
    if (this.lineaBase.idConcesionario === 'new') {
      let vara: any = this.detalleService.guardarNuevoContratistaConcesionario(this.concesionario).subscribe((data: {}) => {
        this.visibleDialogoExitoConcesionario = true;
        let concesionarioNuevo: Contratista = data;
        this.lineaBase.idConcesionario = concesionarioNuevo.id;
        this.nombreConcesionario = concesionarioNuevo.nombre;
        this.existeConcesionario = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.detalleService.editarContratistaConcesionario(this.concesionario, this.lineaBase.idConcesionario).subscribe((data: {}) => {
        this.visibleDialogoExitoConcesionario = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }



  cargarComposicionAccionariaLista(idContratista) {
    return this.detalleService.getComposicionAccionariaPorIdContratista(idContratista).subscribe((data: {}) => {
      this.composicionAccionariaLista = data;
      this.dataSourceComposicionAccionaria = new MatTableDataSource(this.composicionAccionariaLista);
    });
  }

  agregarComposicionAccionaria(forma: NgForm) {
    if (this.visibleDialogoAgregarComposicion) {
      this.accionistaContratistaEncabezado.idContratista = this.lineaBase.idConcesionario;
      if (this.lineaBase.idEncabezado === 'new') {
        let vara: any = this.detalleService.guardarNuevaComposicionAccionaria(this.accionistaContratistaEncabezado).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
          this.cargarComposicionAccionariaLista(this.concesionario.id);
          this.lineaBase.habilitaTabInterventoria = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        this.detalleService.editarComposicionAccionaria(this.accionistaContratistaEncabezado, this.lineaBase.idEncabezado).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
          this.cargarComposicionAccionariaLista(this.concesionario.id);
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      }
    }
  }

  editarComposicionAccionariaPorId(idEncabezado) {
    this.lineaBase.idEncabezado = idEncabezado;
    return this.detalleService.getComposicionAccionariaPorId(idEncabezado).subscribe((data: {}) => {
      this.accionistaContratistaEncabezado = data;
      this.accionistaContratistaEncabezado.fechaInicio = new Date(this.accionistaContratistaEncabezado.fechaInicio);
      this.visibleDialogoAgregarComposicion = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  editarComposicionAccionaria(idEncabezado) {
    this.lineaBase.idEncabezado = idEncabezado;
    this.lineaBase.visibleListaAccionistas = true;
    return this.detalleService.getAccionistaPorIdEncabezado(idEncabezado).subscribe((data: {}) => {
      this.lineaBase.accionistas = data;
      this.lineaBase.dataSourceAccionistas = new MatTableDataSource(this.lineaBase.accionistas);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.habilitaBotonCrearConcesionario = true;
    this.visibleDialogoAgregarComposicion = false;
    if (this.concesionario.id != 0) {
      this.lineaBase.idConcesionario = this.concesionario.id;
    } else {
      this.lineaBase.idConcesionario = "new";
    }
  }

  aceptarDialogoExitoConcesionaio() {
    this.visibleDialogoExitoConcesionario = false;
    this.visibleDialogoAgregarComposicion = false;
    this.existeConcesionario = true;
  }

  visibleAgregarComposicion() {
    this.visibleDialogoAgregarComposicion = true;
    this.accionistaContratistaEncabezado = {};
    this.lineaBase.idEncabezado = "new";
  }

  cancelarAgregarComposicion() {
    this.visibleDialogoAgregarComposicion = false;
  }

  irEliminarComposicion(id) {
    this.visibleConfirmarEliminarComposicion = true;
    this.lineaBase.idEncabezado = id;
  }

  aceptarEliminarComposicion() {
    return this.detalleService.eliminarComposicionAccionaria(this.lineaBase.idEncabezado, this.lineaBase.idConcesionario).subscribe((data: {}) => {
      this.visibleConfirmarEliminarComposicion = false;
      this.cargarComposicionAccionariaLista(this.concesionario.id);
      this.visibleDialogoExitoEliminarComposicion = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarComposicion() {
    this.visibleDialogoExitoEliminarComposicion = false;
  }


  cancelarEliminarComposicion() {
    this.visibleConfirmarEliminarComposicion = false;
  }

}
