import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InversionProyectoDetalleService } from './inversion-proyecto-detalle.service';
import { InversionProyecto } from '../inversion-proyecto/inversionProyecto';
import { MatTableDataSource } from '@angular/material';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-inversion-proyecto-detalle',
  templateUrl: './inversion-proyecto-detalle.component.html',
  styleUrls: ['./inversion-proyecto-detalle.component.sass']
})
export class InversionProyectoDetalleComponent implements OnInit {

  inversionProyecto: InversionProyecto = {};

  idInversionProyectoDetalle: any;

  idProyecto: number;
  proyecto: any;

  visibleDialogoExito: boolean = false;

  constructor(private infoService: InformacionGeneralService, private detalleService: InversionProyectoDetalleService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idInversionProyectoDetalle = params.id;
      this.idInversionProyectoDetalle === 'new' ? this.cargarNuevo(this.idInversionProyectoDetalle) : this.cargarInversionProyecto(this.idInversionProyectoDetalle);
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
    });
  }

  cargarNuevo(id) {
    this.inversionProyecto = {};
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarInversionProyecto(id: number) {
    this.detalleService.getInversionProyectoPorId(id).subscribe((data: {}) => {
      this.inversionProyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.ruta.navigate(['/finInversionProyecto', this.idProyecto]);
  }

  guardarDetalle(forma: NgForm) {
    this.visibleDialogoExito = true;

    if (this.idInversionProyectoDetalle === 'new') {
      this.inversionProyecto.idProyecto = this.idProyecto;
      this.detalleService.guardarNuevoInversionProyecto(this.inversionProyecto).subscribe((data: {}) => {
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.detalleService.editarInversionProyecto(this.inversionProyecto).subscribe((data: {}) => {
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

}
