import { Component, OnInit, ViewChild } from '@angular/core';
import { InversionService } from './inversion.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Inversion } from './inversion';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-inversion',
  templateUrl: './inversion.component.html',
  styleUrls: ['./inversion.component.sass'],
  providers: [InversionService]
})
export class InversionComponent implements OnInit {

  inversiones: any = [];

  displayedColumns: string[] = ['unidadFuncional', 'nombre', 'longitud', 'tipoIntervencion', 'capex', 'opex'];

  dataSource: MatTableDataSource<Inversion>;

  idProyecto: number;
  fechaReferencia: Date;
  proyecto: any;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private infoService: InformacionGeneralService, private infoInversion: InversionService, private route: Router, private ruta: ActivatedRoute,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.fechaReferencia = params.fechaReferencia;
      this.cargarProyectoCarretero(this.idProyecto);
      this.cargarInversiones(this.idProyecto, this.fechaReferencia);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onNew() {
    this.route.navigate(['/finInversionDetalle', this.idProyecto, 'new', this.fechaReferencia]);
  }

  onEdit(id: number) {
    this.route.navigate(['/finInversionDetalle', this.idProyecto, id, this.fechaReferencia]);
  }

  cargarInversiones(id: number, fechaReferencia: Date) {
    return this.infoInversion.getInversiones(id, fechaReferencia).subscribe((data: {}) => {
      this.inversiones = data;
      this.dataSource = new MatTableDataSource(this.inversiones);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    })
  }

  convertStringDateToTime(data) {
    const date = new Date(data);
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = date.getTime() + localOffset;
    return utc;
  }

}
