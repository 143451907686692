import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AmparosService } from './amparos.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { InformacionGeneralAnexos, Anexo } from './informacionGeneral';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { Location } from '@angular/common';

@Component({
	selector: 'app-amparos',
	templateUrl: './amparos.component.html',
	styleUrls: ['./amparos.component.sass'],
	providers: [AmparosService]
})

export class AmparosComponent implements OnInit {

	loading: boolean;
	resultadoInfo: any;
	displayedColumnsAmparos;
	displayedColumns: string[] = ['No. Anexo', 'Fecha Expedición', 'Asociado a', 'Observacion', 'acciones'];
	listaAnexos: any = [];
	idProyecto: any;
	idPoliza: any;
	nombreProyecto;
	visibleConfirmarEliminarAnexo: boolean = false;
	idAnexoEliminar: any;
	anexo: Anexo = {};

	constructor(
		private messageService: MessageService,
		private amparosService: AmparosService,
		private route: Router,
		private ruta: ActivatedRoute,
		public dialog: MatDialog, 
		private principalComponent: PrincipalComponent, public location: Location) {}

	ngOnInit() {
		this.loading = true;	
		this.idProyecto = localStorage.getItem('idProyecto');
		this.idPoliza = localStorage.getItem('idPoliza');
		this.ruta.params.subscribe(params => {
			this.idPoliza = params.id;			
			this.cargarAnexosPoliza(this.idPoliza);
		});
		this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));

		this.displayedColumnsAmparos = [
			{
			field: 'numero',
			header: 'No. Anexo'
			},	
			{
				field: 'fechaExpedicionStr',
				header: 'Fecha Expedición'
			},
			{
				field: 'novedadContrato',
				header: 'Asociado a'
			},
			{
				field: 'observacion',
				header: 'Observacion'
			}
		];

	}

	/**
	 * Metodo que pasa la pagina de creacion de un nuevo anexo con sus amparos
	 */
	onNew() {
		localStorage.setItem('tipoSolicitud', "onNew");
		this.route.navigate([this.route.url+'/anexoEdit', 'new']);
	}

	/**
	 * Metodo que pasa la pagina de editar un anexo con sus amparos
	 * @param objeto 
	 */
	onEdit(objeto) {
		localStorage.setItem('tipoSolicitud', "onEdit");		
		localStorage.setItem('habilitarModificar', ""+objeto.habilitarModificar );	
		this.route.navigate([this.route.url+'/anexoEdit', objeto.id]);
	}

	/**
	 * Metodo que carga la lista de anexos
	 * @param idPoliza 
	 */
	cargarAnexosPoliza(idPoliza) {
		forkJoin(
			this.amparosService.getAnexosPoliza(idPoliza)
		).subscribe(([result]) => {
				this.resultadoInfo = result;
				if (this.resultadoInfo != null && this.resultadoInfo.respuestaServicio != null) {
					if (this.resultadoInfo.respuestaServicio.codigoSalida === 1) {
						var pos = 0;
						this.listaAnexos = [];
						this.resultadoInfo.anexosPolizas.forEach(element => {
							this.anexo = new Anexo();
							this.anexo.id = element.id;
							this.anexo.idPoliza = element.idPoliza;
							this.anexo.fechaExpedicion = element.fechaExpedicion;
							this.anexo.fechaExpedicionStr = new Date(element.fechaExpedicion).toLocaleDateString();
							this.anexo.idMonedaPoliza = element.idMonedaPoliza;
							this.anexo.idNovedadContrato = element.idNovedadContrato;
							this.anexo.monedaPoliza = element.monedaPoliza;
							this.anexo.novedadContrato = element.novedadContrato;
							this.anexo.numero = element.numero;
							this.anexo.objeto = element.objeto;
							this.anexo.observacion = element.observacion;
							this.anexo.habilitarEditar = element.habilitarEditar;
							this.anexo.habilitarEliminar = element.habilitarEliminar;
							this.anexo.orden = element.orden;
							if(pos==0){
								this.anexo.habilitarModificar = true;
							}else{
								this.anexo.habilitarModificar = false;
							}
							pos++;
							localStorage.setItem('monedaAnexo', element.idMonedaPoliza);
							this.listaAnexos.push(this.anexo);
						  });
					} else {
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: this.resultadoInfo.respuestaServicio.mensajeSalida,
							life: 10000
						});
					}
				} else {}
			},error => {
				this.loading = false;
				this.principalComponent.cargarErrorServicio(error);
			  },
			() => {
				this.loading = false;
			}
		);
	}

	/**
	 * Metodo para eliminar una poliza
	 * @param idAnexo
	 */
	 onDelete(idAnexo: number) {
		this.visibleConfirmarEliminarAnexo = true;
		this.idAnexoEliminar = idAnexo;
	}

	/**
	 * Metodo que acepta la eliminacion
	 */
	aceptarEliminarAnexo(){
		this.loading = true;
		if(this.idAnexoEliminar!= null){
			return this.amparosService.deleteAnexoPoliza(this.idAnexoEliminar).subscribe((data: {}) => {
				this.idAnexoEliminar = null;
				this.visibleConfirmarEliminarAnexo = false;
				this.cargarAnexosPoliza(this.idPoliza);
			},error => {
				this.principalComponent.cargarErrorServicio(error);
			  });
		}
	}

	/**
	 * Metodo que oculta el model de eliminacion
	 */
	cancelarEliminarAnexo(){
		this.idAnexoEliminar = null;
		this.visibleConfirmarEliminarAnexo = false;
	}

	/**
	 * Metodo que vuelve a la lista de las polizas
	 */
	 volverListaPoliza(){
		localStorage.removeItem('monedaAnexo');	
		localStorage.removeItem('habilitarModificar');
		//this.route.navigate(['/carreteros/infGeneral/proyectoHome/'+this.idProyecto+'/polizas', this.idProyecto]);
		this.location.back();
	}

	/**
	 * Metodo para convertir una fecha
	 * @param data 
	 */
	convertStringDateToTime(data) {
		const date = new Date(data);
		const localOffset = date.getTimezoneOffset() * 60000;
		const utc = date.getTime() + localOffset;
		return utc;
	}

}