import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { EnumFechaFormato, EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ContratacionService } from '../contratacion.service';
import { CtoContratoInformacionVigenteEncabezadoDTO, CtoSupervisorContrato } from '../contratacion-dto/contrato-informacion-vigente-encabezado';
import { EnumCtoEstadoContrato } from '../constantes/contratacion-constantes';
import { Router } from '@angular/router';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { __asyncDelegator } from 'tslib';

@Component({
  selector: 'app-contrato-dependencia',
  templateUrl: './contrato-dependencia.component.html',
  styleUrls: ['./contrato-dependencia.component.sass']
})
export class ContratoDependenciaComponent implements OnInit {
  visibleDialogoExito: boolean = false;

  dependenciasPrimerNivel: any[];
  dependeciasSegundoNivel: any[];

  contratoSeleccionado: CtoContratoInformacionVigenteEncabezadoDTO = null;
  supervisores: CtoSupervisorContrato[] = null;

  contratos: CtoContratoInformacionVigenteEncabezadoDTO[];
  totalRecords: any;
  documentoBloqueado: boolean = false;

  dependencias = {};
  puedeGuardar = {};

  // Filtros
  buscarFiltro: string;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private parametricasService: ParametricasService,
    private servicioContratacion: ContratacionService,
    private componentePrincipal: PrincipalComponent) { }

  ngOnInit() {
    this.documentoBloqueado = false;
    this.contratos = [];
    this.CargarDependenciasPrimerNivel();
  }

  // Informativo - Dialogo
  public AceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.router.navigate([this.router.url]);
  }

  public Buscar() {
    const filaDesde = 0;
    const cantidadRegistros = 20;
    this.documentoBloqueado = true;
    this.CargarContratos(filaDesde, cantidadRegistros, 'Id', 2);
  }

  public CargarContratos(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.documentoBloqueado = true;
    this.servicioContratacion.srvConsultarContratosInformacionVigente(null, null, null, null, null, new Date().getFullYear().toString(), null, filaDesde, cantidadRegistros, this.buscarFiltro, campoOrden, ascDesc)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.contratos = result.contratosInformacionVigente;
              this.totalRecords = this.contratos.length == 0 ? 0 : this.contratos[0].totalRegistros;
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.componentePrincipal.cargarErrorServicio(error);
          this.documentoBloqueado = false;
        },
        () => {
          this.documentoBloqueado = false;
        });
  }

  public CargarDependenciasPrimerNivel() {
    return this.parametricasService.srvListarDependenciasPorIdPadre(1).subscribe(
      (respuesta: any) => {
        if (respuesta.respuestaServicio.codigoSalida == EnumRespuestaServicio.CODIGO_ERROR_OPERACION) {
          this.componentePrincipal.cargarErrorServicio(respuesta.respuestaServicio.mensajeSalida);
          return;
        }

        this.dependenciasPrimerNivel = respuesta.dependencias.map((dependencia: any) => {
          return { id: dependencia.id, nombre: dependencia.nombre };
        });
      },
      error => {
        this.componentePrincipal.cargarErrorServicio(error);
      },
      () => {
      });
  }

  public CargarDependenciasSegundoNivel(idPersona: number, evento: any) {
    this.puedeGuardar[idPersona] = false;
    if (evento.value != null) {
      return this.parametricasService.ConsultarDependenciasAniPorIdDependenciaPrimerNivel(evento.value.id).subscribe(
        (respuesta: any[]) => {
          this.dependencias[idPersona].dependencias = respuesta.map((dependencia: any) => {
            return { id: dependencia.id, nombre: dependencia.nombre };
          });
        },
        error => {
          this.componentePrincipal.cargarErrorServicio(error);
        },
        () => {
        });
    }
    else {
      this.dependeciasSegundoNivel = [];
    }
  }

  public CargarSupervisores() {
    this.documentoBloqueado = true;
    this.dependencias = {};
    this.puedeGuardar = {};

    this.servicioContratacion.ObtenerSupervisoresContrato(this.contratoSeleccionado.id).subscribe(respuesta => {
      if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: respuesta.respuestaServicio.mensajeSalida, life: 10000 });
        return;
      }

      this.supervisores = respuesta.supervisores.map((supervisor: CtoSupervisorContrato) => {
        this.dependencias[supervisor.idPersona] = { primerNivel: supervisor.dependenciaPrimerNivel, segundoNivel: supervisor.dependencia };
        this.CargarDependenciasSegundoNivel(supervisor.idPersona, { value: { id: supervisor.dependenciaPrimerNivel.id } });
        return supervisor;
      });
    },
      error => {
        console.error(error);
        this.componentePrincipal.cargarErrorServicio(error);
        this.documentoBloqueado = false;
      },
      () => {
        this.documentoBloqueado = false;
      });
  }

  public DependenciaSegundiNivelCambio(idPersona: number, event: any) {
    this.puedeGuardar[idPersona] = event.value != null;
  }

  public GuardarDependencia(supervisor: CtoSupervisorContrato) {
    if (supervisor.dependenciaPrimerNivel == null || supervisor.dependencia == null) {
      this.componentePrincipal.cargarErrorServicio({ error: { message: "Debe seleccionar la dependencia a la que pertenece" } });
      return;
    }

    this.documentoBloqueado = true;

    this.servicioContratacion.GuardarDependenciaSupervisionContrato(supervisor.idPersona, supervisor.idContrato, supervisor.dependencia.id).subscribe(
      respuesta => {
        if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.componentePrincipal.cargarErrorServicio(respuesta.respuestaServicio.mensajeSalida);
          return;
        }

        this.visibleDialogoExito = true;
        this.puedeGuardar[supervisor.idPersona] = false;
      },
      error => {
        this.componentePrincipal.cargarErrorServicio(error);
      },
      () => {
        this.documentoBloqueado = false;
      });
  }

  public IrContratosHome() {
    this.router.navigate(['/contratosHome']);
  }

  public LimpiarFiltros() {
    this.buscarFiltro = null;
    this.Buscar();
  }

  public LoadDataLazy(event: LazyLoadEvent) {
    this.documentoBloqueado = true;
    this.contratos = [];
    this.CargarContratos(event.first, event.rows, 'Id', 1);
  }

  public VerSupervisores(contrato: CtoContratoInformacionVigenteEncabezadoDTO) {
    this.contratoSeleccionado = contrato;
    this.CargarSupervisores();
  }

  public VolverListadoContratos() {
    this.supervisores = null;
    this.contratoSeleccionado = null;
  }

  get EnumCtoEstadoContrato() {
    return EnumCtoEstadoContrato;
  }
}
