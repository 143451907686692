export class LongitudConcesionadaProyecto {
    constructor(
        public id?: number,
        public idConfiguracion?: number,
        public longitudConcesionada?: number,
        public longitudAcargo?: number,
        public tieneCapexOpexPorProyecto?: boolean,
        public capex?: number,
        public fechaReferenciaCapex?: Date,
        public opex?: number,
        public fechaReferenciaOpex?: Date,
        public usuarioActualizacion?: number,
        public fechaInicioSistema?: Date,        
        public fechaFinSistema?: Date,
        public editable?: boolean,
        public aplicaCapexOpex?: boolean){ }
}