import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InformacionGeneralFinancieraDetalleService } from './informacion-general-detalle.service';
import { InformacionGeneralFinanciera } from '../informacion-general/informacionGeneralFinanciera';
import { MatTableDataSource } from '@angular/material';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { NgForm } from '@angular/forms';
import { InformacionGeneralFinancieraService } from '../informacion-general/informacion-general.service';
import { DatosGeneralesFinancieroIndexado } from '../informacion-general/datos-generales-financiero-indexado';
import { InformacionGeneralBaseComponent } from '../informacion-general-base/informacion-general-base.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-informacion-general-financiera-detalle',
  templateUrl: './informacion-general-detalle.component.html',
  styleUrls: ['./informacion-general-detalle.component.sass']
})
export class InformacionGeneralDetalleComponent implements OnInit {

  datosGeneralesFinancieroIndexado: DatosGeneralesFinancieroIndexado = {};

  informacionGeneralFinancieraList: any = [];

  displayedColumns: any[] = ['fechaReferencia', 'equity', 'equityFondeado', 'cierreFinancieroPresentado', 'acciones'];

  dataSource: MatTableDataSource<InformacionGeneralFinanciera>;

  informacionGeneralFinancieraSeleccionada: InformacionGeneralFinanciera;

  idInformacionGeneralFinanciera: any;

  visibleAgregarInformacionIndexada: boolean = false;

  visibleDialogoExito: boolean = false;

  visibleConfirmarEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRegistro: boolean = false;




  constructor(private lineaBase: InformacionGeneralBaseComponent, private infoGeneralFinanciera: InformacionGeneralFinancieraService, private detalleService: InformacionGeneralFinancieraDetalleService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.cargarInformacionIndexadaLista(this.lineaBase.idProyecto);
    this.displayedColumns = [
      { field: 'formatoFecha', header: 'Fecha de indexación' },
      { field: 'formatoValorContrato', header: 'Valor del contrato' },
      { field: 'formatoEquity', header: 'Equity' },
      { field: 'formatoEquityFondeado', header: 'Equity fondeado' },
      { field: 'formatoCierreFinancieroPresentado', header: 'Cierre financiero presentado' }
    ];
  }

  cargarInformacionIndexadaLista(idProyecto) {
    return this.infoGeneralFinanciera.getInformacionGeneralFinancieraIndexadaPorProyecto(idProyecto).subscribe((data: {}) => {
      this.informacionGeneralFinancieraList = data;
      this.dataSource = new MatTableDataSource(this.informacionGeneralFinancieraList);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  irAgregarInformacionIdexada() {
    this.visibleAgregarInformacionIndexada = true;
    this.idInformacionGeneralFinanciera = "new";
    this.datosGeneralesFinancieroIndexado = {};
  }

  cancelarAgregarInformacionIdexada() {
    this.visibleAgregarInformacionIndexada = false;
  }

  guardarInformacionGeneralIndexada(forma: NgForm) {
    if (this.idInformacionGeneralFinanciera === 'new') {
      this.datosGeneralesFinancieroIndexado.idProyecto = this.lineaBase.idProyecto;
      let vara: any = this.infoGeneralFinanciera.guardarInformacionGeneralFinancieraIndexada(this.datosGeneralesFinancieroIndexado).subscribe((data: {}) => {
        this.cargarInformacionIndexadaLista(this.lineaBase.idProyecto);
        this.visibleDialogoExito = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.infoGeneralFinanciera.editarInformacionGeneralFinancieraIndexada(this.datosGeneralesFinancieroIndexado, this.idInformacionGeneralFinanciera).subscribe((data: {}) => {
        this.cargarInformacionIndexadaLista(this.lineaBase.idProyecto);
        this.visibleDialogoExito = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }

  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.visibleAgregarInformacionIndexada = false;
  }

  editarInformacionGeneralIndexada(informacionGeneralFinanciera: InformacionGeneralFinanciera) {
    this.informacionGeneralFinancieraSeleccionada = informacionGeneralFinanciera;
    this.idInformacionGeneralFinanciera = this.informacionGeneralFinancieraSeleccionada.id;
    this.visibleAgregarInformacionIndexada = true;
    return this.infoGeneralFinanciera.getInformacionGeneralFinancieraIndexadaPorId(this.informacionGeneralFinancieraSeleccionada.id).subscribe((data: {}) => {
      this.datosGeneralesFinancieroIndexado = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irEliminarInformacionGeneralIndexada(informacionGeneralFinanciera: InformacionGeneralFinanciera) {
    this.informacionGeneralFinancieraSeleccionada = informacionGeneralFinanciera;
    this.visibleConfirmarEliminarRegistro = true;
    this.idInformacionGeneralFinanciera = this.informacionGeneralFinancieraSeleccionada.id;
  }

  cancelarEliminarInformacionGeneralIndexada() {
    this.visibleConfirmarEliminarRegistro = false;
  }

  aceptarEliminarInformacionGeneralIndexada() {
    return this.infoGeneralFinanciera.eliminarInformacionGeneralFinancieraIndexadaPorId(this.idInformacionGeneralFinanciera).subscribe((data: {}) => {
      this.cargarInformacionIndexadaLista(this.lineaBase.idProyecto);
      this.visibleDialogoExitoEliminarRegistro = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarInformacionGeneralIndexada() {
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleConfirmarEliminarRegistro = false;
  }

}
