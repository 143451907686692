import { Component, OnInit } from '@angular/core';
import { InteresadosExternosService } from './interesados-externos.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProyectoInteresados, TrayectoModel } from '../../../dtos/interesados-externos/proyecto';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-interesados-externos',
  templateUrl: './interesados-externos.component.html',
  styleUrls: ['./interesados-externos.component.sass'],
  providers: [InteresadosExternosService]
})


export class InteresadosExternosComponent implements OnInit {
  displayedColumns1;
  displayedColumns2;

  objectTrayectos: any = [];
  objectInteresados: any = [];
  message;
  visibleDialogoAgregarServicio: boolean = false;
  visibleDialogoVisualizarServicio: boolean = false;
  visibleDialogoVisualizarInteresados: boolean = false;
  visibleConfirmarEliminar: boolean = false;
  eliminarTemp;
  serviciosInteresados: ProyectoInteresados = {};
  serviciosBuscar: any;

  serviciosInteresadosForm: FormGroup;
  serviciosExternos: FormGroup;
  idTrayectoSend;
  idRegistro;
  trayectoSeleccionado: TrayectoModel;
  idProyectoDetalle;
  visibleDialogoExito: boolean = false;
  visibleDialogoContacto: boolean = false;

  fechaSalidaConvert;
  fechaLlegadaConvert;
  proyectos: any = [];
  id: number;
  nombreProyecto;
  displayedColumns: string[] = [ 'id', 'idTipo', 'idEtapaActual', 'priorizacion','descripcion', 'fechaInicio', 'acciones'];
  dataSeguimiento;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>


  constructor(
    private info: InteresadosExternosService,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
    ) { 
    }


  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarTrayectos();
      localStorage.setItem('idP', JSON.stringify(this.id));

    });

    this.displayedColumns1 = [
      { field: 'nombre', header: 'Título' },
      { field: 'trayecto', header: 'Trayecto' },
      { field: 'fechaSalidaStr', header: 'Fecha salida' },
      { field: 'fechaLlegadaStr', header: 'Fecha llegada' },

    ];

    this.displayedColumns2 = [
      { field: 'nit', header: 'NIT' },
      { field: 'nombreEmpresa', header: 'Empresa' },
      { field: 'nombrePersonaContacto', header: 'Persona contacto' },
      { field: 'correoElectronico', header: 'Correo electrónico' },
      { field: 'numeroTelefono', header: 'Número teléfono' },

    ];


    let dataInit = {
      IdTrayecto: null,    
      FechaSalida :  null,
      FechaLlegada: null
    }
    this.getData(dataInit);
  }

  onNew(form: NgForm, payload) {
    this.message = 'Adicionar interesado'
    this.visibleDialogoAgregarServicio = true;
    this.idProyectoDetalle = 'new';
    this.serviciosInteresados = {};
    this.idTrayectoSend = payload.idTrayecto;
    this.idRegistro = payload.id;

    form.reset()
  }

  onFollow(payload: any) {
    this.visibleDialogoVisualizarServicio = true;
    this.serviciosInteresados = payload;
  }

  cancelarAgregarServicio(form: NgForm) {
    this.visibleDialogoAgregarServicio = false;
    form.reset()
  }

  cancelarVisualizar(serviciosInteresadosForm: NgForm) {
    this.visibleDialogoVisualizarServicio = false;
  }

  cargarTrayectos() {
    return this.info.getTrayectos().subscribe((data: {}) => {
      this.objectTrayectos = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
  }

  buscarServicios(form: NgForm) {
    if (this.convertStringDateToTime(this.fechaLlegadaConvert) > this.convertStringDateToTime(this.fechaSalidaConvert)) {


      
      let dataSend = {
        IdTrayecto: this.trayectoSeleccionado.id,    
        FechaSalida :  this.fechaSalidaConvert,
        FechaLlegada: this.fechaLlegadaConvert
    }
      return this.getData(dataSend);

    } else {
      this.lanzarMensajeWarning('La fecha de salida no puede ser mayor o igual a la fecha de llegada ');
    }
  }

  getData(payload) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.info.buscarProyecto(payload).subscribe((data: {}) => {
        let array: any;
        array = data;
        this.proyectos = array.objeto;
        if (array.objeto.length === 0) {
          this.lanzarMensajeWarning('No se encontraron resultados');
        }
        this.dialogSpin.close();
    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  limpiar(payload) {
    this.fechaLlegadaConvert = '';
    this.fechaSalidaConvert = '';
    this.trayectoSeleccionado = {};
    payload.reset();
	
	 let dataInit = {
      IdTrayecto: null,    
      FechaSalida :  null,
      FechaLlegada: null
    }
    this.getData(dataInit);
  }

  guardarDetalle(form: NgForm) {
    this.serviciosInteresados.IdTrayecto = this.idTrayectoSend;
    this.serviciosInteresados.idServicio = this.idRegistro;

    this.visibleDialogoAgregarServicio = false;
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
        return this.info.guardarNuevoInteresado(this.serviciosInteresados).subscribe((data: {}) => {
            let dataArray:any = data;
            this.message = dataArray.respuestaServicio.mensajeSalida;
            this.dialogSpin.close();
            this.serviciosInteresados = {};
            form.resetForm();
            if (dataArray.respuestaServicio.codigoSalida === 1) {
              this.visibleDialogoExito = true;
            } else {
              this.lanzarMensajeError(this.message);
            }
        },error => {
          this.dialogSpin.close();
          this.principalComponent.cargarErrorServicio(error);
        });
    

  }

  infoContacto() {
    this.visibleDialogoContacto = true;
  }

  aceptarDialogoContacto() {
    this.visibleDialogoContacto = false;
  }

  convertStringDateToTime(data) {
      const date = new Date(data);
      const localOffset = date.getTimezoneOffset() * 60000;
      const utc = date.getTime() + localOffset;
      return utc;
    }

    

  ///////////////////////////////Mensajes//////////////////////////////    
  lanzarMensajeInfo(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
  }
    
  lanzarMensajeError(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
  }
    
  lanzarMensajeWarning(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
  }
    
  limpiarMensajes() {
      this.messageService.clear();
  }

    

    

}
