import { Component, OnInit } from '@angular/core';

@Component({
    selector: 's-public-puertos-ficha-proyecto-home',
    templateUrl: './public-puertos-ficha-proyecto-home.component.html'
})
export class PublicPuertosFichaProyectoHomeComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        
    }

    ngOnDestroy() {
    }
}
