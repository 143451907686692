import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ContratoCarretero, HistoricoContrato, NovedadContrato } from '../../informacion-contractual-carretero.dto';
import { forkJoin } from 'rxjs';
import { InformacionContractualCarreteroService } from '../../informacion-contractual-carretero.service';
import { ContratoContratistaCarreteroBaseComponent } from '../contrato-contratista-carretero-base/contrato-contratista-carretero-base.component';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { DetalleGestion } from 'src/app/dtos/temas-gestion/temas-gestion-detalle';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-contrato-carretero',
  templateUrl: './contrato-carretero.component.html',
  styleUrls: ['./contrato-carretero.component.sass']
})
export class ContratoCarreteroComponent implements OnInit {

  blockedDocument: boolean = false;
  historicoContratoLista: HistoricoContrato[] = [];
  visibleAgregarHistorico: boolean;
  historicoContrato: HistoricoContrato = {};
  novedadesContractualesLista: NovedadContrato[] = [];
  novedadContractualSeleccionada: NovedadContrato = null;
  listaEstadosContrato: any = [];
  estadoContratoSeleccionado: any = null;
  idHistoricoContrato: number;
  fechaReferencia: Date;
  soloLectura: boolean;
  visibleConfirmarEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRegistro: boolean = false;
  tiposContrato: SelectorTipoContrato[];
  tipoContratoSeleccionado: SelectorTipoContrato;
  tieneContratoConcesionario: boolean;
  fechaSuscripcion: Date;
  fechaInicio: Date;
  fechaReferenciaContrato: Date;
  habilitaModificacionContractual: boolean;




  constructor(public lineaBase: ContratoContratistaCarreteroBaseComponent, private informacionContractualCarreteroService: InformacionContractualCarreteroService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private parametricasService: ParametricasService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    if (this.lineaBase.idContrato != 0) {
      this.cargarContrato();
    } else {
      this.lineaBase.labelTipoContratista = "Contratista";
      this.lineaBase.habilitaTabContratista = true;
      this.tieneContratoConstruccion();


    }
    this.cargarHistoricosContrato();
    this.cargarNovedadesContractualesSinContrato();
    this.cargarEstadosContrato();
  }

  tieneContratoConstruccion() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.TieneContratoConstruccion(this.lineaBase.idProyecto)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.tieneContratoConcesionario = result.tieneContrato;
          if (this.tieneContratoConcesionario) {
            this.tiposContrato = [
              { id: 2, nombre: 'Interventoria' }
            ];
          } else {
            this.tiposContrato = [
              { id: 1, nombre: 'Construcción' },
              { id: 2, nombre: 'Interventoria' }
            ];
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarEstadosContrato() {
    return this.parametricasService.getEstadosContrato().subscribe((data: {}) => {
      this.listaEstadosContrato = data;
    });
  }

  cargarContrato() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.consultarContratoPorId(this.lineaBase.idContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.lineaBase.contrato = result.contratoCarretero;
          this.fechaSuscripcion = new Date(this.lineaBase.contrato.fechaSuscripcion);
          this.fechaInicio = new Date(this.lineaBase.contrato.fechaInicio);
          this.fechaReferenciaContrato = new Date(this.lineaBase.contrato.fechaReferencia);
          if (this.lineaBase.contrato.idTipoContrato == 1) {
            this.lineaBase.labelTipoContratista = "Concesionario";
          } else {
            this.lineaBase.labelTipoContratista = "Interventoria";
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarHistoricosContrato() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.listarHistoricoContratoPorIdContrato(this.lineaBase.idContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.historicoContratoLista = result.historicosContrato;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarHistoricoContratoPorId() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.ConsultarHistoricoContratoPorId(this.idHistoricoContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.historicoContrato = result.historicoContrato;
          this.novedadContractualSeleccionada = this.historicoContrato.idNovedadContratoNavigation;
          this.estadoContratoSeleccionado = this.historicoContrato.idEstadoNavigation;
          this.fechaReferencia = new Date(this.historicoContrato.fechaReferencia);
          this.visibleAgregarHistorico = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarNovedadesContractualesSinContrato() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.listarNovedadesContractualesSinContrato(this.lineaBase.idContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.novedadesContractualesLista = result.novedadesContractuales;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarNovedadesContractuales() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.listarNovedadesContractualesPorContrato(this.lineaBase.idContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.novedadesContractualesLista = result.novedadesContractuales;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  guardarDetalle(forma: NgForm) {
    this.blockedDocument = true;
    if (this.lineaBase.idContrato == 0) {
      this.lineaBase.contrato.idProyecto = this.lineaBase.idProyecto;
      this.lineaBase.contrato.idTipoContrato = this.tipoContratoSeleccionado.id;
    }
    this.lineaBase.contrato.fechaSuscripcion = this.fechaSuscripcion;
    this.lineaBase.contrato.fechaInicio = this.fechaInicio;
    this.lineaBase.contrato.fechaReferencia = this.fechaReferenciaContrato;
    if (this.fechaSuscripcion > this.fechaInicio) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha de acta de inicio debe ser igual o superior a la fecha de suscripción del contrato', life: 10000 });
      this.blockedDocument = false;
    } else {
      forkJoin(
        this.informacionContractualCarreteroService.GuardarContrato(this.lineaBase.contrato)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.lineaBase.contrato = result.contratoCarretero;
            this.lineaBase.habilitaTabContratista = false;
            if (this.lineaBase.contrato.idTipoContrato == 1) {
              this.lineaBase.labelTipoContratista = "Concesionario";
            } else {
              this.lineaBase.labelTipoContratista = "Interventoria";
            }
            this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El contrato se guardó exitosamente.', life: 10000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }
  }

  irAgregarHistorico() {
    this.visibleAgregarHistorico = true;
    this.historicoContrato = {};
    this.historicoContrato.objeto = this.lineaBase.contrato.objeto;
    this.novedadContractualSeleccionada = null;
    this.estadoContratoSeleccionado = null;
    this.fechaReferencia = null;
    this.cargarNovedadesContractualesSinContrato();
    this.habilitaModificacionContractual = false;
  }

  cancelarHistorico() {
    this.visibleAgregarHistorico = false;
  }

  guardarHistorico(forma: NgForm) {
    this.blockedDocument = true;
    this.historicoContrato.idNovedadContrato = this.novedadContractualSeleccionada.id;
    this.historicoContrato.idEstado = this.estadoContratoSeleccionado.id;
    this.historicoContrato.fechaReferencia = this.fechaReferencia;
    this.visibleAgregarHistorico = false;
    forkJoin(
      this.informacionContractualCarreteroService.GuardarHistoricoContrato(this.historicoContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Los valores se registraron exitosamente.', life: 10000 });
          this.visibleAgregarHistorico = false;
          this.cargarHistoricosContrato();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  verHistorico(idHistorico) {
    this.idHistoricoContrato = idHistorico;
    this.cargarHistoricoContratoPorId();
    this.soloLectura = true;
    this.cargarNovedadesContractuales();
  }

  editarHistorico(idHistorico) {
    this.idHistoricoContrato = idHistorico;
    this.cargarHistoricoContratoPorId();
    this.soloLectura = false;
    this.habilitaModificacionContractual = true;
    this.cargarNovedadesContractuales();
  }

  eliminarHistorico(idHistorico) {
    this.idHistoricoContrato = idHistorico;
    this.visibleConfirmarEliminarRegistro = true;
  }

  cancelarEliminarHistoricoContrato() {
    this.visibleConfirmarEliminarRegistro = false;
  }

  aceptarEliminarHistoricoContrato() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.EliminarHistoricoContrato(this.idHistoricoContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.visibleConfirmarEliminarRegistro = false;
          this.visibleDialogoExitoEliminarRegistro = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  aceptarDialogoEliminarHistoricoContrato() {
    this.visibleDialogoExitoEliminarRegistro = false;
    this.cargarHistoricosContrato();
  }

}

interface SelectorTipoContrato {
  id: number;
  nombre: string;
}
