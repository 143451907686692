import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import {
  CmsProyectosContratistaDTO,
  CmsCiudadAeropuertoDTO,
  CmsFranjaHorariaTiqueteAereoDTO,
  CmsItinerarioAereoDTO,
  CmsModosDTO,
  CmsComisionDTO,
  CmsReasignarComisionDTO,
  CmsCdpDTO, CmsComisionLegalizacionDTO, VwCmsAnexoComisionDTO, CmsAnexoComisionDTO, CmsArchivoAnexoComisionDTO, CmsDatosAdicionalesContratoDTO,
  CmsEnviarSolicitudComisionRpt
} from "../comisiones.dto";
import { ComisionesService } from "../comisiones.service";
import { EnumRegex, EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { TrnPersona } from "src/app/admin-personas/trn-persona";
import { PrsFuncionario } from "src/app/admin-personas/prs-funcionario";
import { EnumCmsCodigosErrorComision, EnumCmsConstantes, EnumCmsMaTipoModificacionComision, EnumCmsTipoAnexo } from '../comisiones-constantes';
import { Ciudad } from "src/app/parametricas/ciudad";
import { ParametricasService } from "src/app/parametricas/parametricas.service";
import { TrnMaTipoProyectoDTO } from "src/app/parametricas/trn-ma-tipo-proyecto";
@Component({
  selector: "app-crear-editar-solicitud-comision",
  templateUrl: "./crear-editar-solicitud-comision.component.html",
  styleUrls: ["./crear-editar-solicitud-comision.component.sass"],
})
export class CrearEditarSolicitudComisionComponent implements OnInit {

  blockedDocument: boolean;
  fechaActual: Date;
  cantidadMinCaracteresCampoAbierto = 10;
  mostrarEnviarSolicitud = false;
  persona: TrnPersona;
  detalleFuncionario: PrsFuncionario;
  modificaComision: boolean;
  cdps: CmsCdpDTO[] = [];
  nombreTabLiquidacion: string;

  //Parametros URL
  idSolicitud: number;
  idComisionVersion: number;
  idAsignacion: number;
  soloLectura: boolean;
  revisando: boolean;
  idPersona: number;
  aprobador: boolean;
  legalizando: boolean;

  //Pestana Informacion Basica
  formComision;
  comision: CmsComisionDTO;
  archivos: any = [];
  visualizarGuardarDestinoDialog = false;
  visualizarEliminarDestinoDialog = false;
  cantidadMaximaAdjuntosSoporteObjetoComision = 1;
  clausulaContratista: CmsDatosAdicionalesContratoDTO = {};

  //Pestana Mis Proyectos
  formProyecto;
  filtroProyectos: any[];
  proyectos: any[];
  proyectoSeleccionado: any;
  visualizarProyectoDialog = false;
  visualizarEliminarProyectoDialog = false;

  //Pestana Mis Proyectos - Evento
  formEvento;

  //Pestana Transporte
  formTransporte;
  formItinerarioAereo;
  formSolicitudCancelacionTiquete;
  agregarEditarItinerarioAereoDialog: boolean;
  eliminarItinerarioAereoDialog: boolean;
  cancelarItinerarioAereoDialog: boolean;
  franjaHoraria: CmsFranjaHorariaTiqueteAereoDTO = {};
  franjaHorariaList: CmsFranjaHorariaTiqueteAereoDTO[];
  ciudadAeropuertoOrigen: CmsCiudadAeropuertoDTO = {};
  ciudadAeropuertoDestino: CmsCiudadAeropuertoDTO = {};
  ciudadAeropuertoList: CmsCiudadAeropuertoDTO[];
  itinerarioAereo: CmsItinerarioAereoDTO = {};
  editarItinerarioAereo: boolean;
  numeroMinimoTrayectosItinerario: number;

  date1: Date;
  //Pestana Aprobacion
  visualizarDialogAprobarSolicitud: boolean;
  visualizarDialogEnviarSolicitud: boolean;
  visualizarDialogRechazarSolicitud: boolean;
  visualizarDialogAdjuntarArchivo: boolean;
  visualizarDialogAmpliarComision: boolean;
  visualizarDialogReducirComision: boolean;
  mensajeFlujoAntesEnviarSolicitudComision: string;
  observacionRechazo: string;
  reasignarComision: CmsReasignarComisionDTO;

  //Pestana Modos
  formModos;
  modo: CmsModosDTO = {};
  tiposProyectoAni: TrnMaTipoProyectoDTO[] = [];
  editarModoDialog: boolean;
  porcentajeTemp: number;
  MODOS_PORCENTAJE_MAXIMO = 100;

  //Pestana Liquidacion
  formLiquidacion;

  //Pestana legalizacion
  formTransporteLegalizacion;
  legalizacion: CmsComisionLegalizacionDTO;
  idComisionVersionLegalizacion: number;
  idAnexoLegalizacion: number;
  nombreTipoDocumentoLegalizacion: string;
  rutaCloudStorageInformeLegalzaion: string;
  visibleDialogoSubirInformeLegalizacion = false;
  adjuntosInformeLegalizacion: any = [];
  adjuntosPasabordo: any = [];
  adjuntosAnexos: any = [];
  tipoSoporteSeleccionado: any;
  anexosConsolidado: VwCmsAnexoComisionDTO[];
  visibleDialogoSubirAnexos = false;
  idPasoLegalizacion: number;
  visibleDialogoEliminarDocumentoLegalizacion = false;
  idDocumentoLegalizacion: number;
  idsTipoSoporteLegalizacion: string;
  visibleDialogoEnviarLegalizacion: boolean;
  responderLegalizacionDto: CmsReasignarComisionDTO;
  visibleDialogoAceptarLegalizacion = false;
  visibleDialogoRechazarLegalizacion = false;
  observacionesCancelacion: string;
  habilitarLiquidacionLegalizacion = false;

  cdpList: CmsCdpDTO[];

  //Validaciones
  respuestaServicioEnviarComision: CmsEnviarSolicitudComisionRpt;
  visibleDialogoValidacionEnviarComision = false;

  constructor(
    formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService,
    private parametricasService: ParametricasService
  ) {
    const regexMayorACero = "^[1-9][0-9]*$";
    const regexMayorIgualACero = "^[0-9]+";

    this.fechaActual = new Date();
    this.idsTipoSoporteLegalizacion =
      EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_RESERVA_TIQUETE + ',' +
      EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_COMPRA_TIQUETE + ',' +
      EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_INFORME_FACTURA + ',' +
      EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_INFORME_PASABORDO + ',' +
      EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_INFORME_FACTURA_TRANSPORTE + ',' +
      EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_INFORME_SOPORTE_PEAJES;

    this.cantidadMaximaAdjuntosSoporteObjetoComision = 1;
    this.mostrarEnviarSolicitud = false;

    // Crear formulario Comisión
    this.formComision = formBuilder.group({
      fechaSalida: [null, [Validators.required]],
      fechaRegreso: [null, [Validators.required]],
      objetoComision: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(2000)]],
      justificacionModificacion: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(2000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });

    // Crear formulario Transporte
    this.formTransporte = formBuilder.group({
      terrestre: [null, [Validators.required]],
      aereo: [null, [Validators.required]],
      fluvial: [null, [Validators.required]],
      valorGastosTransporteTerrestre: [null, [Validators.required, Validators.min(1), Validators.maxLength(14), Validators.pattern(regexMayorACero)]],
      valorGastosTransporteFluvial: [null, [Validators.required, Validators.min(1), Validators.maxLength(14), Validators.pattern(regexMayorACero)]],
      valorGastosTransporteAereo: [null, [Validators.required, Validators.min(1), Validators.maxLength(14), Validators.pattern(regexMayorACero)]],
      justificacionGastosTransporte: [null, [Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(2000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });

    // Crear formulario Transporte Legalizacion
    this.formTransporteLegalizacion = formBuilder.group({
      valorGastosTransporteTerrestreLegalizacion: [null, [Validators.required, Validators.min(0), Validators.maxLength(14), Validators.pattern(regexMayorIgualACero)]],
      valorGastosTransporteFluvialLegalizacion: [null, [Validators.required, Validators.min(0), Validators.maxLength(14), Validators.pattern(regexMayorIgualACero)]],
      valorGastosTransporteAereoLegalizacion: [null, [Validators.required, Validators.min(0), Validators.maxLength(14), Validators.pattern(regexMayorIgualACero)]],
    });

    // Crear formulario ItinearrioAerero
    this.formItinerarioAereo = formBuilder.group({
      ciudadOrigen: [null, [Validators.required]],
      ciudadDestino: [null, [Validators.required]],
      fechaHoraVuelo: [null, [Validators.required]],
      franjaHoraria: [null, [Validators.required]],
    });

    // Crear formulario solicitud cancelacion tiquete
    this.formSolicitudCancelacionTiquete = formBuilder.group({
      observacionesCancelacion: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(2000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });

    // Crear formulario Modos
    this.formModos = formBuilder.group({
      porcentajeRecursosViaje: [null, [Validators.required, Validators.max(100), Validators.min(0), Validators.maxLength(3), Validators.pattern(regexMayorIgualACero)]],
    });

    // Crear formulario liquidacion
    this.formLiquidacion = formBuilder.group({
      permiteLiquidacionCero: [null, [Validators.required]],
    });

    // Crear formulario Proyecto
    this.formProyecto = formBuilder.group({
      autoCompletarProyecto: [null, [Validators.required]],
    });

    // Crear formulario Evento
    this.formEvento = formBuilder.group({
      descripcionEvento: [null, [Validators.required, Validators.minLength(this.cantidadMinCaracteresCampoAbierto), Validators.maxLength(2000), Validators.pattern(EnumRegex.REGEX_ALFANUMERICOS_CON_TILDE)]],
    });
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {

    this.comision = {};
    this.comision.viaticos = {};
    this.comision.viaticosLegalizacion = {};
    this.legalizacion = {};
    this.persona = {};
    this.detalleFuncionario = {};
    this.itinerarioAereo.franjasHorariasLista = [];
    this.numeroMinimoTrayectosItinerario = 1;

    // Obtener los atributos de la url
    const idSolicitudParam = "idSolicitud";
    const idComisionVersionParam = "idComisionVersion";
    const idAsignacionParam = "idAsignacion";
    const soloLecturaParam = "soloLectura";
    const revisandoParam = "revisando";
    const idPersonaParam = "idPersona";
    const legalizandoParam = "legalizando";

    this.ruta.params.subscribe((params) => {
      this.idSolicitud = Number(params[idSolicitudParam]);
      this.idComisionVersion = Number(params[idComisionVersionParam]);
      this.idAsignacion = Number(params[idAsignacionParam]);
      this.soloLectura = params[soloLecturaParam] == "true";
      this.revisando = params[revisandoParam] == "true";
      this.idPersona = Number(params[idPersonaParam]);
      this.legalizando = params[legalizandoParam] == "true";
    });

    if (this.legalizando) {
      this.nombreTabLiquidacion = "Liquidación legalizada";
    } else {
      this.nombreTabLiquidacion = "Liquidación";
    }

    this.obtenerComisionPorId(this.idSolicitud);
    this.inicializarParametricas();
    // this.cargarDatosAdicionalesContratoParaComisiones();
  }

  ngAfterViewInit() {
    console.log("Sent from Ng After View Init");
  }


  inicializarParametricas() {
    forkJoin(
      this.comisionesService.srvListarCiudadesAeropuertos(),
      this.comisionesService.srvListarFranjasHorarias(),
      this.parametricasService.srvGetTrnMaTiposProyecto()
    ).subscribe(
      ([ciudadesData, franjasData, tiposProyectoResult]) => {
        this.ciudadAeropuertoList = ciudadesData.ciudadesAeropuerto;
        this.franjaHorariaList = franjasData.franjasHorarias;
        this.tiposProyectoAni = tiposProyectoResult.tiposProyecto;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  cargarPersona(idPersona) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarFuncionarioComisionPorIdPersona(idPersona)
    ).subscribe(
      ([
        funcionarioData
      ]) => {
        this.persona.id = funcionarioData.configuracionFuncionario.id;
        this.persona.nombre = funcionarioData.configuracionFuncionario.nombre;
        this.persona.numeroDocumento = funcionarioData.configuracionFuncionario.numeroDocumento;
        this.persona.correoElectronicoAni = funcionarioData.configuracionFuncionario.correoElectronico;
        this.detalleFuncionario.honorarios = funcionarioData.configuracionFuncionario.ingresos;
        this.detalleFuncionario.dependenciaPrimerNivel = funcionarioData.configuracionFuncionario.dependenciaPrimerNivel;
        this.detalleFuncionario.idDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.idDependenciaPrimerNivel;
        this.detalleFuncionario.liderDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.liderDependenciaPrimerNivel;
        this.detalleFuncionario.dependenciaEspecifica = funcionarioData.configuracionFuncionario.dependencia;
        this.detalleFuncionario.idDependenciaEspecifica = funcionarioData.configuracionFuncionario.idDependencia;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {

        this.blockedDocument = false;
      }
    );
  }

  obtenerComisionPorId(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarComisionPorId(idComision)
    ).subscribe(
      ([comisionData]) => {
        this.comision = comisionData.comisionDetalle;
        if (this.comision !== null) {
          if (idComision == 0) {
            this.comision.viaticos.generaViaticos = true;
            this.comision.fechaSalida = null;
            this.comision.fechaRegreso = null;
          }

          if (this.comision.fechaSalida != null) {
            this.comision.fechaSalida = new Date(this.comision.fechaSalida);
          }
          if (this.comision.fechaRegreso != null) {
            this.comision.fechaRegreso = new Date(this.comision.fechaRegreso);
          }

          if (this.comision.esContratista) {
            this.persona = {};
            this.detalleFuncionario = {};
          } else {
            this.persona = {};
            this.detalleFuncionario = {};
            this.cargarPersona(this.comision.idPersona);
          }

          if (this.soloLectura) {
            this.comision.objeto = this.comision.objeto.replace('<p>', '').replace('</p>', '');
          }
          if (this.comision.valorTentativoTerrestre === 0) {
            this.comision.valorTentativoTerrestre = null;
          }
          if (this.comision.valorTentativoFluvial === 0) {
            this.comision.valorTentativoFluvial = null;
          }
          if (this.comision.valorTentativoAereo === 0) {
            this.comision.valorTentativoAereo = null;
          }

          if (this.comision.itinerarioAereoLista !== null && this.comision.itinerarioAereoLista.length > 0) {
            this.comision.itinerarioAereoLista.forEach(itAer => {
              if (itAer.fechaVuelo != null) {
                itAer.fechaVuelo = new Date(itAer.fechaVuelo);
              }
            });
          }

          //Actualizamos la variable de sumatoria de modos para visualizarlo en el Datatable
          this.actualizarSumatoriaModos();


          //Habilitar la vista de Viaticos Legalizados
          //Se habilita si en este paso no existe una legalizacion guardada, pero visualmente se carga los viaticos aprobados en la Legalizacion desde el back 
          if (this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_APROBADO) {
            this.habilitarLiquidacionLegalizacion = false;
            //Se habilita si en este paso ya existe una legalizacion guardada
            if (this.comision.viaticosLegalizacion != null) {
              this.habilitarLiquidacionLegalizacion = true;
            }
          } else {
            //Se habilita si en este paso ya existe una legalizacion guardada y ya se ha realizado el proceso de legalizacion
            if (this.comision.idPasoActual >= EnumCmsConstantes.CMS_PASO_COMISION_REVISION_LEGALIZACION
              && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES) {
              if (this.comision.viaticosLegalizacion != null) {
                this.habilitarLiquidacionLegalizacion = true;
              }
            }
          }

          if (this.idPersona === this.comision.idPersona) {
            this.aprobador = false;
          } else {
            this.aprobador = true;
            if (this.legalizando) {
              this.obtenerLegalizacionAsignada(idComision);
            }
          }

          if (this.legalizando && !this.aprobador) {
            this.obtenerLegalizacionPorComision(idComision);
          }
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {

        this.blockedDocument = false;
      }
    );
  }

  cargarDatosAdicionalesContratoParaComisiones() {
    if (this.comision.idContrato != null) {
      forkJoin(
        this.comisionesService.srvObtenerDatosAdicionalesContrato()
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.clausulaContratista.clausulaComisiones = result.datosAdicionalesContratoDTO.clausulaComisiones;
              this.clausulaContratista.linkSecopComisiones = result.datosAdicionalesContratoDTO.linkSecopComisiones;
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            this.blockedDocument = false;
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }


  listarTiquetesSolicitados() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarTiquetesSolicitadosPorComisionVersion(this.comision.idComisionVersion)
    ).subscribe(
      ([data]) => {
        this.comision.itinerarioAereoLista = data.tiquetes;
        if (this.comision.itinerarioAereoLista != null) {
          this.comision.itinerarioAereoLista.forEach(itAer => {
            if (itAer.fechaVuelo != null) {
              itAer.fechaVuelo = new Date(itAer.fechaVuelo);
            }
          });
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {

        this.blockedDocument = false;
      }
    );
  }

  obtenerLegalizacionPorComision(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarLegalizacionPorComision(idComision)
    ).subscribe(
      ([legalizacionData]) => {
        this.legalizacion = legalizacionData.legalizacion;
        this.listarAnexosPorLegalizar(this.legalizacion.idComisionVersion);
        this.idPasoLegalizacion = this.comision.idPasoActual;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {

        this.blockedDocument = false;
      }
    );
  }

  /**
   * 
   * @param idComision Consulta la información de la Legalizacion que se esta revisando de parte de la persona
   */
  obtenerLegalizacionAsignada(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarLegalizacionAsignada(idComision)
    ).subscribe(
      ([legalizacionData]) => {
        this.legalizacion = legalizacionData.comision;
        this.listarAnexosPorLegalizar(this.comision.idComisionVersion);
        this.idPasoLegalizacion = this.comision.idPasoActual;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {

        this.blockedDocument = false;
      }
    );
  }

  irAgregarAdjuntosInformeLegalizacion(idSolicitud: number, idComisionVersion: number, idAnexo: number) {
    this.idSolicitud = idSolicitud;
    this.idComisionVersionLegalizacion = idComisionVersion;
    this.idAnexoLegalizacion = idAnexo;
    this.nombreTipoDocumentoLegalizacion = EnumCmsConstantes.CMS_CARPETA_LEGALIZACION_BLOB_STORAGE;
    this.rutaCloudStorageInformeLegalzaion = this.idSolicitud + '/' + EnumCmsConstantes.CMS_CARPETA_LEGALIZACION_BLOB_STORAGE + '/';
    this.visibleDialogoSubirInformeLegalizacion = true;
  }

  /**
   * Información Básica   
  */
  //#region <Información Básica>
  onSelectFechaSalida() {
    this.calcularGenerarViaticos();
  }

  onSelectFechaRegreso() {
    this.calcularGenerarViaticos();
  }

  aceptarSubirAdjunto() {
    const nombreArchivo = this.archivos[0].nombreCompleto;
    this.comision.rutaSoporteJustificacion = nombreArchivo;
  }

  cancelarSubirAdjunto() {
    this.visualizarDialogAdjuntarArchivo = false;
  }

  outputSelectorDestino(municipioSeleccionado: Ciudad) {
    if (this.comision.destinos == null) {
      this.comision.destinos = [];
    }
    if (municipioSeleccionado != null) {
      const destinoExiste = this.comision.destinos.find(item => item.id === municipioSeleccionado.id);
      if (destinoExiste != null) {
        this.lanzarMensajeWarning("El destino seleccionado ya existe, por favor seleccione otro")
      } else {
        this.comision.destinos.push(municipioSeleccionado);
      }
    }
  }

  eliminarDestino(municipioSeleccionado) {
    if (municipioSeleccionado != null) {
      this.comision.destinos = this.comision.destinos.filter(item => item !== municipioSeleccionado);
    }
  }
  //#endregion 

  //#region <Transporte Comision>
  onSelectViajeTerrestre() {
    if (!this.comision.terreste) {
      this.comision.valorTentativoTerrestre = 0;
      this.calcularGenerarViaticos()
    }
  }

  onSelectViajeFluvial() {
    if (!this.comision.fluvial) {
      this.comision.valorTentativoFluvial = 0;
      this.calcularGenerarViaticos()
    }
  }

  onSelectViajeAereo() {
    if (!this.comision.aereo) {
      this.comision.asumeTransporteAereo = false;
      this.comision.valorTentativoAereo = 0;
      this.comision.itinerarioAereoLista = [];
    }
  }

  onSelectGeneraViaticos(sinoSeleccion) {
    this.comision.viaticos.generaViaticos = sinoSeleccion.valor;
    this.calcularGenerarViaticos();
  }

  onKeyUpTransporteTerrestre(x) { // appending the updated value to the variable    
    this.comision.valorTentativoTerrestre = x.target.value.replace('$', '').replaceAll('.', '');
    this.calcularGenerarViaticos();
  }

  onKeyUpTransporteFluvial(x) { // appending the updated value to the variable
    this.comision.valorTentativoFluvial = x.target.value.replace('$', '').replaceAll('.', '');
    this.calcularGenerarViaticos();
  }

  onKeyUpTransporteAereo(x) { // appending the updated value to the variable
    this.comision.valorTentativoAereo = x.target.value.replace('$', '').replaceAll('.', '');
    this.calcularGenerarViaticos();
  }

  onSelectAsumeTiquetes(sinoSeleccion) {
    this.comision.asumeTransporteAereo = sinoSeleccion.valor;
    if (this.comision.asumeTransporteAereo) {
      this.comision.itinerarioAereoLista = [];
    } else {
      this.comision.valorTentativoAereo = null;
    }
  }

  agregarItinerarioVuelo() {
    this.editarItinerarioAereo = false;
    this.itinerarioAereo = {};
    this.itinerarioAereo.id = null;
    this.itinerarioAereo.franjasHorariasLista = [];
    this.itinerarioAereo.fechaVuelo = null;
    this.ciudadAeropuertoDestino = null;
    this.ciudadAeropuertoOrigen = null;
    this.franjaHoraria = null;
    this.agregarEditarItinerarioAereoDialog = true;
  }

  editarItinerarioVuelo(itinerarioL: CmsItinerarioAereoDTO, index) {
    this.editarItinerarioAereo = true;
    this.itinerarioAereo = itinerarioL;
    this.itinerarioAereo.index = index;
    this.ciudadAeropuertoOrigen = this.ciudadAeropuertoList.find(t => t.idMunicipio === this.itinerarioAereo.idCiudadOrigen);
    this.ciudadAeropuertoDestino = this.ciudadAeropuertoList.find(t => t.idMunicipio === this.itinerarioAereo.idCiudadDestino);
    if (this.itinerarioAereo.franjasHorariasLista.length > 0) {
      this.franjaHoraria = this.itinerarioAereo.franjasHorariasLista[0];
    }
    this.agregarEditarItinerarioAereoDialog = true;
    this.itinerarioAereo.fechaVuelo = new Date(itinerarioL.fechaVuelo);
  }

  eliminarItinerarioVuelo(itinerarioL: CmsItinerarioAereoDTO) {
    this.itinerarioAereo = itinerarioL;
    this.eliminarItinerarioAereoDialog = true;
  }

  cancelarItinerarioVuelo(itinerarioL: CmsItinerarioAereoDTO) {
    this.itinerarioAereo = itinerarioL;
    this.cancelarItinerarioAereoDialog = true;
  }

  solicitudCancelarTiqueteComision() {
    this.blockedDocument = true;
    this.cancelarItinerarioAereoDialog = false;
    if (this.formSolicitudCancelacionTiquete.valid) {

      const cmsSolicitudCancelacionTiqueteDTO = {
        idTiqueteAereoSolicitado: this.itinerarioAereo.id,
        observacionesSolicitud: this.observacionesCancelacion
      };

      forkJoin(
        this.comisionesService.srvSolicitudCancelacionTiqueteComision(cmsSolicitudCancelacionTiqueteDTO)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {

            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

              this.listarTiquetesSolicitados();
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
            this.blockedDocument = false;
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        },
        () => {

          this.blockedDocument = false;
        }
      );
    } else {
      this.messageService.add({ severity: "error", summary: "Error", detail: "El campo justificación de cancelación es obligatorio.", life: 10000, });
      this.blockedDocument = false;
    }

  }

  submitGuardarItinerarioAereo() {

    if (this.formItinerarioAereo.valid) {
      if (this.ciudadAeropuertoOrigen.idMunicipio !== this.ciudadAeropuertoDestino.idMunicipio) {
        if (this.comision.itinerarioAereoLista === null) {
          this.comision.itinerarioAereoLista = []
        }
        //Construir el objeto "itinerarioAereo" a guardar
        this.itinerarioAereo.idCiudadOrigen = this.ciudadAeropuertoOrigen.idMunicipio;
        this.itinerarioAereo.idCiudadDestino = this.ciudadAeropuertoDestino.idMunicipio;
        this.itinerarioAereo.ciudadOrigen = this.ciudadAeropuertoOrigen.nombreMunicipio;
        this.itinerarioAereo.ciudadDestino = this.ciudadAeropuertoDestino.nombreMunicipio;
        this.itinerarioAereo.idEstado = EnumCmsConstantes.CMS_ESTADO_TIQUETE_AEREO_SOLICITADO;
        this.itinerarioAereo.franjasHorariasLista = []
        this.itinerarioAereo.franjasHorariasLista = [...this.itinerarioAereo.franjasHorariasLista, this.franjaHoraria]
        //Validar si ya existe un itinerario aereo con (la misma ciudad origen y destino) o (la misma fecha y franja horaria)

        let mismaCiudadOrigen = false;
        let mismaCiudadDestino = false;
        let mismaFecha = false;
        let mismaFranjaHoraria = false;
        let contador = 0;
        this.comision.itinerarioAereoLista.forEach(itinerarioVar => {
          if (this.itinerarioAereo.index != contador) {
            if (this.itinerarioAereo.idCiudadOrigen === itinerarioVar.idCiudadOrigen) {
              mismaCiudadOrigen = true;
            }
            if (itinerarioVar.idCiudadDestino === this.itinerarioAereo.idCiudadDestino) {
              mismaCiudadDestino = true;
            }
            itinerarioVar.fechaVuelo = new Date(itinerarioVar.fechaVuelo);
            mismaFecha = (itinerarioVar.fechaVuelo.getDate() === this.itinerarioAereo.fechaVuelo.getDate()
              && itinerarioVar.fechaVuelo.getMonth() === this.itinerarioAereo.fechaVuelo.getMonth()
              && itinerarioVar.fechaVuelo.getFullYear() === this.itinerarioAereo.fechaVuelo.getFullYear());
            if (itinerarioVar.franjasHorariasLista[0].id === this.itinerarioAereo.franjasHorariasLista[0].id) {
              mismaFranjaHoraria = true;
            }
          }
          contador++;
        });
        if (!(mismaCiudadOrigen && mismaCiudadDestino)) {
          if (!(mismaFecha && mismaFranjaHoraria)) {
            if (this.itinerarioAereo.id === null) {

              this.itinerarioAereo.idTiqueteAereoSolicitadoVersionAnterior = null;
              this.itinerarioAereo.idEstado = EnumCmsConstantes.CMS_ESTADO_TIQUETE_AEREO_SOLICITADO;
              this.itinerarioAereo.id = this.comision.itinerarioAereoLista.length + 1;
              this.comision.itinerarioAereoLista = [...this.comision.itinerarioAereoLista, this.itinerarioAereo];
            }
            this.agregarEditarItinerarioAereoDialog = false;
            this.lanzarMensajeInfo("El itinerario aéreo se agregó correctamente");
          } else {
            this.lanzarMensajeWarning(
              "Ya se agregó un itinerario aéreo con la misma fecha y franja horaria"
            );
          }
        } else {
          this.lanzarMensajeWarning(
            "Ya se agregó un itinerario aéreo con la misma ciudad origen y destino"
          );
        }

      } else {
        this.lanzarMensajeWarning(
          "La ciudad destino debe ser diferente a la ciudad origen"
        );
      }
    } else {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    }
  }

  submitEliminarItinerarioAereo() {
    this.blockedDocument = true;
    this.comision.itinerarioAereoLista = this.comision.itinerarioAereoLista.filter(item => item !== this.itinerarioAereo);
    this.eliminarItinerarioAereoDialog = false;
    this.blockedDocument = false;
  }
  //#endregion 

  //#region <Proyectos>
  /**
   * Metodo que controla el evento de selección ¿Es un Evneto?
   * @param sinoSeleccion Valor seleccionado (true, false, null)
   */
  onSelectAplicaEvento(sinoSeleccion) {
    if (typeof this.comision.aplicaEvento === 'boolean') {
      const controlSiSelecciono = this.comision.aplicaEvento;
      if (controlSiSelecciono != sinoSeleccion.valor) {
        this.comision.aplicaEvento = sinoSeleccion.valor;
        if (!this.soloLectura) {
          if (this.comision.aplicaEvento) {
            //Es un evento
            this.comision.proyectosLista = [];
            this.actualizarModosAplicaEvento();
          } else {
            //Es un proyecto
            this.comision.descripcionEvento = null;
            this.comision.modoLista = [];
          }
        }
      }
    }

    // this.comision.aplicaEvento = sinoSeleccion.valor;
    // if (!this.soloLectura) {
    //   if (this.comision.aplicaEvento) {
    //     this.actualizarModosAplicaEvento();
    //   } else {
    //     this.comision.descripcionEvento = null;
    //   }
    // }

  }

  cargarProyectos(event) {
    this.filtroProyectos = [];
    return this.comisionesService.srvListarProyectos(0).subscribe((data: any) => {
      this.proyectos = data.proyectosLista;
      if (this.proyectos === null) {
        this.proyectos = [];
      }
      if (event != null) {
        let existeFiltro: boolean;
        for (let i = 0; i < this.proyectos.length; i++) {
          const filtro = this.proyectos[i];
          if (String(filtro.nombreProyecto).toLowerCase().includes(event.query.toLowerCase())) {
            this.filtroProyectos.push(filtro);
            existeFiltro = true;
          }
        }
        if (existeFiltro) {
          this.proyectos = this.filtroProyectos;
        }
      }
    });
  }

  irAgregarProyecto() {
    this.proyectoSeleccionado = null;
    this.visualizarProyectoDialog = true;
  }

  guardarProyecto() {
    this.blockedDocument = true;
    if (this.formProyecto.valid) {
      this.visualizarProyectoDialog = true;
      this.blockedDocument = false;
      if (this.comision.proyectosLista === null) {
        this.comision.proyectosLista = [];
      }
      let yaExiste = false;
      this.comision.proyectosLista.forEach(proyectoVar => {
        if (proyectoVar.id === this.proyectoSeleccionado.id) {
          yaExiste = true;
        }
      });
      if (yaExiste) {
        this.lanzarMensajeWarning("El proyecto seleccionado ya esta agregado");
      } else {
        this.comision.proyectosLista = [...this.comision.proyectosLista, this.proyectoSeleccionado]
        this.actualizarModosDesdeProyectos();
        this.visualizarProyectoDialog = false;
      }
    } else {
      this.lanzarMensajeWarning("Debe seleccionar un proyecto");
    }
    this.blockedDocument = false;
  }

  irEliminarProyecto(proyecto: CmsProyectosContratistaDTO) {
    this.blockedDocument = true;
    this.visualizarEliminarProyectoDialog = true;
    this.proyectoSeleccionado = proyecto;
  }

  aceptarEliminarProyecto() {
    this.comision.proyectosLista = this.comision.proyectosLista.filter(item => item !== this.proyectoSeleccionado)
    this.actualizarModosDesdeProyectos();
    this.visualizarEliminarProyectoDialog = false;
    this.blockedDocument = false;
  }
  //#endregion 

  //#region <Modos>
  editarModos(modoL: CmsModosDTO) {
    this.modo = modoL;
    this.editarModoDialog = true;
    this.porcentajeTemp = this.modo.porcentajeRecursos;
  }

  submitGuardarModo() {
    this.blockedDocument = true;
    if (this.formModos.valid) {
      this.editarModoDialog = false;

      //Actualizamos la variable de sumatoria de modos para visualizarlo en el Datatable
      this.actualizarSumatoriaModos();

    } else {
      if (this.modo.porcentajeRecursos == null) {
        this.lanzarMensajeWarning(
          "Debe diligenciar los campos requeridos marcados con asterisco (*)"
        );
      } else {
        this.lanzarMensajeWarning(
          "Debe diligenciar un valor entre 0 y 100"
        );
      }

    }
    this.blockedDocument = false;
  }

  cancelarModo() {
    this.modo.porcentajeRecursos = this.porcentajeTemp;
    this.editarModoDialog = false;
  }

  /**
   * Metodo que genera el listado de modos cuando es un evento
   */
  actualizarModosAplicaEvento() {
    //Sí es un evento se muestran todos los modos y se deben registrar los porcentajes    
    this.comision.modoLista = [];
    if (this.comision.aplicaEvento) {
      this.comision.modoLista = this.tiposProyectoAni.map(row => ({
        idTipo: row.id,
        nombreTipo: row.nombre,
        porcentajeRecursos: 0
      }));
    }
  }

  /**
   * Metodo para actualizar la variable global que muestra el sumatoria de modos
   */
  actualizarSumatoriaModos() {
    if (Array.isArray(this.comision.modoLista) && this.comision.modoLista.length) {
      return this.comision.modoLista.reduce<number>((sum: number, modoVar: CmsModosDTO) => {
        return Number(sum + Number(modoVar.porcentajeRecursos));
      }, Number(0));
    }
  }

  actualizarModosDesdeProyectos() {
    //Sí es contratista los modos son de acuerdo a los proyectos seleccionados
    if (this.comision.proyectosLista != null && this.comision.proyectosLista.length > 0) {
      const key = 'idTipo';
      const arrayUniqueByKey = [...new Map(this.comision.proyectosLista.map(item =>
        [item[key], item])).values()];
      arrayUniqueByKey.forEach(unique => {
        const modoL: CmsModosDTO = {
          idTipo: unique.idTipo,
          nombreTipo: unique.nombreTipo + '',
          porcentajeRecursos: 0
        }
        let yaExiste = false;
        if (this.comision.modoLista === null) {
          this.comision.modoLista = [];
        }
        this.comision.modoLista.forEach(modoVar => {
          if (modoVar.idTipo === modoL.idTipo) {
            yaExiste = true;
          }
        });
        if (yaExiste) {
          this.comision.modoLista = this.comision.modoLista.filter(item => item.idTipo === modoL.idTipo)
        } else {
          this.comision.modoLista = [...this.comision.modoLista, modoL]
        }
      });
    }
  }

  //#endregion 

  //#region <Liquidación Comisión>
  calcularGenerarViaticos() {
    if (!this.soloLectura && (this.comision.idPasoActual == 0 || this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_EN_BORRADOR
      || this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES)) {
      this.calcularViaticos();
    }
  }

  calcularViaticos() {
    if (!this.soloLectura &&
      (this.comision.idPasoActual == 0
        || this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_EN_BORRADOR
        || this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES)
      && (this.comision.fechaSalida != null && this.comision.fechaRegreso != null)) {
      this.blockedDocument = true;
      forkJoin(
        this.comisionesService.srvCalcularViaticos(this.comision)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.comision.viaticos = result.viaticos;
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  calcularLiquidacionPorModo() {
    this.visualizarDialogAprobarSolicitud = true;
    this.cdps = [];
    this.comisionesService.srvConsultarListadoCdp().subscribe(
      result => {
        if (result != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cdpList = result.cdpList;
            // if (this.comision.esContratista) {
            this.comision.modoLista.forEach(modo => {
              this.cdpList.forEach(cdp => {
                if (cdp.idTipoProyecto === null) {
                  let porcentajeModo = 0;
                  if (modo.porcentajeRecursos != 0) {
                    porcentajeModo = modo.porcentajeRecursos ? modo.porcentajeRecursos / 100 : 1;
                  }
                  cdp.liquidacion = this.comision.viaticos.valorTotal * porcentajeModo;
                  const cdp1: CmsCdpDTO = {};
                  cdp1.id = cdp.id;
                  cdp1.numero = cdp.numero;
                  cdp1.fecha = cdp.fecha;
                  cdp1.denominacionRubro = modo.nombreTipo;
                  cdp1.valor = cdp.valor;
                  cdp1.liquidacion = cdp.liquidacion;
                  cdp1.saldo = cdp.saldo;
                  this.cdps.push(cdp1);
                }
              });
            });
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      }
    );

  }
  //#endregion 

  //#region <General Comision>
  submitGuardarComision() {
    //Validaciones
    let error = false;

    /*
      Información Básica
    */
    if (this.comision.fechaSalida === null) {
      error = true;
      this.lanzarMensajeWarning("Debe seleccionar la fecha de salida");
    }
    if (this.comision.fechaRegreso === null) {
      error = true;
      this.lanzarMensajeWarning("Debe seleccionar la fecha de regreso");
    }
    if (this.comision.objeto === null) {
      error = true;
      this.lanzarMensajeWarning("Debe diligenciar el objeto de la comisión");
    }
    if (this.comision.objeto != null && this.comision.objeto.length < this.cantidadMinCaracteresCampoAbierto) {
      error = true;
      this.lanzarMensajeWarning("El objeto de la comisión debe tener mínimo " + this.cantidadMinCaracteresCampoAbierto + " caracteres.");
    }
    if (this.comision.version > 0 && this.comision.justificacionModificacion != null && this.comision.justificacionModificacion.length < this.cantidadMinCaracteresCampoAbierto) {
      error = true;
      this.lanzarMensajeWarning("La justificación de la modificación de la comisión debe tener mínimo " + this.cantidadMinCaracteresCampoAbierto + " caracteres.");
    }
    if (this.comision.destinos == null || this.comision.destinos.length <= 0) {
      error = true;
      this.lanzarMensajeWarning("Debe agregar por lo menos un destino");
    }

    /*
      Transporte
    */
    if (this.comision.terreste) {
      if (this.comision.valorTentativoTerrestre == 0) {
        error = true;
        this.lanzarMensajeWarning("El valor tentativo del transporte terrestre no puede ser cero.")
      }
    }

    if (this.comision.fluvial) {
      if (this.comision.valorTentativoFluvial == 0) {
        error = true;
        this.lanzarMensajeWarning("El valor tentativo del transporte fluvial no puede ser cero.")
      }
    }

    if (this.comision.aereo) {
      if (this.comision.esContratista) {
        if (this.comision.asumeTransporteAereo == null) {
          error = true;
          this.lanzarMensajeWarning("Debe relacionar si se asume o no transporte aéreo");
        } else {
          if (this.comision.asumeTransporteAereo) {
            if (this.comision.valorTentativoAereo == null || this.comision.valorTentativoAereo == 0) {
              error = true;
              this.lanzarMensajeWarning("El valor tentativo del transporte aéreo no puede ser cero.");
            }
          } else {
            if (this.comision.itinerarioAereoLista == null) {
              error = true;
              this.lanzarMensajeWarning("Si selecciona transporte aéreo debe ingresar por lo menos " + this.numeroMinimoTrayectosItinerario + " trayecto.");
            } else {
              if (this.comision.itinerarioAereoLista.length < this.numeroMinimoTrayectosItinerario) {
                error = true;
                this.lanzarMensajeWarning("Si selecciona transporte aéreo debe ingresar por lo menos " + this.numeroMinimoTrayectosItinerario + " trayecto.");
              }
            }
          }
        }
      } else {
        if (this.comision.itinerarioAereoLista == null) {
          error = true;
          this.lanzarMensajeWarning("Si selecciona transporte aéreo debe ingresar por lo menos " + this.numeroMinimoTrayectosItinerario + " trayecto.");
        } else {
          if (this.comision.itinerarioAereoLista.length < this.numeroMinimoTrayectosItinerario) {
            error = true;
            this.lanzarMensajeWarning("Si selecciona transporte aéreo debe ingresar por lo menos " + this.numeroMinimoTrayectosItinerario + " trayecto.");
          }
        }
      }

      //Validar Rango de Fechas
      if (this.comision.itinerarioAereoLista != null && this.comision.fechaSalida != null && this.comision.fechaRegreso != null) {
        this.comision.itinerarioAereoLista.forEach(itAereo => {
          if (itAereo.fechaVuelo >= this.comision.fechaSalida && itAereo.fechaVuelo <= this.comision.fechaRegreso) {
          } else {
            error = true;
            this.lanzarMensajeWarning("La fecha del vuelo debe estar dentro del rango de fechas de la comisión");
          }
        });
      }
    }

    //Si hay algun transporte es obligatoria la justificación
    if (
      (this.comision.viaticos.valorTransporte != null && this.comision.viaticos.valorTransporte > 0)
    ) {
      if (this.comision.justificacionGastosTransporte == null) {
        error = true;
        this.lanzarMensajeWarning("Debe diligenciar la justificación de los gastos de transporte de la comisión");
      }
      if (this.comision.justificacionGastosTransporte != null && this.comision.justificacionGastosTransporte.length < this.cantidadMinCaracteresCampoAbierto) {
        error = true;
        this.lanzarMensajeWarning("El justificación de los gastos de transporte de la comisión debe tener mínimo " + this.cantidadMinCaracteresCampoAbierto + " caracteres.");
      }
    }

    /*
      Mis Proyectos
    */

    //Si es un evento
    if (this.comision.aplicaEvento) {
      if (this.comision.descripcionEvento != null && this.comision.descripcionEvento.length < this.cantidadMinCaracteresCampoAbierto) {
        error = true;
        this.lanzarMensajeWarning("La descripción del evento de la comisión debe tener mínimo " + this.cantidadMinCaracteresCampoAbierto + " caracteres.");
      }
    } else {
      //Si es por proyectos
      if (this.comision.proyectosLista === null || (this.comision.proyectosLista !== null && this.comision.proyectosLista.length === 0)) {
        error = true;
        this.lanzarMensajeWarning("Debe agregar por lo menos un proyecto")
      }
    }

    /*
      Modos
    */
    if (this.comision.modoLista === null || (this.comision.modoLista !== null && this.comision.modoLista.length === 0)) {
      error = true;
      this.lanzarMensajeWarning("Debe indicar los modos de transporte a que hace referencia esta solicitud de comisión o desplazamiento");
    }

    // Valida que el porcentaje indicado en cada modo no sea 0, solo cuando viene de proyectos
    if (!this.comision.aplicaEvento) {
      this.comision.modoLista.forEach(modoVar => {
        if (modoVar.porcentajeRecursos == 0) {
          error = true;
          this.lanzarMensajeWarning("El porcentaje del modo no puede ser 0, cuando se relacionen los proyectos");
        }
      });
    }

    if (this.actualizarSumatoriaModos() !== this.MODOS_PORCENTAJE_MAXIMO) {
      error = true;
      let strMensajeValidacion = `La sumatoria de porcentajes de los modos debe ser ${this.MODOS_PORCENTAJE_MAXIMO}%`;
      if (this.comision.aplicaEvento) {
        strMensajeValidacion = strMensajeValidacion + ". Si no aplica dejelo en 0.";
      }
      this.lanzarMensajeWarning(strMensajeValidacion);
    }


    if (!error) {
      this.llamarServicioGuardarComision();
    }
  }

  llamarServicioGuardarComision() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvGuardarComision(this.comision)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {

          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

            if (this.modificaComision) {
              this.submitEnviarSolicitudComision();
              this.mostrarEnviarSolicitud = false;
            } else {
              this.mostrarEnviarSolicitud = true;
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            }

            this.visualizarDialogAprobarSolicitud = false;
            this.comision.idComision = result.comisionDetalle.idComision;
            this.comision.idComisionVersion = result.comisionDetalle.idComisionVersion;
            this.obtenerComisionPorId(this.comision.idComision);
          } else if (result.respuestaServicio.codigoSalida === 101) {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          this.blockedDocument = false;
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {

        this.blockedDocument = false;
      }
    );
  }

  irValidarFlujoAntesEnviarSolicitudComision() {
    this.blockedDocument = true;
    this.mensajeFlujoAntesEnviarSolicitudComision = "";
    if (this.comision.idComision !== 0) {
      forkJoin(
        this.comisionesService.srvConsultarPasoEstimadoAsignacionComision(this.comision.idComision, this.comision.idComisionVersion)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.visualizarDialogEnviarSolicitud = true;
              this.mensajeFlujoAntesEnviarSolicitudComision = result.respuestaServicio.mensajeSalida;
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }

  submitEnviarSolicitudComision() {
    this.blockedDocument = true;
    this.visualizarDialogEnviarSolicitud = false;
    if (this.comision.idComision !== 0) {
      forkJoin(
        this.comisionesService.srvEnviarSolicitudComision(this.comision.idComision)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {

            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

              if (!this.modificaComision) {
                this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              }

              this.visualizarProyectoDialog = false;

              this.bntVolver()
            } else if (result.respuestaServicio.codigoSalida === 100) {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            } else if (result.respuestaServicio.codigoSalida === EnumCmsCodigosErrorComision.CMS_ERROR_COMISION_VENCIDAS_LEGALIZACIONES_CODIGO) {
              //Legalizaciones Vencidas
              this.blockedDocument = false;
              this.visibleDialogoValidacionEnviarComision = true;
              this.respuestaServicioEnviarComision = result;
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }

  aceptarSolicitud() {
    this.blockedDocument = true;
    this.visualizarDialogAprobarSolicitud = false;

    if (this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_VAF
      && this.cdps != null && this.cdps.length > 0) {
      let sumCdp: number = this.cdps.map(a => a.liquidacion).reduce(function (a, b) {
        return a + b;
      });
      sumCdp = Math.round(sumCdp * 100) / 100;
      if (sumCdp != this.comision.viaticos.valorTotal) {
        this.lanzarMensajeError("El valor de los movimientos no coincide con el valor de la comisión");
        return;
      }
    }

    this.reasignarComision = {
      idComision: this.comision.idComision,
      idComisionVersion: this.comision.idComisionVersion,
      idAsignacion: this.idAsignacion,
      aprobo: true,
      observaciones: null,
      cdpMovimientos: this.cdps
    }
    if (this.comision.idComision !== 0) {
      forkJoin(
        this.comisionesService.srvReasignarComision(this.reasignarComision)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              //Volver a comisiones-solicitudes
              this.bntVolver();
            } else if (result.respuestaServicio.codigoSalida === 102) {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }

  rechazarSolicitud() {
    this.blockedDocument = true;
    this.visualizarDialogRechazarSolicitud = false;

    this.reasignarComision = {
      idComision: this.comision.idComision,
      idComisionVersion: this.comision.idComisionVersion,
      idAsignacion: this.idAsignacion,
      aprobo: false,
      observaciones: this.observacionRechazo
    }

    if (this.comision.idComision !== 0) {
      forkJoin(
        this.comisionesService.srvReasignarComision(this.reasignarComision)
      ).subscribe(
        ([result]) => {

          if (result != null && result.respuestaServicio != null) {

            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);

              //Volver a comisiones-solicitudes
              this.bntVolver();
            } else if (result.respuestaServicio.codigoSalida === 102) {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }
  //#endregion 

  //#region <Transporte Legalización>
  onKeyUpTransporteTerrestreLegalizacion(x) { // appending the updated value to the variable
    this.comision.viaticosLegalizacion.valorTerrestre = x.target.value.replace('$', '').replaceAll('.', '');
    this.calcularGenerarViaticosLegalizacion();
  }

  onKeyUpTransporteFluvialLegalizacion(x) { // appending the updated value to the variable
    this.comision.viaticosLegalizacion.valorFluvial = x.target.value.replace('$', '').replaceAll('.', '');
    this.calcularGenerarViaticosLegalizacion();
  }

  onKeyUpTransporteAereoLegalizacion(x) { // appending the updated value to the variable
    this.comision.viaticosLegalizacion.valorAereo = x.target.value.replace('$', '').replaceAll('.', '');
    this.calcularGenerarViaticosLegalizacion();
  }


  validarTransporteLegalizacion() {
    let transporteLegalizacionValido = true;
    if (this.comision.viaticosLegalizacion.valorTerrestre != null && this.comision.viaticosLegalizacion.valorTerrestre < 0) {
      transporteLegalizacionValido = false;
      this.lanzarMensajeWarning("El valor del transporte legalizado debe ser mayor o igual a 0");
    }
    if (this.comision.viaticosLegalizacion.valorFluvial != null && this.comision.viaticosLegalizacion.valorFluvial < 0) {
      transporteLegalizacionValido = false;
      this.lanzarMensajeWarning("El valor del transporte legalizado debe ser mayor o igual a 0");
    }
    if (this.comision.viaticosLegalizacion.valorAereo != null && this.comision.viaticosLegalizacion.valorAereo < 0) {
      transporteLegalizacionValido = false;
      this.lanzarMensajeWarning("El valor del transporte legalizado debe ser mayor o igual a 0");
    }

    const totalTransporteEstimado: number = Number(this.comision.valorTentativoTerrestre) + Number(this.comision.valorTentativoFluvial) + Number(this.comision.valorTentativoAereo);
    const valorTerrestre = Number(this.comision.viaticosLegalizacion.valorTerrestre);
    const valorFluvial = Number(this.comision.viaticosLegalizacion.valorFluvial);
    const valorAereo = Number(this.comision.viaticosLegalizacion.valorAereo);
    const totalTransporteALegalizar = valorTerrestre + valorFluvial + valorAereo;
    if (totalTransporteALegalizar > totalTransporteEstimado) {
      transporteLegalizacionValido = false;
      this.lanzarMensajeWarning("El valor de transporte para la legalización no puede ser mayor al valor estimado");
    }
    return transporteLegalizacionValido;
  }

  submitGuardarTransporteLegalizacion() {
    if (this.validarTransporteLegalizacion()) {
      if (this.comision.viaticosLegalizacion.valorTerrestre == null) {
        this.comision.viaticosLegalizacion.valorTerrestre = 0;
      }
      if (this.comision.viaticosLegalizacion.valorFluvial == null) {
        this.comision.viaticosLegalizacion.valorFluvial = 0;
      }
      if (this.comision.viaticosLegalizacion.valorAereo == null) {
        this.comision.viaticosLegalizacion.valorAereo = 0;
      }

      this.blockedDocument = true;
      forkJoin(
        this.comisionesService.srvGuardarLiquidacionLegalizacion(this.comision.viaticosLegalizacion)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo("La liquidación Legalizada se guardó exitosamente.")
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
          this.calcularGenerarViaticosLegalizacion();
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
    }
  }

  //#endregion 

  //#region <Legalizacion - Transporte - Viaticos>
  onSelectGeneraViaticosLegalizacion(sinoSeleccion) {
    this.comision.viaticosLegalizacion.generaViaticos = sinoSeleccion.valor;
    this.calcularGenerarViaticosLegalizacion();
  }


  calcularGenerarViaticosLegalizacion() {
    //En la Legalización solo es posible actualizar el valor del transporte que previamente se había solicitado
    //El valor de transporte para la legalización no puede ser mayor al valor estimado para cada tipo de transporte
    if (this.legalizando && (this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_APROBADO
      || this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES_LEGALIZACION)) {
      this.comision.viaticosLegalizacion.valorTransporte = Number(this.comision.viaticosLegalizacion.valorTerrestre) + Number(this.comision.viaticosLegalizacion.valorFluvial) + Number(this.comision.viaticosLegalizacion.valorAereo);
      this.comision.viaticosLegalizacion.valorTotal = this.comision.viaticosLegalizacion.valorViaticos + Math.round(this.comision.viaticosLegalizacion.valorTransporte);
    }
  }
  //#endregion 

  //#region <Legalizacion - Informe - Anexos>
  aceptarAdjuntoInformeLegalizacion() {
    this.guardarSoporteLegalizacion(this.idComisionVersionLegalizacion, EnumCmsTipoAnexo.INFORME_LEGALIZACION, this.adjuntosInformeLegalizacion[0].nombreCompleto, this.adjuntosInformeLegalizacion[0].nombre);
  }

  guardarSoporteLegalizacion(idComisionVersion: number, idTipoSoporte: number, rutaSoporte: string, nombreArchivoUsuario: string) {

    const anexosComisionDTO: CmsAnexoComisionDTO = {};
    const archivosAnexosComisionDTO: CmsArchivoAnexoComisionDTO = {};
    anexosComisionDTO.idComisionVersion = idComisionVersion;
    if (this.idAnexoLegalizacion != null) {
      anexosComisionDTO.id = this.idAnexoLegalizacion;
    } else {
      anexosComisionDTO.id = 0;
    }
    archivosAnexosComisionDTO.rutaSoporte = rutaSoporte;
    archivosAnexosComisionDTO.nombreArchivoUsuario = nombreArchivoUsuario;
    anexosComisionDTO.adjuntos = [];
    anexosComisionDTO.adjuntos.push(archivosAnexosComisionDTO);
    switch (idTipoSoporte) {
      case EnumCmsTipoAnexo.INFORME_LEGALIZACION: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.INFORME_LEGALIZACION;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_INFORME_FACTURA: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.FACTURA;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_INFORME_PASABORDO: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.PASABORDO;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_INFORME_FACTURA_TRANSPORTE: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.FACTURA_TRANSPORTE;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_INFORME_SOPORTE_PEAJES: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.SOPORTE_PEAJES;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_INFORME_GASTO_COMBUSTIBLE: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.GASTO_COMBUSTIBLE;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_RESERVA_TIQUETE: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.TIQUETE_RESERVA;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_COMPRA_TIQUETE: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.TIQUETE_COMPRA;
        break;
      }
      case EnumTrnMaTipoSoporte.TIPO_SOPORTE_CMS_MEMORANDO_USOS_LEGALIZACION: {
        anexosComisionDTO.idTipoAnexo = EnumCmsTipoAnexo.LEGALIZACION_USOS_CDP;
        break;
      }
      default: {
        anexosComisionDTO.idTipoAnexo = null;
        break;
      }
    }

    //Validar datos anexos archivos
    let esAnexoValido = true;
    if (anexosComisionDTO.idTipoAnexo == null || anexosComisionDTO.idTipoAnexo == 0) {
      esAnexoValido = false;
      this.lanzarMensajeWarning("Debe relacionar el tipo de Anexo: " + anexosComisionDTO.idTipoAnexo)
    }
    if (anexosComisionDTO.idComisionVersion == null || anexosComisionDTO.idComisionVersion == 0) {
      esAnexoValido = false;
      this.lanzarMensajeWarning("Debe relacionar el IdComisionVersion: " + anexosComisionDTO.idComisionVersion);
    }
    if (anexosComisionDTO.adjuntos == null || anexosComisionDTO.adjuntos.length <= 0) {
      esAnexoValido = false;
      this.lanzarMensajeWarning("Debe relacionar al menos un adjunto al Anexo: " + (anexosComisionDTO.adjuntos == null ? 0 : anexosComisionDTO.adjuntos.length));

    }

    if (esAnexoValido) {
      forkJoin(
        this.comisionesService.srvGuardarAnexoArchivoComision(anexosComisionDTO)
      ).subscribe(
        ([result]) => {

          if (result != null && result.respuestaServicio != null) {

            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

              this.obtenerLegalizacionPorComision(this.comision.idComision);

              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);

              this.visibleDialogoSubirAnexos = false;
            } else if (result.respuestaServicio.codigoSalida === 102) {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
    }
  }

  cancelarDialogoSubirInformeLegalizacion() {
    this.visibleDialogoSubirInformeLegalizacion = false;
  }

  irAgregarAdjuntosAnexosLegalizacion(idSolicitud: number, idComisionVersion: number, idPaso: number) {
    this.idSolicitud = idSolicitud;
    this.idComisionVersionLegalizacion = idComisionVersion;
    this.idPasoLegalizacion = idPaso;
    this.nombreTipoDocumentoLegalizacion = EnumCmsConstantes.CMS_CARPETA_LEGALIZACION_BLOB_STORAGE;
    this.rutaCloudStorageInformeLegalzaion = this.idSolicitud + '/' + EnumCmsConstantes.CMS_CARPETA_LEGALIZACION_BLOB_STORAGE + '/';
    this.listarAnexosPorLegalizar(idComisionVersion);
    this.visibleDialogoSubirAnexos = true;
  }

  //Obtiene los anexos cargados para la legalización
  listarAnexosPorLegalizar(idComisionVersion) {
    forkJoin(
      this.comisionesService.srvConsultarAnexoAdjuntosConsolidado(idComisionVersion, EnumCmsTipoAnexo.SUBTIPO_GESTION_LEGALIZACION_ANEXOS + ',' + EnumCmsTipoAnexo.SUBTIPO_GESTION_TIQUETES, false)
    ).subscribe(
      ([comisionesPorLegalizarData]) => {
        this.anexosConsolidado = comisionesPorLegalizarData.adjuntosAnexosConsolidado;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  irEliminarDocumento(id) {
    this.visibleDialogoEliminarDocumentoLegalizacion = true;
    this.idDocumentoLegalizacion = id;
  }

  cancelarDialogoSubirAnexos() {
    this.visibleDialogoSubirAnexos = false;
  }

  aceptarAdjuntoAnexos() {
    this.guardarSoporteLegalizacion(this.idComisionVersionLegalizacion, this.tipoSoporteSeleccionado.id, this.adjuntosAnexos[0].nombreCompleto, this.adjuntosAnexos[0].nombre);
    this.listarAnexosPorLegalizar(this.idComisionVersionLegalizacion);
  }

  aceptarEliminarDocumento() {
    this.visibleDialogoEliminarDocumentoLegalizacion = false;
    this.blockedDocument = true;
    if (this.idDocumentoLegalizacion != null) {
      this.comisionesService.srvEliminarAnexoArchivoComision(this.idDocumentoLegalizacion).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.listarAnexosPorLegalizar(this.idComisionVersionLegalizacion);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
          this.ngOnInit();
        }
      );
    } else {
      this.blockedDocument = false;
    }

  }
  //#endregion 


  //#region <Legalizacion - General>
  irEnviarLegalizacion(solicitudL: any) {
    this.legalizacion = solicitudL;
    this.visibleDialogoEnviarLegalizacion = true;
    this.idComisionVersionLegalizacion = this.legalizacion.idComisionVersion;
    this.listarAnexosPorLegalizar(this.legalizacion.idComisionVersion);
  }

  enviarLegalizacion() {
    this.blockedDocument = true;
    this.visibleDialogoEnviarLegalizacion = false;
    let permitirEnviarLegalizacion = false;
    let tienePasabordo = false;
    let tieneFactura = false;
    if (this.legalizacion.rutaInforme != null) {
      if (this.legalizacion.tieneTiquetes) {
        this.anexosConsolidado.forEach(element => {
          if (element.idTipoAnexo === EnumCmsTipoAnexo.PASABORDO) {
            tienePasabordo = true;
          }
        });
        if (tienePasabordo) {
          permitirEnviarLegalizacion = true;
          if (this.comision.esContratista) {
            if (this.comision.esResponsableIva == 1) {
              this.anexosConsolidado.forEach(element => {
                if (element.idTipoAnexo === EnumCmsTipoAnexo.FACTURA) {
                  tieneFactura = true;
                }
              });
              if (tieneFactura) {
                permitirEnviarLegalizacion = true;
              } else {
                permitirEnviarLegalizacion = false;
                this.lanzarMensajeWarning('Debe adjuntar la factura');
              }
            }
          }
        } else {
          permitirEnviarLegalizacion = false;
          this.lanzarMensajeWarning('Debe adjuntar el Pasabordo');
        }
      } else {
        permitirEnviarLegalizacion = true;
      }

    } else {
      this.lanzarMensajeWarning('Debe adjuntar el Informe de Legalización');
    }

    if (permitirEnviarLegalizacion) {
      this.submitGuardarTransporteLegalizacion();
      this.blockedDocument = true;
      forkJoin(
        this.comisionesService.srvEnviarLegalizacionComision(this.legalizacion.idComision)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              this.bntVolver();
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
          this.obtenerLegalizacionPorComision(this.comision.idComision);
        }
      );
    } else {
      this.blockedDocument = false;
    }
  }

  irAceptarLegalizacion(legalizacion: CmsComisionLegalizacionDTO) {
    this.legalizacion = legalizacion;
    this.visibleDialogoAceptarLegalizacion = true;
  }

  irRechazarLegalizacion(legalizacion: CmsComisionLegalizacionDTO) {
    this.legalizacion = legalizacion;
    this.visibleDialogoRechazarLegalizacion = true;
  }

  aceptarLegalizacion(idComisionVersion) {
    this.blockedDocument = true;
    this.visibleDialogoAceptarLegalizacion = false;
    this.responderLegalizacionDto = {
      idComision: this.legalizacion.idComision,
      idComisionVersion: this.legalizacion.idComisionVersion,
      idAsignacion: this.legalizacion.idAsignacion,
      aprobo: true,
      observaciones: "Legalización Aprobada",
      esComisionCero: false
    }
    let esComisionCero = false;
    if (this.comision.viaticos.valorTotal == 0) {
      esComisionCero = true;
    }
    this.responderLegalizacionDto.esComisionCero = esComisionCero;

    if (
      (this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_REVISION_LEGALIZACION_VGCOR || this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_REVISION_LEGALIZACION_VGCOR_DEVOLUCION)
      && this.comision.viaticos.generaViaticos && this.comision.viaticos.valorTotal > 0) {
      if (this.adjuntosAnexos == null || this.adjuntosAnexos.length <= 0) {
        this.lanzarMensajeWarning("Debe adjuntar el Memorando de Usos Presupuestales");
        this.blockedDocument = false;
        return;
      } else {
        if (this.adjuntosAnexos != null && this.adjuntosAnexos.length > 0) {
          const anexoInvalido = this.adjuntosAnexos.find(anx => anx.estado != "OK");
          if (anexoInvalido != null) {
            this.lanzarMensajeWarning("Debe adjuntar el Memorando de Usos Presupuestales");
            this.blockedDocument = false;
            return;
          }
        }
      }
    }

    if (
      (this.comision.idPasoActual === EnumCmsConstantes.CMS_PASO_COMISION_REVISION_LEGALIZACION_VGCOR || this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_REVISION_LEGALIZACION_VGCOR_DEVOLUCION)
      && this.comision.viaticos.generaViaticos) {
      this.idComisionVersionLegalizacion = idComisionVersion;
      this.guardarSoporteLegalizacion(this.idComisionVersionLegalizacion, this.tipoSoporteSeleccionado.id, this.adjuntosAnexos[0].nombreCompleto, this.adjuntosAnexos[0].nombre);
    }

    forkJoin(
      this.comisionesService.srvReasignarComision(this.responderLegalizacionDto)
    ).subscribe(
      ([result]) => {

        if (result != null && result.respuestaServicio != null) {

          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            this.bntVolver();
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            this.ngOnInit();
          }
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
        this.ngOnInit();
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  rechazarLegalizacion() {
    this.blockedDocument = true;
    this.visibleDialogoRechazarLegalizacion = false;
    this.responderLegalizacionDto = {
      idComision: this.legalizacion.idComision,
      idComisionVersion: this.legalizacion.idComisionVersion,
      idAsignacion: this.legalizacion.idAsignacion,
      aprobo: false,
      observaciones: this.observacionRechazo,
      esComisionCero: false,
    }
    let esComisionCero = false;
    if (this.comision.viaticos.valorTotal == 0) {
      esComisionCero = true;
    }
    this.responderLegalizacionDto.esComisionCero = esComisionCero;

    forkJoin(
      this.comisionesService.srvReasignarComision(this.responderLegalizacionDto)
    ).subscribe(
      ([result]) => {

        if (result != null && result.respuestaServicio != null) {

          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            this.bntVolver();
          } else if (result.respuestaServicio.codigoSalida === 102) {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          this.lanzarMensajeError('No fue posible realizar la acción. Por favor comuniquese con el area de tecnología');
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
        this.ngOnInit();
      }
    );
  }
  //#endregion 


  //#region <Navegación>
  bntVolver() {
    if (this.revisando && this.legalizando) {
      const ruta = "/comisiones/comisiones-legalizaciones-revision/";
      this.router.navigate([ruta]);
    } else if (this.revisando) {
      const ruta = "/comisiones/comisiones-revisar-solicitudes/";
      this.router.navigate([ruta]);
    } else if (this.legalizando) {
      const ruta = "/comisiones/comisiones-legalizaciones-registro/";
      this.router.navigate([ruta]);
    } else {
      const ruta = "/comisiones/comisiones-solicitudes/";
      this.router.navigate([ruta]);
    }
  }
  //#endregion 

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

  get EnumCmsCodigosErrorComision() {
    return EnumCmsCodigosErrorComision;
  }


}
