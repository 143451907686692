export class Condicion {
    constructor(
        public condicion:boolean,
        public nombre:string
    ) { }
}


export class Condiciones{
    public condiciones: Condicion[] = [ new Condicion(true, "SI"), new Condicion(false, "NO") ]
}