import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SeguimientoTemasGestionService } from './temas-gestion-registro.service';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { Location } from '@angular/common';
import { SeguimientoDTO } from '../../../dtos/tramites-ambientales/seguimientoDTO';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';



@Component({
  // tslint:disable-next-line:component-selector
  selector: 'estado-gestion-dialog',
  templateUrl: './estado-gestion.html',
})
// tslint:disable-next-line:component-class-suffix
export class EstadosGestionDialog {
  maxDate: Date;
  visibleDialogoAgregar: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EstadosGestionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
      this.visibleDialogoAgregar = true;
    }

  onNoClick(): void {
    this.dialogRef.close();
    this.visibleDialogoAgregar = false;

  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'priorizacion-gestion-dialog',
  templateUrl: './priorizacion-gestion.html',
})
// tslint:disable-next-line:component-class-suffix
export class PriorizacionGestionDialog {
  maxDate: Date;
  enableAdministrativo = false;
  visibleDialogoAgregar: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PriorizacionGestionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
      this.visibleDialogoAgregar = true;

    }

  onNoClick(): void {
    this.dialogRef.close();
    this.visibleDialogoAgregar = false;
  }

  changeEstado(event){
    if (event === 9) {
      this.enableAdministrativo = true;
    } else {
      this.enableAdministrativo = false;
      this.data.actoAdministrativo = null;

    }
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'actuacion-gestion-dialog',
  templateUrl: './actuacion-gestion.html',
})
// tslint:disable-next-line:component-class-suffix
export class AcuacionGestionDialog {
  maxDate: Date;
  visibleDialogoAgregar: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AcuacionGestionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
      this.visibleDialogoAgregar = true;
    }

  onNoClick(): void {
    this.dialogRef.close();
    this.visibleDialogoAgregar = false;

  }

}


@Component({
  selector: 'app-temas-gestion-registro',
  templateUrl: './temas-gestion-registro.component.html',
  styleUrls: ['./temas-gestion-registro.component.sass']
})


export class TemasGestionRegistroComponent implements OnInit {

  estados: any = [];
  priorizaciones: any = [];
  observacionesDialog: any = [];
  responsables: any = [];
  departamentos: any = [];
  municipios: any = [];
  temporalEnviar: any = [];
  fechaReporte: Date;
  actoAdministrativo: string;
  observaciones: string;
  visibleDialogoExito: boolean;


  estadosTemporal: any = {
    idCompromiso: null,
    idEstado: null,
    fechaReporte: '',
    actoAdministrativo: '',
    observaciones: '',
    nuevo: false,
  };

  priorizacionesTemporal: any = {
    idCompromiso: null,
    idPriorizacion: null,
    fechaReporte: '',
    observaciones: '',
    nuevo: false,
  };

  responsablesTemporal: any = {
    idCompromiso: null,
    idMunicipio: '',
    radicado: '',
    fechaRadicado: null,
    idResponsable: null,
    observaciones: '',
    nuevo: false,
  };

  observacionesTemporal: any = {
    idCompromiso: null,
    fechaReporte: '',
    observaciones: '',
    nuevo: false,
  };


  proyectos: SeguimientoDTO = {};
  id: number;
  nombreProyecto;
  displayedColumns: string[] = ['unidadFuncional', 'subTramo', 'etapa', 'fechaRegistro'];


  displayedEstados = [
    { field: 'id', header: 'ID' },
    { field: 'estado', header: 'Estado' },
    { field: 'fechaReporteMostrar', header: 'Fecha reporte' }, 
    { field: 'observaciones', header: 'Observaciones' }  

  ];

  displayedPriorizaciones = [
    { field: 'id', header: 'ID' },
    { field: 'priorizacion', header: 'Priorización' },
    { field: 'fechaReporteMostrar', header: 'Fecha reporte' }, 
    { field: 'observaciones', header: 'Observaciones' }  
  ];

  displayedActuaciones = [
    { field: 'id', header: 'ID' },
    { field: 'fechaReporteMostrar', header: 'Fecha reporte' }, 
    { field: 'actuacion', header: 'Actuación' },  
    { field: 'accionASeguir', header: 'Accion a seguir' }  

  ];


  seguimientoEstados: any = [];
  seguimientoPriorizaciones: any = [];
  seguimientoActuaciones: any = [];

  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(
    private service: SeguimientoTemasGestionService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
	  public location: Location) {
  }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarProyecto(this.id);
      this.cargarEstados();
      this.cargarPriorizaciones();
      this.cargarResponsables();
      this.cargarDepartamentos();
      // localStorage.setItem('idP', JSON.stringify(this.id));
    });
  }

  onNew() {
    this.route.navigate(['/infTramitesADetalle', 'new']);
  }

  cargarDepartamentos() {
    this.departamentos = [];
    return this.service.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
    });
  }

  filtrarMunicipio(event) {
    this.municipios = [];
    if (event) {
      return this.service.getCiudadesPorDepartamento(event).subscribe((data: {}) => {
        this.municipios = data;
      });
    }
  }


  cargarProyecto(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.service.obtenerListaSeguimiento(id).subscribe((data: {}) => {
    if (data) {
      this.proyectos = data;
      this.initData(this.proyectos);

    }
    this.dialogSpin.close();
    });
  }

  initData(payload) {
    this.proyectos = payload;
    this.seguimientoEstados = payload.estados;
    this.seguimientoPriorizaciones = payload.priorizaciones;
    this.seguimientoActuaciones = payload.actuaciones;
  }

  cargarEstados() {
    return this.service.getEstadoSeguimiento().subscribe((data: {}) => {
      this.estados = data;
    });
  }

  cargarPriorizaciones() {
    return this.service.getPriorizacion().subscribe((data: {}) => {
      this.priorizaciones = data;
    });
  }

  cargarResponsables() {
    return this.service.getResponsable().subscribe((data: {}) => {
      this.responsables = data;
    });
  }

  cancelar() {
    //this.route.navigate(['/infPeajes', JSON.parse(localStorage.getItem('idP'))]);
	this.location.back();
    this.dialogSpin.close();
  }



  getEstado(id) {
    if (id) {
      const dataLength = this.estados.length;
      for (let i = 0; i <= dataLength; i++) {
        if (this.estados[i].id === id) {
          return this.estados[i].nombre;
        }
      }
    } else {
      return '';
    }
  }

  modalEstados(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(EstadosGestionDialog, {
      data: {estados: this.estados,
        fechaReporte: this.fechaReporte,
        actoAdministrativo: this.actoAdministrativo,
        observaciones: this.observaciones
      }

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {

            this.estadosTemporal = {
              idTemaGestion: Number(this.id),
              idEstado: result.idEstado,
              fechaReporte: result.fechaReporte,
              observaciones: result.observaciones,
            };
            this.temporalEnviar = [];
            this.temporalEnviar.push(this.estadosTemporal);

            this.service.guardarSeguientoEstado(this.estadosTemporal).subscribe((data: {}) => {
              if (data) {
                this.visibleDialogoExito = true;
                this.initData(data);
              }
            });
           }
          });
    }
  }

  modalPriorizacion(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(PriorizacionGestionDialog, {
      data: {priorizaciones: this.priorizaciones,
        fechaReporte: this.fechaReporte,
        observaciones: this.observaciones
      }

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {

            this.priorizacionesTemporal = {
              idTemaGestion: Number(this.id),
              idPriorizacion: result.idPriorizacion,
              fechaReporte: result.fechaReporte,
              observaciones: result.observaciones,
            };
            this.temporalEnviar = [];
            this.temporalEnviar.push(this.priorizacionesTemporal);

            this.service.guardarSeguientoPriorizacion(this.priorizacionesTemporal).subscribe((data: {}) => {
              if (data) {
                this.visibleDialogoExito = true;
                this.initData(data);
              }
            });
           }
          });
    }
  }

  modalActuacion(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(AcuacionGestionDialog, {
      data: {estados: this.estados,
        fechaReporte: this.fechaReporte,
        actoAdministrativo: this.actoAdministrativo,
        observaciones: this.observaciones
      }

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {

            this.estadosTemporal = {
              idTemaGestion: Number(this.id),
              fechaReporte: result.fechaReporte,
              actuacion: result.actuacion,
              accionASeguir: result.accionASeguir,


            };
            this.temporalEnviar = [];
            this.temporalEnviar.push(this.estadosTemporal);

            this.service.guardarSeguientoActuacion(this.estadosTemporal).subscribe((data: {}) => {
              if (data) {
                this.visibleDialogoExito = true;
                this.initData(data);
              }
            });
           }
          });
    }
  }

    // Informativo - Dialogo
    aceptarDialogoExito() {
      this.visibleDialogoExito = false;
      //this.router.navigate(['/personaDatosBasicos-base/', this.resultadoGuardarDatosBasicos.resultado.id, this.resultadoGuardarDatosBasicos.resultado.idTipoDocumento]);
    }


  goToMapa(id) {
    // tslint:disable-next-line:max-line-length
    //window.open('https://app.powerbi.com/view?r=eyJrIjoiOWJlMDQxOTctMzM1MC00YTBmLTg0MmMtMzk2YjY2NGRiOTg3IiwidCI6IjcwZGM1N2VlLWZlNDYtNDI4Ni1iNjViLTFhZTllMTI2YzAzZSIsImMiOjR9', '_blank');
  }

}



