import { Component, OnInit, Inject  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { environment } from '../../../environments/environment';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-menu-juridico',
  templateUrl: './menu-juridico.component.html',
  styleUrls: ['./menu-juridico.component.sass']
})
export class MenuJuridicoComponent implements OnInit {
  proyecto: any;
  id: number;
  esProduccion: boolean = false;
  webUrlConcesiones : any;

  constructor(private infoService: InformacionGeneralService,
    private route: ActivatedRoute,
    private router: Router, @Inject(DOCUMENT) private document: any, private messageService: MessageService, private principalComponent: PrincipalComponent) {
   }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.cargarProyectoCarretero(this.id);
    });
    this.esProduccion = environment.esProduccion;
    this.webUrlConcesiones = environment.webUrlConcesiones;

    var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto);
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }



  irATribunales(){
    this.router.navigate([this.router.url+'/infTribunales', this.id]);
  }

  irAAmigables(){
    this.router.navigate([this.router.url+'/infAmigables', this.id]);
  }
}
