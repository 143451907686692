import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlcanceProyectoBaseComponent } from '../alcance-proyecto-base/alcance-proyecto-base.component';
import { EtapaProyectoCarreteroService } from '../etapa-proyecto-carretero/etapa-proyecto-carretero.service';
import { ParametricasService } from '../../../parametricas/parametricas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
    selector: 'app-etapa-proyecto-carretero2',
    templateUrl: './etapa-proyecto-carretero.component.html',
    styleUrls: ['./etapa-proyecto-carretero.component.sass']
})
export class EtapaProyectoCarreteroComponent2 implements OnInit {

    constructor(public lineaBase: AlcanceProyectoBaseComponent
        , private etapaProyectoCarreteroService: EtapaProyectoCarreteroService,
        private parametricasService: ParametricasService, private route: ActivatedRoute, private ruta: Router,
        private messageService: MessageService, private principalComponent: PrincipalComponent) { }

    visibleDialogoExito: boolean = false;
    visibleActualizaFechas: boolean = false;
    visibleHistorialEtapas: boolean = false;

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.lineaBase.idProyecto = params.id;
        });
    }




    guardarEtapas(forma: NgForm) {
       this.lineaBase.etapasProyecto.id = this.lineaBase.idProyecto;
        this.lineaBase.etapasProyecto.fechaInicioPreconstruccion = this.lineaBase.fechaInicioPreconstruccion;
        this.lineaBase.etapasProyecto.fechaFinPreconstruccion = this.lineaBase.fechaFinPreconstruccion;
        this.lineaBase.etapasProyecto.fechaInicioConstruccion = this.lineaBase.fechaInicioConstruccion;
        this.lineaBase.etapasProyecto.fechaFinConstruccion = this.lineaBase.fechaFinConstruccion;
        this.lineaBase.etapasProyecto.fechaInicioOperacion = this.lineaBase.fechaOperacion;
        this.lineaBase.etapasProyecto.fechaInicioReversion = this.lineaBase.fechaReversion;
       let errorFecha: boolean = false;
        if (this.lineaBase.fechaInicioPreconstruccion != null && this.lineaBase.fechaFinPreconstruccion != null) {
            if (this.lineaBase.fechaInicioPreconstruccion > this.lineaBase.fechaFinPreconstruccion) {
                errorFecha = true;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de pre-construcción debe ser menor que la fecha fin de pre-construcción', life: 10000 });
            }
        }
        if (this.lineaBase.fechaInicioConstruccion != null && this.lineaBase.fechaFinConstruccion != null) {
            if (this.lineaBase.fechaInicioConstruccion > this.lineaBase.fechaFinConstruccion) {
                errorFecha = true;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de construcción debe ser menor que la fecha fin de construcción', life: 10000 });
            }
        }
        if (!errorFecha) {
            this.etapaProyectoCarreteroService.guardarEtapa(this.lineaBase.etapasProyecto, this.lineaBase.idProyecto,this.lineaBase.idConfiguracion, this.lineaBase.esBorrador).subscribe((data: {}) => {
                this.visibleDialogoExito = true;
            }, error => {
                console.error(error);
                this.principalComponent.cargarErrorServicio(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
              });
        }
        this.visibleActualizaFechas = false;
    }

    aceptarDialogoExito() {
        this.visibleDialogoExito = false;
    }

}
