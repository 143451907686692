import { Component, OnInit } from '@angular/core';
import { ValidadorPdfService } from './validador-pdf.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../progress-spinner-dialog/progress-spinner-dialog.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
0
@Component({
  selector: 'app-certificado-pdf',
  templateUrl: './certificado-pdf.component.html',
  styleUrls: ['./certificado-pdf.component.sass']
})

export class CertificadoPdfComponent implements OnInit {
  displayedColumns1;
  listaValidadores: any = [];
  proyectos: any = [];
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>
  param: string;
  fileUrl;


  constructor(
    private info: ValidadorPdfService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    private principalComponent: PrincipalComponent) { 
    }

  ngOnInit() {
    this.displayedColumns1 = [
      { field: 'nombreArchivo', header: 'Nombre' },
      { field: 'fechaCreacion', header: 'Fecha Radicación' },       
    ];
    this.ruta.queryParams.subscribe(params => {
      this.param = params['Radicado'];
  });
    this.param ? this.cargarDataValidador(this.param): '';
  }


  onDownload(data) {
    var a = document.createElement("a");
    var fileName = data.nombreArchivo;
    var base64str = data.archivo;
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    var blob = new Blob( [view], { type: "application/pdf" });
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();

  }

  cargarDataValidador(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    return this.info.getProyectosAmbientales(id).subscribe((data: {}) => {
      this.proyectos = data;
      this.listaValidadores = [this.proyectos];
      this.dialogSpin.close();
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

}






