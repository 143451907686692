import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PersonaSeguimientoService } from './persona-seguimiento.service';
import { SeguimientoComunicacionesDetalleService } from '../../componentes/seguimiento/seguimiento-comunicaciones/detalle/seguimiento-comunicaciones-detalle.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-persona-seguimiento',
  templateUrl: './persona-seguimiento.component.html',
  styleUrls: ['./persona-seguimiento.component.sass'],
  providers: [PersonaSeguimientoService]
})
export class PersonaSeguimientoComponent implements OnInit {
  tiposDocumento: any = [];
  personas: any = [];
  numeroIdentificacion: string;
  primerNombre: string;
  otrosNombres: string;
  primerApellido: string;
  segundoApellido: string;
  tipoDocumento: string;
  correoElectronico: string;
  personaCargada: Boolean;
  isDocumento: Boolean;

  @Output() idPersonaChange = new EventEmitter<number>();

  @Output() idPersonaHabilitar = new EventEmitter<number>();

  @Output() idPersonaSeleccionada = new EventEmitter<any>();


  @Output () propagar: EventEmitter<string> = new EventEmitter();
  /**
   * Indica el tipo de persona. 1 Persona Externa, 2. Persona Interna
   */
  @Input() tipoPersona: number = 2;
  @Input() idPersona: number = -1;
  @Input() idTipoDocumento: string;
  constructor(private svcPersonas: PersonaSeguimientoService,
    public info: SeguimientoComunicacionesDetalleService,    
    private messageService: MessageService
    ) { }

  ngOnInit() {
    this.isDocumento = false;
    this.cargarTiposDocumento();
    if (this.idTipoDocumento != null) {
      this.tipoDocumento = this.idTipoDocumento;
    }
    this.cargarPersonas();
  }

  consultarPersona() {
    this.svcPersonas.getPersona(this.idPersona).subscribe((data: any) => {
      if (data != null) {
        this.primerNombre = data.primerNombre;
        this.otrosNombres = data.otrosNombres;
        this.primerApellido = data.primerApellido;
        this.segundoApellido = data.segundoApellido;
        this.numeroIdentificacion = data.numeroDocumento;
        this.tipoDocumento = data.idTipoDocumento;
        this.personaCargada = true;
      }
    });
  }
  resetearCamposPersonaExterna() {
    this.numeroIdentificacion = '';
    this.primerNombre = '';
    this.otrosNombres = '';
    this.primerApellido = '';
    this.segundoApellido = '';
  }

  cargarTiposDocumento() {
    this.svcPersonas.getTiposDocumento().subscribe((data: {}) => {
      this.tiposDocumento = data;
    });
  }

  cargarPersonas() {
    this.svcPersonas.getPersonas().subscribe((data: {}) => {
      this.personas = data;
    })
  }

  cambioNumeroIdentificacion() {
    if (this.tipoPersona == 2) {
      var persona = this.personas.filter(p => p.numeroDocumento == this.numeroIdentificacion && p.idTipoDocumento == this.tipoDocumento);
      if (persona != null && persona.length > 0) {
        this.personaCargada = true;
        this.primerNombre = persona[0].primerNombre
        this.otrosNombres = persona[0].otrosNombres
        this.primerApellido = persona[0].primerApellido;
        this.segundoApellido = persona[0].segundoApellido;
        this.idPersona = persona[0].id;
      } else {
        this.idPersona = -1;
        this.idPersonaChange.emit(this.idPersona);
        this.personaCargada = false;
      }
    }
  }

  retornarPersonaCargada() {
    this.idPersonaChange.emit(this.idPersona);
  }

  guardarPersona() {
    this.svcPersonas.setPersona({
      'primerNombre': this.primerNombre,
      'otrosNombres': this.otrosNombres != '' &&  this.otrosNombres != null && this.otrosNombres != undefined? this.otrosNombres : '',
      'primerApellido': this.primerApellido,
      'segundoApellido': this.segundoApellido != '' &&  this.segundoApellido != null && this.segundoApellido != undefined? this.segundoApellido : '',
      'numeroDocumento': this.numeroIdentificacion,
      'idTipoDocumento': this.tipoDocumento
        }).subscribe((data: any) => {
      this.idPersona = data.id;
      this.personaCargada = true;
      this.resetearCamposPersonaExterna();
      this.propagar.emit('true');
      this.lanzarMensajeInfo('Se ha creado la persona natural')
    });
  }

    ///////////////////////////////Mensajes//////////////////////////////
    lanzarMensajeInfo(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
    }
  
    lanzarMensajeError(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
    }
  
    lanzarMensajeWarning(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
    }
  
    limpiarMensajes() {
      this.messageService.clear();
    }

    
    onChangeDocumento(event) {
      if (event){
        this.isDocumento = true;
        this.numeroIdentificacion = "SIN-DOC";
      } else {
        this.isDocumento = false;
        this.numeroIdentificacion = "";
      }
    }




}
