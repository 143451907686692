import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { NgForm } from '@angular/forms';
import { CtoContratoDTO } from '../../../../contratacion-dto/contrato';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { ContratacionService } from '../../../../contratacion.service';
import { CtoTipoGarantiaDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-garantia';
import { CtoAseguradoraDTO } from 'src/app/contratacion/contratacion-dto/cto-aseguradora';
import { CtoAmparosGarantiaDTO } from '../../../../contratacion-dto/cto-amparos-garantia';

@Component({
  selector: 'app-contrato-poliza',
  templateUrl: './contrato-poliza.component.html'
})
export class ContratoPolizaComponent implements OnInit {


  @Input()
  contratoPoliza: CtoContratoDTO;
  @Output()
  contratoPolizaOutput = new EventEmitter<CtoContratoDTO>();

  ctoTiposGarantia: CtoTipoGarantiaDTO[];
  ctoAmparosGarantia: CtoAmparosGarantiaDTO[];
  ctoAseguradoras: CtoAseguradoraDTO[];

  constructor(
    private parametricasService: ParametricasService,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    if (this.contratoPoliza != null) {
      this.cargarListasParametricas();
    }
  }

  cargarListasParametricas() {
    this.contratacionService.getCtoTipoGarantia()
      .subscribe(data => {
        this.ctoTiposGarantia = data;
        if (this.contratoPoliza.idTipoGarantia != null) {
          this.contratoPoliza.idTipoGarantiaObject = this.ctoTiposGarantia.find(t => Number(t.id) === this.contratoPoliza.idTipoGarantia);
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );

    this.contratacionService.getCtoAmparoGarantia()
      .subscribe(
        data => {
          this.ctoAmparosGarantia = data;
          if (this.contratoPoliza.idAmparosGarantia != null) {
            this.contratoPoliza.idAmparosGarantiaObject = this.ctoAmparosGarantia.find(t => Number(t.id) === this.contratoPoliza.idAmparosGarantia);
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
    this.contratacionService.getCtoAseguradora().subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.ctoAseguradoras = result.aseguradoras;
            if (this.contratoPoliza.idAseguradora != null) {
              this.contratoPoliza.idAseguradoraObject = this.ctoAseguradoras.find(t => Number(t.id) === this.contratoPoliza.idAseguradora);
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }


  cargarTiposGarantia(event) {
    const filtroTiposGarantia = [];
    let existeFiltro: boolean;
    for (const tipGarantia of this.ctoTiposGarantia) {
      if (String(tipGarantia.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroTiposGarantia.push(tipGarantia);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.ctoTiposGarantia = filtroTiposGarantia;
    }
    return this.ctoTiposGarantia;
  }

  cargarAmparosGarantia(event) {
    const filtroAmparosGarantia = [];
    let existeFiltro: boolean;
    for (const ampGarantia of this.ctoAmparosGarantia) {
      if (String(ampGarantia.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroAmparosGarantia.push(ampGarantia);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.ctoAmparosGarantia = filtroAmparosGarantia;
    }
    return this.ctoAmparosGarantia;
  }

  cargarAseguradorasGarantia(event) {
    const filtroAseguradoras = [];
    let existeFiltro: boolean;
    for (const asegGarantia of this.ctoAseguradoras) {
      if (String(asegGarantia.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroAseguradoras.push(asegGarantia);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.ctoAseguradoras = filtroAseguradoras;
    }
    return this.ctoAseguradoras;
  }


  guardarContrato(form: NgForm) {
    this.contratoPolizaOutput.emit(this.contratoPoliza);
  }



}
