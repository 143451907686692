export const environment = {
  production: true,
  baseUrl : 'https://app-aniscopio-api-prod.azurewebsites.net/api',
  webUrlConcesiones: 'https://concesionessherlockaniweb.azurewebsites.net',
  esProduccion: true,
  baseUrlDrupalProyectoFichaPuerto: 'https://portalaniprueb.azurewebsites.net',

  /* Autenticación */
  tenant: "70dc57ee-fe46-4286-b65b-1ae9e126c03e",
  clientId: "362a0ffe-acf6-4473-be9a-d1803d8d2cbe",
  extraQueryParameter: 'nux=1', // This is optional
  endpoints: {
    "https://webaniscopio.azurewebsites.net": "362a0ffe-acf6-4473-be9a-d1803d8d2cbe" // Note, this is an object, you could add several things here
  },
  /* Final de Autenticacion */
  
  appInsights: {
    instrumentationKey: '947a76a6-181a-42a5-b17b-d0d0c020b69c'
  }
};
