import { Component, OnInit, ViewChild } from '@angular/core';
import { ModuloJuridicoService } from './modulo-juridico.service'
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ProyectoCarretero } from './proyecto-carretero';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-modulo-juridico',
  templateUrl: './modulo-juridico.component.html',
  styleUrls: ['./modulo-juridico.component.sass'],
  providers: [ModuloJuridicoService]
})
export class ModuloJuridicoComponent implements OnInit {
  generaciones: any = [];
  proyectos: any = [];
  esProduccion = false;
  displayedColumns: string[] = [
    'nombre',
    'convocado',
    'numero_radicado',
    'cuantia',
    'motivos_convocatoria',
    'observacion_cuantia',
    'fecha_notificacion',
    'descripcion_notificacion',
    'etapa',
    'amigables_componedores',
    'secretario']; // ,'etapaActual'];
  dataSource: MatTableDataSource<ProyectoCarretero>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private infoService: ModuloJuridicoService, private route: Router, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.cargarGeneraciones();
    this.cargarProyectosCarreteros();
    this.esProduccion = environment.production;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onEdit(id:number){
    this.route.navigate(['/menuJuridico', id]);
  }

  cargarGeneraciones() {
    return this.infoService.getGeneraciones().subscribe((data: {}) => {
      this.generaciones = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    })
  }

  cargarProyectosCarreteros() {
    return this.infoService.getProyectosCarreteros().subscribe((data: {}) => {
      this.proyectos = data;
      this.dataSource = new MatTableDataSource(this.proyectos);
      this.dataSource.paginator = this.paginator;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    })
  }

  nuevoProyecto(){
    this.route.navigate(['/nuevoIntervencion']);
  }
}
