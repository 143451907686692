import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ConfigService } from "../../config.service";
import { TrnRespuestaServicio } from "../../parametricas/trn-respuesta-servicio";
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { TrnRespuestaServicioRpt } from 'src/app/parametricas/rpt-respuesta-operacion';
import { PjeDocumentosRpt } from "../tarifas-peajes/nueva-tarifa/PjeDocumentos";

@Injectable({
    providedIn: 'root'
})
export class DocumentosPeajesService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public actualizaDocumento(data) {
        return this.http.post(this.END_POINT + '/PjeDocumentosPeajes/actualizaDocumento', data)
            .pipe(
                retry(1));
    }

    public eliminarDocumento(idDocumento: number): Observable<TrnRespuestaServicioRpt> {
        return this.http.delete<TrnRespuestaServicioRpt>(this.END_POINT + '/PjeDocumentosPeajes/eliminaDocumento/' + idDocumento)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    consultaDocumentosHistoricos(fechaExpedicion: string, numeroResolucion: string, tipoSoporte: number, busquedaFlexible: number) {
        return this.http.get<PjeDocumentosRpt>(this.END_POINT + '/PjeTarifasPeajes/getDocumentosHistorico/' + fechaExpedicion + '/' + numeroResolucion + '/' + tipoSoporte + '/' + busquedaFlexible)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

}

export class RespuestaServicioRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}
