import { Injectable, } from '@angular/core';
import { ConfigService } from '../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class YoutubeProyectoVideoService {
  public END_POINT = '';

  httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  };
      
  private API_URL = 'https://www.googleapis.com/youtube/v3/search';
  //private API_TOKEN = 'AIzaSyBsUN92ivGA20TZu9zyKbVPDcaw6fSHa2A';
  //private API_TOKEN = 'AIzaSyCnOKcEZJHZYGLQBHXKVLaP3otbm31BBbA'; //Clave de API 2
  private API_TOKEN = 'AIzaSyD3QXv5AqC5-efARRUhSKhI5qGwE1vFEHE'; //Clave de API 1  RODRIGO
  private channel = 'UCclV6CI6GC5fnEBpqPGL2zA';  
  //private channel = 'UCbs5y7kY_mBJEzP7jMzO2SQ'; //Canal Rodrigo Nuevo canal UCclV6CI6GC5fnEBpqPGL2zA
  private maxResults = '30';


  constructor(private config: ConfigService, private http: HttpClient) {
      this.END_POINT = environment.baseUrl;
    }

  /**
   * Metodo que carga la info del radicado
   */
   public getListVideosCanal(query: string) {
      const url = `${this.API_URL}?key=${this.API_TOKEN}&channelId=${this.channel}&order=date&part=snippet&type=video&maxResults=${this.maxResults}`;
      return this.http.get(url)
        .pipe(
          map((response: any) => response.items)
        );
  }
}
