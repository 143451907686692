import { Component, OnInit } from '@angular/core';

import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { InformeRevisionService } from '../../informe-revision.service';
import { EnumRespuestaServicio } from '../../../../comun/constantes/constantes-comunes';
import { EnumCtoPasoInforme } from '../../../constantes/contratacion-constantes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoInformeContratistaRevisionDTO } from 'src/app/contratacion/contratacion-dto/informe-revision';

@Component({
  selector: 'app-informes-cxp',
  templateUrl: './informes-cxp.component.html',
  styles: []
})
export class InformesCxpComponent implements OnInit {

  colsInformes: any[];
  informesContratistasCxP: CtoInformeContratistaRevisionDTO[];
  totalPaginator: any;
  blockedDocument = false;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private infCtaSupervisionService: InformeRevisionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarInformes();
  }

  cargarInformes() {
    this.blockedDocument = true;
    this.infCtaSupervisionService.ConsultarInformesContratistasAsignadosCxP()
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.informesContratistasCxP = result.informesContratistaRevision;
              this.totalPaginator = this.informesContratistasCxP.length;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  guardarAsignacionFechaInicialRevision(informe: CtoInformeContratistaRevisionDTO) {
    this.blockedDocument = true;
    this.infCtaSupervisionService.GuardarAsignacionFechaInicialRevisionInformeRevision(informe.idAsignacion, informe.idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            informe.idPersonaAsignacion = result.idPersonaAsignacion;
            this.irRevisarInforme(informe);
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  irRevisarInforme(informe: CtoInformeContratistaRevisionDTO) {
    this.router.navigate([this.router.url + '/informeContratistaCxPRegistro', informe.idContrato, informe.idAsignacion, informe.idInforme, informe.idVersionInforme, informe.idPasoInforme, informe.idPaso, informe.idPersonaAsignacion]);
  }

  irConsultarInforme(informe: CtoInformeContratistaRevisionDTO) {
    this.router.navigate([this.router.url + '/informeContratistaCxPRegistro', informe.idContrato, informe.idAsignacion, informe.idInforme, informe.idVersionInforme, informe.idPasoInforme, informe.idPaso, -1]);
  }

  get EnumCtoPasoInforme() {
    return EnumCtoPasoInforme;
  }
}
