import { DatePipe } from "@angular/common";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/config.service';
import { PjePeriodoTraficoRpt } from 'src/app/peajes-gestion/recaudo-peajes/PjePeriodoTrafico';
import { PjeDetallePeriodoDTO, PjeDetallePeriodoRpt } from 'src/app/peajes-gestion/recaudo-peajes/PjeDetallePeriodo';
import { InformacionPeajesRecaudoAgrupadoMesDTO, InformacionPeajesRecaudoAgrupadoCategoriaDTO, PjePeajeConsultaTPDRptDTO, InformacionPeajesRecaudoDetalleDTO, InformacionPeajesRecaudoEncDTO } from "./reportes-peajes.dto";
import { EnumFechaFormato } from "src/app/comun/constantes/constantes-comunes";


@Injectable({
    providedIn: 'root'
})

export class ReportesPeajesService {
    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
        private datepipe: DatePipe) {

        this.END_POINT = environment.baseUrl;
    }


    public getTraficoRecaudoPorAnioMes(idPeaje) {
        return this.http.get<InformacionPeajesRecaudoAgrupadoMesDTO>(this.END_POINT + '/RptPeajes/traficoRecaudoPorAnioMes/' + idPeaje)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getTraficoRecaudoPorAnioCategoria(idPeaje) {
        return this.http.get<InformacionPeajesRecaudoAgrupadoCategoriaDTO>(this.END_POINT + '/RptPeajes/traficoRecaudoPorAnioCategoria/' + idPeaje)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


    public getTraficoRecaudoPorAnioMesCategoria(idPeaje: number): Observable<any> {
        return this.http.get(this.END_POINT + '/RptPeajes/traficoRecaudoPorAnioMes/' + idPeaje)
            .pipe(
                retry(1));
    }

    public getTraficoRecaudoPorAnioCategoria2(idPeaje: number): Observable<any> {
        return this.http.get(this.END_POINT + '/RptPeajes/traficoRecaudoPorAnioCategoria/' + idPeaje)
            .pipe(
                retry(1));
    }



    public srvGetReportesTraficoRecaudoPeriodoPeaje(idPeaje: number) {
        return this.http.get<InformacionPeajesRecaudoEncDTO>(this.END_POINT + '/RptPeajes/GetReportesTraficoRecaudoPeriodoPeaje/' + idPeaje)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public cargaEncRecaudoPeriodoPorId(idPeriodo: number) {
        return this.http.get<InformacionPeajesRecaudoEncDTO>(this.END_POINT + '/RptPeajes/GetEncabezadoPeriodoPorId/' + idPeriodo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public cargaDetallePeriodo(idPeriodo: number) {
        return this.http.get<InformacionPeajesRecaudoDetalleDTO>(this.END_POINT + '/RptPeajes/GetDetallePeriodo/' + idPeriodo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


    cargaDetallePeriodoSinRecaudo(idPeriodo: number) {
        return this.http.get<PjeDetallePeriodoRpt>(this.END_POINT + '/RptPeajes/GetDetalleSinRecaudo/' + idPeriodo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    /**
     * Metodo que llama al servicio de consulta TPDA y el TPDM de un peaje por fecha de corte
     * @param idPeaje Id del Peaje
     * @param fechaCorte Fecha de corte
     * @returns Respuesta de la operación de tipo PjePeajeConsultaTPDRptDTO
     */
    srvConsultarTPDPeaje(idPeaje: number, fechaCorte: Date): Observable<PjePeajeConsultaTPDRptDTO> {
        let urlEndpoint = this.END_POINT + '/RptPeajes/GetConsultarTPDPeaje?';
        if (idPeaje != null) {
            urlEndpoint = urlEndpoint + '&idPeaje=' + idPeaje;
        }
        if (fechaCorte != null) {
            urlEndpoint = urlEndpoint + '&fechaCorte=' + this.datepipe.transform(fechaCorte, EnumFechaFormato.YYYY_MM_DD_GUION);
        }

        return this.http.get<PjePeajeConsultaTPDRptDTO>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }));
    }


}