import { Component, OnInit } from '@angular/core';
import {ChartModule} from 'primeng/chart';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-public-proyectos5g',
  templateUrl: './public-proyectos5g.component.html',
  styleUrls: ['./public-proyectos5g.component.sass']
})

export class PublicProyectos5gComponent implements OnInit {
 
  items: MenuItem[];
  MostrarOla1: boolean=true;
  MostrarOla2: boolean=false;
  MostrarSostenibilidad: boolean=false;
  constructor() {     

    this.items = [
      {label: 'Proyectos 5G 1 Ola' , icon: 'pi pi-fw pi-plus', command:(event)=>{ this.MostrarOla1=true; this.MostrarOla2=false; this.MostrarSostenibilidad=false;}},
      {label: 'Proyectos 5G 2 Ola' , icon: 'pi pi-fw pi-plus', command:(event)=>{ this.MostrarOla1=false; this.MostrarOla2=true; this.MostrarSostenibilidad=false; }},    
      {label: 'Ficha de Sostenibilidad' , icon: 'pi pi-fw pi-plus', command:(event)=>{ this.MostrarOla1=false; this.MostrarOla2=false; this.MostrarSostenibilidad=true;}},
  ];
  }  

  displayModal: boolean;
  displayModal2: boolean;
  displayModal3: boolean;
  displayModal4: boolean;
  showModalDialog() {
    this.displayModal = true;
  }
  showModalDialog2() {
    this.displayModal2 = true;
  }
  showModalDialog3() {
    this.displayModal3 = true;
  }
  showModalDialog4() {
    this.displayModal4 = true;
  }


  ngOnInit() {

    }
}
