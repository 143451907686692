import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private adalSvc: MsAdalAngular6Service) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    var updatedRequest = null;
    
    if(!request.url.includes('youtube')){
      updatedRequest = request.clone({
        headers: request.headers.set("Authorization", 'Bearer ' + sessionStorage.getItem('authtoken'))
      });
    }else{
      updatedRequest = request.clone();
    }
    

    var updatedRequest = null;
    
    if(!request.url.includes('youtube')){
      updatedRequest = request.clone({
        headers: request.headers.set("Authorization", 'Bearer ' + sessionStorage.getItem('authtoken'))
      });
    }else{
      updatedRequest = request.clone();
    }

    return next.handle(updatedRequest).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {

          }
        },
        error => {
          if (event instanceof HttpResponse) {

          } else if (error instanceof HttpErrorResponse) {

            if (error.status == 401) {

              this.adalSvc.acquireToken('api://ae41e71b-79ec-463f-9a2f-04b9fefd4eef')
                .subscribe((token: string) => {

                  sessionStorage.setItem('authtoken', token);
                  next.handle(updatedRequest).pipe(
                    tap(
                      event => {
                        if (event instanceof HttpResponse) {
                       }
                      },
                      error => {
                        if (event instanceof HttpResponse) {

                        } else if (error instanceof HttpErrorResponse) {                        }
                      }
                    )
                  );
                })
            }
          }
        }
      )
    );
  }
}
