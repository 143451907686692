import { Component, OnInit } from '@angular/core';
import { ContratacionService } from '../contratacion.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CtoContratoInformacionVigenteEncabezadoDTO } from '../contratacion-dto/contrato-informacion-vigente-encabezado';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { EnumCtoEstadoContrato } from 'src/app/contratacion/constantes/contratacion-constantes';
import { EnumFechaFormato, EnumRegex } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from '../../comun/constantes/constantes-comunes';

@Component({
  selector: 'app-contratos-opciones-adicionales',
  templateUrl: './contratos-opciones-adicionales.component.html',
  styleUrls: []
})
export class ContratosOpcionesAdicionalesComponent implements OnInit {

  contratos: CtoContratoInformacionVigenteEncabezadoDTO[] = [];
  totalRecords: any;
  blockedDocument = false;
  // Filtros
  buscarFiltro: string;

  contrato: any;
  titulo: string;
  tipo: string;
  visibleDialogoAgregar: boolean;

  constructor(
    private datepipe: DatePipe,
    private messageService: MessageService,
    private contratacionService: ContratacionService,
    private ruta: ActivatedRoute,
    private router: Router,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.tipo = params.tipo;
      // console.log(this.tipo);
      switch (this.tipo) {
        case 'obl':
          this.titulo = 'Obligaciones Contrato'
          break;
        case 'pln':
          this.titulo = 'Plan de Pagos Contrato'
          break;
      }
    });

  }

  loadDataLazy(event: LazyLoadEvent) {
    this.blockedDocument = true;
    this.contratos = [];
    this.cargarContratos(event.first, event.rows, 'Id', 2);
  }

  buscar() {
    const filaDesde = 0;
    const cantidadRegistros = 20;
    this.blockedDocument = true;
    this.cargarContratos(filaDesde, cantidadRegistros, 'Id', 2);
  }

  limpiarFiltros() {
    this.buscarFiltro = null;
    this.buscar();
  }

  cargarContratos(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.blockedDocument = true;
    this.contratacionService.srvConsultarContratosInformacionVigente(null, null, null, null, null, null, null, filaDesde, cantidadRegistros, this.buscarFiltro, campoOrden, ascDesc)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.contratos = result.contratosInformacionVigente;
              this.contratacionService.srvCalcularEstadoVigenciaContrato(this.contratos);
              this.totalRecords = this.contratos.length == 0 ? 0 : this.contratos[0].totalRegistros;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  irEditarContrato(contrato) {
    switch (this.tipo) {
      case 'obl':
        this.router.navigate([this.router.url + '/obligaciones/', contrato.id]);
        break;
      case 'pln':
        this.router.navigate([this.router.url + '/planPagos/', contrato.id, 'planPagos']);
        break;
    }
  }

  cancelar() {
    this.visibleDialogoAgregar = false;
  }

  get EnumCtoEstadoContrato() {
    return EnumCtoEstadoContrato;
  }

  get EnumRegex() {
    return EnumRegex;
  }
}
