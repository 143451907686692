import { Component, OnInit} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router,ActivatedRoute } from "@angular/router";
import { loadModules } from "esri-loader";

@Component({
  selector: 'app-trafico-ani',
  templateUrl: './trafico-ani.component.html',
  styleUrls: ['./trafico-ani.component.css']
})
export class TraficoAniComponent implements OnInit {
  date7: Date;
  date10: String;
  constructor(
    public datepipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {

  //   function findGetParameter(parameterName) {
  //     var result = null,
  //         tmp = [];
  //     var items = location.search.substr(1).split("&");
  //     for (var index = 0; index < items.length; index++) {
  //         tmp = items[index].split("=");
  //         if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  //     }
  //     return result;
  // }

  //   this.datefecha = findGetParameter('date');
    
  
    document.getElementById("traficodiv").innerHTML = '';
    document.getElementById("basemapGalleryDiv").innerHTML = '';
    document.getElementById("barravalores").innerHTML = '';

    //const vardate = findGetParameter('date');
    const datefecha = this.route.snapshot.params['date'];
    if(datefecha != null){
      console.log('datefecha=' + datefecha);
      var fecha = new Date(datefecha);
    } else{
      var fecha = new Date();
      fecha.setDate(fecha.getDate() - 1);
    }
    //console.log(vardate);
    //var fecha = new Date();
    
    

    loadModules([
      "esri/config",
      "esri/Map",
      "esri/views/SceneView",
      "esri/views/MapView",
      "esri/layers/GeoJSONLayer",
      "esri/geometry",
      "esri/layers/GraphicsLayer",
      "esri/Graphic",
      "esri/layers/FeatureLayer",
      "esri/layers/support/FeatureFilter",
      "esri/widgets/Search",
      "esri/layers/VectorTileLayer",
      "esri/symbols/SimpleLineSymbol",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/geometry/Polyline",
      "esri/renderers/SimpleRenderer",
      "esri/PopupTemplate",
      "esri/popup/content/CustomContent",
      "esri/rest/support/Query",
      "esri/rest/query",
      "esri/rest/support/StatisticDefinition",
      "esri/intl",
      "esri/widgets/Attribution",
      "esri/widgets/LayerList",
      "esri/widgets/LayerList/LayerListViewModel"
    ])
      //.then(([esriConfig,Map,WebMap,Basemap, VectorTileLayer, TileLayer, MapView, FeatureLayer, Search]) => {
      .then(
        ([
          esriConfig,
          Map,
          SceneView,
          MapView,
          GeoJSONLayer,
          geometry,
          GraphicsLayer,
          Graphic, FeatureLayer,
          FeatureFilter,
          Search,
          VectorTileLayer,
          SimpleMarkerSymbol,
          SimpleLineSymbol,
          Polyline,
          SimpleRenderer,
          PopupTemplate,
          CustomContent,
          Query,
          query,
          StatisticDefinition,
          intl,
          Attribution,
          LayerList,
          LayerListVM,
        ]) => {
          let stats = {};
          var resultsLayer = new GraphicsLayer();
          esriConfig.apiKey = "AAPK2456e7e0539844b39d13266e742a95cbYV9nTVDpArBvqC_Y21vE-ZcWrZCNAXTgi3ZGBtO11pzgJMdZwMq9FmQyq_78eY8I";
          
          let fecha1 = this.datepipe.transform(fecha, 'yyyy-MM-dd/HH-mm-ss');
          let fecha2 = this.datepipe.transform(fecha, 'yyyy-MM-ddThh:mm');
          (<HTMLInputElement>document.getElementById('dateget')).value= fecha2;
      
          console.log(fecha1);
          //dateurl = DateFormat.format.date(fecha, " yyyy-MM-dd/HH-mm-ss");
          const urlgeo =
            "https://serviciosaniscopio.ani.gov.co/api/SigWaze/GetGeoJsonWaze/" + fecha1;
            //"https://app-aniscopio-servicios-test.azurewebsites.net/api/SigWaze/GetGeoJsonWaze/2022-10-08T16:37:33.164Z";
            //"https://app-aniscopio-servicios-test.azurewebsites.net/api/SigWaze/GetGeoJsonWaze/2022-10-09/16:37:33"
          /*const map = new Map({
              basemap: "arcgis-light-gray",
              layers: [vtlLayer]
          });*/

          const citiesRenderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              url: "/assets/img/newhome/alertas/ic_p.png",
              width: "45px",
              height: "50px"
            }
          };

          const otherSym2 = {
            type: "picture-marker", // autocasts as new PictureMarkerSymbol()
            url: "/assets/img/newhome/alertas/ic_p.png",
            width: "45px",
            height: "50px"
          };

          const AL1 = {
            type: "picture-marker", // autocasts as new PictureMarkerSymbol()
            url: "/assets/img/newhome/alertas/ic1.png",
            width: "45px",
            height: "50px"
          };

          // Symbol for U.S. Highways
          const AL2 = {
            type: "picture-marker", // autocasts as new PictureMarkerSymbol()
            url: "/assets/img/newhome/alertas/ic2.png",
            width: "45px",
            height: "50px"
          };
          const AL3 = {
            type: "picture-marker", // autocasts as new PictureMarkerSymbol()
            url: "/assets/img/newhome/alertas/ic3.png",
            width: "45px",
            height: "50px"
          };
          const AL4 = {
            type: "picture-marker", // autocasts as new PictureMarkerSymbol()
            url: "/assets/img/newhome/alertas/ic4.png",
            width: "45px",
            height: "50px"
          };

          const renderer2 = {
            type: "simple",
            field: "mag",
            symbol: {
              type: "simple-marker",
              color: "orange",
              outline: {
                color: "white"
              }
            },
            visualVariables: [{
              type: "size",
              field: "mag",
              stops: [{
                value: 2.5,
                size: "4px"
              }, {
                value: 8,
                size: "40px"
              }]
            }]
          };

          const otherSym1 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#FD77DD",
            width: 2,
            style: "solid",
          };
          const UF1 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [242, 202, 37, 0.8],
            width: 5,
            style: "solid",
            trans: 0.1,
          };

          // Symbol for U.S. Highways
          const UF2 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [252, 163, 56, 0.8],
            width: 5,
            style: "solid",
          };
          const UF3 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [229, 113, 35, 0.8],
            width: 5,
            style: "solid",
          };
          const UF4 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [226, 80, 80, 0.8],
            width: 5,
            style: "solid",
          };
          const UF5 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [163, 10, 10, 0.8],
            width: 5,
            style: "solid",
          };
          const hwyRenderer2 = {
            type: "unique-value", // autocasts as new UniqueValueRenderer()
            opacity: 1,
            defaultSymbol: otherSym1,
            defaultLabel: "level",
            field: "level",
            uniqueValueInfos: [{
              value: 1,
              symbol: UF1
            }, {
              value: 2,
              symbol: UF2
            }, {
              value: 3,
              symbol: UF3
            }, {
              value: 4,
              symbol: UF4
            }, {
              value: 5,
              symbol: UF5
            }]

          };

          const alertasRender = {
            type: "unique-value", // autocasts as new UniqueValueRenderer()
            opacity: 1,
            defaultSymbol: otherSym2,
            defaultLabel: "type",
            field: "type",
            uniqueValueInfos: [{
              value: 'JAM',
              symbol: AL1
            }, {
              value: 'WEATHERHAZARD',
              symbol: AL4
            }, {
              value: 'ACCIDENT',
              symbol: AL2
            }, {
              value: 'ROAD_CLOSED',
              symbol: AL3
            }]

          };


          const pointPop = {

            //content: "{street}<br>  {city}"
            content: pointChange,
            overwriteActions: true,
            returnGeometry: true,
          };

          function pointChange(feature) {
            const div = document.createElement("div");

            const type = feature.graphic.attributes.type;
            const street1 = feature.graphic.attributes.street;
            const city = feature.graphic.attributes.city;
            var imagenp;
            imagenp = (type == 'JAM' ? "<img src='/assets/img/newhome/alertas/ic1.png' />" : (type == 'WEATHERHAZARD' ? "<img src='/assets/img/newhome/alertas/ic4.png' />" : (type == 'ACCIDENT' ? "<img src='/assets/img/newhome/alertas/ic2.png' />" : (type == 'ROAD_CLOSED' && "<img src='/assets/img/newhome/alertas/ic3.png' />"))));
            var titlep;
            titlep = (type == 'JAM' ? "Embotellamiento" : (type == 'WEATHERHAZARD' ? "Peligro del Clima" : (type == 'ACCIDENT' ? "Accidente" : (type == 'ROAD_CLOSED' && "Carretera Cerrada "))));

            // Add green arrow if the percent change is positive and a red arrow for negative percent change.
            div.innerHTML =
              "<div class='imgpintpop'>" + imagenp + "</div> <div class='contenpoppoint'> <div class='titlep'>" + titlep + "</div>" +
              "<div>" + street1 +
              "</div></div>";
            return div;
          }

          const linePop = {
            title: "Trafico",
            content: velocidadChange,
            //content: "En {street} en {endNode}<br>Velocidad media: {speedKMH}km/h ",
            overwriteActions: true,
            returnGeometry: true,

          };

          function velocidadChange(feature) {
            const div = document.createElement("div");

            const level1 = feature.graphic.attributes.level;
            const street1 = feature.graphic.attributes.street;
            const endNode = feature.graphic.attributes.endNode;
            const speedKMH = feature.graphic.attributes.speedKMH;

            // Add green arrow if the percent change is positive and a red arrow for negative percent change.
            div.innerHTML =
              "<div class='contenpop d" + level1 + "'>En " + street1 + " en " + endNode + "<hr>Velocidad media: <span class='velicidad'>" + speedKMH + " km/h</span> " +
              "</div>";
            return div;
          }
          console.log('data: '+ urlgeo);
          const geojsonLayer = new GeoJSONLayer({
            url: urlgeo,
            popupTemplate: pointPop,
            renderer: alertasRender,
            geometryType: "point",
            outFields: ["*"],
            //LayerList: "show"
          });

          const geojsonLayer4 = new GeoJSONLayer({
            url: urlgeo,
            popupTemplate: linePop,
            renderer: hwyRenderer2,
            geometryType: "polyline",
            outFields: ["*"],
          });
          /*let filteredFeatures = geojsonLayer.filter(function(feature) {
              return feature.geometry.type == "Point";
          });*/

          const carreterasRender = {
            type: "simple",
            symbol: {
                color: "#1166d0",
                type: "simple-line",
                style: "solid",
                width: 1,
                join: "round",
            },
        };


          const vtlLayer = new VectorTileLayer({
            url: "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/9954884ef30a4f0ca143b5c78b2263aa"
          });

          const featureLayerPeajes = new FeatureLayer({
            url: "https://services8.arcgis.com/qXzQm8c8qEBxS81s/ArcGIS/rest/services/Peajes_Reportes/FeatureServer/0",
            renderer: citiesRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              title: "PEAJE {Generacion}",
              content: "<div class='row poppeaje'><div class='col-md-4'><img src='/assets/img/newhome/alertas/ic_p.png'/></div><div class='col-md-8'><div class='titlepop'>{NOMBRE}</div></br>Estado: {Estado}</div></div>",
              overwriteActions: true,
              returnGeometry: true,
            },
            //definitionExpression: "Idpr = '" + this.idProyecto + "'",
          });

          const featureLayerCarreteros = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgis/rest/services/SigAni/Concesiones_Modos/MapServer/3",
            outFields: ["*"],
            renderer: carreterasRender,
            definitionExpression: "IdPr = '157'",
            popupTemplate: {
                // autocasts as new PopupTemplate()
                type: "fields",
                title: "{NombrePr}",
                content: "<b>Generacion:</b> {Generacion}<br/> <a class='btn-detalle' href='{URL}'>Detalle</a>",
                overwriteActions: false,
                includeDefaultSources: false,
            },
        });


          const map = new Map();

          map.add(vtlLayer);
          map.add(featureLayerCarreteros);
          map.add(featureLayerPeajes);
          /* map.add(featureLayerAeropuertos);
           map.add(featureLayerpuertos);
           map.add(featureLayerFerro);
           */
          //map.add(geojsonLayer);

          map.add(geojsonLayer4);
          map.add(geojsonLayer);

          geojsonLayer4.queryFeatures().then(function (results) {
            let valores5 = [];
            let valores4 = [];
            let valores3 = [];
            let valores2 = [];
            let valores1 = [];
            // prints the array of result graphics to the console
            let todo1 = results.features;
            //console.log('demo');
            //console.log(todo1);
            //const demo23 = results.features;
            let valor5 = 0;
            let valor4 = 0;
            let valor3 = 0;
            let valor2 = 0;
            let valor1 = 0;
            todo1.forEach(function (elemento, indice, array) {
              if (elemento.attributes.level == 5) {
                valor5 += 1;
                valores5.push({
                  ele: elemento.attributes,
                });
              }
              if (elemento.attributes.level == 4) {
                valor4 += 1;
                valores4.push({
                  ele: elemento.attributes,
                });
              }
              if (elemento.attributes.level == 3) {
                valor3 += 1;
                valores3.push({
                  ele: elemento.attributes,
                });
              }
              if (elemento.attributes.level == 2) {
                valor2 += 1;
                valores2.push({
                  ele: elemento.attributes,
                });
              }
              if (elemento.attributes.level == 1) {
                valor1 += 1;
                valores1.push({
                  ele: elemento.attributes,
                });
              }

            });
            //return frutas1 = Puntos;
            const jsonv5 = JSON.stringify(valores5);
            const jsonv4 = JSON.stringify(valores4);
            const jsonv3 = JSON.stringify(valores3);
            const jsonv2 = JSON.stringify(valores2);
            const jsonv1 = JSON.stringify(valores1);

            let totalvalores = valor5 + valor4 + valor3 + valor2 + valor1;
            let porcentajev1 = 100 / totalvalores * valor1;
            let porcentajev2 = 100 / totalvalores * valor2;
            let porcentajev3 = 100 / totalvalores * valor3;
            let porcentajev4 = 100 / totalvalores * valor4;
            let porcentajev5 = 100 / totalvalores * valor5;

            document.getElementById("barravalores").innerHTML =
              "<div data-info='" + porcentajev1.toFixed(2) + "' class='d1 semaf' style='width:" + porcentajev1 + "%;'><div class='numerop'>" + porcentajev1.toFixed(1) + "%</div></div>" +
              "<div data-info='" + porcentajev2.toFixed(2) + "' class='d2 semaf' style='width:" + porcentajev2 + "%;'><div class='numerop'>" + porcentajev2.toFixed(1) + "%</div></div>" +
              "<div data-info='" + porcentajev3.toFixed(2) + "' class='d3 semaf' style='width:" + porcentajev3 + "%;'><div class='numerop'>" + porcentajev3.toFixed(1) + "%</div></div>" +
              "<div data-info='" + porcentajev4.toFixed(2) + "' class='d4 semaf' style='width:" + porcentajev4 + "%;'><div class='numerop'>" + porcentajev4.toFixed(1) + "%</div></div>" +
              "<div data-info='" + porcentajev5.toFixed(2) + "' class='d5 semaf' style='width:" + porcentajev5 + "%;'><div class='numerop'>" + porcentajev5.toFixed(1) + "%</div></div>"
              ;

            document.getElementById("traficodiv").innerHTML =
              '<button class="btn btn-valores b5 btn-block" type="button" data-toggle="collapse" data-target="collapseExample" aria-expanded="false" aria-controls="collapseExample"> Tráfico Nivel 5 <span>' + porcentajev5.toFixed(1) + '% </span></button>' +
              '<div class="collapse" id="collapseExample">' +
              '</div>'
              ;

            const usersByLikes = valores5.map(item => {
              if (item.ele.street != null) {
                document.getElementById("collapseExample").innerHTML +=
                "<div data-trafico='" + item.ele.level + "' class='cardlist-item'><strong>" + item.ele.street +
                "</strong><br/>Velocidad media: " + item.ele.speedKMH + " km/h" +
                "</div>"
                  ;
              }
            })


            document.getElementById("traficodiv").innerHTML +=
              '<button class="btn btn-valores b4 btn-block" type="button" data-toggle="collapse" data-target="collapseExample2" aria-expanded="false" aria-controls="collapseExample2"> Tráfico Nivel 4 <span>' + porcentajev4.toFixed(1) + '%</span></button>' +
              '<div class="collapse" id="collapseExample2">' +
              '</div>'
              ;

            const usersvalores4 = valores4.map(item => {

              document.getElementById("collapseExample2").innerHTML +=
                "<div data-trafico='" + item.ele.level + "' class='cardlist-item'><strong>" + item.ele.street +
                "</strong><br/>Velocidad media: " + item.ele.speedKMH + " km/h" +
                "</div>"
                ;
            })

            document.getElementById("traficodiv").innerHTML +=
              '<button class="btn btn-valores b3 btn-block" type="button" data-toggle="collapse" data-target="collapseExample3" aria-expanded="false" aria-controls="collapseExample3"> Tráfico Nivel 3 <span>' + porcentajev3.toFixed(1) + '%</span></button>' +
              '<div class="collapse" id="collapseExample3">' +
              '</div>'
              ;

            const usersvalores3 = valores3.map(item => {

              document.getElementById("collapseExample3").innerHTML +=
                "<div data-trafico='" + item.ele.level + "' class='cardlist-item'><strong>" + item.ele.street +
                "</strong><br/>Velocidad media: " + item.ele.speedKMH + " km/h" +
                "</div>"
                ;
            })

            document.getElementById("traficodiv").innerHTML +=
              '<button class="btn btn-valores b2 btn-block" type="button" data-toggle="collapse" data-target="collapseExample4" aria-expanded="false" aria-controls="collapseExample4">  Tráfico Nivel 2 <span>' + porcentajev2.toFixed(1) + '%</span></button>' +
              '<div class="collapse" id="collapseExample4">' +
              '</div>'
              ;

            const usersvalores2 = valores2.map(item => {

              document.getElementById("collapseExample4").innerHTML +=
                "<div data-trafico='" + item.ele.level + "' class='cardlist-item'><strong>" + item.ele.street +
                "</strong><br/>Velocidad media: " + item.ele.speedKMH + " km/h" +
                "</div>"
                ;
            })

            document.getElementById("traficodiv").innerHTML +=
              '<button class="btn btn-valores b1 btn-block" type="button" data-toggle="collapse" data-target="collapseExample5" aria-expanded="false" aria-controls="collapseExample5"> Tráfico Nivel 1 <span>' + porcentajev1.toFixed(1) + '%</span></button>' +
              '<div class="collapse" id="collapseExample5">' +
              '</div>'
              ;

            const usersvalores1 = valores1.map(item => {

              document.getElementById("collapseExample5").innerHTML +=
                "<div data-trafico='" + item.ele.level + "' class='cardlist-item'><strong>" + item.ele.street +
                "</strong><br/>Velocidad media: " + item.ele.speedKMH + " km/h" +
                "</div>"
                ;
            })

            var elements = document.getElementsByClassName("btn-valores");

            var myFunction = function () {
              for (var i = 0, all = elements.length; i < all; i++) {
                elements[i].classList.remove("open");
              }
              var attribute = this.getAttribute("data-target");
              var colla = document.getElementById(attribute);
              colla.classList.toggle("open");
            };
            
            for (var i = 0; i < elements.length; i++) {
              elements[i].addEventListener('click', myFunction, false);
            }



          });

          const view = new MapView({
            container: "viewDiv",
            map: map,
            center: [-73.8, 5], // lon, lat
            zoom: 9,
            popup: {

              dockEnabled: false,
              dockOptions: {
                // Disables the dock button from the popup
                buttonEnabled: false,
                // Ignore the default sizes that trigger responsive docking
                breakpoint: false,
                position: 'Top Left'
              }
            },
          });

          view.on("click", function (event) {
            view.popup.location = event.mapPoint;
            // Displays the popup
            view.popup.visible = true;
          });

          // const basemapGallery = new BasemapGallery({
          //   view: view,
          //   container: basemapGallery
          // });

          const searchWidget = new Search({
            view: view,
            container: "basemapGalleryDiv",
            allPlaceholder: "Buscar",
            includeDefaultSources: false,
            sources: [{
              layer: featureLayerPeajes,
              searchFields: ["NOMBRE"],
              suggestionTemplate: " {NOMBRE}",
              displayField: "NOMBRE",
              exactMatch: false,
              outFields: ["*"],
              name: "Peaje",
              maxResults: 6,
              enableLabel: true,
              placeholder: "Andes",
            }]
          });

          // Add the search widget to the top left corner of the view
          // view.ui.add(searchWidget, {
          //     class: 'form-control',
          //     declaredClass: 'form-control',
          // });



          // searchWidget.on("search-start", function(event) {
          //     console.log("Search started.");
          // });
          const button = document.getElementById('btn-filter'); 
          button.addEventListener('click', () => { 
            const dateb = (<HTMLInputElement>document.getElementById('dateget')); 
            document.getElementById("traficodiv").innerHTML = '';
            document.getElementById("basemapGalleryDiv").innerHTML = '';
            document.getElementById("barravalores").innerHTML = '';
            console.log(dateb.value);
            let fecha3 = this.datepipe.transform(dateb.value, 'yyyy-MM-dd/HH-mm-ss');
            console.log(fecha3);
            this.date10 = this.datepipe.transform(dateb.value, 'yyyy-MM-dd/HH-mm-ss');
            
            //  this.router.navigate(["trafico-ani/" + dateb.value]);
            window.location.href = "trafico-ani/" + dateb.value;
            
          }); 

          


        }
      )
      .catch((err) => {
        // handle any errors
        console.error(err);
      });

      
    // var boxes = document.querySelectorAll('.btn-valores');

    // for (var i = 0, all = boxes.length; i < all; i++) {
    //   boxes[i].addEventListener('click', function handleClick() {
    //     boxes[i].classList.add('bg-yellow');
    //   });
    // }


  }
  dateFecha_f(){
    document.getElementById("traficodiv").innerHTML = '';
    document.getElementById("basemapGalleryDiv").innerHTML = '';
    document.getElementById("barravalores").innerHTML = '';
    console.log('dateee:'+ this.date10);
    const datefecha = null;
    this.ngOnInit();
  }

}
