import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/config.service';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { environment } from 'src/environments/environment';
import { AccionistaCarretero, ComposicionAccionistasCarretero, ContratistaCarretero, ContratoCarretero, ContratoContratista, HistoricoContrato, NovedadContrato } from './informacion-contractual-carretero.dto';

@Injectable({
    providedIn: 'root'
})
export class InformacionContractualCarreteroService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public listarContratosPorProyecto(id): Observable<ConsultaContratosPorProyectoCarreteroRpt> {
        return this.http.get<ConsultaContratosPorProyectoCarreteroRpt>(this.END_POINT + '/CrrInformacionContractual/contratosPorProyecto/' + id)
            .pipe(
                retry(1));
    }

    public consultarContratoPorId(id): Observable<ConsultaContratoCarreteroRpt> {
        return this.http.get<ConsultaContratoCarreteroRpt>(this.END_POINT + '/CrrInformacionContractual/contrato/' + id)
            .pipe(
                retry(1));
    }

    public GuardarContrato(contrato: ContratoCarretero): Observable<ConsultaContratoCarreteroRpt> {
        return this.http.post<ConsultaContratoCarreteroRpt>(this.END_POINT + '/CrrInformacionContractual/guardarContrato', contrato)
            .pipe(
                retry(1));
    }

    public consultarContratistaPorId(id): Observable<ConsultaContratistaCarreteroRpt> {
        return this.http.get<ConsultaContratistaCarreteroRpt>(this.END_POINT + '/CrrInformacionContractual/contratista/' + id)
            .pipe(
                retry(1));
    }

    public guardarContratista(contratista: ContratistaCarretero): Observable<ConsultaContratistaCarreteroRpt> {
        return this.http.post<ConsultaContratistaCarreteroRpt>(this.END_POINT + '/CrrInformacionContractual/guardarContratista', contratista)
            .pipe(
                retry(1));
    }
    
    public guardarContratoContratista(contratoContratista: ContratoContratista): Observable<ConsultaContratoContratistaCarreteroRpt> {
        return this.http.post<ConsultaContratoContratistaCarreteroRpt>(this.END_POINT + '/CrrInformacionContractual/guardarContratoContratista', contratoContratista)
            .pipe(
                retry(1));
    }

    public listarAccionistasEncabezadoPorIdContratista(id): Observable<ListaComposicionAccionistaEncabezadoCarreteroRpt> {
        return this.http.get<ListaComposicionAccionistaEncabezadoCarreteroRpt>(this.END_POINT + '/CntAccionistaContratista/composicionAccionaria/list/' + id)
            .pipe(
                retry(1));
    }

    public consultarAccionistasEncabezadoPorId(id): Observable<ConsultaComposicionAccionistaEncabezadoCarreteroRpt> {
        return this.http.get<ConsultaComposicionAccionistaEncabezadoCarreteroRpt>(this.END_POINT + '/CntAccionistaContratista/composicionAccionaria/encabezado/' + id)
            .pipe(
                retry(1));
    }

    public guardarAccionistasEncabezadoPorIdContratista(composicionAccionistasCarretero: ComposicionAccionistasCarretero): Observable<ConsultaComposicionAccionistaEncabezadoCarreteroRpt> {
        return this.http.post<ConsultaComposicionAccionistaEncabezadoCarreteroRpt>(this.END_POINT + '/CntAccionistaContratista/guardarComposicionAccionaria', composicionAccionistasCarretero)
            .pipe(
                retry(1));
    }

    public eliminarAccionistasEncabezadoPorIdContratista(id, idContratista): Observable<EliminarComposicionAccionistaEncabezadoCarreteroRpt> {
        return this.http.delete<EliminarComposicionAccionistaEncabezadoCarreteroRpt>(this.END_POINT + '/CntAccionistaContratista/eliminarComposicionAccionaria/' + id + '/' + idContratista)
            .pipe(
                retry(1));
    }

    public listarAccionistasPorIdEncabezado(idEncabezado, idContrato): Observable<ListaAccionistasPorIdEncabezadoCarreteroRpt> {
        return this.http.get<ListaAccionistasPorIdEncabezadoCarreteroRpt>(this.END_POINT + '/CntAccionistaContratista/composicionAccionaria/' + idEncabezado + '/' + idContrato)
            .pipe(
                retry(1));
    }

    public consultaAccionistaContratistaPorId(id): Observable<ConsultaAccionistasPorIdCarreteroRpt> {
        return this.http.get<ConsultaAccionistasPorIdCarreteroRpt>(this.END_POINT + '/CntAccionistaContratista/obtenerAccionistaContratista/' + id)
            .pipe(
                retry(1));
    }

    public guardarAccionistaContratista(accionistaCarretero: AccionistaCarretero): Observable<ConsultaAccionistasPorIdCarreteroRpt> {
        return this.http.post<ConsultaAccionistasPorIdCarreteroRpt>(this.END_POINT + '/CntAccionistaContratista/guardarAccionistaContratista/', accionistaCarretero)
            .pipe(
                retry(1));
    }

    public eliminarAccionistaContratista(id): Observable<EliminarAccionistaContratistaCarreteroRpt> {
        return this.http.delete<EliminarAccionistaContratistaCarreteroRpt>(this.END_POINT + '/CntAccionistaContratista/eliminarAccionistaContratista/' + id)
            .pipe(
                retry(1));
    }

    public listarNovedadesContractualesPorContrato(id): Observable<ConsultaNovedadesContractualesRpt> {
        return this.http.get<ConsultaNovedadesContractualesRpt>(this.END_POINT + '/CrrInformacionContractual/novedadesContractuales/' + id)
            .pipe(
                retry(1));
    }

    public listarNovedadesContractualesSinContrato(id): Observable<ConsultaNovedadesContractualesRpt> {
        return this.http.get<ConsultaNovedadesContractualesRpt>(this.END_POINT + '/CrrInformacionContractual/novedadContratoSinContrato/' + id)
            .pipe(
                retry(1));
    }

    public listarNovedadesContractualesPorProyecto(id): Observable<ConsultaNovedadesContractualesRpt> {
        return this.http.get<ConsultaNovedadesContractualesRpt>(this.END_POINT + '/CrrInformacionContractual/novedadesContractualesPorProyecto/' + id)
            .pipe(
                retry(1));
    }

    public consultarNovedadContractualPorId(id): Observable<ConsultaNovedadContratoRpt> {
        return this.http.get<ConsultaNovedadContratoRpt>(this.END_POINT + '/CrrInformacionContractual/novedadContrato/' + id)
            .pipe(
                retry(1));
    }

    public listarHistoricoContratoPorIdContrato(id): Observable<ConsultaHistoricosContratoPorContratoRpt> {
        return this.http.get<ConsultaHistoricosContratoPorContratoRpt>(this.END_POINT + '/CrrInformacionContractual/listarHistoricoContrato/' + id)
            .pipe(
                retry(1));
    }

    public consultarHistoricoContratoPorId(id): Observable<ConsultaHistoricoContratoRpt> {
        return this.http.get<ConsultaHistoricoContratoRpt>(this.END_POINT + '/CrrInformacionContractual/obtenerHistoricoContrato/' + id)
            .pipe(
                retry(1));
    }

    public GuardarHistoricoContrato(historicoContrato: HistoricoContrato): Observable<ConsultaHistoricoContratoRpt> {
        return this.http.post<ConsultaHistoricoContratoRpt>(this.END_POINT + '/CrrInformacionContractual/guardarHistoricoContrato', historicoContrato)
            .pipe(
                retry(1));
    }

    public EliminarHistoricoContrato(id): Observable<EliminarHistoricoContratoRpt> {
        return this.http.delete<EliminarHistoricoContratoRpt>(this.END_POINT + '/CrrInformacionContractual/eliminarHistoricoContrato/' + id)
            .pipe(
                retry(1));
    }


    public ConsultarHistoricoContratoPorId(id): Observable<ConsultaHistoricoContratoRpt> {
        return this.http.get<ConsultaHistoricoContratoRpt>(this.END_POINT + '/CrrInformacionContractual/obtenerHistoricoContrato/' + id)
            .pipe(
                retry(1));
    }

    public GuardarNovedadContrato(novedadContrato: NovedadContrato): Observable<ConsultaNovedadContratoRpt> {
        return this.http.post<ConsultaNovedadContratoRpt>(this.END_POINT + '/CrrInformacionContractual/guardarNovedadContrato', novedadContrato)
            .pipe(
                retry(1));
    }

    public eliminarNovedadContractual(id): Observable<EliminarNovedadContratoCarreteroRpt> {
        return this.http.delete<EliminarNovedadContratoCarreteroRpt>(this.END_POINT + '/CrrInformacionContractual/eliminarNovedadContractual/' + id)
            .pipe(
                retry(1));
    }

    public TieneContratoConstruccion(idProyecto): Observable<TieneContratoConstruccionRpt> {
        return this.http.get<TieneContratoConstruccionRpt>(this.END_POINT + '/CrrInformacionContractual/tieneContratoConstruccion/' + idProyecto)
            .pipe(
                retry(1));
    }



    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }

}

export class ConsultaContratosPorProyectoCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public contratos?: ContratoCarretero[]
    ) { }
}

export class ConsultaContratoCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public contratoCarretero?: ContratoCarretero
    ) { }
}

export class ConsultaContratistaCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public contratista?: ContratistaCarretero
    ) { }
}

export class ConsultaContratoContratistaCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public contratoContratista?: ContratoContratista
    ) { }
}

export class ListaComposicionAccionistaEncabezadoCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public accionistasEncabezado?: ComposicionAccionistasCarretero[]
    ) { }
}

export class ConsultaComposicionAccionistaEncabezadoCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public accionistasEncabezado?: ComposicionAccionistasCarretero
    ) { }
}

export class EliminarComposicionAccionistaEncabezadoCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class ListaAccionistasPorIdEncabezadoCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public accionistas?: AccionistaCarretero[]
    ) { }
}

export class ConsultaAccionistasPorIdCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public accionista?: AccionistaCarretero
    ) { }
}

export class EliminarAccionistaContratistaCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class ConsultaNovedadesContractualesRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public novedadesContractuales?: NovedadContrato[]
    ) { }
}

export class ConsultaNovedadContratoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public novedadContrato?: NovedadContrato
    ) { }
}

export class EliminarNovedadContratoCarreteroRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class ConsultaHistoricosContratoPorContratoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public historicosContrato?: HistoricoContrato[]
    ) { }
}

export class ConsultaHistoricoContratoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public historicoContrato?: HistoricoContrato
    ) { }
}

export class EliminarHistoricoContratoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class TieneContratoConstruccionRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public tieneContrato?: boolean
    ) { }
}