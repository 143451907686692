import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-aeropuertos-consulta',
  templateUrl: './public-aeropuertos-consulta.component.html',
  styleUrls: ['./public-aeropuertos-consulta.component.sass']
})
export class PublicAeropuertosConsultaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
