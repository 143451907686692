import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ProyectoCarretero } from 'src/app/informacion-proyecto-carretero/proyectoCarretero';
import { InformacionGeneralCarreteroService } from '../../informacion-general-carretero.service';
import { forkJoin } from 'rxjs';
import { UnidadFuncionalCarreteroService } from '../unidad-funcional-carretero/unidad-funcional-carretero.service';
import { NovedadContrato } from '../../informacion-contractual/informacion-contractual-carretero.dto';
import { InformacionContractualCarreteroService } from '../../informacion-contractual/informacion-contractual-carretero.service';
import { AlcanceProyectoBaseService } from './alcance-proyecto-base.service';
import { LogintudConcesionadaProyectoService } from '../longitud-concesionada-proyecto/longitud-concesionada-proyecto.service';
import { LongitudConcesionadaProyecto } from '../longitud-concesionada-proyecto/longitudConcesionadaProyecto';
import { EtapasProyecto } from '../etapa-proyecto-carretero/etapaProyecto';
import { EtapaProyectoCarreteroService } from '../etapa-proyecto-carretero/etapa-proyecto-carretero.service';
import { IntervencionDepartamentoUf, IntervencionUnidadFuncional } from '../tipo-intervencion-carretero/tipo-intervencion-carretero.dto';
import { UnidadFuncional } from '../unidad-funcional-carretero/unidad-funcional-carretero.dto';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-alcance-proyecto-base',
  templateUrl: './alcance-proyecto-base.component.html',
  styleUrls: ['./alcance-proyecto-base.component.sass']
})
export class AlcanceProyectoBaseComponent implements OnInit {

  // VARIABLES PRINCIPALES LINEA BASE
  esBorrador = true;
  nombreProyecto: any;
  idProyecto: any;
  idConfiguracion: any;
  generacionSeleccionada: any;
  blockedDocument: boolean;
  proyectoCarretero: ProyectoCarretero = {};
  novedadesContractualesLista: NovedadContrato[] = [];
  novedadContractualSeleccionada: NovedadContrato = null;
  fechaAprobacionNuevaConfiguracion: Date;
  visibleCrearNuevaConfiguracion: boolean;
  indiceTab: number;
  novedadNuevaConfiguracion: NovedadContrato;
  visibleConfirmarCancelarBorrador: boolean;


  // VARIABLES UNIDADES FUNCIONALES
  visibleUnidadFuncional: boolean;
  visibleUnidadFuncionalDettalle: boolean;
  unidadFuncional: UnidadFuncional;
  tieneCapexOpexPorUnidadFuncional = false;
  unidadesFuncionales: any = [];
  totalFactorUnidadesFuncionales = 0.0;

  // VARIABLES TIPOS DE INTERVENCION
  visibleTiposIntervencion: boolean;
  intervencion: IntervencionUnidadFuncional = {};
  tipoIntervencionSeleccionado: any;
  visibleTiposIntervencionDetalle: boolean;
  intervencionDepartamentos: any = [];
  intervencionDepartamentoUfSeleccionada: IntervencionDepartamentoUf;
  factorPonderacionIntervencion: number;

  // VARIABLES LONGITUD CONCESIONADA
  longitudConcesionadaProyecto: LongitudConcesionadaProyecto;
  longitudC: number;
  longitudACargo: number;
  idLongitudConcesionadaProyecto: number;
  esEditable: boolean;
  capex: number;
  fechaReferenciaCapex?: Date;
  opex: number;
  fechaReferenciaOpex: Date;
  aplicaCapexOpex: boolean;
  tieneCapexOpexPorProyecto: boolean;

  // VARIABLES ETAPAS PROYECTO
  etapasGet: any = [];
  etapasProyecto: EtapasProyecto;
  fechaInicioPreconstruccion: Date;
  fechaFinPreconstruccion: Date;
  fechaInicioConstruccion: Date;
  fechaFinConstruccion: Date;
  fechaOperacion: Date;
  fechaReversion: Date;



  constructor(private messageService: MessageService, private route: ActivatedRoute, private informacionGeneralCarreteroService: InformacionGeneralCarreteroService, private unidadFuncionalService: UnidadFuncionalCarreteroService,
    private informacionContractualCarreteroService: InformacionContractualCarreteroService, private alcanceProyectoBaseService: AlcanceProyectoBaseService,
    private etapasProyectoService: EtapaProyectoCarreteroService, private longitudConcesionadaService: LogintudConcesionadaProyectoService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idProyecto = params.id;
      this.cargarProyecto(this.idProyecto);
      this.cargarNovedadesContractuales();
    });
    this.limpiarTabUnidadFuncional();
    this.generacionSeleccionada = 4;
    this.indiceTab = 0;
    this.novedadNuevaConfiguracion = {};
    this.novedadNuevaConfiguracion.idTipoNovedadNavigation = {};
  }

  limpiarTabUnidadFuncional() {
    this.visibleUnidadFuncional = true;
    this.visibleUnidadFuncionalDettalle = false;
    this.visibleTiposIntervencion = false;
    this.visibleTiposIntervencionDetalle = false;
  }

  cargarNovedadesContractuales() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.listarNovedadesContractualesPorProyecto(this.idProyecto)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.novedadesContractualesLista = result.novedadesContractuales;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  // tslint:disable-next-line: ban-types
  cargarLongitudConcesionada(idProyecto: number, esBorrador: Boolean, idConfiguracion: number) {
    forkJoin(
      this.longitudConcesionadaService.listarLongitudConcesionadaProyectoPorId(idProyecto, esBorrador, idConfiguracion)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.longitudConcesionadaProyecto = new LongitudConcesionadaProyecto();
          this.longitudConcesionadaProyecto[0] = result.longitudConcesionada;
          this.longitudC = this.longitudConcesionadaProyecto[0].longitudConcesionada;
          this.longitudACargo = this.longitudConcesionadaProyecto[0].longitudAcargo;
          this.idLongitudConcesionadaProyecto = this.longitudConcesionadaProyecto[0].id;
          this.capex = this.longitudConcesionadaProyecto[0].capex;
          if (this.longitudConcesionadaProyecto[0].fechaReferenciaCapex != null) {
            this.fechaReferenciaCapex = new Date(this.longitudConcesionadaProyecto[0].fechaReferenciaCapex);
          } else {
            this.fechaReferenciaCapex = null;
          }
          this.opex = this.longitudConcesionadaProyecto[0].opex;
          if (this.longitudConcesionadaProyecto[0].fechaReferenciaOpex != null) {
            this.fechaReferenciaOpex = new Date(this.longitudConcesionadaProyecto[0].fechaReferenciaOpex);
          } else {
            this.fechaReferenciaOpex = null;
          }
          this.aplicaCapexOpex = this.longitudConcesionadaProyecto[0].aplicaCapexOpex;
          this.tieneCapexOpexPorProyecto = !this.longitudConcesionadaProyecto[0].tieneCapexOpexPorProyecto;
          // tslint:disable-next-line: triple-equals
          if (this.idLongitudConcesionadaProyecto == 0) {
            this.esEditable = true;
          } else {
            this.esEditable = this.longitudConcesionadaProyecto[0].editable;
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        // Desbloquear pantalla

      }
    );

  }

  cargarProyecto(id: number) {
    this.blockedDocument = false;
    this.informacionGeneralCarreteroService.getProyectoPorId(id).subscribe((data: {}) => {
      this.proyectoCarretero = data;
      this.nombreProyecto = this.proyectoCarretero.nombre;
      forkJoin(
        this.informacionGeneralCarreteroService.getObtenerConfiguracionActualProyecto(id)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.idConfiguracion = result.configuracionProyecto.idConfiguracion;
            this.esBorrador = result.configuracionProyecto.esBorrador;
            if (this.esBorrador) {
              this.cargarNovedadContrato(result.configuracionProyecto.idNovedadContrato);
            }
            // Invocar metodo de cargar longitud concesionada
            this.cargarLongitudConcesionada(id, this.esBorrador, this.idConfiguracion);
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      this.blockedDocument = false;
    });
  }

  irDialogoNuevaConfiguracion() {
    this.visibleCrearNuevaConfiguracion = true;
  }

  cancelarDialogoNuevaConfiguracion() {
    this.visibleCrearNuevaConfiguracion = false;
  }

  cargarNovedadContrato(idNovedad) {
    this.blockedDocument = true;
    if (idNovedad != null && idNovedad != -1) {
      forkJoin(
        this.informacionContractualCarreteroService.consultarNovedadContractualPorId(idNovedad)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.novedadNuevaConfiguracion = result.novedadContrato;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
        },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    } else {
      this.novedadNuevaConfiguracion = {};
      this.novedadNuevaConfiguracion.idTipoNovedadNavigation = {};
      console.log(this.novedadNuevaConfiguracion.idTipoNovedadNavigation);
    }

  }

  nuevaConfiguracion() {
    this.blockedDocument = true;
    forkJoin(
      this.alcanceProyectoBaseService.crearNuevaConfiguracionProyecto(this.idProyecto, this.novedadContractualSeleccionada.id, this.fechaAprobacionNuevaConfiguracion)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.cargarNovedadContrato(this.novedadContractualSeleccionada.id);
          this.idConfiguracion = result.idConfiguracionBorrador;
          this.esBorrador = true;
          this.visibleCrearNuevaConfiguracion = false;
          this.novedadContractualSeleccionada = null;
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'La nueva configuración se creó exitosamente.', life: 10000 });
          this.indiceTab = 0;
          this.limpiarTabUnidadFuncional();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  confirmarCancelarBorrador() {
    this.visibleConfirmarCancelarBorrador = true;
  }

  cancelarConfirmarCancelarBorrador() {
    this.visibleConfirmarCancelarBorrador = false;
  }

  eliminarConfiguracionProyectoBorrador() {
    this.blockedDocument = true;
    forkJoin(
      this.alcanceProyectoBaseService.eliminaConfiguracionProyectoBorrador(this.idProyecto)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.cargarProyecto(this.idProyecto);
          this.esBorrador = false;
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El borrador se canceló exitosamente.', life: 10000 });
          this.indiceTab = 0;
          this.limpiarTabUnidadFuncional();
          this.visibleConfirmarCancelarBorrador = false;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  onTabChange(event) {
    const tab: number = event.index;
    if (tab === 1) {
      this.cargarEtapasProyecto(this.idProyecto, this.idConfiguracion, this.esBorrador);
    }
    if (tab === 2) {
      this.cargarUnidadesFuncionales(this.idProyecto, this.idConfiguracion, this.esBorrador);
    }
  }

  cargarEtapasProyecto(id: number, idConfiguracion: number, esBorrador: boolean) {

    forkJoin(
      this.etapasProyectoService.obtenerEtapas(id, esBorrador, idConfiguracion)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.etapasGet = result.etapasProyecto;
          this.etapasProyecto = this.etapasGet;
          if (this.etapasProyecto.fechaInicioPreconstruccion != null) {
            this.fechaInicioPreconstruccion = new Date(this.etapasProyecto.fechaInicioPreconstruccion);
          }
          if (this.etapasProyecto.fechaFinPreconstruccion != null) {
            this.fechaFinPreconstruccion = new Date(this.etapasProyecto.fechaFinPreconstruccion);
          }
          if (this.etapasProyecto.fechaInicioConstruccion != null) {
            this.fechaInicioConstruccion = new Date(this.etapasProyecto.fechaInicioConstruccion);
          }
          if (this.etapasProyecto.fechaFinConstruccion != null) {
            this.fechaFinConstruccion = new Date(this.etapasProyecto.fechaFinConstruccion);
          }
          if (this.etapasProyecto.fechaInicioOperacion != null) {
            this.fechaOperacion = new Date(this.etapasProyecto.fechaInicioOperacion);
          }
          if (this.etapasProyecto.fechaInicioReversion != null) {
            this.fechaReversion = new Date(this.etapasProyecto.fechaInicioReversion);
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );

  }

  cargarUnidadesFuncionales(id: number, idConfiguracion: number, esBorrador: boolean) {
    this.totalFactorUnidadesFuncionales = 0.0;
    this.blockedDocument = true;
    forkJoin(
      this.unidadFuncionalService.getUnidadesFuncionalesPorProyecto(id, idConfiguracion, esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          console.log(' result.unidadesFuncionales --->',  result.unidadesFuncionales);
          
          this.unidadesFuncionales = result.unidadesFuncionales;
          this.unidadesFuncionales.forEach(element => {
            this.totalFactorUnidadesFuncionales = this.totalFactorUnidadesFuncionales + element.factorPonderacion;
          });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

}
