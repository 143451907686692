import { Component, OnInit, ViewChild } from '@angular/core';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { forkJoin, Subscription } from 'rxjs';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { CompensacionesAmbientalesService } from '../../compensaciones-ambientales.service';
import { MessageService } from 'primeng/api';
import {
  AmbEjecucionDTO,
  AmbSeguimientoObligacionDTO,
  AmbSubCuentaDTO,
  AmbMaEstadoObligacionDTO,
  AmbEjecucionDetalleDTO,
  AmbMaTipoCompensacionDTO,
} from '../../compensaciones-ambientales.dto';
import { DomSanitizer } from '@angular/platform-browser';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-ejecuciones-obligacion',
  templateUrl: './ejecuciones-obligacion.component.html',
  styleUrls: ['./ejecuciones-obligacion.component.sass'],
})
export class EjecucionesObligacionComponent implements OnInit {
  subscription: Subscription;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  valorCobrado: boolean = false;

  subCuenta: AmbSubCuentaDTO;

  idObligacion: number;

  ejecucionList: AmbEjecucionDTO[];
  ejecucion: AmbEjecucionDTO;
  ejecucionDetalle: AmbEjecucionDetalleDTO;

  estadoObligacionList: AmbMaEstadoObligacionDTO[];

  tipoCompensacionList: AmbMaTipoCompensacionDTO[];

  ejecucionDetalleDTOClonedList: { [s: string]: AmbEjecucionDetalleDTO } = {};

  @ViewChild('inputPorcentaje', { static: false }) inputPorcentaje: NgForm;

  dialogoEjecucion: boolean;
  dialogoDetalleReporte: boolean;

  formUbicacionAfectada;

  formEjecucion;

  fechaActual: Date;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private compensacionesAmbientalesService: CompensacionesAmbientalesService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private principalComponent: PrincipalComponent
  ) {
    const regexMayorACero = '^[1-9][0-9]*$';
    //  Crear formulario Ubicacion Afectada
    this.formUbicacionAfectada = formBuilder.group({
      idMunicipio: [null, [Validators.required]],
    });
    //  Crear formulario Ejecucion
    this.formEjecucion = formBuilder.group({
      idEstadoObligacion: [null, [Validators.required]],
      fechaCorte: [null, [Validators.required]],
      valorEjecutado: [
        null,
        [
          Validators.required,
          Validators.maxLength(8),
          Validators.pattern(regexMayorACero),
        ],
      ],
      valorCobrado: [null, null],
    });
  }

  ngOnInit() {
    this.fechaActual = new Date();

    this.ejecucion = {};
    this.ejecucionDetalle = {};

    // Obtener el proyecto seleccionado
    const idParam = 'id';
    const idParamOblig = 'idOblig';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.idObligacion = params[idParamOblig];
    });

    this.inicializarListas();
  }

  inicializarListas() {
    // Inicializar listas
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.compensacionesAmbientalesService.srvObtenerValorSubcuentaPorProyecto(
        this.idProyecto
      ),
      this.compensacionesAmbientalesService.srvListarEjecucionPorObligacion(
        this.idObligacion
      ),
      this.compensacionesAmbientalesService.srvAmbMaEstadoObligacion(),
      this.compensacionesAmbientalesService.srvAmbMaTipoCompensacion()
    ).subscribe(
      ([
        proyectoData,
        subCuentaData,
        ejecucionPorObligacionData,
        estadosObligacionData,
        tipoCompensacionData,
      ]) => {
        this.proyectoSeleccionado = proyectoData;
        this.subCuenta = subCuentaData.valorSubCuenta;
        this.ejecucionList = ejecucionPorObligacionData.ejecuciones;
        this.estadoObligacionList = estadosObligacionData.estadosObligacion;
        this.tipoCompensacionList = tipoCompensacionData.tipoCompensaciones;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  btnAgregarEjecucion() {
    this.obtenerEjecucionConDetalles(this.idObligacion, 0);
    this.dialogoEjecucion = true;
  }

  btnEditarEjecucion(ejecucionSelected: AmbEjecucionDTO) {
    this.obtenerEjecucionConDetalles(this.idObligacion, ejecucionSelected.id);

    this.dialogoEjecucion = true;
  }

  btnDetalleReporte(ejecucionSelected: AmbEjecucionDTO) {
    this.obtenerEjecucionConDetalles(this.idObligacion, ejecucionSelected.id);
    this.dialogoDetalleReporte = true;
  }

  btnGestionarLocalizacion(ejecucionSelected: AmbEjecucionDTO) {
    this.router.navigate([
      this.router.url +
      '/gestionar-localizacion-avance/' +
      this.idProyecto +
      '/' +
      this.idObligacion +
      '/' +
      ejecucionSelected.id,
    ]);
  }

  btnConsultarLocalizacion(ejecucionSelected: AmbEjecucionDTO) {
    this.router.navigate([
      this.router.url +
      '/consultar-localizacion-avance/' +
      this.idProyecto +
      '/' +
      this.idObligacion +
      '/' +
      ejecucionSelected.id,
    ]);
  }

  obtenerEjecucionConDetalles(idObligacion: number, idEjecucion: number) {
    forkJoin(
      this.compensacionesAmbientalesService.srvObtenerEjecucionDetallePorId(
        idObligacion,
        idEjecucion
      )
    ).subscribe(
      ([ejecucionConDetallesData]) => {
        console.log(ejecucionConDetallesData);

        this.ejecucion = ejecucionConDetallesData.obligacionEjecucion;

        //  Cargar el objeto del dropdown Estado Ejecucion
        this.ejecucion.idEstadoObligacionDTO = this.estadoObligacionList.find(
          (t) => t.id === this.ejecucion.idEstadoObligacion
        );

        // Cargar la fecha de Corte
        if (this.ejecucion.fechaCorte != null) {
          this.ejecucion.fechaCorte = new Date(this.ejecucion.fechaCorte);
        }
        this.valorCobrado = this.ejecucion.cobrado;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**
   * Se llama cada vez que se elige un registro del selector 'Estado Obligacion'
   */
  onChangeEstadoObligacion(event) { }

  /**
   * Se llama cada vez que se elige un registro del selector 'Tipo de Compensacion'
   */
  onChangeTipoCompensacion(event) { }

  btnEliminar(obligacionDetalleSelected: AmbEjecucionDetalleDTO) {
    this.ejecucion.detalleEjecucion = this.ejecucion.detalleEjecucion.filter(
      (obj) => obj !== obligacionDetalleSelected
    );
  }

  onRowEditInit(obligacionDetalleSelected: AmbEjecucionDetalleDTO) {
    console.log(obligacionDetalleSelected);
    this.ejecucionDetalleDTOClonedList[obligacionDetalleSelected.id] = {
      ...obligacionDetalleSelected,
    };
  }

  onRowEditSave(
    obligacionDetalleSelected: AmbEjecucionDetalleDTO,
    index: number
  ) {
    if (
      obligacionDetalleSelected.cantidadCompensada >
      obligacionDetalleSelected.cantidadACompensar
    ) {
      this.lanzarMensajeWarning(
        'La cantidad compensada no debe ser mayor que la cantidad a compensar'
      );
      // Volver a dejar el valor original
      this.ejecucion.detalleEjecucion[
        index
      ] = this.ejecucionDetalleDTOClonedList[obligacionDetalleSelected.id];
    } else if (
      obligacionDetalleSelected.cantidadCompensada <
      obligacionDetalleSelected.cantidadCompensaReporteAnterior
    ) {
      this.lanzarMensajeWarning(
        'La cantidad compensada debe ser mayor o igual que la cantidad compensada del reporte anterior'
      );
      // Volver a dejar el valor original
      this.ejecucion.detalleEjecucion[
        index
      ] = this.ejecucionDetalleDTOClonedList[obligacionDetalleSelected.id];
    } else {
      delete this.ejecucionDetalleDTOClonedList[obligacionDetalleSelected.id];
    }
  }

  onRowEditCancel(
    obligacionDetalleSelected: AmbEjecucionDetalleDTO,
    index: number
  ) {
    // Volver a dejar el valor original
    this.ejecucion.detalleEjecucion[index] = this.ejecucionDetalleDTOClonedList[
      obligacionDetalleSelected.id
    ];
    delete this.ejecucionDetalleDTOClonedList[obligacionDetalleSelected.id];
  }

  /**
   * Guarda los cambios al crear o editar un bien
   */
  submitGuardarEjecucion() {
    if (this.formEjecucion.valid) {
      //  Completar el DTO a guardar
      if (this.ejecucion != null) {
        this.ejecucion.idEstadoObligacion = this.ejecucion.idEstadoObligacionDTO.id;
        this.ejecucion.cobrado = this.valorCobrado;
      }
      //  Guardar el DTO
      forkJoin(
        this.compensacionesAmbientalesService.srvGuardarReporteEjecucionPorObligacionAmbiental(
          this.ejecucion
        ),
        this.compensacionesAmbientalesService.sincronizarZonaCompensada()
      ).subscribe(
        ([result, resultSincronizacion]) => {
          if (result != null && result.respuestaServicio != null) {
            //  Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              //  Consultar nuevamente la lista
              this.inicializarListas();
              //  Lanzar mensaje de exito
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
              //  Limpiar Formulario
              this.formEjecucion.reset();
              this.formEjecucion.markAsPristine();
              this.formEjecucion.markAsUntouched();

              //  Cerrar el dialogo
              this.dialogoEjecucion = false;
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          }
          if (resultSincronizacion != null) {
            console.log('resultSincronizacionCompensada', resultSincronizacion);
          }
        }, error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          // Desbloquear pantalla
          // this.blockedDocument = false;
        }
      );
    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos');
    }
  }

  bntVolver() {
    const ruta =
      'carreteros/infGeneral/proyectoHome/' +
      this.proyectoSeleccionado.id +
      '/compensaciones-ambientales/' +
      this.proyectoSeleccionado.id +
      '/seguimiento-compensaciones/' +
      this.proyectoSeleccionado.id;
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  getObtenerURLMapa(idObligacion, tieneLocalizaciones) {
    let url = '#';
    if (tieneLocalizaciones) {
      url =
        'https://www.arcgis.com/apps/webappviewer/index.html?id=7acedd3ddb5b481b8909ad7a6f47bc67&query=ACServicios_5783%2CIdObligacion%2C' +
        idObligacion;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
