import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ParametricasService } from "src/app/parametricas/parametricas.service";
import { AlcanceProyectoBaseComponent } from "../alcance-proyecto-base/alcance-proyecto-base.component";
import { UnidadFuncionalCarreteroService } from "./unidad-funcional-carretero.service";
import { forkJoin } from "rxjs";
import { MessageService } from "primeng/api";
import { Subtramo } from "src/app/unidad-funcional-carretero/subtramo";
import { UnidadFuncional } from "./unidad-funcional-carretero.dto";
import { NgForm } from "@angular/forms";
import { PrincipalComponent } from "src/app/home/principal/principal.component";

@Component({
  selector: "app-unidad-funcional-carretero2",
  templateUrl: "./unidad-funcional-carretero.component.html",
  styleUrls: ["./unidad-funcional-carretero.component.sass"],
})
export class UnidadFuncionalCarreteroComponent2 implements OnInit {
  displayedColumns: any[];
  blockedDocument: boolean = false;
  visibleListaSubtramo: boolean;
  visibleNuevoSubtramoPorUnidadFuncional: boolean;
  subtramos: Subtramo[];
  subtramo: Subtramo = {};
  visibleConfirmarEliminarSubtramo: boolean = false;
  visibleDialogoExitoSubtramo: boolean;
  visibleDialogoExitoEliminarSubtramo: boolean = false;

  constructor(
    public lineaBase: AlcanceProyectoBaseComponent,
    private parametricasService: ParametricasService,
    private unidadFuncionalService: UnidadFuncionalCarreteroService,
    private route: ActivatedRoute,
    private ruta: Router,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private router: Router
  ) {}

  ngOnInit() {
    this.displayedColumns = [
      { field: "codigo", header: "Código" },
      { field: "nombre", header: "Nombre" },
      { field: "formatoFactorPonderacion", header: "Factor de ponderación" },
    ];
  }

  onNew() {
    this.lineaBase.visibleUnidadFuncional = false;
    this.lineaBase.visibleUnidadFuncionalDettalle = true;
    this.lineaBase.unidadFuncional = {};
    this.lineaBase.unidadFuncional.idConfiguracion = 3;
  }

  editarUnidadFuncional(unidadFuncional: UnidadFuncional) {
    this.blockedDocument = true;
    forkJoin(
      this.unidadFuncionalService.getUnidadFuncionalPorId(
        unidadFuncional.id,
        this.lineaBase.esBorrador
      )
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.lineaBase.unidadFuncional = result.unidadFuncional;
            this.lineaBase.visibleUnidadFuncional = false;
            this.lineaBase.visibleUnidadFuncionalDettalle = true;
            this.lineaBase.unidadFuncional.longitudConcesionada =
              this.lineaBase.unidadFuncional.longitudConcesionada / 1000;
            this.lineaBase.unidadFuncional.longitudReal =
              this.lineaBase.unidadFuncional.longitudReal / 1000;
            this.lineaBase.unidadFuncional.factorPonderacion =
              this.lineaBase.unidadFuncional.factorPonderacion * 100;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        } else {
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 10000,
        });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irAgregarTipoIntervencion(unidadFuncional: UnidadFuncional) {
    this.lineaBase.unidadFuncional = unidadFuncional;
    this.lineaBase.visibleTiposIntervencion = true;
    this.lineaBase.visibleUnidadFuncional = false;
  }

  irAgregarSubtramo(unidadFuncional: UnidadFuncional) {
    this.lineaBase.unidadFuncional = unidadFuncional;
    this.cargarSubtramosPorUnidadFuncional(this.lineaBase.unidadFuncional.id);
  }

  cargarSubtramosPorUnidadFuncional(idUnidadFuncional) {
    this.blockedDocument = true;
    forkJoin(
      this.unidadFuncionalService.getListarSubtramosPorUnidadFuncional(
        idUnidadFuncional,
        this.lineaBase.esBorrador
      )
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.subtramos = result.subtramos;
            this.visibleListaSubtramo = true;
            this.visibleNuevoSubtramoPorUnidadFuncional = false;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        } else {
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 10000,
        });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  nuevoSubtramoUnidadFuncional() {
    //this.subtramo.idUnidadFuncional = this.idUnidadFuncionalSeleccionada;
    this.visibleNuevoSubtramoPorUnidadFuncional = true;
    this.subtramo.id = 0;
    this.subtramo.idTema = 0;
    this.subtramo.idSubtramo = 0;
    this.subtramo.idUnidadFuncional = this.lineaBase.unidadFuncional.id;
    //this.lineaBase.idSubtramo = "new";
  }

  agregarSubtramo(forma: NgForm) {
    this.blockedDocument = true;
    forkJoin(
      this.unidadFuncionalService.guardarSubtramo(
        this.subtramo,
        this.lineaBase.esBorrador
      )
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.visibleDialogoExitoSubtramo = true;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        } else {
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 10000,
        });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  aceptarDialogoExitoSubtramo() {
    this.visibleDialogoExitoSubtramo = false;
    this.cargarSubtramosPorUnidadFuncional(this.lineaBase.unidadFuncional.id);
    this.cancelarNuevoSubtramo();
  }

  cancelarNuevoSubtramo() {
    this.visibleNuevoSubtramoPorUnidadFuncional = false;
    this.subtramo = {};
  }

  cancelarAgregarSubtramo() {
    this.visibleListaSubtramo = false;
    this.subtramo = {};
  }

  editarSubtramoUnidadFuncional(idSubtramo) {
    this.blockedDocument = true;
    forkJoin(
      this.unidadFuncionalService.getObtenerSubtramoPorId(
        idSubtramo,
        this.lineaBase.esBorrador
      )
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.subtramo = result.subtramo;
            this.subtramo.longitud = this.subtramo.longitud / 1000;
            this.visibleNuevoSubtramoPorUnidadFuncional = true;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        } else {
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 10000,
        });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irEliminarSubtramo(id) {
    this.visibleConfirmarEliminarSubtramo = true;
    //this.lineaBase.idSubtramo = id;
  }

  cancelarEliminarSubtramo() {
    this.visibleConfirmarEliminarSubtramo = false;
  }

  aceptarEliminarSubtramo() {
    /* return this.unidadFuncionalService.eliminarSubtramoPorUnidadFuncional(this.lineaBase.idSubtramo).subscribe((data: {}) => {
      this.visibleConfirmarEliminarSubtramo = false;
      this.cargarSubtramosPorUnidadFuncional(this.lineaBase.unidadFuncional.id);
      this.visibleDialogoExitoEliminarSubtramo = true;
    }); */
  }

  aceptarDialogoEliminarSubtramo() {
    this.visibleDialogoExitoEliminarSubtramo = false;
  }

  btnGestionarLocalizacion(unidadFuncional: UnidadFuncional) {
    console.log("unidadFuncional------->", unidadFuncional);

    this.router.navigate([
      this.router.url +
        "/gestionar-localizacion-uf/" +
        unidadFuncional.id +
        "/" +
        unidadFuncional.idUnidadFuncional,
    ]);
  }

  btnConsultarLocalizacion(unidadFuncional: UnidadFuncional) {
    console.log("unidadFuncional------->", unidadFuncional);

    forkJoin(
      this.unidadFuncionalService.validarExistenciaGeometriaUnidadFuncional(
        unidadFuncional.idUnidadFuncional
      )
    ).subscribe(([resultValidarGeometria]) => {
      console.log(
        "resultValidarGeometria POR UNIDAD FUNCIONAL----->",
        resultValidarGeometria
      );
      if (resultValidarGeometria != null) {
        if (resultValidarGeometria === 0) {
          this.messageService.add({
            severity: "warn",
            summary: "Advertencia",
            detail:
              "Se debe cargar primero la información geografica en 'Gestionar Localización'",
            life: 10000,
          });
        } else {
          this.router.navigate([
            this.router.url +
              "/consultar-localizacion-uf/" +
              unidadFuncional.id +
              "/" +
              unidadFuncional.idUnidadFuncional,
          ]);
        }
      } else {
        this.messageService.add({
          severity: "warn",
          summary: "Advertencia",
          detail:
            "Se debe cargar primero la información geografica en 'Gestionar Localización'",
          life: 10000,
        });
      }
    });
  }
}
