import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { C19ReporteCovidConsolidado, C19ReporteCovid, C19Persona, C19ReporteCovidTrabajador, GrupoMedidaCovid, MedidaCovid, EstadoPersona, EstadoCovid, LugarTrabajo, Sintoma, EntidadArticulacion } from './covid-reportar.dto';
import { TrnRespuestaServicio } from '../parametricas/trn-respuesta-servicio';

@Injectable({
  providedIn: 'root'
})
export class CovidReportarService {

  // private mensajeCompartidoSource = new BehaviorSubject('default message');
  // mensajeCompartido = this.mensajeCompartidoSource.asObservable();

  public END_POINT = '';

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }
  ///////////////////////SERVICIOS//////////////////////

  public srvConsultarConsolidadoPorReporte(idReporte: number): Observable<ConsultarConsolidadoPorReporteRpt> {
    return this.http.get<ConsultarConsolidadoPorReporteRpt>(this.END_POINT + '/C19Consolidado/ConsultarConsolidadoPorReporte/' + idReporte)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  public srvGuardarConsolidadoPorReporte(c19ReporteCovidConsolidado: C19ReporteCovidConsolidado): Observable<GuardarConsolidadoPorReporteRpt> {

    return this.http.post<GuardarConsolidadoPorReporteRpt>(this.END_POINT + '/C19Consolidado/GuardarConsolidadoPorReporte', c19ReporteCovidConsolidado)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvObtenerPersona(idTipoDocumento: string, numeroDocumento: string): Observable<ObtenerPersonaRpt> {
    let url = this.END_POINT + '/C19ReporteEncabezado/getPersona/' + idTipoDocumento + '/' + numeroDocumento;

    return this.http.get<ObtenerPersonaRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarReporteEncabezadoPorProyecto(idProyecto: number): Observable<ConsultarReporteEncabezadoPorProyectoRpt> {
    let url = this.END_POINT + '/C19ReporteEncabezado/getReporteEncabezadoPorProyecto/' + idProyecto;
    return this.http.get<ConsultarReporteEncabezadoPorProyectoRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvGuardarReporteEncabezado(c19ReporteCovid: C19ReporteCovid): Observable<GuardarReporteEncabezadoRpt> {
    let url = this.END_POINT + '/C19ReporteEncabezado/guardarReporteEncabezado';

    return this.http.post<GuardarReporteEncabezadoRpt>(url, c19ReporteCovid)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvPublicarReporteEncabezado(c19ReporteCovid: C19ReporteCovid): Observable<RespuestaServicioRpt> {
    let url = this.END_POINT + '/C19ReporteEncabezado/publicarReporteEncabezado/' + c19ReporteCovid.id + '/' + c19ReporteCovid.publicado;

    return this.http.put<RespuestaServicioRpt>(url, c19ReporteCovid.id)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvReactivarReporteEncabezado(idProyecto: number): Observable<RespuestaServicioRpt> {
    let url = this.END_POINT + '/C19ReporteEncabezado/reactivarReporteEncabezado/' + idProyecto;

    return this.http.put<RespuestaServicioRpt>(url, idProyecto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarTrabajadoresPorReporte(idReporte: number): Observable<ConsultarTrabajadoresPorReporteRpt> {
    let url = this.END_POINT + '/C19Trabajadores/getTrabajadoresPorReporte/' + idReporte;
    return this.http.get<ConsultarTrabajadoresPorReporteRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvGuardarTrabajadorPorReporte(c19Trabajador: C19Persona, idReporte: number): Observable<GuardarTrabajadorPorReporteRpt> {
    let url = this.END_POINT + '/C19Trabajadores/guardarTrabajador/' + idReporte;

    return this.http.post<GuardarTrabajadorPorReporteRpt>(url, c19Trabajador)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvEliminarTrabajadorPorReporte(idTrabajador: number, idReporte: number): Observable<RespuestaServicioRpt> {
    let url = this.END_POINT + '/C19Trabajadores/eliminarTrabajador/' + idTrabajador + '/' + idReporte;
    return this.http.delete<RespuestaServicioRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarSintomasPorReporteYTrabajador(idReporte: number, idTrabajador: number): Observable<ConsultarSintomasPorReporteYTrabajadorRpt> {
    let url = this.END_POINT + '/C19Trabajadores/getSintomasPorReporteTrabajador/' + idReporte + '/' + idTrabajador;
    return this.http.get<ConsultarSintomasPorReporteYTrabajadorRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  public srvGuardarSintomas(c19ReporteCovidTrabajador: C19ReporteCovidTrabajador): Observable<GuardarSintomasRpt> {
    let url = this.END_POINT + '/C19Trabajadores/guardarSintomas';

    return this.http.post<GuardarSintomasRpt>(url, c19ReporteCovidTrabajador)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarMedidas(idGrupo: number, idReporte: number): Observable<ConsultarMedidasRpt> {
    let url = this.END_POINT + '/C19Medidas/ConsultarReporteCovidMedidas/' + idGrupo + '/' + idReporte;
    return this.http.get<ConsultarMedidasRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvGuardarReporteCovidMedida(data) {
    return this.http.post(this.END_POINT + '/C19Medidas/GuardarReporteCovidMedida', data)
      .pipe(
        retry(1));
  }

  public srvGuardarReporteCovidMedidaAdjunto(idReporteMedida, data) {
    return this.http.post(this.END_POINT + '/C19Medidas/GuardarReporteCovidMedidaAdjunto/' + idReporteMedida, data)
      .pipe(
        retry(1));
  }

  public getReporteCovidMedidaAdjuntoPorIdMedida(idReporteMedida) {
    return this.http.get(this.END_POINT + '/C19Medidas/getReporteCovidMedidaAdjuntoPorIdMedida/' + idReporteMedida)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public eliminarReporteCovidMedidaAdjunto(id) {
    return this.http.delete(this.END_POINT + '/C19Medidas/eliminarAdjunto/' + id)
      .pipe(
        retry(1)
      );
  }

  public eliminarReporteCovidMedida(id) {
    return this.http.delete(this.END_POINT + '/C19Medidas/eliminarReporteCovidMedida/' + id)
      .pipe(
        retry(1)
      );
  }

  public srvGuardarReporteCovidMedidas(medidaCovidList: MedidaCovid[], idReporte: number): Observable<GuardarReporteCovidMedidasRpt> {
    let url = this.END_POINT + '/C19Medidas/GuardarReporteCovidMedidas/' + idReporte;

    return this.http.post<GuardarReporteCovidMedidasRpt>(url, medidaCovidList)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvEliminarSintomas(idReporteCovidTrabajador: number): Observable<RespuestaServicioRpt> {
    let url = this.END_POINT + '/C19Trabajadores/eliminarSintomas/' + idReporteCovidTrabajador;

    return this.http.delete<RespuestaServicioRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  //////////////////CONSULTAR PARAMETRICAS/////////////////////////



  public srvConsultarGrupoMedida(): Observable<GrupoMedidaCovid[]> {
    let url = this.END_POINT + '/C19maGrupoMedida/';
    return this.http.get<GrupoMedidaCovid[]>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarEstadoPersona(): Observable<EstadoPersona[]> {
    let url = this.END_POINT + '/C19maEstadoPersona/';
    return this.http.get<EstadoPersona[]>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarSintoma(): Observable<Sintoma[]> {
    let url = this.END_POINT + '/C19maSintoma/';
    return this.http.get<Sintoma[]>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarEstadoCovid(): Observable<EstadoCovid[]> {
    let url = this.END_POINT + '/C19maEstadoCovid/';
    return this.http.get<EstadoCovid[]>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarLugarTrabajo(): Observable<LugarTrabajo[]> {
    let url = this.END_POINT + '/C19maLugarTratado/';
    return this.http.get<LugarTrabajo[]>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ////////////////////////REPORTES//////////////////////////////////////////////////


  public getReporteCovidDepartamento(fechaReporte, idProyecto, idModo) {
    return this.http.get(this.END_POINT + '/C19Reportes/getReporteCovidDepartamento/' + fechaReporte + '/' + idProyecto + '/' + idModo)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public getReporteCovidSintomas(fechaReporte, idProyecto, idModo) {
    return this.http.get(this.END_POINT + '/C19Reportes/getReporteCovidSintomas/' + fechaReporte + '/' + idProyecto + '/' + idModo)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }



}

////////////////////RESPUESTAS////////////////////////////////

export class ObtenerPersonaRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public personaEncargada?: C19Persona
  ) { }
}

export class GuardarConsolidadoPorReporteRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio
  ) { }
}

export class ConsultarConsolidadoPorReporteRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public c19ReporteCovidConsolidadosList?: C19ReporteCovidConsolidado[],
  ) { }
}

export class GuardarReporteEncabezadoRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public reporteCovid?: C19ReporteCovid
  ) { }
}

export class ConsultarReporteEncabezadoPorProyectoRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public reporteCovid?: C19ReporteCovid[],
  ) { }
}

export class ConsultarPersonaEncargadaRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public personaEncargada?: C19Persona[],
  ) { }
}

export class ConsultarTrabajadoresPorReporteRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public reporteTrabajador?: C19Persona[],
  ) { }
}

export class GuardarTrabajadorPorReporteRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public trabajador?: C19Persona,
  ) { }
}

export class ConsultarMedidasRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public reporteCovidMedidas?: MedidaCovid[],
  ) { }
}

export class GuardarReporteCovidMedidasRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio
  ) { }
}

export class ConsultarSintomasPorReporteYTrabajadorRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public reporteTrabajador?: C19ReporteCovidTrabajador[],
  ) { }
}

export class GuardarSintomasRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public reporteTrabajador?: C19ReporteCovidTrabajador,
  ) { }
}

export class RespuestaServicioRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio
  ) { }
}



