import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { forkJoin, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { TrnSiNo } from "src/app/parametricas/trn-si-no";
import { ParametricasService } from "src/app/parametricas/parametricas.service";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { PuestosTrabajoService } from "../puestos-trabajo.service";

import {
  IngBienPersonalDTO,
  IngMaFranjaHorariaDTO,
  IngMaPisoDTO,
  IngPuestoTrabajoDTO,
  IngReservaPuestoTrabajoDTO,
} from "../puestos-trabajo.dto";
import {
  InvBienAniPersonalDTO,
  InvMaTipoBien,
} from "src/app/inventario-equipos/inventario-equipos.dto";
import { InventarioEquiposService } from "src/app/inventario-equipos/inventario-equipos.service";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { EnumRegex } from '../../comun/constantes/constantes-comunes';

@Component({
  selector: "app-crear-editar-reserva",
  templateUrl: "./crear-editar-reserva.component.html",
  styleUrls: ["./crear-editar-reserva.component.sass"],
})
export class CrearEditarReservaComponent implements OnInit {
  blockedDocument: boolean;
  subscription: Subscription;
  esEdicionReserva: boolean;
  habilitaFranja: boolean;
  habilitaPiso: boolean;
  habilitaPuestoTrabajo: boolean;

  idReservaPuestoTrabajo: number;
  reservaPuestoTrabajo: IngReservaPuestoTrabajoDTO;

  franjaHorariaDTOList: IngMaFranjaHorariaDTO[];
  descripcionFranjaSeleccionada: string;
  pisoDTOList: IngMaPisoDTO[];
  descripcionLocalizacionSeleccionada: string;
  puestoTrabajoDTOList: IngPuestoTrabajoDTO[];
  puestoSeleccionado: number;
  puestoNumeroSeleccionado: number;
  llevaraEquipoList: TrnSiNo[];

  bien: InvBienAniPersonalDTO = {};
  bienList: InvBienAniPersonalDTO[];
  bienListSelected: InvBienAniPersonalDTO[];
  tipoBienList: InvMaTipoBien[];

  dialogoGuardarReserva: boolean;
  dialogoReservaPuestoTemporal: boolean;
  dialogoRegistrarEquipoPersonal: boolean;
  dialogoSeleccionarPuestoTrabajo: boolean;
  fechaActual: Date;

  bienPersonal: IngBienPersonalDTO = {};

  formReserva;
  formRegistrarEquipoPersonal;

  fechasDisponibles: Array<Date>;
  fechaMaxima: Date;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private puestosTrabajoService: PuestosTrabajoService,
    private messageService: MessageService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent,
    private inventarioEquiposService: InventarioEquiposService
  ) {


    // Crear formulario Reserva
    this.formReserva = formBuilder.group({
      fechaReserva: [null, [Validators.required]],
      franjaHoraria: [null, [Validators.required]],
      localizacion: [null, [Validators.required]],
      llevaraEquipo: [null, [Validators.required]],
    });

    // Crear formulario Registrar Equipo Personal
    this.formRegistrarEquipoPersonal = formBuilder.group({
      idTipoBien: [null, [Validators.required]],
      marca: [null, [Validators.required, Validators.pattern("^([A-Za-z0-9ñÑáéíóúÁÉÍÓÚ])+[-_.,A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]*")]],
      serial: [null, [Validators.required, Validators.pattern("^([A-Za-z0-9ñÑáéíóúÁÉÍÓÚ])+[-_.,A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]*")]],
    });
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {

    this.fechaActual = new Date();
    let formatoFechaActual: string = this.fechaActual.getFullYear() + "-" + (this.fechaActual.getMonth() + 1) + "-" + this.fechaActual.getDate();
    this.reservaPuestoTrabajo = {};

    // Obtener la reserva seleccionada
    const idReservaPuestoTrabajo = "idReserva";
    this.ruta.params.subscribe((params) => {
      this.idReservaPuestoTrabajo = params[idReservaPuestoTrabajo];
    });

    this.blockedDocument = true;

    forkJoin(
      this.puestosTrabajoService.srvObtenerFechasDisponibles(formatoFechaActual)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.fechasDisponibles = new Array();
          let fechasReserva = result.fechasReserva;
          let ultimaFecha = fechasReserva[fechasReserva.length - 1]
          this.fechaMaxima = new Date(ultimaFecha.fecha);
          // console.log(fechasReserva);
          fechasReserva.forEach(element => {
            if (element.habilitarEsteDiaCalendario === 0) {
              let fechaTemp = new Date(element.fecha);
              this.fechasDisponibles.push(fechaTemp);
              // console.log(element);
            }
          });

        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );

    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.puestosTrabajoService.srvListarFranjaHoraria(),
      this.puestosTrabajoService.srvListarPiso(),
      this.parametricasService.getTrnSiNo(),
      this.puestosTrabajoService.srvConsultarReservaPuestoTrabajoPorId(
        this.idReservaPuestoTrabajo
      ),
      this.puestosTrabajoService.srvConsultarBienesReserva(
        this.idReservaPuestoTrabajo
      ),
      this.inventarioEquiposService.srvListarTipoBien()

    ).subscribe(
      ([
        franjaHorariaData,
        pisoData,
        siNoData,
        reservaData,
        bienesReservaData,
        tipoBienListData
      ]) => {
        this.franjaHorariaDTOList = franjaHorariaData.franjasHorarias;
        // console.log("pisossss " + pisoData);
        this.pisoDTOList = pisoData.pisos;
        this.llevaraEquipoList = siNoData.sinoList;
        this.llevaraEquipoList.splice(0, 1);
        this.tipoBienList = tipoBienListData.tiposBien;

        this.listarMisAsignaciones();
        this.bienListSelected = [];

        //Obtener Reserva (usando obtenerReservaPuestoTrabajoPorId )
        // console.log("reservaData", reservaData);
        this.reservaPuestoTrabajo = reservaData.reserva;



        //Si se tiene una reserva a editar...
        if (this.reservaPuestoTrabajo != null) {
          this.esEdicionReserva = true;
          //Cargar fecha
          if (this.reservaPuestoTrabajo.fecha != null) {
            this.reservaPuestoTrabajo.fecha = new Date(
              this.reservaPuestoTrabajo.fecha
            );
          }

          //  Cargar el objeto del dropdown Franja Horaria
          this.reservaPuestoTrabajo.idFranjaHorariaDTO =
            this.franjaHorariaDTOList.find(
              (t) => t.id === this.reservaPuestoTrabajo.idFranjaHoraria
            );
          this.descripcionFranjaSeleccionada = this.reservaPuestoTrabajo.idFranjaHorariaDTO.descripcion;

          //  Cargar el objeto del dropdown Localizacion

          this.pisoDTOList.forEach(element => {
            // console.log("piso cargado " + element);
          });
          this.reservaPuestoTrabajo.idPisoDTO = this.pisoDTOList.find(
            (t) => t.id === this.reservaPuestoTrabajo.idPisoPiso
          );
          // console.log("localiacion cargada " + this.reservaPuestoTrabajo.idPisoDTO);
          this.descripcionLocalizacionSeleccionada = this.reservaPuestoTrabajo.descripcionPiso;

          //Simular que seleccione Localizacion
          if (this.reservaPuestoTrabajo.idPisoDTO != null) {
            this.consultarPuestosTrabajoDisponiblesPorPiso(
              this.reservaPuestoTrabajo.idPisoDTO.id
            );
          }

          //Consultar los bienes asociados a la reserva
          // console.log(this.llevaraEquipoList);

          this.reservaPuestoTrabajo.llevaraEquipo = this.llevaraEquipoList.find(
            (t) => t.id === 1
          );
          this.puestoSeleccionado = this.reservaPuestoTrabajo.idPuestoTrabajo;
          this.puestoNumeroSeleccionado = this.reservaPuestoTrabajo.numeroPuestoTrabajo;
          // console.log("bienesReservaData", bienesReservaData);
          this.bienListSelected = bienesReservaData.bienesReserva;
          // console.log(this.bienListSelected);


        } else {
          this.reservaPuestoTrabajo = {};
        }

        this.blockedDocument = false;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );

  }

  mostrardivflotante() {
    var d = document.getElementById("divflotante");
    var d2 = document.getElementById("botonflotante");
    var d3 = document.getElementById("botonflotante2");

    if (d.classList.contains("none")) {
      d.classList.remove("none");
      d.classList.add("verdiv");
      d2.classList.add("none");
      d3.classList.remove("none");
    } else {
      d.classList.remove("verdiv");
      d.classList.add("none");
      d2.classList.remove("none");
    }

  }

  cerrardivflotante() {
    var d = document.getElementById("divflotante");
    var d2 = document.getElementById("botonflotante");
    var d3 = document.getElementById("botonflotante2");

    d.classList.remove("verdiv");
    d.classList.add("none");
    d2.classList.remove("none");
    d3.classList.add("none");
  }

  zoomin() {
    var myImg = document.getElementById("plano2");
    var currWidth = myImg.clientWidth;
    //if(currWidth == 2500) return false;
    // else{
    //    myImg.style.width = (currWidth + 100) + "px";
    //} 
    myImg.style.width = (currWidth + 100) + "px";
  }
  zoomout() {
    var myImg = document.getElementById("plano2");
    var currWidth = myImg.clientWidth;
    if (currWidth == 100) return false;
    else {
      myImg.style.width = (currWidth - 100) + "px";
    }
  }

  onChangeFecha(event) {
    this.blockedDocument = true;
    let fechaSeleccionada: Date = new Date(event);
    let formatoFechaSeleccionada: string = fechaSeleccionada.getFullYear() + "-" + (fechaSeleccionada.getMonth() + 1) + "-" + fechaSeleccionada.getDate();
    this.reservaPuestoTrabajo.idFranjaHorariaDTO = {};
    this.reservaPuestoTrabajo.idPisoDTO = {};
    this.habilitaPuestoTrabajo = false;
    this.puestoSeleccionado = null;
    this.puestoNumeroSeleccionado = null;
    forkJoin(
      this.puestosTrabajoService.srvConsultarFranjaHorariaDisponible(formatoFechaSeleccionada)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.franjaHorariaDTOList = result.franjasHorarias;
          // console.log(result.franjasHorarias);
          this.habilitaFranja = true;
        } else {
          if (result.respuestaServicio.codigoSalida === 999) {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  onChangeFranja(event) {
    // console.log(event.value);
    this.reservaPuestoTrabajo.idPisoDTO = {};
    this.reservaPuestoTrabajo.idPuestoTrabajoDTO = null;
    this.habilitaPuestoTrabajo = false;
    this.puestoSeleccionado = null;
    this.puestoNumeroSeleccionado = null;
    this.puestoTrabajoDTOList = [];

    if (event.value != null) {
      this.descripcionFranjaSeleccionada = event.value.descripcion;
      this.consultarPuestosTrabajoDisponiblesPorFranja(event.value.id);
    } else {
      this.puestoTrabajoDTOList = [];
    }
  }

  /**
   * Lista los puestos de trabajo disponibles por franja
   */
  consultarPuestosTrabajoDisponiblesPorFranja(idFranja: number) {
    this.blockedDocument = true;
    var fechaReserva = this.reservaPuestoTrabajo.fecha.getFullYear() + "-" + (this.reservaPuestoTrabajo.fecha.getMonth() + 1) + "-" + this.reservaPuestoTrabajo.fecha.getDate();
    forkJoin(
      this.puestosTrabajoService.srvConsultarPuestosTrabajoDisponiblesPorFranja(fechaReserva, this.reservaPuestoTrabajo.idFranjaHorariaDTO.id)
    ).subscribe(
      ([puestosTrabajoData]) => {
        // console.log(puestosTrabajoData);
        if (puestosTrabajoData.respuestaServicio.codigoSalida == 997 || puestosTrabajoData.respuestaServicio.codigoSalida == 998) {
          this.lanzarMensajeInfo(puestosTrabajoData.respuestaServicio.mensajeSalida);
          this.reservaPuestoTrabajo.idPisoDTO = this.pisoDTOList.find(
            (t) => t.id === puestosTrabajoData.puestosTrabajo[0].idPiso
          );
          this.habilitaPuestoTrabajo = true;
          this.descripcionLocalizacionSeleccionada = this.reservaPuestoTrabajo.idPisoDTO.descripcion;
        } else {
          this.lanzarMensajeInfo(puestosTrabajoData.respuestaServicio.mensajeSalida);
          this.habilitaPiso = true;
        }
        this.puestoTrabajoDTOList = puestosTrabajoData.puestosTrabajo;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  onChangeLocalizacion(event) {
    // console.log(event.value);
    this.reservaPuestoTrabajo.idPuestoTrabajoDTO = null;
    if (event.value != null) {
      this.descripcionLocalizacionSeleccionada = event.value.descripcion;
      this.consultarPuestosTrabajoDisponiblesPorPiso(event.value.id);
    } else {
      this.puestoTrabajoDTOList = [];
    }
  }

  /**
   * Lista las asignaciones asociadas al usuario logueado
   */
  listarMisAsignaciones() {
    this.blockedDocument = true;
    forkJoin(
      this.inventarioEquiposService.srvConsultarBienesAniYPersonal(null)
    ).subscribe(
      ([asignacionesListData]) => {
        // console.log(asignacionesListData);
        this.bienList = asignacionesListData.bienesAniPersonal;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Lista las asignaciones asociadas al usuario logueado
   */
  consultarPuestosTrabajoDisponiblesPorPiso(idPiso: number) {
    this.blockedDocument = true;
    var fechaReserva = this.reservaPuestoTrabajo.fecha.getFullYear() + "-" + (this.reservaPuestoTrabajo.fecha.getMonth() + 1) + "-" + this.reservaPuestoTrabajo.fecha.getDate();
    forkJoin(
      this.puestosTrabajoService.srvConsultarPuestosTrabajoDisponiblesPorPiso(idPiso, fechaReserva, this.reservaPuestoTrabajo.idFranjaHorariaDTO.id)
    ).subscribe(
      ([puestosTrabajoData]) => {
        // console.log('puestosTrabajoData');
        // console.log(puestosTrabajoData);

        if (puestosTrabajoData.respuestaServicio.codigoSalida === 998) {
          this.lanzarMensajeWarning(puestosTrabajoData.respuestaServicio.mensajeSalida);
          this.habilitaPuestoTrabajo = false;
        } else {
          this.puestoTrabajoDTOList = puestosTrabajoData.puestosTrabajo;

          //  Cargar el objeto del dropdown # Puesto de Trabajo
          this.reservaPuestoTrabajo.idPuestoTrabajoDTO =
            this.puestoTrabajoDTOList.find(
              (t) => t.id === this.reservaPuestoTrabajo.idPuestoTrabajo
            );
          this.habilitaPuestoTrabajo = true;
        }


      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  guardarReserva() {
    // console.log("formReserva", this.formReserva);
    // console.log("reservaPuestoTrabajo", this.reservaPuestoTrabajo);
    // console.log("bienListSelected", this.bienListSelected);
    if (!this.esEdicionReserva) {
      if (this.formReserva.valid) {
        //Si llevara equipo...
        if (this.reservaPuestoTrabajo.llevaraEquipo.valor) {
          //Si selecciono por lo menos un equipo....
          if (this.bienListSelected.length > 0) {
            //Mostrar el dialogo
            this.dialogoGuardarReserva = true;
          } else {
            this.lanzarMensajeWarning("Debe seleccionar por lo menos un equipo");
          }
        } else {
          //Si NO llevara equipo, mostrar el dialogo
          this.dialogoGuardarReserva = true;
        }
      } else {
        // console.log(this.puestoSeleccionado);
        // console.log(this.reservaPuestoTrabajo.idPuestoTrabajo);
        // console.log("El puesto es :"+ this.puestoSeleccionado);
        if (this.puestoSeleccionado == null || this.puestoSeleccionado === undefined || this.puestoSeleccionado <= 0) {
          this.lanzarMensajeWarning("Debe seleccionar un puesto de trabajo");
        }
        this.lanzarMensajeWarning("Debe diligenciar los campos requeridos marcados con asterisco (*), incluyendo la selección del puesto de trabajo");
      }
    } else {
      //Si llevara equipo...
      if (this.reservaPuestoTrabajo.llevaraEquipo.valor) {
        //Si selecciono por lo menos un equipo....
        if (this.bienListSelected.length > 0) {
          //Mostrar el dialogo
          this.dialogoGuardarReserva = true;
        } else {
          this.lanzarMensajeWarning("Debe seleccionar por lo menos un equipo");
        }
      } else {
        //Si NO llevara equipo, mostrar el dialogo
        this.dialogoGuardarReserva = true;
      }
    }
  }

  /**
   * Guarda los cambios al crear o editar un predio
   */
  submitGuardarReserva() {
    this.reservaPuestoTrabajo.bienesReserva = [];
    // Completar el DTO a guardar
    this.bienListSelected.forEach((bienSelected) => {
      // console.log("bienSelected", bienSelected);
      if (bienSelected.tipoPertenencia == "Personal") {
        this.reservaPuestoTrabajo.bienesReserva.push({
          idBienAni: null,
          idBienPersonal: bienSelected.idBien,
        });
      }
      if (bienSelected.tipoPertenencia == "ANI") {
        this.reservaPuestoTrabajo.bienesReserva.push({
          idBienAni: bienSelected.idBien,
          idBienPersonal: null,
        });
      }
    });
    this.reservaPuestoTrabajo.idFranjaHoraria =
      this.reservaPuestoTrabajo.idFranjaHorariaDTO.id;
    this.reservaPuestoTrabajo.idPuestoTrabajo = this.puestoSeleccionado;
    this.reservaPuestoTrabajo.tipoReserva = 2;
    // console.log("reservaPuestoTrabajo A GUARDAR", this.reservaPuestoTrabajo);
    this.blockedDocument = true;
    // Guardar el DTO
    forkJoin(
      this.puestosTrabajoService.srvGuardarReserva(this.reservaPuestoTrabajo)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            //Asignar el id del predio recien guardado
            //this.predio.idPredioHistorico = result.predio.idPredioHistorico;
            this.lanzarMensajeInfo("Los cambios se guardaron correctamente");
            this.bntVolver();
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // console.log('Ocurrio un error al consumir el servicio srvGuardarPredio');
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
        this.dialogoGuardarReserva = false;
      }
    );
  }

  btnRegistrarEquipoPersonal() {
    this.dialogoRegistrarEquipoPersonal = true;
    this.bienPersonal = {};
  }

  btnSeleccionarPuestoTrabajo() {
    this.dialogoSeleccionarPuestoTrabajo = true;
    this.puestoSeleccionado = null;
    this.puestoNumeroSeleccionado = null;
    this.reservaPuestoTrabajo.idPuestoTrabajo = null;
  }

  btnDialogoCancelarSeleccionarPuestoTrabajo() {
    this.dialogoSeleccionarPuestoTrabajo = false;
    this.puestoSeleccionado = null;
    this.puestoNumeroSeleccionado = null;
    this.reservaPuestoTrabajo.idPuestoTrabajo = null;
  }

  btnAccionAgregarPuestoTrabajo(event: Event, puestoTrabajoDTO: IngPuestoTrabajoDTO) {
    this.lanzarMensajeWarning("Recuerde confirmar el puesto seleccionado " + puestoTrabajoDTO.numero + " para garantizarle el cupo");
    this.puestoSeleccionado = puestoTrabajoDTO.id;
    this.puestoNumeroSeleccionado = puestoTrabajoDTO.numero;

    // this.reservaPuestoTrabajo.idPuestoTrabajo = puestoTrabajoDTO.id;
    // this.reservaPuestoTrabajo.tipoReserva = 1;
  }

  btnAceptarPuestoSeleccionado() {
    if (this.puestoSeleccionado != null) {
      this.dialogoReservaPuestoTemporal = true;
    } else {
      this.lanzarMensajeWarning("Debe seleccionar un puesto de trabajo");
    }
  }

  btnCancelarSeleccionPuestoTrabajo() {
    this.puestoSeleccionado = null;
    this.puestoNumeroSeleccionado = null;
    this.reservaPuestoTrabajo.idPuestoTrabajo = null;
    this.dialogoReservaPuestoTemporal = false;
    this.lanzarMensajeWarning("No haseleccionado un puesto de trabajo");
  }

  reservarPuestoTrabajoTemporal() {
    this.blockedDocument = true;
    // console.log("reservaPuestoTrabajo A GUARDAR", this.reservaPuestoTrabajo);
    // Guardar el DTO
    this.reservaPuestoTrabajo.idFranjaHoraria = this.reservaPuestoTrabajo.idFranjaHorariaDTO.id;
    this.reservaPuestoTrabajo.idPuestoTrabajo = this.puestoSeleccionado;
    this.reservaPuestoTrabajo.tipoReserva = 1;
    forkJoin(
      this.puestosTrabajoService.srvGuardarReserva(this.reservaPuestoTrabajo)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            //Asignar el id del predio recien guardado
            //this.predio.idPredioHistorico = result.predio.idPredioHistorico;
            this.lanzarMensajeInfo("La reserva se guardó temporalmente por 10 minutos, por favor termine el proceso para finalizar");
            this.dialogoReservaPuestoTemporal = false;
            this.dialogoSeleccionarPuestoTrabajo = false;
            //this.bntVolver();
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // console.log('Ocurrio un error al consumir el servicio srvGuardarPredio');
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
        this.dialogoGuardarReserva = false;
      }
    );
  }

  /**
   * Guarda los cambios al crear o editar un predio
   */
  submitRegistrarEquipoPersonal() {
    if (this.formRegistrarEquipoPersonal.valid) {
      this.blockedDocument = true;
      this.bienPersonal.idTipoBien = this.bienPersonal.idTipoBienDTO.id;
      // Guardar el DTO
      forkJoin(
        this.puestosTrabajoService.srvGuardarBienPersonal(this.bienPersonal)
      ).subscribe(
        ([result]) => {
          // console.log(result);
          if (result != null && result.respuestaServicio != null) {
            // Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              //Asignar el id del predio recien guardado
              //this.predio.idPredioHistorico = result.predio.idPredioHistorico;
              this.lanzarMensajeInfo("Los cambios se guardaron correctamente");
              this.listarMisAsignaciones();
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            // console.log('Ocurrio un error al consumir el servicio srvGuardarPredio');
          }
        },
        (error) => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
          this.dialogoRegistrarEquipoPersonal = false;
        }
      );
    } else {
      this.lanzarMensajeWarning("Debe diligenciar los campos requeridos marcados con asterisco (*)");
    }
  }

  bntVolver() {
    const ruta = "home-puestos-trabajo/puestos-trabajo";
    this.router.navigate([ruta]);
  }

  // // // // /Mensajes// // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
