import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class PqrsReporteEncuestaSatisfaccionService {

    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
      this.END_POINT = environment.baseUrl;
    }

    /**
     * Servicio que realiza la busqueda
     * @param data
     * @returns
     */
    public postBusqueda(data) {
      return this.http.post(this.END_POINT + '/PqrsEncuestaSatisfaccion/listado', data)
        .pipe(
          retry(1));
    }

    /**
     * Servicio que generar el excel
     * @param data
     * @returns
     */
    public postGenerarExcel(data) {
      return this.http.post(this.END_POINT + '/PqrsEncuestaSatisfaccion/listado/excel', data)
        .pipe(
          retry(1));
    }


  }
