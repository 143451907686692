import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {

    constructor(private authService: MsAdalAngular6Service, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isAuthenticated) {
            var paginas = sessionStorage.getItem("paginas");
            if (paginas != null) {
                var _paginas = JSON.parse(paginas);
                var url = state.url
                if (state.url.indexOf('/', 1) > 0) {
                    var url = state.url.substring(0, state.url.indexOf('/', 1))
                }
                var result = _paginas.filter(obj => {
                    return obj != null && obj.urlPagina.toLowerCase().includes(url.toLowerCase());
                })

                return (result != null && result.length > 0);
            } else {
                return false;
            }
            return true;
        } else {
            this.authService.login();
        }
    }
}