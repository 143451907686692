import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { ConcesionarioCarreteroComponent } from '../concesionario-carretero/concesionario-carretero.component';
import { InformacionProyectoCarreteroService } from './informacion-proyecto-carretero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProyectoCarretero } from '../informacion-proyecto-carretero/proyectoCarretero';
import { ParametricasService } from './../parametricas/parametricas.service';
import { ConcesionarioCarreteroService } from '../concesionario-carretero/concesionario-carretero.service';
import { ContratoCreditoCarreteroService } from '../contrato-credito-carretero/contrato-credito-carretero.service';
import { ContratoCredito } from '../contrato-credito-carretero/contratoCredito';
import { isUndefined } from 'util';
import { ProyectoCarreteroHistoricoDetalle } from './proyectoCarreteroHistoricoDetalle';
import { MatTableDataSource } from '@angular/material';
import { IfStmt } from '@angular/compiler';
import { Generacion } from '../informacion-general/generacion';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-informacion-proyecto-carretero',
  templateUrl: './informacion-proyecto-carretero.component.html',
  styleUrls: ['./informacion-proyecto-carretero.component.sass']
})
export class InformacionProyectoCarreteroComponent implements OnInit {

  proyectoCarretero: ProyectoCarretero = {};

  idProyectoCarreteroDetalle: any;


  visibleDialogoExito: boolean = false;

  generaciones: any = [];
  filtroGeneraciones: any[];


  olas: any = [];
  filtroOlas: any[];
  olaSeleccionada: any;

  idHistoricoProyecto: any;
  visibleDialogoAgregarHistoricoProyecto: boolean = false;
  visibleDialogoExitoHistoricoProyecto: boolean = false;
  visibleConfirmarEliminarHistoricoProyecto: boolean = false;
  visibleDialogoExitoEliminarHistoricoProyecto: boolean = false;
  proyectoCarreteroHistorico: ProyectoCarreteroHistoricoDetalle = {};
  historicoProyecto: any = [];
  displayedColumns: string[] = ['longitudConcesionada', 'longitudACargo', 'fechaInicio', 'fechaFin', 'acciones'];
  dataSourceHistoricoProyecto: MatTableDataSource<ProyectoCarreteroHistoricoDetalle>;

  fechaCapex: Date;
  fechaOpex: Date;

  constructor(private lineaBase: ProyectoLineaBaseComponent, private contratoCreditoCarreteroService: ContratoCreditoCarreteroService, private parametricasService: ParametricasService, private detalleService: InformacionProyectoCarreteroService, private route: ActivatedRoute, private ruta: Router, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.lineaBase.idProyecto = params.id;
      this.lineaBase.idProyecto === 'new' ? this.cargarNuevo() : this.cargarProyecto(this.lineaBase.idProyecto);
    });
    this.proyectoCarretero.crrProyecto = {};
    this.proyectoCarretero.crrProyectoHistorico = {};
    if (this.lineaBase.generacionSeleccionada == null) {
      this.lineaBase.generacionSeleccionada = new Generacion(null, 0);
    }
  }

  cargarNuevo() {
    this.proyectoCarretero = {};
    this.lineaBase.idContratoConcesionario = "new";
    this.lineaBase.idContratoInterventoria = "new";
  }

  cargarProyecto(id: number) {
    this.detalleService.getProyectoPorId(id).subscribe((data: {}) => {
      this.lineaBase.etapasProyecto = {};
      this.proyectoCarretero = data;
      this.lineaBase.nombreProyecto = this.proyectoCarretero.nombre;
      if (this.proyectoCarretero != null) {
        if (this.proyectoCarretero.fechaReferenciaCapex != null) {
          this.fechaCapex = new Date(this.proyectoCarretero.fechaReferenciaCapex);
        }
        if (this.proyectoCarretero.fechaReferenciaOpex != null) {
          this.fechaOpex = new Date(this.proyectoCarretero.fechaReferenciaOpex);
        }
        this.lineaBase.tieneCapexOpexPorUnidadFuncional = this.proyectoCarretero.tieneCapexOpexPorUnidadFuncional;
        this.cargarHistoricoProyecto(id);
        this.lineaBase.habilitaTabConcesionario = true;
        this.lineaBase.generacionSeleccionada = this.proyectoCarretero.crrProyecto.idGeneracionNavigation;
        this.olaSeleccionada = this.proyectoCarretero.crrProyecto.idOlaNavigation;
        if (this.proyectoCarretero.idContratoConcesionario != null && this.proyectoCarretero.idContratoConcesionario != 0) {
          this.lineaBase.idContratoConcesionario = this.proyectoCarretero.idContratoConcesionario;
        }
        if (this.proyectoCarretero.idContratoInterventoria != null && this.proyectoCarretero.idContratoInterventoria != 0) {
          this.lineaBase.idContratoInterventoria = this.proyectoCarretero.idContratoInterventoria;
          this.lineaBase.habilitaTabInterventoria = true;
        }
        this.contratoCreditoCarreteroService.getContratoCreditoPorProyecto(id).subscribe((data: {}) => {
          this.lineaBase.contratoCredito = data;
          if (this.lineaBase.contratoCredito == null) {
            this.lineaBase.idContratoCredito = "new";
            this.lineaBase.contratoCredito = {};
            this.lineaBase.tieneContrato = "No";
            this.lineaBase.habilitaEliminarContratoCredito = false;
          } else {
            this.lineaBase.idContratoCredito = this.lineaBase.contratoCredito.id;
            this.lineaBase.tieneContrato = "Si";
            this.lineaBase.habilitaEliminarContratoCredito = true;
          }
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        this.ruta.navigate(['/carreteros'])
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarHistoricoProyecto(id) {
    this.detalleService.listarHistoricoProyectoPorId(id).subscribe((data: {}) => {
      this.historicoProyecto = data;
      this.dataSourceHistoricoProyecto = new MatTableDataSource(this.historicoProyecto);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarGeneraciones(event) {
    this.filtroGeneraciones = [];
    return this.parametricasService.getGeneraciones().subscribe((data: {}) => {
      this.generaciones = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.generaciones.length; i++) {
        let filtroGeneracion = this.generaciones[i];
        let item: string = String(filtroGeneracion.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroGeneraciones.push(filtroGeneracion);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.generaciones = this.filtroGeneraciones;
      }
    });
  }

  cargarFiltroGeneraciones(event) {
    this.filtroGeneraciones = [];
    for (let i = 0; i < this.generaciones.length; i++) {
      let filtro = this.generaciones[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroGeneraciones.push(filtro);
      }
    }
  }

  cargarOlas(event) {
    this.filtroOlas = [];
    return this.parametricasService.getOlas().subscribe((data: {}) => {
      this.olas = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.olas.length; i++) {
        let filtro = this.olas[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroOlas.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.olas = this.filtroOlas;
      }
    });
  }

  cargarFiltroOlas(event) {
    this.filtroOlas = [];
    for (let i = 0; i < this.olas.length; i++) {
      let filtro = this.olas[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroOlas.push(filtro);
      }
    }
  }

  guardarDetalle(forma: NgForm) {
    this.proyectoCarretero.crrProyecto.idGeneracion = this.lineaBase.generacionSeleccionada.id;
    this.proyectoCarretero.crrProyecto.idOla = this.olaSeleccionada.id;
    this.proyectoCarretero.idTipo = 2;
    this.proyectoCarretero.tieneCapexOpexPorUnidadFuncional = this.lineaBase.tieneCapexOpexPorUnidadFuncional;
    this.proyectoCarretero.fechaReferenciaCapex = this.fechaCapex;
    this.proyectoCarretero.fechaReferenciaOpex = this.fechaOpex;
    if (this.lineaBase.idProyecto === 'new') {
      let vara: any = this.detalleService.guardarNuevoProyecto(this.proyectoCarretero).subscribe((data: {}) => {
        this.visibleDialogoExito = true;
        let proyectoNuevo: ProyectoCarretero = data;
        this.lineaBase.idProyecto = proyectoNuevo.id;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.detalleService.editarProyecto(this.proyectoCarretero, this.lineaBase.idProyecto).subscribe((data: {}) => {
        this.visibleDialogoExito = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  agregarRegistroHistorico(forma: NgForm) {
    if (this.visibleDialogoAgregarHistoricoProyecto) {
      this.proyectoCarreteroHistorico.idProyecto = this.lineaBase.idProyecto
      if (this.idHistoricoProyecto === 'new') {
        let vara: any = this.detalleService.guardarHistoricoProyecto(this.proyectoCarreteroHistorico).subscribe((data: {}) => {
          this.visibleDialogoExitoHistoricoProyecto = true;
          this.cargarHistoricoProyecto(this.lineaBase.idProyecto);
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        this.detalleService.editarHistoricoProyecto(this.proyectoCarreteroHistorico, this.idHistoricoProyecto).subscribe((data: {}) => {
          this.visibleDialogoExitoHistoricoProyecto = true;
          this.cargarHistoricoProyecto(this.lineaBase.idProyecto);
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      }
    }
  }

  editarHistoricoProyecto(idHistoricoProyecto) {
    this.idHistoricoProyecto = idHistoricoProyecto;
    this.visibleDialogoAgregarHistoricoProyecto = true;
    return this.detalleService.getHistoricoProyectoPorId(idHistoricoProyecto).subscribe((data: {}) => {
      this.proyectoCarreteroHistorico = data;
      this.proyectoCarreteroHistorico.longitudAcargo = this.proyectoCarreteroHistorico.longitudAcargo / 1000;
      this.proyectoCarreteroHistorico.longitudConcesionada = this.proyectoCarreteroHistorico.longitudConcesionada / 1000;
      this.proyectoCarreteroHistorico.fechaInicio = new Date(this.proyectoCarreteroHistorico.fechaInicio);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  filtroHistoricoProyecto(filterValue: string) {
    this.dataSourceHistoricoProyecto.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceHistoricoProyecto.paginator) {
      this.dataSourceHistoricoProyecto.paginator.firstPage();
    }
  }

  visibleAgregarHistoricoProyecto() {
    this.visibleDialogoAgregarHistoricoProyecto = true;
    this.proyectoCarreteroHistorico = {};
    this.idHistoricoProyecto = "new";
  }

  cancelarAgregarHistoricoProyecto() {
    this.visibleDialogoAgregarHistoricoProyecto = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.lineaBase.habilitaTabConcesionario = true;
  }

  aceptarDialogoExitoHistoricoProyecto() {
    this.visibleDialogoExitoHistoricoProyecto = false;
    this.visibleDialogoAgregarHistoricoProyecto = false;
  }

  irEliminarHistoricoProyecto(id) {
    this.visibleConfirmarEliminarHistoricoProyecto = true;
    this.idHistoricoProyecto = id;
  }

  cancelarEliminarHistoricoProyecto() {
    this.visibleConfirmarEliminarHistoricoProyecto = false;
  }

  aceptarEliminarHistoricoProyecto() {
    return this.detalleService.eliminarHistoricoProyectoPorId(this.idHistoricoProyecto, this.lineaBase.idProyecto).subscribe((data: {}) => {
      this.visibleConfirmarEliminarHistoricoProyecto = false;
      this.cargarHistoricoProyecto(this.lineaBase.idProyecto);
      this.visibleDialogoExitoEliminarHistoricoProyecto = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarHistoricoProyecto() {
    this.visibleDialogoExitoEliminarHistoricoProyecto = false;
  }

}
