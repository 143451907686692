import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { EnumRegex } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { IngCuposPisoDTO, IngMaPisoDTO } from '../../puestos-trabajo.dto';
import { PuestosTrabajoService } from '../../puestos-trabajo.service';

@Component({
  selector: 'app-crear-editar-cupos',
  templateUrl: './crear-editar-cupos.component.html',
  styleUrls: ['./crear-editar-cupos.component.sass']
})
export class CrearEditarCuposComponent implements OnInit {

  blockedDocument: boolean;
  cupoPiso: IngCuposPisoDTO = {};
  idCupo: number;
  fechaActual: Date;
  pisoDTOList: IngMaPisoDTO[];
  formCupo;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private puestosTrabajoService: PuestosTrabajoService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) {

    // Crear formulario Cupo
    this.formCupo = formBuilder.group({
      fechaInicio: [null, [Validators.required]],
      localizacion: [null, [Validators.required]],
      cupos: [null, [Validators.required, Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
    });

  }

  ngOnInit() {
    // Obtener el cupo seleccionado
    const idCupo = "idCupo";
    this.ruta.params.subscribe((params) => {
      this.idCupo = params[idCupo];
    });
    console.log("Cupo seleccionado " + this.idCupo);
    this.fechaActual = new Date();
    this.cargarCupo();

  }

  cargarCupo() {
    this.blockedDocument = true;
    forkJoin(
      this.puestosTrabajoService.srvListarPiso(),
      this.puestosTrabajoService.srvObtenerCupoPorId(this.idCupo)
    ).subscribe(
      ([pisosData,
        cupoData]) => {
        console.log(pisosData);
        this.pisoDTOList = pisosData.pisos;
        console.log(cupoData);
        this.cupoPiso = cupoData.cupo;
        if (this.cupoPiso.fechaInicio != null) {
          this.cupoPiso.fechaInicio = new Date(this.cupoPiso.fechaInicio);
        }
        console.log(this.cupoPiso);
        //  Cargar el objeto del dropdown Localizacion
        this.pisoDTOList.forEach(element => {
          console.log("piso cargado " + element);
        });
        this.cupoPiso.ingMaPisoDTO = this.pisoDTOList.find(
          (t) => t.id === this.cupoPiso.idPiso
        );
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }



  guardarCupo() {
    console.log("formCupo", this.formCupo);
    console.log("cupoPiso", this.cupoPiso);
    this.blockedDocument = true;
    if (!this.formCupo.valid) {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    } else {
      this.cupoPiso.idPiso = this.cupoPiso.ingMaPisoDTO.id;
      forkJoin(
        this.puestosTrabajoService.srvGuardarCupoPiso(this.cupoPiso)
      ).subscribe(
        ([cupoData]) => {
          console.log(cupoData);
          if (cupoData.respuestaServicio.codigoSalida === 1) {
            this.lanzarMensajeInfo("Se guardó la información correctamente");
          } else {
            this.lanzarMensajeWarning(cupoData.respuestaServicio.mensajeSalida);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }
  }

  bntVolver() {
    const ruta = "home-puestos-trabajo/administracion-home/listar-cupos";
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
