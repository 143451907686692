import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { PuestosTrabajoService } from '../../puestos-trabajo.service';
import { IngFranjaHorariaDTO } from '../../puestos-trabajo.dto';

@Component({
  selector: 'app-listar-franjas',
  templateUrl: './listar-franjas.component.html',
  styleUrls: ['./listar-franjas.component.sass']
})
export class ListarFranjasComponent implements OnInit {

  blockedDocument: boolean;
  franjaList: IngFranjaHorariaDTO[];

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private puestosTrabajoService: PuestosTrabajoService,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService) { }

  ngOnInit() {
    this.listarFranjas();
  }

  listarFranjas() {
    this.blockedDocument = true;
    forkJoin(
      this.puestosTrabajoService.srvListarFranjas()
    ).subscribe(
      ([franjasData]) => {
        console.log(franjasData);
        this.franjaList = franjasData.franjasHorarias;
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  btnAgregar() {
    this.router.navigate([this.router.url + "/crear-editar-franjas/0"]);
  }

  btnEditar(franjaSelected: IngFranjaHorariaDTO) {
    this.lanzarMensajeInfo("Editar!!!");
    this.router.navigate([
      this.router.url +
      "/crear-editar-franjas/" +
      franjaSelected.id,
    ]);

  }

  btnVolverHomeAdmin() {
    this.router.navigate(["/home-puestos-trabajo/administracion-home"]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
