import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-public-carreteras-consulta-ficha3g2g',
  templateUrl: './public-carreteras-consulta-ficha3g2g.component.html'
})
export class PublicCarreterasConsultaFicha3g2gComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
  element.classList.add("public");
  }

}
