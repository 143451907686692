import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'public-carreteras-visor-avance-predial',
  templateUrl: './public-carreteras-visor-avance-predial.component.html'
})
export class PublicCarreterasVisorAvancePredialComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
  element.classList.add("public");
  }

}


