import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportes-inventario',
  templateUrl: './reportes-inventario.component.html',
  styleUrls: ['./reportes-inventario.component.sass']
})
export class ReportesInventarioComponent implements OnInit {

  rutaIframe: string;

  constructor() { }

  ngOnInit() {
    this.rutaIframe = 'https://app.powerbi.com/view?r=eyJrIjoiOGMzNjY3ZmUtZWIwMC00MTcwLWIzYzQtMmNkMTZmNzAwMTQxIiwidCI6IjcwZGM1N2VlLWZlNDYtNDI4Ni1iNjViLTFhZTllMTI2YzAzZSIsImMiOjR9'
  }

}
