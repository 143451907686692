import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCarreteroService } from "../reportes-carreteros.service";
import {CrrInformacionProyectoDTO,
  InformacionGeneralCarreterosDTO,
  CrrInformacionGeneralProyectoDTO,
  CrrInformacionDepartamentosDTO,
  CrrInformacionPeajesDTO,CrrInformacionProyectoCsvDTO
} from "../reportes-carreteros.dto";
import { formatDate } from "@angular/common";
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { PaginatorModule } from "primeng/paginator";

@Component({
  selector: "app-rpt-crr-proyectos",
  templateUrl: "./rpt-crr-proyectos.component.html",
  styleUrls: ["./rpt-crr-proyectos.component.sass"],
})
export class RptCrrProyectosComponent implements OnInit {
  informacionGeneralCompleta: CrrInformacionGeneralProyectoDTO[] = [];
  informacionGeneralFiltro: CrrInformacionGeneralProyectoDTO[] = [];
  informacionGeneral: CrrInformacionGeneralProyectoDTO;
  proyectoSeleccionado: CrrInformacionGeneralProyectoDTO;
  proyectoCsv: CrrInformacionProyectoCsvDTO;
  informacionGeneralFiltroCsv: CrrInformacionProyectoCsvDTO[] = [];
  blockedDocument = false;
  informacionGeneralFiltroDropDown: CrrInformacionGeneralProyectoDTO[] = [];


  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent,
    private sanitizer: DomSanitizer,
    private route: Router
  ) {}

  ngOnInit() {
    var elementload = document.getElementById("loaddatos");
    elementload.classList.remove("none");

    this.blockedDocument = true;

    this.reportesCarreteroService.getinformacionGeneralProyectosMosaico().subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.informacionGeneralCompleta = dato.proyectos;
            this.informacionGeneralFiltro = this.informacionGeneralCompleta;
          this.informacionGeneralFiltroDropDown = this.informacionGeneralCompleta;
            elementload.classList.add("none");
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
        this.blockedDocument = false;
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }
  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  btnFiltroGeneracion(generacion: string) {
    this.proyectoSeleccionado = null;

    if (generacion == "Todos") {
      this.informacionGeneralFiltro = this.informacionGeneralCompleta;
      this.informacionGeneralFiltroDropDown = this.informacionGeneralCompleta;
    } else {
      this.informacionGeneralFiltro = this.informacionGeneralCompleta.filter(
        (e) => e.generacion == generacion
      );

      this.informacionGeneralFiltroDropDown =
      this.informacionGeneralCompleta.filter(
        (e) => e.generacion == generacion
      );
    }
  }
  //formato moneda en billones
  formatoMoneda(valor: number) {
    return valor
      ? "$" +
          (valor / 1000000000000)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
          " Bn"
      : "$0";
  }

  actualizarProyectos(event) {
    if (this.proyectoSeleccionado == null) {
      this.informacionGeneralFiltro = this.informacionGeneralCompleta;
     
    }else{
      this.informacionGeneralFiltro = this.informacionGeneralCompleta.filter(
        (e) => e.id === this.proyectoSeleccionado.id
      );
    }
  
  }


  exportCsvProyectosFiltrados() {

    this.informacionGeneralFiltroCsv =  [];
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: ["Id Proyecto", "Proyecto", "Etapa", "Generación", "Porcentaje de avance"]
    };
   
   

    this.informacionGeneralFiltro.forEach(element => {
      this.proyectoCsv = new CrrInformacionProyectoDTO();
      this.proyectoCsv.codProyecto = element.codProyecto;
      this.proyectoCsv.proyecto = element.proyecto;
      this.proyectoCsv.etapaProyecto = element.etapaProyecto;
      this.proyectoCsv.generacion = element.generacion;
      this.proyectoCsv.porcentajeAvance = (element.porcentajeAvance * 100).toFixed(2) +' %';
      this.informacionGeneralFiltroCsv.push(this.proyectoCsv);
    });


    new ngxCsv(this.informacionGeneralFiltroCsv, 'Proyectos' + '_export_' + new Date().getTime(), options);
  }

  irAResumen(): void {
    this.route.navigate(["/reportes/carreteros"]);
  }
}
