import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { InformacionGeneral } from './informacionGeneral';
import { Persona } from './informacionGeneral';
import { Amparo } from './informacionGeneral';
import { InformacionGeneralEnvio } from './informacionGeneral';
import { InformacionGeneralEnvioEdit } from './informacionGeneral';
import { Anexo, Anexos } from './informacionGeneral';
import { AseguradosBeneficiarios } from './informacionGeneral';
import { Amparos } from './informacionGeneral';
import { PolizaEditService } from './poliza-edit.service';
import { EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { isBuffer } from 'util';
import { Location } from '@angular/common';

@Component({
	selector: 'app-poliza-edit',
	templateUrl: './poliza-edit.component.html',
	styleUrls: ['./poliza-edit.component.sass']
})
export class PolizaEditComponent implements OnInit {

	loading: boolean;
	resultadoInfo: any;
	tomador: any;
	visibleDialogoAgregarAsegurado: boolean = false;
	visibleDialogoAgregarAmparo: boolean = false;
	
	informacionGeneral: InformacionGeneral = {};
	//Variables para Armar envio de guardado
	enviarInfo: InformacionGeneralEnvio = {};
	enviarInfoEdit: InformacionGeneralEnvioEdit = {};
	anexo: Anexo = {};
	anexos: Anexos = {};

	amparoEnvio: Amparos = {};
	amparos: any = [];
	aseguradoEnvio: AseguradosBeneficiarios = {};
	aseguradosBeneficiarios: any = [];
	title: any;
	tipoSolicitud: any;
	visibleAnexo: boolean = false;
	
	persona: Persona = {};
	amparo: Amparo = {};
	amparoTempEdit: Amparos = {};
	amparoNew: Amparo = {};
	informacionGeneralGet: any = [];
	//Lista Contratos - Principal
	contratos: any = [];
	contratosTemp: any = [];
	filtroContratos: any[];
	contratoSeleccionada: any = { id: 0, nombre: '', nombreTipoContrato: ''};
	//Lista Aseguradoras - Principal
	aseguradoras: any = [];
	aseguradorasTemp: any = [];
	filtroAseguradoras: any[];
	aseguradoraSeleccionada: any = { id: 0, nombre: ''};
	//Lista Monedas - Principal
	monedas: any = [];
	monedasTemp: any = [];
	filtroMonedas: any[];
	monedaSeleccionada: any = { id: 0, nombre: ''};
	moneda: any;
	//Lista Tipo Personas - modal Personas
	tipoPersonas: any = [];
	tipoPersonasTemp: any = [];
	filtroTipoPersonas: any[];
	tipoPersonaSeleccionada: any = { id: 0, nombre: ''};
	//Lisa de Tipo Documento - modal Personas
	tipoDocumentos: any = [];
	tipoDocumentosTemp: any = [];
	filtroTipoDocumentos: any[];
	tipoDocumentoSeleccionada: any = { id: 0, nombre: ''};
	//Lisa de personas - modal Personas
	personas: any = [];
	personaEliminar: any;
	filtroPersonas: any[];
	personaSeleccionada: any;
	//Lisa de tipo amparos - modal amparos
	tipoAmparos: any = [];
	tipoAmparosTemp: any = [];
	filtroTipoAmparos: any[];
	tipoAmparoSeleccionada: any;
	
	idProyecto: any;
	idPoliza: any;
	blockedDocument: boolean;
	visibleDialogoExito: boolean = false;
	
	listaPersonas: any = [];
	listaAmparos: any = [];
	listaAnexos: any = [];
	listaAnexosEnvio: any = [];

	
  // Arrays de Columnas de Tablas

	displayedPersonas = [
		{ field: 'tipoNombre', header: 'Tipo' },
		{ field: 'nombre', header: 'Nombre' },
		{ field: 'numeroDocumento', header: 'Documento' }
	];

	displayedAmparos = [
		{ field: 'tipoAmparo', header: 'Tipo Amparo' },
		{ field: 'fechaInicioCoberturaStr', header: 'Fecha Inicio' },
		{ field: 'fechaFinCoberturaStr', header: 'Fecha Fin' },
		{ field: 'valorAmparoStr', header: 'Valor Asegurado' }
	];
	
	displayedAnexos = [
		{ field: 'numero', header: 'No Anexo' },
		{ field: 'fechaExpedicionStr', header: 'Fecha Expedición' },
		{ field: 'novedadContrato', header: 'Asociado a' }
	];


	constructor(private messageService: MessageService, private parametricasService: ParametricasService, private polizaEditService: PolizaEditService,
		private route: ActivatedRoute, private ruta: Router, private principalComponent: PrincipalComponent, public location: Location) {}


	/**
	 * Metodo inicializador
	 */
	ngOnInit() {
		this.loading = true;
		this.moneda = "";
		this.idProyecto = localStorage.getItem('idP');
		this.contratoSeleccionada = { id: 0, nombre: ''};
		this.aseguradoraSeleccionada = { id: 0, nombre: ''};
		this.monedaSeleccionada = { id: 0, nombre: ''};
		this.tipoPersonaSeleccionada = { id: 0, nombre: ''};
		this.tipoDocumentoSeleccionada = { id: 0, nombre: ''};
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};

		this.cargarSoloContratos();
		this.cargarSoloAseguradoras();
		this.cargarSoloMonedas();

		this.cargarContratos();
		this.cargarAseguradoras();
		this.cargarMonedas();	
		

		this.informacionGeneral = new InformacionGeneral();
		this.informacionGeneral.habilitarAseguradoNombre = true;		
		this.tipoSolicitud = localStorage.getItem('tipoSolicitud');
		if(this.tipoSolicitud == "onView" 
			|| this.tipoSolicitud == "onViewAmparo"){
			this.visibleAnexo = false;
			this.title = "Visualización";
		}else{
			if(this.tipoSolicitud == "onNew"){
				this.title = "Creación";
			}else{
				this.title = "Edición";
			}
			this.visibleAnexo = true;
		}		
		
		this.route.params.subscribe(params => {
			this.idPoliza = params.id;
			if(this.idPoliza != "new"){
				this.cargarSoloTipoAmparo(this.idPoliza);
				this.cargarSoloTipoDocumento();
				this.cargarSoloTipoPersona();
				this.cargarInformacionGeneral(this.idPoliza);	
			}else{
				this.cargarSoloTipoAmparo(null);
			}
			
		});
		this.loading = false;
	}

	/**
	 * Metodo que carga la informacion cuando es
	 * @param idPoliza 
	 */
	cargarInformacionGeneral(idPoliza: number) {
		this.cargarSoloTipoAmparo(idPoliza);
		forkJoin(
			this.polizaEditService.obtenerInformacionGeneral(idPoliza)
		).subscribe(([result]) => {
				this.resultadoInfo = result;
				if (this.resultadoInfo != null && this.resultadoInfo.respuestaServicio != null) {
					//Si el resultado es exitoso...
					if (this.resultadoInfo.respuestaServicio.codigoSalida === 1) {
						this.informacionGeneralGet = this.resultadoInfo.poliza;
						this.informacionGeneral = new InformacionGeneral();
						this.informacionGeneral.id = this.informacionGeneralGet.id ; 
						this.informacionGeneral.idProyecto = this.informacionGeneralGet.idProyecto ; 
						this.informacionGeneral.idContrato = this.informacionGeneralGet.idContrato ; 
						this.informacionGeneral.idAseguradora = this.informacionGeneralGet.idAseguradora ; 
						this.informacionGeneral.idMoneda = this.informacionGeneralGet.idMoneda ;
						this.informacionGeneral.moneda = this.informacionGeneralGet.moneda ;
						this.informacionGeneral.aseguradora = this.informacionGeneralGet.aseguradora ;               
						this.informacionGeneral.nitAseguradora = this.informacionGeneralGet.nitAseguradora ;        
						this.informacionGeneral.numero = this.informacionGeneralGet.numero ;        
						this.informacionGeneral.numeroContrato = this.informacionGeneralGet.numeroContrato ;        
						this.informacionGeneral.tomador = this.informacionGeneralGet.tomador ; 
						this.informacionGeneral.habilitarEditar = this.informacionGeneralGet.habilitarEditar ;
						this.informacionGeneral.habilitarEliminar = this.informacionGeneralGet.habilitarEliminar ;
						this.moneda = this.informacionGeneralGet.idMoneda;
						localStorage.setItem('monedaAnexo', this.moneda);
						if(this.informacionGeneralGet.anexos!=undefined && this.informacionGeneralGet.anexos != null 
							&& this.informacionGeneralGet.anexos.length > 0){			

							this.informacionGeneral.fechaExpedicionStr = new Date(this.informacionGeneralGet.anexos[this.informacionGeneralGet.anexos.length-1].fechaExpedicion).toLocaleDateString() ;
							this.informacionGeneral.fechaExpedicion = new Date(this.informacionGeneralGet.anexos[this.informacionGeneralGet.anexos.length-1].fechaExpedicion) ;
							this.informacionGeneral.objetoSeguro = this.informacionGeneralGet.anexos[this.informacionGeneralGet.anexos.length-1].objeto ;
							this.transformarAmparos(this.informacionGeneralGet.anexos[this.informacionGeneralGet.anexos.length-1].amparos);
							this.transformarAnexos(this.informacionGeneralGet.anexos);
						}						
						this.transformarPersonas(this.informacionGeneralGet.aseguradosBeneficiarios);
						this.aseguradoraSeleccionada = { id: 0, nombre: ''};
						this.aseguradoraSeleccionada.nombre = this.informacionGeneralGet.aseguradora;
						this.aseguradoraSeleccionada.id = this.informacionGeneralGet.idAseguradora;
						this.contratoSeleccionada = { id: 0, nombre: ''};
						this.contratoSeleccionada.id = this.informacionGeneralGet.idContrato;
						let nombre :string = null;
						if(this.contratosTemp  != null && this.contratosTemp.contratos != null){
							for (let i = 0; i < this.contratosTemp.contratos.length; i++) {
								let filtroContrato = this.contratosTemp.contratos[i];
								let item: string = String(filtroContrato.numero);							
								if (item.toLowerCase().includes(this.informacionGeneralGet.numeroContrato.toLowerCase())) {
									nombre = String(filtroContrato.numero) + " - " + filtroContrato.nombreContratista;
								}
							}
						}							
						this.contratoSeleccionada.nombre = nombre ;
						this.monedaSeleccionada = { id: 0, nombre: ''};

						for (let i = 0; i < this.monedasTemp.monedas.length; i++) {
							let filtro = this.monedasTemp.monedas[i];
							let item: string = String(filtro.id);							
							if (item.toLowerCase().includes(this.informacionGeneralGet.idMoneda.toLowerCase())) {
								this.monedaSeleccionada.id = this.informacionGeneralGet.idMoneda;
								this.monedaSeleccionada.nombre = this.informacionGeneralGet.moneda;
								break;
							}
						}
						
						this.tomador = this.informacionGeneralGet.tomador;
						
					} else {
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: this.resultadoInfo.respuestaServicio.mensajeSalida,
							life: 10000
						});
					}
				} else {}
			},error => {
				this.loading = false;
				this.principalComponent.cargarErrorServicio(error);
			  },
			() => {
				this.loading = false;
			}
		);
	}

	/**
	 * Metodo que actualiza el tomador de la poliza
	 */
	 onChangeContrato(){
		if( this.contratoSeleccionada.id != 0){
			this.tomador = this.contratoSeleccionada.nombreTipoContrato +" - "+this.contratoSeleccionada.nombreContratista;
		}
	}

	/**
	 * Metodo que actualiza el nit de la aseguradora
	 */
	onChangeAseguradora(){
		this.informacionGeneral.nitAseguradora = this.aseguradoraSeleccionada.nit;
	}

	/**
	 * Metodo que actualiza la moneda
	 */
	onChangeMoneda(){
		this.moneda = this.monedaSeleccionada.id;
	}


	/**
	 * Metodo que hace la transformacion de amparos
	 * @param amparos 
	 */
	transformarAmparos(amparos :any[]){
		this.listaAmparos = [];
		amparos.forEach(element => {
			this.amparo = new Amparo();
			this.amparo.descripcion = element.descripcion;
			this.amparo.fechaFinCobertura = element.fechaFinCobertura;
			this.amparo.fechaFinCoberturaStr = new Date(element.fechaFinCobertura).toLocaleDateString();
			this.amparo.fechaInicioCoberturaStr = new Date(element.fechaInicioCobertura).toLocaleDateString();
			this.amparo.fechaInicioCobertura = element.fechaInicioCobertura;
			this.amparo.id = element.id;
			this.amparo.idAnexo = element.idAnexo;
			this.amparo.idTipoAmparo = element.idTipoAmparo;
			this.amparo.tipoAmparo = element.tipoAmparo;
			this.amparo.valorAmparo = element.valorAmparo;
			this.amparo.valorAmparoStr = this.moneda +" "+this.formatearNumero(element.valorAmparo);
			this.listaAmparos.push(this.amparo);
		  });

	}

	/**
	 * Metodo que hace la transformacion 
	 * @param anexos 
	 */
	transformarAnexos(anexos :any[]){
		this.listaAnexos = [];
		anexos.forEach(element => {
			this.anexos = new Anexos();
			this.anexos.fechaExpedicion = element.fechaExpedicion;
			this.anexos.fechaExpedicionStr = new Date(element.fechaExpedicion).toLocaleDateString();
			this.anexos.habilitarEditar = element.habilitarEditar;
			this.anexos.habilitarEliminar = element.habilitarEliminar;
			this.anexos.id = element.id;
			this.anexos.idMonedaPoliza = element.idMonedaPoliza;
			this.anexos.idNovedadContrato = element.idNovedadContrato;
			this.anexos.idPoliza = element.idPoliza;
			this.anexos.monedaPoliza = element.monedaPoliza;
			this.anexos.novedadContrato = element.novedadContrato;
			this.anexos.numero = element.numero;
			this.anexos.objeto = element.objeto;
			this.anexos.observacion = element.observacion;
			this.anexos.orden = element.orden;
			this.anexos.amparos = element.amparos;
			this.listaAnexos.push(this.anexos);
		  });

	}


	/**
	 * Metodo que hace la transformacion de valor numerico formateo
	 * @param nStr 
	 */
	formatearNumero(nStr:any) {
		nStr += '';
		let x = nStr.split('.');
		let x1 = x[0];
		let x2 = x.length > 1 ? ',' + x[1] : '';
		var rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + '.' + '$2');
		}
		return x1 + x2;
	}

	/**
	 * Metodo que hace la transformacion de Personas
	 * @param amparos 
	 */
	 transformarPersonas(personas :any[]){
		this.listaPersonas = [];
		personas.forEach(element => {
			this.persona = new Persona();
			let nombre :string = null;
			for (let i = 0; i < this.tipoPersonasTemp.tipoPersonaPoliza.length; i++) {
				let filtro = this.tipoPersonasTemp.tipoPersonaPoliza[i];
				let item: string = String(filtro.id);	
				if (item.toLowerCase().includes(element.tipo.toLowerCase())) {
					nombre = String(filtro.nombre) ;
				}
			}
			this.persona.id = element.id;
			this.persona.idPersona = element.idPersona;
			this.persona.idPersonaJuridica = element.idPersonaJuridica;
			this.persona.idPoliza = element.idPoliza;
			this.persona.digitoVerificacion = element.digitoVerificacion;
			this.persona.idTipoDocumento = element.idTipoDocumento;
			this.persona.nombre = element.nombre;
			this.persona.numeroDocumento = element.numeroDocumento;
			this.persona.tipo = element.tipo;
			this.persona.tipoNombre = nombre;
			this.listaPersonas.push(this.persona);
		  });

	}

	

	/**
	 * Metodo que realiza el guardado de la informacion
	 */
	guardarInformacionGeneral() {
		this.loading = true;
		this.enviarInfo = new InformacionGeneralEnvio();
		this.enviarInfoEdit = new InformacionGeneralEnvioEdit();
		this.listaAnexosEnvio = [];
		this.aseguradosBeneficiarios = [];
		this.amparos = [];
		this.anexo = new Anexo();

		var reg = new RegExp('^[a-zA-Z0-9_-]*$');
		
		if(!reg.test(this.informacionGeneral.numero)){
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: "Debe ingresar el Número de póliza sin caracteres especiales, solo se acepta guion al piso ( _ ) y guion al centro ( - ) ",
				life: 10000
			});
			this.loading = false;
			return ;
		}


		if(this.informacionGeneral.fechaExpedicion== null){
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: "Debe ingresar la Fecha de expedición ",
				life: 10000
			});
			this.loading = false;
			return ;
		}

		this.anexo.fechaExpedicion  = new Date(this.informacionGeneral.fechaExpedicion);
		this.anexo.objeto  = this.informacionGeneral.objetoSeguro;
		
		this.listaAmparos.forEach(element => {
			this.amparoEnvio = new Amparos();
			this.amparoEnvio.descripcion  = element.descripcion;
			this.amparoEnvio.idTipoAmparo  = element.idTipoAmparo;
			this.amparoEnvio.valorAmparo  = element.valorAmparo;
			this.amparoEnvio.fechaInicioCobertura  = new Date(element.fechaInicioCobertura);
			this.amparoEnvio.fechaFinCobertura  = new Date(element.fechaFinCobertura);
			this.amparos.push(this.amparoEnvio);
		});
		this.anexo.amparos  = this.amparos;
		
		this.listaPersonas.forEach(element => {
			this.aseguradoEnvio = new AseguradosBeneficiarios();
			this.aseguradoEnvio.idPersona = element.idPersona;
			this.aseguradoEnvio.idPersonaJuridica = element.idPersonaJuridica;
			this.aseguradoEnvio.tipo = element.tipo;
			this.aseguradosBeneficiarios.push(this.aseguradoEnvio);
		});

		if(this.tipoSolicitud=="onNew"){
			this.enviarInfo.idAseguradora  = this.aseguradoraSeleccionada.id;
			this.enviarInfo.idAseguradora  = this.aseguradoraSeleccionada.id;
			this.enviarInfo.idContrato  = this.contratoSeleccionada.id;
			this.enviarInfo.idMoneda  = this.monedaSeleccionada.id;
			this.enviarInfo.numero  = this.informacionGeneral.numero;
			this.enviarInfo.aseguradosBeneficiarios = this.aseguradosBeneficiarios;
			this.listaAnexosEnvio.push(this.anexo);
			this.enviarInfo.anexos = this.listaAnexosEnvio;
		}else{
			this.enviarInfoEdit.id  = this.informacionGeneral.id;
			this.enviarInfoEdit.idAseguradora  = this.aseguradoraSeleccionada.id;
			this.enviarInfoEdit.idContrato  = this.contratoSeleccionada.id;
			this.enviarInfoEdit.idMoneda  = this.monedaSeleccionada.id;
			this.enviarInfoEdit.numero  = this.informacionGeneral.numero;
			this.enviarInfoEdit.aseguradosBeneficiarios = this.aseguradosBeneficiarios;
			this.listaAnexosEnvio.push(this.anexo);
			this.enviarInfoEdit.anexos = this.listaAnexosEnvio;
		}

		forkJoin(
			this.polizaEditService.guardarInformacionGeneral((this.tipoSolicitud=="onEdit")? this.enviarInfoEdit :  this.enviarInfo)
		).subscribe(([result]) => {
				if (result != null && result.respuestaServicio != null) {
					if (result.respuestaServicio.codigoSalida === 1) {
						this.loading = false;
						this.visibleDialogoExito = true;
					} else {
						this.loading = false;
						this.messageService.add({
							severity: 'error',
							summary: 'Error',
							detail: result.respuestaServicio.mensajeSalida,
							life: 10000
						});
					}
				} else {}
			},error => {
				this.loading = false;
				this.principalComponent.cargarErrorServicio(error);
			  },() => {
				this.loading = false;
			}
		);
	}

	/**
	 * Metodo que visualiza el Agregar asegurado o beneficiario modal
	 */
	visibleAgregarAsegurado() {
		this.tipoPersonaSeleccionada = { id: 0, nombre: ''};
		this.tipoDocumentoSeleccionada = { id: 0, nombre: ''};
		this.personaSeleccionada = { id: 0, nombre: ''};
		if(this.idPoliza != "new"){
			this.cargarSoloTipoAmparo(this.idPoliza);
			this.cargarTipoAmparo(this.idPoliza);
		}else{
			this.cargarSoloTipoAmparo(null);
			this.cargarTipoAmparo(null);
		}
		
		this.cargarSoloTipoDocumento();
		this.cargarSoloTipoPersona();
		
		this.cargarTipoPersona();		
		this.cargarTipoDocumento();
		this.visibleDialogoAgregarAsegurado = true;
		this.informacionGeneral.habilitarAseguradoNombre = true;
	}

	/**
	 * Metodo que visualiza el Agregar amparo modal
	 */
	 visibleAgregarAmparo() {	
		if(this.monedaSeleccionada != undefined && this.monedaSeleccionada.id != undefined && this.monedaSeleccionada.id != 0){
			this.moneda = this.monedaSeleccionada.id+" ";
			this.tipoAmparoSeleccionada = { id: 0, nombre: ''};
			if(this.idPoliza != "new"){
				this.cargarSoloTipoAmparo(this.idPoliza);
				this.cargarTipoAmparo(this.idPoliza);
			}else{
				this.cargarSoloTipoAmparo(null);
				this.cargarTipoAmparo(null);
			}
			this.amparoNew = new Amparo();		
			this.visibleDialogoAgregarAmparo = true;
		}else{
			this.moneda = "";
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: "Debe seleccionar un tipo de moneda",
				life: 10000
			});
		}		
	}

	/**
	 * Metodo que cancela el Agregar asegurado o beneficiario modal
	 */
	cancelarAgregarAsegurado() {
		this.persona = new Persona();
		this.tipoPersonaSeleccionada = { id: 0, nombre: ''};
		this.tipoDocumentoSeleccionada = { id: 0, nombre: ''};
		this.personaSeleccionada = { id: 0, nombre: ''};
		this.visibleDialogoAgregarAsegurado = false;
	}

	/**
	 * Metodo que cancela el Agregar amparo modal
	 */
	cancelarAgregarAmparo(){
		this.amparoNew = new Amparo();
		this.visibleDialogoAgregarAmparo = false;
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};	
	}

	/**
	 * Metodo que cancela la creacion de la poliza
	 */
	cancelarPoliza(){
		//this.ruta.navigate(['/carreteros/infGeneral/proyectoHome/'+this.idProyecto+'/polizas', this.idProyecto]);
		this.location.back();
	}

	/**
	 * Metodo que visualiza el modal del guardado exitoso
	 */
	aceptarDialogoExito() {
		this.visibleDialogoExito = false;
		//this.ruta.navigate(['/carreteros/infGeneral/proyectoHome/'+this.idProyecto+'/polizas', this.idProyecto]);
		this.location.back();
	  }

	/**
	 * Metodo que guarda el Agregar asegurado o beneficiario modal
	 */
	guardarAsegurado(forma: NgForm){
		this.persona = new Persona();
		if(this.personaSeleccionada != undefined && this.personaSeleccionada.id == undefined && this.personaSeleccionada.nombre == undefined){
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: "Se debe seleccionar una persona",
				life: 10000
			});
		}else{

			if(this.personaSeleccionada != undefined && this.personaSeleccionada.id == 0 && this.personaSeleccionada.nombre == ""){
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: "Se debe seleccionar una persona",
					life: 10000
				});
			}else{
				if(this.persona.id== undefined){
					this.persona.id = this.generarUid();
				}else{
					this.persona.id = this.personaSeleccionada.id;
				}
				this.persona.idTipoDocumento = this.personaSeleccionada.idTipoDocumento;
				this.persona.idPersona = this.personaSeleccionada.idPersona;
				this.persona.idPersonaJuridica = this.personaSeleccionada.idPersonaJuridica;
				this.persona.nombre	 = this.personaSeleccionada.nombre;
				this.persona.numeroDocumento = this.personaSeleccionada.numeroDocumento;
				this.persona.tipo = this.tipoPersonaSeleccionada.id;
				this.persona.tipoNombre = this.tipoPersonaSeleccionada.nombre;
				this.listaPersonas.push(this.persona);
				forma.reset();
	
				this.tipoPersonaSeleccionada = { id: 0, nombre: ''};
				this.tipoDocumentoSeleccionada = { id: 0, nombre: ''};
				this.personaSeleccionada = { id: 0, nombre: ''};
	
				this.tipoAmparoSeleccionada = { id: 0, nombre: ''};		
				this.informacionGeneral.habilitarAseguradoNombre = false;
				this.visibleDialogoAgregarAsegurado = false;
			}
		}
		
	}

	/**
	 * Metodo que guarda el Agregar Amparo modal
	 */
	 guardarAmparo(forma: NgForm){
		this.visibleDialogoAgregarAmparo = false;
		this.amparo = new Amparo();
		if(this.amparoNew.id== undefined){
			this.amparoNew.id = this.generarUid();
		}
		this.amparo.valorAmparo = this.amparoNew.valorAmparo;
		this.amparo.idTipoAmparo = this.tipoAmparoSeleccionada.id;
		this.amparo.tipoAmparo = this.tipoAmparoSeleccionada.nombre;
		this.amparo.descripcion = this.amparoNew.descripcion;
		this.amparo.fechaFinCobertura	 = new Date(this.amparoNew.fechaFinCobertura);
		this.amparo.fechaInicioCobertura = new Date(this.amparoNew.fechaInicioCobertura);
		this.amparo.fechaFinCoberturaStr	 = new Date(this.amparoNew.fechaFinCobertura).toLocaleDateString();
		this.amparo.fechaInicioCoberturaStr = new Date(this.amparoNew.fechaInicioCobertura).toLocaleDateString();
		this.amparo.valorAmparoStr = this.moneda +" "+this.formatearNumero(this.amparoNew.valorAmparo);
		this.listaAmparos.push(this.amparo);
		forma.reset();
		this.tipoAmparoSeleccionada = { id: 0, nombre: ''};
	}

	/**
	 * Metodo que valida la informacion
	 */
	validarTipoDocumento(){
		this.informacionGeneral.habilitarAseguradoNombre = false;
		this.personaSeleccionada = { id: 0, nombre: ''};
	}

	/**
	 * Metodo qye acepta el diagolo exitoso
	 */
	aceptarDialogoExitoProyecto() {
		this.visibleDialogoExito = false;
	}

	/**
	 * Metodo que elimina un registro en la lista de personas
	 * @param persona 
	 */
	onDeletePersona(persona){
		let i = 0;
		this.personaEliminar = persona;
		this.listaPersonas.forEach(element => {
			if(element.id===this.personaEliminar.id){
			   this.listaPersonas.splice(i, 1);
			}
		   i++;
		});
	}

	/**
	 * Metodo que elimina un registro en la lista de amparos
	 * @param amparo 
	 */
	 onDeleteAmparo(amparo){
		 let i = 0;
		 this.listaAmparos.forEach(element => {
			 if(element.id===amparo.id){
				this.listaAmparos.splice(i, 1);
			 }
			i++;
		 });
	}

	/**
	* Metodo que genera un identificador cuando el id es null
	*/  
	generarUid(){
		return Math.floor(Math.random() * 999999);
   	}

	/**
	 * Metodo que captura el evento del teclado solo alfanumerico
	 * @param evento 
	 */
	soloAlfanumericos(evento){

		if(evento.shiftKey || evento.ctrlKey || evento.altKey || evento.code =="BracketLeft"){
			return false;
		}

		if(evento.code == "Backspace" 
		|| evento.code == "Enter"
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"){
			return true;
		}
		
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || evento.keyCode >= 65 &&  evento.keyCode <= 90 ||
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}
	}	

	/**
	 * Metodo que captura el evento del teclado solo numeros 0-9
	 * @param evento 
	 */
	soloNumeros(evento){
		if(evento.code == "Backspace" 
		|| evento.code == "Enter" 
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"
		|| evento.ctrlKey == true){
				return true;
		}
		
		if(evento.ctrlKey == true && evento.keyCode == 67){
		  return true;
		}
		if(evento.ctrlKey == true && evento.keyCode == 86){
		  return true;
		}
	
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || 
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}

	}	   
	
	/**
	 * Metodo que captura el evento del teclado solo numeros 0-9 y , .
	 * @param evento 
	 */
	 soloNumerosDecimal(evento){
		if(evento.code == "Backspace" 
		|| evento.code == "Enter" 
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"
		|| evento.code == "Comma"
		|| evento.key == "." ){
			return true;
		}
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || 
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}
	}

	/**
	 * Metodo que carga el amparo
	 * @param anexo 
	 */
	onViewAmparo(anexo){
		localStorage.setItem('tipoSolicitud', "onViewAmparo");
		
		let str = this.ruta.url, i = 0, nuevaUrl = "";
		const myArr = str.split("/");
		for(i=0;i<myArr.length-2;i++){
			if(myArr[i]!= "" ){
				if(nuevaUrl == "" ) {
					nuevaUrl = "/"+myArr[i];
				}else{
					nuevaUrl = nuevaUrl +"/"+myArr[i];
				}
			}
        }
		
		this.ruta.navigate([nuevaUrl+'/anexos/'+this.idPoliza+'/anexoEdit', anexo.id]);
		//this.ruta.navigate(['/carreteros/infGeneral/proyectoHome/'+this.idProyecto+'/polizas/'+this.idProyecto+'/anexos/'+this.idPoliza+'/anexoEdit', anexo.id]);
	}

	/**
	 * Metodo que carga la lista de los contratos
	 */
	cargarContratos() {
		this.loading = true;
		this.filtroContratos = [];
		return this.polizaEditService.getContratosPorProyecto(this.idProyecto).subscribe((data: {}) => {
			this.contratos = data;
			let existeFiltro: boolean;
			for (let i = 0; i < this.contratos.contratos.length; i++) {
				let filtroContrato = this.contratos.contratos[i];
				let item: string = String(filtroContrato.numero);
				let nombre :string = String(filtroContrato.numero) + " - " + filtroContrato.nombreContratista;
				filtroContrato.nombre = nombre;
				this.filtroContratos.push(filtroContrato);
				existeFiltro = true;
				
			}
			if (existeFiltro) {
				this.contratos = this.filtroContratos;
			}
			this.loading = false;
		},error => {
			this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	/**
	 * Metodo que carga los contratos
	 */
	cargarSoloContratos() {
		return this.polizaEditService.getContratosPorProyecto(this.idProyecto).subscribe((data: {}) => {
			this.contratosTemp = data;		
			if(this.idPoliza == "new"){
				this.contratoSeleccionada = { id: 0, nombre: 'Seleccione...'};				
			}	
		},error => {
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	
	/**
	 * Metodo que carga la lista de las aseguradoras
	 */
	cargarAseguradoras() {
		this.loading = true;
		this.filtroAseguradoras = [];
		return this.parametricasService.getCtoAseguradoraActivas().subscribe((data: {}) => {
			this.aseguradoras = data;
			let existeFiltro: boolean;
			for (let i = 0; i < this.aseguradoras.aseguradoras.length; i++) {
				let filtro = this.aseguradoras.aseguradoras[i];
				this.filtroAseguradoras.push(filtro);
				existeFiltro = true;				
			}
			if (existeFiltro) {
				this.aseguradoras = this.filtroAseguradoras;
			}
			this.loading = false;
		},error => {
			this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	/**
	 * Metodo que carga la lista de las aseguradoras
	 */
	 cargarSoloAseguradoras() {
		return this.parametricasService.getCtoAseguradoraActivas().subscribe((data: {}) => {
			this.aseguradorasTemp = data;
			if(this.idPoliza == "new"){
				this.aseguradoraSeleccionada = { id: 0, nombre: 'Seleccione...', nitAseguradora :'Seleccione una aseguradora'};
			}
		},error => {
			this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	/**
	 * Metodo que carga el filtro de las aseguradoras
	 * @param event 
	 */
	cargarFiltroAseguradoras(event) {
		this.loading = true;
		this.filtroAseguradoras = [];
		for (let i = 0; i < this.aseguradoras.aseguradoras.length; i++) {
			let filtro = this.aseguradoras.aseguradoras[i];
			let item: string = String(filtro.nombre);
			if (item.toLowerCase().includes(event.query.toLowerCase())) {
				this.filtroAseguradoras.push(filtro);
			}
		}
		this.loading = false;
	}

	/**
	 * Metodo que carga la lista de las monedas
	 */
	cargarMonedas() {
		this.loading = true;
		this.filtroMonedas = [];
		return this.parametricasService.getTrnMoneda().subscribe((data: {}) => {
			this.monedas = data;
			let existeFiltro: boolean;
			for (let i = 0; i < this.monedas.monedas.length; i++) {
				let filtro = this.monedas.monedas[i];
				let item: string = String(filtro.nombre);
				this.filtroMonedas.push(filtro);
				existeFiltro = true;
			}
			if (existeFiltro) {
				this.monedas = this.filtroMonedas;
			}
			this.loading = false;
		},error => {
			this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	/**
	 * Metodo que carga la lista de las monedas
	 */
	 cargarSoloMonedas() {
		return this.parametricasService.getTrnMoneda().subscribe((data: {}) => {
			this.monedasTemp = data;
			if(this.idPoliza == "new"){
				this.monedaSeleccionada = { id: 0, nombre: 'Seleccione...'};
			}
		},error => {
            this.principalComponent.cargarErrorServicio(error);
          });
	}
		

	/**
	 * Metodo que carga el filtro de las monedas
	 * @param event 
	 */
	cargarFiltroMonedas(event) {
		this.loading = true;
		this.filtroMonedas = [];
		for (let i = 0; i < this.monedas.monedas.length; i++) {
			let filtro = this.monedas.monedas[i];
			let item: string = String(filtro.nombre);
			if (item.toLowerCase().includes(event.query.toLowerCase())) {
				this.filtroMonedas.push(filtro);
			}
		}
		this.loading = false;
	}

	/**
	 * Metodo que carga la lista de los tipos de persona (asegurado o beneficiario)
	 */
	cargarTipoPersona() {
		this.loading = true;
		this.filtroTipoPersonas = [];
		return this.parametricasService.getPlzMaTipoPersonaPoliza().subscribe((data: {}) => {
			this.tipoPersonas = data;
			let existeFiltro: boolean;
			for (let i = 0; i < this.tipoPersonas.tipoPersonaPoliza.length; i++) {
				let filtro = this.tipoPersonas.tipoPersonaPoliza[i];
				this.filtroTipoPersonas.push(filtro);
				existeFiltro = true;
			}
			if (existeFiltro) {
				this.tipoPersonas = this.filtroTipoPersonas;
			}
			this.loading = false;
		},error => {
			this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		  });
	}

	/**
	 * Metodo que carga la lista de los tipos de persona (asegurado o beneficiario)
	 */
	 cargarSoloTipoPersona() {
		return this.parametricasService.getPlzMaTipoPersonaPoliza().subscribe((data: {}) => {
			this.tipoPersonasTemp = data;
			this.tipoPersonaSeleccionada = { id: 0, nombre: 'Seleccione...'};
		},error => {
			this.principalComponent.cargarErrorServicio(error);
		  });
	}

	/**
	 * Metodo que carga el filtro de los tipos de personas
	 * @param event 
	 */
	cargarFiltroTipoPersonas(event) {
		this.loading = true;
		this.filtroTipoPersonas = [];
		for (let i = 0; i < this.tipoPersonas.tipoPersonaPoliza.length; i++) {
			let filtro = this.tipoPersonas.tipoPersonaPoliza[i];
			let item: string = String(filtro.nombre);
			if (item.toLowerCase().includes(event.query.toLowerCase())) {
				this.filtroTipoPersonas.push(filtro);
			}
		}
		this.loading = false;
	}

	/**
	 * Metodo que carga la lista de las personas de acuerdo a la cedula
	 * @param event 
	 */
	cargarPersonas(event) {
		this.loading = true;
		this.filtroPersonas = [];		
		return this.parametricasService.getTrnPersonasAll(this.tipoDocumentoSeleccionada.id).subscribe((data: {}) => {
			this.personas = data;
			let existeFiltro: boolean;
			for (let i = 0; i < this.personas.personas.length; i++) {
				let filtro = this.personas.personas[i];
				let item: string = String(filtro.nombre);
				if (item.toLowerCase().includes(event.query.toLowerCase())) {
					this.filtroPersonas.push(filtro);
					existeFiltro = true;
				}
			}
			if (existeFiltro) {
				this.personas = this.filtroPersonas;
			}
			this.loading = false;
		},error => {
			this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		  });
	}

	/**
	 * Metodo que carga el filtro de las personas
	 * @param event 
	 */
	cargarFiltroPersonas(event) {
		this.filtroPersonas = [];
		for (let i = 0; i < this.personas.personas.length; i++) {
			let filtro = this.personas.personas[i];
			let item: string = String(filtro.nombre);
			if (item.toLowerCase().includes(event.query.toLowerCase())) {
				this.filtroPersonas.push(filtro);
			}
		}
	}

	/**
	 * Metodo que carga la lista de los tipos de documentos
	 */
	cargarTipoDocumento() {
		this.loading = true;
		this.filtroTipoDocumentos = [];
		return this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.CC + ',' + EnumTipoDocumento.NIT + ',' + EnumTipoDocumento.NIEE).subscribe((data: {}) => {
			this.tipoDocumentos = data;
			let existeFiltro: boolean;
			for (let i = 0; i < this.tipoDocumentos.length; i++) {
				let filtro = this.tipoDocumentos[i];
				let item: string = String(filtro.nombre);
				this.filtroTipoDocumentos.push(filtro);
				existeFiltro = true;
			}
			if (existeFiltro) {
				this.tipoDocumentos = this.filtroTipoDocumentos;
			}
			this.loading = false;
		},error => {
			this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		  });
	}

	/**
	 * Metodo que carga la lista de los tipos de documentos
	 */
	 cargarSoloTipoDocumento() {
		return this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.CC + ',' + EnumTipoDocumento.NIT + ',' + EnumTipoDocumento.NIEE).subscribe((data: {}) => {
			this.tipoDocumentosTemp = data;
			this.tipoDocumentoSeleccionada = { id: 0, nombre: 'Seleccione...'};
		},error => {
			this.principalComponent.cargarErrorServicio(error);
		  });
	}

	/**
	 * Metodo que carga el filtro de los tipos de docuemntos
	 * @param event 
	 */
	cargarFiltroTipoDocumentos(event) {
		this.filtroTipoDocumentos = [];
		for (let i = 0; i < this.tipoDocumentos.length; i++) {
			let filtro = this.tipoDocumentos[i];
			let item: string = String(filtro.nombre);
			if (item.toLowerCase().includes(event.query.toLowerCase())) {
				this.filtroTipoDocumentos.push(filtro);
			}
		}
	}


	/**
	 * Metodo que carga la lista de los tipos de amparos
	 * @param idPoliza 
	 */
	 cargarTipoAmparo(idPoliza) {
		this.loading = true;
		this.filtroTipoAmparos = [];
		return this.polizaEditService.getPlzMaTipoAmparo(idPoliza).subscribe((data: {}) => {
			this.tipoAmparos = data;
			let existeFiltro: boolean;
			let encontro: boolean = false;
			if(this.listaAmparos.length ==0 ){
				for (let i = 0; i < this.tipoAmparos.tipoAmparos.length; i++) {
					let filtro = this.tipoAmparos.tipoAmparos[i];
					this.filtroTipoAmparos.push(filtro);
					existeFiltro = true;
				}
				if (existeFiltro) {
					this.tipoAmparos = this.filtroTipoAmparos;
				}
			}else{
				for (let i = 0; i < this.tipoAmparos.tipoAmparos.length; i++) {
					encontro = false;
					let filtro = this.tipoAmparos.tipoAmparos[i];
					for (let j = 0; j < this.listaAmparos.length; j++) {
						let filtro2 = this.listaAmparos[j];
						if(filtro.id == filtro2.idTipoAmparo){
							encontro = true;
						}
					}
					if (!encontro) {
						this.filtroTipoAmparos.push(filtro);
						existeFiltro = true;
					}
				}
				if (existeFiltro) {
					this.tipoAmparos = this.filtroTipoAmparos;
				}
			}
			
			this.loading = false;
		},error => {
			this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	/**
	 * Metodo que carga la lista de los tipos de amparos
	 * @param idPoliza 
	 */
	 cargarSoloTipoAmparo(idPoliza) {
		return this.polizaEditService.getPlzMaTipoAmparo(idPoliza).subscribe((data: {}) => {
			this.tipoAmparosTemp = data;
			this.tipoAmparoSeleccionada = { id: 0, nombre: 'Seleccione...'};
		},error => {
			this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
          });
	}

	/**
	 * Metodo que carga el filtro de los tipos de personas
	 * @param event 
	 */
	cargarFiltroTipoAmparos(event) {
		this.loading = true;
		this.filtroTipoAmparos = [];
		for (let i = 0; i < this.tipoAmparos.tipoAmparos.length; i++) {
			let filtro = this.tipoAmparos.tipoAmparos[i];
			let item: string = String(filtro.nombre);
			if (item.toLowerCase().includes(event.query.toLowerCase())) {
				this.filtroTipoAmparos.push(filtro);
			}
		}
		this.loading = false;
	}


}