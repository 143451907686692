import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { AlcanceProyectoBaseComponent } from '../alcance-proyecto-base/alcance-proyecto-base.component';
import { TipoIntervencionCarreteroService } from './tipo-intervencion-carretero.service';
import { forkJoin } from 'rxjs';
import { IntervencionUnidadFuncional } from './tipo-intervencion-carretero.dto';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-tipo-intervencion-carretero2',
  templateUrl: './tipo-intervencion-carretero.component.html',
  styleUrls: ['./tipo-intervencion-carretero.component.sass']
})
export class TipoIntervencionCarreteroComponent2 implements OnInit {

  intervenciones: any = [];
  displayedColumnsIntervencion: any[];
  visibleListaTipoIntervencion: boolean;
  visibleNuevaIntervencionPorUnidadFuncional: boolean;
  visibleDialogoExitoTipoIntervencion: boolean;
  visibleConfirmarEliminar: boolean = false;
  visibleDialogoExitoEliminar: boolean = false;
  totalFactor: number = 0.0;
  blockedDocument: boolean;

  constructor(public lineaBase: AlcanceProyectoBaseComponent, private parametricasService: ParametricasService,
    private tipoIntervencionCarreteroService: TipoIntervencionCarreteroService, private route: ActivatedRoute, private ruta: Router, private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.cargarIntervencionesPorUnidadFuncional(this.lineaBase.unidadFuncional.id);
    this.displayedColumnsIntervencion = [
      { field: 'nombre', header: 'Nombre' },
      { field: 'nombreTipoIntervencion', header: 'Tipo de intervención' },
      { field: 'formatoFactorPonderacion', header: 'Factor de ponderación' }];
  }

  cargarIntervencionesPorUnidadFuncional(idUnidadFuncional) {
    this.totalFactor = 0.0;

    this.blockedDocument = true;
    forkJoin(
      this.tipoIntervencionCarreteroService.getIntervencionesPorUnidadFuncional(idUnidadFuncional, this.lineaBase.idProyecto, this.lineaBase.idConfiguracion, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.intervenciones = result.intervenciones;
          this.intervenciones.forEach(element => {
            this.totalFactor = this.totalFactor + element.factorPonderacion;
          });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  nuevoTipoIntervencionUnidadFuncional() {
    this.lineaBase.intervencion = {};
    this.lineaBase.intervencion.id = 0;
    this.lineaBase.intervencion.idUnidadFuncional = this.lineaBase.unidadFuncional.id;
    this.lineaBase.visibleTiposIntervencionDetalle = true;
    this.lineaBase.visibleTiposIntervencion = false;
    this.lineaBase.tipoIntervencionSeleccionado = null;
    this.lineaBase.intervencionDepartamentos = [];
    this.lineaBase.factorPonderacionIntervencion = 0;
  }

  editarTipoIntervencionUnidadFuncional(intervencionUnidadFuncional: IntervencionUnidadFuncional) {


    this.blockedDocument = true;
    forkJoin(
      this.tipoIntervencionCarreteroService.getIntervencionPorId(intervencionUnidadFuncional.id, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.lineaBase.intervencion = result.intervencion;
          this.lineaBase.factorPonderacionIntervencion = this.lineaBase.intervencion.factorPonderacion * 100;
          this.lineaBase.tipoIntervencionSeleccionado = this.lineaBase.intervencion.idTipoIntervencionNavigation;
          this.lineaBase.visibleTiposIntervencion = false;
          this.lineaBase.visibleTiposIntervencionDetalle = true;
          //this.cargarTiposIntervencionDepartamento(this.lineaBase.intervencion.id);
          //this.lineaBase.intervencion.idUnidadFuncional = this.lineaBase.idUnidadFuncional;
          //this.lineaBase.idIntervencion = this.intervencionUnidadFuncionalSeleccionada.id;

        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarTiposIntervencionDepartamento(idIntervencion) {
    forkJoin(
      this.tipoIntervencionCarreteroService.getListaIntervencionDepartamentoPorIdIntervencion(idIntervencion, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.lineaBase.intervencionDepartamentos = result.intervencionesDepartamento;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irEliminarIntervencion(intervencionUnidadFuncional: IntervencionUnidadFuncional) {
    /* this.intervencionUnidadFuncionalSeleccionada = intervencionUnidadFuncional;
    this.visibleConfirmarEliminar = true;
    this.lineaBase.idIntervencion = this.intervencionUnidadFuncionalSeleccionada.id;
  }

  cancelarEliminarIntervencion() {
    this.visibleConfirmarEliminar = false;
  }

  aceptarEliminarIntervencion() {
    /* return this.tipoIntervencionCarreteroDetalleService.eliminarIntervencionPorUnidadFuncional(this.lineaBase.idIntervencion).subscribe((data: {}) => {
      this.visibleConfirmarEliminar = false;
      this.cargarIntervencionesPorUnidadFuncional(this.lineaBase.idUnidadFuncional);
      this.visibleDialogoExitoEliminar = true;
    }); */
    /* return this.tipoIntervencionCarreteroDetalleService.inactivarIntervencionUnidadFuncional(this.lineaBase.idIntervencion).subscribe((data: {}) => {
      this.visibleConfirmarEliminar = false;
      this.cargarIntervencionesPorUnidadFuncional(this.lineaBase.idUnidadFuncional);
      this.visibleDialogoExitoEliminar = true;
    }); */
  }

  aceptarDialogoEliminarIntervencion() {
    this.visibleDialogoExitoEliminar = false;
  }

  cancelarAgregarTipoIntervencion() {
    this.lineaBase.visibleTiposIntervencion = false;
    this.lineaBase.visibleUnidadFuncional = true;
    this.lineaBase.intervencion = {};
  }

}
