import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { InformeRevisionService } from '../../../informe-revision.service';
import { InformeCxpBaseComponent } from '../informe-cxp-base/informe-cxp-base.component';
import { EnumRespuestaServicio } from '../../../../../comun/constantes/constantes-comunes';

@Component({
  selector: 'app-informe-cxp-obligacion-pago',
  templateUrl: './informe-cxp-obligacion-pago.component.html',
  styles: []
})
export class InformeCxpObligacionPagoComponent implements OnInit {

  visibleDialogoSubirCertificadoObligacionPago = false;
  visibleDialogoVerAdjuntos = false;
  visibleDialogoVerAdjuntosAnulados = false;
  numeroObligacionPago: string;

  adjuntosComponente: any = [];
  adjuntosCertificadoObligacionPagoAnulada: CtoAdjuntoInformeBorradorDTO[] = [];
  adjuntosCertificadoObligacionPago: CtoAdjuntoInformeBorradorDTO[] = [];

  rutaCloudStorageCertificadoObligacionPago: string;
  certificadoObligacionPagoAnexo: CtoAdjuntoInformeBorradorDTO = null;
  cantidadMaximaAdjuntos = 3;


  constructor(
    private lineaBase: InformeCxpBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {

    this.adjuntosComponente = [];
    this.adjuntosCertificadoObligacionPagoAnulada = [];
    this.adjuntosCertificadoObligacionPago = [];

    this.cargarObligacionPago(this.lineaBase.informeContratista.id, this.lineaBase.informeContratista.idPlanPago);

    this.rutaCloudStorageCertificadoObligacionPago = this.lineaBase.informeContratista.vigencia + '/'
      + this.lineaBase.informeContratista.idContrato + '_' + this.lineaBase.informeContratista.numeroContrato + '/'
      + this.lineaBase.informeContratista.numeroDocumento + '/'
      + this.lineaBase.informeContratista.numeroPago + '/anexos/';
  }

  cargarObligacionPago(idInforme: number, idPlanPago: number) {
    this.lineaBase.blockedDocument = true;
    this.infCtaSupervisionService.ConsultarObligacionPago(idInforme, idPlanPago)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            // this.numeroObligacionPago = result.numeroObligacionPago;
            // if (result.adjuntoObligacionPago != null) {
            //   this.adjuntosCertificadoObligacionPago = result.adjuntoObligacionPago;
            //   this.adjuntosCertificadoObligacionPagoAnulada = result.adjuntoObligacionPago;
            // }
            if (!this.lineaBase.informeContratista.anuladoObligacionPago) {
              this.numeroObligacionPago = result.numeroObligacionPago;
            } else {
              this.numeroObligacionPago = "";
            }

            if (result.adjuntoObligacionPago != null) {
              result.adjuntoObligacionPago.forEach(adj => {
                if (adj.anulado) {
                  this.adjuntosCertificadoObligacionPagoAnulada.push(adj);
                } else {
                  this.adjuntosCertificadoObligacionPago.push(adj);
                }
              });
            }
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  irVerDialogoSubirAdjuntos() {

    if (this.adjuntosCertificadoObligacionPago == null || (this.adjuntosCertificadoObligacionPago != null && this.adjuntosCertificadoObligacionPago.length < this.cantidadMaximaAdjuntos)) {
      this.visibleDialogoSubirCertificadoObligacionPago = true;
      this.adjuntosComponente = [];
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Elimina algun adjunto para continuar. Recuerda que son máximo ' + this.cantidadMaximaAdjuntos + ' adjuntos', life: 10000 });
    }

  }

  irVerDialogoVerAdjuntos() {
    this.visibleDialogoVerAdjuntos = true;
  }

  verDialogoEliminarAdjunto(adj: CtoAdjuntoInformeBorradorDTO) {

    if (adj != null) {
      if (adj.id != null && adj.id != 0) {
        // Adjunto guardado en BD
        return this.infCtaSupervisionService.EliminarAdjuntoInforme(adj.id)
          .subscribe(
            result => {
              if (result != null && result != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.adjuntosCertificadoObligacionPago = this.adjuntosCertificadoObligacionPago.filter(item => item.rutaSoporte != adj.rutaSoporte);
              } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              }
            },
            error => {
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
              this.ngOnInit();
              this.lineaBase.blockedDocument = false;
            }
          );
      } else {
        // Adjunto guardado en memoria
        this.adjuntosCertificadoObligacionPago = this.adjuntosCertificadoObligacionPago.filter(item => item.rutaSoporte != adj.rutaSoporte);
      }
    }
  }

  cancelarDialogoVerAdjunto() {
    this.visibleDialogoVerAdjuntos = false;
    this.adjuntosComponente = [];
  }
  cancelarDialogoVerAdjuntoAnulados() {
    this.visibleDialogoVerAdjuntosAnulados = false;
    this.adjuntosComponente = [];
  }

  aceptarAdjuntoCertificadoObligacionPago() {
    const adjuntosList: CtoAdjuntoInformeBorradorDTO[] = [];
    this.adjuntosComponente.forEach(arc => {
      const adj: CtoAdjuntoInformeBorradorDTO = {};
      adj.idInforme = this.lineaBase.idInformeParam;
      adj.nombreArchivoUsuario = arc.nombre;
      adj.rutaSoporte = arc.nombreCompleto;
      adjuntosList.push(adj);
    });

    const tamanioFinalAdjuntos: number = adjuntosList.length + this.adjuntosCertificadoObligacionPago.length;
    if (tamanioFinalAdjuntos > this.cantidadMaximaAdjuntos) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Solo se permiten ' + this.cantidadMaximaAdjuntos + ' archivos máximo.', life: 10000 });
    } else {
      adjuntosList.forEach(adj => {
        this.adjuntosCertificadoObligacionPago.push(adj);
      });
    }
    this.visibleDialogoSubirCertificadoObligacionPago = false;
  }

  cancelarAdjuntoCertificadoObligacionPago() {
    this.visibleDialogoSubirCertificadoObligacionPago = false;
    this.adjuntosComponente = [];
  }

  validarObligacionPago(): boolean {
    let validacionExitosa = true;

    if (this.numeroObligacionPago == null || this.numeroObligacionPago.length == 0) {
      validacionExitosa = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debes ingresar el número del registro presupuestal de obligación del pago', life: 10000 });
    }
    if (this.adjuntosComponente == null && this.adjuntosComponente.length == 0) {
      validacionExitosa = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debes adjuntar el documento que relaciona el registro presupuestal de obligación del pago para continuar', life: 10000 });
    }

    // if (this.adjuntosComponente != null && this.adjuntosComponente.length > 0) {
    //   // if (this.certificadoObligacionPagoAnexo.idInforme == null || this.certificadoObligacionPagoAnexo.idInforme == 0) {
    //   //   // validacionExitosa = false;
    //   //   // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La obligación del pago no esta asociada al informe', life: 10000 });
    //   //   this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Recuerda adjuntar al menos un documento relacionado al registro presupuesta de obligación para continuar', life: 10000 });
    //   // }
    //   if (this.certificadoObligacionPagoAnexo != null && this.certificadoObligacionPagoAnexo.rutaSoporte == null) {
    //     // validacionExitosa = false;
    //     this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Recuerda adjuntar al menos un documento relacionado al registro presupuesta de obligación para continuar', life: 10000 });
    //   }
    //   if (this.certificadoObligacionPagoAnexo.nombreArchivoUsuario == null && this.certificadoObligacionPagoAnexo.nombreArchivoUsuario.length == 0) {
    //     validacionExitosa = false;
    //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se cargó correctamente el nombreArchivoUsuario', life: 10000 });
    //   }
    // }

    return validacionExitosa;
  }

  GuardarObligacionPago() {
    this.lineaBase.blockedDocument = true;
    if (this.validarObligacionPago()) {
      this.infCtaSupervisionService.GuardarObligacionPago(this.numeroObligacionPago, this.lineaBase.informeContratista.idPlanPago, this.lineaBase.informeContratista.id, this.adjuntosCertificadoObligacionPago)
        .subscribe(
          result => {
            if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
              this.lineaBase.cargarInformeContratista(this.lineaBase.idInformeParam, this.lineaBase.idPersonaAsignacionParam);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.lineaBase.blockedDocument = false;
            this.ngOnInit();
          }
        );
    }
  }

}
