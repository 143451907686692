import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContratoSupervisorService {
    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
        private datepipe: DatePipe) {
        this.END_POINT = environment.baseUrl;
    }

    ConsultarSupervisoresPorContrato(idsContrato: number[], fechaConsulta?: Date): Observable<any> {
        return this.http.post(this.END_POINT + '/CtoContratoSupervisor/ConsultarSupervisoresPorContrato/', idsContrato, this.httpOptions)
            .pipe(
                retry(1));
    }

    ConsultarFuncionariosSupervisoresAgrupadosParaCambioPorContratos(idsContrato: number[]): Observable<any> {
        return this.http.post(this.END_POINT + '/CtoContratoSupervisor/ConsultarFuncionariosSupervisoresAgrupadosParaCambioPorContratos/', idsContrato, this.httpOptions)
            .pipe(
                retry(1));
    }

    GuardarContratosSupervisor(supervisoresContrato: any[]): Observable<any> {
        return this.http.post(this.END_POINT + '/CtoContratoSupervisor/GuardarContratosSupervisor', supervisoresContrato, this.httpOptions)
            .pipe(
                retry(1));
    }

}
