import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { RecaudoPeajesService } from '../../../peajes-gestion/recaudo-peajes/recaudo-peajes.service';
import { PrincipalComponent } from '../../principal/principal.component';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.sass']
})
export class ReportesComponent implements OnInit {
  esProduccion: boolean = false;
  blockedDocument = false;

  constructor(private router: Router, private recaudoPeajesService: RecaudoPeajesService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.esProduccion = environment.esProduccion;
  }

  onNavigate(link) {
    window.open(link, '_blank');
  }

  irAReportesCovid() {
    this.router.navigate([this.router.url + '/reportesCovid']);
  }

  descargarArchivoTraficoYRecaudo() {
    this.blockedDocument = true;
    this.recaudoPeajesService.verAchivoTraficoyRecaudo()
      .subscribe(result => {
        this.recaudoPeajesService.downLoadFile(result, 'application/zip')
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  irAreportesPeajesTarifas() {
    this.router.navigate([this.router.url + '/reportesPeajes']);
  }

}
