import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MedidaCovid, GrupoMedidaCovid, C19ReporteCovid, C19ReporteCovidMedidaAdjunto } from '../../covid-reportar.dto';
import { SelectItem, MessageService } from 'primeng/api';
import { CovidReportarService } from '../../covid-reportar.service';
import { forkJoin } from 'rxjs';
import { TrnSiNo } from 'src/app/parametricas/trn-si-no';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { TrnMaSiNo } from 'src/app/parametricas/trn-ma-si-no';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-covid-reportar-medidas',
  templateUrl: './covid-reportar-medidas.component.html',
  styleUrls: ['./covid-reportar-medidas.component.sass']
})
export class CovidReportarMedidasComponent implements OnInit {

  blockedDocument: boolean = false;

  @Input() reporteCovid: C19ReporteCovid;

  vistaActual: string = "";

  grupoMedidaCovidList: GrupoMedidaCovid[];
  grupoMedidaCovid: GrupoMedidaCovid;

  medidaCovidList: MedidaCovid[];
  medidaCovid: MedidaCovid

  medidaCovidAGuardarList: MedidaCovid[];

  archivos: any = []; //Los archivos que yo suba van a quedar en este arreglo
  archivo: any; //El unico archivo seleccionado
  rowIndex: number;
  visibleDialogoSubirArchivo: Boolean;
  visibleDialogoVerEvidencia: Boolean;

  sinoList: any = [];

  adjuntosMedidad: C19ReporteCovidMedidaAdjunto[] = [];

  evidenciaList: any = [];
  evidenciaListTabla: any = [];

  rowGroupMetadata: any;

  idReporteCovidMedida: number;
  idEvidenciaEliminar: number;
  visibleDialogoEliminarEvidencia: boolean;

  idMedidaEliminar: number;
  visibleDialogoEliminarMedida: boolean;

  constructor(
    private covidReportarService: CovidReportarService,
    private parametricasService: ParametricasService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    //this.consultarFrecuenciaEstimada();
    this.consultarGrupoMedida();
    this.consultarSiNo();
    this.medidaCovidList = [];
    this.vistaActual = 'gruposMedida';
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  onChangeSeEstaCumpliendo(event, covidMedida, rowIndex) {
    covidMedida.idReporte = this.reporteCovid.id;
    covidMedida.idSiNo = event.value.id;
    let vara: any = this.covidReportarService.srvGuardarReporteCovidMedida(covidMedida).subscribe((data: {}) => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'La medida se guardó existosamente.', life: 10000 });
      let covidMedida: any = data;
      this.medidaCovidList[rowIndex].idReporteCovidMedida = covidMedida.covidMedida.id;
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  onChangeObservaciones(covidMedida) {
    let vara: any = this.covidReportarService.srvGuardarReporteCovidMedida(covidMedida).subscribe((data: {}) => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'La medida se guardó existosamente.', life: 10000 });
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }



  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.medidaCovidList) {
      for (let i = 0; i < this.medidaCovidList.length; i++) {
        let rowData = this.medidaCovidList[i];
        let brand = rowData.grupo;
        if (i == 0) {
          this.rowGroupMetadata[brand] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.medidaCovidList[i - 1];
          let previousRowGroup = previousRowData.grupo;
          if (brand === previousRowGroup)
            this.rowGroupMetadata[brand].size++;
          else
            this.rowGroupMetadata[brand] = { index: i, size: 1 };
        }
      }
    }
  }

  rowSelectGrupoMedida(_grupoMedidaCovid: GrupoMedidaCovid) {
    this.grupoMedidaCovid = _grupoMedidaCovid;
    this.consultarMedidas();
  }


  consultarGrupoMedida() {
    forkJoin(
      this.covidReportarService.srvConsultarGrupoMedida()
    ).subscribe(([result]) => {
      if (result != null) {
        this.grupoMedidaCovidList = result;
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        // this.blockedDocument = false;
      }
    );
  }

  consultarMedidas() {
    //Bloquear pantalla
    this.blockedDocument = true;

    forkJoin(
      this.covidReportarService.srvConsultarMedidas(this.grupoMedidaCovid.id, this.reporteCovid.id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.medidaCovidList = result.reporteCovidMedidas;
          //Cargar el objeto del dropdown
          for (const medidaVar of this.medidaCovidList) {
            medidaVar.idSiNoObj = this.sinoList.find(t => t.id === medidaVar.idSiNo);
          }
          this.vistaActual = 'medidas';
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  consultarSiNo() {
    return this.parametricasService.getTrnMaSiNo().subscribe((data: {}) => {
      this.sinoList = data;
    });
  }

  irAGruposDeMedidas() {
    this.vistaActual = 'gruposMedida';
  }

  irVerDialogoSubirArchivo(_medidaVar, _rowIndex) {
    this.rowIndex = _rowIndex;
    this.medidaCovid = _medidaVar;
    this.visibleDialogoSubirArchivo = true;
  }

  cancelarDialogoSubirArchivo() {
    this.visibleDialogoSubirArchivo = false;
    this.archivos = [];
  }

  aceptarAdjuntoEnvidencia() {
    let idReporteMedida = 0;
    this.archivos.forEach(element => {
      if (element.estado == "OK") {
        let adjuntoMedida: C19ReporteCovidMedidaAdjunto = {};
        idReporteMedida = this.medidaCovidList[this.rowIndex].idReporteCovidMedida;
        adjuntoMedida.idReporteCovidMedida = idReporteMedida;
        adjuntoMedida.rutaSoporte = element.nombreCompleto;
        this.adjuntosMedidad.push(adjuntoMedida);
      }
    });
    this.visibleDialogoSubirArchivo = false;
    this.archivos = [];
    let vara: any = this.covidReportarService.srvGuardarReporteCovidMedidaAdjunto(idReporteMedida, this.adjuntosMedidad).subscribe((data: {}) => {
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'La medida se guardó existosamente.', life: 10000 });
      this.adjuntosMedidad = [];
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  irVerDialogoVerEvidencia(_medidaVar) {
    this.visibleDialogoVerEvidencia = true;
    this.idReporteCovidMedida = _medidaVar.idReporteCovidMedida;
    this.cargaEvidenciasPorMedida(this.idReporteCovidMedida);
  }

  cargaEvidenciasPorMedida(id) {
    this.evidenciaListTabla = [];
    return this.covidReportarService.getReporteCovidMedidaAdjuntoPorIdMedida(id).subscribe((data: {}) => {
      this.evidenciaList = data;
      let contador: number = 1;
      this.evidenciaList.adjuntos.forEach(element => {
        let evidencia: C19ReporteCovidMedidaAdjunto = {};
        evidencia.id = element.id;
        evidencia.nombre = "Evidencia " + contador;
        evidencia.rutaSoporte = element.rutaSoporte;
        this.evidenciaListTabla.push(evidencia);
        contador++;
      });
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cancelarDialogoVerEvidencia() {
    this.visibleDialogoVerEvidencia = false;
  }

  verDialogoEliminarEvidencia(_evidencia) {
    this.idEvidenciaEliminar = _evidencia.id;
    this.visibleDialogoEliminarEvidencia = true;
  }

  aceptarEliminarEvidencia() {
    return this.covidReportarService.eliminarReporteCovidMedidaAdjunto(this.idEvidenciaEliminar).subscribe((data: {}) => {
      this.cargaEvidenciasPorMedida(this.idReporteCovidMedida);
      this.visibleDialogoEliminarEvidencia = false;
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'La evidenca se eliminó existosamente.', life: 10000 });
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cancelarEliminarEvidencia() {
    this.visibleDialogoEliminarEvidencia = false;
  }

  verDialogoEliminarMedida(_medida) {
    this.idMedidaEliminar = _medida.idReporteCovidMedida;
    this.visibleDialogoEliminarMedida = true;
  }

  aceptarEliminarMedida() {
    return this.covidReportarService.eliminarReporteCovidMedida(this.idMedidaEliminar).subscribe((data: {}) => {
      this.consultarMedidas();
      this.visibleDialogoEliminarMedida = false;
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'La medida se eliminó existosamente.', life: 10000 });
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cancelarEliminarMedida() {
    this.visibleDialogoEliminarMedida = false;
  }

  guardarMedidasReporte(form: NgForm) {
    //Bloquear pantalla
    this.blockedDocument = true;

    this.medidaCovidAGuardarList = [];

    for (const medidaVar of this.medidaCovidList) {
      medidaVar.idReporte = this.reporteCovid.id;
      //Objener el valor del dropdown
      if (medidaVar.idSiNoObj != null) {
        medidaVar.idSiNo = medidaVar.idSiNoObj.id;
      }

      if (medidaVar.idSiNo != null) {
        this.medidaCovidAGuardarList.push(medidaVar);
      }
    }


    forkJoin(
      this.covidReportarService.srvGuardarReporteCovidMedidas(this.medidaCovidAGuardarList, this.reporteCovid.id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          //Consultar nuevamente la lista
          this.consultarMedidas();
          //Lanzar mensaje de exito
          this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  validarSiNo(sinoSeleccion, index: number) {
    this.medidaCovidList[index].idSiNo = sinoSeleccion.id;
  }

  ///////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
  }

  lanzarMensajeError(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
  }

  lanzarMensajeWarning(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
