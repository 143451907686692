import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CertificacionRetiradosService } from './certificacion-retirados.service';
import { MatDialog, MatDialogRef  } from '@angular/material/dialog';
import { BusquedaRetirado } from '../../dtos/certificaciones-laborales/certificacion-laboral';
import { ProgressSpinnerDialogComponent } from '../../progress-spinner-dialog/progress-spinner-dialog.component';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';


/**
 * Componente de Certificacion de retrirados
 */
@Component({
  selector: 'app-certificacion-retirados',
  templateUrl: './certificacion-retirados.component.html',
  styleUrls: ['./certificacion-retirados.component.sass'],
  providers: [CertificacionRetiradosService]
})
export class CertificacionRetiradosComponent implements OnInit {
    // Inicializaciones de Variables
    displayedColumns1;
    listaCertificaciones: any = [];
    busqueda: BusquedaRetirado = {};
    tipoCertificado: any = [];
    filtrotipoCertificado: any[];
    tipoCertificadoSeleccionado: any = null;
    numeroIdentificacion: any = null;
    emailSeleccionado: any = null;

    visibleDialogoEnviar: boolean;
    visibleError: boolean;
    message : any;
    evento : any;
    dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent> ;

    constructor(
      private consultaService: CertificacionRetiradosService,
      private route: Router,
      public dialog: MatDialog,
      public location: Location,
      private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.emailSeleccionado = null;
    this.tipoCertificadoSeleccionado = {nombre : '' , valor: ''};
    this.cargarTipoCertificado();
    this.displayedColumns1 = [
      { field: 'nombreEmpleado', header: 'Nombre del Empleado' },
      { field: 'tipoVinculacion', header: 'Tipo Vinculación' },
      { field: 'fechaIngreso', header: 'Fecha Ingreso' },
      { field: 'fechaRetiro', header: 'Fecha de Retiro' }
   ];
  }

  
  /**
   * Metodo que carga la lista de tipo de certificados
   */
  cargarTipoCertificado() {
    return this.consultaService.getTipoCertificacion().subscribe((data: {}) => {
      this.tipoCertificado = data;
    });
  }

  /**
   * Metodo que realiza la busqueda de la información
   */
  onFind() {
    if (this.numeroIdentificacion !== undefined && this.numeroIdentificacion !== null
        && this.numeroIdentificacion !== '') {
          this.busqueda.numeroIdentificacion = this.numeroIdentificacion;
          this.cargarBusqueda(this.busqueda.numeroIdentificacion);
          this.limpiarBusqueda();
    }else{
      this.lanzarMensajeError('Debe diligenciar los campos obligatorios.');
    } 
  }

  /**
    * Metodo cancelar
    */
    onNoClick(): void {
    this.visibleDialogoEnviar = false;
  }

  /**
    * Metodo que valida el email
    * @param email 
    */
  esEmailValido(email: string){
    var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email!= null && !email.match(EMAIL_REGEX)){
      this.lanzarMensajeError('No es un email correcto.');
    }
  }

  /**
   * Metodo que siguiente
   */
  onFollow() {
    this.visibleDialogoEnviar = true;
  }


  // Metodo que carga la busqueda
  cargarBusqueda(objeto) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
    panelClass: 'transparent',
        disableClose: true
    });
    return this.consultaService.getBusqueda(objeto).subscribe((data: {}) => {
        this.responseData(data);
    });
  }


  // Metodo que carga la respuesta
  responseData(payload) {
    if (payload.objeto === null) {
        this.lanzarMensajeError(payload.respuestaServicio.mensajeSalida);
    } else {
        this.listaCertificaciones = payload;
        this.dialogSpin.close();
        if (payload.length === 0) {
          this.lanzarMensajeError('No se encontró información');
        } else {
          this.lanzarMensajeInfo('Se encontraron registros con la búsqueda');
        }
    }
  }


  // Metodo que limpia la busqueda
  limpiarBusqueda() {
    this.tipoCertificadoSeleccionado = {nombre : '' , valor: ''};
    this.emailSeleccionado = null;
    this.numeroIdentificacion = null;
    this.busqueda.numeroIdentificacion = null;
  }

  /**
  * Metodo que realiza el retorno
  */
  onReturn() {
    this.route.navigate(['/home-certificaciones-laborales/']);
  }

  // /////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
  }

  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
  }

  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
