/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { CtoReporteInformeContratistaDTO } from '../../contratacion-dto/reporte-informe-contratista';
import { EnumRegex, EnumRespuestaServicio } from '../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ContratacionService } from '../../contratacion.service';
import { CtoPasoInformeDTO } from '../../contratacion-dto/cto-paso-informe';
import { ReportesCuentasCobroCxpService } from '../reporte-contratacion-cuentas-cobro.service';

@Component({
  selector: 'app-reporte-informes-contratista',
  templateUrl: './reporte-informes-contratista.component.html',
  styles: []
})
export class ReporteInformesContratistaComponent implements OnInit {

  // Informacion del datatable
  reporteInformeContratistasList: CtoReporteInformeContratistaDTO[] = [];
  totalRecords: number;
  loading: boolean;

  // Filtros
  idContratoFiltro?: number;
  numeroDocumentoContratistaFiltro?: string;
  fechaInicioPasoFiltro?: Date;
  fechaFinPasoFiltro?: Date;
  idPersonaRevisaInformeFiltro?: number;
  buscarFiltro: string;
  pasoInformeFiltro?: CtoPasoInformeDTO;

  pasosInformeList: CtoPasoInformeDTO[] = [];

  constructor(
    private messageService: MessageService,
    private reportesCuentasCobroCxpService: ReportesCuentasCobroCxpService,
    private parametricasService: ParametricasService,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarPasosInforme();
  }

  loadDataLazy(event: LazyLoadEvent) {
    this.loading = true;
    this.reporteInformeContratistasList = [];
    this.consultarReporteInformesContratista(event.first, event.rows, event.sortField, event.sortOrder);
  }

  buscar(buscarTodosRegistros: boolean) {
    const filaDesde = 0;
    const cantidadRegistros = 10;
    this.loading = true;
    this.consultarReporteInformesContratista(filaDesde, cantidadRegistros, 'idContrato', 1);
  }

  private consultarReporteInformesContratista(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.reportesCuentasCobroCxpService.srvConsultarReporteInformesContratista(this.idContratoFiltro, this.numeroDocumentoContratistaFiltro,
      this.pasoInformeFiltro, this.fechaInicioPasoFiltro, this.fechaFinPasoFiltro, this.idPersonaRevisaInformeFiltro,
      filaDesde, cantidadRegistros, this.buscarFiltro, campoOrden, ascDesc).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.reporteInformeContratistasList = result.reporteInformesContratista;
              this.totalRecords = this.reporteInformeContratistasList != null && this.reporteInformeContratistasList.length > 0 ? this.reporteInformeContratistasList[0].totalRegistros : 0;
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
          this.loading = false;
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.loading = false;
        }
      );
  }

  limpiarFiltros() {
    this.idContratoFiltro = null;
    this.numeroDocumentoContratistaFiltro = null;
    this.fechaInicioPasoFiltro = null;
    this.fechaFinPasoFiltro = null;
    this.buscarFiltro = null;
    this.pasoInformeFiltro = null;
    this.buscar(false);
  }

  cargarPasosInforme() {
    this.contratacionService.getPasosInforme().subscribe(
      result => {
        if (result != null) {
          this.pasosInformeList = result;
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO, error);
        this.principalComponent.cargarErrorServicio(error);
        this.loading = false;
      },
      () => { }
    );
  }

  get EnumRegex() {
    return EnumRegex;
  }
}