import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TemasGestionService {

  public END_POINT = '';

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public getSubTipo() {
    return this.http.get(this.END_POINT + '/Gestion/subtipo')
      .pipe(
        retry(1));
  }

  public getTipo() {
    return this.http.get(this.END_POINT + '/Gestion/tipo')
      .pipe(
        retry(1));
  }

  public getEtapaActual() {
    return this.http.get(this.END_POINT + '/Gestion/etapas')
      .pipe(
        retry(1));
  }

  public getProyectosAmbientales(id) {
    return this.http.get(this.END_POINT + '/Gestion/list/' + id )
      .pipe(
        retry(1));
  }

  public getSeguimiento(id) {
    return this.http.get(this.END_POINT + '/Gestion/' + id + '/detalle/list' )
      .pipe(
        retry(1));
  }

  public deleteDetalleAmbiental(id) {
    return this.http.delete(this.END_POINT + '/ambiental/' + id )
      .pipe(
        retry(1));
  }

}
