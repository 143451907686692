import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { EnumCtoTipoAnexoInforme } from "src/app/contratacion/constantes/contratacion-constantes";
import { CtoAdjuntoInformeBorradorDTO } from "src/app/contratacion/contratacion-dto/adjunto-informe-borrador";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";
import { RpteCxpBaseComponent } from "../rpte-cxp-base/rpte-cxp-base.component";
import { CtoInformeRevisionAnexoDTO } from "src/app/contratacion/contratacion-dto/informe-revision-anexo";

@Component({
  selector: 'app-rpte-cxp-pago',
  templateUrl: './rpte-cxp-pago.component.html',
  styles: []
})
export class RpteCxpPagoComponent implements OnInit {

  anexosInformeRevision: CtoInformeRevisionAnexoDTO[];
  adjuntosAnexoInforme: CtoAdjuntoInformeBorradorDTO[];
  visibleDialogoVerAdjuntos: boolean;

  constructor(
    private lineaBase: RpteCxpBaseComponent,
    private messageService: MessageService,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
  ) { }

  ngOnInit() {
    this.cargarAnexos(this.lineaBase.idInformeParam);
  }

  cargarAnexos(idInforme: number) {
    this.lineaBase.blockedDocument = true;
    this.reporteCuentaCobroCxpService.srvConsultarAnexosInforme(idInforme, EnumCtoTipoAnexoInforme.SUBTIPO_CUENTAS_REGISTRO, EnumCtoTipoAnexoInforme.CERTIFICADO_PAGO_ANI.toString())
      .subscribe(
        result => {
          // console.log('cargarAnexos');
          // console.log(JSON.stringify(result));
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.anexosInformeRevision = result.anexosInformeRevision;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }


  irVerDialogoVerAdjuntosAnexos(idAnexo: number) {
    this.visibleDialogoVerAdjuntos = true;
    this.cargarAdjuntosPorAnexosAdicionales(idAnexo);
  }

  cargarAdjuntosPorAnexosAdicionales(idAnexo: number) {
    this.reporteCuentaCobroCxpService.srvConsultarAdjuntosPorAnexoInforme(idAnexo)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosAnexoInforme = result.adjuntos;
            } else {
              console.log(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }
}
