import { DatePipe } from "@angular/common";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/config.service';
import { PjePeriodoTraficoRpt } from 'src/app/peajes-gestion/recaudo-peajes/PjePeriodoTrafico';
import { InformacionGeneralCarreterosDTO, InformacionCarreterosDTO, CrrAvanceUfDTO, InformacionAvanceUfDTO, InformacionAvanceProyectoDTO } from "./reportes-carreteros.dto";
import { FncDatosGeneralesFinancierosDTO } from "./reportes-financiero.dto";


@Injectable({
    providedIn: 'root'
})
export class ReportesFinancierosService {
    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
        private datepipe: DatePipe) {

        this.END_POINT = environment.baseUrl;
    }


    public getDatosGeneralesFinancieros(idProyecto) {
        return this.http.get<any>(this.END_POINT + '/RptFichaFinancieroCarretero/datosGeneralesFinancieros/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getVigenciasFuturas(idProyecto) {
        return this.http.get<any>(this.END_POINT + '/RptFichaFinancieroCarretero/vigenciasFuturas/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getContratoPuente(idProyecto) {
        return this.http.get<any>(this.END_POINT + '/RptFichaFinancieroCarretero/contratoPuente/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getDatosGeneralesFinancierosVIx(idProyecto) {
        return this.http.get<any>(this.END_POINT + '/RptFichaFinancieroCarretero/datosGeneralesFinancierosVIx/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getSaldoCuenta(idProyecto) {
        return this.http.get<any>(this.END_POINT + '/RptFichaFinancieroCarretero/saldoCuenta/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getRetribucion(idProyecto) {
        return this.http.get<any>(this.END_POINT + '/RptFichaFinancieroCarretero/retribucion/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getVpip(idProyecto) {
        return this.http.get<any>(this.END_POINT + '/RptFichaFinancieroCarretero/vpip/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getVpipDetallado(idProyecto) {
        return this.http.get<any>(this.END_POINT + '/RptFichaFinancieroCarretero/vpipDetallado/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
    

}