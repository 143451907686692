import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';

import {
  AmbSeguimientoObligacionDTO,
  AmbSubCuentaDTO,
} from '../compensaciones-ambientales.dto';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { CompensacionesAmbientalesService } from '../compensaciones-ambientales.service';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-seguimiento-compensaciones',
  templateUrl: './seguimiento-compensaciones.component.html',
  styleUrls: ['./seguimiento-compensaciones.component.sass'],
})
export class SeguimientoCompensacionesComponent implements OnInit {
  blockedDocument: boolean;
  subscription: Subscription;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  subCuenta: AmbSubCuentaDTO;

  seguimientoObligacion: AmbSeguimientoObligacionDTO;
  seguimientoObligacionList: AmbSeguimientoObligacionDTO[];

  dialogoCompensaciones: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private compensacionesAmbientalesService: CompensacionesAmbientalesService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    // Obtener el proyecto seleccionado
    const idParam = 'id';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
    });

    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.compensacionesAmbientalesService.srvObtenerValorSubcuentaPorProyecto(
        this.idProyecto
      ),
      this.compensacionesAmbientalesService.srvListarSeguimientoObligacionesPorProyecto(
        this.idProyecto
      )
    ).subscribe(
      ([proyectoData, subCuentaData, seguimientoObligacionData]) => {
        this.proyectoSeleccionado = proyectoData;
        this.subCuenta = subCuentaData.valorSubCuenta;
        this.seguimientoObligacionList =
          seguimientoObligacionData.seguiminetoObligaciones;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  btnGestionarAvance(
    seguimientoObligacionSelected: AmbSeguimientoObligacionDTO
  ) {
    this.router.navigate([
      this.router.url +
      '/ejecuciones-obligacion/' +
      this.idProyecto +
      '/' +
      seguimientoObligacionSelected.idObligacion,
    ]);
  }

  btnVerAdjunto(seguimientoObligacionSelected: AmbSeguimientoObligacionDTO) { }

  bntVolver() {
    const ruta =
      'carreteros/infGeneral/proyectoHome/' +
      this.proyectoSeleccionado.id +
      '/compensaciones-ambientales/' +
      this.proyectoSeleccionado.id;
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
