import { Component, OnInit, } from '@angular/core';

@Component({
    selector: 'app-pqrs-home-servicios',
    templateUrl: './pqrs-home-servicios.component.html',
    styleUrls: ['./pqrs-home-servicios.component.css']
})
export class PqrsHomeServiciosComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}

