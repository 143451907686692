import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { IngFranjaHorariaDTO } from '../../puestos-trabajo.dto';
import { PuestosTrabajoService } from '../../puestos-trabajo.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-crear-editar-franjas',
  templateUrl: './crear-editar-franjas.component.html',
  styleUrls: ['./crear-editar-franjas.component.sass']
})
export class CrearEditarFranjasComponent implements OnInit {

  blockedDocument: boolean;
  formFranja;
  franjaHoraria: IngFranjaHorariaDTO = {};
  idFranja: number;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private puestosTrabajoService: PuestosTrabajoService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) {

    // Crear formulario Franja horaria
    this.formFranja = formBuilder.group({
      horaDesde: [null, [Validators.required]],
      horaHasta: [null, [Validators.required]],
      descripcion: [null, [Validators.required]],
    });

  }

  ngOnInit() {
    // Obtener la franja seleccionada
    const idFranja = "idFranja";
    this.ruta.params.subscribe((params) => {
      this.idFranja = params[idFranja];
    });
    console.log("Franja seleccionado " + this.idFranja);
    if (this.idFranja != 0) {
      this.cargarFranja();
    }
  }

  cargarFranja() {
    this.blockedDocument = true;
    forkJoin(
      this.puestosTrabajoService.srvObtenerFranjaPorId(this.idFranja)
    ).subscribe(
      ([franjaData]) => {
        console.log(franjaData);
        this.franjaHoraria = franjaData.franjaHoraria;
        let aHoraDesde: string[] = this.franjaHoraria.horaDesde.split(':');
        let fechaDesde: Date = new Date();
        fechaDesde.setHours(parseInt(aHoraDesde[0]));
        fechaDesde.setMinutes(parseInt(aHoraDesde[1]));
        fechaDesde.setSeconds(parseInt(aHoraDesde[2]));
        this.franjaHoraria.dHoraDesde = fechaDesde;
        let aHoraHasta: string[] = this.franjaHoraria.horaHasta.split(':');
        let fechaHasta: Date = new Date();
        fechaHasta.setHours(parseInt(aHoraHasta[0]));
        fechaHasta.setMinutes(parseInt(aHoraHasta[1]));
        fechaHasta.setSeconds(parseInt(aHoraHasta[2]));
        this.franjaHoraria.dHoraHasta = fechaHasta;
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  guardarFranja() {
    console.log("formFranja", this.formFranja);
    console.log("franjaHoraria", this.franjaHoraria);
    if (!this.formFranja.valid) {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    } else {
      this.blockedDocument = true;
      this.franjaHoraria.horaDesde = this.franjaHoraria.dHoraDesde.getHours() + ":" + this.franjaHoraria.dHoraDesde.getMinutes() + ":00";
      this.franjaHoraria.horaHasta = this.franjaHoraria.dHoraHasta.getHours() + ":" + this.franjaHoraria.dHoraHasta.getMinutes() + ":00";
      forkJoin(
        this.puestosTrabajoService.srvGuardarFranjaHoraria(this.franjaHoraria)
      ).subscribe(
        ([franjaData]) => {
          console.log(franjaData);
          if (franjaData.respuestaServicio.codigoSalida === 1) {
            this.lanzarMensajeInfo("Se guardó la información correctamente");
          } else {
            this.lanzarMensajeError(franjaData.respuestaServicio.mensajeSalida);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }
  }

  bntVolver() {
    const ruta = "home-puestos-trabajo/administracion-home/listar-franjas";
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
