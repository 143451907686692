import { TrnRespuestaServicio } from "../../parametricas/trn-respuesta-servicio";


export class PjeUbicacionPeajeDTORpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public peajeUbicaciones?: PjeUbicacionPeajeDTO[],
        public ubicacionPeaje?: PjeUbicacionPeajeDTO
    ) { }
}
export class PjeUbicacionPeajeDTO {
    constructor(
        public id?: number,
        public idPeaje?: number,
        public nombrePeaje?: string,
        public tipoUbicacion?: string,
        public punto?: number,
        public distanciaAlPunto?: number,
        public codigoVia?: string,
        public idMunicipio?: string,
        public nombreMunicipio?: string,
        public idDepartamento?: string,
        public nombreDepartamento?: string,
        public posicionX?: number,
        public posicionY?: number,
        public fechaInicio?: Date,
        public fechaFin?: Date,
        public fechaFinFormat?: string

    ) { }

}