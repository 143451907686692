import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Accionista } from 'src/app/concesionario-carretero/accionista';
import { AccionistaContratista } from 'src/app/concesionario-carretero/accionista-contratista';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { InformacionContractualCarreteroService } from '../../informacion-contractual-carretero.service';
import { ContratoContratistaCarreteroBaseComponent } from '../contrato-contratista-carretero-base/contrato-contratista-carretero-base.component';
import { forkJoin } from 'rxjs';
import { AccionistaCarretero } from '../../informacion-contractual-carretero.dto';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-accionistas-carretero2',
  templateUrl: './accionistas-carretero2.component.html',
  styleUrls: ['./accionistas-carretero2.component.sass']
})
export class AccionistasCarretero2Component implements OnInit {

  visibleDialogoAgregarAccionista: boolean = false;

  idAccionista: any;
  accionistaContratista: AccionistaCarretero = {};
  accionistasLista: any = [];
  filtroAccionistaLista: any[];
  accionistaSeleccionado: any;
  idConcesionarioAccionista: any;
  visibleDialogoExito: boolean;
  visibleConfirmarEliminarAccionista: boolean = false;
  visibleDialogoExitoEliminarAccionistaConcesionario: boolean = false;
  blockedDocument: boolean = false;



  displayedColumns: string[] = ['nit', 'nombre', 'participacion', 'acciones'];

  dataSource: MatTableDataSource<Accionista>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public lineaBase: ContratoContratistaCarreteroBaseComponent, private informacionContractualCarreteroService: InformacionContractualCarreteroService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private parametricasService: ParametricasService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
  }

  cargarAccionistas(event) {
    this.filtroAccionistaLista = [];
    forkJoin(
      this.parametricasService.getAccionistasSinContratista(this.lineaBase.idContratista, this.lineaBase.idEncabezado)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.accionistasLista = result.accionistas;
          let existeFiltro: boolean;
          for (let i = 0; i < this.accionistasLista.length; i++) {
            let filtro = this.accionistasLista[i];
            let item: string = String(filtro.nombre);
            if (item.toLowerCase().includes(event.query.toLowerCase())) {
              this.filtroAccionistaLista.push(filtro);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.accionistasLista = this.filtroAccionistaLista;
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  visibleAgregarAccionista() {
    this.visibleDialogoAgregarAccionista = true;
    this.accionistaContratista = {};
    this.accionistaSeleccionado = null;
    this.idConcesionarioAccionista = "new";
  }

  guardarAccionista(forma: NgForm) {
    this.blockedDocument = true;
    let validacionParticipacion: boolean = false;
    let sumaParticipacion: number = 0;
    if (this.lineaBase.accionistas != null) {
      this.lineaBase.accionistas.forEach(element => {
        if (this.accionistaContratista.id != element.id) {
          sumaParticipacion = sumaParticipacion + element.porcentajeParticipacion;
        }
      });
      sumaParticipacion = sumaParticipacion + (this.accionistaContratista.porcentajeParticipacion / 100);
      if (sumaParticipacion > 1) {
        validacionParticipacion = true;
      }
    }
    if (!validacionParticipacion) {
      if (this.visibleDialogoAgregarAccionista) {
        this.accionistaContratista.idAccionista = this.accionistaSeleccionado.id;
        this.accionistaContratista.idContratista = this.lineaBase.idContratista;
        this.accionistaContratista.idEncabezado = this.lineaBase.idEncabezado;
        forkJoin(
          this.informacionContractualCarreteroService.guardarAccionistaContratista(this.accionistaContratista)
        ).subscribe(([result]) => {
          if (result != null && result.respuestaServicio != null) {
            //Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              this.visibleDialogoExito = true;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
          error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
            this.blockedDocument = false;
          },
          () => {
            //Desbloquear pantalla
            this.blockedDocument = false;
          }
        );
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La participación de accionistas supera el 100%', life: 10000 });
      this.blockedDocument = false;
    }
  }

  cancelarAgregarAccionista() {
    this.visibleDialogoAgregarAccionista = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.visibleDialogoAgregarAccionista = false;
    this.cargarAccionistasLista(this.lineaBase.idEncabezado);
  }

  cargarAccionistasLista(idEncabezado) {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.listarAccionistasPorIdEncabezado(idEncabezado, this.lineaBase.idContrato)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.lineaBase.accionistas = result.accionistas;
          this.lineaBase.totalParticipacionAccionistas = 0;
          this.lineaBase.accionistas.forEach(element => {
            this.lineaBase.totalParticipacionAccionistas = this.lineaBase.totalParticipacionAccionistas + element.porcentajeParticipacion;
          });
          this.lineaBase.visibleListaAccionistas = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  editarAccionista(idAccionista) {
    this.blockedDocument = true;
    this.idConcesionarioAccionista = idAccionista;
    forkJoin(
      this.informacionContractualCarreteroService.consultaAccionistaContratistaPorId(idAccionista)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.accionistaContratista = result.accionista;
          this.accionistaContratista.porcentajeParticipacion = this.accionistaContratista.porcentajeParticipacion * 100;
          this.accionistaSeleccionado = this.accionistaContratista.idAccionistaNavigation;
          this.visibleDialogoAgregarAccionista = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irEliminarAccionista(id) {
    this.visibleConfirmarEliminarAccionista = true;
    this.idAccionista = id;
  }

  regresarListaAccionistas() {
    this.lineaBase.visibleListaAccionistas = false;
  }

  aceptarEliminarAccionista() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionContractualCarreteroService.eliminarAccionistaContratista(this.idAccionista)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.visibleConfirmarEliminarAccionista = false;
          this.visibleDialogoExitoEliminarAccionistaConcesionario = true;
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El registro se eliminó exitosamente.', life: 10000 });
          this.cargarAccionistasLista(this.lineaBase.idEncabezado);
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  aceptarDialogoEliminarAccionista() {
    this.visibleDialogoExitoEliminarAccionistaConcesionario = false;
  }


  cancelarEliminarAccionista() {
    this.visibleConfirmarEliminarAccionista = false;
  }

}
