import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ProyectoCarretero } from 'src/app/informacion-proyecto-carretero/proyectoCarretero';
import { ContratoCarretero } from '../../informacion-contractual-carretero.dto';
import { InformacionContractualCarreteroService } from '../../informacion-contractual-carretero.service';
import { forkJoin } from 'rxjs';
import { InformacionGeneralCarreteroService } from 'src/app/informacion-general-carretero/informacion-general-carretero.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-contrato-contratista-carretero-base',
  templateUrl: './contrato-contratista-carretero-base.component.html',
  styleUrls: ['./contrato-contratista-carretero-base.component.sass']
})
export class ContratoContratistaCarreteroBaseComponent implements OnInit {

  public idContrato: number;
  public idContratista: number;
  public idProyecto: number;
  public nombreProyecto: string;
  proyectoCarretero: ProyectoCarretero = {};
  public labelTipoContratista;
  public habilitaTabContratista: boolean = false;
  contrato: ContratoCarretero = {};
  idEncabezado: any;
  visibleListaAccionistas: boolean;
  accionistas: any = [];
  totalParticipacionAccionistas: number;

  constructor(private informacionContractualCarreteroService: InformacionContractualCarreteroService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private informacionGeneralCarreteroService: InformacionGeneralCarreteroService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarProyecto(this.idProyecto);
      this.idContrato = params.idContrato;
      this.idContratista = params.idContratista;
    });
  }

  cargarProyecto(id: number) {
    this.informacionGeneralCarreteroService.getProyectoPorId(id).subscribe((data: {}) => {
      this.proyectoCarretero = data;
      this.nombreProyecto = this.proyectoCarretero.nombre;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAInformacionContractual() {
    this.ruta.navigate(['/carreteros/infGeneral/proyectoHome/' + this.idProyecto + '/contratosProyecto/', this.idProyecto]);
  }

}
