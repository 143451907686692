import { Component, OnInit } from '@angular/core';
import { CtoInformeContratistaEncabezadoDTO } from '../../../../contratacion-dto/informe-contratista-encabezado';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InformeContratistaEncabezadoService } from '../../../informe-contratista-encabezado/informe-contratista-encabezado.service';
import { InformeRevisionService } from '../../../informe-revision.service';
import { CtoInformeRevisionAsignacionDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-asignacion';
import { EnumCtoPasoInforme, EnumCtoTipoAnexoInforme } from 'src/app/contratacion/constantes/contratacion-constantes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio, EnumTipoDocumento, EnumTrnConstante, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { EnumInvInventario } from 'src/app/inventario-equipos/inventario-constantes';
import { TrnConstantesService } from 'src/app/comun/constantes/trn-constantes.service';

@Component({
  selector: 'app-informe-cxp-base',
  templateUrl: './informe-cxp-base.component.html',
  styles: []
})
export class InformeCxpBaseComponent implements OnInit {

  idContrato: number;
  idAsignacionParam: number;
  idInformeParam: number;
  idVersionInformeParam: number;
  idPasoInformeParam: number;
  idPasoParam: number;
  idPersonaAsignacionParam: number;
  visibleDialogoExito = false;
  visibleDialogoError = false;

  mapConstantes;

  habilitaTabPazSalvo = false;
  habilitaAnexosPazSalvo = false;
  fechaValidacionAnexosPazSalvo: Date;
  dateMesDiciembre = 11;//Debe ser 0-11, 11 Es Diciembre

  mensajeDialogo;

  informeContratista: CtoInformeContratistaEncabezadoDTO;
  asignacionInforme: CtoInformeRevisionAsignacionDTO;

  static TIPO_SOPORTE_CERTIFICADO_OBLIGACION_PAGO: string = "14";

  blockedDocument = false;

  msgValObservacionRechazo = 'Debes agregar las observaciones pertinentes para Rechazar el informe y devolverlo al contratista';
  msgValObservacionRechazoMinimo = 'La observación debe tener al menos 10 caracteres';

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private infCtaEncabezadoService: InformeContratistaEncabezadoService,
    private infCtaSupervisionService: InformeRevisionService,
    private constantesService: TrnConstantesService,
    private principalComponent: PrincipalComponent
  ) {
    this.mapConstantes = new Map();
  }

  ngOnInit() {
    this.activateRoute.params.subscribe(params => {
      this.idContrato = params.idContrato;
      this.idInformeParam = params.idInforme;
      this.idVersionInformeParam = params.idVersionInforme;
      this.idPasoInformeParam = params.idPasoInforme;
      this.idPasoParam = params.idPaso;
      this.idAsignacionParam = params.idAsignacion;
      this.idPersonaAsignacionParam = params.idPersonaAsignacion;
      this.cargarInformeContratista(this.idInformeParam, this.idPersonaAsignacionParam);
      this.cargarAsignacionInformeRevision(this.idAsignacionParam, this.idPasoInformeParam, this.idPersonaAsignacionParam);
    });
  }

  /**
   * Metodo que llama al servicio de constantes para mostrar en el dialogo de información de las cuentas de cobro
   */
  cargarConstantesCuentasCobro() {
    const nombresConstantes = `${EnumTrnConstante.CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA},${EnumTrnConstante.CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE}`;
    this.constantesService.srvGetConstantesListaPorNombre(nombresConstantes)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.mapConstantes = new Map();
              result.constantesLista.forEach(cns => {
                this.mapConstantes.set(cns.nombre, cns.valor);
              });
              this.validarTabsInforme();
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        }
      );
  }

  /**
   * Metodo que se encarga de llamar el servicio de consulta del informe de cuenta de cobro en revisión 
   * @param idInforme Id del informe
   * @param idPersonaAsignacion Id de la persona que revisa
   */  /**
* Metodo que se encarga de llamar el servicio de consulta del informe de cuenta de cobro en revisión 
* @param idInforme Id del informe
* @param idPersonaAsignacion Id de la persona que revisa
*/
  cargarInformeContratista(idInforme: number, idPersonaAsignacion: number) {
    this.blockedDocument = true;
    this.infCtaEncabezadoService.ConsultarInformeContratista(idInforme, idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.informeContratista = result.informeContratista;
              this.cargarConstantesCuentasCobro();
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        }
      );
  }

  cargarAsignacionInformeRevision(idAsignacion: number, idPasoInforme: number, idPersonaAsignacion: number) {
    this.infCtaSupervisionService.ConsultarAsignacionInformeRevision(idAsignacion, idPasoInforme, idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.asignacionInforme = result.asignacionInforme;
          } else {
            this.mensajeDialogo = result.respuestaServicio.mensajeSalida;
            this.visibleDialogoError = true;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        },
        error => {
          console.log(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  /**
    * Metodo que valida los tabs que se muestran en el informe
    */
  validarTabsInforme() {
    if (this.informeContratista != null && this.mapConstantes != null) {

      //Esta sección se agrego a partir del periodo de Diciembre 2021, aplica para el último pago de un contrato y solo a partir de la fecha de despligue de los ajustes Diciembre 2021      
      if (this.informeContratista.numeroPago == this.informeContratista.totalPagosFuente
        && this.informeContratista.vigencia >= this.mapConstantes.get(EnumTrnConstante.CTO_INFORME_CONTRATISTAS_TAB_PAZ_SALVO_VIGENCIA)) {
        this.habilitaTabPazSalvo = true;

        //Esta regla se agregó a partir del periodo de Diciembre 2023.
        //Se obliga anexar los formatos 014 para la útlima cuenta de cobro del contrato siempre y cuando la FechaFin del contrato sea menor o igual a 18/Dic
        console.log(this.informeContratista.fechaFinPago);
        this.fechaValidacionAnexosPazSalvo = new Date(this.informeContratista.fechaFinPago.getFullYear(), this.dateMesDiciembre, this.mapConstantes.get(EnumTrnConstante.CTO_INFORME_CONTRATISTAS_DIA_CORTE_DICIEMBRE));
        console.log('FechaValidación:' + this.fechaValidacionAnexosPazSalvo);
        if (this.informeContratista.fechaFinPago <= this.fechaValidacionAnexosPazSalvo) {
          this.habilitaAnexosPazSalvo = true;
        }
      }
    }
  }


  redireccionarBandejaCxp() {
    this.visibleDialogoExito = false;
    this.visibleDialogoError = false;
    this.router.navigate(['/contratacionSupervisionHome/informesContratistaCxP/']);
  }


  get EnumCtoPasoInforme() {
    return EnumCtoPasoInforme;
  }

  get EnumTipoDocumento() {
    return EnumTipoDocumento;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumInvInventario() {
    return EnumInvInventario;
  }

  get EnumCtoTipoAnexoInforme() {
    return EnumCtoTipoAnexoInforme;
  }


}
