import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { EtapaProyectoCarreteroService } from './etapa-proyecto-carretero.service';
import { ParametricasService } from '../parametricas/parametricas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InformacionProyectoCarreteroService } from '../informacion-proyecto-carretero/informacion-proyecto-carretero.service';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-etapa-proyecto-carretero',
  templateUrl: './etapa-proyecto-carretero.component.html',
  styleUrls: ['./etapa-proyecto-carretero.component.sass']
})
export class EtapaProyectoCarreteroComponent implements OnInit {

  constructor(public lineaBase: ProyectoLineaBaseComponent, private etapaProyectoCarreteroService: EtapaProyectoCarreteroService,
    private detalleService: InformacionProyectoCarreteroService, private parametricasService: ParametricasService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  visibleDialogoExito: boolean = false;

  fechaInicioPreconstruccion: Date;
  fechaFinPreconstruccion: Date;
  fechaInicioConstruccion: Date;
  fechaFinConstruccion: Date;
  fechaOperacion: Date;
  fechaReversion: Date;

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.lineaBase.idProyecto = params.id;
      this.cargarProyecto(this.lineaBase.idProyecto);
    });
  }

  cargarProyecto(id: number) {
    this.detalleService.getEtapaProyectoPorId(id).subscribe((data: {}) => {
      this.lineaBase.etapasProyecto = {};
      this.lineaBase.etapasProyecto = data;
      if (this.lineaBase.etapasProyecto.fechaInicioPreconstruccion != null) {
        this.fechaInicioPreconstruccion = new Date(this.lineaBase.etapasProyecto.fechaInicioPreconstruccion);
      }
      if (this.lineaBase.etapasProyecto.fechaFinPreconstruccion != null) {
        this.fechaFinPreconstruccion = new Date(this.lineaBase.etapasProyecto.fechaFinPreconstruccion);
      }
      if (this.lineaBase.etapasProyecto.fechaInicioConstruccion != null) {
        this.fechaInicioConstruccion = new Date(this.lineaBase.etapasProyecto.fechaInicioConstruccion);
      }
      if (this.lineaBase.etapasProyecto.fechaFinConstruccion != null) {
        this.fechaFinConstruccion = new Date(this.lineaBase.etapasProyecto.fechaFinConstruccion);
      }
      if (this.lineaBase.etapasProyecto.fechaInicioOperacion != null) {
        this.fechaOperacion = new Date(this.lineaBase.etapasProyecto.fechaInicioOperacion);
      }
      if (this.lineaBase.etapasProyecto.fechaInicioReversion != null) {
        this.fechaReversion = new Date(this.lineaBase.etapasProyecto.fechaInicioReversion);
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  guardarEtapas(forma: NgForm) {
    this.lineaBase.etapasProyecto.id = this.lineaBase.idProyecto;
    this.lineaBase.etapasProyecto.fechaInicioPreconstruccion = this.fechaInicioPreconstruccion;
    this.lineaBase.etapasProyecto.fechaFinPreconstruccion = this.fechaFinPreconstruccion;
    this.lineaBase.etapasProyecto.fechaInicioConstruccion = this.fechaInicioConstruccion;
    this.lineaBase.etapasProyecto.fechaFinConstruccion = this.fechaFinConstruccion;
    this.lineaBase.etapasProyecto.fechaInicioOperacion = this.fechaOperacion;
    this.lineaBase.etapasProyecto.fechaInicioReversion = this.fechaReversion;
    if (this.fechaInicioPreconstruccion == null) {
      this.lineaBase.etapasProyecto.fechaInicioPreconstruccion = new Date(1, 1, 1, 0, 0, 0);
    }
    if (this.fechaInicioConstruccion == null) {
      this.lineaBase.etapasProyecto.fechaInicioConstruccion = new Date(1, 1, 1, 0, 0, 0);
    }
    if (this.fechaOperacion == null) {
      this.lineaBase.etapasProyecto.fechaInicioOperacion = new Date(1, 1, 1, 0, 0, 0);
    }
    if (this.fechaReversion == null) {
      this.lineaBase.etapasProyecto.fechaInicioReversion = new Date(1, 1, 1, 0, 0, 0);
    }
    let errorFecha: boolean = false;
    if (this.fechaInicioPreconstruccion != null && this.fechaFinPreconstruccion != null) {
      if (this.fechaInicioPreconstruccion > this.fechaFinPreconstruccion) {
        errorFecha = true;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de pre-construcción debe ser menor que la fecha fin de pre-construcción', life: 10000 });
      }
    }
    if (this.fechaInicioConstruccion != null && this.fechaFinConstruccion != null) {
      if (this.fechaInicioConstruccion > this.fechaFinConstruccion) {
        errorFecha = true;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de construcción debe ser menor que la fecha fin de construcción', life: 10000 });
      }
    }
    if (!errorFecha) {
      this.etapaProyectoCarreteroService.guardarEtapa(this.lineaBase.etapasProyecto, this.lineaBase.idProyecto).subscribe((data: {}) => {
        this.visibleDialogoExito = true;
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.cargarProyecto(this.lineaBase.idProyecto);
  }

}
