import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { InformeRevisionService } from '../informe-revision.service';
import { CtoSolicitudPagoAprobadaSupervisorDTO } from '../../contratacion-dto/solicitud-pago-aprobadas-supervisor';
import { EnumCtoPasoInforme } from '../../constantes/contratacion-constantes';
import { EnumRespuestaServicio, EnumTipoDocumento, EnumTrnMaTipoSoporte } from '../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumInvInventario } from 'src/app/inventario-equipos/inventario-constantes';

@Component({
  selector: 'app-solicitud-pago-aprobadas-supervisor',
  templateUrl: './solicitud-pago-aprobadas-supervisor.component.html',
  styles: []
})
export class SolicitudPagoAprobadasSupervisorComponent implements OnInit {

  solicitudesPagoAprobada: CtoSolicitudPagoAprobadaSupervisorDTO[];
  blockedDocument = false;

  constructor(
    private messageService: MessageService,
    private infCtaSupervisionService: InformeRevisionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarSolicitudesPagoAprobadas();
  }

  cargarSolicitudesPagoAprobadas() {
    this.blockedDocument = true;
    this.infCtaSupervisionService.ConsultarSolicitudPagoAprobadasFirmadosSupervisor()
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.solicitudesPagoAprobada = result.solicitudesPagoAprobada;
            } else {
              console.log(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.log(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.log(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  get EnumCtoPasoInforme() {
    return EnumCtoPasoInforme;
  }

  get EnumTipoDocumento() {
    return EnumTipoDocumento;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumInvInventario() {
    return EnumInvInventario;
  }

}
