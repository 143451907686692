import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { CmsComisionAsignadaDTO, CmsEstadoDTO, CmsSolicitudComisionDTO } from "../comisiones.dto";
import { forkJoin } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ComisionesService } from "../comisiones.service";
import { EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCmsMaTipoModificacionComision, EnumCmsConstantes } from "../comisiones-constantes";

@Component({
  selector: 'app-comisiones-revisar-solicitudes',
  templateUrl: './comisiones-revisar-solicitudes.component.html',
  styleUrls: ['./comisiones-revisar-solicitudes.component.sass']
})
export class ComisionesRevisarSolicitudesComponent implements OnInit {
  blockedDocument: boolean;


  solicitud: CmsComisionAsignadaDTO = {};
  solicitudList: CmsComisionAsignadaDTO[];
  solicitudListCanceladas: CmsComisionAsignadaDTO[];
  habilitarPestanaCancelar: boolean = false;

  constructor(
    private router: Router,
    private comisionesService: ComisionesService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    var paginas = JSON.parse(sessionStorage.getItem('paginas'))

    paginas.forEach(pagina => {
      if (pagina.acciones != null) {
        var result = pagina.acciones.filter(accion => {
          if (accion.id == 1037) {
            this.habilitarPestanaCancelar = true;
          }
        })

      }
    });

    // Inicializar listas parametricas
    forkJoin(
      this.comisionesService.srvListarComisionesAsignadas(),
      this.comisionesService.srvListarComisionesCanceladas()
    ).subscribe(
      ([comisionAsignadasData, solicitudListCanceladasData]) => {
        this.solicitudList = comisionAsignadasData.comisionesAsignadas;
        this.solicitudListCanceladas = solicitudListCanceladasData.comisionesCanceladas;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  verDetalles(solicitudL: CmsSolicitudComisionDTO) {
    this.solicitud = solicitudL;
    this.guardarFechaInicioRevision(this.solicitud.idCmsAsignacionComision);
    this.router.navigate([this.router.url.replace('comisiones-revisar-solicitudes', '') + "/comisiones-crear-editar-solicitud/" + this.solicitud.idComision + '/' + this.solicitud.idComisionVersion + '/' + this.solicitud.idCmsAsignacionComision + "/true/true/" + 0 + "/false"]);
  }

  verDetallesComisionCancelada(solicitudL: CmsSolicitudComisionDTO) {
    this.solicitud = solicitudL;
    this.router.navigate([this.router.url.replace('comisiones-revisar-solicitudes', '') + "/visualizar-comisiones-cancelacion/" + this.solicitud.idComision]);
  }

  guardarFechaInicioRevision(idAsignacion: number) {
    forkJoin(
      this.comisionesService.srvGuardarFechaInicioRevision(idAsignacion)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            //  Consultar nuevamente la lista

            //  Lanzar mensaje de exito

          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        } else {

        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  bntVolver() {
    const ruta = "/comisiones";
    this.router.navigate([ruta]);
  }

  /////////////////////////////// Mensajes//////////////////////////////
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }


  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }
}
