
import { Injectable } from '@angular/core';
import { map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
import { TrnRespuestaServicioRpt } from '../parametricas/rpt-respuesta-operacion';
import { FrrMaTipoCierreViaRpt, FrrViaAfectadaRpt, FrrMaEtapaProyectoDTO, FrrViaAfectadaDTO, FrrImagenViaAfectadaRpt, FrrImagenViaAfectadaDTO, FrrViaAfectadaReporteDTO, FrrGeneracionRpt, FrrMaClasificacionContingenciaRpt, FrrTipoAfectacionViaRpt, FrrTipoAfectacionViaDTO, FrrMaTipoAfectacionCierreViaRpt } from './ola-invernal-ferreo.dto';
import { Observable } from 'rxjs-compat';

@Injectable({
    providedIn: 'root'
})
export class OlaInvernalFerreoService {
    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    /**
     * Constructor de la clase
     * @param http
     * @param datepipe
     */
    constructor(
        private http: HttpClient,
        private datepipe: DatePipe) {

        this.END_POINT = environment.baseUrl;
    }

    //#region "Parametricas"


    /**
     * Metodo que consulta los tipos de cierre parametricas ola invernal
     * @returns  FrrMaTipoCierreViaRpt
     */
    srvConsultarMaTiposCierreOlaInvernal(): Observable<FrrMaTipoCierreViaRpt> {
        const urlEndpoint = this.END_POINT + '/FrrMaOlaInvernal/FrrMaClasificacionContingenciaCierreVia';
        return this.http.get<FrrMaTipoCierreViaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que consulta las etapas del proyecto
     * @returns  FrrMaEtapaProyectoDTO
     */
    srvConsultarMaEtapaProyectoOlaInvernal(): Observable<FrrMaEtapaProyectoDTO[]> {
        const urlEndpoint = this.END_POINT + '/TrnMaEtapaProyecto/list/4';
        return this.http.get<FrrMaEtapaProyectoDTO[]>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que consulta las clasificaciones parametricas de contigencias de ola invernal
     * @returns FrrMaTipoCierreViaRpt
     */
    srvConsultarMaClasificacionContingenciaOlaInvernal(): Observable<FrrMaClasificacionContingenciaRpt> {
      const urlEndpoint = this.END_POINT + '/FrrMaOlaInvernal/FrrMaClasificacionContingenciaCierreVia';
      return this.http.get<FrrMaClasificacionContingenciaRpt>(urlEndpoint)
          .pipe(
              map(data => {
                  return data;
              }),
              retry(1));
  }


    /**
    * Metodo que consulta los tipos de afectación de ola invernal
    * @returns  FrrMaTipoAfectacionCierreViaRpt
    */
    srvConsultarMaTipoAfectacionCierreVia(): Observable<FrrMaTipoAfectacionCierreViaRpt> {
        const urlEndpoint = this.END_POINT + '/FrrMaOlaInvernal/FrrMaTipoAfectacionCierreVia';
        return this.http.get<FrrMaTipoAfectacionCierreViaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
    //#endregion "Parametricas"

    //#region "Información General"
    srvConsultarCierresPorProyecto(idProyecto: number): Observable<FrrViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/ListarFrrOlaInvernalPorProyecto/' + idProyecto;
        return this.http.get<FrrViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvConsultarCierreOlaInvernalPorId(idOlaInvernal: number): Observable<FrrViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/ConsultarFrrOlaInvernalPorId/' + idOlaInvernal;
        return this.http.get<FrrViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvGuardarCierreOlaInvernal(cierreOlaInvernal: FrrViaAfectadaDTO): Observable<FrrViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/GuardarFrrOlaInvernalPorProyecto';
        return this.http.post<FrrViaAfectadaRpt>(urlEndpoint, cierreOlaInvernal)
            .pipe(
                map(data => {
                    return data;
                }));
    }

    srvEliminarCierreOlaInvernalPorId(idOlaInvernal: number): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/eliminarFrrOlaInvernal/' + idOlaInvernal;
        return this.http.delete<TrnRespuestaServicioRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
    //#endregion "Información General"

    //#region "Afectaciones"
    srvConsultarAfectacionesPorIdViaAfectada(idOlaIinvernal: number): Observable<FrrTipoAfectacionViaRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/ListarFrrTipoAfectacionViaPorIdViaAfectada/' + idOlaIinvernal;
        return this.http.get<FrrImagenViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvConsultarAfectacionPorId(idAfectacion: number): Observable<FrrTipoAfectacionViaRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/ConsultarFrrTipoAfectacionViaPorIdViaAfectadaPorId/' + idAfectacion;
        return this.http.get<FrrTipoAfectacionViaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvGuardarAfectacionViaAfectada(afectacion: FrrTipoAfectacionViaDTO): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/GuardarFrrTipoAfectacionViaPorIdViaAfectada';
        return this.http.post<TrnRespuestaServicioRpt>(urlEndpoint, afectacion)
            .pipe(
                map(data => {
                    return data;
                }));
    }

    srvEliminarAfectacionViaAfectadaPorId(idAfectacionOlaInvernal: number): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/eliminarFrrTipoAfectacionVia/' + idAfectacionOlaInvernal;
        return this.http.delete<TrnRespuestaServicioRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
    //#endregion "Afectaciones"

    //#region "Registro fotografico"
    srvConsultarImagenesPorIdViaAfectada(idOlaIinvernal: number): Observable<FrrImagenViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/ListarImagenesPorIdViaAfectada/' + idOlaIinvernal;
        return this.http.get<FrrImagenViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvConsultarImagenOlaInvernalPorId(idImagenOlaIinvernal: number): Observable<FrrImagenViaAfectadaRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/ConsultarFrrImagenOlaInvernalPorId/' + idImagenOlaIinvernal;
        return this.http.get<FrrImagenViaAfectadaRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    srvGuardarImagenViaAfectada(imagenViaAfectada: FrrImagenViaAfectadaDTO): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/GuardarFrrImagenViaAfectada';
        return this.http.post<TrnRespuestaServicioRpt>(urlEndpoint, imagenViaAfectada)
            .pipe(
                map(data => {
                    return data;
                }));
    }

    srvEliminarImagenViaAfectadaPorId(idFotografiaOlaInvernal: number): Observable<TrnRespuestaServicioRpt> {
        const urlEndpoint = this.END_POINT + '/FrrOlaInvernal/eliminarFrrImagenViaAfectada/' + idFotografiaOlaInvernal;
        return this.http.delete<TrnRespuestaServicioRpt>(urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
    //#endregion "Registro fotografico"

    //#region "Reporte Ola Invernal"
    srvConsultarReporteDetalleOlaInvernal(filtroReporteOlaInvernal: FrrViaAfectadaReporteDTO): Observable<FrrViaAfectadaRpt> {
        const urlEndpoint = '/FrrOlaInvernal/ListarFrrOlaInvernal';
        return this.http.post<FrrViaAfectadaRpt>(this.END_POINT + urlEndpoint, filtroReporteOlaInvernal)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que genera el pdf de ola invernal
     *
     * @param filtroReporteOlaInvernal
     * @returns
     */
    GeneracionPdfOlaInvernal(filtroReporteOlaInvernal: FrrViaAfectadaReporteDTO): Observable<FrrGeneracionRpt> {
        return this.http.post<FrrGeneracionRpt>(this.END_POINT + '/FrrOlaInvernal/GeneracionPdf-Ola', filtroReporteOlaInvernal)
            .pipe(
                retry(1));
    }
    //#endregion "Reporte Ola Invernal"
}
