import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AmigablesService {

  public END_POINT = '';


    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public getTipoObligacion() {
    return this.http.get(this.END_POINT + '/ambiental/tipoObligacion')
      .pipe(
        retry(1));
  }

  public getProyectosAmigables(id) {
    return this.http.get(this.END_POINT + '/juridico/amigable/list/' + id)
      .pipe(
        retry(1));
  }

  public getSeguimientoAmigable(id) {
    return this.http.get(this.END_POINT + '/juridico/amigableSeguimiento/' + id)
      .pipe(
        retry(1));
  }

  public getTiposPersona(){
    return this.http.get(this.END_POINT+'/juridico/tiposPersona');
  }

  public deleteDetalleAmbiental(id) {
    return this.http.delete(this.END_POINT + '/ambiental/' + id )
      .pipe(
        retry(1));
  }

  public guardarSeguientoActuacion(seguimiento){
   return this.http.post(this.END_POINT + '/juridico/amigableActuacion' , seguimiento)
    .pipe(
      retry(1));
  }

public guardarPersona(persona){
  return this.http.post(this.END_POINT+'/juridico/amigablePersona', persona)
  .pipe(retry(1));
}

public finalizarPersona(id){
  return this.http.post(this.END_POINT+'/juridico/finalizarPersona/',{'id':id})
  .pipe(retry(1));
}

}
