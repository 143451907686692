import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import * as Highcharts from "highcharts/highstock";
import { ActivatedRoute } from "@angular/router";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCarreteroService } from "../../reportes-carreteros.service";
import { forkJoin } from "rxjs";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import colorchart from "src/assets/img/coloreschart";
@Component({
  selector: "app-rpt-crr-proyecto-accionistas",
  templateUrl: "./rpt-crr-proyecto-accionistas.component.html",
  styleUrls: ["./rpt-crr-proyecto-accionistas.component.sass"],
})
export class RptCrrProyectoAccionistasComponent implements OnInit {
  optionschart10: any;
  optionschart11: any;
  idProyecto: number;
  proyecto: any;
  accionistasConcesionario: any;
  accionistasInterventoria: any;
  valoresAccionistaConcesionario: any;
  valoresAccionistaInterventoria: any;
  nombreConcesionario: any;
  numeroContratoConcesionario: any;
  fechaSuscripcionConcesionario: any;
  nombreInterventoria: any;
  fechaSuscripcionInterventoria: any;
  numeroContratoInterventoria: any;
  urlSecopConcesionario: any;
  urlSecopInterventoria: any;

  constructor(
    private reportesCarreteroService: ReportesCarreteroService,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    console.log("accionistas");
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params.id;
      this.cargarInformacionGeneral(this.idProyecto);
    });
    this.init();
  }

  cargarInformacionGeneral(idProyecto: number) {
    forkJoin(
      this.reportesCarreteroService.obtenerInformacionGeneral(idProyecto)
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            console.log(result);
            this.proyecto = result.informacionGeneralProyecto;
            console.log(this.proyecto.nombre);
            this.obtenerAccionistas();
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        } else {
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 10000,
        });
      },
      () => {
        //Desbloquear pantalla
      }
    );
  }
  colores = [
    "#313CBC",
    "#00C0FF",
    "#00BFC4",
    "#FC7F25",
    "#FFC438",
    "#800CE5",
    "#BB89F4",
    "#D1D5FA",
  ];
  obtenerAccionistas() {
    forkJoin(
      this.reportesCarreteroService.getAccionistas(this.idProyecto, 1),
      this.reportesCarreteroService.getAccionistas(this.idProyecto, 2)
    ).subscribe(
      ([dataConcesionario, dataInterventoria]) => {
        if (
          dataConcesionario.respuestaServicio.codigoSalida !=
          EnumRespuestaServicio.CODIGO_EXITO_OPERACION
        ) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: dataConcesionario.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }
        this.accionistasConcesionario = dataConcesionario;
        this.nombreConcesionario =
          this.accionistasConcesionario.accionistas[0].contratista;
        this.fechaSuscripcionConcesionario =
          this.accionistasConcesionario.accionistas[0].fechaSuscripcion;
        this.numeroContratoConcesionario =
          this.accionistasConcesionario.accionistas[0].numeroContrato;
          this.urlSecopConcesionario =
          this.accionistasConcesionario.accionistas[0].urlSecop;
        this.accionistasInterventoria = dataInterventoria;
        this.nombreInterventoria =
          this.accionistasInterventoria.accionistas[0].contratista;
        this.fechaSuscripcionInterventoria =
          this.accionistasInterventoria.accionistas[0].fechaSuscripcion;
        this.numeroContratoInterventoria =
          this.accionistasInterventoria.accionistas[0].numeroContrato;
        this.urlSecopInterventoria =
          this.accionistasInterventoria.accionistas[0].urlSecop;
        let contador = 0;
        this.valoresAccionistaConcesionario = {
          participacion: dataConcesionario.accionistas.map(
            (item, index) => ({
              y: item.porcentajeParticipacion * 100,
              color: this.colores[index]
            })

          ),
          accionista: dataConcesionario.accionistas.map(
            (item) => item.accionista
          ),
        };
        this.valoresAccionistaInterventoria = {
          participacion: dataInterventoria.accionistas.map(
            (item, index) => ({
              y: item.porcentajeParticipacion * 100,
              color: this.colores[index]
            })

          ),
          accionista: dataInterventoria.accionistas.map(
            (item) => item.accionista
          ),
        };
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.cargarGraficas();
      }
    );
  }



  cargarGraficas() {
    //ocho
    this.optionschart10 = {
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: this.valoresAccionistaConcesionario.accionista,
        crosshair: true
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: "",
        },
        labels: {
          overflow: "justify",
        },
      },
      tooltip: {
        valueSuffix: " %",
        shared: true,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{point.y:.6f} %',
            align: 'right',
            color: colorchart.color1,
            x: -10
          },
        },
      },

      credits: {
        enabled: false,
      },

      series: [
        {
          name: "Participación",
          data: this.valoresAccionistaConcesionario.participacion,
        },
      ],
    };

    let chart10 = Highcharts.chart("chart10", this.optionschart10);
    //nueve

    this.optionschart11 = {
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: this.valoresAccionistaInterventoria.accionista,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: "",
        },
        labels: {
          overflow: "justify",
        },
      },
      tooltip: {
        valueSuffix: " %",
        shared: true,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{point.y:.6f} %',
            align: 'right',
            color: colorchart.color1,
            x: -10
          },
        },
      },

      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Participación",
          data: this.valoresAccionistaInterventoria.participacion,
        },
      ],
    };
    let chart11 = Highcharts.chart("chart11", this.optionschart11);

  }

  init() { }
}
