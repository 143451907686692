import { AdjuntarAlmacenamientoPublicService, } from './adjuntar-almacenamiento-public.service';
import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Archivo } from '../adjuntar-almacenamiento-public/archivo';
import { AlmacenamientoService } from '../almacenamiento/almacenamiento.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';
import { Subject, interval } from 'rxjs';



@Component({
    selector: 'app-adjuntar-almacenamiento-public',
    templateUrl: './adjuntar-almacenamiento-public.component.html',
    styleUrls: ['./adjuntar-almacenamiento-public.component.sass']
})
export class AdjuntarAlmacenamientoPublicComponent implements OnInit  {
    //destroy$: Subject<boolean> = new Subject<boolean>();

    @Input() modulo: string;
    @Output() idArchivosChange = new EventEmitter<Archivo[]>();
    @Input() idArchivos: Archivo[];
    @Input() id: string;
    @Input() soloUnArchivo: boolean;
    @Input() nombreInicialArchivo: string;
  
    @Input() idTiposSoporte: string;
    @Input() habilitaEnvio: boolean = true;
    @Input() esBlob: boolean = false;
    @Input() contenedor: string;
  
    public files: NgxFileDropEntry[] = [];
    tipoSoporteSeleccionado: any;
    tiposSoporte: any = [];
    filtroTiposSoporte: any[];
    public _files: Archivo[] = [];
    public _filesTemp: Archivo[] = [];

    @Output() OutputValidacionArchivos = new EventEmitter();
    ocurrioAlgunError: boolean;
  
    yaSeVisualizo: boolean;
    tamanioMegas :any;
    tamanioMegasStr :any;
    numeroArchivos :any;
    errorNumeroArchivo: boolean;
  
    constructor(private svc: AdjuntarAlmacenamientoPublicService, private messageService: MessageService,
       private principalComponent: PrincipalComponent,
       private cdr: ChangeDetectorRef ) { }
  
    ngOnInit() {
        this.cargarTiposSoporte("");
        this.tamanioMegas = 20000000;
        this.numeroArchivos = 3;
        this.errorNumeroArchivo = false;
        this.tamanioMegasStr = (this.tamanioMegas / (1000 * 1000 )) + " Megas";
        /*var arch = JSON.parse(localStorage.getItem('archivoPqrsd'));
        if(arch != null && arch.length > 0){
          this._files = arch;
          this._filesTemp = arch;
        } */
    }
  
    ngAfterViewChecked(){
      //your code to update the model
      this.cdr.detectChanges();
      var arch = JSON.parse(localStorage.getItem('archivoPqrsd'));
        if(arch != null && arch.length > 0){
          this._files = arch;
        }else{
          if(arch != null && arch.length == 0 && this._filesTemp.length == 0){
            this._files = arch;
          }
        }
   }

    public dropped(files: NgxFileDropEntry[]) {
      this.files = files;
  
      if (this.soloUnArchivo) {
        this._files = [];
        this._filesTemp = [];
      }
  
      let index: number = 0;
  
      this._filesTemp.forEach(element => {
        if (element.estado == 'Tamaño no valido') {
          this._filesTemp.splice(index, 1);
        }
        if (element.estado == 'Formato no válido') {
          this._filesTemp.splice(index, 1);
        }
        index++;
      });
  
      this.habilitaEnvio = true;
      this.ocurrioAlgunError = false;
      let sum = this._filesTemp.length + files.length;
      if(sum > this.numeroArchivos){
          this.errorNumeroArchivo = true;
          this.habilitaEnvio = true;
          this.ocurrioAlgunError = false;

          if(this.numeroArchivos > 0){
            if (!this.ocurrioAlgunError) {
              this.OutputValidacionArchivos.emit("OK");
            } else {
              this.OutputValidacionArchivos.emit("ERROR");
            }
            localStorage.setItem("archivoPqrsd", JSON.stringify(this._filesTemp));
            this.idArchivosChange.emit(this._filesTemp);
            this.idArchivosChange.emit(this._files);
          }
      }else{
        this.errorNumeroArchivo = false;
        localStorage.setItem("archivoPqrsd", JSON.stringify(this._filesTemp));
        for (const droppedFile of files) {
          // Is it a file?
          if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
              var archivoCargueBase = null;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                  archivoCargueBase = reader.result;
  
                  var archivo = new Archivo(droppedFile.relativePath, "Enviando...", droppedFile.relativePath, this.tipoSoporteSeleccionado.id, archivoCargueBase);
                  this._files.push(archivo);
                  this._filesTemp.push(archivo);
                  localStorage.setItem("archivoPqrsd", JSON.stringify(this._filesTemp));
                  if (file.size <= this.tamanioMegas) {//Tamano maximo permito son 20MB
                    const uploadData = new FormData();
                    uploadData.append('file', file, droppedFile.relativePath);
                    uploadData.append('modulo', this.modulo);
                    uploadData.append('id', this.id);
                    uploadData.append('nombreInicialArchivo', this.nombreInicialArchivo);
                    uploadData.append('esBlob', String(this.esBlob));
                    uploadData.append('contenedor', this.contenedor);
                    this.svc
                      .uploadFile(uploadData)
                      .subscribe(
                        (response: any) => {
                          archivo.estado = "Cargado";
                          archivo.nombreCompleto = response.fileName;
                          archivo.idTipoDocumento = this.tipoSoporteSeleccionado.id;
                          archivo.nombreCompleto = response.fileName;
                          let todosEviados: boolean = true;
                          this._filesTemp.forEach(element => {
                            if (element.estado != "Cargado" ) {
                              todosEviados = false;
                            }
                          });
                          localStorage.setItem("archivoPqrsd", JSON.stringify(this._filesTemp));
                          if (todosEviados) {
                            this.habilitaEnvio = false;
                          }
                        },
                        (error) => {
                          console.error(error);
                          this.principalComponent.cargarErrorServicio(error);
                          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
                          archivo.estado = "No se pudo adjuntar";
                          this.ocurrioAlgunError = true;
                          this.habilitaEnvio = true;
                        }
                      );
                      localStorage.setItem("archivoPqrsd", JSON.stringify(this._filesTemp));
                  } else {  
                    archivo.estado = "Tamaño no valido";
                    this.ocurrioAlgunError = true;
                    this.habilitaEnvio = true;
                  }
              };            
            });
          } else {
            localStorage.setItem("archivoPqrsd", JSON.stringify(this._filesTemp));
            var archivo = new Archivo(droppedFile.relativePath, "Formato no válido", droppedFile.relativePath, this.tipoSoporteSeleccionado.id, null);
            this.ocurrioAlgunError = true;
          }
        }
    
        if (!this.ocurrioAlgunError) {
          this.OutputValidacionArchivos.emit("OK");
        } else {
          this.OutputValidacionArchivos.emit("ERROR");
        }
        localStorage.setItem("archivoPqrsd", JSON.stringify(this._filesTemp));
        this.idArchivosChange.emit(this._filesTemp);
      }
    }

    /**
     * Metodo que valida los tamaños
     */
    validarArchivos(){
      if(this._files.length > this.numeroArchivos){
        this.errorNumeroArchivo = true;
        return true;
      }else{
        this.errorNumeroArchivo = false;
        return false;
      }
    }
  
    limpiarArchivos() {
      //this.tipoSoporteSeleccionado = null;
      this._files = [];
      this.habilitaEnvio = true;
    }
  
    isFileAllowed(fileName: string) {
      let isFileAllowed = false;
      const allowedFiles = this.tipoSoporteSeleccionado.formatoPermitido.split(',');
      const regex = /(?:\.([^.]+))?$/;
      const extension = regex.exec(fileName);
  
      if (undefined !== extension && null !== extension) {
        for (const ext of allowedFiles) {
          if (ext === extension[0]) {
            isFileAllowed = true;
          }
        }
      }
      return isFileAllowed;
    }
  
    public fileOver(event) {
      this.validarArchivos();
    }
  
    public fileLeave(event) {
      this.validarArchivos();
    }
  
    cargarTiposSoporte(event) {
      if (this.idTiposSoporte == null) {
      } else {
      }
      this.filtroTiposSoporte = [];
      this.svc.getTiposSoporte(this.modulo, this.idTiposSoporte).subscribe((data: {}) => {
        this.tiposSoporte = data;
        this.tipoSoporteSeleccionado = {
            id :  this.tiposSoporte[0].id,
            modulo :  this.tiposSoporte[0].modulo,
            formatoPermitido :  this.tiposSoporte[0].formatoPermitido,
            idTipoDocumentoAsociado :  this.tiposSoporte[0].idTipoDocumentoAsociado,
            nombre :  this.tiposSoporte[0].nombre,
            titulo :  this.tiposSoporte[0].titulo
        }

      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });		  
      });
    }

    eliminarAnexo(evento){
        let index: number = 0;
        this.errorNumeroArchivo = false;

        this._files.forEach(element => {
            if (element.nombre == evento.nombre && element.nombreCompleto == evento.nombreCompleto) {
              this._files.splice(index, 1);
            }
            index++;
          });

          if(this._files.length == 0){
            this.habilitaEnvio = false;
          }
    }

  }
  
