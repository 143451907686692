
export class InformacionGeneral {
    constructor(
        public radicado?: string, 
        public fecha?: string, 
        public asunto?: string, 
        public firmantes?: Firmantes []
    ) {}
}

export class Firmantes {
    constructor(
        public fecha_Firma?: string, 
        public firmante?: string
    ) {}
}




