import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-public-ferrocarriles',
  templateUrl: './public-ferrocarriles.component.html'
})
export class PublicFerrocarrilesComponent implements OnInit {

  constructor(private route: Router) { }

  /**Metodo que inicializa el componente */
  ngOnInit() {
    document.getElementById("nameseccion").innerHTML = "<span class='smallicon'><img src='assets/img/icn/frrocaril_500-04.svg'/></span> Ferrocarriles";
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
    element.classList.add("p_ferrocarriles");
  }

  /**Metodo que carga el reporte */
  reportes() {

    this.route.navigate(['ferrocarriles-public/consulta'])
  }
}
