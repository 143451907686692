import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TrnPersona, TrnPersonaRpt } from '../../trn-persona';
import { PersonaService } from '../../../comun/persona/persona.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-persona-base',
  templateUrl: './persona-base.component.html'
})
export class PersonaBaseComponent implements OnInit {

  personaBase: TrnPersona;
  tipoDocumentoVar: any;
  modoAdmin: boolean = true;
  visibleDialogoError = false;
  mensajeError: string;
  blockedDocument: boolean = false;
  paginaAnterior: string = "personas";

  preguntarCondiciones: boolean = false;
  respuestaCondicion: boolean = false;
  aceptoCondiciones: boolean = false;
  preguntaAutorizacionDatosPersonales: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private personaService: PersonaService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      params => {
        console.log(params);
        if (params.idPersona === undefined && params.idTipoDocumento === undefined) {
          this.modoAdmin = false;
          this.paginaAnterior = "ani-apps";
          this.cargarDatosUsuario();
        }
        else if (params.idPersona != null && Number(params.idPersona) !== 0 && params.idTipoDocumento != null) {
          console.log('entro a cargar persona');
          this.cargarPersona(params.idPersona, params.idTipoDocumento);
        } else {
          console.log('crear nueva persona');
          this.personaBase = new TrnPersona();
          this.personaBase.idTipoDocumento = 'CC';
          this.personaBase.id = 0;
        }
      });
  }

  btnVolver() {
    this.router.navigate([this.paginaAnterior]);
  }

  private cargarDatosUsuario() {
    this.blockedDocument = true;
    forkJoin([this.personaService.ConsultarDatosBasicosUsuario(), this.personaService.ObtenerPreguntaAurotizacionDatos()]).subscribe(
      respuestas => {
        if (respuestas[1].respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.mensajeError = respuestas[1].respuestaServicio.mensajeSalida;
          this.visibleDialogoError = true;
          return;
        }
        
        this.preguntaAutorizacionDatosPersonales = respuestas[1].preguntaAutorizacion;
        this.controlarRespuestaDatosPersona(respuestas[0]);
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      });
  }

  cargarPersona(idPersona: number, idTipoDocumento: string) {
    this.blockedDocument = true;
    this.personaService.ConsultarDatosBasicosPersonaId(idTipoDocumento, idPersona).subscribe(
      result => this.controlarRespuestaDatosPersona(result),
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  private controlarRespuestaDatosPersona(respuesta: TrnPersonaRpt) {
    if (respuesta != null && respuesta.respuestaServicio != null) {
      if (respuesta.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        this.personaBase = respuesta.persona;
        this.aceptoCondiciones = respuesta.aceptoCondiciones;
        this.preguntarCondiciones = !respuesta.aceptoCondiciones
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: respuesta.respuestaServicio.mensajeSalida, life: 10000 });
      }
    } else {
      console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
    }
  }

  public respuestaCondicionesTratamientoDatos() {
    this.preguntarCondiciones = false;
    if (!this.respuestaCondicion)
    {
      this.mensajeError = "Si no acepta las condiciones de tratamiento de datos personales no puede proceder a la edición de su información. Será redireccionado al inicio.";
      this.visibleDialogoError = true;
      return;
    }

    this.blockedDocument = true;
    this.personaService.AceptarTratamientoDatos(this.personaBase.id, this.preguntaAutorizacionDatosPersonales.idPregunta)
    .subscribe(respuesta => {
      if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: respuesta.respuestaServicio.mensajeSalida, life: 10000 });
        return;
      }
  
      this.aceptoCondiciones = true;
    },
    error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.blockedDocument = false;
    },
    () => {
      this.blockedDocument = false;
    });
  }

  public aceptarDialogoError() {
    this.visibleDialogoError = false;
    this.router.navigate([this.paginaAnterior]);
  }
}
