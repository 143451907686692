import { Component, OnInit, OnDestroy, } from '@angular/core';
import { FuncionarioRadicacionRespuestaService, } from './funcionario-radicacion-respuesta.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneral, ListaSeleccion } from './informacionGeneral';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@Component({
    selector: 's-funcionario-radicacion-respuesta',
    templateUrl: './funcionario-radicacion-respuesta.component.html',
    styleUrls: ['./funcionario-radicacion-respuesta.component.css']
})
export class FuncionarioRadicacionRespuestaComponent implements OnInit {

    loading: boolean = false;
    habilitarAdjunto: boolean;
    visibleModalErrorBusqueda: boolean;
    visualizarBuscarRadicado: boolean;
    habilitarCrearRespuesta: boolean;
    visibleDetalle : boolean;
    visibleDetalleLimpiar : boolean;
    idSolicitudInivias : any;
    listaProyectosResponder: any = [];
    listaTemporal: any = [];
    informacionGeneral: InformacionGeneral = {};

    listaSeleccion: ListaSeleccion[];

    temporal: any; 
    displayedColumns3;

    constructor(private _service: FuncionarioRadicacionRespuestaService, 
        private route: Router,
        public location: Location,
        private http: HttpClient,
        private messageService: MessageService,
        private principalComponent: PrincipalComponent) { }

    ngOnInit() {
        this.loading = true;
        this.visibleDetalle = false;
        this.habilitarCrearRespuesta = false;
        this.visibleDetalleLimpiar = true;
        this.visualizarBuscarRadicado = true;
        this.habilitarAdjunto = false;
        this.displayedColumns3 = [
            { field: 'proyectos', header: 'Proyecto' },
            { field: 'radicadoSolicitudINVIAS', header: 'Radicado Solicitud INVIAS' },
            { field: 'radicadoRespuestaConcesionarioaANI', header: 'Radicado de respuesta de concesionario a ANI' },
            { field: 'fechaRespuestaConcesionarioaANI', header: 'Fecha de respuesta de concesionario a ANI' },
            { field: 'concepto', header: 'Concepto' },
            { field: 'asunto', header: 'Asunto' }
          ];
        
        this.idSolicitudInivias = localStorage.getItem('idSolicitudInivias');
        this.cargarInformacion();
    }

    
    /**
     * Metodo para validar la finalizacion de un proceso
     */
    onValidarFinalizacion(){
      this.loading = true;
      this._service.getConsultaValidarFinalizacion( this.idSolicitudInivias).subscribe((data: {}) => {
        this.temporal = data;
        if(this.temporal != null && this.temporal.mensajeSalida != null && this.temporal.mensajeSalida == "El estado esta Finalizado"){
          this.habilitarCrearRespuesta = false;
        }else{
          this.habilitarCrearRespuesta = true;
        }     
        this.loading = false;
      },error => {
          this.loading = false;
          this.principalComponent.cargarErrorServicio(error);
      });
    }

    /**
     * Metodo que reset los valores
     */
    resetValoresPrincipal(){
      this.informacionGeneral.asunto = null;
      this.informacionGeneral.fechaRadicadoStr = null;
      this.informacionGeneral.fechaRadicado = null;
      this.informacionGeneral.rutaAdjunto = null;
      this.informacionGeneral.numeroRadicado = null;
      this.habilitarAdjunto = false;
      this.visibleDetalle = false;
      this.visibleDetalleLimpiar = true;
    }

    /**
     * Metodo que carga la información ya seleccionados.
     */
    cargarSeleccionados(){
        this.listaSeleccion = [];
        this._service.getConsultaListaRespuestasSolicitudSeleccionados(this.idSolicitudInivias).subscribe((data: {}) => {
            this.temporal = data;     
            this.listaSeleccion = this.temporal; 
            if(this.listaSeleccion!= null && this.listaProyectosResponder!= null){
              if(this.listaSeleccion.length <= this.listaProyectosResponder.length && this.listaProyectosResponder.length > 0){
                if(this.listaProyectosResponder.length == this.listaSeleccion.length ){
                  this.visualizarBuscarRadicado = false;
                }else{
                  this.listaTemporal = [];                  
                  for (let i = 0; i < this.listaProyectosResponder.length; i++) {
                    let principalEncontro = false;
                    let element = this.listaProyectosResponder[i];                                        
                    for (let j = 0; j < this.listaSeleccion.length; j++) {
                      let filtro = this.listaSeleccion[j];
                      if(filtro.id == element.id){
                        principalEncontro = true;
                      }
                    }
                    if(!principalEncontro){
                      this.listaTemporal.push(element);
                    }
                  }
                  let encontro = false;
                  for (let i = 0; i < this.listaTemporal.length; i++) {
                    let filtro = this.listaTemporal[i];
                      if(filtro.concepto != "Pendiente"){
                        encontro = true;
                      }
                  }
                  if(encontro){
                    this.visualizarBuscarRadicado = true;
                  }else{
                    this.visualizarBuscarRadicado = false;
                  }                 
                }
              }else{
                this.visualizarBuscarRadicado = false;
              }
            }else{
              this.visualizarBuscarRadicado = false;
            }
            this.resetValoresPrincipal();
            this.loading = false;
        },error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }
    
    /**
     * Metodo que carga la información 
     */
    cargarInformacion(){
      this.loading = true;
      this.listaSeleccion = [];
      this.listaProyectosResponder = [];
      this._service.getConsultaListaRespuestasSolicitud(this.idSolicitudInivias).subscribe((data: {}) => {
          this.listaProyectosResponder = data;    
          this.cargarSeleccionados();    
          this.onValidarFinalizacion();  
          this.loading = false;
      },error => {
          this.loading = false;
          this.principalComponent.cargarErrorServicio(error);
      });
    }

    /**
     * Metodo que guarda el proceso de respuesta
     * @param forma 
     */
    guardarRespuestaTramite(){        
        if(this.listaSeleccion.length > 0){
          this.loading = true;
            for (let i = 0; i < this.listaSeleccion.length; i++) {
                let filtro = this.listaSeleccion[i];
                filtro.numeroRadicado = this.informacionGeneral.numeroRadicado;
                filtro.fechaRadicado = this.informacionGeneral.fechaRadicado;
                filtro.rutaAdjunto = this.informacionGeneral.rutaAdjunto;
                filtro.asunto = this.informacionGeneral.asunto;
              }
            this._service.guardarRespuestaConceptos(this.listaSeleccion).subscribe((data: {}) => {
                this.listaProyectosResponder = data; 
                this.informacionGeneral = {};
                this.informacionGeneral.numeroRadicado = "";
                this.cargarInformacion();
            },error => {
                this.loading = false;
                this.principalComponent.cargarErrorServicio(error);
            });
        }else{
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: "Debe seleccionar un registro para poder guardar.",
            life: 10000
          });
        }
    }
  
   /**
   * Metodo que retorna a la pagina anterior de tramites
   */
  bntVolver() {
    const ruta = "/cargaExtrapesadaFun";
    this.route.navigate([ruta]);
  }

  /**
   * Metodo que busca el radicado en orfeo
   * @param radicado 
   */
  onBuscar(radicado){
    if(radicado != null && radicado != undefined && radicado != ""){
      if(radicado.length < 14){
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El número de radicado no poseé la longitud del formato.",
          life: 10000
        });
        return ;
      }
      
      if(this.stringIsNumber(radicado)){
        this.loading = true;
        this._service.getConsultaRadicado(radicado).subscribe((data: {}) => {
          this.temporal = data;
          if( this.temporal.asunto != null && this.temporal.asunto != ""){
            this.informacionGeneral.asunto = this.temporal.asunto;
            this.informacionGeneral.numeroRadicado = this.temporal.numeroRadicado;
            this.informacionGeneral.fechaRadicadoStr = this.temporal.fechaRadicadoStr;
            this.informacionGeneral.fechaRadicado = this.temporal.fechaRadicado;
            this.informacionGeneral.rutaAdjunto = this.temporal.archivo;
            this.visibleModalErrorBusqueda = false;
            this.visibleDetalle = true;      
            if(this.temporal.archivo != null && this.temporal.archivo != ""){
              this.habilitarAdjunto = true;
            }else{
              this.habilitarAdjunto = false;
            }
          }else{
            if(this.temporal!= null && this.temporal.mensajeError!= undefined && this.temporal.mensajeError != null
              && this.temporal.mensajeError!= ''){
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: this.temporal.mensajeError,
                  life: 10000
                });
              }else{
                this.visibleModalErrorBusqueda = true;
              }
            this.habilitarAdjunto = false;
          }
          this.loading = false;
        },error => {
           this.loading = false;
           this.principalComponent.cargarErrorServicio(error);
         });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "Se debe digitar un número de radicado valido.",
          life: 10000
        });
        return ;
      }
    }else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Se debe digitar un número de radicado.",
        life: 10000
      });
      return ;
    }    
  }

   /**
   * Metodo que valida si es un numero.
   * @param s 
   */
  stringIsNumber(s) {
    var x = +s; // made cast obvious for demonstration
    return x.toString() === s;
  }

  /**
   * Metodo que visualiza el modal de error de busqueda
   */
  aceptarDialogoErrorBusqueda(){
      this.visibleModalErrorBusqueda = false;
  }

 /**
	 * Metodo que captura el evento del teclado solo numeros 0-9
	 * @param evento 
	 */
	soloNumeros(evento){
		if(evento.code == "Backspace" 
		|| evento.code == "Enter" 
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"
    || evento.ctrlKey == true){
			return true;
    }
    
    if(evento.ctrlKey == true && evento.keyCode == 67){
      return true;
    }
    if(evento.ctrlKey == true && evento.keyCode == 86){
      return true;
    }

		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || 
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}

	}	   
  
   /**
	 * Metodo que captura el evento del teclado solo alfanumerico
	 * @param evento 
	 */
	soloAlfanumericos(evento){

		if(evento.shiftKey || evento.ctrlKey || evento.altKey || evento.code =="BracketLeft"){
			return false;
		}

		if(evento.code == "Backspace" 
		|| evento.code == "Enter"
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"){
			return true;
		}
		
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || evento.keyCode >= 65 &&  evento.keyCode <= 90 ||
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}
	}	

  /**
   * Metodo de descarga 
   * @param objeto 
   */
  onDescargarAdjunto(objeto){
    if(objeto.adjunto != null){
      window.open(objeto.adjunto);
    }else{
      if(objeto.rutaAdjunto != null){
        window.open(objeto.rutaAdjunto);
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El sistema no encontró el archivo a descargar",
          life: 10000
        });
      }
    }
  }

}
