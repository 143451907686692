import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { CtoNovedadContratoFuenteDTO } from '../../../../contratacion-dto/cto-novedad-contrato-fuente';
import { NovedadesConstractualesService } from '../../novedades-contractuales.service';
import { CtoNovedadContratoDTO } from '../../../../contratacion-dto/cto-novedad-contrato';
import { CtoFuenteFinanciacionDTO } from 'src/app/contratacion/contratacion-dto/cto-fuente-financiacion';
import { EnumCtoOrigenRecurso, EnumCtoTipoNovedadContrato } from '../../../../constantes/contratacion-constantes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { CtoTipoNovedadDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-novedad';
import { CtoImputacionRecursoDTO } from 'src/app/contratacion/contratacion-dto/cto-imputacion-recurso';
import { CtoTipoRecursoDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-recurso';
import { NovedadContratoBaseComponent } from '../novedad-contrato-base/novedad-contrato-base.component';

@Component({
  selector: 'app-novedad-contrato-fuente-financiacion',
  templateUrl: './novedad-contrato-fuente-financiacion.component.html',
  styles: []
})
export class NovedadContratoFuenteFinanciacionComponent implements OnInit {

  @Input()
  novedadContrato: CtoNovedadContratoDTO;
  @Input()
  ctoContratoInformacionVigente: CtoContratoInformacionVigenteEncabezadoDTO;
  @Input()
  tipoNovedad: CtoTipoNovedadDTO;
  @Output()
  fuentesNovedadPorContratoOutput = new EventEmitter<CtoNovedadContratoDTO>();

  fuenteNovedadSeleccionada: CtoNovedadContratoFuenteDTO;
  fuentesFinanciacion: CtoFuenteFinanciacionDTO[];
  fuenteFinanciacionSeleccionada: CtoFuenteFinanciacionDTO;
  mostrarInformacionPresupuestal = false;

  visibleDialogoAgregarRegistro = false;
  visibleDialogoExito = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;

  valorInicialContratoCorregidoCalculado: number;
  ctoImputacionRecursos: CtoImputacionRecursoDTO[];
  ctoImputacionRecursosSeleccionada: CtoImputacionRecursoDTO;
  ctoTiposRecurso: CtoTipoRecursoDTO[];
  ctoTiposRecursoSeleccionada: CtoTipoRecursoDTO;
  mostrarTipoRecurso = true;


  constructor(
    public lineaBase: NovedadContratoBaseComponent,
    private contratacionService: ContratacionService,
    private novedadesConstractualesService: NovedadesConstractualesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {


    if (this.tipoNovedad != null && this.tipoNovedad.id == EnumCtoTipoNovedadContrato.CTO_TIPO_NOVEDAD_OTROSI) {
      this.cargarImputacionRecursos(null);
      this.cargarTiposRecurso(null);
      if (this.novedadContrato.id == 0) {
        this.consultarNovedadContratoFuentesFinanciacionIniciales();
      }
    }

    if (this.novedadContrato != null && this.novedadContrato.id != 0) {
      this.consultarNovedadContratoFuentesFinanciacionPorNovedad();
    }
    this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
  }

  cargarImputacionRecursos(event) {
    const filtro = [];
    this.contratacionService.getCtoImputacionRecurso()
      .subscribe(result => {
        if (result != null) {
          this.ctoImputacionRecursos = result;
          this.ctoImputacionRecursosSeleccionada = this.ctoImputacionRecursos.find(t => Number(t.id) == this.ctoContratoInformacionVigente.idImputacionRecurso);
          this.novedadContrato.idImputacionRecurso = this.ctoContratoInformacionVigente.idImputacionRecurso;

          if (event != null) {
            let existeFiltro: boolean;
            for (const reg of this.ctoImputacionRecursos) {
              if (String(reg.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtro.push(reg);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.ctoImputacionRecursos = filtro;
            }
          }
        }
      },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
      );
  }

  cargarTiposRecurso(event) {
    const filtro = [];
    this.contratacionService.getCtoTipoRecurso()
      .subscribe(result => {
        if (result != null) {
          this.ctoTiposRecurso = result;
          this.ctoTiposRecursoSeleccionada = this.ctoTiposRecurso.find(t => Number(t.id) == this.ctoContratoInformacionVigente.idTipoRecurso);
          this.novedadContrato.idTipoRecurso = this.ctoContratoInformacionVigente.idTipoRecurso;
          if (event != null) {
            let existeFiltro: boolean;
            for (const reg of this.ctoTiposRecurso) {
              if (String(reg.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                filtro.push(reg);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.ctoTiposRecurso = filtro;
            }
          }
        }
      },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        }
      );
  }

  consultarNovedadContratoFuentesFinanciacionIniciales() {
    this.novedadesConstractualesService.ConsultarNovedadContratoFuentesFinanciacionIniciales(this.ctoContratoInformacionVigente.id).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.novedadContrato.novedadContratoFuentes = result.novedadContratoFuentes;
          } else {
            this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
            console.error(result.respuestaServicio.mensajeSalida);
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
      }
    );
  }

  cargarFuentesFinanciacion() {
    let cargarFiducias = false;
    if (this.tipoNovedad != null && this.tipoNovedad.id == EnumCtoTipoNovedadContrato.CTO_TIPO_NOVEDAD_OTROSI && this.ctoImputacionRecursosSeleccionada != null) {

      // Si es un Otro sí cargar información de imputación de recursos y por ende filtrar las fuentes
      if (this.ctoImputacionRecursosSeleccionada.idOrigenRecurso != null) {
        if (this.ctoImputacionRecursosSeleccionada.idOrigenRecurso == EnumCtoOrigenRecurso.ANI) {
          // Ani
          cargarFiducias = false;
        } else if (this.ctoImputacionRecursosSeleccionada.idOrigenRecurso == EnumCtoOrigenRecurso.PATRIMONIO_AUTONOMO) {
          // Patrimonio autónomo
          cargarFiducias = true;
        } else {
          cargarFiducias = null;
        }

        // 1	Funcionamiento	1
        // 2	Inversión	1
        // 3	Fiducia	2
        // 4	Funcionamiento, fiducia	3
        // 5	Inversión, fiducia	3

        this.contratacionService.GetCtoMaFuenteFinanciacionFiltroFiducias(cargarFiducias).subscribe(
          result => {
            this.fuentesFinanciacion = result;
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          }
        );
      }
    } else {
      this.contratacionService.GetFuentesFinanciacion()
        .subscribe(
          result => {
            this.fuentesFinanciacion = result;
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          }
        );
    }
  }

  consultarNovedadContratoFuentesFinanciacionPorNovedad() {
    this.novedadesConstractualesService.ConsultarNovedadContratoFuentesFinanciacionPorNovedad(this.novedadContrato.id).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.novedadContrato.novedadContratoFuentes = result.novedadContratoFuentes;
          } else {
            this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
            console.error(result.respuestaServicio.mensajeSalida);
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);

        }
      },
      error => {
        this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);

        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
      }
    );
  }


  irAgregarFuenteNovedadContrato() {
    this.visibleDialogoAgregarRegistro = true;
    this.fuenteFinanciacionSeleccionada = null;
    this.fuenteNovedadSeleccionada = {};
    this.mostrarInformacionPresupuestal = false;
  }

  validarMostrarInformacionPresupuestal() {
    this.fuenteNovedadSeleccionada = {};
    if (this.fuenteFinanciacionSeleccionada != null) {
      if (this.fuenteFinanciacionSeleccionada.id === 1) {
        this.mostrarInformacionPresupuestal = true;
      } else {
        this.mostrarInformacionPresupuestal = false;
      }
    }
  }

  actualizarNovedadImputacionRecursos() {
    this.novedadContrato.idImputacionRecurso = this.ctoImputacionRecursosSeleccionada.id;
    this.ctoTiposRecursoSeleccionada = null;
    // Actualizar tipos de recurso
    if (this.ctoImputacionRecursosSeleccionada != null && this.ctoImputacionRecursosSeleccionada.idOrigenRecurso != null) {
      switch (this.ctoImputacionRecursosSeleccionada.idOrigenRecurso) {
        case 1: // Ani
          this.mostrarTipoRecurso = true;
          break;
        case 2: // Patrimonio autónomo
          this.mostrarTipoRecurso = false;
          break;
        case 3: // Mixto
          this.mostrarTipoRecurso = true;
          break;
      }
    }
  }

  actualizarNovedadTipoRecursos() {
    this.novedadContrato.idTipoRecurso = this.ctoTiposRecursoSeleccionada.id;
    // Actualizar tipos de recurso
    if (this.ctoImputacionRecursosSeleccionada != null && this.ctoImputacionRecursosSeleccionada.idOrigenRecurso != null) {
      switch (this.ctoImputacionRecursosSeleccionada.idOrigenRecurso) {
        case EnumCtoOrigenRecurso.ANI: // Ani
          this.mostrarTipoRecurso = true;
          break;
        case EnumCtoOrigenRecurso.PATRIMONIO_AUTONOMO: // Patrimonio autónomo
          this.mostrarTipoRecurso = false;
          break;
        case EnumCtoOrigenRecurso.MIXTO: // Mixto
          this.mostrarTipoRecurso = true;
          break;
      }
    }
  }

  aceptarAgregarFuenteNovedadContrato() {
    if (this.validarAgregarFuenteNovedad()) {

      if (this.novedadContrato.novedadContratoFuentes == null) {
        this.novedadContrato.novedadContratoFuentes = [];
      }

      if (this.fuenteFinanciacionSeleccionada != null && this.fuenteNovedadSeleccionada != null) {
        this.fuenteNovedadSeleccionada.idFuente = this.fuenteFinanciacionSeleccionada.id;
        this.fuenteNovedadSeleccionada.nitFuente = this.fuenteFinanciacionSeleccionada.nit;
        this.fuenteNovedadSeleccionada.digitoVerificacionFuente = this.fuenteFinanciacionSeleccionada.digitoVerificacion;
        this.fuenteNovedadSeleccionada.razonSocialFuente = this.fuenteFinanciacionSeleccionada.razonSocial;
        this.novedadContrato.novedadContratoFuentes.push(this.fuenteNovedadSeleccionada);
        this.valorInicialContratoCorregidoCalculado = this.novedadContrato.novedadContratoFuentes.reduce((sum, c) => sum + c.valor, 0);

        // Emitter para notificar al compoenente de la novedad;
        this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);
        this.fuenteFinanciacionSeleccionada = null;
        this.visibleDialogoAgregarRegistro = false;
      }
    }
  }

  validarAgregarFuenteNovedad(): boolean {
    let validacionFuente = true;
    let sumaValoresFuente = 0;
    // El valor máximo para adicionar al coantrato es el 50% del valor inicial
    const sumaValorMaximoTotalContrato = (this.ctoContratoInformacionVigente.valorInicialContrato * 0.5);

    if (this.fuenteFinanciacionSeleccionada != null) {
      if (this.fuenteFinanciacionSeleccionada.id === EnumCtoOrigenRecurso.ANI) {

        if (this.fuenteNovedadSeleccionada == null) {
          validacionFuente = false;
          this.lineaBase.lanzarMensajeError('No ha ingresado la información presupuestal');
        } else {
          if (this.fuenteNovedadSeleccionada.fechaCdp != null && this.ctoContratoInformacionVigente.fechaTerminacionContrato < this.fuenteNovedadSeleccionada.fechaCdp) {
            validacionFuente = false;
            this.lineaBase.lanzarMensajeError('La fecha del CDP debe ser menor o igual a la fecha finalización del contrato');
          }

          // if (this.fuenteNovedadSeleccionada.fechaRp != null && this.ctoContratoInformacionVigente.fechaTerminacionContrato < this.fuenteNovedadSeleccionada.fechaRp) {
          //   validacionFuente = false;
          //   console.error('La fecha del RP debe ser menor o igual a la fecha finalización del contrato');
          //   this.lineaBase.lanzarMensajeError('La fecha del RP debe ser menor o igual a la fecha finalización del contrato');
          // }

          if (this.fuenteNovedadSeleccionada.fechaRp != null && this.fuenteNovedadSeleccionada.fechaCdp != null) {
            if (this.fuenteNovedadSeleccionada.fechaRp < this.fuenteNovedadSeleccionada.fechaCdp) {
              validacionFuente = false;
              this.lineaBase.lanzarMensajeError('La fecha del RP debe ser mayor o igual a la fecha de CDP');
            }
          }
        }
      }

      if (this.novedadContrato.novedadContratoFuentes != null) {
        this.novedadContrato.novedadContratoFuentes.forEach(val => {
          if (this.fuenteFinanciacionSeleccionada.id === val.idFuente) {
            validacionFuente = false;
            this.lineaBase.lanzarMensajeError('La fuente ya se encuentra relacionada al contrato');
          }
          sumaValoresFuente = sumaValoresFuente + val.valor;
        });

        //Se valida el valor maximo de las fuentes para Adicion y Adicion y prorroga
        //Se desactiva esta validación ya que en las cesiones se adiciona y libera el valor pendiente por ejecutar y no el 50% del valor del contrato
        // if (this.tipoNovedad == null || this.tipoNovedad.id == EnumCtoTipoNovedadContrato.CTO_TIPO_NOVEDAD_ADICION_PRORROGA || this.tipoNovedad.id == EnumCtoTipoNovedadContrato.CTO_TIPO_NOVEDAD_ADICION) {
        //   sumaValoresFuente = sumaValoresFuente + this.fuenteNovedadSeleccionada.valor;
        //   if (sumaValoresFuente > sumaValorMaximoTotalContrato) {
        //     validacionFuente = false;
        //     console.error('El valor de las fuentes supera el valor máximo para adicionar al contrato: ' + sumaValorMaximoTotalContrato);
        //     this.lineaBase.lanzarMensajeError('El valor de las fuentes supera el valor máximo para adicionar al contrato: ' + sumaValorMaximoTotalContrato);
        //   }
        // }
      }
    } else {
      validacionFuente = false;
      this.lineaBase.lanzarMensajeError('Debe seleccionar la fuente de financiación');
    }

    return validacionFuente;
  }


  cancelarAgregarFuenteNovedadContrato() {
    this.visibleDialogoAgregarRegistro = false;
    this.fuenteFinanciacionSeleccionada = null;
    this.fuenteNovedadSeleccionada = null;
  }

  irEliminarFuenteNovedadContrato(fuenteNovedad: CtoNovedadContratoFuenteDTO) {
    this.fuenteNovedadSeleccionada = fuenteNovedad;
    this.visibleDialogoConfirmarEliminarRegistro = true;
  }

  cancelarEliminarRegistro() {
    this.visibleDialogoConfirmarEliminarRegistro = false;
  }

  aceptarEliminarRegistro() {
    // Eliminarlo del arreglo y //Emitter para notificar al compoenente de la novedad;
    this.novedadContrato.novedadContratoFuentes = this.novedadContrato.novedadContratoFuentes.filter(fun => fun.idFuente !== this.fuenteNovedadSeleccionada.idFuente);
    // this.fuentesNovedadPorContratoOutput.emit(this.fuentesNovedadPorContrato);
    this.fuentesNovedadPorContratoOutput.emit(this.novedadContrato);

    this.fuenteNovedadSeleccionada = null;
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleDialogoConfirmarEliminarRegistro = false;
  }

  get EnumCtoTipoNovedadContrato() {
    return EnumCtoTipoNovedadContrato;
  }
}
