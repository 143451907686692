import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EnumFechaFormato, EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumCmsConstantes, EnumCmsMaTipoModificacionComision } from '../../comisiones-constantes';
import { VwCmsComision } from '../../comisiones.dto';
import { ComisionesService } from '../../comisiones.service';

@Component({
  selector: 'app-comisiones-gestion-presupuestal-comisiones',
  templateUrl: './comisiones-gestion-presupuestal-comisiones.component.html',
  styles: []
})
export class ComisionesGestionPresupuestalComisionesComponent implements OnInit {
  solicitudList: VwCmsComision[];
  blockedDocument = true;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private datepipe: DatePipe,
    private comisionesService: ComisionesService,
  ) {
  }

  ngOnInit() {
    this.cargarComisiones();
  }

  cargarComisiones() {
    this.comisionesService.srvConsultarComisionesAprobadas().subscribe(
      result => {
        if (result != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.solicitudList = result.comisiones;
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  irGestionarRP(solicitud: VwCmsComision) {
    if (solicitud.idAsignacion != null) {
      this.guardarFechaInicioRevision(solicitud.idAsignacion);
    }
    this.router.navigate([this.router.url + "/comisiones-gestion-presupuestal-registro/" + solicitud.idComision + "/" + solicitud.idComisionVersion + '/' + solicitud.idAsignacion]);
  }

  guardarFechaInicioRevision(idAsignacion: number) {
    this.comisionesService.srvGuardarFechaInicioRevision(idAsignacion).subscribe(
      (result) => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  bntVolver() {
    this.router.navigate(["/comisiones"]);
  }

  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

}
