import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CtoNovedadContratoDTO } from '../../../../contratacion-dto/cto-novedad-contrato';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { MessageService } from 'primeng/api';
import { NovedadContratoBaseComponent } from '../novedad-contrato-base/novedad-contrato-base.component';
import { TrnPersona } from '../../../../../admin-personas/trn-persona';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoTipoNovedadDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-novedad';
import { TrnSiNo } from 'src/app/parametricas/trn-si-no';

@Component({
  selector: 'app-novedad-contrato-cesion',
  templateUrl: './novedad-contrato-cesion.component.html',
  styles: []
})
export class NovedadContratoCesionComponent implements OnInit {

  @Input()
  novedadContrato: CtoNovedadContratoDTO = {};
  @Input()
  ctoContratoInformacionVigente: CtoContratoInformacionVigenteEncabezadoDTO;
  @Input()
  tipoNovedad: CtoTipoNovedadDTO;
  @Output()
  ctoNovedadOutput = new EventEmitter<CtoNovedadContratoDTO>();
  contratistaSeleccionadoAsociar: TrnPersona;

  visualizarDialogoContratista = false;


  constructor(
    public lineaBase: NovedadContratoBaseComponent,
    private personaService: PersonaService,
    private principalComponent: PrincipalComponent
  ) { }


  ngOnInit() {
    this.novedadContrato.novedadContratoFuentes = null;
  }

  /**
   * Metodo que valida y emite el objeto de CtoNovedadContratoDTO al metodo principal de guardarNovedad al dar clic en el botón guardar
   */
  guardarNovedad() {
    if (this.validarNovedad()) {
      this.ctoNovedadOutput.emit(this.novedadContrato);
    }
  }

  /**
  * Metodo de evento de seleción para determinar si un valor es nulo, verdadero o falso, y se asigna a la variable EsResponsableIva.
  * Se agrega validación cuando la variable esResponsableIva es verdadera, para cambiar el estado a null de la variable estaObligadoFacturar 
  * @param sinoSeleccion Objeto de tipo TrnSiNo
  */
  onSelectEsResponsableIva(sinoSeleccion: TrnSiNo) {
    this.novedadContrato.esResponsableIva = sinoSeleccion.valor;
    if (this.novedadContrato.esResponsableIva) {
      this.novedadContrato.estaObligadoFacturar = null;
    }
  }

  /**
   * Metodo de evento de seleción para determinar si un valor es nulo, verdadero o falso, y se asigna a la variable EstaObligadoFacturar.
   * @param sinoSeleccion Objeto de tipo TrnSiNo
   */
  onSelectEstaObligadoFacturar(sinoSeleccion: TrnSiNo) {
    this.novedadContrato.estaObligadoFacturar = sinoSeleccion.valor;
  }

  /**
   * Metodo para asociar una persona para ser el cedente del resultado del componente generico
   * @param persona Objeto con la información de la persona encontrada
   */
  asociarContratista(persona) {
    if (persona != null && persona.idPersona != null && persona.idPersona !== -1 && persona.tipoDocumento != null) {
      switch (persona.tipoDocumento.id) {
        case EnumTipoDocumento.CC:
          this.novedadContrato.idPersona = persona.idPersona;
          this.novedadContrato.idPersonaJuridica = null;
          this.consultarPersona(this.novedadContrato.idPersona, persona.tipoDocumento.id);
          break;
        case EnumTipoDocumento.NIT:
        case EnumTipoDocumento.NIEE:
          this.novedadContrato.idPersona = null;
          this.novedadContrato.idPersonaJuridica = persona.idPersona;
          this.consultarPersona(this.novedadContrato.idPersonaJuridica, persona.tipoDocumento.id);
          break;
      }
      this.visualizarDialogoContratista = false;
    }
  }

  /**
   * Metodo que consulta una persona dado sú id y tipo de documento
   * @param idPersona Id de la persona 
   * @param idTipoDocumento Id Tipo de Documento
   */
  consultarPersona(idPersona: number, idTipoDocumento: string) {
    this.personaService.ConsultarDatosBasicosPersonaId(idTipoDocumento, idPersona).subscribe(
      result => {
        this.contratistaSeleccionadoAsociar = result.persona;
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  /**
   * Metodo que valida la información a guardar para la novedad
   * @returns Verdadero o Falso
   */
  validarNovedad(): boolean {

    let esNovedadValida = true;

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion > this.ctoContratoInformacionVigente.fechaTerminacionContrato) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMayorFechaFinContrato);
    }

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion < this.ctoContratoInformacionVigente.fechainicio) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMenorFechaInicialContrato);
    }

    if (this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion > this.ctoContratoInformacionVigente.fechaTerminacionContrato) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMayorFechaFinContrato);
    }

    if (this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion < this.ctoContratoInformacionVigente.fechainicio) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMenorFechaInicialContrato);
    }

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion < this.novedadContrato.fechaSuscripcion) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMenorFechaSuscripcion);
    }

    if (this.novedadContrato.esResponsableIva == null) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValCesionEsResponsableIvaNulo);
    }

    if (this.novedadContrato.esResponsableIva != null && !this.novedadContrato.esResponsableIva && this.novedadContrato.estaObligadoFacturar == null) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValCesionEstaObligadoFacturarNulo);
    }

    if (this.contratistaSeleccionadoAsociar == null) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValCesionAsociarContratistaNulo);
    } else {
      // ES PERSONA NATURAL
      if (this.contratistaSeleccionadoAsociar.idTipoDocumento == EnumTipoDocumento.CC && this.contratistaSeleccionadoAsociar.id == this.ctoContratoInformacionVigente.idPersona) {
        esNovedadValida = false;
        this.lineaBase.lanzarMensajeError(this.lineaBase.msgValCesionAsociarMismoContratista);
      }
      // ES PERSONA JURIDICA
      if (this.contratistaSeleccionadoAsociar.idTipoDocumento == EnumTipoDocumento.NIT && this.contratistaSeleccionadoAsociar.id == this.ctoContratoInformacionVigente.idPersonaJuridica) {
        esNovedadValida = false;
        this.lineaBase.lanzarMensajeError(this.lineaBase.msgValCesionAsociarMismoContratista);
      }
    }
    return esNovedadValida;
  }



}
