import { Component, OnInit } from '@angular/core';
import { ProyectoDTO } from '../componentes-comunes/componentes-comunes.dto';
import { ComponentesComunesService } from '../componentes-comunes/componentes-comunes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-predios',
  templateUrl: './predios.component.html',
  styleUrls: ['./predios.component.sass']
})
export class PrediosComponent implements OnInit {
  loading: boolean;
  idProyecto: number;

  proyectoSeleccionado: ProyectoDTO;

  constructor(
    private router: Router,
    private datepipe: DatePipe,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService
  ) { }

  ngOnInit() {
    const idParam = 'id';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      forkJoin(
        this.componentesComunesService.obtenerProyecto(this.idProyecto)
      ).subscribe(
        ([proyectoSeleccionadoData]) => {
          this.proyectoSeleccionado = proyectoSeleccionadoData;
        },
        (e) => { }
      );
    });
  }

  irARegistroAgrupado() {
    this.router.navigate([
      this.router.url + '/registro-agrupado-predios/' + this.idProyecto,
    ]);
  }

  irARegistroDetallado() {
    this.router.navigate([
      this.router.url + '/registro-detallado-predios/' + this.idProyecto,
    ]);
  }

  irACargaMasiva() {
    this.router.navigate([
      this.router.url + '/carga-masiva-predios/' + this.idProyecto,
    ]);
  }

  irInformeEjecutivo(){
    const reporteExcelHoja1: any[] = [];
    const reporteExcelHoja2: any[] = [];
    const reporteExcelHoja3: any[] = [];
    const reporteExcelHoja4: any[] = [];
    const reporteExcelHoja5: any[] = [];
    const reporteExcelHoja6: any[] = [];
    this.loading = true;

    forkJoin(
      this.componentesComunesService.listarPrediosPorProyecto(this.idProyecto),
      this.componentesComunesService.listarPrdDisponibilidadPrediosPorProyecto(this.idProyecto),
      this.componentesComunesService.listarPrdMargenPrediosPorProyecto(this.idProyecto),
      this.componentesComunesService.listarPrdPropietariosPrediosPorProyecto(this.idProyecto),
      this.componentesComunesService.listarPrdDocumentoPrediosPorProyecto(this.idProyecto)
    ).subscribe(([data1, data2, data3, data4, data5]) => {
      if(data1.predios.length > 0 ){
        data1.predios.forEach(rpt => {
          const hoja1 = {
            FichaPredial: rpt.fichaPredial,
            UnidadFuncional: rpt.nombreUnidadFuncional,
            Subtramo: rpt.nombreSubtramo,
            'Longitud Efectiva en Metros': rpt.longitudEfectiva,
            'Area Terreno Requerida': rpt.areaTerrenoRequerido,
            'Valor Avaluo': rpt.valorAvaluo,
            Estado:  ((rpt.borrador)? "Borrador" : "Publicado"),
          }
          reporteExcelHoja1.push(hoja1);
        });
      }else{
        const hoja1 = {
          FichaPredial: '',
          UnidadFuncional: '',
          Subtramo: '',
          'Longitud Efectiva en Metros': '',
          'Area Terreno Requerida': '',
          'Valor Avaluo': '',
          Estado:  ''
        }
        reporteExcelHoja1.push(hoja1);
      }

      if(data1.predios.length > 0 ){
        data1.predios.forEach(rpt => {
          const hoja1 = {
            'FichaPredial': rpt.fichaPredial,
            'Naturaleza Jurídica': rpt.nombreNaturalezaJuridica,
            'Cedula Catastral': rpt.cedulaCatastral,
            'Folio Matricula': rpt.folioMatricula,
            'Valor Avaluo': rpt.valorAvaluo,
            'Unidad Funcional': rpt.nombreUnidadFuncional,
            'Subtramo': rpt.nombreSubtramo,
            'Area Terreno Requerido': rpt.areaTerrenoRequerido,
            'Unidad de medida': rpt.unidadMedidaTerrenoRequerido,
            'Longitud Efectiva': rpt.longitudEfectiva,
            'Departamento': rpt.departamento,
            'Municipio': rpt.nombreMunicipio,
            'Abscisa Inicial': rpt.abscisaInicial,
            'Abscisa Final': rpt.abscisaFinal,
            'Predio Requerido':  ((rpt.predioRequerido)? "Si" : "No"),
            'Predio Afectado': ((rpt.predioAfectado)? "Si" : "No"),
            'Uso del Suelo': rpt.nombreUsoSuelo,
            'Predio Ofertado': ((rpt.predioOfertado)? "Si" : "No"),
            'Predio Reportado en NIIF': ((rpt.predioReportadoFormatoNiif)? "Si" : "No"),
            'Trimestre del año que reporto NIIF': rpt.trimestreAnioReporteNiif,
            'Area Remanente no desarrollable': ((rpt.areaRemanenteNoDesarrollable)? "Si" : "No"),
            'Area Remanente en metros cuadrados': rpt.areaRemanente,
            'Predio Sobrante': ((rpt.predioSobrante)? "Si" : "No"),
            'Area Predio Sobrante': rpt.areaPredioSobrante,
            'Predio Archivado Fisico ANI': ((rpt.predioArchivadoFiisicoAni)? "Si" : "No"),
            'Numero Radicación': rpt.numeroRadicacionAni,
            'Fecha Radicación': (rpt.fechaRadicacionAni != null)? this.datepipe.transform(rpt.fechaRadicacionAni, 'dd/MM/yyyy') : null
          }
          reporteExcelHoja2.push(hoja1);
        });
      }else{
        const hoja1 = {
          'FichaPredial': '',
          'Naturaleza Jurídica': '',
          'Cedula Catastral': '',
          'Folio Matricula': '',
          'Valor Avaluo': '',
          'Unidad Funcional': '',
          'Subtramo': '',
          'Area Terreno Requerido': '',
          'Unidad de medida': '',
          'Longitud Efectiva': '',
          'Departamento': '',
          'Municipio': '',
          'Abscisa Inicial': '',
          'Abscisa Final': '',
          'Predio Requerido':  '',
          'Predio Afectado': '',
          'Uso del Suelo': '',
          'Predio Ofertado': '',
          'Predio Reportado en NIIF': '',
          'Trimestre del año que reporto NIIF': '',
          'Area Remanente no desarrollable': '',
          'Area Remanente en metros cuadrados': '',
          'Predio Sobrante': '',
          'Area Predio Sobrante': '',
          'Predio Archivado Fisico ANI': '',
          'Numero Radicación': '',
          'Fecha Radicación': ''
        }
        reporteExcelHoja2.push(hoja1);
      }

      if(data2.listaPrdDisponibilidadPredios.length > 0 ){
        data2.listaPrdDisponibilidadPredios.forEach(rpt => {
          const hoja1 = {
            FichaPredial: rpt.fichaPredial,
            'El predio esta Disponible': ((rpt.predioDisponible)? "Si" : "No"),
            'Fecha de Disponibilidad': (rpt.fechaDisponible != null)? this.datepipe.transform(rpt.fechaDisponible, 'dd/MM/yyyy') : null,
            'El Predio esta Adquirido': ((rpt.predioAdquirido)? "Si" : "No"),
            'Proceso de Adquision': rpt.procesoAdquisicion,
            'Observaciones': rpt.observaciones
          }
          reporteExcelHoja3.push(hoja1);
        });
      }else{
        const hoja1 = {
          FichaPredial: '',
          'El predio esta Disponible': '',
          'Fecha de Disponibilidad': '',
          'El Predio esta Adquirido': '',
          'Proceso de Adquision': '',
          'Observaciones': ''
        }
        reporteExcelHoja3.push(hoja1);
      }

      if(data3.listaPrdMargen.length > 0 ){
        data3.listaPrdMargen.forEach(rpt => {
          const hoja1 = {
            FichaPredial: rpt.fichaPredial,
            'Margen del Predio': rpt.tipoMargen,
            'Abscisa Inicial Izquierda': rpt.abscisaInicialIzquierda,
            'Abscisa Inicial Derecha': rpt.abscisaInicialDerecha,
            'Abscisa Final Izquierda': rpt.abscisaFinalIzquierda,
            'Abscisa Final Derecha': rpt.abscisaFinalDerecha,
            'Longitud Efectiva Izquierda (m)': rpt.longitudEfectivaIzquierda,
            'Longitud Efectiva Derecha (m)': rpt.longitudEfectivaDerecha
          }
          reporteExcelHoja4.push(hoja1);
        });
      }else{
        const hoja1 = {
          FichaPredial: '',
          'Margen del Predio': '',
          'Abscisa Inicial Izquierda': '',
          'Abscisa Inicial Derecha': '',
          'Abscisa Final Izquierda': '',
          'Abscisa Final Derecha': '',
          'Longitud Efectiva Izquierda (m)': '',
          'Longitud Efectiva Derecha (m)': ''
        }
        reporteExcelHoja4.push(hoja1);
      }

      if(data4.listaPrdPropietarios.length > 0 ){
        data4.listaPrdPropietarios.forEach(rpt => {
          const hoja1 = {
            FichaPredial: rpt.fichaPredial,
            'Numero Documento': rpt.numeroIdentificacion,
            'Tipo de Propietario': rpt.tipoPersona,
            'Nombre': rpt.nombres,
            'Porcentaje de Participación': rpt.porcentajeParticipacion *100
          }
          reporteExcelHoja5.push(hoja1);
        });
      }else{
        const hoja1 = {
          FichaPredial: '',
          'Numero Documento': '',
          'Tipo de Propietario': '',
          'Nombre': '',
          'Porcentaje de Participación': ''
        }
        reporteExcelHoja5.push(hoja1);
      }

      if(data5.listaPrdDocumentoPredio.length > 0 ){
        data5.listaPrdDocumentoPredio.forEach(rpt => {
          const hoja1 = {
            FichaPredial: rpt.fichaPredial,
            'Tipo Documento': rpt.tipoDocumento,
            'Nombre Documento': rpt.nombreDocumento,
            'Fecha del Documento': (rpt.fechaDocumento != null)? this.datepipe.transform(rpt.fechaDocumento, 'dd/MM/yyyy') : null,
            'Observaciones': rpt.observaciones,
            'Estado': rpt.estado
          }
          reporteExcelHoja6.push(hoja1);
        });
      }else{
        const hoja1 = {
          FichaPredial: '',
          'Tipo Documento': '',
          'Nombre Documento': '',
          'Fecha del Documento': '',
          'Observaciones': '',
          'Estado': ''
        }
        reporteExcelHoja6.push(hoja1);
      }

      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(reporteExcelHoja1);
        const worksheet2 = xlsx.utils.json_to_sheet(reporteExcelHoja2);
        const worksheet3 = xlsx.utils.json_to_sheet(reporteExcelHoja3);
        const worksheet4 = xlsx.utils.json_to_sheet(reporteExcelHoja4);
        const worksheet5 = xlsx.utils.json_to_sheet(reporteExcelHoja5);
        const worksheet6 = xlsx.utils.json_to_sheet(reporteExcelHoja6);

        worksheet['!cols'] = [
          {wch:25},{wch:50},{wch:50},{wch:25},{wch:25},{wch:20},{wch:20}];

        worksheet2['!cols'] = [
          {wch:25},{wch:20},{wch:25},{wch:20},{wch:20},{wch:40},
          {wch:40},{wch:20},{wch:20},{wch:20},{wch:20},{wch:20},
          {wch:20},{wch:20},{wch:20},{wch:20},{wch:20},{wch:20},
          {wch:22},{wch:30},{wch:30},{wch:30},{wch:20},{wch:20},
          {wch:25},{wch:20},{wch:20} ];

        worksheet3['!cols'] = [
          {wch:30},{wch:21},{wch:20},{wch:20},{wch:20},{wch:60} ];

        worksheet4['!cols'] = [
          {wch:60},{wch:20},{wch:20},{wch:20},{wch:20},{wch:20},{wch:20},{wch:20}];

        worksheet5['!cols'] = [
          {wch:30},{wch:20},{wch:20},{wch:40},{wch:30} ];

        worksheet6['!cols'] = [
          {wch:30},{wch:30},{wch:60},{wch:60},{wch:60},{wch:20} ];

        const workbook = {
          Sheets: {
            Predios: worksheet , Detalles: worksheet2, Disponibilidad: worksheet3, MargenDelPredio: worksheet4, Propietarios: worksheet5, Documentos: worksheet6
          }, SheetNames: ['Predios','Detalles','Disponibilidad','MargenDelPredio','Propietarios','Documentos'] };

        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'reportePredios');
      });

    }, error => {
      this.loading = false;
    },
      () => {
       this.loading = false;
      }
    );
  }

    /**
   * Metodo utilizado para descargar el archivo en formato xlsx generado
   * @param buffer
   * @param fileName
   */
    saveAsExcelFile(buffer: any, fileName: string): void {
      import('file-saver').then(FileSaver => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        this.loading = false;
      });
    }



}
