import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { EnumRegex, EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCtoPasoInforme } from 'src/app/contratacion/constantes/contratacion-constantes';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { CtoReporteCuentasCobroCxpDTO } from '../../../contratacion-dto/reporte-cuentas-cxp';

import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { CtoPasoInformeDTO } from 'src/app/contratacion/contratacion-dto/cto-paso-informe';
import { ReportesCuentasCobroCxpService } from '../../reporte-contratacion-cuentas-cobro.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-reporte-cuentas-cxp',
  templateUrl: './reporte-cuentas-cxp.component.html',
  styles: []
})
export class ReporteCuentasCxpComponent implements OnInit {
  // Informacion del datatable
  reporteCuentasCobroCxp: CtoReporteCuentasCobroCxpDTO[] = [];
  reporteExcelCuentasCobroCxp: CtoReporteCuentasCobroCxpDTO[] = [];
  totalRecords: number;
  loading: boolean;

  // Filtros
  idContratoFiltro?: number;
  numeroDocumentoContratistaFiltro?: string;
  fechaInicioPasoFiltro?: Date;
  fechaFinPasoFiltro?: Date;
  idPersonaRevisaInformeFiltro?: number;
  buscarFiltro: string;
  pasoInformeFiltro?: CtoPasoInformeDTO;

  pasosInformeList: CtoPasoInformeDTO[] = [];

  constructor(
    private router: Router,
    private messageService: MessageService,
    private datepipe: DatePipe,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private parametricasService: ParametricasService,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarPasosInforme();
  }

  loadDataLazy(event: LazyLoadEvent) {
    this.loading = true;
    this.reporteCuentasCobroCxp = [];
    this.consultarReporteCuentasCobroCxp(event.first, event.rows, event.sortField, event.sortOrder);
  }

  buscar() {
    const filaDesde = 0;
    const cantidadRegistros = 30;
    this.loading = true;
    this.consultarReporteCuentasCobroCxp(filaDesde, cantidadRegistros, 'fechaUltimoPaso', 2);
  }

  private consultarReporteCuentasCobroCxp(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    this.reporteCuentaCobroCxpService.srvConsultarReporteCuentasCobroCxp(this.idContratoFiltro, null, this.numeroDocumentoContratistaFiltro,
      this.pasoInformeFiltro, this.fechaInicioPasoFiltro, this.fechaFinPasoFiltro,
      filaDesde, cantidadRegistros, this.buscarFiltro, campoOrden, ascDesc).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.reporteCuentasCobroCxp = result.reporteCuentasCobroCxp;
              this.totalRecords = this.reporteCuentasCobroCxp != null && this.reporteCuentasCobroCxp[0] != null ? this.reporteCuentasCobroCxp[0].totalRegistros : 0;
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
          this.loading = false;
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.loading = false;
        }
      );
  }

  limpiarFiltros() {
    this.idContratoFiltro = null;
    this.numeroDocumentoContratistaFiltro = null;
    this.fechaInicioPasoFiltro = null;
    this.fechaFinPasoFiltro = null;
    this.buscarFiltro = null;
    this.pasoInformeFiltro = null;
    this.buscar();
  }


  cargarPasosInforme() {
    this.contratacionService.getPasosInforme().subscribe(
      result => {
        if (result != null) {
          this.pasosInformeList = result;
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO, error);
        this.principalComponent.cargarErrorServicio(error);
        this.loading = false;
      }
    );
  }

  irConsultarCuenta(cuenta: CtoReporteCuentasCobroCxpDTO) {
    this.router.navigate([this.router.url + '/reporteCuentaCxpDetalle', cuenta.idContrato, cuenta.id]);
  }

  autocompletarPasoInforme(event) {
    const filtroCtoPasoInforme = [];
    let existeFiltro: boolean;
    for (const paso of this.pasosInformeList) {
      if (String(paso.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroCtoPasoInforme.push(paso);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.pasosInformeList = filtroCtoPasoInforme;
    }
    return this.pasosInformeList;
  }


  exportExcel() {
    this.loading = true;
    this.reporteCuentaCobroCxpService.srvConsultarReporteCuentasCobroCxp(this.idContratoFiltro, null, this.numeroDocumentoContratistaFiltro,
      this.pasoInformeFiltro, this.fechaInicioPasoFiltro, this.fechaFinPasoFiltro,
      0, 99999999, this.buscarFiltro, 'fechaUltimoPaso', 2).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.reporteExcelCuentasCobroCxp = result.reporteCuentasCobroCxp;

              //flatten objects
              const rows = this.reporteCuentasCobroCxp.map(row => ({
                numeroContrato: row.numeroContrato,
                numeroDocumento: row.numeroDocumento,
                nombreContratista: row.nombreContratista,
                numeroPago: row.numeroPago,
                valorPeriodoPago: row.valorPago,
                fechaRecibido: this.datepipe.transform(row.fechaEstadoCentralCuentas, 'dd/MM/yyyy HH:mm:ss'),
                fechaInicioRevision: this.datepipe.transform(row.fechaInicioRevisionCentralCuentas, 'dd/MM/yyyy HH:mm:ss'),
                fechaRespuestaRevision: this.datepipe.transform(row.fechaRespuestaCentralCuentas, 'dd/MM/yyyy HH:mm:ss'),
                ultimoPaso: row.ultimoPaso,
                fechaUltimoPaso: this.datepipe.transform(row.fechaUltimoPaso, 'dd/MM/yyyy HH:mm:ss'),
              }));
              import('xlsx')
                .then(xlsx => {
                  const headings = [
                    [
                      "No.Contrato",
                      "No.Documento",
                      "Contratista",
                      "Número de pago",
                      "Valor periodo de pago",
                      "Turno asignado",
                      "Fecha recibido",
                      "Fecha inicio revisión",
                      "Fecha de respuesta revisión",
                      "Días Trasncurridos para Pago",
                      "Último paso",
                      "Fecha de último paso",
                    ]];
                  const worksheet = xlsx.utils.json_to_sheet(rows);
                  /* fix headers */
                  xlsx.utils.sheet_add_aoa(worksheet, headings);
                  const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                  const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                  this.saveAsExcelFile(excelBuffer, 'ctoReporteCxpCuentasCobro');
                });
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
          this.loading = false;
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.loading = false;
        }
      );

  }

  /**
   * Metodo utilizado para descargar el archivo en formato xlsx generado
   * @param buffer 
   * @param fileName Nombre del archivo descargado
   */
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }



  get EnumCtoPasoInforme() {
    return EnumCtoPasoInforme;
  }

  get EnumRegex() {
    return EnumRegex;
  }
}
