import { Component, OnInit, Inject  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TribunalesDetalleService } from './tribunales-detalle.service';
import { Tribunales } from '../detalle/tribunales';
import { MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-tribunales-detalle',
  templateUrl: './tribunales-detalle.component.html',
  styleUrls: ['./tribunales-detalle.component.sass']
})

export class TribunalesDetalleComponent implements OnInit {

  proyecto: Tribunales = {};
  idTribunalesDetalle: any;
  id: number;
  contratos;
  contratistaConvocados;
  contratistaConvocantes;
  nombreProyecto;
  dataSource: MatTableDataSource<any>;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>;

  constructor(
    private detalleService: TribunalesDetalleService,
    private route: ActivatedRoute,
    private ruta: Router,
    public dialog: MatDialog, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.proyecto.idProyecto = Number(JSON.parse(localStorage.getItem('idP')));
    this.cargarContrato();
    this.cargarContratistaConvocado();
    this.cargarContratistaConvocante();

    this.route.params.subscribe(params => {
      this.idTribunalesDetalle = params.id;
      this.idTribunalesDetalle === 'new' ? this.cargarNuevo(this.idTribunalesDetalle) : this.cargarProyectoTribunales(this.idTribunalesDetalle);
    });
    var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto);
  }

  cargarContrato() {
    return this.detalleService.getContrato(this.proyecto.idProyecto).subscribe((data: {}) => {
      this.contratos = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarContratistaConvocado() {
    return this.detalleService.getContratistaConvocado(this.proyecto.idProyecto).subscribe((data: {}) => {
      this.contratistaConvocados = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarContratistaConvocante() {
    return this.detalleService.getContratistaConvocante(this.proyecto.idProyecto).subscribe((data: {}) => {
      this.contratistaConvocantes = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarNuevo(id) {
    this.proyecto = {};
    this.reset();
  }

  reset(){
    this.proyecto.idProyecto = Number(JSON.parse(localStorage.getItem('idP')));
  }


  cargarProyectoTribunales(id: number) {
    this.detalleService.getproyectoTribunales(id).subscribe((data: {}) => {
      if (data) {
        this.proyecto = data;
      }
      this.dialogSpin.close();
    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  guardarDetalle(payload) {

    this.proyecto.idProyecto = Number(JSON.parse(localStorage.getItem('idP')));
    if( this.proyecto.idContratistaConvocado === this.proyecto.idContratistaConvocante) {
      alert('El contratista convocado no puede ser igual al contratista convocante')
    } else {
      this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true
      });
      if (this.idTribunalesDetalle === 'new') {
        return this.detalleService.guardarNuevoTribunal(payload).subscribe((data: {}) => {
          this.ruta.navigate(['/infTribunales', JSON.parse(localStorage.getItem('idP'))]);
          this.dialogSpin.close();
          },error => {
            this.dialogSpin.close();
            this.principalComponent.cargarErrorServicio(error);
          });
      } else {
          return this.detalleService.guardarDetalleTribunal(payload).subscribe((data: {}) => {
            this.ruta.navigate(['/infTribunales', JSON.parse(localStorage.getItem('idP'))]);
            this.dialogSpin.close();
            },error => {
              this.dialogSpin.close();
              this.principalComponent.cargarErrorServicio(error);
            });
      }
    }
  }

  convertStringDateToTime(data) {
    const date = new Date(data);
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = date.getTime() + localOffset;
    return utc;
  }

  cancelar() {
    this.ruta.navigate(['/infTribunales', JSON.parse(localStorage.getItem('idP'))]);
  }

}
