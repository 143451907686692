import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.sass']
})
export class MenuLateralComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  menulateral() {
    var element = document.getElementById("sideAni");
    element.classList.toggle("expandend");
  }

}
