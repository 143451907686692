import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PersonaService } from '../persona/persona.service';
import { ParametricasService } from '../../parametricas/parametricas.service';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { EnumRegex, EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-persona-juridica',
  templateUrl: './persona-juridica.component.html',
  styleUrls: ['./persona-juridica.component.sass']
})
export class PersonaJuridicaComponent implements OnInit {

  @Input()
  idTipoDocumento: string;
  @Output()
  idPersonaJuridicaVar = new EventEmitter<number>();
  @Output()
  cancelarComponenteVar = new EventEmitter<boolean>();

  persona: any = {};
  numeroDocumentoBuscar: number;
  tipoDocumentoVar: any = [];
  personasList: any = [];
  habilitarCampos = false;
  personaExistente = false;

  // Informacion para los dialogos
  visibleDialogoExito = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;

  // Listas
  tiposDocumentoList: any = [];
  // tiposDocumentoSeleccionado: any;
  filtroDepartamentos: any[];
  departamentos: any[];
  departamentoSeleccionado: any;
  filtroCiudades: any[];
  ciudadSeleccionada: any;
  ciudades: any[] = [];

  ngOnInit() {
    this.cargarTiposDocumento();
    this.cargarDepartamentos(null);


    if (this.idTipoDocumento != null) {
      this.persona.idTipoDocumento = this.idTipoDocumento;
    }
  }


  constructor(
    private messageService: MessageService,
    private svcPersonas: PersonaService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent) {
  }

  cargarTiposDocumento() {
    this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.NIT).subscribe(
      result => {
        this.tiposDocumentoList = result;
        if (this.tiposDocumentoList != null && this.idTipoDocumento != null) {
          this.tipoDocumentoVar = this.tiposDocumentoList.find(t => String(t.id).toLowerCase().includes(this.idTipoDocumento.toLowerCase()));
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }

  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe((data: any) => {
      this.departamentos = data;
      if (event != null) {
        let existeFiltro: boolean;
        for (let i = 0; i < this.departamentos.length; i++) {
          const filtro = this.departamentos[i];
          if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
            this.filtroDepartamentos.push(filtro);
            existeFiltro = true;
          }
        }
        if (existeFiltro) {
          this.departamentos = this.filtroDepartamentos;
        }
      }
    });
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      const filtro = this.departamentos[i];
      if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  limpiarCiudades(event) {
    this.ciudadSeleccionada = null;
  }

  cargarCiudades(event) {
    if (this.departamentoSeleccionado != null) {
      this.filtroCiudades = [];
      return this.parametricasService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id).subscribe(
        (data: any) => {
          this.ciudades = data;
          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.ciudades.length; i++) {
              const filtro = this.ciudades[i];
              if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
                this.filtroCiudades.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.ciudades = this.filtroCiudades;
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
    }
  }

  cargarFiltroCiudades(event) {
    this.filtroCiudades = [];
    for (let i = 0; i < this.ciudades.length; i++) {
      const filtro = this.ciudades[i];
      if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudades.push(filtro);
      }
    }
  }

  cargarPersonasJuridica() {
    return this.svcPersonas.getPersonasJurdicas()
      .subscribe(
        data => {
          this.personasList = data;
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  validarNumeroDocumento() {
    let errorValidacion = false;
    if (this.numeroDocumentoBuscar == null) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar un número de documento' });
    }
    if (this.numeroDocumentoBuscar != null && String(this.numeroDocumentoBuscar).length == 0) {
      errorValidacion = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar un número de documento' });
    }

    return errorValidacion;
  }

  buscarPersonaJuridicaPorTipoNumeroDocumento(event) {
    if (!this.validarNumeroDocumento()) {
      if (this.numeroDocumentoBuscar != null) {
        this.svcPersonas.getPersonaJurdicaPorTipoNumeroDocumento(EnumTipoDocumento.NIT, String(this.numeroDocumentoBuscar))
          .subscribe(
            result => {
              this.persona = result;
              if (this.persona == null) {
                this.persona = {};
                this.departamentoSeleccionado = {};
                this.ciudadSeleccionada = {};
                this.persona.idTipoDocumento = this.idTipoDocumento;
                this.persona.numeroDocumento = this.numeroDocumentoBuscar;
                this.habilitarCampos = true;
                this.personaExistente = false;
              } else {
                this.personaExistente = true;
                this.departamentoSeleccionado = {
                  id: this.persona.idDepartamentoMunicipioDomicilio,
                  nombre: this.persona.departamentoMunicipioDomicilio
                };

                this.ciudadSeleccionada = {
                  id: this.persona.idMunicipioDomicilio,
                  nombre: this.persona.municipioDomicilio
                };
                this.habilitarCampos = false;
              }

              return this.persona;
            },
            error => {
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
            }
          );
      }
    }
  }

  guardarPersonaJuridica(forma: NgForm) {

    if (this.persona != null && !this.personaExistente) {

      const personJuridicaPost = {
        Id: (this.persona.id != null && this.persona.id !== 0 ? this.persona.id : 0),
        IdTipoDocumento: this.persona.idTipoDocumento,
        NumeroDocumento: this.persona.numeroDocumento,
        DigitoVerificacion: this.persona.digitoVerificacion,
        Nombre: this.persona.nombre,
        RepresentanteLegal: this.persona.representanteLegal,
        IdMunicipioDomicilio: this.ciudadSeleccionada.id,
        DireccionDomicilio: this.persona.direccionDomicilio,
        NumeroCelular: this.persona.numeroCelular,
        CorreoElectronico: this.persona.correoElectronico,
        PaginaWeb: this.persona.paginaWeb,
        Observaciones: this.persona.observaciones
      };
      this.svcPersonas.setPersonaJuridica(personJuridicaPost)
        .subscribe(
          (data: any) => {
            this.lanzarMensajeInfo(data.resultado.message);
            this.habilitarCampos = false;
            this.idPersonaJuridicaVar.emit(data.resultado.id);
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
          }
        );
    } else {
      this.habilitarCampos = false;
      this.idPersonaJuridicaVar.emit(this.persona.id);
    }
  }


  cancelarAgregarPersonaJuridica(cancelar: boolean) {
    this.idTipoDocumento = null;
    this.persona = {};
    this.cancelarComponenteVar.emit(cancelar);
  }


  /**
   * Dialogos
   */
  ///////////////////////////////Mensajes//////////////////////////////
  onRejectMessage() {
    this.messageService.clear('toastPerJur');
  }
  lanzarMensajeInfo(mensaje: string) {
    this.onRejectMessage();
    this.messageService.add({ key: 'toastPerJur', severity: 'success', summary: 'Info', detail: mensaje });
  }

  lanzarMensajeError(mensaje: string) {
    this.onRejectMessage();
    this.messageService.add({ key: 'toastPerJur', severity: 'error', summary: 'Error', detail: mensaje });
  }

  lanzarMensajeWarning(mensaje: string) {
    this.onRejectMessage();
    this.messageService.add({ key: 'toastPerJur', severity: 'warn', summary: 'Advertencia', detail: mensaje });
  }
  
  get EnumRegex() {
    return EnumRegex;
  }
}
