import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contratacion-reportes-cuentas-cobro-home',
  templateUrl: './contratacion-reportes-cuentas-cobro-home.component.html',
  styles: []
})
export class ContratacionReportesCuentasCobroHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
