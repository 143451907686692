import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { C19ReporteCovidConsolidado, C19ReporteCovid } from '../../covid-reportar.dto';
import { CovidReportarService } from '../../covid-reportar.service';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-covid-reportar-proyecto',
  templateUrl: './covid-reportar-proyecto.component.html',
  styleUrls: ['./covid-reportar-proyecto.component.sass']
})
export class CovidReportarProyectoComponent implements OnInit {

  blockedDocument: boolean = false;

  @Input() reporteCovid: C19ReporteCovid;

  vistaActual: string = "";
  consolidadoCovidList: C19ReporteCovidConsolidado[];
  consolidadoCovid: C19ReporteCovidConsolidado;

  departamentos: any[];
  departamentoSeleccionado = { numero: -1, nombre: '' };

  seEstaEditando: boolean;

  //reporteCovidDetalleList: ReporteCovidDetalle[];
  //reporteCovidDetalle: ReporteCovidDetalle;

  constructor(
    private covidReportarService: CovidReportarService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.consolidadoCovid = {};

    //Construir array
    this.departamentos = [
      { numero: 1, nombre: 'CUNDINAMARCA' },
      { numero: 2, nombre: 'TOLIMA' }
    ];

    this.consultarConsolidadosPorReporte();
    this.irAReportes();
  }

  irAReportes() {
    this.vistaActual = "listaConsolidado";
  }

  irACrearReporte() {
    this.consolidadoCovid = {};
    this.departamentoSeleccionado = null;
    this.seEstaEditando = false;
    this.vistaActual = "reportarConsolidado";
  }

  outputSelectorMunicipio(_departamentoObj) {
    this.consolidadoCovid.idDepartamento = _departamentoObj.departamento.id;
  }

  consultarConsolidadosPorReporte() {

    if (this.reporteCovid != null && this.reporteCovid.id != null) {
      //Bloquear pantalla
      this.blockedDocument = true;

      forkJoin(
        this.covidReportarService.srvConsultarConsolidadoPorReporte(this.reporteCovid.id)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.consolidadoCovidList = result.c19ReporteCovidConsolidadosList;
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    }

  }

  guardarConsolidadoPorReporte(form: NgForm) {


    //this.consolidadoCovid.id = this.consolidadoCovidList.length + 1;
    this.consolidadoCovid.idReporte = this.reporteCovid.id;
    //this.consolidadoCovid.idDepartamento = 11;

    let departamentoYaIngresado = false;

    if (!this.seEstaEditando) {
      for (let index = 0; index < this.consolidadoCovidList.length; index++) {
        const consolidadoVar = this.consolidadoCovidList[index];
        if (this.consolidadoCovid.idDepartamento == consolidadoVar.idDepartamento) {
          departamentoYaIngresado = true;
        }
      }
    }


    if (!departamentoYaIngresado) {
      //Bloquear pantalla
      this.blockedDocument = true;

      forkJoin(
        this.covidReportarService.srvGuardarConsolidadoPorReporte(this.consolidadoCovid)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            //Consultar nuevamente la lista
            this.consultarConsolidadosPorReporte();
            //Limpiar campos
            this.seEstaEditando = false;
            //Volver a reportes
            this.irAReportes();
            //Lanzar mensaje de exito
            this.messageService.add({ severity: 'info', summary: 'Información', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: "Ya se ingresó información para el departamento seleccionado", life: 10000 });
    }
  }

  rowSelectConsolidado(_c19ConsolidadoCovid: C19ReporteCovidConsolidado) {
    this.consolidadoCovid = _c19ConsolidadoCovid;
    this.seEstaEditando = true;
    this.vistaActual = "reportarConsolidado";
  }

  /**
  guardarReporte(form: NgForm) {
    if (this.consolidadoCovidList != null && this.consolidadoCovidList.length > 0) {
      this.consolidadoCovid.id = this.consolidadoCovidList.length + 1;
      this.consolidadoCovidList.push(this.consolidadoCovid);
      //Logica de Guardar
      this.irAReportes();
    } else {
   }
  }**/

}
