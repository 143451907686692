import { Component, OnInit } from '@angular/core';
import {MenubarModule} from 'primeng/menubar';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-mesa-uat',
  templateUrl: './mesa-uat.component.html',
  styleUrls: ['../public-uat.component.sass']
})
export class MesaUatComponent implements OnInit {
  items: MenuItem[];

  ngOnInit() {
      this.items = [
          {
              label: 'UAT'
              , "routerLink": "/uat"
          },
          {
              label: 'Servicios',
              items: [
                {label: 'Repositorio de Información de tráfico y recaudo', "routerLink": "/uat/repositorio"},
                {label: 'Modelo Nacional de Transporte', "routerLink": "/uat/modelo"},
                {label: 'Mesa Nacional de modelación de transporte', "routerLink": "/uat/mesa"}
            ]
          },
          {
              label: 'Aliados'
              , "routerLink": "/uat/aliados"
          },
          {
              label: 'Investigación'
              , "routerLink": "/uat/investigacion"
          }
      ];
      
  }

}
