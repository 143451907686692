import { Component, OnInit } from '@angular/core';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';

@Component({
  selector: 'app-api-service',
  templateUrl: './api-service.component.html',
  styleUrls: ['./api-service.component.sass']
})
export class ApiServiceComponent implements OnInit {

  apiService: string;

  constructor(
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarApiService();
  }


  cargarApiService() {
    this.parametricasService.getApiService().subscribe(
      result => {
        const value = result.toString();
        this.apiService = value.replace(/\n/g, '<br/>');
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

}
