import { Component, OnInit } from '@angular/core';
import { ConcesionarioInviasDetalleService } from './concesionario-invias-detalle.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneral, ConceptoRespuesta, GurdarConceptoRespuesta } from './informacionGeneral';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';



  // Componente de detalle
@Component({
  selector: 'app-concesionario-invias-detalle.component',
  templateUrl: './concesionario-invias-detalle.component.html',
  styleUrls: ['./concesionario-invias-detalle.component.sass'],
  providers: [ConcesionarioInviasDetalleService]
})
export class ConcesionarioInviasDetalleComponent implements OnInit {

  loading: boolean;  
  visibleDialogoRespuesta: boolean;
  visibleDetalleRespusta: boolean;
  visibleModalErrorBusquedaConcepto: boolean;
  idConceptoSolicitado : any;
  volverListado: any;
  respuestaConceptoSeleccionada: any ;

  listaConceptosSolicitados: any = [];
  listaConceptoTecnico: any = [];

  temporal: any;
  idProyecto: number;

  habilitarAdjuntoRespuesta: boolean;
  habilitarCrearRespuesta: boolean;
  habilitarAdjuntoConcepto: boolean;
  visibleEliminarConcepto: boolean;
  informacionGeneral: InformacionGeneral = {};

  respuestaConcesionario : ConceptoRespuesta = {};
  objetoGuardar : GurdarConceptoRespuesta = {};

	displayedConceptos = [
  { field: 'numeroRadicado', header: 'N° Radicado de Respuesta ANI' },
		{ field: 'fechaRadicadoStr', header: 'Fecha Radicado ANI' },
    { field: 'respuestaConcepto', header: 'Concepto Técnico' },
    { field: 'adjunto', header: 'Documento Respuesta' },
	];

  constructor(
    private _service: ConcesionarioInviasDetalleService,
    private router: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent, 
    private parametricasService: ParametricasService
    ) {
    }

  ngOnInit() {
    this.loading = true;
    this.visibleDialogoRespuesta = false;
    this.habilitarCrearRespuesta = false;
    this.visibleDetalleRespusta = true;
    this.visibleModalErrorBusquedaConcepto = false;
    this.idProyecto = Number(localStorage.getItem('idP'));

    this.idConceptoSolicitado = localStorage.getItem('idConcesionarioConcepto');
    this.habilitarAdjuntoConcepto = true;
    this.habilitarAdjuntoRespuesta = true;
    this.visibleEliminarConcepto = false;
    this.respuestaConceptoSeleccionada = { id: 0, nombre: 'Seleccione...'};
    this.getListaRespuestaConceptoTecnico();
    this.cargarInformacion(this.idConceptoSolicitado);    
  }

  /**
   * Metodo que carga la informacion a editar.
   * @param idConceptoSolicitado 
   */
  cargarInformacion(idConceptoSolicitado){
    this._service.getConsultaInformacionConcepto(idConceptoSolicitado).subscribe((data: {}) => {
      this.temporal = data;
      if(this.temporal != undefined && this.temporal != null){
        this.informacionGeneral.idTramite = this.temporal.solicitudes.idTramite;
        this.informacionGeneral.idConceptoSolicitado = idConceptoSolicitado;
        this.informacionGeneral.idSolicitudInivias = this.temporal.solicitudes.idSolicitudInivias;
        this.informacionGeneral.asunto = this.temporal.solicitudes.asunto;
        this.informacionGeneral.idProyecto = this.temporal.solicitudes.idProyecto;
        this.informacionGeneral.idConcesionario = this.temporal.solicitudes.idConcesionario;
        this.informacionGeneral.proyecto = this.temporal.solicitudes.proyecto;
        this.informacionGeneral.concesionario = this.temporal.solicitudes.concesionario;
        this.informacionGeneral.fechaRadicado = this.temporal.solicitudes.fechaRadicado;
        this.informacionGeneral.fechaRadicadoStr = this.temporal.solicitudes.fechaRadicadoStr;
        this.informacionGeneral.numeroRadicado = this.temporal.solicitudes.numeroRadicado;
        this.informacionGeneral.adjunto = this.temporal.solicitudes.rutaAdjunto;
        this.listaConceptosSolicitados = this.temporal.solicitudes.conceptosSolicitados;
        this.validarCrearRespuesta();
      }else{
          this.messageService.add({
              severity: 'warn',
              summary: 'Advertencia',
              detail: "El sistema no encontró información para la edición.",
              life: 10000
            });
      }            
      this.loading = false;
    }, (error)=>{
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
    });
  }


   /**
   * Metodo que retorna a la pagina anterior de tramites
   */
  bntVolver() {
    this.volverListado = (localStorage.getItem('volverListado'));
    this.router.navigate([this.volverListado]);
  }


  /**
   * Metod que realiza el descargue del archivo del radicado
   * @param objeto 
   */
  onDescargarAdjunto(objeto){
    if(objeto.adjunto != null){
      window.open(objeto.adjunto);
    }else{
      if(objeto.rutaAdjunto != null){
        window.open(objeto.rutaAdjunto);
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El sistema no encontró el archivo a descargar",
          life: 10000
        });
      }
    }
  }

  /**
   * Metodo que invoca la pantalla de PQRS
   */
  visibleAgregarRespuestaConcepto(){
    this.visibleDialogoRespuesta = true;
  }

  /**
   * Metodo que valida el proceso de finalizacion
   */
  validarCrearRespuesta(){
    if( this.listaConceptosSolicitados.length == 0 ){
      this.habilitarCrearRespuesta = true;
    }
    this.onValidarFinalizacion();
  }

  
  /**
   * Metodo para validar la finalizacion de un proceso
   */
   onValidarFinalizacion(){
    this.loading = true;
    this._service.getConsultaValidarFinalizacion( this.informacionGeneral.idSolicitudInivias).subscribe((data: {}) => {
      this.temporal = data;
      if(this.temporal != null && this.temporal.mensajeSalida != null && this.temporal.mensajeSalida == "El estado esta Finalizado"){
        this.habilitarCrearRespuesta = false;
      }else{
        this.habilitarCrearRespuesta = true;
      }     
      this.loading = false;
    },error => {
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
    });
  }
  

  /**
   * Metodo que pregunta el modal para eliminar y valida si puede eliminar.
   * @param registro 
   */
  onDeleteRespuestaConcepto(registro){

  }

   /**
   * Metodo que limpia los valores seleccionados
   */
  resetValoresConcepto(){
    this.respuestaConcesionario.asunto = null;
    this.respuestaConcesionario.fechaRadicadoStr = null;
    this.respuestaConcesionario.fechaRadicado = null;
    this.respuestaConcesionario.rutaAdjunto = null;
    this.respuestaConcesionario.numeroRadicado = null;
    this.respuestaConcesionario.justificacionConcepto = null;
    this.visibleDetalleRespusta = true;
    this.habilitarAdjuntoRespuesta = false;
    this.respuestaConceptoSeleccionada = { id: 0, nombre: 'Seleccione...'};
  }

  /**
   * Metodo qye guarda la respuesta
   */
  guardarRespuesta(){
    this.loading = true;
    this.visibleDialogoRespuesta = false;
    this.objetoGuardar = {};
    this.objetoGuardar.idConceptoSolicitado = this.informacionGeneral.idConceptoSolicitado;
    this.objetoGuardar.idTramite = this.informacionGeneral.idTramite;    
    this.objetoGuardar.numeroRadicado = this.respuestaConcesionario.numeroRadicado;
    this.objetoGuardar.fechaRadicado = this.respuestaConcesionario.fechaRadicado;
    this.objetoGuardar.idRespuestaConcepto = this.respuestaConceptoSeleccionada.id;
    this.objetoGuardar.justificacionConcepto = this.respuestaConcesionario.justificacionConcepto;
    this.objetoGuardar.asunto = this.respuestaConcesionario.asunto;
    this.objetoGuardar.rutaAdjunto = this.respuestaConcesionario.rutaAdjunto;
    this.objetoGuardar.radicadoRespuesta = this.informacionGeneral.numeroRadicado;
    this.objetoGuardar.asunto = this.respuestaConcesionario.asunto;

    this._service.postGuardarRespuestaConcesionario(this.objetoGuardar).subscribe((data: {}) => {
      this.temporal = data;
      if( this.temporal.asunto != null && this.temporal.asunto != ""){
        this.respuestaConcesionario.asunto = this.temporal.asunto;
        this.respuestaConcesionario.numeroRadicado = this.temporal.numeroRadicado;
        this.respuestaConcesionario.fechaRadicado = this.temporal.fechaRadicado;
        this.respuestaConcesionario.fechaRadicadoStr = this.temporal.fechaRadicadoStr;
        this.respuestaConcesionario.rutaAdjunto = this.temporal.archivo;
        this.visibleModalErrorBusquedaConcepto = false;
        this.visibleDetalleRespusta = false;
        if(this.temporal.archivo != null && this.temporal.archivo != ""){
          this.habilitarAdjuntoRespuesta = true;
        }else{
          this.habilitarAdjuntoRespuesta = false;
        }
        this.visibleDialogoRespuesta = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Se ha guardado la información',
          life: 10000
        });
        this.resetValoresConcepto();
        this.cargarInformacion(this.idConceptoSolicitado);
      }else{
        this.visibleModalErrorBusquedaConcepto = true;
        this.habilitarAdjuntoRespuesta = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: (this.temporal.mensajeError!= undefined && this.temporal.mensajeError!= null) ? this.temporal.mensajeError: ( (this.temporal.message!= undefined && this.temporal.message!= null) ? this.temporal.message : "Ha ocurrido un error")   ,
          life: 10000
        });
        this.visibleDialogoRespuesta = true;
      }
      this.loading = false;
    },error => {
       this.loading = false;
       this.principalComponent.cargarErrorServicio(error);
     });
  }

  /**
   * Metodo que cancela la respuesta
   */
  cancelarRespuesta(){
    this.visibleDialogoRespuesta = false;
    this.resetValoresConcepto();
  }


  
  /**
   * Metodo que busca el radicado en orfeo en concepto
   * @param radicado 
   */
  onBuscarRadicadoRespuesta(radicado){
    
    this.respuestaConcesionario.asunto = null;
    this.respuestaConcesionario.fechaRadicadoStr = null;
    this.respuestaConcesionario.fechaRadicado = null;
    this.respuestaConcesionario.rutaAdjunto = null;

    if(radicado != null && radicado != undefined && radicado != ""){
      if(radicado.length < 14){
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El número de radicado no poseé la longitud del formato.",
          life: 10000
        });
        return ;
      }
      
      if(this.stringIsNumber(radicado)){
        this.loading = true;
        this._service.getConsultaRadicadoResuesta(radicado).subscribe((data: {}) => {
          this.temporal = data;
          if( this.temporal.asunto != null && this.temporal.asunto != ""){
            this.respuestaConcesionario.asunto = this.temporal.asunto;
            this.respuestaConcesionario.numeroRadicado = this.temporal.numeroRadicado;
            this.respuestaConcesionario.fechaRadicado = this.temporal.fechaRadicado;
            this.respuestaConcesionario.fechaRadicadoStr = this.temporal.fechaRadicadoStr;
            this.respuestaConcesionario.rutaAdjunto = this.temporal.archivo;
            this.visibleModalErrorBusquedaConcepto = false;
            this.visibleDetalleRespusta = false;
            if(this.temporal.archivo != null && this.temporal.archivo != ""){
              this.habilitarAdjuntoRespuesta = true;
            }else{
              this.habilitarAdjuntoRespuesta = false;
            }
          }else{
            this.visibleModalErrorBusquedaConcepto = true;
            this.habilitarAdjuntoRespuesta = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: this.temporal.mensajeError,
              life: 10000
            });
          }
          this.loading = false;
        },error => {
           this.loading = false;
           this.principalComponent.cargarErrorServicio(error);
         });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "Se debe digitar un número de radicado valido.",
          life: 10000
        });
        return ;
      }
    }else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Se debe digitar un número de radicado.",
        life: 10000
      });
      return ;
    }
  }


  /**
   * Metodo que valida si es un numero.
   * @param s 
   */
  stringIsNumber(s) {
    var x = +s; // made cast obvious for demonstration
    return x.toString() === s;
  }

  
  
  /**
	 * Metodo que captura el evento del teclado solo numeros 0-9
	 * @param evento 
	 */
	soloNumeros(evento){
		if(evento.code == "Backspace" 
		|| evento.code == "Enter" 
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
    || evento.code == "Delete"
    || evento.ctrlKey == true){
			return true;
    }
    
    if(evento.ctrlKey == true && evento.keyCode == 67){
      return true;
    }
    if(evento.ctrlKey == true && evento.keyCode == 86){
      return true;
    }

		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || 
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}

	}	   
  
   /**
	 * Metodo que captura el evento del teclado solo alfanumerico
	 * @param evento 
	 */
	soloAlfanumericos(evento){

		if(evento.shiftKey || evento.ctrlKey || evento.altKey || evento.code =="BracketLeft"){
			return false;
		}

		if(evento.code == "Backspace" 
		|| evento.code == "Enter"
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"){
			return true;
		}
		
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || evento.keyCode >= 65 &&  evento.keyCode <= 90 ||
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}
  }	
  
    /**
   * Metodo que carga la lista los conceptos tecnicos
   */
  getListaRespuestaConceptoTecnico(){
    this.loading = true;
    this._service.getListaRespuestaConceptoTecnico().subscribe((data: {}) => {
        this.listaConceptoTecnico = data;
        this.loading = false;
    },error => {
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
    });
  }

}
