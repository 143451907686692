import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, retry } from 'rxjs/operators';
import { Observable } from "rxjs-compat";
import { ConfigService } from "src/app/config.service";
import { TrnRespuestaServicio } from "src/app/parametricas/trn-respuesta-servicio";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TrnConstantesService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo que consulta el servicio con el listado de la información de la tabla de constantes
     * @returns Objeto de respuesta de tipo TrnConstanteRptDTO
     */
    public srvGetTrnConstantes(): Observable<TrnConstanteRptDTO> {
        return this.http
            .get<TrnConstanteRptDTO>(this.END_POINT + "/TrnConstante/GetConstantes/")
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que consulta el servicio con el listado de la información de la tabla de constantes
     * @param nombresConstantes Nombre de las constantes separados por coma
     * @returns Objeto de respuesta de tipo TrnConstanteRptDTO
     */
    public srvGetConstantesListaPorNombre(nombresConstantes: string): Observable<TrnConstanteRptDTO> {
        let urlEndpoint = this.END_POINT + "/TrnConstante/GetConstantesListaPorNombre?";
        if (nombresConstantes != null) {
            urlEndpoint = urlEndpoint + '&nombresConstantes=' + nombresConstantes;
        }
        return this.http
            .get<TrnConstanteRptDTO>(urlEndpoint, this.httpOptions)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que consulta el servicio con la información de la constante por su id
     * @param idConstante Id de la constante a consultar
     * @returns Objeto de respuesta de tipo TrnConstanteRptDTO
     */
    public srvGetTrnConstantePorId(idConstante: number): Observable<TrnConstanteRptDTO> {
        return this.http
            .get<TrnConstanteRptDTO>(this.END_POINT + "/TrnConstante/GetConstantePorId/" + idConstante)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


    /**
     * Metodo que consulta el servicio con la información de la constante por su nombre
     * @param nombreConstante Nombre de la constante a consultar
     * @returns Objeto de respuesta de tipo TrnConstanteRptDTO
     */
    public srvGetTrnConstantePorNombre(nombreConstante: string): Observable<TrnConstanteRptDTO> {
        return this.http
            .get<TrnConstanteRptDTO>(this.END_POINT + "/TrnConstante/GetConstantePorNombre/" + nombreConstante)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
}

//interface DTO
export interface TrnConstanteRptDTO {
    respuestaServicio?: TrnRespuestaServicio,
    constantesLista?: TrnConstanteDTO[],
    constante?: TrnConstanteDTO
}

export interface TrnConstanteDTO {
    id?: number,
    nombre?: string,
    valor?: string
}