import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { VwCmsComision } from '../../comisiones.dto';
import { ComisionesService } from '../../comisiones.service';

@Component({
  selector: 'app-comisiones-legalizaciones-registro',
  templateUrl: './comisiones-legalizaciones-registro.component.html',
  styleUrls: ['./comisiones-legalizaciones-registro.component.sass']
})
export class ComisionesLegalizacionesRegistroComponent implements OnInit {

  blockedDocument: boolean;

  solicitud: VwCmsComision = {};
  solicitudList: VwCmsComision[];

  constructor(
    private messageService: MessageService,
    private router: Router,
    private comisionesService: ComisionesService,
    private principalComponent: PrincipalComponent
  ) {
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.blockedDocument = true;
    this.obtenerComisionesPorLegalizar();
  }

  obtenerComisionesPorLegalizar() {
    forkJoin(
      this.comisionesService.srvConsultarComisionesPersonaLegalizacion()
    ).subscribe(
      ([result]) => {
        if (result != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.solicitudList = result.comisionesAsignadas;
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  bntVolver() {
    const ruta = "/comisiones";
    this.router.navigate([ruta]);
  }

  legalizarSolicitud(solicitudL: VwCmsComision) {
    this.solicitud = solicitudL;
    this.router.navigate([this.router.url.replace('comisiones-legalizaciones-registro', '') + "/comisiones-crear-editar-solicitud/" + this.solicitud.idComision + "/" + this.solicitud.idComisionVersion + "/0" + "/true/false/" + this.solicitud.idPersona + "/true"]);
  }


  limpiarMensajes() {
    this.messageService.clear();
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000
    });
  }

}