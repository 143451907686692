import { Component, OnInit, Inject } from '@angular/core';
import { InformacionGeneralService } from '../informacion-general/informacion-general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-proyecto-home-ferreo',
  templateUrl: './proyecto-home-ferreo.component.html',
  styleUrls: ['./proyecto-home-ferreo.component.sass']
})
export class ProyectoHomeFerreoComponent implements OnInit {


  proyecto: any;
  id: number;
  esProduccion: boolean = false;
  webUrlConcesiones: any;
  ruta: string;

  constructor(private infoService: InformacionGeneralService, private route: ActivatedRoute, private router: Router, @Inject(DOCUMENT) private document: any,
  private messageService: MessageService, private principalComponent: PrincipalComponent) {
    this.ruta = router.url;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.cargarProyectoCarretero(this.id);
    });
    localStorage.removeItem('idP');
    this.esProduccion = environment.esProduccion;
    this.webUrlConcesiones = environment.webUrlConcesiones;
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoFerreo(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irALineaBase() {
    this.router.navigate([this.ruta + '/proyectoLineaBase', this.id]);
  }

  irAGeneral() {
    this.router.navigate([this.ruta + '/infGeneral', this.id]);
  }

  irAAmbiental() {
    this.router.navigate([this.ruta + '/infAmbiental', this.id]);
  }

  irAPredial() {
    this.router.navigate([this.ruta + '/infPredial', this.id]);
  }

  irARegistro() {
    this.document.location = "https://concesionessherlockaniwebtest.azurewebsites.net/concesiones/" + this.id + "/dashboard";
  }

  irAInfInterventoriaMinTrans() {
    this.document.location = "https://gpi.mintransporte.gov.co/reports/loadReports"
  }

  irAUnidadesFuncionales() {
    this.router.navigate([this.ruta + '/uniFuncionales', this.id])
  }

  irAFinancieroHome() {
    this.router.navigate([this.ruta + '/financieroHome', this.id]);
  }

  irAJuridico() {
    this.router.navigate([this.ruta + '/menuJuridico', this.id]);
  }

  irAFinanciero() {
    this.router.navigate([this.ruta + '/menuFinanciero', this.id])
  }

  irATramitesAmbientales(){
    this.router.navigate([this.router.url+'/tramites-ambientales-listado', this.id])
  }

  irATemasG() {
    this.router.navigate([this.ruta + '/infTemasG', this.id])
  }

  irACovidReportar(){
    this.router.navigate([this.router.url+'/covid-reportar', this.id])
  }

  irAPolizas(){
    this.router.navigate([this.router.url + '/polizas', this.id]);
  }

  irAServicioCarga(){
    this.router.navigate([this.router.url+'/servicioCarga', this.id])
  }

  irOlaInvernal() {
    this.router.navigate([this.router.url + '/ola-invernal-ferreo-registro', this.id]);

  }




}
