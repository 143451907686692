import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ReportesComponent } from "./home/home-carreteros/proyecto-carreteras/reportes.component";
import { ReportesAeropuertoComponent } from "./reportes/internos/reportes-aeropuerto/reportes-aeropuerto.component";
import { ReportesPuertosComponent } from "./reportes/internos/reportes-puertos/reportes-puertos.component";
import { ReportesFerroComponent } from "./reportes/internos/pagina-menu-reportes-ferro/reportes-ferro.component";

import { InformacionGeneralComponent } from "./informacion-general/informacion-general.component";
import { HomeComponent } from "./home/home/home.component";
import { ProyectoComponent } from "./proyecto/proyecto.component";
import { ProyectoHomeComponent } from "./proyecto-home/proyecto-home.component";
import { AmbientalComponent } from "./componentes/ambiental/lista/ambiental.component";
import { AmbientalDetalleComponent } from "./componentes/ambiental/detalle/ambiental-detalle.component";

import { PredialComponent } from "./componentes/predial/lista/predial.component";
import { PredialDetalleComponent } from "./componentes/predial/detalle/predial-detalle.component";
import { UnidadFuncionalComponent } from "./unidad-funcional/unidad-funcional.component";
import { UnidadFuncionalDetalleComponent } from "./unidad-funcional-detalle/unidad-funcional-detalle.component";
import { InversionComponent } from "./financiero/inversion/inversion.component";
import { InversionDetalleComponent } from "./financiero/inversion-detalle/inversion-detalle.component";
import { FinancieroHomeComponent } from "./financiero/financiero-home/financiero-home.component";
import { InversionProyectoComponent } from "./financiero/inversion-proyecto/inversion-proyecto.component";
import { InversionProyectoDetalleComponent } from "./financiero/inversion-proyecto-detalle/inversion-proyecto-detalle.component";
import { ModuloJuridicoComponent } from "./modulo-juridico/modulo-juridico.component";
import { NuevoIntervencionComponent } from "./modulo-juridico/nuevo-intervencion/nuevo-intervencion.component";
import { MenuPrincipalComponent } from "./modulo-juridico/menu-principal/menu-principal.component";
import { AmigablesComponedoresComponent } from "./modulo-juridico/amigables-componedores/amigables-componedores.component";
import { PronunciamientoComponent } from "./modulo-juridico/pronunciamiento/pronunciamiento.component";
import { AudienciaPreliminarComponent } from "./modulo-juridico/audiencia-preliminar/audiencia-preliminar.component";
import { AudienciaPruebasComponent } from "./modulo-juridico/audiencia-pruebas/audiencia-pruebas.component";
import { AudienciaAlegatosComponent } from "./modulo-juridico/audiencia-alegatos/audiencia-alegatos.component";
import { AudienciaDecisionComponent } from "./modulo-juridico/audiencia-decision/audiencia-decision.component";
import { SolicitudAclaracionComponent } from "./modulo-juridico/solicitud-aclaracion/solicitud-aclaracion.component";
import { DecisionAclaracionComponent } from "./modulo-juridico/decision-aclaracion/decision-aclaracion.component";
import { TerminacionAnticipadaComponent } from "./modulo-juridico/terminacion-anticipada/terminacion-anticipada.component";
import { InformacionGeneralFinancieroComponent } from "./financiero/informacion-general/informacion-general.component";
import { InformacionGeneralDetalleComponent } from "./financiero/informacion-general-detalle/informacion-general-detalle.component";
import { TransversalesComponent } from "./transversales/transversales.component";
import { ContratoCreditoComponent } from "./financiero/contrato-credito/contrato-credito.component";
import { ContratoCreditoDetalleComponent } from "./financiero/contrato-credito-detalle/contrato-credito-detalle.component";
import { MenuJuridicoComponent } from "./modulo-juridico/menu-juridico/menu-juridico.component";
import { AmigablesComponent } from "./modulo-juridico/amigables/amigables.component";
import { AmigablesDetalleComponent } from "./modulo-juridico/amigables-detalle/amigables-detalle.component";
import { ProyectoLineaBaseComponent } from "./proyecto-linea-base/proyecto-linea-base.component";
import { TemasGestionComponent } from "./componentes/temas-gestion/lista/temas-gestion.component";
import { TemasGestionDetalleComponent } from "./componentes//temas-gestion/detalle/temas-gestion-detalle.component";
import { TemasGestionRegistroComponent } from "./componentes//temas-gestion/registro/temas-gestion-registro.component";
import { CanActivateViaAuthGuard } from "./CanActivateViaAuthGuard";
//REFACTORING
import { CarreteroVisorMapaComponent } from "./reportes/internos/carretero-home-visor-mapa/carretero-home-visor-mapa.component";
import { AeropuertosHomeVisorMapaComponent } from "./reportes/internos/aeropuertos-home-visor-mapa/aeropuertos-home-visor-mapa.component";
import { PuertoHomeVisorMapaComponent } from "./reportes/internos/puerto-home-visor-mapa/puerto-home-visor-mapa.component";
import { FerreoVisorMapaComponent } from "./reportes/internos/reportes-ferreo-visor-mapa/reportes-ferreo-visor-mapa.component";

import { TramiteAmbientalComponent } from "./componentes/tramites-ambientales/lista/tramite-ambiental.component";
import { TramiteAmbientalDetalleComponent } from "./componentes/tramites-ambientales/detalle/tramite-ambiental-detalle.component";
import { SeguimientoComponent } from "./componentes/tramites-ambientales/seguimiento/seguimiento.component";

import { InteresadosListaComponent } from "./componentes/interesados/lista/interesados-lista.component";
import { InteresadosExternosComponent } from "./componentes/interesados-externos/lista/interesados-externos.component";

import { SeguimientoComunicacionesComponent } from "./componentes/seguimiento/seguimiento-comunicaciones/lista/seguimiento-comunicaciones.component";
import { SeguimientoComunicacionesDetalleComponent } from "./componentes/seguimiento/seguimiento-comunicaciones/detalle/seguimiento-comunicaciones-detalle.component";
import { SeguimientoComunicacionesRegistroComponent } from "./componentes/seguimiento/seguimiento-comunicaciones/registro/seguimiento-comunicaciones-registro.component";
import { SeguimientoHomeComponent } from "./componentes/seguimiento/seguimiento-home/seguimiento-home.component";
import { SeguimientoReporteComponent } from "./reportes/internos/seguimiento-reporte/seguimiento-reporte.component";
import { SeguimientoConsultaComponent } from "./componentes/seguimiento/seguimiento-consulta/seguimiento-consulta.component";
import { SeguimientoVerComunicacionComponent } from "./componentes/seguimiento/seguimiento-ver-comunicacion/seguimiento-ver-comunicacion.component";

import { CertificacionHomeComponent } from "./certificaciones-laborales/certificacion-home/certificacion-home.component";
import { CertificacionesLaboralesValidacionComponent } from "./certificaciones-laborales/certificaciones-laborales-validacion/certificaciones-laborales-validacion.component";
import { CertificacionContratistaValidacionComponent } from "./certificaciones-laborales-contratista/certificacion-contratista-validacion/certificacion-contratista-validacion.component";
import { CertificacionRetiradosComponent } from "./certificaciones-laborales/certificacion-retirados/certificacion-retirados.component";
import { CertificacionActivosComponent } from "./certificaciones-laborales/certificacion-activos/certificacion-activos.component";
import { CertificacionGeneralComponent } from "./certificaciones-laborales/certificacion-general/certificacion-general.component";
import { CertificacionConfiguracionComponent } from "./certificaciones-laborales/certificacion-configuracion/certificacion-configuracion.component";

import { YoutubePlaylistComponent } from "./video/youtube-playlist.component/youtube-playlist.component";
import { YoutubeSearchListComponent } from "./video/youtube-search-list.component/youtube-search-list.component";
import { YoutubeUploadFileComponent } from "./video/youtube-upload-file.component/youtube-upload-file.component";
import { YoutubeHomePublicComponent } from "./video/youtube-home-public.component/youtube-home-public.component";
import { YoutubeHomeComponent } from "./video/youtube-home.component/youtube-home.component";

import { YoutubeProyectoComponent } from "./video/youtube-proyecto.component/youtube-proyecto.component";
import { YoutubeProyectoVideoComponent } from "./video/youtube-proyecto-video.component/youtube-proyecto-video.component";

import { UploadFileComponent } from "./radicacion-web/upload-file/upload-file.component";
//REFACTORING
import { TramitesVisorSeguimientoComponent } from "./reportes/internos/tramites-visor-seguimiento/tramites-visor-seguimiento.component";

import { TribunalesComponent } from "./componentes/juridico/tribunales/lista/tribunales.component";
import { TribunalesDetalleComponent } from "./componentes/juridico/tribunales/detalle/tribunales-detalle.component";
import { SeguimientoTribunalesComponent } from "./componentes/juridico/tribunales/seguimiento/seguimiento-tribunales.component";

import { ProyectoHomeAeroComponent } from "./proyecto-home-aero/proyecto-home-aero.component";
import { ProyectoHomePuertoComponent } from "./proyecto-home-puerto/proyecto-home-puerto.component";
import { ProyectoHomeFerreoComponent } from "./proyecto-home-ferreo/proyecto-home-ferreo.component";
import { ListarPlaneacionesComponent } from "./modulo-planeacion-proyecto/listar-planeaciones/listar-planeaciones.component";
import { CrearPlaneacionComponent } from "./modulo-planeacion-proyecto/crear-planeacion/crear-planeacion.component";
import { AvanceProyectoComponent } from "./avance-proyecto/avance-proyecto.component";
import { SeleccionarUnidadfuncionalComponent } from "./componentes-comunes/seleccionar-unidadfuncional/seleccionar-unidadfuncional.component";
import { SeleccionarProyectoComponent } from "./componentes-comunes/seleccionar-proyecto/seleccionar-proyecto.component";
import { PolizasComponent } from "./polizas/principal/polizas.component";
import { PolizaEditComponent } from "./polizas/poliza-edit/poliza-edit.component";
import { AmparosComponent } from "./polizas/amparos/amparos.component";
import { AmparosEditComponent } from "./polizas/amparos/amparos-edit/amparos-edit.component";

import { InformacionGeneralBaseComponent } from "./financiero/informacion-general-base/informacion-general-base.component";
import { SaldoFinancieroComponent } from "./financiero/saldo-financiero/saldo-financiero.component";
import { RetribucionFinancieraComponent } from "./financiero/retribucion-financiera/retribucion-financiera.component";
import { VigenciasFuturasComponent } from "./financiero/vigencias-futuras/vigencias-futuras.component";
import { VpipFinancieroComponent } from "./financiero/vpip-financiero/vpip-financiero.component";
import { InversionIndexadaComponent } from "./financiero/inversion-indexada/inversion-indexada.component";
import { SeguimientoAmigableComponent } from "./modulo-juridico/seguimiento-amigable/seguimiento-amigable.component";
import { ContratoCreditoBaseComponent } from "./financiero/contrato-credito-base/contrato-credito-base.component";
import { TraficoRecaudoComponent } from "./trafico-recaudo/trafico-recaudo.component";
import { ContratoCreditoPuenteBaseComponent } from "./financiero/contrato-credito-puente/contrato-credito-puente-base/contrato-credito-puente-base.component";
import { ContratosComponent } from "./contratacion/contrato/contrato-borrador/contratos/contratos.component";
import { PersonaBuscarAgregarComponent } from "./comun/persona-buscar-agregar/persona-buscar-agregar.component";
import { ContratoBaseComponent } from "./contratacion/contrato/contrato-borrador/contrato-borrador-registro/contrato-base/contrato-base.component";
import { PersonasComponent } from "./admin-personas/personas/personas.component";
import { PersonaBaseComponent } from "./admin-personas/persona/persona-base/persona-base.component";
import { VistoBuenoContratoComponent } from "./contratacion/visto-bueno-contrato/visto-bueno-contrato.component";
import { ObligacionesContratoComponent } from "./contratacion/obligaciones-contrato/obligaciones-contrato.component";
import { ContratosOpcionesAdicionalesComponent } from "./contratacion/contratos-opciones-adicionales/contratos-opciones-adicionales.component";
import { CovidReportarComponent } from "./covid-reportar/covid-reportar.component";
import { PaginaInicialComponent } from "./publico/pagina-inicial/pagina-inicial.component";
import { CovidReportarReinicioObraComponent } from "./covid-reportar/covid-reportar-reinicio-obra/covid-reportar-reinicio-obra.component";
import { CovidReportarSeguimientoComponent } from "./covid-reportar/covid-reportar-seguimiento/covid-reportar-seguimiento.component";
import { ContratosHomeComponent } from "./contratacion/contratacion-home/contratos-home/contratos-home.component";
import { ContratosPublicadosComponent } from "./contratacion/contrato/contrato-publicado/contratos-publicados/contratos-publicados.component";
import { ContratosContratistaComponent } from "./contratacion/informe-contratista/informe-contratista/contratos-contratista/contratos-contratista.component";
import { ContratoPlanPagoContratistaComponent } from "./contratacion/informe-contratista/informe-contratista/contrato-plan-pago-contratista/contrato-plan-pago-contratista.component";
import { InformeContratistaBaseComponent } from "./contratacion/informe-contratista/informe-contratista/informe-contratista-registro/informe-contratista-base/informe-contratista-base.component";
import { InformesVoboComponent } from "./contratacion/informe-contratista/informe-vobo/informes-vobo/informes-vobo.component";
import { InformesSupervisorComponent } from "./contratacion/informe-contratista/informe-supervisor/informes-supervisor/informes-supervisor.component";
import { InformeVoboBaseComponent } from "./contratacion/informe-contratista/informe-vobo/informe-vobo-registro/informe-vobo-base/informe-vobo-base.component";
import { ContratacionSupervisionHomeComponent } from "./contratacion/contratacion-home/contratacion-supervision-home/contratacion-supervision-home.component";
import { InformeSupervisorBaseComponent } from "./contratacion/informe-contratista/informe-supervisor/informe-supervisor-registro/informe-supervisor-base/informe-supervisor-base.component";
import { CertificadoPdfComponent } from "./componentes/certificado-pdf/certificado-pdf.component";
//public
import { AnonimoCarreterosComponent } from "./publico/anonimo-carreteros/anonimo-carreteros.component";
import { PublicCarreterasComponent } from "./publico/public-carreteras/public-carreteras.component";
import { PublicCarreterasMapaComponent } from "./reportes/publicos/public-carreteras-mapa/public-carreteras-mapa.component";
import { PublicCarreterasConsultaComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-consulta.component";
import { PublicCarreterasConsultaResumenComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-resumen/public-carreteras-consulta-resumen.component";
import { PublicCarreterasConsultaFicha5gComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-ficha5g/public-carreteras-consulta-ficha5g.component";
//REFACTORING
import { PublicCarreterasVisorResumenEjecutivoComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-visor-resumen-ejecutivo/public-carreteras-visor-resumen-ejecutivo.component";
import { CarreterasVisorFichaProyecto4gComponent } from "./reportes/internos/carreteras-visor-ficha-proyecto4g/carreteras-visor-ficha-proyecto4g.component";
import { PublicCarreterasVisorFichaProyectoComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-visor-ficha-proyecto/public-carreteras-visor-ficha-proyecto.component";
import { PublicCarreterasVisorInversionPorDepartamentoComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-visor-inversion-por-departamento/public-carreteras-visor-inversion-por-departamento.component";
import { PublicCarreterasVisorAvancePredialComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-visor-avance-predial/public-carreteras-visor-avance-predial.component";
import { PublicCarreterasVisorTramitesAmbientalesComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-visor-tramites-ambientales/public-carreteras-visor-tramites-ambientales.component";
import { PublicCarreterasVisorPeajesComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-visor-peajes/public-carreteras-visor-peajes.component";
import { PublicCarreterasVisorEerComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-visor-eer/public-carreteras-visor-eer.component";
import { PublicCarreterasVisorAreasDeInfluenciaComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-visor-areas-de-influencia/public-carreteras-visor-areas-de-influencia.component";
import { VisorReportePredialComponent } from "./reportes/internos/visor-reporte-predial/visor-reporte-predial.component";
import { FuncionarioInviasPrincipalListComponent } from "./invias/funcionario/principal/funcionario-invias-principal-list.component";
import { ConcesionarioInviasPrincipalListComponent } from "./invias/concesionario/principal/concesionario-invias-principal-list.component";

import { FuncionarioInviasDetalleComponent } from "./invias/funcionario/detalle/funcionario-invias-detalle.component";
import { ConcesionarioInviasDetalleComponent } from "./invias/concesionario/detalle/concesionario-invias-detalle.component";
import { ConcesionarioRadicacionRespuestaComponent } from "./invias/concesionario/respuesta/concesionario-radicacion-respuesta.component";
import { FuncionarioRadicacionRespuestaComponent } from "./invias/funcionario/respuesta/funcionario-radicacion-respuesta.component";

import { ListadoRadicacionWebComponent } from "./radicacion-web/listado-radicacion-web/listado-radicacion-web.component";
import { NewEditRadicacionWebComponent } from "./radicacion-web/new-edit-radicacion-web/new-edit-radicacion-web.component";
import { ConsultaArchivoRadicacionWebComponent } from "./radicacion-web/consulta-archivo-radicacion-web/consulta-archivo-radicacion-web.component";
import { EditFirmantesRadicacionWebComponent } from "./radicacion-web/edit-firmantes-radicacion-web/edit-firmantes-radicacion-web.component";

import { PublicCarreterasConsultaFicha4gComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-ficha4g/public-carreteras-consulta-ficha4g.component";
import { PublicCarreterasConsultaFicha3g2gComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-ficha3g2g/public-carreteras-consulta-ficha3g2g.component";
import { PublicCarreterasConsultaPeajesComponent } from "./reportes/publicos/public-carreteras-consulta/public-carreteras-consulta-peajes/public-carreteras-consulta-peajes.component";
import { PublicAeropuertosComponent } from "./publico/public-aeropuertos/public-aeropuertos.component";
import { PublicAeropuertosMapaComponent } from "./reportes/publicos/public-aeropuertos-mapa/public-aeropuertos-mapa.component";
import { PublicPuertosComponent } from "./publico/public-puertos/public-puertos.component";
import { PublicPuertosMapaComponent } from "./reportes/publicos/public-puertos-mapa/public-puertos-mapa.component";
import { PublicPuertosFichaProyectoComponent } from "./reportes/publicos/public-puertos-ficha-proyecto/public-puertos-ficha-proyecto.component";
import { PublicPuertosFichaProyectoHomeComponent } from "./reportes/publicos/public-puertos-ficha-proyecto-home/public-puertos-ficha-proyecto-home.component";
import { PublicPuertosFichaProyectoDrupalComponent } from "./reportes/publicos/public-puertos-ficha-proyecto-drupal/public-puertos-ficha-proyecto-drupal.component";
import { PublicAeroFichaProyectoDrupalComponent } from "./reportes/publicos/public-aero-ficha-proyecto-drupal/public-aero-ficha-proyecto-drupal.component";
import { PublicFerroFichaProyectoDrupalComponent } from "./reportes/publicos/public-ferro-ficha-proyecto-drupal/public-ferro-ficha-proyecto-drupal.component";

import { PuertoSeguimientoGestionComponent } from "./reportes/internos/puerto-seguimiento-gestion/puerto-seguimiento-gestion.component";
import { PuertoHomeFichaProyectoComponent } from "./reportes/internos/puerto-home-ficha-proyecto/puerto-home-ficha-proyecto.component";
import { AeropuertosHomeFichaProyectoDrupalComponent } from "./reportes/internos/aeropuertos-home-ficha-proyecto-drupal/aeropuertos-home-ficha-proyecto-drupal.component";
import { FerreoFichaProyectoDrupalComponent } from "./reportes/internos/ferreo-ficha-proyecto-drupal/ferreo-ficha-proyecto-drupal.component";
import { PuertoHomeRegistrarComponent } from "./reportes/internos/puerto-home-registrar/puerto-home-registrar.component";

import { PqrsHomeServiciosComponent } from "./pqrs/pqrs-home-servicios/pqrs-home-servicios.component";
import { PqrsReporteEncuestaSatisfaccionComponent } from "./pqrs/pqrs-reporte-encuesta-satisfaccion/pqrs-reporte-encuesta-satisfaccion.component";

import { PqrsEncuestaSatisfaccionComponent } from "./pqrs/pqrs-encuesta-satisfaccion/pqrs-encuesta-satisfaccion.component";
import { PqrsConsultaComponent } from "./pqrs/pqrs-consulta/pqrs-consulta.component";
import { PqrsRadicacionComponent } from "./pqrs/pqrs-radicacion/pqrs-radicacion.component";

import { OrfeoConsultaComponent } from "./orfeo/orfeo-consulta/orfeo-consulta.component";

import { PublicFerrocarrilesComponent } from "./publico/public-ferrocarriles/public-ferrocarriles.component";
import { PublicFerrocarrilesConsultaComponent } from "./reportes/publicos/public-ferrocarriles-consulta/public-ferrocarriles-consulta.component";

import { PublicFerrocarrilesConsultaEstadisticaComponent } from "./reportes/publicos/public-ferrocarriles-consulta-estadistica/public-ferrocarriles-consulta-estadistica.component";
import { PublicFerrocarrilesMapaComponent } from "./reportes/publicos/public-ferrocarriles-mapa/public-ferrocarriles-mapa.component";
import { PublicFerrocarrilesConsultaFicha5gComponent } from "./reportes/publicos/public-ferrocarriles-consulta/public-ferrocarriles-consulta-ficha5g/public-ferrocarriles-consulta-ficha5g.component";
import { PublicFluvialComponent } from "./publico/public-fluvial/public-fluvial.component";
import { PublicFluvialConsultaComponent } from "./reportes/publicos/public-fluvial-consulta/public-fluvial-consulta.component";
import { PublicFluvialConsultaFicha5gComponent } from "./reportes/publicos/public-fluvial-consulta/public-fluvial-consulta-ficha5g/public-fluvial-consulta-ficha5g.component";
import { PublicAeropuertosConsultaComponent } from "./reportes/publicos/public-aeropuertos-consulta/public-aeropuertos-consulta.component";
import { PublicAeropuertosConsultaFicha5gComponent } from "./reportes/publicos/public-aeropuertos-consulta/public-aeropuertos-consulta-ficha5g/public-aeropuertos-consulta-ficha5g.component";
import { PublicProyectos5gComponent } from "./publico/public-proyectos5g/public-proyectos5g.component";
import { PublicProyectos5gFichaOla2Component } from "./publico/public-proyectos5g/public-proyectos5g-ficha-ola2/public-proyectos5g-ficha-ola2.component";

import { AgendamientoCitaComponent } from "./componentes/agendamiento-cita/agendamiento-cita.component";
import { AgendamientoConsultasComponent } from "./componentes/agendamiento-consultas/agendamiento-consultas.component";
import { CertificacionContratistaComponent } from "./certificaciones-laborales-contratista/certificacion-contratista/certificacion-contratista.component";

import { PublicMapComponent } from "./reportes/publicos/public-map/public-map.component";
import { SigComponent } from "./reportes/internos/sig/sig.component";
import { VisorPlanesAgenciaComponent } from "./reportes/internos/visor-planes-agencia/visor-planes-agencia.component";
import { ReporteDepartamentoComponent } from "./covid-reportar/covid-reportar-reportes/reporte-departamento/reporte-departamento.component";
import { ReporteSintomasComponent } from "./covid-reportar/covid-reportar-reportes/reporte-sintomas/reporte-sintomas.component";
import { CovidReportesComponent } from "./covid-reportar/covid-reportar-reportes/covid-reportes/covid-reportes.component";
import { ContratosNovedadesComponent } from "./contratacion/novedades-contractuales/contratos-novedades/contratos-novedades.component";
import { NovedadesContratoComponent } from "./contratacion/novedades-contractuales/novedades/novedades-contrato/novedades-contrato.component";
import { CovidReportesInternoComponent } from "./covid-reportar/covid-reportar-reportes/covid-reportar-reportes-interno/covid-reportes-interno/covid-reportes-interno.component";
import { ReporteDepartamentoInternoComponent } from "./covid-reportar/covid-reportar-reportes/covid-reportar-reportes-interno/reporte-departamento-interno/reporte-departamento-interno.component";
import { ReporteSintomasInternoComponent } from "./covid-reportar/covid-reportar-reportes/covid-reportar-reportes-interno/reporte-sintomas-interno/reporte-sintomas-interno.component";
import { NovedadContratoBaseComponent } from "./contratacion/novedades-contractuales/novedades/novedad-registro/novedad-contrato-base/novedad-contrato-base.component";
import { InformesCxpComponent } from "./contratacion/informe-contratista/informe-cxp/informes-cxp/informes-cxp.component";
import { InformeCxpBaseComponent } from "./contratacion/informe-contratista/informe-cxp/informe-cxp-registro/informe-cxp-base/informe-cxp-base.component";
import { InformesSpaComponent } from "./contratacion/informe-contratista/informe-spa/informes-spa/informes-spa.component";
import { InformeSpaBaseComponent } from "./contratacion/informe-contratista/informe-spa/informe-spa-registro/informe-spa-base/informe-spa-base.component";
import { ReporteCovidBiInternoComponent } from "./covid-reportar/covid-reportar-reportes/covid-reportar-reportes-interno/reporte-covid-bi-interno/reporte-covid-bi-interno.component";
import { ContratacionReportesHomeComponent } from "./contratacion/contratacion-home/contratacion-reportes-home/contratacion-reportes-home.component";
import { ReporteInformesContratistaComponent } from "./contratacion/reportes/reporte-informes-contratista/reporte-informes-contratista.component";
import { TraficoPeajesComponent } from "./peajes-gestion/trafico-peajes/trafico-peajes-carga/trafico-peajes-carga.component";
import { ReporteContratosComponent } from "./contratacion/reportes/reporte-contrato/reporte-contratos/reporte-contratos.component";
import { ReporteContratoBaseComponent } from "./contratacion/reportes/reporte-contrato/reporte-contrato-detalle/reporte-contrato-base/reporte-contrato-base.component";
import { InventarioEquiposComponent } from "./inventario-equipos/inventario-equipos.component";
import { RegistrarModeloComponent } from "./inventario-equipos/registrar-modelo/registrar-modelo.component";
import { RegistrarBienComponent } from "./inventario-equipos/registrar-bien/registrar-bien.component";
import { MenuPeajesComponent } from "./peajes-gestion/peajes-menu.component";
import { TarifasPeajesComponent } from "./peajes-gestion/tarifas-peajes/tarifas-peajes.component";
import { NuevaTarifaPeajesComponent } from "./peajes-gestion/tarifas-peajes/nueva-tarifa/nueva-tarifa.component";
import { ContratosCarreteroComponent } from "./informacion-general-carretero/informacion-contractual/contratos-carretero/contratos-carretero.component";
import { ContratoContratistaCarreteroBaseComponent } from "./informacion-general-carretero/informacion-contractual/contrato-contratista-carretero/contrato-contratista-carretero-base/contrato-contratista-carretero-base.component";
import { ModificacionContractualCarreteroComponent } from "./informacion-general-carretero/informacion-contractual/modificacion-contractual-carretero/modificacion-contractual-carretero.component";
import { AlcanceProyectoBaseComponent } from "./informacion-general-carretero/alcance-proyecto/alcance-proyecto-base/alcance-proyecto-base.component";
import { InformacionGeneralProyectoComponent } from "./informacion-general-carretero/informacion-general-proyecto/informacion-general-proyecto.component";
import { ContratoPlanPagoComponent } from "./contratacion/contrato-plan-pago/contrato-plan-pago.component";
import { ContratoSupervisorComponent } from "./contratacion/contrato-supervisor/contrato-supervisor.component";

import { PublicUatComponent } from "./publico/public-uat/public-uat.component";
import { SobreUatComponent } from "./publico/public-uat/sobre-uat/sobre-uat.component";
import { RepositorioUatComponent } from "./publico/public-uat/repositorio-uat/repositorio-uat.component";
import { InvestigacionUatComponent } from "./publico/public-uat/investigacion-uat/investigacion-uat.component";
import { AliadosUatComponent } from "./publico/public-uat/aliados-uat/aliados-uat.component";
import { MesaUatComponent } from "./publico/public-uat/mesa-uat/mesa-uat.component";
import { ModeloUatComponent } from "./publico/public-uat/modelo-uat/modelo-uat.component";
import { MisBienesComponent } from "./inventario-equipos/mis-bienes/mis-bienes.component";
import { ReporteCuentasCxpComponent } from "./contratacion/reportes/reporte-cuentas-cxp/reporte-cuentas-cxp/reporte-cuentas-cxp.component";
import { CompensacionesAmbientalesComponent } from "./compensaciones-ambientales/compensaciones-ambientales.component";
import { ObligacionesCompensacionComponent } from "./compensaciones-ambientales/obligaciones-compensacion/obligaciones-compensacion.component";
import { SeguimientoCompensacionesComponent } from "./compensaciones-ambientales/seguimiento-compensaciones/seguimiento-compensaciones.component";
import { SolicitudPagoAprobadasSupervisorComponent } from "./contratacion/informe-contratista/solicitud-pago-aprobadas-supervisor/solicitud-pago-aprobadas-supervisor.component";
import { RpteCxpBaseComponent } from "./contratacion/reportes/reporte-cuentas-cxp/reporte-cuenta-cxp-detalle/rpte-cxp-base/rpte-cxp-base.component";
import { MenuPeajesExternosComponent } from "./peajes-gestion/menu-peajes-externos/menu-peajes-externos.component";
import { DocumentosPeajesComponent } from "./peajes-gestion/documentos-peajes/documentos-peajes.component";
import { ReportesPeajesExternosComponent } from "./reportes/internos/interno-carreteras-consulta/interno-peajes-externos/interno-peajes-externos.component";
import { ReporteInformesContratistaEjecutivoComponent } from "./contratacion/reportes/reporte-informes-contratista-ejecutivo/reporte-informes-contratista-ejecutivo.component";
import { RecaudoPeajesComponent } from "./peajes-gestion/recaudo-peajes/recaudo-peajes.component";
import { DetalleRecaudoPeajesComponent } from "./peajes-gestion/recaudo-peajes/detalle-recaudo-peajes/detalle-recaudo-peajes.component";
import { ObligacionAmbientalComponent } from "./compensaciones-ambientales/obligaciones-compensacion/obligacion-ambiental/obligacion-ambiental.component";
import { GestionarLocalizacionComponent } from "./compensaciones-ambientales/obligaciones-compensacion/gestionar-localizacion/gestionar-localizacion.component";
import { EjecucionesObligacionComponent } from "./compensaciones-ambientales/seguimiento-compensaciones/ejecuciones-obligacion/ejecuciones-obligacion.component";
import { GestionarLocalizacionAvanceComponent } from "./compensaciones-ambientales/seguimiento-compensaciones/ejecuciones-obligacion/gestionar-localizacion-avance/gestionar-localizacion-avance.component";
import { RpteCxpTotalComponent } from "./contratacion/reportes/reporte-cuentas-cxp/reporte-cuentas-cxp-consolidado/rpte-cxp-total/rpte-cxp-total.component";
import { RpteCxpTotalDpnComponent } from "./contratacion/reportes/reporte-cuentas-cxp/reporte-cuentas-cxp-consolidado/rpte-cxp-total-dpn/rpte-cxp-total-dpn.component";
import { RpteCxpCmpvoComponent } from "./contratacion/reportes/reporte-cuentas-cxp/reporte-cuentas-cxp-comparativo/rpte-cxp-cmpvo/rpte-cxp-cmpvo.component";
import { ContratacionReportesCuentasCobroHomeComponent } from "./contratacion/contratacion-home/contratacion-reportes-cuentas-cobro-home/contratacion-reportes-cuentas-cobro-home.component";
import { ConsultarLocalizacionComponent } from "./compensaciones-ambientales/obligaciones-compensacion/consultar-localizacion/consultar-localizacion.component";
import { ConsultarLocalizacionAvanceComponent } from "./compensaciones-ambientales/seguimiento-compensaciones/ejecuciones-obligacion/consultar-localizacion-avance/consultar-localizacion-avance.component";
import { ReportesInventarioComponent } from "./inventario-equipos/reportes-inventario/reportes-inventario.component";
import { ReportesPeajesRecaudoComponent } from "./reportes/internos/interno-carreteras-consulta/interno-peajes-externos/interno-peajes-recaudo/interno-peajes-recaudo.component";
import { PrediosComponent } from "./predios/predios.component";
import { RegistroDetalladoPrediosComponent } from "./predios/registro-detallado-predios/registro-detallado-predios.component";
import { CrearEditarPredioComponent } from "./predios/registro-detallado-predios/crear-editar-predio/crear-editar-predio.component";
import { AdjuntosPredioComponent } from "./predios/registro-detallado-predios/adjuntos-predio/adjuntos-predio.component";
import { InformacionGeneralPeajesComponent } from "./peajes-gestion/informacion-general-peajes/informacion-general-peajes.component";
import { ConsultarMapaInformacionGeneralComponent } from "./peajes-gestion/informacion-general-peajes/informacion-general-peajes-consultar-mapa/informacion-general-peajes-consultar-mapa";
import { AniAppsComponent } from "./comun/ani-apps/ani-apps.component";
import { DatosAbiertosComponent } from "./datos-abiertos/datos-abiertos/datos-abiertos.component";
import { TarifasPeajesReportesComponent } from "./peajes-gestion/tarifas-peajes/tarifas-peajes-reportes/tarifas-peajes-reportes.component";
import { PuestosTrabajoComponent } from "./puestos-trabajo/puestos-trabajo.component";
import { CrearEditarReservaComponent } from "./puestos-trabajo/crear-editar-reserva/crear-editar-reserva.component";
import { RegistrarReservaComponent } from "./puestos-trabajo/registrar-reserva/registrar-reserva.component";
import { ListarCuposComponent } from "./puestos-trabajo/administracion/listar-cupos/listar-cupos.component";
import { CrearEditarCuposComponent } from "./puestos-trabajo/administracion/crear-editar-cupos/crear-editar-cupos.component";
import { PuestosTrabajoHomeComponent } from "./puestos-trabajo/puestos-trabajo-home/puestos-trabajo-home.component";
import { AdministracionHomeComponent } from "./puestos-trabajo/administracion/administracion-home/administracion-home.component";
import { ListarFranjasComponent } from "./puestos-trabajo/administracion/listar-franjas/listar-franjas.component";
import { CrearEditarFranjasComponent } from "./puestos-trabajo/administracion/crear-editar-franjas/crear-editar-franjas.component";
import { ConsultarLocalizacionUfComponent } from "./informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero/consultar-localizacion-uf/consultar-localizacion-uf.component";
import { GestionarLocalizacionUfComponent } from "./informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero/gestionar-localizacion-uf/gestionar-localizacion-uf.component";
import { ConsultarLocalizacionPredioComponent } from "./predios/registro-detallado-predios/consultar-localizacion-predio/consultar-localizacion-predio.component";
import { GestionarLocalizacionPredioComponent } from "./predios/registro-detallado-predios/gestionar-localizacion-predio/gestionar-localizacion-predio.component";
import { CargaMasivaPrediosComponent } from "./predios/carga-masiva-predios/carga-masiva-predios.component";
import { DatosMovimientoComponent } from "./datos-movimiento/datos-movimiento.component";
import { ComisionesHomeComponent } from "./comisiones/comisiones-home/comisiones-home.component";
import { CrearEditarSolicitudComisionComponent } from "./comisiones/crear-editar-solicitud-comision/crear-editar-solicitud-comision.component";
import { ComisionesLegalizacionesRegistroComponent } from "./comisiones/comisiones-legalizaciones/comisiones-legalizaciones-registro/comisiones-legalizaciones-registro.component";
import { ComisionesLegalizacionesRevisionComponent } from "./comisiones/comisiones-legalizaciones/comisiones-legalizaciones-revision/comisiones-legalizaciones-revision.component";
import { ComisionesTiquetesRegistroComponent } from "./comisiones/comisiones-tiquetes/comisiones-tiquetes-registro/comisiones-tiquetes-registro.component";
import { CrearEditarTiqueteComisionComponent } from "./comisiones/comisiones-tiquetes/comisiones-tiquetes-registro/crear-editar-tiquete-comision/crear-editar-tiquete-comision.component";
import { ComisionesTiquetesRevisionComponent } from "./comisiones/comisiones-tiquetes/comisiones-tiquetes-revision/comisiones-tiquetes-revision.component";
import { ComisionesConfiguracionComponent } from "./comisiones/comisiones-configuracion/comisiones-configuracion.component";
import { ComisionesSolicitudesComponent } from "./comisiones/comisiones-solicitudes/comisiones-solicitudes.component";
import { ComisionesConfiguracionDependenciasResponsableComponent } from "./comisiones/comisiones-configuracion-dependencias-responsable/comisiones-configuracion-dependencias-responsable.component";
import { ComisionesConfiguracionFuncionarioComponent } from "./comisiones/comisiones-configuracion-funcionario/comisiones-configuracion-funcionario.component";
import { ComisionesRevisarSolicitudesComponent } from "./comisiones/comisiones-revisar-solicitudes/comisiones-revisar-solicitudes.component";
import { ContratoBalanceFinancieroComponent } from "./contratacion/contrato-balance-financiero/contrato-balance-financiero.component";
import { VisorCompensacionesAmbientalesComponent } from "./reportes/internos/visor-compensaciones-ambientales/visor-compensaciones-ambientales.component";
import { VisorPolizasComponent } from "./reportes/internos/visor-polizas/visor-polizas.component";
import { RptCrrProyectoDetalleComponent } from "./reportes/carreteros/rpt-crr-proyecto-detalle/rpt-crr-proyecto-detalle.component";
import { RptCrrFichaProyectosComponent } from "./reportes/carreteros/rpt-crr-ficha-proyectos/rpt-crr-ficha-proyectos.component";
import { RptCrrProyectosComponent } from "./reportes/carreteros/rpt-crr-proyectos/rpt-crr-proyectos.component";
import { RptCrrPeajesComponent } from "./reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes.component";
import { RptCrrPeajesProyectoComponent } from "./reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-proyecto/rpt-crr-peajes-proyecto.component";
import { RptCrrPeajesDetalleComponent } from "./reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-detalle/rpt-crr-peajes-detalle.component";
import { RptCrrFicha5gComponent } from "./reportes/carreteros/rpt-crr-ficha5g/rpt-crr-ficha5g.component";
import { RptCrrPeajesTraficoRecaudoComponent } from "./reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-trafico-recaudo/rpt-crr-peajes-trafico-recaudo.component";
import { ComisionesGestionPresupuestalComisionesComponent } from "./comisiones/comisiones-gestion-presupuestal/comisiones-gestion-presupuestal-comisiones/comisiones-gestion-presupuestal-comisiones.component";
import { ComisionesGestionPresupuestalRegistroComponent } from "./comisiones/comisiones-gestion-presupuestal/comisiones-gestion-presupuestal-registro/comisiones-gestion-presupuestal-registro.component";
import { ComisionesRegistroPresupuestalObligacionComisionesComponent } from "./comisiones/comisiones-registro-presupuestal-obligacion/comisiones-registro-presupuestal-obligacion-comisiones/comisiones-registro-presupuestal-obligacion-comisiones.component";
import { ComisionesRegistroPresupuestalObligacionRegistroComponent } from "./comisiones/comisiones-registro-presupuestal-obligacion/comisiones-registro-presupuestal-obligacion-registro/comisiones-registro-presupuestal-obligacion-registro.component";
import { ComisionesOrdenPagoPresupuestalComisionesComponent } from "./comisiones/comisiones-orden-pago-presupuestal/comisiones-orden-pago-presupuestal-comisiones/comisiones-orden-pago-presupuestal-comisiones.component";
import { ComisionesOrdenPagoPresupuestalRegistroComponent } from "./comisiones/comisiones-orden-pago-presupuestal/comisiones-orden-pago-presupuestal-registro/comisiones-orden-pago-presupuestal-registro.component";
import { ComisionesReportesHomeComponent } from "./comisiones/comisiones-reportes/comisiones-reportes-home/comisiones-reportes-home.component";
import { ComisionesReporteEjecutivoComponent } from "./comisiones/comisiones-reportes/comisiones-reporte-ejecutivo/comisiones-reporte-ejecutivo.component";
import { ComisionesReporteDetalleVersionesComponent } from "./comisiones/comisiones-reportes/comisiones-reporte-detalle/comisiones-reporte-detalle-versiones/comisiones-reporte-detalle-versiones.component";
import { ComisionesReporteDetalleRegistroVersionesComponent } from "./comisiones/comisiones-reportes/comisiones-reporte-detalle/comisiones-reporte-detalle-registro-versiones/comisiones-reporte-detalle-registro-versiones.component";
import { RptCrrPeajesTarifasComponent } from "./reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-tarifas/rpt-crr-peajes-tarifas.component";
import { RptCrrPeajesTraficoRecaudoDetalleComponent } from "./reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-trafico-recaudo-detalle/rpt-crr-peajes-trafico-recaudo-detalle.component";
import { VisualizarComisionesCancelacionComponent } from "./comisiones/comisiones-revisar-solicitudes/visualizar-comisiones-cancelacion/visualizar-comisiones-cancelacion.component";
import { ContratoDependenciaComponent } from "./contratacion/contrato-dependencia/contrato-dependencia.component";
import { EquiposItsComponent } from "./equipos-its/equipos-its.component";
import { RegistroEquipoItsComponent } from "./equipos-its/registro-equipo-its/registro-equipo-its.component";
import { VisorMapaConflictividadProyectosComponent } from "./reportes/internos/visor-mapa-conflictividad-proyectos/visor-mapa-conflictividad-proyectos.component";
import { VisorMapaSocialRiesgosPeajesComponent } from "./reportes/internos/visor-mapa-social-riesgos-peajes/visor-mapa-social-riesgos-peajes.component";
import { VisorDispositivosItsComponent } from "./reportes/internos/visor-dispositivos-its/visor-dispositivos-its.component";
import { ApiServiceComponent } from "./comun/api-service/api-service.component";
import { TraficoAniComponent } from "./home/trafico-ani/trafico-ani.component";
import { OlaInvernalRegistroListadoComponent } from "./ola-invernal/ola-invernal-registro/ola-invernal-registro-listado/ola-invernal-registro-listado.component";
import { OlaInvernalRegistroComponent } from "./ola-invernal/ola-invernal-registro/ola-invernal-registro-registro/ola-invernal-registro.component";
import { OlaInvernalReporteDetalleListadoComponent } from "./ola-invernal/ola-invernal-reporte-detalle/ola-invernal-reporte-detalle-listado/ola-invernal-reporte-detalle-listado.component";
import { OlaInvernalReporteDetalleComponent } from "./ola-invernal/ola-invernal-reporte-detalle/ola-invernal-reporte-detalle/ola-invernal-reporte-detalle.component";

import { OlaInvernalFerreoRegistroListadoComponent } from "./ola-invernal-ferreo/ola-invernal-ferreo-registro/ola-invernal-ferreo-registro-listado/ola-invernal-ferreo-registro-listado.component";
import { OlaInvernalFerreoRegistroRegistroComponent } from "./ola-invernal-ferreo/ola-invernal-ferreo-registro/ola-invernal-ferreo-registro-registro/ola-invernal-ferreo-registro-registro.component";
import { OlaInvernalFerreoReporteDetalleListadoComponent } from "./ola-invernal-ferreo/ola-invernal-ferreo-reporte-detalle/ola-invernal-ferreo-reporte-detalle-listado/ola-invernal-ferreo-reporte-detalle-listado.component";
import { OlaInvernalFerreoReporteDetalleComponent } from "./ola-invernal-ferreo/ola-invernal-ferreo-reporte-detalle/ola-invernal-ferreo-reporte-detalle/ola-invernal-ferreo-reporte-detalle.component";

import { PublicNovedadesComponent } from "./publico/public-novedades/public-novedades.component";
import { PortafolioComponent } from "./publico/public-novedades/portafolio/portafolio.component";
import { ProyectosAeroportuarioComponent } from "./publico/public-novedades/proyectos-aeroportuario/proyectos-aeroportuario.component";
import { RutaFerrecaribeComponent } from "./publico/public-novedades/ruta-ferrecaribe/ruta-ferrecaribe.component";
import { ProyectosEducacionComponent } from "./publico/public-novedades/proyectos-educacion/proyectos-educacion.component";
import { ProyectosSaludComponent } from "./publico/public-novedades/proyectos-salud/proyectos-salud.component";
import { ProyectosSocialComponent } from "./publico/public-novedades/proyectos-social/proyectos-social.component";

import { PublicMapModosComponent } from "./reportes/publicos/public-map/public-map-modos/public-map-modos.component";
import { PublicMapPeajesComponent } from "./reportes/publicos/public-map/public-map-peajes/public-map-peajes.component";
import { PublicMapAreasInfluenciaComponent } from "./reportes/publicos/public-map/public-map-areas-influencia/public-map-areas-influencia.component";
import { PublicMapAreasAtlasComponent } from "./reportes/publicos/public-map/public-map-areas-atlas/public-map-areas-atlas.component";
import { PublicMapServiciosAyudaComponent } from "./reportes/publicos/public-map/public-map-servicios-ayuda/public-map-servicios-ayuda.component";

import { SigCanalDelDiqueComponent } from "./reportes/internos/sig/sig-canal-del-dique/sig-canal-del-dique.component";
import { SigFaunaniComponent } from "./reportes/internos/sig/sig-faunani/sig-faunani.component";

import { SigCompensacionesAmbientalesComponent } from "./reportes/internos/sig/sig-compensaciones-ambientales/sig-compensaciones-ambientales.component";
import { SigCriticidadPeajesComponent } from "./reportes/internos/sig/sig-criticidad-peajes/sig-criticidad-peajes.component";
import { SigDispositivosITSComponent } from "./reportes/internos/sig/sig-dispositivos-its/sig-dispositivos-its.component";
import { SigPredialComponent } from "./reportes/internos/sig/sig-predial/sig-predial.component";
import { SigPuertosComponent } from "./reportes/internos/sig/sig-puertos/sig-puertos.component";
import { SigTraficoWazeComponent } from "./reportes/internos/sig/sig-trafico-waze/sig-trafico-waze.component";
import { CtoReporteCxpDevolucionesTesoreriaComponent } from "./contratacion/reportes/cto-reporte-cxp-devoluciones-tesoreria/cto-reporte-cxp-devoluciones-tesoreria.component";
import { CtoReporteCxpPlanesPagoCuentasCobroComponent } from "./contratacion/reportes/cto-reporte-cxp-planes-pago-cuentas-cobro/cto-reporte-cxp-planes-pago-cuentas-cobro.component";
import { CtoReporteCxpContratosComponent } from "./contratacion/reportes/cto-reporte-cxp-contratos/cto-reporte-cxp-contratos.component";
import { RptCrrPeajesTpdComponent } from "./reportes/carreteros/rpt-crr-peajes/rpt-crr-peajes-trafico-recaudo/rpt-crr-peajes-tpd/rpt-crr-peajes-tpd.component";

import { TramitesAmbientalesListadoComponent } from './tramites-ambientales-aeropuerto/tramites-ambientales-listado/tramites-ambientales-listado.component';
import { TramitesAmbientalesComponent } from './tramites-ambientales-aeropuerto/tramites-ambientales/tramites-ambientales.component';
import { TramitesAmbientalesSeguimientoComponent } from './tramites-ambientales-aeropuerto/tramites-ambientales-seguimiento/tramites-ambientales-seguimiento.component';

import { TramitesAmbientalesListadoFerreoComponent } from './tramites-ambientales-ferreo/tramites-ambientales-listado/tramites-ambientales-listado.component';
import { TramitesAmbientalesFerreoComponent } from './tramites-ambientales-ferreo/tramites-ambientales/tramites-ambientales.component';
import { TramitesAmbientalesSeguimientoFerreoComponent } from './tramites-ambientales-ferreo/tramites-ambientales-seguimiento/tramites-ambientales-seguimiento.component';

import { TramitesAmbientalesListadoPuertoComponent } from './tramites-ambientales-puerto/tramites-ambientales-listado/tramites-ambientales-listado.component';
import { TramitesAmbientalesPuertoComponent } from './tramites-ambientales-puerto/tramites-ambientales/tramites-ambientales.component';
import { TramitesAmbientalesSeguimientoPuertoComponent } from './tramites-ambientales-puerto/tramites-ambientales-seguimiento/tramites-ambientales-seguimiento.component';

import { TramitesAmbientalesListadoCarreteroComponent } from './tramites-ambientales-carretero/tramites-ambientales-listado-carretero/tramites-ambientales-listado-carretero.component';
import { TramitesAmbientalesCarreteroComponent } from './tramites-ambientales-carretero/tramites-ambientales-carretero/tramites-ambientales-carretero.component';
import { TramitesAmbientalesSeguimientoCarreteroComponent } from './tramites-ambientales-carretero/tramites-ambientales-seguimiento-carretero/tramites-ambientales-seguimiento-carretero.component';
import { TramitesAmbientalesFluvialComponent } from './tramites-ambientales-fluvial/tramites-ambientales-fluvial/tramites-ambientales-fluvial.component';
import { FluvialComponent } from './fluvial/fluvial.component';
import { ProyectoHomeFluvialComponent } from './proyecto-home-fluvial/proyecto-home-fluvial.component';
import { TramitesAmbientalesListadoFluvialComponent } from './tramites-ambientales-fluvial/tramites-ambientales-listado-fluvial/tramites-ambientales-listado-fluvial.component';
import { TramitesAmbientalesSeguimientoFluvialComponent } from './tramites-ambientales-fluvial/tramites-ambientales-seguimiento-fluvial/tramites-ambientales-seguimiento-fluvial.component';

const routes: Routes = [
  { path: "apiService", component: ApiServiceComponent },
  { path: "inicial", component: PaginaInicialComponent },
  { path: "carreterosNoAut", component: AnonimoCarreterosComponent },
  { path: "validarCertificado", component: CertificadoPdfComponent },
  { path: "reportes/carreteros", component: RptCrrFichaProyectosComponent },
  {
    path: "reportes/carreteros/proyectos",
    component: RptCrrProyectosComponent,
  },
  {
    path: "reportes/carreteros/proyectoDetalle/:id",
    component: RptCrrProyectoDetalleComponent,
  },
  { path: "reportes/carreteros/peajes", component: RptCrrPeajesComponent },
  {
    path: "reportes/carreteros/peajes-proyecto",
    component: RptCrrPeajesProyectoComponent,
  },
  {
    path: "reportes/carreteros/peajes-tarifas/:idPeaje",
    component: RptCrrPeajesTarifasComponent,
  },
  {
    path: "reportes/carreteros/peajes-traficorecaudo/:idPeaje",
    component: RptCrrPeajesTraficoRecaudoComponent,
  },
  {
    path: "reportes/carreteros/peajes-tpd",
    component: RptCrrPeajesTpdComponent,
  },
  {
    path: "reportes/carreteros/peajes-detalle/:idPeaje/:idEncabezado",
    component: RptCrrPeajesDetalleComponent,
  },
  {
    path: "reportes/carreteros/peajes-traficorecaudo-detalle/:idEncabezado",
    component: RptCrrPeajesTraficoRecaudoDetalleComponent,
  },
  {
    path: "reportes/carreteros/ficha5g/:id",
    component: RptCrrFicha5gComponent,
  },
  {
    path: "reportes/carreteros/ola-invernal-reporte-detalle-listado",
    data: { breadcrumb: "Reporte detalle Ola Invernal" },
    children: [
      {
        path: "",
        component: OlaInvernalReporteDetalleListadoComponent,
        data: { breadcrumb: null },
      },
      {
        path: "reportes/carreteros/ola-invernal-reporte-detalle/:idProyecto/:idOlaInvernal",
        component: OlaInvernalReporteDetalleComponent,
        data: { breadcrumb: "Reporte Detalle Ola Invernal" },
      },
    ],
  },
  {
    path: "reportes/ferrocarriles/ola-invernal-ferreo-reporte-detalle-listado",
    data: { breadcrumb: "Reporte detalle Ola Invernal Ferrocariles" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: "",
        component: OlaInvernalFerreoReporteDetalleListadoComponent,
        data: { breadcrumb: null },
      },
      {
        path: "reportes/ferrocarriles/ola-invernal-ferreo-reporte-detalle/:idProyecto/:idOlaInvernal",
        component: OlaInvernalFerreoReporteDetalleComponent,
        data: { breadcrumb: "Reporte Detalle Ola Invernal Ferrocariles" },
        canActivate: [CanActivateViaAuthGuard],
      },
    ],
  },

  {
    path: "",
    data: { breadcrumb: "" },
    children: [
      { path: "", component: HomeComponent, data: { breadcrumb: null } },
      {
        path: "carreteros",
        canActivate: [CanActivateViaAuthGuard],
        data: { breadcrumb: "Carreteros" },
        children: [
          {
            path: "",
            component: InformacionGeneralComponent,
            data: { breadcrumb: null },
          },
          {
            path: "infGeneral",
            canActivate: [CanActivateViaAuthGuard],
            data: { breadcrumb: "Registra Información" },
            children: [
              {
                path: "",
                component: InformacionGeneralComponent,
                data: { breadcrumb: null },
              },
              {
                path: "proyectoLineaBase/:id",
                component: ProyectoLineaBaseComponent,
                data: { breadcrumb: "Nuevo Proyecto" },
              },
              {
                path: "proyectoHome/:id",
                data: { breadcrumb: "Proyecto" },
                canActivate: [CanActivateViaAuthGuard],
                children: [
                  {
                    path: "",
                    component: ProyectoHomeComponent,
                    data: { breadcrumb: null },
                  },
                  {
                    path: "contratosProyecto/:id",
                    data: { breadcrumb: "Infomación Contractual" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: ContratosCarreteroComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "contrato-contratista-carretero/:idProyecto/:idContrato/:idContratista",
                        component: ContratoContratistaCarreteroBaseComponent,
                        data: { breadcrumb: "Infomación del contrato" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "modificacion-contractual-carretero/:idProyecto/:idContrato",
                        component: ModificacionContractualCarreteroComponent,
                        data: { breadcrumb: "Modificación contractual" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "informacion-general-proyecto/:id",
                    component: InformacionGeneralProyectoComponent,
                    data: { breadcrumb: "Infomación General Proyecto" },
                    canActivate: [CanActivateViaAuthGuard],
                  },
                  {
                    path: "alcance-proyecto/:id",
                    component: AlcanceProyectoBaseComponent,
                    data: { breadcrumb: "Alcance del Proyecto" },
                    canActivate: [CanActivateViaAuthGuard],
                  },
                  {
                    path: "alcance-proyecto/:id/consultar-localizacion-uf/:ufId/:ufIdUnidadFuncional",
                    component: ConsultarLocalizacionUfComponent,
                    data: { breadcrumb: "Alcance del Proyecto" },
                    canActivate: [CanActivateViaAuthGuard],
                  },
                  {
                    path: "alcance-proyecto/:id/gestionar-localizacion-uf/:ufId/:ufIdUnidadFuncional",
                    component: GestionarLocalizacionUfComponent,
                    data: { breadcrumb: "Alcance del Proyecto" },
                    canActivate: [CanActivateViaAuthGuard],
                  },
                  {
                    path: "proyectoLineaBase/:id",
                    component: ProyectoLineaBaseComponent,
                    data: { breadcrumb: "Información General" },
                    canActivate: [CanActivateViaAuthGuard],
                  },
                  {
                    path: "avance-proyecto/:id",
                    component: AvanceProyectoComponent,
                    data: { breadcrumb: "Avance Proyecto" },
                  },
                  {
                    path: "trafico-recaudo/:id",
                    component: TraficoRecaudoComponent,
                    data: { breadcrumb: "Tráfico y Recaudo" },
                  },
                  {
                    path: "covid-reportar/:id",
                    data: { breadcrumb: "Reportar Covid 19" },
                    children: [
                      {
                        path: "",
                        component: CovidReportarComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "covid-reportar-reinicio-obra/:id",
                        component: CovidReportarReinicioObraComponent,
                        data: { breadcrumb: "Reinicio de Obra" },
                      },
                      {
                        path: "covid-reportar-seguimiento/:id",
                        component: CovidReportarSeguimientoComponent,
                        data: { breadcrumb: "Seguimiento de Protocolos" },
                      },
                      {
                        path: "covid-reportes-interno/:idProyecto",
                        data: { breadcrumb: "Reportes COVID" },
                        children: [
                          {
                            path: "",
                            component: CovidReportesInternoComponent,
                            data: { breadcrumb: null },
                          },

                          {
                            path: "covid-departamento-interno/:idProyecto",
                            component: ReporteDepartamentoInternoComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Covid Por Departamento" },
                          },
                          {
                            path: "covid-sintomas-interno/:idProyecto",
                            component: ReporteSintomasInternoComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Covid Sintomas" },
                          },
                          {
                            path: "reporte-covid-bi-interno/:idProyecto",
                            component: ReporteCovidBiInternoComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Tablero Covid" },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "compensaciones-ambientales/:id",
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Compensaciones Ambientales" },
                    children: [
                      {
                        path: "",
                        component: CompensacionesAmbientalesComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "obligaciones-compensacion/:id",
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Obligaciones Compensacion" },
                        children: [
                          {
                            path: "",
                            component: ObligacionesCompensacionComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "obligacion-ambiental/:id/:idOblig",
                            component: ObligacionAmbientalComponent,
                            data: { breadcrumb: "Obligación Ambiental" },
                          },
                          {
                            path: "gestionar-localizacion/:id/:idOblig",
                            component: GestionarLocalizacionComponent,
                            data: { breadcrumb: "Gestionar Localizacion" },
                          },
                          {
                            path: "consultar-localizacion/:id/:idOblig",
                            component: ConsultarLocalizacionComponent,
                            data: { breadcrumb: "Consultar Localizacion" },
                          },
                        ],
                      },
                      {
                        path: "seguimiento-compensaciones/:id",
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Seguimiento Compensaciones" },
                        children: [
                          {
                            path: "",
                            component: SeguimientoCompensacionesComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "ejecuciones-obligacion/:id/:idOblig",
                            data: { breadcrumb: "Ejecuciones Obligacion" },
                            children: [
                              {
                                path: "",
                                component: EjecucionesObligacionComponent,
                                data: { breadcrumb: null },
                              },
                              {
                                path: "gestionar-localizacion-avance/:id/:idOblig/:idEjecucion",
                                component: GestionarLocalizacionAvanceComponent,
                                data: {
                                  breadcrumb: "Gestionar Localizacion Avance",
                                },
                              },
                              {
                                path: "consultar-localizacion-avance/:id/:idOblig/:idEjecucion",
                                component: ConsultarLocalizacionAvanceComponent,
                                data: {
                                  breadcrumb: "Consultar Localizacion Avance",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "predios/:id",
                    data: { breadcrumb: "Predial" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: PrediosComponent,
                        data: { breadcrumb: null },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "registro-agrupado-predios/:id",
                        data: { breadcrumb: "Registro Agrupado Predios" },
                        children: [
                          {
                            path: "",
                            component: PredialComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "infPredialDetalle/:id",
                            component: PredialDetalleComponent,
                            data: { breadcrumb: "Detalle Predial" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                      {
                        path: "registro-detallado-predios/:id",
                        data: { breadcrumb: "Registro Detallado Predios" },
                        children: [
                          {
                            path: "",
                            component: RegistroDetalladoPrediosComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "crear-editar-predio/:id/:idPredioHistorico/:borrador",
                            component: CrearEditarPredioComponent,
                            data: { breadcrumb: "Predio" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "adjuntos-predio/:id/:idPredioHistorico/:idPredio/:borrador",
                            component: AdjuntosPredioComponent,
                            data: { breadcrumb: "Adjuntos Predio" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "consultar-localizacion-predio/:id/:idPredio/:borrador",
                            component: ConsultarLocalizacionPredioComponent,
                            data: {
                              breadcrumb: "Consultar Localización Predio",
                            },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "gestionar-localizacion-predio/:id/:idPredio/:borrador",
                            component: GestionarLocalizacionPredioComponent,
                            data: {
                              breadcrumb: "Gestionar Localización Predio",
                            },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                      {
                        path: "carga-masiva-predios/:id",
                        data: { breadcrumb: "Carga Masiva Predios" },
                        children: [
                          {
                            path: "",
                            component: CargaMasivaPrediosComponent,
                            data: { breadcrumb: null },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "equiposIts/:idProyecto",
                    data: { breadcrumb: "Equipos ITS" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: EquiposItsComponent,
                        data: { breadcrumb: null },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "registro-equipos-its/:idProyecto/:id/:soloLectura",
                        data: { breadcrumb: "Registro Equipo Its" },
                        children: [
                          {
                            path: "",
                            component: RegistroEquipoItsComponent,
                            data: { breadcrumb: null },
                          },
                        ],
                      },
                    ],
                  },

                  {
                    path: "menu-peajes/:id",
                    data: { breadcrumb: "Peajes" },
                    children: [
                      {
                        path: "",
                        component: MenuPeajesComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "trafico-peajes/:id",
                        component: TraficoPeajesComponent,
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Trafico de Peajes" },
                      },
                      {
                        path: "tarifas-peajes/:id",
                        data: { breadcrumb: "Tarifas" },
                        children: [
                          {
                            path: "",
                            component: TarifasPeajesComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: null },
                          },
                          {
                            path: "nueva-tarifa/:id/:idPeaje/:esLectura/:idEncabezado",
                            component: NuevaTarifaPeajesComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Configuracion Tarifa" },
                          },
                        ],
                      },
                      {
                        path: "informacion-general-peajes/:id",
                        data: { breadcrumb: "Información General Peajes" },
                        children: [
                          {
                            path: "",
                            component: InformacionGeneralPeajesComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: null },
                          },
                          {
                            path: "consultar-mapa-informacion-general/:idProyecto/:idPeaje",
                            component: ConsultarMapaInformacionGeneralComponent,
                            data: { breadcrumb: "Ubicación Peaje" },
                          },
                        ],
                      },
                      {
                        path: "recaudo-peajes/:id",
                        data: { breadcrumb: "Recaudo de Peajes" },
                        children: [
                          {
                            path: "",
                            component: RecaudoPeajesComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: null },
                          },
                          {
                            path: "detalle-recaudo-peajes/:id/:idPeriodo",
                            component: DetalleRecaudoPeajesComponent,
                            data: { breadcrumb: "Detalle Periodo Recaudo" },
                          },
                        ],
                      },
                    ],
                  },

                  {
                    path: "infAmbiental/:id",
                    data: { breadcrumb: "Obligaciones Ambientales" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: AmbientalComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infAmbientalDetalle/:id",
                        component: AmbientalDetalleComponent,
                        data: { breadcrumb: "Detalle Obligación Ambiental" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "infPredial/:id",
                    data: { breadcrumb: "Predial" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: PredialComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infPredialDetalle/:id",
                        component: PredialDetalleComponent,
                        data: { breadcrumb: "Detalle Predial" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "menuJuridico/:id",
                    data: { breadcrumb: "Jurídico" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: MenuJuridicoComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infAmigables/:id",
                        data: { breadcrumb: "Amigables" },
                        canActivate: [CanActivateViaAuthGuard],
                        children: [
                          {
                            path: "",
                            component: AmigablesComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "infAmigablesDetalle/:id",
                            component: AmigablesDetalleComponent,
                            data: { breadcrumb: "Detalle Amigable" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "infSeguimientoAmigables/:id",
                            component: SeguimientoAmigableComponent,
                            data: { breadcrumb: "Seguimiento Amigable" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                      {
                        path: "infTribunales/:id",
                        data: { breadcrumb: "Tribunales" },
                        canActivate: [CanActivateViaAuthGuard],
                        children: [
                          {
                            path: "",
                            component: TribunalesComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "infTribunalesDetalle/:id",
                            component: TribunalesDetalleComponent,
                            data: { breadcrumb: "Detalle Tribunal" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "infSeguimientoTribunales/:id",
                            component: SeguimientoTribunalesComponent,
                            data: { animation: "Seguimiento Tribunal" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "financieroHome/:id",
                    data: { breadcrumb: "Información Financiera" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: FinancieroHomeComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "finInversionIndexada/:idProyecto",
                        component: InversionIndexadaComponent,
                        data: { breadcrumb: "Inversion Por Proyecto" },
                      },
                      {
                        path: "informacionGeneralFinanciera/:idProyecto",
                        component: InformacionGeneralBaseComponent,
                        data: { breadcrumb: "Información General" },
                      },
                      {
                        path: "finSaldosFinancieros/:idProyecto",
                        component: SaldoFinancieroComponent,
                        data: { breadcrumb: "Saldos Financieros" },
                      },
                      {
                        path: "finContratoCreditoBase/:idProyecto",
                        component: ContratoCreditoBaseComponent,
                        data: { breadcrumb: "Contrato Crédito" },
                      },
                      {
                        path: "finContratoCreditoPuenteBase/:idProyecto",
                        component: ContratoCreditoPuenteBaseComponent,
                        data: { breadcrumb: "Contrato Crédito Puente" },
                      },
                      {
                        path: "finRetribucionFinanciera/:idProyecto",
                        component: RetribucionFinancieraComponent,
                        data: { breadcrumb: "Retribuciones" },
                      },
                      {
                        path: "finVigenciasFuturas/:idProyecto",
                        component: VigenciasFuturasComponent,
                        data: { breadcrumb: "Vigencias Futuras" },
                      },
                      {
                        path: "finVpip/:idProyecto",
                        component: VpipFinancieroComponent,
                        data: { breadcrumb: "VPIP" },
                      },
                    ],
                  },
                  {
                    path: "infTemasG/:id",
                    data: { breadcrumb: "Temas de Gestión" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: TemasGestionComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infTemasGDetalle/:id",
                        component: TemasGestionDetalleComponent,
                        data: { breadcrumb: "Detalle Tema" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "infTemasGRegistro/:id",
                        component: TemasGestionRegistroComponent,
                        data: { breadcrumb: "Actualizar Tema" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "servicioCarga/:id",
                    data: { breadcrumb: "Servicios de carga" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: InteresadosListaComponent,
                        data: { breadcrumb: null },
                      },
                    ],
                  },
                  {
                    path: "infTramitesA/:id",
                    data: { breadcrumb: "Trámites Ambientales" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: TramiteAmbientalComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infTramitesADetalle/:id",
                        component: TramiteAmbientalDetalleComponent,
                        data: { breadcrumb: "Detalle Trámite" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "infSeguimientoAmbiental/:id",
                        component: SeguimientoComponent,
                        data: { breadcrumb: "Seguimiento Trámite" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: 'tramites-carretero-listado/:idProyecto', data: { breadcrumb: 'Trámites Ambientales' }, canActivate: [CanActivateViaAuthGuard], children: [
                      { path: '', component: TramitesAmbientalesListadoCarreteroComponent, data: { breadcrumb: null } },
                      { path: 'tramites-carretero-detalle/:idProyecto/:idTramite', component: TramitesAmbientalesCarreteroComponent, data: { breadcrumb: 'Detalle Trámite' }, canActivate: [CanActivateViaAuthGuard] },
                      { path: 'tramites-carretero-seguimiento/:idProyecto/:idTramite', component: TramitesAmbientalesSeguimientoCarreteroComponent, data: { breadcrumb: 'Seguimiento Trámite' }, canActivate: [CanActivateViaAuthGuard] },
                    ]
                  },
                  {
                    path: "polizas/:id",
                    data: { breadcrumb: "Pólizas" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: PolizasComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "polizaEdit/:id",
                        component: PolizaEditComponent,
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Detalle Póliza" },
                      },
                      {
                        path: "anexos/:id",
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Lista Anexos" },
                        children: [
                          {
                            path: "",
                            component: AmparosComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "anexoEdit/:id",
                            component: AmparosEditComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Detalle Anexo" },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "cargaExtrapesadaConc/:id",
                    data: { breadcrumb: "Listado de Carga Extrapesada" },
                    children: [
                      {
                        path: "",
                        component: ConcesionarioInviasPrincipalListComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "crearRespuesta",
                        data: { breadcrumb: "Crear Respuesta" },
                        children: [
                          {
                            path: "",
                            component: ConcesionarioInviasDetalleComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "radicacionRespuesta(DESACTIVADO)",
                            data: { breadcrumb: "Radicacion de Respuesta" },
                            children: [
                              {
                                path: "",
                                component:
                                  ConcesionarioRadicacionRespuestaComponent,
                                data: { breadcrumb: null },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "listRadicacionWeb/:id",
                    data: { breadcrumb: "Bandeja de Radicación Web" },
                    children: [
                      {
                        path: "",
                        component: ListadoRadicacionWebComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "newEditRadicacion",
                        data: { breadcrumb: "Crear/Editar Radicación" },
                        children: [
                          {
                            path: "",
                            component: NewEditRadicacionWebComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "editFirmantesRwb",
                            data: { breadcrumb: "Editar de Firmantes" },
                            children: [
                              {
                                path: "",
                                component: EditFirmantesRadicacionWebComponent,
                                data: { breadcrumb: null },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "ola-invernal-registro/:idProyecto",
                    data: { breadcrumb: "Registro Ola Invernal" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: OlaInvernalRegistroListadoComponent,
                        data: { breadcrumb: null },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "ola-invernal-registro-registro/:idProyecto/:idOlaInveral/:soloLectura",
                        data: { breadcrumb: "Registro Ola Invernal" },
                        children: [
                          {
                            path: "",
                            component: OlaInvernalRegistroComponent,
                            data: { breadcrumb: null },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "reportes",
            canActivate: [CanActivateViaAuthGuard],
            data: { breadcrumb: "Reportes" },
            children: [
              {
                path: "",
                component: ReportesComponent,
                data: { breadcrumb: null },
              },
              {
                path: "reportesCovid",
                data: { breadcrumb: "COVID" },
                children: [
                  {
                    path: "",
                    component: CovidReportesComponent,
                    data: { breadcrumb: null },
                  },

                  {
                    path: "covidDepartamento",
                    component: ReporteDepartamentoComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Covid Por Departamento" },
                  },
                  {
                    path: "covidSintomas",
                    component: ReporteSintomasComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Covid Sintomas" },
                  },
                  {
                    path: "covidTablero",
                    component: ReporteCovidBiInternoComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Tablero Covid" },
                  },
                ],
              },
              {
                path: "reportesPeajes",
                component: TarifasPeajesReportesComponent,
                data: { breadcrumb: "Tarifas Peajes" },
              },
            ],
          },
        ],
      },
      {
        path: 'fluvial', data: { breadcrumb: "Fluvial" }, children: [
          { path: '', component: InformacionGeneralComponent, data: { breadcrumb: null } },
          {
            path: 'infGeneral', data: { breadcrumb: 'Registra Información' }, children: [
              { path: '', component: InformacionGeneralComponent, data: { breadcrumb: null } },
              {
                path: 'proyectoHome/:id', data: { breadcrumb: 'Proyecto' }, children: [
                  { path: '', component: ProyectoHomeFluvialComponent, data: { breadcrumb: null } },
                  {
                    path: 'tramites-fluvial-listado/:idProyecto', data: { breadcrumb: 'Trámites Ambientales' }, children: [
                      { path: '', component: TramitesAmbientalesListadoFluvialComponent, data: { breadcrumb: null } },
                      { path: 'tramites-fluvial-detalle/:idProyecto/:idTramite', component: TramitesAmbientalesFluvialComponent, data: { breadcrumb: 'Detalle Trámite' }},
                      { path: 'tramites-fluvial-seguimiento/:idProyecto/:idTramite', component: TramitesAmbientalesSeguimientoFluvialComponent, data: { breadcrumb: 'Seguimiento Trámite' }},
                    ]
                  }

                ]
              },
            ]
          },
        ]
      },
      {
        path: "aeropuertos",
        canActivate: [CanActivateViaAuthGuard],
        data: { breadcrumb: "Aeropuertos" },
        children: [
          {
            path: "",
            component: InformacionGeneralComponent,
            data: { breadcrumb: null },
          },
          {
            path: "infGeneral",
            canActivate: [CanActivateViaAuthGuard],
            data: { breadcrumb: "Registra Información" },
            children: [
              {
                path: "",
                component: InformacionGeneralComponent,
                data: { breadcrumb: null },
              },
              {
                path: "proyectoLineaBase/:id",
                component: ProyectoLineaBaseComponent,
                data: { breadcrumb: "Nuevo Proyecto" },
              },
              {
                path: "proyectoHome/:id",
                data: { breadcrumb: "Proyecto" },
                canActivate: [CanActivateViaAuthGuard],
                children: [
                  {
                    path: "",
                    component: ProyectoHomeAeroComponent,
                    data: { breadcrumb: null },
                  },
                  {
                    path: "proyectoLineaBase/:id",
                    component: ProyectoLineaBaseComponent,
                    data: { breadcrumb: "Información General" },
                    canActivate: [CanActivateViaAuthGuard],
                  },
                  {
                    path: "avance-proyecto/:id",
                    component: AvanceProyectoComponent,
                    data: { breadcrumb: "Avance Proyecto" },
                  },
                  {
                    path: "trafico-recaudo/:id",
                    component: TraficoRecaudoComponent,
                    data: { breadcrumb: "Tráfico y Recaudo" },
                  },
                  {
                    path: "infAmbiental/:id",
                    data: { breadcrumb: "Obligaciones Ambientales" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: AmbientalComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infAmbientalDetalle/:id",
                        component: AmbientalDetalleComponent,
                        data: { breadcrumb: "Detalle Obligación Ambiental" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "infPredial/:id",
                    data: { breadcrumb: "Predial" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: PredialComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infPredialDetalle/:id",
                        component: PredialDetalleComponent,
                        data: { breadcrumb: "Detalle Predial" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "menuJuridico/:id",
                    data: { breadcrumb: "Jurídico" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: MenuJuridicoComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infAmigables/:id",
                        data: { breadcrumb: "Amigables" },
                        canActivate: [CanActivateViaAuthGuard],
                        children: [
                          {
                            path: "",
                            component: AmigablesComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "infAmigablesDetalle/:id",
                            component: AmigablesDetalleComponent,
                            data: { breadcrumb: "Detalle Amigable" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "infSeguimientoAmigables/:id",
                            component: SeguimientoAmigableComponent,
                            data: { breadcrumb: "Seguimiento Amigable" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                      {
                        path: "infTribunales/:id",
                        data: { breadcrumb: "Tribunales" },
                        canActivate: [CanActivateViaAuthGuard],
                        children: [
                          {
                            path: "",
                            component: TribunalesComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "infTribunalesDetalle/:id",
                            component: TribunalesDetalleComponent,
                            data: { breadcrumb: "Detalle Tribunal" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "infSeguimientoTribunales/:id",
                            component: SeguimientoTribunalesComponent,
                            data: { animation: "Seguimiento Tribunal" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "financieroHome/:id",
                    data: { breadcrumb: "Información Financiera" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: FinancieroHomeComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "finInversionIndexada/:idProyecto",
                        component: InversionIndexadaComponent,
                        data: { breadcrumb: "Inversion Por Proyecto" },
                      },
                      {
                        path: "informacionGeneralFinanciera/:idProyecto",
                        component: InformacionGeneralBaseComponent,
                        data: { breadcrumb: "Información General" },
                      },
                      {
                        path: "finSaldosFinancieros/:idProyecto",
                        component: SaldoFinancieroComponent,
                        data: { breadcrumb: "Saldos Financieros" },
                      },
                      {
                        path: "finContratoCreditoBase/:idProyecto",
                        component: ContratoCreditoBaseComponent,
                        data: { breadcrumb: "Contrato Crédito" },
                      },
                      {
                        path: "finContratoCreditoPuenteBase/:idProyecto",
                        component: ContratoCreditoPuenteBaseComponent,
                        data: { breadcrumb: "Contrato Crédito Puente" },
                      },
                      {
                        path: "finRetribucionFinanciera/:idProyecto",
                        component: RetribucionFinancieraComponent,
                        data: { breadcrumb: "Retribuciones" },
                      },
                      {
                        path: "finVigenciasFuturas/:idProyecto",
                        component: VigenciasFuturasComponent,
                        data: { breadcrumb: "Vigencias Futuras" },
                      },
                      {
                        path: "finVpip/:idProyecto",
                        component: VpipFinancieroComponent,
                        data: { breadcrumb: "VPIP" },
                      },
                    ],
                  },
                  {
                    path: "infTemasG/:id",
                    data: { breadcrumb: "Temas de Gestión" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: TemasGestionComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infTemasGDetalle/:id",
                        component: TemasGestionDetalleComponent,
                        data: { breadcrumb: "Detalle Tema" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "infTemasGRegistro/:id",
                        component: TemasGestionRegistroComponent,
                        data: { breadcrumb: "Actualizar Tema" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "servicioCarga/:id",
                    data: { breadcrumb: "Servicios de carga" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: InteresadosListaComponent,
                        data: { breadcrumb: null },
                      },
                    ],
                  },
                  {
                    path: "infTramitesA/:id",
                    data: { breadcrumb: "Trámites Ambientales" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: TramiteAmbientalComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infTramitesADetalle/:id",
                        component: TramiteAmbientalDetalleComponent,
                        data: { breadcrumb: "Detalle Trámite" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "infSeguimientoAmbiental/:id",
                        component: SeguimientoComponent,
                        data: { breadcrumb: "Seguimiento Trámite" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "polizas/:id",
                    data: { breadcrumb: "Pólizas" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: PolizasComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "polizaEdit/:id",
                        component: PolizaEditComponent,
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Detalle Póliza" },
                      },
                      {
                        path: "anexos/:id",
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Lista Anexos" },
                        children: [
                          {
                            path: "",
                            component: AmparosComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "anexoEdit/:id",
                            component: AmparosEditComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Detalle Anexo" },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'tramites-ambientales-listado/:id', data: { breadcrumb: "Trámites Ambientales Listado" }, canActivate: [CanActivateViaAuthGuard], children: [
                      { path: '', component: TramitesAmbientalesListadoComponent, data: { breadcrumb: null } },
                      { path: 'tramites-ambientales-edit/:id', component: TramitesAmbientalesComponent, canActivate: [CanActivateViaAuthGuard], data: { breadcrumb: 'Agregar/Editar Tramites Ambientales' } },
                      { path: 'tramites-ambientales-seguimiento/:id', component: TramitesAmbientalesSeguimientoComponent, canActivate: [CanActivateViaAuthGuard], data: { breadcrumb: 'Seguimiento Trámites Ambientales' } },
                    ]
                  },
                  {
                    path: "covid-reportar/:id",
                    data: { breadcrumb: "Reportar Covid 19" },
                    children: [
                      {
                        path: "",
                        component: CovidReportarComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "covid-reportar-reinicio-obra/:id",
                        component: CovidReportarReinicioObraComponent,
                        data: { breadcrumb: "Reinicio de Obra" },
                      },
                      {
                        path: "covid-reportar-seguimiento/:id",
                        component: CovidReportarSeguimientoComponent,
                        data: { breadcrumb: "Seguimiento de Protocolos" },
                      },
                      {
                        path: "covid-reportes-interno/:idProyecto",
                        data: { breadcrumb: "Reportes COVID" },
                        children: [
                          {
                            path: "",
                            component: CovidReportesInternoComponent,
                            data: { breadcrumb: null },
                          },

                          {
                            path: "covid-departamento-interno/:idProyecto",
                            component: ReporteDepartamentoInternoComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Covid Por Departamento" },
                          },
                          {
                            path: "covid-sintomas-interno/:idProyecto",
                            component: ReporteSintomasInternoComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Covid Sintomas" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "aeropuertos-interno/ficha-proyecto-drupal",
            component: AeropuertosHomeFichaProyectoDrupalComponent,
          },
          {
            path: "reportesAero",
            canActivate: [CanActivateViaAuthGuard],
            data: { breadcrumb: "Reportes" },
            children: [
              {
                path: "",
                component: ReportesAeropuertoComponent,
                data: { breadcrumb: null },
              },

              {
                path: "reportesCovid",
                data: { breadcrumb: "COVID" },
                children: [
                  {
                    path: "",
                    component: CovidReportesComponent,
                    data: { breadcrumb: null },
                  },

                  {
                    path: "covidDepartamento",
                    component: ReporteDepartamentoComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Covid Por Departamento" },
                  },
                  {
                    path: "covidSintomas",
                    component: ReporteSintomasComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Covid Sintomas" },
                  },
                  {
                    path: "covidTablero",
                    component: ReporteCovidBiInternoComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Tablero Covid" },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "puertos",
        canActivate: [CanActivateViaAuthGuard],
        data: { breadcrumb: "Puertos" },
        children: [
          {
            path: "",
            component: InformacionGeneralComponent,
            data: { breadcrumb: null },
          },
          {
            path: "infGeneral",
            canActivate: [CanActivateViaAuthGuard],
            data: { breadcrumb: "Registra Información" },
            children: [
              {
                path: "",
                component: InformacionGeneralComponent,
                data: { breadcrumb: null },
              },
              {
                path: "proyectoLineaBase/:id",
                component: ProyectoLineaBaseComponent,
                data: { breadcrumb: "Nuevo Proyecto" },
              },
              {
                path: "proyectoHome/:id",
                data: { breadcrumb: "Proyecto" },
                canActivate: [CanActivateViaAuthGuard],
                children: [
                  {
                    path: "",
                    component: ProyectoHomePuertoComponent,
                    data: { breadcrumb: null },
                  },
                  {
                    path: "proyectoLineaBase/:id",
                    component: ProyectoLineaBaseComponent,
                    data: { breadcrumb: "Información General" },
                    canActivate: [CanActivateViaAuthGuard],
                  },
                  {
                    path: "avance-proyecto/:id",
                    component: AvanceProyectoComponent,
                    data: { breadcrumb: "Avance Proyecto" },
                  },
                  {
                    path: "trafico-recaudo/:id",
                    component: TraficoRecaudoComponent,
                    data: { breadcrumb: "Tráfico y Recaudo" },
                  },
                  {
                    path: "infAmbiental/:id",
                    data: { breadcrumb: "Obligaciones Ambientales" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: AmbientalComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infAmbientalDetalle/:id",
                        component: AmbientalDetalleComponent,
                        data: { breadcrumb: "Detalle Obligación Ambiental" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "infPredial/:id",
                    data: { breadcrumb: "Predial" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: PredialComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infPredialDetalle/:id",
                        component: PredialDetalleComponent,
                        data: { breadcrumb: "Detalle Predial" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "menuJuridico/:id",
                    data: { breadcrumb: "Jurídico" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: MenuJuridicoComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infAmigables/:id",
                        data: { breadcrumb: "Amigables" },
                        canActivate: [CanActivateViaAuthGuard],
                        children: [
                          {
                            path: "",
                            component: AmigablesComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "infAmigablesDetalle/:id",
                            component: AmigablesDetalleComponent,
                            data: { breadcrumb: "Detalle Amigable" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "infSeguimientoAmigables/:id",
                            component: SeguimientoAmigableComponent,
                            data: { breadcrumb: "Seguimiento Amigable" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                      {
                        path: "infTribunales/:id",
                        data: { breadcrumb: "Tribunales" },
                        canActivate: [CanActivateViaAuthGuard],
                        children: [
                          {
                            path: "",
                            component: TribunalesComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "infTribunalesDetalle/:id",
                            component: TribunalesDetalleComponent,
                            data: { breadcrumb: "Detalle Tribunal" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "infSeguimientoTribunales/:id",
                            component: SeguimientoTribunalesComponent,
                            data: { animation: "Seguimiento Tribunal" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "financieroHome/:id",
                    data: { breadcrumb: "Información Financiera" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: FinancieroHomeComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "finInversionIndexada/:idProyecto",
                        component: InversionIndexadaComponent,
                        data: { breadcrumb: "Inversion Por Proyecto" },
                      },
                      {
                        path: "informacionGeneralFinanciera/:idProyecto",
                        component: InformacionGeneralBaseComponent,
                        data: { breadcrumb: "Información General" },
                      },
                      {
                        path: "finSaldosFinancieros/:idProyecto",
                        component: SaldoFinancieroComponent,
                        data: { breadcrumb: "Saldos Financieros" },
                      },
                      {
                        path: "finContratoCreditoBase/:idProyecto",
                        component: ContratoCreditoBaseComponent,
                        data: { breadcrumb: "Contrato Crédito" },
                      },
                      {
                        path: "finContratoCreditoPuenteBase/:idProyecto",
                        component: ContratoCreditoPuenteBaseComponent,
                        data: { breadcrumb: "Contrato Crédito Puente" },
                      },
                      {
                        path: "finRetribucionFinanciera/:idProyecto",
                        component: RetribucionFinancieraComponent,
                        data: { breadcrumb: "Retribuciones" },
                      },
                      {
                        path: "finVigenciasFuturas/:idProyecto",
                        component: VigenciasFuturasComponent,
                        data: { breadcrumb: "Vigencias Futuras" },
                      },
                      {
                        path: "finVpip/:idProyecto",
                        component: VpipFinancieroComponent,
                        data: { breadcrumb: "VPIP" },
                      },
                    ],
                  },
                  {
                    path: "infTemasG/:id",
                    data: { breadcrumb: "Temas de Gestión" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: TemasGestionComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infTemasGDetalle/:id",
                        component: TemasGestionDetalleComponent,
                        data: { breadcrumb: "Detalle Tema" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "infTemasGRegistro/:id",
                        component: TemasGestionRegistroComponent,
                        data: { breadcrumb: "Actualizar Tema" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "servicioCarga/:id",
                    data: { breadcrumb: "Servicios de carga" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: InteresadosListaComponent,
                        data: { breadcrumb: null },
                      },
                    ],
                  },
                  {
                    path: "infTramitesA/:id",
                    data: { breadcrumb: "Trámites Ambientales" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: TramiteAmbientalComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infTramitesADetalle/:id",
                        component: TramiteAmbientalDetalleComponent,
                        data: { breadcrumb: "Detalle Trámite" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "infSeguimientoAmbiental/:id",
                        component: SeguimientoComponent,
                        data: { breadcrumb: "Seguimiento Trámite" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "polizas/:id",
                    data: { breadcrumb: "Pólizas" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: PolizasComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "polizaEdit/:id",
                        component: PolizaEditComponent,
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Detalle Póliza" },
                      },
                      {
                        path: "anexos/:id",
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Lista Anexos" },
                        children: [
                          {
                            path: "",
                            component: AmparosComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "anexoEdit/:id",
                            component: AmparosEditComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Detalle Anexo" },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "covid-reportar/:id",
                    data: { breadcrumb: "Reportar Covid 19" },
                    children: [
                      {
                        path: "",
                        component: CovidReportarComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "covid-reportar-reinicio-obra/:id",
                        component: CovidReportarReinicioObraComponent,
                        data: { breadcrumb: "Reinicio de Obra" },
                      },
                      {
                        path: "covid-reportar-seguimiento/:id",
                        component: CovidReportarSeguimientoComponent,
                        data: { breadcrumb: "Seguimiento de Protocolos" },
                      },
                      {
                        path: "covid-reportes-interno/:idProyecto",
                        data: { breadcrumb: "Reportes COVID" },
                        children: [
                          {
                            path: "",
                            component: CovidReportesInternoComponent,
                            data: { breadcrumb: null },
                          },

                          {
                            path: "covid-departamento-interno/:idProyecto",
                            component: ReporteDepartamentoInternoComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Covid Por Departamento" },
                          },
                          {
                            path: "covid-sintomas-interno/:idProyecto",
                            component: ReporteSintomasInternoComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Covid Sintomas" },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'tramites-ambientales-listado/:id', data: { breadcrumb: "Trámites Ambientales Listado" }, canActivate: [CanActivateViaAuthGuard], children: [
                      { path: '', component: TramitesAmbientalesListadoPuertoComponent, data: { breadcrumb: null } },
                      { path: 'tramites-ambientales-edit/:id', component: TramitesAmbientalesPuertoComponent, canActivate: [CanActivateViaAuthGuard], data: { breadcrumb: 'Agregar/Editar Tramites Ambientales' } },
                      { path: 'tramites-ambientales-seguimiento/:id', component: TramitesAmbientalesSeguimientoPuertoComponent, canActivate: [CanActivateViaAuthGuard], data: { breadcrumb: 'Seguimiento Trámites Ambientales' } },
                    ]
                  },
                ],
              },
            ],
          },

          {
            path: "reportesPuer",
            canActivate: [CanActivateViaAuthGuard],
            data: { breadcrumb: "Reportes" },
            children: [
              {
                path: "",
                component: ReportesPuertosComponent,
                data: { breadcrumb: null },
              },

              {
                path: "reportesCovid",
                data: { breadcrumb: "COVID" },
                children: [
                  {
                    path: "",
                    component: CovidReportesComponent,
                    data: { breadcrumb: null },
                  },

                  {
                    path: "covidDepartamento",
                    component: ReporteDepartamentoComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Covid Por Departamento" },
                  },
                  {
                    path: "covidSintomas",
                    component: ReporteSintomasComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Covid Sintomas" },
                  },
                  {
                    path: "covidTablero",
                    component: ReporteCovidBiInternoComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Tablero Covid" },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "ferrocarriles",
        canActivate: [CanActivateViaAuthGuard],
        data: { breadcrumb: "Ferrocarriles" },
        children: [
          {
            path: "",
            component: InformacionGeneralComponent,
            data: { breadcrumb: null },
          },
          {
            path: "ferrocarriles-interno/ficha-proyecto-drupal",
            component: FerreoFichaProyectoDrupalComponent,
          },
          {
            path: "infGeneral",
            canActivate: [CanActivateViaAuthGuard],
            data: { breadcrumb: "Registra Información" },
            children: [
              {
                path: "",
                component: InformacionGeneralComponent,
                data: { breadcrumb: null },
              },
              {
                path: "proyectoLineaBase/:id",
                component: ProyectoLineaBaseComponent,
                data: { breadcrumb: "Nuevo Proyecto" },
              },
              {
                path: "proyectoHome/:id",
                data: { breadcrumb: "Proyecto" },
                canActivate: [CanActivateViaAuthGuard],
                children: [
                  {
                    path: "",
                    component: ProyectoHomeFerreoComponent,
                    data: { breadcrumb: null },
                  },
                  {
                    path: "proyectoLineaBase/:id",
                    component: ProyectoLineaBaseComponent,
                    data: { breadcrumb: "Información General" },
                    canActivate: [CanActivateViaAuthGuard],
                  },
                  {
                    path: "avance-proyecto/:id",
                    component: AvanceProyectoComponent,
                    data: { breadcrumb: "Avance Proyecto" },
                  },
                  {
                    path: "trafico-recaudo/:id",
                    component: TraficoRecaudoComponent,
                    data: { breadcrumb: "Tráfico y Recaudo" },
                  },
                  {
                    path: "infAmbiental/:id",
                    data: { breadcrumb: "Obligaciones Ambientales" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: AmbientalComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infAmbientalDetalle/:id",
                        component: AmbientalDetalleComponent,
                        data: { breadcrumb: "Detalle Obligación Ambiental" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "infPredial/:id",
                    data: { breadcrumb: "Predial" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: PredialComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infPredialDetalle/:id",
                        component: PredialDetalleComponent,
                        data: { breadcrumb: "Detalle Predial" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "menuJuridico/:id",
                    data: { breadcrumb: "Jurídico" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: MenuJuridicoComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infAmigables/:id",
                        data: { breadcrumb: "Amigables" },
                        canActivate: [CanActivateViaAuthGuard],
                        children: [
                          {
                            path: "",
                            component: AmigablesComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "infAmigablesDetalle/:id",
                            component: AmigablesDetalleComponent,
                            data: { breadcrumb: "Detalle Amigable" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "infSeguimientoAmigables/:id",
                            component: SeguimientoAmigableComponent,
                            data: { breadcrumb: "Seguimiento Amigable" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                      {
                        path: "infTribunales/:id",
                        data: { breadcrumb: "Tribunales" },
                        canActivate: [CanActivateViaAuthGuard],
                        children: [
                          {
                            path: "",
                            component: TribunalesComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "infTribunalesDetalle/:id",
                            component: TribunalesDetalleComponent,
                            data: { breadcrumb: "Detalle Tribunal" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                          {
                            path: "infSeguimientoTribunales/:id",
                            component: SeguimientoTribunalesComponent,
                            data: { animation: "Seguimiento Tribunal" },
                            canActivate: [CanActivateViaAuthGuard],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "financieroHome/:id",
                    data: { breadcrumb: "Información Financiera" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: FinancieroHomeComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "finInversionIndexada/:idProyecto",
                        component: InversionIndexadaComponent,
                        data: { breadcrumb: "Inversion Por Proyecto" },
                      },
                      {
                        path: "informacionGeneralFinanciera/:idProyecto",
                        component: InformacionGeneralBaseComponent,
                        data: { breadcrumb: "Información General" },
                      },
                      {
                        path: "finSaldosFinancieros/:idProyecto",
                        component: SaldoFinancieroComponent,
                        data: { breadcrumb: "Saldos Financieros" },
                      },
                      {
                        path: "finContratoCreditoBase/:idProyecto",
                        component: ContratoCreditoBaseComponent,
                        data: { breadcrumb: "Contrato Crédito" },
                      },
                      {
                        path: "finContratoCreditoPuenteBase/:idProyecto",
                        component: ContratoCreditoPuenteBaseComponent,
                        data: { breadcrumb: "Contrato Crédito Puente" },
                      },
                      {
                        path: "finRetribucionFinanciera/:idProyecto",
                        component: RetribucionFinancieraComponent,
                        data: { breadcrumb: "Retribuciones" },
                      },
                      {
                        path: "finVigenciasFuturas/:idProyecto",
                        component: VigenciasFuturasComponent,
                        data: { breadcrumb: "Vigencias Futuras" },
                      },
                      {
                        path: "finVpip/:idProyecto",
                        component: VpipFinancieroComponent,
                        data: { breadcrumb: "VPIP" },
                      },
                    ],
                  },
                  {
                    path: "infTemasG/:id",
                    data: { breadcrumb: "Temas de Gestión" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: TemasGestionComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infTemasGDetalle/:id",
                        component: TemasGestionDetalleComponent,
                        data: { breadcrumb: "Detalle Tema" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "infTemasGRegistro/:id",
                        component: TemasGestionRegistroComponent,
                        data: { breadcrumb: "Actualizar Tema" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "servicioCarga/:id",
                    data: { breadcrumb: "Servicios de carga" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: InteresadosListaComponent,
                        data: { breadcrumb: null },
                      },
                    ],
                  },
                  {
                    path: "infTramitesA/:id",
                    data: { breadcrumb: "Trámites Ambientales" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: TramiteAmbientalComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "infTramitesADetalle/:id",
                        component: TramiteAmbientalDetalleComponent,
                        data: { breadcrumb: "Detalle Trámite" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "infSeguimientoAmbiental/:id",
                        component: SeguimientoComponent,
                        data: { breadcrumb: "Seguimiento Trámite" },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                    ],
                  },
                  {
                    path: "polizas/:id",
                    data: { breadcrumb: "Pólizas" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: PolizasComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "polizaEdit/:id",
                        component: PolizaEditComponent,
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Detalle Póliza" },
                      },
                      {
                        path: "anexos/:id",
                        canActivate: [CanActivateViaAuthGuard],
                        data: { breadcrumb: "Lista Anexos" },
                        children: [
                          {
                            path: "",
                            component: AmparosComponent,
                            data: { breadcrumb: null },
                          },
                          {
                            path: "anexoEdit/:id",
                            component: AmparosEditComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Detalle Anexo" },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "covid-reportar/:id",
                    data: { breadcrumb: "Reportar Covid 19" },
                    children: [
                      {
                        path: "",
                        component: CovidReportarComponent,
                        data: { breadcrumb: null },
                      },
                      {
                        path: "covid-reportar-reinicio-obra/:id",
                        component: CovidReportarReinicioObraComponent,
                        data: { breadcrumb: "Reinicio de Obra" },
                      },
                      {
                        path: "covid-reportar-seguimiento/:id",
                        component: CovidReportarSeguimientoComponent,
                        data: { breadcrumb: "Seguimiento de Protocolos" },
                      },
                      {
                        path: "covid-reportes-interno/:idProyecto",
                        data: { breadcrumb: "Reportes COVID" },
                        children: [
                          {
                            path: "",
                            component: CovidReportesInternoComponent,
                            data: { breadcrumb: null },
                          },

                          {
                            path: "covid-departamento-interno/:idProyecto",
                            component: ReporteDepartamentoInternoComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Covid Por Departamento" },
                          },
                          {
                            path: "covid-sintomas-interno/:idProyecto",
                            component: ReporteSintomasInternoComponent,
                            canActivate: [CanActivateViaAuthGuard],
                            data: { breadcrumb: "Covid Sintomas" },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "ola-invernal-ferreo-registro/:idProyecto",
                    data: { breadcrumb: "Registro Ola Invernal Ferreo" },
                    canActivate: [CanActivateViaAuthGuard],
                    children: [
                      {
                        path: "",
                        component: OlaInvernalFerreoRegistroListadoComponent,
                        data: { breadcrumb: null },
                        canActivate: [CanActivateViaAuthGuard],
                      },
                      {
                        path: "ola-invernal-ferreo-registro-registro/:idProyecto/:idOlaInveral/:soloLectura",
                        data: { breadcrumb: "Registro Ola Invernal Ferreo" },
                        children: [
                          {
                            path: "",
                            component:
                              OlaInvernalFerreoRegistroRegistroComponent,
                            data: { breadcrumb: null },
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'tramites-ambientales-listado/:id', data: { breadcrumb: "Trámites Ambientales Listado" }, canActivate: [CanActivateViaAuthGuard], children: [
                      { path: '', component: TramitesAmbientalesListadoFerreoComponent, data: { breadcrumb: null } },
                      { path: 'tramites-ambientales-edit/:id', component: TramitesAmbientalesFerreoComponent, canActivate: [CanActivateViaAuthGuard], data: { breadcrumb: 'Agregar/Editar Tramites Ambientales' } },
                      { path: 'tramites-ambientales-seguimiento/:id', component: TramitesAmbientalesSeguimientoFerreoComponent, canActivate: [CanActivateViaAuthGuard], data: { breadcrumb: 'Seguimiento Trámites Ambientales' } },
                    ]
                  },
                ],
              },
            ],
          },
          {
            path: "reportesFerro",
            canActivate: [CanActivateViaAuthGuard],
            data: { breadcrumb: "Reportes" },
            children: [
              {
                path: "",
                component: ReportesFerroComponent,
                data: { breadcrumb: null },
              },
              {
                path: "reportesCovid",
                data: { breadcrumb: "COVID" },
                children: [
                  {
                    path: "",
                    component: CovidReportesComponent,
                    data: { breadcrumb: null },
                  },

                  {
                    path: "covidDepartamento",
                    component: ReporteDepartamentoComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Covid Por Departamento" },
                  },
                  {
                    path: "covidSintomas",
                    component: ReporteSintomasComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Covid Sintomas" },
                  },
                  {
                    path: "covidTablero",
                    component: ReporteCovidBiInternoComponent,
                    canActivate: [CanActivateViaAuthGuard],
                    data: { breadcrumb: "Tablero Covid" },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "menu-peajes-externos/:id",
    data: { breadcrumb: "Peajes Externos" },
    children: [
      {
        path: "",
        component: MenuPeajesExternosComponent,
        data: { breadcrumb: null },
      },
      {
        path: "peajes-externos/:id",
        component: TraficoPeajesComponent,
        data: { breadcrumb: "Tráfico de Peajes Detallado" },
      },
      {
        path: "informacion-general-peajes/:id",
        data: { breadcrumb: "Información General Peajes" },
        children: [
          {
            path: "",
            component: InformacionGeneralPeajesComponent,
            data: { breadcrumb: null },
          },
          {
            path: "consultar-mapa-informacion-general/:idProyecto/:idPeaje",
            component: ConsultarMapaInformacionGeneralComponent,
            data: { breadcrumb: "Ubicación Peaje" },
          },
        ],
      },
    ],
  },

  {
    path: "infGeneral",
    component: InformacionGeneralComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      breadcrumb: "Germany",
    },
  },
  {
    path: "infGeneral/:id",
    component: ProyectoComponent,
    data: { animation: "anianim" },
  },

  {
    path: "proyectoHomeAero/:id",
    component: ProyectoHomeAeroComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "proyectoHomePuerto/:id",
    component: ProyectoHomePuertoComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "proyectoHomeFerreo/:id",
    component: ProyectoHomeFerreoComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "uniFuncionales/:id",
    component: UnidadFuncionalComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "uniFuncional/:idUni/:idProj",
    component: UnidadFuncionalDetalleComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "finInversion/:idProyecto/:fechaReferencia",
    component: InversionComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "finInversionDetalle/:idProyecto/:idInversion/:fechaReferencia",
    component: InversionDetalleComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "finInversionProyecto/:id",
    component: InversionProyectoComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "finInversionProyectoDetalle/:idProyecto/:id",
    component: InversionProyectoDetalleComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "infJuridico",
    component: ModuloJuridicoComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "nuevoIntervencion",
    component: NuevoIntervencionComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "menuPrincipal",
    component: MenuPrincipalComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "amigablesComponedores",
    component: AmigablesComponedoresComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "pronunciamiento",
    component: PronunciamientoComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "audienciaPreliminar",
    component: AudienciaPreliminarComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "audienciaPruebas",
    component: AudienciaPruebasComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "audienciaAlegatos",
    component: AudienciaAlegatosComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "audienciaDecision",
    component: AudienciaDecisionComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "solicitudAclaracion",
    component: SolicitudAclaracionComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "decisionAclaracion",
    component: DecisionAclaracionComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "terminacionAnticipada",
    component: TerminacionAnticipadaComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "finInformacionGeneral/:idProyecto",
    component: InformacionGeneralFinancieroComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "finInformacionGeneralDetalle/:idProyecto/:id",
    component: InformacionGeneralDetalleComponent,
    data: { animation: "anianim" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "transversales",
    component: TransversalesComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "finContratoCredito/:idProyecto",
    component: ContratoCreditoComponent,
  },
  {
    path: "finContratoCreditoDetalle/:idProyecto/:id",
    component: ContratoCreditoDetalleComponent,
  },
  {
    path: "planeacion-proyecto/listar-planeaciones",
    component: ListarPlaneacionesComponent,
  },
  {
    path: "planeacion-proyecto/crear-planeacion",
    component: CrearPlaneacionComponent,
  },
  {
    path: "componentes-comunes/seleccionar-proyecto",
    component: SeleccionarProyectoComponent,
  },
  {
    path: "componentes-comunes/seleccionar-unidadfuncional",
    component: SeleccionarUnidadfuncionalComponent,
  },
  { path: "personaBuscarAgregar", component: PersonaBuscarAgregarComponent },
  {
    path: "personas",
    data: { breadcrumb: "Administración de Personas" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      { path: "", component: PersonasComponent, data: { breadcrumb: null } },
      {
        path: "persona-base/:idPersona/:idTipoDocumento",
        component: PersonaBaseComponent,
        data: { breadcrumb: "Persona" },
        canActivate: [CanActivateViaAuthGuard],
      },
    ],
  },
  {
    path: "personas/persona-base",
    component: PersonaBaseComponent,
    data: { breadcrumb: "Mis Datos Personales" },
  },

  {
    path: "ctoAdicional/:tipo",
    component: ContratosOpcionesAdicionalesComponent,
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "misContratos",
    data: { breadcrumb: "Mis contratos" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: "",
        component: ContratosContratistaComponent,
        data: { breadcrumb: null },
      },
      {
        path: "planPagosContratista/:idContrato/:idPersona",
        data: { breadcrumb: "Ejecución del contrato - Mis pagos" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ContratoPlanPagoContratistaComponent,
            data: { breadcrumb: null },
          },
          {
            path: "informeContratista/:idContrato/:idPersona/:idPlanPago/:idInforme/:borrador/:version/:paso/:esResponsableIva/:estaObligadoFacturar/:numeroPago",
            component: InformeContratistaBaseComponent,
            data: { breadcrumb: "Informe" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "planPagos/:idContrato/:callback",
            component: ContratoPlanPagoComponent,
            data: { breadcrumb: "Plan de pago" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "balanceFinancieroContrato/:idContrato/:idPersona",
        component: ContratoBalanceFinancieroComponent,
        canActivate: [CanActivateViaAuthGuard],
      },
    ],
  },
  {
    path: "cargaExtrapesadaFun",
    data: { breadcrumb: "Listado de Carga Extrapesada" },
    children: [
      {
        path: "",
        component: FuncionarioInviasPrincipalListComponent,
        data: { breadcrumb: null },
      },
      {
        path: "crearTramite",
        data: { breadcrumb: "Crear Trámite" },
        children: [
          {
            path: "",
            component: FuncionarioInviasDetalleComponent,
            data: { breadcrumb: null },
          },
        ],
      },
      {
        path: "crearRespuesta",
        data: { breadcrumb: "Crear Respuesta" },
        children: [
          {
            path: "",
            component: FuncionarioRadicacionRespuestaComponent,
            data: { breadcrumb: null },
          },
        ],
      },
    ],
  },
  {
    path: "listadoConsultaArchivo",
    data: { breadcrumb: "Listado de Consulta de Archivo" },
    children: [
      {
        path: "",
        component: ConsultaArchivoRadicacionWebComponent,
        data: { breadcrumb: null },
      },
    ],
  },
  {
    path: "seguimiento-comunicaciones",
    data: { breadcrumb: "Seguimiento Comunicaciones" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: "",
        component: SeguimientoHomeComponent,
        data: { breadcrumb: null },
      },
      {
        path: "registro-seguimiento-comunicaciones",
        data: { breadcrumb: "Listado" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: SeguimientoComunicacionesComponent,
            data: { breadcrumb: null },
          },
          {
            path: "infSeguimientoCDetalle/:id",
            component: SeguimientoComunicacionesDetalleComponent,
            data: { breadcrumb: "Editar Seguimiento Comunicación" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "infSeguimientoCNuevo/new",
            component: SeguimientoComunicacionesDetalleComponent,
            data: { breadcrumb: "Nueva Comunicación" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "infSeguimientoCRegistro/:id",
            component: SeguimientoComunicacionesRegistroComponent,
            data: { breadcrumb: "Detalle Seguimiento Comunicación" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "reporte-seguimiento-comunicaciones",
        data: { breadcrumb: "Reporte" },
        component: SeguimientoReporteComponent,
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "consulta-seguimiento-comunicaciones",
        data: { breadcrumb: "Consulta" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: SeguimientoConsultaComponent,
            data: { breadcrumb: null },
          },
          {
            path: "ver-seguimiento-comunicaciones/:id",
            component: SeguimientoVerComunicacionComponent,
            data: { breadcrumb: "Ver Seguimiento Comunicación" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
    ],
  },
  {
    path: "home-certificaciones-laborales",
    data: { breadcrumb: "Certificaciones Laborales" },
    children: [
      {
        path: "",
        component: CertificacionHomeComponent,
        data: { breadcrumb: null },
      },
      {
        path: "generar-activos-certificaciones-laborales",
        data: { breadcrumb: "Certificados Laborales - Activos" },
        component: CertificacionActivosComponent,
      },
      {
        path: "generar-retirados-certificaciones-laborales",
        data: { breadcrumb: "Certificados Laborales - Retirados" },
        children: [
          {
            path: "",
            component: CertificacionRetiradosComponent,
            data: { breadcrumb: null },
          },
        ],
      },
      {
        path: "generar-certificaciones-laborales",
        data: { breadcrumb: "Generar Certificado" },
        children: [
          {
            path: "",
            component: CertificacionGeneralComponent,
            data: { breadcrumb: null },
          },
        ],
      },
      {
        path: "configuracion-certificaciones-laborales",
        data: { breadcrumb: "Configuración Certificado Laboral" },
        children: [
          {
            path: "",
            component: CertificacionConfiguracionComponent,
            data: { breadcrumb: null },
          },
        ],
      },
    ],
  },
  {
    path: "contratosHome",
    data: { breadcrumb: "Contratación" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: "",
        component: ContratosHomeComponent,
        data: { breadcrumb: null },
      },
      {
        path: "contratos",
        data: { breadcrumb: "Nuevos Contratos" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ContratosComponent,
            data: { breadcrumb: null },
          },
          {
            path: "contrato-base/:idContrato/:esBorrador",
            component: ContratoBaseComponent,
            data: { breadcrumb: "Contrato" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "contratosPublicados",
        data: { breadcrumb: "Modificar Datos Contratos" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ContratosPublicadosComponent,
            data: { breadcrumb: null },
          },
        ],
      },
      {
        path: "ctoAdicional/:tipo",
        data: { breadcrumb: "Contrato" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ContratosOpcionesAdicionalesComponent,
            data: { breadcrumb: null },
          },
          {
            path: "planPagos/:idContrato/:callback",
            component: ContratoPlanPagoComponent,
            data: { breadcrumb: "Plan de pago" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "obligaciones/:id",
            component: ObligacionesContratoComponent,
            data: { breadcrumb: "Obligaciones" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "novedadesContractuales",
        data: { breadcrumb: "Novedades Contractuales" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ContratosNovedadesComponent,
            data: { breadcrumb: null },
          },
          {
            path: "novedadesContrato/:idContrato",
            data: { breadcrumb: "Novedades Contrato" },
            canActivate: [CanActivateViaAuthGuard],
            children: [
              {
                path: "",
                component: NovedadesContratoComponent,
                data: { breadcrumb: null },
              },
              {
                path: "novedadesContractualesRegistro/:idTipoNovedad/:idNovedad",
                component: NovedadContratoBaseComponent,
                data: { breadcrumb: "Novedad" },
                canActivate: [CanActivateViaAuthGuard],
              },
            ],
          },
        ],
      },
      {
        path: "contratosSupervisor",
        component: ContratoSupervisorComponent,
        data: { breadcrumb: "Modificar Supervisor" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "contratacionReportesHome",
        data: { breadcrumb: "Reportes" },
        children: [
          {
            path: "",
            component: ContratacionReportesHomeComponent,
            data: { breadcrumb: null },
          },
          {
            path: "reportesContratos",
            data: { breadcrumb: "Reporte detallado de contratos" },
            children: [
              {
                path: "",
                component: ReporteContratosComponent,
                data: { breadcrumb: null },
              },
              {
                path: "reporteContratoDetalle/:idContrato",
                component: ReporteContratoBaseComponent,
                data: { breadcrumb: "Contrato" },
              },
            ],
          },
          {
            path: "reportesInformesContratista",
            component: ReporteInformesContratistaComponent,
            data: { breadcrumb: "Reporte Informes Contratista" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "dependendciaContratacion",
        component: ContratoDependenciaComponent,
        data: { breadcrumb: "Dependencia Supervisión" },
        canActivate: [CanActivateViaAuthGuard],
      },
    ],
  },

  {
    path: "contratacionSupervisionHome",
    data: { breadcrumb: "Cuentas de Cobro" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: "",
        component: ContratacionSupervisionHomeComponent,
        data: { breadcrumb: null },
      },
      {
        path: "solicitudPagoAprobadasSupervisor",
        component: SolicitudPagoAprobadasSupervisorComponent,
        data: { breadcrumb: "Solicitudes de Pago Aprobadas Supervisor" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "informesContratistaSupervisor",
        data: { breadcrumb: "Informes de Supervisión Supervisor" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: InformesSupervisorComponent,
            data: { breadcrumb: null },
          },
          {
            path: "informeContratistaSupervisorRegistro/:idContrato/:idAsignacion/:idInforme/:idVersionInforme/:idPasoInforme/:idPaso/:idPersonaAsignacion",
            component: InformeSupervisorBaseComponent,
            data: { breadcrumb: "Informe" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "vistoBuenoContrato",
        component: VistoBuenoContratoComponent,
        data: { breadcrumb: "Asignación VoBo" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "informesContratistaCxP",
        data: { breadcrumb: "Cuentas por Pagar - Central de Pagos" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: InformesCxpComponent,
            data: { breadcrumb: null },
          },
          {
            path: "informeContratistaCxPRegistro/:idContrato/:idAsignacion/:idInforme/:idVersionInforme/:idPasoInforme/:idPaso/:idPersonaAsignacion",
            component: InformeCxpBaseComponent,
            data: { breadcrumb: "Informe" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "informesContratistaSpa",
        data: { breadcrumb: "Solicitudes de Pago - Tesorería" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: InformesSpaComponent,
            data: { breadcrumb: null },
          },
          {
            path: "informeContratistaSpaRegistro/:idContrato/:idAsignacion/:idInforme/:idVersionInforme/:idPasoInforme/:idPaso/:idPersonaAsignacion",
            component: InformeSpaBaseComponent,
            data: { breadcrumb: "Informe" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "informesContratistaVoBo",
        data: { breadcrumb: "Revisión de Informes VoBo" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: InformesVoboComponent,
            data: { breadcrumb: null },
          },
          {
            path: "informeContratistaVoBoRegistro/:idContrato/:idAsignacion/:idInforme/:idVersionInforme/:idPasoInforme/:idPaso/:idPersonaAsignacion",
            component: InformeVoboBaseComponent,
            data: { breadcrumb: "Informe" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "ctoAdicional/:tipo",
        data: { breadcrumb: "Contrato" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ContratosOpcionesAdicionalesComponent,
            data: { breadcrumb: null },
          },
          {
            path: "planPagos/:idContrato/:callback",
            component: ContratoPlanPagoComponent,
            data: { breadcrumb: "Plan de pago" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "reportesInformesContratista",
        component: ReporteInformesContratistaComponent,
        data: { breadcrumb: "Reporte Informes Contratista" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "reportesInformesContratistaEjecutivo",
        component: ReporteInformesContratistaEjecutivoComponent,
        data: { breadcrumb: "Reporte Ejecutivo de Informes a contratistas" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "reportesContratos",
        data: { breadcrumb: "Reporte detallado de contratos" },
        children: [
          {
            path: "",
            component: ReporteContratosComponent,
            data: { breadcrumb: null },
          },
          {
            path: "reporteContratoDetalle/:idContrato",
            component: ReporteContratoBaseComponent,
            data: { breadcrumb: "Contrato" },
          },
        ],
      },
      {
        path: "reportesCuentasCobroCxpHome",
        data: { breadcrumb: "Reporte de cuentas de cobro - Central de pagos" },
        children: [
          {
            path: "",
            component: ContratacionReportesCuentasCobroHomeComponent,
            data: { breadcrumb: null },
          },
          {
            path: "reportesCuentasCxp",
            data: { breadcrumb: "Reporte detallado de Cuentas Cobro" },
            canActivate: [CanActivateViaAuthGuard],
            children: [
              {
                path: "",
                component: ReporteCuentasCxpComponent,
                data: { breadcrumb: null },
              },
              {
                path: "reporteCuentaCxpDetalle/:idContrato/:idInforme",
                component: RpteCxpBaseComponent,
                data: { breadcrumb: "Cuenta" },
                canActivate: [CanActivateViaAuthGuard],
              },
            ],
          },
          {
            path: "reportesCuentasCxpConsolidadoTotal",
            component: RpteCxpTotalComponent,
            data: { breadcrumb: "Consolidado Total" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "reportesCuentasCxpConsolidadoTotalDependencias",
            component: RpteCxpTotalDpnComponent,
            data: { breadcrumb: "Consolidado Total por Dependencias" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "reportesCuentasCxpComparativo",
            component: RpteCxpCmpvoComponent,
            data: { breadcrumb: "Comparativo" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "ctoReporteCxpDevolucionesTesoreria",
            component: CtoReporteCxpDevolucionesTesoreriaComponent,
            data: { breadcrumb: "Reporte Devoluciones de Tesoreía" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "ctoReporteCxpPlanesPagoCuentasCobro",
            component: CtoReporteCxpPlanesPagoCuentasCobroComponent,
            data: { breadcrumb: "Reporte Planes de Pago" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "ctoReporteCxpContratos",
            component: CtoReporteCxpContratosComponent,
            data: { breadcrumb: "Reporte Contratos - Central de Pagos" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
    ],
  },

  {
    path: "contratacionReportesHome",
    data: { breadcrumb: "Reportes" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: "",
        component: ContratacionReportesHomeComponent,
        data: { breadcrumb: null },
      },
      {
        path: "reportesInformesContratista",
        component: ReporteInformesContratistaComponent,
        data: { breadcrumb: "Reporte Informes Contratista" },
        canActivate: [CanActivateViaAuthGuard],
      },
      // { path: 'reportesInformesContratista', component: ReporteCuentasCxpComponent, data: { breadcrumb: 'Reporte Informes Contratista' }, canActivate: [CanActivateViaAuthGuard] },
    ],
  },

  {
    path: "trafico-uno-a-uno",
    component: ReportesPeajesExternosComponent,
    data: { breadcrumb: "Tráfico Uno a Uno" },
    canActivate: [CanActivateViaAuthGuard],
  },
  {
    path: "reporte-trafico-recaudo",
    component: ReportesPeajesRecaudoComponent,
    data: { breadcrumb: "Tráfico y Recaudo" },
    canActivate: [CanActivateViaAuthGuard],
  },

  {
    path: "documentos-peajes",
    data: { breadcrumb: "Documentos Peajes" },
    canActivate: [CanActivateViaAuthGuard],
    component: DocumentosPeajesComponent,
  },
  { path: "visor-planes-agencia", component: VisorPlanesAgenciaComponent },
  { path: "sigMapas", component: SigComponent },
  { path: "visor-planes-agencia", component: VisorPlanesAgenciaComponent },
  // rutas public
  { path: "uat", component: PublicUatComponent },
  { path: "uat/repositorio", component: RepositorioUatComponent },
  {
    path: "uat/somos",
    component: SobreUatComponent,
    data: { breadcrumb: null },
  },
  { path: "uat/investigacion", component: InvestigacionUatComponent },
  { path: "uat/aliados", component: AliadosUatComponent },
  { path: "uat/mesa", component: MesaUatComponent },
  { path: "uat/modelo", component: ModeloUatComponent },

  { path: "carreteras-public", component: PublicCarreterasComponent },
  {
    path: "carreteras-public/consulta",
    component: PublicCarreterasConsultaComponent,
  },
  { path: "carreteras-public/mapa", component: PublicCarreterasMapaComponent },
  {
    path: "carreteras-public/consulta/resumen-ejecutivo",
    component: PublicCarreterasConsultaResumenComponent,
  },
  {
    path: "carreteras-public/consulta/ficha-5g",
    component: PublicCarreterasConsultaFicha5gComponent,
  },
  {
    path: "carreteras-public/consulta/ficha-4g",
    component: PublicCarreterasConsultaFicha4gComponent,
  },
  //REFACTORING
  {
    path: "carreteros-interno/visor-resumen-ejecutivo",
    component: PublicCarreterasVisorResumenEjecutivoComponent,
  },
  {
    path: "carreteros-interno/visor-ficha-proyecto4g",
    component: CarreterasVisorFichaProyecto4gComponent,
  },
  {
    path: "carreteros-interno/visor-ficha-proyecto",
    component: PublicCarreterasVisorFichaProyectoComponent,
  },
  {
    path: "carreteros-interno/visor-inversion-por-departamento",
    component: PublicCarreterasVisorInversionPorDepartamentoComponent,
  },
  {
    path: "carreteros-interno/visor-avance-predial",
    component: PublicCarreterasVisorAvancePredialComponent,
  },
  {
    path: "carreteros-interno/visor-tramites-ambientales",
    component: PublicCarreterasVisorTramitesAmbientalesComponent,
  },
  {
    path: "carreteros-interno/visor-compensaciones-ambientales",
    component: VisorCompensacionesAmbientalesComponent,
  },
  {
    path: "carreteros-interno/visor-peajes",
    component: PublicCarreterasVisorPeajesComponent,
  },
  {
    path: "carreteros-interno/visor-mapa-conflictividad-proyectos",
    component: VisorMapaConflictividadProyectosComponent,
  },
  {
    path: "carreteros-interno/visor-mapa-social-riesgos-peajes",
    component: VisorMapaSocialRiesgosPeajesComponent,
  },
  {
    path: "carreteros-interno/visor-dispositivos-its",
    component: VisorDispositivosItsComponent,
  },
  {
    path: "carreteros-interno/visor-eer",
    component: PublicCarreterasVisorEerComponent,
  },
  {
    path: "carreteros-interno/visor-areas-de-influencia",
    component: PublicCarreterasVisorAreasDeInfluenciaComponent,
  },
  {
    path: "carreteros-interno/ficha-5g",
    component: PublicCarreterasConsultaFicha5gComponent,
  },
  {
    path: "seguimiento/public/ficha-proyectos/tramites-ambientales",
    component: TramitesVisorSeguimientoComponent,
  },
  {
    path: "carretero-home/public-carretero/visor/mapa",
    component: CarreteroVisorMapaComponent,
  },
  { path: "puertos-home/visor/mapa", component: PuertoHomeVisorMapaComponent },
  { path: "public-ferreo/visor-mapa", component: FerreoVisorMapaComponent },
  { path: "visor/reporte-predial", component: VisorReportePredialComponent },
  {
    path: "carreteros-interno/visor-polizas",
    component: VisorPolizasComponent,
  },
  {
    path: "GeneracionPdfCertificadosLaborales/validarCertificado",
    component: CertificacionesLaboralesValidacionComponent,
  },
  {
    path: "GeneracionPdfCertificadosLaboralesContratista/validarCertificado",
    component: CertificacionContratistaValidacionComponent,
  },

  { path: "youtube/playlist", component: YoutubePlaylistComponent },
  { path: "youtube/search-list", component: YoutubeSearchListComponent },
  { path: "youtube/upload-file", component: YoutubeUploadFileComponent },
  {
    path: "youtube/public-home-principal",
    component: YoutubeHomePublicComponent,
  },
  { path: "youtube/private-home-principal", component: YoutubeHomeComponent },
  {
    path: "youtube/video-avance-proyecto",
    component: YoutubeProyectoComponent,
  },
  { path: "youtube/video-proyecto", component: YoutubeProyectoVideoComponent },

  { path: "radicacion-web/upload-file", component: UploadFileComponent },

  { path: "pqrs/pqrs-home-servicios", component: PqrsHomeServiciosComponent },
  {
    path: "pqrs/pqrs-encuesta-satisfaccion",
    component: PqrsReporteEncuestaSatisfaccionComponent,
  },

  {
    path: "pqrs-consulta-public/pqrs-encuesta-satisfaccion",
    component: PqrsEncuestaSatisfaccionComponent,
  },
  { path: "pqrs-consulta-public", component: PqrsConsultaComponent },
  { path: "pqrs-radicacion-public", component: PqrsRadicacionComponent },

  { path: "orfeo-consulta-public", component: OrfeoConsultaComponent },

  {
    path: "carreteras-public/consulta/ficha-4g",
    component: PublicCarreterasConsultaFicha4gComponent,
  },
  {
    path: "carreteras-public/consulta/ficha-3g2g",
    component: PublicCarreterasConsultaFicha3g2gComponent,
  },
  {
    path: "carreteras-public/consulta/peajes",
    component: PublicCarreterasConsultaPeajesComponent,
  },
  { path: "aeropuertos-public", component: PublicAeropuertosComponent },
  {
    path: "aeropuertos-public/mapa",
    component: PublicAeropuertosMapaComponent,
  },
  {
    path: "aeropuertos-public/ficha-proyecto-drupal",
    component: PublicAeroFichaProyectoDrupalComponent,
  },
  { path: "puertos-public", component: PublicPuertosComponent },
  { path: "puertos-public/mapa", component: PublicPuertosMapaComponent },
  {
    path: "puertos-public/ficha-proyecto",
    component: PublicPuertosFichaProyectoComponent,
  },
  {
    path: "puertos-public/ficha-proyecto-home",
    component: PublicPuertosFichaProyectoHomeComponent,
  },
  {
    path: "puertos-public/ficha-proyecto-drupal",
    component: PublicPuertosFichaProyectoDrupalComponent,
  },
  {
    path: "puertos-privado/seguimiento-gestion",
    component: PuertoSeguimientoGestionComponent,
  },
  {
    path: "puertos-privado/home-ficha-proyecto",
    component: PuertoHomeFichaProyectoComponent,
  },
  {
    path: "aeropuertos-interno/ficha-proyecto-drupal",
    component: AeropuertosHomeFichaProyectoDrupalComponent,
  },
  {
    path: "ferrocarriles-interno/ficha-proyecto-drupal",
    component: FerreoFichaProyectoDrupalComponent,
  },
  {
    path: "puertos-public/home-registrar",
    component: PuertoHomeRegistrarComponent,
  },

  { path: "ferrocarriles-public", component: PublicFerrocarrilesComponent },
  {
    path: "ferrocarriles-public/consulta",
    component: PublicFerrocarrilesConsultaComponent,
  },
  {
    path: "ferrocarriles-public/consulta/estadistica",
    component: PublicFerrocarrilesConsultaEstadisticaComponent,
  },
  {
    path: "ferrocarriles-public/ficha-proyecto-drupal",
    component: PublicFerroFichaProyectoDrupalComponent,
  },

  {
    path: "ferrocarriles-public/mapa",
    component: PublicFerrocarrilesMapaComponent,
  },
  {
    path: "ferrocarriles-public/consulta",
    component: PublicFerrocarrilesConsultaComponent,
  },
  {
    path: "ferrocarriles-public/consulta/ficha5g",
    component: PublicFerrocarrilesConsultaFicha5gComponent,
  },
  {
    path: "fluvial-public/consulta",
    component: PublicFluvialConsultaComponent,
  },
  {
    path: "fluvial-public/consulta/ficha5g",
    component: PublicFluvialConsultaFicha5gComponent,
  },
  { path: "fluvial-public", component: PublicFluvialComponent },

  {
    path: "aeropuertos-public/consulta",
    component: PublicAeropuertosConsultaComponent,
  },
  {
    path: "aeropuertos-public/consulta/ficha5g",
    component: PublicAeropuertosConsultaFicha5gComponent,
  },
  //REFACTORING
  {
    path: "aeropuerto-home/visor/mapa",
    component: AeropuertosHomeVisorMapaComponent,
  },

  { path: "proyectos5g-public", component: PublicProyectos5gComponent },
  {
    path: "proyectos5g-ficha-ola2-public",
    component: PublicProyectos5gFichaOla2Component,
  },
  { path: "mapas", component: PublicMapComponent },
  {
    path: "agendamiento-citas-no-resuelve",
    component: AgendamientoCitaComponent,
  },
  {
    path: "agendamiento-consultas-no-resuelve",
    component: AgendamientoConsultasComponent,
  },
  {
    path: "ferrocarriles-public/consulta/servicio-carga-externos",
    component: InteresadosExternosComponent,
  },
  { path: "datos-abiertos", component: DatosAbiertosComponent },
  { path: "datos-movimiento", component: DatosMovimientoComponent },
  {
    path: "certificaciones-contratista(DESACTIVADO)",
    component: CertificacionContratistaComponent,
  },

  {
    path: "inventario-equipos",
    data: { breadcrumb: "Bienes Informáticos" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: "",
        component: InventarioEquiposComponent,
        data: { breadcrumb: null },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "registrar-modelo",
        component: RegistrarModeloComponent,
        data: { breadcrumb: "Registrar Modelo" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "registrar-bien",
        component: RegistrarBienComponent,
        data: { breadcrumb: "Registrar Bien" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "mis-bienes",
        component: MisBienesComponent,
        data: { breadcrumb: "Mis Bienes" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "reportes-inventario",
        component: ReportesInventarioComponent,
        data: { breadcrumb: "Reportes Inventario" },
        canActivate: [CanActivateViaAuthGuard],
      },
    ],
  },
  {
    path: "comisiones",
    data: { breadcrumb: "Comisiones" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: "",
        component: ComisionesHomeComponent,
        data: { breadcrumb: null },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-configuracion",
        component: ComisionesConfiguracionComponent,
        data: { breadcrumb: "Comisiones Configuración" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-vistobueno-configuracion",
        component: ComisionesConfiguracionDependenciasResponsableComponent,
        data: { breadcrumb: "Flujo de aprobación Comisiones" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-funcionario-configuracion",
        component: ComisionesConfiguracionFuncionarioComponent,
        data: { breadcrumb: "Comisiones Funcionario Configuración" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-solicitudes",
        component: ComisionesSolicitudesComponent,
        data: { breadcrumb: "Comisiones Solicitudes" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-crear-editar-solicitud/:idSolicitud/:idComisionVersion/:idAsignacion/:soloLectura/:revisando/:idPersona/:legalizando",
        component: CrearEditarSolicitudComisionComponent,
        data: { breadcrumb: "Registro de solicitudes de Comisión" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-revisar-solicitudes",
        component: ComisionesRevisarSolicitudesComponent,
        data: { breadcrumb: "Revisión Solicitudes de Comisión" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "visualizar-comisiones-cancelacion/:idComision",
        component: VisualizarComisionesCancelacionComponent,
        data: { breadcrumb: "Comisión Cancelada" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-tiquetes-revision",
        component: ComisionesTiquetesRevisionComponent,
        data: { breadcrumb: "Validar tiquetes" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-legalizaciones-registro",
        component: ComisionesLegalizacionesRegistroComponent,
        data: { breadcrumb: "Registro de solicitudes de Comisión" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-legalizaciones-revision",
        component: ComisionesLegalizacionesRevisionComponent,
        data: { breadcrumb: "Revisión de Legalizaciones de Comisiones" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-tiquetes-registro",
        component: ComisionesTiquetesRegistroComponent,
        data: { breadcrumb: "Carga de tiquetes" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-tiquetes-registro",
        data: { breadcrumb: "Tiquetes" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ComisionesTiquetesRegistroComponent,
            data: { breadcrumb: null },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "crear-editar-tiquete-comision/:idComision",
            component: CrearEditarTiqueteComisionComponent,
            data: { breadcrumb: "Crear / Editar Tiquete" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "comisiones-gestion-presupuestal-comisiones",
        data: { breadcrumb: "Comisiones Aprobadas" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ComisionesGestionPresupuestalComisionesComponent,
            data: { breadcrumb: null },
          },
          {
            path: "comisiones-gestion-presupuestal-registro/:idComision/:idComisionVersion/:idAsignacion",
            component: ComisionesGestionPresupuestalRegistroComponent,
            data: { breadcrumb: "RP Asociados" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "comisiones-registro-presupuestal-obligacion-comisiones",
        component: ComisionesRegistroPresupuestalObligacionComisionesComponent,
        data: { breadcrumb: "Registro Presupuestal de Obligación" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "comisiones-registro-presupuestal-obligacion-comisiones",
        data: {
          breadcrumb:
            "Registro presupuestal de obligación de pago - Central de Pagos",
        },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component:
              ComisionesRegistroPresupuestalObligacionComisionesComponent,
            data: { breadcrumb: null },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "comisiones-registro-presupuestal-obligacion-registro/:idComision/:idComisionVersion/:idAsignacion",
            component:
              ComisionesRegistroPresupuestalObligacionRegistroComponent,
            data: { breadcrumb: "Registro Presupuestal de Obligación" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "comisiones-orden-pago-presupuestal-comisiones",
        data: { breadcrumb: "Orden de pago - Tesorería" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ComisionesOrdenPagoPresupuestalComisionesComponent,
            data: { breadcrumb: null },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "comisiones-orden-pago-presupuestal-registro/:idComision/:idComisionVersion/:idAsignacion",
            component: ComisionesOrdenPagoPresupuestalRegistroComponent,
            data: { breadcrumb: "Orden de Pago Presupuestal" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "comisiones-reportes-home",
        data: { breadcrumb: "Reportes" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: ComisionesReportesHomeComponent,
            data: { breadcrumb: null },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "comisiones-reporte-ejecutivo",
            component: ComisionesReporteEjecutivoComponent,
            data: { breadcrumb: "Reporte Ejecutivo de Comisiones" },
            canActivate: [CanActivateViaAuthGuard],
          },
          {
            path: "comisiones-reporte-detallado-versiones",
            data: { breadcrumb: "Reporte detallado de comisiones" },
            canActivate: [CanActivateViaAuthGuard],
            children: [
              {
                path: "",
                component: ComisionesReporteDetalleVersionesComponent,
                data: { breadcrumb: null },
                canActivate: [CanActivateViaAuthGuard],
              },
              {
                path: "comisiones-reporte-detallado-registro-versiones/:idComision/:idComisionVersion",
                component: ComisionesReporteDetalleRegistroVersionesComponent,
                data: { breadcrumb: "Reporte detallado de comisiones" },
                canActivate: [CanActivateViaAuthGuard],
              },
            ],
          },
        ],
      },
    ],
  },
  { path: "ani-apps", component: AniAppsComponent },
  {
    path: "home-puestos-trabajo",
    data: { breadcrumb: "Gestión de accesos" },
    canActivate: [CanActivateViaAuthGuard],
    children: [
      {
        path: "",
        component: PuestosTrabajoHomeComponent,
        data: { breadcrumb: null },
      },
      {
        path: "puestos-trabajo",
        data: { breadcrumb: "Listar Reservas" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: PuestosTrabajoComponent,
            data: { breadcrumb: null },
          },
          {
            path: "crear-editar-reserva/:idReserva",
            component: CrearEditarReservaComponent,
            data: { breadcrumb: "Reserva" },
            canActivate: [CanActivateViaAuthGuard],
          },
        ],
      },
      {
        path: "registrar-reserva",
        component: RegistrarReservaComponent,
        data: { breadcrumb: "Registro" },
        canActivate: [CanActivateViaAuthGuard],
      },
      {
        path: "administracion-home",
        data: { breadcrumb: "Administración gestión de accesos" },
        canActivate: [CanActivateViaAuthGuard],
        children: [
          {
            path: "",
            component: AdministracionHomeComponent,
            data: { breadcrumb: null },
          },
          {
            path: "listar-cupos",
            data: { breadcrumb: "Cupos" },
            canActivate: [CanActivateViaAuthGuard],
            children: [
              {
                path: "",
                component: ListarCuposComponent,
                data: { breadcrumb: null },
              },
              {
                path: "crear-editar-cupos/:idCupo",
                component: CrearEditarCuposComponent,
                data: { breadcrumb: "Cupo" },
                canActivate: [CanActivateViaAuthGuard],
              },
            ],
          },
          {
            path: "listar-franjas",
            data: { breadcrumb: "Franjas" },
            canActivate: [CanActivateViaAuthGuard],
            children: [
              {
                path: "",
                component: ListarFranjasComponent,
                data: { breadcrumb: null },
              },
              {
                path: "crear-editar-franjas/:idFranja",
                component: CrearEditarFranjasComponent,
                data: { breadcrumb: "Franja" },
                canActivate: [CanActivateViaAuthGuard],
              },
            ],
          },
        ],
      },
    ],
  },
  { path: "trafico-ani", component: TraficoAniComponent },
  { path: "trafico-ani/:date", component: TraficoAniComponent },
  //sigpublico
  { path: "mapas/modos", component: PublicMapModosComponent },
  { path: "mapas/peajes", component: PublicMapPeajesComponent },
  {
    path: "mapas/areas-influencia",
    component: PublicMapAreasInfluenciaComponent,
  },
  { path: "mapas/atlas", component: PublicMapAreasAtlasComponent },
  {
    path: "mapas/servicios-ayuda",
    component: PublicMapServiciosAyudaComponent,
  },
  //sigprivado
  { path: "sigMapas/faunani", component: SigFaunaniComponent },
  { path: "sigMapas/canal-del-dique", component: SigCanalDelDiqueComponent },
  {
    path: "sigMapas/compensaciones-ambientales",
    component: SigCompensacionesAmbientalesComponent,
  },
  {
    path: "sigMapas/criticidad-peajes",
    component: SigCriticidadPeajesComponent,
  },
  { path: "sigMapas/dispositivos-ITS", component: SigDispositivosITSComponent },
  { path: "sigMapas/predial", component: SigPredialComponent },
  { path: "sigMapas/puertos", component: SigPuertosComponent },
  { path: "sigMapas/trafico-waze", component: SigTraficoWazeComponent },
  //presentaciones
  { path: "novedades-ani", component: PublicNovedadesComponent },
  { path: "novedades-ani/portafolio", component: PortafolioComponent },
  {
    path: "novedades-ani/proyectos-aeroportuarios",
    component: ProyectosAeroportuarioComponent,
  },
  {
    path: "novedades-ani/ruta-ferrea-caribe",
    component: RutaFerrecaribeComponent,
  },
  {
    path: "novedades-ani/proyectos-educacion",
    component: ProyectosEducacionComponent,
  },
  { path: "novedades-ani/proyectos-salud", component: ProyectosSaludComponent },
  {
    path: "novedades-ani/proyectos-social-productiva",
    component: ProyectosSocialComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
