import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { forkJoin, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { PredioDTO } from "../predios.dto";
import { PrediosService } from "../predios.service";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-registro-detallado-predios",
  templateUrl: "./registro-detallado-predios.component.html",
  styleUrls: ["./registro-detallado-predios.component.sass"],
})
export class RegistroDetalladoPrediosComponent implements OnInit {
  blockedDocument: boolean;
  subscription: Subscription;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  predioList: PredioDTO[];
  predio: PredioDTO;

  dialogoCompensaciones: boolean;
  dialogoEliminar: boolean;

  fechaActual: Date;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private prediosService: PrediosService,
    private messageService: MessageService
  ) {}

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.fechaActual = new Date();
    // Obtener el proyecto seleccionado
    const idParam = "id";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
    });
    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
      },
      (e) => {}
    );
    this.listarPredios();
  }

  listarPredios() {
    forkJoin(
      this.prediosService.srvListarPrediosPorProyecto(this.idProyecto)
    ).subscribe(
      ([prediosData]) => {
        // console.log(prediosData);
        this.predioList = prediosData.predios;
      },
      (e) => {}
    );
  }

  btnAgregar() {
    this.router.navigate([
      this.router.url + "/crear-editar-predio/" + this.idProyecto + "/0/true",
    ]);
  }

  btnEditar(predioSelected: PredioDTO) {
    this.router.navigate([
      this.router.url +
        "/crear-editar-predio/" +
        this.idProyecto +
        "/" +
        predioSelected.idPredioHistorico +
        "/" +
        predioSelected.borrador,
    ]);
  }

  btnAdjuntos(predioSelected: PredioDTO) {
    this.router.navigate([
      this.router.url +
        "/adjuntos-predio/" +
        this.idProyecto +
        "/" +
        predioSelected.idPredioHistorico +
        "/" +
        predioSelected.idPredio +
        "/" +
        predioSelected.borrador,
    ]);
  }

  btnEliminar(predioSelected: PredioDTO) {
    this.predio = predioSelected;
    this.dialogoEliminar = true;
  }

  submitEliminar() {
    forkJoin(
      this.prediosService.srvEliminarPredio(
        this.predio.idPredioHistorico,
        this.predio.borrador
      )
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // console.log('----------srvEliminarPredio');
            // console.log(result.respuestaServicio.mensajeSalida);
            //  Consultar nuevamente la lista
            this.listarPredios();
            //  Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            // // console.log(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // // console.log('Ocurrio un error al consumir el servicio srvEliminarPredio');
        }
      },
      (error) => {
        // // console.log(error);
      },
      () => {
        // Desbloquear pantalla
        this.dialogoEliminar = false;
        this.blockedDocument = false;
      }
    );
  }

  bntVolver() {
    const ruta =
      "carreteros/infGeneral/proyectoHome/" +
      this.idProyecto +
      "/predios/" +
      this.idProyecto;
    this.router.navigate([ruta]);
  }

  btnGestionarLocalizacion(predioSelected: PredioDTO) {
    console.log("Gestionar Localizacion", predioSelected);
    this.router.navigate([
      this.router.url +
        "/gestionar-localizacion-predio/" +
        this.idProyecto +
        "/" +
        predioSelected.idPredioHistorico +
        "/" +
        predioSelected.borrador,
    ]);
  }

  btnConsultarLocalizacion(predioSelected: PredioDTO) {
    console.log("Consultar Geolocalizacion", predioSelected);
    this.router.navigate([
      this.router.url +
        "/consultar-localizacion-predio/" +
        this.idProyecto +
        "/" +
        predioSelected.idPredioHistorico +
        "/" +
        predioSelected.borrador,
    ]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
