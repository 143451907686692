import { Injectable } from "@angular/core";
import { from, Observable, throwError } from "rxjs";
import { retry } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigService } from "src/app/config.service";
import { environment } from "src/environments/environment";
import { TrnRespuestaServicio } from "src/app/parametricas/trn-respuesta-servicio";
import { Subtramo, UnidadFuncional } from "./unidad-funcional-carretero.dto";

@Injectable({
  providedIn: "root",
})
export class UnidadFuncionalCarreteroService {
  public END_POINT = "";

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public getUnidadesFuncionalesPorProyecto(
    idProyecto,
    idConfiguracion,
    esBorrador
  ): Observable<ListaUnidadFuncionalRpt> {
    return this.http
      .get<ListaUnidadFuncionalRpt>(
        this.END_POINT +
          "/CrrUnidadFuncional/list/" +
          idProyecto +
          "/" +
          idConfiguracion +
          "/" +
          esBorrador
      )
      .pipe(retry(1));
  }

  public getUnidadesFuncionalesPorSoloProyecto(
    idProyecto
  ): Observable<ListaUnidadFuncionalRpt> {
    return this.http
      .get<ListaUnidadFuncionalRpt>(
        this.END_POINT + "/CrrUnidadFuncional/list/" + idProyecto
      )
      .pipe(retry(1));
  }

  public getUnidadFuncionalPorId(
    id,
    esBorrador
  ): Observable<ConsultaUnidadFuncionalRpt> {
    return this.http
      .get<ConsultaUnidadFuncionalRpt>(
        this.END_POINT + "/CrrUnidadFuncional/" + id + "/" + esBorrador
      )
      .pipe(retry(1));
  }

  public GuardarUnidadFuncional(
    unidadFuncional: UnidadFuncional,
    esBorrador
  ): Observable<ConsultaUnidadFuncionalRpt> {
    return this.http
      .post<ConsultaUnidadFuncionalRpt>(
        this.END_POINT +
          "/CrrUnidadFuncional/guardarUnidadFuncional/" +
          esBorrador,
        unidadFuncional
      )
      .pipe(retry(1));
  }

  public getListarSubtramosPorUnidadFuncional(
    idUnidadFuncional,
    esBorrador
  ): Observable<ListaSubtramoRpt> {
    return this.http
      .get<ListaSubtramoRpt>(
        this.END_POINT +
          "/CrrSubtramo/listarSubtramosPorUnidadFuncional/" +
          idUnidadFuncional +
          "/" +
          esBorrador
      )
      .pipe(retry(1));
  }

  public getObtenerSubtramoPorId(
    id,
    esBorrador
  ): Observable<ConsultaSubtramoRpt> {
    return this.http
      .get<ConsultaSubtramoRpt>(
        this.END_POINT +
          "/CrrSubtramo/obtenerSubtramoPorId/" +
          id +
          "/" +
          esBorrador
      )
      .pipe(retry(1));
  }

  public guardarSubtramo(
    subtramo: Subtramo,
    esBorrador
  ): Observable<ConsultaSubtramoRpt> {
    return this.http
      .post<ConsultaSubtramoRpt>(
        this.END_POINT + "/CrrSubtramo/guardarSubtramo/" + esBorrador,
        subtramo
      )
      .pipe(retry(1));
  }

  public getSubtramosPorId(idSubtramo) {
    return this.http
      .get(this.END_POINT + "/CrrSubtramo/" + idSubtramo)
      .pipe(retry(1));
  }

  public guardarNuevoSubtramoPorUnidadFuncional(data) {
    return this.http.post(this.END_POINT + "/CrrSubtramo", data).pipe(retry(1));
  }

  public editarSubtramoPorUnidadFuncional(data, idSubtramo) {
    return this.http
      .put(this.END_POINT + "/CrrSubtramo/" + idSubtramo, data)
      .pipe(retry(1));
  }

  public eliminarSubtramoPorUnidadFuncional(idSubtramo) {
    return this.http
      .delete(this.END_POINT + "/CrrSubtramo/" + idSubtramo)
      .pipe(retry(1));
  }

  public sincronizarUnidadFuncional(
    idUnidadFuncional: number
  ): Observable<any> {
    let urlSincronizacion =
      "https://sig.ani.gov.co/arcgissrv/rest/services/8_UnidadesFuncionales/UFSinc/GPServer/Sincronizacion%20Unidades%20Funcionales/submitJob?IDUF1=" +
      idUnidadFuncional +
      "&env%3AoutSR=&env%3AprocessSR=&returnZ=false&returnM=false&returnTrueCurves=false&f=pjson"; // the url you are trying to access
    return from(
      fetch(urlSincronizacion, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET", // GET, POST, PUT, DELETE
        mode: "no-cors", // the most important option
      })
    );
  }

  public validarExistenciaGeometriaUnidadFuncional(
    idUnidadFuncional: number
  ): any {
    let urlSincronizacion =
      "https://sig.ani.gov.co/arcgissrv/rest/services/8_UnidadesFuncionales/UFDissolve/FeatureServer/0/query?where=IdUf%3D" +
      idUnidadFuncional +
      "&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=true&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&sqlFormat=none&f=pjson"; // the url you are trying to access

    return fetch(urlSincronizacion)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Server response wasn't OK");
        }
      })
      .then((json) => {
        return json.count;
      });
  }

  public sincronizarProyecto(idProyecto: number): Observable<any> {
    let urlSincronizacion =
      "https://sig.ani.gov.co/arcgissrv/rest/services/8_UnidadesFuncionales/UFSincPr/GPServer/Sincronizacion%20Unidades%20Funcionales%20Proyecto/submitJob?IDPR=" +
      idProyecto +
      "&env%3AoutSR=&env%3AprocessSR=&returnZ=false&returnM=false&returnTrueCurves=false&f=pjson"; // the url you are trying to access
    return from(
      fetch(urlSincronizacion, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "GET", // GET, POST, PUT, DELETE
        mode: "no-cors", // the most important option
      })
    );
  }

  // Error handling
  handleError(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

export class ListaUnidadFuncionalRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public unidadesFuncionales?: UnidadFuncional[]
  ) {}
}

export class ConsultaUnidadFuncionalRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public unidadFuncional?: UnidadFuncional
  ) {}
}

export class ListaSubtramoRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public subtramos?: Subtramo[]
  ) {}
}

export class ConsultaSubtramoRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public subtramo?: Subtramo
  ) {}
}
