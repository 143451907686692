import { Component, OnInit } from '@angular/core';
import { loadModules } from "esri-loader";
@Component({
  selector: 'app-rpt-crr-peajes-proyecto',
  templateUrl: './rpt-crr-peajes-proyecto.component.html',
  styleUrls: ['./rpt-crr-peajes-proyecto.component.sass']
})
export class RptCrrPeajesProyectoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
