import { Injectable } from '@angular/core';
import { ConfigService } from '../../../../config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map, retry } from 'rxjs/operators';
import { ProyectoEstructuracion } from '../../proyectoEstructuracion';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';

@Injectable({
  providedIn: 'root'
})
export class PublicFerrocarrilesConsultaFicha5gService {

  public END_POINT = '';

  // Http Options
  httpOptions = {
   headers: new HttpHeaders({
       'Content-Type': 'application/json'
   })
};


 constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
   }

   //Consulta los datos de la base de datos
   public getDatosProyectos5GBD() {
    return this.http.get<ConsultarProyectoEstructuracionRpt>(this.END_POINT + '/TrnProyectoEstructuracion')
         .pipe(
           map(data => {
            return data;
           }),
           retry(1)
         );}
}

//Objeto respuesta servicio 
export class ConsultarProyectoEstructuracionRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public proyectos?: ProyectoEstructuracion[],
  ) { }
}
