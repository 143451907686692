import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-puerto-seguimiento-gestion',
  templateUrl: './puerto-seguimiento-gestion.component.html'
})
export class PuertoSeguimientoGestionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
    element.classList.add("p_puertos");
  }

}