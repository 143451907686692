import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PersonaSeguimientoService } from '../persona-seguimiento/persona-seguimiento.service';
import { isGeneratedFile } from '@angular/compiler/src/aot/util';

@Component({
  selector: 'app-persona-buscar-seguimiento',
  templateUrl: './persona-buscar-seguimiento.component.html',
  styleUrls: []
})
export class PersonaBuscarSeguimientoComponent implements OnInit {

  tiposDocumentoList: any = [];
  tipoDocumentoVar: any;
  personasList: any = [];
  numeroDocumentoVar: number;
  existePersona = false;
  personaEncontrada: any;
  mostrarComponente = false;

  @Input()
  valoresLista: number[];
  
  @Input()
  tipoDocumentoModal: string;

  @Output()
  cancelarPersonaJuridica = new EventEmitter<boolean>();
  @Output()
  idPersonaSeleccionada = new EventEmitter<any>();

  @Output () propagarpadre: EventEmitter<string> = new EventEmitter();


  constructor(private svcPersonas: PersonaSeguimientoService) { }

  ngOnInit() {
    this.cargarTiposDocumento();
  }

  cargarTiposDocumento() {
    this.svcPersonas.getTiposDocumento().subscribe((data: {}) => {
      this.tiposDocumentoList = data;
      
      if (this.tiposDocumentoList && this.valoresLista != null) {
        this.tiposDocumentoList = this.tiposDocumentoList.filter(dato => this.valoresLista.includes(dato.id));
      }
    });
  }  

  cancelarComponente(cancelar: boolean) {
    this.tipoDocumentoVar = null;
    this.cancelarPersonaJuridica.emit(cancelar);
  }

  retornarIdPersona(idPersona) {
    const persona = {
      idPersona,
      tipoDocumento: this.tipoDocumentoVar
    };
   }

  procesaPropagar(mensaje) {
    this.propagarpadre.emit(mensaje);

  }

}
