import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { ContratoCreditoCarreteroService } from './contrato-credito-carretero.service';
import { ContratoCredito } from './contratoCredito';
import { NgForm } from '@angular/forms';
import { IfStmt } from '@angular/compiler';
import { isUndefined } from 'util';
import { MessageService } from 'primeng/api';
import { ContratoCreditoBaseComponent } from '../financiero/contrato-credito-base/contrato-credito-base.component';
import { ConcesionarioCarreteroService } from '../concesionario-carretero/concesionario-carretero.service';
import { Contrato } from '../concesionario-carretero/contrato';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-contrato-credito-carretero',
  templateUrl: './contrato-credito-carretero.component.html',
  styleUrls: ['./contrato-credito-carretero.component.sass']
})
export class ContratoCreditoCarreteroComponent implements OnInit {

  contratoCredito: ContratoCredito = {};
  visibleDialogoExito: boolean = false;

  visibleConfirmarEliminarContratoCredito: boolean = false;
  visibleDialogoExitoEliminarContratoCredito: boolean = false;

  contrato: Contrato = {};

  idProyecto: number;



  constructor(public lineaBase: ContratoCreditoBaseComponent, private concesionarioService: ConcesionarioCarreteroService,
    private contratoCreditoCarreteroService: ContratoCreditoCarreteroService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    // this.lineaBase.idContratoCredito === 'new' ? this.cargarNuevo() : this.cargarContratoCredito(this.lineaBase.idProyecto);
    this.route.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarContratoCredito(this.idProyecto);
      this.cargarConcesionario(this.idProyecto);
    });

  }

  cargarContratoCredito(id) {
    this.contratoCreditoCarreteroService.getContratoCreditoPorProyecto(id).subscribe((data: {}) => {
      this.lineaBase.contratoCredito = data;
      if (this.lineaBase.contratoCredito == null) {
        this.lineaBase.idContratoCredito = "new";
        this.lineaBase.contratoCredito = {};
        this.lineaBase.tieneContrato = "No";
        this.lineaBase.habilitaEliminarContratoCredito = false;
        this.lineaBase.habilitaIndexacionContrato = false;
      } else {
        this.lineaBase.idContratoCredito = this.lineaBase.contratoCredito.id;
        this.lineaBase.tieneContrato = "Si";
        this.lineaBase.habilitaEliminarContratoCredito = true;
        this.lineaBase.habilitaIndexacionContrato = true;
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarConcesionario(id) {
    this.concesionarioService.getContratoConcesionarioPorIdProyecto(id, 1).subscribe((data: {}) => {
      this.contrato = data;
      if (this.contrato != null) {
        this.lineaBase.idContratoConcesionario = this.contrato.id;
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarNuevo() {
    this.lineaBase.contratoCredito = {};
    this.lineaBase.idContratoCredito = "new";
    this.lineaBase.tieneContrato = "No";
    this.lineaBase.habilitaEliminarContratoCredito = false;
    this.lineaBase.habilitaIndexacionContrato = false;
  }

  /* public cargarContratoCredito(id: number) {
    if (!isUndefined(id)) {
      this.contratoCreditoCarreteroService.getContratoCreditoPorProyecto(id).subscribe((data: {}) => {
        this.contratoCredito = data;
      });
      if (this.contratoCredito == {}) {
        this.lineaBase.idContratoCredito = "new";
      }
    }
  } */

  guardarContratoCredito(forma: NgForm) {
    if (this.lineaBase.contratoCredito.valorPesos == 0 && this.lineaBase.contratoCredito.valorDolares == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Al menos un valor debe ser diferente de 0.', life: 10000 });
    } else {
      if (this.lineaBase.idContratoCredito === 'new') {
        this.lineaBase.contratoCredito.idContrato = this.lineaBase.idContratoConcesionario;
        let vara: any = this.contratoCreditoCarreteroService.guardarNuevoContratoCredito(this.lineaBase.contratoCredito).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
          let contratoNuevo: ContratoCredito = data;
          this.lineaBase.idContratoCredito = contratoNuevo.id;
          this.lineaBase.contratoCredito = contratoNuevo;
          this.lineaBase.tieneContrato = "Si";
          this.lineaBase.habilitaEliminarContratoCredito = true;
          this.lineaBase.habilitaIndexacionContrato = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        let vara: any = this.contratoCreditoCarreteroService.editarContratoCredito(this.lineaBase.contratoCredito, this.lineaBase.idContratoCredito).subscribe((data: {}) => {
          this.visibleDialogoExito = true;
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      }
    }
  }

  irEliminarContratoCredito() {
    this.visibleConfirmarEliminarContratoCredito = true;
  }

  cancelarEliminarContratoCredito() {
    this.visibleConfirmarEliminarContratoCredito = false;
  }

  aceptarEliminarContratoCredito() {
    return this.contratoCreditoCarreteroService.eliminarContratoCredito(this.lineaBase.contratoCredito.id).subscribe((data: {}) => {
      this.visibleConfirmarEliminarContratoCredito = false;
      this.visibleDialogoExitoEliminarContratoCredito = true;
      this.cargarNuevo();
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarContratoCredito() {
    this.visibleDialogoExitoEliminarContratoCredito = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
  }

}
