import { Component, OnInit, } from '@angular/core';
import { FuncionarioInviasPrincipalListService, } from './funcionario-invias-principal-list.service';
import { Router } from '@angular/router';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';
import { NgForm } from '@angular/forms';
import { InformacionGeneral, SolicitudInvias, SolicitudInviasFinalizacionProceso } from './informacionGeneral';



@Component({
    selector: 'app-funcionario-invias-principal-list.component',
    templateUrl: './funcionario-invias-principal-list.component.html',
    styleUrls: ['./funcionario-invias-principal-list.component.css'],
	providers: [FuncionarioInviasPrincipalListService]
})
export class FuncionarioInviasPrincipalListComponent implements OnInit {

  loading: boolean;
  visibleDialogoProyectos: boolean;
  visibleDialogoFinalizar: boolean;
  visibleDialogoNewSolicitud: boolean;
  visibleModalErrorBusqueda: boolean;
  habilitarAdjunto: boolean;
  temporal: any; 
  descripcionFinalizar: any; 
  displayedColumns1;
  displayedColumns2;
  objetoFinalizar: any; 

  listaTramites: any = [];
  listaProyectos: any = [];
  listaProyectosResponder: any = [];
  informacionGeneral: InformacionGeneral = {};
  solicitudInviasNew : SolicitudInvias = {};
  finalizacionProceso : SolicitudInviasFinalizacionProceso = {};
  personaSeleccionada: any = { id: 0, nombre: '', tipoDocumento: '' , numeroDocumento: '' };
  cols: any[];


  constructor(private _service: FuncionarioInviasPrincipalListService,
      private router: Router,
      private messageService: MessageService,
      private principalComponent: PrincipalComponent
      ) { }

  ngOnInit() {
    this.visibleDialogoProyectos = false;
    this.visibleDialogoFinalizar = false;
    this.visibleDialogoNewSolicitud = false;
    this.visibleModalErrorBusqueda = false;
    this.descripcionFinalizar = null;
    this.habilitarAdjunto = false;
    this.displayedColumns1 = [
        { field: 'idTramite', header: 'N° Trámite' },
        { field: 'numeroRadicado', header: 'N° Radicado ANI Solicitud INVIAS' },
        { field: 'fechaRadicado', header: 'Fecha Radicado ANI Solicitud INVIAS' },
        { field: 'conceptos', header: 'Conceptos Solicitados' },
        { field: 'conceptosConRespuesta', header: 'Concepto Recibidos' },
        { field: 'conceptosPendientesPorRespuesta', header: 'Conceptos Pendientes Por Recibir' },
        { field: 'conceptosConRespuestaPendientesPorEnviarAinvias', header: 'Conceptos Recibidos, pendientes Por Enviar a INVIAS' }
      ];

      this.displayedColumns2 = [
        { field: 'proyectos', header: 'Proyectos' },
        { field: 'radicadoSolicitudINVIAS', header: 'Radicado Solicitud INVIAS' },
        { field: 'radicadoRespuestaConcesionarioaANI', header: 'Radicado de respuesta de concesionario a ANI' },
        { field: 'fechaRespuestaConcesionarioaANI', header: 'Fecha de respuesta de concesionario a ANI' },
        { field: 'radicadoRespuestaANIaINVIAS', header: 'Número Radicado respuesta ANI a INVIAS' },
        { field: 'concepto', header: 'Concepto' },
        { field: 'asunto', header: 'Asunto' }
      ];

      this.cols = [
        { field: 'idTramite', header: 'N° Trámite' },
        { field: 'numeroRadicado', header: 'N° Radicado ANI Solicitud INVIAS' },
        { field: 'fechaRadicadoStr', header: 'Fecha Radicado ANI Solicitud INVIAS' },
        { field: 'conceptos', header: 'Conceptos Solicitados' },
        { field: 'conceptosConRespuesta', header: 'Concepto Recibidos' },
        { field: 'conceptosPendientesPorRespuesta', header: 'Conceptos Pendientes Por Recibir' },
        { field: 'conceptosConRespuestaPendientesPorEnviarAinvias', header: 'Conceptos Recibidos, pendientes Por Enviar a INVIAS' }
    ];
      
    this.personaSeleccionada = { id: 0, nombre: '', tipoDocumento: '' , numeroDocumento: '' };
    this.cargarInformacion();       
  }

  /**
   * Metodo que carga la informacion de los tramites
   */
  cargarInformacion(){
      this.loading = true;
      this._service.getConsultaListado().subscribe((data: {}) => {
        this.temporal = data;
        if(this.temporal != undefined && this.temporal != null 
            && this.temporal.solicitudes != undefined && this.temporal.solicitudes != null
            && this.temporal.solicitudes.length > 0 ){
                this.listaTramites = this.temporal.solicitudes;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: "Se cargo la información.",
                    life: 10000
                  });
        }else{
            this.messageService.add({
                severity: 'warn',
                summary: 'Advertencia',
                detail: "El sistema no encontró información.",
                life: 10000
              });
        }            
        this.loading = false;
      }, (error)=>{
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**
   * Metodo que realiza el volver
   */
  bntVolver() {
    this.loading = true;
    const ruta = "/ani-apps";
    this.router.navigate([ruta]);
  }

  /**
   * Metodo que carga el agregar un nuevo tramite
   */
  onNew(){
    this.loading = true;
    localStorage.setItem('tipoSolicitudTramite', "onNew");	
    const ruta = "/cargaExtrapesadaFun/crearTramite";
    this.router.navigate([ruta]);
  }

  /**
   * Metodo qye realiza la invocacion de la edicion
   * @param registro 
   */
  onEdit(registro){
    this.loading = true;
    localStorage.setItem('tipoSolicitudTramite', "onEdit");	
    localStorage.setItem('idTramiteEditar', registro.idTramite );
    localStorage.setItem('idSolicitudIniviasEditar', registro.idSolicitudInivias );
    const ruta = "/cargaExtrapesadaFun/crearTramite";
    this.router.navigate([ruta]);
  }

  /**
   * Metodo que invoca la respuesta 
   * @param registro 
   */
  onFollow(registro){
    this.loading = true;
    localStorage.setItem('idSolicitudInivias', registro.idSolicitudInivias);	
    const ruta = "/cargaExtrapesadaFun/crearRespuesta";
    this.router.navigate([ruta]);
  }

  /**
   * Metodo que abre el modal para finalizar
   * @param registro 
   */
  onFinish(registro){
    this.visibleDialogoFinalizar = true;
    this.descripcionFinalizar = null;
    this.objetoFinalizar = registro;
  }

  /**
   * Metodo que cancela el dialogo Finalizar
   */
  cancelarDialogoFinalizar(){
    this.visibleDialogoFinalizar = false;
    this.descripcionFinalizar = null;
  }

  /**
   * Metodo que acepta el dialogo finalizar
   */
  aceptarDialogoFinalizar(){
    if(this.descripcionFinalizar != null && this.descripcionFinalizar.trim() != ""){

      this.finalizacionProceso = {};
      this.finalizacionProceso.descripcionEstado = this.descripcionFinalizar;
      this.finalizacionProceso.estado = "Finalizado";
      this.finalizacionProceso.idSolicitudInivias = this.objetoFinalizar.idSolicitudInivias;

      this._service.guardarFinalizacionProceso(this.finalizacionProceso).subscribe((data: {}) => {
          this.cargarInformacion();       
          this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: "Se generó el proceso de finalización de proceso.",
              life: 10000
          });       
          this.visibleDialogoFinalizar = false;
          this.descripcionFinalizar = null;
          this.loading = false;
      }, (error)=>{
          this.loading = false;
          this.visibleDialogoFinalizar = false;
          this.principalComponent.cargarErrorServicio(error);
      });   

    }else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Debe agregar una descripción para la finalización del proceso.",
        life: 10000
      });
    }

  }

  /**
   * Metodo que gestiona la parte visual de los proyectos seleccionados.
   * @param registro 
   */
  onView(registro){
    this.loading = true;
    this.listaProyectosResponder = [];      
    this._service.getConsultaListaRespuestasSolicitud(registro.idSolicitudInivias).subscribe((data: {}) => {
      this.listaProyectosResponder = data;   
      this.visibleDialogoProyectos = true;       
      this.loading = false;
    },error => {
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
    });
  }

  /**
   * Metodo que carga el nuevo la pantalla 
   * @param rowData 
   */
  onNewSoli(rowData){
    this.informacionGeneral = {};
    this.personaSeleccionada = { id: 0, nombre: '', tipoDocumento: '' , numeroDocumento: '' };
    this.informacionGeneral.idTramite = rowData.idTramite;
    this.informacionGeneral.numeroRadicado = '';
    this.informacionGeneral.fechaRadicado = '';
    this.informacionGeneral.asunto = '';
    this.informacionGeneral.idSolicitudInivias = rowData.idSolicitudInivias;
    this.personaSeleccionada.nombre = rowData.nombre;
    this.personaSeleccionada.tipoDocumento = "NIT";
    this.personaSeleccionada.numeroDocumento = rowData.numeroDocumento;
    this.visibleDialogoNewSolicitud = true;
  }


   /**
   * Metodo que busca el radicado en orfeo
   * @param radicado 
   */
  onBuscar(radicado){
    if(radicado != null && radicado != undefined && radicado != ""){
      if(radicado.length < 14){
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El número de radicado no poseé la longitud del formato.",
          life: 10000
        });
        return ;
      }
      
      if(this.stringIsNumber(radicado)){
        this.loading = true;
        this._service.getConsultaRadicado(radicado).subscribe((data: {}) => {
          this.temporal = data;
          if( this.temporal.asunto != null && this.temporal.asunto != ""){
            this.informacionGeneral.asunto = this.temporal.asunto;
            this.informacionGeneral.numeroRadicado = this.temporal.numeroRadicado;
            this.informacionGeneral.fechaRadicadoStr = this.temporal.fechaRadicadoStr;
            this.informacionGeneral.fechaRadicado = this.temporal.fechaRadicado;
            this.informacionGeneral.rutaAdjunto = this.temporal.archivo;
            this.visibleModalErrorBusqueda = false;
            if(this.temporal.archivo != null && this.temporal.archivo != ""){
              this.habilitarAdjunto = true;
            }else{
              this.habilitarAdjunto = false;
            }
          }else{
            if(this.temporal!= null && this.temporal.mensajeError!= undefined && this.temporal.mensajeError != null
              && this.temporal.mensajeError!= ''){
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: this.temporal.mensajeError,
                  life: 10000
                });
              }else{
                this.visibleModalErrorBusqueda = true;
              }
            this.habilitarAdjunto = false;
          }
          this.loading = false;
        },error => {
           this.loading = false;
           this.principalComponent.cargarErrorServicio(error);
         });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "Se debe digitar un número de radicado valido.",
          life: 10000
        });
        return ;
      }
    }else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Se debe digitar un número de radicado.",
        life: 10000
      });
      return ;
    }    
  }

   /**
   * Metodo que valida si es un numero.
   * @param s 
   */
  stringIsNumber(s) {
    var x = +s; // made cast obvious for demonstration
    return x.toString() === s;
  }

  /**
   * Metodo que cancela la modal
   */
  cancelarNewSolicitud(){
      this.visibleDialogoNewSolicitud = false;
  }   

  /**
   * Metodo que oculta el modal de Proyectos
   */
  cancelarDialogoProyectos(){
      this.visibleDialogoProyectos = false;
  }

  /**
   * Metod que realiza el descargue del archivo del radicado
   * @param objeto 
   */
  onDescargarAdjunto(objeto){
    if(objeto.adjunto != null){
      window.open(objeto.adjunto);
    }else{
      if(objeto.rutaAdjunto != null){
        window.open(objeto.rutaAdjunto);
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El sistema no encontró el archivo a descargar",
          life: 10000
        });
      }
    }
  }

  /**
   * Metodo que realiza la creacion de una nueva solicitud de un tramite
   * @param forma 
   */
  guardarNewSolicitud(forma: NgForm){
      if(this.informacionGeneral != null &&  this.informacionGeneral.numeroRadicado != undefined
          && this.informacionGeneral.numeroRadicado != null && this.informacionGeneral.numeroRadicado != ''
          && this.informacionGeneral.asunto != undefined && this.informacionGeneral.asunto != null
          && this.informacionGeneral.asunto != '' && this.informacionGeneral.fechaRadicado != undefined 
          && this.informacionGeneral.fechaRadicado != null && this.informacionGeneral.fechaRadicado != ''
      ){
      this.solicitudInviasNew = {};
      this.solicitudInviasNew.asunto = this.informacionGeneral.asunto;
      this.solicitudInviasNew.fechaRadicado = this.informacionGeneral.fechaRadicado;
      this.solicitudInviasNew.numeroRadicado = this.informacionGeneral.numeroRadicado;
      this.solicitudInviasNew.idTramite = this.informacionGeneral.idTramite;
      this.solicitudInviasNew.rutaAdjunto = this.informacionGeneral.rutaAdjunto;

      this.loading = true;
      this._service.guardarNewSolicitud(this.solicitudInviasNew).subscribe((data: {}) => {
          this.temporal = data;
          
          this.cargarInformacion(); 
      
          this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: "Se generó el encabezado",
              life: 10000
          });
          this.visibleDialogoNewSolicitud = false;
          forma.resetForm();
          this.informacionGeneral.asunto = '';
          this.informacionGeneral.fechaRadicado = '';
          this.informacionGeneral.numeroRadicado = '';         
          
          this.loading = false;
      }, (error)=>{
          this.loading = false;
          this.visibleDialogoNewSolicitud = false;
          this.principalComponent.cargarErrorServicio(error);
          });
      }        
  }

  /**
   * Metodo que visualiza el modal de error de busqueda
   */
  aceptarDialogoErrorBusqueda(){
      this.visibleModalErrorBusqueda = false;
  }


    /**
	 * Metodo que captura el evento del teclado solo numeros 0-9
	 * @param evento 
	 */
	soloNumeros(evento){
		if(evento.code == "Backspace" 
		|| evento.code == "Enter" 
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
    || evento.code == "Delete"
    || evento.ctrlKey == true){
			return true;
    }
    
    if(evento.ctrlKey == true && evento.keyCode == 67){
      return true;
    }
    if(evento.ctrlKey == true && evento.keyCode == 86){
      return true;
    }

		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || 
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}

	}	   
  
   /**
	 * Metodo que captura el evento del teclado solo alfanumerico
	 * @param evento 
	 */
	soloAlfanumericos(evento){

		if(evento.shiftKey || evento.ctrlKey || evento.altKey || evento.code =="BracketLeft"){
			return false;
		}

		if(evento.code == "Backspace" 
		|| evento.code == "Enter"
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"){
			return true;
		}
		
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || evento.keyCode >= 65 &&  evento.keyCode <= 90 ||
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}
	}	



}
