import { TrnRespuestaServicio } from "../../../parametricas/trn-respuesta-servicio";

export class PjeDetalleTarifaRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public detalleTarifa?: PjeDetalleTarifa[],
    ) { }
}

export class PjeDetalleTarifa {
    constructor(
        public id?: number,
        public idEncabezado?: number,
        public idTarifa?: string,
        public valor?: number,
        public tipoCategoria?: string
    ) { }
}
