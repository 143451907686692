import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { environment } from '../../../environments/environment';
import { ProyectoCarretero } from 'src/app/informacion-proyecto-carretero/proyectoCarretero';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-financiero-home',
  templateUrl: './financiero-home.component.html',
  styleUrls: ['./financiero-home.component.sass']
})
export class FinancieroHomeComponent implements OnInit {

  proyecto: ProyectoCarretero;
  id: number;

  constructor(private infoService: InformacionGeneralService, private route: ActivatedRoute, private router: Router, @Inject(DOCUMENT) private document: any,
    private messageService: MessageService, private principalComponent: PrincipalComponent) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.cargarProyectoCarretero(this.id);
    });
    /* var nombreProyecto = document.getElementById("nombre_proyecto");
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto); */

  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAInversionProyecto() {
    this.router.navigate([this.router.url + '/finInversionIndexada', this.id]);
  }

  irAInformacionGeneral() {
    this.router.navigate([this.router.url + '/informacionGeneralFinanciera', this.id]);
  }

  irAContratoCredito() {
    this.router.navigate([this.router.url + '/finContratoCreditoBase', this.id]);
  }

  irAContratoCreditoPuente() {
    this.router.navigate([this.router.url + '/finContratoCreditoPuenteBase', this.id]);
  }

  irASaldosFinancieros() {
    this.router.navigate([this.router.url + '/finSaldosFinancieros', this.id]);
  }

  irARetribucionFinanciera() {
    this.router.navigate([this.router.url + '/finRetribucionFinanciera', this.id]);
  }

  irAVigenciasFuturas() {
    this.router.navigate([this.router.url + '/finVigenciasFuturas', this.id]);
  }

  irAVpip() {
    this.router.navigate([this.router.url + '/finVpip', this.id]);
  }

}
