import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-selector-fecha-quincena',
  templateUrl: './selector-fecha-quincena.component.html',
  styleUrls: ['./selector-fecha-quincena.component.sass']
})
export class SelectorFechaQuincenaComponent implements OnInit {

  anioSeleccionado: { numeroAnio: '-1' };

  dias: any[];
  diaSeleccionado: { numeroDia: '-1' };

  meses: any[];
  mesSeleccionado = { numeroMes: '-1', nombreMes: '' };

  anios: any[];

  @Input() fechaMinima: Date;
  @Output() OutputFechaConstruida = new EventEmitter();

  constructor() { }

  ngOnInit() {
    //Construir array dias
    this.dias = [
      { numeroDia: '15' },
      { numeroDia: '30' }
    ];
    //Construir array meses
    this.meses = [
      { numeroMes: '0', nombreMes: 'Enero' },
      { numeroMes: '1', nombreMes: 'Febrero' },
      { numeroMes: '2', nombreMes: 'Marzo' },
      { numeroMes: '3', nombreMes: 'Abril' },
      { numeroMes: '4', nombreMes: 'Mayo' },
      { numeroMes: '5', nombreMes: 'Junio' },
      { numeroMes: '6', nombreMes: 'Julio' },
      { numeroMes: '7', nombreMes: 'Agosto' },
      { numeroMes: '8', nombreMes: 'Septiembre' },
      { numeroMes: '9', nombreMes: 'Octubre' },
      { numeroMes: '10', nombreMes: 'Noviembre' },
      { numeroMes: '11', nombreMes: 'Diciembre' }
    ];
    //Construir array anios
    const currentYear = (new Date()).getFullYear();
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
    let rangoAnios = range(currentYear + 1, currentYear - 1, -1);
    this.anios = [];
    rangoAnios.forEach(element => {
      this.anios.push({ numeroAnio: element });
    });
  }

  onChange(event) {
    this.construirFecha();
  }

  construirFecha() {
    if (this.mesSeleccionado != null) {
      let mesNumber = Number(this.mesSeleccionado.numeroMes);
      if (mesNumber == 1) {
        this.dias = [
          { numeroDia: '15' },
          { numeroDia: '28' }
        ];
      } else {
        this.dias = [
          { numeroDia: '15' },
          { numeroDia: '30' }
        ];
      }
    }

    if (this.anioSeleccionado != null && this.mesSeleccionado != null && this.diaSeleccionado != null) {
      let anioNumber = Number(this.anioSeleccionado.numeroAnio);
      let mesNumber = Number(this.mesSeleccionado.numeroMes);
      let diaNumber = Number(this.diaSeleccionado.numeroDia);



      var fechaConstruida = new Date(anioNumber, mesNumber, diaNumber);
      if (fechaConstruida > new Date(this.fechaMinima) && fechaConstruida <= new Date()) {
        this.OutputFechaConstruida.emit(fechaConstruida);
      } else {
        this.OutputFechaConstruida.emit(null);
      }
    } else {
      this.OutputFechaConstruida.emit(null);
    }
  }

}
