import { Component, OnInit } from '@angular/core';
import {MenubarModule} from 'primeng/menubar';
import {CarouselModule} from "primeng/primeng";
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-public-uat',
  templateUrl: './public-uat.component.html',
  styleUrls: ['./public-uat.component.sass']
})

export class PublicUatComponent implements OnInit {

    items: MenuItem[];
    jsonStr: CarouselModule[];
    page: any;
    constructor() {

        this.jsonStr = [

            {"Url": "assets/img/aliados/logo1.jpg","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo2.png","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo3.jpg","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo6.jpg","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo9.png","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo18.png","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo13.png","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo5.png","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo8.jpg","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo11.jpg","Manager": "Usain"},
            {"Url": "assets/img/aliados/logo19.jpg","Manager": "Usain"},
        ]

      }

    ngOnInit() {


        this.items = [
            {
                label: 'UAT'
                , "routerLink": "/uat"
            },
            {
                label: 'Servicios',
                items: [
                {label: 'Repositorio de Información de tráfico y recaudo', "routerLink": "/uat/repositorio"},
                {label: 'Modelo Nacional de Transporte', "routerLink": "/uat/modelo"},
                {label: 'Mesa Nacional de modelación de transporte', "routerLink": "/uat/mesa"}
            ]
            },
            {
                label: 'Aliados'
                , "routerLink": "/uat/aliados"
            },
            {
                label: 'Investigación'
                , "routerLink": "/uat/investigacion"
            }
        ];


    }
    setPage(page){
        this.page = page;
      }



}

