
export class InformacionGeneral {
    constructor(
        public id?: number, 
        public idProyecto?: number, 
        public idContrato?: number, 
        public idAseguradora?: number, 
        public idMoneda?: string,
        public moneda?: string,
        public aseguradora?: string,               
        public nitAseguradora?: string,        
        public numero?: string,        
        public numeroContrato?: string,        
        public tomador?: string,        
        public fechaExpedicion?: Date,
        public fechaExpedicionStr?: string,
        public objetoSeguro?: string,
        public habilitarEditar?: boolean,
        public habilitarEliminar?: boolean,
        public habilitarAseguradoNombre?: boolean) {}

}

export class Persona {
    constructor(
    public id?: number, 
    public idPersona?: number, 
    public idPersonaJuridica?: number, 
    public idPoliza?: number, 
    public digitoVerificacion?: string, 
    public idTipoDocumento?: string, 
    public nombre?: string, 
    public numeroDocumento?: string, 
    public tipo?: string,
    public tipoNombre?: string ) {}
}


export class Amparo {
    constructor(
        public id?: number, 
        public idAnexo?: number, 
        public valorAmparo?: number, 
        public valorAmparoStr?: string, 
        public idTipoAmparo?: number, 
        public descripcion?: string, 
        public tipoAmparo?: string, 
        public fechaFinCobertura?: Date, 
        public fechaFinCoberturaStr?: string, 
        public fechaInicioCoberturaStr?: string,
        public fechaInicioCobertura?: Date
        ) {}
}


//TODO: ENVIO DE LA INFORMACION
export class InformacionGeneralEnvioEdit {
    constructor(
        public id?: number, 
        public idContrato?: number, 
        public numero?: string,
        public idAseguradora?: number, 
        public idMoneda?: string,        
        public anexos?: Anexo [],
        public aseguradosBeneficiarios?: AseguradosBeneficiarios []
        ) {}
}

export class InformacionGeneralEnvio {
    constructor(
        public idContrato?: number, 
        public numero?: string,
        public idAseguradora?: number, 
        public idMoneda?: string,        
        public anexos?: Anexo [],
        public aseguradosBeneficiarios?: AseguradosBeneficiarios []
        ) {}
}

export class Anexo {
    constructor(
        public fechaExpedicion?: Date, 
        public objeto?: string, 
        public amparos?: Amparos []
        ) {}
}


export class Anexos {
    constructor(
        public fechaExpedicion?: Date, 
        public fechaExpedicionStr?: string, 
        public habilitarEditar?: number, 
        public habilitarEliminar?: number, 
        public id?: number, 
        public idMonedaPoliza?: string, 
        public idNovedadContrato?: number, 
        public idPoliza?: number, 
        public monedaPoliza?: string, 
        public novedadContrato?: string, 
        public numero?: string, 
        public objeto?: string, 
        public observacion?: string, 
        public orden?: string, 
        public amparos?: Amparos []
        ) {}
}

export class AseguradosBeneficiarios {
    constructor(
        public idPersona?: number, 
        public idPersonaJuridica?: number, 
        public tipo?: string
        ) {}
}

export class Amparos {
    constructor(
        public idTipoAmparo?: number, 
        public descripcion?: string, 
        public valorAmparo?: number, 
        public fechaInicioCobertura?: Date, 
        public fechaFinCobertura?: Date
        ) {}
}


export interface Selects {
    id?: number;
    nombre?: string;
  }



