import { Generacion } from 'src/app/informacion-general/generacion';
import { Ola } from 'src/app/parametricas/ola';

export class InformacionGeneral {
    constructor(
        public idProyecto?: number, 
        public codigo?: string,
        public nombre?: string,
        public nombreCorto?: string,               
        public alcance?: string,        
        public fechaReferenciaCapex?: Date,
        public capex?: number,
        public fechaReferenciaOpex?: Date,
        public opex?: number,
        public idTipo?: number,
        public tieneCapexOpexPorUnidadFuncional?: boolean,
        public idOla?: Ola,
        public idGeneracion?: Generacion) {}
}