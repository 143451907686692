import { Component, OnInit } from '@angular/core';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { forkJoin, Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { AmbObligacionEncabezadoDTO } from 'src/app/compensaciones-ambientales/compensaciones-ambientales.dto';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { CompensacionesAmbientalesService } from 'src/app/compensaciones-ambientales/compensaciones-ambientales.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-consultar-localizacion-avance',
  templateUrl: './consultar-localizacion-avance.component.html',
  styleUrls: ['./consultar-localizacion-avance.component.sass']
})
export class ConsultarLocalizacionAvanceComponent implements OnInit {

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  idObligacion: number;
  obligacionAmbiental: AmbObligacionEncabezadoDTO;

  rutaIframe: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private compensacionesAmbientalesService: CompensacionesAmbientalesService,
    private principalComponent: PrincipalComponent,
    private domSanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    // Obtener parametros
    const idParam = 'id';
    const idParamOblig = 'idOblig';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.idObligacion = params[idParamOblig];
      this.rutaIframe = 'https://www.arcgis.com/apps/webappviewer/index.html?id=7acedd3ddb5b481b8909ad7a6f47bc67&query=ACServicios_6538%2CIdObligacion%2C' +
        this.idObligacion;
    });

    this.inicializar();
  }

  inicializar() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.compensacionesAmbientalesService.srvObtenerObligacionAmbientalPorId(
        this.idObligacion
      )
    ).subscribe(
      ([
        proyectoData,
        obligacionAmbientalData
      ]) => {
        this.proyectoSeleccionado = proyectoData;
        this.obligacionAmbiental = obligacionAmbientalData.obligacionAmbiental;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });

  }

  bntVolver() {
    const ruta =
      'carreteros/infGeneral/proyectoHome/' +
      this.proyectoSeleccionado.id +
      '/compensaciones-ambientales/' +
      this.proyectoSeleccionado.id +
      '/seguimiento-compensaciones/' +
      this.proyectoSeleccionado.id +
      '/ejecuciones-obligacion/' +
      this.proyectoSeleccionado.id +
      '/' +
      this.idObligacion;
    this.router.navigate([ruta]);
  }

}
