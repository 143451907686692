import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { EjecucionProyectoService } from '../ejecucion_proyecto.service';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { ProyectoDTO, VwCrrAvanceUfDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { CambioConfiguracionProyectoDTO, EjecucionDTO } from '../ejecucion-proyecto.dto';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-avance-proyecto-reportar-avance',
  templateUrl: './avance-proyecto-reportar-avance.component.html',
  styleUrls: ['./avance-proyecto-reportar-avance.component.sass']
})
export class AvanceProyectoReportarAvanceComponent implements OnInit {

  vistaActual: string = "";
  msgs = [];
  blockedDocument: boolean = false;

  idProyecto: number;
  idUltimaEjecucion: number;
  fechaUltimaEjecucion: Date;
  fechaUltimaEjecucionPublicada: Date;
  idUltimaEjecucionPublicada: number;
  porcentajeAvanceProyecto: number;
  porcentajeAvanceProyectoPublicado: number;

  ejecucionSeleccionada: EjecucionDTO;
  unidadFuncionalSeleccionada: VwCrrAvanceUfDTO;

  crrCambioConfiguracionProyecto: CambioConfiguracionProyectoDTO;

  minDateFechaReporte: Date;

  @Input() proyectoSeleccionado: ProyectoDTO;

  constructor(private route: Router, private ruta: ActivatedRoute,
    private ejecucionProyectoService: EjecucionProyectoService,
    private componentesComunesService: ComponentesComunesService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    //Bloquear pantalla
    this.blockedDocument = true;

    this.ruta.params.subscribe(params => {
      this.idProyecto = params.id;
      this.cargarInfoAvanceProyecto();
      this.vistaActual = "ejecuciones";
    });
  }

  cargarInfoAvanceProyecto() {
    forkJoin(
      this.ejecucionProyectoService.srvCambioConfiguracionProyecto(this.proyectoSeleccionado.id),
      this.ejecucionProyectoService.srvAvanceProyecto(this.idProyecto),
      this.ejecucionProyectoService.srvAvanceProyectoPublicado(this.idProyecto)
    ).subscribe(([data1, data2, data3]) => {

      if (data1 != null) {
        this.crrCambioConfiguracionProyecto = data1;
      } else {
        this.crrCambioConfiguracionProyecto = null;
      }
      if (data2 != null && data2.length > 0) {
        this.idUltimaEjecucion = data2[0].idEjecucion;
        this.fechaUltimaEjecucion = data2[0].fechaReporte;
        this.porcentajeAvanceProyecto = data2[0].porcentajeAvance;
      } else {
        this.idUltimaEjecucion = null;
        this.fechaUltimaEjecucion = null;
        this.porcentajeAvanceProyecto = null;
      }
      if (data3 != null && data3.length > 0) {
        this.idUltimaEjecucionPublicada = data3[0].idEjecucion;
        this.fechaUltimaEjecucionPublicada = data3[0].fechaReporte;
        this.porcentajeAvanceProyectoPublicado = data3[0].porcentajeAvance;
      } else {
        this.idUltimaEjecucionPublicada = null;
        this.fechaUltimaEjecucionPublicada = null;
        this.porcentajeAvanceProyectoPublicado = null;
      }

    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    },
      () => {
       this.blockedDocument = false;
      }
    );

  }

  yaCargoUnidadesFuncionales: boolean;
  outputYaCargoUnidadesFuncionales(event) {
    this.yaCargoUnidadesFuncionales = true;
    this.desbloquearPantalla();
  }

  yaCargoEjecuciones: boolean;
  outputYaCargoEjecuciones(event) {
    this.yaCargoEjecuciones = true;
    this.desbloquearPantalla();
  }

  desbloquearPantalla() {
    if (this.yaCargoUnidadesFuncionales && this.yaCargoEjecuciones) {
      this.blockedDocument = false;
      this.yaCargoUnidadesFuncionales = false;
      this.yaCargoEjecuciones = false;
    }
  }

  outputReportarEjecucion(event): void {
    if (event.codigo == 1) {
      this.vistaActual = "crearEjecucion";
      //this.lanzarMensajeInfo(event.respuesta);
    } else {
      this.lanzarMensajeWarning(event.respuesta);
    }
  }

  outputCrearEjecucion(event): void {
    //this.lanzarMensajeInfo(event);
    if (event.resultado.idCrrEjecucion != "") {
      this.srvObtenerEjecucion(event.resultado.idCrrEjecucion);
      this.vistaActual = "unidadesFuncionales";
    } else {
      this.lanzarMensajeWarning(event.resultado.message);
    }

  }

  outputMinDateFechaReporte(event): void {
    this.minDateFechaReporte = event;
  }

  outputIrAPreConstruccion(event): void {
    //Bloquear pantalla
    //this.blockedDocument = true;
    this.vistaActual = "preconstruccion";
  }

  outputIrAEjecuciones(event): void {
    //Bloquear pantalla
    this.blockedDocument = true;
    this.cargarInfoAvanceProyecto();
    this.vistaActual = "ejecuciones";
  }

  outputIrAOperacionMant(event): void {
    //Bloquear pantalla
    //this.blockedDocument = true;
    this.vistaActual = "operacionMant";
  }

  outputIrAReversion(event): void {
    //Bloquear pantalla
    //this.blockedDocument = true;
    this.vistaActual = "reversion";
  }

  outputPublicarEjecucion(event): void {
    if (event.codigo == 1) {
      this.idUltimaEjecucionPublicada = event.idEjecucionPublicada;
      this.srvObtenerEjecucion(this.idUltimaEjecucionPublicada);
      this.vistaActual = "ejecuciones";
      this.lanzarMensajeInfo(event.respuesta);
    } else {
      this.lanzarMensajeError(event.respuesta);
    }
  }

  outputSeleccionarUnidadFuncional(event): void {
    this.unidadFuncionalSeleccionada = event;
    this.vistaActual = "intervenciones";
  }

  outputIrAUnidadesFuncionales(event): void {
    this.vistaActual = "unidadesFuncionales";
  }

  outputEjecucionSeleccionado(event): void {
    this.ejecucionSeleccionada = event;
    this.vistaActual = "unidadesFuncionales";
  }

  outputGuardarEjecucion(event): void {
    if (event.codigo == 1) {
      this.vistaActual = "unidadesFuncionales";
      this.lanzarMensajeInfo(event.respuesta);
    } else {
      this.lanzarMensajeError(event.respuesta);
    }
  }

  outputGuardarEtapaEjecutadaUf(event): void {
    if (event.resultado.codigo == 1) {
      this.vistaActual = "ejecuciones";
      this.lanzarMensajeInfo("Los datos se guardaron correctamente");
    } else {
      this.lanzarMensajeError("No fue posible guardar la información");
    }
  }

  outputGuardarEtapaEjecutadaIntervencionUf(event): void {
    if (event.resultado.codigo == 1) {
      this.vistaActual = "ejecuciones";
      this.lanzarMensajeInfo("Los datos se guardaron correctamente");
    } else {
      this.lanzarMensajeError("No fue posible guardar la información");
    }
  }

  outputGuardarEtapaEjecutadaProyecto(event): void {
    if (event.resultado.codigo == 1) {
      this.vistaActual = "ejecuciones";
      this.lanzarMensajeInfo("Los datos se guardaron correctamente");
    } else {
      this.lanzarMensajeError("No fue posible guardar la información");
    }
  }

  ///////////////////////////////LLamar Servicios//////////////////////////////
  srvObtenerProyecto(_idProyecto: number) {
    return this.componentesComunesService.obtenerProyecto(_idProyecto).subscribe(data => {
      this.proyectoSeleccionado = data;
    });
  }

  /**
  srvAvanceProyecto(_idProyecto: number) {
    return this.ejecucionProyectoService.srvAvanceProyecto(_idProyecto).subscribe(data => {
     if (data != null && data.length > 0) {
        this.idUltimaEjecucion = data[0].idEjecucion;
        this.fechaUltimaEjecucion = data[0].fechaReporte;
        this.porcentajeAvanceProyecto = data[0].porcentajeAvance;
      } else {
        this.idUltimaEjecucion = null;
        this.fechaUltimaEjecucion = null;
        this.porcentajeAvanceProyecto = null;
      }
    });
  }**/

  srvObtenerEjecucion(_idEjecucion: number) {
    return this.componentesComunesService.obtenerEjecucion(_idEjecucion).subscribe(data => {
      this.ejecucionSeleccionada = data;
      this.idUltimaEjecucionPublicada = this.ejecucionSeleccionada.id;
      this.fechaUltimaEjecucionPublicada = this.ejecucionSeleccionada.fechaReporte;
    });
  }

  ///////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
  }

  lanzarMensajeError(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
  }

  lanzarMensajeWarning(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }





}
