import { Component, OnInit, ViewChild } from '@angular/core';
import { PolizasService } from './polizas.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatTableDataSource , MatPaginator} from '@angular/material';
import { ProyectoPolizas } from '../../dtos/polizas/proyecto-polizas';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
	selector: 'app-polizas',
	templateUrl: './polizas.component.html',
	styleUrls: ['./polizas.component.sass'],
	providers: [PolizasService]
})

export class PolizasComponent implements OnInit {

	loading: boolean;
	displayedColumnsPolizas;
	listaData: any = [];
	contratos: any = [];
	data: any ;
	idPolizaEliminar: any ;
	idProyecto: number;
	nombreProyecto;
	displayedColumns: string[] = ['Número contrato', 'Número de poliza', 'Aseguradora', 'Tomador', 'acciones'];
	dataSource: MatTableDataSource < ProyectoPolizas > ;

	visibleConfirmarEliminarPoliza: boolean = false;


	@ViewChild(MatPaginator, {
		static: true
	}) paginator: MatPaginator;
	@ViewChild(MatSort, {
		static: true
	}) sort: MatSort;

	constructor(
		private info: PolizasService,
		private route: Router,
		private ruta: ActivatedRoute,
		public dialog: MatDialog, 
		private principalComponent: PrincipalComponent) {}

	/**
	 * Metodo inicalizador
	 */		
	ngOnInit() {
		this.loading = true;
		this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
		this.ruta.params.subscribe(params => {
			this.idProyecto = params.id;
			this.cargarContratosLista(this.idProyecto);
			localStorage.setItem('idP', String(this.idProyecto));
		});

		this.displayedColumnsPolizas = [{
				field: 'numeroContrato',
				header: 'Número contrato'
			},
			{
				field: 'numero',
				header: 'Número de póliza'
			},
			{
				field: 'aseguradora',
				header: 'Aseguradora'
			},
			{
				field: 'tomador',
				header: 'Tomador'
			}
		];
	}
	
	/**
	 * Metodo que realiza el filter
	 * @param filterValue 
	 */
	applyFilter(filterValue: string) {
		this.dataSource.filter = filterValue.trim().toLowerCase();
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	/**
	 * Metodo para crear una nueva poliza
	 */
	onNew() {
		localStorage.setItem('tipoSolicitud', "onNew");
		this.route.navigate([this.route.url + '/polizaEdit', 'new']);
	}

	/**
	 * Metodo para edita una poliza
	 */
	onEdit(idPoliza: number) {
		localStorage.setItem('tipoSolicitud', "onEdit");
		this.route.navigate([this.route.url + '/polizaEdit', idPoliza]);
	}

	/**
	 * Metodo para visualizar una poliza
	 */
	 onView(idPoliza: number) {
		localStorage.setItem('tipoSolicitud', "onView");
		this.route.navigate([this.route.url + '/polizaEdit', idPoliza]);
	}

	/**
	 * Metodo para el seguimiento
	 * @param poliza 
	 */
	onFollow(poliza: any) {
		localStorage.setItem('idProyecto', ""+poliza.idProyecto);
		localStorage.setItem('idPoliza', ""+poliza.id);
		localStorage.setItem('idContrato', ""+poliza.idContrato);
		this.route.navigate([this.route.url + '/anexos', poliza.id]);
	}

	/**
	 * Metodo para eliminar una poliza
	 * @param idRegistry 
	 */
	onDelete(idPoliza: number) {
		this.visibleConfirmarEliminarPoliza = true;
		this.idPolizaEliminar = idPoliza;
	}

	/**
	 * Metodo que acepta la eliminacion
	 */
	aceptarEliminarPoliza(){
		this.loading = true;
		if(this.idPolizaEliminar!= null){
			return this.info.deleteDetalle(this.idPolizaEliminar).subscribe((data: {}) => {
				this.idPolizaEliminar = null;
				this.visibleConfirmarEliminarPoliza = false;
				this.cargarContratosLista(this.idProyecto);
			},error => {
				this.principalComponent.cargarErrorServicio(error);
			  });
		}
	}

	/**
	 * Metodo que oculta el model de eliminacion
	 */
	cancelarEliminarPoliza(){
		this.idPolizaEliminar = null;
		this.visibleConfirmarEliminarPoliza = false;
	}


	/**
	 * Metodo que carga la lista de contratos
	 * @param idPoliza 
	 */
	cargarContratosLista(idPoliza) {
		return this.info.getContratosListaPrincipal(idPoliza).subscribe((data: {}) => {
			this.data = data;
			this.contratos = this.data.polizasProyecto;
			this.dataSource = new MatTableDataSource(this.contratos.polizasContrato);
			this.dataSource.paginator = this.paginator;
			this.listaData = this.contratos;
			this.loading = false;
		},error => {
			this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		  });
	}

	/**
	 * Metodo que cambia el timpo de entrada a UTC
	 * @param data 
	 */
	convertStringDateToTime(data) {
		const date = new Date(data);
		const localOffset = date.getTimezoneOffset() * 60000;
		const utc = date.getTime() + localOffset;
		return utc;
	}

}