import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ciudad } from 'src/app/parametricas/ciudad';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';

@Component({
  selector: 'app-seleccionar-municipios',
  templateUrl: './seleccionar-municipios.component.html',
  styleUrls: ['./seleccionar-municipios.component.sass']
})
export class SeleccionarMunicipiosComponent implements OnInit {

  municipios: Ciudad[] = [];
  filtroMunicipioDepartamento: Ciudad[];
  municipioSeleccionado: Ciudad;

  @Input()
  visibleMunicipio: boolean;

  @Output()
  municipoEmiter = new EventEmitter();

  constructor(private parametricasService: ParametricasService) { }

  ngOnInit() { }

  ngAfterContentInit() {
    this.limpiarCampos();
  }

  cargarMunicipioConDepartamentos(event) {
    this.filtroMunicipioDepartamento = [];
    return this.parametricasService.getMunicipiosConDepartamentos().subscribe(
      result => {
        this.municipios = result;
        let existeFiltro: boolean;
        for (let i = 0; i < this.municipios.length; i++) {
          let filtro = this.municipios[i];
          let item: string = String(filtro.nombre);
          if (item.toLowerCase().includes(event.query.toLowerCase())) {
            this.filtroMunicipioDepartamento.push(filtro);
            existeFiltro = true;
          }
        }
        if (existeFiltro) {
          this.municipios = this.filtroMunicipioDepartamento;
        }
      });
  }

  cargarFiltroMunicipioConDepartamentos(event) {
    this.filtroMunicipioDepartamento = [];
    for (let i = 0; i < this.municipios.length; i++) {
      let filtro = this.municipios[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroMunicipioDepartamento.push(filtro);
      }
    }
  }

  onChangeMunicipios() {
    this.municipoEmiter.emit(
      this.municipioSeleccionado
    );
  }

  limpiarCampos() {
    this.municipioSeleccionado = null;
  }
}


