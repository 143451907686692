import { Component, OnInit } from '@angular/core';
import { CtoInformeRevisionAnexoDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-anexo';
import { InformeSpaBaseComponent } from '../informe-spa-base/informe-spa-base.component';
import { InformeRevisionService } from '../../../informe-revision.service';
import { MessageService } from 'primeng/api';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';

@Component({
  selector: 'app-informe-spa-beneficios-tributarios',
  templateUrl: './informe-spa-beneficios-tributarios.component.html',
  styles: []
})
export class InformeSpaBeneficiosTributariosComponent implements OnInit {

  planillasInforme: CtoAdjuntoInformeBorradorDTO[];
  anexosInformeRevision: CtoInformeRevisionAnexoDTO[];
  adjuntosAnexoInforme: CtoAdjuntoInformeBorradorDTO[];
  visibleDialogoVerAdjuntos: boolean;

  constructor(
    private lineaBase: InformeSpaBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarAnexosTributarios(this.lineaBase.idInformeParam, this.lineaBase.idPersonaAsignacionParam, this.lineaBase.idPasoParam);
    this.cargarPlanillasInforme(this.lineaBase.idInformeParam, this.lineaBase.idPersonaAsignacionParam);
  }

  cargarPlanillasInforme(idInforme: number, idPersonaAsignacion: number) {
    this.infCtaSupervisionService.ConsultarPlanillasInformeRevision(idInforme, idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.planillasInforme = result.adjuntos;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  cargarAnexosTributarios(idInforme: number, idPersonaAsignacion: number, idPaso: number) {
    this.infCtaSupervisionService.ConsultarAnexosTributariosInformeRevision(idInforme, idPersonaAsignacion, idPaso)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.anexosInformeRevision = result.anexosInformeRevision;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }


  irVerDialogoVerAdjuntosAnexos(idAnexo: number) {
    this.visibleDialogoVerAdjuntos = true;
    this.cargarAdjuntosPorAnexosTributarios(idAnexo, this.lineaBase.idPersonaAsignacionParam);
  }

  cargarAdjuntosPorAnexosTributarios(idAnexo: number, idPersonaAsignacion: number) {
    this.infCtaSupervisionService.ConsultarAdjuntosPorAnexoInformeRevision(idAnexo, idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosAnexoInforme = result.adjuntos;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

}
