import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-puerto-home-registrar',
  templateUrl: './puerto-home-registrar.component.html'
})
export class PuertoHomeRegistrarComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  loadProyectoPuerto(){
    this.loadFile("https://anionline.sharepoint.com/Gesti%C3%B3n%20VGC/GerenciaPuertos/SitePages/Home.aspx");
  }

  loadGestionPuerto(){
    this.loadFile("https://anionline.sharepoint.com/Gesti%C3%B3n%20VGC/GerenciaPuertos/SitePages/Home.aspx");
  }


  loadFile(urlFile){
    window.open(urlFile, null);
  }

  irATramitesA(){
    
    let datosTramites =[
      {"id": "0",
        "nombreIndicador": "Indicador Tramite Ambiental 1",
        "formula": "3 Tramites cerrados / 5 Tramites radicados",
        "actualizacion": "Semanal",
        "variablesEntrada": "Proyecto",
        "modo": "Unico",
        "desarrollos": "La información requerida reposa en ANISCOPIO",
        "salida": "% TRAMITES CERRADOS REPORTE DE ESTADO DE TRAMITES / PROYECTO O POR  ENTIDAD / POR TIPO TRAMITE / POR ESTADO / POR FECHAS."
      },
      {"id": "1",
        "nombreIndicador": "Indicador Tramite Ambiental 2",
        "formula": "1 Tramites cerrados / 1 Tramites radicados",
        "actualizacion": "Mensual",
        "variablesEntrada": "Proyecto",
        "modo": "Todos",
        "desarrollos": "La información requerida reposa en ANISCOPIO",
        "salida": "% TRAMITES CERRADOS REPORTE DE ESTADO DE TRAMITES / PROYECTO O POR  ENTIDAD / POR TIPO TRAMITE / POR ESTADO / POR FECHAS.  Prueba..."
      },
      {"id": "2",
        "nombreIndicador": "Indicador Tramite Ambiental 3",
        "formula": "5  Tramites cerrados / 1 Tramites radicados",
        "actualizacion": "Diario",
        "variablesEntrada": "Proyecto",
        "modo": "Varios",
        "desarrollos": "La información requerida reposa en ANISCOPIO...Datos Pruebas",
        "salida": "% TRAMITES CERRADOS REPORTE DE ESTADO DE TRAMITES / PROYECTO O POR  ENTIDAD / POR TIPO TRAMITE / POR ESTADO / POR FECHAS.  Pruebas..."
      }
    ]
    
    localStorage.setItem('datosPrueba', JSON.stringify(datosTramites));
    this.router.navigate([this.router.url+'/infTramitesA', 1])
  }

  irATemasG(){
    this.router.navigate([this.router.url+'/infTemasG', 1])
  } 
  
  irACovidReportar(){
    this.router.navigate([this.router.url+'/covid-reportar', 1])
  }

}
