import { TrnRespuestaServicio } from "../parametricas/trn-respuesta-servicio";
import { UnidadFuncional } from "../unidad-funcional/unidad-funcional";
import { CrrMaPrKDTO } from '../equipos-its/equipos-its.dto';
import { PjePeajePorProyecto } from "../peajes-gestion/trafico-peajes/PjePeajePorProyecto";

export interface CrrMaTipoUbicacionDTO {
    nombre?: string,
}
export interface CrrMaTipoCierreViaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaCrrMaTipoCierreVia?: CrrMaTipoCierreViaDTO[]
}
export interface CrrMaTipoCierreViaDTO {
    id?: number,
    nombre?: string,
}
export interface CrrMaClasificacionContingenciaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaCrrMaClasificacionContingenciaCierreVia?: CrrMaClasificacionContingenciaCierreViaDTO[]
}

export interface CrrMaClasificacionContingenciaCierreViaDTO {
    id?: number,
    nombre?: string,
}

export interface CrrMaTipoAfectacionCierreViaDTO {
    id?: number,
    nombre?: string,
}
export interface CrrMaTipoAfectacionCierreViaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaCrrMaTipoAfectacionCierreVia?: CrrMaTipoAfectacionCierreViaDTO[]
}

export interface CrrTipoAfectacionViaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaCrrTipoAfectacionVia?: CrrTipoAfectacionViaDTO[],
    crrTipoAfectacionVia?: CrrTipoAfectacionViaDTO,
    id?: number,
}
export interface CrrTipoAfectacionViaDTO {
    id?: number,
    idViaAfectada?: number,
    idTipo?: number,
    tipo?: string,
    tipoAfectacionDTO?: CrrMaTipoAfectacionCierreViaDTO,
    idPeaje?: number,
    peaje?: string,
    peajeDTO?: PjePeajePorProyecto,
    tipoUbicacion?: string,
    tipoUbicacionDTO?: CrrMaPrKDTO,
    puntoInicial?: number,
    distanciaAlPuntoInicial?: number,
    puntoFinal?: number,
    distanciaAlPuntoFinal?: number,
    longitud?: number,
    latitud?: number,
    observaciones?: string
}

export enum EnumCrrMaTipoAfectacion {
    PJE_TIPO_AFECTACION_PUENTE_PEATONAL = 1,
    PJE_TIPO_AFECTACION_PUENTE_VEHICULAR = 2,
    PJE_TIPO_AFECTACION_MURO_CONTENCION = 3,
    PJE_TIPO_AFECTACION_PEAJE = 4,
}

export interface CrrViaAfectadaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaCrrOlaInvernal?: CrrViaAfectadaDTO[],
    crrViaAfectada?: CrrViaAfectadaDTO,
    id?: number,
}
export interface CrrViaAfectadaDTO {
    id?: number,
    idUnidadFuncional?: number,
    unidadFuncional?: string,
    unidadFuncionalDTO?: UnidadFuncional,
    idMunicipio?: string,
    municipio?: string,
    idDepartamento?: string,
    departamento?: string,
    idTipoCierreVia?: number,
    tipoCierreVia?: string,
    tipoCierreViaDTO?: CrrMaTipoCierreViaDTO,
    idClasificacionContingencia?: number,
    clasificacionContingencia?: string,
    clasificacionContingenciaDTO?: CrrMaClasificacionContingenciaCierreViaDTO,
    fechaReporte?: Date,
    codigoRuta?: string,
    tipoUbicacion?: string,
    tipoUbicacionDTO?: CrrMaPrKDTO,
    puntoInicial?: number,
    distanciaAlPuntoInicial?: number,
    puntoFinal?: number,
    distanciaAlPuntoFinal?: number,
    longitudInicial?: number,
    latitudInicial?: number,
    longitudFinal?: number,
    latitudFinal?: number,
    sentidoCirculacion?: string,
    motivoEvento?: string,
    planAccion?: string,
    rutaAlterna?: string,
    fechaCierre?: Date
    fechaEstimadaApertura?: Date
    observacionesApertura?: string,
    fechaRealApertura?: Date,
    personalOperativo?: number,
    maquinariaAmarillaDisponible?: number,
    idProyecto?: number,
    nombreProyecto?: string,
    unidadesEventosGrandesMagnitudes?: number,
    rutaImagen1?: string,
    rutaImagen2?: string,
    totalRegistros?: number,
}

export interface CrrImagenViaAfectadaRpt {
    respuestaServicio?: TrnRespuestaServicio,
    listaCrrImagenViaAfectada?: CrrImagenViaAfectadaDTO[],
    crrImagenViaAfectada?: CrrImagenViaAfectadaDTO,
}

export interface CrrImagenViaAfectadaDTO {
    id?: number,
    idViaAfectada?: number,
    fechaFotografia?: Date,
    tituloFotografia?: string,
    descripcionFotografia?: string,
    ruta?: string,
}

export interface CrrViaAfectadaReporteDTO {
    fechaInicioReporte?: Date,
    fechaFinReporte?: Date,
    idProyecto?: number,
    idTipoCierre?: number,
    filaDesde?: number,
    buscar?: string,
    cantidadRegistros?: number
    campoOrden?: string,
    ascDesc?: string
}

export interface CrrGeneracionRpt {
    respuestaServicio?: TrnRespuestaServicio,
    datos?: OlaInvernalDatosDTO
}

export interface OlaInvernalDatosDTO {
    archivo?: string,
    archivoUpload?: string,
    nombreArchivo?: string,
    rutaArchivo?: string,
    fechaCreacion?: string
}