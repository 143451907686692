import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContratoBaseComponent } from '../contrato-base/contrato-base.component';
import { MessageService } from 'primeng/api';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { EnumTrnDependenciaAni, TrnDependenciaAni } from 'src/app/parametricas/trn-dependencia-ani';
import { NgForm } from '@angular/forms';
import { PrsFuncionario } from 'src/app/admin-personas/prs-funcionario';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { ContratacionBorradorService } from '../../contratacion-brr.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumCtoOrigenRecurso } from 'src/app/contratacion/constantes/contratacion-constantes';
import { EnumRegex } from 'src/app/comun/constantes/constantes-comunes';
import { TrnSiNo } from 'src/app/parametricas/trn-si-no';
import { CtoContratoDTO } from '../../../../contratacion-dto/contrato';
import { CtoOrigenRecursoDTO } from '../../../../contratacion-dto/cto-origen-recurso';
import { CtoImputacionRecursoDTO } from '../../../../contratacion-dto/cto-imputacion-recurso';
import { CtoTipoRecursoDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-recurso';
import { CtoTipoGarantiaDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-garantia';
import { CtoAmparosGarantiaDTO } from 'src/app/contratacion/contratacion-dto/cto-amparos-garantia';
import { CtoRegimenContratacionDTO } from '../../../../contratacion-dto/cto-regimen-contratacion';
import { CtoModalidadSeleccionDTO } from '../../../../contratacion-dto/cto-modalidad-seleccion';
import { CtoTipoContratoDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-contrato';

@Component({
  selector: 'app-contrato-detalle',
  templateUrl: './contrato-detalle.component.html'
})
export class ContratoDetalleComponent implements OnInit {

  @Input()
  contratoDetalle: CtoContratoDTO;

  @Output()
  contratoDetalleOutput = new EventEmitter<CtoContratoDTO>();

  // Informacion de las listas parametricas
  ctoOrigenRecursos: CtoOrigenRecursoDTO[];
  ctoImputacionRecursos: CtoImputacionRecursoDTO[];
  ctoImputacionRecursosFilter: CtoImputacionRecursoDTO[];
  ctoTiposRecurso: CtoTipoRecursoDTO[];
  trnDependenciasAni: TrnDependenciaAni[];
  ctoTiposGarantia: CtoTipoGarantiaDTO[];
  ctoAmparosGarantia: CtoAmparosGarantiaDTO[];
  ctoRegimenesContratacion: CtoRegimenContratacionDTO[];
  ctoModalidadesSeleccion: CtoModalidadSeleccionDTO[];
  ctoTiposContrato: CtoTipoContratoDTO[];
  personasResponsableContrato: any = [];
  funcionariosOrdenadoresGasto: PrsFuncionario[] = [];
  valoresListaEstaObligadoFacturar = [0, 1, 2];

  mostrarImputacionRecurso = false;
  mostrarTipoRecurso = false;
  mostrarInformacionPresupuestal = false;

  constructor(
    private lineaBase: ContratoBaseComponent,
    private messageService: MessageService,
    private contratacionService: ContratacionService,
    private contratacionBorradorService: ContratacionBorradorService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    if (this.contratoDetalle != null) {
      this.cargarListasParametricas();
    }
  }

  /**
   * Metodo que consulta la información parametrica de los tipos de cierre de ola invernal
   * @param event Evento que buscar dentro del listado 
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  /**
   * Metodo que consulta la información parametrica de los selectores para asociar al contrato
   */
  cargarListasParametricas() {
    this.contratacionService.getCtoTipoContrato()
      .subscribe(data => {
        this.ctoTiposContrato = data;
        if (this.contratoDetalle.idTipoContrato != null) {
          this.contratoDetalle.idTipoContratoObject = this.ctoTiposContrato.find(t => Number(t.id) === this.contratoDetalle.idTipoContrato);
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );

    this.contratacionService.getPrsFuncionariosOrdenadoresGasto()
      .subscribe(data => {
        this.funcionariosOrdenadoresGasto = data;
        if (this.contratoDetalle.idPersonaOrdenador != null) {
          this.contratoDetalle.idPersonaOrdenadorObject = this.funcionariosOrdenadoresGasto.find(t => t.idPersona === this.contratoDetalle.idPersonaOrdenador);
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );

    this.contratacionService.getCtoImputacionRecurso()
      .subscribe(data => {
        this.mostrarTipoRecurso = false;
        this.ctoImputacionRecursos = data;
        this.ctoImputacionRecursosFilter = data;
        if (this.contratoDetalle.idImputacionRecurso != null) {
          this.contratoDetalle.idImputacionRecursoObject = this.ctoImputacionRecursos.find(t => Number(t.id) === this.contratoDetalle.idImputacionRecurso);

          if (this.contratoDetalle.idImputacionRecursoObject != null && this.contratoDetalle.idImputacionRecursoObject.idOrigenRecurso != null) {
            switch (this.contratoDetalle.idImputacionRecursoObject.idOrigenRecurso) {
              case EnumCtoOrigenRecurso.ANI: // Ani
                this.mostrarInformacionPresupuestal = true;
                this.mostrarTipoRecurso = true;
                break;
              case EnumCtoOrigenRecurso.PATRIMONIO_AUTONOMO: // Patrimonio autónomo
                this.mostrarInformacionPresupuestal = false;
                break;
              case EnumCtoOrigenRecurso.MIXTO: // Mixto
                this.mostrarInformacionPresupuestal = true;
                this.mostrarTipoRecurso = true;
                break;
            }
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );

    this.contratacionService.getCtoTipoRecurso()
      .subscribe(data => {
        this.ctoTiposRecurso = data;
        if (this.contratoDetalle.idTipoRecurso != null) {
          this.contratoDetalle.idTipoRecursoObject = this.ctoTiposRecurso.find(t => Number(t.id) === this.contratoDetalle.idTipoRecurso);
          this.mostrarTipoRecurso = true;
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );

    this.parametricasService.ConsultarDependenciasAniPorTipo(EnumTrnDependenciaAni.TRN_DEPENDENCIA_ANI_PRESIDENCIA_TIPO + ',' + EnumTrnDependenciaAni.TRN_DEPENDENCIA_ANI_VICEPRESIDENCIA_TIPO)
      .subscribe(data => {
        this.trnDependenciasAni = data;
        if (this.contratoDetalle.idDependenciaRecursos != null) {
          this.contratoDetalle.idDependenciaRecursosObject = this.trnDependenciasAni.find(t => t.id === this.contratoDetalle.idDependenciaRecursos);
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );

    this.contratacionService.getCtoRegimenContratacion()
      .subscribe(data => {
        this.ctoRegimenesContratacion = data;
        if (this.contratoDetalle.idRegimenContratacion != null) {
          this.contratoDetalle.idRegimenContratacionObject = this.ctoRegimenesContratacion.find(t => Number(t.id) === this.contratoDetalle.idRegimenContratacion);
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );

    this.contratacionService.getCtoModalidadSeleccion()
      .subscribe(data => {
        this.ctoModalidadesSeleccion = data;
        if (this.contratoDetalle.idModalidadSeleccion != null) {
          this.contratoDetalle.idModalidadSeleccionObject = this.ctoModalidadesSeleccion.find(t => Number(t.id) === this.contratoDetalle.idModalidadSeleccion);
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  cargarTiposContratos(event) {
    const filtroCtoTiposContrato = [];
    let existeFiltro: boolean;
    for (const tipoCto of this.ctoTiposContrato) {
      if (String(tipoCto.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroCtoTiposContrato.push(tipoCto);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.ctoTiposContrato = filtroCtoTiposContrato;
    }
    return this.ctoTiposContrato;
  }

  cargarUsuariosOrdenadores(event) {
    const filtroCtoPersonaOdenadorContrato = [];
    let existeFiltro: boolean;
    for (const funOrdGasto of this.funcionariosOrdenadoresGasto) {
      if (String(funOrdGasto.correoElectronico).toLowerCase().includes(event.query.toLowerCase()) || String(funOrdGasto.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroCtoPersonaOdenadorContrato.push(funOrdGasto);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.funcionariosOrdenadoresGasto = filtroCtoPersonaOdenadorContrato;
    }
    return this.funcionariosOrdenadoresGasto;
  }

  cargarDependenciasAni(event, contrato) {
    const filtroTrnDependenciasAni = [];
    let existeFiltro: boolean;
    for (const trnDepAni of this.trnDependenciasAni) {
      if (String(trnDepAni.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroTrnDependenciasAni.push(trnDepAni);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.trnDependenciasAni = filtroTrnDependenciasAni;
    }
    return this.trnDependenciasAni;
  }

  cargarTiposRecurso(event) {
    const filtroTiposRecurso = [];
    let existeFiltro: boolean;
    for (const tipRecurso of this.ctoTiposRecurso) {
      if (String(tipRecurso.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroTiposRecurso.push(tipRecurso);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.ctoTiposRecurso = filtroTiposRecurso;
    }
    return this.ctoTiposRecurso;
  }

  cargarOrigenRecursos(event) {
    const filtroOrigenRecursos = [];
    let existeFiltro: boolean;
    for (const orgRecurso of this.ctoOrigenRecursos) {
      if (String(orgRecurso.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroOrigenRecursos.push(orgRecurso);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.ctoOrigenRecursos = filtroOrigenRecursos;
    }
    return this.ctoOrigenRecursos;
  }

  cargarImputacionRecursoPorOrigen(event) {

    this.validarInformacionPresupuestal();
  }

  cargarImputacionRecursos(event) {
    const filtroImputacionRecurso = [];
    let existeFiltro: boolean;
    for (const imputacionRec of this.ctoImputacionRecursos) {
      if (String(imputacionRec.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroImputacionRecurso.push(imputacionRec);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.ctoImputacionRecursos = filtroImputacionRecurso;
    }
    return this.ctoImputacionRecursos;
  }

  cargarRegimenesContratacion(event) {
    const filtroRegimenesContratacion = [];
    let existeFiltro: boolean;
    for (const regContratacion of this.ctoRegimenesContratacion) {
      if (String(regContratacion.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroRegimenesContratacion.push(regContratacion);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.ctoRegimenesContratacion = filtroRegimenesContratacion;
    }
    return this.ctoRegimenesContratacion;
  }

  cargarModalidadesSeleccion(event) {
    const filtroModalidadSeleccion = [];
    let existeFiltro: boolean;
    for (const modSeleccion of this.ctoModalidadesSeleccion) {
      if (String(modSeleccion.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        filtroModalidadSeleccion.push(modSeleccion);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.ctoModalidadesSeleccion = filtroModalidadSeleccion;
    }
    return this.ctoModalidadesSeleccion;
  }

  /**
   * Metodo de evento de seleción para determinar si un valor es nulo, verdadero o falso, y se asigna a la variable EsResponsableIva.
   * Se agrega validación cuando la variable esResponsableIva es verdadera, para cambiar el estado a null de la variable estaObligadoFacturar 
   * @param sinoSeleccion Objeto de tipo TrnSiNo
   */
  onSelectEsResponsableIva(sinoSeleccion: TrnSiNo) {
    this.contratoDetalle.esResponsableIva = sinoSeleccion.valor;
    if (this.contratoDetalle.esResponsableIva) {
      this.contratoDetalle.estaObligadoFacturar = null;
    }
  }

  /**
   * Metodo de evento de seleción para determinar si un valor es nulo, verdadero o falso, y se asigna a la variable EstaObligadoFacturar.
   * @param sinoSeleccion Objeto de tipo TrnSiNo
   */
  onSelectEstaObligadoFacturar(sinoSeleccion: TrnSiNo) {
    this.contratoDetalle.estaObligadoFacturar = sinoSeleccion.valor;
  }


  validarEsTiempoEjecucionContratoEnFecha(sinoSeleccion) {
    this.contratoDetalle.esTiempoEjecucionContratoEnFecha = sinoSeleccion.valor;
    if (this.contratoDetalle.esTiempoEjecucionContratoEnFecha) {
      this.contratoDetalle.plazoDias = null;
      this.contratoDetalle.plazoMeses = null;
      this.contratoDetalle.plazoAnos = null;
    } else {
      this.contratoDetalle.fechaTerminacionContrato = null;
    }
  }

  validarInformacionPresupuestal() {
    if (this.contratoDetalle.idImputacionRecursoObject != null
      && this.contratoDetalle.idImputacionRecursoObject.idOrigenRecurso != null) {
      switch (this.contratoDetalle.idImputacionRecursoObject.idOrigenRecurso) {
        case EnumCtoOrigenRecurso.ANI: // Ani
          this.mostrarInformacionPresupuestal = true;
          this.mostrarTipoRecurso = true;
          break;
        case EnumCtoOrigenRecurso.PATRIMONIO_AUTONOMO: // Patrimonio autónomo
          this.mostrarInformacionPresupuestal = false;

          this.contratoDetalle.numeroCdp = null;
          this.contratoDetalle.numeroRp = null;
          this.contratoDetalle.fechaRp = null;
          this.contratoDetalle.fechaCdp = null;
          this.mostrarTipoRecurso = false;
          this.contratoDetalle.idTipoRecursoObject = null;
          this.contratoDetalle.idTipoRecurso = null;
          this.contratoDetalle.tipoRecurso = null;
          break;
        case EnumCtoOrigenRecurso.MIXTO: // Mixto
          this.mostrarInformacionPresupuestal = true;
          this.mostrarTipoRecurso = true;
          break;
      }
    } else {
      this.mostrarInformacionPresupuestal = false;
      this.mostrarTipoRecurso = false;
    }
  }

  guardarContrato(form: NgForm) {
    this.contratoDetalleOutput.emit(this.contratoDetalle);
  }

  get EnumRegex() {
    return EnumRegex;
  }
}
