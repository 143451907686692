import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { forkJoin, Subscription } from "rxjs";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { PrediosService } from "../predios.service";

@Component({
  selector: 'app-carga-masiva-predios',
  templateUrl: './carga-masiva-predios.component.html',
  styleUrls: ['./carga-masiva-predios.component.sass']
})
export class CargaMasivaPrediosComponent implements OnInit {

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  rutaIframe: string;

  constructor(
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    // Obtener parametros
    const idParam = "id";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
    });

    this.inicializar();
  }

  inicializar() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
        console.log("proyectoSeleccionado", this.proyectoSeleccionado);
        this.rutaIframe =
          "https://sig.ani.gov.co/masivo/?IdPry=" +
          this.idProyecto;
        console.log("rutaIframe", this.rutaIframe);
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }
}