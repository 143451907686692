import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  InvMaTipoBien,
  InvModeloDTO,
  InvMaMarca,
  InvMaSistemaOperativo,
} from '../inventario-equipos.dto';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InventarioEquiposService } from 'src/app/inventario-equipos/inventario-equipos.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-registrar-modelo',
  templateUrl: './registrar-modelo.component.html',
  styleUrls: ['./registrar-modelo.component.sass'],
})
export class RegistrarModeloComponent implements OnInit {
  blockedDocument: boolean;

  form;

  modelo: InvModeloDTO = {};
  modeloList: InvModeloDTO[];
  tipoBienList: InvMaTipoBien[];
  marcaList: InvMaMarca[];
  sistemaOperativoList: InvMaSistemaOperativo[];
  renderEsPcOPortatil = false;

  displayModal: boolean;
  displayModal2: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private inventarioEquiposService: InventarioEquiposService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
    // Crear formulario
    this.form = formBuilder.group({
      idTipoBien: [null, [Validators.required]],
      nombre: [null, [Validators.required, Validators.maxLength(20)]],
      idMarca: [null, [Validators.required]],
      ram: [null, [Validators.maxLength(2), Validators.pattern('^[0-9]*$')]],
      disco: [null, [Validators.maxLength(5), Validators.pattern('^[0-9]*$')]],
      discoSsd: [
        null,
        [Validators.maxLength(4), Validators.pattern('^[0-9]*$')],
      ],
      idSistemaOperativo: [null, []],
      idMarcaProcesador: [null, []],
      referenciaProcesador: [null, [Validators.maxLength(20)]],
      caracteristicasAdicionales: [null, [Validators.maxLength(1000)]],
      fechaIngreso: [null, [Validators.required]],
      cantidad: [
        null,
        [
          Validators.required,
          Validators.maxLength(5),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
    });
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    // Inicializar listas parametricas
    forkJoin(
      this.inventarioEquiposService.srvListarTipoBien(),
      this.inventarioEquiposService.srvListarMarca(),
      this.inventarioEquiposService.srvListarSistemaOperativo()
    ).subscribe(
      ([tipoBienListData, marcaListData, sistemaOperativoListData]) => {
        this.tipoBienList = tipoBienListData.tiposBien;
        this.marcaList = marcaListData.marcas;
        this.sistemaOperativoList = sistemaOperativoListData.sistemasOperativos;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    // Listar los modelos guardados
    this.listarModelos();
  }

  /**
   * Se llama cada vez que se selecciona un registro del selector 'Tipo Bien'
   */
  onChangeTipoBien(event) {
    const tipoBienControl = this.form.get('idTipoBien');
    const ramControl = this.form.get('ram');
    const discoControl = this.form.get('disco');
    const discoSsdControl = this.form.get('discoSsd');
    const sistemaOperativoControl = this.form.get('idSistemaOperativo');
    const marcaProcesadorControl = this.form.get('idMarcaProcesador');
    const referenciaProcesadorControl = this.form.get('referenciaProcesador');

    if (tipoBienControl.value != null) {
      if (tipoBienControl.value.id === 1 || tipoBienControl.value.id === 2) {
        ramControl.setValidators([
          Validators.required,
          Validators.maxLength(2),
          Validators.pattern('^[0-9]*$'),
        ]);
        discoControl.setValidators([
          Validators.required,
          Validators.maxLength(5),
          Validators.pattern('^[0-9]*$'),
        ]);
        discoSsdControl.setValidators([
          Validators.required,
          Validators.maxLength(4),
          Validators.pattern('^[0-9]*$'),
        ]);
        sistemaOperativoControl.setValidators([Validators.required]);
        marcaProcesadorControl.setValidators([Validators.required]);
        referenciaProcesadorControl.setValidators([
          Validators.required,
          Validators.maxLength(20),
        ]);
        this.renderEsPcOPortatil = true;
      } else {
        console.log('quitar los validators');
        ramControl.setValidators(null);
        discoControl.setValidators(null);
        discoSsdControl.setValidators(null);
        sistemaOperativoControl.setValidators(null);
        marcaProcesadorControl.setValidators(null);
        referenciaProcesadorControl.setValidators(null);
        this.renderEsPcOPortatil = false;
      }
    } else {
      this.renderEsPcOPortatil = false;
    }

    ramControl.updateValueAndValidity();
    discoControl.updateValueAndValidity();
    discoSsdControl.updateValueAndValidity();
    sistemaOperativoControl.updateValueAndValidity();
    marcaProcesadorControl.updateValueAndValidity();
    referenciaProcesadorControl.updateValueAndValidity();
  }

  /**
   * Abre el dialogo para agregar modelo
   */
  agregarModelo() {
    this.modelo = {};
    // Simular que elegi un item del dropdown TipoBien
    this.form.get('idTipoBien').setValue(this.modelo.idTipoBienDTO);
    this.onChangeTipoBien(null);
    this.showModalDialog();
  }

  showModalDialog() {
    this.displayModal = true;
  }

  /**
   * Inicializa el listado de modelos registrados
   */
  listarModelos() {
    forkJoin(this.inventarioEquiposService.srvListarModelo()).subscribe(
      ([modeloListData]) => {
        this.modeloList = modeloListData.modelos;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**
   * Precarga los datos del modelo seleccionado y abre el dialogo para editarlo
   */
  editarModelo(modeloL: InvModeloDTO) {
    this.modelo = modeloL;
    this.modelo.fechaIngreso = new Date(this.modelo.fechaIngreso);
    // Cargar el objeto del dropdown TipoBien
    this.modelo.idTipoBienDTO = this.tipoBienList.find(
      (t) => t.id === this.modelo.idTipoBien
    );
    // Cargar el objeto del dropdown Marca
    this.modelo.idMarcaDTO = this.marcaList.find(
      (t) => t.id === this.modelo.idMarca
    );
    // Simular que elegi un item del dropdown TipoBien
    this.form.get('idTipoBien').setValue(this.modelo.idTipoBienDTO);
    this.onChangeTipoBien(null);

    // Cargar el objeto del dropdown Sistema Operativo
    this.modelo.idSistemaOperativoDTO = this.sistemaOperativoList.find(
      (t) => t.id === this.modelo.idSistemaOperativo
    );
    // Cargar el objeto del dropdown Marca Procesador
    this.modelo.idMarcaProcesadorDTO = this.marcaList.find(
      (t) => t.id === this.modelo.idMarcaProcesador
    );
    // Mostrar el dialogo
    this.showModalDialog();
  }

  /**
   * Precarga el modelo seleccionado y abre el dialogo para editarlo
   */
  eliminarModelo(modeloL: InvModeloDTO) {
    this.modelo = modeloL;
    this.modelo.fechaIngreso = new Date(this.modelo.fechaIngreso);
    // this.lanzarMensajeInfo('Eliminar el modelo ' + this.modelo.nombre);
    this.displayModal2 = true;
  }

  /**
   * Guarda el modelo
   */
  submit() {
    if (this.form.valid) {
      // Completar el DTO a guardar
      if (this.modelo.idTipoBienDTO != null) {
        this.modelo.idTipoBien = this.modelo.idTipoBienDTO.id;
        if (this.modelo.idTipoBien === 1 || this.modelo.idTipoBien === 2) {
          if (this.modelo.disco == null) {
            this.modelo.disco = 0;
          }
          if (this.modelo.discoSsd == null) {
            this.modelo.discoSsd = 0;
          }
        }
      }
      if (this.modelo.idMarcaDTO != null) {
        this.modelo.idMarca = this.modelo.idMarcaDTO.id;
      }
      if (this.modelo.idMarcaProcesadorDTO != null) {
        this.modelo.idMarcaProcesador = this.modelo.idMarcaProcesadorDTO.id;
      }
      if (this.modelo.idSistemaOperativoDTO != null) {
        this.modelo.idSistemaOperativo = this.modelo.idSistemaOperativoDTO.id;
      }

      // Guardar el DTO
      forkJoin(
        this.inventarioEquiposService.srvGuardarModelo(this.modelo)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            // Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              // Consultar nuevamente la lista
              this.listarModelos();
              // Lanzar mensaje de exito
              this.messageService.add({
                severity: 'info',
                summary: 'Información',
                detail: result.respuestaServicio.mensajeSalida,
                life: 10000,
              });
              // Limpiar Formulario
              this.form.reset();
              this.form.markAsPristine();
              this.form.markAsUntouched();
              // Cerrar el dialogo
              this.displayModal = false;
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            // console.log('Ocurrio un error al consumir el servicio srvGuardarModelo');
          }
        }, error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos');
    }
  }

  /**
   * Cierra el dialogo de eliminar modelo
   */
  cancelarEliminarModelo() {
    this.displayModal2 = false;
  }

  /**
   * Elimina el modelo
   */
  aceptarEliminarModelo() {
    // Guardar el DTO
    forkJoin(
      this.inventarioEquiposService.srvEliminarModelo(this.modelo)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // Consultar nuevamente la lista
            this.listarModelos();
            // Lanzar mensaje de exito
            this.messageService.add({
              severity: 'info',
              summary: 'Información',
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
            // Limpiar Formulario
            this.form.reset();
            this.form.markAsPristine();
            this.form.markAsUntouched();
            // Cerrar el dialogo
            this.displayModal2 = false;
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // console.log('Ocurrio un error al consumir el servicio srvEliminarModelo');
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  /////////////////////////////// Mensajes//////////////////////////////
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
