import { Component, OnInit } from '@angular/core';
import { MessageService } from "primeng/api";
import * as Highcharts from "highcharts/highstock";
import { ReportesFinancierosService } from '../../reportes-financiero-service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { FncDatosGeneralesFinancierosDTO, FncVigenciasFuturasDTO, CntContratoPuenteDTO, FncDatosGeneralesFinancierosVIxDTO, FncSaldoCuentaDTO, FncRetribucionDTO, FncVpipDTO, FncValoresIndexadosDatosGeneralesDTO, CntValoresIndexadosContratoCreditoDTO, FncValoresIndexadosVigenciasFuturasDTO, FncSaldoCuentaCSVDTO, FncRetribucionCSVDTO } from '../../reportes-financiero.dto';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ConsultaInformacionGeneralProyectoRpt, InformacionGeneralProyectoService } from 'src/app/informacion-general-carretero/informacion-general-proyecto/informacion-general-proyecto.service';
import { ReportesCarreteroService } from '../../reportes-carreteros.service';
import { InformacionGeneral } from 'src/app/video/youtube-playlist.component/proyecto-carretero';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import colorchart from "src/assets/img/coloreschart";

@Component({
  selector: 'app-rpt-crr-proyecto-financiero',
  templateUrl: './rpt-crr-proyecto-financiero.component.html',
  styleUrls: ['./rpt-crr-proyecto-financiero.component.sass']
})
export class RptCrrProyectoFinancieroComponent implements OnInit {

  optionschart8: any;
  optionschart9: any;
  optionschartVpip: any;
  idProyecto: number;
  proyecto: any;
  datosGeneralesFinancieros: FncDatosGeneralesFinancierosDTO;
  vigenciasFuturas: FncVigenciasFuturasDTO;
  contratoPuente: CntContratoPuenteDTO;
  datosGeneralesFinancierosVIx: FncDatosGeneralesFinancierosVIxDTO;
  valoresIndexadosDatosGenerales: FncValoresIndexadosDatosGeneralesDTO;
  valoresIndexadosContratoCredito: CntValoresIndexadosContratoCreditoDTO;
  valoresIndexadosVigenciasFuturas: FncValoresIndexadosVigenciasFuturasDTO;
  saldosCuenta: FncSaldoCuentaDTO[] = [];
  retribucion: FncRetribucionDTO[] = [];
  vpip: FncVpipDTO[] = [];
  vpipContractual: FncVpipDTO[] = [];
  vpipVpip8: any[] = [];
  vpipVpip13: any[] = [];
  vpipVpip18: any[] = [];
  vpipDetallado: FncVpipDTO[] = [];
  valoresVpip: any;
  private reporteSaldosFinancieros: any = [];
  private reporteRetribucion: any = [];
  private reporteVpip: any = [];
  fechas?: any[] = [];
  fechaReferencia?: any = null;
  tieneDatosVpip: boolean;


  constructor(private reportesFinancierosService: ReportesFinancierosService, private informacionGeneralProyectoService: ReportesCarreteroService,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    console.log("financiero");
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params.id;
      this.cargarDatosGraficas();
    });
    /*  this.contratoPuente = {};
     this.obtenerRetribucion();
     this.obtenerVpip();
     this.obtenerVpipDetallado(); */
    this.init();
  }

  cargarDatosGraficas() {
    this.cargarInformacionGeneral(this.idProyecto);
    this.contratoPuente = {};
    this.obtenerRetribucion();
    this.obtenerVpip();
    this.obtenerVpipDetallado();
  }



  cargarInformacionGeneral(idProyecto: number) {
    forkJoin(
      this.informacionGeneralProyectoService.obtenerInformacionGeneral(idProyecto)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          console.log(result);
          this.proyecto = result.informacionGeneralProyecto;
          console.log(this.proyecto.nombre);
          this.obtenerDatosGeneralesFinancieros();
          this.obtenerVigenciasFuturas();
          this.obtenerContratoPuente();
          this.obtenerSaldosCuenta();
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla

      }
    );

  }

  obtenerDatosGeneralesFinancieros() {
    forkJoin(
      this.reportesFinancierosService.getDatosGeneralesFinancieros(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }
        console.log("DATOS FINANCIEROS!!!!");
        console.log(data);
        this.datosGeneralesFinancieros = data.datosGeneralesFinanciero;
        this.fechas = data.datosGeneralesFinanciero.fechasReferencia;
        this.fechaReferencia = this.fechas[0];
        let filtroValoresIndexadosDatosGenerales = this.datosGeneralesFinancieros.valoresIndexadosDatosGenerales.filter((item) => item.fecha == this.fechaReferencia.fecha);
        this.valoresIndexadosDatosGenerales = filtroValoresIndexadosDatosGenerales[0];
        let filtroValoresIndexadosContratoCredito = this.datosGeneralesFinancieros.valoresContratoCredito.filter((item) => item.fecha == this.fechaReferencia.fecha);
        this.valoresIndexadosContratoCredito = filtroValoresIndexadosContratoCredito[0];
        let filtroValoresIndexadosVigenciasFuturas = this.datosGeneralesFinancieros.valoresIndexadosVigenciasFuturas.filter((item) => item.fecha == this.fechaReferencia.fecha);
        this.valoresIndexadosVigenciasFuturas = filtroValoresIndexadosVigenciasFuturas[0];

        //this.obtenerDatosGeneralesFinancierosVIx();
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {

      }
    );
  }

  calcularValoresFinancieros() {
    let filtroValoresIndexadosDatosGenerales = this.datosGeneralesFinancieros.valoresIndexadosDatosGenerales.filter((item) => item.fecha == this.fechaReferencia.fecha);
    this.valoresIndexadosDatosGenerales = filtroValoresIndexadosDatosGenerales[0];
    let filtroValoresIndexadosContratoCredito = this.datosGeneralesFinancieros.valoresContratoCredito.filter((item) => item.fecha == this.fechaReferencia.fecha);
    this.valoresIndexadosContratoCredito = filtroValoresIndexadosContratoCredito[0];
    let filtroValoresIndexadosVigenciasFuturas = this.datosGeneralesFinancieros.valoresIndexadosVigenciasFuturas.filter((item) => item.fecha == this.fechaReferencia.fecha);
    this.valoresIndexadosVigenciasFuturas = filtroValoresIndexadosVigenciasFuturas[0];
  }

  obtenerVigenciasFuturas() {
    forkJoin(
      this.reportesFinancierosService.getVigenciasFuturas(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }

        console.log(data);
        this.vigenciasFuturas = data.vigenciasFuturas;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {

      }
    );
  }

  obtenerContratoPuente() {
    forkJoin(
      this.reportesFinancierosService.getContratoPuente(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }

        console.log("contrato puente");
        console.log(data);
        this.contratoPuente = data.contratoPuente;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {

      }
    );
  }

  obtenerDatosGeneralesFinancierosVIx() {
    forkJoin(
      this.reportesFinancierosService.getDatosGeneralesFinancierosVIx(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }

        console.log(data);
        this.datosGeneralesFinancierosVIx = data.datosGeneralesFinancierosVIx;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.cargarGraficas();
      }
    );
  }

  obtenerSaldosCuenta() {
    forkJoin(
      this.reportesFinancierosService.getSaldoCuenta(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }

        console.log(data);
        this.saldosCuenta = data.saldosCuenta;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {

      }
    );
  }

  exportCsvSaldosCuenta() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: ["Proyecto", "Fecha", "Origen", "Valor Pesos COP", "Valor Dollares USD"]
    };

    let _saldosCuenta: FncSaldoCuentaCSVDTO[] = [];

    this.saldosCuenta.forEach(element => {
      let _saldoCuenta = new FncSaldoCuentaCSVDTO();
      _saldoCuenta.proyecto = element.proyecto;
      _saldoCuenta.fecha = element.fecha;
      _saldoCuenta.origen = element.origen;
      _saldoCuenta.valorPesos = element.valorPesos;
      _saldoCuenta.valorDolares = element.valorDolares;
      _saldosCuenta.push(_saldoCuenta);
    });
    new ngxCsv(_saldosCuenta, 'SaldosFinancieros' + '_export_' + new Date().getTime(), options);
  }

  obtenerRetribucion() {
    forkJoin(
      this.reportesFinancierosService.getRetribucion(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }

        console.log(data);
        this.retribucion = data.retribucion;



      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {

      }
    );
  }

  exportCsvRetribucion() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: ["Proyecto", "Unidad Funcional", "Fecha Retribucion", "Valor Explotacion Comercial", "% Explotacion Comercial", "Valor Peajes", "% Peajes", "Valor vigencias futuras COP", "Valor vigencias futuras US", "% Vigencias futuras"]
    };
    let _retribuciones: FncRetribucionCSVDTO[] = [];

    this.retribucion.forEach(element => {
      let _retribucion = new FncRetribucionCSVDTO();
      _retribucion.proyecto = element.proyecto;
      _retribucion.unidadFuncional = element.unidadFuncional;
      _retribucion.fechaRetribucion = element.fechaRetribucion;
      _retribucion.valorExplotacionComercial = element.valorExplotacionComercial != null ? element.valorExplotacionComercial : 'Sin información';
      _retribucion.porcentajeExplotacionComercial = element.porcentajeExplotacionComercial != null ? element.porcentajeExplotacionComercial : 'Sin información';
      _retribucion.valorPeajes = element.valorPeajes != null ? element.valorPeajes : 'Sin información';
      _retribucion.porcentajePeajes = element.porcentajePeajes != null ? element.porcentajePeajes : 'Sin información';
      _retribucion.valorPesosVigenciasFuturas = element.valorPesosVigenciasFuturas != null ? element.valorPesosVigenciasFuturas : 'Sin información';
      _retribucion.valorDolaresVigenciasFuturas = element.valorDolaresVigenciasFuturas != null ? element.valorDolaresVigenciasFuturas : 'Sin información';
      _retribucion.porcentajeVigenciasFuturas = element.porcentajeVigenciasFuturas != null ? element.porcentajeVigenciasFuturas : 'Sin información';
      _retribuciones.push(_retribucion);
    });
    new ngxCsv(_retribuciones, 'Retribucion' + '_export_' + new Date().getTime(), options);
  }

  obtenerVpip() {
    forkJoin(
      this.reportesFinancierosService.getVpip(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }
        this.tieneDatosVpip = true;
        console.log(data);
        this.vpip = data.vpip;

        if (this.vpip.length < 4) {
          //this.tieneDatosVpip = false;
          if (this.vpip.length >= 1) {
            this.vpipContractual = this.vpip[0].valorContractualList;
          }

          if (this.vpip.length >= 2) {
            this.vpip[1].valorVpip8List.forEach(element => {
              if (element != null) {
                this.vpipVpip8.push(element.valorContractual);
              } else {
                this.vpipVpip8.push(null);
              }
            });
          }

          if (this.vpip.length >= 3) {
            this.vpip[2].valorVpip13List.forEach(element => {
              if (element != null) {
                this.vpipVpip13.push(element.valorContractual);
              } else {
                this.vpipVpip13.push(null);
              }
            });
          }

          if (this.vpip.length >= 4) {
            this.vpip[3].valorVpip18List.forEach(element => {
              if (element != null) {
                this.vpipVpip18.push(element.valorContractual);
              } else {
                this.vpipVpip18.push(null);
              }
            });
          }
        } else {
          this.vpipContractual = this.vpip[0].valorContractualList;
          this.vpip[1].valorVpip8List.forEach(element => {
            if (element != null) {
              this.vpipVpip8.push(element.valorContractual);
            } else {
              this.vpipVpip8.push(null);
            }
          });




          this.vpip[2].valorVpip13List.forEach(element => {
            if (element != null) {
              this.vpipVpip13.push(element.valorContractual);
            } else {
              this.vpipVpip13.push(null);
            }
          });

          this.vpip[3].valorVpip18List.forEach(element => {
            if (element != null) {
              this.vpipVpip18.push(element.valorContractual);
            } else {
              this.vpipVpip18.push(null);
            }
          });
        }

      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.cargarGraficaVPIP();
      }
    );
  }

  obtenerVpipDetallado() {
    forkJoin(
      this.reportesFinancierosService.getVpipDetallado(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }

        console.log(data);
        this.vpipDetallado = data.vpip;

      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {

      }
    );
  }

  exportCsvVpipDetallado() {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: ["Id", "Id Proyecto", "Proyecto", "Id Tipo", "Tipo", "Valor Contractual", "Valor Acumulado", "Porcentaje Cumplimiento", "Fecha Valor Acumulado", "Fecha Inicio Preconstruccion", "Anios", "Meses Totales", "Meses", "FechaCumpliminetoVPIP"]
    };
    new ngxCsv(this.vpipDetallado, 'Vpip' + '_export_' + new Date().getTime(), options);
  }


  tableflotante(div) {
    console.log("asd2");
    var botonesactive1 = document.getElementsByClassName("tablaflotante"); //
    for (var i = 0, all = botonesactive1.length; i < all; i++) {
      botonesactive1[i].classList.add("none");
    }
    let div2 = document.getElementById(div);
    div2.classList.remove("none");
    var body = document.body;
    body.classList.add("sinscrool");
  }
  tableflotanteocultar(div) {
    console.log("asd");
    let div2 = document.getElementById(div);
    div2.classList.add("none");
    var body = document.body;
    body.classList.remove("sinscrool");
  }

  cargarGraficaVPIP() {
    /* this.optionschart9 = {
      chart: {
        type: "column",
      },
      title: {
        text: "VPIP",
      },
      subtitle: {
        text: "Valor presente al Mes de Referencia",
      },
      xAxis: {
        categories: [
          this.valoresVpip.vpipContractual,
          this.valoresVpip.vpipContractualAcumulado,
          this.valoresVpip.vpipAcumulado,
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Millones",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>${point.y:,.1f} mill</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Valor Contractual",
          color: colorchart.color1,
          data: [this.valoresVpip.valorContractual],
        },
        {
          name: "Valor Contractual Acumulado",
          color: colorchart.color3,
          data: [this.valoresVpip.valorContractualAcumulado],
        },
        {
          name: "Valor " + this.valoresVpip.vpipAcumulado,
          color: colorchart.color2,
          data: [this.valoresVpip.valorVpipAcumulado],
        }
      ],
    }; */
    //let chart9 = Highcharts.chart("chart9", this.optionschart9);

    /* this.optionschartVpip = {
      chart: {
        type: "column",
      },
      title: {
        text: "VPIP",
      },
      subtitle: {
        text: "Valor presente al Mes de Referencia",
      },
      xAxis: {
        categories: [
          this.valoresVpip.vpipContractual,
          this.valoresVpip.vpipContractualAcumulado,
          this.valoresVpip.vpipAcumulado,
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Millones",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>${point.y:,.1f} mill</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Valor Contractual",
          color: colorchart.color1,
          data: [this.valoresVpip.valorContractual],
        },
        {
          name: "Valor Contractual Acumulado",
          color: colorchart.color3,
          data: [this.valoresVpip.valorContractualAcumulado],
        },
        {
          name: "Valor " + this.valoresVpip.vpipAcumulado,
          color: colorchart.color2,
          data: [this.valoresVpip.valorVpipAcumulado],
        }
      ],
    }; */

    if (this.tieneDatosVpip) {
      this.optionschartVpip = {
        credits: {
          enabled: false,
        },
        title: {
          text: 'VPIP'
        },
        subtitle: {
          text: 'Valor presente al mes de referencia'
        },
        yAxis: {
          min: 0,
          title: { text: "Millones COP" }
        },
        xAxis: {
          categories: this.vpip.map((item) => item.formatoFechaCumplimientoVPIP)
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>$ {point.y:,.0f} Mill</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              format: '$ {point.y:,.0f} Mill'
            },
            pointPadding: 0,
            groupPadding: 0
          }
        },

        series: [{
          name: 'Valor contractual acumulado',
          data: this.vpipContractual.map((item) => item.valorContractual),
          lineWidth: 0,
          color: "#313CBC"
        }, {
          name: 'Valor VPIP8 acumulado',
          data: this.vpipVpip8,
          lineWidth: 0,
          color: "#00C0FF"
        }, {
          name: 'Valor VPIP13 acumulado',
          data: this.vpipVpip13,
          lineWidth: 0,
          color: "#00BFC4"
        }, {
          name: 'Valor VPIP18 acumulado',
          data: this.vpipVpip18,
          lineWidth: 0,
          color: "#FC7F25"
        }],
      };

      let chart2 = Highcharts.chart("chart9", this.optionschartVpip);
    }

  }

  init() {
    //ocho

    //nueve


  }

  cargarGraficas() {
    console.log("GRAFICAS!!!");
    console.log(this.datosGeneralesFinancieros.equity);
    console.log(this.datosGeneralesFinancieros.equityFondeado);
    console.log(this.datosGeneralesFinancierosVIx.cierreFinancieroPresentado);
    this.optionschart8 = {
      chart: {
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "Financiero",
      },
      subtitle: {
        text: "Valores en pesos según fecha de referencia ",
      },
      xAxis: {
        categories: [this.proyecto.nombre],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Millones",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>${point.y:.1f} Mill</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Equity en pesos",
          color: colorchart.color3,
          data: [
            {
              color: colorchart.color3,
              y: this.datosGeneralesFinancierosVIx.equity,
            },
          ],
        },
        {
          name: "Equity Fondeado en pesos",
          color: colorchart.color1,
          data: [
            {
              color: colorchart.color1,
              y: this.datosGeneralesFinancierosVIx.equityFondeado,
            },
          ],
        },
        {
          name: "Cierre Financiero en pesos",
          color: colorchart.color2,
          data: [
            {
              color: colorchart.color2,
              y: this.datosGeneralesFinancierosVIx.cierreFinancieroPresentado,
            },
          ],
        },
      ],
    };

    let chart8 = Highcharts.chart("chart8", this.optionschart8);
  }

  formatoMoneda(valor: number) {
    return valor
      ? "$" +
      (valor / 1000000).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
      " mill."
      : "$0";
  }

}

