import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class CertificacionConfiguracionService {
    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
      this.END_POINT = environment.baseUrl;
    }

    
    /**
     * Metodo que carga la lista del tipo de certificado
     */
     public getlistaFirmaCargo() {
        return this.http.get(this.END_POINT + '/ClbCertificadoLaboral/lista/cargoFirma')
        .pipe(
        retry(1));
    }

     /**
     * Metodo que carga los datos inicialesiniciales
     */
    public getDatosIniciales() {
        return this.http.get(this.END_POINT + '/ClbCertificadoLaboral/configuracion/datos')
        .pipe(
        retry(1));
    }

      /**
     * Metodo que guarda la informacion
     */
    public postEnviarInfo(datos) {
        return this.http.post(this.END_POINT + '/ClbCertificadoLaboral/configuracion', datos)
        .pipe(
        retry(1));
    }
}
