import { Component, OnInit } from '@angular/core';
import { InteresadosListaService } from './interesados-lista.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProyectoInteresados, TrayectoModel } from '../../../dtos/interesados/proyecto';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-interesados-lista',
  templateUrl: './interesados-lista.component.html',
  styleUrls: ['./interesados-lista.component.sass'],
  providers: [InteresadosListaService]
})


export class InteresadosListaComponent implements OnInit {
  displayedColumns1;
  displayedColumns2;
  disableTrayecto: boolean = false;
  objectTrayectos: any = [];
  objectInteresados: any = [];
  message;
  visibleDialogoAgregarServicio: boolean = false;
  visibleDialogoVisualizarServicio: boolean = false;
  visibleDialogoVisualizarInteresados: boolean = false;
  visibleConfirmarEliminar: boolean = false;
  eliminarTemp;
  serviciosInteresados: ProyectoInteresados = {};
  serviciosInteresadosForm: FormGroup;
  trayectoSeleccionado: TrayectoModel;
  trayectoInteresado: TrayectoModel;

  idProyectoDetalle;
  visibleDialogoExito: boolean = false;
  fechaSalidaConvert;
  fechaLlegadaConvert;
  fechaDesde: Date = new Date;

  proyectos: any = [];
  id: number;
  nombreProyecto;
  displayedColumns: string[] = [ 'id', 'idTipo', 'idEtapaActual', 'priorizacion','descripcion', 'fechaInicio', 'acciones'];
  dataSeguimiento;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>


  constructor(
    private info: InteresadosListaService,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
    ) {
    }


  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarProyectos(this.id);
      this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true
      });
      this.cargarTrayectos(this.id);
      localStorage.setItem('idP', JSON.stringify(this.id));

    });

    this.displayedColumns1 = [
      { field: 'nombre', header: 'Título' },
      { field: 'trayecto', header: 'Trayecto' },
      { field: 'fechaSalidaStr', header: 'Fecha salida' },
      { field: 'fechaLlegadaStr', header: 'Fecha llegada' },

    ];

    this.displayedColumns2 = [
      { field: 'nit', header: 'NIT' },
      { field: 'nombreEmpresa', header: 'Empresa' },
      { field: 'nombrePersonaContacto', header: 'Persona contacto' },
      { field: 'correoElectronico', header: 'Correo electrónico' },
      { field: 'numeroTelefono', header: 'Número teléfono' },

    ];
  }

  onNew(form: NgForm) {
    this.message = 'Adicionar servicio'
    this.visibleDialogoAgregarServicio = true;
    this.idProyectoDetalle = 'new';
    this.serviciosInteresados = {};
    this.fechaLlegadaConvert = '';
    this.fechaSalidaConvert = '';
    this.trayectoSeleccionado = {};
    form.reset()
    this.disableTrayecto = false;
  }

  onFollow(payload: any) {
    this.visibleDialogoVisualizarServicio = true;
    this.serviciosInteresados = payload;
  }

  onEdit(data: ProyectoInteresados) {
    this.message = 'Editar servicio'
    this.visibleDialogoAgregarServicio = true;
    this.idProyectoDetalle = 'edit';
    this.serviciosInteresados = data;
    this.trayectoSeleccionado = this.objectTrayectos.filter(value => value.id === data.idTrayecto)[0];
    this.fechaLlegadaConvert = new Date(this.convertStringDateToTime(data.fechaLlegada));
    this.fechaSalidaConvert = new Date(this.convertStringDateToTime(data.fechaSalida))
    this.disableTrayecto = true;

  }

  onDelete(idRegistry: number) {
    this.visibleConfirmarEliminar = true;
    this.eliminarTemp = idRegistry;
  }

  onFollowInterest(idRegistry: number) {
    this.visibleDialogoVisualizarInteresados = true;
    return this.info.getInteresados(this.id, idRegistry ).subscribe((data: {}) => {
      this.objectInteresados = data;
      },error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  cancelarAgregarServicio(serviciosInteresadosForm: NgForm) {
    this.visibleDialogoAgregarServicio = false;
    this.cargarProyectos(this.id);
  }

  cancelarVisualizar(serviciosInteresadosForm: NgForm) {
    this.visibleDialogoVisualizarServicio = false;
  }

  cancelarEliminar() {
    this.visibleConfirmarEliminar = false;
  }

  cancelarVisualizarInteresados(serviciosInteresadosForm: NgForm) {
    this.visibleDialogoVisualizarInteresados = false;
  }

  cargarTrayectos(payload) {
    return this.info.getTrayectos(payload).subscribe((data: {}) => {
      this.objectTrayectos = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarProyectos(id) {
    this.info.getProyectos(id).subscribe((data: {}) => {
      this.proyectos = data;
      this.dialogSpin.close();
    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
  }

  aceptarEliminar() {
    this.visibleConfirmarEliminar = false;
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.info.deleteProyecto(this.eliminarTemp).subscribe((data: {}) => {
      this.message ='La información se eliminó exitosamente';
      this.visibleDialogoExito = true;
      this.cargarProyectos(this.id);
    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  guardarDetalle(form: NgForm) {
    if (this.convertStringDateToTime(this.fechaLlegadaConvert) > this.convertStringDateToTime(this.fechaSalidaConvert)) {
      this.serviciosInteresados.idProyecto = this.id;
      this.serviciosInteresados.idTrayecto = this.trayectoSeleccionado.id;
      this.serviciosInteresados.fechaLlegada = this.fechaLlegadaConvert;
      this.serviciosInteresados.fechaSalida = this.fechaSalidaConvert;
      this.visibleDialogoAgregarServicio = false;
      this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
        panelClass: 'transparent',
        disableClose: true
      });
      if (this.idProyectoDetalle === 'new') {
        return this.info.guardarNuevoProyecto(this.serviciosInteresados).subscribe((data: {}) => {
          this.message ='La información se guardó exitosamente';
          this.visibleDialogoExito = true;
          this.cargarProyectos(this.id);
          },error => {
            this.dialogSpin.close();
            this.principalComponent.cargarErrorServicio(error);
          });
    } else if (this.idProyectoDetalle === 'edit') {
        return this.info.guardarEditProyecto(this.serviciosInteresados).subscribe((data: {}) => {
          this.message ='La información se actualizó exitosamente';
          this.visibleDialogoExito = true;
          this.cargarProyectos(this.id);
          },error => {
            this.dialogSpin.close();
            this.principalComponent.cargarErrorServicio(error);
          });
    }
    } else {
      this.lanzarMensajeWarning('La fecha de salida no puede ser mayor o igual a la fecha de llegada ');
    }
  }

  convertStringDateToTime(data) {
      const date = new Date(data);
      const localOffset = date.getTimezoneOffset() * 60000;
      const utc = date.getTime() + localOffset;
      return utc;
    }

  ///////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
  }

  lanzarMensajeError(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
  }

  lanzarMensajeWarning(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
  }

  limpiarMensajes() {
      this.messageService.clear();
  }





}
