import { Component } from '@angular/core';

@Component({
  selector: 'app-proyectos-educacion',
  templateUrl: './proyectos-educacion.component.html',
  styleUrls: ['./proyectos-educacion.component.sass']
})
export class ProyectosEducacionComponent {

}
