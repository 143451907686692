import { Component, OnInit } from "@angular/core";
import { Observable, forkJoin } from 'rxjs';
import { loadModules } from "esri-loader";
import { Router, ActivatedRoute } from "@angular/router";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import {
  CrrPeajesTarifasDTO,
  CrrPeajesTarifasEncDTO,
  CrrPeajesDTO,
} from "src/app/reportes/carreteros/reportes-peajes.dto";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { formatDate } from "@angular/common";
import * as Highcharts from "highcharts/highstock";

@Component({
  selector: "app-rpt-crr-peajes-tarifas",
  templateUrl: "./rpt-crr-peajes-tarifas.component.html",
  styleUrls: ["./rpt-crr-peajes-tarifas.component.sass"],
})
export class RptCrrPeajesTarifasComponent implements OnInit {
  blockedDocument = false;
  idPeaje: number = 0;
  optionschart2: any;

  informacionTarifasCompleta: CrrPeajesTarifasDTO[] = [];
  informacionTarifasHistorico: CrrPeajesTarifasDTO[] = [];
  informacionTarifasFiltro: CrrPeajesTarifasDTO[] = [];
  informacionTarifasEnc: CrrPeajesTarifasEncDTO[] = [];
  informacionTarifasEncVigente: CrrPeajesTarifasEncDTO;
  informacionGeneralPeajes: CrrPeajesDTO[] = [];

  informaPeaje: CrrPeajesDTO;
  valorCategoriaI: number = 0;
  valorCategoriaII: number = 0;
  valorCategoriaIII: number = 0;
  valorCategoriaIV: number = 0;
  valorCategoriaV: number = 0;
  valorCategoriaVI: number = 0;
  valorCategoriaVII: number = 0;
  valorCategoriaEA: number = 0;
  valorCategoriaEG: number = 0;
  valorCategoriaER: number = 0;
  valorCategoriaEC: number = 0;
  valoresCategoriasEspecialesI: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesII: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesIII: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesIV: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesV: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesVI: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesVII: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesEA: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesEG: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesER: CrrPeajesTarifasDTO[] = [];
  valoresCategoriasEspecialesEC: CrrPeajesTarifasDTO[] = [];
  private documentos: any = [];

  fechas?: any[] = [];
  fechaCategoriaI: string;

  fechaCategoriaII: string;
  fechaCategoriaIII: string;
  fechaCategoriaIV: string;
  fechaCategoriaV: string;
  fechaCategoriaVI: string;
  fechaCategoriaVII: string;
  fechaCategoriaEA: string;
  fechaCategoriaEG: string;
  fechaCategoriaER: string;
  fechaCategoriaEC: string;


  colores: any[];
  nombrePeaje: string;
  nombreProyecto: string;
  fechaInicioVigente: Date;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent,
    private ruta: ActivatedRoute
  ) {

    Highcharts.setOptions({
      lang: {
        loading: 'Cargando...',
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],

        rangeSelectorFrom: "Desde",
        rangeSelectorTo: "Hasta",
        rangeSelectorZoom: "Período",
        downloadPNG: 'Descargar imagen PNG',
        downloadJPEG: 'Descargar imagen JPEG',
        downloadPDF: 'Descargar imagen PDF',
        downloadSVG: 'Descargar imagen SVG',
        printChart: 'Imprimir',
        resetZoom: 'Reiniciar zoom',
        resetZoomTitle: 'Reiniciar zoom',
        thousandsSep: ".",
        decimalPoint: ',',
        drillUpText: 'Volver a Categorias'
      }
    });

    this.colores = ["#11528a", "#01d3c1", "#00c0ff", "#00BFC4", "#FC7F25", "#FFC438", "#800CE5", "#BB89F4", "#D1D5FA"];
  }

  ngOnInit() {
    this.blockedDocument = true;
    this.ruta.params.subscribe((params) => {
      this.idPeaje = params.idPeaje;
    });

    this.reportesCarreteroService
      .getInformacionCrrPeajesPorId(this.idPeaje)
      .subscribe(
        (dato) => {
          if (dato != null && dato.respuestaServicio != null) {
            if (dato.respuestaServicio.codigoSalida === 1) {
              this.informacionGeneralPeajes = dato.infoPeajes;

              const infoPeajes = this.informacionGeneralPeajes[0];
              this.nombrePeaje = infoPeajes.peaje;
              this.nombreProyecto = infoPeajes.proyecto;
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: dato.respuestaServicio.mensajeSalida,
                life: 10000,
              });
            }
          }
        },
        (error) => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => { }
      );

    var infoHistorica = [];
    var info;

    forkJoin(this.reportesCarreteroService.getInformacionCrrPeajesTarifasEnc(this.idPeaje), this.reportesCarreteroService
      .getInformacionCrrPeajesTarifas(this.idPeaje)).subscribe(([InformacionPeajesTarifasEncDTO, InformacionPeajesTarifasDTO]) => {


        if (InformacionPeajesTarifasEncDTO != null && InformacionPeajesTarifasEncDTO.respuestaServicio != null) {
          if (InformacionPeajesTarifasEncDTO.respuestaServicio.codigoSalida === 1) {
            this.informacionTarifasEnc = InformacionPeajesTarifasEncDTO.encTarifas;            
            const tarifaVigente = this.informacionTarifasEnc.filter(
              (vig) => (vig.ordenDesc = 1)
            )[0];

            this.documentos = tarifaVigente.documentos;

            this.fechaInicioVigente = tarifaVigente.fechaInicio;
            this.fechas = this.informacionTarifasEnc.map((fecha) =>
              formatDate(fecha.fechaInicio, "dd/MM/yyyy", "es-ES")
            );
            this.fechas = this.fechas.reverse();
            console.log("fechs hist tarfas")
            console.log(this.fechas)
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: InformacionPeajesTarifasEncDTO.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }



        if (InformacionPeajesTarifasDTO != null && InformacionPeajesTarifasDTO.respuestaServicio != null) {
          if (InformacionPeajesTarifasDTO.respuestaServicio.codigoSalida === 1) {
            this.informacionTarifasCompleta = InformacionPeajesTarifasDTO.infoTarifas;
            this.informacionTarifasHistorico = InformacionPeajesTarifasDTO.infoTarifas;
            this.informacionTarifasCompleta =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.fechaFin.toString() === "9999-12-31T00:00:00"
              );

            const valoresVigentesI = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "I"
            )[0];

            if (valoresVigentesI == null) {
              this.valorCategoriaI = 0;
            } else {
              this.valorCategoriaI = valoresVigentesI.valor;
              this.fechaCategoriaI = formatDate(
                valoresVigentesI.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "I",
                color: "#11528a",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "I")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor]),
              };
              infoHistorica.push(info);
            }

            this.valoresCategoriasEspecialesI =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "I" &&
                  vigente.idTipoTarifa === 2
              );

            const valoresVigentesII = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "II"
            )[0];
            if (valoresVigentesII == null) {
              this.valorCategoriaII = 0;
            } else {
              this.valorCategoriaII = valoresVigentesII.valor;
              this.fechaCategoriaII = formatDate(
                valoresVigentesII.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "II",
                color: "#01d3c1",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "II")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor]),
              };
              infoHistorica.push(info);
            }

            this.valoresCategoriasEspecialesII =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "II" &&
                  vigente.idTipoTarifa === 2
              );

            const valoresVigentesIII = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "III"
            )[0];
            if (valoresVigentesIII == null) {
              this.valorCategoriaIII = 0;
            } else {
              this.valorCategoriaIII = valoresVigentesIII.valor;
              this.fechaCategoriaIII = formatDate(
                valoresVigentesIII.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "III",
                color: "#00c0ff",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "III")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor]),
              };

              infoHistorica.push(info);
            }
            this.valoresCategoriasEspecialesIII =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "III" &&
                  vigente.idTipoTarifa === 2
              );

            const valoresVigentesIV = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "IV"
            )[0];
            if (valoresVigentesIV == null) {
              this.valorCategoriaIV = 0;
            } else {
              this.valorCategoriaIV = valoresVigentesIV.valor;
              this.fechaCategoriaIV = formatDate(
                valoresVigentesIV.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "IV",
                color: "#00BFC4",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "IV")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor]),
              };

              infoHistorica.push(info);
            }

            this.valoresCategoriasEspecialesIV =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "IV" &&
                  vigente.idTipoTarifa === 2
              );

            const valoresVigentesV = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "V"
            )[0];

            if (valoresVigentesV == null) {
              this.valorCategoriaV = 0;
            } else {
              this.valorCategoriaV = valoresVigentesV.valor;
              this.fechaCategoriaV = formatDate(
                valoresVigentesV.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "V",
                color: "#FC7F25",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "V")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor]),
              };

              infoHistorica.push(info);
            }
            this.valoresCategoriasEspecialesV =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "V" &&
                  vigente.idTipoTarifa === 2
              );

            const valoresVigentesVI = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "VI"
            )[0];

            if (valoresVigentesVI == null) {
              this.valorCategoriaVI = 0;
            } else {
              this.valorCategoriaVI = valoresVigentesVI.valor;
              this.fechaCategoriaVI = formatDate(
                valoresVigentesVI.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "VI",
                color: "#FFC438",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "VI")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor]),
              };

              infoHistorica.push(info);
            }
            this.valoresCategoriasEspecialesVI =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "VI" &&
                  vigente.idTipoTarifa === 2
              );

            const valoresVigentesVII = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "VII"
            )[0];
            if (valoresVigentesVII == null) {
              this.valorCategoriaVII = 0;
            } else {
              this.valorCategoriaVII = valoresVigentesVII.valor;
              this.fechaCategoriaVII = formatDate(
                valoresVigentesVII.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "VII",
                color: "#800CE5",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "VII")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor])
              };

              infoHistorica.push(info);
            }
            this.valoresCategoriasEspecialesVII =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "VII" &&
                  vigente.idTipoTarifa === 2
              );


            const valoresVigentesEA = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "EA"
            )[0];
            if (valoresVigentesEA == null) {
              this.valorCategoriaEA = 0;
            } else {
              this.valorCategoriaEA = valoresVigentesEA.valor;
              this.fechaCategoriaEA = formatDate(
                valoresVigentesEA.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "EA",
                color: "#BB89F4",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "EA")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor])
              };

              infoHistorica.push(info);
            }
            this.valoresCategoriasEspecialesEA =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "EA" &&
                  vigente.idTipoTarifa === 2
              );

            const valoresVigentesEG = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "EG"
            )[0];
            if (valoresVigentesEG == null) {
              this.valorCategoriaEG = 0;
            } else {
              this.valorCategoriaEG = valoresVigentesEG.valor;
              this.fechaCategoriaEG = formatDate(
                valoresVigentesEG.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "EG",
                color: "#D1D5FA",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "EG")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor])
              };

              infoHistorica.push(info);
            }
            this.valoresCategoriasEspecialesEA =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "EG" &&
                  vigente.idTipoTarifa === 2
              );


            const valoresVigentesER = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "ER"
            )[0];
            if (valoresVigentesER == null) {
              this.valorCategoriaER = 0;
            } else {
              this.valorCategoriaER = valoresVigentesER.valor;
              this.fechaCategoriaER = formatDate(
                valoresVigentesER.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "ER",
                color: "#C70039",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "ER")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor])
              };

              infoHistorica.push(info);
            }
            this.valoresCategoriasEspecialesER =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "ER" &&
                  vigente.idTipoTarifa === 2
              );


            const valoresVigentesEC = this.informacionTarifasCompleta.filter(
              (vigente) => vigente.idCategoriaTarifa === "EC"
            )[0];
            if (valoresVigentesEC == null) {
              this.valorCategoriaEC = 0;
            } else {
              this.valorCategoriaEC = valoresVigentesEC.valor;
              this.fechaCategoriaEC = formatDate(
                valoresVigentesEC.fechaInicio,
                "dd/MM/yyyy",
                "es-ES"
              );
              info = {
                name: "EC",
                color: "#581845",
                data: this.informacionTarifasHistorico
                  .filter((info) => info.idCategoriaTarifa === "EC")
                  .map((a) => [formatDate(
                    a.fechaInicio,
                    "dd/MM/yyyy",
                    "es-ES"
                  ), a.valor])
              };

              infoHistorica.push(info);
            }
            this.valoresCategoriasEspecialesEC =
              this.informacionTarifasCompleta.filter(
                (vigente) =>
                  vigente.categoriaVehiculo === "EC" &&
                  vigente.idTipoTarifa === 2
              );

            console.log("infoHistorica");
            console.log(infoHistorica);


            this.optionschart2 = {
              chart: {
                zoomType: "x",
              },
              title: {
                text: "",
              },
              credits: {
                enabled: false,
              },
              xAxis: {
                type: "category"
              },
              yAxis: {

                title: { text: "Tarifa" },
              },

              tooltip: {
                headerFormat:
                  '<span style="font-size:10px"> <strong>Categoría:</strong> {point.key} </span><table>',
                pointFormat:
                  '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                  '<td style="padding:0"><b>$ {point.y:,.1f} </b></td></tr>',
                footerFormat: "</table>",
                shared: true,
                useHTML: true,
              },
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: true,
                    format: "$ {point.y:,.1f}",
                    align: "right",
                    color: "#11528a",
                    x: -10,
                  },
                  pointPadding: 0.1,
                  groupPadding: 0,
                },
              },
              series: infoHistorica,
              responsive: {
                rules: [
                  {
                    condition: {
                      maxWidth: 800,
                    },
                    chartOptions: {
                      rangeSelector: {
                        inputEnabled: false,
                      },
                    },
                  },
                ],
              },
            };

            let chart2 = Highcharts.chart(
              "chartHistoriaTarifas",
              this.optionschart2
            );
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: InformacionPeajesTarifasDTO.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }


          this.blockedDocument = false;


        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });




    loadModules([
      "esri/config",
      "esri/Map",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/widgets/Search",
      "esri/layers/VectorTileLayer",
      "esri/symbols/SimpleLineSymbol",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/renderers/SimpleRenderer",
    ])
      //.then(([esriConfig,Map,WebMap,Basemap, VectorTileLayer, TileLayer, MapView, FeatureLayer, Search]) => {
      .then(
        ([
          esriConfig,
          Map,
          MapView,
          FeatureLayer,
          Search,
          VectorTileLayer,
          SimpleMarkerSymbol,
          SimpleLineSymbol,
          SimpleRenderer,
        ]) => {
          //esriConfig.apiKey = "AAPK2456e7e0539844b39d13266e742a95cbYV9nTVDpArBvqC_Y21vE-ZcWrZCNAXTgi3ZGBtO11pzgJMdZwMq9FmQyq_78eY8I";
          /*
          const vtlLayer = new VectorTileLayer({
            url: "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/9954884ef30a4f0ca143b5c78b2263aa",
          });

          const map = new Map({
            basemap: "arcgis-light-gray",
            layers: [vtlLayer],
          });

            const map = new Map({
            basemap: "topo",
          });
          */

          const map = new Map({
            basemap: "streets-navigation-vector",
          });

          const view = new MapView({
            container: "viewDivpeajes",
            map: map,
            center: [-72, 4], // lon, lat
            zoom: 6,
          });

          const trailsRenderer = {
            type: "simple",
            symbol: {
              color: "#5dfca5",
              type: "simple-line",
              style: "solid",
              width: 2,
            },
          };

          let symbol = {
            type: "picture-marker", // autocasts as new PictureMarkerSymbol()
            url: "https://static.arcgis.com/images/Symbols/Shapes/BlackStarLargeB.png",
            width: "64px",
            height: "64px",
          };

          const citiesRenderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              url: "/assets/img/newhome/peaje.png",
              width: "20px",
              height: "20px",
            },
          };

          const renderer = new SimpleRenderer({
            symbol: new SimpleMarkerSymbol({
              width: 2,
              color: "#fb12ff",
            }),
          });

          const proyRenderer = {
            type: "simple",
            symbol: {
              color: "#5dfca5",
              type: "simple-line",
              style: "solid",
              width: 2,
            },
          };

          const featureLayerUF = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/2",

            renderer: trailsRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              title: "{PROYECTO}</br>CODIGOUF: {CODIGOUF}",
              overwriteActions: true,
            },
          });

          const featureLayerPeajes = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/0",
            renderer: citiesRenderer,

            popupTemplate: {
              // autocasts as new PopupTemplate()
              title: "{NOMBRE}</br>Estado: {Estado}",

              overwriteActions: true,
            },
          });
          map.add(featureLayerPeajes);
        }
      )
      .catch((err) => {
        // handle any errors
        console.error(err);
      });
  }
  atras() {
    window.history.back();
  }
}
