import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InformacionGeneralService } from '../informacion-general/informacion-general.service';
import { ProyectoCarretero } from '../informacion-general/proyecto-carretero';
import { MatTableDataSource } from '@angular/material';
import { EtapaProyecto } from '../informacion-general/etapa-proyecto';
import { ProyectoEtapa } from '../informacion-general/proyecto-etapa';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-proyecto',
  templateUrl: './proyecto.component.html',
  styleUrls: ['./proyecto.component.sass']
})
export class ProyectoComponent implements OnInit {
  proyecto: any;
  generaciones: any = [];
  olas: any = [];
  id: number;
  etapas: any = [];
  etapa: ProyectoEtapa;
  displayedColumns: string[] = ['idEtapaNavigation', 'fechaPlaneado', 'fechaReal', 'botones'];
  dataSource: MatTableDataSource<any>;

  constructor(private infoService: InformacionGeneralService, private route: ActivatedRoute, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id'] != 'n') {
        this.id = params['id'];
        this.cargarProyectoCarretero(this.id)
        this.etapa = new ProyectoEtapa(0, this.id, 0)
      } else {
        this.proyecto = new ProyectoCarretero(0, '', '', '', '')
      }
    });
    this.cargarGeneraciones();
    this.cargarOlas();
    this.cargarEtapas();
  }

  agregarEtapa() {
   this.infoService.setProyectoEtapa(this.etapa).subscribe((data: any) => {
      if (data != null) {
        this.etapa.idProyectoEtapa = data.idProyectoEtapa
      }

      if (this.proyecto.proyectoEtapa == undefined){
        this.proyecto.proyectoEtapa = []
      }
      this.proyecto.proyectoEtapa.push(this.etapa);
      this.etapa = new ProyectoEtapa(0, this.id, 0)
      this.cargarProyectoCarretero(this.id)
    })
  }

  nuevaEtapa() {
    this.etapa = new ProyectoEtapa(0, this.id, 0)
  }

  onEdit(proyectoEtapa: any) {
    this.etapa = proyectoEtapa
  }

  eliminarEtapa(idProyectoEtapa: any) {
    this.infoService.deleteProyectoEtapa(idProyectoEtapa).subscribe((data: any) => {
      this.cargarProyectoCarretero(this.id)
    });
  }

  grabarProyecto() {
   this.infoService.setProyectoCarretero(this.proyecto).subscribe((data: any) => {
      if (data != null) {
        this.proyecto.idProyecto = data.idProyecto
        this.id = data.idProyecto
        this.etapa = new ProyectoEtapa(0, this.id, 0)
      }
      window.alert("Proyecto Creado o Actualizado");
    })
  }

  cargarGeneraciones() {
    return this.infoService.getGeneraciones().subscribe((data: {}) => {
      this.generaciones = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarOlas() {
    return this.infoService.getOlas().subscribe((data: {}) => {
      this.olas = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarEtapas() {
    return this.infoService.getEtapasProyecto().subscribe((data: {}) => {
      this.etapas = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
      this.dataSource = new MatTableDataSource(this.proyecto.proyectoEtapa);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }
}
