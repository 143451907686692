import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { AlcanceProyectoBaseComponent } from '../alcance-proyecto-base/alcance-proyecto-base.component';
import { TipoIntervencionCarreteroService } from '../tipo-intervencion-carretero/tipo-intervencion-carretero.service';
import { forkJoin } from 'rxjs';
import { IntervencionDepartamentoUf } from '../tipo-intervencion-carretero/tipo-intervencion-carretero.dto';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-tipo-intervencion-carretero-detalle2',
  templateUrl: './tipo-intervencion-carretero-detalle.component.html',
  styleUrls: ['./tipo-intervencion-carretero-detalle.component.sass']
})
export class TipoIntervencionCarreteroDetalleComponent2 implements OnInit {

  tiposIntervencion: any = [];
  filtroTiposIntervencion: any[];
  tipoIntervencionSeleccionado: any;
  fechaInicioConstruccion: Date;
  fechaFinConstruccion: Date;
  displayedColumnsIntervencionDepartamentos: any = [];
  intervencionDepartamentos: any = [];
  totalFactor: number = 0.0;
  visibleDialogoExito: boolean = false;
  visibleConfirmarEliminarDepartamento: boolean = false;
  visibleDialogoExitoEliminarDepartamento: boolean = false;
  visibleDialogoAgregarDepartamento: boolean = false;
  intervencionDepartamento: IntervencionDepartamentoUf = {};
  departamentos: any = [];
  filtroDepartamentos: any[];
  departamentoSeleccionado: any;
  intervencionDepartamentoUfSeleccionada: IntervencionDepartamentoUf;
  habilitaBotonEliminar: boolean = false;
  tieneDepartamentos: boolean = false;
  blockedDocument: boolean = false;
  habilitaTipoIntervencion: boolean;


  constructor(public lineaBase: AlcanceProyectoBaseComponent, private parametricasService: ParametricasService,
    private tipoIntervencionCarreteroService: TipoIntervencionCarreteroService, private route: ActivatedRoute, private ruta: Router, private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.displayedColumnsIntervencionDepartamentos = [
      { field: 'nombreDepartamento', header: 'Departamento' },
      { field: 'longitudConcesionada', header: 'Longitud concesionada en kilómetros' },
      { field: 'longitudReal', header: 'Longitud real en kilómetros' },
      { field: 'factorPonderacion', header: 'Factor de ponderación %' }];
    if (this.lineaBase.intervencion.fechaInicio != null) {
      this.fechaInicioConstruccion = new Date(this.lineaBase.intervencion.fechaInicio)
    }
    if (this.lineaBase.intervencion.fechaFin != null) {
      this.fechaFinConstruccion = new Date(this.lineaBase.intervencion.fechaFin)
    }
    this.tieneDepartamentos = false;
    if (this.lineaBase.intervencion.id != 0) {
      this.cargarTiposIntervencionDepartamento(this.lineaBase.intervencion.id);
      this.habilitaTipoIntervencion = true;
    }else{
      this.habilitaTipoIntervencion = false;
    }
  }

  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.departamentos.length; i++) {
        let filtro = this.departamentos[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDepartamentos.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.departamentos = this.filtroDepartamentos;
      }
    });
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      let filtro = this.departamentos[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  editarIntervencionDepartamento(intervencionDepartamentoUf: IntervencionDepartamentoUf) {
    this.intervencionDepartamentoUfSeleccionada = intervencionDepartamentoUf;


    this.blockedDocument = true;
    forkJoin(
      this.tipoIntervencionCarreteroService.getObtenerIntervencionDepartamentoBorradorPorId(this.intervencionDepartamentoUfSeleccionada.id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.intervencionDepartamento = result.intervencionDepartamento;
          this.departamentoSeleccionado = this.intervencionDepartamento.idDepartamentoNavigation;
          this.visibleDialogoAgregarDepartamento = true;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  agregarDepartamento(forma: NgForm) {
    if (this.visibleDialogoAgregarDepartamento) {
      if (this.fechaInicioConstruccion > this.fechaFinConstruccion) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de construcción debe ser menor que la fecha fin de construcción', life: 10000 });
      } else {
        this.blockedDocument = true;
        this.intervencionDepartamento.idDepartamento = this.departamentoSeleccionado.id;
        if (this.lineaBase.intervencion.id == 0) {
          this.lineaBase.intervencion.idTipoIntervencion = this.lineaBase.tipoIntervencionSeleccionado.id;
          this.lineaBase.intervencion.idProyecto = this.lineaBase.idProyecto;
          this.lineaBase.intervencion.fechaInicio = this.fechaInicioConstruccion;
          this.lineaBase.intervencion.fechaFin = this.fechaFinConstruccion;
          this.lineaBase.intervencion.factorPonderacion = this.lineaBase.factorPonderacionIntervencion / 100;
        }
        this.intervencionDepartamento.intervencion = this.lineaBase.intervencion;
        forkJoin(
          this.tipoIntervencionCarreteroService.guardarIntervencionDepartamentoBorrador(this.intervencionDepartamento)
        ).subscribe(([result]) => {
          if (result != null && result.respuestaServicio != null) {
            //Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              if (this.lineaBase.intervencion.id == 0) {
                this.lineaBase.intervencion.id = result.intervencionDepartamento.idIntervencionUf;
              }
              this.cargarTiposIntervencionDepartamento(this.lineaBase.intervencion.id);
              this.visibleDialogoExito = true;
              this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El registro se guardó exitosamente.', life: 10000 });
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
          error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          },
          () => {
            //Desbloquear pantalla
            this.blockedDocument = false;
          }
        );
      }
    }


    /* if (this.visibleDialogoAgregarDepartamento) {
      if (this.fechaInicioConstruccion > this.fechaFinConstruccion) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de construcción debe ser menor que la fecha fin de construcción', life: 10000 });
      } else {
        this.intervencionDepartamento.idDepartamento = this.departamentoSeleccionado.id;
        this.intervencionDepartamento.idProyecto = this.lineaBase.idProyecto;
        this.lineaBase.intervencion.fechaInicio = this.fechaInicioConstruccion;
        this.lineaBase.intervencion.fechaFin = this.fechaFinConstruccion;
        if (this.lineaBase.idIntervencion === 'new') {
          this.lineaBase.intervencion.idTipoIntervencion = this.lineaBase.tipoIntervencionSeleccionado.id;
          this.lineaBase.intervencion.idProyecto = this.lineaBase.idProyecto;
          this.intervencionDepartamento.idIntervencionUf = 0;
          this.lineaBase.intervencion.crrIntervencionUfDepartamento = [this.intervencionDepartamento];
          let vara: any = this.tipoIntervencionCarreteroDetalleService.guardarNuevaIntervencionPorUnidadFuncional(this.lineaBase.intervencion).subscribe((data: {}) => {
            this.lineaBase.intervencion = data;
            this.lineaBase.intervencion.factorPonderacion = this.lineaBase.intervencion.factorPonderacion * 100;
            this.lineaBase.idIntervencion = this.lineaBase.intervencion.id;
            this.visibleDialogoExito = true;
            this.cargarIntervencionDepartamentoPorIntervencion(this.lineaBase.idIntervencion);
          });
        } else {
          this.intervencionDepartamento.idIntervencionUf = this.lineaBase.idIntervencion;
          if (this.idIntervencionDepartamento === 'new') {
            let vara: any = this.tipoIntervencionCarreteroDetalleService.guardarIntervencionDepartamentoPorIntervencion(this.intervencionDepartamento).subscribe((data: {}) => {
              this.visibleDialogoExito = true;
              this.cargarIntervencionDepartamentoPorIntervencion(this.lineaBase.idIntervencion);
            });
          } else {
            this.tipoIntervencionCarreteroDetalleService.editarIntervencionDepartamentoPorIntervencion(this.intervencionDepartamento, this.idIntervencionDepartamento).subscribe((data: {}) => {
              this.visibleDialogoExito = true;
              this.cargarIntervencionDepartamentoPorIntervencion(this.lineaBase.idIntervencion);
            });
          }
        }
      }
    } */
  }

  cargarTiposIntervencionDepartamento(idIntervencion) {
    forkJoin(
      this.tipoIntervencionCarreteroService.getListaIntervencionDepartamentoPorIdIntervencion(idIntervencion, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.lineaBase.intervencionDepartamentos = result.intervencionesDepartamento;
          this.totalFactor = 0;
          this.lineaBase.intervencionDepartamentos.forEach(element => {
            this.totalFactor = this.totalFactor + element.factorPonderacion;
            this.tieneDepartamentos = true;
          });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  agregarTipoIntervencion(forma: NgForm) {

    if (this.fechaInicioConstruccion > this.fechaFinConstruccion) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de construcción debe ser menor que la fecha fin de construcción', life: 10000 });
    } else {
      this.lineaBase.intervencion.idTipoIntervencion = this.lineaBase.tipoIntervencionSeleccionado.id;
      this.lineaBase.intervencion.idProyecto = this.lineaBase.idProyecto;
      this.lineaBase.intervencion.fechaInicio = this.fechaInicioConstruccion;
      this.lineaBase.intervencion.fechaFin = this.fechaFinConstruccion;
      this.lineaBase.intervencion.factorPonderacion = this.lineaBase.factorPonderacionIntervencion / 100;
      this.blockedDocument = true;
      forkJoin(
        this.tipoIntervencionCarreteroService.guardarNuevaIntervencionPorUnidadFuncional(this.lineaBase.intervencion, this.lineaBase.esBorrador)
      ).subscribe(([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            //this.lineaBase.intervencion.factorPonderacion = this.lineaBase.intervencion.factorPonderacion * 100;
            this.visibleDialogoExito = true;
            this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'La intervención se guardó exitosamente.', life: 10000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );

    }
  }

  cargarTipoIntervencion(event) {
    this.filtroTiposIntervencion = [];
    return this.parametricasService.getTiposIntervencion().subscribe((data: {}) => {
      this.tiposIntervencion = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.tiposIntervencion.length; i++) {
        let filtro = this.tiposIntervencion[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroTiposIntervencion.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.tiposIntervencion = this.filtroTiposIntervencion;
      }
    });
  }



  cargarFiltroTiposIntervencion(event) {
    this.filtroTiposIntervencion = [];
    for (let i = 0; i < this.tiposIntervencion.length; i++) {
      let filtro = this.tiposIntervencion[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroTiposIntervencion.push(filtro);
      }
    }
  }

  visibleAgregarIntervencionDepartamento() {
    this.visibleDialogoAgregarDepartamento = true;
    this.intervencionDepartamento = {};
    this.departamentoSeleccionado = null;
    this.intervencionDepartamento.idIntervencionUf = this.lineaBase.intervencion.id;
  }

  cancelarNuevoIntervencionDepartamento() {
    this.visibleDialogoAgregarDepartamento = false;
  }

  cancelarNuevoTipoIntervencion() {
    this.lineaBase.visibleTiposIntervencionDetalle = false;
    this.lineaBase.visibleTiposIntervencion = true;
    this.lineaBase.intervencion = {};
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.visibleDialogoAgregarDepartamento = false;
  }

  irEliminarIntervencionDepartamento(intervencionDepartamentoUf: IntervencionDepartamentoUf) {
    this.intervencionDepartamentoUfSeleccionada = intervencionDepartamentoUf;
    this.visibleConfirmarEliminarDepartamento = true;
  }

  cancelarEliminarDepartamento() {
    this.visibleConfirmarEliminarDepartamento = false;
  }

  aceptarEliminarDepartamento() {
    this.blockedDocument = true;
    forkJoin(
      this.tipoIntervencionCarreteroService.eliminarIntervencionUfDepartamento(this.intervencionDepartamentoUfSeleccionada.id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.visibleConfirmarEliminarDepartamento = false;
          this.cargarTiposIntervencionDepartamento(this.lineaBase.intervencion.id);
          this.visibleDialogoExitoEliminarDepartamento = true;
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El registro se eliminó exitosamente.', life: 10000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  aceptarDialogoEliminarDepartamento() {
    this.visibleDialogoExitoEliminarDepartamento = false;
  }

}
