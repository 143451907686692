import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Accionista } from '../concesionario-carretero/accionista';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { ParametricasService } from '../parametricas/parametricas.service';
import { ConcesionarioCarreteroService } from '../concesionario-carretero/concesionario-carretero.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccionistaContratista } from '../concesionario-carretero/accionista-contratista';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-accionistas-carretero',
  templateUrl: './accionistas-carretero.component.html',
  styleUrls: ['./accionistas-carretero.component.sass']
})
export class AccionistasCarreteroComponent implements OnInit {




  visibleDialogoAgregarAccionista: boolean = false;

  idAccionistaConcesionario: any;
  accionistaContratista: AccionistaContratista = {};
  accionistasLista: any = [];
  filtroAccionistaLista: any[];
  accionistaSeleccionado: any;
  idConcesionarioAccionista: any;
  visibleDialogoExito: boolean;
  visibleConfirmarEliminarAccionista: boolean = false;
  visibleDialogoExitoEliminarAccionistaConcesionario: boolean = false;


  displayedColumns: string[] = ['nit', 'nombre', 'participacion', 'acciones'];

  dataSource: MatTableDataSource<Accionista>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private lineaBase: ProyectoLineaBaseComponent, private parametricasService: ParametricasService,
    private detalleService: ConcesionarioCarreteroService, private route: ActivatedRoute, private ruta: Router, private messageService: MessageService) { }

  ngOnInit() {
  }

  cargarAccionistas(event) {
    this.filtroAccionistaLista = [];
    return this.parametricasService.getAccionistasSinContratista(this.lineaBase.idConcesionario, this.lineaBase.idEncabezado).subscribe((data: {}) => {
      this.accionistasLista = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.accionistasLista.length; i++) {
        let filtro = this.accionistasLista[i];
        let item: string = String(filtro.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroAccionistaLista.push(filtro);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.accionistasLista = this.filtroAccionistaLista;
      }
    });
  }

  cargarFiltroAccionista(event) {
    this.filtroAccionistaLista = [];
    for (let i = 0; i < this.accionistasLista.length; i++) {
      let filtro = this.accionistasLista[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroAccionistaLista.push(filtro);
      }
    }
  }

  regresarListaAccionistas() {
    this.lineaBase.visibleListaAccionistas = false;
    this.lineaBase.visibleListaAccionistasInterventoria = false;
    this.lineaBase.visibleFormularioContrato = false;
  }

  filtroAccionistas(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  visibleAgregarAccionista() {
    this.visibleDialogoAgregarAccionista = true;
    this.accionistaContratista = {};
    this.accionistaSeleccionado = null;
    this.idConcesionarioAccionista = "new";
  }

  cancelarAgregarAccionista() {
    this.visibleDialogoAgregarAccionista = false;
  }

  agregarAccionista(forma: NgForm) {
    let validacionParticipacion: boolean = false;
    let sumaParticipacion: number = 0;
    if (this.lineaBase.accionistas != null) {
      this.lineaBase.accionistas.forEach(element => {
        if (this.accionistaContratista.id != element.id) {
          sumaParticipacion = sumaParticipacion + element.porcentajeParticipacion;
        }
      });
      sumaParticipacion = sumaParticipacion + (this.accionistaContratista.porcentajeParticipacion / 100);
      if (sumaParticipacion > 1) {
        validacionParticipacion = true;
      }
    }
    if (!validacionParticipacion) {
      if (this.visibleDialogoAgregarAccionista) {
        this.accionistaContratista.idAccionista = this.accionistaSeleccionado.id;
        this.accionistaContratista.idContratista = this.lineaBase.idConcesionario;
        this.accionistaContratista.idEncabezado = this.lineaBase.idEncabezado;
        if (this.idConcesionarioAccionista === 'new') {
          let vara: any = this.detalleService.guardarNuevoConcesionarioAccionista(this.accionistaContratista).subscribe((data: {}) => {
            this.visibleDialogoExito = true;
            this.cargarAccionistasLista(this.lineaBase.idEncabezado);
            this.lineaBase.habilitaTabInterventoria = true;
          });
        } else {
          this.detalleService.editarConcesionarioAccionista(this.accionistaContratista, this.idConcesionarioAccionista).subscribe((data: {}) => {
            this.visibleDialogoExito = true;
            this.cargarAccionistasLista(this.lineaBase.idEncabezado);
          });
        }
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La participación de accionistas supera el 100%', life: 10000 });
    }
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.visibleDialogoAgregarAccionista = false;
    this.cargarAccionistasLista(this.lineaBase.idEncabezado);
  }

  cargarAccionistasLista(idEncabezado) {
    return this.detalleService.getAccionistaPorIdEncabezado(idEncabezado).subscribe((data: {}) => {
      this.lineaBase.accionistas = data;
      this.lineaBase.dataSourceAccionistas = new MatTableDataSource(this.lineaBase.accionistas);
    });
  }

  editarAccionista(idAccionista) {
    this.idConcesionarioAccionista = idAccionista;
    this.visibleDialogoAgregarAccionista = true;
    return this.detalleService.getAccionistaPorId(idAccionista).subscribe((data: {}) => {
      this.accionistaContratista = data;
      this.accionistaContratista.porcentajeParticipacion = this.accionistaContratista.porcentajeParticipacion * 100;
      this.accionistaSeleccionado = this.accionistaContratista.idAccionistaNavigation;
    });
  }

  irEliminarAccionista(id) {
    this.visibleConfirmarEliminarAccionista = true;
    this.idAccionistaConcesionario = id;
  }

  aceptarEliminarAccionista() {
    return this.detalleService.eliminarAccionistaPorIdContratista(this.idAccionistaConcesionario).subscribe((data: {}) => {
      this.visibleConfirmarEliminarAccionista = false;
      this.cargarAccionistasLista(this.lineaBase.idEncabezado);
      this.visibleDialogoExitoEliminarAccionistaConcesionario = true;
    });
  }

  aceptarDialogoEliminarAccionista() {
    this.visibleDialogoExitoEliminarAccionistaConcesionario = false;
  }


  cancelarEliminarAccionista() {
    this.visibleConfirmarEliminarAccionista = false;
  }

}
