import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ProyectoDTO } from 'src/app/modulo-planeacion-proyecto/planeacion-proyecto.dto';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { TrnSiNo } from 'src/app/parametricas/trn-si-no';
import { InformacionGeneralPeajesService } from 'src/app/peajes-gestion/informacion-general-peajes/informacion-general-peajes.service';
import { PjeInformacionGeneralPeajeDTORpt } from 'src/app/peajes-gestion/informacion-general-peajes/PjeInformacionGeneralPeajeDTO';
import { PjeMaOperadorPagoElectronico } from 'src/app/peajes-gestion/informacion-general-peajes/PjeMaOperadorPagoElectronico';
import { PjePeajeOperadorPagoElectronicoDTO } from 'src/app/peajes-gestion/informacion-general-peajes/PjePeajeOperadorPagoElectronicoDTO';
import { PjePeajePorProyecto } from 'src/app/peajes-gestion/trafico-peajes/PjePeajePorProyecto';
import { TraficoPeajesCargaService } from 'src/app/peajes-gestion/trafico-peajes/trafico-peajes-carga/trafico-peajes-carga.service';
import { PrediosService } from 'src/app/predios/predios.service';
import { UnidadFuncionalCarreteroService } from 'src/app/unidad-funcional-carretero/unidad-funcional-carretero.service';
import { CrrEquipoItsDTO, CrrMaEstadoEquipoItsDTO, CrrMaMedioTransmisionEquipoItsDTO, CrrMaPrKDTO, CrrMaProtocoloComunicacionEquipoItsDTO, CrrMaSentidoTraficoDTO, CrrMaTipoEquipoItsDTO, CrrMaTipoSuministroEnergeticoEquipoItsDTO } from '../equipos-its.dto';
import { EquiposItsService } from '../equipos-its.service';

@Component({
  selector: 'app-registro-equipo-its',
  templateUrl: './registro-equipo-its.component.html',
  styleUrls: ['./registro-equipo-its.component.sass']
})
export class RegistroEquipoItsComponent implements OnInit {

  blockedDocument: boolean;

  TIPO_EQUIPO_PEAJE: number = 11;
  ESTADO_OPERACION: number = 1;
  ESTADO_CONSTRUCCION: number = 2;
  esEquipoItsPeaje: boolean;

  idProyecto: number;
  idEquipoIts: number;
  soloLectura: boolean;
  proyectoSeleccionado: ProyectoDTO;

  equipoIts: CrrEquipoItsDTO;
  crrMaTipoEquipoItsDTOList: CrrMaTipoEquipoItsDTO[];
  crrMaEstadoEquipoItsDTOList: CrrMaEstadoEquipoItsDTO[];
  crrMaProtocoloComunicacionEquipoItsDTOList: CrrMaProtocoloComunicacionEquipoItsDTO[];
  crrMaTipoSuministroEnergeticoEquipoItsDTOList: CrrMaTipoSuministroEnergeticoEquipoItsDTO[];
  ipv6List: TrnSiNo[];
  crrMaMedioTransmisionEquipoItsDTOList: CrrMaMedioTransmisionEquipoItsDTO[];
  unidadesFuncionales: any = [];
  unidadesFuncionalesFiltered: any[];
  prkList: CrrMaPrKDTO[];
  crrMaSentidoTraficoDTOList: CrrMaSentidoTraficoDTO[];
  departamentos: any = [];
  filtroDepartamentos: any[];
  departamentoSeleccionado: any;
  ciudades: any[] = [];
  filtroCiudades: any[];
  ciudadSeleccionada: any;
  ipredList: TrnSiNo[];
  tieneIpred: Boolean;
  informacionGeneralPeaje: any;

  // variables para cargar lista de peajes
  filtroPeajes: any[];
  peajesPorProyecto: any[];
  peajeSeleccionado: PjePeajePorProyecto;

  // variables Operador
  visibleDialogoAgregarOperador = false;
  filtroOperador: any[];
  operadores: any[];
  operadorSeleccionado: PjeMaOperadorPagoElectronico;
  peajeOperadorPagoElectronico: PjePeajeOperadorPagoElectronicoDTO;
  mensajeOperadorDialog: string;
  operadorEditable;
  visibleConfirmarEliminarOperador;
  operadorElimina: number;
  fechaInicioOperadorConvert: Date;
  fechaMaxima: Date;

  // variables listar operadores
  operadoresElectronicos: any = [];
  displayedColumns: any[];
  operadorElectronicoEdita: PjePeajeOperadorPagoElectronicoDTO;

  //Validacion de fechas
  fechaMin: Date;
  fechaMax: Date;



  formEquipoIts;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private equiposItsService: EquiposItsService,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService,
    private unidadFuncionalCarreteroService: UnidadFuncionalCarreteroService,
    private parametricasService: ParametricasService,
    private traficoPeajesCargaService: TraficoPeajesCargaService,
    private informacionGeneralPeajesService: InformacionGeneralPeajesService) {

    // Crear formulario Predio
    this.formEquipoIts = formBuilder.group({
      nombreEquipoIts: [null, [Validators.required, Validators.maxLength(100)]],
      tipoEquipoIts: [null, [Validators.required]],
      estadoEquipoIts: [null, [Validators.required]],
      inicioOperacionEquipoIts: [null, [Validators.required]],
      protocoloComunicacion: [null, [Validators.required]],
      suministroEnergetico: [null, [Validators.required]],
      ipv6: [null, [Validators.required]],
      medioTransmision: [null, [Validators.required]],
      departamento: [null, [Validators.required]],
      municipio: [null, [Validators.required]],
      peaje: [null],
      unidadFuncional: [null, [Validators.required]],
      prk: [null, [Validators.required]],
      sentido: [null, [Validators.required]],
      distancia: [null, [Validators.required, Validators.min(0), Validators.max(1200)]],
      punto: [null, [Validators.required, Validators.min(0), Validators.max(999)]],
      longitud: [null, [Validators.required, Validators.min(-79), Validators.max(-67)]],
      latitud: [null, [Validators.required, Validators.min(-4), Validators.max(13)]],
      descripcion: [null],
      tieneIpred: [null],      
      fechaInicioPagoElectronico: [null],
    });

  }


  ngOnInit() {
    // Obtener el proyecto seleccionado
    const idParam = "idProyecto";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
    });
    const idEquipoParam = "id";
    this.ruta.params.subscribe((params) => {
      this.idEquipoIts = params[idEquipoParam];
      if (this.idEquipoIts != 0) {
        this.obtenerEquipoIts();
      } else {
        this.obtenerUnidadesFuncionales();
      }
    });
    const idParamVer = "soloLectura";
    this.ruta.params.subscribe((params) => {
      this.soloLectura = params[idParamVer] == "true";
    });
    console.log("this.soloLectura");
    console.log(this.soloLectura);
    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
      },
      (e) => { }
    );
    this.equipoIts = {};
    this.equipoIts.id = 0;
    this.prkList = [];
    let pr: CrrMaPrKDTO = {};
    pr.nombre = "PR";
    let k: CrrMaPrKDTO = {};
    k.nombre = "KM";
    this.prkList.push(pr);
    this.prkList.push(k);
    this.peajeOperadorPagoElectronico = {};
    this.obtenerListasEquipoIts();
  }

  obtenerEquipoIts() {
    this.blockedDocument = true;
    forkJoin(
      this.equiposItsService.srvConsultarEquipoItsPorId(this.idEquipoIts)
    ).subscribe(
      ([crrEquipoItsData]) => {
        this.equipoIts = crrEquipoItsData.crrEquipoIts;
        this.equipoIts.fechaEntradaOperacion = new Date(this.equipoIts.fechaEntradaOperacion);
        if (this.equipoIts.tieneIpv6) {
          this.equipoIts.Ipv6SiNo = new TrnSiNo();
          this.equipoIts.Ipv6SiNo.id = 1;
          this.equipoIts.Ipv6SiNo.nombre = 'Si';
          this.equipoIts.Ipv6SiNo.valor = true;
        } else {
          this.equipoIts.Ipv6SiNo = new TrnSiNo();
          this.equipoIts.Ipv6SiNo.id = 2;
          this.equipoIts.Ipv6SiNo.nombre = 'No';
          this.equipoIts.Ipv6SiNo.valor = false;
        }
        this.equipoIts.prKUbicacion = new CrrMaPrKDTO();
        this.equipoIts.prKUbicacion.nombre = this.equipoIts.tipoUbicacion;
        this.peajeSeleccionado = {};
        this.peajeSeleccionado.idPeaje = this.equipoIts.idPeaje;
        this.obtenerUnidadesFuncionales();
        this.actionListaTipoEquipo();
        if (this.equipoIts.tienePagoElectronico) {
          this.equipoIts.pagoElectronicoSiNo = new TrnSiNo();
          this.equipoIts.pagoElectronicoSiNo.id = 1;
          this.equipoIts.pagoElectronicoSiNo.nombre = 'Si';
          this.equipoIts.pagoElectronicoSiNo.valor = true;
          this.cargarOperadoresElectronicos();
          this.tieneIpred = true;
        } else {
          this.equipoIts.pagoElectronicoSiNo = new TrnSiNo();
          this.equipoIts.pagoElectronicoSiNo.id = 2;
          this.equipoIts.pagoElectronicoSiNo.nombre = 'No';
          this.equipoIts.pagoElectronicoSiNo.valor = false;
          this.tieneIpred = false;
        }
        console.log(crrEquipoItsData);
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  obtenerListasEquipoIts() {
    this.blockedDocument = true;
    forkJoin(
      this.equiposItsService.srvListarMaTipoEquipoIts(),
      this.equiposItsService.srvListarMaEstadoEquipoIts(),
      this.equiposItsService.srvListarMaProtocoloComunicacionEquipoIts(),
      this.equiposItsService.srvListarMaTipoSuministroEnergeticoEquipoIts(),
      this.parametricasService.getTrnSiNo(),
      this.equiposItsService.srvListarMaMedioTransmisionEquipoIts(),
      this.equiposItsService.srvListarMaSentidoTrafico(),
      this.equiposItsService.getDepartamentos(this.idProyecto)
    ).subscribe(
      ([tipoEquipoItsData,
        estadoEquipoItsData,
        protocoloComunicacionEquipoItsData,
        tipoSuministroEnergeticoEquipoItsData,
        ipv6Data,
        medioTransmisionEquipoItsData,
        sentidoTraficoData,
        departamentoData]) => {
        this.crrMaTipoEquipoItsDTOList = tipoEquipoItsData.listaCrrMaTipoEquipoIts;
        this.crrMaEstadoEquipoItsDTOList = estadoEquipoItsData.listaCrrMaEstadoEquipoIts;
        this.crrMaProtocoloComunicacionEquipoItsDTOList = protocoloComunicacionEquipoItsData.listaCrrMaProtocoloComunicacionEquipoIts;
        this.crrMaTipoSuministroEnergeticoEquipoItsDTOList = tipoSuministroEnergeticoEquipoItsData.listaCrrMaTipoSuministroEnergeticoEquipoIts;
        this.ipv6List = ipv6Data.sinoList;
        this.ipv6List.shift();
        this.ipredList = ipv6Data.sinoList;
        this.crrMaMedioTransmisionEquipoItsDTOList = medioTransmisionEquipoItsData.listaCrrMaMedioTransmisionEquipoIts;
        this.crrMaSentidoTraficoDTOList = sentidoTraficoData.listaCrrMaSentidoTrafico;
        // Cargar el objeto del dropdown Departamento
        this.departamentos = departamentoData;
        // console.log('departamentos', this.departamentos);
        this.departamentoSeleccionado = this.departamentos.find(
          (t) => t.id === this.equipoIts.idDepartamento
        );

        if (this.departamentoSeleccionado != null) {
          // Simular que elegi un item del dropdown Departamento
          this.formEquipoIts.get('departamento').setValue(this.departamentoSeleccionado);
          forkJoin(
            this.equiposItsService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id)
          ).subscribe(([ciudadesData]: any) => {
            // Cargar el objeto del dropdown Municipio
            // console.log('ciudadesss data', ciudadesData);
            this.ciudades = ciudadesData;
            this.ciudadSeleccionada = this.ciudades.find(
              (t) => t.id === this.equipoIts.idMunicipio
            );
          }, error => {
            console.error(error);
            this.principalComponent.cargarErrorServicio(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          }
          );
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  obtenerUnidadesFuncionales() {
    this.blockedDocument = true;
    forkJoin(
      this.unidadFuncionalCarreteroService.getUnidadesFuncionalesPorProyecto(this.idProyecto)
    ).subscribe(
      ([unidadFuncionalData]) => {
        this.unidadesFuncionales = unidadFuncionalData.unidadesFuncionales;
        // Cargar el objeto del dropdown UnidadFuncional
        this.equipoIts.unidadFuncionalDto = this.unidadesFuncionales.find(
          (t) => t.idUnidadFuncional === this.equipoIts.idUnidadFuncional
        );

        // Simular que elegi un item del dropdown UnidadFuncional
        this.formEquipoIts.get('unidadFuncional').setValue(this.equipoIts.unidadFuncionalDto);
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }


  cargarUnidadesFuncionales(event) {
    this.unidadesFuncionalesFiltered = [];
    let existeFiltro: boolean;
    for (let i = 0; i < this.unidadesFuncionales.length; i++) {
      let brand = this.unidadesFuncionales[i];
      let item: string = String(brand.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.unidadesFuncionalesFiltered.push(brand);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.unidadesFuncionales = this.unidadesFuncionalesFiltered;
    }
  }

  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    let existeFiltro: boolean;
    for (let i = 0; i < this.departamentos.length; i++) {
      const filtro = this.departamentos[i];
      if (event != null) {
        if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroDepartamentos.push(filtro);
          existeFiltro = true;
        }
      }
    }
    if (existeFiltro) {
      this.departamentos = this.filtroDepartamentos;
    }
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      const filtro = this.departamentos[i];
      if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  limpiarCiudades(event) {
    this.ciudadSeleccionada = null;
  }

  cargarCiudades(event) {
    this.filtroCiudades = [];
    if (this.departamentoSeleccionado != null) {
      return this.equiposItsService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id).subscribe((data: any) => {
        this.ciudades = data;
        let existeFiltro: boolean;
        for (let i = 0; i < this.ciudades.length; i++) {
          const filtro = this.ciudades[i];
          if (event != null) {
            if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              this.filtroCiudades.push(filtro);
              existeFiltro = true;
            }
          }
        }
        if (existeFiltro) {
          this.ciudades = this.filtroCiudades;
        }
      });
    }
  }

  cargarFiltroCiudades(event) {
    this.filtroCiudades = [];
    for (let i = 0; i < this.ciudades.length; i++) {
      const filtro = this.ciudades[i];
      if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudades.push(filtro);
      }
    }
  }

  actionListaTipoEquipo() {
    if (this.equipoIts.tipoEquipoIts.id === this.TIPO_EQUIPO_PEAJE) {
      this.esEquipoItsPeaje = true;
      this.blockedDocument = true;
      // Inicializar listas parametricas y DTOs
      forkJoin(
        this.traficoPeajesCargaService.getPeajes(this.idProyecto)
      ).subscribe(
        ([peajeData]) => {
          this.peajesPorProyecto = peajeData.peajes;
          // Cargar el objeto del dropdown UnidadFuncional
          this.peajeSeleccionado = this.peajesPorProyecto.find(
            (t) => t.idPeaje === this.equipoIts.idPeaje
          );
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    } else {
      this.esEquipoItsPeaje = false;
      this.tieneIpred = false;
      this.equipoIts.pagoElectronicoSiNo = null;
      this.equipoIts.tienePagoElectronico = false;
      this.equipoIts.idPeaje = null;
    }
  }

  actionListaEstado() {
    this.equipoIts.fechaEntradaOperacion = null;
    if (this.equipoIts.estadoEquipoIts.id === this.ESTADO_OPERACION) {
      this.fechaMax = new Date();
      this.fechaMin = null;
    } else {
      if (this.equipoIts.estadoEquipoIts.id === this.ESTADO_CONSTRUCCION) {
        this.fechaMax = null;
        this.fechaMin = new Date();
      }
    }
  }


  cargarPeajes(event) {
    this.blockedDocument = true;

    this.filtroPeajes = [];
    return this.traficoPeajesCargaService.getPeajes(this.idProyecto).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida !== 0) {
            this.peajesPorProyecto = result.peajes;
            this.equipoIts.pagoElectronicoSiNo = null;
          } else {
          }
        } else {
        }
      },
      error => {
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  irAgregarOperador() {
    this.peajeOperadorPagoElectronico = new PjePeajeOperadorPagoElectronicoDTO();
    this.operadorSeleccionado = new PjeMaOperadorPagoElectronico();
    this.mensajeOperadorDialog = "Agregar Operador";
    this.operadorEditable = false;
    this.visibleDialogoAgregarOperador = true;
    this.peajeOperadorPagoElectronico.horaInicio = 0;
    this.peajeOperadorPagoElectronico.minutoInicio = 0;

  }

  cargarOperadores(event) {
    this.blockedDocument = true;
    this.filtroOperador = [];
    return this.informacionGeneralPeajesService.getOperadores().subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida !== 0) {
            this.operadores = result.operadoresPeaje;
          } else {
          }
        } else {
        }
      },
      error => {
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }



  cancelarDialogoAgregarOperador() {
    this.visibleDialogoAgregarOperador = false;
  }

  aceptarAgregarOperador() {
    this.visibleDialogoAgregarOperador = false;
    this.peajeOperadorPagoElectronico.idOperador = this.operadorSeleccionado;
    this.peajeOperadorPagoElectronico.idPeaje = this.peajeSeleccionado.idPeaje;
    this.guardarOperadorElectronico();

  }



  guardarOperadorElectronico() {
    this.blockedDocument = true;
    forkJoin(
      this.informacionGeneralPeajesService.guardaOperadorElectronico(this.peajeOperadorPagoElectronico)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          this.cargarOperadoresElectronicos();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarOperadoresElectronicos() {
    // Bloquear pantalla
    this.blockedDocument = true;
    this.tieneIpred = this.equipoIts.pagoElectronicoSiNo.valor;
    if (typeof this.peajeSeleccionado != 'undefined') {
      if (this.tieneIpred) {
        this.equipoIts.tienePagoElectronico = true;
        forkJoin(
          this.informacionGeneralPeajesService.cargaOperadoresElectronicosPeaje(this.peajeSeleccionado.idPeaje),
          this.informacionGeneralPeajesService.cargaInformacionGeneralPeaje(this.peajeSeleccionado.idPeaje, this.idProyecto)
        ).subscribe(([result, dataPeaje]) => {
          if (result != null && result.respuestaServicio != null) {
            // Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              // recarga información según peaje seleccionado                    
              this.operadoresElectronicos = result.peajeOperadorPagoElectronico;
            }

          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
          this.informacionGeneralPeaje = dataPeaje;          
          if (this.informacionGeneralPeaje.informacionGeneralPeajes.fechaInicioPagoElectronico != null) {
            this.equipoIts.fechaInicioPagoElectronico = new Date(this.informacionGeneralPeaje.informacionGeneralPeajes.fechaInicioPagoElectronico);
          }else{
            this.equipoIts.fechaInicioPagoElectronico = null;
          }
        },
          error => {
            this.blockedDocument = false;
          },
          () => {
            // Desbloquear pantalla
            this.blockedDocument = false;
          }
        );
      } else {
        this.equipoIts.tienePagoElectronico = false;
        this.blockedDocument = false;
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar un peaje', life: 10000 });
      this.blockedDocument = false;
      this.tieneIpred = false;
      this.equipoIts.pagoElectronicoSiNo = null;
    }

  }

  irEditarOperador(id: number) {

    this.mensajeOperadorDialog = "Editar Operador";
    this.operadorEditable = true;
    this.operadorElectronicoEdita = this.operadoresElectronicos.find(x => x.id === id);
    // crea nuevo objeto PjePeajeOperadorPagoElectronicoDTO
    this.peajeOperadorPagoElectronico = new PjePeajeOperadorPagoElectronicoDTO();
    this.peajeOperadorPagoElectronico.id = this.operadorElectronicoEdita.id;
    this.peajeOperadorPagoElectronico.idOperador = this.operadorElectronicoEdita.idOperador;
    this.peajeOperadorPagoElectronico.fechaInicio = new Date(this.operadorElectronicoEdita.fechaInicio);
    this.peajeOperadorPagoElectronico.horaInicio = this.operadorElectronicoEdita.horaInicio;
    this.peajeOperadorPagoElectronico.minutoInicio = this.operadorElectronicoEdita.minutoInicio;
    if (this.operadorElectronicoEdita.fechaFinFormat != 'Vigente') {
      this.peajeOperadorPagoElectronico.fechaFin = new Date(this.operadorElectronicoEdita.fechaFin);
      this.peajeOperadorPagoElectronico.horaFin = this.operadorElectronicoEdita.horaFin;
      this.peajeOperadorPagoElectronico.minutoFin = this.operadorElectronicoEdita.minutoFin;
    }
    this.operadorSeleccionado = this.operadorElectronicoEdita.idOperador;
    this.visibleDialogoAgregarOperador = true;
  }

  irConfirmarEliminarOperador(id: number) {
    this.visibleConfirmarEliminarOperador = true;
    this.operadorElimina = id;
  }

  cancelarEliminarOperador() {
    this.visibleConfirmarEliminarOperador = false;
  }

  aceptarEliminarOperador() {

    this.blockedDocument = true;

    this.informacionGeneralPeajesService.eliminarOperador(this.operadorElimina).subscribe(result => {
      if (result != null && result.respuestaServicio != null) {
        if (result.respuestaServicio.codigoSalida === 1) {
          this.cargarOperadoresElectronicos();
          this.messageService.add({ severity: 'success', summary: 'Información', detail: 'Registro Eliminado Correctamente', life: 10000 });
        } else {
          console.error(result.respuestaServicio.mensajeSalida);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      }
    },
      error => {
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );


    this.visibleConfirmarEliminarOperador = false;
  }

  guardarEquipoIts() {
    let error: boolean = true;
    if (this.formEquipoIts.valid) {
      this.equipoIts.idTipo = this.equipoIts.tipoEquipoIts.id;
      this.equipoIts.idEstado = this.equipoIts.estadoEquipoIts.id;
      this.equipoIts.idProtocoloComunicacion = this.equipoIts.protocoloComunicacionEquipoIts.id;
      this.equipoIts.idTipoSuministroEnergetico = this.equipoIts.tipoSuministroEnergeticoEquipoIts.id;
      this.equipoIts.tieneIpv6 = this.equipoIts.Ipv6SiNo.valor;
      this.equipoIts.idMedioTransmision = this.equipoIts.medioTransmisionEquipoIts.id;
      this.equipoIts.idUnidadFuncional = this.equipoIts.unidadFuncionalDto.idUnidadFuncional;
      this.equipoIts.tipoUbicacion = this.equipoIts.prKUbicacion.nombre;
      this.equipoIts.idSentidoTrafico = this.equipoIts.sentidoTraficoDTO.id;
      this.equipoIts.idMunicipio = this.ciudadSeleccionada.id;
      this.equipoIts.idProyecto = this.idProyecto;
      if (this.esEquipoItsPeaje) {
        if (typeof this.peajeSeleccionado != 'undefined') {
          this.equipoIts.idPeaje = this.peajeSeleccionado.idPeaje;
          if (typeof this.equipoIts.fechaInicioPagoElectronico != 'undefined') {
            if (this.equipoIts.pagoElectronicoSiNo != null && this.equipoIts.fechaInicioPagoElectronico != null) {
              error = false;
            }
          } else {
            if (this.equipoIts.pagoElectronicoSiNo != null) {
              error = false;
            }
          }
        }
      } else {
        error = false;
      }
      if (!error) {
        console.log(this.equipoIts);
        this.blockedDocument = true;
        forkJoin(
          this.equiposItsService.srvGuardarEquipoIts(this.equipoIts)
        ).subscribe(
          ([data]) => {
            console.log(data);
            if (data.respuestaServicio.codigoSalida === 1) {
              this.messageService.add({
                severity: 'success',
                summary: 'Información',
                detail: 'El dispositivo se registro exitosamente.',
                life: 10000,
              });
              this.bntVolver();
            }
          },
          (error) => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            // Desbloquear pantalla
            this.blockedDocument = false;
          }
        );
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Debe diligenciar todos los campos marcados con (*).',
          life: 10000,
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Debe diligenciar todos los campos marcados con (*).',
        life: 10000,
      });
    }


  }

  bntVolver() {
    const ruta =
      'carreteros/infGeneral/proyectoHome/' +
      this.idProyecto +
      '/equiposIts/' +
      this.idProyecto;
    this.router.navigate([ruta]);
  }

}

