import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { InterventoriaCarreteroService } from './interventoria-carretero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Contrato } from '../concesionario-carretero/contrato';
import { Contratista } from '../concesionario-carretero/contratista';
import { Accionista } from '../concesionario-carretero/accionista';
import { AccionistaContratista } from '../concesionario-carretero/accionista-contratista';
import { ParametricasService } from './../parametricas/parametricas.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { ConcesionarioCarreteroService } from '../concesionario-carretero/concesionario-carretero.service';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-interventoria-carretero',
  templateUrl: './interventoria-carretero.component.html',
  styleUrls: ['./interventoria-carretero.component.sass']
})
export class InterventoriaCarreteroComponent implements OnInit {

  contratos: any = [];

  displayedColumns: string[] = ['numero', 'objeto', 'valorInicial', 'fechaSuscripcion', 'activa', 'acciones'];



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public lineaBase: ProyectoLineaBaseComponent, private interventoriaCarreteroService: InterventoriaCarreteroService,
    private route: ActivatedRoute, private ruta: Router, private concesionarioService: ConcesionarioCarreteroService,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    if (this.lineaBase.idProyecto != "new") {
      this.cargarContratos(this.lineaBase.idProyecto);
    }
  }

  cargarContratos(id: number) {
    return this.interventoriaCarreteroService.getContratoInterventoriaPorIdProyecto(id, 2).subscribe((data: {}) => {
      this.lineaBase.habilitaTabUnidadFuncional = true;
      this.contratos = data;
      this.lineaBase.dataSourceContratos = new MatTableDataSource(this.contratos);
      if (this.contratos != null) {
        if (this.contratos.length != 0) {
          this.lineaBase.habilitaTabUnidadFuncional = true;
        }
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  editarContrato(idContrato, contratoActivo) {
    this.cargarContrato(idContrato);
    this.lineaBase.interventoriaVigente = contratoActivo;
  }

  onNew() {
    this.lineaBase.contrato = {};
    this.lineaBase.interventoria = {};
    this.lineaBase.nombreInterventoria = null;
    this.lineaBase.habilitaBotonCrearInterventoria = false;
    this.lineaBase.existeInterventoria = false;
    this.lineaBase.visibleListaContratos = false;
    this.lineaBase.idContratoInterventoria = "new";
    this.lineaBase.interventoriaVigente = true;
  }

  public cargarContrato(id: number) {
    this.interventoriaCarreteroService.getContratoInterventoriaPorId(id, 2).subscribe((data: {}) => {
      this.lineaBase.contrato = data;
      if (this.lineaBase.contrato.id != 0) {
        this.interventoriaCarreteroService.getContratistaInterventoriaPorIdContrato(this.lineaBase.contrato.id).subscribe((data: {}) => {
          this.lineaBase.interventoria = data;
          if (this.lineaBase.interventoria.id == 0) {
            this.lineaBase.existeInterventoria = false;
          } else {
            this.lineaBase.existeInterventoria = true;
          }
          this.lineaBase.idContratoInterventoria = this.lineaBase.contrato.id;
          this.lineaBase.habilitaBotonCrearInterventoria = true;
          this.lineaBase.visibleListaContratos = false;
          if (this.lineaBase.interventoria.id != 0) {
            this.lineaBase.idInterventoria = this.lineaBase.interventoria.id;
            this.lineaBase.nombreInterventoria = this.lineaBase.interventoria.nombre;
            this.lineaBase.existeInterventoria = true;
            this.lineaBase.departamentoSeleccionado = this.lineaBase.interventoria.idDepartamentoNavigation;
            this.lineaBase.ciudadSeleccionada = this.lineaBase.interventoria.idMunicipioUbicacionNavigation;
            this.cargarComposicionAccionariaLista(this.lineaBase.interventoria.id);
            if (this.lineaBase.accionistas != null) {
              this.lineaBase.habilitaTabUnidadFuncional = true;
            }
          } else {
            this.lineaBase.idInterventoria = "new";
          }
        }, error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        });
      } else {
        this.lineaBase.idContratoInterventoria = "new";
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarComposicionAccionariaLista(idContratista) {
    return this.concesionarioService.getComposicionAccionariaPorIdContratista(idContratista).subscribe((data: {}) => {
      this.lineaBase.composicionAccionariaLista = data;
      this.lineaBase.dataSourceComposicionAccionaria = new MatTableDataSource(this.lineaBase.composicionAccionariaLista);
    });
  }

  filtroContratos(filterValue: string) {
    this.lineaBase.dataSourceContratos.filter = filterValue.trim().toLowerCase();

    if (this.lineaBase.dataSourceContratos.paginator) {
      this.lineaBase.dataSourceContratos.paginator.firstPage();
    }
  }

  validaInterventoriaActual() {
    return false;
  }

}
