import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
@Injectable()
export class OrfeoConsultaService {

    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

 
   /**
   * Metodo que carga la informacion de la consulta
   * @param numeroRadicado 
   */
   public getConsultaRadicado(numeroRadicado) {
    return this.http.get(this.END_POINT + '/orfeo/consulta/' + numeroRadicado ) 
    .pipe(
        retry(1));
    } 

}
