import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ProyectoCarretero } from '../informacion-proyecto-carretero/proyectoCarretero';
import { ProyectoCarreteroHistoricoDetalle } from './proyectoCarreteroHistoricoDetalle';


@Injectable({
    providedIn: 'root'
})
export class InformacionProyectoCarreteroService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public guardarNuevoProyecto(data: ProyectoCarretero) {
       return this.http.post(this.END_POINT + '/TrnProyecto', data)
            .pipe(
                retry(1));
    }

    public editarProyecto(data, idProyecto) {
        return this.http.put(this.END_POINT + '/TrnProyecto/'+idProyecto, data)
            .pipe(
                retry(1));
    }


    public getProyectoPorId(id) {
        return this.http.get(this.END_POINT + '/TrnProyecto/' + id)
            .pipe(
                retry(1));
    }

    public getEtapaProyectoPorId(id) {
        return this.http.get(this.END_POINT + '/TrnProyecto/etapa/' + id)
            .pipe(
                retry(1));
    }

    public listarHistoricoProyectoPorId(id) {
        return this.http.get(this.END_POINT + '/TrnProyecto/historico/list/' + id)
            .pipe(
                retry(1));
    }

    public getHistoricoProyectoPorId(id) {
        return this.http.get(this.END_POINT + '/TrnProyecto/historico/' + id)
            .pipe(
                retry(1));
    }

    public guardarHistoricoProyecto(data: ProyectoCarretero) {
       return this.http.post(this.END_POINT + '/TrnProyecto/historico/', data)
            .pipe(
                retry(1));
    }

    public editarHistoricoProyecto(data, idProyecto) {
        return this.http.put(this.END_POINT + '/TrnProyecto/historico/'+idProyecto, data)
            .pipe(
                retry(1));
    }

    public eliminarHistoricoProyectoPorId(id, idProyecto) {
        return this.http.delete(this.END_POINT + '/TrnProyecto/historico/'+id+'/'+idProyecto)
            .pipe(
                retry(1));
    }



    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }


}
