import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AgendamientoConsultasService {

  public END_POINT = '';

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }


  public getAgendamientoList(data) {
    return this.http.post(this.END_POINT + '/Citas/list', data )
      .pipe(
        retry(1));
  }


}
