import { Component, OnInit } from '@angular/core';
import { FuncionarioInviasDetalleService } from './funcionario-invias-detalle.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneral, Encabezado, ConceptoSolicitado } from './informacionGeneral';
import { EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { NgForm } from '@angular/forms';



  // Componente de detalle - Seguimiento
@Component({
  selector: 'app-funcionario-invias-detalle.component',
  templateUrl: './funcionario-invias-detalle.component.html',
  styleUrls: ['./funcionario-invias-detalle.component.sass'],
  providers: [FuncionarioInviasDetalleService]
})
export class FuncionarioInviasDetalleComponent implements OnInit {

  loading: boolean;
  visibleAgregar: boolean;
  visibleDetalle: boolean;
  visibleDetalleConcepto: boolean;
  visibleDetalleLimpiar: boolean;
  visibleEliminarConcepto:  boolean;
  visibleModalErrorBusqueda: boolean;
  visibleModalErrorBusquedaConcepto: boolean;
  visibleDialogoConcepto: boolean;
  visibleDialogoAgregarSolicitante: boolean;
  title: any;
  listaConceptos: any = [];
  temporal: any;
  idEliminar: any;

  habilitarAdjunto: boolean;
  habilitarAdjuntoConcepto: boolean;
  habilitarNombre: boolean;
  visibleConfirmarEliminar: boolean;

  //Lisa de Tipo Documento - modal Personas
  tipoDocumentos: any = [];
  tipoDocumentosTemp: any = [];
  filtroTipoDocumentos: any[];
  tipoDocumentoSeleccionada: any = { id: 0, nombre: ''};

  //Lisa de personas - modal Personas
  personas: any = [];
  personasTemp: any = [];
	personaEliminar: any;
	filtroPersonas: any[];
  personaSeleccionada: any;
  tipoSolicitud: any;
  listaPersonas: any = [];
  idTramiteEditar: any;
  idSolicitudIniviasEditar: any;


  //modal concepto - Lista Proyectos
  proyectos: any = [];
  proyectosTemp: any = [];
  filtroProyectos: any[];
  proyectoSeleccionada: any = { id: 0, nombre: '' , concesionario: '', idConcesionario: '', nit:  ''};

  //modal concepto - Lista Concesionario
  concesionarios: any = [];
  concesionariosTemp: any = [];
  filtroConcesionarios: any[];
  concesionarioSeleccionada: any = { id: 0, nombre: ''};
  seleccionTipoDocumento: any;

  informacionGeneral: InformacionGeneral = {};
  conceptoSolicitado: ConceptoSolicitado = {};

  encabezado : Encabezado = {};
  concepto : ConceptoSolicitado = {};

	displayedConceptos = [
		{ field: 'proyecto', header: 'Proyecto' },
		{ field: 'concesionario', header: 'Concesionario' },
    { field: 'numeroRadicado', header: 'Radicado Salida' },
    { field: 'fechaRadicadoStr', header: 'Fecha Radicado Salida' },
    { field: 'solicitudConcepto', header: 'Solicitud de Concepto' },
	];

  constructor(
    private _service: FuncionarioInviasDetalleService,
    private router: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent, 
    private parametricasService: ParametricasService
    ) {
    }

  ngOnInit() {
    this.proyectoSeleccionada = { id: 0, nombre: 'Seleccione...' , concesionario: '', idConcesionario: '', nit:  ''};
    this.loading = false;
    this.tipoSolicitud = localStorage.getItem('tipoSolicitudTramite');
    this.cargarTipoDocumento();
    this.visibleEliminarConcepto  = true;
    this.visibleModalErrorBusqueda = false;
    this.visibleModalErrorBusquedaConcepto = false;
    this.visibleDialogoConcepto = false;
    this.habilitarAdjuntoConcepto = false;
    this.visibleDialogoAgregarSolicitante = false;
   
    if(this.tipoSolicitud == "onNew"){
      this.title = "Creación";
      this.visibleAgregar = false;
      this.visibleDetalle = false;
      this.visibleDetalleLimpiar = false;
      this.personaSeleccionada = { id: 0, nombreCompleto: 'Seleccione...', numeroDocumento: ''};
      this.tipoDocumentoSeleccionada = { id: 0, nombre: ''};
      this.habilitarNombre = true;
      this.habilitarAdjunto = false;
      this.informacionGeneral.asunto = '';
      this.informacionGeneral.id = '';

    }else{
      this.personaSeleccionada = { id: 0, nombreCompleto: 'Seleccione...', numeroDocumento: ''};
      this.tipoDocumentoSeleccionada = { id: 0, nombre: ''};
      this.idTramiteEditar = localStorage.getItem('idTramiteEditar');
      this.idSolicitudIniviasEditar = localStorage.getItem('idSolicitudIniviasEditar');
      this.title = "Edición";
      this.visibleAgregar = true;
      this.visibleDetalle = true;
      this.visibleDetalleLimpiar = false;
      this.habilitarNombre = true;
      this.habilitarAdjunto = true;
      this.cargarInformacionEditar(this.idSolicitudIniviasEditar);
      this.onValidarFinalizacion();
    }
    this.concesionarioSeleccionada = { id: 0, nombre: 'Seleccione...'};

    

  }

  /**
   * Metodo para validar la finalizacion de un proceso
   */
  onValidarFinalizacion(){
    this.loading = true;
    this._service.getConsultaValidarFinalizacion(this.idSolicitudIniviasEditar).subscribe((data: {}) => {
      this.temporal = data;
      if(this.temporal != null && this.temporal.mensajeSalida != null && this.temporal.mensajeSalida == "El estado esta Finalizado"){
        this.visibleAgregar = false;
      }else{
        this.visibleAgregar = true;
      }
     
      this.loading = false;
    },error => {
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
    });
  }


  /**
   * Metodo que limpia los valores seleccionados
   */
  resetValoresConcepto(){
    this.conceptoSolicitado.asunto = null;
    this.conceptoSolicitado.fechaRadicadoStr = null;
    this.conceptoSolicitado.fechaRadicado = null;
    this.conceptoSolicitado.adjunto = null;
    this.conceptoSolicitado.numeroRadicado = null;
    this.visibleDetalleConcepto = true;
  }

    /**
   * Metodo que limpia los valores seleccionados
   */
  resetValoresPrincipal(){
    this.informacionGeneral.asunto = null;
    this.informacionGeneral.fechaRadicadoStr = null;
    this.informacionGeneral.fechaRadicado = null;
    this.informacionGeneral.adjunto = null;
    this.informacionGeneral.numeroRadicado = null;
    this.visibleDetalleLimpiar = false;
    
  }

  /**
   * Metodo que carga la informacion a editar.
   * @param idSolicitudInivias
   */
  cargarInformacionEditar(idSolicitudInivias){
    this.seleccionTipoDocumento = "";
    this._service.getConsultaInformacionTramite(idSolicitudInivias).subscribe((data: {}) => {
      this.temporal = data;
      if(this.temporal != undefined && this.temporal != null){
        if(this.temporal.idPersonaJuridicaSolicitante != null && this.temporal.idPersonaJuridicaSolicitante != ""){
          this.tipoDocumentoSeleccionada.id = 'NIT';
          this.cargarPersonasSolo(this.temporal.idPersonaJuridicaSolicitante);
        }else{
          this.tipoDocumentoSeleccionada.id = 'CC';
          this.cargarPersonasSolo(this.temporal.idPersonaSolicitante);
        }
        this.informacionGeneral.id = this.temporal.idTramite;
        this.informacionGeneral.idSolicitudInivias = this.temporal.idSolicitudInivias;
        this.informacionGeneral.asunto = this.temporal.asunto;
        this.informacionGeneral.fechaRadicado = this.temporal.fechaRadicado;
        this.informacionGeneral.fechaRadicadoStr = this.temporal.fechaRadicadoStr;
        this.informacionGeneral.numeroRadicado = this.temporal.numeroRadicado;
        this.informacionGeneral.adjunto = this.temporal.rutaAdjunto;
        this.listaConceptos = this.temporal.conceptos;
      }else{
          this.messageService.add({
              severity: 'warn',
              summary: 'Advertencia',
              detail: "El sistema no encontró información para la edición.",
              life: 10000
            });
      }            
      this.loading = false;
    }, (error)=>{
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
    });
  }


   /**
   * Metodo que retorna a la pagina anterior de tramites
   */
  bntVolver() {
    const ruta = "/cargaExtrapesadaFun";
    this.router.navigate([ruta]);
  }

  /**
   * Metodo que retorna a la pagina anterior de tramites
   */
  bntVolverEncabezado(){
    const ruta = "/cargaExtrapesadaFun";
    this.router.navigate([ruta]);
  }

  /**
   * Metodo que muestra el modal de agregar concepto
   */
  visibleAgregarConcepto(){
    this.loading = false;
    this.visibleDialogoConcepto = true;
    this.visibleDetalleConcepto = true;
    this.conceptoSolicitado = {};
    this.proyectoSeleccionada = { id: 0, nombre: 'Seleccione...' , concesionario: '', idConcesionario: '', nit:  ''};
    this.concesionarioSeleccionada = { id: 0, nombre: 'Seleccione...'};
  }

  /**
   * Metodo que elimina un concepto
   * @param registro 
   */
  onDeleteConcepto(registro){
    this.loading = true;
    this._service.getConsultaValidar(registro.id).subscribe((data: {}) => {
      this.temporal = data;
      if( this.temporal.isEliminar != null && this.temporal.isEliminar != ""){
        if(this.temporal.isEliminar){
          this.visibleConfirmarEliminar = true;  
          this.idEliminar = registro.id;      
        }else{
          this.visibleConfirmarEliminar = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: this.temporal.mensaje,
            life: 10000
          });
        }
      }else{
        this.visibleConfirmarEliminar = false;
        this.messageService.add({
          severity: 'warn',
          summary: 'Advertencia',
          detail: "El sistema no puede eliminar el registro, hay información asociada a este radicado.",
          life: 10000
        });
      }
      this.loading = false;
    },error => {
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
    });
  }

  /**
   * Metodo que cancela la confirmacion de la modal
   */
  cancelarEliminar(){
    this.visibleConfirmarEliminar = false;  
  }

  /**
   * Metodo que ejecuta el cancelar
   */
  aceptarEliminar(){
    if(this.idEliminar!= undefined && this.idEliminar!= null){
      this.visibleConfirmarEliminar = false;
      this.loading = true;
      this._service.deleteProyectoComunicacion(this.idEliminar).subscribe((data: {}) => {
        this.temporal = data;
        if( this.temporal.isEliminar != null && this.temporal.isEliminar != ""){
          if(this.temporal.isEliminar){
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: this.temporal.mensaje,
              life: 10000
            });
            this.loading = true;
            this.cargarInformacionEditar(this.idSolicitudIniviasEditar);
          }
        }else{
          this.messageService.add({
            severity: 'warn',
            summary: 'Advertencia',
            detail: "El sistema no puede eliminar el registro, hay información asociada a este radicado.",
            life: 10000
          });
        }
      },error => {
          this.loading = false;
          this.visibleConfirmarEliminar = true;
          this.principalComponent.cargarErrorServicio(error);
      });
      
    }
  }

  /**
   * Metodo que guarda el encabezado
   */
  guardarEncabezado(){
    this.loading = true;
    this.encabezado.asunto = this.informacionGeneral.asunto;
    this.encabezado.numeroRadicado = this.informacionGeneral.numeroRadicado;
    this.encabezado.fechaRadicado = this.informacionGeneral.fechaRadicado;
    this.encabezado.rutaAdjunto = this.informacionGeneral.adjunto;
    if(this.tipoDocumentoSeleccionada.id == "NIT"){
      this.encabezado.idPersonaJuridicaSolicitante = this.personaSeleccionada.id;
    }else{
      this.encabezado.idPersonaSolicitante = this.personaSeleccionada.id;
    }

    this._service.guardarEncabezado(this.encabezado).subscribe((data: {}) => {
      this.temporal = data;
      if(this.temporal.objeto != undefined && this.temporal.objeto != null && this.temporal.objeto.idTramite != null && 
        this.temporal.objeto.idTramite != ""){
          this.informacionGeneral.id = this.temporal.objeto.idTramite;
          this.informacionGeneral.adjunto = this.temporal.objeto.rutaAdjunto;
          this.informacionGeneral.idSolicitudInivias = this.temporal.objeto.idSolicitudInivias;
          this.informacionGeneral.asunto = this.temporal.objeto.asunto;
          this.informacionGeneral.fechaRadicadoStr = this.temporal.objeto.fechaRadicadoStr;
          this.informacionGeneral.fechaRadicado = this.temporal.objeto.fechaRadicado;
          this.informacionGeneral.numeroRadicado = this.temporal.objeto.numeroRadicado;
      }

      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: "Se generó el encabezado",
        life: 10000
      });
      this.visibleDetalle = true;
      this.habilitarNombre = true;
      this.visibleAgregar = true;
      this.visibleDialogoConcepto = false;
      this.loading = false;
    }, (error)=>{
      this.loading = false;
      this.visibleDialogoConcepto = false;
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  
  /**
   * Metodo que guarda el proceso de respuesta
   * @param forma 
   */
  guardarConcepto(forma: NgForm){
    this.loading = true;

    if(this.proyectoSeleccionada!= undefined && this.proyectoSeleccionada.id != 0 
      && this.conceptoSolicitado.numeroRadicado != undefined && this.conceptoSolicitado.numeroRadicado!= null
      && this.conceptoSolicitado.numeroRadicado != "" && this.conceptoSolicitado.asunto != undefined 
      && this.conceptoSolicitado.asunto != null && this.conceptoSolicitado.asunto != ""  ){

        this.visibleDialogoConcepto = false;
        this.visibleDetalleConcepto = false;
        this.concepto.idTramite = this.informacionGeneral.id;
        this.concepto.idSolicitudInivias = this.informacionGeneral.idSolicitudInivias;
        this.concepto.numeroRadicado = this.conceptoSolicitado.numeroRadicado;
        this.concepto.fechaRadicado = this.conceptoSolicitado.fechaRadicado;
        this.concepto.asunto = this.conceptoSolicitado.asunto;
        this.concepto.rutaAdjunto = this.conceptoSolicitado.adjunto;
        this.concepto.idProyecto = this.proyectoSeleccionada.id;
        this.concepto.idConcesionario = this.proyectoSeleccionada.idConcesionario;

        var encontro = false;
        for (let i = 0; i < this.listaConceptos.length; i++) {
          let filtro = this.listaConceptos[i];
          if(filtro.idProyecto == this.concepto.idProyecto && this.concepto.idSolicitudInivias == filtro.idSolicitudInivias){
            encontro = true;
            break;
          }
        }

        if(encontro){
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Ya existe el proyecto con un concepto en proceso iniciado/finalizado.',
            life: 10000
          }); 
          this.loading = false;   
        }else{
          this._service.getValidarRespuestaProyecto(this.concepto.idSolicitudInivias, this.concepto.idProyecto).subscribe((data: {}) => {
            this.temporal = data;
            if(this.temporal.mensajeSalida != undefined && this.temporal.mensajeSalida != null ){
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: this.temporal.mensajeSalida,
                  life: 10000
                }); 
                this.loading = false;       
            }else{
              this._service.guardarConcepto(this.concepto).subscribe((data: {}) => {
                this.temporal = data;
                if(this.temporal.objeto != undefined && this.temporal.objeto != null && this.temporal.objeto.length > 0){
                    this.listaConceptos =  this.temporal.objeto;              
                }
          
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: "Se generó el encabezado",
                  life: 10000
                });
                this.visibleDetalle = true;
                this.habilitarNombre = true;
                this.visibleDialogoConcepto = false;
                this.visibleAgregar = true;
                this.loading = false;
                
                this.conceptoSolicitado.asunto = '';
                this.conceptoSolicitado.fechaRadicado = '';
                this.proyectoSeleccionada  = { id: 0, nombre: 'Seleccione...' , concesionario: '', idConcesionario: '', nit:  ''};
                this.concesionarioSeleccionada = { id: 0, nombre: 'Seleccione...'};

                forma.resetForm();
               
      
              }, (error)=>{
                this.loading = false;
                this.visibleDialogoConcepto = false;
                this.principalComponent.cargarErrorServicio(error);
              });
            }
          }, (error)=>{
            this.loading = false;
            this.visibleDialogoConcepto = false;
            this.principalComponent.cargarErrorServicio(error);
          });
        }    
      }    
  }


  /**
   * Metodo que busca el radicado en orfeo
   * @param radicado 
   */
  onBuscar(radicado){
    if(radicado != null && radicado != undefined && radicado != ""){
      if(radicado.length < 14){
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El número de radicado no poseé la longitud del formato.",
          life: 10000
        });
        return ;
      }
      
      if(this.stringIsNumber(radicado)){
        this.loading = true;
        this._service.getConsultaRadicado(radicado).subscribe((data: {}) => {
          this.temporal = data;
          if(this.temporal.mensajeError!= undefined && this.temporal.mensajeError!=null){
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: this.temporal.mensajeError,
              life: 10000
            });
          }else{
            if( this.temporal.asunto != null && this.temporal.asunto != ""){
              this.informacionGeneral.asunto = this.temporal.asunto;
              this.informacionGeneral.numeroRadicado = this.temporal.numeroRadicado;
              this.informacionGeneral.fechaRadicado = this.temporal.fechaRadicado;
              this.informacionGeneral.fechaRadicadoStr = this.temporal.fechaRadicadoStr;
              this.informacionGeneral.adjunto = this.temporal.archivo;
              this.visibleModalErrorBusqueda = false;
              this.visibleDetalleLimpiar = true;
              if(this.temporal.archivo != null && this.temporal.archivo != ""){
                this.habilitarAdjunto = true;
              }else{
                this.habilitarAdjunto = false;
              }
            }else{
              this.visibleModalErrorBusqueda = true;
              this.habilitarAdjunto = false;
            }
          }          
          this.loading = false;
        },error => {
           this.loading = false;
           this.principalComponent.cargarErrorServicio(error);
         });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "Se debe digitar un número de radicado valido.",
          life: 10000
        });
        return ;
      }
    }else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Se debe digitar un número de radicado.",
        life: 10000
      });
      return ;
    }    
  }

  /**
   * Metodo que busca el radicado en orfeo en concepto
   * @param radicado 
   */
  onBuscarRadicadoConcepto(radicado){
    
    this.conceptoSolicitado.asunto = null;
    this.conceptoSolicitado.fechaRadicadoStr = null;
    this.conceptoSolicitado.fechaRadicado = null;
    this.conceptoSolicitado.adjunto = null;

    if(radicado != null && radicado != undefined && radicado != ""){
      if(radicado.length < 14){
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El número de radicado no poseé la longitud del formato.",
          life: 10000
        });
        return ;
      }
      
      if(this.stringIsNumber(radicado)){
        this.loading = true;
        this._service.getConsultaRadicadoConcepto(radicado).subscribe((data: {}) => {
          this.temporal = data;
          if( this.temporal.asunto != null && this.temporal.asunto != ""){
            this.conceptoSolicitado.asunto = this.temporal.asunto;
            this.conceptoSolicitado.numeroRadicado = this.temporal.numeroRadicado;
            this.conceptoSolicitado.fechaRadicado = this.temporal.fechaRadicado;
            this.conceptoSolicitado.fechaRadicadoStr = this.temporal.fechaRadicadoStr;
            this.conceptoSolicitado.adjunto = this.temporal.archivo;
            this.visibleModalErrorBusquedaConcepto = false;
            this.visibleDetalleConcepto = false;
            if(this.temporal.archivo != null && this.temporal.archivo != ""){
              this.habilitarAdjuntoConcepto = true;
            }else{
              this.habilitarAdjuntoConcepto = false;
            }
          }else{
            if(this.temporal!= null && this.temporal.mensajeError!= undefined && this.temporal.mensajeError != null
              && this.temporal.mensajeError!= ''){
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: this.temporal.mensajeError,
                  life: 10000
                });
              }else{
                this.visibleModalErrorBusquedaConcepto = true;
              }
            this.habilitarAdjuntoConcepto = false;
          }
          this.loading = false;
        },error => {
           this.loading = false;
           this.principalComponent.cargarErrorServicio(error);
         });
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "Se debe digitar un número de radicado valido.",
          life: 10000
        });
        return ;
      }
    }else{
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: "Se debe digitar un número de radicado.",
        life: 10000
      });
      return ;
    }
  }

  /**
   * Metodo que valida si es un numero.
   * @param s 
   */
  stringIsNumber(s) {
    var x = +s; // made cast obvious for demonstration
    return x.toString() === s;
  }

  /**
   * Metodo que visualiza el modal de error de busqueda
   */
  aceptarDialogoErrorBusqueda(){
    this.visibleModalErrorBusqueda = false;
  }
  
  /**
   * Metodo que visualiza el modal de error de busqueda de concepto
   */
  aceptarDialogoErrorBusquedaConcepto(){
    this.visibleModalErrorBusquedaConcepto = false;
  }

  /**
	 * Metodo que captura el evento del teclado solo numeros 0-9
	 * @param evento 
	 */
	soloNumeros(evento){
		if(evento.code == "Backspace" 
		|| evento.code == "Enter" 
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
    || evento.code == "Delete"
    || evento.ctrlKey == true){
			return true;
    }
    
    if(evento.ctrlKey == true && evento.keyCode == 67){
      return true;
    }
    if(evento.ctrlKey == true && evento.keyCode == 86){
      return true;
    }

		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || 
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}

	}	   
  
   /**
	 * Metodo que captura el evento del teclado solo alfanumerico
	 * @param evento 
	 */
	soloAlfanumericos(evento){

		if(evento.shiftKey || evento.ctrlKey || evento.altKey || evento.code =="BracketLeft"){
			return false;
		}

		if(evento.code == "Backspace" 
		|| evento.code == "Enter"
		|| evento.code == "Home" 
		|| evento.code == "End" 
		|| evento.code == "Slash" 
		|| evento.code == "Dash" 
		|| evento.code == "ArrowLeft"
		|| evento.code == "ArrowRight"
		|| evento.code == "Delete"){
			return true;
		}
		
		if (evento.keyCode >= 48 &&  evento.keyCode <= 57 || evento.keyCode >= 65 &&  evento.keyCode <= 90 ||
			evento.keyCode >= 96 &&  evento.keyCode <= 105 ) {
			return true;
		}else{
			return false;
		}
	}	

  /**
	 * Metodo que carga la lista de los tipos de documentos
	 */
  cargarSoloTipoDocumento(tipoDocumento) {
    this.loading = true;
		return this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.CC+","+EnumTipoDocumento.NIT).subscribe((data: {}) => {
			this.tipoDocumentos = data;
      for (let i = 0; i < this.tipoDocumentos.length; i++) {
				let filtro = this.tipoDocumentos[i];
				let item: string = String(filtro.id);
				if (item.toLowerCase().includes(tipoDocumento.toLowerCase())) {
          this.tipoDocumentoSeleccionada.id = tipoDocumento;
          this.tipoDocumentoSeleccionada.nombre = filtro.nombre;
				}
			}
      this.loading = false;
		},error => {
      this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		});
  }

  /**
	 * Metodo que carga la lista de los tipos de documentos
	 */
	cargarTipoDocumento() {
		this.loading = true;
		this.filtroTipoDocumentos = [];
		return this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.CC+","+EnumTipoDocumento.NIT).subscribe((data: {}) => {
      this.tipoDocumentos = data;
      this.tipoDocumentoSeleccionada = { id: 0, nombre: 'Seleccione...'};
			this.loading = false;
		},error => {
			this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		  });
	}
  
   /**
	 * Metodo que carga la lista de las personas de acuerdo a la cedula
	 * @param event 
	 */
	cargarProyectos(event) {
		this.loading = true;
		this.filtroProyectos = [];		
		return this._service.getConsultaProyectos().subscribe((data: {}) => {
      this.proyectos = data;
      let existeFiltro: boolean;
			for (let i = 0; i < this.proyectos.length; i++) {
				let filtro = this.proyectos[i];
				let item: string = String(filtro.nombre);
				if (item.toLowerCase().includes(event.query.toLowerCase())) {
					this.filtroProyectos.push(filtro);
					existeFiltro = true;
				}
			}
			if (existeFiltro) {
				this.proyectos = this.filtroProyectos;
			}
			this.loading = false;
		},error => {
			this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		  });
  }

  /**
	 * Metodo que carga la lista de las personas de acuerdo a la cedula
	 * @param event 
	 */
	cargarConcesionarios(event) {
		this.loading = true;
		this.filtroConcesionarios = [];		
		return this._service.getConsultaConcesionarios().subscribe((data: {}) => {
      this.concesionarios = data;
			let existeFiltro: boolean;
			for (let i = 0; i < this.concesionarios.length; i++) {
				let filtro = this.concesionarios[i];
				let item: string = String(filtro.nombre);
				if (item.toLowerCase().includes(event.query.toLowerCase())) {
					this.filtroConcesionarios.push(filtro);
					existeFiltro = true;
				}
			}
			if (existeFiltro) {
				this.concesionarios = this.filtroConcesionarios;
			}
			this.loading = false;
		},error => {
			this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		  });
  }

  
  /**
	 * Metodo que carga la lista de las personas de acuerdo a la cedula
	 * @param event 
	 */
	cargarPersonas(event) {
		this.loading = true;
		this.filtroPersonas = [];		
		return this._service.getSolicitante(this.tipoDocumentoSeleccionada.id).subscribe((data: {}) => {
      this.personas = data;
			let existeFiltro: boolean;
			for (let i = 0; i < this.personas.length; i++) {
				let filtro = this.personas[i];
				let item: string = String(filtro.nombreCompleto);
				if (item.toLowerCase().includes(event.query.toLowerCase())) {
					this.filtroPersonas.push(filtro);
					existeFiltro = true;
				}
			}
			if (existeFiltro) {
				this.personas = this.filtroPersonas;
			}
			this.loading = false;
		},error => {
			this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		  });
  }

  /**
	 * Metodo que carga la lista de las personas de acuerdo a la cedula
	 * @param event 
	 */
	cargarPersonasSolo(idSolicitante) {
		this.loading = true;
		this.filtroPersonas = [];		
		return this._service.getSolicitante(this.tipoDocumentoSeleccionada.id).subscribe((data: {}) => {
      this.personasTemp = data;
      this.personas = this.personasTemp;
      for (let i = 0; i < this.personasTemp.length; i++) {
        let filtro = this.personasTemp[i];
				let item: string = String(filtro.id);
        if (item == idSolicitante) {
          this.filtroPersonas.push(filtro);
          this.personas = this.filtroPersonas;
          this.personaSeleccionada = filtro;
          //this.tipoDocumentoSeleccionada.id = 'NIT';
          this.cargarSoloTipoDocumento(this.tipoDocumentoSeleccionada.id);
        }
      }

			this.loading = false;
		},error => {
			this.loading = false;
			this.principalComponent.cargarErrorServicio(error);
		  });
  }
  
  /**
	 * Metodo que valida la informacion
	 */
	validarTipoDocumento(){
		this.habilitarNombre = false;
    this.personaSeleccionada = { id: 0, nombreCompleto: 'Seleccione...', numeroDocumento: ''};

    if(this.tipoDocumentoSeleccionada!= undefined && this.tipoDocumentoSeleccionada!= null
      && this.tipoDocumentoSeleccionada.id != undefined && this.tipoDocumentoSeleccionada.id != null){
          this.seleccionTipoDocumento = this.tipoDocumentoSeleccionada.id;
    }    
	}

  /**
   * Metod que realiza el descargue del archivo del radicado
   * @param objeto 
   */
  onDescargarAdjunto(objeto){
    if(objeto.adjunto != null){
      window.open(objeto.adjunto);
    }else{
      if(objeto.rutaAdjunto != null){
        window.open(objeto.rutaAdjunto);
      }else{
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: "El sistema no encontró el archivo a descargar",
          life: 10000
        });
      }
    }
    
  }

  /**
   * Metodo que cancela el concepto
   */
  cancelarConcepto(){
    this.visibleDialogoConcepto = false;
    this.loading = false;
  }


  /**
   * 
   * @param mensaje 
   */
  procesaPropagar(mensaje) {
    this.visibleDialogoAgregarSolicitante = false;
  }

}
