import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { EnumCtoTipoAnexoInforme } from "src/app/contratacion/constantes/contratacion-constantes";
import { CtoAdjuntoInformeBorradorDTO } from "src/app/contratacion/contratacion-dto/adjunto-informe-borrador";
import { CtoInformeRevisionAnexoDTO } from "src/app/contratacion/contratacion-dto/informe-revision-anexo";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";
import { RpteCxpBaseComponent } from "../rpte-cxp-base/rpte-cxp-base.component";

@Component({
  selector: 'app-rpte-cxp-ben-trib',
  templateUrl: './rpte-cxp-ben-trib.component.html',
  styles: []
})
export class RpteCxpBenTribComponent implements OnInit {

  planillasInforme: CtoAdjuntoInformeBorradorDTO[];
  anexosInformeRevision: CtoInformeRevisionAnexoDTO[];
  adjuntosAnexoInforme: CtoAdjuntoInformeBorradorDTO[];
  visibleDialogoVerAdjuntos: boolean;

  constructor(
    private lineaBase: RpteCxpBaseComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarAnexosTributarios(this.lineaBase.idInformeParam);
    this.cargarPlanillasInforme(this.lineaBase.idInformeParam);
  }

  cargarPlanillasInforme(idInforme: number) {
    this.reporteCuentaCobroCxpService.srvConsultarPlanillasInforme(idInforme)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.planillasInforme = result.adjuntos;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO, error)
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  cargarAnexosTributarios(idInforme: number) {
    this.reporteCuentaCobroCxpService.srvConsultarAnexosInforme(idInforme, EnumCtoTipoAnexoInforme.SUBTIPO_BENEFICIO_TRIBUTARIO, null)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.anexosInformeRevision = result.anexosInformeRevision;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }


  irVerDialogoVerAdjuntosAnexos(idAnexo: number) {
    this.visibleDialogoVerAdjuntos = true;
    this.cargarAdjuntosPorAnexosTributarios(idAnexo);
  }

  cargarAdjuntosPorAnexosTributarios(idAnexo: number) {
    this.reporteCuentaCobroCxpService.srvConsultarAdjuntosPorAnexoInforme(idAnexo)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosAnexoInforme = result.adjuntos;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

}
