import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TrnPersona, TrnPersonaRpt } from '../../admin-personas/trn-persona';
import { Data } from '@angular/router';
import { PrsDatosAdicionalesPersona } from 'src/app/admin-personas/prs-datos-adicionales-persona';
import { PrsFuncionario, PrsFuncionarioRpt } from 'src/app/admin-personas/prs-funcionario';
import { PrsEducacionRpt, PrsEducacion } from '../../admin-personas/prs-educacion';
import { PrsNivelEducativo } from 'src/app/admin-personas/prs-nivel-educativo';
import { PrsProgamaAcademico } from '../../admin-personas/prs-programa-academico';
import { TrnRespuestaServicio } from '../../parametricas/trn-respuesta-servicio';
import { SgrUsuarioPerfilRpt } from 'src/app/admin-personas/sgr-usuario-perfil';


@Injectable({
    providedIn: 'root'
})
export class PersonaService {

    public END_POINT = '';


    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }


    public getPersonaNaturalPorTipoNumeroDocumento(tipoDocumento: string, numeroDocumento: string): Observable<TrnPersonaRpt> {
        return this.http.get<TrnPersonaRpt>(this.END_POINT + '/TrnPersonas/GetPersonaNaturalPorTipoNumeroDocumento/' + tipoDocumento + '/' + numeroDocumento)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public GuardarPersonaNatural(persona: TrnPersona): Observable<TrnPersonaRpt> {
        return this.http.post<TrnPersonaRpt>(environment.baseUrl + '/TrnPersonas/GuardarPersonaNatural', persona, this.httpOptions)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


    public getPersonas() {
        return this.http.get(this.END_POINT + '/TrnPersonas')
            .pipe(retry(1));
    }

    public getPersonasExceptoUserName(username: string) {
        return this.http.get(this.END_POINT + '/TrnPersonas/obtenerPersonasExceptoUserName/' + username)
            .pipe(retry(1));
    }

    public getPersona(idPersona) {
        return this.http.get(this.END_POINT + '/TrnPersonas/' + idPersona)
            .pipe(retry(1));
    }

    public getPersonasUsuarios() {
        return this.http.get(this.END_POINT + '/TrnPersonasAll/ConsultarPersonasUsuario')
            .pipe(retry(1));
    }

    public getPersonasUsuarios2(): Observable<any> {
        return this.http.get(this.END_POINT + '/TrnPersonasAll/ConsultarPersonasUsuario')
            .pipe(retry(1));
    }

    public getPersonasVigentes(): Observable<any> {
        return this.http.get(this.END_POINT + '/TrnPersonas/listarPersonasVigentes')
            .pipe(retry(1));
    }

    public setPersona(persona) {
        return this.http.post(environment.baseUrl + '/TrnPersonas', JSON.stringify(persona), this.httpOptions);
    }

    public getPersonasJurdicas() {
        return this.http.get(this.END_POINT + '/TrnPersonaJuridica')
            .pipe(retry(1));
    }

    public getPersonaJurdicaPorTipoNumeroDocumento(tipoDocumento: string, numeroDocumento: string) {
        return this.http.get(this.END_POINT + '/TrnPersonaJuridica/' + tipoDocumento + '/' + numeroDocumento)
            .pipe(retry(1));
    }

    public getPersonaJurdicaPorId(idPersona) {
        return this.http.get(this.END_POINT + '/TrnPersonaJuridica/' + idPersona)
            .pipe(retry(1));
    }

    public setPersonaJuridica(persona) {
        return this.http.post(environment.baseUrl + '/TrnPersonaJuridica/GuardarPersonaJuridica',
            JSON.stringify(persona), this.httpOptions);
    }

    public getPrsFuncionarios(): Observable<PrsFuncionario[]> {
        return this.http.get<PrsFuncionario[]>(this.END_POINT + '/TrnPersonasAll/ConsultarFuncionarios')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getPrsPersonasNaturales(): Observable<any> {
        return this.http.get<any>(this.END_POINT + '/TrnPersonasAll/ConsultarPersonasSelector/CC')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getPrsFuncionarioPorId(idPersona): Observable<PrsFuncionario> {
        return this.http.get<PrsFuncionario>(this.END_POINT + '/TrnPersonasAll/ConsultarFuncionarioPorId/'+idPersona)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getPrsPersonaPorId(idPersona): Observable<PrsFuncionario> {
        return this.http.get<PrsFuncionario>(this.END_POINT + '/TrnPersonasAll/ConsultarPersonaPorId/'+idPersona)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public getPersonasAllPaginado(idTipoDocumento?: string, numeroDocumento?: string, filaDesde?: number, cantidadRegistros?: number, buscar?: string): Observable<Array<Data>> {
        let urlEndpoint = '/TrnPersonasAll/ListarPersonasPaginado?';

        if (idTipoDocumento != null) {
            urlEndpoint = urlEndpoint + '&idTipoDocumento=' + idTipoDocumento;
        }
        if (numeroDocumento != null) {
            urlEndpoint = urlEndpoint + '&numeroDocumento=' + numeroDocumento;
        }
        if (buscar != null) {
            urlEndpoint = urlEndpoint + '&buscar=' + buscar;
        }
        if (filaDesde != null) {
            urlEndpoint = urlEndpoint + '&filaDesde=' + filaDesde;
        }
        if (cantidadRegistros != null) {
            urlEndpoint = urlEndpoint + '&cantidadRegistros=' + cantidadRegistros;
        }

        return this.http.get<Array<Data>>(
            this.END_POINT + urlEndpoint)
            .pipe(retry(1));
    }

    public ConsultarDatosBasicosPersonaId(idTipoDocumento?: string, id?: number): Observable<TrnPersonaRpt> {
        return this.http.get<TrnPersonaRpt>(this.END_POINT + '/TrnPersonasAll/ConsultarDatosBasicosPersonaId/' + id + '/' + idTipoDocumento)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    public ConsultarDatosBasicosUsuario(): Observable<TrnPersonaRpt> {
        return this.http.get<TrnPersonaRpt>(this.END_POINT + '/TrnPersonasAll/ConsultarDatosBasicosUsuario')
        .pipe(map(data => data), retry(1));
    }

    public ObtenerPreguntaAurotizacionDatos(): Observable<any> {
        return this.http.get<any>(`${this.END_POINT}/TrnPersonasAll/ObtenerPreguntaAurotizacionDatos`)
    }

    public AceptarTratamientoDatos(idPersona: number, idPregunta: number): Observable<any> {
        return this.http.get<any>(`${this.END_POINT}/TrnPersonasAll/AceptarTramaientoDatos/${idPersona}?idPregunta=${idPregunta}`)
        .pipe(map(data => data), retry(1));
    }

    public GuardarPersonaDatosBasicos(trnPersona: TrnPersona, modoAdmin: boolean = false): Observable<any> {
        return this.http.post<any>(`${this.END_POINT}/TrnPersonasAll/GuardarPersonaDatosBasicos/${modoAdmin}`, trnPersona)
            .pipe(
                retry(1));
    }

    public ConsultarPersonaDatosAdicionales(idPersona: number): Observable<PrsDatosAdicionalesPersona> {
        return this.http.get<PrsDatosAdicionalesPersona>(this.END_POINT + '/TrnPersonasAll/ConsultarPersonaDatosAdicionalesIdPersona/' + idPersona)
            .pipe(
                retry(1));
    }

    public GuardarPersonaDatosAdicionales(personaDatosAdicionales: PrsDatosAdicionalesPersona): Observable<any> {
        return this.http.post<any>(this.END_POINT + '/TrnPersonasAll/GuardarPersonaDatosAdicionales', personaDatosAdicionales)
            .pipe(
                retry(1));
    }

    public GuardarDependencia(idPersona: number, idPrimerNivel: number, idSegundoNivel: number): Observable<any> {
        return this.http.put<any>(`${this.END_POINT}/PrsFuncionarioHistorico/PutGuardarDependencia?idPersona=${idPersona}&idPrimerNivel=${idPrimerNivel}&idSegundoNivel=${idSegundoNivel}`, null)
        .pipe(retry(1));
    }

    public ObtenerFuncionario(idPersona: number) {
        return this.http.get<any>(`${this.END_POINT}/PrsFuncionarioHistorico/GetFuncionario/${idPersona}`)
        .pipe(retry(1));
    }

    public GetInformacionFuncionarioIdPersona(idPersona: number) {
        return this.http.get<any>(`${this.END_POINT}/PrsFuncionarioHistorico/GetInformacionFuncionarioIdPersona/${idPersona}`)
        .pipe(retry(1));
    }

    ConsultarPersonaEducacion(idPersona: number): Observable<PrsEducacionRpt> {
        return this.http.get<PrsEducacionRpt>(this.END_POINT + '/TrnPersonasAll/ConsultarPersonaEducacion/' + idPersona)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    GuardarPersonaEducacion(educacion: PrsEducacion): Observable<PrsEducacionRpt> {
        return this.http.post<PrsEducacionRpt>(this.END_POINT + '/TrnPersonasAll/GuardarPersonaEducacion', educacion)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    EliminarPersonaEducacion(idPersonaEducacion: PrsEducacion): Observable<PrsEducacionRpt> {
        return this.http.post<PrsEducacionRpt>(this.END_POINT + '/TrnPersonasAll/EliminarPersonaEducacion/', idPersonaEducacion)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    CargarNivelesEducativos(): Observable<PrsNivelEducativo[]> {
        return this.http.get<PrsNivelEducativo[]>(this.END_POINT + '/PrsMaNivelEducativo')
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    CargarProgramasAcademicosPorNivelEducativo(idNivelEducativo: number): Observable<PrsProgamaAcademico[]> {
        return this.http.get<PrsProgamaAcademico[]>(this.END_POINT + '/PrsMaProgramaAcademico/GetPrsMaProgramaAcademicoPorNivelEducativo/' + idNivelEducativo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    ConsultarUsuarioPerfil(idPersona: number): Observable<SgrUsuarioPerfilRpt> {
        return this.http.get<SgrUsuarioPerfilRpt>(this.END_POINT + '/TrnPersonasAll/ConsultarUsuarioPerfil/' + idPersona)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }


    GuardarFuncionario(data): Observable<any> {
        console.log("data");
        console.log(data);
        return this.http
          .post(this.END_POINT + "/PrsFuncionarioHistorico/GuardarFuncionario", data)
          .pipe(retry(1));
      }

}
