import { Component, OnInit, ViewChild } from '@angular/core';
import { InversionProyectoService } from './inversion-proyecto.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { InversionProyecto } from './inversionProyecto';
import { InformacionGeneralService } from '../../informacion-general/informacion-general.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-inversion-proyecto',
  templateUrl: './inversion-proyecto.component.html',
  styleUrls: ['./inversion-proyecto.component.sass'],
  providers: [InversionProyectoService]
})
export class InversionProyectoComponent implements OnInit {

  inversionesProyecto: any = [];

  displayedColumns: string[] = ['valorContrato', 'fechaReferencia', 'inversionesUnidadFuncional'];

  dataSource: MatTableDataSource<InversionProyecto>;

  idProyecto: number;
  proyecto: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private infoService: InformacionGeneralService, private infoInversion: InversionProyectoService, private route: Router, private ruta: ActivatedRoute,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.id;
      this.cargarProyectoCarretero(this.idProyecto);
      this.cargarInversiones(this.idProyecto);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onNew() {
    this.route.navigate(['/finInversionProyectoDetalle', this.idProyecto, 'new']);
  }

  onEdit(id: number) {
    this.route.navigate(['/finInversionProyectoDetalle', this.idProyecto, id]);
  }

  irAInversionPorUnidadFuncional(fechaReferencia:Date){
    this.route.navigate(['/finInversion', this.idProyecto, fechaReferencia]);
  }

  cargarInversiones(id:number) {
    return this.infoInversion.getInversionesProyecto(id).subscribe((data: {}) => {
      this.inversionesProyecto = data;
     this.dataSource = new MatTableDataSource(this.inversionesProyecto);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

}
