import { Component, Input, OnInit } from '@angular/core';
import { TrnPersona } from 'src/app/admin-personas/trn-persona';
import { PrsFuncionario } from '../../admin-personas/prs-funcionario';
import { PersonaService } from '../../comun/persona/persona.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { VwTrnDependenciaAni } from 'src/app/parametricas/trn-dependencia-ani';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { forkJoin } from 'rxjs';
import { ComisionesService } from '../comisiones.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsConfiguracionFuncionarioDTO } from '../comisiones.dto';


@Component({
  selector: 'app-comisiones-configuracion-funcionario',
  templateUrl: './comisiones-configuracion-funcionario.component.html',
  styleUrls: ['./comisiones-configuracion-funcionario.component.sass']
})
export class ComisionesConfiguracionFuncionarioComponent implements OnInit {

  blockedDocument = true;
  persona: TrnPersona;
  detalleFuncionario: PrsFuncionario;
  @Input()
  fijarAcordeon = true;
  @Input()
  esModoconsulta = false;

  visualizarGrupoTrabajoDialog: boolean = false;
  visualizarEliminarProyectoDialog: boolean = false;
  proyectos: {};
  dependenciaAniGrupoTrabajo: VwTrnDependenciaAni[];
  dependenciaAniGrupoTrabajoSeleccionado: VwTrnDependenciaAni;


  formAsignarDependencias;

  constructor(
    private messageService: MessageService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService,
    formBuilder: FormBuilder,
  ) {

    // Crear formulario Reserva
    this.formAsignarDependencias = formBuilder.group({
      dependenciaEspecifica: [null, [Validators.required]],
    });

  }

  ngOnInit() {
    this.persona = {};
    this.cargarPersona();
    this.detalleFuncionario = {
      dependenciaPrimerNivel: 'Vicepresidencia de Planeación Riesgos y Entorno',
      dependencia: 'GIT Tecnologías de la Información y las Telecomunicaciones',
      honorarios: 2222222
    };
    this.dependenciaAniGrupoTrabajoSeleccionado = {
      id: 0,
      nombre: 'Sin información'
    };
  }

  cargarPersona() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarFuncionarioComision()
    ).subscribe(
      ([
        funcionarioData
      ]) => {
        this.persona.id = funcionarioData.configuracionFuncionario.id;
        this.persona.nombre = funcionarioData.configuracionFuncionario.nombre;
        this.persona.numeroDocumento = funcionarioData.configuracionFuncionario.numeroDocumento;
        this.persona.correoElectronicoAni = funcionarioData.configuracionFuncionario.correoElectronico;
        this.detalleFuncionario.honorarios = funcionarioData.configuracionFuncionario.ingresos;
        this.detalleFuncionario.dependenciaPrimerNivel = funcionarioData.configuracionFuncionario.dependenciaPrimerNivel;
        this.detalleFuncionario.idDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.idDependenciaPrimerNivel;
        this.detalleFuncionario.liderDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.liderDependenciaPrimerNivel;
        this.detalleFuncionario.dependenciaEspecifica = funcionarioData.configuracionFuncionario.dependencia;
        this.detalleFuncionario.idDependenciaEspecifica = funcionarioData.configuracionFuncionario.idDependencia;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irEditarGrupoTrabajo(idDependenciaPadre) {
    this.visualizarGrupoTrabajoDialog = true;
    this.dependenciaAniGrupoTrabajoSeleccionado = null;
    this.blockedDocument = true;
    forkJoin(
      this.parametricasService.srvListarDependenciasPorIdPadre(idDependenciaPadre)
    ).subscribe(
      ([
        dependenciasData
      ]) => {
        this.dependenciaAniGrupoTrabajo = dependenciasData.dependencias;
        let despacho: VwTrnDependenciaAni = {};
        despacho.id = this.detalleFuncionario.idDependenciaPrimerNivel;
        despacho.nombre = "Despacho"
        this.dependenciaAniGrupoTrabajo.unshift(despacho);
        this.dependenciaAniGrupoTrabajoSeleccionado = this.dependenciaAniGrupoTrabajo.find(
          (t) => t.id === this.detalleFuncionario.idDependenciaEspecifica
        );
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cancelarEditarGrupoTrabajo() {
    this.visualizarGrupoTrabajoDialog = false;
    this.dependenciaAniGrupoTrabajoSeleccionado = null;
    this.dependenciaAniGrupoTrabajo = null;
  }

  guardarGrupoTrabajo() {
    if (this.formAsignarDependencias.valid) {
      this.blockedDocument = true;
      let configuracionFuncionario: CmsConfiguracionFuncionarioDTO = {};
      configuracionFuncionario.idDependencia = this.dependenciaAniGrupoTrabajoSeleccionado.id;
      configuracionFuncionario.id = this.persona.id;
      forkJoin(
        this.comisionesService.srvGuardarDependenciaEspecifica(configuracionFuncionario)
      ).subscribe(
        () => {
          this.visualizarGrupoTrabajoDialog = false;
          this.cargarPersona();
          this.lanzarMensajeInfo("El grupo de trabajo se guardó exitosamente.");
        },
        (error) => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
          this.blockedDocument = false;
          this.visualizarGrupoTrabajoDialog = false;
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
          this.visualizarGrupoTrabajoDialog = false;
        }
      );
    } else {
      this.lanzarMensajeWarning("Debe diligenciar los campos requeridos marcados con asterisco (*)");
    }

  }

  // // // // /Mensajes// // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }


}

