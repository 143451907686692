import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Archivo } from '../almacenamiento/archivo';
import { AlmacenamientoService } from '../almacenamiento/almacenamiento.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-adjuntar-almacenamimento',
  templateUrl: './adjuntar-almacenamimento.component.html',
  styleUrls: ['./adjuntar-almacenamimento.component.sass']
})
export class AdjuntarAlmacenamimentoComponent implements OnInit {
  @Input() modulo: string;
  @Output() idArchivosChange = new EventEmitter<Archivo[]>();
  @Input() idArchivos: Archivo[];
  @Input() id: string;//ruta donde se vana  guardar los archivos
  @Input() soloUnArchivo: boolean;
  @Input() nombreInicialArchivo: string;

  @Input() idTiposSoporte: string;
  @Input() tipoSoporteSeleccionado: any;
  @Output() tipoSoporteSeleccionadoChange = new EventEmitter<any>();
  @Input() habilitaEnvio: boolean = true;
  @Input() esBlob: boolean = false;
  @Input() contenedor: string;

  public files: NgxFileDropEntry[] = [];

  tiposSoporte: any = [];
  filtroTiposSoporte: any[];
  public _files: Archivo[] = [];

  @Output() OutputValidacionArchivos = new EventEmitter();
  ocurrioAlgunError: boolean;

  yaSeVisualizo: boolean;

  constructor(private svc: AlmacenamientoService, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {

  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;

    if (this.soloUnArchivo) {
      this._files = [];
    }

    let index: number = 0;

    this._files.forEach(element => {
      if (element.estado == 'Tamaño no valido') {
        this._files.splice(index, 1);
      }
      if (element.estado == 'Formato no válido') {
        this._files.splice(index, 1);
      }
      index++;
    });

    this.habilitaEnvio = true;
    this.ocurrioAlgunError = false;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          var archivo = new Archivo(droppedFile.relativePath, "Enviando...", droppedFile.relativePath, this.tipoSoporteSeleccionado.id);
          this._files.push(archivo);
          if (file.size <= 42000000) {//Tamano maximo permito son 20MB
            const uploadData = new FormData();
            uploadData.append('file', file, droppedFile.relativePath);
            uploadData.append('modulo', this.modulo);
            uploadData.append('id', this.id);
            uploadData.append('nombreInicialArchivo', this.nombreInicialArchivo);
            uploadData.append('esBlob', String(this.esBlob));
            uploadData.append('contenedor', this.contenedor);
            this.svc
              .uploadFile(uploadData)
              .subscribe(
                (response: any) => {
                  archivo.estado = "OK";
                  archivo.nombre = droppedFile.relativePath;
                  archivo.idTipoDocumento = this.tipoSoporteSeleccionado.id;
                  archivo.nombreCompleto = response.fileName;
                  let todosEviados: boolean = true;
                  this._files.forEach(element => {
                    if (element.estado != "OK") {
                      todosEviados = false;
                    }
                  });
                  if (todosEviados) {
                    this.habilitaEnvio = false;
                  }
                },
                (error) => {
                  console.error(error);
                  this.principalComponent.cargarErrorServicio(error);
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
                  archivo.estado = "No se pudo adjuntar";
                  this.ocurrioAlgunError = true;
                  this.habilitaEnvio = true;
                }
              );
          } else {

            archivo.estado = "Tamaño no valido";
            this.ocurrioAlgunError = true;
            this.habilitaEnvio = true;

          }
        });
      } else {
        var archivo = new Archivo(droppedFile.relativePath, "Formato no válido", droppedFile.relativePath, this.tipoSoporteSeleccionado.id);
        this.ocurrioAlgunError = true;
        this._files.push(archivo);
      }
    }

    if (!this.ocurrioAlgunError) {
      this.OutputValidacionArchivos.emit("OK");
    } else {
      this.OutputValidacionArchivos.emit("ERROR");
    }
    this.tipoSoporteSeleccionadoChange.emit(this.tipoSoporteSeleccionado);
    this.idArchivosChange.emit(this._files);
  }

  limpiarArchivos() {
    this.tipoSoporteSeleccionado = null;
    this._files = [];
    this.habilitaEnvio = true;
  }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = this.tipoSoporteSeleccionado.formatoPermitido.split(',');
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);

    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  public fileOver(event) {
  }

  public fileLeave(event) {
  }

  cargarTiposSoporte(event) {
    if (this.idTiposSoporte == null) {
    } else {
    }
    this.filtroTiposSoporte = [];
    // console.log('cargarTiposSoporte');
    // console.log('this.modulo'+this.modulo);
    // console.log('this.idTiposSoporte'+this.idTiposSoporte);
    return this.svc.getTiposSoporte(this.modulo, this.idTiposSoporte).subscribe((data: {}) => {
      this.tiposSoporte = data;
      let existeFiltro: boolean;
      for (let i = 0; i < this.tiposSoporte.length; i++) {
        let filtroTipoSoporte = this.tiposSoporte[i];
        let item: string = String(filtroTipoSoporte.nombre);
        if (item.toLowerCase().includes(event.query.toLowerCase())) {
          this.filtroTiposSoporte.push(filtroTipoSoporte);
          existeFiltro = true;
        }
      }
      if (existeFiltro) {
        this.tiposSoporte = this.filtroTiposSoporte;
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }
}
