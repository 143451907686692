import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { TramiteAmbientalDetalleService } from './tramite-ambiental-detalle.service';
import { TramiteAmbientalDetalleDTO} from '../../../dtos/tramites-ambientales/tramiteAmbientalDetalleDTO';
import { Location } from '@angular/common';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confirmacion-dialog',
  templateUrl: '../confirmacion/confirmacion.html',
})
// tslint:disable-next-line:component-class-suffix
export class ConfirmacionDialog {

confirmacion = false;
constructor(
  public dialogRef: MatDialogRef<ConfirmacionDialog>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
}

onNoClick(payload): void {
    this.dialogRef.close();
}

onConfirm(): void {
    this.dialogRef.close(true);
}

onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
}
}  


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'funcionales-dialog',
  templateUrl: './unidad-funcional/unidades-funcionales.html',
})
// tslint:disable-next-line:component-class-suffix
export class FuncionalesDialog implements OnInit {

  subTramos: any = [];
  temp;

  constructor(
    public dialogRef: MatDialogRef<FuncionalesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private detalleService: TramiteAmbientalDetalleService,
    ) {
      this.temp = data;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (this.temp.id) {
      this.getSubTramo(this.temp.id);
    }
  }

  getSubTramo(payload) {
    this.detalleService.getTramo(payload).subscribe((res: {}) => {
      this.data.subTramos = res;
    });
  }

}

@Component({
  selector: 'app-tramite-ambiental-detalle',
  templateUrl: './tramite-ambiental-detalle.component.html',
  styleUrls: ['./tramite-ambiental-detalle.component.sass']
})

export class TramiteAmbientalDetalleComponent implements OnInit {

  proyecto: TramiteAmbientalDetalleDTO = {};
  maxDate: Date;
  dataArray: any;
  etapas: any = [];
  compromisos: any = [];
  responsable: any = [];
  entidad: any = [];
  priorizacion: any = [];
  estado: any = [];
  idDetalle: any;
  unidades: any = [];
  subTramosTemp: any = [];
  idAmigablesDetalle: any;
  datosAmigables: any = [];
  datosApoderados: any = [];
  datosSubTramos: any = [];
  amigableTemporal : any = {
    id: null,
    idCompromiso: null,
    idUnidadFuncional: null,
    unidadFuncional: '',
    idSubTramo: null,
    idEtapa: null,
    subTramo: '',
    usuarioActualizacion: 0
  };

  estadoTemporal : any = {
    actoAdministrativo: null,
    fecha: null,
    id: null,
    idCompromiso: '',
    idEstado: null,
    nombreEstado: '',
    observaciones: 0
  };
  contadorEstado = 0;
  enableActo = false;
  enableAdministrativo = false;
  nombre: string;
  idAmigable: number;
  id: number;
  idSubTramo: number;
  idEtapa: number;
  nombreProyecto;
  dataSource: MatTableDataSource<any>;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  departamentos: any = [];
  filtroDepartamentos: any[];
  departamentoSeleccionado: any;

  municipios: any = [];
  filtroCiudades: any[];
  ciudadSeleccionada: any;
  tipoProy: string;
  disabledEntidad = false;

  constructor(
    private detalleService: TramiteAmbientalDetalleService,
    private route: ActivatedRoute,
    private ruta: Router,
    public dialog: MatDialog,
    public location: Location, private principalComponent: PrincipalComponent,
    private messageService: MessageService
    ) {
    this.maxDate = new Date();
     }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.cargarDepartamentos();
    this.cargarUnidades();
    this.cargarEtapa();
    this.cargarCompromisoTipo();
    this.cargarEntidad();
    this.cargarPriorizacion();
    this.cargarSubTramos();
    this.route.params.subscribe(params => {
      this.idDetalle = params.id;
      this.idDetalle === 'new' ? this.cargarNuevoProyecto(this.idDetalle) : this.cargarTramiteAmbiental(this.idDetalle);
    });
    this.tipoProy = sessionStorage.getItem('tipoProy')

  }

  cargarDepartamentos() {
    this.departamentos = [];
    return this.detalleService.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  filtrarMunicipio(event) {
    this.municipios = [];
    if (event) {
      return this.detalleService.getCiudadesPorDepartamento(event).subscribe((data: {}) => {
        this.municipios = data;
      },error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }
  }

  cambioEntidad(event) {
    if (event === 2) {
      this.disabledEntidad = false;
    } else {
      this.disabledEntidad = true;
      this.proyecto.idDepartamento = null;
      this.proyecto.idMunicipio = null;

    }
  }

  cargarUnidades() {
    return this.detalleService.getUnidades(JSON.parse(localStorage.getItem('idP'))).subscribe((data: {}) => {
      this.unidades = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarEtapa() {
    return this.detalleService.getEtapa().subscribe((data: {}) => {
      this.etapas = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarCompromisoTipo() {
    return this.detalleService.getCompromisoTipo().subscribe((data: {}) => {
      this.compromisos = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarEntidad() {
    return this.detalleService.getEntidad().subscribe((data: {}) => {
      this.entidad = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarSubTramos() {
    return this.detalleService.getAllSubTramos().subscribe((data: {}) => {
      this.datosSubTramos = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarPriorizacion() {
    return this.detalleService.getPriorizacion().subscribe((data: {}) => {
      this.priorizacion = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarNuevoProyecto(id) {
    this.proyecto = {};
    this.proyecto.lstUnidadFuncional = [];
    this.proyecto.radicado = '';
    this.proyecto.descripcion = '';
    this.proyecto.usuarioActualizacion = 0;
  }

  cargarTramiteAmbiental(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.detalleService.getproyectoTramite(id).subscribe((data: {}) => {
      if (data) {
        this.proyecto = data;
        this.filtrarMunicipio(this.proyecto.idDepartamento);
      }
      this.dialogSpin.close();
    },error => {
      if(error.error != undefined && error.error != null){
        this.dialogSpin.close();
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error,
          life: 10000
        });
      }else{
        this.dialogSpin.close();
        this.principalComponent.cargarErrorServicio(error);
      } 
    });
  }

  guardarDetalle(payload) {
    this.openDialogEliminar(payload);
  }

  cancelar() {
    //this.ruta.navigate(['/infTramitesA', JSON.parse(localStorage.getItem('idP'))]);
    this.location.back();
  }

  getUnidadFuncional(id) {
    if (id) {
      for (let i = 0; i < this.datosSubTramos.length; i++) {
        if (this.datosSubTramos[i].id === id) {
          for (let j = 0; j < this.unidades.length; j++) {
            if (this.unidades[j].id === this.datosSubTramos[i].idUnidadFuncional) {
             return this.unidades[j].nombre;
            }
         }
        }
      }
    } else {
      return '';
    }
  }

  modalUnidadFuncional(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(FuncionalesDialog, {
      data: {id: this.id, idSubTramo: this.idSubTramo, unidades: this.unidades}

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result.id != null && result.id !== '' || result.idSubTramo != null && result.idSubTramo !== '')  {
            let unidadName;
            let nameSubtramo;
            for (let i = 0; i < this.unidades.length; i++) {
              if (this.unidades[i].id === result.id) {
               unidadName = this.unidades[i].nombre;
              }
           }
            this.detalleService.getTramo(result.id).subscribe((data: {}) => {
              if (data) {
                this.subTramosTemp = data;
                for (let i = 0; i < this.subTramosTemp.length; i++) {
                  if (this.subTramosTemp[i].id === result.idSubTramo) {
                    nameSubtramo = this.subTramosTemp[i].nombre;
                  }
                }
                this.amigableTemporal = {
                  id: 1,
                  idCompromiso: 1,
                  idUnidadFuncional: result.id,
                  idSubTramo: result.idSubTramo,
                  unidadFuncional: unidadName,
                  subTramo: nameSubtramo,
                  usuarioActualizacion: 0
                };

                let found = false;
                let count = 0;
                if (this.proyecto.lstUnidadFuncional.length > 0 ) {

                for (let i = 0; i < this.proyecto.lstUnidadFuncional.length; i++) {
                  if (this.proyecto.lstUnidadFuncional[i].idSubTramo === result.idSubTramo && this.proyecto.lstUnidadFuncional[i].idUnidadFuncional === result.id) {
                    found = true ;
                    alert('No se puede agregar el mismo registro dos veces')
                  }
                }
                if (!found) {
                   this.proyecto.lstUnidadFuncional.push(this.amigableTemporal);
                }
                } else if (this.proyecto.lstUnidadFuncional.length === 0) {
                    this.proyecto.lstUnidadFuncional.push(this.amigableTemporal);
                }
              }
            },error => {
              if(error.error != undefined && error.error != null){
                this.dialogSpin.close();
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: error.error,
                  life: 10000
                });
              }else{
                this.dialogSpin.close();
                this.principalComponent.cargarErrorServicio(error);
              } 
            });

 
          }
      });
    } else if (isEdit) {
      const dialogRef = this.dialog.open(FuncionalesDialog, {
      data: {id: payload.idUnidadFuncional, idSubTramo: payload.idSubTramo, unidades: this.unidades }
    });
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {

            this.detalleService.getTramo(payload.idUnidadFuncional).subscribe((data: {}) => {
              this.subTramosTemp = data;
              let found = false;
              for (let i = 0; i < this.subTramosTemp.length; i++) {
                if (this.subTramosTemp[i].id === result.idSubTramo) {
                  for (let i = 0; i < this.proyecto.lstUnidadFuncional.length; i++) {
                    if (this.proyecto.lstUnidadFuncional[i].idSubTramo === result.idSubTramo && this.proyecto.lstUnidadFuncional[i].idUnidadFuncional === result.id) {
                      found = true ;
                      alert('No se puede agregar el mismo registro dos veces')
                    }
                  }
                  if (!found) {
                    this.proyecto.lstUnidadFuncional[index].subTramo = this.subTramosTemp[i].nombre;
                    this.proyecto.lstUnidadFuncional[index].idSubTramo = this.subTramosTemp[i].id;                 
                   }
                }
             }
            },error => {
               if(error.error != undefined && error.error != null){
                  this.dialogSpin.close();
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.error,
                    life: 10000
                  });
                }else{
                  this.dialogSpin.close();
                  this.principalComponent.cargarErrorServicio(error);
                } 
            });

            for (let i = 0; i < this.unidades.length; i++) {
                if (this.unidades[i].id === result.id) {
                 this.proyecto.lstUnidadFuncional[index].unidadFuncional = this.unidades[i].nombre;
                 this.proyecto.lstUnidadFuncional[index].idUnidadFuncional = this.unidades[i].id;
                }
             }

          }
      });
    }
  }


  openDialogEliminar(payload): void {
    const dialogRef = this.dialog.open(ConfirmacionDialog, {
    height: '200px', width: '300px', data: {message: "¿Esta seguro de guardar la información?"}
  });
    payload.idProyecto = JSON.parse(localStorage.getItem('idP'));

    dialogRef.afterClosed().subscribe(result => {
        if (result)  {
            // this.service.eliminarTramiteAmbiental(id).subscribe((data: {}) => {
            // this.cargarProyecto(this.id);
            // });

            this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
              panelClass: 'transparent',
              disableClose: true
            });

            if (this.idDetalle === 'new') {
              this.detalleService.guardarNuevoTramite(payload).subscribe((data: {}) => {
                this.dialogSpin.close();
                this.location.back();

              }, error => {
                if(error.error != undefined && error.error != null){
                  this.dialogSpin.close();
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.error,
                    life: 10000
                  });
                }else{
                  this.dialogSpin.close();
                  this.principalComponent.cargarErrorServicio(error);
                }                
              });
            } else {
              this.detalleService.guardarDetalleTramite(payload).subscribe((data: {}) => {
              if (data) {
                //this.ruta.navigate(['/infTramitesA', JSON.parse(localStorage.getItem('idP'))]);
                this.dialogSpin.close();
                this.location.back();

                }
              }, error => {
                if(error.error != undefined && error.error != null){
                  this.dialogSpin.close();
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.error,
                    life: 10000
                  });
                }else{
                  this.dialogSpin.close();
                  this.principalComponent.cargarErrorServicio(error);
                } 
              });
            }
        }
    });
  }


}


