import { Component, Input, OnInit, Inject, Renderer2 } from "@angular/core";
import { Location, DOCUMENT } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { Chart } from "angular-highcharts";
import { ChartModule } from "angular-highcharts";
import * as Highcharts from "highcharts";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCarreteroService } from "../reportes-carreteros.service";
import { RecaudoPeajesService } from "../../../peajes-gestion/recaudo-peajes/recaudo-peajes.service";
import * as data from "src/assets/img/proyect.json";
import { ngxCsv } from "ngx-csv/ngx-csv";
import { ProyectoEstructuracion } from "src/app/reportes/publicos/proyectoEstructuracion";
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import {
  InformacionGeneralCarreterosDTO,
  CrrInformacionGeneralProyectoDTO,
  CrrInformacionDepartamentosDTO,
  CrrInformacionPeajesDTO,
  CrrInformacionProyectoDTO,
  CrrInformacionProyectoCsvDTO,
} from "../reportes-carreteros.dto";
import { formatDate } from "@angular/common";
import { PaginatorModule } from "primeng/paginator";
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PrediosService } from '../../../predios/predios.service';

@Component({
  selector: "app-rpt-crr-ficha-proyectos",
  templateUrl: "./rpt-crr-ficha-proyectos.component.html",
  styleUrls: [
    "./rpt-crr-ficha-proyectos.component.sass",
    "../reportes-carreteros.css",
  ],
})
export class RptCrrFichaProyectosComponent implements OnInit {
  private isAuthenticated: boolean;
  basicData: any;
  dataSource: any;
  stackedOptions: any;
  horizontalOptions: any;
  multiAxisOptions: any;
  value: number = 1;
  blockedDocument = false;
  datos: any = data;
  fechaActual: any;
  filtroGeneracion: any;
  filtroEtapa: any;
  informacionGeneralCompleta: CrrInformacionGeneralProyectoDTO[] = [];
  informacionGeneralFiltro: CrrInformacionGeneralProyectoDTO[] = [];
  informacionGeneralFiltroDropDown: CrrInformacionGeneralProyectoDTO[] = [];
  informacionGeneralFiltroCsv: CrrInformacionProyectoCsvDTO[] = [];
  proyectoCsv: CrrInformacionProyectoCsvDTO;
  proyectoSeleccionado: CrrInformacionGeneralProyectoDTO;
  informacionDepartamentos: CrrInformacionDepartamentosDTO[] = [];
  informacionDepartamentosFiltro: CrrInformacionDepartamentosDTO[] = [];
  informacionDepartamentosGroupBy: any = [];
  informacionPeajes: CrrInformacionPeajesDTO[] = [];
  informacionPeajesFiltro: CrrInformacionPeajesDTO[] = [];
  totalProyectos: number = 0;
  totalPeajesOperacion: number = 0;
  totalPeajesSuspension: number = 0;
  km_longituds: number = 0;

  totalEstructuracion: number = 0;
  totalOperacion: number = 0;
  totalPreconstruccion: number = 0;
  totalNormalizacion: number = 0;
  totalConstruccion: number = 0;
  totalEmpleosGenerados: number = 0;
  totalDepartamentos: number = 0;
  optionschartbar: any;
  chart3: Highcharts.ChartObject;
  renderedCajasVerdes: boolean;
  proyectos5G: ProyectoEstructuracion[] = [];
  proyectos5GFiltro: ProyectoEstructuracion;
  ngOnInit() {
    this.isAuthenticated = this.adalSvc.isAuthenticated;
    let script = this._renderer2.createElement("script");
    script.id = `deCarusel_menu`;
    script.text = `deCarusel_menu();`;
    console.log("i2");

    this._renderer2.appendChild(this.document.body, script);

    this.init();
    this.renderedCajasVerdes = true;

    this.blockedDocument = true;
    this.fechaActual = formatDate(new Date(), "dd/MM/yyyy", "es-ES");
    this.filtroGeneracion = "Todos";
    this.filtroEtapa = "Todos";
    this.reportesCarreteroService.getinformacionGeneralProyectos().subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.informacionGeneralCompleta = dato.proyectos;
            this.informacionGeneralFiltro = this.informacionGeneralCompleta;
            this.informacionGeneralFiltroDropDown =
              this.informacionGeneralCompleta;
            this.informacionDepartamentos = dato.departamentos;
            this.informacionDepartamentosFiltro =
              this.informacionDepartamentos.filter(
                (a) => a.idEtapaProyecto != 6 && a.idEtapaProyecto != 5
              );

            this.informacionDepartamentosGroupBy =
              this.informacionDepartamentosFiltro.reduce(function (
                result,
                current
              ) {
                result[current.departamento] =
                  result[current.departamento] || [];
                result[current.departamento].push(current);
                return result;
              },
                {});

            this.totalDepartamentos = Object.keys(
              this.informacionDepartamentosGroupBy
            ).length;

            let proyectos = this.informacionGeneralCompleta;

            this.informacionPeajes = dato.peajes;
            this.totalPeajesOperacion = this.informacionPeajes.filter(
              (a) => a.idEstado == 1
            ).length;
            this.totalPeajesSuspension = this.informacionPeajes.filter(
              (a) => a.idEstado == 3
            ).length;
            this.totalProyectos = proyectos.length;
            const km_longituds = proyectos
              .map((item) => item.longitudConcesionadaKm)
              .reduce((prev, curr) => prev + curr, 0);
            this.km_longituds = km_longituds;

            const estructuracion = proyectos.filter(
              (estructuracion1) =>
                estructuracion1.etapaProyecto === "Estructuración"
            );

            this.totalEstructuracion = estructuracion.length;
            const operacion = proyectos.filter(
              (estructuracion1) => estructuracion1.etapaProyecto === "Operación"
            );

            this.totalOperacion = operacion.length;
            const preconstruccion = proyectos.filter(
              (estructuracion1) =>
                estructuracion1.etapaProyecto === "Pre-construcción"
            );

            this.totalPreconstruccion = preconstruccion.length;
            const normalizacion = proyectos.filter(
              (estructuracion1) =>
                estructuracion1.etapaProyecto === "Normalización"
            );

            this.totalNormalizacion = normalizacion.length;
            const construccion = proyectos.filter(
              (estructuracion1) =>
                estructuracion1.etapaProyecto === "Construcción"
            );

            this.totalConstruccion = construccion.length;
            const empleosGenerados = proyectos
              .map((item) => item.empleosGeneradosConcesionario)
              .reduce((prev, curr) => prev + curr, 0);

            this.totalEmpleosGenerados = empleosGenerados;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
        this.blockedDocument = false;
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );

    this.reportesCarreteroService.getDatosProyectos5GBD().subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.proyectos5G = dato.proyectos.filter((p) => p.tipoModo == 2);
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => { }
    );
  }

  constructor(
    private router: Router,
    private datepipe: DatePipe,
    private activateRoute: ActivatedRoute,
    private prediosService: PrediosService,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent,
    private _renderer2: Renderer2,
    private recaudoPeajesService: RecaudoPeajesService,
    private adalSvc: MsAdalAngular6Service,
    @Inject(DOCUMENT) private document: any
  ) { }

  init() {
    this.optionschartbar = {
      chart: {
        type: "bar",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: ["Proyectos"],
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: [
        {
          color: "#31438C",
          name: "Normalización",

          data: [1],
        },
        {
          color: "#2E3AC2",
          name: "Operación y Mantenimiento",
          data: [12],
        },
        {
          color: "#0067D0",
          name: "Construcción",
          data: [26],
        },
        {
          color: "#00ADB6",
          name: "PreConstrucción",
          data: [4],
        },
        {
          color: "#73DFD2",
          name: "Estructuración",
          data: [18],
        },
      ],
    };

    let chart3 = Highcharts.chart("chartEtapas", this.optionschartbar);
    this.chart3 = chart3;
  }

  actualizarProyectos(event) {
    if (this.proyectoSeleccionado == null) {
      this.informacionGeneralFiltro = this.informacionGeneralCompleta;
      this.informacionDepartamentosFiltro = this.informacionDepartamentos;
      this.informacionPeajesFiltro = this.informacionPeajes;
    } else {
      this.informacionGeneralFiltro = this.informacionGeneralCompleta.filter(
        (e) => e.id === this.proyectoSeleccionado.id
      );

      this.informacionDepartamentosFiltro =
        this.informacionDepartamentos.filter(
          (a) =>
            a.idProyecto == this.proyectoSeleccionado.id &&
            a.idEtapaProyecto != 6 &&
            a.idEtapaProyecto != 5
        );

      this.informacionPeajesFiltro = this.informacionPeajes.filter(
        (a) => a.idProyecto === this.proyectoSeleccionado.id
      );
    }


    this.totalPeajesOperacion = this.informacionPeajesFiltro.filter(
      (a) => a.idEstado == 1
    ).length;
    this.totalPeajesSuspension = this.informacionPeajesFiltro.filter(
      (a) => a.idEstado == 3
    ).length;
    let proyectos = this.informacionGeneralFiltro;
    this.totalProyectos = proyectos.length;
    const km_longituds = proyectos
      .map((item) => item.longitudConcesionadaKm)
      .reduce((prev, curr) => prev + curr, 0);
    this.km_longituds = km_longituds;

    const estructuracion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Estructuración"
    );

    this.totalEstructuracion = estructuracion.length;
    const operacion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Operación"
    );

    this.totalOperacion = operacion.length;
    const preconstruccion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Pre-construcción"
    );

    this.totalPreconstruccion = preconstruccion.length;
    const normalizacion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Normalización"
    );

    this.totalNormalizacion = normalizacion.length;
    const construccion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Construcción"
    );

    this.totalConstruccion = construccion.length;
    const empleosGeneradosConcesionario = proyectos
      .map((item) => item.empleosGeneradosConcesionario)
      .reduce((prev, curr) => prev + curr, 0);

    this.totalEmpleosGenerados = empleosGeneradosConcesionario;

    this.informacionDepartamentosGroupBy =
      this.informacionDepartamentosFiltro.reduce(function (result, current) {
        result[current.departamento] = result[current.departamento] || [];
        result[current.departamento].push(current);
        return result;
      }, {});

    this.totalDepartamentos = Object.keys(
      this.informacionDepartamentosGroupBy
    ).length;
  }

  btnFiltroGeneracion(generacion: string) {
    this.proyectoSeleccionado = null;
    this.filtroGeneracion = generacion;

    if (generacion == "Todos") {
      this.informacionGeneralFiltro = this.informacionGeneralCompleta;
      this.informacionDepartamentosFiltro = this.informacionDepartamentos;
      this.informacionPeajesFiltro = this.informacionPeajes;
      this.renderedCajasVerdes = true;
      this.informacionGeneralFiltroDropDown = this.informacionGeneralCompleta;
    } else {
      if (generacion == "5G") {
        this.renderedCajasVerdes = false;
      } else {
        this.renderedCajasVerdes = true;
      }
      this.informacionGeneralFiltroDropDown =
        this.informacionGeneralCompleta.filter(
          (e) => e.generacion == generacion
        );
      this.informacionGeneralFiltro = this.informacionGeneralCompleta.filter(
        (e) => e.generacion == generacion
      );
      this.informacionDepartamentosFiltro =
        this.informacionDepartamentos.filter(
          (a) =>
            a.generacion == generacion &&
            a.idEtapaProyecto != 6 &&
            a.idEtapaProyecto != 5
        );
      this.informacionPeajesFiltro = this.informacionPeajes.filter(
        (a) => a.generacion == generacion
      );
    }

    if (this.filtroEtapa != "Todos") {
      this.informacionDepartamentosFiltro =
        this.informacionDepartamentosFiltro.filter(
          (a) => a.etapaProyecto == this.filtroEtapa
        );
      this.informacionPeajesFiltro = this.informacionPeajesFiltro.filter(
        (a) => a.etapaProyecto == this.filtroEtapa
      );
    }

    this.totalPeajesOperacion = this.informacionPeajesFiltro.filter(
      (a) => a.idEstado == 1
    ).length;
    this.totalPeajesSuspension = this.informacionPeajesFiltro.filter(
      (a) => a.idEstado == 3
    ).length;
    let proyectos = this.informacionGeneralFiltro;
    this.totalProyectos = proyectos.length;
    const km_longituds = proyectos
      .map((item) => item.longitudConcesionadaKm)
      .reduce((prev, curr) => prev + curr, 0);
    this.km_longituds = km_longituds;

    const estructuracion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Estructuración"
    );

    this.totalEstructuracion = estructuracion.length;
    const operacion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Operación"
    );

    this.totalOperacion = operacion.length;
    const preconstruccion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Pre-construcción"
    );

    this.totalPreconstruccion = preconstruccion.length;
    const normalizacion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Normalización"
    );

    this.totalNormalizacion = normalizacion.length;
    const construccion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Construcción"
    );

    this.totalConstruccion = construccion.length;
    const empleosGeneradosConcesionario = proyectos
      .map((item) => item.empleosGeneradosConcesionario)
      .reduce((prev, curr) => prev + curr, 0);

    this.totalEmpleosGenerados = empleosGeneradosConcesionario;

    this.informacionDepartamentosGroupBy =
      this.informacionDepartamentosFiltro.reduce(function (result, current) {
        result[current.departamento] = result[current.departamento] || [];
        result[current.departamento].push(current);
        return result;
      }, {});

    this.totalDepartamentos = Object.keys(
      this.informacionDepartamentosGroupBy
    ).length;

    var botonesactive = document.getElementsByClassName("r_btn"); // a list of matching elements, *not* the element itself
    for (var i = 0, all = botonesactive.length; i < all; i++) {
      botonesactive[i].classList.remove("active");
    }

    this.chart3.update({
      series: [
        {
          color: "#31438C",
          name: "Normalización",

          data: [this.totalNormalizacion],
        },
        {
          color: "#2E3AC2",
          name: "Operación y Mantenimiento",
          data: [this.totalOperacion],
        },
        {
          color: "#0067D0",
          name: "Construcción",
          data: [this.totalConstruccion],
        },
        {
          color: "#00ADB6",
          name: "PreConstrucción",
          data: [this.totalPreconstruccion],
        },
        {
          color: "#73DFD2",
          name: "Estructuración",
          data: [this.totalEstructuracion],
        },
      ],
    });
  }

  activebtn(e: any) {
    console.log(e.srcElement);
    let divclick = e.srcElement;
    divclick.classList.add("active");
    console.log(divclick);
  }

  btnFiltroEtapa(etapa: string) {
    this.filtroEtapa = etapa;
    this.proyectoSeleccionado = null;

    if (etapa == "Todos") {
      this.informacionGeneralFiltro = this.informacionGeneralCompleta;
      this.informacionDepartamentosFiltro = this.informacionDepartamentos;
      this.informacionPeajesFiltro = this.informacionPeajes;
    } else {
      this.informacionGeneralFiltro = this.informacionGeneralCompleta.filter(
        (e) => e.etapaProyecto == etapa
      );

      this.informacionDepartamentosFiltro =
        this.informacionDepartamentos.filter(
          (a) =>
            a.etapaProyecto == etapa &&
            a.idEtapaProyecto != 6 &&
            a.idEtapaProyecto != 5
        );
      this.informacionPeajesFiltro = this.informacionPeajes.filter(
        (a) => a.etapaProyecto == etapa
      );
    }

    if (this.filtroGeneracion != "Todos") {
      this.informacionGeneralFiltro = this.informacionGeneralFiltro.filter(
        (a) => a.generacion == this.filtroGeneracion
      );
      this.informacionDepartamentosFiltro =
        this.informacionDepartamentosFiltro.filter(
          (a) =>
            a.generacion == this.filtroGeneracion &&
            a.idEtapaProyecto != 6 &&
            a.idEtapaProyecto != 5
        );
      this.informacionPeajesFiltro = this.informacionPeajesFiltro.filter(
        (a) => a.generacion == this.filtroGeneracion
      );
    }

    let proyectos = this.informacionGeneralFiltro;
    this.totalProyectos = proyectos.length;
    this.totalPeajesOperacion = this.informacionPeajesFiltro.filter(
      (a) => a.idEstado == 1
    ).length;
    this.totalPeajesSuspension = this.informacionPeajesFiltro.filter(
      (a) => a.idEstado == 3
    ).length;
    const km_longituds = proyectos
      .map((item) => item.longitudConcesionadaKm)
      .reduce((prev, curr) => prev + curr, 0);
    this.km_longituds = km_longituds;
    const empleosGeneradosConcesionario = proyectos
      .map((item) => item.empleosGeneradosConcesionario)
      .reduce((prev, curr) => prev + curr, 0);
    this.totalEmpleosGenerados = empleosGeneradosConcesionario;

    this.informacionDepartamentosGroupBy =
      this.informacionDepartamentosFiltro.reduce(function (result, current) {
        result[current.departamento] = result[current.departamento] || [];
        result[current.departamento].push(current);
        return result;
      }, {});

    this.totalDepartamentos = Object.keys(
      this.informacionDepartamentosGroupBy
    ).length;

    const estructuracion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Estructuración"
    );

    this.totalEstructuracion = estructuracion.length;
    const operacion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Operación"
    );

    this.totalOperacion = operacion.length;
    const preconstruccion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Pre-construcción"
    );

    this.totalPreconstruccion = preconstruccion.length;
    const normalizacion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Normalización"
    );

    this.totalNormalizacion = normalizacion.length;
    const construccion = proyectos.filter(
      (estructuracion1) => estructuracion1.etapaProyecto === "Construcción"
    );

    this.totalConstruccion = construccion.length;

    var botonesactive1 = document.getElementsByClassName("btn_dinamic"); //
    for (var i = 0, all = botonesactive1.length; i < all; i++) {
      botonesactive1[i].classList.remove("active");
    }

    var botonesactive = document.getElementsByClassName("r_btnetapa"); //
    for (var i = 0, all = botonesactive.length; i < all; i++) {
      botonesactive[i].classList.remove("active");
    }
  }

  onNavigate(link) {
    window.open(link, "_blank");
  }

  descargarArchivoTraficoYRecaudo() {
    this.blockedDocument = true;
    this.recaudoPeajesService.verAchivoTraficoyRecaudo()
      .subscribe(result => {
        this.recaudoPeajesService.downLoadFile(result, 'application/zip')
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  /**
   * Metodo que descarga el excel del reporte
   */
  descargarArchivoPredialAgrupado() {
    const reporteExcelHoja1: any[] = [];
    this.blockedDocument = true;

    forkJoin(
      this.prediosService.getPredialAgrupadoReporte()
    ).subscribe(([data1]) => {
      debugger;
      if (data1.informeEjecutivoAgrupado.length > 0) {
        data1.informeEjecutivoAgrupado.forEach(rpt => {
          const hoja1 = {
            'Proyecto': rpt.proyecto,
            'Generación': rpt.generacion,
            'Unidad Funcional': rpt.unidadFuncional,
            'Subtramo': rpt.subtramo,
            'Fecha 40% Disponible': (rpt.fechaObtuvoDisponibilidad != null) ? this.datepipe.transform(rpt.fechaObtuvoDisponibilidad, 'dd/MM/yyyy') : null,
            'Alcance Fisico Contractual': rpt.alcanceFisicoContractual,
            'Origen Destino': rpt.origenDestino,
            'Longitud Proyecto(Km)': rpt.longitudProyecto,
            'Longitud Efectiva Total Predial(Km)': rpt.longitudEfectivaPredialTotal,
            'Predios Requeridos': rpt.prediosRequeridos,
            'Predios Ofertados': rpt.prediosOfertados,
            'Predios Disponibles': rpt.prediosDisponibles,
            'Porcentaje Predios Disponibles': rpt.porcentajePrediosDisponibles,
            'Longitud Disponible(Km)': rpt.longitudDisponible,
            'Porcentaje Longitud Disponible': rpt.porcentajeLongitudDisponible,
            'Predios Adquiridos por Enajenacion Voluntaria': rpt.prediosAdquiridosEnajenacionVoluntaria,
            'Porcentaje por Enajenacion': rpt.porcentajePrediosAdquiridosEnajenacion,
            'Predios adquiridos por expropiación': rpt.prediosExpropiados,
            'Porcentaje por expropiación': rpt.porcentajePrediosExpropiados,
            'Total Predios Adquiridos': rpt.totalPrediosAdquiridos,
            'Porcentaje Total Predios Adquiridos': rpt.porcentajeTotalPrediosAdquiridos,
            'Predios Faltantes por Adquirir': rpt.prediosFaltantesPorAdquirir,
            'Porcentaje de Predios Adquiridos': rpt.porcentajePrediosFaltantesPorAdquirir,
            'Observaciones': rpt.observaciones,
            'Fecha de Corte': (rpt.fechaCorte != null) ? this.datepipe.transform(rpt.fechaCorte, 'dd/MM/yyyy') : null,
            'Tecnico a Cargo': rpt.tecnicoACargo,
            'Fecha Asociada Tecnico a Cargo': (rpt.fechaAsociadaTecnicoACargo != null) ? this.datepipe.transform(rpt.fechaAsociadaTecnicoACargo, 'dd/MM/yyyy') : null
          }
          reporteExcelHoja1.push(hoja1);
        });
      } else {
        const hoja1 = {
          'Proyecto': '',
          'Generación': '',
          'Unidad Funcional': '',
          'subtramo': '',
          'FechaObtuvoDisponibilidad': '',
          'alcanceFisicoContractual': '',
          'origenDestino': '',
          'longitudProyecto': '',
          'longitudEfectivaPredialTotal': '',
          'prediosRequeridos': '',
          'prediosOfertados': '',
          'prediosDisponibles': '',
          'porcentajePrediosDisponibles': '',
          'longitudDisponible': '',
          'porcentajeLongitudDisponible': '',
          'prediosAdquiridosEnajenacionVoluntaria': '',
          'porcentajePrediosAdquiridosEnajenacion': '',
          'prediosExpropiados': '',
          'porcentajePrediosExpropiados': '',
          'totalPrediosAdquiridos': '',
          'porcentajeTotalPrediosAdquiridos': '',
          'prediosFaltantesPorAdquirir': '',
          'porcentajePrediosFaltantesPorAdquirir': '',
          'observaciones': '',
          'fechaCorte': '',
          'tecnicoACargo': '',
          'fechaAsociadaTecnicoACargo': ''
        }
        reporteExcelHoja1.push(hoja1);
      }

      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(reporteExcelHoja1);

        worksheet['!cols'] = [
          { wch: 25 }, { wch: 20 }, { wch: 25 }, { wch: 20 }, { wch: 20 }, { wch: 40 },
          { wch: 40 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 },
          { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 },
          { wch: 22 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 20 }, { wch: 20 },
          { wch: 25 }, { wch: 20 }, { wch: 20 }];


        const workbook = {
          Sheets: {
            Predios: worksheet
          }, SheetNames: ['Predios']
        };

        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'reportePredios');
      });

    }, error => {
      this.blockedDocument = false;
    },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo utilizado para descargar el archivo en formato xlsx generado
   * @param buffer
   * @param fileName
   */
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      this.blockedDocument = false;
    });
  }


  irAreportesPeajesTarifas() {
    this.router.navigate(["/carreteros/reportes/reportesPeajes"]);
  }

  irAReportesCovid() {
    this.router.navigate(["/carreteros/reportes/reportesCovid"]);
  }

  exportCsvProyectosFiltrados() {
    this.informacionGeneralFiltroCsv = [];
    var options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      useBom: true,
      headers: [
        "Id Proyecto",
        "Proyecto",
        "Etapa",
        "Generación",
        "Porcentaje de avance",
      ],
    };

    this.informacionGeneralFiltro.forEach((element) => {
      this.proyectoCsv = new CrrInformacionProyectoDTO();
      this.proyectoCsv.codProyecto = element.codProyecto;
      this.proyectoCsv.proyecto = element.proyecto;
      this.proyectoCsv.etapaProyecto = element.etapaProyecto;
      this.proyectoCsv.generacion = element.generacion;
      this.proyectoCsv.porcentajeAvance =
        (element.porcentajeAvance * 100).toFixed(2) + " %";
      this.informacionGeneralFiltroCsv.push(this.proyectoCsv);
    });

    new ngxCsv(
      this.informacionGeneralFiltroCsv,
      "Proyectos" + "_export_" + new Date().getTime(),
      options
    );
  }

  renderedVerDetalle(proyecto: CrrInformacionGeneralProyectoDTO) {
    if (proyecto.generacion == "5G") {
      this.proyectos5GFiltro = this.proyectos5G.filter(
        (p) => p.id == proyecto.id
      )[0];

      if (this.proyectos5GFiltro != null) {

        // console.log("this.proyectos5GFiltro");
        // console.log(this.proyectos5GFiltro);
        if (this.proyectos5GFiltro.imagen1 != null) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
