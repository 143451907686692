import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponentesComunesService {

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  public END_POINT = '';

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  intercambiarMensaje(message: string) {
    this.messageSource.next(message)
  }

  public listarPrediosPorProyecto(idproyecto): Observable<any> {
    return this.http.get(this.END_POINT + '/PrdPredio/listarPrediosPorProyecto/'+idproyecto )
      .pipe(
        retry(1));
  }

  public listarPrdDisponibilidadPrediosPorProyecto(idproyecto): Observable<any> {
    return this.http.get(this.END_POINT + '/PrdPredio/ListarPrdDisponibilidadPrediosPorProyecto/'+idproyecto )
    .pipe(
      retry(1));
  }

  public listarPrdMargenPrediosPorProyecto(idproyecto): Observable<any> {
    return this.http.get(this.END_POINT + '/PrdPredio/ListarPrdMargenPrediosPorProyecto/'+idproyecto )
    .pipe(
      retry(1));
  }

  public listarPrdPropietariosPrediosPorProyecto(idproyecto): Observable<any> {
    return this.http.get(this.END_POINT + '/PrdPredio/ListarPrdPropietariosPrediosPorProyecto/'+idproyecto )
    .pipe(
      retry(1));
  }

  public listarPrdDocumentoPrediosPorProyecto(idproyecto): Observable<any> {
    return this.http.get(this.END_POINT + '/PrdPredio/ListarPrdDocumentoPrediosPorProyecto/'+idproyecto )
    .pipe(
      retry(1));
  }

  public listarProyectos(): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/listarProyectos')
      .pipe(
        retry(1));
  }

  public listarEjecucionesPorProyecto(idProyecto: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/listarEjecucionesPorProyecto/' + idProyecto)
      .pipe(
        retry(1));
  }

  public obtenerProyecto(idProyecto: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/obtenerProyecto/' + idProyecto)
      .pipe(
        retry(1));
  }

  public obtenerEjecucion(idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/obtenerEjecucion/' + idEjecucion)
      .pipe(
        retry(1));
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
