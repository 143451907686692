
export class InformacionGeneral {
    constructor(
        public id?: any, 
        public idPersonaJuridica?: any, 
        public idProyecto?: any,         
        public proyecto?: any, 
        public codigoProyecto?: any, 
        public descripcionProyecto?: any, 
        public razonSocial?: any, 
        public nitRS?: any, 
        public sigla?: any, 
        public departamentoRS?: any, 
        public idDepartamentoRS?: any, 
        public municipioRS?: any, 
        public idMunicipioRS?: any, 
        public direccion?: any, 
        public correoRS?: any, 
        public telefonoRS?: any, 
        public representanteLegal?: any, 
        public numeroDocumentoRL?: any, 
        public correoRL?: any,
        public isRemisorio?: any,
        public firmantes?: Firmantes[]) {}
}


export class Firmantes {
    constructor(
        public id?: any, 
        public idProyecto?: any, 
        public proyecto?: any, 
        public idPersonaJuridica?: any, 
        public personaJuridica?: any, 
        public idPersona?: any, 
        public persona?: any, 
        public numeroDocumento?: any, 
        public tipoDocumento?: any, 
        public cargo?: any, ) {}
}

export class InformacionGeneralRadicacion {
    constructor(
        public id?: any, 
        public idPersonaJuridica?: any, 
        public personaJuridica?: any, 
        public tipoBorradorFinalizado?: any, 
        public idProyecto?: any,         
        public proyecto?: any, 
        public numeroTemporal?: any, 
        public fechaCreacion?: any, 
        public fechaCreacionStr?: any, 
        public fechaRadicacion?: any, 
        public fechaRadicacionStr?: any, 
        public idTipoDocumento?: any, 
        public tipoDocumento?: any, 
        public correoElectronicoPersonal?: any,         
        public asunto?: any, 
        public datosRemitenteJson?: any, 
        public descripcion?: any,
        public numeroRadicacion?: any
         ) {}
}

export class RwbDocumentoRadicadoDTO{
    constructor( 
        public id?: any, 
        public idProyecto?: any, 
        public proyecto?: any, 
        public tipoBorradorFinalizado?: any, 
        public idPersonaJuridica?: any, 
        public personaJuridica?: any, 
        public numeroTemporal?: any, 
        public fechaCreacion?: any, 
        public fechaCreacionStr?: any, 
        public numeroRadicacion?: any, 
        public fechaRadicacion?: any, 
        public fechaRadicacionStr?: any, 
        public idTipoDocumento?: any, 
        public tipoDocumento?: any, 
        public descripcion?: any, 
        public asunto?: any, 
        public correoElectronicoPersonal?: any, 
        public datosRemitenteJson?: any, 
        
        //Estos datos son del proyecto
        public idDepartamento?: any, 
        public departamento?: any, 
        public idCiudad?: any, 
        public ciudad?: any, 
        public direccion?: any, 
        public telefono?: any, 
        public nit?: any, 
        public razonSocial?: any, 
        public sigla?: any, 
        public representanteLegal?: any, 
    ) {}
}




export class ArchivoRemisorio{
    constructor(public nombre: string, public estado:string, public nombreCompleto:string, public idTipoDocumento:string, public archivo: string){
    
    }
} 

export class ArchivoAnexo{
    constructor(public nombre: string, public estado:string, public nombreCompleto:string, public idTipoDocumento:string, public archivo: string){
    
    }
} 