import { Component, OnInit, } from '@angular/core';
import { ConsultaArchivoRadicacionWebService, } from './consulta-archivo-radicacion-web.service';
import { HttpClient } from "@angular/common/http";
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';


@Component({
    selector: 's-consulta-archivo-radicacion-web',
    templateUrl: './consulta-archivo-radicacion-web.component.html',
    styleUrls: ['./consulta-archivo-radicacion-web.component.css']
})
export class ConsultaArchivoRadicacionWebComponent implements OnInit {

    constructor(private _service: ConsultaArchivoRadicacionWebService,
                 private http: HttpClient,
                 private messageService: MessageService,
                 private principalComponent: PrincipalComponent) { }

     // Inicializaciones de Variables
     loading : boolean;
     displayedColumns1;
     listado: any = [];
  
    ngOnInit() {
        this.loading = true;
        this.displayedColumns1 = [
            { field: 'nombreArchivo', header: 'Nombre Archivo' },
            { field: 'fechaInicioDescargaStr', header: 'Fecha Descarga' },
            { field: 'descargaCompleta', header: 'Descarga Completa' },
            { field: 'numeroTemporal', header: 'Número Temporal' },
            { field: 'numeroRadicacion', header: 'Número Radicacion' },
            { field: 'direccionIpUsuario', header: 'IP Usuario' }
          ];
          this.cargarlistado();
    }
    
    /**
     * Metodo que carga el listado
     */
    cargarlistado(){
        return this._service.getListadoArchivoConsulta().subscribe((data: {}) => {
          this.responseData(data);
          this.loading = false;
        }, (error)=>{
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });    
    }

    /**
     * Metodo que racorre la info
     * @param payload 
     */
    responseData(payload) {
        if (payload === null) {
          this.lanzarMensajeError("No se econtraron datos");
          this.loading = false;
        } else {
          this.listado = payload;
          this.loading = false;
        }
    }
  
    // /////////////////////////////Mensajes//////////////////////////////
    lanzarMensajeInfo(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
    }
    lanzarMensajeError(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    }
    lanzarMensajeWarning(mensaje: string) {
        this.limpiarMensajes();
        this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
    }

    limpiarMensajes() {
        this.messageService.clear();
    }

}
