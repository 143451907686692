import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatTableDataSource , MatPaginator} from '@angular/material';
import { SeguimientoService } from './seguimiento.service';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

import { SeguimientoDTO,
  UnidadesFuncionales,
  Estados,
  Priorizaciones,
  Observaciones,
  Responsables } from '../../../dtos/tramites-ambientales/seguimientoDTO';
  import { Location } from '@angular/common';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'eliminar-dialog',
    templateUrl: '../eliminar/eliminar.html',
  })
  // tslint:disable-next-line:component-class-suffix
  export class EliminiarDialog {
  
  confirmacion = false;
  constructor(
    public dialogRef: MatDialogRef<EliminiarDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  
  onNoClick(payload): void {
      this.dialogRef.close();
  }
  
  onConfirm(): void {
      this.dialogRef.close(true);
  }
  
  onDismiss(): void {
      // Close the dialog, return false
      this.dialogRef.close(false);
  }
}  

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'estados-dialog',
  templateUrl: './estados.html',
})
// tslint:disable-next-line:component-class-suffix
export class EstadosDialog {
  maxDate: Date;
  enableAdministrativo = false;

  constructor(
    public dialogRef: MatDialogRef<EstadosDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeEstado(event){
    if (event === 9) {
      this.enableAdministrativo = true;
    } else {
      this.enableAdministrativo = false;
      this.data.actoAdministrativo = null;

    }
  }

   
  openDialogEliminar(): void {
    
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'priorizaciones-dialog',
  templateUrl: './priorizaciones.html',
})
// tslint:disable-next-line:component-class-suffix
export class PriorizacionesDialog {
  maxDate: Date;

  constructor(
    public dialogRef: MatDialogRef<PriorizacionesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'responsables-dialog',
  templateUrl: './responsables.html',
})
// tslint:disable-next-line:component-class-suffix

export class ResponsablesDialog implements OnInit {

  municipios: any = [];
  temp;
  disabledEntidad = false;
  maxDate: Date;

  constructor(
    public dialogRef: MatDialogRef<ResponsablesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private detalleService: SeguimientoService
    ) {
      this.temp = data;
      this.maxDate = new Date();
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (this.temp.idDepartamento) {
      this.filtrarMunicipio(this.temp.id);
    }
  }

  filtrarMunicipio(event) {
    this.municipios = [];
    if (event) {
      return this.detalleService.getCiudadesPorDepartamento(event).subscribe((data: {}) => {
        this.data.municipios = data;
      },error => {
      });
    }
  }

  cambioResponsable(event) {
    if (event === 2) {
      this.disabledEntidad = false;
    } else {
      this.disabledEntidad = true;
      this.temp.idDepartamento = null;
      this.temp.idMunicipio = null;

    }
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'observaciones-dialog',
  templateUrl: './observaciones.html',
})
// tslint:disable-next-line:component-class-suffix
export class ObservacionesDialog {
  maxDate: Date;
  constructor(
    public dialogRef: MatDialogRef<ObservacionesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.maxDate = new Date();
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.sass']
})

export class SeguimientoComponent implements OnInit {

  estados: any = [];
  priorizaciones: any = [];
  observacionesDialog: any = [];
  responsables: any = [];
  departamentos: any = [];
  municipios: any = [];
  temporalEnviar: any = [];
  fechaReporte: Date;
  actoAdministrativo: string;
  observaciones: string;

  estadosTemporal: any = {
    idCompromiso: null,
    idEstado: null,
    fechaReporte: '',
    actoAdministrativo: '',
    observaciones: '',
    nuevo: false,
  };

  priorizacionesTemporal: any = {
    idCompromiso: null,
    idPriorizacion: null,
    fechaReporte: '',
    observaciones: '',
    nuevo: false,
  };

  responsablesTemporal: any = {
    idCompromiso: null,
    idMunicipio: '',
    radicado: '',
    fechaRadicado: null,
    idResponsable: null,
    observaciones: '',
    nuevo: false,
  };

  observacionesTemporal: any = {
    idCompromiso: null,
    fechaReporte: '',
    observaciones: '',
    nuevo: false,
  };


  proyectos: SeguimientoDTO = {};
  id: number;
  nombreProyecto;
  displayedColumns: string[] = ['unidadFuncional', 'subTramo', 'etapa', 'fechaRegistro'];
  displayedEstados: string[] = ['estado', 'fechaReporte', 'actoAdministrativo', 'observaciones', 'fechaRegistro'];
  displayedPriorizaciones: string[] = ['priorizacion', 'fechaReporte', 'observaciones', 'fechaRegistro'];
  displayedResponsables: string[] = ['responsable', 'radicado', 'fechaRadicado', 'observaciones', 'fechaRegistro'];
  displayedObservaciones: string[] = [ 'fechaReporte', 'observaciones', 'fechaRegistro'];


  dataSource: MatTableDataSource<UnidadesFuncionales>;
  dataSourceEstados: MatTableDataSource<Estados>;
  dataSourcePriorizaciones: MatTableDataSource<Priorizaciones>;
  dataSourceResponsables: MatTableDataSource<Observaciones>;
  dataSourceObservaciones: MatTableDataSource<Responsables>;



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginatorEstados: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortEstados: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginatorPriorizaciones: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortPriorizaciones: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginatorResponsables: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortResponsables: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginatorObservaciones: MatPaginator;
  @ViewChild(MatSort, { static: true }) sortObservaciones: MatSort;

  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(
    private service: SeguimientoService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    public location: Location, private principalComponent: PrincipalComponent
    ) {
  }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarProyecto(this.id);
      this.cargarEstados();
      this.cargarPriorizaciones();
      this.cargarResponsables();
      this.cargarDepartamentos();
      // localStorage.setItem('idP', JSON.stringify(this.id));
    });
  }

  

  onNew() {
    this.route.navigate(['/infTramitesADetalle', 'new']);
  }

  cargarDepartamentos() {
    this.departamentos = [];
    return this.service.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  filtrarMunicipio(event) {
    this.municipios = [];
    if (event) {
      return this.service.getCiudadesPorDepartamento(event).subscribe((data: {}) => {
        this.municipios = data;
      },error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }
  }


  cargarProyecto(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    this.service.obtenerListaSeguimiento(id).subscribe((data: {}) => {
      if (data) {
        this.proyectos = data;
        this.initData(this.proyectos);

      }
      this.dialogSpin.close();
    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  initData(payload) {
    this.dataSource = new MatTableDataSource(payload.compromisoMaestro.lstUnidadFuncional);
    this.dataSource.paginator = this.paginator;
    this.dataSourceEstados = new MatTableDataSource(payload.estados);
    this.dataSourcePriorizaciones = new MatTableDataSource(payload.priorizaciones);
    this.dataSourceResponsables = new MatTableDataSource(payload.responsables);
    this.dataSourceObservaciones = new MatTableDataSource(payload.observaciones);
   // this.dataSourceEstados.paginator = this.paginator;
  }

  cargarEstados() {
    return this.service.getEstadoCompromiso().subscribe((data: {}) => {
      this.estados = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarPriorizaciones() {
    return this.service.getPriorizacion().subscribe((data: {}) => {
      this.priorizaciones = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarResponsables() {
    return this.service.getResponsable().subscribe((data: {}) => {
      this.responsables = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cancelar() {
   // this.route.navigate(['/infTramitesA', JSON.parse(localStorage.getItem('idP'))]);
    this.location.back();

  }

  
  openDialogEliminar(): void {
    const dialogRef = this.dialog.open(EliminiarDialog, {
      height: '200px', width: '300px', data: {message: "¿Esta seguro de eliminar este registro?"}
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result)  {
            // this.service.eliminarTramiteAmbiental(id).subscribe((data: {}) => {
            // this.cargarProyecto(this.id);
        // });
        }
    });
  }

  getEstado(id) {
    if (id) {
      const dataLength = this.estados.length;
      for (let i = 0; i <= dataLength; i++) {
        if (this.estados[i].id === id) {
          return this.estados[i].nombre;
        }
      }
    } else {
      return '';
    }
  }

  modalEstado(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(EstadosDialog, {
      data: {estados: this.estados,
        fechaReporte: this.fechaReporte,
        actoAdministrativo: this.actoAdministrativo,
        observaciones: this.observaciones
      }

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {

            this.estadosTemporal = {
              idCompromiso: Number(this.id),
              idEstado: result.idEstado,
              fechaReporte: result.fechaReporte,
              actoAdministrativo: result.actoAdministrativo,
              observaciones: result.observaciones,
              nuevo: true,
            };
            this.temporalEnviar = [];
            this.temporalEnviar.push(this.estadosTemporal);

            this.service.guardarSeguientoEstado(this.temporalEnviar).subscribe((data: {}) => {
              if (data) {
                this.initData(data);
              }
            },error => {
              this.principalComponent.cargarErrorServicio(error);
            });
           }
          });
    }
  }

  modalPriorizacion(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(PriorizacionesDialog, {
      data: {priorizaciones: this.priorizaciones,
        fechaReporte: this.fechaReporte,
        observaciones: this.observaciones
      }

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {

            this.priorizacionesTemporal = {
              idCompromiso: Number(this.id),
              idPriorizacion: result.idPriorizacion,
              fechaReporte: result.fechaReporte,
              observaciones: result.observaciones,
              nuevo: true,
            };
            this.temporalEnviar = [];
            this.temporalEnviar.push(this.priorizacionesTemporal);

            this.service.guardarSeguientoPriorizacion(this.temporalEnviar).subscribe((data: {}) => {
              if (data) {
                this.initData(data);
              }
            },error => {
              this.principalComponent.cargarErrorServicio(error);
            });
           }
          });
    }
  }

  modalResponsable(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(ResponsablesDialog, {width: '600px',
      data: {responsables: this.responsables,
        departamentos: this.departamentos,
        municipios: this.municipios,
        radicado: this.actoAdministrativo,
        observaciones: this.observaciones
      }

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {

            this.responsablesTemporal = {
              idCompromiso: Number(this.id),
              idMunicipio: result.idMunicipio,
              radicado: result.radicado,
              fechaRadicado: result.fechaRadicado,
              idResponsable: result.idResponsable,
              observaciones: result.observaciones,
              nuevo: true
            };
            this.temporalEnviar = [];
            this.temporalEnviar.push(this.responsablesTemporal);

            this.service.guardarSeguientoResponsable(this.temporalEnviar).subscribe((data: {}) => {
              if (data) {
                this.initData(data);
              }
            },error => {
              this.principalComponent.cargarErrorServicio(error);
            });
           }
          });
    }
  }

  modalObservacion(isEdit, isDelete, payload, dataPay, index) {
    if (!isEdit) {
      const dialogRef = this.dialog.open(ObservacionesDialog, {
      data: {
        fechaReporte: this.fechaReporte,
        observaciones: this.observaciones
      }

    });
      dialogRef.afterClosed().subscribe(result => {
          if (result != null && result !== '')  {

            this.observacionesTemporal = {
              idCompromiso: Number(this.id),
              fechaReporte: result.fechaReporte,
              observaciones: result.observaciones,
              nuevo: true,
            };
            this.temporalEnviar = [];
            this.temporalEnviar.push(this.observacionesTemporal);

            this.service.guardarSeguientoObservacion(this.temporalEnviar).subscribe((data: {}) => {
              if (data) {
                this.initData(data);
              }
            },error => {
              this.principalComponent.cargarErrorServicio(error);
            });
           }
          });
    }
  }


}


