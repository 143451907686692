export class ProyectoCarretero {
    constructor(
        public idProyecto: number,
        public codigo: string,
        public nombre: string,
        public nombreCorto: string,
        public rutaNacional: string,
        public idGeneracion?: number,
        public idOla?: number,
        public idTipo?: number) { }
}


export class InformacionGeneral {
    constructor(
        public id?: number, 
        public idProyecto?: number, 
        public idUnidadFuncional?: number, 
        public idIntervencionUf?: number, 
        public intervencionUf?: string,
        public canal?: string,
        public playList?: string,               
        public idVideo?: string,        
        public nombreVideo?: string,        
        public avance?: string,        
        public rutaVideo?: string,        
        public descripcion?: string,        
        public fechaCorte?: Date) {}

}
