import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { Observable, forkJoin } from 'rxjs';
import { EjecucionProyectoService } from '../ejecucion_proyecto.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-avance-proyecto-reportar-etapas',
  templateUrl: './avance-proyecto-reportar-etapas.component.html',
  styleUrls: ['./avance-proyecto-reportar-etapas.component.sass']
})
export class AvanceProyectoReportarEtapasComponent implements OnInit {

  blockedDocument: boolean = false;
  vistaActual: string = "";

  @Input() proyectoSeleccionado: ProyectoDTO;

  @Output() OutputIrAvanceProyectoMenu = new EventEmitter();

  idUltimaEjecucionPublicada: number;
  porcentajeAvanceProyectoPublicado: number;

  constructor(private ejecucionProyectoService: EjecucionProyectoService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    //Bloquear pantalla
    this.blockedDocument = true;
    this.cargarInfoInicial();
    this.vistaActual = 'menuEtapas';
  }

  cargarInfoInicial() {
    forkJoin(
      this.ejecucionProyectoService.srvAvanceProyectoPublicado(this.proyectoSeleccionado.id)
    ).subscribe(([avancePublicadoList]) => {
      if (avancePublicadoList != null && avancePublicadoList.length > 0) {
        this.idUltimaEjecucionPublicada = avancePublicadoList[0].idEjecucion;
        this.porcentajeAvanceProyectoPublicado = avancePublicadoList[0].porcentajeAvance;
        //Desbloquear pantalla
        this.blockedDocument = false;
      } else {
        this.idUltimaEjecucionPublicada = null;
        this.porcentajeAvanceProyectoPublicado = null;
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  irAConstruccion() {
    this.vistaActual = 'construccion';
  }

  irAMenuEtapas() {
    this.vistaActual = 'menuEtapas';
  }

  volver() {
    this.OutputIrAvanceProyectoMenu.emit("");
  }

}
