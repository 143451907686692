import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TrnPersona } from 'src/app/admin-personas/trn-persona';
import { EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { PersonaService } from 'src/app/comun/persona/persona.service';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { CtoTipoNovedadDTO } from 'src/app/contratacion/contratacion-dto/cto-tipo-novedad';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { TrnSiNo } from 'src/app/parametricas/trn-si-no';
import { CtoNovedadContratoDTO } from '../../../../contratacion-dto/cto-novedad-contrato';
import { NovedadContratoBaseComponent } from '../novedad-contrato-base/novedad-contrato-base.component';

@Component({
  selector: 'app-novedad-contrato-cambio-honorarios',
  templateUrl: './novedad-contrato-cambio-honorarios.component.html',
  styles: []
})
export class NovedadContratoCambioHonorariosComponent implements OnInit {

  @Input()
  novedadContrato: CtoNovedadContratoDTO = {};
  @Input()
  ctoContratoInformacionVigente: CtoContratoInformacionVigenteEncabezadoDTO;
  @Input()
  tipoNovedad: CtoTipoNovedadDTO;
  @Output()
  ctoNovedadOutput = new EventEmitter<CtoNovedadContratoDTO>();

  constructor(
    public lineaBase: NovedadContratoBaseComponent,
    private principalComponent: PrincipalComponent
  ) { }


  ngOnInit() {
  }

  /**
   * Metodo que valida y emite el objeto de CtoNovedadContratoDTO al metodo principal de guardarNovedad al dar clic en el botón guardar
   */
  guardarNovedad() {
    if (this.validarNovedad()) {
      this.ctoNovedadOutput.emit(this.novedadContrato);
    }
  }

  /**
  * Metodo de evento de seleción para determinar si un valor es nulo, verdadero o falso, y se asigna a la variable EsResponsableIva.
  * Se agrega validación cuando la variable esResponsableIva es verdadera, para cambiar el estado a null de la variable estaObligadoFacturar 
  * @param sinoSeleccion Objeto de tipo TrnSiNo
  */
  onSelectEsResponsableIva(sinoSeleccion: TrnSiNo) {
    this.novedadContrato.esResponsableIva = sinoSeleccion.valor;
    if (this.novedadContrato.esResponsableIva) {
      this.novedadContrato.estaObligadoFacturar = null;
    }
  }

  /**
   * Metodo de evento de seleción para determinar si un valor es nulo, verdadero o falso, y se asigna a la variable EstaObligadoFacturar.
   * @param sinoSeleccion Objeto de tipo TrnSiNo
   */
  onSelectEstaObligadoFacturar(sinoSeleccion: TrnSiNo) {
    this.novedadContrato.estaObligadoFacturar = sinoSeleccion.valor;
  }

  /**
   * Metodo que valida la información a guardar para la novedad
   * @returns Verdadero o Falso
   */
  validarNovedad(): boolean {

    let esNovedadValida = true;

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion > this.ctoContratoInformacionVigente.fechaTerminacionContrato) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMayorFechaFinContrato);
    }

    if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaSuscripcion < this.ctoContratoInformacionVigente.fechainicio) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaSuscripcionMenorFechaInicialContrato);
    }

    if (this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion > this.ctoContratoInformacionVigente.fechaTerminacionContrato) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMayorFechaFinContrato);
    }

    if (this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion < this.ctoContratoInformacionVigente.fechainicio) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMenorFechaInicialContrato);
    }

    // if (this.novedadContrato.fechaSuscripcion != null && this.novedadContrato.fechaAplicacion != null && this.novedadContrato.fechaAplicacion < this.novedadContrato.fechaSuscripcion) {
    //   esNovedadValida = false;
    //   this.lineaBase.lanzarMensajeError(this.lineaBase.msgValFechaAplicacionMenorFechaSuscripcion);
    // }

    if (this.novedadContrato.esResponsableIva == null) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValCesionEsResponsableIvaNulo);
    }

    if (this.novedadContrato.esResponsableIva != null && !this.novedadContrato.esResponsableIva && this.novedadContrato.estaObligadoFacturar == null) {
      esNovedadValida = false;
      this.lineaBase.lanzarMensajeError(this.lineaBase.msgValCesionEstaObligadoFacturarNulo);
    }
    return esNovedadValida;
  }
}
