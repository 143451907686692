import { Component } from '@angular/core';

@Component({
  selector: 'app-proyectos-aeroportuario',
  templateUrl: './proyectos-aeroportuario.component.html',
  styleUrls: ['./proyectos-aeroportuario.component.sass']
})
export class ProyectosAeroportuarioComponent {

}
