import { Component, OnInit } from "@angular/core";
import { MenuItem } from "primeng/api";
import { ProyectoEstructuracion } from "../../proyectoEstructuracion";
import { PublicFluvialConsultaFicha5gService } from "./public-fluvial-consulta-ficha5g.service";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";

@Component({
  selector: "app-public-fluvial-consulta-ficha5g",
  templateUrl: "./public-fluvial-consulta-ficha5g.component.html",
  styleUrls: ["./public-fluvial-consulta-ficha5g.component.sass"],
})
export class PublicFluvialConsultaFicha5gComponent implements OnInit {
  displayedColumnsT1: any[];
  DatosTabla: any = [];
  DatosTablaTotal: any = [];
  items: MenuItem[] = [];
  itemsPasos: MenuItem[] = [];
  activarPasos: number = 1;
  estadosProyectos: any = [];
  proyectoSeleccionado: ProyectoEstructuracion = new ProyectoEstructuracion(
    0,
    "",
    0,
    "",
    "",
    "",
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    "",
    0,
    0,
    0,
    0,
    0,
    "",
    "",
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    0,
    "",
    0,
    new Date(),
    new Date(),
    new Date()
  );
  blockedDocument = false;

  constructor(
    private publicFluvialConsultaFicha5gService: PublicFluvialConsultaFicha5gService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
    publicFluvialConsultaFicha5gService.getDatosProyectos5GBD().subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            //cargar los estados del proyecto

            dato.proyectos.forEach((e) => {
              if (!this.estadosProyectos.includes(e.estado)) {
                if (e.estado != "No Aplica") {
                  this.estadosProyectos.push(e.estado);
                  this.itemsPasos.push({ label: e.estado });
                }
              }
            });
            this.itemsPasos.sort(function (a, b) {
              if (a.label > b.label) {
                return -1;
              }
              if (a.label < b.label) {
                return 1;
              }
              return 0;
            });
            this.CargarDatos(
              dato.proyectos.filter((p) => p.tipoModo == 6 && ( p.idOla == 1 || p.idOla == 2 ))
            );
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
        this.blockedDocument = false;
        console.error(error);
        principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  //formato moneda en billones
  formatoMoneda(valor: number) {
    return valor
      ? "$" +
          (valor / 1000000000000)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,") +
          " Bn"
      : "$0";
  }

  //formato separador miles
  formatoNumeros(valor: number) {
    return valor ? valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  }

  ngOnInit() {
    this.blockedDocument = true;
  }

  // Cargar los datos del menu lateral y los estados
  CargarDatos(proyectos: ProyectoEstructuracion[]) {
    this.activarPasos = 1;
    proyectos.forEach((p) => {
      this.items.push({
        label: p.nombre + " " + this.formatoMoneda(p.capex),
        icon: "pi pi-fw pi-plus",
        command: (event) => {
          this.actualizarDatos(p);
        },
      });
    });

    this.displayedColumnsT1 = [
      { field: "Caracteristica", header: "Característica" },
      { field: "Valor", header: "Valor" },
    ];

    this.actualizarDatos(proyectos[0]);
  }

  actualizarDatos(proyecto: ProyectoEstructuracion) {
    this.proyectoSeleccionado = proyecto;
    this.DatosTabla = [];
    this.DatosTablaTotal = [
      {
        Caracteristica: "Longitud Origen – Destino: ",
        Valor: proyecto.longitudOrigenDestino / 1000 + " km",
      },
      {
        Caracteristica: "Ancho Canal Navegable:",
        Valor: proyecto.anchoCanalNavegable,
      },
      { Caracteristica: "Profundidad:", Valor: proyecto.profundidad },
      {
        Caracteristica: "Duración Pre-Construcción:",
        Valor: proyecto.duracionPreConstruccion / 12 + " año(s)",
      },
      {
        Caracteristica: "Duración Construcción: ",
        Valor: proyecto.duracionConstruccion / 12 + " año(s)",
      },
      {
        Caracteristica: "Duración Concesión (años):",
        Valor: proyecto.duracionConcesion / 12 + " año(s)",
      },
      { Caracteristica: "Capex:", Valor: this.formatoMoneda(proyecto.capex) },
      { Caracteristica: "Opex:", Valor: this.formatoMoneda(proyecto.opex) },
    ];

    //Solo mostrar los que tienen informacion
    for (var j in this.DatosTablaTotal) {
      if (
        this.DatosTablaTotal[j].Valor != "" &&
        this.DatosTablaTotal[j].Valor != 0 &&
        this.DatosTablaTotal[j].Valor != "0 km" &&
        this.DatosTablaTotal[j].Valor != "0 año(s)"
      ) {
        this.DatosTabla.push(this.DatosTablaTotal[j]);
      }
    }
    //Activas estados del proyecto
    for (var i = 0; i < this.itemsPasos.length; i++) {
      if (this.itemsPasos[i].label == proyecto.estado) {
        this.activarPasos = i;
        i = this.itemsPasos.length;
      }
    }
  }
  onNavigate(link) {
    window.open(link, "_blank");
  }
}
