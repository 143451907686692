import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'visor-planes-agencia',
  templateUrl: './visor-planes-agencia.component.html'
})
export class VisorPlanesAgenciaComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
  element.classList.add("public");
  }

}
