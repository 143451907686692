import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';

import { AlcanceProyectoBaseComponent } from '../alcance-proyecto-base/alcance-proyecto-base.component';
import { forkJoin } from 'rxjs';
import { UnidadFuncionalCarreteroService } from '../unidad-funcional-carretero/unidad-funcional-carretero.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-unidad-funcional-carretero-detalle2',
  templateUrl: './unidad-funcional-carretero-detalle.component.html',
  styleUrls: ['./unidad-funcional-carretero-detalle.component.sass']
})
export class UnidadFuncionalCarreteroDetalleComponent2 implements OnInit {

  fechaInicioConstruccion: Date;
  fechaFinConstruccion: Date;
  iniciaEnOperacion: boolean = false;
  fechaReferenciaOpex: Date;
  fechaReferenciaCapex: Date;
  visibleDialogoExito: boolean;
  blockedDocument: boolean;

  constructor(public lineaBase: AlcanceProyectoBaseComponent, private parametricasService: ParametricasService,
    private unidadFuncionalService: UnidadFuncionalCarreteroService, private route: ActivatedRoute, private ruta: Router, private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.iniciaEnOperacion = this.lineaBase.unidadFuncional.iniciaEnOperacion;
    if (this.lineaBase.unidadFuncional.fechaInicio != null) {
      this.fechaInicioConstruccion = new Date(this.lineaBase.unidadFuncional.fechaInicio);
    }
    if (this.lineaBase.unidadFuncional.fechaFin != null) {
      this.fechaFinConstruccion = new Date(this.lineaBase.unidadFuncional.fechaFin);
    }
    if (this.lineaBase.unidadFuncional.fechaReferenciaCapex != null) {
      this.fechaReferenciaCapex = new Date(this.lineaBase.unidadFuncional.fechaReferenciaCapex);
    }
    if (this.lineaBase.unidadFuncional.fechaReferenciaOpex != null) {
      this.fechaReferenciaOpex = new Date(this.lineaBase.unidadFuncional.fechaReferenciaOpex);
    }
  }

  guardarUnidadFuncional(forma: NgForm) {

    this.blockedDocument = true;

    if (this.fechaInicioConstruccion > this.fechaFinConstruccion) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La fecha inicio de construcción debe ser menor que la fecha fin de construcción', life: 10000 });
    } else {
      this.lineaBase.unidadFuncional.idProyecto = this.lineaBase.idProyecto;
      this.lineaBase.unidadFuncional.idConfiguracion = this.lineaBase.idConfiguracion;
      this.lineaBase.unidadFuncional.iniciaEnOperacion = this.iniciaEnOperacion;
      this.lineaBase.unidadFuncional.fechaReferenciaOpex = this.fechaReferenciaOpex;
      if (!this.iniciaEnOperacion) {
        this.lineaBase.unidadFuncional.fechaInicio = this.fechaInicioConstruccion;
        this.lineaBase.unidadFuncional.fechaFin = this.fechaFinConstruccion;
        this.lineaBase.unidadFuncional.fechaReferenciaCapex = this.fechaReferenciaCapex;
      }

      forkJoin(
        this.unidadFuncionalService.GuardarUnidadFuncional(this.lineaBase.unidadFuncional, this.lineaBase.esBorrador),
        this.unidadFuncionalService.sincronizarUnidadFuncional(this.lineaBase.unidadFuncional.idUnidadFuncional)
      ).subscribe(([result, resultSincronizacion]) => {
        console.log("resultSincronizacion POR UNIDAD FUNCIONAL----->", resultSincronizacion);
       if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.lineaBase.unidadFuncional = result.unidadFuncional;
            this.visibleDialogoExito = true;
            this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'La unidad funcional se guardó exitosamente.', life: 10000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
        }
      },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        },
        () => {
          //Desbloquear pantalla
          this.blockedDocument = false;
        }
      );


    }
  }

  actionRegresar() {
    this.lineaBase.visibleUnidadFuncional = true;
    this.lineaBase.visibleUnidadFuncionalDettalle = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.lineaBase.visibleUnidadFuncionalDettalle = false;
    this.lineaBase.visibleUnidadFuncional = true;
    this.lineaBase.cargarUnidadesFuncionales(this.lineaBase.idProyecto, this.lineaBase.idConfiguracion, this.lineaBase.esBorrador);
  }

}
