import { Component, OnInit, OnDestroy, } from '@angular/core';
import { PqrsRadicacionService, } from './pqrs-radicacion.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneral } from './informacionGeneral';
import { NgForm } from '@angular/forms';
import { EnumRegex, EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { Archivo } from './archivo';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 's-pqrs-radicacion',
    templateUrl: './pqrs-radicacion.component.html',
    styleUrls: ['./pqrs-radicacion.component.css']
})
export class PqrsRadicacionComponent implements OnInit {

    respuestaRecaptcha: any = null;
    codigo: any = null;
    isAnonimo: boolean = false;
    isAutorizo: boolean = false;
    isAnexos: boolean = false;
    isColombia: boolean = false;
    loading: boolean = false;
    visibleDialogoError: boolean = false;
    visibleDialogoExito: boolean = false;
    visibleDialogoErrorServicio: boolean = false;
    isError: boolean = false;
    habilitarCargueAnexo: boolean = false;
    visibleDialogoVerAdjuntosAnexos: boolean = false;
    visibleDialogoEliminarAdjunto: boolean = false;
    isNaturalJuridico: boolean = true;
    obligatorioNumero: boolean = true;
    ejeNombre:any;
    ejeApellido:any;

    listaTipo: any = [];
    listaSolicitante: any = [];
    listaDocumentos: any = [];
    listaPais: any = [];
    listaDepartamentos: any = [];
    listaCiudad: any = [];
    listaMedioRespuesta: any = [];
    listaSoporte: any = [];


    informacionGeneral: InformacionGeneral = {};
    tipoSoliciudSeleccionada: any;
    tipoSolicitanteSeleccionada: any;
    tipoDocumentoSeleccionada: any;
    paisSeleccionada: any;
    departamentoSeleccionada: any;
    ciudadSeleccionada: any;
    medioRespuestaSeleccionada: any;
    soporteSeleccionada: any ;
    radicado: any;
    fechaRadicado: any;
    horaRadicado: any;
    errorEncontrado: any;
    datosServicio: any;
    labelDocumento : any;

    static TIPO_SOPORTE_ANEXO: string = "20";
    nombreTipoAnexo: string;
    mensajeError: string;
    rutaAnexosAdicionales: string;
    archivos: any = [];
    tmpEliminarArchivo: any = [];
    expresionRegularTipoDoc: string;


    public _files: Archivo[] = [];
    public files: NgxFileDropEntry[] = [];

    constructor(private _service: PqrsRadicacionService,
        private route: Router,
        public location: Location,
        private http: HttpClient,
        private principalComponent: PrincipalComponent) { }

    ngOnInit() {
        this.mensajeError = "";
        this.nombreTipoAnexo = "anexo";
        this.rutaAnexosAdicionales = "pqrsd_";
        this.labelDocumento = "Número Documento Identificación * ";
        this.loading = true;
        this.isAutorizo = false;
        this.tipoSoliciudSeleccionada = { id: 0, nombre: 'Seleccione...'};
        this.tipoSolicitanteSeleccionada = { id: 0, nombre: 'Seleccione...'};
        this.tipoDocumentoSeleccionada = { id: 0, nombre: 'Seleccione...'};
        this.paisSeleccionada = { id: 0, nombre: 'Seleccione...'};
        this.departamentoSeleccionada = { id: 0, nombre: 'Seleccione...'};
        this.ciudadSeleccionada = { id: 0, nombre: 'Seleccione...'};
        this.medioRespuestaSeleccionada = { id: 0, nombre: 'Seleccione...'};
        this.soporteSeleccionada = { id: 0, nombre: 'Seleccione...'};
        this.listaSoporte.push({ id: 1, nombre: 'Anexo'});

        this.getListaTipoSolicitud();
        this.loading = true;
        this.getListaTipoSolicitante();
        this.loading = true;
        this.getListaTipoDocumento();
        this.loading = true;
        this.getListaPaises();
        this.loading = true;
        this.getListaMedioRespuesta();
        this.loading = false;
    }

    /**
     * Metodo que captura la seleccion de captcha
     *
     * @param {string} captchaResponse
     * @memberof PqrsRadicacionComponent
     */
    public resolved(captchaResponse: string) {
        this.respuestaRecaptcha = captchaResponse;
    }

    /**
     * Metodo que entra el enviar
     *
     * @param {NgForm} formRadicacionPqrs
     * @memberof PqrsRadicacionComponent
     */
    enviarRadicacion(formRadicacionPqrs: NgForm){
        this.loading = true;
        this.mensajeError = "";
        if(this.isAnonimo == false){
            this.informacionGeneral.tipoSolicitud = this.tipoSoliciudSeleccionada.nombre;
            this.informacionGeneral.idTipoSolicitud = this.tipoSoliciudSeleccionada.id;
            this.informacionGeneral.tipoSolicitante = this.tipoSolicitanteSeleccionada.nombre;
            this.informacionGeneral.idTipoSolicitante = this.tipoSolicitanteSeleccionada.id;
            this.informacionGeneral.tipoDocumento = this.tipoDocumentoSeleccionada.nombre;
            this.informacionGeneral.idTipoDocumento = this.tipoDocumentoSeleccionada.id;
            this.informacionGeneral.pais = this.paisSeleccionada.nombre;
            this.informacionGeneral.idPais = this.paisSeleccionada.id;
            this.informacionGeneral.medioRespuesta = this.medioRespuestaSeleccionada.nombre;
            this.informacionGeneral.idMedioRespuesta = this.medioRespuestaSeleccionada.id;
            this.informacionGeneral.isAnonimo = false;
            this.informacionGeneral.anexos = this.archivos;

            if(this.paisSeleccionada.nombre == "Colombia"){
                this.informacionGeneral.isColombia = true;
                this.informacionGeneral.idDepartamento = this.departamentoSeleccionada.id;
                this.informacionGeneral.departamento = this.departamentoSeleccionada.nombre;
                this.informacionGeneral.idCiudad = this.ciudadSeleccionada.id;
                this.informacionGeneral.ciudad = this.ciudadSeleccionada.nombre;
            }else{
                this.informacionGeneral.isColombia = false;
            }
        }else{
            this.informacionGeneral.isAnonimo = true;
        }

        this._service.postRadicar(this.informacionGeneral).subscribe((data: {}) => {
            this.datosServicio = data;
            if(this.datosServicio!= null && this.datosServicio.codigo == 400){
                this.visibleDialogoErrorServicio = true;
                this.mensajeError = this.datosServicio.estado;
            }else{
                this.mensajeError = "";
                this.visibleDialogoExito = true;
                formRadicacionPqrs.resetForm();
                this.isAnonimo = this.informacionGeneral.isAnonimo;
                this.radicado = this.datosServicio.radicado;
                this.fechaRadicado = this.datosServicio.fechaRadicado;
                this.horaRadicado =  this.datosServicio.horaRadicado;
                this.tipoSoliciudSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.tipoSolicitanteSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.tipoDocumentoSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.paisSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.departamentoSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.ciudadSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.medioRespuestaSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.soporteSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.habilitarCargueAnexo = false;
                this.archivos = [];
                this.isNaturalJuridico = true;
            }
            this.loading = false;
        },error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     * Metodo que transforma en base 64
     *
     * @param {*} data
     * @param {string} type
     * @returns
     * @memberof PqrsRadicacionComponent
     */
    downLoadFile(data: any, type: string) {
        var base64str = data.archivo;
        let blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);
        return base64str;
    }

    /**
     * Metodo que cancela el modal anexo
     *
     * @memberof PqrsRadicacionComponent
     */
    cancelarAnexo(){
        this.isAnexos = false;
    }

    /**
     * Metodo que activa el modal
     *
     * @memberof PqrsRadicacionComponent
     */
    anexos(){
        this.isAnexos = true;
        localStorage.setItem("archivoPqrsd", JSON.stringify(this.archivos));
    }

    /**
     * Metodo que valida el formulario
     *
     * @memberof PqrsRadicacionComponent
     */
    validarFormulario(){
        if(this.isAnonimo == true){
            return false;
        }else{
            this.isError = false;
            this.errorEncontrado = "";
            if(this.tipoSoliciudSeleccionada != undefined &&
                this.tipoSoliciudSeleccionada != null &&
                this.tipoSoliciudSeleccionada.nombre != undefined &&
                this.tipoSoliciudSeleccionada.nombre != null &&
                this.tipoSoliciudSeleccionada.nombre.trim() != "" &&
                this.tipoSoliciudSeleccionada.nombre.trim() != "Seleccione..."){}else{
                    this.isError = true;
                    this.errorEncontrado = this.errorEncontrado + "Tipo, ";
            }
            if(this.tipoDocumentoSeleccionada.id == 4){
                if(this.informacionGeneral.razonSocial != undefined &&
                    this.informacionGeneral.razonSocial != null &&
                    this.informacionGeneral.razonSocial.trim() != ""){}else{
                        this.isError = true;
                        this.errorEncontrado = this.errorEncontrado + "Razon Social, ";
                    }
            }else{
                if(this.informacionGeneral.primerNombre != undefined &&
                    this.informacionGeneral.primerNombre != null &&
                    this.informacionGeneral.primerNombre.trim() != ""){}else{
                        this.isError = true;
                        this.errorEncontrado = this.errorEncontrado + "Primer Nombre, ";
                    }
                if(this.informacionGeneral.primerApellido != undefined &&
                    this.informacionGeneral.primerApellido != null &&
                    this.informacionGeneral.primerApellido.trim() != ""){}else{
                        this.isError = true;
                        this.errorEncontrado = this.errorEncontrado + "Primer Apellido, ";
                    }
            }

            if(this.informacionGeneral.direccion != undefined &&
                this.informacionGeneral.direccion != null &&
                this.informacionGeneral.direccion.trim() != ""){
                    if(this.informacionGeneral.direccion.length < 2 ){
                        this.isError = true;
                        this.errorEncontrado = this.errorEncontrado + "Dirección debe ser mayor de 1 carácteres, ";
                    }
                }else{
                    this.isError = true;
                    this.errorEncontrado = this.errorEncontrado + "Dirección, ";
            }

            if(this.tipoDocumentoSeleccionada != undefined &&
                this.tipoDocumentoSeleccionada != null &&
                this.tipoDocumentoSeleccionada.nombre != undefined &&
                this.tipoDocumentoSeleccionada.nombre != null &&
                this.tipoDocumentoSeleccionada.nombre.trim() != ""  &&
                this.tipoDocumentoSeleccionada.nombre.trim() != "Seleccione..."){
                if(this.tipoDocumentoSeleccionada.id == 7 ||
                    this.tipoDocumentoSeleccionada.id == 8 ||
                    this.tipoDocumentoSeleccionada.id == 12 ){
                        this.obligatorioNumero = false;
                }else{
                    this.obligatorioNumero = true;
                }
            }else{
                this.isError = true;
                this.errorEncontrado = this.errorEncontrado + "Tipo Documento Identificacion, ";
            }

            if(this.obligatorioNumero){
                if(this.informacionGeneral.numeroIdentificacion != undefined &&
                    this.informacionGeneral.numeroIdentificacion != null &&
                    this.informacionGeneral.numeroIdentificacion.trim() != ""){}else{
                    this.isError = true;
                    if(this.tipoDocumentoSeleccionada.id == 4){
                        this.errorEncontrado = this.errorEncontrado + "Nit, "
                    }else{
                        this.errorEncontrado = this.errorEncontrado + "Numero Documento Identificacion, ";
                    }
                }
            }
            if(this.paisSeleccionada != undefined &&
                this.paisSeleccionada != null &&
                this.paisSeleccionada.nombre != undefined &&
                this.paisSeleccionada.nombre != null &&
                this.paisSeleccionada.nombre.trim() != ""  &&
                this.paisSeleccionada.nombre.trim() != "Seleccione..."){
                if(this.paisSeleccionada.nombre == "Colombia"){
                    if(this.departamentoSeleccionada != undefined &&
                        this.departamentoSeleccionada != null &&
                        this.departamentoSeleccionada.nombre != undefined &&
                        this.departamentoSeleccionada.nombre != null &&
                        this.departamentoSeleccionada.nombre.trim() != ""  &&
                        this.departamentoSeleccionada.nombre.trim() != "Seleccione..."){}else{
                            this.isError = true;
                            this.errorEncontrado = this.errorEncontrado + "Departamento, ";
                        }
                    if(this.ciudadSeleccionada != undefined &&
                        this.ciudadSeleccionada != null &&
                        this.ciudadSeleccionada.nombre != undefined &&
                        this.ciudadSeleccionada.nombre != null &&
                        this.ciudadSeleccionada.nombre.trim() != ""  &&
                        this.ciudadSeleccionada.nombre.trim() != "Seleccione..."){}else{
                            this.isError = true;
                            this.errorEncontrado = this.errorEncontrado + "Ciudad, ";
                        }
                }
            }else{
                this.isError = true;
                this.errorEncontrado = this.errorEncontrado + "País, "
            }
            if(this.informacionGeneral.correoElectronico != undefined &&
                this.informacionGeneral.correoElectronico != null &&
                this.informacionGeneral.correoElectronico.trim() != ""){}else{
                    this.isError = true;
                    this.errorEncontrado = this.errorEncontrado + "Correo Electronico, ";
            }
            if(this.medioRespuestaSeleccionada != undefined &&
                this.medioRespuestaSeleccionada != null &&
                this.medioRespuestaSeleccionada.nombre != undefined &&
                this.medioRespuestaSeleccionada.nombre != null &&
                this.medioRespuestaSeleccionada.nombre.trim() != ""  &&
                this.medioRespuestaSeleccionada.nombre.trim() != "Seleccione..."){}else{
                    this.isError = true;
                    this.errorEncontrado = this.errorEncontrado + "Medio Respuesta, ";
            }
             if(this.informacionGeneral.asunto != undefined &&
                this.informacionGeneral.asunto != null &&
                this.informacionGeneral.asunto.trim() != ""){}else{
                    this.isError = true;
                    this.errorEncontrado = this.errorEncontrado + "Asunto, ";
            }
            if(this.isAutorizo == false){
                this.isError = true;
                this.errorEncontrado = this.errorEncontrado + "Autoizo, ";
            }

            return this.isError;
        }
    }

    /**
     * Metodo que carga la lista de tipo solicitudd
     */
    getListaTipoSolicitud(){
        this.loading = true;
        this._service.getListaTipoSolicitud().subscribe((data: {}) => {
           this.listaTipo = data;
           this.loading = false;
        },error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     * Metodo que carga la lista de tipo solicitante
     */
    getListaTipoSolicitante(){
        this._service.getListaTipoSolicitante().subscribe((data: {}) => {
           this.listaSolicitante = data;
        },error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     * Metodo que carga la lista de tipo documento
     */
    getListaTipoDocumento(){
        this._service.getListaTipoDocumento().subscribe((data: {}) => {
           this.listaDocumentos = data;
         });
    }

    /**
     * Metodo que carga la lista de paises
     */
    getListaPaises(){
        this.loading = true;
        this._service.getListaPaises().subscribe((data: {}) => {
           this.listaPais = data;
           this.loading = false;
        },error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     * Metodo que carga la lista de departamentos
     */
    getListaDepartamentos(){
        this.loading = true;
        this._service.getListaDepartamentos().subscribe((data: {}) => {
           this.listaDepartamentos = data;
           this.loading = false;
        },error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     * Metodo que carga la lista de municipios
     */
    getListaMunicipios(){
        this.loading = true;
        this._service.getListaCiudadxDepartamentos( this.departamentoSeleccionada.id ).subscribe((data: {}) => {
           this.listaCiudad = data;
           this.loading = false;
        },error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     * Metodo que carga la lista de medios de respuestas
     */
    getListaMedioRespuesta(){
        this.loading = true;
        this._service.getListaMedioRespuesta().subscribe((data: {}) => {
           this.listaMedioRespuesta = data;
           this.loading = false;
        },error => {
            this.loading = false;
            this.principalComponent.cargarErrorServicio(error);
        });
    }

    /**
     * Metodo que valida el tipo de documento
     *
     * @memberof PqrsRadicacionComponent
     */
    onChangeTipoDoc(evento){
        this.expresionRegularTipoDoc = EnumRegex.REGEX_PERSONA_NATURAL_NUMERO_DOCUMENTO;
        if(this.tipoDocumentoSeleccionada.id == 4){
            this.isNaturalJuridico = false;
        }else{
            if(this.tipoDocumentoSeleccionada.id == 7 ||
                this.tipoDocumentoSeleccionada.id == 8 ||
                this.tipoDocumentoSeleccionada.id == 12 ){
                    this.obligatorioNumero = false;
            }else{
                this.obligatorioNumero = true;
            }
            this.isNaturalJuridico = true;
        }

        if(this.tipoDocumentoSeleccionada.id == 7 ||
            this.tipoDocumentoSeleccionada.id == 8 ){
                this.labelDocumento = "Número Documento Identificación";
        }else{
            this.labelDocumento = "Número Documento Identificación * ";
        }

        if(this.tipoDocumentoSeleccionada.id == 3){
            this.expresionRegularTipoDoc = EnumRegex.REGEX_ALFANUMERICOS;
        }

    }

    /**
     * Metodo que carga los paises
     */
    onChangePais(evento){
        this.loading = true;
        if(evento!= null && evento != undefined &&
            evento.value != undefined && evento.value != null &&
            evento.value.nombre != undefined && evento.value.nombre != null){
            if(evento.value.nombre == "Colombia"){
                this.isColombia = true;
                this.departamentoSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.ciudadSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.getListaDepartamentos();
            }else{
                this.isColombia = false;
                this.listaDepartamentos = [];
                this.listaCiudad = [];
                this.departamentoSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.ciudadSeleccionada = { id: 0, nombre: 'Seleccione...'};
                this.loading = false;
            }
        }
    }

    /**
     * Metodo que carga los departamentos
     */
    onChangeDepartamentos(evento){
        this.getListaMunicipios();
    }

    /**
     * Metodo que habilita el cargar archivos
     *
     * @param {*} evento
     * @memberof PqrsRadicacionComponent
     */
    onChageSoporte(evento){
        this.habilitarCargueAnexo = true;
            //Carga de archivos
    }

    /**
     * Metodo que cierra el dialogo de Exito
     */
    aceptarDialogoExito(){
        this.visibleDialogoExito = false;
        this.isAnonimo = false;
        this.habilitarCargueAnexo = false;
    }

    /**
     * Metodo que cierra el dialogo de Error
     */
    aceptarDialogoError(){
        this.visibleDialogoError = false;
    }

    /**
     * Metodo que cierra el dialogo de Error Servicio
     */
    aceptarDialogoErrorServicio(){
        this.visibleDialogoErrorServicio = false;
    }


    get EnumRegex() {
        return EnumRegex;
    }

    get EnumTipoDocumento() {
        return EnumTipoDocumento;
    }

    /**
     * Para cargar los archivoos
     *
     * @memberof PqrsRadicacionComponent
     */
    aceptarAdjuntoAnexo() {
        this.isAnexos = false;
    }

    /**
     * Metodo que habilita el modal de anexos
     *
     * @memberof PqrsRadicacionComponent
     */
    verAnexos(){
        this.visibleDialogoVerAdjuntosAnexos = true;
    }

    /**
     * Metodo que oculta el modal de ver anexos
     *
     * @memberof PqrsRadicacionComponent
     */
    cancelarDialogoVerAdjunto(){
        this.visibleDialogoVerAdjuntosAnexos = false;
    }

    /**
     * Metodo que quita un adjunto de la lista a cargar
     *
     * @param {*} _adjunto
     * @memberof PqrsRadicacionComponent
     */
    verDialogoEliminarAdjunto(_adjunto) {
        this.tmpEliminarArchivo = _adjunto;
        this.visibleDialogoEliminarAdjunto = true;
        this.visibleDialogoVerAdjuntosAnexos = false;
    }

    /**
     * Metodo que cancela la validacion de eliminacion de un adjunto
     *
     * @memberof PqrsRadicacionComponent
     */
    cancelarEliminarAdjunto(){
        this.tmpEliminarArchivo = null;
        this.visibleDialogoEliminarAdjunto = false;
        if(this.archivos.length == 0){
            this.visibleDialogoVerAdjuntosAnexos = false;
        }else{
            this.visibleDialogoVerAdjuntosAnexos = true;
        }
    }

    /**
     * Metodo que realiza la descarga o visualizacion del archivo
     */
    verDialogoAdjunto(data){
        var base64str = data.archivo;

        let pdfWindow = window.open("");
        pdfWindow.document.write( "<iframe width='100%' height='100%' src='" + encodeURI(base64str) + "'></iframe>" );
    }

    /**
     * Metodo que elimina un registro de adjunto
     *
     * @memberof PqrsRadicacionComponent
     */
    aceptarEliminarAdjunto(){
        let i = 0;
        if(this.archivos.length > 0 && this.tmpEliminarArchivo != null){
            this.archivos.forEach(element => {
                if(element.nombre == this.tmpEliminarArchivo.nombre && this.tmpEliminarArchivo.nombreArchivo == element.nombreArchivo){
                    this.archivos.splice(i, 1);
                    this.visibleDialogoEliminarAdjunto = false;
                    if(this.archivos.length == 0){
                        this.visibleDialogoEliminarAdjunto = false;
                        this.visibleDialogoVerAdjuntosAnexos = false;
                    }
                }
            });
        }
        if(this.archivos.length == 0){
            this.visibleDialogoVerAdjuntosAnexos = false;
        }else{
            this.visibleDialogoVerAdjuntosAnexos = true;
        }


    }


  calcularTipoArchivo(nombreArchivo): string {
    switch (nombreArchivo.substr(nombreArchivo.lastIndexOf('.') + 1, nombreArchivo.length + 1 - nombreArchivo.lastIndexOf('.'))) {
      case "pdf":
        return 'application/pdf';
      case 'jpg':
        return 'image/jpg';
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'zip':
        return 'application/zip';
      case 'txt':
        return 'text/plain';
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'csv':
        return 'application/vnd.ms-excel';
      case "PDF":
        return 'application/pdf';
      case 'JPG':
        return 'image/jpg';
      case 'JPEG':
        return 'image/jpeg';
      case 'PNG':
        return 'image/png';
      case 'ZIP':
        return 'application/zip';
      case 'TXT':
        return 'text/plain';
      case 'DOC':
        return 'application/msword';
      case 'DOCX':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'CSV':
        return 'application/vnd.ms-excel';
      case 'XLS': case 'xls':
        return 'application/vnd.ms-excel';
      case 'XLSX': case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        return 'application/pdf';
    }
  }


}
