import { Component, OnInit } from '@angular/core';
import { CmsComisionDTO, CmsComisionLegalizacionDTO, VwCmsAnexoComisionDTO, CmsAprobacionDTO, VwCmsTiqueteAereoDTO, CmsTiqueteAereoDTO, CmsAnexoComisionDTO, CmsArchivoAnexoComisionDTO, CmsReasignarComisionDTO, VwCmsComision, CmsRpDTO, CmsModosDTO } from '../../comisiones.dto';
import { TrnPersona } from '../../../admin-personas/trn-persona';
import { PrsFuncionario } from '../../../admin-personas/prs-funcionario';
import { EnumRegex, EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ComisionesService } from 'src/app/comisiones/comisiones.service';
import { forkJoin } from 'rxjs';
import { EnumCmsConstantes, EnumCmsMaTipoModificacionComision, EnumCmsTipoAnexo } from '../../comisiones-constantes';
import { throwMatDuplicatedDrawerError } from '@angular/material';

@Component({
  selector: 'app-comisiones-registro-presupuestal-obligacion-registro',
  templateUrl: './comisiones-registro-presupuestal-obligacion-registro.component.html',
  styleUrls: ['./comisiones-registro-presupuestal-obligacion-registro.component.sass']
})
export class ComisionesRegistroPresupuestalObligacionRegistroComponent implements OnInit {

  blockedDocument: boolean;
  fechaActual: Date;
  persona: TrnPersona;
  detalleFuncionario: PrsFuncionario;


  //Informacion comision
  idComision: number;
  idComisionVersion: number;
  idAsignacion: number;

  comision: CmsComisionDTO;

  //Pestana Liquidacion
  nombreTabLiquidacion: string;
  habilitarLiquidacionLegalizacion = false;

  legalizacion: CmsComisionLegalizacionDTO;
  anexosConsolidado: VwCmsAnexoComisionDTO[];//Anexos de Legalizacion  

  //Pestana tiquetes
  itinerarioAereoList: VwCmsTiqueteAereoDTO[];
  observacionesList: VwCmsTiqueteAereoDTO[];
  dialogoObservacionesTiquete: boolean;
  tiqueteSeleccionado: CmsTiqueteAereoDTO;

  rpAsociadosList: CmsRpDTO[];
  totalValorRpComision = 0;

  anexoGestionPresupuestal: CmsAnexoComisionDTO;
  visibleAnexosGestionPresupuestal = false;

  formObligacionPago;
  cantidadMaximaAdjuntos = 3;

  visibleSubirAnexoDialog = false;
  visibleAdjuntosDialog = false;
  visibleConfirmarEnviarDialogo = false;
  visualizarDialogObservacionRechazo = false;

  anexosConsolidadoObligacionPago: VwCmsAnexoComisionDTO[];
  anexoComisionObligacionPago: CmsAnexoComisionDTO;
  adjuntosComponente: any = [];

  rutaCloudStorageComisionesGestionPresupuestal: string;

  reasignarComision: CmsReasignarComisionDTO;

  constructor(formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService) {

    this.fechaActual = new Date();

    // Crear formulario Obligacion
    this.formObligacionPago = formBuilder.group({
      numeroObligacion: [null, [Validators.required, Validators.min(1), Validators.maxLength(20), Validators.pattern(EnumRegex.REGEX_MAYOR_A_CERO)]],
    });
  }

  ngOnInit() {
    this.blockedDocument = true;
    this.comision = {};
    this.comision.viaticos = {};
    this.legalizacion = {};
    this.persona = {};
    this.detalleFuncionario = {};

    // Obtener parametros url
    const idComisionParam = "idComision";
    const idComisionVersionParam = "idComisionVersion";
    const idAsignacionParam = "idAsignacion";

    this.ruta.params.subscribe(params => {
      this.idComision = Number(params[idComisionParam]);
      this.idComisionVersion = Number(params[idComisionVersionParam]);
      this.idAsignacion = Number(params[idAsignacionParam]);
    });


    this.reasignarComision = {};
    this.reasignarComision.idComision = this.idComision;
    this.reasignarComision.idComisionVersion = this.idComisionVersion;
    this.reasignarComision.idAsignacion = this.idAsignacion;


    this.inicializarVariables();

    this.obtenerComisionPorId(this.idComision);
    this.tiqueteSeleccionado = {};


    this.cargarRpAsociados(this.idComisionVersion);
  }

  inicializarVariables() {
    this.adjuntosComponente = [];
    this.cantidadMaximaAdjuntos = 3;
    this.anexosConsolidadoObligacionPago = [];
    this.blockedDocument = true;
    this.habilitarLiquidacionLegalizacion = true;

    this.anexoComisionObligacionPago = {};
    this.anexoComisionObligacionPago.adjuntos = [];
    this.anexoComisionObligacionPago.idTipoAnexo = EnumCmsTipoAnexo.REGISTRO_PRESUPUESTAL_OBLIGACION_PAGO_ANI;
    this.anexoComisionObligacionPago.idComisionVersion = this.idComisionVersion;

    this.cargarAnexoPorTipoAnexo(this.idComisionVersion, EnumCmsTipoAnexo.REGISTRO_PRESUPUESTAL_OBLIGACION_PAGO_ANI);
  }

  obtenerComisionPorId(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarComisionPorId(idComision)
    ).subscribe(
      ([comisionData]) => {

        this.comision = comisionData.comisionDetalle;

        if (this.comision !== null) {
          if (this.comision.fechaSalida != null) {
            this.comision.fechaSalida = new Date(this.comision.fechaSalida);
          }
          if (this.comision.fechaRegreso != null) {
            this.comision.fechaRegreso = new Date(this.comision.fechaRegreso);
          }
          this.comision.objeto = this.comision.objeto.replace('<p>', '').replace('</p>', '');

          if (this.comision.valorTentativoTerrestre === 0) {
            this.comision.valorTentativoTerrestre = null;
          }
          if (this.comision.valorTentativoFluvial === 0) {
            this.comision.valorTentativoFluvial = null;
          }
          if (this.comision.valorTentativoAereo === 0) {
            this.comision.valorTentativoAereo = null;
          }

          if (this.comision.itinerarioAereoLista !== null && this.comision.itinerarioAereoLista.length > 0) {
            this.comision.itinerarioAereoLista.forEach(itAer => {
              if (itAer.fechaVuelo != null) {
                itAer.fechaVuelo = new Date(itAer.fechaVuelo);
              }
            });
          }

          if (this.comision.esContratista) {
            this.persona = {};
            this.detalleFuncionario = {};
          } else {
            this.persona = {};
            this.detalleFuncionario = {};
            this.cargarPersona(this.comision.idPersona);
          }


          //Actualizamos la variable de sumatoria de modos para visualizarlo en el Datatable
          this.actualizarSumatoriaModos();

          this.obtenerLegalizacionPorComision(idComision);
          this.cargarItinerarios();
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarPersona(idPersona) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarFuncionarioComisionPorIdPersona(idPersona)
    ).subscribe(
      ([
        funcionarioData
      ]) => {

        this.persona.id = funcionarioData.configuracionFuncionario.id;
        this.persona.nombre = funcionarioData.configuracionFuncionario.nombre;
        this.persona.numeroDocumento = funcionarioData.configuracionFuncionario.numeroDocumento;
        this.persona.correoElectronicoAni = funcionarioData.configuracionFuncionario.correoElectronico;
        this.detalleFuncionario.honorarios = funcionarioData.configuracionFuncionario.ingresos;
        this.detalleFuncionario.dependenciaPrimerNivel = funcionarioData.configuracionFuncionario.dependenciaPrimerNivel;
        this.detalleFuncionario.idDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.idDependenciaPrimerNivel;
        this.detalleFuncionario.liderDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.liderDependenciaPrimerNivel;
        this.detalleFuncionario.dependenciaEspecifica = funcionarioData.configuracionFuncionario.dependencia;
        this.detalleFuncionario.idDependenciaEspecifica = funcionarioData.configuracionFuncionario.idDependencia;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  /**
    * Metodo para actualizar la variable global que muestra el sumatoria de modos
    */
  actualizarSumatoriaModos() {
    if (Array.isArray(this.comision.modoLista) && this.comision.modoLista.length) {
      return this.comision.modoLista.reduce<number>((sum: number, modoVar: CmsModosDTO) => {
        return Number(sum + Number(modoVar.porcentajeRecursos));
      }, Number(0));
    }
  }

  obtenerLegalizacionPorComision(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarLegalizacionPorComision(idComision)
    ).subscribe(
      ([legalizacionData]) => {
        this.legalizacion = legalizacionData.legalizacion;
        this.listarAnexosPorLegalizar(this.legalizacion.idComisionVersion);
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  listarAnexosPorLegalizar(idComisionVersion) {
    forkJoin(
      this.comisionesService.srvConsultarAnexoAdjuntosConsolidado(idComisionVersion, EnumCmsTipoAnexo.SUBTIPO_GESTION_LEGALIZACION_ANEXOS + ',' + EnumCmsTipoAnexo.SUBTIPO_GESTION_TIQUETES, false)
    ).subscribe(
      ([comisionesPorLegalizarData]) => {
        this.anexosConsolidado = comisionesPorLegalizarData.adjuntosAnexosConsolidado;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }


  cargarItinerarios() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarTiquetesSolicitadosPorIdComision(this.idComision, this.idComisionVersion)
    ).subscribe(
      ([
        tiquetesData
      ]) => {
        this.itinerarioAereoList = tiquetesData.transporteAereoLista;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  irVerObservaciones(tiquete: VwCmsTiqueteAereoDTO) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarObservacionesPorIdTiquete(tiquete.idTiqueteAereo)
    ).subscribe(
      ([
        observacionesData
      ]) => {
        this.observacionesList = observacionesData.tiquetes;
        this.dialogoObservacionesTiquete = true;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  cargarAnexoGestionPresupuestal(idTipoAnexo: number) {
    this.visibleAnexosGestionPresupuestal = true;
    this.comisionesService.srvConsultarAnexoPorTipoAnexo(this.idComisionVersion, idTipoAnexo, false)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              if (result.anexoComision != null) {
                this.anexoGestionPresupuestal = result.anexoComision;
              }
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {

          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarAnexoPorTipoAnexo(idComisionVersion: number, idTipoAnexo: number) {
    this.comisionesService.srvConsultarAnexoPorTipoAnexo(idComisionVersion, idTipoAnexo)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              if (result.anexoComision != null) {
                this.anexoComisionObligacionPago = result.anexoComision;
              }
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {

          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  irAnexosConsolidadoComision() {
    this.anexosConsolidado = [];
    this.visibleAdjuntosDialog = true;
    this.cargarAnexoAdjuntosConsolidado(this.idComisionVersion, EnumCmsTipoAnexo.SUBTIPO_GESTION_LEGALIZACION + ',' + EnumCmsTipoAnexo.SUBTIPO_GESTION_LEGALIZACION_ANEXOS);
  }


  irSubirAdjuntos() {
    this.rutaCloudStorageComisionesGestionPresupuestal = this.comision.idComision + '/' + EnumCmsConstantes.CMS_CARPETA_GESTION_PRESUPUESTAL_BLOB_STORAGE + '/';

    if (this.anexoComisionObligacionPago.adjuntos == null || (this.anexoComisionObligacionPago.adjuntos != null && this.anexoComisionObligacionPago.adjuntos.length < this.cantidadMaximaAdjuntos)) {
      this.visibleSubirAnexoDialog = true;
      this.adjuntosComponente = [];
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Elimina algun adjunto para continuar. Recuerda que son máximo ' + this.cantidadMaximaAdjuntos + ' adjuntos', life: 10000 });
    }
  }

  cargarRpAsociados(idComisionVersion: number) {
    this.comisionesService.srvConsultarListadoRpComision(idComisionVersion).subscribe(
      result => {
        if (result != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.rpAsociadosList = result.rpComisionList;
            this.totalValorRpComision = 0;
            if (this.rpAsociadosList != null && this.rpAsociadosList.length > 0) {
              this.totalValorRpComision = this.rpAsociadosList.map(rp => rp.valorPagado).reduce((prev, next) => prev + next);
            }
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }


  irRechazarPasoComision() {
    this.visualizarDialogObservacionRechazo = true;
  }

  irEnviarPasoComision() {
    this.visibleConfirmarEnviarDialogo = true;
  }

  enviarSiguientePasoConmision() {
    //Valida que se haya cargado la información del registro preupuestal 
    this.blockedDocument = true;
    this.visibleConfirmarEnviarDialogo = false;

    this.reasignarComision.aprobo = true;

    //consultar que exista el Anexo en BD y luego pasar a la reasignación
    this.comisionesService.srvConsultarAnexoPorTipoAnexo(this.idComisionVersion,
      EnumCmsTipoAnexo.REGISTRO_PRESUPUESTAL_OBLIGACION_PAGO_ANI, false)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              if (result.anexoComision != null) {
                this.anexoComisionObligacionPago = result.anexoComision;
              }
              if (!this.validarInfoAnexo()) {
                this.blockedDocument = false;
                return;
              }
              this.comisionesService.srvReasignarComision(this.reasignarComision)
                .subscribe(
                  result => {
                    if (result != null && result.respuestaServicio != null) {
                      if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                        this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
                        this.bntVolver();
                      } else {
                        this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                      }
                    } else {
                      console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
                    }
                  },
                  error => {
                    this.principalComponent.cargarErrorServicio(error);
                  },
                  () => {
                    this.ngOnInit();
                  }
                );
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }


  rechazarSiguientePasoComision() {
    //Valida que se haya cargado la información del certificado de pago
    this.blockedDocument = true;
    this.visualizarDialogObservacionRechazo = false;
    this.reasignarComision.aprobo = false;

    forkJoin(
      this.comisionesService.srvReasignarComision(this.reasignarComision)
    ).subscribe(
      ([result]) => {

        if (result != null && result.respuestaServicio != null) {

          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {

            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            this.bntVolver();
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
        this.ngOnInit();
      }
    );
  }

  cancelarRechazarComision() {
    this.visualizarDialogObservacionRechazo = false;
    this.reasignarComision.aprobo = null;
    this.reasignarComision.observaciones = null;
  }

  validarInfoAnexo(): boolean {
    let validacionExitosa = true;
    if (this.anexoComisionObligacionPago == null) {
      validacionExitosa = false;
      this.lanzarMensajeError('No ha relacionado el registro presupuestal de obligación');
    } else {
      if (this.anexoComisionObligacionPago.numero == null || this.anexoComisionObligacionPago.numero.length == 0) {
        validacionExitosa = false;
        this.lanzarMensajeError('Debes ingresar el número del registro presupuestal de obligación del pago');
      }
      if (this.anexoComisionObligacionPago.adjuntos == null || this.anexoComisionObligacionPago.adjuntos.length == 0) {
        validacionExitosa = false;
        this.lanzarMensajeError('Debes adjuntar el documento que relaciona el registro presupuestal de obligación del pago para continuar');
      }
    }

    return validacionExitosa;
  }

  submitGuardarAnexo() {
    this.blockedDocument = true;
    if (this.validarInfoAnexo()) {
      this.anexoComisionObligacionPago.anulado = false;
      this.comisionesService.srvGuardarAnexoArchivoComision(this.anexoComisionObligacionPago).subscribe(
        result => {
          if (result != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
          this.ngOnInit();
        }
      );
    } else {
      this.blockedDocument = false;
    }
  }

  cancelarSubirAdjunto() {
    this.visibleSubirAnexoDialog = false;
    this.adjuntosComponente = [];
  }

  aceptarSubirAdjunto() {
    const adjuntosList: CmsArchivoAnexoComisionDTO[] = [];
    this.adjuntosComponente.forEach(arc => {
      const adj: CmsArchivoAnexoComisionDTO = {};
      adj.nombreArchivoUsuario = arc.nombre;
      adj.rutaSoporte = arc.nombreCompleto;
      adjuntosList.push(adj);
    });

    const tamanioFinalAdjuntos: number = adjuntosList.length + (this.anexoComisionObligacionPago.adjuntos == null ? 0 : this.anexoComisionObligacionPago.adjuntos.length);
    if (tamanioFinalAdjuntos > this.cantidadMaximaAdjuntos) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Solo se permiten ' + this.cantidadMaximaAdjuntos + ' archivos máximo.', life: 10000 });
    } else {
      adjuntosList.forEach(adj => {
        this.anexoComisionObligacionPago.adjuntos.push(adj);
      });
    }
    this.visibleSubirAnexoDialog = false;
  }

  cargarAnexoAdjuntosConsolidado(idComisionVersion: number, idsSubtipoAnexo: string) {
    this.comisionesService.srvConsultarAnexoAdjuntosConsolidado(idComisionVersion, idsSubtipoAnexo, false)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.anexosConsolidadoObligacionPago = result.adjuntosAnexosConsolidado;
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  aceptarEliminarAdjunto(adj: CmsArchivoAnexoComisionDTO) {

    if (adj != null) {
      this.anexoComisionObligacionPago.adjuntos = this.anexoComisionObligacionPago.adjuntos.filter(item => item.rutaSoporte != adj.rutaSoporte);
    }
  }

  limpiarFormularios() {
    this.formObligacionPago.reset();
  }


  bntVolver() {
    const ruta = "/comisiones/comisiones-registro-presupuestal-obligacion-comisiones";
    this.router.navigate([ruta]);
  }

  // // // // /Mensajes// // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsTipoAnexo() {
    return EnumCmsTipoAnexo;
  }

  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }


}
