import { Component, OnInit, } from '@angular/core';
import { YoutubeUploadFileService, } from './youtube-upload-file.service';
import { Video } from '../search.interface';
import {
    HttpClient,
    HttpEventType,
    HttpHeaders
  } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";


  

@Component({
    selector: 's-youtube-upload-file',
    templateUrl: './youtube-upload-file.component.html',
    styleUrls: ['./youtube-upload-file.component.css'],
    providers: [YoutubeUploadFileService]
})
export class YoutubeUploadFileComponent implements OnInit {

    videos: Video[] = [];
    progress: number;


    // Inicializaciones de Variables
    loading : boolean;
    inputTouched = false;

  
    private API_URL = 'https://www.googleapis.com/youtube/v3/search';
    private API_KEY = 'AIzaSyCnOKcEZJHZYGLQBHXKVLaP3otbm31BBbA'; //Clave de API 2
    private maxResults = '10';


    constructor(private _service: YoutubeUploadFileService, private http: HttpClient) { }

    ngOnInit() {
        this.loading = true;

        this.loading = false;
    }

    upload(file) {
        debugger

        let newHeader = {
            'Authorization': 'Bearer ',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        newHeader.Authorization = newHeader.Authorization + '4/0AX4XfWh5Zhry8cVW6ZDRfEq0_F-XhW438l4LtoDS7dTm9sXcH0TGet7Rrz3-tz5YG2nhAw';
        const httpOptions3 = {
            headers: new HttpHeaders(newHeader)
        };
        this.progress = 1;
        const formData = new FormData();
        const metadata = {
              snippet: {
                title: 'Test video',
                description: 'Testing the YouTube API---MK',
              },
              status: {
                privacyStatus: 'private'
              }
          };

        formData.append('metadata', JSON.stringify(metadata));
        formData.append("file", file);

        let urlToken  = 'https://accounts.google.com/o/oauth2/auth?client_id=711957771421-b5a7gp0d5lsqgnmkj765saat6gk9j19k.apps.googleusercontent.com&redirect_uri=http://localhost:4200/oauth2callback&scope=https://www.googleapis.com/auth/youtube.upload&response_type=code&access_type=offline';

        this.http.get(urlToken)
        .pipe(
          map((response: any) => {
              debugger;
            console.log(response.items);
          } ),
          catchError((err: any) => {
            alert(err.message);
            return throwError(err.message);
          })
        )
        .toPromise();




        this.http
          .post("https://www.googleapis.com/youtube/v3/videos?part=snippet,status&key=" + this.API_KEY, formData, httpOptions3)
          .pipe(
            map((event: any) => {
              if (event.type == HttpEventType.UploadProgress) {
                this.progress = Math.round((100 / event.total) * event.loaded);
              } else if (event.type == HttpEventType.Response) {
                this.progress = null;
              }
            }),
            catchError((err: any) => {
              this.progress = null;
              alert(err.message);
              return throwError(err.message);
            })
          )
          .toPromise();
      }


}
