import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { CtoAdjuntoInformeBorradorDTO } from "src/app/contratacion/contratacion-dto/adjunto-informe-borrador";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";
import { RpteCxpBaseComponent } from "../rpte-cxp-base/rpte-cxp-base.component";

@Component({
  selector: 'app-rpte-cxp-obl-pago',
  templateUrl: './rpte-cxp-obl-pago.component.html',
  styles: []
})
export class RpteCxpOblPagoComponent implements OnInit {

  visibleDialogoSubirCertificadoObligacionPago = false;
  visibleDialogoVerAdjuntos = false;

  numeroObligacionPago: string;
  adjuntosCertificadoObligacionPago: CtoAdjuntoInformeBorradorDTO[] = [];

  constructor(
    private lineaBase: RpteCxpBaseComponent,
    private messageService: MessageService,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarObligacionPago(this.lineaBase.idInformeParam);
  }

  cargarObligacionPago(idInforme: number) {
    this.reporteCuentaCobroCxpService.srvConsultarObligacionPago(idInforme)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.numeroObligacionPago = result.numeroObligacionPago;
            if (result.adjuntoObligacionPago != null) {
              this.adjuntosCertificadoObligacionPago = result.adjuntoObligacionPago;
            }
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }
}
