import { Component, OnInit } from "@angular/core";
import { loadModules } from "esri-loader";
import { ReportesCarreteroService } from "../reportes-carreteros.service";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { CrrPeajesDTO } from "../reportes-peajes.dto";
import * as _moment from 'moment';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

import { formatDate } from "@angular/common";
import {
  InformacionCarreterosDTO, CrrInformacionProyectoDTO,
  InformacionGeneralCarreterosDTO,
  CrrInformacionGeneralProyectoDTO,
  CrrInformacionDepartamentosDTO,
  CrrInformacionPeajesDTO,
} from "../reportes-carreteros.dto";

import { Router, ActivatedRoute } from "@angular/router";
export interface Demo {
  name: string;
  code: string;
}

export interface AdministradoPor {
  id: number;
  nombre: string;
}

@Component({
  selector: "app-rpt-crr-peajes",
  templateUrl: "./rpt-crr-peajes.component.html",
  styleUrls: ["./rpt-crr-peajes.component.sass"],
})
export class RptCrrPeajesComponent implements OnInit {
  private isAuthenticated: boolean;
  demos: Demo[];
  administradoPor: AdministradoPor[];
  selectedAdministradoPor: AdministradoPor;

  selectedDemo: Demo;
  blockedDocument = false;
  informacionGeneralPeajes: CrrPeajesDTO[] = [];
  informacionGeneralPeajesFiltro: CrrPeajesDTO[] = [];
  totalPeajesOperacion: number = 0;
  totalPeajesSuspension: number = 0;
  totalTarifaPreferencial: number = 0;
  totalPeajesElectronicos: number = 0;
  totalProyectos: number = 0;
  informacionPeajesGroupBy: any = [];
  informacionGeneralCompleta: CrrInformacionProyectoDTO[] = [];
  proyectoSeleccionado: CrrInformacionProyectoDTO;
  idPeaje: number = 0;
  fechaFiltro: any = null;






  constructor(
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private adalSvc: MsAdalAngular6Service, 
    private ruta: ActivatedRoute
  ) {
    this.demos = [
      { name: "Demo1", code: "NY" },
      { name: "Demo2", code: "RM" },
      { name: "Demo3", code: "LDN" },
      { name: "Demo4", code: "IST" },
      { name: "Demo5", code: "PRS" },
    ];

    this.administradoPor = [
      { id: 1, nombre: "ANI" },
      { id: 3, nombre: "Departamentos" },
     ];
  }

  ngOnInit() {
    
    this.isAuthenticated = this.adalSvc.isAuthenticated;
    
    this.ruta.params.subscribe((params) => {
      this.idPeaje = params.id;
    });



    this.blockedDocument = true;

    this.reportesCarreteroService.getInformacionProyectos().subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.informacionGeneralCompleta = dato.fichaCarretero;
            
          } else {  
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
       
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        
      }
    );


    this.reportesCarreteroService.getInformacionCrrPeajes().subscribe(
      (dato) => {

        var dateCompare = _moment().format('YYYY-MM-DD HH:mm');
        
      
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.informacionGeneralPeajes = dato.infoPeajes;
          
            this.informacionGeneralPeajes = this.informacionGeneralPeajes;
            this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes.filter(a => a.idAdministradoPor == 1 && dateCompare >= a.fechaInicioEstado && dateCompare <= a.fechaFinEstado);
            
            this.totalPeajesOperacion = this.informacionGeneralPeajesFiltro.filter(
              (a) => a.idEstado ==1 
            ).length;
            this.totalPeajesSuspension = this.informacionGeneralPeajesFiltro.filter(
              (a) => a.idEstado ==3 
            ).length;
            this.informacionPeajesGroupBy =
              this.informacionGeneralPeajesFiltro.reduce(function (result, current) {
                result[current.idProyecto] = result[current.idProyecto] || [];
                result[current.idProyecto].push(current);
                return result;
              }, {});
            
            this.totalProyectos = Object.keys(
              this.informacionPeajesGroupBy
            ).length;

            this.totalTarifaPreferencial = this.informacionGeneralPeajesFiltro.filter(
              (a) => a.tieneTarifaEspecial === 1
            ).length;
            this.totalPeajesElectronicos = this.informacionGeneralPeajesFiltro.filter(
              (a) => a.tienePagoElectronico === true
            ).length;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
        this.blockedDocument = false;
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );

    loadModules([
      "esri/config",
      "esri/Map",
      "esri/WebMap",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/widgets/Search",
      "esri/layers/VectorTileLayer",
      "esri/symbols/SimpleLineSymbol",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/renderers/SimpleRenderer",
      "esri/widgets/Legend"
    ])
      //.then(([esriConfig,Map,WebMap,Basemap, VectorTileLayer, TileLayer, MapView, FeatureLayer, Search]) => {
      .then(
        ([
          esriConfig,
          Map,
          WebMap,
          MapView,
          FeatureLayer,
          Search,
          VectorTileLayer,
          SimpleMarkerSymbol,
          SimpleLineSymbol,
          SimpleRenderer,
          Legend
        ]) => {
          esriConfig.apiKey = "AAPK2456e7e0539844b39d13266e742a95cbYV9nTVDpArBvqC_Y21vE-ZcWrZCNAXTgi3ZGBtO11pzgJMdZwMq9FmQyq_78eY8I";
          /*
          const vtlLayer = new VectorTileLayer({
            url: "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/9954884ef30a4f0ca143b5c78b2263aa",
          });

          const map = new Map({
            basemap: "arcgis-light-gray",
            layers: [vtlLayer],
          });

            const map = new Map({
            basemap: "topo",
          });
          const map = new Map({
            basemap: "streets-navigation-vector",
          });
          */

        
          const webmap = new WebMap({
            portalItem: {
              // autocasts as new PortalItem()
              id: "d9e1868ce24a40adaa94a2c9a5f942a6",
            },
          });

          const view = new MapView({
            container: "viewDivpeajes",
            map: webmap,
            center: [-74, 4], // lon, lat
            zoom: 5,
          });

          const uf1 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#EB5255",
            width: "7px",
            style: "solid",
            transparency: 1,
          };
          const uf2 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#239FCC",
            width: "7px",
            style: "solid",
          };
          const uf3 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#B3DF69",
            width: "7px",
            style: "solid",
          };
          const uf4 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#BC80BD",
            width: "7px",
            style: "solid",
          };
          const uf5 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#30408e",
            width: "7px",
            style: "solid",
          };
          const uf6 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#42AF99",
            width: "7px",
            style: "solid",
          };
          const uf7 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#B34979",
            width: "7px",
            style: "solid",
          };
          const uf8 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#b34950",
            width: "7px",
            style: "solid",
          };
          const uf9 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#b35949",
            width: "7px",
            style: "solid",
          };
          const uf10 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#b35949",
            width: "7px",
            style: "solid",
          };

          // Symbol for other major highways
          const otherSym = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#FD77DD",
            width: "3px",
            style: "solid",
          };


          const hwyRenderer = {
            type: "unique-value", // autocasts as new UniqueValueRenderer()
            defaultSymbol: otherSym,
            defaultLabel: "Uf",
            field: "CODIGOUF",
            uniqueValueInfos: [
              {
                value: "UF1", // code for interstates/freeways
                symbol: uf1,
              },
              {
                value: "UF2", // code for U.S. highways
                symbol: uf2,
              },
              {
                value: "UF3", // code for U.S. highways
                symbol: uf3,
              },
              {
                value: "UF4", // code for U.S. highways
                symbol: uf4,
              },
              {
                value: "UF5", // code for U.S. highways
                symbol: uf5,
              },
              {
                value: "UF6", // code for U.S. highways
                symbol: uf6,
              },
              {
                value: "UF7", // code for U.S. highways
                symbol: uf7,
              },
              {
                value: "UF8", // code for U.S. highways
                symbol: uf8,
              },
              {
                value: "UF9", // code for U.S. highways
                symbol: uf9,
              },
              {
                value: "UF10", // code for U.S. highways
                symbol: uf10,
              },
            ],
          };


          const featureLayerUF = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/2",

            renderer: hwyRenderer,
            title: "Unidades Funcionales",
            //definitionExpression: "ID_MT = '" + this.idProyecto + "'",
          });

          const trailsRenderer = {
            type: "simple",
            symbol: {
              color: "#0b2ac6",
              type: "simple-line",
              style: "short-dot",
              width: 1,
            },
          };

 

          const citiesRenderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              url: "/assets/img/newhome/peaje.png",
              width: "40px",
              height: "40px",
            },
          };

          const renderer = new SimpleRenderer({
            symbol: new SimpleLineSymbol({
              width: 1,
              color: "#0b2ac6",
              style: "short-dot",
            }),
          });

          const proyRenderer = {
            type: "simple",
            symbol: {
              color: "#5dfca5",
              type: "simple-line",
              style: "solid",
              width: 2,
            },
          };



          // const featureLayerPeajes = new FeatureLayer({
          //   url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/0",
          //   renderer: citiesRenderer,

          //   popupTemplate: {
          //     // autocasts as new PopupTemplate()
          //     title: '{NOMBRE}</br>Estado: {Estado}',
          //     overwriteActions: true,
          //   },
          // });

          const featureLayerPeajes = new FeatureLayer({
            url: "https://services8.arcgis.com/qXzQm8c8qEBxS81s/ArcGIS/rest/services/Peajes_Reportes/FeatureServer/0",
            renderer: citiesRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              title: "{NOMBRE}</br>Estado: {Estado}",

              overwriteActions: true,
            }
            
          });

          const featureLayerConcesiones = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/1",
            renderer: trailsRenderer,
          });
         
          webmap.add(featureLayerUF);
          webmap.add(featureLayerConcesiones);
          webmap.add(featureLayerPeajes);
        }
      )
      .catch((err) => {
        // handle any errors
        console.error(err);
      });
  }


  actualizarProyectos(event) {

    if(this.fechaFiltro == null){
      var dateCompare = _moment().format('YYYY-MM-DD HH:mm');
    }else{
      var dateCompare = formatDate(this.fechaFiltro, "yyyy-MM-dd", "es-ES");
    }


    if (this.proyectoSeleccionado == null){
      this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes.filter(a => a.idAdministradoPor == 1 && dateCompare >= a.fechaInicioEstado && dateCompare <= a.fechaFinEstado
        );


        
    loadModules([
      "esri/config",
      "esri/Map",
      "esri/WebMap",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/widgets/Search",
      "esri/layers/VectorTileLayer",
      "esri/symbols/SimpleLineSymbol",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/renderers/SimpleRenderer",
      "esri/widgets/Legend"
    ])
      //.then(([esriConfig,Map,WebMap,Basemap, VectorTileLayer, TileLayer, MapView, FeatureLayer, Search]) => {
      .then(
        ([
          esriConfig,
          Map,
          WebMap,
          MapView,
          FeatureLayer,
          Search,
          VectorTileLayer,
          SimpleMarkerSymbol,
          SimpleLineSymbol,
          SimpleRenderer,
          Legend
        ]) => {
          esriConfig.apiKey = "AAPK2456e7e0539844b39d13266e742a95cbYV9nTVDpArBvqC_Y21vE-ZcWrZCNAXTgi3ZGBtO11pzgJMdZwMq9FmQyq_78eY8I";
          /*
          const vtlLayer = new VectorTileLayer({
            url: "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/9954884ef30a4f0ca143b5c78b2263aa",
          });

          const map = new Map({
            basemap: "arcgis-light-gray",
            layers: [vtlLayer],
          });

            const map = new Map({
            basemap: "topo",
          });
          const map = new Map({
            basemap: "streets-navigation-vector",
          });
          */

        
          const webmap = new WebMap({
            portalItem: {
              // autocasts as new PortalItem()
              id: "d9e1868ce24a40adaa94a2c9a5f942a6",
            },
          });

          const view = new MapView({
            container: "viewDivpeajes",
            map: webmap,
            center: [-74, 4], // lon, lat
            zoom: 5,
          });

          const uf1 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#EB5255",
            width: "7px",
            style: "solid",
            transparency: 1,
          };
          const uf2 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#239FCC",
            width: "7px",
            style: "solid",
          };
          const uf3 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#B3DF69",
            width: "7px",
            style: "solid",
          };
          const uf4 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#BC80BD",
            width: "7px",
            style: "solid",
          };
          const uf5 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#30408e",
            width: "7px",
            style: "solid",
          };
          const uf6 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#42AF99",
            width: "7px",
            style: "solid",
          };
          const uf7 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#B34979",
            width: "7px",
            style: "solid",
          };
          const uf8 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#b34950",
            width: "7px",
            style: "solid",
          };
          const uf9 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#b35949",
            width: "7px",
            style: "solid",
          };
          const uf10 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#b35949",
            width: "7px",
            style: "solid",
          };

          // Symbol for other major highways
          const otherSym = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#FD77DD",
            width: "3px",
            style: "solid",
          };


          const hwyRenderer = {
            type: "unique-value", // autocasts as new UniqueValueRenderer()
            defaultSymbol: otherSym,
            defaultLabel: "Uf",
            field: "CODIGOUF",
            uniqueValueInfos: [
              {
                value: "UF1", // code for interstates/freeways
                symbol: uf1,
              },
              {
                value: "UF2", // code for U.S. highways
                symbol: uf2,
              },
              {
                value: "UF3", // code for U.S. highways
                symbol: uf3,
              },
              {
                value: "UF4", // code for U.S. highways
                symbol: uf4,
              },
              {
                value: "UF5", // code for U.S. highways
                symbol: uf5,
              },
              {
                value: "UF6", // code for U.S. highways
                symbol: uf6,
              },
              {
                value: "UF7", // code for U.S. highways
                symbol: uf7,
              },
              {
                value: "UF8", // code for U.S. highways
                symbol: uf8,
              },
              {
                value: "UF9", // code for U.S. highways
                symbol: uf9,
              },
              {
                value: "UF10", // code for U.S. highways
                symbol: uf10,
              },
            ],
          };


          const featureLayerUF = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/2",

            renderer: hwyRenderer,
            title: "Unidades Funcionales",
            //definitionExpression: "ID_MT = '" + this.idProyecto + "'",
          });

          const trailsRenderer = {
            type: "simple",
            symbol: {
              color: "#0b2ac6",
              type: "simple-line",
              style: "short-dot",
              width: 1,
            },
          };

 

          const citiesRenderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              url: "/assets/img/newhome/peaje.png",
              width: "40px",
              height: "40px",
            },
          };

          const renderer = new SimpleRenderer({
            symbol: new SimpleLineSymbol({
              width: 1,
              color: "#0b2ac6",
              style: "short-dot",
            }),
          });

          const proyRenderer = {
            type: "simple",
            symbol: {
              color: "#5dfca5",
              type: "simple-line",
              style: "solid",
              width: 2,
            },
          };



          const featureLayerPeajes = new FeatureLayer({
            url: "https://services8.arcgis.com/qXzQm8c8qEBxS81s/ArcGIS/rest/services/Peajes_Reportes/FeatureServer/0",
            renderer: citiesRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              title: "{NOMBRE}</br>Estado: {Estado}",

              overwriteActions: true,
            }
            
          });

          const featureLayerConcesiones = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/1",
            renderer: trailsRenderer,
          });
         
          webmap.add(featureLayerUF);
          webmap.add(featureLayerConcesiones);
          webmap.add(featureLayerPeajes);
        }
      )
      .catch((err) => {
        // handle any errors
        console.error(err);
      });

    }else{
      this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes.filter(
        (e) => e.idProyecto === this.proyectoSeleccionado.id && dateCompare >= e.fechaInicioEstado && dateCompare <= e.fechaFinEstado
      );



      
    loadModules([
      "esri/config",
      "esri/Map",
      "esri/WebMap",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/widgets/Search",
      "esri/symbols/SimpleLineSymbol",
      "esri/renderers/SimpleRenderer",
      "esri/widgets/Legend",
    ])
      //.then(([esriConfig,Map,WebMap,Basemap, VectorTileLayer, TileLayer, MapView, FeatureLayer, Search]) => {
      .then(
        ([
          esriConfig,
          Map,
          WebMap,
          MapView,
          FeatureLayer,
          Search,
          SimpleLineSymbol,
          SimpleRenderer,
          Legend,
        ]) => {
          esriConfig.apiKey = "AAPK2456e7e0539844b39d13266e742a95cbYV9nTVDpArBvqC_Y21vE-ZcWrZCNAXTgi3ZGBtO11pzgJMdZwMq9FmQyq_78eY8I";
          /*
          const vtlLayer = new VectorTileLayer({
            url: "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/9954884ef30a4f0ca143b5c78b2263aa",
          });

          const map = new Map({
            basemap: "arcgis-light-gray",
            layers: [vtlLayer],
          });

            const map = new Map({
            basemap: "topo",
          });
          const map = new Map({
            basemap: "streets-navigation-vector",
          });
          */

          var capaufs = [];
          const webmap = new WebMap({
            portalItem: {
              // autocasts as new PortalItem()
              id: "d9e1868ce24a40adaa94a2c9a5f942a6",
            },
          });

          const view = new MapView({
            container: "viewDivpeajes",
            map: webmap,
            center: [-74, 4], // lon, lat
            zoom: 5,
          });

          const uf1 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#EB5255",
            width: "7px",
            style: "solid",
            transparency: 1,
          };
          const uf2 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#239FCC",
            width: "7px",
            style: "solid",
          };
          const uf3 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#B3DF69",
            width: "7px",
            style: "solid",
          };
          const uf4 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#BC80BD",
            width: "7px",
            style: "solid",
          };
          const uf5 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#30408e",
            width: "7px",
            style: "solid",
          };
          const uf6 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#42AF99",
            width: "7px",
            style: "solid",
          };
          const uf7 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#B34979",
            width: "7px",
            style: "solid",
          };
          const uf8 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#b34950",
            width: "7px",
            style: "solid",
          };
          const uf9 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#b35949",
            width: "7px",
            style: "solid",
          };
          const uf10 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#b35949",
            width: "7px",
            style: "solid",
          };

          // Symbol for other major highways
          const otherSym = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#FD77DD",
            width: "3px",
            style: "solid",
          };


          const hwyRenderer = {
            type: "unique-value", // autocasts as new UniqueValueRenderer()
            defaultSymbol: otherSym,
            defaultLabel: "Uf",
            field: "CODIGOUF",
            uniqueValueInfos: [
              {
                value: "UF1", // code for interstates/freeways
                symbol: uf1,
              },
              {
                value: "UF2", // code for U.S. highways
                symbol: uf2,
              },
              {
                value: "UF3", // code for U.S. highways
                symbol: uf3,
              },
              {
                value: "UF4", // code for U.S. highways
                symbol: uf4,
              },
              {
                value: "UF5", // code for U.S. highways
                symbol: uf5,
              },
              {
                value: "UF6", // code for U.S. highways
                symbol: uf6,
              },
              {
                value: "UF7", // code for U.S. highways
                symbol: uf7,
              },
              {
                value: "UF8", // code for U.S. highways
                symbol: uf8,
              },
              {
                value: "UF9", // code for U.S. highways
                symbol: uf9,
              },
              {
                value: "UF10", // code for U.S. highways
                symbol: uf10,
              },
            ],
          };


         
          const trailsRenderer = {
            type: "simple",
            symbol: {
              color: "#0b2ac6",
              type: "simple-line",
              style: "short-dot",
              width: 1,
            },
          };

 

          const citiesRenderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              url: "/assets/img/newhome/peaje.png",
              width: "40px",
              height: "40px",
            },
          };

          const renderer = new SimpleRenderer({
            symbol: new SimpleLineSymbol({
              width: 1,
              color: "#0b2ac6",
              style: "short-dot",
            }),
          });

          const proyRenderer = {
            type: "simple",
            symbol: {
              color: "#5dfca5",
              type: "simple-line",
              style: "solid",
              width: 2,
            },
          };



          
          const featureLayerPeajes = new FeatureLayer({
            url: "https://services8.arcgis.com/qXzQm8c8qEBxS81s/ArcGIS/rest/services/Peajes_Reportes/FeatureServer/0",
            renderer: citiesRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              title: "{NOMBRE}</br>Estado: {Estado}",

              overwriteActions: true,
            }
          });

          const featureLayerPeajesFiltro = new FeatureLayer({
            url: "https://services8.arcgis.com/qXzQm8c8qEBxS81s/ArcGIS/rest/services/Peajes_Reportes/FeatureServer/0",
            renderer: citiesRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              title: "{NOMBRE}</br>Estado: {Estado}",

              overwriteActions: true,
            },
            definitionExpression: "Idpr = '" + this.proyectoSeleccionado.id  + "'",
          });

          const featureLayerUF = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/2",

            renderer: hwyRenderer,
            title: "Unidades Funcionales",
            definitionExpression: "ID_MT = '" + this.proyectoSeleccionado.id + "'",
          });
          
          const featureLayerUFFiltro = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/2",

            renderer: hwyRenderer,
            title: "Unidades Funcionales",
            definitionExpression: "ID_MT = '" + this.proyectoSeleccionado.id + "'",
          });

          const featureLayerConcesiones = new FeatureLayer({
            url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/1",
            renderer: trailsRenderer,
          });
         if(this.proyectoSeleccionado.id == null){
          webmap.add(featureLayerUF);
          webmap.add(featureLayerConcesiones);
          webmap.add(featureLayerPeajes);
         }else{
         
          
         
          featureLayerUF.queryFeatures().then(function (results) {
            // prints the array of result graphics to the console
            let demo23 = results.features;
            let colorUFS = [
              "#00ced1",
              "#2f4f4f",
              "#483d8b",
              "#8fbc8f",
              "#e9967a",
              "#8b0000",
              "#ff8c00",
              "#008b8b",
              "#00008b",
              "#dc143c",
              "#6495ed",
              "#ff7f50",
              "#7fff00",
              "#ff1493",
              "#9400d3",
              "#008000",
              "#ff69b4",
              "#cd5c5c",
              "#b349ae",
              "#f08080",
              "#b3495c",
              "#b0c4de",
            ];
            //const demo23 = results.features;
            let contador = 0;
            demo23.forEach(function (elemento, indice, array) {
              contador = contador + 1;
              capaufs.push({
                value: elemento.attributes.CODIGOUF,
                symbol: {
                  type: "simple-line", // autocasts as new SimpleLineSymbol()
                  color: colorUFS[contador],
                  width: "7px",
                  style: "solid",
                },
              });
            });

            //return frutas;
          });

          const loadLegend = async () => {
            const otherSym1 = {
              type: "simple-line", // autocasts as new SimpleLineSymbol()
              color: "#FD77DD",
              width: "5px",
              style: "solid",
            };
            //console.log(hwyRenderer2);
            const hwyRenderer3 = {
              type: "unique-value", // autocasts as new UniqueValueRenderer()
              opacity: 1,
              //defaultSymbol: otherSym1,
              defaultLabel: "UF",
              field: "CODIGOUF",
              uniqueValueInfos: capaufs,
            };
            
           
            const labelClass2 = {
              // autocasts as new LabelClass()
              symbol: {
                type: "text", // autocasts as new TextSymbol()
                color: "#11528a",
                haloColor: "#ffffff",
                haloSize: 1,
                font: {
                  size: 11,
                  weight: "bold",
                },
              },
              labelPlacement: "center-along",
              labelExpressionInfo: {
                expression: "$feature.CODIGOUF",
              },
            };

            const featureLayerUF2 = new FeatureLayer({
              url: "https://sig.ani.gov.co/arcgissrv/rest/services/3_DatosAbiertos/OpenDataICDE/FeatureServer/2",
              outFields: ["*"],
              renderer: hwyRenderer3,
              title: "Unidades Funcionales",
              definitionExpression: "ID_MT = '" +   this.proyectoSeleccionado.id + "'",
              labelingInfo: [labelClass2],
            });
            
            const legend = new Legend({
              view: view,
              hideLayersNotInCurrentView: true,
              respectLayerVisibility: false,
              layerInfos: [
                {
                  layer: featureLayerUF2,
                  title: "Unidad Funcional",
                },
              ],
              definitionExpression: "ID_MT = '" + this.proyectoSeleccionado.id+ "'",
            });
            // Add widget to the bottom right corner of the view
            
            //view.ui.add(legend, "bottom-left");
            webmap.add(featureLayerUFFiltro);
          
            webmap.add(featureLayerPeajesFiltro);
          };
          
         
       
   
          
          const searchWidget = new Search({
            view: view,
            renderer: proyRenderer,
            container: "basemapGalleryDiv",
            allPlaceholder: "Proyecto",
            popupEnabled: false,
            includeDefaultSources: false,
            sources: [
              {
                layer: featureLayerConcesiones,
                searchFields: ["ID_MT"],
                enableLabel: false,
                suggestionTemplate: "{ID_MT}, Party: {NOMBRE}",
                displayField: "ID_MT",
                exactMatch: true,
                outFields: ["*"],
                name: "Proyecto",
                maxResults: 9,
                placeholder: "example: 3708",
                resultSymbol: {
                  color: "#0b2ac6",
                  type: "simple-line",
                  style: "short-dot",
                  width: 1,
                },
              },
            ],
          });

          // Add the search widget to the top left corner of the view
          view.ui.add(searchWidget, {
            position: "top-right",
            class: "none",
            declaredClass: "none",
          });

          searchWidget.on("search-start", function (event) {
            console.log("Search started.");
          });
          const myTimeout = setTimeout(loadLegend, 3000);
          searchWidget.search("" + this.proyectoSeleccionado.id+ "");
         }
       
        }
      )
      .catch((err) => {
        // handle any errors
        console.error(err);
      });


    }
   

    this.totalPeajesOperacion = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.idEstado ==1 
    ).length;
    this.totalPeajesSuspension = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.idEstado ==3 
    ).length;

    this.informacionPeajesGroupBy =
      this.informacionGeneralPeajesFiltro.reduce(function (result, current) {
        result[current.idProyecto] = result[current.idProyecto] || [];
        result[current.idProyecto].push(current);
        return result;
      }, {});

    this.totalProyectos = Object.keys(
      this.informacionPeajesGroupBy
    ).length;

    this.totalTarifaPreferencial = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.tieneTarifaEspecial === 1
    ).length;
    this.totalPeajesElectronicos = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.tienePagoElectronico === true
    ).length;

  }

  
  actualizarAdministrado(event) {


    if(this.fechaFiltro == null){
      var dateCompare = _moment().format('YYYY-MM-DD HH:mm');
    }else{
      var dateCompare = formatDate(this.fechaFiltro, "yyyy-MM-dd", "es-ES");
    }

    if (this.selectedAdministradoPor == null){
      this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes.filter(a => a.idAdministradoPor == 1 && dateCompare >= a.fechaInicioEstado && dateCompare <= a.fechaFinEstado
      );
    }else{
      this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes.filter(
        (e) => e.idAdministradoPor === this.selectedAdministradoPor.id && dateCompare >= e.fechaInicioEstado && dateCompare <= e.fechaFinEstado
      );
    }



    if (this.proyectoSeleccionado != null){
      this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes.filter(
        (e) => e.idProyecto === this.proyectoSeleccionado.id
      );
    }
   

    this.totalPeajesOperacion = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.idEstado ==1 
    ).length;
    this.totalPeajesSuspension = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.idEstado ==3 
    ).length;

    this.informacionPeajesGroupBy =
      this.informacionGeneralPeajesFiltro.reduce(function (result, current) {
        result[current.idProyecto] = result[current.idProyecto] || [];
        result[current.idProyecto].push(current);
        return result;
      }, {});

    this.totalProyectos = Object.keys(
      this.informacionPeajesGroupBy
    ).length;

    this.totalTarifaPreferencial = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.tieneTarifaEspecial === 1
    ).length;
    this.totalPeajesElectronicos = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.tienePagoElectronico === true
    ).length;
  }

  btnFiltroTodos(generacion: string) {
    this.fechaFiltro = null;
    this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes;
    this.proyectoSeleccionado = null;

    this.totalPeajesOperacion = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.idEstado ==1 
    ).length;
    this.totalPeajesSuspension = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.idEstado ==3 
    ).length;

    this.informacionPeajesGroupBy =
      this.informacionGeneralPeajesFiltro.reduce(function (result, current) {
        result[current.idProyecto] = result[current.idProyecto] || [];
        result[current.idProyecto].push(current);
        return result;
      }, {});

    this.totalProyectos = Object.keys(
      this.informacionPeajesGroupBy
    ).length;

    this.totalTarifaPreferencial = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.tieneTarifaEspecial === 1
    ).length;
    this.totalPeajesElectronicos = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.tienePagoElectronico === true
    ).length;
  }




  
  btnFiltroFechaCorte() {
    
    var dateCompareNow = _moment().format('YYYY-MM-DD HH:mm');
    var dateCompareFiltro = formatDate(this.fechaFiltro, "yyyy-MM-dd", "es-ES");

   
if (this.fechaFiltro!=null){
  this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes;
  this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes.filter(a => a.idAdministradoPor == 1 && dateCompareFiltro >= a.fechaInicioEstado && dateCompareFiltro <= a.fechaFinEstado);
 
}else{
  this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes;
  this.informacionGeneralPeajesFiltro = this.informacionGeneralPeajes.filter(a => a.idAdministradoPor == 1 && dateCompareNow >= a.fechaInicioEstado && dateCompareNow <= a.fechaFinEstado);

}

           
    this.proyectoSeleccionado = null;

    this.totalPeajesOperacion = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.idEstado ==1 
    ).length;
    this.totalPeajesSuspension = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.idEstado ==3 
    ).length;

    this.informacionPeajesGroupBy =
      this.informacionGeneralPeajesFiltro.reduce(function (result, current) {
        result[current.idProyecto] = result[current.idProyecto] || [];
        result[current.idProyecto].push(current);
        return result;
      }, {});

    this.totalProyectos = Object.keys(
      this.informacionPeajesGroupBy
    ).length;

    this.totalTarifaPreferencial = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.tieneTarifaEspecial === 1
    ).length;
    this.totalPeajesElectronicos = this.informacionGeneralPeajesFiltro.filter(
      (a) => a.tienePagoElectronico === true
    ).length;
  }
}
