import { Component, OnInit } from '@angular/core';
import { ContratoCredito } from 'src/app/contrato-credito-carretero/contratoCredito';

@Component({
  selector: 'app-contrato-credito-base',
  templateUrl: './contrato-credito-base.component.html',
  styleUrls: ['./contrato-credito-base.component.sass']
})
export class ContratoCreditoBaseComponent implements OnInit {

  nombreProyecto: any;
  idProyecto: number;
  visibleContratoCreditoIndexado: boolean = false;
  idContratoCredito: any;
  contratoCredito: ContratoCredito; 
  habilitaEliminarContratoCredito: boolean;
  tieneContrato: string;
  idContratoConcesionario: any;
  habilitaIndexacionContrato: boolean;

  constructor() { }

  ngOnInit() {
   /*  var nombreProyecto = document.getElementById("nombre_proyecto");
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto); */
  }

}
