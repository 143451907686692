import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ProyectoCarretero } from '../../../informacion-proyecto-carretero/proyectoCarretero';


@Injectable({
    providedIn: 'root'
})
export class ContratoCreditoPuenteService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public getContratoCreditoPuentePorProyecto(idProyecto) {
        return this.http.get(this.END_POINT + '/CntContratoCreditoPuente/list/' + idProyecto)
            .pipe(
                retry(1));
    }

    public guardarNuevoContratoCreditoPuente(data) {
       return this.http.post(this.END_POINT + '/CntContratoCreditoPuente', data)
            .pipe(
                retry(1));
    }

    public editarContratoCreditoPuente(data, idContratoCredito) {
       return this.http.put(this.END_POINT + '/CntContratoCreditoPuente/' + idContratoCredito, data)
            .pipe(
                retry(1));
    }

    public eliminarContratoCreditoPuente(id) {
        return this.http.delete(this.END_POINT + '/CntContratoCreditoPuente/' + id)
            .pipe(
                retry(1));
    }

    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }


}
