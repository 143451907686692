import { Component, OnInit } from '@angular/core';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import {
  AmbObligacionDetalleDTO,
  AmbObligacionEncabezadoDTO,
  AmbSubCuentaDTO,
  AmbMaTipoCompensacionDTO,
} from '../../compensaciones-ambientales.dto';
import { forkJoin, Subscription } from 'rxjs';
import { CompensacionesAmbientalesService } from '../../compensaciones-ambientales.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-obligacion-ambiental',
  templateUrl: './obligacion-ambiental.component.html',
  styleUrls: ['./obligacion-ambiental.component.sass'],
})
export class ObligacionAmbientalComponent implements OnInit {
  subscription: Subscription;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  idObligacion: number;
  obligacionAmbiental: AmbObligacionEncabezadoDTO;

  obligacionAmbientalDetalle: AmbObligacionDetalleDTO;
  subCuenta: AmbSubCuentaDTO;

  tipoCompensacionList: AmbMaTipoCompensacionDTO[];

  form;
  formTipoCompensacion;

  fechaActual: Date;
  fechaMinima: Date;

  dialogoCompensacion: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private compensacionesAmbientalesService: CompensacionesAmbientalesService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
    const regexMayorACeroDecimal2Digitos = '[0-9]+(.[0-9][0-9]?)?';
    //  Crear formulario
    this.form = formBuilder.group({
      numeroActoAdm: [null, [Validators.required, Validators.maxLength(50)]],
      fechaSuscripcion: [null, [Validators.required]],
      observaciones: [null, [Validators.required, Validators.maxLength(1000)]],
    });
    //  Crear formulario
    this.formTipoCompensacion = formBuilder.group({
      idTipoCompensacion: [null, [Validators.required]],
      cantidadCompensada: [
        null,
        [
          Validators.required,
          Validators.maxLength(12),
          Validators.pattern(regexMayorACeroDecimal2Digitos),
        ],
      ],
    });
  }

  ngOnInit() {
    this.fechaActual = new Date();

    // Obtener parametros
    const idParam = 'id';
    const idParamOblig = 'idOblig';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.idObligacion = params[idParamOblig];
    });

    // Obtener Obligacion Ambiental
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.compensacionesAmbientalesService.srvObtenerValorSubcuentaPorProyecto(
        this.idProyecto
      ),
      this.compensacionesAmbientalesService.srvObtenerObligacionAmbientalPorId(
        this.idObligacion
      )
    ).subscribe(([proyectoData, subCuentaData, obligacionAmbientalData]) => {
      this.proyectoSeleccionado = proyectoData;
      this.subCuenta = subCuentaData.valorSubCuenta;

      if (this.subCuenta != null) {
        this.fechaMinima = new Date(this.subCuenta.fechaReferencia);
      } else {
        this.fechaMinima = null;
      }

      this.obligacionAmbiental = obligacionAmbientalData.obligacionAmbiental;
      console.log('obligacionAmbientalData', obligacionAmbientalData);

      if (
        this.obligacionAmbiental != null &&
        this.obligacionAmbiental.dcmDocumentoDTO != null
      ) {
        if (this.obligacionAmbiental.id != 0) {
          this.obligacionAmbiental.dcmDocumentoDTO.fechaExpedicion = new Date(
            this.obligacionAmbiental.dcmDocumentoDTO.fechaExpedicion
          );
        } else {
          this.obligacionAmbiental.dcmDocumentoDTO.fechaExpedicion = null;
        }
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });


    this.obligacionAmbientalDetalle = {};

    if (this.obligacionAmbiental == null) {
      this.obligacionAmbiental = {
        dcmDocumentoDTO: {
          numero: null,
          fechaExpedicion: null,
        },
        observaciones: null,
        ambObligacionDetalleDTO: [],
      };
    } else {
      if (this.obligacionAmbiental.dcmDocumentoDTO != null) {
        this.obligacionAmbiental.dcmDocumentoDTO.fechaExpedicion = new Date(
          this.obligacionAmbiental.dcmDocumentoDTO.fechaExpedicion
        );
      }
    }

    // Inicializar Listas
    forkJoin(
      this.compensacionesAmbientalesService.srvAmbMaTipoCompensacion()
    ).subscribe(
      ([tipoCompensacionData]) => {
        this.tipoCompensacionList = tipoCompensacionData.tipoCompensaciones;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  /**
   * Se llama cada vez que se elige un registro del selector 'Tipo de Compensacion'
   */
  onChangeTipoCompensacion(event) { }

  btnAgregar() {
    this.obligacionAmbientalDetalle = {};
    // Abrir el dialogo
    this.dialogoCompensacion = true;
  }

  btnEditar(obligacionDetalleSelected: AmbObligacionDetalleDTO) {
    this.obligacionAmbientalDetalle = obligacionDetalleSelected;
    //  Cargar el objeto del dropdown Tipo Compensacion
    this.obligacionAmbientalDetalle.idTipoCompensacionObj = this.tipoCompensacionList.find(
      (t) => t.id === this.obligacionAmbientalDetalle.idTipoCompensacion
    );
    // Abrir el dialogo
    this.dialogoCompensacion = true;
  }

  btnEliminar(obligacionDetalleSelected: AmbObligacionDetalleDTO) {
    this.obligacionAmbiental.ambObligacionDetalleDTO = this.obligacionAmbiental.ambObligacionDetalleDTO.filter(
      (obj) => obj !== obligacionDetalleSelected
    );
  }

  /**
   * Agrega el tipo de compensacion al listado
   */
  submitAgregarTipoCompensacion() {
    if (this.formTipoCompensacion.valid) {
      this.obligacionAmbientalDetalle.idTipoCompensacion = this.obligacionAmbientalDetalle.idTipoCompensacionObj.id;
      this.obligacionAmbientalDetalle.nombreTipoCompensacion = this.obligacionAmbientalDetalle.idTipoCompensacionObj.nombre;
      this.obligacionAmbientalDetalle.unidadMedida = this.obligacionAmbientalDetalle.idTipoCompensacionObj.unidadMedida;
      this.obligacionAmbientalDetalle.activo = true;
      // Si el objeto NO esta en la lista...
      if (
        !this.obligacionAmbiental.ambObligacionDetalleDTO.includes(
          this.obligacionAmbientalDetalle
        )
      ) {
        // Agregar objeto al listado
        this.obligacionAmbiental.ambObligacionDetalleDTO.push(
          this.obligacionAmbientalDetalle
        );
      }

      this.obligacionAmbientalDetalle = {};
      // Limpiar Formulario
      this.formTipoCompensacion.reset();
      this.formTipoCompensacion.markAsPristine();
      this.formTipoCompensacion.markAsUntouched();

      this.dialogoCompensacion = false;
    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos');
    }
  }

  /**
   * Guarda los cambios al crear o editar un bien
   */
  submitGuardarObligacionAmbiental() {
    if (this.form.valid) {
      //  Completar el DTO a guardar
      this.obligacionAmbiental.idProyecto = this.proyectoSeleccionado.id;
      this.obligacionAmbiental.rutaShape = null;
      this.obligacionAmbiental.dcmDocumentoDTO.idTipo = 0;
      this.obligacionAmbiental.dcmDocumentoDTO.modifica = false;
      this.obligacionAmbiental.dcmDocumentoDTO.descripcion = null;
      // this.obligacionAmbiental.dcmDocumentoDTO.rutaDocumento = 'ruta-archivo';
      this.obligacionAmbiental.dcmDocumentoDTO.fechaInicio = this.obligacionAmbiental.dcmDocumentoDTO.fechaExpedicion;
      this.obligacionAmbiental.dcmDocumentoDTO.fechaFin = null;

      if (this.obligacionAmbiental.ambObligacionDetalleDTO.length > 0) {
        //  Guardar el DTO
        forkJoin(
          this.compensacionesAmbientalesService.srvGuardarObligacionAmbiental(
            this.obligacionAmbiental
          ),
          this.compensacionesAmbientalesService.sincronizarZonaAfectada()
        ).subscribe(
          ([result, resultSincronizacion]) => {
            if (result != null && result.respuestaServicio != null) {
              //  Si el resultado es exitoso...
              if (result.respuestaServicio.codigoSalida === 1) {
                //  Consultar nuevamente la lista
                // this.listarBienes();
                //  Lanzar mensaje de exito
                this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
                //  Limpiar Formulario
                this.form.reset();
                this.form.markAsPristine();
                this.form.markAsUntouched();

                //  Cerrar el dialogo
                // this.dialogoGuardarBien = false;
              } else {
                this.lanzarMensajeWarning(
                  result.respuestaServicio.mensajeSalida
                );
              }
            }
            if (resultSincronizacion != null) {
              console.log('resultSincronizacion', resultSincronizacion);
            }
          }, error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            // Desbloquear pantalla
            // this.blockedDocument = false;
            const paginaAnterior =
              '/carreteros/infGeneral/proyectoHome/' +
              this.proyectoSeleccionado.id +
              '/compensaciones-ambientales/' +
              this.proyectoSeleccionado.id +
              '/obligaciones-compensacion/' +
              this.proyectoSeleccionado.id;
            this.router.navigate([paginaAnterior]);
          }
        );
      } else {
        this.lanzarMensajeWarning('Debe agregar por lo menos una compensacion');
      }
    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos');
    }
  }

  bntVolver() {
    const ruta =
      'carreteros/infGeneral/proyectoHome/' +
      this.proyectoSeleccionado.id +
      '/compensaciones-ambientales/' +
      this.proyectoSeleccionado.id +
      '/obligaciones-compensacion/' +
      this.proyectoSeleccionado.id;
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
