import { Component } from '@angular/core';

@Component({
  selector: 'app-public-novedades',
  templateUrl: './public-novedades.component.html',
  styleUrls: ['./public-novedades.component.sass']
})
export class PublicNovedadesComponent {

}
