import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProyectoDTO, VwCrrAvanceUfDTO, IntervencionDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable, forkJoin } from 'rxjs';
import { VwAvanceProyectoDTO, CrrEtapaEjecutadaProyectoDTO, CrrEtapaEjecutadaUfDTO, VwCrrAvanceIntervencionUfDepartamentoDTO, CrrEtapaEjecutadaIntervencionUfDTO } from '../../ejecucion-proyecto.dto';
import { EjecucionProyectoService } from '../../ejecucion_proyecto.service';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-avance-proyecto-construccion',
  templateUrl: './avance-proyecto-construccion.component.html',
  styleUrls: ['./avance-proyecto-construccion.component.sass']
})
export class AvanceProyectoConstruccionComponent implements OnInit {

  vistaActual: string = "";

  @Input() proyectoSeleccionado: ProyectoDTO;
  @Input() idUltimaEjecucionPublicada: number;
  @Input() porcentajeAvanceProyectoPublicado: number;
  @Output() OutputGuardarEtapaEjecutadaProyecto = new EventEmitter();
  @Output() OutputIrAMenuEtapas = new EventEmitter();

  ETAPA_PRECONSTRUCCION: number = 2;
  ETAPA_CONSTRUCCION: number = 3;
  ETAPA_OPERACION_Y_MANTENIMIENTO: number = 4;

  public unidadFuncionalSeleccionada: VwCrrAvanceUfDTO;
  public intervencionDTOSeleccionada: VwCrrAvanceIntervencionUfDepartamentoDTO;

  vwAvanceProyectoList: VwAvanceProyectoDTO[];

  crrEtapaEjecutadaProyectoPreconsDTO: CrrEtapaEjecutadaProyectoDTO = { idEtapa: null, idProyecto: null, fechaInicioEtapa: null, fechaFinEtapa: null };
  crrEtapaEjecutadaProyectoConstruccionDTO: CrrEtapaEjecutadaProyectoDTO = { idEtapa: null, idProyecto: null, fechaInicioEtapa: null, fechaFinEtapa: null };
  crrEtapaEjecutadaProyectoOperYMantDTO: CrrEtapaEjecutadaProyectoDTO = { idEtapa: null, idProyecto: null, fechaInicioEtapa: null, fechaFinEtapa: null };

  crrEtapaEjecutadaUfPreconsDTO: CrrEtapaEjecutadaUfDTO;
  crrEtapaEjecutadaUfConstruccionDTO: CrrEtapaEjecutadaUfDTO;
  crrEtapaEjecutadaUfOperYMantDTO: CrrEtapaEjecutadaUfDTO;

  crrEtapaEjecutadaIntervencionUfPreconsDTO: CrrEtapaEjecutadaIntervencionUfDTO;
  crrEtapaEjecutadaIntervencionUfConstruccionDTO: CrrEtapaEjecutadaIntervencionUfDTO;
  crrEtapaEjecutadaIntervencionUfOperYMantDTO: CrrEtapaEjecutadaIntervencionUfDTO;

  disabledFechaFinProyecto: boolean;
  disabledFechaFinProyectoConstruccion: boolean;
  disabledFechaFinUnidadFuncional: boolean;
  disabledFechaFinUnidadFuncionalConstruccion: boolean;

  porcentajeMinimo: number;

  constructor(private router: Router, private ruta: ActivatedRoute,
    private ejecucionProyectoService: EjecucionProyectoService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.obtenerConstantePorcentajeMinimo();
    this.listarEtapasProyecto();
    this.vistaActual = "proyecto";
  }

  obtenerConstantePorcentajeMinimo() {
    return this.ejecucionProyectoService.srvObtenerConstantePorcentajeMinimo().subscribe(data => {
      this.porcentajeMinimo = data;
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  listarEtapasProyecto() {
    forkJoin(
      this.ejecucionProyectoService.srvListarEtapaEjecutadaProyecto(this.proyectoSeleccionado.id, this.ETAPA_PRECONSTRUCCION),
      this.ejecucionProyectoService.srvListarEtapaEjecutadaProyecto(this.proyectoSeleccionado.id, this.ETAPA_CONSTRUCCION),
      this.ejecucionProyectoService.srvListarEtapaEjecutadaProyecto(this.proyectoSeleccionado.id, this.ETAPA_OPERACION_Y_MANTENIMIENTO),
    ).subscribe(([preconstruccionDataList, construccionDataList, operacionYMantDataList]) => {
      if (preconstruccionDataList != null && preconstruccionDataList.length > 0) {
        this.crrEtapaEjecutadaProyectoPreconsDTO = preconstruccionDataList[0];
        if (this.crrEtapaEjecutadaProyectoPreconsDTO.fechaInicioEtapa != null) {
          this.crrEtapaEjecutadaProyectoPreconsDTO.fechaInicioEtapa = new Date(this.crrEtapaEjecutadaProyectoPreconsDTO.fechaInicioEtapa);
        }
        if (this.crrEtapaEjecutadaProyectoPreconsDTO.fechaFinEtapa != null) {
          this.crrEtapaEjecutadaProyectoPreconsDTO.fechaFinEtapa = new Date(this.crrEtapaEjecutadaProyectoPreconsDTO.fechaFinEtapa);
        }
      } else {
        this.crrEtapaEjecutadaProyectoPreconsDTO.idEtapa = this.ETAPA_PRECONSTRUCCION;
        this.crrEtapaEjecutadaProyectoPreconsDTO.idProyecto = this.proyectoSeleccionado.id;
      }

      if (construccionDataList != null && construccionDataList.length > 0) {
        this.crrEtapaEjecutadaProyectoConstruccionDTO = construccionDataList[0];
        if (this.crrEtapaEjecutadaProyectoConstruccionDTO.fechaInicioEtapa != null) {
          this.crrEtapaEjecutadaProyectoConstruccionDTO.fechaInicioEtapa = new Date(this.crrEtapaEjecutadaProyectoConstruccionDTO.fechaInicioEtapa);
        }
        if (this.crrEtapaEjecutadaProyectoConstruccionDTO.fechaFinEtapa != null) {
          this.crrEtapaEjecutadaProyectoConstruccionDTO.fechaFinEtapa = new Date(this.crrEtapaEjecutadaProyectoConstruccionDTO.fechaFinEtapa);
        }
      } else {
        this.crrEtapaEjecutadaProyectoConstruccionDTO.idEtapa = this.ETAPA_CONSTRUCCION;
        this.crrEtapaEjecutadaProyectoConstruccionDTO.idProyecto = this.proyectoSeleccionado.id;
      }

      if (operacionYMantDataList != null && operacionYMantDataList.length > 0) {
        this.crrEtapaEjecutadaProyectoOperYMantDTO = operacionYMantDataList[0];
        if (this.crrEtapaEjecutadaProyectoOperYMantDTO.fechaInicioEtapa != null) {
          this.crrEtapaEjecutadaProyectoOperYMantDTO.fechaInicioEtapa = new Date(this.crrEtapaEjecutadaProyectoOperYMantDTO.fechaInicioEtapa);
        }
        if (this.crrEtapaEjecutadaProyectoOperYMantDTO.fechaFinEtapa != null) {
          this.crrEtapaEjecutadaProyectoOperYMantDTO.fechaFinEtapa = new Date(this.crrEtapaEjecutadaProyectoOperYMantDTO.fechaFinEtapa);
        }
      } else {
        this.crrEtapaEjecutadaProyectoOperYMantDTO.idEtapa = this.ETAPA_OPERACION_Y_MANTENIMIENTO;
        this.crrEtapaEjecutadaProyectoOperYMantDTO.idProyecto = this.proyectoSeleccionado.id;
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  guardarEtapasProyecto(forma: NgForm) {
    if (this.crrEtapaEjecutadaProyectoPreconsDTO.fechaInicioEtapa != null) {
      this.ejecucionProyectoService.srvGuardarEtapaEjecutadaProyecto(this.crrEtapaEjecutadaProyectoPreconsDTO).subscribe((data: any) => {
        this.crrEtapaEjecutadaProyectoPreconsDTO.id = data.resultado.id;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    if (this.crrEtapaEjecutadaProyectoConstruccionDTO.fechaInicioEtapa != null) {
      this.ejecucionProyectoService.srvGuardarEtapaEjecutadaProyecto(this.crrEtapaEjecutadaProyectoConstruccionDTO).subscribe((data: any) => {
        this.crrEtapaEjecutadaProyectoConstruccionDTO.id = data.resultado.id;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    if (this.crrEtapaEjecutadaProyectoOperYMantDTO.fechaInicioEtapa != null) {
      this.ejecucionProyectoService.srvGuardarEtapaEjecutadaProyecto(this.crrEtapaEjecutadaProyectoOperYMantDTO).subscribe((data: any) => {
        this.crrEtapaEjecutadaProyectoOperYMantDTO.id = data.resultado.id;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    this.lanzarMensajeInfo("Las fechas se guardaron correctamente");
    this.OutputGuardarEtapaEjecutadaProyecto.emit({ resultado: { codigo: 1, descripcion: "Etapas del Proyecto guardadas correctamente" } });
  }

  outputUnidadFuncionalSeleccionada(unidadFuncionalSeleccionadaL) {
    this.vistaActual = 'unidadFuncional';
    this.unidadFuncionalSeleccionada = unidadFuncionalSeleccionadaL;
    this.crrEtapaEjecutadaUfPreconsDTO = {};
    this.crrEtapaEjecutadaUfConstruccionDTO = {};
    this.crrEtapaEjecutadaUfOperYMantDTO = {};
    this.listarEtapasUnidadFuncional();
  }

  outputYaCargaronUnidadesFuncionales(todasAl100L: boolean) {
    this.disabledFechaFinProyecto = !todasAl100L;
  }

  outputYaCargaronUnidadesFuncionalesConstruccion(todasAl100L: boolean) {
    this.disabledFechaFinProyectoConstruccion = !todasAl100L;
  }

  listarEtapasUnidadFuncional() {
    forkJoin(
      this.ejecucionProyectoService.srvListarEtapaEjecutadaUf(this.unidadFuncionalSeleccionada.idUnidadFuncional, this.ETAPA_PRECONSTRUCCION),
      this.ejecucionProyectoService.srvListarEtapaEjecutadaUf(this.unidadFuncionalSeleccionada.idUnidadFuncional, this.ETAPA_CONSTRUCCION),
      this.ejecucionProyectoService.srvListarEtapaEjecutadaUf(this.unidadFuncionalSeleccionada.idUnidadFuncional, this.ETAPA_OPERACION_Y_MANTENIMIENTO),
    ).subscribe(([preconstruccionDataList, construccionDataList, operacionYMantDataList]) => {

      if (preconstruccionDataList != null && preconstruccionDataList.length > 0) {
        this.crrEtapaEjecutadaUfPreconsDTO = preconstruccionDataList[0];
        if (this.crrEtapaEjecutadaUfPreconsDTO.fechaInicioEtapa != null) {
          this.crrEtapaEjecutadaUfPreconsDTO.fechaInicioEtapa = new Date(this.crrEtapaEjecutadaUfPreconsDTO.fechaInicioEtapa);
        }
        if (this.crrEtapaEjecutadaUfPreconsDTO.fechaFinEtapa != null) {
          this.crrEtapaEjecutadaUfPreconsDTO.fechaFinEtapa = new Date(this.crrEtapaEjecutadaUfPreconsDTO.fechaFinEtapa);
        }
      }

      if (construccionDataList != null && construccionDataList.length > 0) {
        this.crrEtapaEjecutadaUfConstruccionDTO = construccionDataList[0];
        if (this.crrEtapaEjecutadaUfConstruccionDTO.fechaInicioEtapa != null) {
          this.crrEtapaEjecutadaUfConstruccionDTO.fechaInicioEtapa = new Date(this.crrEtapaEjecutadaUfConstruccionDTO.fechaInicioEtapa);
        }
        if (this.crrEtapaEjecutadaUfConstruccionDTO.fechaFinEtapa != null) {
          this.crrEtapaEjecutadaUfConstruccionDTO.fechaFinEtapa = new Date(this.crrEtapaEjecutadaUfConstruccionDTO.fechaFinEtapa);
        }
      }

      if (operacionYMantDataList != null && operacionYMantDataList.length > 0) {
        this.crrEtapaEjecutadaUfOperYMantDTO = operacionYMantDataList[0];
        if (this.crrEtapaEjecutadaUfOperYMantDTO.fechaInicioEtapa != null) {
          this.crrEtapaEjecutadaUfOperYMantDTO.fechaInicioEtapa = new Date(this.crrEtapaEjecutadaUfOperYMantDTO.fechaInicioEtapa);
        }
        if (this.crrEtapaEjecutadaUfOperYMantDTO.fechaFinEtapa != null) {
          this.crrEtapaEjecutadaUfOperYMantDTO.fechaFinEtapa = new Date(this.crrEtapaEjecutadaUfOperYMantDTO.fechaFinEtapa);
        }
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  guardarEtapasUnidadFuncional(forma: NgForm) {
    if (this.crrEtapaEjecutadaUfPreconsDTO.fechaInicioEtapa != null) {
      this.crrEtapaEjecutadaUfPreconsDTO.idEtapa = this.ETAPA_PRECONSTRUCCION;
      this.crrEtapaEjecutadaUfPreconsDTO.idUnidadFuncional = this.unidadFuncionalSeleccionada.idUnidadFuncional;

      this.ejecucionProyectoService.srvGuardarEtapaEjecutadaUf(this.crrEtapaEjecutadaUfPreconsDTO).subscribe((data: any) => {
        this.crrEtapaEjecutadaUfPreconsDTO.id = data.resultado.id;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    if (this.crrEtapaEjecutadaUfConstruccionDTO.fechaInicioEtapa != null) {
      this.crrEtapaEjecutadaUfConstruccionDTO.idEtapa = this.ETAPA_CONSTRUCCION;
      this.crrEtapaEjecutadaUfConstruccionDTO.idUnidadFuncional = this.unidadFuncionalSeleccionada.idUnidadFuncional;

      this.ejecucionProyectoService.srvGuardarEtapaEjecutadaUf(this.crrEtapaEjecutadaUfConstruccionDTO).subscribe((data: any) => {
        this.crrEtapaEjecutadaUfConstruccionDTO.id = data.resultado.id;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    if (this.crrEtapaEjecutadaUfOperYMantDTO.fechaInicioEtapa != null) {
      this.crrEtapaEjecutadaUfOperYMantDTO.idEtapa = this.ETAPA_OPERACION_Y_MANTENIMIENTO;
      this.crrEtapaEjecutadaUfOperYMantDTO.idUnidadFuncional = this.unidadFuncionalSeleccionada.idUnidadFuncional;

      this.ejecucionProyectoService.srvGuardarEtapaEjecutadaUf(this.crrEtapaEjecutadaUfOperYMantDTO).subscribe((data: any) => {
        this.crrEtapaEjecutadaUfOperYMantDTO.id = data.resultado.id;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    this.lanzarMensajeInfo('Se guardaron las fechas correctamente');
  }

  outputIntervencionSeleccionada(intervencionSeleccionadaL) {
    this.vistaActual = 'intervencion';
    this.intervencionDTOSeleccionada = intervencionSeleccionadaL;
    this.intervencionDTOSeleccionada.nombreIntervencionUf = intervencionSeleccionadaL.nombreIntUf;
    this.crrEtapaEjecutadaIntervencionUfPreconsDTO = {};
    this.crrEtapaEjecutadaIntervencionUfConstruccionDTO = {};
    this.crrEtapaEjecutadaIntervencionUfOperYMantDTO = {};
    this.listarEtapasIntervencion();
  }

  outputYaCargaronIntervenciones(todasAl100L: boolean) {
    this.disabledFechaFinUnidadFuncional = !todasAl100L;
  }

  outputYaCargaronIntervencionesConstruccion(todasAl100L: boolean) {
    this.disabledFechaFinUnidadFuncionalConstruccion = !todasAl100L;
  }

  listarEtapasIntervencion() {
    forkJoin(
      this.ejecucionProyectoService.srvListarEtapaEjecutadaIntervencionUf(this.intervencionDTOSeleccionada.idIntervencionUf, this.ETAPA_PRECONSTRUCCION),
      this.ejecucionProyectoService.srvListarEtapaEjecutadaIntervencionUf(this.intervencionDTOSeleccionada.idIntervencionUf, this.ETAPA_CONSTRUCCION),
      this.ejecucionProyectoService.srvListarEtapaEjecutadaIntervencionUf(this.intervencionDTOSeleccionada.idIntervencionUf, this.ETAPA_OPERACION_Y_MANTENIMIENTO),
    ).subscribe(([preconstruccionDataList, construccionDataList, operacionYMantDataList]) => {
      if (preconstruccionDataList != null && preconstruccionDataList.length > 0) {
        this.crrEtapaEjecutadaIntervencionUfPreconsDTO = preconstruccionDataList[0];
        if (this.crrEtapaEjecutadaIntervencionUfPreconsDTO.fechaInicioEtapa != null) {
          this.crrEtapaEjecutadaIntervencionUfPreconsDTO.fechaInicioEtapa = new Date(this.crrEtapaEjecutadaIntervencionUfPreconsDTO.fechaInicioEtapa);
        }
        if (this.crrEtapaEjecutadaIntervencionUfPreconsDTO.fechaFinEtapa != null) {
          this.crrEtapaEjecutadaIntervencionUfPreconsDTO.fechaFinEtapa = new Date(this.crrEtapaEjecutadaIntervencionUfPreconsDTO.fechaFinEtapa);
        }
      }

      //PARA LAS INTERVENCIONES LA ETAPAS DE PRECONSTRUCCION ES LA MISMA QUE LA DE LA UNIDAD FUNCIONAL
      if (this.crrEtapaEjecutadaUfPreconsDTO != null) {
        this.crrEtapaEjecutadaIntervencionUfPreconsDTO.fechaInicioEtapa = this.crrEtapaEjecutadaUfPreconsDTO.fechaInicioEtapa;
        this.crrEtapaEjecutadaIntervencionUfPreconsDTO.fechaFinEtapa = this.crrEtapaEjecutadaUfPreconsDTO.fechaFinEtapa;
      }


      if (construccionDataList != null && construccionDataList.length > 0) {
        this.crrEtapaEjecutadaIntervencionUfConstruccionDTO = construccionDataList[0];
        if (this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.fechaInicioEtapa != null) {
          this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.fechaInicioEtapa = new Date(this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.fechaInicioEtapa);
        }
        if (this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.fechaFinEtapa != null) {
          this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.fechaFinEtapa = new Date(this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.fechaFinEtapa);
        }
      }

      if (operacionYMantDataList != null && operacionYMantDataList.length > 0) {
        this.crrEtapaEjecutadaIntervencionUfOperYMantDTO = operacionYMantDataList[0];
        if (this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.fechaInicioEtapa != null) {
          this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.fechaInicioEtapa = new Date(this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.fechaInicioEtapa);
        }
        if (this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.fechaFinEtapa != null) {
          this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.fechaFinEtapa = new Date(this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.fechaFinEtapa);
        }
      }
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }


  guardarEtapasIntervencion(forma: NgForm) {

    if (this.crrEtapaEjecutadaIntervencionUfPreconsDTO.fechaInicioEtapa != null) {
      this.crrEtapaEjecutadaIntervencionUfPreconsDTO.idEtapa = this.ETAPA_PRECONSTRUCCION;
      this.crrEtapaEjecutadaIntervencionUfPreconsDTO.idIntervencionUf = this.intervencionDTOSeleccionada.idIntervencionUf;
      this.ejecucionProyectoService.srvGuardarEtapaEjecutadaIntervencionUf(this.crrEtapaEjecutadaIntervencionUfPreconsDTO).subscribe((data: any) => {
        this.crrEtapaEjecutadaIntervencionUfPreconsDTO.id = data.resultado.id;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    if (this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.fechaInicioEtapa != null) {
      this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.idEtapa = this.ETAPA_CONSTRUCCION;
      this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.idIntervencionUf = this.intervencionDTOSeleccionada.idIntervencionUf;
      this.ejecucionProyectoService.srvGuardarEtapaEjecutadaIntervencionUf(this.crrEtapaEjecutadaIntervencionUfConstruccionDTO).subscribe((data: any) => {
        this.crrEtapaEjecutadaIntervencionUfConstruccionDTO.id = data.resultado.id;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    if (this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.fechaInicioEtapa != null) {
      this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.idEtapa = this.ETAPA_OPERACION_Y_MANTENIMIENTO;
      this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.idIntervencionUf = this.intervencionDTOSeleccionada.idIntervencionUf;
      this.ejecucionProyectoService.srvGuardarEtapaEjecutadaIntervencionUf(this.crrEtapaEjecutadaIntervencionUfOperYMantDTO).subscribe((data: any) => {
        this.crrEtapaEjecutadaIntervencionUfOperYMantDTO.id = data.resultado.id;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    }

    this.lanzarMensajeInfo('Se guardaron las fechas correctamente');

  }

  irAProyecto() {
    this.vistaActual = 'proyecto';
  }

  irAUnidadFuncional() {
    this.vistaActual = 'unidadFuncional';
  }

  volver() {
    this.OutputIrAMenuEtapas.emit("");
  }

  ///////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
  }

  lanzarMensajeError(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
  }

  lanzarMensajeWarning(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
