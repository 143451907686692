import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  Inject,
} from "@angular/core";
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";

import { loadModules } from "esri-loader";

import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import {
  CrrInformacionProyectoDTO,
  CrrInformacionGeneralProyectoDTO,
} from "src/app/reportes/carreteros/reportes-carreteros.dto";
import { formatDate } from "@angular/common";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";

@Component({
  selector: "app-rpt-crr-proyecto-detalle",
  templateUrl: "./rpt-crr-proyecto-detalle.component.html",
  styleUrls: [
    "./rpt-crr-proyecto-detalle.component.sass",
    "../reportes-carreteros.css",
  ],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate(300)]),
      transition(":leave", [animate(500)]),
    ]),
  ],
})
export class RptCrrProyectoDetalleComponent implements OnInit {
  sticky: boolean = false;
  elementPosition: any;
  private isAuthenticated: boolean;
  basicData: any;
  dataSource: any;
  stackedOptions: any;
  horizontalOptions: any;
  multiAxisOptions: any;
  value: number = 1;
  informacionGeneralCompleta: CrrInformacionGeneralProyectoDTO[] = [];
  informacionGeneral: CrrInformacionGeneralProyectoDTO;
  idProyecto: number;
  blockedDocument = false;
  fechaIndexacion: any;
  fechaUltimoReporte: any;
  nombreProyecto: any;
  etapaProyecto: any;
  idEtapaProyecto: any;
  constructor(
    @Inject(DOCUMENT) document,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent,
    private ruta: ActivatedRoute,
    private adalSvc: MsAdalAngular6Service,
    private route: Router
  ) {
    this.basicData = {
      labels: [
        "Construcción",
        "Pre-Construcción",
        "Operación",
        "Normalización",
      ],
      datasets: [
        {
          label: "Proyectos",
          backgroundColor: ["#1C74C0", "#229D8E", "#C14133", "#FA6581"],

          data: [22, 4, 3, 13],
        },
      ],
    };

    this.multiAxisOptions = {
      plugins: {
        legend: {
          labels: {
            color: "#ebedef",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(255,255,255,0.2)",
          },
        },
        y: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(255,255,255,0.2)",
          },
        },
      },
    };
  }

  showVideo = true;
  public YT: any;
  public video: any;
  public playList: any;
  public player: any;
  public reframed: Boolean = false;

  isRestricted = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  init() {
    // Return if Player is already created
    if (window["YT"]) {
      this.startVideo();
      return;
    }

    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    /* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
    window["onYouTubeIframeAPIReady"] = () => this.startVideo();
  }

  ngOnInit(): void {
    this.isAuthenticated = this.adalSvc.isAuthenticated;
    console.log("inicio");
    this.blockedDocument = true;
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params.id;
    });

    this.reportesCarreteroService.getinformacionGeneralProyectos().subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.informacionGeneralCompleta = dato.proyectos;

            this.informacionGeneral = this.informacionGeneralCompleta.filter(
              (proyecto) => proyecto.id == this.idProyecto
            )[0];

            this.video = this.informacionGeneral.videoId;
            this.playList = this.informacionGeneral.playList;

            this.init();
            this.nombreProyecto = this.informacionGeneral.proyecto;
            this.etapaProyecto = this.informacionGeneral.etapaProyecto;
            this.idEtapaProyecto = this.informacionGeneral.idEtapaProyecto;

            if (this.informacionGeneral.fechaIndexacionCapexOpex != null) {
              this.fechaIndexacion = formatDate(
                this.informacionGeneral.fechaIndexacionCapexOpex,
                "dd/MM/yyyy",
                "es-ES"
              );
            }

            if (this.informacionGeneral.fechaUltimoReporteAvance != null) {
              this.fechaUltimoReporte = formatDate(
                this.informacionGeneral.fechaUltimoReporteAvance,
                "dd/MM/yyyy",
                "es-ES"
              );
            }
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
        this.blockedDocument = false;
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  startVideo() {
    this.reframed = false;
    this.player = new window["YT"].Player("player", {
      videoId: this.video,
      playerVars: {
        autoplay: 1,
        loop: 1,
        modestbranding: 0,
        controls: 0,
        autohide: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playlist: this.video,
      },
      events: {
        //'onStateChange': this.onPlayerStateChange.bind(this),
        onError: this.onPlayerError.bind(this),
        onReady: this.onPlayerReady.bind(this),
      },
    });
  }

  onPlayerReady(event) {
    if (this.isRestricted) {
      event.target.mute();
      event.target.playVideo();
    } else {
      event.target.playVideo();
    }
  }

  onPlayerError(event) {
    switch (event.data) {
      case 2:
        console.log("" + this.video);
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }

  scrollToBottom = (node) => {
    node.scrollTop = node.scrollHeight;
  };

  @HostListener("window:scroll", ["$event"])
  // no logueo
  onWindowScroll(e) {
    if (!this.isAuthenticated) {
      if (window.pageYOffset > 350) {
        let element = document.getElementById("header2");
        element.classList.add("sticky");
      } else {
        let element = document.getElementById("header2");
        element.classList.remove("sticky");
      }

      var divavances = document.getElementById("lagtitle");
      var yPos = divavances.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 120) {
        console.log("divavances" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }

        let menu_avances = document.getElementById("menu_general");
        menu_avances.classList.add("active");
      }

      var divavances1 = document.getElementById("encabezado");
      var yPos = divavances1.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divavances" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }

        let menu_avances = document.getElementById("menu_avances");
        menu_avances.classList.add("active");
      }

      var divinversion = document.getElementById("avances");
      var yPos = divinversion.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divinversion" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_inversion = document.getElementById("menu_inversion");
        menu_inversion.classList.add("active");
      }

      var divinversion2 = document.getElementById("inversion");
      var yPos = divinversion2.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divinversion" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_inversion2 = document.getElementById("menu_financiero");
        menu_inversion2.classList.add("active");
      }

      var divpeaje = document.getElementById("financiero");
      var yPos = divpeaje.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divpeaje" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_peajes = document.getElementById("menu_peajes");
        menu_peajes.classList.add("active");
      }

      var divaccionistas = document.getElementById("peaje");
      var yPos = divaccionistas.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divaccionistas" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_accionistas = document.getElementById("menu_accionistas");
        menu_accionistas.classList.add("active");
      }
    } else {
      if (window.pageYOffset > 350) {
        let element = document.getElementById("header2");
        element.classList.add("sticky");
      } else {
        let element = document.getElementById("header2");
        element.classList.remove("sticky");
      }

      var divavances = document.getElementById("lagtitle");
      var yPos = divavances.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 120) {
        console.log("divavances" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }

        let menu_avances = document.getElementById("menu_general");
        menu_avances.classList.add("active");
      }

      var divavances = document.getElementById("encabezado");
      var yPos = divavances.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divavances" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }

        let menu_avances = document.getElementById("menu_avances");
        menu_avances.classList.add("active");
      }
      var divinversion = document.getElementById("avances");
      var yPos = divinversion.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divinversion" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_inversion = document.getElementById("menu_inversion");
        menu_inversion.classList.add("active");
      }

      //financiero
      var divinversion2 = document.getElementById("inversion");
      var yPos = divinversion2.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divinversion" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_inversion2 = document.getElementById("menu_financiero");
        menu_inversion2.classList.add("active");
      }

      var divpeaje = document.getElementById("financiero");
      var yPos = divpeaje.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divpeaje" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_peajes = document.getElementById("menu_peajes");
        menu_peajes.classList.add("active");
      }

      var divaccionistas = document.getElementById("peaje");
      var yPos = divaccionistas.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divaccionistas" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_accionistas = document.getElementById("menu_accionistas");
        menu_accionistas.classList.add("active");
      }
      var divpredial = document.getElementById("accionistas");
      var yPos = divpredial.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divpredial" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_predial = document.getElementById("menu_predial");
        menu_predial.classList.add("active");
      }
      var divtemasgestion = document.getElementById("predial");
      var yPos = divtemasgestion.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divtemasgestion" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_gestion = document.getElementById("menu_gestion");
        menu_gestion.classList.add("active");
      }
      var divmetas = document.getElementById("temasgestion");
      var yPos = divmetas.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divmetas" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_metas = document.getElementById("menu_metas");
        menu_metas.classList.add("active");
      }
      var divvideos = document.getElementById("metas");
      var yPos = divvideos.getBoundingClientRect().bottom;
      if (Math.abs(yPos) < 130) {
        console.log("divvideos" + yPos);
        var botonesactive = document.getElementsByClassName("btn_menulateral"); //
        for (var i = 0, all = botonesactive.length; i < all; i++) {
          botonesactive[i].classList.remove("active");
        }
        let menu_videos = document.getElementById("menu_videos");
        menu_videos.classList.add("active");
      }
    }
  }
  // si logueo
  /* onWindowScroll(e) {
  
  } */
}
