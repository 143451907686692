import { Injectable } from "@angular/core";
import { ConfigService } from "../config.service";
import { Observable, throwError } from "rxjs";
import { map, retry, catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import {
  InvAsignacionDTO,
  InvBienDTO,
  InvModeloDTO,
  VwInvBienAniYpersonalRpt,
} from "./inventario-equipos.dto";
import { TrnRespuestaServicio } from "../parametricas/trn-respuesta-servicio";

@Injectable({
  providedIn: "root",
})
export class InventarioEquiposService {
  private mensajeCompartidoSource = new BehaviorSubject("default message");
  mensajeCompartido = this.mensajeCompartidoSource.asObservable();

  public END_POINT = "";

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  intercambiarMensaje(mensaje: string) {
    this.mensajeCompartidoSource.next(mensaje);
  }

  public srvGuardarModelo(
    invModeloDTO: InvModeloDTO
  ): Observable<RespuestaGenericaRpt> {
    console.log(invModeloDTO);
    let url = this.END_POINT + "/InvModelo/guardarModelo";
    return this.http.post<RespuestaGenericaRpt>(url, invModeloDTO).pipe(
      map((data) => {
        return data;
      }),
      retry(1));
  }

  public srvListarModelo(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvModelo/listarModelos")
      .pipe(retry(1));
  }

  public srvEliminarModelo(invModeloDTO: InvModeloDTO): Observable<RespuestaGenericaRpt> {
    console.log(invModeloDTO);
    let url = this.END_POINT + "/InvModelo/eliminarModelo/" + invModeloDTO.id;
    return this.http.put<RespuestaGenericaRpt>(url, invModeloDTO).pipe(
      map((data) => {
        return data;
      }),
      retry(1));
  }

  public srvListarTipoBien(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvMaTipoBien/")
      .pipe(retry(1));
  }

  public srvListarMarca(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvMaMarca/")
      .pipe(retry(1));
  }

  public srvListarSistemaOperativo(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvMaSistemaOperativo/")
      .pipe(retry(1));
  }

  public srvListarTipoAsignacion(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvMaTipoAsignacion/")
      .pipe(retry(1));
  }

  public srvListarBien(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvBien/listarBienes")
      .pipe(retry(1));
  }

  public srvObtenerBienPorModelo(idModelo: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvBien/obtenerBienPorModelo/" + idModelo)
      .pipe(retry(1));
  }

  public srvListarAsignaciones(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvBien/listarAsignaciones")
      .pipe(retry(1));
  }

  public srvListarAsignacionesPorPersona(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvBien/listarAsignacionesPorPersona")
      .pipe(retry(1));
  }

  public srvListarAsignacionesPorPersonaConfirmaRecibido(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvBien/listarAsignacionesPorPersonaConfirmaRecibido")
      .pipe(retry(1));
  }

  public srvGuardarBien(
    invBienDTO: InvBienDTO
  ): Observable<RespuestaGenericaRpt> {
    console.log(invBienDTO);
    let url = this.END_POINT + "/InvBien/guardarBien";
    return this.http.post<RespuestaGenericaRpt>(url, invBienDTO).pipe(
      map((data) => {
        return data;
      }),
      retry(1));
  }

  public srvEliminarBien(invBienDTO: InvBienDTO): Observable<RespuestaGenericaRpt> {
    console.log(invBienDTO);
    let url = this.END_POINT + "/InvBien/eliminarBien/" + invBienDTO.id;
    return this.http.put<RespuestaGenericaRpt>(url, invBienDTO).pipe(
      map((data) => {
        return data;
      }),
      retry(1));
  }

  public srvGuardarRegistroAsignacion(
    invASignacionDTO: InvAsignacionDTO
  ): Observable<RespuestaGenericaRpt> {
    console.log(invASignacionDTO);
    let url = this.END_POINT + "/InvBien/guardarRegistroAsignacion";
    return this.http.post<RespuestaGenericaRpt>(url, invASignacionDTO).pipe(
      map((data) => {
        return data;
      }),
      retry(1));
  }

  public srvGuardarAsignacionBien(
    invASignacionDTO: InvAsignacionDTO
  ): Observable<RespuestaGenericaRpt> {
    console.log(invASignacionDTO);
    let url = this.END_POINT + "/InvBien/guardarAsignacionBien";
    return this.http.post<RespuestaGenericaRpt>(url, invASignacionDTO).pipe(
      map((data) => {
        return data;
      }),
      retry(1));
  }


  public srvObtenerAsignacionPorBien(idBien: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/InvBien/obtenerAsignacionPorBien/" + idBien)
      .pipe(retry(1));
  }

  public srvAnularAsignacionBien(idBien: number): Observable<any> {
    return this.http
      .delete(this.END_POINT + "/InvBien/anularAsignacion/" + idBien)
      .pipe(retry(1));
  }

  public srvGeneracionPdfInventario(idAsignacion: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/GeneracionPdfInventario/generacionPdfInventario/" + idAsignacion, { responseType: 'text' })
      .pipe(retry(1));
  }

  /**
   * Consulta los bienes asignados ANI y Personales para ser 
   */
  public srvConsultarBienesAniYPersonal(tipoPertenencia: string): Observable<VwInvBienAniYpersonalRpt> {
    let urlEndpoint = this.END_POINT + '/InvBien/ConsultarBienesAniYPersonal?';
    if (tipoPertenencia != null) {
      urlEndpoint = urlEndpoint + '&tipoPertenencia=' + tipoPertenencia;
    }
    return this.http.get<VwInvBienAniYpersonalRpt>(urlEndpoint)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  
  /**
   * Consulta los bienes asignados ANI y Personales para ser 
   */
   public srvConsultarBienesAniYPersonalPorPersona(idPersona:number, tipoPertenencia: string): Observable<VwInvBienAniYpersonalRpt> {
    let urlEndpoint = this.END_POINT + '/InvBien/ConsultarBienesAniYPersonalPorPersona?';
    if (idPersona != null) {
      urlEndpoint = urlEndpoint + '&idPersona=' + idPersona;
    }
    if (tipoPertenencia != null) {
      urlEndpoint = urlEndpoint + '&tipoPertenencia=' + tipoPertenencia;
    }
    return this.http.get<VwInvBienAniYpersonalRpt>(urlEndpoint)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }
}

export class RespuestaGenericaRpt {
  constructor(public respuestaServicio?: TrnRespuestaServicio) { }
}
