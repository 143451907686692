import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/config.service';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';
import { CrrConfiguracionProyecto } from './informacion-general-carretero.dto';




@Injectable({
    providedIn: 'root'
})
export class InformacionGeneralCarreteroService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }


    public getProyectoPorId(id) {
        return this.http.get(this.END_POINT + '/TrnProyecto/' + id)
            .pipe(
                retry(1));
    }

    public getObtenerConfiguracionActualProyecto(idProyecto): Observable<ConsultaCrrConfiguracionProyectoRpt> {
        return this.http.get<ConsultaCrrConfiguracionProyectoRpt>(this.END_POINT + '/CrrInformacionGeneralProyecto/obtenerConfiguracionActualPorProyecto/' + idProyecto)
            .pipe(
                retry(1));
    }



    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }



}


export class ConsultaCrrConfiguracionProyectoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public configuracionProyecto?: CrrConfiguracionProyecto
    ) { }
}

