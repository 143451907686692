import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProyectoDTO } from '../componentes-comunes/componentes-comunes.dto';
import { Router, ActivatedRoute } from '@angular/router';
import { ComponentesComunesService } from '../componentes-comunes/componentes-comunes.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { CovidReportarReinicioObraService, ConsultaReinicioObraRpt } from './covid-reportar-reinicio-obra.service';
import { DisableLinkADirectiveDirective } from '../disable-link-a-directive.directive';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-covid-reportar',
  templateUrl: './covid-reportar.component.html',
  styleUrls: ['./covid-reportar.component.sass']
})
export class CovidReportarComponent implements OnInit {

  idProyecto: number;

  proyectoSeleccionado: ProyectoDTO;

  reinicioDeObraPublicado: Boolean;

  requiereProtocoloBioseguridad: Boolean;

  visibleDialogoProteccionDatos: boolean = false;

  constructor(private router: Router, private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private covidReportarReinicioObraService: CovidReportarReinicioObraService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params['id'];
      forkJoin(
        this.componentesComunesService.obtenerProyecto(this.idProyecto),
        this.covidReportarReinicioObraService.srvConsultarReinicioObra(this.idProyecto)
      ).subscribe(([data1, data2]) => {
        this.proyectoSeleccionado = data1;
        if (data2.c19ReinicioObraList != null && data2.c19ReinicioObraList.length > 0) {
          this.reinicioDeObraPublicado = data2.c19ReinicioObraList[0].publicado;
          this.requiereProtocoloBioseguridad = data2.c19ReinicioObraList[0].requiereProtocolo;
        } else {
          this.reinicioDeObraPublicado = false;
          this.requiereProtocoloBioseguridad = false;
        }

      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    });
  }



  irAReinicioObra() {
    this.router.navigate([this.router.url + '/covid-reportar-reinicio-obra', this.idProyecto])
  }

  irASeguimiento() {
    if (this.reinicioDeObraPublicado) {
      if (this.requiereProtocoloBioseguridad) {
        //Mostrar dialogo de proteccion de datos
        this.visibleDialogoProteccionDatos = true;
      } else {
        this.messageService.add({ severity: 'info', summary: 'Información', detail: 'No es necesario reportar ya que el proyecto no requiere Protocolo de Bioseguridad', life: 10000 });
      }
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debe publicar el Protocolo de Bioseguridad', life: 10000 });
    }

  }

  aceptarProteccionDatos(articulacionForm: NgForm) {
    this.router.navigate([this.router.url + '/covid-reportar-seguimiento', this.idProyecto])
    this.visibleDialogoProteccionDatos = false;
    articulacionForm.resetForm();
  }

  cancelarProteccionDatos(articulacionForm: NgForm) {
    this.visibleDialogoProteccionDatos = false;
    articulacionForm.resetForm();
  }

  irAReportesCovid() {
    this.router.navigate([this.router.url + '/covid-reportes-interno', this.idProyecto]);
  }



}
