import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrsFuncionario } from 'src/app/admin-personas/prs-funcionario';
import { TrnPersona } from 'src/app/admin-personas/trn-persona';
import { EnumCmsTipoAnexo, EnumCmsConstantes, EnumCmsMaTipoModificacionComision } from 'src/app/comisiones/comisiones-constantes';
import { CmsComisionDTO, CmsComisionLegalizacionDTO, VwCmsAnexoComisionDTO, VwCmsTiqueteAereoDTO, CmsTiqueteAereoDTO, CmsRpDTO, CmsAnexoComisionDTO, CmsModosDTO } from 'src/app/comisiones/comisiones.dto';
import { ComisionesService } from 'src/app/comisiones/comisiones.service';
import { EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-comisiones-reporte-detalle-registro-versiones',
  templateUrl: './comisiones-reporte-detalle-registro-versiones.component.html',
  styleUrls: ['./comisiones-reporte-detalle-registro-versiones.component.sass']
})
export class ComisionesReporteDetalleRegistroVersionesComponent implements OnInit {


  blockedDocument = true;

  //Informacion comision
  idComision: number;
  idComisionVersion: number;
  idAsignacion: number;

  comision: CmsComisionDTO;
  persona: TrnPersona;
  detalleFuncionario: PrsFuncionario;
  legalizacion: CmsComisionLegalizacionDTO;
  anexosConsolidado: VwCmsAnexoComisionDTO[];
  anexosCancelacion: VwCmsAnexoComisionDTO[];
  nombreTabLiquidacion: string;

  itinerarioAereoList: VwCmsTiqueteAereoDTO[];
  observacionesList: VwCmsTiqueteAereoDTO[];
  dialogoObservacionesTiquete: boolean;
  tiqueteSeleccionado: CmsTiqueteAereoDTO;

  rpAsociadosList: CmsRpDTO[];
  totalValorRpComision = 0;

  anexoGestionPresupuestal: CmsAnexoComisionDTO;
  visibleAnexosGestionPresupuestal = false;

  anexoComisionObligacionPago: CmsAnexoComisionDTO;

  visibleSubirAnexoDialog = false;
  visibleAdjuntosDialog = false;
  visibleConfirmarEnviarDialogo = false;

  anexoComisionCertificadoPago: CmsAnexoComisionDTO;
  adjuntosComponente: any = [];

  rutaCloudStorageComisionesGestionPresupuestal: string;

  habilitarTabLegalizacion = false;
  habilitarTabGestionPresupuestal = false;
  habilitarTabObligacionPago = false;
  habilitarTabCertificadoPago = false;

  constructor(
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService) {
  }

  ngOnInit() {

    this.blockedDocument = true;

    this.comision = {};
    this.comision.viaticos = {};
    this.legalizacion = {};
    this.persona = {};
    this.detalleFuncionario = {};

    // Obtener parametros url
    const idComisionParam = "idComision";
    const idComisionVersionParam = "idComisionVersion";
    const idAsignacionParam = "idAsignacion";

    this.ruta.params.subscribe(params => {
      this.idComision = Number(params[idComisionParam]);
      this.idComisionVersion = Number(params[idComisionVersionParam]);
      this.idAsignacion = Number(params[idAsignacionParam]);
    });

    this.obtenerComisionPorId(this.idComision, this.idComisionVersion);
    this.tiqueteSeleccionado = {};


  }

  obtenerComisionPorId(idComision: number, idComisionVersion: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvObtenerComisionPorIdComisionIdVersion(idComision, idComisionVersion)
    ).subscribe(
      ([comisionData]) => {

        this.comision = comisionData.comisionDetalle;

        if (this.comision !== null) {
          if (this.comision.fechaSalida != null) {
            this.comision.fechaSalida = new Date(this.comision.fechaSalida);
          }
          if (this.comision.fechaRegreso != null) {
            this.comision.fechaRegreso = new Date(this.comision.fechaRegreso);
          }

          if (this.comision.esContratista) {
            this.persona = {};
            this.detalleFuncionario = {};
          } else {
            this.persona = {};
            this.detalleFuncionario = {};
            this.cargarPersona(this.comision.idPersona);
          }

          this.comision.objeto = this.comision.objeto.replace('<p>', '').replace('</p>', '');

          if (this.comision.valorTentativoTerrestre === 0) {
            this.comision.valorTentativoTerrestre = null;
          }
          if (this.comision.valorTentativoFluvial === 0) {
            this.comision.valorTentativoFluvial = null;
          }
          if (this.comision.valorTentativoAereo === 0) {
            this.comision.valorTentativoAereo = null;
          }

          if (this.comision.itinerarioAereoLista !== null && this.comision.itinerarioAereoLista.length > 0) {
            this.comision.itinerarioAereoLista.forEach(itAer => {
              if (itAer.fechaVuelo != null) {
                itAer.fechaVuelo = new Date(itAer.fechaVuelo);
              }
            });
          }

          //Actualizamos la variable de sumatoria de modos para visualizarlo en el Datatable
          this.actualizarSumatoriaModos();

          if (
            (this.comision.idPasoActual >= EnumCmsConstantes.CMS_PASO_COMISION_APROBADO
              || this.comision.idPasoActual >= EnumCmsConstantes.CMS_PASO_COMISION_REVISION_LEGALIZACION)
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_CANCELADA_GESTIONADA_VAF
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_CANCELADA
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DESISTIDA) {
            this.habilitarTabLegalizacion = true;
          }


          if (this.comision.idPasoActual >= EnumCmsConstantes.CMS_PASO_COMISION_REVISION_LEGALIZACION
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES) {
            this.obtenerLegalizacionPorComision(idComision);
          }

          if (this.comision.idPasoActual >= EnumCmsConstantes.CMS_PASO_COMISION_APROBADO
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES_LEGALIZACION
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DESISTIDA) {
            this.habilitarTabGestionPresupuestal = true;
            this.cargarRpAsociados(this.idComisionVersion);
          } else {
            if (this.comision.idTipoModificacion == EnumCmsMaTipoModificacionComision.CMS_TIPO_MODIFICACION_AMPLIACION
              || this.comision.idTipoModificacion == EnumCmsMaTipoModificacionComision.CMS_TIPO_MODIFICACION_REDUCCION) {
              this.habilitarTabGestionPresupuestal = true;
              this.cargarRpAsociados(this.idComisionVersion);
            }
          }

          if (this.comision.idPasoActual >= EnumCmsConstantes.CMS_PASO_COMISION_REVISION_CENTRAL_PAGOS
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES_LEGALIZACION
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_CANCELADA_GESTIONADA_VAF
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_CANCELADA
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DESISTIDA) {
            this.habilitarTabObligacionPago = true;
            this.cargarAnexoObligacionPago(this.idComisionVersion, EnumCmsTipoAnexo.REGISTRO_PRESUPUESTAL_OBLIGACION_PAGO_ANI);
          }


          if (this.comision.idPasoActual >= EnumCmsConstantes.CMS_PASO_COMISION_APROBADO_PARA_PAGO
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DEVULETO_OBSERVACIONES_LEGALIZACION
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_CANCELADA_GESTIONADA_VAF
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_CANCELADA
            && this.comision.idPasoActual != EnumCmsConstantes.CMS_PASO_COMISION_DESISTIDA) {
            this.habilitarTabCertificadoPago = true;
            this.cargarAnexoCertificadoPago(this.idComisionVersion, EnumCmsTipoAnexo.CERTIFICADO_PAGO_ANI);
          }
          if (this.comision.idPasoActual == EnumCmsConstantes.CMS_PASO_COMISION_CANCELADA_GESTIONADA_VAF) {
            this.listarAnexosPorCancelar(this.comision.idComisionVersion);
          }
          this.cargarItinerarios();
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }


  cargarPersona(idPersona) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarFuncionarioComisionPorIdPersona(idPersona)
    ).subscribe(
      ([
        funcionarioData
      ]) => {
        this.persona.id = funcionarioData.configuracionFuncionario.id;
        this.persona.nombre = funcionarioData.configuracionFuncionario.nombre;
        this.persona.numeroDocumento = funcionarioData.configuracionFuncionario.numeroDocumento;
        this.persona.correoElectronicoAni = funcionarioData.configuracionFuncionario.correoElectronico;
        this.detalleFuncionario.honorarios = funcionarioData.configuracionFuncionario.ingresos;
        this.detalleFuncionario.dependenciaPrimerNivel = funcionarioData.configuracionFuncionario.dependenciaPrimerNivel;
        this.detalleFuncionario.idDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.idDependenciaPrimerNivel;
        this.detalleFuncionario.liderDependenciaPrimerNivel = funcionarioData.configuracionFuncionario.liderDependenciaPrimerNivel;
        this.detalleFuncionario.dependenciaEspecifica = funcionarioData.configuracionFuncionario.dependencia;
        this.detalleFuncionario.idDependenciaEspecifica = funcionarioData.configuracionFuncionario.idDependencia;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }


  /**
* Metodo para actualizar la variable global que muestra el sumatoria de modos
*/
  actualizarSumatoriaModos() {
    if (Array.isArray(this.comision.modoLista) && this.comision.modoLista.length) {
      return this.comision.modoLista.reduce<number>((sum: number, modoVar: CmsModosDTO) => {
        return Number(sum + Number(modoVar.porcentajeRecursos));
      }, Number(0));
    }
  }


  //Obtiene los anexos cargados para justificar la cancelacion
  listarAnexosPorCancelar(idComisionVersion) {
    forkJoin(
      this.comisionesService.srvConsultarAnexoAdjuntosConsolidado(idComisionVersion, EnumCmsTipoAnexo.SUBTIPO_GESTION_CANCELACION_ANEXOS, false)
    ).subscribe(
      ([comisionesPorLegalizarData]) => {
        this.anexosCancelacion = comisionesPorLegalizarData.adjuntosAnexosConsolidado;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  obtenerLegalizacionPorComision(idComision: number) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvConsultarLegalizacionPorComision(idComision)
    ).subscribe(
      ([legalizacionData]) => {
        this.legalizacion = legalizacionData.legalizacion;
        this.listarAnexosPorLegalizar(this.legalizacion.idComisionVersion);
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  listarAnexosPorLegalizar(idComisionVersion) {
    forkJoin(
      this.comisionesService.srvConsultarAnexoAdjuntosConsolidado(idComisionVersion, EnumCmsTipoAnexo.SUBTIPO_GESTION_LEGALIZACION_ANEXOS + ',' + EnumCmsTipoAnexo.SUBTIPO_GESTION_TIQUETES, false)
    ).subscribe(
      ([comisionesPorLegalizarData]) => {
        this.anexosConsolidado = comisionesPorLegalizarData.adjuntosAnexosConsolidado;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  cargarItinerarios() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarTiquetesSolicitadosPorIdComision(this.idComision, this.idComisionVersion)
    ).subscribe(
      ([
        tiquetesData
      ]) => {
        this.itinerarioAereoList = tiquetesData.transporteAereoLista;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  irVerObservaciones(tiquete: VwCmsTiqueteAereoDTO) {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarObservacionesPorIdTiquete(tiquete.idTiqueteAereo)
    ).subscribe(
      ([
        observacionesData
      ]) => {
        this.observacionesList = observacionesData.tiquetes;
        this.dialogoObservacionesTiquete = true;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarAnexoGestionPresupuestal(idTipoAnexo: number) {
    this.visibleAnexosGestionPresupuestal = true;
    this.comisionesService.srvConsultarAnexoPorTipoAnexo(this.idComisionVersion, idTipoAnexo, false)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              if (result.anexoComision != null) {
                this.anexoGestionPresupuestal = result.anexoComision;
              }
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {

          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarRpAsociados(idComisionVersion: number) {
    this.comisionesService.srvConsultarListadoRpComision(idComisionVersion).subscribe(
      result => {
        if (result != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.rpAsociadosList = result.rpComisionList;
            this.totalValorRpComision = 0;
            if (this.rpAsociadosList != null && this.rpAsociadosList.length > 0) {
              this.totalValorRpComision = this.rpAsociadosList.map(rp => rp.valorPagado).reduce((prev, next) => prev + next);
            }
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  cargarAnexoObligacionPago(idComisionVersion: number, idTipoAnexo: number) {
    this.comisionesService.srvConsultarAnexoPorTipoAnexo(idComisionVersion, idTipoAnexo, false)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              if (result.anexoComision != null) {
                this.anexoComisionObligacionPago = result.anexoComision;
              }
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {

          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarAnexoCertificadoPago(idComisionVersion: number, idTipoAnexo: number) {
    this.comisionesService.srvConsultarAnexoPorTipoAnexo(idComisionVersion, idTipoAnexo, false)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              if (result.anexoComision != null) {
                this.anexoComisionCertificadoPago = result.anexoComision;
              }
            } else {
              this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {

          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );

  }

  bntVolver() {
    const ruta = "/comisiones/comisiones-reportes-home/comisiones-reporte-detallado-versiones";
    this.router.navigate([ruta]);
  }


  // // // // /Mensajes// // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

  get EnumCmsTipoAnexo() {
    return EnumCmsTipoAnexo;
  }

}
