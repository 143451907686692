import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { DatePipe } from '@angular/common';
import { EnumFechaFormato, EnumRegex } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCtoEstadoContrato } from 'src/app/contratacion/constantes/contratacion-constantes';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { CtoContratoDTO } from 'src/app/contratacion/contratacion-dto/contrato';
import { EnumRespuestaServicio } from '../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ContratacionContratoPublicadoService } from '../contratacion-pub.service';

@Component({
  selector: 'app-contratos-publicados',
  templateUrl: './contratos-publicados.component.html',
  styles: []
})
export class ContratosPublicadosComponent implements OnInit {

  contratosPublicados: CtoContratoInformacionVigenteEncabezadoDTO[];
  totalRecords: any;
  blockedDocument = false;
  contrato: CtoContratoDTO;

  // Filtros
  buscarFiltro: string;

  msgRecuerdeNoModificaContraoConPago = 'Recuerde que solo podrá modificar los datos iniciales de los contratos que aún no tengan un pago asociado o no tengan una radicación de pago en trámite. Si el contrato tiene registrado una o más novedades estas se deberán registrar nuevamente';
  visibleDialogoConfirmarAnularPublicacion = false;
  visibleDialogoErrorAnularPublicacion = false;
  visibleDialogoExitoAnularPublicacion = false;
  mensajeErrorAnularPublicacion;

  constructor(
    private datepipe: DatePipe,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private principalComponent: PrincipalComponent,
    private contratacionService: ContratacionService,
    private contratosPublicadosService: ContratacionContratoPublicadoService
  ) { }

  ngOnInit() {
    this.contratosPublicados = [];
    this.blockedDocument = false;
  }

  loadDataLazy(event: LazyLoadEvent) {
    this.blockedDocument = true;
    this.contratosPublicados = [];
    this.cargarContratos(event.first, event.rows, 'Id', 0);
  }

  buscar() {
    const filaDesde = 0;
    const cantidadRegistros = 20;
    this.blockedDocument = true;
    this.cargarContratos(filaDesde, cantidadRegistros, 'Id', 0);
  }

  limpiarFiltros() {
    this.buscarFiltro = null;
    this.buscar();
  }

  cargarContratos(filaDesde: number, cantidadRegistros: number, campoOrden: string, ascDesc: number) {
    const fechaActual = new Date();
    this.contratacionService.srvConsultarContratosInformacionVigente(null, null, null, null, null, fechaActual.getFullYear().toString(), null, filaDesde, cantidadRegistros, this.buscarFiltro, campoOrden, ascDesc)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.contratosPublicados = result.contratosInformacionVigente;
              this.contratacionService.srvCalcularEstadoVigenciaContrato(this.contratosPublicados);
              this.totalRecords = this.contratosPublicados.length == 0 ? 0 : this.contratosPublicados[0].totalRegistros;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  irAnularPublicacionContrato(contrato: CtoContratoDTO) {
    this.visibleDialogoConfirmarAnularPublicacion = true;
    this.contrato = contrato;
  }

  anularPublicacionContrato() {
    this.visibleDialogoConfirmarAnularPublicacion = false;
    this.blockedDocument = true;
    this.contratosPublicadosService.AnularPublicacionContrato(this.contrato.id)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.blockedDocument = false;
              this.buscar();
              this.visibleDialogoExitoAnularPublicacion = true;
            } else {
              this.mensajeErrorAnularPublicacion = result.respuestaServicio.mensajeSalida;
              this.visibleDialogoErrorAnularPublicacion = true;
              this.blockedDocument = false;
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  irContratosBorrador() {
    this.router.navigate(['/contratosHome/contratos/']);
  }

  get EnumCtoEstadoContrato() {
    return EnumCtoEstadoContrato;
  }

  get EnumRegex() {
    return EnumRegex;
  }
}
