import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FuncionarioInviasDetalleService {
  
  public END_POINT = '';

  // Http Options
  httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
  };

  constructor(private http: HttpClient) {
      this.END_POINT = environment.baseUrl;
  }


  /**
   * Metodo que carga la informacion de la consulta del radicado principal
   * @param numeroRadicado 
   */
  public getConsultaRadicado(numeroRadicado) {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/consultaRadicado/' + numeroRadicado ) 
    .pipe(
        retry(1));
    }

   /**
   * Metodo que carga la informacion de los proyectos
   */
  public getConsultaProyectos() {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/lista/proyectos' ) 
    .pipe(
        retry(1));
    }

   /**
   * Metodo que carga la informacion de los concesionarios
   */
  public getConsultaConcesionarios() {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/lista/concesionarios' ) 
    .pipe(
        retry(1));
    }
    
  /**
   * Metodo que carga la informacion de la consulta del radicado concepto
   * @param numeroRadicado 
   */
  public getConsultaRadicadoConcepto(numeroRadicado) {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/consultaRadicado/' + numeroRadicado ) 
    .pipe(
        retry(1));
    }

   /**
   * Metodo que carga la informacion para su edicion
   * @param idSolicitudInivias
   */
  public getConsultaInformacionTramite(idSolicitudInivias) {
    return this.http.get(this.END_POINT + '/CargaExtrapesada/tramite/consulta/' + idSolicitudInivias ) 
    .pipe(
        retry(1));
    }



     /**
     * Metodo que guarda la informacion del encabezado    
     * @param objeto 
     */
    public guardarEncabezado(objeto){
        return this.http.post(this.END_POINT + '/CargaExtrapesada/GuardarEncabezado', objeto)
        .pipe(
            retry(1));
    }

     /**
     * Metodo que guarda la informacion del concepto de un encabezado    
     * @param objeto 
     */
    public guardarConcepto(objeto){
        return this.http.post(this.END_POINT + '/CargaExtrapesada/invias/concepto/Guardar', objeto)
        .pipe(
            retry(1));
    }

    /**
     * Metodo que solicita validacionn del proyecto a guardar
     * @param idSolicitud 
     * @param idProyecto 
     */
    public getValidarRespuestaProyecto(idSolicitud, idProyecto) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/invias/validar/respuesta/' + idSolicitud+'/'+idProyecto)
          .pipe(
            retry(1));
      }


    /**
     * Metodo que solicita la informacion del solicitante en persona Juridica
     * @param tipo 
     */
    public getSolicitante(tipo) {
        return this.http.get(this.END_POINT + '/SolicitudComunicaciones/lista/solicitante/' + tipo)
          .pipe(
            retry(1));
      }

    /**
    * Metodo que carga realiza la validacion de un eliminacion de un concepto
    * @param numeroRadicado 
    */
    public getConsultaValidar(idConceptoSolicitado) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/invias/validar/eliminacion/' + idConceptoSolicitado ) 
        .pipe(
            retry(1));
    }

    /**
     * Metodo que realiza la eliminacion
     * @param idConceptoSolicitado 
     */
    public deleteProyectoComunicacion(idConceptoSolicitado) {
        return this.http.delete(this.END_POINT + '/CargaExtrapesada/invias/eliminacion/' + idConceptoSolicitado)
            .pipe(
            retry(1));
    }   

    /**
    * Metodo que carga realiza la validacion de una finalizacion de un concepto
    * @param numeroRadicado 
    */
       public getConsultaValidarFinalizacion(idSolicitudInvias) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/invias/validar/finalizacion/' + idSolicitudInvias ) 
        .pipe(
            retry(1));
    }

}
