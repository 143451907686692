
import { forkJoin } from 'rxjs';
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import * as Highcharts from "highcharts";
import drilldown from 'highcharts/modules/drilldown.src.js';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
drilldown(Highcharts)

import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesPeajesService } from "src/app/reportes/carreteros/reportes-peajes.service";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import { CrrPeajesRecaudoEncDTO, CrrPeajesDTO, CrrPeajesRecaudoAgrupadoMesDTO, CrrPeajesRecaudoAgrupadoCategoriaDTO, PjePeajeConsultaTPDDTO } from "src/app/reportes/carreteros/reportes-peajes.dto";
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { EnumPjeConstantes } from 'src/app/peajes-gestion/peajes-constantes';

export interface Anios {
  id: any;
  anio: any;
}

export interface Serie {
  name: string;
  color: string;
  data: any[];
}

export interface SerieDillDrown {
  id: string;
  name: string;
  data: any[];
}

export interface AdministradoPor {
  id: number;
  nombre: string;
}

@Component({
  selector: 'app-rpt-crr-peajes-trafico-recaudo',
  templateUrl: './rpt-crr-peajes-trafico-recaudo.component.html',
  styleUrls: ['./rpt-crr-peajes-trafico-recaudo.component.sass']
})

export class RptCrrPeajesTraficoRecaudoComponent implements OnInit {
  private isAuthenticated: boolean;
  anios: any[] = [];
  anios3: Anios[] = [];
  colores: any[];
  selectedAnio: Anios;
  selectedAnio2: any[];

  seriesA: Serie[];
  seriesB: Serie[];
  seriesTraficoCategoriaMes: Serie[];
  seriesD: Serie[];
  seriesF: Serie[];

  seriesCDillDrown: Serie[];
  seriesDDillDrown: Serie[];

  infoSerieA: Serie;
  infoSerieB: Serie;
  infoSerieTraficoCategoriaMes: Serie;
  infoSerieD: Serie;
  infoSerieF: Serie;

  infoSerieCDillDrown: Serie;
  infoSerieDDillDrown: Serie;;

  administradoPor: AdministradoPor[];
  selectedAdministradorPor: AdministradoPor;

  blockedDocument = false;
  idPeaje: number = 0;

  periodosPeajes: CrrPeajesRecaudoEncDTO[] = [];
  recaudoPeajesAgrupadoMes: CrrPeajesRecaudoAgrupadoMesDTO[] = [];
  recaudoPeajesAgrupadoMesFiltro: CrrPeajesRecaudoAgrupadoMesDTO[] = [];
  recaudoPeajesAgrupadoMesFiltro2: CrrPeajesRecaudoAgrupadoMesDTO[] = [];
  recaudoPeajesAgrupadoCategoria: CrrPeajesRecaudoAgrupadoCategoriaDTO[] = [];
  recaudoPeajesAgrupadoCategoriaFiltro: CrrPeajesRecaudoAgrupadoCategoriaDTO[] = [];
  recaudoPeajesAgrupadoCategoriaFiltro2: CrrPeajesRecaudoAgrupadoCategoriaDTO[] = [];

  totalRecaudo: number;
  totalTrafico: number;
  totalTraficoTpd: number;
  // tpda: number; //TODO: JENNY para eliminar
  consolidadoRecaudo: string;
  consolidadoTrafico: string;
  // consolidadoTpda: string;//TODO: JENNY para eliminar
  anioss: any;
  meses: any = [];
  optionschartRecaudo1: any;
  optionschartRecaudo2: any;
  optionsChartTraficoCategoriaMes: any;
  optionschartRecaudo4: any;
  optionschart5: any;
  optionschartRecaudoTest: any;
  informacionGeneralPeajes: CrrPeajesDTO[] = [];
  nombrePeaje: string;
  nombreProyecto: string;

  fechaActual: Date;

  //TPD
  fechaCorteTPD: Date;
  optionschartTPD: any;
  seriesTPD: Serie[];



  constructor(private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private reportesPeajesService: ReportesPeajesService,
    private principalComponent: PrincipalComponent,
    private adalSvc: MsAdalAngular6Service,
    private ruta: ActivatedRoute) {
    this.colores = ["#11528a", "#01d3c1", "#00c0ff"];



    this.meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];


    Highcharts.setOptions({
      lang: {
        loading: 'Cargando...',
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],

        rangeSelectorFrom: "Desde",
        rangeSelectorTo: "Hasta",
        rangeSelectorZoom: "Período",
        downloadPNG: 'Descargar imagen PNG',
        downloadJPEG: 'Descargar imagen JPEG',
        downloadPDF: 'Descargar imagen PDF',
        downloadSVG: 'Descargar imagen SVG',
        printChart: 'Imprimir',
        resetZoom: 'Reiniciar zoom',
        resetZoomTitle: 'Reiniciar zoom',
        thousandsSep: ".",
        decimalPoint: ',',
        drillUpText: 'Volver a Categorias'
      }
    });

  }

  ngOnInit() {
    this.isAuthenticated = this.adalSvc.isAuthenticated;

    this.fechaActual = new Date();
    this.fechaCorteTPD = this.fechaActual;

    this.ruta.params.subscribe((params) => {
      this.idPeaje = Number(params.idPeaje);

    });
    this.administradoPor = [
      { id: 1, nombre: "Ani" },
      { id: 3, nombre: "Departamentos" },
    ];

    // Bloquear pantalla
    this.blockedDocument = true;

    //Llamado servicio
    forkJoin(
      this.reportesPeajesService.getTraficoRecaudoPorAnioMesCategoria(this.idPeaje),
      this.reportesPeajesService.getTraficoRecaudoPorAnioCategoria2(this.idPeaje)

    ).subscribe(([InformacionPeajesRecaudoAgrupadoMesDTO, InformacionPeajesRecaudoAgrupadoCategoriaDTO]) => {
      if (InformacionPeajesRecaudoAgrupadoMesDTO.respuestaServicio.codigoSalida === 1) {

        this.selectedAnio = { id: this.fechaActual.getFullYear(), anio: this.fechaActual.getFullYear() };
        this.selectedAnio2 = [{ id: this.fechaActual.getFullYear(), anio: this.fechaActual.getFullYear() }];


        this.recaudoPeajesAgrupadoMes = InformacionPeajesRecaudoAgrupadoMesDTO.traficoRecaudo;


        this.recaudoPeajesAgrupadoMesFiltro = this.recaudoPeajesAgrupadoMes.filter(a => a.anioReporte == this.selectedAnio.anio);
        this.recaudoPeajesAgrupadoMesFiltro2 = this.recaudoPeajesAgrupadoMes.filter(a => a.anioReporte == (this.selectedAnio.anio - 1));

        this.totalRecaudo = this.recaudoPeajesAgrupadoMesFiltro
          .map((item) => item.recaudo)
          .reduce((prev, curr) => prev + curr, 0);


        this.totalTrafico = this.recaudoPeajesAgrupadoMesFiltro
          .map((item) => item.cantidadVehiculos)
          .reduce((prev, curr) => prev + curr, 0);

        this.consolidadoRecaudo = this.formatoMoneda(this.totalRecaudo);
        this.consolidadoTrafico = this.formatoCantidad(this.totalTrafico);

        this.anioss = this.recaudoPeajesAgrupadoMes.map((e) => e.anioReporte);

        this.anioss = this.anioss.filter((item, index) => {
          return this.anioss.indexOf(item) === index;
        })
        this.anios = [];
        this.anioss.forEach(element => {

          var infoAnio = {
            id: element,
            anio: element
          };
          this.anios.push(infoAnio);
        });

        this.anios = this.anios.reverse();
        var name;
        var tables = [];
        var auxFecha = [];

        var result = this.recaudoPeajesAgrupadoMesFiltro.map(i => [this.meses[i.mesReporte - 1], i.cantidadVehiculos]);

      } else {
        this.lanzarMensajeError(InformacionPeajesRecaudoAgrupadoMesDTO.respuestaServicio.mensajeSalida);
      }


      if (InformacionPeajesRecaudoAgrupadoCategoriaDTO.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        this.recaudoPeajesAgrupadoCategoria = InformacionPeajesRecaudoAgrupadoCategoriaDTO.traficoRecaudo;

        this.recaudoPeajesAgrupadoCategoriaFiltro = this.recaudoPeajesAgrupadoCategoria.filter(a => a.anioReporte == this.selectedAnio.anio)


        this.cargarDataTraficoCategoriaMes(this.recaudoPeajesAgrupadoCategoriaFiltro, this.recaudoPeajesAgrupadoMesFiltro);
        //TODO: JENNY para eliminar se reemplaza por el metodo generarChartTraficoCategoriaMes
        // var infoTrafico = [];
        // var infoTraficoDillDrownSerie = [];
        // this.recaudoPeajesAgrupadoCategoriaFiltro.forEach(element => {
        //   var info = {
        //     name: element.idCategoriaTarifa,
        //     y: element.cantidadVehiculos,
        //     drilldown: element.idCategoriaTarifa + "-" + element.anioReporte
        //   };

        //   infoTrafico.push(info);

        //   var traficoRecaudoPorMes = this.recaudoPeajesAgrupadoMesFiltro.filter(a => a.idCategoriaTarifa == element.idCategoriaTarifa);

        //   var infoTraficoDillDrown = {
        //     id: element.idCategoriaTarifa + "-" + element.anioReporte,
        //     name: element.idCategoriaTarifa + "-" + element.anioReporte,
        //     data: traficoRecaudoPorMes.map(i => [this.meses[i.mesReporte - 1], i.cantidadVehiculos])
        //   };
        //   infoTraficoDillDrownSerie.push(infoTraficoDillDrown);


        // })

        var infoRecaudo = [];
        var infoRecaudoDillDrownSerie = [];

        this.recaudoPeajesAgrupadoCategoriaFiltro.forEach(element => {
          var info = {
            y: element.recaudo / 1000000,
            name: element.idCategoriaTarifa,
            drilldown: element.idCategoriaTarifa + "-" + element.anioReporte
          };

          infoRecaudo.push(info);
          var traficoRecaudoPorMes = this.recaudoPeajesAgrupadoMesFiltro.filter(a => a.idCategoriaTarifa == element.idCategoriaTarifa);
          var infoRecaudoDillDrown = {
            id: element.idCategoriaTarifa + "-" + element.anioReporte,
            name: element.idCategoriaTarifa + "-" + element.anioReporte,
            data: traficoRecaudoPorMes.map(i => [this.meses[i.mesReporte - 1], i.recaudo / 1000000])

          };

          infoRecaudoDillDrownSerie.push(infoRecaudoDillDrown);
        })

        //TODO: JENNY para eliminar se reemplaza por el metodo this.cargarDataTraficoCategoriaMes(this.recaudoPeajesAgrupadoCategoriaFiltro, this.recaudoPeajesAgrupadoMesFiltro);
        // this.optionsChartTraficoCategoriaMes = {
        //   chart: {
        //     zoomType: "x",
        //     type: "column",
        //   },
        //   credits: {
        //     enabled: false,
        //   },
        //   title: {
        //     text: "",
        //   },
        //   subtitle: {

        //     text: 'Para ir al detalle por mes, por favor dar click en cada columna'
        //   },
        //   xAxis: {

        //     type: "category"
        //   },
        //   yAxis: {
        //     min: 0,
        //     title: {
        //       text: "",
        //     },
        //   },
        //   tooltip: {
        //     headerFormat: '<span style="font-size:10px"> <strong>Categoría:</strong> {point.key}</span><table>',
        //     pointFormat:
        //       '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //       '<td style="padding:0"><b>{point.y}</b></td></tr>',
        //     footerFormat: "</table>",
        //     shared: true,
        //     useHTML: true,
        //   },
        //   plotOptions: {
        //     series: {
        //       dataLabels: {
        //         enabled: true,
        //         align: 'right',
        //         color: '#438DF7',
        //         x: -10,
        //         format: '{point.y}',
        //       },
        //       pointPadding: 0.1,
        //       groupPadding: 0
        //     }
        //   },
        //   series: [
        //     {
        //       name: this.selectedAnio.anio,
        //       color: "#11528a",
        //       data: infoTrafico
        //     }
        //   ],
        //   drilldown: {
        //     drillUpButton: {
        //       position: {
        //         y: -35
        //       }
        //     },
        //     allowPointDrilldown: false,
        //     series: infoTraficoDillDrownSerie
        //   }

        // };
        // const chart3 = Highcharts.chart("chartTraficoCategoriaMes", this.optionsChartTraficoCategoriaMes);

        this.optionschartRecaudo4 = {
          chart: {
            zoomType: "x",
            type: "column",
          },
          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          subtitle: {

            text: 'Para ir al detalle por mes, por favor dar click en cada columna'
          },
          xAxis: {
            type: "category"
          },
          yAxis: {
            min: 0,
            title: {
              text: "",
            },
          },
          tooltip: {
            headerFormat: '<span style="font-size:10px"><strong>Categoría:</strong> {point.key}</span><table>',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>${point.y:,.2f} mill</b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            series: {
              dataLabels: {
                enabled: true,
                align: 'right',
                color: '#438DF7',
                x: -10,
                format: '${point.y:,.2f} mill '
              },
              pointPadding: 0.1,
              groupPadding: 0
            }
          },
          series: [
            {
              name: this.selectedAnio.anio,
              color: "#11528a",
              data: infoRecaudo,
            }
          ],
          drilldown: {
            drillUpButton: {
              position: {
                y: -35
              }
            },
            allowPointDrilldown: false,
            series: infoRecaudoDillDrownSerie
          }
        };
        const chart4 = Highcharts.chart("chartRecaudo4", this.optionschartRecaudo4);

      } else {
        this.lanzarMensajeError(InformacionPeajesRecaudoAgrupadoCategoriaDTO.respuestaServicio.mensajeSalida);
      }

    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });

    //Llamado servicio
    this.reportesCarreteroService.getInformacionCrrPeajesPorId(this.idPeaje).subscribe(
      (dato) => {
        if (dato != null && dato.respuestaServicio != null) {
          if (dato.respuestaServicio.codigoSalida === 1) {
            this.informacionGeneralPeajes = dato.infoPeajes;

            const infoPeajes = this.informacionGeneralPeajes[0];
            this.nombrePeaje = infoPeajes.peaje;
            this.nombreProyecto = infoPeajes.proyecto;
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: dato.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        }
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );

    //Llamado servicio
    forkJoin(
      this.reportesPeajesService.srvGetReportesTraficoRecaudoPeriodoPeaje(this.idPeaje)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.periodosPeajes = result.encabezadoPeriodo;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      }
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );

    //TPD
    this.cargarPeajeTPD();

  }

  /**
   * Metodo que actualiza la información de los gráficos por la selección de los años
   * @param event 
   */
  actualizarInfo(event) {
    var contador = 0;
    this.seriesA = [];
    this.seriesB = [];
    this.seriesTraficoCategoriaMes = [];
    this.seriesD = [];
    this.seriesF = [];

    this.totalRecaudo = 0;
    this.totalTrafico = 0;
    var infoTraficoDillDrownSerie = [];
    var infoRecaudoDillDrownSerie = [];
    this.selectedAnio2.forEach(element => {


      this.recaudoPeajesAgrupadoMesFiltro = this.recaudoPeajesAgrupadoMes.filter(a => a.anioReporte == element.anio);
      this.recaudoPeajesAgrupadoCategoriaFiltro = this.recaudoPeajesAgrupadoCategoria.filter(a => a.anioReporte == element.anio)

      this.totalRecaudo = this.totalRecaudo + this.recaudoPeajesAgrupadoMesFiltro
        .map((item) => item.recaudo)
        .reduce((prev, curr) => prev + curr, 0);


      this.totalTrafico = this.totalTrafico + this.recaudoPeajesAgrupadoMesFiltro
        .map((item) => item.cantidadVehiculos)
        .reduce((prev, curr) => prev + curr, 0);

      this.totalTraficoTpd = this.recaudoPeajesAgrupadoMesFiltro
        .map((item) => item.cantidadVehiculos)
        .reduce((prev, curr) => prev + curr, 0);


      let num = element.anio;
      let text = num.toString();
      this.infoSerieA = { name: text, color: this.colores[contador], data: this.recaudoPeajesAgrupadoMesFiltro.map(i => [this.meses[i.mesReporte - 1], i.cantidadVehiculos]) };
      this.infoSerieB = { name: text, color: this.colores[contador], data: this.recaudoPeajesAgrupadoMesFiltro.map(i => [this.meses[i.mesReporte - 1], i.recaudo / 1000000]) };


      this.seriesA.push(this.infoSerieA);
      this.seriesB.push(this.infoSerieB);

      //Cargar data por cada ano seleccionado para Trafico por categoria y mes
      const infoTrafico = [];
      const ejesAdicionales = [EnumPjeConstantes.PJE_CATEGORIA_VEHICULO_EJE_ADICIONAL, EnumPjeConstantes.PJE_CATEGORIA_VEHICULO_EJE_GRUA, EnumPjeConstantes.PJE_CATEGORIA_VEHICULO_EJE_REMOLQUE, EnumPjeConstantes.PJE_CATEGORIA_VEHICULO_EJE_CANERO];
      //Filtro para no mostrar los ejes adicionales, ejes grua y ejes remolques
      const traficoCategoriaMesList = this.recaudoPeajesAgrupadoCategoriaFiltro.filter(item => !ejesAdicionales.includes(item.idCategoriaVehiculo));

      traficoCategoriaMesList.forEach(element => {
        const traficoRecaudoPorMes = this.recaudoPeajesAgrupadoMesFiltro.filter(a => a.idCategoriaTarifa == element.idCategoriaTarifa);
        const info = {
          y: element.cantidadVehiculos,
          name: element.idCategoriaTarifa,
          drilldown: element.idCategoriaTarifa + "-" + element.anioReporte
        };
        infoTrafico.push(info);

        const infoTraficoDillDrown = {
          id: element.idCategoriaTarifa + "-" + element.anioReporte,
          name: element.idCategoriaTarifa + "-" + element.anioReporte,
          data: traficoRecaudoPorMes.map(i => [this.meses[i.mesReporte - 1], i.cantidadVehiculos])
        };
        infoTraficoDillDrownSerie.push(infoTraficoDillDrown);
      });

      this.infoSerieTraficoCategoriaMes = { name: text, color: this.colores[contador], data: infoTrafico };
      this.seriesTraficoCategoriaMes.push(this.infoSerieTraficoCategoriaMes);
      //Fin Cargar data por cada ano seleccionado para Trafico por categoria y mes


      var infoRecaudo = [];
      this.recaudoPeajesAgrupadoCategoriaFiltro.forEach(element => {

        var traficoRecaudoPorMes = this.recaudoPeajesAgrupadoMesFiltro.filter(a => a.idCategoriaTarifa == element.idCategoriaTarifa);


        var info = {
          y: element.recaudo,
          name: element.idCategoriaTarifa,
          drilldown: element.idCategoriaTarifa + "-" + element.anioReporte
        };

        infoRecaudo.push(info);


        var traficoRecaudoPorMes = this.recaudoPeajesAgrupadoMesFiltro.filter(a => a.idCategoriaTarifa == element.idCategoriaTarifa);
        var infoRecaudoDillDrown = {
          id: element.idCategoriaTarifa + "-" + element.anioReporte,
          name: element.idCategoriaTarifa + "-" + element.anioReporte,
          data: traficoRecaudoPorMes.map(i => [this.meses[i.mesReporte - 1], i.recaudo / 1000000])

        };

        infoRecaudoDillDrownSerie.push(infoRecaudoDillDrown);
      });
      this.infoSerieD = { name: text, color: this.colores[contador], data: infoRecaudo };


      var infoTpd = [];
      this.infoSerieF = { name: text, color: this.colores[contador], data: infoTpd };

      this.seriesD.push(this.infoSerieD);
      this.seriesF.push(this.infoSerieF);


      contador++;
    });

    this.consolidadoRecaudo = this.formatoMoneda(this.totalRecaudo);
    this.consolidadoTrafico = this.formatoCantidad(this.totalTrafico);

    //Chart  Tráfico por Categoría y por Mes    
    this.optionschartRecaudo1 = {
      chart: {
        zoomType: "x",
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "octubre", "Noviembre", "Diciembre"],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: 'right',
            color: '#438DF7',
            x: -10,
            format: '{point.y} '
          },
          pointPadding: 0.1,
          groupPadding: 0
        }
      },
      series: this.seriesA,
    };
    const chart1 = Highcharts.chart("chartRecaudo1", this.optionschartRecaudo1);


    //Chart  Recaudo por Categoría y por Mes
    this.optionschartRecaudo2 = {
      chart: {
        zoomType: "x",
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "octubre", "Noviembre", "Diciembre"],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} </b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: 'right',
            color: '#438DF7',
            x: -10,
            format: '{point.y} m'
          },
          pointPadding: 0.1,
          groupPadding: 0
        }
      },
      series: this.seriesB,
    };
    const chart2 = Highcharts.chart("chartRecaudo2", this.optionschartRecaudo2);

    //Chart Tráfico por Categoría y por Mes
    //TODO: JENNY para eliminar
    // this.optionsChartTraficoCategoriaMes = {
    //   chart: {
    //     zoomType: "x",
    //     type: "column",
    //   },
    //   credits: {
    //     enabled: false,
    //   },
    //   title: {
    //     text: "",
    //   },
    //   xAxis: {
    //     type: "category"
    //   },
    //   yAxis: {
    //     min: 0,
    //     title: {
    //       text: "",
    //     },
    //   },
    //   tooltip: {
    //     headerFormat: '<span style="font-size:10px"><strong>Categoría:</strong> {point.key}</span><table>',
    //     pointFormat:
    //       '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
    //       '<td style="padding:0"><b>{point.y} </b></td></tr>',
    //     footerFormat: "</table>",
    //     shared: true,
    //     useHTML: true,
    //   },
    //   plotOptions: {
    //     series: {
    //       dataLabels: {
    //         enabled: true,
    //         align: 'right',
    //         color: '#438DF7',
    //         x: -10,
    //         format: '{point.y}'
    //       },
    //       pointPadding: 0.1,
    //       groupPadding: 0
    //     }
    //   },
    //   series: this.seriesTraficoCategoriaMes,
    //   drilldown: {
    //     drillUpButton: {
    //       position: {
    //         y: -35
    //       }
    //     },
    //     allowPointDrilldown: false,
    //     series: infoTraficoDillDrownSerie
    //   }
    // };
    // const chart3 = Highcharts.chart("chartTraficoCategoriaMes", this.optionsChartTraficoCategoriaMes);
    this.generarChartTraficoCategoriaMesComparacionAnos(this.seriesTraficoCategoriaMes, infoTraficoDillDrownSerie);



    //Chart  Recaudo por Categoría y por Mes
    this.optionschartRecaudo4 = {
      chart: {
        zoomType: "x",
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "category"
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px"><strong>Categoría:</strong> {point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>${point.y:,.2f} mill </b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: 'right',
            color: '#438DF7',
            x: -10,
            format: '${point.y:,.2f} mill '
          },
          pointPadding: 0.1,
          groupPadding: 0
        }
      },
      series: this.seriesD,
      drilldown: {
        drillUpButton: {
          position: {
            y: -35
          }
        },
        allowPointDrilldown: false,
        series: infoRecaudoDillDrownSerie
      }
    };
    const chart4 = Highcharts.chart("chartRecaudo4", this.optionschartRecaudo4);
  }

  //#region <Tráfico por categoria y por mes>

  /**
   * Metodo que carga la data que se va a usar para el chart de Tráfico por categoria y por mes inicial 
   * @param recaudoPeajesAgrupadoCategoriaFiltro Información de Tráfico por Categoría
   * @param recaudoPeajesAgrupadoMesFiltro Información de Tráfico por Categoría por Mes
   */
  cargarDataTraficoCategoriaMes(recaudoPeajesAgrupadoCategoriaFiltro: CrrPeajesRecaudoAgrupadoCategoriaDTO[], recaudoPeajesAgrupadoMesFiltro: CrrPeajesRecaudoAgrupadoMesDTO[]) {

    const ejesAdicionales = [EnumPjeConstantes.PJE_CATEGORIA_VEHICULO_EJE_ADICIONAL, EnumPjeConstantes.PJE_CATEGORIA_VEHICULO_EJE_GRUA, EnumPjeConstantes.PJE_CATEGORIA_VEHICULO_EJE_REMOLQUE, EnumPjeConstantes.PJE_CATEGORIA_VEHICULO_EJE_CANERO];
    //Filtro para no mostrar los ejes adicionales, ejes grua y ejes remolques
    const traficoCategoriaMesList = this.recaudoPeajesAgrupadoCategoriaFiltro.filter(item => !ejesAdicionales.includes(item.idCategoriaVehiculo));

    const infoTrafico = [];
    const infoTraficoDillDrownSerie = [];

    traficoCategoriaMesList.forEach(element => {
      const info = {
        name: element.idCategoriaTarifa,
        y: element.cantidadVehiculos,
        drilldown: element.idCategoriaTarifa + "-" + element.anioReporte
      };
      infoTrafico.push(info);

      const traficoRecaudoPorMes = recaudoPeajesAgrupadoMesFiltro.filter(a => a.idCategoriaTarifa == element.idCategoriaTarifa);

      const infoTraficoDillDrown = {
        id: element.idCategoriaTarifa + "-" + element.anioReporte,
        name: element.idCategoriaTarifa + "-" + element.anioReporte,
        data: traficoRecaudoPorMes.map(i => [this.meses[i.mesReporte - 1], i.cantidadVehiculos])
      };
      infoTraficoDillDrownSerie.push(infoTraficoDillDrown);
    });

    this.generarChartTraficoCategoriaMes(infoTrafico, infoTraficoDillDrownSerie);
  }

  /**
   * Metodo que genera el chart de Tráfico por categoria y por mes inicial 
   * @param infoTrafico Información de Tráfico por Categoría
   * @param infoTraficoDillDrownSerie Información de Tráfico por Categoría por Mes
   */
  generarChartTraficoCategoriaMes(infoSerieTrafico: any, infoTraficoDillDrownSerie: any) {
    this.optionsChartTraficoCategoriaMes = {
      chart: {
        zoomType: "x",
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      subtitle: {

        text: 'Para ir al detalle por mes, por favor dar click en cada columna'
      },
      xAxis: {

        type: "category"
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px"> <strong>Categoría:</strong> {point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: 'right',
            color: '#438DF7',
            x: -10,
            format: '{point.y}',
          },
          pointPadding: 0.1,
          groupPadding: 0
        }
      },
      series: [
        {
          name: this.selectedAnio.anio,
          color: "#11528a",
          data: infoSerieTrafico
        }
      ],
      drilldown: {
        drillUpButton: {
          position: {
            y: -35
          }
        },
        allowPointDrilldown: false,
        series: infoTraficoDillDrownSerie
      }

    };
    Highcharts.chart("chartTraficoCategoriaMes", this.optionsChartTraficoCategoriaMes);
  }

  /**
   * Metodo que genera el chart de Tráfico por categoria y por mes para la comparación de anos @see actualizarInfo
   * @param seriesTraficoCategoriaMes Data de la serie que se genera en el metodo @see actualizarInfo para actualizar el chart en la comparacion por anos
   * @param infoTraficoDillDrownSerie Data de la serie que se genera en el metodo @see actualizarInfo para actualizar el chart en la comparacion por anos
   */
  generarChartTraficoCategoriaMesComparacionAnos(seriesTraficoCategoriaMes: Serie[], infoTraficoDillDrownSerie: any) {
    this.optionsChartTraficoCategoriaMes = {
      chart: {
        zoomType: "x",
        type: "column",
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "category"
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px"><strong>Categoría:</strong> {point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y} </b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            align: 'right',
            color: '#438DF7',
            x: -10,
            format: '{point.y}'
          },
          pointPadding: 0.1,
          groupPadding: 0
        }
      },
      series: seriesTraficoCategoriaMes,
      drilldown: {
        drillUpButton: {
          position: {
            y: -35
          }
        },
        allowPointDrilldown: false,
        series: infoTraficoDillDrownSerie
      }
    };
    const chart3 = Highcharts.chart("chartTraficoCategoriaMes", this.optionsChartTraficoCategoriaMes);
  }
  //#endregion




  /**
   * TPD
  */
  //#region <TPD>

  /**
   * Metodo que llama al servicio de consulta TPDA y el TPDM de un peaje por fecha de corte y de acuerdo al resultado genera el chart
   */
  cargarPeajeTPD() {
    this.blockedDocument = true;
    this.reportesPeajesService.srvConsultarTPDPeaje(this.idPeaje, this.fechaCorteTPD).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.generarChartTPD(result.tpdPeaje);
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que controla el evento de seleccion de la @fechaCorteTPD
   */
  onSelectFechaCorteTPD() {
    this.cargarPeajeTPD();
  }

  /**
   * Metodo que genera el char para el TPDA y TPDM de un Peaje
   * 
   * @param tpdPeaje Info del peaje
   */
  generarChartTPD(tpdPeaje: PjePeajeConsultaTPDDTO) {

    let mensajeCalculoTPD = '';

    if (tpdPeaje.tpda == null && tpdPeaje.tpdm == null) {
      mensajeCalculoTPD = 'No se puede calcular el TPDA ni el TPDM porque no se registra reporte del mes según fecha de corte';
    } else {
      if (tpdPeaje.tpda == null && tpdPeaje.tpdm != null) {
        mensajeCalculoTPD = 'No se puede calcular el TPDA porque no se registra reporte de todos los meses para la fecha de corte';
      }
    }

    const dataEjeXtpdaArr = [];

    //Data del eje X, se arma cada dato el valor de Y, y el nombre X    
    const dataEjeXtpdm = {
      y: tpdPeaje.tpdm,//Valor eje Y
      name: 'TPDM',//Leyenda eje x Valor      
      custom: {
        mensajeInformativo: 'Tráfico promedio diario mensual calculado con base en los días calendario del mes consultado.',
      }
    };
    dataEjeXtpdaArr.push(dataEjeXtpdm);

    const dataEjeXtpda = {
      y: tpdPeaje.tpda,//Valor eje Y
      name: 'TPDA', //Leyenda eje x Valor
      custom: {
        mensajeInformativo: 'Tráfico promedio diario anual calculado con base en los días calendario de los últimos 12 meses anteriores al mes consultado.',
      }
    };
    dataEjeXtpdaArr.push(dataEjeXtpda);

    //Data del eje X
    const infoSerieTPD = {
      name: this.fechaCorteTPD.getMonth() + 1 + '-' + this.fechaCorteTPD.getFullYear().toString(), //Leyenda eje X
      color: this.colores[0],
      data: dataEjeXtpdaArr
    };

    this.seriesTPD = [];
    this.seriesTPD.push(infoSerieTPD);

    this.optionschartTPD = {
      chart: {
        type: "column",
      },
      credits: { enabled: false },
      title: { text: "Tráfico promedio diario" },
      subtitle: { text: mensajeCalculoTPD },
      xAxis: {
        type: "category"
      },
      yAxis: {
        min: 0,
        title: { text: "" }, labels: {
          format: '{value} '
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr>' +
          '<td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f}</b></td>' +
          '</tr>' +
          '<tr>' +
          '<td style="">{point.custom.mensajeInformativo}</td>' +
          '<td></td>' +
          '</tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },


      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.y:.0f}',
            align: 'right',
            color: '#11528a',
            x: -10
          },
          pointPadding: 0.1,
          groupPadding: 0
        }
      },
      series: this.seriesTPD,
    };
    Highcharts.chart("chartTPD", this.optionschartTPD);
  }

  //#endregion 


  //#region <Utilidades>
  formatoMoneda(valor: number) {
    return valor
      ? "$" +
      (valor / 1000000).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
      " mill."
      : "$0";
  }
  formatoCantidad(valor: number) {
    return valor
      ? "" +
      (valor / 1000000).toFixed(0).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
      " mill."
      : "0";
  }
  //#endregion 

  get EnumPjeConstantes() {
    return EnumPjeConstantes;
  }

  atras() {
    window.history.back();
  }


  /**
 * Lanza mensaje de informacion
 */
  lanzarMensajeInfo(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.messageService.add({ severity: "error", summary: "Error", detail: mensaje, life: 10000, });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    // this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }



}
