import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  AfterContentInit,
  SimpleChanges,
} from '@angular/core';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';

@Component({
  selector: 'app-seleccionar-departamento-municipio',
  templateUrl: './seleccionar-departamento-municipio.component.html',
  styleUrls: ['./seleccionar-departamento-municipio.component.sass'],
})
export class SeleccionarDepartamentoMunicipioComponent
  implements OnInit, AfterContentInit {
  departamentos: any = [];
  filtroDepartamentos: any[];
  departamentoSeleccionado: any;

  ciudades: any = [];
  filtroCiudades: any[];
  ciudadSeleccionada: any;

  @Input()
  visibleMunicipio: boolean;

  @Input()
  idMunicipioAlmacenado: string;
  @Input()
  idDepartamentoAlmacenado: string;
  @Input()
  soloLectura: boolean;


  @Output() departamentoMunicipioEmiter = new EventEmitter();

  constructor(private parametricasService: ParametricasService) { }

  ngOnInit() {
    this.cargarDepartamentos(null);
    if (this.departamentoSeleccionado != null && this.departamentoSeleccionado.id != null) {
      this.cargarCiudades(null);
    }
  }



  ngOnChanges(changes: SimpleChanges) {
    if (changes['idDepartamentoAlmacenado']) {
      let variableChange = changes['idDepartamentoAlmacenado'];
      //do something with variableChange.currentValue
      this.cargarDepartamentos(null);
    }
    if (changes['idMunicipioAlmacenado']) {
      let variableChange2 = changes['idMunicipioAlmacenado'];
      //do something with variableChange.currentValue
      this.cargarCiudades(null);
    }
  }

  ngAfterContentInit() {
    this.limpiarCampos();
  }

  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe((data: {}) => {
      this.departamentos = data;
      this.cargarValorSeleccionadoDepartamento();
      let existeFiltro: boolean;
      if (event != null) {
        for (let i = 0; i < this.departamentos.length; i++) {
          let filtro = this.departamentos[i];
          let item: string = String(filtro.nombre);
          if (item.toLowerCase().includes(event.query.toLowerCase())) {
            this.filtroDepartamentos.push(filtro);
            existeFiltro = true;
          }
        }
        if (existeFiltro) {
          this.departamentos = this.filtroDepartamentos;
        }
      }

    });
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      let filtro = this.departamentos[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  limpiarCiudades(event) {
    this.ciudadSeleccionada = null;
  }

  cargarCiudades(event) {
    this.filtroCiudades = [];
    if (this.departamentoSeleccionado != null && this.departamentoSeleccionado.id != null) {
      return this.parametricasService
        .getCiudadesPorDepartamento(this.departamentoSeleccionado.id)
        .subscribe((data: {}) => {
          this.ciudades = data;
          this.cargarValorSeleccionadoCiudad();
          if (event != null) {
            let existeFiltro: boolean;
            for (let i = 0; i < this.ciudades.length; i++) {
              let filtro = this.ciudades[i];
              let item: string = String(filtro.nombre);
              if (item.toLowerCase().includes(event.query.toLowerCase())) {
                this.filtroCiudades.push(filtro);
                existeFiltro = true;
              }
            }
            if (existeFiltro) {
              this.ciudades = this.filtroCiudades;
            }
          }
        });
    }
  }

  cargarFiltroCiudades(event) {
    this.filtroCiudades = [];
    for (let i = 0; i < this.ciudades.length; i++) {
      let filtro = this.ciudades[i];
      let item: string = String(filtro.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudades.push(filtro);
      }
    }
  }

  onChangeDepartamento() {
    this.departamentoMunicipioEmiter.emit({
      departamento: this.departamentoSeleccionado,
      municipio: null,
    });
  }

  onChangeMunicipio() {
    this.departamentoMunicipioEmiter.emit({
      departamento: this.departamentoSeleccionado,
      municipio: this.ciudadSeleccionada,
    });
  }

  cargarValorSeleccionadoDepartamento() {
    if (this.idDepartamentoAlmacenado != null) {
      this.departamentoSeleccionado = this.departamentos.find(dato => dato.id == this.idDepartamentoAlmacenado);
      this.cargarCiudades(null);

      this.departamentoMunicipioEmiter.emit({
        departamento: this.departamentoSeleccionado,
        municipio: null,
      });

    }
  }

  cargarValorSeleccionadoCiudad() {
    if (this.idMunicipioAlmacenado != null) {
      this.ciudadSeleccionada = this.ciudades.find(dato => dato.id == this.idMunicipioAlmacenado);
      this.departamentoMunicipioEmiter.emit({
        departamento: this.departamentoSeleccionado,
        municipio: this.ciudadSeleccionada,
      });
    }
  }


  limpiarCampos() {
    this.departamentoSeleccionado = null;
    this.ciudadSeleccionada = null;
  }
}
