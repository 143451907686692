import { DatePipe } from "@angular/common";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map, retry, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReportesPredialService {
    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(
        private http: HttpClient,
        private datepipe: DatePipe) {

        this.END_POINT = environment.baseUrl;
    }

    public getPredialAgrupadoVsDetallado(idProyecto) {
        return this.http.get<any>(this.END_POINT + '/RptFichaPredialCarretero/comparativoAgrupadoVsDetallado/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

}