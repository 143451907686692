import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { VigenciasFuturasIndexada } from './vigencias-futuras-indexadas';
import { InformacionGeneralService } from 'src/app/informacion-general/informacion-general.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { VigenciasFuturasService } from './vigencias-futuras.service';
import { NgForm } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-vigencias-futuras',
  templateUrl: './vigencias-futuras.component.html',
  styleUrls: ['./vigencias-futuras.component.sass']
})
export class VigenciasFuturasComponent implements OnInit {

  vigenciasFuturasList: any = [];

  displayedColumns: any[];

  dataSource: MatTableDataSource<VigenciasFuturasIndexada>;

  vigenciasFuturasIndexadaSeleccionada: VigenciasFuturasIndexada;

  idProyecto: number;
  proyecto: any;

  idVigenciaFutura: any;

  visibleAgregarVigenciaFuturaIndexado: boolean = false;
  visibleDialogoAdvertenciaFecha: boolean = false;
  visibleDialogoExito: boolean = false;
  visibleConfirmarEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarVigenciaFutura: boolean = false;

  datosVigenciaFuturaIndexado: VigenciasFuturasIndexada = {};

  origenSaldos: any = [];
  filtroOrigenSaldos: any[];
  origenSaldoSeleccionado: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private infoService: InformacionGeneralService, private vigenciasFuturasService: VigenciasFuturasService, private route: Router, private ruta: ActivatedRoute, private parametricasService: ParametricasService,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
      this.cargarVigenciaFutura(this.idProyecto);
    });
    this.displayedColumns = [
      { field: 'formatoFecha', header: 'Fecha mes de referencia' },
      { field: 'formatoAcumulado', header: 'Sumatoria vigencias Futuras($) Giradas a pesos - Acumulado' },
      { field: 'formatoGiradas', header: 'Sumatoria giradas ($) pesos' }
      //{ field: 'formatoPorcentajeDolares', header: '% Dolares Vigencia Futura' }
    ];
    /* var nombreProyecto = document.getElementById("nombre_proyecto") ;
    document.getElementById("nameproyecto_h").appendChild(nombreProyecto); */
  }

  cargarVigenciaFutura(id: number) {
    return this.vigenciasFuturasService.getVigenciaFuturaIndexada(id).subscribe((data: {}) => {
      this.vigenciasFuturasList = data;
     this.dataSource = new MatTableDataSource(this.vigenciasFuturasList);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irAgregarVigenciaFuturaIdexada() {
    this.visibleAgregarVigenciaFuturaIndexado = true;
    this.idVigenciaFutura = "new";
    this.datosVigenciaFuturaIndexado = {};
  }

  cancelarAgregarVigenciaFuturaIdexada() {
    this.visibleAgregarVigenciaFuturaIndexado = false;
  }

  guardarVigenciaFuturaIdexada(forma: NgForm) {
    if (this.idVigenciaFutura === "new") {
      this.datosVigenciaFuturaIndexado.idProyecto = this.idProyecto;
     let vara: any = this.vigenciasFuturasService.guardarVigenciaFuturaIndexada(this.datosVigenciaFuturaIndexado).subscribe((data: {}) => {
        let respuesta: VigenciasFuturasIndexada = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
        this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.vigenciasFuturasService.editarVigenciaFuturaIndexada(this.datosVigenciaFuturaIndexado, this.idVigenciaFutura).subscribe((data: {}) => {
        let respuesta: VigenciasFuturasIndexada = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
        this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  aceptarDialogoAdvertenciaFecha() {
    this.visibleDialogoAdvertenciaFecha = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.cargarVigenciaFutura(this.idProyecto);
    this.visibleAgregarVigenciaFuturaIndexado = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editarVigenciaFuturaIdexada(vigenciasFuturasIndexada: VigenciasFuturasIndexada) {
    this.vigenciasFuturasIndexadaSeleccionada = vigenciasFuturasIndexada;
    this.idVigenciaFutura = this.vigenciasFuturasIndexadaSeleccionada.id;
    this.visibleAgregarVigenciaFuturaIndexado = true;
      return this.vigenciasFuturasService.getVigenciaFuturaIndexadaPorId(this.vigenciasFuturasIndexadaSeleccionada.id).subscribe((data: {}) => {
        this.datosVigenciaFuturaIndexado = data;
        this.datosVigenciaFuturaIndexado.porcentajeDolares = this.datosVigenciaFuturaIndexado.porcentajeDolares * 100;
     }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irEliminarVigenciaFuturaIdexada(vigenciasFuturasIndexada: VigenciasFuturasIndexada) {
    this.vigenciasFuturasIndexadaSeleccionada = vigenciasFuturasIndexada;
    this.visibleConfirmarEliminarRegistro = true;
    this.idVigenciaFutura = this.vigenciasFuturasIndexadaSeleccionada.id;
  }

  cancelarEliminarVigenciaFuturaIdexada() {
    this.visibleConfirmarEliminarRegistro = false;
  }

  aceptarEliminarVigenciaFuturaIdexada() {
    return this.vigenciasFuturasService.eliminarVigenciaFuturaIndexada(this.idVigenciaFutura).subscribe((data: {}) => {
      this.cargarVigenciaFutura(this.idProyecto);
      this.visibleDialogoExitoEliminarRegistro = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarVigenciaFuturaIdexada() {
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleConfirmarEliminarRegistro = false;
  }


}
