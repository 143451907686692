import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-fluvial',
  templateUrl: './fluvial.component.html',
  styleUrls: ['./fluvial.component.sass']
})
export class FluvialComponent implements OnInit {

  constructor(private route: Router, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
  }

}
