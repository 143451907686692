import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PqrsReporteEncuestaSatisfaccionService } from './pqrs-reporte-encuesta-satisfaccion.service';
import { Busqueda } from '../../dtos/pqrs/pqrs-encuesta-satisfaccion';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-pqrs-reporte-encuesta-satisfaccion',
  templateUrl: './pqrs-reporte-encuesta-satisfaccion.component.html',
  styleUrls: ['./pqrs-reporte-encuesta-satisfaccion.component.scss']
})
export class PqrsReporteEncuestaSatisfaccionComponent implements OnInit {

  // Inicializaciones de Variables
  displayedColumns1;
  listaEncuestas: any = [];
  archivoExcelBase64: any = null;
  responseExcel: any = null;
  busqueda: Busqueda = {};
  numeroRadicado: any = null;
  loading: any = false;

  constructor(
      private consultaService: PqrsReporteEncuestaSatisfaccionService,
      private route: Router,
      private messageService: MessageService,
      private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.loading = true;
    this.displayedColumns1 = [
        { field: 'fechaReporte', header: 'Fecha Reporte' },
        { field: 'numeroRadicado', header: 'NumeroRadicado' },
        { field: 'remitente', header: 'Remitente' },
        { field: 'email', header: 'Email' }
      ];
    this.loading = false;
  }

  // Metodo que carga la busqueda
  onFind() {
    if (this.numeroRadicado !== undefined && this.numeroRadicado !== null
        && this.numeroRadicado !== '') {
          this.busqueda.numeroRadicado = this.numeroRadicado;
    }else{
      this.busqueda.numeroRadicado = null;
    }

    if(this.busqueda.fechaInicio !== undefined &&
      this.busqueda.fechaInicio !== null &&
      this.busqueda.fechaFin !== undefined &&
      this.busqueda.fechaFin !== null){
      this.cargarBusqueda(this.busqueda);
      this.archivoExcelBase64 = null;
    }else{
      this.lanzarMensajeError("Por favor, seleccionar la fecha inicio y fecha fin.")
    }
  }

  // Metodo que carga la busqueda
  cargarBusqueda(objeto) {
    this.loading = true;
    return this.consultaService.postBusqueda(objeto).subscribe((data: {}) => {
      this.responseData(data);
    }, (error)=>{
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  // Metodo que carga la respuesta
  responseData(payload) {
    if (payload.objeto === null) {
        this.lanzarMensajeError(payload.respuestaServicio.mensajeSalida);
    } else {
      this.listaEncuestas = payload.objeto;
      this.loading = false;
      if (payload.objeto.length === 0) {
        this.lanzarMensajeError('No se encontró información');
      } else {
        this.lanzarMensajeInfo('Se encontraron registros con la búsqueda');
      }
    }
  }

  /**
   * Metodo que realiza el retorno
   */
  onReturn() {
    this.route.navigate(['/pqrs/pqrs-home-servicios']);
  }

  /**
   * Metodo que realiza la generacion del excel
   */
  onExcel(){
    this.loading = true;
    this.consultaService.postGenerarExcel(this.busqueda).subscribe((data: {}) => {
      this.responseExcel = data;
      this.archivoExcelBase64 = this.responseExcel.objeto;
      this.onDownload(this.archivoExcelBase64);
      this.loading = false;
    }, (error)=>{
      this.loading = false;
      this.principalComponent.cargarErrorServicio(error);
    });
  }


  /**
  *Metodo que descarga el archivo
  *
  * @param {*} data
  * @memberof PqrsReporteEncuestaSatisfaccionComponent
  */
  onDownload(data) {
    var a = document.createElement("a");
    var fileName = "archivExcel";
    var base64str = data;
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }
    var blob = new Blob( [view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }

  // Metodo que limpia la busqueda
  onLimpiar(){
    this.busqueda.numeroRadicado = null;
    this.busqueda.fechaFin = null;
    this.busqueda.fechaInicio = null;
    this.numeroRadicado = null;
    this.listaEncuestas = [];
  }

  // /////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
  }

  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
  }

  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
