import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sig-trafico-waze',
  templateUrl: './sig-trafico-waze.component.html',
  styleUrls: ['./sig-trafico-waze.component.sass']
})
export class SigTraficoWazeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
