import { Component, OnInit } from '@angular/core';
import { MessageService } from "primeng/api";
import * as Highcharts from "highcharts/highstock";
import { PrdComparativoAgrupadoVsDetalladoDTO } from '../../reportes-predial-dto';
import { ReportesPredialService } from '../../reportes-predial-service';
import { ReportesCarreteroService } from '../../reportes-carreteros.service';
import { ActivatedRoute } from '@angular/router';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { forkJoin } from 'rxjs';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { UnidadesFuncionales } from '../../../../dtos/juridico/tribunales/seguimientoDTO';
import { UnidadFuncionalDTO } from '../../reportes-carreteros.dto';

@Component({
  selector: 'app-rpt-crr-proyecto-predial',
  templateUrl: './rpt-crr-proyecto-predial.component.html',
  styleUrls: ['./rpt-crr-proyecto-predial.component.sass']
})
export class RptCrrProyectoPredialComponent implements OnInit {
  chartPredialAgrupado: any;
  chartPredialDetallado: any;
  optionschart12: any;
  optionschart13: any;
  optionschart14: any;
  optionschart15: any;
  optionschart16: any;
  optionschart17: any;
  idProyecto: number;
  proyecto: any;
  agrupadoPredios: PrdComparativoAgrupadoVsDetalladoDTO[] = [];
  agrupadoPrediosFiltrado: PrdComparativoAgrupadoVsDetalladoDTO[] = [];
  porcentajePrediosDisponiblesAgrupado: number = 0;
  numeroPrediosDisponiblesAgrupado: number = 0;
  numeroPrediosRequeridosAgrupado: number = 0;
  porcentajePrediosAdquiridosAgrupado: number = 0;
  numeroPrediosAdquiridosAgrupado: number = 0;
  numeroPrediosOfertadosAgrupado: number = 0;
  numeroPrediosExpropiadosAgrupado: number = 0;
  porcentajeLongitudDisponibleAgrupado: number = 0;
  longitudDisponibleAgrupado: number = 0;
  longitudRequeridaAgrupado: number = 0;
  valoresPrediosUnidadFuncionalAgrupado: any;
  fechaCorteAgrupado: Date;
  fechaCorteDetallado: Date;

  porcentajePrediosDisponiblesDetallado: number = 0;
  numeroPrediosDisponiblesDetallado: number = 0;
  numeroPrediosRequeridosDetallado: number = 0;
  porcentajePrediosAdquiridosDetallado: number = 0;
  numeroPrediosAdquiridosDetallado: number = 0;
  numeroPrediosOfertadosDetallado: number = 0;
  numeroPrediosExpropiadosDetallado: number = 0;
  porcentajeLogintudDisponibleDetallado: number = 0;
  longitudDisponibleDetallado: number = 0;
  longitudRequeridaDetallado: number = 0;
  valoresPrediosUnidadFuncionalDetallado: any;

  unidadesFuncionales?: any[] = [];
  unidadFuncional?: any = null;

  constructor(private reportesPredialService: ReportesPredialService, private informacionGeneralProyectoService: ReportesCarreteroService,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    console.log("predial");
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params.id;
      //this.cargarInformacionGeneral(this.idProyecto);
      this.obtenerAgrupadoVsDetalladoPredios();
    });
    this.init();
    //this.actualizarGraficas();

  }

  actualizarGraficas() {

    this.chartPredialAgrupado = {
      chart: {
        type: 'column'
      },
      credits: { enabled: false },
      title: {
        text: 'Predial agrupado'
      },
      xAxis: {
        categories: [
          ''
        ],
        crosshair: false
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Predios'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true
          },
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Predios Requeridos',
        color: "#313CBC",
        data: [this.numeroPrediosRequeridosAgrupado]

      }, {
        name: 'Predios Disponibles',
        color: "#00C0FF",
        data: [this.numeroPrediosDisponiblesAgrupado]

      }, {
        name: 'Predios Adquiridos',
        color: "#00BFC4",
        data: [this.numeroPrediosAdquiridosAgrupado]

      }, {
        name: 'Predios Ofertados',
        color: "#FC7F25",
        data: [this.numeroPrediosOfertadosAgrupado]
      }]
    };

    let chartPredialAgrupado = Highcharts.chart("chartPredialAgrupado", this.chartPredialAgrupado);

    this.chartPredialDetallado = {
      chart: {
        type: 'column'
      },
      credits: { enabled: false },
      title: {
        text: 'Predial detallado'
      },
      xAxis: {
        categories: [
          ''
        ],
        crosshair: false
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Predios'
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true
          },
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: [{
        name: 'Predios Requeridos',
        color: "#313CBC",
        data: [this.numeroPrediosRequeridosDetallado]

      }, {
        name: 'Predios Disponibles',
        color: "#00C0FF",
        data: [this.numeroPrediosDisponiblesDetallado]

      }, {
        name: 'Predios Adquiridos',
        color: "#00BFC4",
        data: [this.numeroPrediosAdquiridosDetallado]

      }, {
        name: 'Predios Ofertados',
        color: "#FC7F25",
        data: [this.numeroPrediosOfertadosDetallado]

      }]
    };

    let chartPredialDetallado = Highcharts.chart("chartPredialDetallado", this.chartPredialDetallado);


  }

  cargarInformacionGeneral(idProyecto: number) {
    forkJoin(
      this.informacionGeneralProyectoService.obtenerInformacionGeneral(idProyecto)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          console.log(result);
          this.proyecto = result.informacionGeneralProyecto;
          console.log(this.proyecto.nombre);
          this.obtenerAgrupadoPredios();
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla

      }
    );

  }

  obtenerAgrupadoVsDetalladoPredios() {
    forkJoin(
      this.reportesPredialService.getPredialAgrupadoVsDetallado(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }
        console.log("PREDIOS AGRUPADOS");
        console.log(data);
        this.agrupadoPredios = data.agrupadoPredios;

        console.log("UNIDAD FUNCIONAL SELECCIONADA");

        console.log(this.unidadFuncional);

        this.unidadesFuncionales = this.agrupadoPredios.map((item) => new UnidadFuncionalDTO(item.nombreUnidadFuncional));

        this.unidadesFuncionales.unshift(new UnidadFuncionalDTO("Todas"));

        this.unidadFuncional = new UnidadFuncionalDTO("Todas");

        console.log(this.unidadesFuncionales);

        this.agrupadoPrediosFiltrado = this.agrupadoPredios;

        this.calcularValoresReporte();


      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.actualizarGraficas();
      }
    );
  }

  calcularValoresReporte() {
    if (this.unidadFuncional.nombre != 'Todas') {
      this.agrupadoPrediosFiltrado = this.agrupadoPredios.filter((item) => item.nombreUnidadFuncional == this.unidadFuncional.nombre);
    } else {
      this.agrupadoPrediosFiltrado = this.agrupadoPredios;
    }

    let arrayFechasAgrupado = this.agrupadoPrediosFiltrado.map((item) => new Date(item.fechaCorteAgrupado));
    let arrayFechasDetallado = this.agrupadoPrediosFiltrado.map((item) => new Date(item.fechaUltimaActualizacionDetallado));

    this.fechaCorteAgrupado = new Date(Math.max.apply(null, arrayFechasAgrupado));
    this.fechaCorteDetallado = new Date(Math.max.apply(null, arrayFechasDetallado));

    if (this.fechaCorteDetallado.getFullYear() === 1969) {
      this.fechaCorteDetallado = null;
    }

    //Calculos predial agrupado
    //Predios requeridos
    const prediosRequeridosAgrupado = this.agrupadoPrediosFiltrado
      .map((item) => item.prediosRequeridosAgrupado)
      .reduce((prev, curr) => prev + curr, 0);
    this.numeroPrediosRequeridosAgrupado = prediosRequeridosAgrupado;

    //Predios disponibles
    const prediosDisponiblesAgrupado = this.agrupadoPrediosFiltrado
      .map((item) => item.prediosDisponiblesAgrupado)
      .reduce((prev, curr) => prev + curr, 0);
    this.numeroPrediosDisponiblesAgrupado = prediosDisponiblesAgrupado;

    //Predios adquiridos
    const prediosAdquiridosAgrupado = this.agrupadoPrediosFiltrado
      .map((item) => item.prediosAdquiridosAgrupado)
      .reduce((prev, curr) => prev + curr, 0);
    this.numeroPrediosAdquiridosAgrupado = prediosAdquiridosAgrupado;

    //Predios ofertados
    const prediosOfertadosAgrupado = this.agrupadoPrediosFiltrado
      .map((item) => item.prediosOfertadosAgrupado)
      .reduce((prev, curr) => prev + curr, 0);
    this.numeroPrediosOfertadosAgrupado = prediosOfertadosAgrupado;

    //Logitud requerida
    const prediosLongitudRequeridaAgrupado = this.agrupadoPrediosFiltrado
      .map((item) => item.longitudRequeridaAgrupado)
      .reduce((prev, curr) => prev + curr, 0);
    this.longitudRequeridaAgrupado = prediosLongitudRequeridaAgrupado / 1000;

    //Longitud disponiblle
    const prediosLongitudDisponibleAgrupado = this.agrupadoPrediosFiltrado
      .map((item) => item.longitudDisponibleAgrupado)
      .reduce((prev, curr) => prev + curr, 0);
    this.longitudDisponibleAgrupado = prediosLongitudDisponibleAgrupado / 1000;

    //Calculos predial detallado
    //Predios requeridos
    const prediosRequeridosDetallado = this.agrupadoPrediosFiltrado
      .map((item) => item.prediosRequeridosDetallado)
      .reduce((prev, curr) => prev + curr, 0);
    this.numeroPrediosRequeridosDetallado = prediosRequeridosDetallado;

    //Predios disponibles
    const prediosDisponiblesDetallado = this.agrupadoPrediosFiltrado
      .map((item) => item.prediosDisponiblesDetallado)
      .reduce((prev, curr) => prev + curr, 0);
    this.numeroPrediosDisponiblesDetallado = prediosDisponiblesDetallado;

    //Predios adquiridos
    const prediosAdquiridosDetallado = this.agrupadoPrediosFiltrado
      .map((item) => item.prediosAdquiridosDetallado)
      .reduce((prev, curr) => prev + curr, 0);
    this.numeroPrediosAdquiridosDetallado = prediosAdquiridosDetallado;

    //Predios ofertados
    const prediosOfertadosDetallado = this.agrupadoPrediosFiltrado
      .map((item) => item.prediosOfertadosDetallado)
      .reduce((prev, curr) => prev + curr, 0);
    this.numeroPrediosOfertadosDetallado = prediosOfertadosDetallado;

    //Logitud requerida
    const prediosLongitudRequeridaDetallado = this.agrupadoPrediosFiltrado
      .map((item) => item.longitudRequeridaDetallado)
      .reduce((prev, curr) => prev + curr, 0);
    this.longitudRequeridaDetallado = prediosLongitudRequeridaDetallado / 1000;

    //Longitud disponiblle
    const prediosLongitudDisponibleDetallado = this.agrupadoPrediosFiltrado
      .map((item) => item.longitudDisponibleDetallado)
      .reduce((prev, curr) => prev + curr, 0);
    this.longitudDisponibleDetallado = prediosLongitudDisponibleDetallado / 1000;

  }

  actionFiltrarUnidadesFuncionales() {
    this.calcularValoresReporte();
    this.actualizarGraficas();
  }

  obtenerAgrupadoPredios() {
    forkJoin(
      this.reportesPredialService.getPredialAgrupadoVsDetallado(this.idProyecto)
    ).subscribe(
      ([data]) => {
        if (data.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: data.respuestaServicio.mensajeSalida,
            life: 10000,
          });
          console.log("errordatos");
          return;
        }
        console.log(data);
        this.agrupadoPredios = data.agrupadoPredios;
        this.agrupadoPredios.forEach(element => {
          this.numeroPrediosDisponiblesAgrupado = this.numeroPrediosDisponiblesAgrupado + element.prediosDisponiblesAgrupado;
          this.numeroPrediosRequeridosAgrupado = this.numeroPrediosRequeridosAgrupado + element.prediosRequeridosAgrupado;
          this.numeroPrediosAdquiridosAgrupado = this.numeroPrediosAdquiridosAgrupado + element.prediosAdquiridosAgrupado;
          this.numeroPrediosOfertadosAgrupado = this.numeroPrediosOfertadosAgrupado + element.prediosOfertadosAgrupado;
          this.numeroPrediosExpropiadosAgrupado = this.numeroPrediosExpropiadosAgrupado + element.prediosExpropiadosAgrupado;
          this.longitudDisponibleAgrupado = this.longitudDisponibleAgrupado + element.longitudDisponibleAgrupado;
          this.longitudRequeridaAgrupado = this.longitudRequeridaAgrupado + element.longitudRequeridaAgrupado;
        });
        this.porcentajePrediosDisponiblesAgrupado = (this.numeroPrediosDisponiblesAgrupado / this.numeroPrediosRequeridosAgrupado);
        this.porcentajePrediosAdquiridosAgrupado = (this.numeroPrediosAdquiridosAgrupado / this.numeroPrediosDisponiblesAgrupado);
        this.porcentajeLongitudDisponibleAgrupado = (this.longitudDisponibleAgrupado / this.longitudRequeridaAgrupado);
        this.valoresPrediosUnidadFuncionalAgrupado = {
          unidadFuncional: this.agrupadoPredios.map(item => item.nombreUnidadFuncional),
          prediosRequeridos: this.agrupadoPredios.map(item => item.prediosRequeridosAgrupado),
          prediosDisponibles: this.agrupadoPredios.map(item => item.prediosDisponiblesAgrupado),
          prediosAdquiridos: this.agrupadoPredios.map(item => item.prediosAdquiridosAgrupado),
          prediosOfertados: this.agrupadoPredios.map(item => item.prediosOfertadosAgrupado),
          prediosExpropiados: this.agrupadoPredios.map(item => item.prediosExpropiadosAgrupado)
        }
        this.agrupadoPredios.forEach(element => {
          this.numeroPrediosDisponiblesDetallado = this.numeroPrediosDisponiblesDetallado + element.prediosDisponiblesDetallado;
          this.numeroPrediosRequeridosDetallado = this.numeroPrediosRequeridosDetallado + element.prediosRequeridosDetallado;
          this.numeroPrediosAdquiridosDetallado = this.numeroPrediosAdquiridosDetallado + element.prediosAdquiridosDetallado;
          this.numeroPrediosOfertadosDetallado = this.numeroPrediosOfertadosDetallado + element.prediosOfertadosDetallado;
          //this.numeroPrediosExpropiadosDetallado = this.numeroPrediosExpropiadosDetallado + element.prediosExpropiados;
          //this.longitudDisponibleDetallado = this.longitudDisponibleDetallado + element.lo
          //this.longitudRequeridaDetallado = this.longitudRequeridaDetallado + element.longitud
        });
        this.porcentajePrediosDisponiblesDetallado = (this.numeroPrediosDisponiblesDetallado / this.numeroPrediosRequeridosDetallado);
        this.porcentajePrediosAdquiridosDetallado = (this.numeroPrediosAdquiridosDetallado / this.numeroPrediosDisponiblesDetallado);
        this.porcentajeLogintudDisponibleDetallado = (this.longitudDisponibleDetallado / this.longitudRequeridaDetallado);
        this.valoresPrediosUnidadFuncionalDetallado = {
          unidadFuncional: this.agrupadoPredios.map(item => item.nombreUnidadFuncional),
          prediosRequeridos: this.agrupadoPredios.map(item => item.prediosRequeridosDetallado),
          prediosDisponibles: this.agrupadoPredios.map(item => item.prediosDisponiblesDetallado),
          prediosAdquiridos: this.agrupadoPredios.map(item => item.prediosAdquiridosDetallado),
          prediosOfertados: this.agrupadoPredios.map(item => item.prediosOfertadosDetallado)
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.cargarGraficasPredialAgrupado();
        this.cargarGraficasPredialDetallado();
      }
    );
  }

  cargarGraficasPredialAgrupado() {
    //ocho
    this.optionschart12 = {
      chart: {
        type: "column",
      },
      credits: { enabled: false },
      title: {
        text: "Disponibilidad Predial por Proyecto",
      },
      xAxis: {
        categories: [
          this.proyecto.nombre,
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:,.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Predios Requeridos",
          color: "#313CBC",
          data: [
            this.numeroPrediosRequeridosAgrupado,
          ],
        },
        {
          name: "Predios Disponibles",
          color: "#00C0FF",
          data: [
            this.numeroPrediosDisponiblesAgrupado,
          ],
        },
        {
          name: "Predios Adquiridos",
          color: "#00BFC4",
          data: [
            this.numeroPrediosAdquiridosAgrupado,
          ],
        },
        {
          name: "Predios Ofertados",
          color: "#FC7F25",
          data: [
            this.numeroPrediosOfertadosAgrupado,
          ],
        },
        {
          name: "Predios adquiridos por expropiación",
          color: "#FFC438",
          data: [
            this.numeroPrediosExpropiadosAgrupado,
          ],
        }
      ],
    };

    let chart12 = Highcharts.chart("chart12", this.optionschart12);
    //nueve

    this.optionschart13 = {
      chart: {
        type: "column",
      },
      credits: { enabled: false },
      title: {
        text: "Longitud Requerida y Longitud Disponible",
      },
      xAxis: {
        categories: [
          this.proyecto.nombre,
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:,.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Longitud Requerida ",
          color: "#00c0ff",
          data: [
            this.longitudRequeridaAgrupado,
          ],
        },
        {
          name: "Longitud Disponible",
          color: "#11528a",
          data: [
            this.longitudDisponibleAgrupado,
          ],
        }
      ],
    };
    let chart13 = Highcharts.chart("chart13", this.optionschart13);

    this.optionschart14 = {
      chart: {
        type: "column",
      },
      credits: { enabled: false },
      title: {
        text: "Disponibilidad Predial por Unidad Funcional",
      },
      xAxis: {
        categories: this.valoresPrediosUnidadFuncionalAgrupado.unidadFuncional,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:,.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Predios Requeridos",
          color: "#313CBC",
          data: this.valoresPrediosUnidadFuncionalAgrupado.prediosRequeridos,
        },
        {
          name: "Predios Disponibles",
          color: "#00C0FF",
          data: this.valoresPrediosUnidadFuncionalAgrupado.prediosDisponibles,
        },
        {
          name: "Predios Adquiridos",
          color: "#00BFC4",
          data: this.valoresPrediosUnidadFuncionalAgrupado.prediosAdquiridos,
        },
        {
          name: "Predios Ofertados",
          color: "#FC7F25",
          data: this.valoresPrediosUnidadFuncionalAgrupado.prediosOfertados,
        },
        {
          name: "Predios adquiridos por expropiación",
          color: "#FFC438",
          data: this.valoresPrediosUnidadFuncionalAgrupado.prediosExpropiados,
        }
      ],
    };
    let chart14 = Highcharts.chart("chart14", this.optionschart14);
  }

  cargarGraficasPredialDetallado() {
    //ocho
    this.optionschart15 = {
      chart: {
        type: "column",
      },
      credits: { enabled: false },
      title: {
        text: "Disponibilidad Predial por Proyecto",
      },
      xAxis: {
        categories: [
          this.proyecto.nombre,
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:,.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Predios Requeridos",
          color: "#313CBC",
          data: [
            this.numeroPrediosRequeridosDetallado,
          ],
        },
        {
          name: "Predios Disponibles",
          color: "#00C0FF",
          data: [
            this.numeroPrediosDisponiblesDetallado,
          ],
        },
        {
          name: "Predios Adquiridos",
          color: "#00BFC4",
          data: [
            this.numeroPrediosAdquiridosDetallado,
          ],
        },
        {
          name: "Predios Ofertados",
          color: "#FC7F25",
          data: [
            this.numeroPrediosOfertadosDetallado,
          ],
        },
        {
          name: "Predios adquiridos por expropiación",
          color: "#FFC438",
          data: [
            this.numeroPrediosExpropiadosDetallado,
          ],
        }
      ],
    };

    let chart15 = Highcharts.chart("chart15", this.optionschart15);
    //nueve

    this.optionschart16 = {
      chart: {
        type: "column",
      },
      credits: { enabled: false },
      title: {
        text: "Longitud Requerida y Longitud Disponible",
      },
      xAxis: {
        categories: [
          this.proyecto.nombre,
        ],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:,.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Longitud Requerida ",
          color: "#00c0ff",
          data: [
            this.longitudRequeridaDetallado,
          ],
        },
        {
          name: "Longitud Disponible",
          color: "#11528a",
          data: [
            this.longitudDisponibleDetallado,
          ],
        }
      ],
    };
    let chart16 = Highcharts.chart("chart16", this.optionschart16);

    this.optionschart17 = {
      chart: {
        type: "column",
      },
      credits: { enabled: false },
      title: {
        text: "Disponibilidad Predial por Unidad Funcional",
      },
      xAxis: {
        categories: this.valoresPrediosUnidadFuncionalDetallado.unidadFuncional,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "",
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:,.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "Predios Requeridos",
          color: "#313CBC",
          data: this.valoresPrediosUnidadFuncionalDetallado.prediosRequeridos,
        },
        {
          name: "Predios Disponibles",
          color: "#00C0FF",
          data: this.valoresPrediosUnidadFuncionalDetallado.prediosDisponibles,
        },
        {
          name: "Predios Adquiridos",
          color: "#00BFC4",
          data: this.valoresPrediosUnidadFuncionalDetallado.prediosAdquiridos,
        },
        {
          name: "Predios Ofertados",
          color: "#FC7F25",
          data: this.valoresPrediosUnidadFuncionalDetallado.prediosOfertados,
        },
        {
          name: "Predios adquiridos por expropiación",
          color: "#FFC438",
          data: this.valoresPrediosUnidadFuncionalDetallado.prediosExpropiados,
        }
      ],
    };
    let chart17 = Highcharts.chart("chart17", this.optionschart17);
  }

  init() {



  }

  formatoMoneda(valor: number) {
    return valor
      ? "$" +
      (valor / 1000000).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") +
      " mill."
      : "$0";
  }



}
