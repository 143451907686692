import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { EjecucionDTO, CrrEtapaEjecutadaUfDTO, CrrEtapaEjecutadaIntervencionUfDTO, CrrEtapaEjecutadaProyectoDTO } from './ejecucion-proyecto.dto';
import { TrnRespuestaServicio } from '../parametricas/trn-respuesta-servicio';


@Injectable({
  providedIn: 'root'
})
export class EjecucionProyectoService {

  private mensajeCompartidoSource = new BehaviorSubject('default message');
  mensajeCompartido = this.mensajeCompartidoSource.asObservable();

  public END_POINT = '';

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  intercambiarMensaje(mensaje: string) {
    this.mensajeCompartidoSource.next(mensaje)
  }

  public srvAvanceUfPorProyecto(idProyecto: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceUfPorProyecto/' + idProyecto)
      .pipe(
        retry(1));
  }

  public srvAvanceUfPorEjecucion(idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceUfPorEjecucion/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvAvanceUfPublicadoPorEjecucion(idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceUfPublicadoPorEjecucion/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvAvanceUfPublicadoConEtapasPorEjecucion(idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceUfPublicadoConEtapasPorEjecucion/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvAvanceUfPublicadoConEtapasPorEjecucionYEtapa(idEjecucion: number, idEtapa: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceUfPublicadoConEtapasPorEjecucionYEtapa/' + idEjecucion + '/' + idEtapa)
      .pipe(
        retry(1));
  }

  //////////////////////////////AVANCE INTERVENCIONES////////////////////////////////////////////
  public srvAvanceIntervencionUfDepartamento(idUnidadFuncional: number, idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceIntervencionUfDepartamento/' + idUnidadFuncional + '/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvAvanceIntervencionUf(idUnidadFuncional: number, idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceIntervencionUf/' + idUnidadFuncional + '/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvAvanceIntervencionUfPublicado(idUnidadFuncional: number, idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceIntervencionUfPublicado/' + idUnidadFuncional + '/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvAvanceIntervencionUfPublicadoConEtapasPorUfYEjecucion(idUnidadFuncional: number, idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceIntervencionUfPublicadoConEtapasPorUfYEjecucion/' + idUnidadFuncional + '/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvAvanceIntervencionUfPublicadoConEtapasPorUfYEjecucionYEtapa(idUnidadFuncional: number, idEjecucion: number, idEtapa: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceIntervencionUfPublicadoConEtapasPorUfYEjecucionYEtapa/' + idUnidadFuncional + '/' + idEjecucion + '/' + idEtapa)
      .pipe(
        retry(1));
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////

  public srvAvanceProyecto(idProyecto: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceProyecto/' + idProyecto)
      .pipe(
        retry(1));
  }

  public srvAvanceProyectoPublicado(idProyecto: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/avanceProyectoPublicado/' + idProyecto)
      .pipe(
        retry(1));
  }

  public guardarEjecucion(ejecucionDTO: EjecucionDTO) {
    return this.http.post(this.END_POINT + '/CrrAvanceYEtapas/guardarEjecucion', ejecucionDTO)
      .pipe(
        retry(1));
  }

  public actualizaTablasAvance(idProyecto: number): Observable<RespuestaGenericaAvanceRpt> {
    return this.http.post(this.END_POINT + '/CrrAvanceYEtapas/ActualizaTablasAvance/' + idProyecto, idProyecto)
      .pipe(
        retry(1));
  }

  

  public crearEjecucion(ejecucionDTO: EjecucionDTO) {
    return this.http.post(this.END_POINT + '/CrrAvanceYEtapas/crearEjecucion', ejecucionDTO)
      .pipe(
        retry(1));
  }

  public srvGuardarEtapaEjecutadaUf(crrEtapaEjecutadaUfDTO: CrrEtapaEjecutadaUfDTO) {
    return this.http.post(this.END_POINT + '/CrrAvanceYEtapas/guardarEtapaEjecutadaUf', crrEtapaEjecutadaUfDTO)
      .pipe(
        retry(1));
  }

  public srvListarEtapaEjecutadaUf(idUnidadFuncional: number, idEtapa: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/listarEtapaEjecutadaUf/' + idUnidadFuncional + '/' + idEtapa)
      .pipe(
        retry(1));
  }

  public srvGuardarEtapaEjecutadaIntervencionUf(crrEtapaEjecutadaIntervencionUfDTO: CrrEtapaEjecutadaIntervencionUfDTO) {
    return this.http.post(this.END_POINT + '/CrrAvanceYEtapas/guardarEtapaEjecutadaIntervencionUf', crrEtapaEjecutadaIntervencionUfDTO)
      .pipe(
        retry(1));
  }

  public srvListarEtapaEjecutadaIntervencionUf(idIntervencionUf: number, idEtapa: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/listarEtapaEjecutadaIntervencionUf/' + idIntervencionUf + '/' + idEtapa)
      .pipe(
        retry(1));
  }

  public srvGuardarEtapaEjecutadaProyecto(crrEtapaEjecutadaProyectoDTO: CrrEtapaEjecutadaProyectoDTO) {
    return this.http.post(this.END_POINT + '/CrrAvanceYEtapas/guardarEtapaEjecutadaProyecto', crrEtapaEjecutadaProyectoDTO)
      .pipe(
        retry(1));
  }

  public srvListarEtapaEjecutadaProyecto(idUnidadFuncional: number, idEtapa: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/listarEtapaEjecutadaProyecto/' + idUnidadFuncional + '/' + idEtapa)
      .pipe(
        retry(1));
  }

  public srvCambioConfiguracionProyecto(idProyecto: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/cambioConfiguracionProyecto/' + idProyecto)
      .pipe(
        retry(1));
  }

  /////////////////////////////ETAPAS///////////////////////////////////////////////////////
  public srvEtapaConstruccionUnidadesFuncionales(idProyecto: number, idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/etapaConstruccionUnidadesFuncionales/' + idProyecto + '/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvEtapaOperYMantUnidadesFuncionales(idProyecto: number, idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/etapaOperYMantUnidadesFuncionales/' + idProyecto + '/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvEtapaConstruccionIntervenciones(idUnidadFuncional: number, idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/etapaConstruccionIntervenciones/' + idUnidadFuncional + '/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvEtapaOperYMantIntervenciones(idUnidadFuncional: number, idEjecucion: number): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/etapaOperYMantIntervenciones/' + idUnidadFuncional + '/' + idEjecucion)
      .pipe(
        retry(1));
  }

  public srvObtenerConstantePorcentajeMinimo(): Observable<any> {
    return this.http.get(this.END_POINT + '/CrrAvanceYEtapas/obtenerConstantePorcentajeMinimo/')
      .pipe(
        retry(1));
  }

}

export class RespuestaGenericaAvanceRpt {
  constructor(public respuestaServicio?: TrnRespuestaServicio) { }
}
