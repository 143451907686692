import { TrnRespuestaServicio } from "../../../parametricas/trn-respuesta-servicio";


export class PjeEncabezadoTarifaRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public encabezadoTarifa?: PjeEncabezadoTarifa[],
    ) { }
}

export class PjeEncabezadoTarifa {
    constructor(
        public idEncabezado?: number,
        public idPeaje?: number,
        public nombrePeaje?: string,
        public idTipoNorma?: number,
        public descripcionNorma?: string,
        public tarifaFsv?: number,
        public rutaDocumentoNorma?: string,
        public fechaInicio?: Date,
        public idUsuarioActualizacion?: number,
        public numeroDocumento?: string,
        public fechaExpedicionDocumento?: Date,
        public idTipoDocumentoSeleccionado?: string


    ) { }
}