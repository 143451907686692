import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { forkJoin, Subscription } from "rxjs";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { UnidadFuncionalCarreteroService } from "src/app/unidad-funcional-carretero/unidad-funcional-carretero.service";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { PrediosService } from "../../predios.service";

@Component({
  selector: "app-consultar-localizacion-predio",
  templateUrl: "./consultar-localizacion-predio.component.html",
  styleUrls: ["./consultar-localizacion-predio.component.sass"],
})
export class ConsultarLocalizacionPredioComponent implements OnInit {
  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  idPredio: number;
  fichaPredial: string;
  esBorrador;

  rutaIframe: string;

  constructor(
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private prediosService: PrediosService,
    private principalComponent: PrincipalComponent
  ) {}

  ngOnInit() {
    // Obtener parametros
    const idParam = "id";
    const idPredio = "idPredio";
    const borrador = "borrador";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.idPredio = params[idPredio];
      this.esBorrador = params[borrador];
      console.log("param idPredio-------->", idPredio);
    });

    this.inicializar();
  }

  inicializar() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.prediosService.srvObtenerPredioPorIdYBorrador(this.idPredio, this.esBorrador)
    ).subscribe(
      ([proyectoData, predioData]) => {
        this.proyectoSeleccionado = proyectoData;
        console.log("predioData", predioData);
        this.fichaPredial = predioData.predio.fichaPredial;
        this.rutaIframe =
          "https://animapas.maps.arcgis.com/apps/webappviewer/index.html?id=08625d26135c4d95b2c8a83e6517bf90&query=PrediosServicio_3375%2CFichaPredial%2C" +
          predioData.predio.fichaPredial;
        console.log("rutaIframe", this.rutaIframe);
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }
}
