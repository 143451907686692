import { Component, OnInit, } from '@angular/core';

@Component({
    selector: 's-youtube-home-public',
    templateUrl: './youtube-home-public.component.html',
    styleUrls: ['./youtube-home-public.component.css']
})
export class YoutubeHomePublicComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
}
