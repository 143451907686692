import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { forkJoin } from 'rxjs';
import {
  TramiteAmbientalDTO, UnidadFuncionalDTO, EstadoTramitelDTO, PriorizacionDTO,
  ResponsableEntidadDTO, ObservacionesDTO
} from '../tramites-ambientales-carretero.dto.js';
import { TramitesAmbientalesService } from '../tramites-ambientales-carretero.service.js';


@Component({
  selector: 'app-tramites-ambientales-seguimiento-carretero',
  templateUrl: './tramites-ambientales-seguimiento-carretero.component.html',
  styleUrls: ['./tramites-ambientales-seguimiento-carretero.component.sass']
})
export class TramitesAmbientalesSeguimientoCarreteroComponent implements OnInit {

  loading: boolean;
  idProyecto: number;
  idTramiteAmbiental: number;
  proyectoSeleccionado: ProyectoDTO;

  nombreProyecto;
  tramiteAmbiental: TramiteAmbientalDTO;
  unidadFuncional: UnidadFuncionalDTO;
  dialogoEliminar: boolean;

  //Listas en la presentacion
  unidadesFuncionalesAsociadasList: UnidadFuncionalDTO[];
  seguimientoEstados: EstadoTramitelDTO[];
  seguimientoPriorizaciones: PriorizacionDTO[];
  seguimientoResponsables: ResponsableEntidadDTO[];
  seguimientoObservaciones: ObservacionesDTO[];

  //TODO: BORRAR fechaHoy CUANDO SE TENGA LOS SERVICIOS
  fechaHoy: Date

  constructor(
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private tramitesAmbientalesService: TramitesAmbientalesService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.loading = true;
    this.tramiteAmbiental = {};
    const idParamProyecto = "idProyecto";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParamProyecto];
    });

    const idParamTramite = "idTramite";
    this.ruta.params.subscribe((params) => {
      this.idTramiteAmbiental = params[idParamTramite];
    });

    this.cargarTramiteAmbientalPorId();


    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
      },
      (e) => { }
    );


    this.loading = false;

  }

  /**
   * Metodo que consulta la información del tramite ambiental por id
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarTramiteAmbientalPorId() {
    this.loading = true;
    return this.tramitesAmbientalesService
      .srvConsultarTramiteAmbientalPorId(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.tramiteAmbiental = result.tramiteAmbiental;
        console.log(this.tramiteAmbiental);

        if (this.tramiteAmbiental.fechaRadicado != null) {
          this.tramiteAmbiental.fechaRadicado = new Date(this.tramiteAmbiental.fechaRadicado);
        }

        if (this.tramiteAmbiental.fechaRadicadoEntidad != null) {
          this.tramiteAmbiental.fechaRadicadoEntidad = new Date(this.tramiteAmbiental.fechaRadicadoEntidad);
        }

        this.cargarUnidadesFuncionales();
        this.cargarEstados();
        this.cargarPriorizaciones();
        this.cargarResponsables();
        this.cargarObservaciones();

        if (this.tramiteAmbiental === null) {
          this.tramiteAmbiental = {};
        }
        this.loading = false;
      });
  }

  /**
   * Metodo que consulta la información de unidades funcionales por tramite
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarUnidadesFuncionales() {
    return this.tramitesAmbientalesService
      .srvListarUnidadesFuncionalesPorIdTramite(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.unidadesFuncionalesAsociadasList = result.listaUnidadesFuncionales;

        if (this.unidadesFuncionalesAsociadasList === null) {
          this.unidadesFuncionalesAsociadasList = [];
        }


      });
  }

  /**
   * Metodo que consulta la información de estados por tramite
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarEstados() {
    return this.tramitesAmbientalesService
      .srvListarEstadosPorIdTramite(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.seguimientoEstados = result.listaEstados;

        if (this.seguimientoEstados === null) {
          this.seguimientoEstados = [];
        }


      });
  }

  /**
   * Metodo que consulta la información de priorizaciones por tramite
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarPriorizaciones() {
    return this.tramitesAmbientalesService
      .srvListarPriorizacionesPorIdTramite(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.seguimientoPriorizaciones = result.listaPriorizaciones;

        if (this.seguimientoPriorizaciones === null) {
          this.seguimientoPriorizaciones = [];
        }


      });
  }

  /**
   * Metodo que consulta la información de responsables por tramite
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarResponsables() {
    return this.tramitesAmbientalesService
      .srvListarResponsablesPorIdTramite(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.seguimientoResponsables = result.listaResponsables;

        if (this.seguimientoResponsables === null) {
          this.seguimientoResponsables = [];
        }


      });
  }

  /**
   * Metodo que consulta la información de observaciones por tramite
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarObservaciones() {
    return this.tramitesAmbientalesService
      .srvListarObservacionesPorIdTramite(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.seguimientoObservaciones = result.listaObservaciones;

        if (this.seguimientoObservaciones === null) {
          this.seguimientoObservaciones = [];
        }


      });
  }



  /**
   * metodo que devuelve la pagina
   */
  bntVolver() {
    const ruta =
      "carreteros/infGeneral/proyectoHome/" +
      this.idProyecto +
      "/tramites-carretero-listado/" +
      this.idProyecto;
    this.router.navigate([ruta]);
  }

}
