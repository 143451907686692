import { Injectable } from '@angular/core';
import { map, retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PjeArchivoCargadoRpt, PjeArchivoCargado } from '../PjeArchivoCargado';
import { PjePeajePorProyectoRpt, PjePeajePorProyecto } from '../PjePeajePorProyecto';
import { ConfigService } from '../../../config.service';
import { environment } from '../../../../environments/environment';
import { TrnRespuestaServicio } from '../../../parametricas/trn-respuesta-servicio';


@Injectable({
    providedIn: 'root'
})
export class TraficoPeajesCargaService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    getArchivosPorIdPeaje(idPeaje: number): Observable<PjeArchivoCargadoRpt> {
        return this.http.get<PjeArchivoCargadoRpt>(this.END_POINT + '/PjeTraficoPeaje/GetArchivosPorIdPeaje/' + idPeaje)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    getPeajes(idProyecto: number): Observable<PjePeajePorProyectoRpt> {
        return this.http.get<PjePeajePorProyectoRpt>(this.END_POINT + '/PjeTraficoPeaje/GetPeajes/' + idProyecto)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));

    }

    validarFechas(fechaDesdeParam: string, fechaHastaParam: string, idPeajeParam: string, datas) {
        return this.http.post(this.END_POINT + '/PjeTraficoPeaje/validaRangoFechas/' + fechaDesdeParam + '/' + fechaHastaParam + '/' + idPeajeParam, datas)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }



    public guardarArchivoTrafico(data) {
        return this.http.post(this.END_POINT + '/PjeTraficoPeaje/ProcesarArchivoPeaje', data)
            .pipe(
                retry(1));
    }

    verAchivo(idArchivo: string, contenedor: string) {

        this.http.post(environment.baseUrl + '/PjeTraficoPeaje/getFileError', { nombre: idArchivo, nombreContenedor: contenedor },
            {
                responseType: 'arraybuffer'
            }
        ).subscribe(response => this.downLoadFile(response, 'application/vnd.ms-excel'));
    }

    downLoadFile(data: any, type: string) {

        let blob = new Blob([data], { type: type });
        let url = window.URL.createObjectURL(blob);
        let pwa = window.open(url);
        if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
            alert('Please disable your Pop-up blocker and try again.');
        }
    }

    anularArchivo(idArchivo: string): Observable<RespuestaServicioRpt> {
        return this.http.put(this.END_POINT + '/PjeTraficoPeaje/anularArchivo/' + idArchivo, idArchivo)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }
}

export class RespuestaServicioRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}
