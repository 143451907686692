import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ComponentesComunesService } from '../componentes-comunes.service';
import { EjecucionDTO, VwAvanceProyectoDTO } from 'src/app/avance-proyecto/ejecucion-proyecto.dto';
import { EjecucionProyectoService } from 'src/app/avance-proyecto/ejecucion_proyecto.service';

@Component({
  selector: 'app-seleccionar-ejecucion',
  templateUrl: './seleccionar-ejecucion.component.html',
  styleUrls: ['./seleccionar-ejecucion.component.sass']
})
export class SeleccionarEjecucionComponent implements OnInit {

  public ejecucionDTOList: VwAvanceProyectoDTO[];
  public ejecucionSeleccionado: VwAvanceProyectoDTO;
  public ejecucionMasReciente: VwAvanceProyectoDTO;

  public ejecucionEmitir: EjecucionDTO;

  // Usamos el decorador Output
  @Output() OutputEjecucionSeleccionado = new EventEmitter();
  @Input() idProyecto: number;

  @Output() OutputFechaEjecucionMasReciente = new EventEmitter();
  @Output() OutputYaCargoComponente = new EventEmitter();

  constructor(private route: Router, private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private ejecucionProyectoService: EjecucionProyectoService) { }

  ngOnInit() {
    if (this.idProyecto != null) {
      this.listarEjecucionesPorProyecto(this.idProyecto);
    }
  }

  listarEjecucionesPorProyecto(_idProyecto: number) {
    return this.ejecucionProyectoService.srvAvanceProyecto(_idProyecto).subscribe(data => {
      this.ejecucionDTOList = data;
      if (this.ejecucionDTOList != null && this.ejecucionDTOList.length > 0) {
        this.ejecucionMasReciente = this.ejecucionDTOList[0];

        //Obtener la fecha minima de reporte mas 1 dia
        let fechaMinReporte = new Date(this.ejecucionMasReciente.fechaReporte);
        let minDateFechaReporte = new Date(fechaMinReporte.getFullYear(),
          fechaMinReporte.getMonth(), fechaMinReporte.getDate() + 1, 0, 0, 0, 0);

        //Emitir la minDateFechaReporte afuera del componente
        this.OutputFechaEjecucionMasReciente.emit(minDateFechaReporte);
        this.OutputYaCargoComponente.emit("");
      }
    });
  }

  rowSelectEjecucion(_ejecucionSeleccionado: VwAvanceProyectoDTO) {
    //Aca se convierte de VwAvanceProyectoDTO a EjecucionDTO
    this.ejecucionEmitir = {};
    this.ejecucionEmitir.id = _ejecucionSeleccionado.idEjecucion;
    this.ejecucionEmitir.idProyecto = _ejecucionSeleccionado.idProyecto;
    this.ejecucionEmitir.publicado = _ejecucionSeleccionado.publicado;
    this.ejecucionEmitir.fechaReporte = _ejecucionSeleccionado.fechaReporte;
    //Emitir el objeto EjecucionDTO afuera del componente
    this.OutputEjecucionSeleccionado.emit(this.ejecucionEmitir);
  }

}
