import { Directive, OnInit, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDisableLinkA]'
})
export class DisableLinkADirectiveDirective implements OnInit {
  @Input('appDisableLinkA') disabled: string; // Required permission passed in
  constructor(private el: ElementRef) { }

  ngOnInit() {
    if (this.disabled) {
      this.deshabilitar()
    }
  }

  deshabilitar() {
    this.el.nativeElement.disabled = true;
    this.el.nativeElement.className += ' estiloBotonDeshabilitado'
  }

}
