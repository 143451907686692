import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-avance-proyecto-menus',
  templateUrl: './avance-proyecto-menus.component.html',
  styleUrls: ['./avance-proyecto-menus.component.sass']
})
export class AvanceProyectoMenusComponent implements OnInit {

  vistaActual: string = "";

  @Output() OutputIrAReportarAvance = new EventEmitter();
  @Output() OutputIrAReportarEtapas = new EventEmitter();

  /**
  
  **/

  constructor() { }

  ngOnInit() {
    this.vistaActual = "menuPrincipal";
  }

  irAReportarAvance() {
    this.OutputIrAReportarAvance.emit("");
  }

  irAReportarAEtapas() {
    this.OutputIrAReportarEtapas.emit("");
  }

  /**
  
  **/

}
