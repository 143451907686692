import { TrnRespuestaServicio } from "../../parametricas/trn-respuesta-servicio";


export class PjeMaOperadorPagoElectronicoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public operadoresPeaje?: PjeMaOperadorPagoElectronico[],
    ) { }
}

export class PjeMaOperadorPagoElectronico {
    constructor(
        public id?: number,
        public cc?: string,
        public activo?: boolean,
        public usuarioActualizacion?: number,
        public fechaInicioSistema?: Date,
        public fechaFinSistema?: Date
    ) { }
}
