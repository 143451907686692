import { PrsFuncionario } from "../admin-personas/prs-funcionario";

export class TrnDependenciaAni {
    constructor(
        public id?: number,
        public nombre?: string,
        public idDependenciaPrimerNivel?: number
    ) { }
}

export interface TrnDependenciaFuncionarioAni {
    dependencia?: any,
    funcionarioEncargado?: PrsFuncionario,
    funcionarioTitular?: PrsFuncionario,
    vistoBueno?: PrsFuncionario
}

export class VwTrnDependenciaAni {
    constructor(
        public id?: number,
        public nombre?: string,
        public nombreSegundoNivel?: string,
        public sigla?: string,
        public tipo?: string,
        public idDependenciaPadre?: number,
        public dependenciaPadre?: string,
        public idDependenciaPrimerNivel?: number,
        public dependenciaPrimerNivel?: string,
        public nivel?: number
    ) { }
}

export enum EnumTrnDependenciaAni {
    TRN_DEPENDENCIA_ANI_PRESIDENCIA_SIGLA = 'PRE',
    TRN_DEPENDENCIA_ANI_PRESIDENCIA_TIPO = 'P',

    TRN_DEPENDENCIA_ANI_VICEPRESIDENCIA_TIPO = "V",
    TRN_DEPENDENCIA_ANI_OFICINA_TIPO = "O",

    TRN_DEPENDENCIA_ANI_GRUPO_INTERNO_TIPO = 'GIT'
}

