import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { PredialDetalleService } from "./predial-detalle.service";
import { MatTableDataSource } from "@angular/material";
import { PrincipalComponent } from "src/app/home/principal/principal.component";

@Component({
  selector: "app-predial-detalle",
  templateUrl: "./predial-detalle.component.html",
  styleUrls: ["./predial-detalle.component.sass"],
})
export class PredialDetalleComponent implements OnInit {
  proyecto: any = [];
  id: any;
  enableAvance = false;
  loading: boolean;
  dataSource: MatTableDataSource<any>;

  constructor(
    private detalleService: PredialDetalleService,
    private route: ActivatedRoute,
    private ruta: Router,
    private principalComponent: PrincipalComponent
  ) {}

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe((params) => {
      this.id = params.id;
      this.proyecto = JSON.parse(localStorage.getItem("dataPredial"));
      this.proyecto.fechaCorte = new Date(this.proyecto.fechaCorte);
      this.proyecto.fechaObtuvoDisponibilidad = new Date(this.proyecto.fechaObtuvoDisponibilidad );
      this.proyecto.fechaAsociadaTecnicoAcargo = new Date(this.proyecto.fechaAsociadaTecnicoAcargo );
      const temp =
        (this.proyecto.areaDisponible /
          (this.proyecto.areaRequerida ? this.proyecto.areaRequerida : 1)) *
        100;
      temp >= 40 ? (this.enableAvance = false) : (this.enableAvance = false); ///habilitar a true en :
      this.loading = false;
    });
  }

  guardarDetalle() {
    if (this.proyecto.areaAdquirida >= this.proyecto.prediosEnajenados) {
      this.proyecto.longitudEfectivaPredialTotal = this.proyecto.longitudRequerida;
      if (this.id === "new") {
        this.loading = true;
        return this.detalleService.guardarNuevoPredial(this.proyecto).subscribe(
          (data: {}) => {
            this.loading = false;
          },
          (error) => {
            this.principalComponent.cargarErrorServicio(error);
          }
        );
      } else {
        this.proyecto.fechaCorte = this.convertDateToTime(
          this.proyecto.fechaCorte
        );
        this.proyecto.fechaObtuvoDisponibilidad = this.convertDateToTime(
          this.proyecto.fechaObtuvoDisponibilidad
        );
        this.loading = true;
        return this.detalleService
          .guardarDetallePredial(this.proyecto)
          .subscribe(
            (data: {}) => {
              this.loading = false;
              this.ruta.navigate([
                "/carreteros/infGeneral/proyectoHome/" +
                  this.id +
                  "/infPredial/",
                this.id,
              ]);
              this.removeData();
            },
            (error) => {
              this.principalComponent.cargarErrorServicio(error);
            }
          );
      }
    } //else {
    //alert( 'Los predios adquiridos no pueden ser mayor a los enajenados');
    //}
  }

  cancelarDetalle() {
    this.ruta.navigate([
      "/carreteros/infGeneral/proyectoHome/" + this.id + "/infPredial/",
      this.id,
    ]);
    this.removeData();
  }

  removeData() {
    localStorage.removeItem("dataPredial");
  }

  convertDateToTime(_date) {
    let date = new Date(_date);
    let localOffset = date.getTimezoneOffset() * 60000;
    let utc = date.getTime() + localOffset;
    return utc;
  }

  calcularPorcentajes() {
    this.proyecto.porcentajePredioDisponible =
      this.proyecto.areaDisponible / this.proyecto.areaRequerida;
    this.proyecto.porcentajeDeLongitudEfectivaDisponible =
      this.proyecto.longitudDisponible / this.proyecto.longitudEfectivaPredialTotal;
    this.proyecto.totalPredioAdquirido =
      this.proyecto.prediosEnajenados + this.proyecto.prediosAdquiridosEnajenacionVoluntaria;
    this.proyecto.porcentajePorEnajenacion =
      this.proyecto.prediosAdquiridosEnajenacionVoluntaria / this.proyecto.areaRequerida;
    this.proyecto.porcentajePrediosExpropiacion =
      this.proyecto.prediosEnajenados / this.proyecto.areaRequerida;
    this.proyecto.porcentajeTotalPrediosAdquiridos =
      this.proyecto.totalPredioAdquirido / this.proyecto.areaRequerida;
    this.proyecto.prediosFaltantesPorAdquirir =
      this.proyecto.areaRequerida - this.proyecto.totalPredioAdquirido;
    this.proyecto.porcentajesPrediosFaltantesPorAdquirir =
      this.proyecto.prediosFaltantesPorAdquirir / this.proyecto.areaRequerida;
  }
}
