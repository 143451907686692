import { Component, OnInit, } from '@angular/core';
import { ListadoRadicacionWebService, } from './listado-radicacion-web.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { InformacionGeneral, InformacionConsultaGeneral } from './informacionGeneral';



@Component({
    selector: 's-listado-radicacion-web',
    templateUrl: './listado-radicacion-web.component.html',
    styleUrls: ['./listado-radicacion-web.component.sass','./listado-radicacion-web.component.css'],
})
export class ListadoRadicacionWebComponent implements OnInit {

    loading: boolean;
    visibleDialogoRespuesta: boolean;
    visibleConfirmarElimnacion: boolean;
    visibleConfirmarRadicacion: boolean;
    mostrarResultadoTabla: boolean = true;
    numeroRadicado: any = null;
    listadoTemp: any = null;

    displayedColumns1;
    displayedColumns2;
    idProyecto: number;
    volverHomeMenuProyectos: any;
    listadoBorradores: any = [];
    listadoFinalizados: any = [];
    listadoFirmantes: any = [];
    temporal: any; 
    idEliminarBorrador: any; 
    informacionGeneral: InformacionGeneral = {};

    informacionConsultaGeneral: InformacionConsultaGeneral = {};
    listadoRespuestas: any = [];
    archivos: any = [];
    respuestas: any = { anexo: null, codigo: null, descripcion: null, respuesta: null};

    displayedRespuestas = [
      { field: 'respuesta', header: 'No. Respuesta' }
  ];


    constructor(private _service: ListadoRadicacionWebService,
      private router: Router,
      private ruta: ActivatedRoute,
      private messageService: MessageService,
      private principalComponent: PrincipalComponent) { }

    ngOnInit() {
        this.loading = true;
        this.mostrarResultadoTabla = true;
        this.ruta.params.subscribe(params => {
            this.idProyecto = params.id;
        });
        this.displayedColumns1 = [
            { field: 'numeroTemporal', header: 'N° Temporal' },
            { field: 'fechaCreacionStr', header: 'Fecha de Creación' },
            { field: 'tipoDocumento', header: 'Tipo Documento' },
            { field: 'asunto', header: 'Asunto' }
          ];

        this.displayedColumns2 = [
            { field: 'numeroRadicacion', header: 'N° Radicado' },
            { field: 'fechaRadicacionStr', header: 'Fecha de Radicación' },
            { field: 'tipoDocumento', header: 'Tipo Documento' },
            { field: 'asunto', header: 'Asunto' }
          ];
        
        this.visibleDialogoRespuesta = false;
        this.visibleConfirmarElimnacion = false;
        this.visibleConfirmarRadicacion = false;

        this.cargarInformacion();
    }

    /**
    * Metodo que devuelve la pagina
    */
    bntVolver() {    
        this.volverHomeMenuProyectos = localStorage.getItem('volverHomeMenuProyectos');
        localStorage.setItem('idDocumentoRadicado', null);
        this.router.navigate([this.volverHomeMenuProyectos]);
    }

    /**
    * metodo que carga el nuevo registro
    * @param registro 
    */
    irNuevaRadicacion(registro){
        localStorage.setItem('idDocumentoRadicado', null);
        localStorage.setItem('volverListado', this.router.url );

        this.router.navigate([this.router.url + '/newEditRadicacion']);
    }


    /**
     * Metodo que edita el borrador
     * @param registro 
     */
    onEdit(registro){
        localStorage.setItem('idDocumentoRadicado', registro.id );
        localStorage.setItem('volverListado', this.router.url );
        this.router.navigate([this.router.url + '/newEditRadicacion']);
    }

    /**
     * Metodo que elimina el borrador
     * @param registro 
     */
    onDelete(registro){
      this.idEliminarBorrador = registro.id;
      this.visibleConfirmarElimnacion = true;        
    }

    /**
     * metodo para cancelar el modal de eliminacion
     */
    cancelarEliminar(){
      this.visibleConfirmarElimnacion = false;
    }

    /**
     * metodo para aceptar el modal de eliminacion
     */
    aceptarEliminar(){
      this.loading = true;
      this.visibleConfirmarElimnacion = false;
      this._service.getDocumentoEliminar(this.idEliminarBorrador).subscribe((data: {}) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: "Se eliminó el registro.", life: 10000 });
        this.listadoBorradores = [];
        this.cargarInformacion();
        this.visibleConfirmarElimnacion = false;
    }, (error)=>{
        this.loading = false;
        this.visibleConfirmarElimnacion = false;
        this.principalComponent.cargarErrorServicio(error);
    });
    }

    /**
     * Metodo que elimina el borrador
     * @param registro 
     */
    onView(registro){
      this.visibleConfirmarRadicacion = true;    
      this.numeroRadicado = registro.numeroRadicacion;
      this.loading = true;
      this.onFind();
    }

    /**
     * Metodo que cancela radicacion
     */
    cancelarRadicar(){
      this.visibleConfirmarRadicacion = false;
    }

    /**
     * Metodo que aceptar radicacion
     */
    aceptarRadicar(){
      this.visibleConfirmarRadicacion = false;
    }

    /**
     * Buscador de radicado
     */
    onFind(){
      this.loading = true;
      this.informacionGeneral = new InformacionGeneral();

      if(this.numeroRadicado != null && this.numeroRadicado.trim() != ""){
          this._service.getConsultaRadicado(this.numeroRadicado).subscribe((data: {}) => {
             this.listadoTemp = data;
             if(this.listadoTemp != undefined 
                  && this.listadoTemp != null
                  && this.listadoTemp.consulta != undefined
                  && this.listadoTemp.consulta != null){

                  if(this.listadoTemp.consulta.numeroRadicado!= ""){
                      this.numeroRadicado = null;
                      if(this.listadoTemp.consulta.respuesta!= null){
                          this.mostrarResultadoTabla = true;
                          this.respuestas.respuesta = this.listadoTemp.consulta.respuesta;
                          this.respuestas.codigo = this.listadoTemp.consulta.codigo;
                          this.respuestas.descripcion = this.listadoTemp.consulta.descripcion;
                          this.respuestas.anexo = this.listadoTemp.consulta.anexo;
                          this.listadoRespuestas.push( this.respuestas);
                      }else{
                          this.mostrarResultadoTabla = false;
                      }
                      this.informacionConsultaGeneral.asunto = this.listadoTemp.consulta.asunto;
                      this.informacionConsultaGeneral.dependencia = this.listadoTemp.consulta.dependencia;
                      this.informacionConsultaGeneral.email = this.listadoTemp.consulta.email;
  
                      if(this.listadoTemp.consulta.estadoActual != undefined
                          && this.listadoTemp.consulta.estadoActual != null
                          && this.listadoTemp.consulta.estadoActual != "" ){
                              this.informacionConsultaGeneral.estadoActual = this.listadoTemp.consulta.estadoActual;
                          }else{
                              this.informacionConsultaGeneral.estadoActual = "EN TRAMITE";
                          }                  
                      this.informacionConsultaGeneral.numeroRadicado = this.listadoTemp.consulta.numeroRadicado;
                      this.informacionConsultaGeneral.remitente = this.listadoTemp.consulta.remitente;
                  }else{
                      this.numeroRadicado = null;
                  }
              }else{
                  this.numeroRadicado = null;
              }
              this.loading = false;
          },error => {
              this.loading = false;
              this.numeroRadicado = null;
              this.principalComponent.cargarErrorServicio(error);
            });
      }
   }

    /**
    * Metodo que carga la informacion de la tabla de radicados y borradores
    */
    cargarInformacion(){
      this._service.getListadoBorradores(this.idProyecto).subscribe((data: {}) => {
        this.temporal = data;
        this.listadoBorradores = this.temporal;
        if(this.temporal != undefined && this.temporal != null 
            && this.temporal.length > 0 ){                
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: "Se cargo la información borradores.",
                    life: 10000
                  });
        }else{
            this.messageService.add({
                severity: 'warn',
                summary: 'Advertencia',
                detail: "El sistema no encontró información de borradores.",
                life: 10000
              });
        }            
        this.loading = false;
      }, (error)=>{
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
      });

      
    this._service.getListadoRadicacion(this.idProyecto).subscribe((data: {}) => {
        this.temporal = data;
        if(this.temporal != undefined && this.temporal != null 
            && this.temporal.length > 0 ){
                this.listadoFinalizados = this.temporal;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: "Se cargo la información radicados finalizados.",
                    life: 10000
                  });
        }else{
            this.messageService.add({
                severity: 'warn',
                summary: 'Advertencia',
                detail: "El sistema no encontró información de radicados finalizados.",
                life: 10000
              });
        }            
        this.loading = false;
      }, (error)=>{
        this.loading = false;
        this.principalComponent.cargarErrorServicio(error);
      });   
    }







}
