import { Component, OnInit,Inject, Renderer2 } from '@angular/core';
import { YoutubeProyectoVideoService, } from './youtube-proyecto-video.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Location, DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-youtube-proyecto-video.component',
  templateUrl: './youtube-proyecto-video.component.component.html',
  styleUrls: ['./youtube-proyecto-video.component.component.css'],
  providers: [YoutubeProyectoVideoService]
})
export class YoutubeProyectoVideoComponent implements OnInit {
  // Inicializaciones de Variables
  loading : boolean;
  listadoItems : any;
  urlSafe: SafeResourceUrl;

 
  

  ngOnInit() {
    var datos = sessionStorage.getItem('videoProyecto');
    this.listadoItems = JSON.parse(datos);

    let script = this._renderer2.createElement('script');
        script.id = `deCarusel_v`;
        script.text = `deCarusel_v();`;

    this._renderer2.appendChild(this.document.body, script);
  }
  constructor(@Inject(DOCUMENT) private document: any,
    private _service: YoutubeProyectoVideoService, 
    public sanitizer: DomSanitizer,  private _renderer2: Renderer2,
    public location: Location,) { }

  regresar(){
    this.location.back();
  }

  /**
   * metodo para transformar la url
   * @param objeto 
   */
  VideoURL(objeto) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("http://www.youtube.com/embed/"+objeto+"?loop=1&autoplay=1&mute=1&playlist="+objeto);
     return this.urlSafe ;
  }

    /**
   * metodo para transformar la fecha
   * @param objeto 
   */
  Year(objeto) {
     return objeto ;
  }

}
