import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CovidReportarService } from '../../covid-reportar.service';
import { Location } from '@angular/common';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-reporte-departamento',
  templateUrl: './reporte-departamento.component.html',
  styleUrls: ['./reporte-departamento.component.sass']
})
export class ReporteDepartamentoComponent implements OnInit {

  private reporte: any = [];
  fechaCorte: Date;
  idModo = 0;

  constructor(private route: Router, private ruta: ActivatedRoute, private covidReportarService: CovidReportarService, private location: Location, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    const url: string = this.route.url;
    const arrayUrl = url.split('/');
    let modo = 'carreteros'.split(' ');
    let matches = modo.every((item) => arrayUrl.includes(item));
    if (matches) {
      this.idModo = 2;
    } else {
      modo = 'aeropuertos'.split(' ');
      matches = modo.every((item) => arrayUrl.includes(item));
      if (matches) {
        this.idModo = 1;
      } else {
        modo = 'puertos'.split(' ');
        matches = modo.every((item) => arrayUrl.includes(item));
        if (matches) {
          this.idModo = 5;
        } else {
          modo = 'ferrocarriles'.split(' ');
          matches = modo.every((item) => arrayUrl.includes(item));
          if (matches) {
            this.idModo = 4;
          }
        }
      }
    }
  }

  accionConsultar() {
    let fecha: any;
    if (this.fechaCorte == null) {
      fecha = '01-01-1900';
    } else {
      if (typeof this.fechaCorte === 'undefined') {
        fecha = '01-01-1900';
      } else {
        const mes = this.fechaCorte.getMonth() + 1;
        if (mes < 10) {
          fecha = '0' + mes + '-' + this.fechaCorte.getDate() + '-' + this.fechaCorte.getFullYear();
        } else {
          fecha = mes + '-' + this.fechaCorte.getDate() + '-' + this.fechaCorte.getFullYear();
        }
      }
    }
    this.cargarReporte(fecha);
  }

  cargarReporte(fecha) {
    return this.covidReportarService.getReporteCovidDepartamento(fecha, 0, this.idModo).subscribe((data: {}) => {
      this.reporte = data;
      import('xlsx').then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.reporte);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'reporteCovid');
      });
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  exportExcel() {
    this.accionConsultar();
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then(FileSaver => {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  irAReportesCovid() {
    this.location.back();
  }

}
