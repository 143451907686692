import { Component, OnInit, ViewChild } from '@angular/core';
import { AmbientalService } from './ambiental.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProyectoAmbiental } from '../../../dtos/ambiental/lista'
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';

@Component({
  selector: 'app-ambiental',
  templateUrl: './ambiental.component.html',
  styleUrls: ['./ambiental.component.sass'],
  providers: [AmbientalService]
})

export class AmbientalComponent implements OnInit {

  tipoObligacion: any = [];
  proyectos: any = [];
  id: number;
  nombreProyecto;
  displayedColumns: string[] = [ 'tipoObligacion', 'obligacion', 'avance', 'fechaVencimiento', 'observaciones', 'indicador', 'acciones'];

  dataSource: MatTableDataSource<ProyectoAmbiental>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(private infoAmbiental: AmbientalService, private route: Router, private ruta: ActivatedRoute, public dialog: MatDialog, private principalComponent: PrincipalComponent) { 
  }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.cargarTipoObligacion();
      this.id = params.id;
      this.cargarProyectosAmbientales(this.id);
      localStorage.setItem('idP', JSON.stringify(this.id));

    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onNew() {
    this.route.navigate([this.route.url+'/infAmbientalDetalle', 'new']);
  }

  onEdit(id: number) {
    this.route.navigate([this.route.url+'/infAmbientalDetalle', id]);
  }

  onDelete(idRegistry: number) {
    return this.infoAmbiental.deleteDetalleAmbiental(idRegistry).subscribe((data: {}) => {
      alert('Se ha elimindado el Registro');
      this.cargarProyectosAmbientales(this.id);
      },error => {
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  cargarTipoObligacion() {
    return this.infoAmbiental.getTipoObligacion().subscribe((data: {}) => {
      this.tipoObligacion = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  getTipoObligacion(id) {
    const dataLength = this.tipoObligacion.length;
    for (let i = 0; i <= dataLength; i++) {
      if (this.tipoObligacion[i].idTipoObligacionAmbiental === id) {
        return this.tipoObligacion[i].descripcion;
      }
    }
  }

  getStyle(estado) {
    if (estado) {
      switch (estado) {
        case 'Verde':
            return 'badge badge-success';
        case 'Amarillo':
            return 'badge badge-warning';
        case 'Rojo':
            return 'badge badge-danger';
       default:
        // code...
        break;
        }
    }
}

  cargarProyectosAmbientales(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    return this.infoAmbiental.getProyectosAmbientales(id).subscribe((data: {}) => {
      this.proyectos = data;
      this.dataSource = new MatTableDataSource(this.proyectos);
      this.dataSource.paginator = this.paginator;
      this.dialogSpin.close();
    },error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  convertStringDateToTime(data) {
      const date = new Date(data);
      const localOffset = date.getTimezoneOffset() * 60000;
      const utc = date.getTime() + localOffset;
      return utc;
    }



}
