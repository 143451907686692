import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';

export class CtoContratoInformacionVigenteEncabezadoRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public contratoInformacionVigenteEncabezado?: CtoContratoInformacionVigenteEncabezadoDTO,
        public contratosInformacionVigente?: CtoContratoInformacionVigenteEncabezadoDTO[],
    ) { }
}

export class CtoContratoInformacionVigenteEncabezadoDTO {
    constructor(
        public id?: number,
        public numeroContrato?: string,
        public vigencia?: number,
        public fechaSuscripcion?: Date,
        public idPersona?: number,
        public idPersonaJuridica?: number,
        public idUsuario?: number,
        public esResponsableIva?: boolean,
        public estaObligadoFacturar?: boolean,
        public idTipoDocumento?: string,
        public numeroDocumento?: string,
        public digitoVerificacion?: number,
        public nombreContratista?: string,
        public naturalezaPersona?: string,
        public correoElectronico?: string,
        public correoElectronicoPersonal?: string,
        public numeroCelular?: string,
        public codigoColombiaCompraEficiente?: string,
        public idOrigenRecurso?: number,
        public origenRecurso?: string,
        public idImputacionRecurso?: number,
        public imputacionRecurso?: string,
        public idTipoRecurso?: number,
        public tipoRecurso?: string,
        public idDependenciaRecursos?: number,
        public dependenciaRecursos?: string,
        public idFuncionarioOrdenador?: number,
        public personaOrdenador?: string,
        public objetoContrato?: string,
        public valorInicialAni?: number,
        public valorInicialFiducias?: number,
        public valorInicialContrato?: number,
        public valorAdicionesAni?: number,
        public valorAdicionesFiducias?: number,
        public valorAdicionesContrato?: number,
        public valorFinalAni?: number,
        public valorFinalFiducias?: number,
        public valorFinalContrato?: number,
        public honorariosMensuales?: number,
        public fechainicio?: Date,
        public plazoDias?: number,
        public plazoMeses?: number,
        public plazoAnos?: number,
        public fechaTerminacionContrato?: Date,
        public tieneTerminacionAnticipada?: boolean,
        public idTipoGarantia?: number,
        public tipoGarantia?: number,
        public idAseguradora?: number,
        public aseguradora?: number,
        public fechaExpedicionGarantia?: Date,
        public idAmparosGarantia?: number,
        public amparosGarantia?: string,
        public numeroPoliza?: string,
        public idRegimenContratacion?: number,
        public regimenContratacion?: string,
        public idModalidadSeleccion?: number,
        public modalidadSeleccion?: string,
        public idTipoContrato?: number,
        public tipoContrato?: string,
        public cdp?: string,
        public rp?: string,
        public supervisores?: string,
        public cantidadSupervisores?: number,
        public vistosBuenos?: string,
        public cantidadVistosBuenos?: number,
        public observaciones?: string,
        public usuarioActualizacion?: number,
        public totalRegistros?: number,
        public estadoVigenciaContrato?: string,//Solo Web
        public habilitaGestionNovedadesContrato?: boolean//Solo Web
    ) { }
}

export class CtoSupervisorContrato {
    constructor(
        public idContrato?: number,
        public numeroContrato?: string,
        public idPersona?: number,
        public nombreSupervisor?: string,
        public dependencia?: Dependencia,
        public dependenciaPrimerNivel?: Dependencia
    ) { }
}

export class Dependencia {
    constructor(
        public id?: number,
        public nombre?: string
    ) { }
}

