import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { VwCmsComision } from '../comisiones.dto';
import { ComisionesService } from '../comisiones.service';

@Component({
  selector: 'app-comision-informacion-general',
  templateUrl: './comision-informacion-general.component.html',
  styles: []
})
export class ComisionInformacionGeneralComponent implements OnInit, OnChanges {

  @Input()
  idComision: number;
  @Input()
  idComisionVersion: number;
  @Output()
  cmsComisionOutput = new EventEmitter<VwCmsComision>();
  comision: VwCmsComision = {};

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService,
    private comisionesService: ComisionesService,
  ) { }

  ngOnInit() {
    this.cargarComision();
  }

  cargarComision() {
    this.comisionesService.srvConsultarComisionInformacionGeneral(this.idComision, this.idComisionVersion).subscribe(
      result => {
        if (result != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.comision = result.comision;
            if (this.comision.fechaSalida != null) {
              this.comision.fechaSalida = new Date(this.comision.fechaSalida);
            }
            if (this.comision.fechaRegreso != null) {
              this.comision.fechaRegreso = new Date(this.comision.fechaRegreso);
            }
            this.cmsComisionOutput.emit(this.comision);
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }

  ngOnChanges() {
    this.cargarComision();
  }
}