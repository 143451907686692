import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contratacion-reportes-home',
  templateUrl: './contratacion-reportes-home.component.html',
  styles: []
})
export class ContratacionReportesHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onNavigate(link) {
    window.open(link, '_blank');
  }
}
