import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/config.service';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';





@Injectable({
    providedIn: 'root'
})
export class AlcanceProyectoBaseService {

    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }


    public crearNuevaConfiguracionProyecto(idProyecto, idNovedadContrato, fechaAprobacion): Observable<UspCrrCreaConfiguracionProyectoBorradorRpt> {
        let crrNuevaConfiguracionProyectoDTO: CrrNuevaConfiguracionProyectoDTO = {};
        crrNuevaConfiguracionProyectoDTO.idProyecto = idProyecto;
        crrNuevaConfiguracionProyectoDTO.idNovedadContrato = idNovedadContrato;
        crrNuevaConfiguracionProyectoDTO.fechaAprobacion = fechaAprobacion;
       return this.http.post<UspCrrCreaConfiguracionProyectoBorradorRpt>(this.END_POINT + '/CrrAlcanceProyecto/crearNuevaConfiguracionProyecto', crrNuevaConfiguracionProyectoDTO)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    public finalizarConfiguracionProyecto(idProyecto): Observable<UspCrrFinalizarConfiguracionProyectoBorradorRpt> {
        return this.http.post<UspCrrFinalizarConfiguracionProyectoBorradorRpt>(this.END_POINT + '/CrrAlcanceProyecto/finalizarConfiguracionProyectoBorrador/' + idProyecto, idProyecto)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }

    public eliminaConfiguracionProyectoBorrador(idProyecto): Observable<UspCrrEliminaConfiguracionProyectoBorradorRpt> {
        return this.http.post<UspCrrEliminaConfiguracionProyectoBorradorRpt>(this.END_POINT + '/CrrAlcanceProyecto/eliminarConfiguracionProyectoBorrador/' + idProyecto, idProyecto)
            .pipe(
                retry(1),
                catchError(this.handleError)
            );
    }



    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }



}

export class CrrNuevaConfiguracionProyectoDTO {
    constructor(
        public idProyecto?: number,
        public idNovedadContrato?: number,
        public fechaAprobacion?: Date
    ) { }
}

export class UspCrrEliminaConfiguracionProyectoBorradorRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio
    ) { }
}

export class UspCrrCreaConfiguracionProyectoBorradorRpt {
    constructor(
        public respuestaServicio?: TrnRespuestaServicio,
        public idConfiguracionBorrador?: number
    ) { }
}

export class UspCrrFinalizarConfiguracionProyectoBorradorRpt {
    constructor(
        public codigoSalida: number,
        public mensajeSalida: string,
        public idConfiguracion?: number,
        public uspCrrValidaInformacionProyectoDTO?: UspCrrValidaInformacionProyectoDTO[]
    ) { }
}

export class UspCrrValidaInformacionProyectoDTO {
    constructor(
        id: number,
        idProyecto: number,
        idTema: number,
        tema: string,
        descripcion: string,
        esInformativo: boolean
    ) { }
}

