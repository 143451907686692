import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AlmacenamientoService {

    public END_POINT = '';


    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    public downloadFile(data) {
        return this.http.get(this.END_POINT + '/Comun/getFile/' + data.nombreArchivo + '/' + data.modulo, this.httpOptions)
            .pipe(
                retry(1));
    }

    public uploadFile(data) {
        return this.http.post(this.END_POINT + '/Comun/setFile', data)
            .pipe(
                retry(1));
    }

    public getTiposSoporte(modulo, idTiposSoporte) {
        return this.http.get(this.END_POINT + '/TipoSoportes/' + modulo + '/' + idTiposSoporte)
            .pipe(
                retry(1));
    }


    // Error handling
    handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }


}
