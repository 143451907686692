import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { TramitesAmbientalesService } from "../tramites-ambientales-carretero.service";
import { TramiteAmbientalDTO } from "../tramites-ambientales-carretero.dto";
import { forkJoin } from 'rxjs';
import { ProyectoDTO } from "src/app/modulo-planeacion-proyecto/planeacion-proyecto.dto";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-tramites-ambientales-listado-carretero",
  templateUrl: "./tramites-ambientales-listado-carretero.component.html",
  styleUrls: ["./tramites-ambientales-listado-carretero.component.sass"],
})
export class TramitesAmbientalesListadoCarreteroComponent implements OnInit {
  idProyecto: number;
  idTramite: number;
  listaTramitesAmbientales: TramiteAmbientalDTO[];
  tramitesAmbientalesHistoricoList: TramiteAmbientalDTO[]; 
  proyectoSeleccionado: ProyectoDTO;
  loading: boolean;
  dialogoEliminar: boolean;

  constructor(
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private principalComponent: PrincipalComponent,
    private tramitesAmbientalesService: TramitesAmbientalesService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.loading = true;
    const idParam = "idProyecto";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
    });
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),      
    ).subscribe(
      ([proyectoData, lstTramites]) => {        
        this.proyectoSeleccionado = proyectoData;        
        this.loading = false;
      },
      (e) => {}
    );
    this.listarTramitesAmbientalesPorProyecto();
    this.listarTramitesAmbientalesPorProyectoHistorico();
  }

  /**
   * Metodo que lista los tramites ambientales por proyecto
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  listarTramitesAmbientalesPorProyecto() {
    return this.tramitesAmbientalesService
      .srvListarTramitesAmbientalesPorProyecto(this.idProyecto)
      .subscribe((result) => {
        this.listaTramitesAmbientales = result.listaTramitesAmbientales;

        if (this.listaTramitesAmbientales === null) {
          this.listaTramitesAmbientales = [];
        }
      });
  }

  /**
   * Metodo que lista los tramites ambientales por proyecto
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  listarTramitesAmbientalesPorProyectoHistorico() {
    return this.tramitesAmbientalesService
      .srvListarTramitesAmbientalesPorProyectoHistorico(this.idProyecto)
      .subscribe((result) => {
        this.tramitesAmbientalesHistoricoList = result.listaTramitesAmbientales;

        if (this.tramitesAmbientalesHistoricoList === null) {
          this.tramitesAmbientalesHistoricoList = [];
        }
      });
  }

  /**
	 * Metodo para crear una nuevo tramite ambiental
	 */
	btnAgregar() {
		localStorage.setItem('tipoSolicitudTACarretero', "onNew");
		this.router.navigate([this.router.url + '/tramites-carretero-detalle', this.idProyecto, 0]);
	}

  /**
	 * Metodo para editar un tramite ambiental
	 */
  btnEditar(tramiteAmbientalSelected: TramiteAmbientalDTO) {
    localStorage.setItem('tipoSolicitudTACarretero', "onEdit");
		this.router.navigate([this.router.url + '/tramites-carretero-detalle', this.idProyecto, tramiteAmbientalSelected.idTramite]);
	}

  /**
   * Metodo para edita un tramite ambiental
   */
  btnConsultar(tramiteAmbientalSelected: TramiteAmbientalDTO) {    
    localStorage.setItem('tipoSolicitudTACarretero', "onView");
    this.router.navigate([
      this.router.url + "/tramites-carretero-detalle",this.idProyecto, tramiteAmbientalSelected.idTramite
    ]);
  }

  /**
   * Metodo para edita un tramite ambiental
   */
  btnConsultarHistorico(tramiteAmbientalSelected: TramiteAmbientalDTO) {    
    localStorage.setItem('tipoSolicitudTACarretero', "onViewHistorico");
    this.router.navigate([
      this.router.url + "/tramites-carretero-detalle",this.idProyecto, tramiteAmbientalSelected.idTramite
    ]);
  }

  /**
	 * Metodo para visualizar el seguimiento de un tramite ambiental
	 */
  btnSeguimiento(tramiteAmbientalSelected: TramiteAmbientalDTO) {
		this.router.navigate([this.router.url + '/tramites-carretero-seguimiento',this.idProyecto, tramiteAmbientalSelected.idTramite]);
	}

  btnEliminar(tramiteAmbientalSelected: TramiteAmbientalDTO) {
    this.idTramite = tramiteAmbientalSelected.idTramite;
    this.dialogoEliminar = true;
  }

  submitEliminar() {
    this.loading = true;
    forkJoin(
      this.tramitesAmbientalesService.srvEliminarTramite(
        this.idTramite)
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // console.log('----------srvEliminarPredio');
            // console.log(result.respuestaServicio.mensajeSalida);
            //  Consultar nuevamente la lista
            this.listarTramitesAmbientalesPorProyecto();
            //  Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            // // console.log(result.respuestaServicio.mensajeSalida);
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // // console.log('Ocurrio un error al consumir el servicio srvEliminarPredio');
        }
      },
      (error) => {
        // // console.log(error);
      },
      () => {
        // Desbloquear pantalla
        this.dialogoEliminar = false;
        this.loading = false;
      }
    );
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
