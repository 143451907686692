import { Component, OnInit, ViewChild } from '@angular/core';
import { InformacionGeneralService } from 'src/app/informacion-general/informacion-general.service';
import { UnidadFuncionalCarreteroService } from 'src/app/unidad-funcional-carretero/unidad-funcional-carretero.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { UnidadFuncional } from 'src/app/unidad-funcional-carretero/unidadFuncional';
import { InversionIndexadaService } from './inversion-indexada.service';
import { InversionIndexada } from './inversion-indexada';
import { NgForm } from '@angular/forms';
import { UnidadesFuncionales } from '../../dtos/juridico/amigables/tramiteAmbientalDetalleDTO';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-inversion-indexada',
  templateUrl: './inversion-indexada.component.html',
  styleUrls: ['./inversion-indexada.component.sass']
})
export class InversionIndexadaComponent implements OnInit {

  unidadesFuncionales: any = [];

  inversionIndexadaList: any = [];

  displayedColumnsInversionIndexada: any[];

  dataSourceInversionIndexada: MatTableDataSource<InversionIndexada>;

  inversionIndexadaSeleccionada: InversionIndexada;


  displayedColumns: any[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: MatTableDataSource<UnidadFuncional>;
  totalUnidadesPaginator: any;

  unidadFuncionalSeleccionada: UnidadFuncional;

  idProyecto: number;
  idUnidadFuncional: number;
  proyecto: any = {};
  unidadFuncional: any;

  idCapexOpex: any;

  visibleListaCapexOpexIndexado: boolean = false;
  visibleCapexOpexIndexado: boolean = false;
  visibleDialogoAdvertenciaFecha: boolean = false;
  visibleDialogoExito: boolean = false;
  visibleConfirmarEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarRegistro: boolean = false;
  visibleDialogoExitoEliminarInversionIndexada: boolean = false;

  datosInversionIndexado: InversionIndexada = {};



  constructor(private infoService: InformacionGeneralService, private unidadFuncionalService: UnidadFuncionalCarreteroService, private inversionIndexadaService: InversionIndexadaService, private route: Router, private ruta: ActivatedRoute, private parametricasService: ParametricasService,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.ruta.params.subscribe(params => {
      this.idProyecto = params.idProyecto;
      this.cargarProyectoCarretero(this.idProyecto);
      this.cargarUnidadesFuncionales(this.idProyecto);
    });
    this.displayedColumns = [
      { field: 'codigo', header: 'Código' },
      { field: 'nombre', header: 'Nombre' }
    ];
    this.displayedColumnsInversionIndexada = [
      { field: 'formatoFecha', header: 'Fecha de indexación' },
      { field: 'formatoCapex', header: 'CAPEX' },
      { field: 'formatoOpex', header: 'OPEX' }
    ];
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoCarretero(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  cargarUnidadesFuncionales(id: number) {
    forkJoin(
      this.unidadFuncionalService.getUnidadesFuncionalesPorProyecto(id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        // Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.unidadesFuncionales = result.unidadesFuncionales;
          this.dataSource = new MatTableDataSource(this.unidadesFuncionales);
          this.dataSource.paginator = this.paginator;
          this.totalUnidadesPaginator = this.unidadesFuncionales.length;
        } else {
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        // Desbloquear pantalla

      }
    );
    /* return this.unidadFuncionalService.getUnidadesFuncionalesPorProyecto(id).subscribe((data: {}) => {
      this.unidadesFuncionales = data;
      this.dataSource = new MatTableDataSource(this.unidadesFuncionales);
      this.dataSource.paginator = this.paginator;
      this.totalUnidadesPaginator = this.unidadesFuncionales.length;
    }); */
  }

  cargarInversionIndexada(id: number) {
    forkJoin(
      this.unidadFuncionalService.getUnidadFuncionalPorId(id)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        //Si el resultado es exitoso...
        if (result.respuestaServicio.codigoSalida === 1) {
          this.unidadFuncional = result.unidadFuncional;
          this.idUnidadFuncional = this.unidadFuncional.idUnidadFuncional;
          this.cargarInversionenIndexadasPorUnidadFuncional(this.idUnidadFuncional);
        } else {
        }
      } else {
      }
    },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      },
      () => {
        //Desbloquear pantalla

      }
    );

  }

  cargarInversionenIndexadasPorUnidadFuncional(idUnidadFuncional) {
    return this.inversionIndexadaService.getInversionIndexadaPorUnidadFuncional(idUnidadFuncional).subscribe((data: {}) => {
      this.inversionIndexadaList = data;
      this.dataSourceInversionIndexada = new MatTableDataSource(this.inversionIndexadaList);
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irListaCapexOpexIdexadaPorUnidadFuncional(unidadFuncional: UnidadFuncional) {
    this.visibleListaCapexOpexIndexado = true;
    this.unidadFuncionalSeleccionada = unidadFuncional;
    this.cargarInversionIndexada(this.unidadFuncionalSeleccionada.id);
  }

  irAgregarCapexOpexIdexado() {
    this.visibleCapexOpexIndexado = true;
    this.idCapexOpex = "new";
    this.datosInversionIndexado = {};
  }

  cancelarListaInversionIdexada() {
    this.visibleListaCapexOpexIndexado = false;
    this.unidadFuncional = null;
  }

  cancelarAgregarInversionIdexada() {
    this.visibleCapexOpexIndexado = false;
  }

  guardarInversionIdexada(forma: NgForm) {
    if (this.idCapexOpex === "new") {
      this.datosInversionIndexado.idUnidadFuncional = this.idUnidadFuncional;
      let vara: any = this.inversionIndexadaService.guardarInversionIndexada(this.datosInversionIndexado).subscribe((data: {}) => {
        let respuesta: InversionIndexada = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
        this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    } else {
      this.inversionIndexadaService.editarInversionIndexada(this.datosInversionIndexado, this.idCapexOpex).subscribe((data: {}) => {
        let respuesta: InversionIndexada = data;
        //if (respuesta.mensajeError == "401") {
        //  this.visibleDialogoAdvertenciaFecha = true;
        //}
        //if (respuesta.mensajeError == "200") {
        this.visibleDialogoExito = true;
        //}
      }, error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
      });
    }
  }

  aceptarDialogoAdvertenciaFecha() {
    this.visibleDialogoAdvertenciaFecha = false;
  }

  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.cargarInversionIndexada(this.unidadFuncionalSeleccionada.id);
    this.visibleCapexOpexIndexado = false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editarInversionIdexada(inversionIndexada: InversionIndexada) {
    this.inversionIndexadaSeleccionada = inversionIndexada;
    this.idCapexOpex = this.inversionIndexadaSeleccionada.id;
    this.visibleCapexOpexIndexado = true;
    return this.inversionIndexadaService.getInversionIndexadaPorId(this.inversionIndexadaSeleccionada.id).subscribe((data: {}) => {
      this.datosInversionIndexado = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irEliminarInversionIdexada(inversionIndexada: InversionIndexada) {
    this.inversionIndexadaSeleccionada = inversionIndexada;
    this.visibleConfirmarEliminarRegistro = true;
    this.idCapexOpex = this.inversionIndexadaSeleccionada.id;
  }

  cancelarEliminarInversionIdexada() {
    this.visibleConfirmarEliminarRegistro = false;
  }

  aceptarEliminarInversionIdexada() {
    return this.inversionIndexadaService.eliminarInversionIndexada(this.idCapexOpex).subscribe((data: {}) => {
      this.cargarInversionIndexada(this.unidadFuncionalSeleccionada.id);
      this.visibleDialogoExitoEliminarRegistro = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarInversionIdexada() {
    this.visibleDialogoExitoEliminarRegistro = false;
    this.visibleConfirmarEliminarRegistro = false;
  }


}
