import { Input, Output, Component, OnInit, EventEmitter } from '@angular/core';
import { ContratacionService } from '../contratacion.service';
import { CtoContratoBalanceFinancieroDTO } from '../contratacion-dto/contrato-balance-financiero';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCtoOrigenRecurso } from '../constantes/contratacion-constantes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contrato-balance-financiero',
  templateUrl: './contrato-balance-financiero.component.html',
  styles: []
})
export class ContratoBalanceFinancieroComponent implements OnInit {

  @Input()
  idContrato: number;
  @Input()
  fijarAcordeon = true;
  @Input()
  mostrarEnComponente = true;
  @Input()
  mostrarSoloBalance = false;
  @Output()
  ctoContratoBalanceFinancieroOutput = new EventEmitter<CtoContratoBalanceFinancieroDTO>();
  ctoContratoBalanceFinanciero: CtoContratoBalanceFinancieroDTO = {};

  idPersona: number;

  constructor(
    private activateRoute: ActivatedRoute,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent,

  ) { }

  ngOnInit() {
    //Si no viene desde un componente sino por llamado de URL
    if (this.idContrato == undefined) {
      this.activateRoute.params.subscribe(params => {
        this.idContrato = params.idContrato;
        this.idPersona = params.idPersona;
        this.mostrarEnComponente = false;
      });
    }
    this.cargarBalanceFinanciero(this.idContrato);
  }

  cargarBalanceFinanciero(idContrato) {
    this.contratacionService.ConsultarContratoBalanceFinanciero(idContrato)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.ctoContratoBalanceFinanciero = result.contratoBalanceFinanciero;
              this.ctoContratoBalanceFinancieroOutput.emit(this.ctoContratoBalanceFinanciero);
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  ngOnChanges() {
    this.cargarBalanceFinanciero(this.idContrato);
  }


  get EnumCtoOrigenRecurso() {
    return EnumCtoOrigenRecurso;
  }
}
