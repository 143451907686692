import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AgendamientoCitaService {

  public END_POINT = '';

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor( private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public getTipoDocumento() {
    return this.http.get(this.END_POINT + '/Citas/documentos')
      .pipe(
        retry(1));
  }

  public getTemaVisita() {
    return this.http.get(this.END_POINT + '/Citas/temaVisita')
      .pipe(
        retry(1));
  }

  public getHorario(fecha) {
    return this.http.get(this.END_POINT + '/Citas/horario/' + fecha)
      .pipe(
        retry(1));
  }

  public getTurnos() {
    return this.http.get(this.END_POINT + '/Citas/parametrizacion/TURNOS')
      .pipe(
        retry(1));
  }

  public getDiasAdelante() {
    return this.http.get(this.END_POINT + '/Citas/parametrizacion/DIAS_ADELANTE')
      .pipe(
        retry(1));
  }

  public getFestivos() {
    return this.http.get(this.END_POINT + '/Citas/festivos')
      .pipe(
        retry(1));
  }


  public guardarAgendamiento(data) {
    return this.http.post(this.END_POINT + '/Citas/enviar', data)
      .pipe(
        retry(1));
  }


  public consultarAgendamiento(data) {
    return this.http.post(this.END_POINT + '/Citas/validarCodigo', data)
      .pipe(
        retry(1));
  }

  public cancelarAgendamiento(data) {
    return this.http.put(this.END_POINT + '/Citas/cancelarCodigo', data)
      .pipe(
        retry(1));
  }


}
