import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagina-inicial',
  templateUrl: './pagina-inicial.component.html',
  styleUrls: []
})
export class PaginaInicialComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
  element.classList.add("public");
  }

  ingresar(){
    this.route.navigate(['/home']);
  }

  menulateral() {
    var element = document.getElementById("menu_lateral");
    element.classList.toggle("close");
  }

  reportes() {
   
    this.route.navigate(['carreterosNoAut'])
  }

  irAMapas(): void {
    //    this.document.location = "https://sig.ani.gov.co/mapasinterno/";
    window.open('https://animapas.maps.arcgis.com/apps/webappviewer/index.html?id=8782c0e22b3140f293fdce88d7ab8fcb', '_blank');
  }

}
