import { Component, Input, OnInit, Inject, Renderer2 } from "@angular/core";
import { loadModules } from "esri-loader";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import {
  InformacionGeneralCarreterosDTO,
  CrrInformacionGeneralProyectoDTO,
  CrrInformacionDepartamentosDTO,
  CrrInformacionPeajesDTO,
  CrrInformacionProyectoDTO,
  ValoresIndexadosDTO,
  CrrAvanceUfDTO,
} from "src/app/reportes/carreteros/reportes-carreteros.dto";
import { formatDate } from "@angular/common";
import { Location, DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-rpt-crr-proyecto-encabezado",
  templateUrl: "./rpt-crr-proyecto-encabezado.component.html",
  styleUrls: [
    "./rpt-crr-proyecto-encabezado.component.sass",
    "./rpt-crr-proyecto-encabezado.component.css",
  ],
})
export class RptCrrProyectoEncabezadoComponent implements OnInit {
  basicData: any;
  dataSource: any;
  stackedOptions: any;
  horizontalOptions: any;
  multiAxisOptions: any;
  blockedDocument = false;
  value: number = 1;
  informacionGeneralCompleta: CrrInformacionGeneralProyectoDTO[] = [];
  @Input() informacionGeneralProyecto: CrrInformacionGeneralProyectoDTO;
  idProyecto: number;
  fechaFirmaContrato: any;
  fechaInicioPreConstruccion: any;
  fechaInicioConstruccion: any;
  fechaInicioOperacion: any;
  fechaReferencia: any;
  valorContratoFechaReferencia: number;
  valorContratoIndexado: number;
  valorCapexIndexado: number;
  valorOpexIndexado: number;
  fechaIndexacion: any;
  fechaUltimoReporte: any;
  nombreProyecto: any;
  etapaProyecto: any;
  idEtapaProyecto: any;
  fechas?: any[] = [];
  fechaIndexacionSeleccionada?: any = null;
  valoresIndexados: ValoresIndexadosDTO[] = [];
  valoresIndexadosFiltro: ValoresIndexadosDTO[] = [];
  avanceUf: CrrAvanceUfDTO[] = [];
  esPreconstruccion: boolean;

  proyectos: any = [];
  showVideo = true;
  public YT: any;
  public video: any;
  public playList: any;
  public player: any;
  public reframed: Boolean = false;
  rutaIframe: string;
  validacionuf: number;

  isRestricted = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent,
    private ruta: ActivatedRoute,
    private route: Router,
    private _renderer2: Renderer2,
    private http: HttpClient
  ) {
    this.basicData = {
      labels: [
        "Construcción",
        "Pre-Construcción",
        "Operación",
        "Normalización",
      ],
      datasets: [
        {
          label: "Proyectos",
          backgroundColor: ["#1C74C0", "#229D8E", "#C14133", "#FA6581"],

          data: [22, 4, 3, 13],
        },
      ],
    };

    this.multiAxisOptions = {
      plugins: {
        legend: {
          labels: {
            color: "#ebedef",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(255,255,255,0.2)",
          },
        },
        y: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(255,255,255,0.2)",
          },
        },
      },
    };
  }

  startVideo() {
    this.reframed = false;
    this.player = new window["YT"].Player("player", {
      videoId: this.video,
      playerVars: {
        autoplay: 1,
        loop: 1,
        modestbranding: 0,
        controls: 0,
        autohide: 1,
        disablekb: 1,
        rel: 0,
        showinfo: 0,
        fs: 0,
        playlist: this.video,
      },
      events: {
        //'onStateChange': this.onPlayerStateChange.bind(this),
        onError: this.onPlayerError.bind(this),
        onReady: this.onPlayerReady.bind(this),
      },
    });
  }

  onPlayerReady(event) {
    if (this.isRestricted) {
      event.target.mute();
      event.target.playVideo();
    } else {
      event.target.playVideo();
    }
  }

  onPlayerError(event) {
    switch (event.data) {
      case 2:
        console.log("" + this.video);
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }
  init() {
    // Return if Player is already created
    if (window["YT"]) {
      this.startVideo();
      return;
    }

    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    /* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
    window["onYouTubeIframeAPIReady"] = () => this.startVideo();
  }

  ngOnInit(): void {


    var colorUFS = [
      "#00ced1",
      "#2f4f4f",
      "#483d8b",
      "#8fbc8f",
      "#e9967a",
      "#8b0000",
      "#ff8c00",
      "#008b8b",
      "#00008b",
      "#dc143c",
      "#6495ed",
      "#ff7f50",
      "#7fff00",
      "#ff1493",
      "#9400d3",
      "#008000",
      "#ff69b4",
      "#cd5c5c",
      "#b349ae",
      "#f08080",
      "#b3495c",
      "#b0c4de",
    ];

    this.blockedDocument = true;

    fetch(
      "https://aniscopiosig-server.ani.gov.co/arcgisserver/rest/services/UnidadFuncional/Unidades_Funcionales/FeatureServer/0/query?where=IDPR=" +
      this.informacionGeneralProyecto.id +
      "&returnCountOnly=true&f=json"
    )
      .then((response) => response.json())
      .then((conteouf) => {
        this.validacionuf = conteouf.count;
      });

    this.ruta.params.subscribe((params) => {
      this.idProyecto = params.id;
      this.rutaIframe =
        "https://animapas.maps.arcgis.com/apps/dashboards/a9b307a3cd854f43b02764d027c95e20#p1=" +
        this.idProyecto +
        "&p2=" +
        this.idProyecto;
    });

    this.reportesCarreteroService
      .getProyectosYoutube(this.informacionGeneralProyecto.id)
      .subscribe(
        (data: any = []) => {
          this.proyectos = data;
          console.log("this.proyectos = data ");
          console.log(data.items);
          let script = this._renderer2.createElement("script");
          script.id = `deCarusel_v`;
          script.text = `deCarusel_v();`;

          this._renderer2.appendChild(this.document.body, script);
        },
        (error) => {
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error,
            life: 10000,
          });
        }
      );

    this.video = this.informacionGeneralProyecto.videoId;
    this.playList = this.informacionGeneralProyecto.playList;
    this.nombreProyecto = this.informacionGeneralProyecto.proyecto;
    this.etapaProyecto = this.informacionGeneralProyecto.etapaProyecto;
    this.idEtapaProyecto = this.informacionGeneralProyecto.idEtapaProyecto;

    //valida etapa
    console.log("this.idEtapaProyecto");
    console.log(this.idEtapaProyecto);
    if (this.idEtapaProyecto === 2) {
      this.esPreconstruccion = true;
    } else {
      this.esPreconstruccion = false;
    }

    if (this.informacionGeneralProyecto.fechaIndexacionCapexOpex != null) {
      this.fechaIndexacion = formatDate(
        this.informacionGeneralProyecto.fechaIndexacionCapexOpex,
        "dd/MM/yyyy",
        "es-ES"
      );
    }

    if (this.informacionGeneralProyecto.fechaUltimoReporteAvance != null) {
      this.fechaUltimoReporte = formatDate(
        this.informacionGeneralProyecto.fechaUltimoReporteAvance,
        "dd/MM/yyyy",
        "es-ES"
      );
    }

    if (this.informacionGeneralProyecto.fechaSuscripcionContrato != null) {
      this.fechaFirmaContrato = formatDate(
        this.informacionGeneralProyecto.fechaSuscripcionContrato,
        "dd/MM/yyyy",
        "es-ES"
      );
    }
    if (
      this.informacionGeneralProyecto.fechaPreconstruccionContratada != null
    ) {
      this.fechaInicioPreConstruccion = formatDate(
        this.informacionGeneralProyecto.fechaPreconstruccionContratada,
        "dd/MM/yyyy",
        "es-ES"
      );
    }
    if (this.informacionGeneralProyecto.fechaConstruccionContratada != null) {
      this.fechaInicioConstruccion = formatDate(
        this.informacionGeneralProyecto.fechaConstruccionContratada,
        "dd/MM/yyyy",
        "es-ES"
      );
    }

    if (this.informacionGeneralProyecto.fechaOperacionContratada != null) {
      this.fechaInicioOperacion = formatDate(
        this.informacionGeneralProyecto.fechaOperacionContratada,
        "dd/MM/yyyy",
        "es-ES"
      );
    }
    if (this.informacionGeneralProyecto.fechaReferenciaContrato != null) {
      this.fechaReferencia = formatDate(
        this.informacionGeneralProyecto.fechaReferenciaContrato,
        "dd/MM/yyyy",
        "es-ES"
      );
    }

    this.valorContratoFechaReferencia = this.formatoMoneda(
      this.informacionGeneralProyecto.valorContratoFechaReferencia
    );
    this.valorContratoIndexado = this.formatoMoneda(
      this.informacionGeneralProyecto.valorContratoIndexado
    );
    this.valorCapexIndexado = this.formatoMoneda(
      this.informacionGeneralProyecto.capexIndexado
    );
    this.valorOpexIndexado = this.formatoMoneda(
      this.informacionGeneralProyecto.opexIndexado
    );

    var capaufs = [];

    this.reportesCarreteroService
      .getInformacionCrrAvanceUf(this.informacionGeneralProyecto.id)
      .subscribe(
        (dato) => {
          if (dato != null && dato.respuestaServicio != null) {
            if (dato.respuestaServicio.codigoSalida === 1) {
              this.avanceUf = dato.avanceUf;

              let contador = 0;

              this.avanceUf.forEach(function (elemento, indice, array) {
                contador = contador + 1;
                if (elemento.factorPonderacion * 100 != 0) {
                  capaufs.push({
                    value: elemento.codigoUf,
                    symbol: {
                      type: "simple-line", // autocasts as new SimpleLineSymbol()
                      color: colorUFS[contador],
                      width: "7px",
                      style: "solid",
                    },
                  });
                }
              });
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: dato.respuestaServicio.mensajeSalida,
                life: 10000,
              });
            }
          }
        },
        (error) => {
          this.blockedDocument = false;
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => { }
      );

    this.reportesCarreteroService
      .getValoresIndexadosPorProyecto(this.idProyecto)
      .subscribe(
        (dato) => {
          if (dato != null && dato.respuestaServicio != null) {
            if (dato.respuestaServicio.codigoSalida === 1) {
              this.valoresIndexados = dato.valoresIndexados;
              console.log("dato getValoresIndexadosPorProyecto");
              console.log(dato);

              this.fechas = dato.valoresIndexados.map((fecha) => {
                return {
                  fecha: fecha.fechaIndexacion,
                  fechaString: formatDate(
                    fecha.fechaIndexacion,
                    "dd/MM/yyyy",
                    "es-ES"
                  ),
                };
              });
              this.fechaIndexacionSeleccionada = this.fechas[0];

              this.valoresIndexadosFiltro = this.valoresIndexados.filter(
                (a) =>
                  a.fechaIndexacion == this.fechaIndexacionSeleccionada.fecha
              );

              this.valorContratoIndexado = this.formatoMoneda(
                this.valoresIndexadosFiltro[0].valorContratoIndexado
              );
              this.valorCapexIndexado = this.formatoMoneda(
                this.valoresIndexadosFiltro[0].capex
              );
              this.valorOpexIndexado = this.formatoMoneda(
                this.valoresIndexadosFiltro[0].opex
              );
            } else {
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: dato.respuestaServicio.mensajeSalida,
                life: 10000,
              });
            }
          }
        },
        (error) => {
          this.blockedDocument = false;
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  formatoMoneda(valor: number) {
    return valor / 1000000;
  }

  irAResumen(): void {
    this.route.navigate(["/reportes/carreteros"]);
  }

  actualizarValores(event) {
    this.valoresIndexadosFiltro = this.valoresIndexados.filter(
      (a) => a.fechaIndexacion == this.fechaIndexacionSeleccionada.fecha
    );

    this.valorContratoIndexado = this.formatoMoneda(
      this.valoresIndexadosFiltro[0].valorContratoIndexado
    );
    this.valorCapexIndexado = this.formatoMoneda(
      this.valoresIndexadosFiltro[0].capex
    );
    this.valorOpexIndexado = this.formatoMoneda(
      this.valoresIndexadosFiltro[0].opex
    );
  }

  fullscreen() {
    var divmap = document.getElementById("mapfull");
    divmap.classList.add("fullposition");
  }

  normalscreen() {
    var divmap = document.getElementById("mapfull");
    divmap.classList.remove("fullposition");
  }

  parseInt(valor: any) {
    return parseInt(valor, 10);
  }

  tofixed(valor: any) {
    return valor.toFixed(2);
  }
}
