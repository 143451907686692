import { Component, OnInit } from '@angular/core';
import { InformeSupervisorBaseComponent } from '../informe-supervisor-base/informe-supervisor-base.component';
import { CtoInformeContratistaObservacionDTO } from '../../../../contratacion-dto/informe-contratista-observacion';
import { InformeRevisionService } from '../../../informe-revision.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { CtoInformeRevisionAsignacionDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-asignacion';

@Component({
  selector: 'app-informe-supervisor-observaciones',
  templateUrl: './informe-supervisor-observaciones.component.html',
  styles: []
})
export class InformeSupervisorObservacionesComponent implements OnInit {

  observacionesInforme: CtoInformeContratistaObservacionDTO[];
  totalPaginator: any;

  constructor(
    private lineaBase: InformeSupervisorBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarObservacionesInformeRevision(this.lineaBase.idInformeParam, this.lineaBase.idVersionInformeParam, this.lineaBase.idPasoParam);
  }

  guardarAsignacionObservacion(asignacion: CtoInformeRevisionAsignacionDTO) {
    this.lineaBase.blockedDocument = true;
    this.infCtaSupervisionService.GuardarAsignacionObservacionInformeRevision(asignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
          this.ngOnInit();
        }
      );
  }

  cargarObservacionesInformeRevision(idInforme: number, idVersionInforme: number, idPaso: number) {
    this.infCtaSupervisionService.ConsultarObservacionesInformeRevision(idInforme, idVersionInforme, idPaso)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.observacionesInforme = result.observacionesInforme;
              this.totalPaginator = this.observacionesInforme.length;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }
}
