import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-archivo-shp",
  templateUrl: "./archivo-shp.component.html",
  styleUrls: ["./archivo-shp.component.sass"],
})
export class ArchivoShpComponent implements OnInit {
  @Input() urlSig: string;
  @Input() paramIdObligacion: number;
  @Input() paramNombreProyecto: string;
  @Input() paramValorSubcuenta: number;
  @Input() paramValorEjecutado: number;
  @Input() paramFechaCorte: string;
  @Input() paramZonaAfectada: boolean;
  @Input() tipo: string;
  @Input() paramIdUnidadFuncional: number;
  @Input() paramFichaPredial: number;
  urlIframe: string;

  safeUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.tipo != null && this.tipo == "uf") {
      this.urlIframe = this.urlSig + "Iduf1=" + this.paramIdUnidadFuncional;
      console.log("this.urlIframe", this.urlIframe);
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.urlIframe
      );
    } else if (this.tipo != null && this.tipo == "predial") {
      this.urlIframe = this.urlSig + "IdPred=" + this.paramFichaPredial;
      console.log("this.urlIframe", this.urlIframe);
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.urlIframe
      );
    } else {
      //this.safeUrl = null;
      if (this.paramZonaAfectada) {
        this.urlIframe =
          this.urlSig +
          "IdObl=" +
          this.paramIdObligacion +
          "&Nomb=" +
          this.paramNombreProyecto +
          "&Val=" +
          this.paramValorSubcuenta;
        console.log("this.urlIframe", this.urlIframe);
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.urlIframe
        );
      } else {
        this.urlIframe =
          this.urlSig +
          "IdObl=" +
          this.paramIdObligacion +
          "&Nomb=" +
          this.paramNombreProyecto +
          "&Val=" +
          this.paramValorSubcuenta +
          "&ValE=" +
          this.paramValorEjecutado +
          "&Fech=" +
          this.paramFechaCorte;
        console.log("this.urlIframe", this.urlIframe);
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.urlIframe
        );
      }
    }
  }
}
