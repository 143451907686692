import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TemasGestionService } from './temas-gestion.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, MatTableDataSource , MatPaginator} from '@angular/material';
import { ProyectoAmbiental } from '../../../dtos/temas-gestion/proyecto-ambiental';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'seguimiento-dialog',
  templateUrl: 'seguimiento-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class SeguimientoDialog {

  displayedColumns: string[] = [ 'observaciones', 'priorizacion', 'idEtapa', 'fechaInicio'];
  paginator1;

  dataSource1: MatTableDataSource<ProyectoAmbiental>;

  @ViewChild(MatPaginator, { static: true }) set matPaginator(mp: MatPaginator) {
    this.paginator1 = mp;
    this.setDataSourceAttributes();
    }
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<SeguimientoDialog>,
    private principalComponent: PrincipalComponent,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.dataSource1 = new MatTableDataSource(data.resultado);
    }

  setDataSourceAttributes() {
      this.dataSource1.paginator = this.paginator1;
  }

  goToMapa(id) {
    // tslint:disable-next-line:max-line-length
     window.open('https://app.powerbi.com/view?r=eyJrIjoiMjQ4YzczNGYtMjhiMS00ODhkLWI0YjktYzA2OTY3YmY0ZGU3IiwidCI6IjcwZGM1N2VlLWZlNDYtNDI4Ni1iNjViLTFhZTllMTI2YzAzZSIsImMiOjR9', '_blank');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  applyFilter1(filterValue: string) {
    this.dataSource1.filter = filterValue.trim().toLowerCase();

    if (this.dataSource1.paginator) {
      this.dataSource1.paginator.firstPage();
    }
  }

  getEtapa(payload) {
    if (payload) {
      switch (payload) {
        case 1:
            return 'Registro';
        case 2:
            return 'Seguimiento';
        case 3:
            return 'Suspendido';
        case 4:
              return 'Reanudado';
        case 5:
              return 'Cerrado';
       default:
        // code...
        break;
        }
    }
}
}


@Component({
  selector: 'app-temas-gestion',
  templateUrl: './temas-gestion.component.html',
  styleUrls: ['./temas-gestion.component.sass'],
  providers: [TemasGestionService]
})

export class TemasGestionComponent implements OnInit {
  displayedColumns1;

  objectSubTipo: any = [];
  objectTipo: any = [];
  objectEtapaActual: any = [];
  listaTemasGestion: any = [];

  proyectos: any = [];
  id: number;
  nombreProyecto;
  displayedColumns: string[] = [ 'id', 'idTipo', 'idEtapaActual', 'priorizacion','descripcion', 'fechaInicio', 'acciones'];


  dataSeguimiento;

  dataSource: MatTableDataSource<ProyectoAmbiental>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(
    private info: TemasGestionService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    private principalComponent: PrincipalComponent) { 
    }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      //this.cargarSubTipo();
      //this.cargarTipo();
      //this.cargarEtapaActual();
      this.id = params.id;
      this.cargarProyectosAmbientales(this.id);
      localStorage.setItem('idP', JSON.stringify(this.id));

    });

    this.displayedColumns1 = [
      { field: 'id', header: 'ID' },
      { field: 'tema', header: 'Tema' },
      { field: 'subTema', header: 'Sub Tema' },
      { field: 'unidadFuncional', header: 'Unidad F.' },
      { field: 'subtramo', header: 'Sub Tramo' },
      { field: 'ultimaActuacion', header: 'Ultima Actuación' },
      { field: 'estadoActual', header: 'Estado Actual' } ,
      { field: 'priorizacionActual', header: 'Priorización Actual' }        
       
    ];
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onNew() {
    this.route.navigate([this.route.url+'/infTemasGDetalle', 'new']);
  }

  onEdit(id: number) {
    this.route.navigate([this.route.url+'/infTemasGDetalle', id]);
  }

  onFollow(id: number) {
    this.route.navigate([this.route.url+'/infTemasGRegistro', id]);
  }

  openDialog(id, data) {
    this.info.getSeguimiento(id).subscribe((payload: {}) => {
      const dialogRef = this.dialog.open(SeguimientoDialog, {width: '1500px',
        data: {resultado: payload,
          datosCabecera: data
        }

      });
      dialogRef.afterClosed().subscribe(result => {
        // this.user = user;
      });
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }


  onDelete(idRegistry: number) {
    return this.info.deleteDetalleAmbiental(idRegistry).subscribe((data: {}) => {
        alert('Se ha elimindado el Registro');
        this.cargarProyectosAmbientales(this.id);
      }, (error)=>{
        this.principalComponent.cargarErrorServicio(error);
      });
  }

  cargarSubTipo() {
    return this.info.getSubTipo().subscribe((data: {}) => {
      this.objectSubTipo = data;
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarTipo() {
    return this.info.getTipo().subscribe((data: {}) => {
      this.objectTipo = data;
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarEtapaActual() {
    return this.info.getEtapaActual().subscribe((data: {}) => {
      this.objectEtapaActual = data;
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }
  
  getSubTipo(id) {
    if (id) {
      const dataLength = this.objectSubTipo.length;
      for (let i = 0; i <= dataLength; i++) {
        if (this.objectSubTipo[i].id === id) {
          return this.objectSubTipo[i].nombre;
        } else {
          return '';
        }
      }
    } else {
      return '';
    }
  }

  getTipo(id) {
    if (id) {
      const dataLength = this.objectTipo.length;
      for (let i = 0; i <= dataLength; i++) {
        if (this.objectTipo[i].id === id) {
          return this.objectTipo[i].nombre;
        } else {
          return '';
        }
      }
    } else {
      return '';
    }
  }

  getEtapaActual(id) {
    if (id) {
      const dataLength = this.objectEtapaActual.length;
      for (let i = 0; i <= dataLength; i++) {
        if (this.objectEtapaActual[i].id === id) {
          return this.objectEtapaActual[i].nombre;
        }
      }
    } else {
      return '';
    }
  }

  getStyle(estado) {
    if (estado) {
      switch (estado) {
        case 'Verde':
            return 'badge badge-success';
        case 'Amarillo':
            return 'badge badge-warning';
        case 'Rojo':
            return 'badge badge-danger';
       default:
        // code...
        break;
        }
    }
}

  cargarProyectosAmbientales(id) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    return this.info.getProyectosAmbientales(id).subscribe((data: {}) => {
      this.proyectos = data;
      this.listaTemasGestion = this.proyectos;
      this.dialogSpin.close();
    }, (error)=>{
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  convertStringDateToTime(data) {
      const date = new Date(data);
      const localOffset = date.getTimezoneOffset() * 60000;
      const utc = date.getTime() + localOffset;
      return utc;
    }

}
