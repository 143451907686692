import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ProyectoEstructuracion } from '../../proyectoEstructuracion';
import { PublicAeropuertosConsultaFicha5gService } from './public-aeropuertos-consulta-ficha5g.service';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-public-aeropuertos-consulta-ficha5g',
  templateUrl: './public-aeropuertos-consulta-ficha5g.component.html',
  styleUrls: ['./public-aeropuertos-consulta-ficha5g.component.sass']
})
export class PublicAeropuertosConsultaFicha5gComponent implements OnInit {

  displayedColumnsT1: any[];
  DatosTabla: any = [];  
  proyectoSeleccionado:ProyectoEstructuracion = new ProyectoEstructuracion(0,'',0,'','','',0,0,0,0,0,0,0,0,0,0,'',0,0,0,0,0,'','',0,'','','','','','','','','','',0,'',0,new Date(),new Date(),new Date());
  items: MenuItem[]=[];
  itemsPasos:MenuItem[]=[];
  activarPasos:number=1; 
  estadosProyectos: any=[]; 
  blockedDocument = false;

  constructor(private publicAeropuertosConsultaFicha5gService: PublicAeropuertosConsultaFicha5gService,   private messageService: MessageService, private principalComponent: PrincipalComponent) {
    publicAeropuertosConsultaFicha5gService.getDatosProyectos5GBD().subscribe((dato) =>{          
      if (dato != null && dato.respuestaServicio != null) {        
        if (dato.respuestaServicio.codigoSalida === 1){  
          dato.proyectos.forEach(e => {
            if(!this.estadosProyectos.includes(e.estado)){
              if(e.estado != 'No Aplica'){
                this.estadosProyectos.push(e.estado);
                this.itemsPasos.push({label:e.estado});
              }    
              
            }
          });   
          this.itemsPasos.sort(function(a,b){
            if(a.label > b.label) { return -1; }
                if(a.label < b.label) { return 1; }
        return 0;
    
          });   
          
          this.CargarDatos(dato.proyectos.filter(p=>p.tipoModo==1  && (p.idOla==1 || p.idOla==2)))
        }
        else{         
          this.messageService.add({severity: 'error', summary: 'Error', detail: dato.respuestaServicio.mensajeSalida, life: 10000 });
        }
      }
    },
    error => {
      this.blockedDocument = false;
      console.error(error);
      principalComponent.cargarErrorServicio(error);      
    },
    () => {
      this.blockedDocument = false;
    });
    }

  //formato moneda en billones
   formatoMoneda(valor: number) {
    return valor? "$" + (valor/1000000000000).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + " Bn" : "$0";
  } 
    
    //formato separador miles
    formatoNumeros(valor: number) {
      return valor? valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
    }
 
  ngOnInit() {  
    this.blockedDocument = true;   
  }
  
  // Cargar los datos del menu lateral y los estados      
  CargarDatos(proyectos: ProyectoEstructuracion[]){
    
      this.activarPasos=1;
      proyectos.forEach( p => {                 
              this.items.push({label: p.nombre + " " + this.formatoMoneda(p.capex) , icon: 'pi pi-fw pi-plus', command:(event)=>{this.actualizarDatos(p)}})
      });

      

        this.displayedColumnsT1 = [
          { field: 'Caracteristica', header: 'Característica' },
          { field: 'Valor', header: 'Valor' }    
        ];
      
        this.actualizarDatos(proyectos[0]);
  }

  // actualizar datos segun el proyecto seleccionado
  actualizarDatos(proyecto: ProyectoEstructuracion ){   
    this.proyectoSeleccionado= proyecto;
    this.DatosTabla = [      
      { Caracteristica: 'Capex:', Valor: this.formatoMoneda(proyecto.capex)},
      { Caracteristica: 'Opex:', Valor: this.formatoMoneda(proyecto.opex)}      
    ]; 
     //Activas estados del proyecto
     for(var i=0; i<this.itemsPasos.length; i++)
     {
         if(this.itemsPasos[i].label == proyecto.estado)
       {
          this.activarPasos=i;      
          i=this.itemsPasos.length;
       }
     }  
  };

  //Navegar a cuarto de datos
  onNavigate(link) {    
    window.open(link, '_blank');    
  }
}