import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { InformeRevisionService } from '../../../informe-revision.service';
import { InformeSpaBaseComponent } from '../informe-spa-base/informe-spa-base.component';
import { EnumRespuestaServicio } from '../../../../../comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-informe-spa-obligacion-pago',
  templateUrl: './informe-spa-obligacion-pago.component.html',
  styles: []
})
export class InformeSpaObligacionPagoComponent implements OnInit {

  visibleDialogoSubirCertificadoObligacionPago = false;
  visibleDialogoVerAdjuntos = false;

  numeroObligacionPago: string;
  adjuntosCertificadoObligacionPago: CtoAdjuntoInformeBorradorDTO[] = [];

  constructor(
    private lineaBase: InformeSpaBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }
  ngOnInit() {
    this.cargarObligacionPago(this.lineaBase.informeContratista.id, this.lineaBase.informeContratista.idPlanPago);
  }

  cargarObligacionPago(idInforme: number, idPlanPago: number) {
    this.infCtaSupervisionService.ConsultarObligacionPago(idInforme, idPlanPago)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.numeroObligacionPago = result.numeroObligacionPago;
            if (result.adjuntoObligacionPago != null) {
              this.adjuntosCertificadoObligacionPago = result.adjuntoObligacionPago;
            }
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }
}
