import { Component, OnInit  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AmbientalDetalleService } from './ambiental-detalle.service';
import { Ambiental } from '../../../dtos/ambiental/detalle'
import { MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-ambiental-detalle',
  templateUrl: './ambiental-detalle.component.html',
  styleUrls: ['./ambiental-detalle.component.sass']
})

export class AmbientalDetalleComponent implements OnInit {

  proyecto: Ambiental = {};
  tipoObligacion: any = [];
  idAmbientalDetalle: any;
  nombreProyecto;

  dataSource: MatTableDataSource<any>;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(private detalleService: AmbientalDetalleService, private route: ActivatedRoute, private ruta: Router, public dialog: MatDialog, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.cargarTipoObligacion();
    this.route.params.subscribe(params => {
      this.idAmbientalDetalle = params.id;
      this.idAmbientalDetalle === 'new' ? this.cargarNuevo(this.idAmbientalDetalle) : this.cargarProyectoAmbiental(this.idAmbientalDetalle);
    });
  
  }

  cargarTipoObligacion() {
    return this.detalleService.getTipoObligacion().subscribe((data: {}) => {
      this.tipoObligacion = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarNuevo(id) {
    this.proyecto = {};
    this.proyecto.idProyecto = JSON.parse(localStorage.getItem('idP'));
  }

  cargarProyectoAmbiental(id: number) {
    this.detalleService.getProyectoAmbiental(id).subscribe((data: {}) => {
      this.proyecto = data;
    });
  }

  guardarDetalle(payload) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    if (this.idAmbientalDetalle === 'new') {
        return this.detalleService.guardarNuevoAmbiental(payload).subscribe((data: {}) => {
          this.ruta.navigate(['/infAmbiental', JSON.parse(localStorage.getItem('idP'))]);
          this.dialogSpin.close();
          },error => {
            this.principalComponent.cargarErrorServicio(error);
            this.dialogSpin.close();
          });
    } else {
        return this.detalleService.guardarDetalleAmbiental(payload).subscribe((data: {}) => {
          this.ruta.navigate(['/infAmbiental', JSON.parse(localStorage.getItem('idP'))]);
          this.dialogSpin.close();
          },error => {
            this.principalComponent.cargarErrorServicio(error);
            this.dialogSpin.close();
          });
    }
  }

  cancelar() {
    this.ruta.navigate(['/infAmbiental', JSON.parse(localStorage.getItem('idP'))]);
  }

}
