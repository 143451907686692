import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';

import { from } from 'rxjs';
import {
  AmbEjecucionDTO,
  AmbObligacionEncabezadoDTO,
  AmbSubCuentaDTO,
  AmbUbicacionAfectadaDTO,
  AmbUbicacionCompensadaDTO,
} from './compensaciones-ambientales.dto';
import { TrnRespuestaServicio } from '../parametricas/trn-respuesta-servicio';

@Injectable({
  providedIn: 'root',
})
export class CompensacionesAmbientalesService {
  public END_POINT = '';

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  /////////////////////////// Datos Compartidos ////////////////////////////////
  private proyectoSeleccionadoSource = new BehaviorSubject('');
  private subCuentaSource = new BehaviorSubject('');
  private obligacionAmbientalSource = new BehaviorSubject('');
  private seguimientoObligacionSource = new BehaviorSubject('');
  private obligacionEjecucionSource = new BehaviorSubject('');

  proyectoSeleccionado = this.proyectoSeleccionadoSource.asObservable();
  subCuenta = this.subCuentaSource.asObservable();
  obligacionAmbiental = this.obligacionAmbientalSource.asObservable();
  seguimientoObligacion = this.seguimientoObligacionSource.asObservable();
  obligacionEjecucion = this.obligacionEjecucionSource.asObservable();

  setProyectoSeleccionado(proyectoSeleccionadoL: any) {
    this.proyectoSeleccionadoSource.next(proyectoSeleccionadoL);
  }

  setSubCuenta(subCuentaL: any) {
    this.subCuentaSource.next(subCuentaL);
  }

  setObligacionAmbiental(obligacionAmbientalL: any) {
    this.obligacionAmbientalSource.next(obligacionAmbientalL);
  }

  setSeguimientoObligacion(seguimientoOblitacionL: any) {
    this.seguimientoObligacionSource.next(seguimientoOblitacionL);
  }

  setObligacionEjecucion(obligacionEjecucionL: any) {
    this.obligacionEjecucionSource.next(obligacionEjecucionL);
  }
  /////////////////////////////////////////////////////////////////////////

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public srvObtenerValorSubcuentaPorProyecto(
    idProyecto: number
  ): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        '/AmbValorSubCuenta/obtenerValorSubcuentaPorProyecto/' +
        idProyecto
      )
      .pipe(retry(1));
  }

  public srvGuardarValorSubcuenta(
    subcuentaDTO: AmbSubCuentaDTO
  ): Observable<RespuestaGenericaRpt> {
    const url = this.END_POINT + '/AmbValorSubCuenta/guardarValorSubCuenta';
    return this.http.post<RespuestaGenericaRpt>(url, subcuentaDTO).pipe(
      map((data) => {
        return data;
      }),
      retry(1)

    );
  }

  public srvListarObligacionesAmbientalesPorProyecto(
    idProyecto: number
  ): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        '/AmbObligacion/listarObligacionesAmbientalesPorProyecto/' +
        idProyecto
      )
      .pipe(retry(1));
  }

  public srvObtenerObligacionAmbientalPorId(
    idObligacion: number
  ): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        '/AmbObligacion/obtenerObligacionAmbientalPorId/' +
        idObligacion
      )
      .pipe(retry(1));
  }

  public srvAmbMaTipoCompensacion(): Observable<any> {
    return this.http
      .get(this.END_POINT + '/AmbMaTipoCompensacion')
      .pipe(retry(1));
  }

  public srvGuardarObligacionAmbiental(
    obligacionAmbiental: AmbObligacionEncabezadoDTO
  ): Observable<RespuestaGenericaRpt> {
    const url = this.END_POINT + '/AmbObligacion/guardarObligacionAmbiental';
    return this.http.post<RespuestaGenericaRpt>(url, obligacionAmbiental).pipe(
      map((data) => {
        return data;
      }),
      retry(1)

    );
  }

  public srvListarUbicacionesAfectadasPorObligacion(
    idObligacion: number
  ): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        '/AmbObligacion/listarUbicacionesAfectadasPorObligacion/' +
        idObligacion
      )
      .pipe(retry(1));
  }

  public srvGuardarUbicacionAfectadaPorObligacion(
    ubicacionAfectada: AmbUbicacionAfectadaDTO
  ): Observable<RespuestaGenericaRpt> {
    const url =
      this.END_POINT + '/AmbObligacion/guardarUbicacionAfectadaPorObligacion';
    return this.http.post<RespuestaGenericaRpt>(url, ubicacionAfectada).pipe(
      map((data) => {
        return data;
      }),
      retry(1)

    );
  }

  public srvEliminarUbicacionAfectada(
    ubicacionAfectada: AmbUbicacionAfectadaDTO
  ): Observable<RespuestaGenericaRpt> {
    const url =
      this.END_POINT +
      '/AmbObligacion/eliminarUbicacionAfectada/' +
      ubicacionAfectada.id;
    return this.http.delete<RespuestaGenericaRpt>(url).pipe(
      map((data) => {
        return data;
      }),
      retry(1)

    );
  }

  public srvListarSeguimientoObligacionesPorProyecto(
    idProyecto: number
  ): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        '/AmbObligacionEjecucion/listarSeguimientoObligacionesPorProyecto/' +
        idProyecto
      )
      .pipe(retry(1));
  }

  public srvListarEjecucionPorObligacion(
    idObligacion: number
  ): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        '/AmbObligacionEjecucion/listarEjecucionPorObligacion/' +
        idObligacion
      )
      .pipe(retry(1));
  }

  public srvObtenerEjecucionDetallePorId(
    idObligacion: number,
    idObligacionEjecucion: number
  ): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        '/AmbObligacionEjecucion/obtenerEjecucionDetallePorId/' +
        idObligacion +
        '/' +
        idObligacionEjecucion
      )
      .pipe(retry(1));
  }

  public srvAmbMaEstadoObligacion(): Observable<any> {
    return this.http
      .get(this.END_POINT + '/AmbMaEstadoObligacion')
      .pipe(retry(1));
  }

  public srvGuardarReporteEjecucionPorObligacionAmbiental(
    ejecucion: AmbEjecucionDTO
  ): Observable<RespuestaGenericaRpt> {
    const url =
      this.END_POINT +
      '/AmbObligacionEjecucion/guardarReporteEjecucionPorObligacionAmbiental';
    return this.http.post<RespuestaGenericaRpt>(url, ejecucion).pipe(
      map((data) => {
        return data;
      }),
      retry(1)

    );
  }

  public srvListarUbicacionesCompensadasPorEjecucion(
    idEjecucion: number
  ): Observable<any> {
    return this.http
      .get(
        this.END_POINT +
        '/AmbObligacionEjecucion/listarUbicacionesCompensadasPorEjecucion/' +
        idEjecucion
      )
      .pipe(retry(1));
  }

  public srvGuardarUbicacionCompensadaPorEjecucion(
    ubicacionAfectada: AmbUbicacionCompensadaDTO
  ): Observable<RespuestaGenericaRpt> {
    const url =
      this.END_POINT +
      '/AmbObligacionEjecucion/guardarUbicacionCompensadaPorEjecucion';
    return this.http.post<RespuestaGenericaRpt>(url, ubicacionAfectada).pipe(
      map((data) => {
        return data;
      }),
      retry(1)

    );
  }

  public srvEliminarUbicacionCompensada(
    ubicacionAfectada: AmbUbicacionCompensadaDTO
  ): Observable<RespuestaGenericaRpt> {
    const url =
      this.END_POINT +
      '/AmbObligacionEjecucion/eliminarUbicacionCompensada/' +
      ubicacionAfectada.id;
    return this.http.delete<RespuestaGenericaRpt>(url).pipe(
      map((data) => {
        return data;
      }),
      retry(1)

    );
  }

  public sincronizarZonaAfectada(): Observable<any> {
    return from(
      fetch(
        'https://sig.ani.gov.co/arcgissrv/rest/services/5_Ambiental/UpdateAreaAfectada/GPServer/Update%20Areas%20afectadas/submitJob?env%3AoutSR=&env%3AprocessSR=&returnZ=false&returnM=false&returnTrueCurves=false&f=pjson', // the url you are trying to access
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET', // GET, POST, PUT, DELETE
          mode: 'no-cors', // the most important option
        }
      )
    );
  }

  public sincronizarZonaCompensada(): Observable<any> {
    return from(
      fetch(
        'https://sig.ani.gov.co/arcgissrv/rest/services/5_Ambiental/UpdateAreaComp/GPServer/Update%20Areas%20Compensaci%C3%B3n/submitJob?env%3AoutSR=&env%3AprocessSR=&returnZ=false&returnM=false&returnTrueCurves=false&f=pjson', // the url you are trying to access
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET', // GET, POST, PUT, DELETE
          mode: 'no-cors', // the most important option
        }
      )
    );
  }

  public validarGeometriaAfectada(idObligacion: number): Observable<any> {
    return from(
      fetch(
        'https://sig.ani.gov.co/arcgissrv/rest/services/5_Ambiental/ACServicios/FeatureServer/0/query?where=IdObligacion%3D' +
        idObligacion +
        '&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=&returnGeometry=false&maxAllowableOffset=&geometryPrecision=&outSR=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=true&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&sqlFormat=none&f=pjson', // the url you are trying to access
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET', // GET, POST, PUT, DELETE
          mode: 'no-cors', // the most important option
        }
      )
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}

export class RespuestaGenericaRpt {
  constructor(public respuestaServicio?: TrnRespuestaServicio) { }
}
