import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { avanceProyecto } from '../../datos-abiertos/avanceProyecto';
import { DatosabiertosService } from '../../datos-abiertos.service';
import { ProyectoCarretero } from 'src/app/informacion-general/proyecto-carretero';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-da-avance-proyecto',
  templateUrl: './da-avance-proyecto.component.html',
  styleUrls: ['./da-avance-proyecto.component.sass']
})
export class DaAvanceProyectoComponent implements OnInit {
 
  avanceProyecto: avanceProyecto[]; 
  selectedAvanceProyecto: avanceProyecto[];
  cols: any[];
  exportColumns: any[];
  blockedDocument = false;
  proyectoCarretero: ProyectoCarretero[]=[];
  proyectoCarreteroSeleccionado: any;
  proyectoFiltro: any = []; 
  fechaInicio:Date;
  fechaFin:Date;
  
  constructor(private datosAbiertosService: DatosabiertosService, private principalComponent: PrincipalComponent) {    
     datosAbiertosService.getProyecto().subscribe((dato)=>{
       this.proyectoCarretero=dato.proyectoCarretero;       
     },
     error=>{
      this.blockedDocument = false;
      console.error(error);
      principalComponent.cargarErrorServicio(error);  
     },
     ()=>{
       this.blockedDocument=false;
     })
   }
  
  ngOnInit() {
    this.blockedDocument = true;
    this.cols = [
      { field: 'id', header: 'id' },
      { field: 'nombre', header: 'Nombre' },
      { field: 'porcentajeAvance', header: '% Avance' },
      { field: 'fechaReporte', header: 'Fecha Reporte' }
  ];  

  this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  // Filtrar por nombre de proyecto
  filtrarProyectos(event){   
    this.proyectoFiltro=this.proyectoCarretero.filter(d=> { return d.nombre.toLowerCase().includes(event.query.toLowerCase())});
  }

  // Consultar el proyecto seleccionado
  consultarProyecto(){   
    var idProyecto=null;
    if(this.proyectoCarreteroSeleccionado != null){
      idProyecto= this.proyectoCarreteroSeleccionado.idProyecto;
    }  
    this.blockedDocument=true;
    this.datosAbiertosService.getAvanceProyecto(idProyecto,this.fechaInicio,this.fechaFin).subscribe((dato)=>{
      if (dato.avanceProyecto != null) {
        this.avanceProyecto=dato.avanceProyecto.sort((a,b)=>(a.fechaReporte < b.fechaReporte?1:-1));          
      }
    },
      error=>{
        this.blockedDocument = false;
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);  
      },
     ()=>{this.blockedDocument=false}    
     ); 
  }

  public limpiarBusqueda() {
    this.proyectoCarreteroSeleccionado = null;
    this.fechaInicio = null;
    this.fechaFin = null;
    this.avanceProyecto = null;
  }
 
    //Exportar PDF
    exportPdf() {
      import("jspdf").then(jsPDF => {
        import("jspdf-autotable").then(x => {
           const doc = new jsPDF.default(0,0);
            doc.autoTable(this.exportColumns, this.avanceProyecto);
            doc.save('avanceProyecto.pdf');
        })
    })

    }

    //Exportar Excel
exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.avanceProyecto);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "avanceProyecto");
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}
}
