import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ProyectoDTO } from 'src/app/modulo-planeacion-proyecto/planeacion-proyecto.dto';
import { CrrViaAfectadaDTO, CrrImagenViaAfectadaDTO, CrrTipoAfectacionViaDTO, EnumCrrMaTipoAfectacion } from '../../ola-invernal.dto';
import { OlaInvernalService } from '../../ola-invernal.service';

@Component({
  selector: 'app-ola-invernal-reporte-detalle',
  templateUrl: './ola-invernal-reporte-detalle.component.html',
  styles: []
})
export class OlaInvernalReporteDetalleComponent implements OnInit {

  blockedDocument: boolean;

  idProyecto: number;
  soloLectura: boolean;
  idOlaInvernal: number;

  proyectoSeleccionado: ProyectoDTO;

  cierreViaAfectada: CrrViaAfectadaDTO;

  listaCrrTipoAfectacionVia: CrrTipoAfectacionViaDTO[] = [];
  afectacionViaAfectada: CrrTipoAfectacionViaDTO;
  dialogoGuardarAfectacion = false;

  listaCrrImagenViaAfectada: CrrImagenViaAfectadaDTO[] = [];
  imagenViaAfectada: CrrImagenViaAfectadaDTO;
  dialogoGuardarFotografia = false;

  constructor(
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private olaInvernalService: OlaInvernalService,
    private componentesComunesService: ComponentesComunesService,
  ) {
    this.cierreViaAfectada = {};
    this.afectacionViaAfectada = {};
    this.imagenViaAfectada = {};
  }

  ngOnInit() {

    const paramIdProyecto = "idProyecto";
    const paramIdOlaInveral = "idOlaInvernal";

    this.ruta.params.subscribe((params) => {
      this.idProyecto = Number(params[paramIdProyecto]);
      this.idOlaInvernal = Number(params[paramIdOlaInveral]);
    });
    if (this.idOlaInvernal != 0) {
      this.cargarInformacionCierre();
      this.cargarAfectacion();
      this.cargarRegistroFotografico();
    }
  }

  /**
   * Metodo para cargar la información del proyecto seleccionado
   */
  cargarinfoProyecto() {
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto)
    ).subscribe(
      ([proyectoData]) => {
        this.proyectoSeleccionado = proyectoData;
      },
      (error) => {
        this.principalComponent.cargarErrorServicio(error);
      }
    );
  }

  /**
   * Metodo que consulta la información de Ola Invernal registrada por id especificado
   * @returns Objeto de respuesta de tipo CrrViaAfectadaRpt
   */
  cargarInformacionCierre() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarCierreOlaInvernalPorId(this.idOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cierreViaAfectada = result.crrViaAfectada;
            if (this.cierreViaAfectada != null) {
              if (this.cierreViaAfectada.fechaCierre != null) {
                this.cierreViaAfectada.fechaCierre = new Date(this.cierreViaAfectada.fechaCierre);
              }
              if (this.cierreViaAfectada.fechaEstimadaApertura != null) {
                this.cierreViaAfectada.fechaEstimadaApertura = new Date(this.cierreViaAfectada.fechaEstimadaApertura);
              }
              if (this.cierreViaAfectada.fechaRealApertura != null) {
                this.cierreViaAfectada.fechaRealApertura = new Date(this.cierreViaAfectada.fechaRealApertura);
              }
              if (this.cierreViaAfectada.fechaReporte != null) {
                this.cierreViaAfectada.fechaReporte = new Date(this.cierreViaAfectada.fechaReporte);
              }
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que llama al servicio de consulta de afectaciones por cierre de ola invernal
   * @returns Objeto de tipo CrrTipoAfectacionViaDTO[] con el listado de afectaciones
   */
  cargarAfectacion() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarAfectacionesPorIdViaAfectada(this.idOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.listaCrrTipoAfectacionVia = result.listaCrrTipoAfectacionVia;
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
  * Metodo que gestiona el formulario de afectacion para consulta y consulta de la afectacion 
  * @param afectacion Objeto de tipo CrrTipoAfectacionViaDTO para realizar la consulta correspondiente
  */
  irConsultarAfectacion(afectacion: CrrTipoAfectacionViaDTO) {
    this.blockedDocument = true;
    this.cargarAfectacionPorId(afectacion.id);
  }

  /**
   * Metodo que llama al servicio de consulta de afectacion por id y gestiona los datos del formulario de afectacion
   * @param idAfectacion Id de la afectacion a consulatr
   * @returns 
   */
  cargarAfectacionPorId(idAfectacion: number) {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarAfectacionPorId(idAfectacion).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            if (result.crrTipoAfectacionVia != null) {
              this.dialogoGuardarAfectacion = true;
              this.afectacionViaAfectada = result.crrTipoAfectacionVia;
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que controla el evento de cierre del dialogo del formulario de afectacion 
   */
  cancelarDialogoAfectacion() {
    this.blockedDocument = false;
    this.dialogoGuardarAfectacion = false;
  }

  /**
    * Metodo que llama al servicio de consulta de imagenes por ola invernal
    * @returns Listado de registro fotograficos
    */
  cargarRegistroFotografico() {
    this.blockedDocument = true;
    return this.olaInvernalService.srvConsultarImagenesPorIdViaAfectada(this.idOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.listaCrrImagenViaAfectada = result.listaCrrImagenViaAfectada;
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que gestiona el formulario de fotografia para consulta y consulta de la fotografia por id 
   * @param foto Fotografia seleccionada para consultar
   */
  irConsultarFotografia(foto: CrrImagenViaAfectadaDTO) {
    this.blockedDocument = true;
    this.cargarRegistroFotograficoPorId(foto.id);
  }

  /**
   * Metodo que llama al servicio de consulta de fotografia por id y gestiona los datos del formulario de fotografia
   * @param idImagenOlaInvernal Id de la fotografia a consultar
   */
  cargarRegistroFotograficoPorId(idImagenOlaInvernal: number) {
    this.olaInvernalService.srvConsultarImagenOlaInvernalPorId(idImagenOlaInvernal).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.imagenViaAfectada = result.crrImagenViaAfectada;
            if (this.imagenViaAfectada != null) {
              this.dialogoGuardarFotografia = true;
              if (this.imagenViaAfectada.fechaFotografia != null) {
                this.imagenViaAfectada.fechaFotografia = new Date(this.imagenViaAfectada.fechaFotografia);
              }
            }
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que controla el evento de cierre del dialogo del formulario de fotografia 
   */
  cancelarDialogoSubirFotografia() {
    this.blockedDocument = false;
    this.dialogoGuardarFotografia = false;
  }

  bntVolver() {
    const ruta = "/reportes/carreteros/ola-invernal-reporte-detalle-listado";
    this.router.navigate([ruta]);
  }

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
      life: 10000,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
      life: 10000,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCrrMaTipoAfectacion() {
    return EnumCrrMaTipoAfectacion;
  }
}
