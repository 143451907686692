import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class CertificacionActivosService {

    public END_POINT = '';

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
      this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo que carga la lista del tipo de certificado
     */
    public getTipoCertificacion() {
        return this.http.get(this.END_POINT + '/ClbCertificadoLaboral/lista/tipoCertificado')
        .pipe(
        retry(1));
    }

    /**
     * Metodo que realiza la generacion del pdf retirado
     * @param data 
     */
    public postGenerarPdfActivo(data) {
      return this.http.post(this.END_POINT + '/ClbCertificadoLaboral/generacionPdfActivo/', data)
        .pipe(
          retry(1));
    }

    /**
     * Metodo que realiza la busqueda de los registrados por medio del numero de documento
     * @param numeroDocumento 
     */
    public getBusqueda(numeroDocumento) {
        return this.http.get(this.END_POINT + '/ClbCertificadoLaboral/activos/'+numeroDocumento)
          .pipe(
            retry(1));
      }

  }
