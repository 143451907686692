import { Component, OnInit } from '@angular/core';
import { CertificacionContratistaValidacionService, } from './certificacion-contratista-validacion.service';
import { Router } from '@angular/router';

import { PdfViewerModule } from 'ng2-pdf-viewer';

const ZOOM_STEP:number = 0.25;
const DEFAULT_ZOOM:number = 0.5;


@Component({
    selector: 'app-certificacion-contratista-validacion',
    templateUrl: './certificacion-contratista-validacion.component.html',
    styleUrls: ['./certificacion-contratista-validacion.component.sass'],
    providers: [CertificacionContratistaValidacionService]
})
export class CertificacionContratistaValidacionComponent implements OnInit {

    // Inicializaciones de Variables
    loading : boolean;
    datos : any = [];
    radicado : any;
    datosPdf : any;
    mostrar : any;
    mensajeError : any;
    urlTree : any;
    pdfSrc : any;
    pdfZoom : number = DEFAULT_ZOOM;


    constructor(private consultaService: CertificacionContratistaValidacionService,
        private router: Router, private pdfViewer: PdfViewerModule) { }

    ngOnInit() {
        this.loading = true;
        this.urlTree =  this.router.parseUrl(this.router.url)
        this.radicado = this.urlTree.queryParams['Radicado'];

        if(this.radicado != null && this.radicado!= undefined){
            this.getDescargarPdfInfoCertificado(this.radicado);
        }else{
            this.mostrar = false;
            this.mensajeError = "No se ha podido cargar la información con ese radicado. Puede ser que no se encuentre en el sistema.";
            this.loading = false;
        }
    }

  /**
  * Metodo que carga el pdf
  */
  getDescargarPdfInfoCertificado(radicado) {
    return this.consultaService.getDescargarPdfInfoCertificado(radicado).subscribe((data: {}) => {
      this.datosPdf = data;
      if(this.datosPdf!= null && this.datosPdf!= undefined && this.datosPdf.nombreArchivo != null ){
        this.mostrar = true;
        this.pdfSrc = this.base64ToArrayBuffer(this.datosPdf.archivo);
        this.loading = false;
      }else{
        this.mensajeError = "No se ha podido cargar la información con ese radicado. Puede ser que no se encuentre en el sistema.";
        this.mostrar = false;
        this.loading = false;
      }
    });
  }


  /**
   *Metodo que realiza la transformacion del file
   *
   * @param {*} base64
   * @returns
   * @memberof CertificacionesLaboralesValidacionComponent
   */
  base64ToArrayBuffer(base64) {
    let binary_string =  window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  /**
   * Metodo que incrementa el zoom
   *
   * @memberof CertificacionesLaboralesValidacionComponent
   */
  zoomIn()
	{
		this.pdfZoom += ZOOM_STEP;
	}

 /**
  * Metodo que aleja el zoom
  *
  * @memberof CertificacionesLaboralesValidacionComponent
  */
 zoomOut()
	{
		if (this.pdfZoom > DEFAULT_ZOOM) {
			this.pdfZoom -= ZOOM_STEP;
		}
	}

 /**
  * Metodo que reset el zoom
  *
  * @memberof CertificacionesLaboralesValidacionComponent
  */
 resetZoom()
	{
		this.pdfZoom = DEFAULT_ZOOM;
	}



}
