import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'visor-reporte-predial',
  templateUrl: './visor-reporte-predial.component.html'
})
export class VisorReportePredialComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    document.getElementById("nameseccion").innerHTML = "<span class='smallicon'><img src='assets/img/icn/carreteras_500-01-01.svg'/></span> Carreteras";
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
  }
  
}



