import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'aeropuertos-home-visor-mapa',
  templateUrl: './aeropuertos-home-visor-mapa.component.html'
})
export class AeropuertosHomeVisorMapaComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    document.getElementById("nameseccion").innerHTML = "<span class='smallicon'><img src='assets/img/icn/aeropuerto_500-02-02.svg'/></span> Aeropuertos";
    var element = document.getElementById("aniscopio_body");
    element.classList.add("public");
  }

  reportes(){
    this.route.navigate(['aeropuertos-public/consulta']);
  }

}
