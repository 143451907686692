import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-carreteras',
  templateUrl: './public-carreteras.component.html'
})
export class PublicCarreterasComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    document.getElementById("nameseccion").innerHTML = "<span class='smallicon'><img src='assets/img/icn/carreteras_500-01-01.svg'/></span> Carreteras";
    var element = document.getElementById("aniscopio_body");
  element.classList.add("public");
  }

  ingresar(){
    this.route.navigate(['/home']);
  }

  menulateral() {
    var element = document.getElementById("menu_lateral");
    element.classList.toggle("close");
  }

  reportes() {

    this.route.navigate(['carreteras-public/consulta'])
  }

  irAMapas(): void {
    this.route.navigate(['carreteras-public/mapa'])
    //    this.document.location = "https://sig.ani.gov.co/mapasinterno/";
    //window.open('https://animapas.maps.arcgis.com/apps/webappviewer/index.html?id=8782c0e22b3140f293fdce88d7ab8fcb', '_blank');
  }

}
