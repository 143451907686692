import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { CtoContratoInformacionVigenteEncabezadoDTO } from 'src/app/contratacion/contratacion-dto/contrato-informacion-vigente-encabezado';
import { CtoPlanPagoDetalleDTO } from '../../../../contratacion-dto/contrato-plan-pago-vigente';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { ReportesCuentasCobroCxpService } from '../../../reporte-contratacion-cuentas-cobro.service';

@Component({
  selector: 'app-reporte-contrato-plan-pago',
  templateUrl: './reporte-contrato-plan-pago.component.html',
  styles: []
})
export class ReporteContratoPlanPagoComponent implements OnInit {

  @Input()
  ctoContratoInformacionVigente: CtoContratoInformacionVigenteEncabezadoDTO;
  // Informacion del datatable
  reportePlanPagoVigente: CtoPlanPagoDetalleDTO[] = [];
  blockedDocument = false;

  constructor(
    private messageService: MessageService,
    private reportesCuentasCobroCxpService: ReportesCuentasCobroCxpService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarPlanPagoContrato(this.ctoContratoInformacionVigente.id);
  }

  cargarPlanPagoContrato(idContrato: number) {
    this.blockedDocument = true;
    this.reportesCuentasCobroCxpService.srvConsultarReporteContratoPlanPagoVigente(idContrato)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.reportePlanPagoVigente = result.contratoPlanPagoVigente;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );

  }

}
