import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PersonaSeguimientoService } from '../persona-seguimiento/persona-seguimiento.service';
import { ParametricasService } from '../../parametricas/parametricas.service';
import { NgForm } from '@angular/forms';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-persona-juridica-seguimiento',
  templateUrl: './persona-juridica-seguimiento.component.html',
  styleUrls: ['./persona-juridica-seguimiento.component.sass']
})
export class PersonaJuridicaSeguimientoComponent implements OnInit {

  @Input()
  idTipoDocumento: string;
  @Output()
  idPersonaJuridicaVar = new EventEmitter<number>();
  @Output()
  cancelarComponenteVar = new EventEmitter<boolean>();

  @Output () propagar: EventEmitter<string> = new EventEmitter();


  persona: any = {};
  numeroDocumentoBuscar: number;
  tipoDocumentoVar: any = [];
  personasList: any = [];
  habilitarCampos = false;
  personaExistente = false;

  // Informacion para los dialogos
  visibleDialogoExito = false;
  visibleDialogoConfirmarEliminarRegistro = false;
  visibleDialogoExitoEliminarRegistro = false;

  // Listas
  tiposDocumentoList: any = [];
  // tiposDocumentoSeleccionado: any;
  filtroDepartamentos: any[];
  departamentos: any[];
  departamentoSeleccionado: any;
  filtroCiudades: any[];
  ciudadSeleccionada: any;
  ciudades: any[] = [];

  ngOnInit() {
    this.cargarTiposDocumento();
    this.cargarDepartamentos(null);


    if (this.idTipoDocumento != null) {
      this.persona.idTipoDocumento = this.idTipoDocumento;
    }
  }


  constructor(private messageService: MessageService, private router: Router, private activatedRoute: ActivatedRoute,
    private svcPersonas: PersonaSeguimientoService,
    private parametricasService: ParametricasService) {
  }

  cargarTiposDocumento() {
    this.svcPersonas.getTiposDocumento().subscribe((data: any) => {
      this.tiposDocumentoList = data;
    });
    if (this.tiposDocumentoList != null && this.idTipoDocumento != null) {
      this.tipoDocumentoVar = this.tiposDocumentoList.find(t => String(t.id).toLowerCase().includes(this.idTipoDocumento.toLowerCase()));
    }
  }

  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    return this.parametricasService.getDepartamentos().subscribe((data: any) => {
      this.departamentos = data;
      if (event != null) {
        let existeFiltro: boolean;
        for (let i = 0; i < this.departamentos.length; i++) {
          const filtro = this.departamentos[i];
          if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
            this.filtroDepartamentos.push(filtro);
            existeFiltro = true;
          }
        }
        if (existeFiltro) {
          this.departamentos = this.filtroDepartamentos;
        }
      }
    });
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      const filtro = this.departamentos[i];
      if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  limpiarCiudades(event) {
    this.ciudadSeleccionada = null;
  }

  cargarCiudades(event) {
    if (this.departamentoSeleccionado != null) {
      this.filtroCiudades = [];
      return this.parametricasService.getCiudadesPorDepartamento(this.departamentoSeleccionado.id).subscribe((data: any) => {
        this.ciudades = data;
        if (event != null) {
          let existeFiltro: boolean;
          for (let i = 0; i < this.ciudades.length; i++) {
            const filtro = this.ciudades[i];
            if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              this.filtroCiudades.push(filtro);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.ciudades = this.filtroCiudades;
          }
        }
      });
    }
  }

  cargarFiltroCiudades(event) {
    this.filtroCiudades = [];
    for (let i = 0; i < this.ciudades.length; i++) {
      const filtro = this.ciudades[i];
      if (String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())) {
        this.filtroCiudades.push(filtro);
      }
    }
  }

  cargarPersonasJuridica() {
    return this.svcPersonas.getPersonasJurdicas()
      .subscribe(data => {
        this.personasList = data;
      });
  }



  guardarPersonaJuridica(forma: NgForm) {

    if (this.persona != null && !this.personaExistente) {

      const personJuridicaPost = {
        Nit: this.persona.numeroDocumento,
        Nombre: this.persona.nombre,
      };
      this.svcPersonas.setPersonaJuridica(personJuridicaPost)
        .subscribe((data: any) => {
          this.propagar.emit('true');
          this.lanzarMensajeInfo('Se ha creado la persona jurídica')

        });
    } else {
      this.habilitarCampos = false;
      this.idPersonaJuridicaVar.emit(this.persona.id);
    }
  }


  cancelarAgregarPersonaJuridica(cancelar: boolean) {
    this.idTipoDocumento = null;
    this.persona = {};
    this.cancelarComponenteVar.emit(cancelar);
  }


  /**
   * Dialogos
   */
  ///////////////////////////////Mensajes//////////////////////////////
    lanzarMensajeInfo(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
    }
  
    lanzarMensajeError(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
    }
  
    lanzarMensajeWarning(_mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
    }
  
    limpiarMensajes() {
      this.messageService.clear();
    }

  
}
