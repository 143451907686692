import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenusService {

  constructor(private http: HttpClient) { }

  public getMenus() {
   // if (sessionStorage.getItem('paginas') == null) {

    return  this.http.get<any>(environment.baseUrl + '/Menus')
        .pipe(
          retry(1))
    //}
  }
  
}
