
export class InformacionGeneral {
    constructor(
       public idSolicitud?: number,
        public numeroRadicado?: string,
        public remitente?: string,
        public asunto?: string,
        public email?: string,
        public estadoActual?: string,
        public tieneEncuestaSatisfaccion?: string,
        public dependencia?: string) {}

}




