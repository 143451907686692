
export class InformacionGeneral {
    constructor(
        public id?: any, 
        public idTramite?: number, 
        public idSolicitudInivias?: number, 
        public numeroRadicado?: string, 
        public fechaRadicado?: string, 
        public fechaRadicadoStr?: string, 
        public asunto?: string,
        public rutaAdjunto?: string,
        public adjunto?: string,
        public idPersonaSolicitante?: number,
        public idPersonaJuridicaSolicitante?: number) {}
}

export class InformacionConsultaGeneral {
    constructor(
        public numeroRadicado?: string, 
        public remitente?: string, 
        public asunto?: string, 
        public email?: string, 
        public estadoActual?: string,
        public dependencia?: string) {}

}

