import { Injectable } from "@angular/core";
import { ConfigService } from "../config.service";
import { from, Observable, throwError } from "rxjs";
import { map, retry, catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { TrnRespuestaServicio } from "../parametricas/trn-respuesta-servicio";
import { RptConsultaCrrEquipoItsDTO, RptCrrEquipoItsDTO, RptCrrMaEstadoEquipoItsDTO, RptCrrMaMedioTransmisionEquipoItsDTO, RptCrrMaProtocoloComunicacionEquipoItsDTO, RptCrrMaSentidoTraficoDTO, RptCrrMaTipoEquipoItsDTO, RptCrrMaTipoSuministroEnergeticoEquipoItsDTO } from "./equipos-its.dto";

@Injectable({
    providedIn: "root",
})
export class EquiposItsService {
    private mensajeCompartidoSource = new BehaviorSubject("default message");
    mensajeCompartido = this.mensajeCompartidoSource.asObservable();

    public END_POINT = "";

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
        }),
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    intercambiarMensaje(mensaje: string) {
        this.mensajeCompartidoSource.next(mensaje);
    }


    /**
     * Obtener listado parametrico de 
    */
    public srvListarMaEstadoEquipoIts(): Observable<RptCrrMaEstadoEquipoItsDTO> {
        return this.http
            .get(this.END_POINT + "/CrrMaEquipoIts/CrrMaEstadoEquipoIts/")
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Obtener listado parametrico de 
    */
    public srvListarMaMedioTransmisionEquipoIts(): Observable<RptCrrMaMedioTransmisionEquipoItsDTO> {
        return this.http
            .get(this.END_POINT + "/CrrMaEquipoIts/CrrMaMedioTransmisionEquipoIts/")
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Obtener listado parametrico de 
    */
    public srvListarMaProtocoloComunicacionEquipoIts(): Observable<RptCrrMaProtocoloComunicacionEquipoItsDTO> {
        return this.http
            .get(this.END_POINT + "/CrrMaEquipoIts/CrrMaProtocoloComunicacionEquipoIts/")
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Obtener listado parametrico de 
    */
    public srvListarMaSentidoTrafico(): Observable<RptCrrMaSentidoTraficoDTO> {
        return this.http
            .get(this.END_POINT + "/CrrMaEquipoIts/CrrMaSentidoTrafico/")
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Obtener listado parametrico de 
    */
    public srvListarMaTipoEquipoIts(): Observable<RptCrrMaTipoEquipoItsDTO> {
        return this.http
            .get(this.END_POINT + "/CrrMaEquipoIts/CrrMaTipoEquipoIts/")
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Obtener listado parametrico de 
    */
    public srvListarMaTipoSuministroEnergeticoEquipoIts(): Observable<RptCrrMaTipoSuministroEnergeticoEquipoItsDTO> {
        return this.http
            .get(this.END_POINT + "/CrrMaEquipoIts/CrrMaTipoSuministroEnergeticoEquipoIts/")
            .pipe(retry(1), catchError(this.handleError));
    }

    /**
     * Obtener listado parametrico de 
    */
    public srvListarEquipoItsPorProyecto(idProyecto: number): Observable<RptCrrEquipoItsDTO> {
        return this.http
            .get(this.END_POINT + "/CrrEquipoIts/ListarCrrEquipoItsPorProyecto/" + idProyecto)
            .pipe(retry(1), catchError(this.handleError));
    }

    public srvConsultarEquipoItsPorId(id: number): Observable<RptConsultaCrrEquipoItsDTO> {
        return this.http
            .get(this.END_POINT + "/CrrEquipoIts/ConsultarCrrEquipoItsPorId/" + id)
            .pipe(retry(1), catchError(this.handleError));
    }

    public srvGuardarEquipoIts(data): Observable<any> {
        // console.log(data);
        return this.http
            .post(this.END_POINT + "/CrrEquipoIts/GuardarCrrEquipoItsPorProyecto", data)
            .pipe(retry(1));
    }

    public getDepartamentos(idProyecto) {
        return this.http.get(this.END_POINT + '/CrrProyectoDepartamento/' + idProyecto)
            .pipe(
                catchError(this.handleError));
    }

    public getCiudadesPorDepartamento(idDepartamento) {
        return this.http.get(this.END_POINT + '/tramiteAmbiental/municipio/' + idDepartamento)
            .pipe(
                catchError(this.handleError));
    }

    /**
 * Elimina un equipo
*/
    public srvEliminarEquipoIts(id: number): Observable<any> {
        let url = this.END_POINT + "/CrrEquipoIts/eliminarCrrEquipoIts/" + id;
        return this.http.delete<any>(url).pipe(
            map((data) => {
                return data;
            }),
            retry(1),
            catchError(this.handleError)
        );
    }


    /////////////////////////////////////////////////////////////////////////////////////////

    // Error handling
    handleError(error) {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        window.alert(errorMessage);
        return throwError(errorMessage);
    }
}


