import { Component, OnInit, } from '@angular/core';
import { CertificacionConfiguracionService, } from './certificacion-configuracion.service';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
    selector: 'app-certificacion-configuracion',
    templateUrl: './certificacion-configuracion.component.html',
    providers: [CertificacionConfiguracionService]
})
export class CertificacionConfiguracionComponent implements OnInit {

    loading : boolean;
    listaCargoFirma : any = [];
    listaCargoFirmaCompleta : any = [];
    selectedSexo: string;
    cargoFirmaSeleccionada: any = { id: 0, nombre: ''};
    select = { id: 0, nombre: ''};
    objeto = { nombre: '', sexo: '', cargo: '', firma: ''};
    objetoEnviar = { nombre: '', sexo: '', cargo: '', firma: ''};
    fileToUpload: File = null;
    imageUpload : any;
    mostrarImg : any;
    datos : any;


    constructor(private consultaService: CertificacionConfiguracionService,
      private messageService: MessageService,
      public location: Location, ) { }

    /**
     * Metodo inicializador
     */
    ngOnInit() {
        this.loading = true;
        this.mostrarImg = false;
        this.cargoFirmaSeleccionada = { id: 0, nombre: ''};
        this.objetoEnviar = { nombre: '', sexo: '', cargo: '', firma: ''};
        this.objeto = { nombre: '', sexo: '', cargo: '', firma: ''};
        this.listaCargoFirma = [];
        this.cargarListaFirma();
        this.cargarDatosIniciales();
    }

  /**
   * Metodo que carga la lista de tipo de certificados
   */
  cargarListaFirma() {
    this.listaCargoFirma = [];
    this.select = { id: 0, nombre: 'Seleccione...'};
    this.listaCargoFirma.push(this.select);
    
    return this.consultaService.getlistaFirmaCargo().subscribe((data: {}) => {
      this.listaCargoFirmaCompleta = data;
    });
  }

   /**
   * Metodo que carga los datos inciales
   */
  cargarDatosIniciales() {
    return this.consultaService.getDatosIniciales().subscribe((data: {}) => {
      this.datos = data;
      this.objeto.sexo = this.datos.sexo;
      this.cargarInformacionActual();
      this.objeto.nombre = this.datos.nombre;
      this.objeto.firma = this.datos.firma;      
      this.imageUpload = this.objeto.firma;
      this.cargoFirmaSeleccionada.nombre =  this.datos.cargo;
      if(this.objeto.firma!= ""){
        this.mostrarImg = true;
      }else{
        this.mostrarImg = false;
      }
      this.objeto.cargo = this.datos.tipoCargo;
      this.loading = false;
    });
  }


  /**
   * Metodo que carga la firma
   * 
   * @param {FileList} files
   * @memberof CertificacionConfiguracionComponent
   */
  handleFileInput(files: FileList) {
    this.loading = true;
    this.fileToUpload = files.item(0);
    if(this.fileToUpload!= null){
      if(this.fileToUpload.type.includes("png") || this.fileToUpload.type.includes("jpg") || this.fileToUpload.type.includes("jpeg") ||
        this.fileToUpload.type.includes("PNG") || this.fileToUpload.type.includes("JPG") || this.fileToUpload.type.includes("JPEG")){
        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.imageUpload = reader.result;
            this.mostrarImg = true;
            this.loading = false;
        };
        this.loading = false;
      }else{
        files = null;
        this.mostrarImg = false;
        this.fileToUpload = null;
        this.imageUpload = null;
        this.lanzarMensajeError("El formato seleccionado no es compatible, debe ser algunos de los siguientes: png, jpg, jpeg.");
        this.loading = false;
      }
    }
    
  }

  /**
   * Metodo que realiza el guardado.
   *
   * @param {NgForm} forma
   * @memberof CertificacionConfiguracionComponent
   */
  enviar(forma: NgForm){
    this.loading = true;
    this.objetoEnviar = { nombre: '', sexo: '', cargo: '', firma: ''}; 
    var retorno = "";

    if(this.objeto.nombre != undefined && this.objeto.nombre != null && this.objeto.nombre != ""){
      this.objetoEnviar.nombre = this.objeto.nombre;
    }else{
      retorno = retorno + "Digitar el nombre del funcionario.\n";
    }
    if(this.objeto.sexo != undefined && this.objeto.sexo != null ){
      this.objetoEnviar.sexo = this.objeto.sexo;
    }else{
      retorno = retorno + "Seleccionar el sexo.\n";
    }
    if(this.cargoFirmaSeleccionada.nombre != undefined && this.cargoFirmaSeleccionada.nombre != null && this.cargoFirmaSeleccionada.nombre != ""){
      this.objetoEnviar.cargo = this.cargoFirmaSeleccionada.nombre;
    }else{
      retorno = retorno + "Seleccionar el cargo.\n";
    }
    if(this.imageUpload != undefined && this.imageUpload != null  && this.imageUpload != ""){
      this.objetoEnviar.firma = this.imageUpload;
    }else{
      retorno = retorno + "Seleccionar la imagen.\n";
    }
    if(retorno != ""){
      this.lanzarMensajeError(retorno);
      this.loading = false;
    }

    return this.consultaService.postEnviarInfo(this.objetoEnviar).subscribe((data: {}) => {
      this.loading = false;
      this.lanzarMensajeInfo("Se realizado el guardado de la información.");
    });

  }

  /**
   * Metodo que retorna
   */
  onReturn(){
    this.location.back();
  }


  /**
   * Metodo que actualiza la infomacion
   */
  cargarInformacionActual(){
    this.listaCargoFirma = [];
    this.select = { id: 0, nombre: 'Seleccione...'};
    this.listaCargoFirma.push(this.select);

    if(this.listaCargoFirmaCompleta!= null && this.objeto.sexo!= null ){
        this.listaCargoFirmaCompleta.forEach(element => {
            if(this.objeto.sexo  == "Masculino"){
                this.select = { id: 0, nombre: element.nombreMasculino};
            }else{
                this.select = { id: 0, nombre: element.nombreFemenino};
            }
            this.listaCargoFirma.push(this.select);
       });
    }  
  }


   ///////////////////////////////Mensajes//////////////////////////////
   lanzarMensajeInfo(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
  }

  lanzarMensajeError(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
  }

  lanzarMensajeWarning(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }


}
