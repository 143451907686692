import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PjePeriodoTraficoRpt } from './PjePeriodoTrafico';
import { map, retry } from 'rxjs/operators';
import { ConfigService } from '../../config.service';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class RecaudoPeajesService {


    public END_POINT = '';
    fechaActual: Date;

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient, public datepipe: DatePipe) {
        this.END_POINT = environment.baseUrl;
    }

    cargarPeriodos(idPeaje: number) {
        return this.http.get<PjePeriodoTraficoRpt>(this.END_POINT + '/PjeRecaudoPeajes/GetPeriodos/' + idPeaje)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    verAchivoTraficoyRecaudo() {
        return this.http.get(this.END_POINT + '/PjeRecaudoPeajes/GetArchivoTraficoRecaudo',
            {
                responseType: 'arraybuffer'
            }
        );
    }

    downLoadFile(data: any, type: string) {

        this.fechaActual = new Date();
        var fechaArchivo = this.datepipe.transform(this.fechaActual, 'yyyy-MM-dd HH:mm');
        console.log(fechaArchivo);

        let blob = new Blob([data], { type: type });

        saveAs.saveAs(blob, 'traficoYRecaudo ' + fechaArchivo + '.zip');
    }


}

