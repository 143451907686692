import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-puerto-home-ficha-proyecto',
  templateUrl: './puerto-home-ficha-proyecto.component.html'
})
export class PuertoHomeFichaProyectoComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
  }

  seg_gest_p_l() {
    this.route.navigate(['puertos-privado/seguimiento-gestion'])
  }

}
