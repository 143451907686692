import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TemasGestionDetalleService {

  public END_POINT = '';

    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public getTipoObligacion() {
    return this.http.get(this.END_POINT + '/ambiental/tipoObligacion')
      .pipe(
        retry(1));
  }

  public guardarDetalleGestion(data) {
    return this.http.put(this.END_POINT + '/Gestion/' , data)
      .pipe(
        retry(1));
  }

  public guardarNuevoGestion(data) {
    return this.http.post(this.END_POINT + '/Gestion/', data)
      .pipe(
        retry(1));
  }

  public getProyectosCarreteros() {
    return this.http.get(this.END_POINT + '/proyectoscarreteros')
      .pipe(
        retry(1));
  }

  public getProyectoAmbiental(id: number) {
    return this.http.get(this.END_POINT + '/Gestion/' + id)
      .pipe(
        retry(1));
  }


  public getSubTema() {
    return this.http.get(this.END_POINT + '/gestion/subtema')
      .pipe(
        retry(1));
  }

  public getTema() {
    return this.http.get(this.END_POINT + '/gestion/tema')
      .pipe(
        retry(1));
  }

  public getPriorizacion() {
    return this.http.get(this.END_POINT + '/Gestion/priorizacion')
      .pipe(
        retry(1));
  }

  public getEtapaActual() {
    return this.http.get(this.END_POINT + '/Gestion/etapas')
      .pipe(
        retry(1));
  }

  public getUnidades(id: number) {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/unidadFuncional/' + id)
      .pipe(retry(1));
  }


  public getTramo(id: number) {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/subtramo/' + id)
      .pipe(retry(1));
  }

}
