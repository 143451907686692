import { Component, OnInit } from '@angular/core';
import { SeguimientoComunicacionesService } from './seguimiento-comunicaciones.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { MessageService } from 'primeng/api';
import { EnumRespuestaServicio } from '../../../../comun/constantes/constantes-comunes';
import { SgrMessageError } from 'src/app/parametricas/sgr-message-error';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

  // Componente de Lista - Seguimiento

@Component({
  selector: 'app-seguimiento-comunicaciones',
  templateUrl: './seguimiento-comunicaciones.component.html',
  styleUrls: ['./seguimiento-comunicaciones.component.sass'],
  providers: [SeguimientoComunicacionesService]
})

export class SeguimientoComunicacionesComponent implements OnInit {

  // Inicializaciones de Variables

  displayedColumns1;
  listaSeguimiento: any = [];
  proyectos: any = [];
  id: number;
  nombreProyecto;
  dataSeguimiento;
  visibleConfirmarEliminar: boolean;
  visibleDialogoErrorServicio: boolean;
  message : any;
  evento : any;
  dataDelete;
  dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent> ;
  errorServicio: SgrMessageError = {};

  constructor(
    private info: SeguimientoComunicacionesService,
    private route: Router,
    private ruta: ActivatedRoute,
    public dialog: MatDialog,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
    ) {
    }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.ruta.params.subscribe(params => {
      this.id = params.id;
      this.cargarProyectosComunicaciones(this.id);
      localStorage.setItem('idP', JSON.stringify(this.id));
    });

    this.displayedColumns1 = [
      { field: 'origenSolicitud', header: 'Origen Solicitud' },
      { field: 'numeroRadicado', header: 'Número de Radicado' },
      { field: 'fechaRadicadoStr', header: 'Fecha de Radicado/Email' },
      { field: 'dependencia', header: 'Dependencia' },
      { field: 'grupoInterno', header: 'Grupo Interno de Trabajo' },
      { field: 'proyecto', header: 'Proyecto' },
      { field: 'solicitante', header: 'Solicitante' } ,
      { field: 'tipoSolicitud', header: 'Tipo de Solicitud' },
      { field: 'estado', header: 'Estado' }
    ];
  }

    // Funciones del componente
  onNew() {
    this.route.navigate(['/seguimiento-comunicaciones/registro-seguimiento-comunicaciones/infSeguimientoCNuevo/new']);
  }

  onEdit(id: number) {
    this.route.navigate(['/seguimiento-comunicaciones/registro-seguimiento-comunicaciones/infSeguimientoCDetalle', id]);
  }

  onFollow(id: number) {
    this.route.navigate(['/seguimiento-comunicaciones/registro-seguimiento-comunicaciones/infSeguimientoCRegistro', id]);
  }

  onDelete(data) {
    this.dataDelete = data;
    this.visibleConfirmarEliminar = true;
  }

  cancelarEliminar() {
    this.visibleConfirmarEliminar = false;
  }

  aceptarDialogoErrorServicio() {
    this.visibleDialogoErrorServicio = false;
  }

  aceptarEliminar() {
    this.info.deleteProyectoComunicacion(this.dataDelete.id).subscribe((data: {}) => {
      this.visibleConfirmarEliminar = false;
      this.responseDataDelete(data);
    }, error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
  });
  }

  cargarProyectosComunicaciones(id) {
    this.message = null;
    this.evento = null;
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    return this.info.getProyectosComunicaciones(id).subscribe((data: {}) => {
      this.responseData(data);
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });    
  }

  responseDataDelete(payload) {
    if (payload.objeto === null) {
      this.lanzarMensajeError(payload.respuestaServicio.mensajeSalida);
    } else {
      this.cargarProyectosComunicaciones(this.id);
    }
  }

  responseData(payload) {
    if (payload.objeto === null) {
      this.lanzarMensajeError(payload.respuestaServicio.mensajeSalida);
    } else {
      this.proyectos = payload;
      this.listaSeguimiento = this.proyectos;
      this.dialogSpin.close();
    }
  }
    // /////////////////////////////Mensajes//////////////////////////////
    lanzarMensajeInfo(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'success', summary: 'Información', detail: mensaje });
    }
    lanzarMensajeError(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    }
    lanzarMensajeWarning(mensaje: string) {
      this.limpiarMensajes();
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje });
    }

    limpiarMensajes() {
      this.messageService.clear();
    }


}
