import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlaneacionProyectoService {

  public END_POINT = '';

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public listarPlaneaciones(): Observable<any> {
    return this.http.get(this.END_POINT + '/PlaneacionProyecto/listarPlaneaciones')
      .pipe(
        retry(1),
        catchError(this.handleError));
  }

  public listarProyectos(): Observable<any> {
    return this.http.get(this.END_POINT + '/PlaneacionProyecto/listarProyectos')
      .pipe(
        retry(1),
        catchError(this.handleError));
  }

  public listarPediodicidad(): Observable<any> {
    return this.http.get(this.END_POINT + '/PlaneacionProyecto/listarPeriodicidad')
      .pipe(
        retry(1),
        catchError(this.handleError));
  }

  // Error handling
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
