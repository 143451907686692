import { Injectable } from '@angular/core';
import * as datos from '../../../assets/datos/datosGenerales1Ola.json';
import { Proyecto5G } from './Proyecto5G';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ProyectoEstructuracion } from 'src/app/reportes/publicos/proyectoEstructuracion';
import { ConfigService } from 'src/app/config.service';
import { environment } from 'src/environments/environment';
import { TrnRespuestaServicio } from 'src/app/parametricas/trn-respuesta-servicio';

@Injectable({
  providedIn: 'root'
})
export class PublicProyectos5GService {

  datosProyectos: any = (datos as any).default;  
  proyectos5G:Proyecto5G[];

  public END_POINT = '';

  // Http Options
  httpOptions = {
   headers: new HttpHeaders({
       'Content-Type': 'application/json'
   })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
   }

  //Consulta los datos de la base de datos
  public getDatosProyectos5GBD() {
    return this.http.get<ConsultarProyectoEstructuracionRpt>(this.END_POINT + '/TrnProyectoEstructuracion')
         .pipe(
           map(data => {            
            return data;
           }),
           retry(1)
          );
  }

  //Consultar la informacion consolidada de la primera ola
  public getDatosProyectosCarretero5G(tipo: string):Proyecto5G[]{
    this.proyectos5G= this.datosProyectos.filter(p=> p.tipo==tipo);    
    return this.proyectos5G;
  }  
}

//Objeto respuesta servicio 
export class ConsultarProyectoEstructuracionRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public proyectos?: ProyectoEstructuracion[],
  ) { }
}