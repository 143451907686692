import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { C19ReporteCovidConsolidado, C19ReporteCovid, C19ReinicioObra, C19ReinicioArticulacion, EntidadArticulacion, EstadoReinicioObra, C19TrabajadoresPorDepartamento } from './covid-reportar.dto';
import { TrnRespuestaServicio } from '../parametricas/trn-respuesta-servicio';

@Injectable({
  providedIn: 'root'
})
export class CovidReportarReinicioObraService {

  // private mensajeCompartidoSource = new BehaviorSubject('default message');
  // mensajeCompartido = this.mensajeCompartidoSource.asObservable();

  public END_POINT = '';

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private config: ConfigService, private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  public srvConsultarReinicioObra(id): Observable<ConsultaReinicioObraRpt> {
    return this.http.get<ConsultaReinicioObraRpt>(this.END_POINT + '/C19ReinicioDeObra/ConsultarReinicioObra/' + id)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvGuardarReinicioObra(c19ReporteCovid: any): Observable<GuardarReinicioObraRpt> {
    let url = this.END_POINT + '/C19ReinicioDeObra/GuardarReinicioObra';

    return this.http.post<GuardarReinicioObraRpt>(url, c19ReporteCovid)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvReactivarReinicioObra(idProyecto: number): Observable<RespuestaGenericaRpt> {
    let url = this.END_POINT + '/C19ReinicioDeObra/reactivarReinicioObra/' + idProyecto;

    return this.http.put<RespuestaGenericaRpt>(url, idProyecto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvGuardarAccionArticulacion(c19ReinicioArticulacion: C19ReinicioArticulacion): Observable<RespuestaGenericaRpt> {
    let url = this.END_POINT + '/C19ReinicioDeObra/GuardarAccionArticulacion/';

    return this.http.post<RespuestaGenericaRpt>(url, c19ReinicioArticulacion)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvGuardarTrabajadoresPorDepartamento(c19TrabajadoresPorDepto: C19TrabajadoresPorDepartamento): Observable<RespuestaGenericaRpt> {
    let url = this.END_POINT + '/C19ReinicioDeObra/GuardarTrabajadoresPorDepartamento/';

    return this.http.post<RespuestaGenericaRpt>(url, c19TrabajadoresPorDepto)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarAccionArticulacion(idProyecto: number): Observable<ConsultarArticulacionProyectoRpt> {
    let url = this.END_POINT + '/C19ReinicioDeObra/getAccionArticulacionPorIdProyecto/' + idProyecto;

    return this.http.get<ConsultarArticulacionProyectoRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarTrabajadoresPorDepartamento(idProyecto: number): Observable<ConsultarTrabajadoresPorDepartamentoRpt> {
    let url = this.END_POINT + '/C19ReinicioDeObra/ListarTrabajadoresPorDepartamento/' + idProyecto;

    return this.http.get<ConsultarTrabajadoresPorDepartamentoRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ////////////////////Parametricas/////////////////////////
  public srvConsultarEstadosReinicioObra(): Observable<EstadoReinicioObra[]> {
    let url = this.END_POINT + '/C19maEstadoReinicioObra/';
    return this.http.get<EstadoReinicioObra[]>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarEntidadArticulacion(): Observable<ConsultaEntidadArticulacionRpt> {
    let url = this.END_POINT + '/C19ReinicioDeObra/listadoEntidadArticulacion/';
    return this.http.get<ConsultaEntidadArticulacionRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

}

export class RespuestaGenericaRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio
  ) { }
}

export class GuardarReinicioObraRpt {
  constructor(
    public respuestaServicio?: GuardarReinicioObraRptServicio
  ) { }
}

export class GuardarReinicioObraRptServicio {
  constructor(
    public codigoSalida?: number,
    public mensajeSalida?: string,
    public idReinicioObra?: number) { }
}



export class ConsultaReinicioObraRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public c19ReinicioObraList?: C19ReinicioObra[]
  ) { }
}

export class ConsultarArticulacionProyectoRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public c19accionArticulacion?: C19ReinicioArticulacion[],
  ) { }
}

export class ConsultarTrabajadoresPorDepartamentoRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public c19TrabajadoresPorDepartamento?: C19TrabajadoresPorDepartamento[],
  ) { }
}

export class ConsultaEntidadArticulacionRpt {
  constructor(
    public respuestaServicio?: TrnRespuestaServicio,
    public c19EntidadArticulacion?: EntidadArticulacion[]
  ) { }
}

