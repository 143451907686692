import { Component, OnInit } from '@angular/core';
import { InformeVoboBaseComponent } from '../informe-vobo-base/informe-vobo-base.component';
import { InformeRevisionService } from '../../../informe-revision.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { EnumCtoTipoAnexoInforme } from 'src/app/contratacion/constantes/contratacion-constantes';
import { CtoInformeRevisionAnexoDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-anexo';

@Component({
  selector: 'app-informe-vobo-anexos',
  templateUrl: './informe-vobo-anexos.component.html',
  styles: []
})
export class InformeVoboAnexosComponent implements OnInit {

  anexosInformeRevision: CtoInformeRevisionAnexoDTO[];
  adjuntosAnexoInforme: CtoAdjuntoInformeBorradorDTO[];
  visibleDialogoVerAdjuntos: boolean;

  constructor(
    private lineaBase: InformeVoboBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    // this.cargarAnexos(this.lineaBase.idInformeParam, this.lineaBase.idPersonaAsignacionParam, this.lineaBase.idPasoParam);
    this.cargarAnexos(EnumCtoTipoAnexoInforme.SUBTIPO_ANEXOS_ADICIONALES, this.lineaBase.idInformeParam, this.lineaBase.idPersonaAsignacionParam, this.lineaBase.idPasoParam);
  }

  cargarAnexos(idsSubTipoAnexos: string, idInforme: number, idPersonaAsignacion: number, idPaso: number) {
    this.lineaBase.blockedDocument = true;
    this.infCtaSupervisionService.ConsultarAnexosPorSubTipoInformeRevision(idsSubTipoAnexos, idInforme, idPersonaAsignacion, idPaso)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.anexosInformeRevision = result.anexosInformeRevision;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  irVerDialogoVerAdjuntosAnexos(idAnexo: number) {
    this.visibleDialogoVerAdjuntos = true;
    this.cargarAdjuntosPorAnexosAdicionales(idAnexo, this.lineaBase.idPersonaAsignacionParam);
  }

  cargarAdjuntosPorAnexosAdicionales(idAnexo: number, idPersonaAsignacion: number) {
    this.infCtaSupervisionService.ConsultarAdjuntosPorAnexoInformeRevision(idAnexo, idPersonaAsignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosAnexoInforme = result.adjuntos;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

}
