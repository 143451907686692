import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { CtoContratoBalanceFinancieroDTO } from '../contratacion-dto/contrato-balance-financiero';
import { CtoContratoInformacionVigenteEncabezadoDTO } from '../contratacion-dto/contrato-informacion-vigente-encabezado';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { Location } from '@angular/common';
import { CtoPlanPagoDetalleDTO } from '../contratacion-dto/contrato-plan-pago-vigente';
import { EnumRespuestaServicio } from '../../comun/constantes/constantes-comunes';
import { EnumCtoPasoInforme } from '../constantes/contratacion-constantes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';


@Component({
  selector: 'app-contrato-plan-pago',
  templateUrl: './contrato-plan-pago.component.html',
  styles: []
})
export class ContratoPlanPagoComponent implements OnInit {

  blockedDocument: boolean;

  idContrato: number;
  ctoContratoInformacionVigenteBase: CtoContratoInformacionVigenteEncabezadoDTO;
  ctoContratoBalanceFinanciero: CtoContratoBalanceFinancieroDTO = {};

  periodoPago: CtoPlanPagoDetalleDTO;

  visibleDialogoAgregar: boolean;
  visibleConfirmarEliminar: boolean;

  habilitarEdicionPagadoGeneral = false;

  formEnError = true;
  fechaInicioError = true;
  fechaFinError = true;
  mensajeFechaInicioError: string;
  mensajeFechaFinError: string;

  valorMaximo: number;
  valorPendiente: number;

  callback: string;

  msgRecuerdePuedeCambiarPlanPagos = 'Recuerde. Los periodos de pago se puede poner Pagados solo sí, no se tiene relacionado ningún informe de contratista (aplica para contratos que nunca han pasado por Aniscopio o son nuevos). Para un periodo de pago puede modificar las fechas de inicio y fin, valor y estado pagado solo sí No tiene un informe contratista relacionado o si el informe que esté relacionado está en estado Correcciones o en Borrador';


  constructor(
    private messageService: MessageService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private location: Location,
    private parametricasService: ParametricasService,
    private contratacionService: ContratacionService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.blockedDocument = true;
    this.ctoContratoBalanceFinanciero = {};
    this.ctoContratoBalanceFinanciero.planPagos = [];
    this.activateRoute.params.subscribe(params => {
      this.idContrato = params.idContrato;
    });
    this.habilitarEdicionPagadoGeneral = false;
  }

  actualizarContratoInformacionVigente(contratoVigente: CtoContratoInformacionVigenteEncabezadoDTO) {
    this.ctoContratoInformacionVigenteBase = contratoVigente;
    this.ctoContratoInformacionVigenteBase.fechaSuscripcion = new Date(contratoVigente.fechaSuscripcion);
    this.ctoContratoInformacionVigenteBase.fechainicio = new Date(contratoVigente.fechainicio);
    this.ctoContratoInformacionVigenteBase.fechaTerminacionContrato = new Date(contratoVigente.fechaTerminacionContrato);
    this.cargarBalanceFinanciero();
  }

  cargarBalanceFinanciero() {
    this.blockedDocument = true;
    this.contratacionService.ConsultarContratoBalanceFinanciero(this.ctoContratoInformacionVigenteBase.id)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.ctoContratoBalanceFinanciero = result.contratoBalanceFinanciero;
              this.cargarValidacionesPlanPago();
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.blockedDocument = false;
        }
      );
  }

  cargarValidacionesPlanPago() {
    let valorPlanPago = 0;
    if (this.ctoContratoBalanceFinanciero.planPagos != null) {
      this.ctoContratoBalanceFinanciero.planPagos.forEach(pln => {
        valorPlanPago = valorPlanPago + pln.valorPlanPago;
      });

      this.valorPendiente = this.ctoContratoInformacionVigenteBase.valorFinalContrato - valorPlanPago;


      // VALIDAR EDICION PAGADO GENERAL
      // Si ya existe al menos un periodo de pago con informe definitivo y en estado pagado, no se permite editar el pagado
      const periodosPagoConInformeDefinitvoPagado: CtoPlanPagoDetalleDTO[] = this.ctoContratoBalanceFinanciero.planPagos.filter(pln => pln.idInforme != null && pln.idPaso != null && pln.idPaso == 9);
      if (periodosPagoConInformeDefinitvoPagado == null || (periodosPagoConInformeDefinitvoPagado != null && periodosPagoConInformeDefinitvoPagado.length == 0)) {
        this.habilitarEdicionPagadoGeneral = true;
      }
      // Validar cada periodo de pago si puede editar el pagado
      this.ctoContratoBalanceFinanciero.planPagos.forEach(pln => {
        // Si el periodo no tiene informe definitivo, se puede modificar el pagado
        if (pln.idInforme == null) {
          pln.habilitarEdicionPagado = true;
        } else {
          pln.habilitarEdicionPagado = false;
        }

        // VALIDAR EDICION PERIODO DE PAGO
        if (pln.idInforme == null) {
          pln.habilitarEdicionPeriodoPago = true;
        } else {
          // Si el periodo tiene informe definitivo en poder del contratista poder modificar el periodo de pago
          if (pln.idInforme != null && pln.idPaso != null && pln.idPaso == EnumCtoPasoInforme.EN_CORRECCIONES) {
            pln.habilitarEdicionPeriodoPago = true;
          } else {
            pln.habilitarEdicionPeriodoPago = false;
          }
        }
      });
    }
  }

  onNew() {
    this.periodoPago = {
      idContrato: this.ctoContratoInformacionVigenteBase.id,
      fechaInicioPlanPago: new Date(),
      fechaFinPlanPago: new Date(),
      valorPlanPago: 0
    };
    // Obtener valor máximo a partir de la resta del valor del contrato menos el plan de pagos programado
    // this.valorPendiente = this.contrato.valorContrato - this.pagos.reduce((suma, actual) => suma + actual.valor, 0);
    // this.valorMaximo = this.honorariosMensuales > this.valorPendiente ? this.valorPendiente : this.honorariosMensuales;
    this.valorMaximo = this.ctoContratoInformacionVigenteBase.honorariosMensuales > this.valorPendiente ? this.valorPendiente : this.ctoContratoInformacionVigenteBase.honorariosMensuales;
    this.fechaCambiada();
    this.visibleDialogoAgregar = true;
  }

  agregar() {
    this.blockedDocument = true;
    this.visibleDialogoAgregar = false;

    this.contratacionService.GuardarPeriodoPago(this.periodoPago).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cargarBalanceFinanciero();
            this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          } else {
            console.error(result.respuestaServicio.mensajeSalida);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {
      }
    );
  }

  cancelar() {
    this.visibleDialogoAgregar = false;
    this.periodoPago = null;
    // this.cargarBalanceFinanciero();
  }

  onEdit(pago) {
    this.periodoPago = pago;
    this.periodoPago.fechaInicioPlanPago = new Date(pago.fechaInicioPlanPago);
    this.periodoPago.fechaFinPlanPago = new Date(pago.fechaFinPlanPago);
    this.visibleDialogoAgregar = true;

    // this.valorPendiente = this.contrato.valorContrato - this.pagos.reduce((suma, actual) => suma + actual.valor, 0);
    this.valorMaximo = (this.ctoContratoInformacionVigenteBase.honorariosMensuales > this.valorPendiente ? this.valorPendiente : this.ctoContratoInformacionVigenteBase.honorariosMensuales) + pago.valorPlanPago;

    this.fechaCambiada();
  }
  onDelete(pago) {
    this.periodoPago = pago;
    this.visibleConfirmarEliminar = true;
  }
  cancelarEliminar() {
    this.visibleConfirmarEliminar = false;
  }

  aceptarEliminar() {
    this.blockedDocument = true;
    this.visibleConfirmarEliminar = false;
    this.contratacionService.eliminarPlanPago(this.periodoPago.idPlanPago)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.cargarBalanceFinanciero();
              this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        },
        () => {
        }
      );
  }

  fechaCambiada() {
    this.fechaInicioError = this.periodoPago.fechaInicioPlanPago < this.ctoContratoInformacionVigenteBase.fechainicio;
    if (this.fechaInicioError) {
      this.mensajeFechaInicioError = 'La fecha de inicio no puede ser anterior al inicio del contrato';
    } else {
      this.fechaInicioError = this.periodoPago.fechaInicioPlanPago > this.periodoPago.fechaFinPlanPago;
      if (this.fechaInicioError) {
        this.mensajeFechaInicioError = 'La fecha no puede ser superior a la fecha fin';
      }
    }

    this.fechaFinError = this.periodoPago.fechaFinPlanPago > this.ctoContratoInformacionVigenteBase.fechaTerminacionContrato;
    if (this.fechaFinError) {
      this.mensajeFechaFinError = 'La fecha no puede ser superior a la fecha final del contrato';
    } else {
      this.fechaFinError = this.periodoPago.fechaFinPlanPago < this.periodoPago.fechaInicioPlanPago;
      if (this.fechaFinError) {
        this.mensajeFechaFinError = 'La fecha no puede ser inferior a la fecha inicio';
      } else {
        const diff = Math.abs(this.periodoPago.fechaFinPlanPago.getTime() - this.periodoPago.fechaInicioPlanPago.getTime());
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        this.fechaFinError = diffDays > 31;
        if (this.fechaFinError) {
          this.mensajeFechaFinError = 'Un periodo no puede ser superior a 30 días';
        }
      }
    }

    if (this.ctoContratoBalanceFinanciero != null && this.ctoContratoBalanceFinanciero.planPagos != null && this.ctoContratoBalanceFinanciero.planPagos.length > 0) {
      if (!this.fechaInicioError) {
        this.fechaInicioError = this.ctoContratoBalanceFinanciero.planPagos.filter(p => {
          if (this.periodoPago.id != p.id) {
            return new Date(this.periodoPago.fechaInicioPlanPago) <= new Date(p.fechaFinPlanPago) && new Date(this.periodoPago.fechaInicioPlanPago) >= new Date(p.fechaInicioPlanPago);
          }
        }).length > 0;
        if (this.fechaInicioError) {
          this.mensajeFechaInicioError = 'La fecha no puede estar entre el periodo de otro pago';
        }
      }

      if (!this.fechaFinError) {
        this.fechaFinError = this.ctoContratoBalanceFinanciero.planPagos.filter(p => {
          if (this.periodoPago.id != p.id) {
            return new Date(this.periodoPago.fechaFinPlanPago) <= new Date(p.fechaFinPlanPago) && new Date(this.periodoPago.fechaFinPlanPago) >= new Date(p.fechaInicioPlanPago);
          }
        }).length > 0;
        if (this.fechaFinError) {
          this.mensajeFechaFinError = 'La fecha no puede estar entre el periodo de otro pago';
        }
      }
    }
  }

  aprobarPago(pago: CtoPlanPagoDetalleDTO) {
    this.blockedDocument = true;
    this.contratacionService.establecerPago(pago.idPlanPago, this.ctoContratoInformacionVigenteBase.id, pago.pagado)
      .subscribe(
        result => {
          this.cargarBalanceFinanciero();
          this.blockedDocument = false;
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.blockedDocument = false;
        },
        () => {
        }
      );
  }

  irRegresar() {


    this.location.back();

    if (this.callback == 'planPagos') {
      // this.route.navigate([this.route.url + '/novedadesContrato',  this.idContrato]);
      // this.route.navigate(['ctoAdicional/pln/planPagos/', this.idContrato, 'contratoPlanPagoContratista']);
    }

    if (this.callback == 'contratoPlanPagoContratista') {
      // this.route.navigate([this.route.url + '/novedadesContrato',  this.idContrato]);
      // this.route.navigate(['ctoAdicional/pln/planPagos/', this.idContrato, 'contratoPlanPagoContratista']);
    }
    // if (contrato.esResponsableIva) {
    //   this.route.navigate([this.route.url + '/planPagosContratista', contrato.id, 1]);
    // } else {
    //   this.route.navigate([this.route.url + '/planPagosContratista', contrato.id, 0]);
    //   http://localhost:4200/misContratos/planPagosContratista/363/0
    // }


  }

}
