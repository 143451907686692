import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InformacionGeneralService } from '../informacion-general/informacion-general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from '../home/principal/principal.component';

@Component({
  selector: 'app-proyecto-home-puerto',
  templateUrl: './proyecto-home-puerto.component.html',
  styleUrls: ['./proyecto-home-puerto.component.sass']
})
export class ProyectoHomePuertoComponent implements OnInit {

  proyecto: any;
  id: number;
  esProduccion: boolean = false;
  webUrlConcesiones : any;

  constructor(private infoService: InformacionGeneralService, private route: ActivatedRoute, private router: Router, @Inject(DOCUMENT) private document: any,
  private messageService: MessageService, private principalComponent: PrincipalComponent) {
   }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.cargarProyectoCarretero(this.id);
    });
    localStorage.removeItem('idP');
    this.esProduccion = environment.esProduccion;
    this.webUrlConcesiones = environment.webUrlConcesiones;
  }

  cargarProyectoCarretero(id: number) {
    this.infoService.getProyectoPortuario(id).subscribe((data: {}) => {
      this.proyecto = data;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irALineaBase(){
    this.router.navigate([this.router.url+'/proyectoLineaBase', this.id]);
  }

  irAGeneral() {
    this.router.navigate([this.router.url+'/infGeneral', this.id]);
  }

  irAAmbiental() {
      this.router.navigate([this.router.url+'/infAmbiental', this.id]);
  }

  irAPredial() {
    this.router.navigate([this.router.url+'/infPredial', this.id]);
  }

  irARegistro() {
    this.document.location = "https://concesionessherlockaniwebtest.azurewebsites.net/concesiones/" + this.id + "/dashboard";
  }

  irAInfInterventoriaMinTrans() {
    this.document.location = "https://gpi.mintransporte.gov.co/reports/loadReports"
  }

  irAUnidadesFuncionales() {
    this.router.navigate([this.router.url+'/uniFuncionales', this.id])
  }

  irAFinancieroHome(){
    this.router.navigate([this.router.url+'/financieroHome', this.id]);
  }

  irAJuridico(){
    this.router.navigate([this.router.url+'/menuJuridico', this.id]);
  }

  irAFinanciero(){
    this.router.navigate([this.router.url+'/menuFinanciero', this.id])
  }

  irATramitesAmbientales(){
    this.router.navigate([this.router.url+'/tramites-ambientales-listado', this.id])
  }

  irATemasG(){
    this.router.navigate([this.router.url+'/infTemasG', this.id])
  }

  irACovidReportar(){
    this.router.navigate([this.router.url+'/covid-reportar', this.id])
  }

  irAPolizas(){
    this.router.navigate([this.router.url + '/polizas', this.id]);
  }

  loadProyectoPuerto(){
    this.loadFile("https://anionline.sharepoint.com/Gesti%C3%B3n%20VGC/GerenciaPuertos/SitePages/Home.aspx");
  }

  loadGestionPuerto(){
    this.loadFile("https://anionline.sharepoint.com/Gesti%C3%B3n%20VGC/GerenciaPuertos/SitePages/Home.aspx");
  }

  loadFile(urlFile){
    window.open(urlFile, null);
  }
}
