import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { forkJoin, Subscription } from "rxjs";
import { Router } from "@angular/router";

import { MessageService } from "primeng/api";
import { IngReservaPuestoTrabajoDTO } from "./puestos-trabajo.dto";
import { PuestosTrabajoService } from "./puestos-trabajo.service";
import { InvBienAniPersonalDTO } from "../inventario-equipos/inventario-equipos.dto";
import { PrincipalComponent } from "../home/principal/principal.component";

@Component({
  selector: "app-puestos-trabajo",
  templateUrl: "./puestos-trabajo.component.html",
  styleUrls: ["./puestos-trabajo.component.sass"],
})
export class PuestosTrabajoComponent implements OnInit {
  blockedDocument: boolean;
  subscription: Subscription;

  reservaPuestoTrabajoList: IngReservaPuestoTrabajoDTO[];
  reservaPuestoTrabajoListHistorico: IngReservaPuestoTrabajoDTO[];
  reservaPuestoTrabajo: IngReservaPuestoTrabajoDTO;
  imagenQr: any;
  isImageLoading: boolean;
  equiposReserva: InvBienAniPersonalDTO[];

  dialogoCompensaciones: boolean;
  dialogoEquipos: boolean;
  dialogoQrReserva: boolean;
  dialogoEliminar: boolean;

  fechaActual: Date;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private puestosTrabajoService: PuestosTrabajoService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.fechaActual = new Date();
    // Inicializar listas parametricas y DTOs
    this.listarPuestosTrabajoReservados();
  }

  listarPuestosTrabajoReservados() {
    this.blockedDocument = true;
    let fechaActual: Date = new Date();
    var formatoFechaActual = this.fechaActual.getFullYear() + "-" + (fechaActual.getMonth() + 1) + "-" + fechaActual.getDate();
    forkJoin(
      this.puestosTrabajoService.srvConsultarReservasPuestoTrabajo(),
      this.puestosTrabajoService.srvConsultarReservasPuestoTrabajoPorFecha(formatoFechaActual)
    ).subscribe(
      ([reservasPuestoTrabajoDataHistorico, reservasPuestoTrabajoData]) => {
        console.log(reservasPuestoTrabajoData);
        console.log(reservasPuestoTrabajoDataHistorico);
        this.reservaPuestoTrabajoListHistorico = reservasPuestoTrabajoDataHistorico.reservas;
        this.reservaPuestoTrabajoList = reservasPuestoTrabajoData.reservas;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  btnAgregar() {
    this.router.navigate([this.router.url + "/crear-editar-reserva/0"]);
  }

  btnVolverHome() {
    this.router.navigate(["/home-puestos-trabajo/"]);
  }


  btnEquipos(reservaPuestoTrabajoSelected: IngReservaPuestoTrabajoDTO) {
    this.blockedDocument = true;
    forkJoin(
      this.puestosTrabajoService.srvConsultarBienesReserva(reservaPuestoTrabajoSelected.id)
    ).subscribe(
      ([equiposReservaData]) => {
        console.log(equiposReservaData);
        this.equiposReserva = equiposReservaData.bienesReserva;
        this.dialogoEquipos = true;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  btnCerraDialogoEquipos() {
    this.dialogoEquipos = false;
  }

  btnQR(reservaPuestoTrabajoSelected: IngReservaPuestoTrabajoDTO) {
    this.blockedDocument = true;
    this.isImageLoading = true;
    forkJoin(
      this.puestosTrabajoService.srvObtenerQrReserva(reservaPuestoTrabajoSelected.id)
    ).subscribe(
      ([qrData]) => {
        console.log('Reserva seleccionada id:' + reservaPuestoTrabajoSelected.id);
        console.log(qrData);
        this.crearImagenDesdeBlob(qrData)
        this.isImageLoading = false;
        this.dialogoQrReserva = true;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
        this.isImageLoading = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  btnRegistrarEntrada(reservaPuestoTrabajoSelected: IngReservaPuestoTrabajoDTO){    
    this.router.navigate([
      this.router.url +
      "/registrar-reserva/" +
      reservaPuestoTrabajoSelected.id,
    ]);
  }

  btnCerraDialogoQR() {
    this.dialogoQrReserva = false;
  }

  crearImagenDesdeBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load",
      () => {
        this.imagenQr = reader.result;
      },
      false);
    if (image) {
      if (image.type !== "application/pdf")
        reader.readAsDataURL(image);
    }
  }

  btnEditar(reservaPuestoTrabajoSelected: IngReservaPuestoTrabajoDTO) {
    this.lanzarMensajeInfo("Editar!!!");
    this.router.navigate([
      this.router.url +
      "/crear-editar-reserva/" +
      reservaPuestoTrabajoSelected.id,
    ]);
  }

  btnEliminar(reservaPuestoTrabajoSelected: IngReservaPuestoTrabajoDTO) {
    this.reservaPuestoTrabajo = reservaPuestoTrabajoSelected;
    this.dialogoEliminar = true;
  }

  submitEliminar() {
    this.blockedDocument = true;
    forkJoin(
      this.puestosTrabajoService.srvEliminarReserva(
        this.reservaPuestoTrabajo.id
      )
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            //  Consultar nuevamente la lista
            this.listarPuestosTrabajoReservados();
            //  Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.dialogoEliminar = false;
        this.blockedDocument = false;
      }
    );
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
