import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { VwCmsTiqueteAereoDTO, CmsEstadoTiqueteAereoDTO } from '../../comisiones.dto';
import { ComisionesService } from '../../comisiones.service';
import { forkJoin } from 'rxjs';
import { EnumCmsConstantes, EnumCmsMaTipoModificacionComision } from '../../comisiones-constantes';
import { EnumRespuestaServicio, EnumTrnMaTipoSoporte } from 'src/app/comun/constantes/constantes-comunes';

@Component({
  selector: 'app-comisiones-tiquetes-revision',
  templateUrl: './comisiones-tiquetes-revision.component.html',
  styleUrls: ['./comisiones-tiquetes-revision.component.sass']
})
export class ComisionesTiquetesRevisionComponent implements OnInit {

  itinerarioAereoList: VwCmsTiqueteAereoDTO[];
  visualizarDialogTiquetesAprobar: boolean;
  visualizarDialogTiquetesRechazar: boolean;
  observacionRechazo: string;
  blockedDocument = true;
  estadoTiqueteAereo: CmsEstadoTiqueteAereoDTO;

  constructor(private messageService: MessageService,
    private router: Router,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService) { }

  ngOnInit() {
    this.cargarItinerarios();
  }

  cargarItinerarios() {
    this.blockedDocument = true;
    forkJoin(
      this.comisionesService.srvListarTiquetesPorPersona()
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {

          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.itinerarioAereoList = result.tiquetes;
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          this.lanzarMensajeError('No fue posible realizar la acción. Por favor comuniquese con el area de tecnología');
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  irAceptarTiquete(tiquete: VwCmsTiqueteAereoDTO) {
    this.visualizarDialogTiquetesAprobar = true;
    this.estadoTiqueteAereo = {};
    this.estadoTiqueteAereo.id = tiquete.idEstadoTiqueteAereo;
    this.estadoTiqueteAereo.idTiqueteAereoSolicitado = tiquete.idTiqueteAereoSolicitado;
    this.estadoTiqueteAereo.idEstadoTiqueteAereo = EnumCmsConstantes.CMS_ESTADO_TIQUETE_AEREO_RESERVA_APROBADA;
    this.estadoTiqueteAereo.observaciones = "Reserva aprobada";
  }

  irRechazarTiquete(tiquete: VwCmsTiqueteAereoDTO) {
    this.visualizarDialogTiquetesRechazar = true;
    this.estadoTiqueteAereo = {};
    this.estadoTiqueteAereo.id = tiquete.idEstadoTiqueteAereo;
    this.estadoTiqueteAereo.idTiqueteAereoSolicitado = tiquete.idTiqueteAereoSolicitado;
    this.estadoTiqueteAereo.idEstadoTiqueteAereo = EnumCmsConstantes.CMS_ESTADO_TIQUETE_AEREO_RESERVA_RECHAZADA;
  }

  aceptarTiquete() {
    this.blockedDocument = true;
    this.visualizarDialogTiquetesAprobar = false;
    forkJoin(
      this.comisionesService.srvGuardarEstadoTiqueteComision(this.estadoTiqueteAereo)
    ).subscribe(
      ([result]) => {

        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cargarItinerarios();
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          this.lanzarMensajeError('No fue posible realizar la acción. Por favor comuniquese con el area de tecnología');
        }
      },

      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
        this.visualizarDialogTiquetesAprobar = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
        this.visualizarDialogTiquetesAprobar = false;
      }
    );
  }

  rechazarTiquete() {
    this.blockedDocument = true;
    this.visualizarDialogTiquetesRechazar = false;
    this.estadoTiqueteAereo.observaciones = this.observacionRechazo;
    forkJoin(
      this.comisionesService.srvGuardarEstadoTiqueteComision(this.estadoTiqueteAereo)
    ).subscribe(
      ([result]) => {

        if (result != null && result.respuestaServicio != null) {

          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.cargarItinerarios();
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
          } else {
            this.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        } else {
          this.lanzarMensajeError('No fue posible realizar la acción. Por favor comuniquese con el area de tecnología');
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  bntVolver() {
    const ruta = "/comisiones";
    this.router.navigate([ruta]);
  }



  get EnumCmsConstantes() {
    return EnumCmsConstantes;
  }

  get EnumTrnMaTipoSoporte() {
    return EnumTrnMaTipoSoporte;
  }

  get EnumCmsMaTipoModificacionComision() {
    return EnumCmsMaTipoModificacionComision;
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
