import { Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts/highstock";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import { CrrInformacionProyectoDTO } from "src/app/reportes/carreteros/reportes-carreteros.dto";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { forkJoin } from 'rxjs';
import { formatDate } from "@angular/common";
import colorchart from "src/assets/img/coloreschart";

@Component({
  selector: "app-rpt-crr-proyecto-inversion",
  templateUrl: "./rpt-crr-proyecto-inversion.component.html",
  styleUrls: ["./rpt-crr-proyecto-inversion.component.sass"],
})
export class RptCrrProyectoInversionComponent implements OnInit {
  @Input() informacionGeneralProyecto: CrrInformacionProyectoDTO;
  
  fechaReferencia?: any = null;
  fechas?: any[] = [];
  valoresTotales: any;
  valoresPorUF: any;
  documentoBloqueado: boolean = false;

  constructor(private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent) {}

  ngOnInit() {
    this.documentoBloqueado = true
    console.log("inversion");
    this.cargarFechas();
    this.cargarDatosGraficas();
  }

  private cargarDatosGraficas() {
    
    let tasks = [this.reportesCarreteroService.getCrrValoresPorProyecto(this.informacionGeneralProyecto.id, this.fechaReferencia != null ? this.fechaReferencia.fecha : null),
      this.reportesCarreteroService.getCrrValoresPorUnidadFuncional(this.informacionGeneralProyecto.id, this.fechaReferencia != null ? this.fechaReferencia.fecha : null)];
    forkJoin(tasks).subscribe(respuestas => {
      if (respuestas[0].respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        this.principalComponent.cargarErrorServicio({
          error: {
            message: EnumRespuestaServicio.ERROR_OPERACION,
            logEvento: respuestas[0].respuestaServicio.mensajeSalida
          }
        });
        return;
      }

      if (respuestas[1].respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
        this.principalComponent.cargarErrorServicio({
          error: {
            message: EnumRespuestaServicio.ERROR_OPERACION,
            logEvento: respuestas[1].respuestaServicio.mensajeSalida
          }
        });
        return;
      }

      this.valoresTotales = {
        capex: Math.round(respuestas[0].inversionTotal.capex / 10000) / 100,
        opex: Math.round(respuestas[0].inversionTotal.opex  / 10000) / 100,
        fechaReferencia: formatDate(respuestas[0].inversionTotal.fechaReferencia, "dd/MM/yyyy", "es-ES"),
        nombreProyecto: respuestas[0].inversionTotal.nombre,
        longitud: respuestas[0].inversionTotal.longitudConcesionada / 1000
      };

      this.valoresPorUF = {
        capex: respuestas[1].inversionPorUF.map(item => { return { y: Math.round(item.capex / 10000) / 100, longitud: item.longitudConcesionada / 1000 }; }),
        opex: respuestas[1].inversionPorUF.map(item => Math.round(item.opex / 10000) / 100),
        fechaReferencia: formatDate(respuestas[1].inversionPorUF[0].fechaReferencia, "dd/MM/yyyy", "es-ES"),
        unidadesFuncionales: respuestas[1].inversionPorUF.map(item => item.nombre)
      }
      console.log("this.valoresPorUF");
      console.log(this.valoresPorUF);
    },
    (error) => {
      this.valoresTotales = {};
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
    },
    () => {
      this.documentoBloqueado = false;
      this.inicializarGraficas();
    });
  }

  private inicializarGraficas() {
    // Chart Valores por Proyecto
    let optionsChartValoresPorProyecto = {
      chart: { type: "column" },
      credits: { enabled: false },
      title: { text: "Valores por Proyecto" },
      subtitle: { text: `Valores en millones de pesos colombianos según fecha de referencia ${this.valoresTotales.fechaReferencia}` },
      xAxis: {
        categories: [this.valoresTotales.nombreProyecto],
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: { text: "Millones COP" }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>$ {point.y:.2f} Mill</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                align: 'right',
                color: colorchart.color1,
                x: -10
            },
            pointPadding: 0.1,
            groupPadding: 0
        }
      },
      series: [
        {
          name: "Capex",
          color: colorchart.color1,
          data: [{ y: this.valoresTotales.capex, longitud: this.valoresTotales.longitud }]
        },
        {
          name: "Opex",
          color: colorchart.color3,
          data: [this.valoresTotales.opex]
        },
      ],
    };

    Highcharts.chart("chartValoresPorProyecto", optionsChartValoresPorProyecto);
    
    // chart Capex y Opex Unidad Funcional
    let optionschartCapex = {
      credits: { enabled: false },
      chart: { type: "column" },
      title: { text: "Capex y Opex Unidad Funcional" },
      subtitle: { text: `Valores en millones de pesos colombianos según fecha de referencia ${this.valoresPorUF.fechaReferencia}` },
      xAxis: {
        categories: this.valoresPorUF.unidadesFuncionales,
        crosshair: true
      },
      yAxis: {
        min: 0,
        title: { text: "Millones COP" }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key} - <strong>Longitud:</strong> {point.point.longitud} km</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>$ {point.y:.2f} Mill</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                align: 'right',
                color: colorchart.color1,
                x: -10
            },
            pointPadding: 0.1,
            groupPadding: 0
        }
      },
      series: [
        {
          longitud: this.valoresPorUF.longitud,
          name: "Capex",
          color: colorchart.color1,
          data: this.valoresPorUF.capex
        },
        {
          longitud: this.valoresPorUF.longitud,
          name: "Opex",
          color: colorchart.color3,
          data: this.valoresPorUF.opex
        },
      ],
    };

    Highcharts.chart("chartCapex", optionschartCapex);
  }

  public actualizarGraficas() {
    this.cargarDatosGraficas();
  }

  public cargarFechas() {
    const filtroFechas = [];
    return this.reportesCarreteroService.getFechasReferenciaCapexOpex(this.informacionGeneralProyecto.id).subscribe(
      (respuesta) => {
        if (respuesta.respuestaServicio.codigoSalida != EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.principalComponent.cargarErrorServicio({
            error: {
              message: EnumRespuestaServicio.ERROR_OPERACION,
              logEvento: respuesta.respuestaServicio.mensajeSalida
            }
          });
          return;
        }

        if (respuesta.fechasReferencia == null) {
          return;
        }

        this.fechas = respuesta.fechasReferencia.map(fecha => { return { fecha: fecha, fechaString: formatDate(fecha, "dd/MM/yyyy", "es-ES") }; });

        this.fechaReferencia = this.fechas[0];
      
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }
}
