import { Component, OnInit, ViewChild } from '@angular/core';
import { ProyectoLineaBaseComponent } from '../proyecto-linea-base/proyecto-linea-base.component';
import { ParametricasService } from '../parametricas/parametricas.service';
import { TipoIntervencionCarreteroService } from './tipo-intervencion-carretero.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { IntervencionUnidadFuncional } from '../unidad-funcional-carretero/intervencionUnidadFuncional';
import { TipoIntervencionCarreteroDetalleService } from '../tipo-intervencion-carretero-detalle/tipo-intervencion-carretero-detalle.service';
import { PrincipalComponent } from '../home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-tipo-intervencion-carretero',
  templateUrl: './tipo-intervencion-carretero.component.html',
  styleUrls: ['./tipo-intervencion-carretero.component.sass']
})
export class TipoIntervencionCarreteroComponent implements OnInit {

  intervenciones: any = [];

  displayedColumnsIntervencion: any[];
  dataSourceIntervencion: MatTableDataSource<IntervencionUnidadFuncional>;
  intervencionUnidadFuncionalSeleccionada: IntervencionUnidadFuncional;
  @ViewChild(MatPaginator, { static: true }) paginatorIntervencion: MatPaginator;
  visibleListaTipoIntervencion: boolean;
  visibleNuevaIntervencionPorUnidadFuncional: boolean;
  visibleDialogoExitoTipoIntervencion: boolean;
  visibleConfirmarEliminar: boolean = false;
  visibleDialogoExitoEliminar: boolean = false;
  totalFactor: number = 0.0;

  constructor(private lineaBase: ProyectoLineaBaseComponent, private parametricasService: ParametricasService,
    private tipoIntervencionCarreteroService: TipoIntervencionCarreteroService, private tipoIntervencionCarreteroDetalleService: TipoIntervencionCarreteroDetalleService,
    private route: ActivatedRoute, private ruta: Router, private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.cargarIntervencionesPorUnidadFuncional(this.lineaBase.idUnidadFuncional);
    this.displayedColumnsIntervencion = [
      { field: 'nombre', header: 'Nombre' },
      { field: 'nombreTipoIntervencion', header: 'Tipo de intervención' },
      { field: 'formatoFactorPonderacion', header: 'Factor de ponderación' }];
  }

  cargarIntervencionesPorUnidadFuncional(idUnidadFuncional) {
    this.totalFactor = 0.0;
    return this.tipoIntervencionCarreteroService.getIntervencionesPorUnidadFuncional(idUnidadFuncional).subscribe((data: {}) => {
      this.intervenciones = data;
      if (this.intervenciones != null && this.intervenciones.length != 0) {
        this.lineaBase.habilitaTabContratoCredito = true;
      }
      this.dataSourceIntervencion = new MatTableDataSource(this.intervenciones);
      this.dataSourceIntervencion.paginator = this.paginatorIntervencion;
      this.intervenciones.forEach(element => {
        this.totalFactor = this.totalFactor + element.factorPonderacion;
      });
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  nuevoTipoIntervencionUnidadFuncional() {
    this.lineaBase.intervencion = {};
    this.lineaBase.intervencion.idUnidadFuncional = this.lineaBase.idUnidadFuncional;
    this.lineaBase.visibleTiposIntervencionDetalle = true;
    this.lineaBase.visibleTiposIntervencion = false;
    this.lineaBase.idIntervencion = "new";
    this.lineaBase.tipoIntervencionSeleccionado = null;
  }

  cancelarAgregarTipoIntervencion() {
    this.lineaBase.visibleTiposIntervencion = false;
    this.lineaBase.visibleListaUnidadFuncional = true;
    this.lineaBase.intervencion = {};
  }

  editarTipoIntervencionUnidadFuncional(intervencionUnidadFuncional: IntervencionUnidadFuncional) {
    this.intervencionUnidadFuncionalSeleccionada = intervencionUnidadFuncional;
    return this.tipoIntervencionCarreteroDetalleService.getIntervencionPorId(this.intervencionUnidadFuncionalSeleccionada.id).subscribe((data: {}) => {
      this.lineaBase.intervencion = data;
      this.lineaBase.intervencion.factorPonderacion = this.lineaBase.intervencion.factorPonderacion * 100;
      this.lineaBase.tipoIntervencionSeleccionado = this.lineaBase.intervencion.idTipoIntervencionNavigation;
      this.lineaBase.visibleTiposIntervencion = false;
      this.lineaBase.visibleTiposIntervencionDetalle = true;
      this.lineaBase.intervencion.idUnidadFuncional = this.lineaBase.idUnidadFuncional;
      this.lineaBase.idIntervencion = this.intervencionUnidadFuncionalSeleccionada.id;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  irEliminarIntervencion(intervencionUnidadFuncional: IntervencionUnidadFuncional) {
    this.intervencionUnidadFuncionalSeleccionada = intervencionUnidadFuncional;
    this.visibleConfirmarEliminar = true;
    this.lineaBase.idIntervencion = this.intervencionUnidadFuncionalSeleccionada.id;
  }

  cancelarEliminarIntervencion() {
    this.visibleConfirmarEliminar = false;
  }

  aceptarEliminarIntervencion() {
    /* return this.tipoIntervencionCarreteroDetalleService.eliminarIntervencionPorUnidadFuncional(this.lineaBase.idIntervencion).subscribe((data: {}) => {
      this.visibleConfirmarEliminar = false;
      this.cargarIntervencionesPorUnidadFuncional(this.lineaBase.idUnidadFuncional);
      this.visibleDialogoExitoEliminar = true;
    }); */
    return this.tipoIntervencionCarreteroDetalleService.inactivarIntervencionUnidadFuncional(this.lineaBase.idIntervencion).subscribe((data: {}) => {
      this.visibleConfirmarEliminar = false;
      this.cargarIntervencionesPorUnidadFuncional(this.lineaBase.idUnidadFuncional);
      this.visibleDialogoExitoEliminar = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoEliminarIntervencion() {
    this.visibleDialogoExitoEliminar = false;
  }

}
