import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TemasGestionDetalleService } from './temas-gestion-detalle.service';
import { DetalleGestion } from '../../../dtos/temas-gestion/temas-gestion-detalle';
import { MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';
import { Location } from '@angular/common';
import { forkJoin } from 'rxjs';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-temas-gestion-detalle',
  templateUrl: './temas-gestion-detalle.component.html',
  styleUrls: ['./temas-gestion-detalle.component.sass']
})

export class TemasGestionDetalleComponent implements OnInit {

  proyecto: DetalleGestion = {};
  objectSubTipo: any = [];
  objectTipo: any = [];
  objectPriorizacion: any = [];
  objectEtapaActual: any = [];
  objectEtapaActualTemp: any = [];
  datosSubTramos: any = [];
  unidades: any = [];
  visibleDialogoExito: boolean;

  idAmbientalDetalle: any;
  nombreProyecto;
  dataSource: MatTableDataSource<any>;
  enableEdit = false;
  dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(
    private detalleService: TemasGestionDetalleService,
    private route: ActivatedRoute,
    private ruta: Router,
    public dialog: MatDialog,
    public location: Location,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'));
    this.cargarUnidades();
    this.cargarSubTema();
    this.cargarTema();
    this.cargarPriorizacion();
    this.route.params.subscribe(params => {
      this.idAmbientalDetalle = params.id;
      this.idAmbientalDetalle === 'new' ? this.cargarNuevo(this.idAmbientalDetalle) : this.cargarProyectoAmbiental(this.idAmbientalDetalle);
    });
    ;
  }

  cargarSubTema() {
    return this.detalleService.getSubTema().subscribe((data: {}) => {
      this.objectSubTipo = data;
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarTema() {
    return this.detalleService.getTema().subscribe((data: {}) => {
      this.objectTipo = data;
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarPriorizacion() {
    return this.detalleService.getPriorizacion().subscribe((data: {}) => {
      this.objectPriorizacion = data;
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }


  cargarNuevo(id) {
    this.enableEdit = false;
    this.proyecto = {};
    this.proyecto.idUnidadFuncional = '';
    this.proyecto.idSubtramo = '';
    this.proyecto.cerrado = false;
    this.proyecto.idProyecto = JSON.parse(localStorage.getItem('idP'));
  }

  cargarProyectoAmbiental(id: number) {
    this.enableEdit = true;
    forkJoin(
      this.detalleService.getProyectoAmbiental(id),
      this.detalleService.getUnidades(JSON.parse(localStorage.getItem('idP'))),
      this.detalleService.getSubTema(),
      this.detalleService.getTema(),
      this.detalleService.getPriorizacion(),

    ).subscribe(([datosProyecto, unidadesList, objectSubTipoList, objectTipoList, objectPriorizacionList]) => {
      this.unidades = unidadesList;
      this.objectSubTipo = objectSubTipoList;
      this.objectTipo = objectTipoList;
      this.objectPriorizacion = objectPriorizacionList;
      this.proyecto = datosProyecto;
      if (this.proyecto.idUnidadFuncional) {
        this.getSubTramo(this.proyecto.idUnidadFuncional);
      }
    }, error => {
      this.dialogSpin.close();
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarUnidades() {
    return this.detalleService.getUnidades(JSON.parse(localStorage.getItem('idP'))).subscribe((data: {}) => {
      this.unidades = data;
    }, (error)=>{
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  // Informativo - Dialogo
  aceptarDialogoExito() {
    this.visibleDialogoExito = false;
    this.location.back();
    //this.router.navigate(['/personaDatosBasicos-base/', this.resultadoGuardarDatosBasicos.resultado.id, this.resultadoGuardarDatosBasicos.resultado.idTipoDocumento]);
  }

  getSubTramo(payload) {
    if (payload != null && payload != undefined && payload != '' ) {
      this.detalleService.getTramo(payload).subscribe((data: {}) => {
        this.datosSubTramos = data;

        if (this.idAmbientalDetalle === 'new') {
          this.proyecto.idSubtramo = '';
        }
        if (this.datosSubTramos.length === 0) {
          this.proyecto.idSubtramo = '';
        }
      }, (error)=>{
        this.principalComponent.cargarErrorServicio(error);
      });
    } else {
      this.proyecto.idSubtramo = '';
      this.datosSubTramos = [];
    }
  }

  guardarDetalle(payload) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    if (this.idAmbientalDetalle === 'new') {
      return this.detalleService.guardarNuevoGestion(payload).subscribe((data: {}) => {
        this.visibleDialogoExito = true;
        this.dialogSpin.close();
      }, (error)=>{
        this.dialogSpin.close();
        this.principalComponent.cargarErrorServicio(error);
      });
    } else {
      return this.detalleService.guardarDetalleGestion(payload).subscribe((data: {}) => {
        this.visibleDialogoExito = true;
        this.dialogSpin.close();
      }, (error)=>{
        this.dialogSpin.close();
        this.principalComponent.cargarErrorServicio(error);
      });
    }
  }

  cancelar() {
    this.location.back();
  }


}


