import { Component, OnInit } from '@angular/core';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { forkJoin, Subscription } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { CompensacionesAmbientalesService } from '../../compensaciones-ambientales.service';
import { MessageService } from 'primeng/api';
import {
  AmbObligacionEncabezadoDTO,
  AmbSubCuentaDTO,
  AmbUbicacionAfectadaDTO,
} from '../../compensaciones-ambientales.dto';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-gestionar-localizacion',
  templateUrl: './gestionar-localizacion.component.html',
  styleUrls: ['./gestionar-localizacion.component.sass'],
})
export class GestionarLocalizacionComponent implements OnInit {
  subscription: Subscription;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  idObligacion: number;
  obligacionAmbiental: AmbObligacionEncabezadoDTO;

  subCuenta: AmbSubCuentaDTO;

  ubicacionesAfectadasList: AmbUbicacionAfectadaDTO[];
  ubicacionAfectada: AmbUbicacionAfectadaDTO;

  dialogoUbicacionAfectada: boolean;
  dialogoUbicacionAfectadaEliminar: boolean;

  formUbicacionAfectada;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private compensacionesAmbientalesService: CompensacionesAmbientalesService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) {
    //  Crear formulario Ubicacion Afectada
    this.formUbicacionAfectada = formBuilder.group({
      idMunicipio: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.ubicacionAfectada = {};

    // Obtener parametros
    const idParam = 'id';
    const idParamOblig = 'idOblig';
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.idObligacion = params[idParamOblig];
    });

    this.listarUbicacionesAfectadas();
  }

  listarUbicacionesAfectadas() {
    // Inicializar listas
    forkJoin(
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.compensacionesAmbientalesService.srvObtenerValorSubcuentaPorProyecto(
        this.idProyecto
      ),
      this.compensacionesAmbientalesService.srvObtenerObligacionAmbientalPorId(
        this.idObligacion
      ),
      this.compensacionesAmbientalesService.srvListarUbicacionesAfectadasPorObligacion(
        this.idObligacion
      )
    ).subscribe(
      ([
        proyectoData,
        subCuentaData,
        obligacionAmbientalData,
        ubicacionesAfectadasData,
      ]) => {
        this.proyectoSeleccionado = proyectoData;
        this.subCuenta = subCuentaData.valorSubCuenta;
        this.obligacionAmbiental = obligacionAmbientalData.obligacionAmbiental;
        this.ubicacionesAfectadasList =
          ubicacionesAfectadasData.ubicacionesAfectadas;
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });

  }

  btnAgregarUbicacion() {
    this.dialogoUbicacionAfectada = true;
    this.ubicacionAfectada = {};
    this.ubicacionAfectada.idObligacion = this.obligacionAmbiental.id;
  }

  btnEditarUbicacion(ubicacionSelected: AmbUbicacionAfectadaDTO) {
    this.dialogoUbicacionAfectada = true;
    this.ubicacionAfectada = ubicacionSelected;
  }

  btnEliminarUbicacion(ubicacionSelected: AmbUbicacionAfectadaDTO) {
    this.dialogoUbicacionAfectadaEliminar = true;
    this.ubicacionAfectada = ubicacionSelected;
  }

  outputSelectorMunicipio(departamentoObj) {
    const idMunicipioControl = this.formUbicacionAfectada.get('idMunicipio');
    if (departamentoObj.municipio != null) {
      idMunicipioControl.setValue(departamentoObj.municipio.id);
    }
  }

  /**
   * Guarda los cambios de la Ubicacion Afectada
   */
  submitGuardarUbicacionAfectada() {
    if (this.formUbicacionAfectada.valid) {
      this.ubicacionAfectada.idMunicipio = this.formUbicacionAfectada.value.idMunicipio;
      //  Completar el DTO a guardar
      if (this.ubicacionAfectada != null) {
        this.ubicacionAfectada.nombreMunicipio = '';
      }
      //  Guardar el DTO
      forkJoin(
        this.compensacionesAmbientalesService.srvGuardarUbicacionAfectadaPorObligacion(
          this.ubicacionAfectada
        )
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            //  Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              //  Consultar nuevamente la lista
              this.listarUbicacionesAfectadas();
              //  Lanzar mensaje de exito
              this.messageService.add({
                severity: 'info',
                summary: 'Información',
                detail: result.respuestaServicio.mensajeSalida,
                life: 10000,
              });
              //  Limpiar Formulario
              this.formUbicacionAfectada.reset();
              this.formUbicacionAfectada.markAsPristine();
              this.formUbicacionAfectada.markAsUntouched();

              //  Cerrar el dialogo
              this.dialogoUbicacionAfectada = false;
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
          }
        }, error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => { }
      );
    } else {
      this.lanzarMensajeWarning('Debe diligenciar los campos requeridos');
    }
  }

  /**
   * Eliminar la Ubicacion Afectada
   */
  submitEliminarUbicacionAfectada() {
    forkJoin(
      this.compensacionesAmbientalesService.srvEliminarUbicacionAfectada(
        this.ubicacionAfectada
      )
    ).subscribe(
      ([result]) => {
        if (result != null && result.respuestaServicio != null) {
          //  Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            //  Consultar nuevamente la lista
            this.listarUbicacionesAfectadas();
            //  Lanzar mensaje de exito
            this.lanzarMensajeInfo(result.respuestaServicio.mensajeSalida);
            //  Cerrar el dialogo
            this.dialogoUbicacionAfectadaEliminar = false;
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
        }
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        // Desbloquear pantalla
        // this.blockedDocument = false;
      }
    );
  }

  bntVolver() {
    const ruta =
      'carreteros/infGeneral/proyectoHome/' +
      this.proyectoSeleccionado.id +
      '/compensaciones-ambientales/' +
      this.proyectoSeleccionado.id +
      '/obligaciones-compensacion/' +
      this.proyectoSeleccionado.id;
    this.router.navigate([ruta]);
  }

  // // // // // // // // // // // // // // // /Mensajes// // // // // // // // // // // // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'success',
      summary: 'Información',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: 'warn',
      summary: 'Advertencia',
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
