import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'seguimiento-reporte',
	templateUrl: './seguimiento-reporte.component.html'
})

export class SeguimientoReporteComponent implements OnInit {

	constructor(private route: Router) { }
  
	ngOnInit() {
	  var element = document.getElementById("aniscopio_body");
	element.classList.add("public");
	}
  
}
