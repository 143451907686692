import { Injectable } from "@angular/core";
import { ConfigService } from "../config.service";
import { Observable } from "rxjs";
import { map, retry } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject } from "rxjs";
import {
  CmsArchivoAnexoComisionRptDTO,
  CmsConfiguracionFuncionarioDTO,
  VwCmsComisionRpt,
  CmsGestionPresupuestalRpt,
  CmsRpDTO,
  CmsAnexoComisionDTO,
  CmsReasignarComisionDTO,
  CmsComisionLegalizacionDTO,
  CmsReporteComisionesDetalleRpt,
  CmsComisionModificacionDTO,
  CmsConfiguracionPersonasFlujoAprobacionDependenciaDTO, CmsTiqueteAereoDTO, CmsEstadoTiqueteAereoDTO, CmsSolicitudCancelacionTiqueteDTO, CmsComisionDTO,
  CmsEnviarSolicitudComisionRpt
} from "./comisiones.dto";
import { TrnRespuestaServicioRpt } from "../parametricas/rpt-respuesta-operacion";
import { DatePipe } from "@angular/common";
import { CmsComisionModificacionRpt } from './comisiones.dto';

@Injectable({
  providedIn: "root",
})
export class ComisionesService {
  private mensajeCompartidoSource = new BehaviorSubject("default message");
  mensajeCompartido = this.mensajeCompartidoSource.asObservable();

  public END_POINT = "";

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(
    private config: ConfigService,
    private http: HttpClient,
    public datepipe: DatePipe) {
    this.END_POINT = environment.baseUrl;
  }

  intercambiarMensaje(mensaje: string) {
    this.mensajeCompartidoSource.next(mensaje);
  }

  public srvListarComisionesPersona(): Observable<VwCmsComisionRpt> {
    const url = this.END_POINT + "/CmsComision/GetComisionesPersona";
    return this.http.get<VwCmsComisionRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }


  public srvListarComisionesPorEstado(idEstado: number): Observable<VwCmsComisionRpt> {
    const url = this.END_POINT + "/CmsComision/GetComisionesPersonaPorEstado/" + idEstado;
    return this.http.get<VwCmsComisionRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }



  public srvConsultarComisionInformacionGeneral(idComision: number, idComisionVersion: number): Observable<VwCmsComisionRpt> {
    const url = this.END_POINT + "/CmsComision/ConsultarComisionInformacionGeneral/" + idComision + '/' + idComisionVersion;
    return this.http.get<VwCmsComisionRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public srvConsultarComisionPorId(idComision: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsComision/GetComisionDetalle/" + idComision)
      .pipe(retry(1));
  }

  public srvObtenerComisionPorIdComisionIdVersion(idComision: number, idComisionVersion: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsReportesComisiones/GetComisionesDetalleVersion/" + idComision + "/" + idComisionVersion)
      .pipe(retry(1));
  }

  public srvListarTiquetesSolicitadosPorComisionVersion(idComisionVersion: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsTiqueteComision/listarTiquetesSolicitadosPorComisionVersion/" + idComisionVersion)
      .pipe(retry(1));
  }

  public srvListarTiquetesCanceladosPorComision(idComisionVersion: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsTiqueteComision/listarTiquetesCanceladosPorComision/" + idComisionVersion)
      .pipe(retry(1));
  }

  public srvListarProyectos(idTipo: number): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsComision/GetProyectos/" + idTipo)
      .pipe(retry(1));
  }

  public srvListarProyectosContratistaConfiguracion(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsComision/GetProyectosContratistaConfiguracion/")
      .pipe(retry(1));
  }

  public srvGuardarProyectoContrato(proyectoContrato: any): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsComision/guardarProyectoContrato";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, proyectoContrato)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  public srvEliminarProyectoContrato(id): Observable<TrnRespuestaServicioRpt> {
    return this.http.delete(this.END_POINT + '/CmsComision/EliminarProyectoContrato/' + id)
      .pipe(
        retry(1));
  }

  public srvListarDependenciasAni(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/TrnMaDependenciaAni/ListarDependenciasAni")
      .pipe(retry(1));
  }

  public srvGuardarAsignacionDependencia(cmsDependenciaFuncionarioVoBoComisionDTO: CmsConfiguracionPersonasFlujoAprobacionDependenciaDTO): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsConfiguracionFlujoAprobacion/AsignarFuncionariosDependencia";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, cmsDependenciaFuncionarioVoBoComisionDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  public srvGuardarComision(data): Observable<any> {
    return this.http
      .post(this.END_POINT + "/CmsComision/GuardarComision", data)
      .pipe(retry(1));
  }

  public srvModificarComision(cmsModificacion: CmsComisionModificacionDTO): Observable<CmsComisionModificacionRpt> {
    return this.http
      .post(this.END_POINT + "/CmsComision/ModificarComision/", cmsModificacion)
      .pipe(retry(0));
  }

  public srvDesistirModificarComision(cmsModificacion: CmsComisionModificacionDTO): Observable<TrnRespuestaServicioRpt> {
    return this.http
      .post(this.END_POINT + "/CmsComision/DesistirModificacionComision/", cmsModificacion)
      .pipe(retry(0));
  }

  public srvListarCiudadesAeropuertos(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsComision/GetCiudadesAeropuertos/")
      .pipe(retry(1));
  }

  public srvListarFranjasHorarias(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsComision/GetFranjasHorarias/")
      .pipe(retry(1));
  }

  public srvObtenerDatosAdicionalesContrato(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsConfiguracionComision/obtenerDatosAdicionalesContrato")
      .pipe(retry(1));
  }

  public srvGuardarDatosAdicionalesContrato(data): Observable<any> {
    return this.http
      .post(this.END_POINT + "/CmsConfiguracionComision/guardarDatosAdicionalesContrato", data)
      .pipe(retry(1));
  }

  public srvListarEstadosComision(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsComision/GetEstadosComisiones")
      .pipe(retry(1));
  }

  public srvReasignarComision(data: CmsReasignarComisionDTO): Observable<TrnRespuestaServicioRpt> {
    return this.http
      .post(this.END_POINT + "/CmsComisionAsignacion/ReAsignarComision", data)
      .pipe(retry(0));
  }

  /**
   * Consulta la configuracion del funcionario
   */
  public srvConsultarFuncionarioComision(): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsConfiguracionComision/ConsultarFuncionarioComision")
      .pipe(retry(1));
  }

  public srvConsultarFuncionarioComisionPorIdPersona(idPersona): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsConfiguracionComision/ConsultarFuncionarioComisionPorIdPersona/" + idPersona)
      .pipe(retry(1));
  }

  /**
   * Guarda la dependencia seleccionada por le funcionario
   * @param cmsConfiguracionFuncionarioDTO 
   * @returns 
   */
  public srvGuardarDependenciaEspecifica(cmsConfiguracionFuncionarioDTO: CmsConfiguracionFuncionarioDTO): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsConfiguracionComision/guardarDependenciaEspecifica";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, cmsConfiguracionFuncionarioDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  public srvConsultarPasoEstimadoAsignacionComision(idComision: number, idComisionVersion: number): Observable<TrnRespuestaServicioRpt> {
    return this.http.get(this.END_POINT + '/CmsComision/ConsultarPasoEstimadoAsignacionComision/' + idComision + '/' + idComisionVersion)
      .pipe(
        retry(1));
  }

  public srvEnviarSolicitudComision(idSolicitud): Observable<CmsEnviarSolicitudComisionRpt> {
    return this.http.put(this.END_POINT + '/CmsComision/EnviarSolicitudComision/' + idSolicitud, idSolicitud)
      .pipe(
        retry(1));
  }

  public srvListarComisionesAsignadas(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsComisionAsignacion/GetComisionesAsignadas")
      .pipe(retry(1));
  }

  public srvListarComisionesCanceladas(): Observable<any> {
    return this.http
      .get(this.END_POINT + "/CmsComisionAsignacion/GetComisionesCanceladas")
      .pipe(retry(1));
  }

  public srvGuardarFechaInicioRevision(idAsignacion): Observable<TrnRespuestaServicioRpt> {
    return this.http.put(this.END_POINT + '/CmsComisionAsignacion/GuardarFechaInicioRevision/' + idAsignacion, idAsignacion)
      .pipe(
        retry(1));
  }

  public srvDesistirComision(cmsModificacion: CmsComisionModificacionDTO): Observable<TrnRespuestaServicioRpt> {
    return this.http.post(this.END_POINT + '/CmsComision/DesistirComision/', cmsModificacion)
      .pipe(
        retry(1));
  }

  public srvCancelarComision(cmsModificacion: CmsComisionModificacionDTO): Observable<TrnRespuestaServicioRpt> {
    return this.http.post(this.END_POINT + '/CmsComision/CancelarComision/', cmsModificacion)
      .pipe(
        retry(1));
  }

  /**
   * lista las comisiones que tienen tiquetes solicitados
   */
  public srvListarComisionesTiquetesSolicitados(): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsTiqueteComision/listarComisionesTiquetesSolicitados")
      .pipe(retry(1));
  }

  /**
   * lista los tiquetes solicitados por comision
   */
  public srvListarTiquetesSolicitadosPorIdComision(idComision, idComisionVersion): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsTiqueteComision/listarTiquetesSolicitadosPorIdComision/" + idComision + "/" + idComisionVersion)
      .pipe(retry(1));
  }

  /**
   * Guarda reserva de tiquete seleccionado
   * @param CmsTiqueteAereoDTO 
   * @returns 
   */
  public srvReservarTiqueteComision(cmsTiqueteAereoDTO: CmsTiqueteAereoDTO): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsTiqueteComision/reservarTiqueteComision";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, cmsTiqueteAereoDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  /**
   * Guarda compra de tiquete seleccionado
   * @param CmsTiqueteAereoDTO 
   * @returns 
   */
  public srvComprarTiqueteComision(cmsTiqueteAereoDTO: CmsTiqueteAereoDTO): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsTiqueteComision/comprarTiqueteComision";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, cmsTiqueteAereoDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  /**
   * Guarda solicitud cancelacion de tiquete seleccionado
   * @param CmsTiqueteAereoDTO 
   * @returns 
   */
  public srvSolicitudCancelacionTiqueteComision(cmsTiqueteAereoDTO: CmsSolicitudCancelacionTiqueteDTO): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsTiqueteComision/solicitudCancelacionTiqueteComision";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, cmsTiqueteAereoDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  /**
   * Guarda solicitud cancelacion de tiquete seleccionado
   * @param CmsTiqueteAereoDTO 
   * @returns 
   */
  public srvConfirmaSolicitudCancelacionTiqueteComision(cmsTiqueteAereoDTO: CmsSolicitudCancelacionTiqueteDTO): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsTiqueteComision/confirmaSolicitudCancelacionTiqueteComision";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, cmsTiqueteAereoDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  /**
   * lista los tiquetes por persona
   */
  public srvListarTiquetesPorPersona(): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsTiqueteComision/listarTiquetesPorPersona/")
      .pipe(retry(1));
  }

  /**
   * lista observaciones por tiquete
   */
  public srvListarObservacionesPorIdTiquete(idTiqueteAereoSolicitado: number): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsTiqueteComision/listarObservacionesPorIdTiquete/" + idTiqueteAereoSolicitado)
      .pipe(retry(1));
  }

  /**
   * Guardar estado tiquete seleccionado
   * @param CmsTiqueteAereoDTO 
   * @returns 
   */
  public srvGuardarEstadoTiqueteComision(cmsEstadoTiqueteAereoDTO: CmsEstadoTiqueteAereoDTO): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsTiqueteComision/guardarEstadoTiqueteComision";
    return this.http
      .post<TrnRespuestaServicioRpt>(url, cmsEstadoTiqueteAereoDTO)
      .pipe(
        map((data) => {
          return data;
        }),
        retry(1)
      );
  }

  /**
    * Consulta el listado de comsiiones de una persona pendientes por legalizar o ya legalizadas
    */
  public srvConsultarComisionesPersonaLegalizacion(): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsLegalizacionComision/GetComisionesPersonaLegalizacion")
      .pipe(retry(1));
  }

  /**
    * Consulta datos de Legalizacion, como el informe 
    */
  public srvConsultarLegalizacionPorComision(idComision): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsLegalizacionComision/GetLegalizacionPorComision/" + idComision)
      .pipe(retry(1));
  }

  public srvConsultarLegalizacionAsignada(idComision): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsLegalizacionComision/GetLegalizacionAsignada/" + idComision)
      .pipe(retry(1));
  }

  public srvConsultarLiquidacionLegalizacion(idComision): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsLegalizacionComision/GetLiquidacionLegalizacion/" + idComision)
      .pipe(retry(1));
  }

  public srvGuardarSoporteLegalizacion(data): Observable<any> {
    return this.http
      .post(this.END_POINT + "/CmsLegalizacionComision/GuardarSoporteLegalizacion", data)
      .pipe(retry(1));
  }

  public srvEnviarLegalizacionComision(idComision: number): Observable<TrnRespuestaServicioRpt> {
    return this.http.put(this.END_POINT + '/CmsLegalizacionComision/EnviarLegalizacionComision/' + idComision, idComision)
      .pipe(
        retry(1));
  }

  public srvConsultarLegalizacionesAsignadas(): Observable<any> {
    return this.http.get(this.END_POINT + "/CmsLegalizacionComision/GetLegalizacionesAsignadas")
      .pipe(retry(1));
  }

  /**
   * Servicios de Gestión Presupuestal
   * 
   */
  public srvConsultarComisionesAprobadas(): Observable<VwCmsComisionRpt> {
    const url = this.END_POINT + "/CmsGestionPresupuestal/ConsultarComisionesAprobadas";
    return this.http.get<VwCmsComisionRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public srvConsultarComisionesParaObligar(): Observable<VwCmsComisionRpt> {
    const url = this.END_POINT + "/CmsGestionPresupuestal/ConsultarComisionesParaObligar";
    return this.http.get<VwCmsComisionRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }
  public srvConsultarComisionesParaPagar(): Observable<VwCmsComisionRpt> {
    const url = this.END_POINT + "/CmsGestionPresupuestal/ConsultarComisionesParaPagar";
    return this.http.get<VwCmsComisionRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public srvConsultarListadoCdp(): Observable<CmsGestionPresupuestalRpt> {
    const url = this.END_POINT + "/CmsGestionPresupuestal/ConsultarListadoCdp";
    return this.http.get<CmsGestionPresupuestalRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }
  public srvConsultarListadoCdpMovimientos(idComision: number, idComisionVersion: number): Observable<CmsGestionPresupuestalRpt> {
    const url = this.END_POINT + "/CmsGestionPresupuestal/ConsultarListadoCdpMovimientos/" + idComision + '/' + idComisionVersion;
    return this.http.get<CmsGestionPresupuestalRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public srvConsultarListadoRpComision(idComisionVersion: number): Observable<CmsGestionPresupuestalRpt> {
    const url = this.END_POINT + "/CmsGestionPresupuestal/ConsultarListadoRpComision/" + idComisionVersion;
    return this.http.get<CmsGestionPresupuestalRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public srvGuardarRPComision(idComision: number, idComisionVersion: number, rp: CmsRpDTO[]): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsGestionPresupuestal/GuardarRPComision/" + idComision + '/' + idComisionVersion;
    return this.http.post<TrnRespuestaServicioRpt>(url, rp)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public srvEliminarRPComision(idRp: number, idComision: number, idCdp: number): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsGestionPresupuestal/EliminarRPComision/" + idRp + '/' + idComision + '/' + idCdp;
    return this.http.delete<TrnRespuestaServicioRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  /**
   * Metodo pra consultar el listado de Adjuntos y Anexos asociados a una comision usando varios filtros o ninguno
   * @param idComisionVersion Obligatorio, IdVersion de la comisión
   * @param subtipoAnexo Opcional, Filtro de Anexos de este subtipo, pueden ser varios separados por coma
   * @returns Listado de anexos y adjuntos
   */
  public srvConsultarAnexoAdjuntosConsolidado(idComisionVersion: number, subtipoAnexo: string, mostrarAnulado?: boolean): Observable<CmsArchivoAnexoComisionRptDTO> {
    let url = this.END_POINT + "/CmsAnexosComision/ConsultarArchivosAnexosConsolidado?";
    if (idComisionVersion != null) {
      url = url + '&idComisionVersion=' + idComisionVersion;
    }
    if (subtipoAnexo != null) {
      url = url + '&subtipoAnexo=' + subtipoAnexo;
    }
    if (mostrarAnulado != null) {
      url = url + '&mostrarAnulado=' + mostrarAnulado;
    }

    return this.http.get<CmsArchivoAnexoComisionRptDTO>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvConsultarAnexoPorTipoAnexo(idComisionVersion: number, idTipoAnexo: number, mostrarAnulado?: boolean): Observable<CmsArchivoAnexoComisionRptDTO> {
    let url = this.END_POINT + "/CmsAnexosComision/ConsultarAnexoPorTipoAnexo?";
    if (idComisionVersion != null) {
      url = url + '&idComisionVersion=' + idComisionVersion;
    }
    if (idTipoAnexo != null) {
      url = url + '&idTipoAnexo=' + idTipoAnexo;
    }
    if (mostrarAnulado != null) {
      url = url + '&mostrarAnulado=' + mostrarAnulado;
    }
    return this.http.get<CmsArchivoAnexoComisionRptDTO>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public srvGuardarAnexoArchivoComision(anexoComision: CmsAnexoComisionDTO): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsAnexosComision/GuardarAnexoArchivoComision";
    return this.http.post<TrnRespuestaServicioRpt>(url, anexoComision)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  public srvEliminarAnexoArchivoComision(idArchivoAdjunto: number): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsAnexosComision/EliminarAnexoArchivoComision/" + idArchivoAdjunto;
    return this.http.delete<TrnRespuestaServicioRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public srvGuardarLiquidacionLegalizacion(cmsComisionLegalizacion: CmsComisionLegalizacionDTO): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsLegalizacionComision/GuardarLiquidacionLegalizacion";
    return this.http.post<TrnRespuestaServicioRpt>(url, cmsComisionLegalizacion)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  srvConsultarReporteComisionesDetalle(
    idComision?: number, idPaso?: number, nombrePersona?: string,
    fechaInicioSalida?: Date, fechaFinSalida?: Date,
    fechaInicioRegreso?: Date, fechaFinRegreso?: Date,
    filaDesde?: number, cantidadRegistros?: number, buscar?: string, campoOrden?: string, ascDesc?: number): Observable<CmsReporteComisionesDetalleRpt> {
    let urlEndpoint = '/CmsReportesComisiones/ConsultarReporteDetalladoComision?';
    if (idComision != null) {
      urlEndpoint = urlEndpoint + '&idComision=' + idComision;
    }
    if (idPaso != null) {
      urlEndpoint = urlEndpoint + '&idPaso=' + idPaso;
    }
    if (nombrePersona != null) {
      urlEndpoint = urlEndpoint + '&nombrePersona=' + nombrePersona;
    }
    if (fechaInicioSalida != null) {
      urlEndpoint = urlEndpoint + '&fechaInicioSalida=' + this.datepipe.transform(fechaInicioSalida, 'yyyy/MM/dd');
    }
    if (fechaFinSalida != null) {
      urlEndpoint = urlEndpoint + '&fechaFinSalida=' + this.datepipe.transform(fechaFinSalida, 'yyyy/MM/dd');
    }
    if (fechaInicioRegreso != null) {
      urlEndpoint = urlEndpoint + '&fechaInicioRegreso=' + this.datepipe.transform(fechaInicioRegreso, 'yyyy/MM/dd');
    }
    if (fechaFinRegreso != null) {
      urlEndpoint = urlEndpoint + '&fechaFinRegreso=' + this.datepipe.transform(fechaFinRegreso, 'yyyy/MM/dd');
    }
    if (buscar != null) {
      urlEndpoint = urlEndpoint + '&buscar=' + buscar;
    }
    if (filaDesde != null) {
      urlEndpoint = urlEndpoint + '&filaDesde=' + filaDesde;
    }
    if (cantidadRegistros != null) {
      urlEndpoint = urlEndpoint + '&cantidadRegistros=' + cantidadRegistros;
    }
    if (campoOrden != null) {
      urlEndpoint = urlEndpoint + '&campoOrden=' + campoOrden;
    }
    if (ascDesc != null) {
      if (ascDesc == 1) {
        urlEndpoint = urlEndpoint + '&ascDesc=asc';
      } else {
        urlEndpoint = urlEndpoint + '&ascDesc=desc';
      }
    }


    return this.http.get<CmsReporteComisionesDetalleRpt>(this.END_POINT + urlEndpoint)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }



  srvConsultarReporteComisionesDetalleVersion(
    idComision?: number, idPaso?: number, nombrePersona?: string,
    fechaInicioSalida?: Date, fechaFinSalida?: Date,
    fechaInicioRegreso?: Date, fechaFinRegreso?: Date,
    filaDesde?: number, cantidadRegistros?: number, buscar?: string, campoOrden?: string, ascDesc?: number): Observable<any> {
    let urlEndpoint = '/CmsReportesComisiones/ConsultarReporteDetalladoComisionVersiones?';
    if (idComision != null) {
      urlEndpoint = urlEndpoint + '&idComision=' + idComision;
    }
    if (idPaso != null) {
      urlEndpoint = urlEndpoint + '&idPaso=' + idPaso;
    }
    if (nombrePersona != null) {
      urlEndpoint = urlEndpoint + '&nombrePersona=' + nombrePersona;
    }
    if (fechaInicioSalida != null) {
      urlEndpoint = urlEndpoint + '&fechaInicioSalida=' + this.datepipe.transform(fechaInicioSalida, 'yyyy/MM/dd');
    }
    if (fechaFinSalida != null) {
      urlEndpoint = urlEndpoint + '&fechaFinSalida=' + this.datepipe.transform(fechaFinSalida, 'yyyy/MM/dd');
    }
    if (fechaInicioRegreso != null) {
      urlEndpoint = urlEndpoint + '&fechaInicioRegreso=' + this.datepipe.transform(fechaInicioRegreso, 'yyyy/MM/dd');
    }
    if (fechaFinRegreso != null) {
      urlEndpoint = urlEndpoint + '&fechaFinRegreso=' + this.datepipe.transform(fechaFinRegreso, 'yyyy/MM/dd');
    }
    if (buscar != null) {
      urlEndpoint = urlEndpoint + '&buscar=' + buscar;
    }
    if (filaDesde != null) {
      urlEndpoint = urlEndpoint + '&filaDesde=' + filaDesde;
    }
    if (cantidadRegistros != null) {
      urlEndpoint = urlEndpoint + '&cantidadRegistros=' + cantidadRegistros;
    }
    if (campoOrden != null) {
      urlEndpoint = urlEndpoint + '&campoOrden=' + campoOrden;
    }
    if (ascDesc != null) {
      if (ascDesc == 1) {
        urlEndpoint = urlEndpoint + '&ascDesc=asc';
      } else {
        urlEndpoint = urlEndpoint + '&ascDesc=desc';
      }
    }

    return this.http.get<any>(this.END_POINT + urlEndpoint)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  srvValidarAccesoComision(): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsComision/ValidarAccesoComision/";
    return this.http.get<TrnRespuestaServicioRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  srvValidarDependenciaPersonaComision(): Observable<TrnRespuestaServicioRpt> {
    const url = this.END_POINT + "/CmsComision/ValidarDependenciaComision/";
    return this.http.get<TrnRespuestaServicioRpt>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }

  srvCalcularViaticos(comision: CmsComisionDTO): Observable<any> {
    const url = this.END_POINT + "/CmsComision/CalcularViaticos";
    return this.http.post<TrnRespuestaServicioRpt>(url, comision)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1)
      );
  }
}
