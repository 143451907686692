import { Component, OnInit, ViewChild  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DecisionAclaracionService } from './decision-aclaracion.service';
import { Ambiental } from '../decision-aclaracion/ambiental';
import { MatTableDataSource, MatSort, MatPaginator, MatToolbar,  MatDialog, MatDialogRef } from '@angular/material';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

export interface Audiencia {
  fechaAudiencia: string;
  observaciones: string;

}

const ELEMENT_DATA: Audiencia[] = [
  {fechaAudiencia: '02/01/2019', observaciones: 'Observación 1'},
  {fechaAudiencia: '01/04/2019', observaciones: 'Observación 2'},
  {fechaAudiencia: '03/07/2019', observaciones: 'Observación 3'},
  {fechaAudiencia: '10/09/2019', observaciones: 'Observación 4'},
  {fechaAudiencia: '09/10/2019', observaciones: 'Observación 5'},
  {fechaAudiencia: '02/11/2019', observaciones: 'Observación 6'},
  {fechaAudiencia: '07/12/2019', observaciones: 'Observación 7'},
  {fechaAudiencia: '08/11/2019', observaciones: 'Observación 8'},
  {fechaAudiencia: '12/03/2019', observaciones: 'Observación 9'},
  {fechaAudiencia: '04/01/2019', observaciones: 'Observación 10'},
];
@Component({
  selector: 'app-decision-aclaracion',
  templateUrl: './decision-aclaracion.component.html',
  styleUrls: ['./decision-aclaracion.component.sass']
})

export class DecisionAclaracionComponent implements OnInit {

  proyecto: Ambiental = {};
  tipoObligacion: any = [];
  idAmbientalDetalle: any;

  displayedColumns: string[] = [
    'fechaAudiencia',
    'adjuntar'

];

  dataSource: MatTableDataSource<Audiencia>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;  constructor(private detalleService: DecisionAclaracionService, private route: ActivatedRoute, private ruta: Router
    , private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(ELEMENT_DATA);

    this.route.params.subscribe(params => {
      this.idAmbientalDetalle = params.id;
     // this.idAmbientalDetalle === 'new' ? this.cargarNuevo(this.idAmbientalDetalle) : this.cargarProyectoAmbiental(this.idAmbientalDetalle);
    });
    this.cargarNuevo(this.idAmbientalDetalle)
    // this.cargarTipoObligacion();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  cargarTipoObligacion() {
    return this.detalleService.getTipoObligacion().subscribe((data: {}) => {
      this.tipoObligacion = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarNuevo(id) {
    this.proyecto = {};
    // this.proyecto.idProyecto = JSON.parse(localStorage.getItem('idP'));
  }

  cargarProyectoAmbiental(id: number) {
    this.detalleService.getProyectoAmbiental(id).subscribe((data: {}) => {
      this.proyecto = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  guardarDetalle(payload) {
   if (this.idAmbientalDetalle === 'new') {
        return this.detalleService.guardarNuevoAmbiental(payload).subscribe((data: {}) => {
          this.ruta.navigate(['/infAmbiental', JSON.parse(localStorage.getItem('idP'))]);
          },error => {
            this.principalComponent.cargarErrorServicio(error);
          });
    } else {
        return this.detalleService.guardarDetalleAmbiental(payload).subscribe((data: {}) => {
          this.ruta.navigate(['/infAmbiental', JSON.parse(localStorage.getItem('idP'))]);
          },error => {
            this.principalComponent.cargarErrorServicio(error);
          });
    }
  }

}
