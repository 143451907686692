import { Component, OnInit } from '@angular/core';
import { CtoInformeRevisionAsignacionDTO } from 'src/app/contratacion/contratacion-dto/informe-revision-asignacion';
import { CtoInformeContratistaObservacionDTO } from '../../../../contratacion-dto/informe-contratista-observacion';
import { InformeSpaBaseComponent } from '../informe-spa-base/informe-spa-base.component';
import { InformeRevisionService } from '../../../informe-revision.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';

@Component({
  selector: 'app-informe-spa-observaciones',
  templateUrl: './informe-spa-observaciones.component.html',
  styles: []
})
export class InformeSpaObservacionesComponent implements OnInit {

  // asignacionInforme: CtoInformeRevisionAsignacion;
  observacionesInforme: CtoInformeContratistaObservacionDTO[];
  totalPaginator: any;

  constructor(
    private lineaBase: InformeSpaBaseComponent,
    private infCtaSupervisionService: InformeRevisionService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    // this.cargarAsignacionInformeRevision(this.lineaBase.idAsignacionParam, this.lineaBase.idPasoInformeParam, this.lineaBase.idPersonaAsignacionParam);
    this.cargarObservacionesInformeRevision(this.lineaBase.idInformeParam, this.lineaBase.idVersionInformeParam, this.lineaBase.idPasoParam);

  }

  // cargarAsignacionInformeRevision(idAsignacion: number, idPasoInforme: number, idPersonaAsignacion: number) {
  //   this.infCtaSupervisionService.ConsultarAsignacionInformeRevision(idAsignacion, idPasoInforme, idPersonaAsignacion)
  //     .subscribe(
  //       result => {
  //         if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
  //           this.asignacionInforme = result.asignacionInforme;
  //         } else {
  //           this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
  //         }
  //       },
  //       error => {
  //       },
  //       () => {
  //         this.lineaBase.blockedDocument = false;
  //       }
  //     );
  // }

  guardarAsignacionObservacion(asignacion: CtoInformeRevisionAsignacionDTO) {
    this.lineaBase.blockedDocument = true;
    this.infCtaSupervisionService.GuardarAsignacionObservacionInformeRevision(asignacion)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null && result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
          this.ngOnInit();
        }
      );
  }

  cargarObservacionesInformeRevision(idInforme: number, idVersionInforme: number, idPaso: number) {
    this.infCtaSupervisionService.ConsultarObservacionesInformeRevision(idInforme, idVersionInforme, idPaso)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.observacionesInforme = result.observacionesInforme;
              this.totalPaginator = this.observacionesInforme.length;
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }
}
