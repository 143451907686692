import { Component, OnInit, Inject  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TribunalesDetalleService } from './tribunales-detalle.service';
import { Tribunales } from '../tribunales-detalle/tribunales';
import { MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../progress-spinner-dialog/progress-spinner-dialog.component';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'arbitros-dialog',
  templateUrl: 'arbitros.html',
})
// tslint:disable-next-line:component-class-suffix
export class ArbitrosDialog {

  constructor(
    public dialogRef: MatDialogRef<ArbitrosDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'app-tribunales-detalle',
  templateUrl: './tribunales-detalle.component.html',
  styleUrls: ['./tribunales-detalle.component.sass']
})

export class TribunalesDetalleComponent implements OnInit {

  proyecto: Tribunales = {};
  etapas: any = [];
  idTribunalesDetalle: any;
  datosArbitros: any = [];

  arbitroTemporal: any = {
    primerNombre: '', otrosNombres: '', primerApellido: '', segundoApellido: '', idTribunal: null, id:null 
  };
  nombre: string;
  primerNombre: string;
  otrosNombres: string;
  primerApellido: string;
  segundoApellido: string;
  idTribunal: number;
  id: number;
  nombreProyecto;

  dataSource: MatTableDataSource<any>;
  dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(private detalleService: TribunalesDetalleService, private route: ActivatedRoute, private ruta: Router, public dialog: MatDialog, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.nombreProyecto = JSON.parse(localStorage.getItem('nombreP'))
    this.cargarEtapa();
    this.route.params.subscribe(params => {
      this.idTribunalesDetalle = params.id;
      this.idTribunalesDetalle === 'new' ? this.cargarNuevo(this.idTribunalesDetalle) : this.cargarProyectoTribunales(this.idTribunalesDetalle);
    });

  }

  cargarEtapa() {
    return this.detalleService.getEtapa().subscribe((data: {}) => {
      this.etapas = data;
    },error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }

  cargarNuevo(id) {
    this.proyecto = {};
    this.reset();
  }

  reset(){
    this.proyecto.idProyecto = Number(JSON.parse(localStorage.getItem('idP')));
    this.proyecto.ekogui = 0;
    this.proyecto.ccb = 0;
    this.proyecto.proyecto = '';
    this.proyecto.modalidad = '';

    this.proyecto.cto = '';
    this.proyecto.anioCto = 0;
    this.proyecto.vicepresidencia = '';

    this.proyecto.fechaRadicacionObservacion = '';
    this.proyecto.cuantiaPretensionesPesos = 0;
    this.proyecto.cuantiaPretensionesDolares = 0;

    this.proyecto.admisionDemandaObservacion = '';
    this.proyecto.cuantiaReconvencionPesos = 0;
    this.proyecto.cuantiaReconvencionDolares = 0;

    this.proyecto.admisionReconvencionObservacion = '';

    this.proyecto.arbitros = [];
    this.proyecto.secretario = '';
    this.proyecto.procuradorJudicial = '';
    this.proyecto.fechaUltimaActuacionObservacion = '';
    this.proyecto.ultimaActuacion = '';
    this.proyecto.fechaProximaActuacionObservacion = '';

    this.proyecto.proximaActuacion = '';
    this.proyecto.apoderado = '';
    this.proyecto.responsableGerencia = '';
    this.proyecto.negociaciones = '';
    this.proyecto.apoderadoContraparte = '';



  }


  cargarProyectoTribunales(id: number) {
    if (this.etapas) {
      this.proyecto = this.detalleService.getDataTribunales();
    }
  }

  guardarDetalle(payload) {
    this.dialogSpin = this.dialog.open(ProgressSpinnerDialogComponent, {
      panelClass: 'transparent',
      disableClose: true
    });
    if (this.proyecto.fechaRadicacion) {
    if (this.proyecto.convocante) {
    if (this.proyecto.pretensiones) {
    if (this.proyecto.admisionDemanda) {
          if (this.proyecto.convocado) {
            if (this.proyecto.hechosReconvecion) {
              if (this.proyecto.admisionReconvencion) {
                if (this.proyecto.idEtapa) {
                  if (this.proyecto.fechaUltimaActuacion) {
                    const date = this.convertStringDateToTime(new Date());
                    if (this.convertStringDateToTime(this.proyecto.fechaUltimaActuacion) < date) {
                      if (this.proyecto.fechaProximaActuacion) {

                          this.proyecto.etapa = this.getTipoEtapa(this.proyecto.idEtapa);
                          if (this.idTribunalesDetalle === 'new') {
                              return this.detalleService.guardarNuevoTribunal(payload).subscribe((data: {}) => {
                                this.ruta.navigate(['/infTribunales', JSON.parse(localStorage.getItem('idP'))]);
                                this.dialogSpin.close();

                                },error => {
                                  this.dialogSpin.close();
                                  this.principalComponent.cargarErrorServicio(error);
                                });
                          } else {
                              return this.detalleService.guardarDetalleTribunal(payload).subscribe((data: {}) => {
                                this.ruta.navigate(['/infTribunales', JSON.parse(localStorage.getItem('idP'))]);
                                this.dialogSpin.close();

                                },error => {
                                  this.dialogSpin.close();
                                  this.principalComponent.cargarErrorServicio(error);
                                });
                          }


                      } else {
                        alert('El campo Fecha Proxima Actuación es Obligatorio');
                      }
                    } else {
                      alert('La Fecha Ultima Actuación no puede ser mayor a la Actual');
                    }
                  } else {
                    alert('El campo Fecha Ultima Actuación es Obligatorio');
                  }
                } else {
                  alert('El campo Etapa es Obligatorio');
                }
              } else {
                alert('El campo Fecha Admisión Reconvención es Obligatorio');
              }
            } else {
              alert('El campo Hechos Reconvención es Obligatorio');
            }
          } else {
            alert('El campo Convocado es Obligatorio');
          }
        } else {
          alert('El campo Fecha Admisión Demanda es Obligatorio');
        }
      } else {
          alert('El campo Pretenciones es Obligatorio');
        }
      } else {
          alert('El campo Convocante es Obligatorio');
        }
      } else {
          alert('El campo Fecha Radicación es Obligatorio');
        }



  }

  getTipoEtapa(payload) {
    const dataLength = this.etapas.length;
    for (let i = 0; i <= dataLength; i++) {
      if (this.etapas[i].id === payload) {
        return this.etapas[i].nombre;
      }
    }
  }

  convertStringDateToTime(data) {
    const date = new Date(data);
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = date.getTime() + localOffset;
    return utc;
  }

  cancelar() {
    this.ruta.navigate(['/infTribunales', JSON.parse(localStorage.getItem('idP'))]);
  }



deleteArbitros(isEdit, isDelete, payload, idProyecto, index) {
  if (isDelete) {
    if (this.proyecto.arbitros[index].id === 0 ) {
      this.proyecto.arbitros.splice(index, 1);
    } else {
      this.proyecto.arbitros[index].eliminar = true;
    }
  }
}




openDialogArbitros(isEdit, isDelete, payload, idProyecto, index) {
  if (!isEdit) {
    const dialogRef = this.dialog.open(ArbitrosDialog, {width: '300px',
    data: {primerNombre: this.primerNombre, otrosNombres: this.otrosNombres, primerApellido: this.primerApellido, segundoApellido: this.segundoApellido,idTribunal: this.idTribunal, id: this.id}
  });
    dialogRef.afterClosed().subscribe(result => {
        if (result != null && result !== '')  {
          let count  = 0;
          for (let i = 0; i < this.proyecto.arbitros.length; i++) {
            if (this.proyecto.arbitros[i].eliminar === false) {
              count = count + 1;
            }
         }
          if (count === 4) {
            window.alert('No puede agregar mas de 4 componedores');
          } else {
            this.arbitroTemporal = {
            id: 0, idTribunal: idProyecto ? idProyecto : 0, primerNombre: result.primerNombre, otrosNombres: result.otrosNombres, primerApellido: result.primerApellido, segundoApellido: result.segundoApellido, eliminar: false};
            this.proyecto.arbitros.push(this.arbitroTemporal);
          }
        }
    });
  } else if (isEdit) {
    const dialogRef = this.dialog.open(ArbitrosDialog, {width: '300px',
    data: { primerNombre: payload.primerNombre, otrosNombres: payload.otrosNombres, primerApellido: payload.primerApellido, segundoApellido: payload.segundoApellido, idTribunal: payload.idTribunal, id: payload.id}
  });
    dialogRef.afterClosed().subscribe(result => {
        if (result != null && result !== '')  {
            this.arbitroTemporal = {
              primerNombre: payload.primerNombre, otrosNombres: payload.otrosNombres, primerApellido: payload.primerApellido, segundoApellido: payload.segundoApellido, idTribunal: payload ? payload : null, id: null, eliminar: false
            };
            for (let i = 0; i < this.proyecto.arbitros.length; i++) {
              if (i === index) {
               this.proyecto.arbitros[i].primerNombre = result.primerNombre;
               this.proyecto.arbitros[i].otrosNombres = result.otrosNombres;
               this.proyecto.arbitros[i].primerApellido = result.primerApellido;
               this.proyecto.arbitros[i].segundoApellido = result.segundoApellido;
              }
           }
        }
    });
  }

}


}
