import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { PuestosTrabajoService } from '../puestos-trabajo.service';

@Component({
  selector: 'app-puestos-trabajo-home',
  templateUrl: './puestos-trabajo-home.component.html',
  styleUrls: ['./puestos-trabajo-home.component.sass']
})
export class PuestosTrabajoHomeComponent implements OnInit {

  blockedDocument: boolean;
  esPuestoFijo: boolean;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private puestosTrabajoService: PuestosTrabajoService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    this.consultarPersonaTienePuestoPermanente();
  }


  consultarPersonaTienePuestoPermanente() {


    this.blockedDocument = true;
    // Valida si la persona cuenta con puestos de trabajo fijos
    forkJoin(
      this.puestosTrabajoService.srvConsultarPersonaTienePuestoPermanente(),
    ).subscribe(
      ([
        tienePuestoTrabajoData
      ]) => {

        // console.log("reservaData", tienePuestoTrabajoData);

        if (tienePuestoTrabajoData.personaPuestoTrabajo != null) {
          this.esPuestoFijo = true;
        } else {
          this.esPuestoFijo = false;
        }

        this.blockedDocument = false;
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
        this.blockedDocument = false;
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );

  }

}
