import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../../../config.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class FuncionarioInviasPrincipalListService {
    public END_POINT = '';

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
        'Content-Type': 'application/json'
        })
    };

    constructor(private config: ConfigService, private http: HttpClient) {
        this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo que carga la informacion de la consulta 
     */
    public getConsultaListado() {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/invias/listadoTramites' ) 
        .pipe(
            retry(1));
    }

    /**
     * Metodo que carga la informacion de la consulta del radicado principal
     * @param numeroRadicado 
     */
    public getConsultaRadicado(numeroRadicado) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/consultaRadicado/' + numeroRadicado ) 
        .pipe(
        retry(1));
    }

    /**
     * Metodo que guarda la informacion de la nueva solicitud    
     * @param objeto 
     */
    public guardarNewSolicitud(objeto){
        return this.http.post(this.END_POINT + '/CargaExtrapesada/invias/new/solicitud/guardar', objeto)
        .pipe(
            retry(1));
    }

     /**
     * Metodo que guarda la informacion de la finalizacion del proceso    
     * @param objeto 
     */
      public guardarFinalizacionProceso(objeto){
        return this.http.post(this.END_POINT + '/CargaExtrapesada/invias/finalizar/proceso', objeto)
        .pipe(
            retry(1));
    }

    
    /**
     * Metodo que carga la informacion de la consulta de los conceptos que tienen o no respuestas
     * @param idSolicitudInvias 
     */
    public getConsultaListaRespuestasSolicitud(idSolicitudInvias) {
        return this.http.get(this.END_POINT + '/CargaExtrapesada/tramite/consulta/respuesta/' + idSolicitudInvias ) 
        .pipe(
        retry(1));
    }
    
}
