import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { Observable, throwError } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TrnPersona } from '../admin-personas/trn-persona';
import { PrsFuncionario, PrsFuncionarioRpt } from '../admin-personas/prs-funcionario';
import { CtoContratoInformacionVigenteEncabezadoDTO, CtoContratoInformacionVigenteEncabezadoRpt } from './contratacion-dto/contrato-informacion-vigente-encabezado';
import { CtoContratoBalanceFinancieroRpt } from './contratacion-dto/contrato-balance-financiero';
import { CtoFuenteFinanciacionDTO } from './contratacion-dto/cto-fuente-financiacion';
import { DatePipe } from '@angular/common';
import { CtoPlanPagoDetalleDTO } from './contratacion-dto/contrato-plan-pago-vigente';
import { TrnRespuestaServicioRpt } from '../parametricas/rpt-respuesta-operacion';
import { EnumFechaFormato } from '../comun/constantes/constantes-comunes';
import { CtoContratoDTO } from './contratacion-dto/contrato';
import { CtoPasoInformeDTO } from './contratacion-dto/cto-paso-informe';
import { CtoOrigenRecursoDTO } from './contratacion-dto/cto-origen-recurso';
import { CtoImputacionRecursoDTO } from './contratacion-dto/cto-imputacion-recurso';
import { CtoTipoRecursoDTO } from './contratacion-dto/cto-tipo-recurso';
import { CtoTipoGarantiaDTO } from './contratacion-dto/cto-tipo-garantia';
import { CtoAmparosGarantiaDTO } from './contratacion-dto/cto-amparos-garantia';
import { CtoRegimenContratacionDTO } from './contratacion-dto/cto-regimen-contratacion';
import { CtoModalidadSeleccionDTO } from './contratacion-dto/cto-modalidad-seleccion';
import { CtoTipoContratoDTO } from './contratacion-dto/cto-tipo-contrato';
import { CtoAseguradoraRpt } from './contratacion-dto/cto-aseguradora';
import { CtoTipoNovedadDTO } from './contratacion-dto/cto-tipo-novedad';
import { CtoContratoContratistaRpt } from './contratacion-dto/contratos-contratista';
import { EnumCtoEstadoContrato } from './constantes/contratacion-constantes';

@Injectable({
  providedIn: 'root'
})
export class ContratacionService {
  public END_POINT = '';
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private datepipe: DatePipe) {

    this.END_POINT = environment.baseUrl;
  }


  getPasosInforme(): Observable<CtoPasoInformeDTO[]> {
    return this.http.get<CtoPasoInformeDTO[]>(this.END_POINT + '/CtoMaPasoInforme')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }


  public getCtoOrigenRecurso(): Observable<CtoOrigenRecursoDTO[]> {
    return this.http.get<CtoOrigenRecursoDTO[]>(this.END_POINT + '/CtoMaOrigenRecurso')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  public getCtoImputacionRecurso(): Observable<CtoImputacionRecursoDTO[]> {
    return this.http.get<CtoImputacionRecursoDTO[]>(this.END_POINT + '/CtoMaImputacionRecurso')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  public getCtoTipoRecurso(): Observable<CtoTipoRecursoDTO[]> {
    return this.http.get<CtoTipoRecursoDTO[]>(this.END_POINT + '/CtoMaTipoRecurso')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }



  public getCtoTipoGarantia(): Observable<CtoTipoGarantiaDTO[]> {
    return this.http.get<CtoTipoGarantiaDTO[]>(this.END_POINT + '/CtoMaTipoGarantia')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  public getCtoAmparoGarantia(): Observable<CtoAmparosGarantiaDTO[]> {
    return this.http.get<CtoAmparosGarantiaDTO[]>(this.END_POINT + '/CtoMaAmparosGarantia')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  public getCtoRegimenContratacion(): Observable<CtoRegimenContratacionDTO[]> {
    return this.http.get<CtoRegimenContratacionDTO[]>(this.END_POINT + '/CtoMaRegimenContratacion')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  public getCtoModalidadSeleccion(): Observable<CtoModalidadSeleccionDTO[]> {
    return this.http.get<CtoModalidadSeleccionDTO[]>(this.END_POINT + '/CtoMaModalidadSeleccion')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  public getCtoTipoContrato(): Observable<CtoTipoContratoDTO[]> {
    return this.http.get<CtoTipoContratoDTO[]>(this.END_POINT + '/CtoMaTipoContrato')
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }


  public getCtoAseguradora(): Observable<CtoAseguradoraRpt> {
    return this.http.get<CtoAseguradoraRpt>(this.END_POINT + '/CtoMaAseguradora')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getCtoAseguradoraActivas(): Observable<CtoAseguradoraRpt> {
    return this.http.get<CtoAseguradoraRpt>(this.END_POINT + '/CtoMaAseguradora/ConsultarAseguradorasActivas')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getCtoTipoNovedad(): Observable<CtoTipoNovedadDTO[]> {
    return this.http.get<CtoTipoNovedadDTO[]>(this.END_POINT + '/CtoMaTipoNovedad')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getCtoTipoNovedadActivos(): Observable<CtoTipoNovedadDTO[]> {
    return this.http.get<CtoTipoNovedadDTO[]>(this.END_POINT + '/CtoMaTipoNovedad/Activos')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getCtoTipoNovedadPorId(idTipoNovedad: number): Observable<CtoTipoNovedadDTO> {
    return this.http.get<CtoTipoNovedadDTO>(this.END_POINT + '/CtoMaTipoNovedad/' + idTipoNovedad)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }
  /**
   * Metodo para consultar un contrato o el listado de contratos registrados con la información vigente a la fecha
   * @param idContrato Valor Opcional id del contrato
   * @param idPersonaContratista Valor Opcional id del contratista persona natural
   * @param idPersonaJuridicaContratista Valor Opcional  id del contratista persona juridica
   * @param idPersonaSupervisor Valor Opcional id del supervisor persona natural
   * @param idDependencia Valor Opcional id de la dependencia del contrato
   * @param vigencia Valor Opcional vigencia del contrato
   * @param fechaCorte Valor Opcional Fecha de corte para consultar contratos
   * @param filaDesde Valor Opcional >0 para consultar contratos
   * @param cantidadRegistros Valor Opcional >0 para consultar X cantidad de contratos
   * @param buscar Valor Opcional para consultar X dato dentro de la informacíon de un contrato
   * @param campoOrden Valor Opcional para ordenar los contratos de acuerdo a uno de sus campos
   * @param ascDesc Valor Opcional (ascDesc) para ordenar los valores del listado a consultar de forma ascednte o descente
   * @returns Objeto de respuesta CtoContratoInformacionVigenteEncabezadoRpt con la información de un contrato o el listado de contratos
   */
  srvConsultarContratosInformacionVigente(idContrato?: number, idPersonaContratista?: number, idPersonaJuridicaContratista?: number, idPersonaSupervisor?: number, idDependencia?: number, vigencia?: string,
    fechaCorte?: Date, filaDesde?: number, cantidadRegistros?: number, buscar?: string, campoOrden?: string, ascDesc?: number): Observable<CtoContratoInformacionVigenteEncabezadoRpt> {
    let urlEndpoint = this.END_POINT + '/CtoContratacion/ConsultarContratosInformacionVigente?';
    if (idContrato != null) {
      urlEndpoint = urlEndpoint + '&idContrato=' + idContrato;
    }
    if (idPersonaContratista != null) {
      urlEndpoint = urlEndpoint + '&idPersonaContratista=' + idPersonaContratista;
    }
    if (idPersonaJuridicaContratista != null) {
      urlEndpoint = urlEndpoint + '&idPersonaJuridicaContratista=' + idPersonaJuridicaContratista;
    }
    if (idPersonaSupervisor != null) {
      urlEndpoint = urlEndpoint + '&idPersonaSupervisor=' + idPersonaSupervisor;
    }
    if (idDependencia != null) {
      urlEndpoint = urlEndpoint + '&idDependencia=' + idDependencia;
    }
    if (vigencia != null) {
      urlEndpoint = urlEndpoint + '&vigencia=' + vigencia;
    }
    if (fechaCorte != null) {
      urlEndpoint = urlEndpoint + '&fechaCorte=' + this.datepipe.transform(fechaCorte, EnumFechaFormato.YYYY_MM_DD_GUION);
    }
    if (filaDesde != null) {
      urlEndpoint = urlEndpoint + '&filaDesde=' + filaDesde;
    }
    if (cantidadRegistros != null) {
      urlEndpoint = urlEndpoint + '&cantidadRegistros=' + cantidadRegistros;
    }
    if (buscar != null) {
      urlEndpoint = urlEndpoint + '&buscar=' + buscar;
    }
    if (campoOrden != null) {
      urlEndpoint = urlEndpoint + '&campoOrden=' + campoOrden;
    }
    if (ascDesc != null) {
      if (ascDesc == 1) {
        urlEndpoint = urlEndpoint + '&ascDesc=asc';
      } else {
        urlEndpoint = urlEndpoint + '&ascDesc=desc';
      }
    }

    return this.http.get<CtoContratoInformacionVigenteEncabezadoRpt>(urlEndpoint)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public ObtenerSupervisoresContrato(idContrato: number): Observable<any> {
    return this.http.get<any>(`${this.END_POINT}/CtoContratacion/ObtenerSupervisoresContratos/${idContrato}`)
      .pipe(map(dato => dato), retry(1));
  }

  /**
   * Metodo para consultar el listado de contratos asociados al supervisor (usuario logueado)
   */
  ListarContratosPublicadosPorSupervisor(): Observable<CtoContratoInformacionVigenteEncabezadoRpt> {
    return this.http.get<CtoContratoInformacionVigenteEncabezadoRpt>(this.END_POINT + '/CtoContratacion/ListarContratosPublicadosPorSupervisor')
      .pipe(
        map(cto => {
          this.srvCalcularEstadoVigenciaContrato(cto.contratosInformacionVigente)
          return cto;
        }),
        retry(1));
  }

  public GetFuentesFinanciacion() {
    return this.http.get<CtoFuenteFinanciacionDTO[]>(this.END_POINT + '/CtoMaFuenteFinanciacion')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public GetCtoMaFuenteFinanciacionFiltroFiducias(cargarFiducias?: boolean) {
    let url = this.END_POINT + '/CtoMaFuenteFinanciacion/GetCtoMaFuenteFinanciacionFiltroFiducias?';
    if (cargarFiducias != null) {
      url = url + 'cargarFiducias=' + cargarFiducias;
    }
    return this.http.get<CtoFuenteFinanciacionDTO[]>(url)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public ConsultarObligacionesPorContrato(idContrato: number) {
    return this.http.get<any[]>(this.END_POINT + '/CtoObligacionesContratos/' + idContrato)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public GuardarDependenciaSupervisionContrato(idPersona: number, idContrato: number, idDependencia: number): Observable<any> {
    return this.http.post(`${this.END_POINT}/CtoContratacion/GuardarDependenciaContrato?idPersona=${idPersona}&idContrato=${idContrato}&idDependencia=${idDependencia}`, null)
      .pipe(map(data => data), retry(1));
  }

  public GuardarObligacionContrato(obligacion: any) {
    return this.http.post(this.END_POINT + '/CtoObligacionesContratos', obligacion, this.httpOptions)
      .pipe(
        retry(1));
  }

  public EliminarObligacion(idObligacion: any) {
    return this.http.delete(this.END_POINT + '/CtoObligacionesContratos/' + idObligacion, this.httpOptions)
      .pipe(
        retry(1));
  }

  GuardarPeriodoPago(pago: CtoPlanPagoDetalleDTO): Observable<TrnRespuestaServicioRpt> {
    return this.http.post<TrnRespuestaServicioRpt>(this.END_POINT + '/CtoPlanesPago/GuardarPeriodoPago/', pago, this.httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }


  public eliminarPlanPago(idPago: any): Observable<TrnRespuestaServicioRpt> {
    return this.http.delete<TrnRespuestaServicioRpt>(this.END_POINT + '/CtoPlanesPago/' + idPago, this.httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public GetConsultarPersonasDisponiblesParaVistoBuenoInforme(idsContrato: number[]): Observable<any> {
    return this.http.post(this.END_POINT + '/CtoContratacionPersonas/ConsultarPersonasDisponiblesParaVistoBuenoInforme/', idsContrato, this.httpOptions)
      .pipe(
        retry(1));
  }

  public GetConsultarPersonasAsociadasVistoBuenoInformesPorContrato(idContrato: number): Observable<TrnPersona[]> {
    return this.http.get<TrnPersona[]>(this.END_POINT + '/CtoContratacionPersonas/ConsultarPersonasAsociadasVistoBuenoInformesPorContrato/' + idContrato)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public GuardarContratoFuncionarioVistoBueno(funcionarioVistoBuenoContrato): Observable<any> {
    return this.http.post(this.END_POINT + '/CtoVistoBuenoInformesContrato/GuardarContratoVistoBuenoInformes', funcionarioVistoBuenoContrato, this.httpOptions)
      .pipe(
        retry(1));
  }

  public EliminarContratoFuncionarioVistoBueno(funcionarioVistoBuenoContrato): Observable<any> {
    return this.http.post(this.END_POINT + '/CtoVistoBuenoInformesContrato/EliminarContratoVistoBuenoInformes', funcionarioVistoBuenoContrato)
      .pipe(
        retry(1));
  }

  public EliminarContratoFuncionarioVistoBuenoPorIdContrato(idContrato): Observable<any> {
    return this.http.delete(this.END_POINT + '/CtoVistoBuenoInformesContrato/EliminarContratoVistoBuenoInformesPorIdContrato/' + idContrato)
      .pipe(
        retry(1));
  }

  ConsultarPrsFuncionariosSupervisores(fechaConsulta?: Date): Observable<PrsFuncionario[]> {
    let urlEndpoint = this.END_POINT + '/CtoContratacionPersonas/ConsultarFuncionariosSupervisores?';
    if (fechaConsulta != null) {
      urlEndpoint = urlEndpoint + 'fechaConsulta=' + this.datepipe.transform(fechaConsulta, 'yyyy-MM-dd');
    }
    return this.http.get<PrsFuncionario[]>(urlEndpoint)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarFuncionariosSupervisoresPorDependencia(idDependencia: number, fechaConsulta?: Date): Observable<PrsFuncionario[]> {
    let urlEndpoint = this.END_POINT + '/CtoContratacionPersonas/ConsultarFuncionariosSupervisoresPorDependencia?';
    if (idDependencia != null) {
      urlEndpoint = urlEndpoint + '&idDependencia=' + idDependencia;
    }

    if (fechaConsulta != null) {
      urlEndpoint = urlEndpoint + '&fechaConsulta=' + this.datepipe.transform(fechaConsulta, 'yyyy-MM-dd');
    }
    return this.http.get<PrsFuncionario[]>(urlEndpoint)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  ConsultarPersonasSupervisoresPorDependencia(idDependencia: number): Observable<PrsFuncionarioRpt> {
    let urlEndpoint = this.END_POINT + '/CtoContratacionPersonas/ConsultarPersonasSupervisoresPorDependencia?';
    if (idDependencia != null) {
      urlEndpoint = urlEndpoint + '&idDependencia=' + idDependencia;
    }

    return this.http.get<PrsFuncionarioRpt>(urlEndpoint)
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public getPrsFuncionariosOrdenadoresGasto(): Observable<PrsFuncionario[]> {
    return this.http.get<PrsFuncionario[]>(this.END_POINT + '/CtoContratacionPersonas/ConsultarFuncionariosOrdenadoresGasto/')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  public establecerPago(idPlanPago: number, idContrato: number, pagado: boolean) {
    return this.http.post(this.END_POINT + '/CtoPlanesPago/EstablecerPago/' + idPlanPago + '/' + idContrato + '/' + pagado, this.httpOptions)
      .pipe(
        retry(1));
  }

  ConsultarContratoBalanceFinanciero(idContrato: number) {
    return this.http.get<CtoContratoBalanceFinancieroRpt>(this.END_POINT + '/CtoContratacion/ConsultarContratoBalanceFinanciero/' + idContrato)
      .pipe(
        map(cto => {
          return cto;
        }),
        retry(1));
  }

  /**
   * Metodo que consulta los contratos de un contraetista según el usuario logueado
   * @returns listaContratos Objeto de tipo CtoContratoContratistaRpt
   */
  public ConsultarContratosPorIdContratista(): Observable<CtoContratoContratistaRpt> {
    return this.http.get<CtoContratoContratistaRpt>(this.END_POINT + '/CtoContratacion/ConsultarContratosPorIdContratista')
      .pipe(
        map(data => {
          return data;
        }),
        retry(1));
  }

  /**
   * Metodo generico que recibe el listado de contrato y determina si estan en ejecución o finalizados
   * @param listaContratos Objeto de tipo CtoContratoInformacionVigenteEncabezadoDTO
   * @returns listaContratos Objeto de tipo CtoContratoInformacionVigenteEncabezadoDTO, con el estado de cada contrato consultado
   */
  srvCalcularEstadoVigenciaContrato(listaContratos: CtoContratoInformacionVigenteEncabezadoDTO[]) {
    return listaContratos.forEach(cto => {
      const fechaTerminacionContrato = this.datepipe.transform(new Date(cto.fechaTerminacionContrato), EnumFechaFormato.YYYY_MM_DD_GUION);
      const fechaActual = this.datepipe.transform(new Date(), EnumFechaFormato.YYYY_MM_DD_GUION);
      if (fechaTerminacionContrato >= fechaActual) {
        cto.estadoVigenciaContrato = EnumCtoEstadoContrato.EN_EJECUCION;
      } else {
        cto.estadoVigenciaContrato = EnumCtoEstadoContrato.FINALIZADO;
      }
    });
  }

  /**
   * Metodo que retorna el pdf del contratista.
   * @returns
   */
  public getGeneracionPdfContratista(idContrato) {
    return this.http.get(this.END_POINT + '/ClbCertificadoContratista/generacionPdfContratista/' + idContrato)
      .pipe(
        retry(1));
  }

}
