import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PersonaService } from '../persona/persona.service';
import { EnumTipoDocumento } from 'src/app/comun/constantes/constantes-comunes';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { PrincipalComponent } from '../../home/principal/principal.component';

@Component({
  selector: 'app-persona-buscar-agregar',
  templateUrl: './persona-buscar-agregar.component.html',
  styleUrls: []
})
export class PersonaBuscarAgregarComponent implements OnInit {

  tiposDocumentoList: any = [];
  tipoDocumentoVar: any;
  personasList: any = [];
  numeroDocumentoVar: number;
  existePersona = false;
  personaEncontrada: any;
  mostrarComponente = false;
  esFuncionario = false;

  @Input()
  valoresLista: number[];
  @Input()
  habilitarComponenteFuncionario = false;
  @Output()
  cancelarPersonaJuridica = new EventEmitter<boolean>();
  @Output()
  idPersonaSeleccionada = new EventEmitter<any>();

  constructor(
    private svcPersonas: PersonaService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarTiposDocumento();
  }

  cargarTiposDocumento() {
    this.parametricasService.getTrnTiposDocumentoPorIds(EnumTipoDocumento.CC + ',' + EnumTipoDocumento.NIT + ',' + EnumTipoDocumento.NIEE).
      subscribe(
        result => {
          this.tiposDocumentoList = result;
          if (this.tiposDocumentoList && this.valoresLista != null) {
            this.tiposDocumentoList = this.tiposDocumentoList.filter(dato => this.valoresLista.includes(dato.id));
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cancelarComponente(cancelar: boolean) {
    this.tipoDocumentoVar = null;
    this.cancelarPersonaJuridica.emit(cancelar);
  }

  retornarIdPersona(idPersona) {

    const persona = {
      idPersona,
      tipoDocumento: this.tipoDocumentoVar
    };

    this.idPersonaSeleccionada.emit(persona);
  }

  get EnumTipoDocumento() {
    return EnumTipoDocumento;
  }
}
