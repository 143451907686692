import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-public-carreteras-consulta-peajes',
  templateUrl: './public-carreteras-consulta-peajes.component.html'
})
export class PublicCarreterasConsultaPeajesComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit() {
    var element = document.getElementById("aniscopio_body");
  element.classList.add("public");
  }

}
