import { Component, OnInit } from '@angular/core';
import { TramitesAmbientalesAeropuertoService } from '../tramites-ambientales.service';
import {
  TramitesAmbientalesDTO, UnidadFuncionalDTO, JustificacionModificacionDTO,
  EntidadDTO, TipoTramiteDTO, TramiteRelacionadoDTO, EtapaProyectoDTO,
  PriorizacionDTO, EstadoDTO, ObjectUnidadFuncionalDTO, ObjectTramoDTO
} from '../tramites-ambientales.dto.js';
import { Router, ActivatedRoute } from '@angular/router';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ProyectoDTO } from "src/app/componentes-comunes/componentes-comunes.dto";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { forkJoin } from "rxjs";
import { FormBuilder, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { EnumAmbMaTipoTramite, EnumAmbMaTramiteRelacionado, EnumTrnMaTipoProyecto } from "src/app/comun/constantes/constantes-comunes.js";


@Component({
  selector: 'app-tramites-ambientales',
  templateUrl: './tramites-ambientales.component.html',
  styleUrls: ['./tramites-ambientales.component.css']
})
export class TramitesAmbientalesComponent implements OnInit {

  loading: boolean;
  idProyecto: number;
  idTramiteAmbiental: number;
  proyectoSeleccionado: ProyectoDTO;
  formTramiteAmbiental;
  formUnidadFuncionalAsociado;
  formJustificacionModificacion;
  formUnidadesFuncionales;

  lstEntidad: EntidadDTO[];
  lstTipoTramite: TipoTramiteDTO[];
  lstTramiteRelacionado: TramiteRelacionadoDTO[];
  lstEtapaProyecto: EtapaProyectoDTO[];
  lstUnidadFuncional: ObjectUnidadFuncionalDTO[];
  lstTramo: any;
  lstPriorizacion: PriorizacionDTO[];
  lstEstado: EstadoDTO[];

  nombreProyecto;
  tipoSolicitudTA: string;
  tipoSolicitudStr: string;
  solicitudUnidadFuncional: string;
  tramiteAmbiental: TramitesAmbientalesDTO;
  unidadFuncional: UnidadFuncionalDTO;
  unidadesFuncionalesAsociadasList: UnidadFuncionalDTO[];
  unidadFuncionalAsociada: UnidadFuncionalDTO;
  justificacionModificacion: JustificacionModificacionDTO;

  dialogoUnidadFuncionalEliminar: boolean;
  dialogoGuardarTramiteAmbiental: boolean;
  dialogoJustificacionModificacion: boolean;
  dialogoUnidadesFuncionales: boolean;
  isView: boolean;
  isEdit: boolean;
  habilitaEnFirme: boolean;

  habilitaTramitesRelacionados: boolean;
  habilitaOtroTramite: boolean;
  habilitaPermisoTramiteRelacionado: boolean;

  tempUnidadFuncionalSelected: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private messageService: MessageService,
    private componentesComunesService: ComponentesComunesService,
    private service: TramitesAmbientalesAeropuertoService,
    private principalComponent: PrincipalComponent
  ) {
    // Crear formulario tramite ambiental
    this.formTramiteAmbiental = formBuilder.group({
      entidadFormAtributte: [null, [Validators.required]],
      tipoTramiteFormAtributte: [null, [Validators.required]],
      tramiteRelacionadoFormAtributte: [null],
      otroTramiteRelacionadoFormAtributte: [null],
      tramiteRelacionadoCualFormAtributte: [null],
      etapaProyectoFormAtributte: [null, [Validators.required]],
      priorizacionFormAtributte: [null, [Validators.required]],
      estadoTramiteFormAtributte: [null, [Validators.required]],
      radicadoConcesionarioFormAtributte: [null, [Validators.required]],
      fechaRadicadoConcesionarioFormAtributte: [null, [Validators.required]],
      radicadoEntidadFormAtributte: [null, [Validators.maxLength(14), Validators.required]],
      fechaEntidadFormAtributte: [null, [Validators.required]],
      expedienteFormAtributte: [null, [Validators.maxLength(10), Validators.minLength(5), Validators.required]],
      descripcionFormAtributte: [null, [Validators.maxLength(100), Validators.minLength(5), Validators.required]],
      enFirmeFormAtributte: [null, null],
    });

    // Crear formulario unidad funcional
    this.formUnidadFuncionalAsociado = formBuilder.group({
      unidadFuncionalFormAtributte: [null, [Validators.required]],
      tramoTramiteFormAtributte: [null, null]
    });

    this.formJustificacionModificacion = formBuilder.group({
      fechaActuacionFormAtributte: [null, [Validators.required]],
      observacionesFormAtributte: [null, [Validators.maxLength(100), Validators.minLength(5), Validators.required]],
    })

    this.formUnidadesFuncionales = formBuilder.group({
      unidadFuncionalFormAtributte: [null, [Validators.required]],
      tramoFormAtributte: [null, null],
    })
  }

  ngOnInit() {
    this.tramiteAmbiental = {};
    this.tramiteAmbiental.entidadDTO = {};
    this.tramiteAmbiental.tipoTramiteDTO = {};
    this.tramiteAmbiental.tramiteRelacionadoDTO = {};
    this.tramiteAmbiental.etapaProyectoDTO = {};
    this.tramiteAmbiental.priorizacionDTO = {};
    this.tramiteAmbiental.estadoDTO = {};
    this.unidadFuncionalAsociada = {};
    this.justificacionModificacion = {};

    this.loading = true;
    this.dialogoJustificacionModificacion = false;
    this.isEdit = false;
    this.solicitudUnidadFuncional = "";
    this.tipoSolicitudStr = "";
    this.dialogoUnidadFuncionalEliminar = false;
    this.dialogoGuardarTramiteAmbiental = false;
    this.dialogoUnidadesFuncionales = false;
    this.idProyecto = (localStorage.getItem('idProyectoAero') != null) ? Number(localStorage.getItem('idProyectoAero')) : 0;

    this.ruta.params.subscribe(params => {
      this.idTramiteAmbiental = params.id;
      // Inicializar listas parametricas y DTOs
      forkJoin(
        this.componentesComunesService.obtenerProyecto(this.idProyecto),
        this.service.getEntidad(),
        this.service.getPiorizacion(),
        this.service.getTipoTramite(),
        this.service.getEtapasProyecto(EnumTrnMaTipoProyecto.MODO_AEROPUERTO), //this.idProyecto 2 para probar
        this.service.getTramiteRelacionado(),
        this.service.getUnidades(this.idProyecto)//this.idProyecto  6 para probar
      ).subscribe(
        ([proyectoData, entidadData, priorizacionData,
          tipoTramiteData, etapaProyectoData, tramiteRelacionadoData, unidadesData]) => {
          this.proyectoSeleccionado = proyectoData;
          this.lstEntidad = entidadData.listaAmbMaEntidadAmbiental;
          this.lstTramiteRelacionado = tramiteRelacionadoData.listaAmbMaTramiteRelacionado;
          this.lstTipoTramite = tipoTramiteData.listaAmbMaTipoTramite;
          this.lstPriorizacion = priorizacionData.listaAmbMaPriorizacion;

          this.lstEtapaProyecto = etapaProyectoData.listaEtapasProyecto;
          this.lstUnidadFuncional = unidadesData;

          if (this.tipoSolicitudTA === "onNew") {
            this.tipoSolicitudStr = "Modo Creación";
            this.formTramiteAmbiental.enable();
            this.formUnidadFuncionalAsociado.enable();
            this.formJustificacionModificacion.disable();
            this.isView = true;
            this.isEdit = false;
            this.loading = false;
          }
          if (this.tipoSolicitudTA === "onView") {
            this.tipoSolicitudStr = "Modo Consultar";
            this.formTramiteAmbiental.disable();
            this.formUnidadFuncionalAsociado.disable();
            this.formJustificacionModificacion.disable();
            this.isView = false;
            this.isEdit = false;
            this.habilitaEnFirme = true;
            this.cargaDatosConsulta();

          }
          if (this.tipoSolicitudTA === "onEdit") {
            this.tipoSolicitudStr = "Modo Edición";
            this.formTramiteAmbiental.enable();
            this.formUnidadFuncionalAsociado.enable();
            this.formJustificacionModificacion.enable();
            this.isView = true;
            this.isEdit = true;
            this.habilitaEnFirme = true;
            this.cargaDatosConsulta();
          }

          if (this.tipoSolicitudTA === "onViewHistorico") {
            this.tipoSolicitudStr = "Modo Consultar";
            this.formTramiteAmbiental.disable();
            this.formUnidadFuncionalAsociado.disable();
            this.formJustificacionModificacion.disable();
            this.isView = false;
            this.habilitaTramitesRelacionados = true;
            this.isEdit = false;
            this.habilitaEnFirme = true;
            this.cargarTramiteAmbientalPorIdHistorico();
          }
        },
        (e) => { }
      );

      if (this.idTramiteAmbiental != 0) {
        console.log(this.idTramiteAmbiental);
        if (this.tipoSolicitudTA === "onViewHistorico") {
          this.tipoSolicitudStr = "Modo Consultar";
          this.formTramiteAmbiental.disable();
          this.formUnidadFuncionalAsociado.disable();
          this.formJustificacionModificacion.disable();
          this.isView = false;
          this.habilitaTramitesRelacionados = true;
          this.isEdit = false;
          this.habilitaEnFirme = true;
          this.cargarTramiteAmbientalPorIdHistorico();
          this.cargarMaEstadoTramites();
        } else {
          this.cargarMaEstadoTramitesActivos();
        }
      }

      this.tipoSolicitudTA = localStorage.getItem('tipoSolicitudTA');

    });
  }

  /**
   * Metodo que consulta la información parametrica de priorización de los tramites ambientales
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaEstadoTramites() {
    return this.service
      .getEstadoCompromiso()
      .subscribe((result) => {
        this.lstEstado = result.listaAmbMaEstadoCompromiso;

        if (this.lstEstado === null) {
          this.lstEstado = [];
        }

        if (this.tramiteAmbiental.idEstadoTramite != null) {
          this.tramiteAmbiental.estadoDTO = this.lstEstado.find(
            (item) => item.id == this.tramiteAmbiental.idEstadoTramite
          );
          this.formTramiteAmbiental
            .get("estadoTramiteFormAtributte")
            .setValue(this.tramiteAmbiental.estadoDTO);
        }
      });
  }

  /**
   * Metodo que consulta la información parametrica de priorización de los tramites ambientales
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarMaEstadoTramitesActivos() {
    return this.service
      .getEstadoCompromisoActivos()
      .subscribe((result) => {
        this.lstEstado = result.listaAmbMaEstadoCompromiso;

        if (this.lstEstado === null) {
          this.lstEstado = [];
        }

        if (this.tramiteAmbiental.idEstadoTramite != null) {
          this.tramiteAmbiental.estadoDTO = this.lstEstado.find(
            (item) => item.id == this.tramiteAmbiental.idEstadoTramite
          );
          this.formTramiteAmbiental
            .get("estadoTramiteFormAtributte")
            .setValue(this.tramiteAmbiental.estadoDTO);
        }
      });
  }

  /**
   * Metodo que consulta la información del tramite ambiental por id
   * @param event Evento que buscar dentro del listado
   * @returns Listado filtrado de acuerdo en caso contrario listado completo
   */
  cargarTramiteAmbientalPorIdHistorico() {
    this.loading = true;
    return this.service
      .getConsultarTramiteAmbientalPorIdHistorico(this.idTramiteAmbiental)
      .subscribe((result) => {
        this.tramiteAmbiental = result.tramiteAmbiental;
        this.cargarInfoTramite();
        this.habilitaTramitesRelacionados = true;
        this.loading = false;
      });
  }

  /**
   * Metodo que carga el subtramo
   *
   * @param payload
   */
  getSubTramo(payload) {
    if (payload != null && payload != undefined && payload != ''
      && payload.id != undefined && payload.id != null && payload.id != '') {
      this.service.getTramo(payload.id).subscribe((data: {}) => {
        this.lstTramo = [];
        this.lstTramo = data;
      }, (error) => {
        this.principalComponent.cargarErrorServicio(error);
      });
    } else {
      this.lstTramo = [];
    }
  }

  /**
   * metodo que guarda una unidad funcional
   */
  submitUnidadesFuncionales() {
    if (this.tramiteAmbiental.unidadFuncionalDTO != undefined
      && this.tramiteAmbiental.unidadFuncionalDTO != null
      && this.tramiteAmbiental.unidadFuncionalDTO.id != null
      && this.tramiteAmbiental.unidadFuncionalDTO.id > 0) {

      if (this.unidadesFuncionalesAsociadasList == undefined || this.unidadesFuncionalesAsociadasList == null) {
        this.unidadesFuncionalesAsociadasList = [];
      }

      if (this.tempUnidadFuncionalSelected != null) {
        this.unidadesFuncionalesAsociadasList = this.unidadesFuncionalesAsociadasList.filter(
          (obj) => obj !== this.tempUnidadFuncionalSelected
        );
      }

      let error = false;
      if (this.tramiteAmbiental.tramoDTO != undefined
        && this.tramiteAmbiental.tramoDTO != null
        && this.tramiteAmbiental.tramoDTO.id != null
        && this.tramiteAmbiental.tramoDTO.id > 0) {

        this.unidadesFuncionalesAsociadasList.forEach(element => {
          if (element.idUnidadFuncional === this.tramiteAmbiental.unidadFuncionalDTO.id
            && element.idTramo === this.tramiteAmbiental.tramoDTO.id) {
            this.lanzarMensajeError("Ya existe una unidad funcional y tramo con la misma información.");
            error = true;
            return;
          }
        });
        if (!error) {
          this.unidadesFuncionalesAsociadasList.push(
            {
              "codigo": this.tramiteAmbiental.unidadFuncionalDTO.codigo,
              "idUnidadFuncional": this.tramiteAmbiental.unidadFuncionalDTO.id,
              "unidadFuncional": this.tramiteAmbiental.unidadFuncionalDTO.nombre,
              "subtramo": this.tramiteAmbiental.tramoDTO.nombre,
              "idTramo": this.tramiteAmbiental.tramoDTO.id
            });
          this.dialogoUnidadesFuncionales = false;
          this.solicitudUnidadFuncional = "";
        }
      } else {
        this.unidadesFuncionalesAsociadasList.forEach(element => {
          if (element.idUnidadFuncional === this.tramiteAmbiental.unidadFuncionalDTO.id) {
            this.lanzarMensajeError("Ya existe una unidad funcional con la misma información.");
            error = true;
            return;
          }
        });
        if (!error) {
          this.unidadesFuncionalesAsociadasList.push(
            {
              "codigo": this.tramiteAmbiental.unidadFuncionalDTO.codigo,
              "idUnidadFuncional": this.tramiteAmbiental.unidadFuncionalDTO.id,
              "unidadFuncional": this.tramiteAmbiental.unidadFuncionalDTO.nombre,
              "subtramo": null,
              "idTramo": null
            });
          this.dialogoUnidadesFuncionales = false;
          this.solicitudUnidadFuncional = "";
        }
      }
      this.tramiteAmbiental.unidadFuncionalDTO = {};
      this.tramiteAmbiental.tramoDTO = {};
    } else {
      this.lanzarMensajeError("Debe seleccionar la unidad funcional");
      return;
    }

  }

  /**
   * Metodo que carga l tramite y las unidades modo consulta
   */
  cargaDatosConsulta() {
    forkJoin(
      this.service.getTramiteById(this.idTramiteAmbiental),
      this.service.getTramiteUnidadesFuncionales(this.idTramiteAmbiental)
    ).subscribe(
      ([tramiteData, tramiteUnidadFData]) => {
        this.tramiteAmbiental = tramiteData.tramiteAmbiental;
        this.unidadesFuncionalesAsociadasList = tramiteUnidadFData.listaUnidadesFuncionales;
        this.cargarInfoTramite();
        this.loading = false;
      },
      (e) => { }
    );
  }

  /**
   * Metodoo que carga la informacion
   */
  cargarInfoTramite() {
    if (this.lstEntidad === null) {
      this.lstEntidad = [];
    }
    if (this.lstTramiteRelacionado === undefined || this.lstTramiteRelacionado === null) {
      this.lstTramiteRelacionado = [];
    }
    if (this.lstTipoTramite === undefined || this.lstTipoTramite === null) {
      this.lstTipoTramite = [];
    }
    if (this.lstEtapaProyecto === undefined || this.lstEtapaProyecto === null) {
      this.lstEtapaProyecto = [];
    }
    if (this.lstPriorizacion === undefined || this.lstPriorizacion === null) {
      this.lstPriorizacion = [];
    }
    if (this.lstEstado === undefined || this.lstEstado === null) {
      this.lstEstado = [];
    }

    if (this.tramiteAmbiental.idEntidad != null) {
      this.tramiteAmbiental.entidadDTO = this.lstEntidad.find(
        (item) => item.id == this.tramiteAmbiental.idEntidad
      );
      this.formTramiteAmbiental
        .get("entidadFormAtributte")
        .setValue(this.tramiteAmbiental.entidadDTO);
    }

    if (this.tramiteAmbiental.idTipoTramite != null) {
      this.tramiteAmbiental.tipoTramiteDTO = this.lstTipoTramite.find(
        (item) => item.id == this.tramiteAmbiental.idTipoTramite
      );
      this.formTramiteAmbiental
        .get("tipoTramiteFormAtributte")
        .setValue(this.tramiteAmbiental.tipoTramiteDTO);
    }

    if (this.tramiteAmbiental != null && this.tramiteAmbiental.idTipoTramite != null) {
      if (EnumAmbMaTipoTramite.TIPO_TRAMITE_CESION === this.tramiteAmbiental.idTipoTramite
        || EnumAmbMaTipoTramite.TIPO_TRAMITE_CIERRE_ARCHIVO === this.tramiteAmbiental.idTipoTramite
        || EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === this.tramiteAmbiental.idTipoTramite
        || EnumAmbMaTipoTramite.TIPO_TRAMITE_MODIFICACION === this.tramiteAmbiental.idTipoTramite) {
        this.habilitaTramitesRelacionados = false;
        this.tramiteAmbiental.tramiteRelacionadoDTO = {};
        this.habilitaPermisoTramiteRelacionado = false;
        this.habilitaOtroTramite = false;
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === this.tramiteAmbiental.idTipoTramite) {
          this.habilitaOtroTramite = true;
        }
      } else {
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_OTRO === this.tramiteAmbiental.idTipoTramite) {
          this.habilitaOtroTramite = true;
        } else {
          this.habilitaOtroTramite = false;
        }
        this.tramiteAmbiental.tramiteRelacionadoDTO = {};
        this.habilitaPermisoTramiteRelacionado = false;
      }
    }

    if (this.tramiteAmbiental.idTramiteRelacionado != null) {
      this.tramiteAmbiental.tramiteRelacionadoDTO = this.lstTramiteRelacionado.find(
        (item) => item.id == this.tramiteAmbiental.idTramiteRelacionado
      );
      this.formTramiteAmbiental
        .get("tramiteRelacionadoFormAtributte")
        .setValue(this.tramiteAmbiental.tramiteRelacionadoDTO);

      if (this.tramiteAmbiental.tramiteRelacionadoDTO != null && this.tramiteAmbiental.tramiteRelacionadoDTO.id != null) {
        this.habilitaTramitesRelacionados = false;
      } else {
        this.habilitaTramitesRelacionados = true;
      }

      if (this.tramiteAmbiental.idTramiteRelacionado === EnumAmbMaTramiteRelacionado.TRAMITE_RELACIONADO_PERMISO) {
        this.habilitaPermisoTramiteRelacionado = true;
      } else {
        this.habilitaPermisoTramiteRelacionado = false;
      }
    }

    if (this.tramiteAmbiental.idEtapaProyecto != null) {
      this.tramiteAmbiental.etapaProyectoDTO = this.lstEtapaProyecto.find(
        (item) => item.id == this.tramiteAmbiental.idEtapaProyecto
      );
      this.formTramiteAmbiental
        .get("etapaProyectoFormAtributte")
        .setValue(this.tramiteAmbiental.etapaProyectoDTO);
    }

    if (this.tramiteAmbiental.idPriorizacion != null) {
      this.tramiteAmbiental.priorizacionDTO = this.lstPriorizacion.find(
        (item) => item.id == this.tramiteAmbiental.idPriorizacion
      );
      this.formTramiteAmbiental
        .get("priorizacionFormAtributte")
        .setValue(this.tramiteAmbiental.priorizacionDTO);
    }

    if (this.tramiteAmbiental.idEstadoTramite != null) {
      this.tramiteAmbiental.estadoDTO = this.lstEstado.find(
        (item) => item.id == this.tramiteAmbiental.idEstadoTramite
      );
      this.formTramiteAmbiental
        .get("estadoTramiteFormAtributte")
        .setValue(this.tramiteAmbiental.estadoDTO);
    }

    if (this.tramiteAmbiental.fechaRadicado != null) {
      this.tramiteAmbiental.fechaRadicado = new Date(this.tramiteAmbiental.fechaRadicado);
    }

    if (this.tramiteAmbiental.fechaRadicadoEntidad != null) {
      this.tramiteAmbiental.fechaRadicadoEntidad = new Date(this.tramiteAmbiental.fechaRadicadoEntidad);
    }

    if (this.tipoSolicitudTA === "onViewHistorico" || this.tipoSolicitudTA === "onView") {
      this.habilitaTramitesRelacionados = true;
    }
  }

  /**
   * Metodo que carga la informacion del json
   */
  cargaDatosConsultaUF(unidadFuncionalSelected: UnidadFuncionalDTO) {
    if (unidadFuncionalSelected != undefined && unidadFuncionalSelected != null) {
      if (unidadFuncionalSelected.idUnidadFuncional != undefined && unidadFuncionalSelected.idUnidadFuncional != null) {
        this.tramiteAmbiental.unidadFuncionalDTO.id = unidadFuncionalSelected.idUnidadFuncional;
        this.tramiteAmbiental.unidadFuncionalDTO.idUnidadFuncional = unidadFuncionalSelected.idUnidadFuncional;
        this.tramiteAmbiental.unidadFuncionalDTO.nombreUnidadFuncional = unidadFuncionalSelected.unidadFuncional;
        this.tramiteAmbiental.unidadFuncionalDTO = this.lstUnidadFuncional.find(
          (t) => t.id === unidadFuncionalSelected.idUnidadFuncional
        );
        if (unidadFuncionalSelected.idTramo != undefined && unidadFuncionalSelected.idTramo != null) {
          this.service.getTramo(unidadFuncionalSelected.idUnidadFuncional).subscribe((data: {}) => {
            this.lstTramo = [];
            this.lstTramo = data;
            this.tramiteAmbiental.tramoDTO = this.lstTramo.find(
              (t) => t.id === unidadFuncionalSelected.idTramo
            );
          }, (error) => {
            this.principalComponent.cargarErrorServicio(error);
          });
        }
      }
    }
  }

  /**
   * metodo que carga el modal de la unidad funcional
   */
  cargarUnidadFuncionalDatosDialog(unidadFuncionalSelected) {
    this.tramiteAmbiental.unidadFuncionalDTO = {};
    this.tramiteAmbiental.tramoDTO = {};
    if (this.solicitudUnidadFuncional === "onNew") {
      this.formUnidadesFuncionales.enable();
      this.loading = false;
    }
    if (this.solicitudUnidadFuncional === "onView") {
      this.formUnidadesFuncionales.disable();
      this.cargaDatosConsultaUF(unidadFuncionalSelected);
    }
    if (this.solicitudUnidadFuncional === "onEdit") {
      this.formUnidadesFuncionales.enable();
      this.cargaDatosConsultaUF(unidadFuncionalSelected);
      this.tempUnidadFuncionalSelected = unidadFuncionalSelected;
    }
  }

  /**
   * Metodo que cancela el diagolo de unidad funcional
   */
  cancelarDiagoloGuardadoUF() {
    this.dialogoUnidadesFuncionales = false;
    this.tempUnidadFuncionalSelected = null;
  }

  /**
   * Metodo que realiza la opcionde agregar la unidad funcional
   */
  btnAgregarUnidadFuncional() {
    this.dialogoUnidadFuncionalEliminar = false;
    this.dialogoUnidadesFuncionales = true;
    this.solicitudUnidadFuncional = "onNew";
    this.cargarUnidadFuncionalDatosDialog(null);
  }

  /**
   * metodo que muestra la consulta de la unidad funcional
   * @param unidadFuncionalSelected
   */
  btnConsultarUnidadFuncional(unidadFuncionalSelected: UnidadFuncionalDTO) {
    this.dialogoUnidadesFuncionales = true;
    this.solicitudUnidadFuncional = "onView";
    this.cargarUnidadFuncionalDatosDialog(unidadFuncionalSelected);
  }

  /**
  * metodo que muestra la consulta de la unidad funcional
  * @param unidadFuncionalSelected
  */
  btnEditarUnidadFuncional(unidadFuncionalSelected: UnidadFuncionalDTO) {
    this.dialogoUnidadesFuncionales = true;
    this.solicitudUnidadFuncional = "onEdit";
    this.cargarUnidadFuncionalDatosDialog(unidadFuncionalSelected);
  }

  /**
  * metodo que muestra la consulta de la unidad funcional
  * @param unidadFuncionalSelected
  */
  btnEliminarUnidadFuncional(unidadFuncionalSelected: UnidadFuncionalDTO) {
    this.dialogoUnidadFuncionalEliminar = true;
    this.tempUnidadFuncionalSelected = unidadFuncionalSelected;
  }

  /**
   * Metodo que elimina el registro
   */
  btnEliminarUnidad(isEliminar) {
    if (isEliminar) {
      if (this.tempUnidadFuncionalSelected != null) {
        this.unidadesFuncionalesAsociadasList = this.unidadesFuncionalesAsociadasList.filter(
          (obj) => obj !== this.tempUnidadFuncionalSelected
        );
      }
    }
    this.tempUnidadFuncionalSelected = null;
    this.dialogoUnidadFuncionalEliminar = false;
  }

  /**
  * metodo que devuelve la pagina
  */
  bntVolver() {
    const ruta = "aeropuertos/infGeneral/proyectoHome/" + this.idProyecto + "/tramites-ambientales-listado/" + this.idProyecto;
    this.router.navigate([ruta]);
  }

  /**
   * Metodo que realiza el guardado
   */
  guardarTramiteAmbiental() {
    this.dialogoJustificacionModificacion = false;
    if (this.tipoSolicitudTA === "onNew") {
      if (this.formTramiteAmbiental.valid) {
        this.submitGuardarTramiteAmbiental();
      } else {
        this.lanzarMensajeWarning(
          "Debe diligenciar los campos requeridos marcados con asterisco (*)"
        );
      }
    } else {
      if (this.tipoSolicitudTA === "onEdit") {
        this.dialogoJustificacionModificacion = true;
        this.tramiteAmbiental.observaciones = null;
        this.tramiteAmbiental.fechaActuacion = null;
      }
    }
  }

  /**
   * Metodo que guarda la justificacion de la modificacion
   */
  submitJustificacionModificacion() {
    if (this.formJustificacionModificacion.valid) {
      this.dialogoJustificacionModificacion = false;
      this.submitGuardarTramiteAmbiental();
    } else {
      this.lanzarMensajeWarning("Debe diligenciar los campos requeridos marcados con asterisco (*)");
    }
  }

  /**
   * Metodo que realiza el guardado
   */
  submitGuardarTramiteAmbiental() {
    this.loading = true;
    this.tramiteAmbiental.idProyecto = this.idProyecto;
    this.tramiteAmbiental.nombreProyecto = this.proyectoSeleccionado.nombre;
    this.tramiteAmbiental.idEntidad = this.tramiteAmbiental.entidadDTO.id;
    this.tramiteAmbiental.nombreEntidad = this.tramiteAmbiental.entidadDTO.nombre;
    this.tramiteAmbiental.idTipoTramite = this.tramiteAmbiental.tipoTramiteDTO.id;
    this.tramiteAmbiental.nombreTipoTramite = this.tramiteAmbiental.tipoTramiteDTO.nombre;
    this.tramiteAmbiental.idTramiteRelacionado = this.tramiteAmbiental.tramiteRelacionadoDTO.id;
    this.tramiteAmbiental.nombreTramiteRelacionado = this.tramiteAmbiental.tramiteRelacionadoDTO.nombre;
    this.tramiteAmbiental.idEtapaProyecto = this.tramiteAmbiental.etapaProyectoDTO.id;
    this.tramiteAmbiental.etapaProyecto = this.tramiteAmbiental.etapaProyectoDTO.nombre;
    this.tramiteAmbiental.idEstadoTramite = this.tramiteAmbiental.estadoDTO.id;
    this.tramiteAmbiental.estadoTramite = this.tramiteAmbiental.estadoDTO.nombre;
    this.tramiteAmbiental.idPriorizacion = this.tramiteAmbiental.priorizacionDTO.id;
    this.tramiteAmbiental.nombrePriorizacion = this.tramiteAmbiental.priorizacionDTO.nombre;
    this.tramiteAmbiental.idResponsable = this.tramiteAmbiental.entidadDTO.id;
    this.tramiteAmbiental.nombreResponsable = this.tramiteAmbiental.entidadDTO.nombre;
    this.tramiteAmbiental.unidadesFuncionales = [];
    if (this.tipoSolicitudTA === "onEdit") {
      this.tramiteAmbiental.observaciones = this.justificacionModificacion.observaciones;
      this.tramiteAmbiental.fechaActuacion = this.justificacionModificacion.fechaActuacion;
    }

    forkJoin(
      this.service.postGuardarEditarTramite(this.tramiteAmbiental)
    ).subscribe(
      ([resultData]) => {
        if (resultData != null && resultData.respuestaServicio.codigoSalida == 1) {
          this.lanzarMensajeInfo(resultData.respuestaServicio.mensajeSalida);
          const ruta = "aeropuertos/infGeneral/proyectoHome/" + this.idProyecto + "/tramites-ambientales-listado/" + this.idProyecto;
          this.router.navigate([ruta]);
          this.dialogoGuardarTramiteAmbiental = true;
        } else {
          this.lanzarMensajeError(resultData.respuestaServicio.mensajeSalida);
        }
        this.loading = false;
      },
      (e) => {
        this.loading = false;
      }
    );
  }

  /**
   * Metodo que controla el evento al momento de seleccionar un Tipo de tramite
   * @param tipoTramite
   */
  onSelectTipoTramite(tipoTramite) {
    if (tipoTramite != null && tipoTramite.value != null && tipoTramite.value.id != null) {
      this.tramiteAmbiental.idTipoTramite = tipoTramite.value.id;
      if (EnumAmbMaTipoTramite.TIPO_TRAMITE_CESION === tipoTramite.value.id || EnumAmbMaTipoTramite.TIPO_TRAMITE_CIERRE_ARCHIVO === tipoTramite.value.id
        || EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === tipoTramite.value.id || EnumAmbMaTipoTramite.TIPO_TRAMITE_MODIFICACION === tipoTramite.value.id) {
        this.habilitaTramitesRelacionados = false;
        this.tramiteAmbiental.tramiteRelacionadoDTO = {};
        this.habilitaPermisoTramiteRelacionado = false;
        this.habilitaOtroTramite = false;
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_DESISTIMIENTO === tipoTramite.value.id) {
          this.habilitaOtroTramite = true;
          this.tramiteAmbiental.tipoTramiteCual = null;
        }
      } else {
        if (EnumAmbMaTipoTramite.TIPO_TRAMITE_OTRO === tipoTramite.value.id) {
          this.habilitaOtroTramite = true;
          this.tramiteAmbiental.tipoTramiteCual = null;
        } else {
          this.habilitaOtroTramite = false;
          this.tramiteAmbiental.tipoTramiteCual = null;
        }
        this.tramiteAmbiental.tramiteRelacionadoDTO = {};
        this.habilitaPermisoTramiteRelacionado = false;
        this.habilitaTramitesRelacionados = true;
      }
    }
  }

  /**
  * Metodo que controla el evento al momento de seleccionar un Tramite relacionado
  * @param tramiteRelacionado
  */
  onSelectTramiteRelacionado(tramiteRelacionado) {
    if (tramiteRelacionado != null && tramiteRelacionado.value.id === EnumAmbMaTramiteRelacionado.TRAMITE_RELACIONADO_PERMISO) {
      this.habilitaPermisoTramiteRelacionado = true;
      this.tramiteAmbiental.tramiteRelacionadoCual = null;
    } else {
      this.habilitaPermisoTramiteRelacionado = false;
      this.tramiteAmbiental.tramiteRelacionadoCual = null;
    }
  }

  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success", summary: "Información", detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: "error", summary: "Error", detail: mensaje, });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: "warn", summary: "Advertencia", detail: mensaje, });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
