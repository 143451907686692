import { Injectable } from '@angular/core';
import { ConfigService } from '../../../../config.service';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../../../progress-spinner-dialog/progress-spinner-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class SeguimientoSeguimientoComunicacionesService {

  public END_POINT = '';

    // Http Options

    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    dialogSpin: MatDialogRef<ProgressSpinnerDialogComponent>;

    // Constructor de servicios

  constructor(private config: ConfigService, private http: HttpClient, public dialog: MatDialog) {
    this.END_POINT = environment.baseUrl;
  }

    // Servicios

  public guardarSeguimientoEstado(data) {
    return this.http.post(this.END_POINT + '/SolicitudEstado' , data)
      .pipe(
        retry(1));
  }

  public guardarSeguimientoRespuesta(data) {
    return this.http.post(this.END_POINT + '/SolicitudRespuesta' , data)
      .pipe(
        retry(1));
  }

  public getEstadoSeguimiento() {
    return this.http.get(this.END_POINT + '/SolicitudEstado/lista/estados')
      .pipe(
        retry(1));
  }

  public getTipoRespuesta() {
    return this.http.get(this.END_POINT + '/SolicitudRespuesta/lista/tipoRespuesta')
      .pipe(
        retry(1));
  }

  public obtenerListaSeguimiento(id) {
    return this.http.get(this.END_POINT + '/SolicitudComunicaciones/seguimiento/' + id )
      .pipe(
        retry(1));
  }

}
