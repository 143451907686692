import { Component, OnInit } from '@angular/core';
import { ProyectoLineaBaseComponent } from 'src/app/proyecto-linea-base/proyecto-linea-base.component';
import { ValidacionInformacionProyectoService } from './validacion-informacion-proyecto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UspCrrValidaInformacionProyectoDto } from './UspCrrValidaInformacionProyectoDto';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-validacion-informacion-proyecto',
  templateUrl: './validacion-informacion-proyecto.component.html',
  styleUrls: ['./validacion-informacion-proyecto.component.sass']
})
export class ValidacionInformacionProyectoComponent implements OnInit {


  visibleConfirmarPublicacion: boolean = false;
  visibleDialogoExitoPublicacionProyecto: boolean = false;

  constructor(private lineaBase: ProyectoLineaBaseComponent, private validacionInformacionProyectoService: ValidacionInformacionProyectoService, private route: ActivatedRoute, private ruta: Router,
    private messageService: MessageService, private principalComponent: PrincipalComponent) { }

  ngOnInit() {
    //Valida si el proyecto fue publicado
    this.validacionInformacionProyectoService.getDatosConfiguracionProyecto(this.lineaBase.idProyecto).subscribe((data: {}) => {
      this.lineaBase.crrDatosConfiguracionProyecto = data;
      if (this.lineaBase.crrDatosConfiguracionProyecto.publicado) {
        this.lineaBase.proyectoPublicado = true;
      } else {
        this.lineaBase.proyectoPublicado = false;
      }
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
    this.lineaBase.displayedColumns = [
      { field: 'tema', header: 'Tema' },
      { field: 'descripcion', header: 'Descripción' }
    ];
  }

  accionVisibleConfirmacionPublicar() {
    this.visibleConfirmarPublicacion = true;
  }

  accionCancelarVisibleConfirmacionPublicar() {
    this.visibleConfirmarPublicacion = false;
  }

  accionPublicarProyecto() {
    this.validacionInformacionProyectoService.publicarProyecto(this.lineaBase.idProyecto).subscribe((data: {}) => {
      this.visibleConfirmarPublicacion = false;
      this.visibleDialogoExitoPublicacionProyecto = true;
      this.lineaBase.proyectoPublicado = true;
    }, error => {
      console.error(error);
      this.principalComponent.cargarErrorServicio(error);
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error, life: 10000 });
    });
  }

  aceptarDialogoExitoPublicacion() {
    this.visibleDialogoExitoPublicacionProyecto = false;
  }
}
