import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CertificacionContratistaService {

  public END_POINT = '';

    // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor( private http: HttpClient) {
    this.END_POINT = environment.baseUrl;
  }

  /**
   * Metodo que retorna el tipo de documento.
   * @returns
   */
  public getTipoDocumento() {
    return this.http.get(this.END_POINT + '/ClbCertificadoContratista/lista/tipoDocumentos')
      .pipe(
        retry(1));
  }

    /**
   * Metodo que retorna la lista de contratos de un contratista.
   * @returns
   */
    public getlistaContratosPorContratista(tipoDocumento, numDocumento) {
      return this.http.get(this.END_POINT + '/ClbCertificadoContratista/listaContratos/'+tipoDocumento+'/'+numDocumento)
        .pipe(
          retry(1));
    }

   /**
   * Metodo que retorna el pdf del contratista.
   * @returns
   */
     public getGeneracionPdfContratista(idContrato) {
      return this.http.get(this.END_POINT + '/ClbCertificadoContratista/generacionPdfContratista/'+idContrato)
        .pipe(
          retry(1));
    }




}
