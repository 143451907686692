import { Injectable } from '@angular/core';
import { ConfigService } from '../../../config.service';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressSpinnerDialogComponent } from '../../../progress-spinner-dialog/progress-spinner-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class SeguimientoService {

  public END_POINT = '';

    // Http Options
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    dialogSpin:MatDialogRef<ProgressSpinnerDialogComponent>

  constructor(private config: ConfigService, private http: HttpClient, public dialog: MatDialog) {
    this.END_POINT = environment.baseUrl;
  }

  public getUnidades(id: number) {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/unidadFuncional/' + id)
      .pipe(
        retry(1));
  }


  public getCompromisoTipo() {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/tipo')
      .pipe(
        retry(1));
  }

  public getTramo(id: number) {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/subtramo/' + id)
      .pipe(
        retry(1));
  }

  public getEtapa() {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/etapa')
      .pipe(
        retry(1));
  }

  public guardarSeguientoEstado(data) {
    return this.http.post(this.END_POINT + '/tramiteAmbiental/seguimiento/estado' , data)
      .pipe(
        retry(1));
  }

  public guardarSeguientoPriorizacion(data) {
    return this.http.post(this.END_POINT + '/tramiteAmbiental/seguimiento/priorizacion' , data)
      .pipe(
        retry(1));
  }

  public guardarSeguientoResponsable(data) {
    return this.http.post(this.END_POINT + '/tramiteAmbiental/seguimiento/responsable' , data)
      .pipe(
        retry(1));
  }

  public guardarSeguientoObservacion(data) {
    return this.http.post(this.END_POINT + '/tramiteAmbiental/seguimiento/observacion' , data)
      .pipe(
        retry(1));
  }

  public guardarNuevoTramite(data) {
    return this.http.post(this.END_POINT + '/tramiteAmbiental/', data)
      .pipe(
        retry(1));
  }

  public getSubTramo() {
    return this.http.get(this.END_POINT + '/Gestion/subtipo')
      .pipe(
        retry(1));
  }

  public getResponsable() {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/responsable')
      .pipe(
        retry(1));
  }

  public getEntidad() {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/entidad')
      .pipe(
        retry(1));
  }

  public getPriorizacion() {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/priorizacion')
      .pipe(
        retry(1));
  }


  public getAllSubTramos() {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/subtramo')
      .pipe(
        retry(1));
  }

  public getEstadoCompromiso() {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/estado')
      .pipe(
        retry(1));
  }

  public getproyectoTramite(id: number) {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/' + id )
      .pipe(
        retry(1));
  }


public obtenerListaSeguimiento(id) {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/seguimiento/' + id )
      .pipe(
        retry(1));
  }

public eliminarTramiteAmbiental(id) {
    return this.http.delete(this.END_POINT + '/tramiteAmbiental/' + id )
      .pipe(
        retry(1));
  }

public getDepartamentos() {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/departamento')
      .pipe(
        retry(1));
 }

public getCiudadesPorDepartamento(idDepartamento) {
    return this.http.get(this.END_POINT + '/tramiteAmbiental/municipio/' + idDepartamento)
      .pipe(
        retry(1));
  }

}
