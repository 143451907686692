import { Component, OnInit } from "@angular/core";
import { PjePeajePorProyecto } from "../../trafico-peajes/PjePeajePorProyecto";
import { TarifasPeajesService } from "../tarifas-peajes.service";



@Component({
    selector: 'app-tarifas-peajes-reportes',
    templateUrl: './tarifas-peajes-reportes.component.html',
    styleUrls: ['./tarifas-peajes-reportes.component.sass']
})

export class TarifasPeajesReportesComponent implements OnInit {
    
    peajeSeleccionado: PjePeajePorProyecto;
    peajesPorProyecto: any[];
    blockedDocument = false;

    // variables para cargar lista de peajes
    filtroPeajes: any[];


    constructor(private tarifasPeajesService: TarifasPeajesService) { }

    ngOnInit() {
        console.log('init');
    }

    

    cargarPeajes(event) {
        this.blockedDocument = true;

        this.filtroPeajes = [];
        return this.tarifasPeajesService.cargarPeajesReportes().subscribe(
            result => {
                if (result != null && result.respuestaServicio != null) {
                    if (result.respuestaServicio.codigoSalida !== 0) {
                        this.peajesPorProyecto = result.peajes;
                        let existeFiltro: boolean;                        
                        for (let i = 0; i < this.peajesPorProyecto.length; i++) {
                            let filtroPeaje = this.peajesPorProyecto[i];
                            let item: string = String(filtroPeaje.peaje);                            
                            if (item.toLowerCase().includes(event.query.toLowerCase())) {
                                this.filtroPeajes.push(filtroPeaje);
                                existeFiltro = true;                                
                            }
                        }
                        if (existeFiltro) {                            
                            this.peajesPorProyecto = this.filtroPeajes;
                        }
                    } else {
                    }
                } else {
                }
            },
            error => {                
                this.blockedDocument = false;
            },
            () => {
                this.blockedDocument = false;
            }
        );
    }

    descargarArchivo(){
        this.tarifasPeajesService.verArchivoTarifas(this.peajeSeleccionado);
    }

}