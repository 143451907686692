import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';

import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { EjecucionDTO } from '../../ejecucion-proyecto.dto';
import { EjecucionProyectoService } from '../../ejecucion_proyecto.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-avance-proyecto-crear-ejecucion',
  templateUrl: './avance-proyecto-crear-ejecucion.component.html',
  styleUrls: ['./avance-proyecto-crear-ejecucion.component.sass']
})
export class AvanceProyectoCrearEjecucionComponent implements OnInit {

  @Input() proyectoSeleccionado: ProyectoDTO;
  @Input() minDateFechaReporte: Date;
  @Input() fechaUltimaEjecucionPublicada: Date;
  @Output() OutputCrearEjecucion = new EventEmitter();
  @Output() OutputIrAEjecuciones = new EventEmitter();

  ejecucionSeleccionada: EjecucionDTO;
  fechaReporte: Date;
  observaciones: string;

  constructor(private router: Router, private ruta: ActivatedRoute,
    private ejecucionProyectoService: EjecucionProyectoService,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.ejecucionSeleccionada = {};
  }

  guardarEjecucionSinDetalles() {
    if (this.fechaReporte != null) {
      //Guardar la ejecucion sin  detalles
      this.ejecucionSeleccionada.idProyecto = this.proyectoSeleccionado.id;
      this.ejecucionSeleccionada.fechaReporte = this.fechaReporte;
      this.ejecucionSeleccionada.observaciones = this.observaciones;
      if (this.ejecucionSeleccionada.observaciones == null) {
        this.ejecucionSeleccionada.observaciones = '';
      }
      this.ejecucionSeleccionada.publicado = false;
      this.ejecucionSeleccionada.usuarioActualizacion = 0;
      this.ejecucionProyectoService.crearEjecucion(this.ejecucionSeleccionada).subscribe((data) => {
        this.OutputCrearEjecucion.emit(data);
      }, error => {
        this.principalComponent.cargarErrorServicio(error);
      });
    } else {
      this.lanzarMensajeWarning("Debe seleccionar una fecha superior a la del ultimo reporte publicado o inferior a la fecha actual");
    }

  }

  irAEjecuciones() {
    this.OutputIrAEjecuciones.emit("");
  }

  outputSelectorFecha(_fechaConstruida) {
    this.fechaReporte = _fechaConstruida;
  }


  ///////////////////////////////Mensajes//////////////////////////////
  lanzarMensajeInfo(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'success', summary: 'Información', detail: _mensaje });
  }

  lanzarMensajeError(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'error', summary: 'Error', detail: _mensaje });
  }

  lanzarMensajeWarning(_mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: _mensaje });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }

}
