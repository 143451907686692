import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MenusService } from '../../menus.service';
import { environment } from '../../../environments/environment';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { MessageService } from 'primeng/api';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-ani-apps',
  templateUrl: './ani-apps.component.html',
  styleUrls: ['./ani-apps.component.sass']
})
export class AniAppsComponent implements OnInit {
  esProduccion: boolean = false;

  display: boolean = false;
  displayModal: boolean;

  private isAuthenticated: boolean;


  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private menusvc: MenusService,
    private adalSvc: MsAdalAngular6Service) {

  }


  ngOnInit() {
    this.esProduccion = environment.production;

    /* if (sessionStorage.getItem('paginas') == null) {
       this.menusvc.getMenus().subscribe((data: {}) => {
        sessionStorage.setItem('paginas', JSON.stringify(data));
        window.location.reload();
       });
     }*/
    //document.getElementById("nameseccion").innerHTML = "";
    //var element = document.getElementById("aniscopio_body");
    //element.classList.add("home");
    this.isAuthenticated = this.adalSvc.isAuthenticated;
    var element = document.getElementById("aniscopio_body");
    // carusel

  }

  showDialog() {
    this.display = true;
  }
  showModalDialog() {
    this.displayModal = true;
    var element = document.getElementById("aniscopio_body");
    element.classList.add("modalsuper");
  }
  noShowModalDialog() {
    this.displayModal = false;
    var element = document.getElementById("aniscopio_body");
    element.classList.remove("modalsuper");
  }
}
