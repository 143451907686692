import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProyectoDTO, VwCrrAvanceUfDTO, IntervencionDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { EjecucionDTO, EjecucionDetalleDTO, VwCrrAvanceIntervencionUfDepartamentoDTO, CambioConfiguracionProyectoDTO } from '../../ejecucion-proyecto.dto';
import { EjecucionProyectoService } from '../../ejecucion_proyecto.service';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';

@Component({
  selector: 'app-avance-proyecto-intervenciones',
  templateUrl: './avance-proyecto-intervenciones.component.html',
  styleUrls: ['./avance-proyecto-intervenciones.component.css']
})
export class AvanceProyectoIntervencionesComponent implements OnInit {

  @Input() proyectoSeleccionado: ProyectoDTO;
  @Input() unidadFuncionalSeleccionada: VwCrrAvanceUfDTO;
  @Input() ejecucionSeleccionada: EjecucionDTO;
  @Input() crrCambioConfiguracionProyecto: CambioConfiguracionProyectoDTO;

  @Output() OutputIrAUnidadesFuncionales = new EventEmitter();
  @Output() OutputGuardarEjecucion = new EventEmitter();

  @ViewChild('inputPorcentaje', { static: false }) inputPorcentaje: NgForm;
  @ViewChild('inputKilometros', { static: false }) inputKilometros: NgForm;

  //intervencionDTOList: IntervencionDTO[] = [];
  //intervencionDTOClonedList: { [s: string]: IntervencionDTO; } = {};

  ejecucionDetalleDTOList: EjecucionDetalleDTO[] = [];

  vwCrrAvanceIntervencionUfDeptoDTOList: VwCrrAvanceIntervencionUfDepartamentoDTO[];
  vwCrrAvanceIntervencionUfDeptoDTOClonedList: { [s: string]: VwCrrAvanceIntervencionUfDepartamentoDTO; } = {};

  porcentajeAvanceReportado: number;

  rowGroupMetadata: any;

  blockedDocument: boolean = false;

  constructor(private route: Router, private ruta: ActivatedRoute,
    private ejecucionProyectoService: EjecucionProyectoService,
    private principalComponent: PrincipalComponent,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    //Bloquear pantalla
    this.blockedDocument = true;

    this.obtenerAvanceIntervencionUfDepartamento();
  }

  irAUnidadesFuncionales() {
    this.OutputIrAUnidadesFuncionales.emit("");
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.vwCrrAvanceIntervencionUfDeptoDTOList) {
      for (let i = 0; i < this.vwCrrAvanceIntervencionUfDeptoDTOList.length; i++) {
        let rowData = this.vwCrrAvanceIntervencionUfDeptoDTOList[i];
        let brand = rowData.nombreIntervencionUf;
        if (i == 0) {
          this.rowGroupMetadata[brand] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.vwCrrAvanceIntervencionUfDeptoDTOList[i - 1];
          let previousRowGroup = previousRowData.nombreIntervencionUf;
          if (brand === previousRowGroup)
            this.rowGroupMetadata[brand].size++;
          else
            this.rowGroupMetadata[brand] = { index: i, size: 1 };
        }
      }
    }
  }

  imprimirAlgo(algo) {
  }

  onRowEditInit(_intervencionDTOSeleccionado: VwCrrAvanceIntervencionUfDepartamentoDTO) {
    this.vwCrrAvanceIntervencionUfDeptoDTOClonedList[_intervencionDTOSeleccionado.id] = { ..._intervencionDTOSeleccionado };
  }

  onRowEditSave(_intervencionDTOSeleccionado: VwCrrAvanceIntervencionUfDepartamentoDTO, index: number) {
    if (this.inputPorcentaje.errors || (this.inputKilometros != null && this.inputKilometros.errors)) {
      //this.lanzarMensajeError("Error en los datos ingresados");
    } else {
      delete this.vwCrrAvanceIntervencionUfDeptoDTOClonedList[_intervencionDTOSeleccionado.id];
      //this.lanzarMensajeInfo("Actualizado");
    }
  }

  onRowEditCancel(_intervencionDTOSeleccionado: VwCrrAvanceIntervencionUfDepartamentoDTO, index: number) {
    this.vwCrrAvanceIntervencionUfDeptoDTOList[index] = this.vwCrrAvanceIntervencionUfDeptoDTOClonedList[_intervencionDTOSeleccionado.id];
    delete this.vwCrrAvanceIntervencionUfDeptoDTOClonedList[_intervencionDTOSeleccionado.id];
  }

  guardarEjecucionIntervenciones() {
    //Bloquear pantalla
    this.blockedDocument = true;

    if (this.ejecucionSeleccionada.fechaReporte == null) {
      this.OutputGuardarEjecucion.emit({ codigo: 3, respuesta: "Debe ingresar una fecha de reporte" });
    } else {
      let faltaPorcentajeOKilometros = false;



      //Verificar si faltaPorcentajeOKilometros...
      this.vwCrrAvanceIntervencionUfDeptoDTOList.forEach(intervencionVar => {
        let noIngresoLongitud = intervencionVar.longitudConcesionada != null && intervencionVar.longitudConcesionada > 0 && intervencionVar.longitudConcesionada == null;
        let noIngresoPorcentaje = intervencionVar.porcentajeAvance == null;
        if (noIngresoLongitud || noIngresoPorcentaje) {
          faltaPorcentajeOKilometros = true;
        }
      });


      //Si faltaPorcentajeOKilometros...
      if (faltaPorcentajeOKilometros) {
        this.OutputGuardarEjecucion.emit({ codigo: 2, respuesta: "Debe registrar todas los registros de porcentaje o kilometros" });
      } else {

        this.ejecucionDetalleDTOList = [];

        //Convertir de VwCrrAvanceIntervencionUfDepartamentoDTO a EjecucionDetalle
        this.vwCrrAvanceIntervencionUfDeptoDTOList.forEach(intervencionVar => {
          var ejecucionDetalleDTO: EjecucionDetalleDTO = {};
          ejecucionDetalleDTO.id = intervencionVar.idEjecucionDetalle;
          ejecucionDetalleDTO.idIntervencionUfDepartamentoHst = intervencionVar.idIntervencionUfDepartamentoHst;
          ejecucionDetalleDTO.avanceLongitud = intervencionVar.avanceLongitud;
          ejecucionDetalleDTO.porcentajeAvance = intervencionVar.porcentajeAvance;
          this.ejecucionDetalleDTOList.push(ejecucionDetalleDTO);



          /**
          this.ejecucionDetalleDTOList.forEach(ejecucionDetalleVar => {
            if (intervencionVar.idIntervencionUf == ejecucionDetalleVar.idIntervencionUF) {
              ejecucionDetalleVar.avanceLongitud = intervencionVar.avanceLongitud;
              ejecucionDetalleVar.porcentajeAvance = intervencionVar.porcentajeAvance;
            }
          });
          **/
        });


        this.ejecucionSeleccionada.ejecucionDetalleDTOList = this.ejecucionDetalleDTOList;

        //Guardar los cambios en BD
        this.ejecucionProyectoService.guardarEjecucion(this.ejecucionSeleccionada).subscribe((data: {}) => {
          this.OutputGuardarEjecucion.emit({ codigo: 1, respuesta: "Las ejecución se guardó correctamente" });

          //Desbloquear pantalla
          this.blockedDocument = false;
        }, error => {
          this.principalComponent.cargarErrorServicio(error);
        });

      }
    }
  }

  renderedInputKilometros(longitud): boolean {
    let rendered = longitud != null && longitud > 0;
    return rendered
  }

  obtenerAvanceIntervencionUfDepartamento() {
    return this.ejecucionProyectoService.srvAvanceIntervencionUfDepartamento(this.unidadFuncionalSeleccionada.idUnidadFuncional, this.ejecucionSeleccionada.id).subscribe(data => {
      this.vwCrrAvanceIntervencionUfDeptoDTOList = data;
      this.updateRowGroupMetaData();
      this.porcentajeAvanceReportado = this.unidadFuncionalSeleccionada.porcentajeAvance;
      //Desbloquear pantalla
      this.blockedDocument = false;
    }, error => {
      this.principalComponent.cargarErrorServicio(error);
    });
  }



}
