import * as internal from "assert";

export class ProyectoEstructuracion
{
    constructor(

   public id: number,
   public nombre : string,
   public tipoModo: number,
   public ubicacion : string,
   public tipoIniciativa : string,
   public estado : string,
   public longitudOrigenDestino : number,
   public longitudConstruccionDobleCalzada : number,
   public longitudConstruccionSegundaCalzada : number,
   public longitudMejoramientoCalzadaExistente : number,
   public longitudPuestaPuntoCalzadaExistente : number,
   public longitudConstruccionViaNueva : number,
   public longitudRehabilitacionCalzadaExistente : number,
   public longitudConstruccionCalzadaSencilla : number,
   public longitudConstruccionTuneles: number,
   public longitudOperacionYMantenimiento: number,
   public ampliacion : string,
   public duracionPreConstruccion : number,
   public duracionConstruccion : number,
   public duracionConcesion : number,
   public capex : number,
   public opex : number,
   public valorCifras : string,
   public situacionActual : string,
   public generacionEmpleos : number,
   public descripcion : string,
   public imagen1 : string,
   public imagen2 : string,
   public municipiosBeneficiados : string,
   public alcance : string,
   public urlCuartoDatos : string,
   public urlSecop : string,
   public originador : string,
   public anchoCanalNavegable : string,
   public profundidad : string,
   public idOla:number,
   public nombreModo: string,
   public usuarioActualizacion : number,
   public fechaInicioSistema :Date,
   public fechaFinSistema : Date,
   public fechaUltimoReporte : Date
    )
{}
}