import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProyectoDTO } from 'src/app/componentes-comunes/componentes-comunes.dto';
import { EjecucionProyectoService } from '../../ejecucion_proyecto.service';
import { ComponentesComunesService } from 'src/app/componentes-comunes/componentes-comunes.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { VwAvanceProyectoDTO, EjecucionDTO } from '../../ejecucion-proyecto.dto';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-avance-proyecto-ejecuciones',
  templateUrl: './avance-proyecto-ejecuciones.component.html',
  styleUrls: ['./avance-proyecto-ejecuciones.component.sass']
})
export class AvanceProyectoEjecucionesComponent implements OnInit {

  @Input() proyectoSeleccionado: ProyectoDTO;

  @Input() idUltimaEjecucion: number;
  @Input() idUltimaEjecucionPublicada: number;

  @Input() fechaUltimaEjecucion: Date;
  @Input() fechaUltimaEjecucionPublicada: Date;

  @Input() porcentajeAvanceProyecto: number;
  @Input() porcentajeAvanceProyectoPublicado: number;

  @Output() OutputReportarEjecucion = new EventEmitter();
  @Output() OutputMinDateFechaReporte = new EventEmitter();
  @Output() OutputEjecucionSeleccionado = new EventEmitter();
  @Output() OutputReportarEtapas = new EventEmitter();
  @Output() OutputReportarEtapasProyecto = new EventEmitter();
  @Output() OutputYaCargoUnidadesFuncionales = new EventEmitter();
  @Output() OutputYaCargoEjecuciones = new EventEmitter();


  public ejecucionDTOList: EjecucionDTO[];

  constructor(private router: Router, private ruta: ActivatedRoute,
    private ejecucionProyectoService: EjecucionProyectoService,
    private componentesComunesService: ComponentesComunesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {

  }

  reportarEjecucion() {
    forkJoin(
      this.componentesComunesService.listarEjecucionesPorProyecto(this.proyectoSeleccionado.id)
    ).subscribe(([ejecucionesPorProyectoData]) => {
      this.ejecucionDTOList = ejecucionesPorProyectoData;
      let algunaSinPublicar = false;
      this.ejecucionDTOList.forEach(ejecucionVar => {
        if (!ejecucionVar.publicado) {
          algunaSinPublicar = true;
        }
      });
      if (algunaSinPublicar) {
        this.OutputReportarEjecucion.emit({ codigo: 2, respuesta: "Aun tiene ejecuciones reportadas sin publicar" });
      } else {
        this.OutputReportarEjecucion.emit({ codigo: 1, respuesta: "Es permitido reportar una nueva ejecucion" });
      }
    }, e => {
    });
  }

  reportarEtapas() {
    this.OutputReportarEtapasProyecto.emit({ codigo: 1, respuesta: "Es permitido reportar etapas proyecto", proyectoSeleccionado: this.proyectoSeleccionado });
  }

  outputReportarEtapas(unidadFuncionalSeleccionada) {
    this.OutputReportarEtapas.emit({ codigo: 1, respuesta: "Es permitido reportar etapas", uf: unidadFuncionalSeleccionada });
  }

  outputEjecucionSeleccionado(ejecucionSeleccionada) {
    this.OutputEjecucionSeleccionado.emit(ejecucionSeleccionada);
  }

  outputFechaEjecucionMasReciente(_fechaEjecucionMasReciente): void {
    this.OutputMinDateFechaReporte.emit(_fechaEjecucionMasReciente)
  }

  outputYaCargoUnidadesFuncionales(event){
    this.OutputYaCargoUnidadesFuncionales.emit("");
  }

  outputYaCargoEjecuciones(event){
    this.OutputYaCargoEjecuciones.emit("");
  }

}
