/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PrsFuncionario } from 'src/app/admin-personas/prs-funcionario';
import { ContratacionService } from 'src/app/contratacion/contratacion.service';
import { ParametricasService } from 'src/app/parametricas/parametricas.service';
import { TrnDependenciaAni, EnumTrnDependenciaAni } from 'src/app/parametricas/trn-dependencia-ani';
import { CtoContratoDTO } from '../../../../contratacion-dto/contrato';
import { ContratacionBorradorService } from '../../contratacion-brr.service';
import { CtoContratoSupervisorBorradorDTO } from '../../../../contratacion-dto/cto-contrato-supervisor-brr';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { ContratoBaseComponent } from '../contrato-base/contrato-base.component';


@Component({
  selector: 'app-contrato-supervisor-brr',
  templateUrl: './contrato-supervisor-brr.component.html'
})
export class ContratoSupervisorBrrComponent implements OnInit {

  @Input()
  contratoSupervisor: CtoContratoDTO;
  @Output()
  contratoSupervisorOutput = new EventEmitter<CtoContratoDTO>();

  supervisoresPorContrato: CtoContratoSupervisorBorradorDTO[];
  colsContratoSupervisor: any[];
  supervisorSeleccionado: PrsFuncionario;
  funcionariosSupervisores: PrsFuncionario[] = [];

  dependenciaPrimerNivelSeleccionada: TrnDependenciaAni;
  dependenciaSegundoNivelSeleccionada: TrnDependenciaAni;
  dependenciasAniPrimerNivel: TrnDependenciaAni[] = [];
  dependenciasAniSegundoNivel: TrnDependenciaAni[] = [];

  visibleDialogoConfirmarEliminarRegistro = false;
  visualizarDialogSupervisor = false;

  constructor(
    private lineaBase: ContratoBaseComponent,
    private messageService: MessageService,
    private contratacionBorradorService: ContratacionBorradorService,
    private contratacionService: ContratacionService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.colsContratoSupervisor = [
      { field: 'nombre', header: 'Supervisor' },
      { field: 'correoElectronico', header: 'Usuario ANI' },
      { field: 'dependencia', header: 'Dependencia de la supervisión' }
    ];

    if (this.contratoSupervisor != null) {
      this.cargarSupervisoresContrato();
    }
  }

  cargarDependenciasAniPrimerNivel(event) {
    const filtroTrnDependenciasAniPrimerNivel = [];
    this.parametricasService.ConsultarDependenciasAniPorTipo(EnumTrnDependenciaAni.TRN_DEPENDENCIA_ANI_PRESIDENCIA_TIPO + ',' + EnumTrnDependenciaAni.TRN_DEPENDENCIA_ANI_VICEPRESIDENCIA_TIPO + ',' + EnumTrnDependenciaAni.TRN_DEPENDENCIA_ANI_OFICINA_TIPO)
      .subscribe(data => {
        this.dependenciasAniPrimerNivel = data;
        if (event != null) {
          let existeFiltro: boolean;
          for (const dpn of this.dependenciasAniPrimerNivel) {
            if (String(dpn.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroTrnDependenciasAniPrimerNivel.push(dpn);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.dependenciasAniPrimerNivel = filtroTrnDependenciasAniPrimerNivel;
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarDependenciasAniSegundoNivel(event) {
    if (event == null) {
      this.dependenciasAniSegundoNivel = null;
      this.dependenciaSegundoNivelSeleccionada = null;
      this.funcionariosSupervisores = null;
      this.supervisorSeleccionado = null;
    }

    const filtroTrnDependenciasAniSegundoNivel = [];
    this.parametricasService.ConsultarDependenciasAniPorIdDependenciaPrimerNivel(this.dependenciaPrimerNivelSeleccionada.idDependenciaPrimerNivel)
      .subscribe(data => {
        this.dependenciasAniSegundoNivel = data;
        if (event != null) {
          let existeFiltro: boolean;
          for (const dpn of this.dependenciasAniSegundoNivel) {
            if (String(dpn.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroTrnDependenciasAniSegundoNivel.push(dpn);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.dependenciasAniSegundoNivel = filtroTrnDependenciasAniSegundoNivel;
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }


  cargarFuncionariosSupervisores(event) {
    const filtroFuncionariosSupervisores = [];
    this.contratacionService.ConsultarFuncionariosSupervisoresPorDependencia(this.dependenciaSegundoNivelSeleccionada.idDependenciaPrimerNivel, new Date())
      .subscribe(data => {
        this.funcionariosSupervisores = data;
        if (event != null) {
          let existeFiltro: boolean;
          for (const funSupervisor of this.funcionariosSupervisores) {
            if (String(funSupervisor.correoElectronico).toLowerCase().includes(event.query.toLowerCase()) || String(funSupervisor.nombre).toLowerCase().includes(event.query.toLowerCase())) {
              filtroFuncionariosSupervisores.push(funSupervisor);
              existeFiltro = true;
            }
          }
          if (existeFiltro) {
            this.funcionariosSupervisores = filtroFuncionariosSupervisores;
          }
        }
      },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
  }

  cargarSupervisoresContrato() {
    if (this.contratoSupervisor != null && this.contratoSupervisor.id != null) {
      this.contratacionBorradorService.ConsultarSupervisoresPorContratoBorrador(this.contratoSupervisor.id).subscribe(
        result => {
          this.supervisoresPorContrato = result;
          if (result != null && result.length > 0) {
            this.contratoSupervisor.tieneSupervisor = true;
          } else {
            this.contratoSupervisor.tieneSupervisor = false;
          }
          this.contratoSupervisorOutput.emit(this.contratoSupervisor);
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
        }
      );
    }
  }

  cancelarSupervisorContrato() {
    this.visualizarDialogSupervisor = false;
    this.supervisorSeleccionado = null;
    this.dependenciaPrimerNivelSeleccionada = null;
    this.dependenciaSegundoNivelSeleccionada = null;
  }

  validarAgregarSupervisor(): boolean {
    let validacionSupervisor = true;

    if (this.dependenciaPrimerNivelSeleccionada == null) {
      validacionSupervisor = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar la dependencia a la cual esta relacionado el supervisor', life: 10000 });
    }

    if (this.supervisorSeleccionado == null) {
      validacionSupervisor = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar un supervisor', life: 10000 });
    }

    if (this.supervisoresPorContrato != null) {
      this.supervisoresPorContrato.forEach(val => {
        if (this.supervisorSeleccionado.idPersona === val.idPersona) {
          validacionSupervisor = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El supervisor ya se encuentra relacionado al contrato', life: 10000 });
        }
      });
    }

    return validacionSupervisor;
  }

  guardarSupervisorContrato() {
    this.lineaBase.blockedDocument = true;
    this.visualizarDialogSupervisor = false;
    if (this.validarAgregarSupervisor()) {
      if (this.contratoSupervisor != null && this.supervisorSeleccionado != null && this.dependenciaSegundoNivelSeleccionada != null) {
        const supervisorContrato = {
          IdContrato: this.contratoSupervisor.id,
          IdPersona: this.supervisorSeleccionado.idPersona,
          IdDependencia: this.dependenciaSegundoNivelSeleccionada.id,
          EsBorrador: this.contratoSupervisor.esBorrador
        };

        this.contratacionBorradorService.GuardarContratoBorradorSupervisor(supervisorContrato)
          .subscribe(
            result => {
              if (result != null && result.respuestaServicio != null) {
                if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                  this.messageService.add({ severity: 'success', summary: 'Exito', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                } else {
                  console.error(result.respuestaServicio.mensajeSalida);
                  this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
                }
              } else {
                console.error('Ocurrió un error al consumir el servicio');
              }
            },
            error => {
              console.error(error);
              this.principalComponent.cargarErrorServicio(error);
            },
            () => {
              this.supervisorSeleccionado = null;
              this.dependenciaPrimerNivelSeleccionada = null;
              this.dependenciaSegundoNivelSeleccionada = null;
              this.cargarSupervisoresContrato();
              this.contratoSupervisorOutput.emit(this.contratoSupervisor);
              this.lineaBase.blockedDocument = false;
            }
          );
      }
    }
  }

  aceptarEliminarSupervisorContrato() {
    this.lineaBase.blockedDocument = true;
    this.visibleDialogoConfirmarEliminarRegistro = false;
    if (this.supervisorSeleccionado != null) {
      const supervisorContrato = {
        IdContrato: this.contratoSupervisor.id,
        IdPersona: this.supervisorSeleccionado.idPersona,
        EsBorrador: this.contratoSupervisor.esBorrador
      };
      this.contratacionBorradorService.EliminarContratoBorradorSupervisor(supervisorContrato).subscribe(
        () => {
          this.supervisorSeleccionado = null;
          this.dependenciaPrimerNivelSeleccionada = null;
          this.dependenciaSegundoNivelSeleccionada = null;
          this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'El supervisor se eliminó exitosamente', life: 10000 });
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ocurrió un error al eliminar el supervisor', life: 10000 });
        },
        () => {
          this.visibleDialogoConfirmarEliminarRegistro = false;
          this.lineaBase.blockedDocument = false;
          this.ngOnInit();
        }
      );
    }
  }

  // Eliminacion - Cancelar
  cancelarEliminarSupervisorContrato() {
    this.visibleDialogoConfirmarEliminarRegistro = false;
    this.supervisorSeleccionado = null;
  }

  irEliminarSupervisorContrato(supervisorContrato: CtoContratoSupervisorBorradorDTO) {
    this.supervisorSeleccionado = {
      idPersona: supervisorContrato.idPersona
    };
    this.visibleDialogoConfirmarEliminarRegistro = true;
  }
}
