import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ComisionesService } from "../comisiones.service";


@Component({
  selector: 'app-comisiones-home',
  templateUrl: './comisiones-home.component.html',
  styles: []
})
export class ComisionesHomeComponent implements OnInit {

  accesoDenegado = true;
  mensaje;
  visibleDialogoAccesoDenegado = false;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent,
    private comisionesService: ComisionesService) {
  }

  ngOnInit() {
    this.accesoDenegado = true;
    this.mensaje = "";

    this.validarAccesoComision();
    const element = document.getElementById("aniscopio_body");
    element.className = "ani public logeo contratacion";
  }


  validarAccesoComision() {
    this.comisionesService.srvValidarAccesoComision().subscribe(
      result => {
        if (result != null) {
          this.mensaje = result.respuestaServicio.mensajeSalida;
          if (result.respuestaServicio.codigoSalida !== EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.visibleDialogoAccesoDenegado = true;
          } else {
            this.accesoDenegado = false;
          }
        } else {
          console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
        }
      },
      error => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
      }
    );
  }

  aceptarAccesoDenegado() {
    this.visibleDialogoAccesoDenegado = false;
    this.mensaje = "";
  }

  irAMisServicios() {
    this.router.navigate(["/ani-apps"]);
    this.visibleDialogoAccesoDenegado = false;
  }

  irAComisionesConfiguracion() {
    this.router.navigate([
      this.router.url + "/comisiones-configuracion",
    ]);
  }

  irAComisionesVistobuenoConfiguracion() {
    this.router.navigate([
      this.router.url + "/comisiones-vistobueno-configuracion",
    ]);
  }

  irAComisionesFuncionarioConfiguracion() {
    this.router.navigate([
      this.router.url + "/comisiones-funcionario-configuracion",
    ]);
  }

  irAComisionesSolicitudes() {
    this.router.navigate([
      this.router.url + "/comisiones-solicitudes",
    ]);
  }

  irAComisionesLegalizaciones() {
    this.router.navigate([this.router.url + "/comisiones-legalizaciones"]);
  }

  irARevisarSolicitudes() {
    this.router.navigate([
      this.router.url + "/comisiones-revisar-solicitudes",
    ]);
  }
}
