import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  Renderer2,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { loadModules } from "esri-loader";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { MenusService } from "../../menus.service";
import { MsAdalAngular6Service } from "microsoft-adal-angular6";
import { DialogModule } from "primeng/dialog";
import { ReportesCarreteroService } from "src/app/reportes/carreteros/reportes-carreteros.service";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { MessageService } from "primeng/api";
//import { PublicAeropuertosConsultaFicha5gService } from "src/app/reportes/publicos/public-aeropuertos-consulta/public-aeropuertos-consulta-ficha5g/public-aeropuertos-consulta-ficha5g.service";
import {
  ConsolidadosProyectosDTO,
  ProyectosDTO,
} from "src/app/reportes/carreteros/reportes-carreteros.dto";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  esProduccion: boolean = false;
  private isAuthenticated: boolean;
  totalProyectosCarreteros: number = 0;
  totalProyectosAeropuertos: number = 0;
  totalProyectosPuertos: number = 0;
  totalProyectosFerreos: number = 0;
  informacionProyectos: ProyectosDTO[] = [];
  informacionProyectosFiltro: ProyectosDTO[] = [];

  ngOnInit() {
    this.esProduccion = environment.production;
    console.log("dato.proyectossa");
    // this.reportesCarreteroService.getConsolidadoProyectos().subscribe(
    //     (dato) => {
    //         if (dato != null && dato.respuestaServicio != null) {
    //             if (dato.respuestaServicio.codigoSalida === 1) {
    //                 let carreteros = dato.carreteros;
    //                 this.totalProyectosCarreteros = carreteros.length;

    //                 let aeropuertos = dato.aeropuertos;
    //                 this.totalProyectosAeropuertos = aeropuertos.length;

    //                 let puertos = dato.puertos;
    //                 this.totalProyectosPuertos = puertos.length;

    //                 let ferreo = dato.ferreo;
    //                 this.totalProyectosFerreos = ferreo.length;
    //             } else {
    //                 this.messageService.add({
    //                     severity: "error",
    //                     summary: "Error",
    //                     detail: dato.respuestaServicio.mensajeSalida,
    //                     life: 10000,
    //                 });
    //             }
    //         }
    //     },
    //     (error) => {
    //         console.error(error);
    //         this.principalComponent.cargarErrorServicio(error);
    //     },
    //     () => {}
    // );
    /* if (sessionStorage.getItem('paginas') == null) {
           this.menusvc.getMenus().subscribe((data: {}) => {
            sessionStorage.setItem('paginas', JSON.stringify(data));
            window.location.reload();
           });
         }*/
    //document.getElementById("nameseccion").innerHTML = "";
    //var element = document.getElementById("aniscopio_body");
    //element.classList.add("home");
    this.isAuthenticated = this.adalSvc.isAuthenticated;
    var element = document.getElementById("aniscopio_body");
    // carusel

    let script = this._renderer2.createElement("script");
    script.id = `deCarusel`;
    script.text = `deCarusel();`;

    this._renderer2.appendChild(this.document.body, script);

    loadModules([
      "esri/config",
      "esri/Map",
      "esri/views/SceneView",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/widgets/Search",
      "esri/widgets/Search/LayerSearchSource",
      "esri/layers/VectorTileLayer",
      "esri/symbols/SimpleLineSymbol",
      "esri/symbols/SimpleMarkerSymbol",
      "esri/renderers/SimpleRenderer",
      "esri/PopupTemplate",
      "esri/popup/content/CustomContent",
      //"esri/rest/support/Query",
      //"esri/rest/query",
      "esri/widgets/LayerList",
      "esri/widgets/DistanceMeasurement2D",
      "esri/widgets/AreaMeasurement2D",
      "esri/widgets/Print",
      "esri/widgets/Zoom",
      "esri/core/watchUtils",
      "esri/widgets/Locate",
      "esri/widgets/Expand",
      "esri/widgets/Legend",
    ])
      .then(
        ([
          esriConfig,
          Map,
          SceneView,
          MapView,
          FeatureLayer,
          Search,
          LayerSearchSource,
          VectorTileLayer,
          SimpleMarkerSymbol,
          SimpleLineSymbol,
          SimpleRenderer,
          PopupTemplate,
          CustomContent,
          LayerList,
          DistanceMeasurement2D,
          AreaMeasurement2D,
          Print,
          Zoom,
          watchUtils,
          Locate,
          Expand,
          Legend,
        ]) => {
          let stats = {};
          var activeWidget = null;
          esriConfig.apiKey =
            "AAPK2456e7e0539844b39d13266e742a95cbYV9nTVDpArBvqC_Y21vE-ZcWrZCNAXTgi3ZGBtO11pzgJMdZwMq9FmQyq_78eY8I";

          const map = new Map();

          const view = new MapView({
            container: "viewDiv",
            map: map,
            center: [-76, 5], // lon, lat
            zoom: 5.8,
          });

          view.constraints = {
            minZoom: 5,
            maxZoom: 19,
          };

          view.ui.move(["zoom"], "bottom-right");
          /*const map = new Map({
                              basemap: "arcgis-light-gray",
                              layers: [vtlLayer]
                          });*/

          const ferroRenderer = {
            type: "simple",
            symbol: {
              color: "#58248e",
              type: "simple-line",
              style: "short-dot",
              width: 2,
            },
          };

          const citiesRenderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              url: "assets/img/newhome/alertas/ic_p.png",
              width: "25px",
              height: "30px",
            },
          };

          let symbol = {
            type: "picture-marker", // autocasts as new PictureMarkerSymbol()
            url: "https://static.arcgis.com/images/Symbols/Shapes/BlackStarLargeB.png",
            width: "64px",
            height: "64px",
          };

          const aeroRenderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              url: "assets/img/newhome/ic_map/ic_a2.png",
              width: "25px",
              height: "30px",
            },
          };

          const puertoRenderer = {
            type: "simple", // autocasts as new SimpleRenderer()
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              url: "assets/img/newhome/ic_map/ic_f2.png",
              width: "25px",
              height: "30px",
            },
          };

          const renderer = new SimpleRenderer({
            symbol: new SimpleMarkerSymbol({
              width: 2,
              color: "#fb12ff",
            }),
          });

          const carreterasRender = {
            type: "simple",
            symbol: {
              color: [252, 88, 47, 1],
              type: "simple-line",
              style: "solid",
              width: 2,
              join: "round",
            },
          };

          const redprimaria = {
            type: "simple",
            symbol: {
              type: "picture-marker", // autocasts as new PictureMarkerSymbol()
              url: "assets/img/newhome/ic_sig/POSTES-SOS.png",
              width: "30px",
              height: "30px",
            },
          };

          const redterciaria = {
            type: "simple",
            symbol: {
              color: "#000000",
              type: "simple-line",
              style: "solid",
              width: 0.5,
              join: "round",
            },
          };

          const otherSym1 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: "#000000",
            width: 2,
            style: "solid",
          };

          const UF1 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [249, 196, 48, 0.89], //rgb(237, 81, 81, 0.79)//invias
            width: 1.5,
            style: "solid",
            trans: 0.1,
          };

          const UF2 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [252, 88, 47, 0.79], //rgb(197 4 204 / 40%);//ani
            width: 1.5,
            style: "solid",
            trans: 0.5,
            Size: 2,
          };
          const UF3 = {
            type: "simple-line", // autocasts as new SimpleLineSymbol()
            color: [167, 198, 54, 0.79], //rgb(167 198 54)
            width: 1.5,
            style: "solid",
            trans: 0.1,
            Size: 2,
          };

          const redvial = {
            type: "unique-value", // autocasts as new UniqueValueRenderer()
            opacity: 1,
            Size: 2,
            defaultSymbol: otherSym1,
            field: "administrador",
            uniqueValueInfos: [
              {
                value: 1,
                symbol: UF1,
              },
              {
                value: 2,
                symbol: UF2,
              },
              {
                value: 3,
                symbol: UF3,
              },
            ],
          };

          const vtlLayer = new VectorTileLayer({
            url: "https://basemaps-api.arcgis.com/arcgis/rest/services/styles/a7f885034a5e4c33b92504bf4f177e93?type=style&token=AAPK2456e7e0539844b39d13266e742a95cbYV9nTVDpArBvqC_Y21vE-ZcWrZCNAXTgi3ZGBtO11pzgJMdZwMq9FmQyq_78eY8I",
            title: "mapabase",
          });

          const featureLayerCarreteros = new FeatureLayer({
            url: "https://aniscopiosig-server.ani.gov.co/arcgisserver/rest/services/Hosted/ModosANI/FeatureServer/3",
            outFields: ["*"],
            renderer: carreterasRender,
            definitionExpression:
              "etp IN ('Construcción','Operación','Pre-construcción','Estructuración')",

            popupTemplate: {
              // autocasts as new PopupTemplate()
              type: "fields",
              title: "{nombrepr}",
              content:
                "<b>Generación:</b> {gen}<br/> <a class='btn-detalle' href='https://aniscopio.ani.gov.co/reportes/carreteros/proyectoDetalle/{idpr}'>Detalle</a>",
              overwriteActions: false,
              includeDefaultSources: false,
            },
          });

          const featureLayerAeropuertos = new FeatureLayer({
            url: "https://aniscopiosig-server.ani.gov.co/arcgisserver/rest/services/Hosted/ModosANI/FeatureServer/1",
            outFields: ["*"],
            renderer: aeroRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              type: "fields",
              title: "{LISTA_AERO}",
              content:
                "<b>Proyecto:</b> {PROYECTO}<br/><b>Contrato:</b> {CONTRATO} <br/> <b>Concesionario:</b><br/> {CCNARIO} <br/> <a class='btn-detalle' href='https://aniscopio.ani.gov.co/aeropuertos-public/ficha-proyecto-drupal'>Detalle</a>",
              overwriteActions: false,
              includeDefaultSources: false,
            },
          });

          const featureLayerpuertos = new FeatureLayer({
            url: "https://aniscopiosig-server.ani.gov.co/arcgisserver/rest/services/Hosted/ModosANI/FeatureServer/0",
            outFields: ["*"],
            renderer: puertoRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              type: "fields",
              title: "{NOMBRE}",
              content:
                "<b>Zona Portuaria:</b> {ZONA_PORT}<br/> <b>Concesionario:</b><br/>{CCNARIO} <br/> <a class='btn-detalle' href='https://aniscopio.ani.gov.co/puertos-public/ficha-proyecto'>Detalle</a>",
              overwriteActions: false,
              includeDefaultSources: false,
            },
          });

          const featureLayerFerro = new FeatureLayer({
            url: "https://aniscopiosig-server.ani.gov.co/arcgisserver/rest/services/Hosted/ModosANI/FeatureServer/2",
            outFields: ["*"],
            renderer: ferroRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              type: "fields",
              title: "{CONCESION}",
              content:
                "<b>Concesionario:</b><br/>{CCNARIO} <br/> <a class='btn-detalle' href='https://aniscopio.ani.gov.co/ferrocarriles-public/ficha-proyecto-drupal'>Detalle</a>",
              overwriteActions: false,
              includeDefaultSources: false,
            },
          });

          const labelClass2 = {
            // autocasts as new LabelClass()
            symbol: {
              type: "text", // autocasts as new TextSymbol()
              color: "#11528a",
              haloColor: "#ffffff",
              haloSize: 1,
              font: {
                size: 11,
                weight: "bold",
              },
            },
            labelPlacement: "center-along",
            labelExpressionInfo: {
              expression: "$feature.categoria",
            },
          };

          // const featureLayerRedPrimaria = new FeatureLayer({
          //   url: "https://hermes2.invias.gov.co/server/rest/services/MapaCarreteras/PosteReferencia/MapServer/0",
          //   outFields: ["*"],
          //   renderer: redprimaria,
          //   popupTemplate: {
          //     // autocasts as new PopupTemplate()
          //     type: "fields",
          //     title: "{postereferencia}",
          //     content: "<b>Poste de referencia<br/>",
          //     overwriteActions: false,
          //     includeDefaultSources: false,
          //   },
          // });

          // const featureLayerRedTerciariaDep = new FeatureLayer({
          //   url: "https://hermes2.invias.gov.co/server/rest/services/MapaCarreteras/RedVial/FeatureServer/1",
          //   outFields: ["*"],
          //   renderer: redvial,
          //   labelingInfo: [
          //     {
          //       maxScale: 1000,
          //       minScale: 25000000,
          //       // symbol: {
          //       //   type: "text", // autocasts as new TextSymbol()
          //       //   color: "#11528a",
          //       //   haloColor: "#ffffff",
          //       //   haloSize: 1,
          //       //   font: {
          //       //     size: 11,
          //       //     weight: "bold",
          //       //   },
          //       // },
          //       // labelPlacement: "center-along",
          //       // labelExpressionInfo: {
          //       //   expression: "$feature.categoria",
          //       // },
          //     },
          //   ],
          // });

          const featureLayerPeajes = new FeatureLayer({
            url: "https://services8.arcgis.com/qXzQm8c8qEBxS81s/ArcGIS/rest/services/Peajes_Reportes/FeatureServer/0",
            renderer: citiesRenderer,
            popupTemplate: {
              // autocasts as new PopupTemplate()
              title: "PEAJE {Generacion}",
              content:
                "<div class='row poppeaje'><div class='col-md-4'><img src='https://ani.kol3.com/waze/alertas/ic_p.png'/></div><div class='col-md-8'><div class='titlepop'>{NOMBRE}</div></br>Estado: {Estado}</div></div>",
              overwriteActions: true,
              returnGeometry: true,
            },
            //definitionExpression: "Idpr = '" + this.idProyecto + "'",
          });

          // const legend = new Legend({
          //   view: view,
          //   hideLayersNotInCurrentView: true,
          //   respectLayerVisibility: false,
          //   layerInfos: [
          //     {
          //       layer: featureLayerRedTerciariaDep,
          //       title: "Red Vial",
          //     },
          //   ],
          // });
          // view.ui.add(legend, "bottom-right");

          map.add(vtlLayer);

          // map.add(featureLayerRedPrimaria);
          // map.add(featureLayerRedTerciariaDep);

          map.add(featureLayerCarreteros);
          map.add(featureLayerFerro);
          //map.add(featureLayerPeajes);
          map.add(featureLayerpuertos);

          map.add(featureLayerAeropuertos);

          const searchWidget = new Search({
            view: view,
            allPlaceholder: "Buscador ANISCOPIO",
            includeDefaultSources: false,
            sources: [
              {
                name: "General",
                placeholder: "Colombia",
                singleLineFieldName: "SingleLine",
                url: "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer",
                countryCode: "CO",
              },
              {
                layer: featureLayerCarreteros,
                searchFields: ["nombrePr"],
                displayField: "nombrePr",
                exactMatch: false,
                outFields: ["*"],
                name: "Búsqueda Carreteros",
                placeholder: "Ejemplo: Autopista",
              },
              {
                layer: featureLayerAeropuertos,
                searchFields: ["lista_aero", "proyecto"],
                suggestionTemplate: "{lista_aero}, PROYECTO: {proyecto}",
                exactMatch: false,
                outFields: ["*"],
                placeholder: "Ejemplo: Opain",
                name: "Búsqueda Aeropuertos",
                zoomScale: 500000,
              },
              {
                layer: featureLayerpuertos,
                searchFields: ["NOMBRE", "zona_port"],
                suggestionTemplate: "{nombre}, Concesionario: {zona_port}",
                exactMatch: false,
                outFields: ["*"],
                placeholder: "Ejemplo: Atlántica",
                name: "Búsqueda Puertos",
              },
              {
                layer: featureLayerFerro,
                searchFields: ["CONCESION", "ccnario"],
                displayField: "CONCESION",
                suggestionTemplate: "{concesion}, Concesionario: {ccnario}",
                exactMatch: false,
                outFields: ["*"],
                name: "Búsqueda Férreo",
                placeholder: "Ejemplo: Férreo",
              },
            ],
          });

          view.ui.add(searchWidget, {
            position: "top-right",
            index: 2,
          });

          const listNode = document.getElementById("nyc_graphics");
          const listNode2 = document.getElementById("nyc_graphics2");
          const listNode3 = document.getElementById("nyc_graphics3");
          const listNode4 = document.getElementById("nyc_graphics4");

          let graphics;
          let graphics2;
          let graphics3;
          let graphics4;

          featureLayerAeropuertos
            .queryFeatures({
              outFields: ["*"],
              returnGeometry: true,
              orderByFields: ["lista_aero ASC"],
              where: ["1=1"],
            })
            .then(function (results) {
              let todo1 = results.features;
              //console.log('demo');
              console.log(todo1);
              graphics = results.features;
              const fragment = document.createDocumentFragment();
              console.log("aero: " + graphics.length);
              const num_a = document.getElementById("num_a");
              num_a.innerHTML = graphics.length;
              graphics.forEach(function (result, index) {
                const attributes = result.attributes;
                //console.log(result.attributes);
                const name = attributes.lista_aero;

                // Create a list zip codes in NY
                const li = document.createElement("li");
                li.classList.add("panel-result");
                li.tabIndex = 0;
                li.setAttribute("data-result-id-1", index);
                li.textContent = name;

                fragment.appendChild(li);
              });
              // Empty the current list
              listNode.innerHTML = "";
              listNode.appendChild(fragment);
            });

          featureLayerCarreteros
            .queryFeatures({
              outFields: ["*"],
              returnGeometry: true,
              orderByFields: ["nombrepr ASC"],
              where: ["1=1"],
            })
            .then(function (results) {
              graphics2 = results.features;
              const fragment = document.createDocumentFragment();
              const num_a = document.getElementById("num_c");
              num_a.innerHTML = graphics2.length;

              graphics2.forEach(function (result, index) {
                const attributes = result.attributes;
                const name = attributes.nombrepr;

                // Create a list zip codes in NY
                const li = document.createElement("li");
                li.classList.add("panel-result");
                li.tabIndex = 0;
                li.setAttribute("data-result-id-2", index);
                li.textContent = name;

                fragment.appendChild(li);
              });
              // Empty the current list
              listNode2.innerHTML = "";
              listNode2.appendChild(fragment);
            });

          featureLayerpuertos
            .queryFeatures({
              outFields: ["*"],
              returnGeometry: true,
              orderByFields: ["nombre ASC"],
              where: ["1=1"],
            })
            .then(function (results) {
              graphics3 = results.features;

              const fragment = document.createDocumentFragment();
              const num_p = document.getElementById("num_p");
              num_p.innerHTML = graphics3.length;

              graphics3.forEach(function (result, index) {
                const attributes = result.attributes;
                const name = attributes.nombre;

                // Create a list zip codes in NY
                const li = document.createElement("li");
                li.classList.add("panel-result");
                li.tabIndex = 0;
                li.setAttribute("data-result-id-3", index);
                li.textContent = name;

                fragment.appendChild(li);
              });
              // Empty the current list
              listNode3.innerHTML = "";
              listNode3.appendChild(fragment);
            });

          featureLayerFerro
            .queryFeatures({
              outFields: ["*"],
              returnGeometry: true,
              orderByFields: ["concesion ASC"],
              where: ["1=1"],
            })
            .then(function (results) {
              graphics4 = results.features;

              const fragment = document.createDocumentFragment();
              const num_a = document.getElementById("num_f");
              num_a.innerHTML = graphics4.length;

              graphics4.forEach(function (result, index) {
                const attributes = result.attributes;
                const name = attributes.concesion;

                // Create a list zip codes in NY
                const li = document.createElement("li");
                li.classList.add("panel-result");
                li.tabIndex = 0;
                li.setAttribute("data-result-id-4", index);
                li.textContent = name;

                fragment.appendChild(li);
              });
              // Empty the current list
              listNode4.innerHTML = "";
              listNode4.appendChild(fragment);
            });

          function onListClickHandler1(event) {
            const target = event.target;
            const resultId = target.getAttribute("data-result-id-1");

            // get the graphic corresponding to the clicked zip code
            const result =
              resultId && graphics && graphics[parseInt(resultId, 10)];

            if (result) {
              // open the popup at the centroid of zip code polygon
              // and set the popup's features which will populate popup content and title.
              console.log(result.geometry.extent);
              view
                .goTo(result.geometry.extent.expand(2))
                .then(function () {
                  view.popup.open({
                    features: [result],
                    location: result.geometry.centroid,
                  });
                })
                .catch(function (error) {
                  if (error.name != "AbortError") {
                    console.error(error);
                  }
                });
            }
          }

          function onListClickHandler2(event) {
            const target = event.target;
            const resultId2 = target.getAttribute("data-result-id-2");

            // get the graphic corresponding to the clicked zip code
            const result =
              resultId2 && graphics2 && graphics2[parseInt(resultId2, 10)];

            if (result) {
              // open the popup at the centroid of zip code polygon
              // and set the popup's features which will populate popup content and title.

              view
                .goTo(result.geometry.extent.expand(2))
                .then(function () {
                  view.popup.open({
                    features: [result],
                    location: result.geometry.centroid,
                  });
                })
                .catch(function (error) {
                  if (error.name != "AbortError") {
                    console.error(error);
                  }
                });
            }
          }

          function onListClickHandler3(event) {
            const target = event.target;
            const resultId3 = target.getAttribute("data-result-id-3");

            // get the graphic corresponding to the clicked zip code
            const result =
              resultId3 && graphics3 && graphics3[parseInt(resultId3, 10)];

            if (result) {
              // open the popup at the centroid of zip code polygon
              // and set the popup's features which will populate popup content and title.
              console.log(result.geometry);
              view
                .goTo(result.geometry.extent.expand(2))
                .then(function () {
                  view.popup.open({
                    features: [result],
                    location: result.geometry.centroid,
                  });
                })
                .catch(function (error) {
                  if (error.name != "AbortError") {
                    console.error(error);
                  }
                });
            }
          }

          function onListClickHandler4(event) {
            const target = event.target;
            const resultId4 = target.getAttribute("data-result-id-4");

            // get the graphic corresponding to the clicked zip code
            const result =
              resultId4 && graphics4 && graphics4[parseInt(resultId4, 10)];

            if (result) {
              // open the popup at the centroid of zip code polygon
              // and set the popup's features which will populate popup content and title.

              view
                .goTo(result.geometry.extent.expand(2))
                .then(function () {
                  view.popup.open({
                    features: [result],
                    location: result.geometry.centroid,
                  });
                })
                .catch(function (error) {
                  if (error.name != "AbortError") {
                    console.error(error);
                  }
                });
            }
          }

          // listen to click event on the zip code list

          listNode.addEventListener("click", onListClickHandler1);
          listNode2.addEventListener("click", onListClickHandler2);
          listNode3.addEventListener("click", onListClickHandler3);
          listNode4.addEventListener("click", onListClickHandler4);
          // Adds widget below other elements in the top left corner of the view

          view.ui.add("topbar", "top-right");

          const locate = new Locate({
            view: view,
            useHeadingEnabled: false,
            goToOverride: function (view, options) {
              options.target.scale = 8500;
              return view.goTo(options.target);
            },
          });
          view.ui.add(locate, "top-right");

          view.ui.add(
            [
              new Expand({
                content: new LayerList({
                  view: view,
                }),
                view: view,
                group: "top-right",
              }),
            ],
            "top-right"
          );

          document
            .getElementById("printerButton")
            .addEventListener("click", function () {
              setActiveWidget(null);
              if (!this.classList.contains("active")) {
                setActiveWidget("print");
              } else {
                setActiveButton(null);
              }
            });

          document
            .getElementById("distanceButton")
            .addEventListener("click", function () {
              setActiveWidget(null);
              if (!this.classList.contains("active")) {
                setActiveWidget("distance");
              } else {
                setActiveButton(null);
              }
            });

          document
            .getElementById("areaButton")
            .addEventListener("click", function () {
              setActiveWidget(null);
              if (!this.classList.contains("active")) {
                setActiveWidget("area");
              } else {
                setActiveButton(null);
              }
            });

          function setActiveWidget(type) {
            switch (type) {
              case "distance":
                activeWidget = new DistanceMeasurement2D({
                  view: view,
                });

                // skip the initial 'new measurement' button
                activeWidget.viewModel.start();

                view.ui.add(activeWidget, "top-right");
                setActiveButton(document.getElementById("distanceButton"));
                break;
              case "area":
                activeWidget = new AreaMeasurement2D({
                  view: view,
                });

                // skip the initial 'new measurement' button
                activeWidget.viewModel.start();

                view.ui.add(activeWidget, "top-right");
                setActiveButton(document.getElementById("areaButton"));
                break;
              case "print":
                activeWidget = new Print({
                  view: view,
                  printServiceUrl:
                    "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
                  templateOptions: {
                    title: "ANISCOPIO",
                    author: "ANI",
                    copyright: "ANI",
                    legendEnabled: false,
                  },
                });

                // skip the initial 'new measurement' button
                //activeWidget.viewModel.start();

                view.ui.add(activeWidget, "top-right");
                setActiveButton(document.getElementById("printerButton"));

                break;
              case null:
                if (activeWidget) {
                  view.ui.remove(activeWidget);
                  activeWidget.destroy();
                  activeWidget = null;
                }
                break;
            }
          }

          function setActiveButton(selectedButton) {
            // focus the view to activate keyboard shortcuts for sketching
            view.focus();
            var elements = document.getElementsByClassName("active");
            for (var i = 0; i < elements.length; i++) {
              elements[i].classList.remove("active");
            }
            if (selectedButton) {
              selectedButton.classList.add("active");
            }
          }

          //1
          document.getElementById("m_aero").onclick = function () {
            var element = document.getElementById("contenido-panel");
            element.classList.add("hide");

            var element = document.getElementById("listaero");
            element.classList.add("see");
          };

          document.getElementById("m_aero_close").onclick = function () {
            var element = document.getElementById("contenido-panel");
            element.classList.remove("hide");

            var element = document.getElementById("listaero");
            element.classList.remove("see");
          };
          //2
          document.getElementById("m_carre").onclick = function () {
            var element = document.getElementById("contenido-panel");
            element.classList.add("hide");

            var element = document.getElementById("listcarre");
            element.classList.add("see");
          };

          document.getElementById("m_carre_close").onclick = function () {
            var element = document.getElementById("contenido-panel");
            element.classList.remove("hide");

            var element = document.getElementById("listcarre");
            element.classList.remove("see");
          };
          //3
          document.getElementById("m_puer").onclick = function () {
            var element = document.getElementById("contenido-panel");
            element.classList.add("hide");

            var element = document.getElementById("listpuer");
            element.classList.add("see");
          };

          document.getElementById("m_puer_close").onclick = function () {
            var element = document.getElementById("contenido-panel");
            element.classList.remove("hide");

            var element = document.getElementById("listpuer");
            element.classList.remove("see");
          };
          //4
          document.getElementById("m_ferre").onclick = function () {
            var element = document.getElementById("contenido-panel");
            element.classList.add("hide");

            var element = document.getElementById("listaferro");
            element.classList.add("see");
          };

          document.getElementById("m_ferre_close").onclick = function () {
            var element = document.getElementById("contenido-panel");
            element.classList.remove("hide");

            var element = document.getElementById("listaferro");
            element.classList.remove("see");
          };

          document.getElementById("viewDiv").onclick = function () {
            var element = document.getElementById("contenido-panel");
            element.classList.remove("hide");

            var panels = document.getElementsByClassName("super-panel");
            for (var i = 0, all = panels.length; i < all; i++) {
              panels[i].classList.remove("see");
            }
          };

          watchUtils.whenTrue(view, "updating", (updating) => {
            console.log("whenTrue: ", updating);
            const load = document.getElementById("load55");
            load.classList.remove("none");
            //if (!mapView.interacting) setMapLoading(true); // turn off loading spinner
          });

          watchUtils.whenFalse(view, "updating", (updating) => {
            console.log("whenFalse: ", updating);
            const load = document.getElementById("load55");
            load.classList.add("none");
            //setMapLoading(false); // turn off loading spinner
          });
        }
      )
      .catch((err) => {
        // handle any errors
        console.error(err);
      });
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private route: Router,
    private menusvc: MenusService,
    private adalSvc: MsAdalAngular6Service,
    private _renderer2: Renderer2,
    private messageService: MessageService,
    private reportesCarreteroService: ReportesCarreteroService,
    private principalComponent: PrincipalComponent //private publicAeropuertosConsultaFicha5gService: PublicAeropuertosConsultaFicha5gService
  ) {}

  irAMapas(): void {
    this.document.location = "https://sig.ani.gov.co/mapasinterno/";
  }

  onNavigate(link) {
    if (!this.esProduccion) {
      window.open(link, "_blank");
    }
  }
  menulateral() {
    var element = document.getElementById("sideAni");
    element.classList.toggle("expandend");
  }
  seguimientoProyectos() {
    var usuario = localStorage.getItem("nomUsu");
    if (usuario == null) {
      localStorage.setItem("backProj", "1");
    }
    this.route.navigate(["/infGeneral"]);
  }

  transversales() {
    var usuario = localStorage.getItem("nomUsu");
    if (usuario == null) {
      localStorage.setItem("backProj", "2");
    }
    this.route.navigate(["/cargue"]);
  }

  irACarretero() {
    if (this.isAuthenticated) {
      sessionStorage.setItem("tipoProy", "2");
      this.route.navigate(["/carreteros"]);
    } else {
      this.route.navigate(["/carreteras-public"]);
    }
  }

  irAAeropuertos() {
    sessionStorage.setItem("tipoProy", "1");
    console.log("aeropuertos1");
    this.route.navigate(["/aeropuertos"]);
  }
  irAPuertos() {
    sessionStorage.setItem("tipoProy", "5");
    this.route.navigate(["/puertos"]);
  }
  irAFerrocarriles() {
    sessionStorage.setItem("tipoProy", "4");
    this.route.navigate(["/ferrocarriles"]);
  }

  //new
  reportes_c() {
    this.route.navigate(["carreteras-public/consulta"]);
  }
  irAMapas_c() {
    this.route.navigate(["carreteras-public/mapa"]);
  }
  irVideoAvance_c() {
    this.route.navigate(["youtube/public-home-principal"]);
  }
  reportes_c_l() {
    var usuario = localStorage.getItem("nomUsu");
    if (usuario == null) {
      localStorage.setItem("backProj", "3");
    }
    this.route.navigate(["reportes/carreteros"]);
  }
  irAMapas_c_l() {
    this.route.navigate(["/carretero-home/public-carretero/visor/mapa"]);
  }
  reg_info_c_l() {
    var usuario = localStorage.getItem("nomUsu");
    if (usuario == null) {
      localStorage.setItem("backProj", "1");
    }
    sessionStorage.setItem("tipoProy", "2");
    this.route.navigate(["carreteros/infGeneral"]);
  }
  irVideoAvance_c_l() {
    this.route.navigate(["youtube/private-home-principal"]);
  }
  reportes_a() {
    this.route.navigate(["aeropuertos-public/consulta"]);
  }
  reportes_a_l() {
    sessionStorage.setItem("tipoProy", "1");
    this.route.navigate(["aeropuertos/reportesAero"]);
  }
  irAMapas_a() {
    this.route.navigate(["aeropuertos-public/mapa"]);
  }
  irAMapas_a_l() {
    this.route.navigate(["aeropuerto-home/visor/mapa"]);
  }
  reg_info_a_l() {
    sessionStorage.setItem("tipoProy", "1");
    this.route.navigate(["aeropuertos/infGeneral"]);
  }

  reportes_p() {
    this.route.navigate(["carreteras-public/consulta"]);
  }
  irAMapas_p() {
    this.route.navigate(["puertos-public/mapa"]);
  }
  irFicha_p() {
    this.route.navigate(["puertos-public/ficha-proyecto-home"]);
  }
  irAMapas_p_l() {
    this.route.navigate(["puertos-home/visor/mapa"]);
  }
  reg_info_p_l() {
    sessionStorage.setItem("tipoProy", "5");
    this.route.navigate(["puertos/infGeneral"]);
    //this.route.navigate(['puertos-public/home-registrar'])
  }
  seg_gest_p_l() {
    this.route.navigate(["puertos/reportesPuer"]);
    //this.route.navigate(['puertos-privado/home-ficha-proyecto'])
  }
  reg_fich_p_l() {
    this.route.navigate(["puertos-public/home-registrar"]);
  }
  reportes_fr() {
    this.route.navigate(["ferrocarriles-public/consulta"]);
  }
  irAMapas_fr() {
    this.route.navigate(["ferrocarriles-public/mapa"]);
  }
  irAMapas_fr_l() {
    this.route.navigate(["public-ferreo/visor-mapa"]);
  }
  reportes_fr_l() {
    this.route.navigate(["ferrocarriles/reportesFerro"]);
  }
  reg_info_fr_l() {
    sessionStorage.setItem("tipoProy", "4");
    this.route.navigate(["ferrocarriles/infGeneral"]);
  }

  reportes_fl() {
    this.route.navigate(["fluvial-public/consulta"]);
  }
  irAMapas_fl() {
    this.route.navigate(["fluvial-public/mapa"]);
  }
  reportes_fl_l() {
    this.route.navigate(["fluvial-public/consulta"]);
  }
  reg_info_fl_l() {
    var usuario = localStorage.getItem("nomUsu");
    if (usuario == null) {
      localStorage.setItem("backProj", "1");
    }
    sessionStorage.setItem("tipoProy", "6");
    this.route.navigate(["fluvial/infGeneral"]);
  }
  tramites_tr_l() {
    this.route.navigate(["tramites"]);
  }

  display: boolean = false;
  displayModal: boolean;
  showDialog() {
    this.display = true;
  }
  showModalDialog() {
    this.displayModal = true;
    var element = document.getElementById("aniscopio_body");
    element.classList.add("modalsuper");
  }
  noShowModalDialog() {
    this.displayModal = false;
    var element = document.getElementById("aniscopio_body");
    element.classList.remove("modalsuper");
  }
}
