import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CtoContratoInformacionVigenteEncabezadoDTO } from '../contratacion-dto/contrato-informacion-vigente-encabezado';
import { ContratacionService } from '../contratacion.service';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from 'src/app/comun/constantes/constantes-comunes';

@Component({
  selector: 'app-contrato-informacion-vigente-encabezado',
  templateUrl: './contrato-informacion-vigente-encabezado.component.html',
  styles: []
})

export class ContratoInformacionVigenteEncabezadoComponent implements OnInit, OnChanges {

  @Input()
  idContrato: number;
  @Input()
  fijarAcordeon = true;
  @Output()
  ctoContratoInformacionVigenteOutput = new EventEmitter<CtoContratoInformacionVigenteEncabezadoDTO>();
  ctoContratoInformacionVigenteEncabezado: CtoContratoInformacionVigenteEncabezadoDTO = {};

  constructor(
    private contratacionService: ContratacionService,
    private route: Router,
    private ruta: ActivatedRoute,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarEncabezado(this.idContrato);
  }

  /**
   * Metodo para consultar un contrato o el listado de contratos registrados con la información vigente a la fecha
   * @param idContrato Id del contrato a consultar
   */
  cargarEncabezado(idContrato) {
    this.contratacionService.srvConsultarContratosInformacionVigente(idContrato, null, null, null, null, null, null, 0, 1, null, 'Id', 1)
      .subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.ctoContratoInformacionVigenteEncabezado = result.contratoInformacionVigenteEncabezado;
              this.ctoContratoInformacionVigenteOutput.emit(this.ctoContratoInformacionVigenteEncabezado);
            } else {
              console.error(result.respuestaServicio.mensajeSalida);
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        }
      );

  }

  ngOnChanges() {
    // console.log('ngOnChanges');
    this.cargarEncabezado(this.idContrato);
  }
}
